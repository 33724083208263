import styles from "./SettingsLayout.module.scss";

export const SettingsLayout = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h1>Settings</h1>
      </div>
      <div className={styles.content}>
        {/*Temp content, the splash style is likely to be removed*/}
        <div className={styles.splash}>Coming soon</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
