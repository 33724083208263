import React from "react";
import styles from "./GradingDataError.module.scss";

import { MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES } from "../../../../../state/workspace/grading/types";

export const GradingDataError = () => {
  return (
    <div className={styles.root} data-test-id="Ws-Grading-Data-Error">
      <div className={styles.header}>
        {MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES.NOT_VALID.header}
      </div>
      <div className={styles.body}>
        {MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES.NOT_VALID.body}
      </div>
    </div>
  );
};

GradingDataError.propTypes = {};

export default GradingDataError;
