import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./GradingPage.module.scss";
import { connect } from "react-redux";
import {
  getGradingCard,
  getAssigneeData,
} from "../../../../state/workspace/grading/selectors";
import LocationChangeHandler from "./LocationChangeHandler";
import GradingDocumentContainer from "../../../partial/Workspace/Grading/GradingDocumentContainer/GradingDocumentContainer";
import GradingAssigneeInfoPanel from "../../../partial/Workspace/Grading/GradingAssigneeInfoPanel/GradingAssigneeInfoPanel";
import GradingTitle from "../../../partial/Workspace/Grading/GradingTitle/GradingTitle";
import {
  MYWORKSPACES_GRADING_CARD_TYPE,
  MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
} from "../../../../state/workspace/grading/types";
import { APP_PAGES } from "../../../../state/router/types";

export const GradingPage = ({ card, assigneeData }) => {
  const [pageTitle, setPageTitle] = useState(APP_PAGES.WORKSPACE_GRADING.title);

  useEffect(() => {
    if (card && assigneeData) {
      setPageTitle(
        `${card.Title} - ${assigneeData.LastName} ${assigneeData.FirstName} - ${APP_PAGES.WORKSPACE_GRADING.title}`
      );
    } else {
      setPageTitle(`${APP_PAGES.WORKSPACE_GRADING.title}`);
    }
  }, [card, assigneeData]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <LocationChangeHandler />

      <div className={styles.root} data-test-id="ws-Grading-GradingPanel">
        <GradingTitle />
        <div className={styles.gradingContentAndPanel}>
          <main className={styles.gradingMain}>
            <GradingDocumentContainer />
          </main>
          <GradingAssigneeInfoPanel />
        </div>
      </div>
    </div>
  );
};

GradingPage.propTypes = {
  card: MYWORKSPACES_GRADING_CARD_TYPE,
  assigneeData: MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
};

export default connect((state) => ({
  card: getGradingCard(state),
  assigneeData: getAssigneeData(state),
}))(GradingPage);
