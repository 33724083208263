import React from "react";
import styles from "./SideNavItem.module.scss";

interface EmptyStateProps {
  emptyStateText: string;
}

export const EmptyState = ({ emptyStateText }: EmptyStateProps) => {
  return (
    <div className={styles.stateContainer}>
      <div className={styles.text}>{emptyStateText}</div>
    </div>
  );
};
