import { Button, BUTTON_SIZES } from "@hapara/ui/src/atomic/Button/Button";
import styles from "./EvidenceStatus.module.scss";
import classnames from "classnames";
import { MouseEvent } from "react";

interface EvidenceStatusProps {
  isStaticCard: boolean;
  className?: string;
  dataTestIdPrefix: string;
  isDarkCard?: boolean;
  cardColour?: string;
  sectionTitle: string;
  evidenceTitle: string;
  setEvidenceModalPayload: (modalResponse: [string, string]) => void;
}
const EvidenceStatus = ({
  isStaticCard,
  className,
  dataTestIdPrefix,
  sectionTitle,
  evidenceTitle,
  setEvidenceModalPayload,
  isDarkCard,
  cardColour,
}: EvidenceStatusProps) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    //In the future this will take the user to the evidence page(s)
    e.preventDefault();
    e.stopPropagation();
    setEvidenceModalPayload([evidenceTitle, sectionTitle]);
  };

  // console.log('sectionTitle', typeof sectionTitle)
  // console.log('evidence', typeof evidenceTitle)

  return (
    <div
      className={classnames(styles.root, className ?? "", {
        [styles.isStaticCard]: isStaticCard,
      })}
      role="group"
      aria-label="Activity status"
    >
      {!isStaticCard && (
        <div
          className={classnames(styles.statusTitle, {
            [styles.lightCardTitle]: !isDarkCard,
          })}
        >
          Individual evidence
        </div>
      )}
      <div
        className={classnames(styles.statusGroup, {
          [styles.isStaticCard]: isStaticCard,
        })}
        //The Button component does not allow the passing of the onClick event so capture in the parent
        //When choosing the modal to open the Button component may need changing to allow the passing of the onClick event
        //So we can behave differently depending on the button clicked.
        onClick={(e: MouseEvent<HTMLDivElement>) => onClick(e)}
      >
        <Button
          className={classnames(styles.notStartedButton, {
            [styles.lightCardButton]: cardColour === "1",
            [styles.darkCardButton]: cardColour !== "1",
          })}
          size={BUTTON_SIZES.XSMALL}
          label={
            <>
              <b>101</b> not started
            </>
          }
          dataTestId={`${dataTestIdPrefix}-button-not-started`}
        />
        <Button
          className={classnames(styles.submittedButton, {
            [styles.lightCardButton]: cardColour === "1",
            [styles.darkCardButton]: cardColour !== "1",
          })}
          size={BUTTON_SIZES.XSMALL}
          label={
            <>
              <b>50</b> submitted
            </>
          }
          dataTestId={`${dataTestIdPrefix}-button-submitted`}
        />
      </div>
    </div>
  );
};

export default EvidenceStatus;
