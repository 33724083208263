import { hideAppError, showAppError } from "../../app/actions";
import actionTypes from "../../actionTypes";
import { AppDispatch } from "../../store";
import {
  fetchClassesList,
  postAddClass,
  postGCSync,
  postUpdateClassColour,
  postUpdateClassesOrder,
  postUpdateClassStarStatus,
} from "../../../apiCalls/dashboard";
import { ClassInfo } from "../../../types/dashboard";
import { LoadClassesSuccessAction } from "./types";
import { GoogleClassroomCourse } from "../../../types/shared";
import { updateClassesListSyncStatus } from "./statusPollService";
import { getMyClassesAction } from "../../shared/actions";

export const loadClassesList =
  (query: string, domain: string, school: string, allClasses: boolean) =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.DASHBOARD_CLASS_LIST_PENDING,
    });
    dispatch(hideAppError());
    fetchClassesList(query, domain, school, allClasses)
      .then((data: ClassInfo[]) => {
        dispatch<LoadClassesSuccessAction>({
          type: actionTypes.DASHBOARD_CLASS_LIST_SUCCESS,
          payload: data,
        });
        if (data.filter((cl) => cl.isClassSyncInProgress).length > 0) {
          dispatch(updateClassesListSyncStatus());
        }
        return data;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DASHBOARD_CLASS_LIST_ERROR,
        });
      });
  };

type UpdateOrder = {
  id: string;
  priority: number;
};

export const updateClassesOrder =
  (updates: UpdateOrder[]) => (dispatch: AppDispatch) => {
    Promise.all(
      updates.map((u) => {
        return postUpdateClassesOrder(u.id, u.priority);
      })
    )
      .then(() => {
        dispatch({
          type: actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_PRIORITY_UPDATED,
          updates,
        });
      })
      .catch((error) => {
        //ignore errors for now
      });
  };

export const updateClassStarStatus =
  (classURN: string, isStarred: boolean) => (dispatch: AppDispatch) => {
    return postUpdateClassStarStatus(classURN, isStarred)
      .then(() => {
        dispatch({
          type: actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_STAR_UPDATED,
          classURN,
          isStarred,
        });
        dispatch(getMyClassesAction());
      })
      .catch((error) => {
        dispatch(showAppError(error, "api-error"));
      });
  };

export const updateClassColour =
  (classURN: string, newColour: string) => (dispatch: AppDispatch) => {
    return postUpdateClassColour(classURN, newColour).then(() => {
      dispatch({
        type: actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_COLOUR_UPDATED,
        classURN,
        newColour,
      });
      return true;
    });
  };

export const addClass =
  (name: string, domain: string, school: string) => (dispatch: AppDispatch) => {
    return postAddClass(name, domain, school).then((cl: ClassInfo) => {
      window.location.assign(
        `${process.env.REACT_APP_HSUITE_BASE_URL}/class-info/${cl.id}`
      );
      return true;
    });
  };

export const syncGoogleClassromsClasses =
  (domain: string, school: string, classes: GoogleClassroomCourse[]) =>
  (dispatch: AppDispatch) => {
    return postGCSync(domain, school, classes).then((cls: ClassInfo[]) => {
      dispatch({
        type: actionTypes.DASHBOARD_MANAGE_CLASSES_GC_CLASSES_SYNCED,
        payload: cls,
      });
      dispatch(updateClassesListSyncStatus());
      dispatch(getMyClassesAction());
      return true;
    });
  };

// UI actions

export const openEditColourDialog = (editedClass: ClassInfo) => {
  return {
    type: actionTypes.DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_OPEN,
    payload: editedClass,
  };
};
export const closeEditColourDialog = () => {
  return { type: actionTypes.DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_CLOSE };
};

export const openAddClassDialog = () => {
  return { type: actionTypes.DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_OPEN };
};

export const closeAddClassDialog = () => {
  return { type: actionTypes.DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_CLOSE };
};

export const openGCSyncDialog = () => {
  return { type: actionTypes.DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_OPEN };
};

export const closeGCSyncDialog = () => {
  return { type: actionTypes.DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_CLOSE };
};

export const setSearchClassesQuery = (query: string) => ({
  type: actionTypes.DASHBOARD_MANAGE_CLASSES_SET_SEARCH_CLASSES_QUERY,
  query,
});
