import actionTypes from "../../actionTypes";
import {
  actionStudentRequest,
  getStudentRequestsCall,
} from "../../../apiCalls/highlights";
import { AppDispatch } from "../../store";
import { studentRequest } from "./types";

export const getStudentRequests = () => (dispatch: AppDispatch) => {
  dispatch({
    type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_PENDING,
  });
  getStudentRequestsCall()
    .then((response) => response.data)
    .then((data) => {
      dispatch({
        type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_SUCCESS,
        payload: data as studentRequest[],
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_ERROR,
      });
    });
};

export const approveStudentRequest =
  (id: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_PENDING,
      payload: id,
    });
    actionStudentRequest({ id, approved: true })
      .then(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_SUCCESS,
          payload: id,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_ERROR,
          payload: id,
        });
      });
  };

export const rejectStudentRequest = (id: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_PENDING,
    payload: id,
  });
  actionStudentRequest({ id: id, approved: false })
    .then(() => {
      dispatch({
        type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_SUCCESS,
        payload: id,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_ERROR,
        payload: id,
      });
    });
};

export const dismissSuccessNotification =
  (id: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_DISMISS_SUCCESS_NOTIFICATION,
      payload: id,
    });
  };
