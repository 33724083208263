import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./RecipientPicker.module.scss";
import _ from "lodash";
import classnames from "classnames";
import MultiSelectList from "../../../../atomic/MultiSelectList/MultiSelectList";
import ContentLoading from "../../../ContentLoading/ContentLoading";
import {
  getMyClasses,
  getStudents,
  getUserGroups,
} from "../../../../apiCalls/smartshare";
import Reveal, {
  RevealDetails,
  RevealToggle,
  withExpanded,
} from "../../../../atomic/Reveal/Reveal";
import Checkbox from "../../../../atomic/Checkbox/Checkbox";
import HapReactIcon from "../../../../atomic/icon/hapReactIcon";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "../../../../atomic/Dropdown/Dropdown";
import { THEME_TYPES } from "../../../../atomic/consts";
import FormBlock from "../../../FormBlock/FormBlock";

export const SHARE_RECIPIENT_CLASSES = "classes";
export const SHARE_RECIPIENT_STUDENTS = "students";
export const SHARE_RECIPIENT_GROUPS = "groups";

export const SHARE_RECIPIENT_TYPES = [
  SHARE_RECIPIENT_CLASSES,
  SHARE_RECIPIENT_STUDENTS,
  SHARE_RECIPIENT_GROUPS,
];

export const UPREF_NAME_FIRST_LAST = "fname_lname";
export const UPREF_NAME_LAST_FIRST = "lname_fname";

export const getFormattedName = (nameFormat, assignee) => {
  return nameFormat === UPREF_NAME_FIRST_LAST
    ? `${assignee.FirstName} ${assignee.LastName}`
    : `${assignee.LastName}, ${assignee.FirstName}`;
};

const RecipientPicker = ({
  recipientType,
  selectedStudents = [],
  onSelectedStudentsChange,
  dataTestPrefix,
  nameFormat,
  groupUrns = [],
  onGroupUrnsChange,
  classUrn,
  onClassFoldersChange,
  classesUrns,
  folders = [],
  currentClassSelectedFolder,
  onCurrentClassSelectedFolderChange,
  setRecipientType,
}) => {
  const intl = useIntl();
  const [classes, setClasses] = useState(null);
  const { folderType } = useParams();
  let currentClass = null;
  let hasFolders = false;
  if (classes && classes.length > 0) {
    currentClass = _.find(classes, (cl) => cl.id === classUrn);
    if (currentClass) {
      hasFolders =
        currentClass.subject_folders && currentClass.subject_folders.length > 0;
      if (hasFolders && currentClass.subject_folders.length > 1) {
        currentClass.subject_folders = _.filter(
          currentClass.subject_folders,
          (folder) => {
            return folder !== "*";
          }
        );
      }
    }
  }
  const getFolderName = (folder, classInfo) => {
    if (classInfo.isGoogleClassroom) {
      return "Google Classroom folder in Drive";
    }
    if (folder === "root" || folder === "*") {
      return "students' Google Drive";
    }
    return folder;
  };

  const isSpecialFolder = (folder) => {
    return folder === "root" || folder === "*";
  };

  const preSelectFolder = useCallback(
    (classes) => {
      if (!currentClassSelectedFolder) {
        currentClass = _.find(classes, (cl) => cl.id === classUrn);
        if (currentClass) {
          hasFolders =
            currentClass.subject_folders &&
            currentClass.subject_folders.length > 0;
          const currentLocation = folderType
            ? window.location.href
            : window.location.hash;
          const folderName = folderType
            ? folderType
            : decodeURIComponent(currentLocation.split("/")[4]);
          const selectedFolder = _.find(
            currentClass.subject_folders,
            (f) => f === folderName
          );
          const preSelectedFolders = hasFolders
            ? [
                {
                  name: selectedFolder
                    ? selectedFolder
                    : currentClass.subject_folders[0],
                  class_id: classUrn,
                  class_name: currentClass.name,
                },
              ]
            : [
                {
                  name: "root",
                  class_id: classUrn,
                  class_name: currentClass.name,
                },
              ];
          onClassFoldersChange(preSelectedFolders);
          onCurrentClassSelectedFolderChange(
            preSelectedFolders[0] ? preSelectedFolders[0].name : null
          );
        }
      }
    },
    [
      onClassFoldersChange,
      onCurrentClassSelectedFolderChange,
      currentClassSelectedFolder,
    ]
  );

  const loadClasses = useCallback(async () => {
    if (classes) {
      return classes.length;
    }
    const cls = await getMyClasses(classUrn);
    setClasses(cls);
    //we also want to auto-select folder if it's the only folder in the current class
    preSelectFolder(cls);
    return cls.length;
  }, [getMyClasses, setClasses, classUrn, classes, preSelectFolder]);

  const [students, setStudents] = useState(null);
  const loadStudents = useCallback(async () => {
    if (students) {
      return students.length;
    }
    preSelectFolder(classes);
    const sts = await getStudents(classUrn);
    setStudents(sts);
    onSelectedStudentsChange([]);
    return sts.length;
  }, [
    getStudents,
    setStudents,
    classUrn,
    students,
    currentClass,
    preSelectFolder,
  ]);

  const [groups, setGroups] = useState(null);
  const [groupsToShow, setGroupsToShow] = useState(null);
  const [groupsViewType, setGroupsViewType] = useState();

  const loadGroups = useCallback(async () => {
    if (groups) {
      return groups.length;
    }
    preSelectFolder(classes);
    const resp = await getUserGroups(classUrn);
    const groupsViewType = _.get(resp, "viewType");
    const grs = _.get(resp, "groups");
    setGroupsViewType(groupsViewType);
    setGroups(grs);
    onGroupUrnsChange([], grs);
    return grs.length;
  }, [getUserGroups, setGroups, classUrn, groups, preSelectFolder]);

  useEffect(() => {
    if (groupsViewType === "SPECIFIC_TO_TAB") {
      const folderName =
        currentClassSelectedFolder === "root"
          ? "documents"
          : currentClassSelectedFolder;
      setGroupsToShow(
        (groups || []).filter((g) => g.id.includes(_.toLower(folderName)))
      );
    } else {
      setGroupsToShow(groups);
    }
  }, [groupsViewType, groups, currentClassSelectedFolder]);

  const folderSelectionId = "folderSelectionId";
  const getFolderSelectionComponent = () => {
    return (
      <div className={styles.folderSelection}>
        {isSpecialFolder(currentClassSelectedFolder) && (
          <div>
            <label className={styles.folderSelectionLabel}>
              Files will be shared to the{" "}
              {getFolderName(currentClassSelectedFolder, currentClass)}.
            </label>
          </div>
        )}
        {!isSpecialFolder(currentClassSelectedFolder) && (
          <>
            <label
              htmlFor={folderSelectionId}
              className={styles.folderSelectionLabel}
            >
              <FormattedMessage
                defaultMessage="Share files to this folder:"
                id="/Wt/SM"
              />
            </label>
            <Dropdown
              label={intl.formatMessage({
                defaultMessage: "Select folder",
                id: "jOJqwS",
              })}
              positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
              widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
              triggerComponent={(props) => (
                <DropdownTrigger
                  label={
                    !hasFolders || currentClassSelectedFolder === "root"
                      ? "Documents"
                      : currentClassSelectedFolder || "Select folder"
                  }
                  id={folderSelectionId}
                  widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                  data-test-id="common-Smartshare-Dialog-ChooseSelectedFolder-DropdownTrigger"
                  className={styles.shareTypesDropdownTrigger}
                  {...props}
                />
              )}
              itemComponentList={(hasFolders
                ? currentClass.subject_folders
                : ["root"]
              ).map((folder) => ({ onClick, className, ...rest }) => (
                <button
                  className={classnames(className, {
                    [styles.selected]: !hasFolders
                      ? folder === "root"
                      : folder === currentClassSelectedFolder,
                  })}
                  data-test-id="common-Smartshare-Dialog-ChooseSelectedFolder-Action"
                  aria-label={`Select ${folder}`}
                  onClick={() => {
                    onClick();
                    onClassFoldersChange([
                      {
                        name: folder,
                        class_id: currentClass.id,
                        class_name: currentClass.name,
                      },
                    ]);
                    onCurrentClassSelectedFolderChange(folder);
                  }}
                  {...rest}
                >
                  {folder}
                </button>
              ))}
            />
          </>
        )}
      </div>
    );
  };
  let isTitleBorder = false;
  if (
    recipientType === SHARE_RECIPIENT_STUDENTS &&
    students &&
    students.length > 0
  ) {
    isTitleBorder = true;
  }
  if (recipientType === SHARE_RECIPIENT_GROUPS && groups && groups.length > 0) {
    isTitleBorder = true;
  }
  return (
    <FormBlock
      className={classnames(styles.secondFormBlock)}
      variant={THEME_TYPES.DARK}
      dataTestIdPrefix="common-Smartshare-Dialog-Select-Recipient"
      options={[
        { label: "Class(es)", value: SHARE_RECIPIENT_CLASSES },
        { label: "Student(s)", value: SHARE_RECIPIENT_STUDENTS },
        { label: "Group(s)", value: SHARE_RECIPIENT_GROUPS },
      ]}
      title={intl.formatMessage({
        defaultMessage: "Who are you sharing to?",
        id: "xbrCOU",
      })}
      selectedOption={recipientType}
      onOptionSelect={(e) => setRecipientType(e.target.value)}
      isTitleBorder={isTitleBorder}
    >
      <ContentLoading
        loadFunc={loadClasses}
        dataTestIdPrefix="common-Smartshare-ClassesConfig-ContentLoading"
        errorMessageText="Trouble loading classes. Please check your connection and try again."
        emptyMessageText="No classes found"
      >
        <div
          data-test-id={`${dataTestPrefix}-FormStudentPickerBlock`}
          className={styles.root}
        >
          {recipientType === SHARE_RECIPIENT_STUDENTS && (
            <ContentLoading
              loadFunc={loadStudents}
              dataTestIdPrefix="common-Smartshare-StudentsList-ContentLoading"
              errorMessageText="Trouble loading students. Please check your connection and try again."
              emptyMessageText="No students found"
            >
              {getFolderSelectionComponent()}
              <div className={styles.selectionComponent}>
                <MultiSelectList
                  items={_.sortBy(students, (s) =>
                    getFormattedName(nameFormat, s)
                  ).map((s) => {
                    return { name: getFormattedName(nameFormat, s), id: s.id };
                  })}
                  selectedItems={(students || [])
                    .map((s) => {
                      return {
                        name: getFormattedName(nameFormat, s),
                        id: s.id,
                        email: s.email,
                      };
                    })
                    .filter(
                      (s) => !!_.find(selectedStudents, (st) => st.id === s.id)
                    )}
                  onItemsSelect={(selected) =>
                    onSelectedStudentsChange(
                      selected.map((st) =>
                        _.find(students, (s) => s.id === st.id)
                      )
                    )
                  }
                  dataTestPrefix={`${dataTestPrefix}-StudentsSelect`}
                  itemsName="students"
                />
              </div>
            </ContentLoading>
          )}

          {recipientType === SHARE_RECIPIENT_GROUPS && (
            <ContentLoading
              loadFunc={loadGroups}
              dataTestIdPrefix="common-Smartshare-GroupsList-ContentLoading"
              errorMessageText="Trouble loading groups. Please check your connection and try again."
              emptyMessageText="No groups found"
            >
              {getFolderSelectionComponent()}
              <div className={styles.selectionComponent}>
                <MultiSelectList
                  itemsName="groups"
                  items={_.sortBy(groupsToShow, (g) => g.name)}
                  selectedItems={(groupsToShow || []).filter(
                    (s) => groupUrns.indexOf(s.id) >= 0
                  )}
                  onItemsSelect={(selected) => {
                    onGroupUrnsChange(
                      selected.map((s) => s.id),
                      groupsToShow,
                      _.map(selected, "name")
                    );
                  }}
                  dataTestPrefix={`${dataTestPrefix}-GroupsSelect`}
                />
              </div>
            </ContentLoading>
          )}

          {recipientType === SHARE_RECIPIENT_CLASSES && (
            <div className={styles.classList}>
              {(_.sortBy(classes, (cl) => _.toLower(cl.name)) || []).map(
                (cl) => {
                  return (
                    <Reveal
                      key={cl.id}
                      expanded={
                        (classesUrns.length === 0
                          ? [classUrn]
                          : classesUrns
                        ).indexOf(cl.id) >= 0
                      }
                    >
                      {withExpanded((isExpanded) => {
                        return (
                          <div
                            key={cl.id}
                            className={classnames(styles.classSelection, {
                              [styles.noBorder]: isExpanded,
                            })}
                            data-test-id={`common-Smartshare-ClassesConfig-${cl.id}`}
                          >
                            <RevealToggle
                              data-test-id={`common-Smartshare-ClassesConfig-${cl.id}-Expand-Button`}
                              className={classnames(styles.expandButton, {
                                [styles.expanded]: isExpanded,
                              })}
                            >
                              <h3 className={styles.title}>{cl.name}</h3>
                              <div className={styles.buttons}>
                                {cl.isGoogleClassroom && (
                                  <HapReactIcon
                                    svg="google-classroom"
                                    width={18}
                                    height={18}
                                    alt=""
                                    className={styles.icon}
                                  />
                                )}
                                <HapReactIcon
                                  svg={
                                    isExpanded
                                      ? "arrow-angle-up"
                                      : "arrow-angle-down"
                                  }
                                  width={18}
                                  height={18}
                                  alt=""
                                />
                              </div>
                            </RevealToggle>
                            <RevealDetails>
                              {(cl.subject_folders
                                ? cl.subject_folders.length > 1
                                  ? _.sortBy(
                                      _.filter(cl.subject_folders, (sf) => {
                                        return sf !== "*";
                                      }),
                                      (f) => _.toLower(f)
                                    )
                                  : _.sortBy(cl.subject_folders, (f) =>
                                      _.toLower(f)
                                    )
                                : ["root"]
                              ).map((f) => {
                                const isSelected = !!_.find(
                                  folders,
                                  (fl) => fl.name === f && fl.class_id === cl.id
                                );
                                return (
                                  <div
                                    className={classnames(styles.folderSelect, {
                                      [styles.folderSelected]: isSelected,
                                    })}
                                    key={f}
                                  >
                                    <Checkbox
                                      label={_.upperFirst(getFolderName(f, cl))}
                                      checked={isSelected}
                                      dataTestIdPrefix="common-Smartshare-DialogFolderNameCheckbox"
                                      className={styles.folderNameCheckbox}
                                      onChange={(isChecked) => {
                                        onClassFoldersChange(
                                          isChecked
                                            ? folders.concat({
                                                name: f,
                                                class_id: cl.id,
                                                class_name: cl.name,
                                              })
                                            : folders.filter(
                                                (fl) =>
                                                  !(
                                                    fl.name === f &&
                                                    fl.class_id === cl.id
                                                  )
                                              )
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </RevealDetails>
                          </div>
                        );
                      })}
                    </Reveal>
                  );
                }
              )}
            </div>
          )}
        </div>
      </ContentLoading>
    </FormBlock>
  );
};

export const CLASS_FOLDER = PropTypes.shape({
  name: PropTypes.string,
  class_id: PropTypes.string,
});
export const STUDENT_INFO = PropTypes.shape({
  email: PropTypes.string,
  id: PropTypes.string,
});

RecipientPicker.propTypes = {
  recipientType: PropTypes.oneOf(SHARE_RECIPIENT_TYPES),
  selectedStudents: PropTypes.arrayOf(STUDENT_INFO),
  onSelectedStudentsChange: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  nameFormat: PropTypes.string.isRequired,
  groupUrns: PropTypes.arrayOf(PropTypes.string),
  onGroupUrnsChange: PropTypes.func.isRequired,
  classUrn: PropTypes.string.isRequired,
  classesUrns: PropTypes.arrayOf(PropTypes.string).isRequired,
  folders: PropTypes.arrayOf(CLASS_FOLDER),
  onClassFoldersChange: PropTypes.func.isRequired,
  currentClassSelectedFolder: PropTypes.string,
  onCurrentClassSelectedFolderChange: PropTypes.func.isRequired,
  setRecipientType: PropTypes.func.isRequired,
};

export default RecipientPicker;
