// TODO: roll-in and remove AlertDeprecated dependency
import React from "react";
import { type AlertType } from "../types";
import styles from "./AlertIcon.module.scss";

import { CircleWarningIcon } from "../../../icons/CircleWarningIcon";
import { CircleCheckIcon } from "../../../icons/CircleCheckIcon";
import { CircleInfoIcon } from "../../../icons/CircleInfoIcon";
import { IconProps } from "../../../icons/utils/createIcon";

type AlertIconProps = {
  type: AlertType;
  // TODO: implement size: md, lg
};

const iconMap: Record<AlertType, React.ComponentType<IconProps>> = {
  success: CircleCheckIcon,
  danger: CircleWarningIcon,
  warning: CircleWarningIcon,
  info: CircleInfoIcon,
};

export const AlertIcon = ({ type }: AlertIconProps) => {
  const Icon = iconMap[type];
  return <Icon className={styles[type]} />;
};
