import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PageLoading.module.scss";
import { LinearProgress } from "../../LinearProgress";

import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "../../Button/Button";
import HapReactIcon from "../../icon/hapReactIcon";

const PageLoading = ({
  className,
  isError = false,
  isCenteredAlert = false,
  useErrorIcon = false,
  dataTestIdPrefix = "ws-Page-Loading",
  errorMessageText,
  isTryAgainVisible = true,
  isGoBackVisible = true,
  renderButtonBlock = true,
}) => {
  const intl = useIntl();

  const defaultErrorMessageText = intl.formatMessage({
    defaultMessage: "Sorry, there was a problem loading this page.",
    id: "yWZY5B",
  });

  return (
    <div className={classnames(styles.root, className)}>
      {isError && (
        <>
          <div
            className={classnames(styles.text, {
              [styles.centerText]: isCenteredAlert,
            })}
          >
            {useErrorIcon && <HapReactIcon svg="fill-circle-exclamation" />}
            {errorMessageText ?? defaultErrorMessageText}
          </div>
          {renderButtonBlock && (
            <div className={styles.buttons}>
              {isGoBackVisible && (
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Go back",
                    id: "orvpWh",
                  })}
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  size={BUTTON_SIZES.LARGE}
                  onClick={() => window.history.back()}
                  dataTestId={`${dataTestIdPrefix}-GoBackButton`}
                />
              )}
              {isTryAgainVisible && (
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Try again",
                    id: "FazwRl",
                  })}
                  type={
                    isGoBackVisible
                      ? BUTTON_TYPES.PRIMARY
                      : BUTTON_TYPES.OUTLINED
                  }
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  size={BUTTON_SIZES.LARGE}
                  onClick={() => window.location.reload()}
                  dataTestId={`${dataTestIdPrefix}-TryAgainButton`}
                />
              )}
            </div>
          )}
        </>
      )}
      {!isError && (
        <>
          <LinearProgress className={styles.progressbar} />
          <div className={styles.text}>
            <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
          </div>
        </>
      )}
    </div>
  );
};

PageLoading.propTypes = {
  className: PropTypes.string,
  isError: PropTypes.bool,
  isCenteredAlert: PropTypes.bool,
  useErrorIcon: PropTypes.bool,
  isTryAgainVisible: PropTypes.bool,
  isGoBackVisible: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string,
  errorMessageText: PropTypes.string,
  renderButtonBlock: PropTypes.bool,
};

export default PageLoading;
