import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { pushQueryToHistory } from "../../../../state/router/navigation";
import { resourceItemType } from "../../../../state/resources/types";
import styles from "./ResourceTileList.module.scss";
import ResourceTile from "../ResourceTile/ResourceTile";
import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";
import { RESOURCE_FORMAT } from "../../../../state/resources/types";
export const ResourceTileList = ({
  items,
  currentPage,
  pagesCount,
  dataTestIdPrefix,
  emptyState,
  dataTestIdsFF,
}) => {
  if (items && items.length === 0) {
    return (
      <div className={styles.root}>
        <div className={styles.emptyState}>{emptyState}</div>
      </div>
    );
  }

  const lb_ResourceTile = "lb-ResourceTile";
  const lb_WorkspaceTile = "lb-WorkspaceTile";
  return (
    <div className={styles.root}>
      <>
        {items && items.length > 0 && (
          <ul className={styles.list}>
            {_.map(items, (item, ind) => (
              <li className={styles.listItem} key={`${item.ID}-${ind}`}>
                <ResourceTile
                  item={item}
                  dataTestIdPrefix={`${dataTestIdPrefix}-Tile`}
                  dataTestIdPrefixWithResourceTile={
                    item.Type === RESOURCE_FORMAT.WORKSPACE
                      ? lb_WorkspaceTile
                      : lb_ResourceTile
                  }
                />
              </li>
            ))}
          </ul>
        )}
        {pagesCount > 1 && (
          <Pagination
            pages={pagesCount}
            currentPage={currentPage}
            onSelectPage={(page) => pushQueryToHistory({ page })}
            dataTestIdPrefix={`${dataTestIdPrefix}-Pagination`}
          />
        )}
      </>
    </div>
  );
};

ResourceTileList.propTypes = {
  items: PropTypes.arrayOf(resourceItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  emptyState: PropTypes.node,
};

export default ResourceTileList;
