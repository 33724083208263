import actionTypes from "../../actionTypes";

const INIT_STATE = {
  isLoading: false,
  isError: false,
  isShared: false,
};

const clickToShareReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_PENDING: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isShared: false,
      };
    }

    case actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isShared: true,
      };
    }

    case actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isShared: false,
      };
    }

    default:
      return state;
  }
};

export default clickToShareReducer;
