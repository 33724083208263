import React, { useState, useEffect, useCallback } from "react";
import { normaliseUrl, validateUrl } from "../../../../../utils";
import styles from "./CardModal.module.scss";
import classnames from "classnames";
import _ from "lodash";

import { Button, BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";

interface InputModalProps {
  modalType: string;
  modalState: boolean;
  dataTestIdPrefix: string;
  modalStateCB: (isOpen: boolean) => void; //Modal open || closed
  modalPayloadCB: (linkPayload: {}) => void; //Modal link data
}

export const inputModalTypes = {
  INPUT_LINK: "inputLink",
  CONFIRM_ACTION: "confirmAction",
};

const InputModal = ({
  modalType,
  modalState,
  modalStateCB,
  modalPayloadCB,
  dataTestIdPrefix,
}: InputModalProps) => {
  const [inputValue, setInputValue] = useState<any>("");
  const [isError, setIsError] = useState<boolean>(false);

  //RESETS INPUT ON MODAL CLOSING
  useEffect(() => {
    setInputValue("");
    setIsError(false);
  }, [modalState]);

  //VALIDATES INPUT AND MANAGES ERROR STATES
  const handleInputSubmission = useCallback(() => {
    const normalisedURL = normaliseUrl(inputValue);
    const isValidLink = validateUrl(normalisedURL);

    if (isValidLink) {
      modalPayloadCB(compileLinkPayload(normalisedURL));
      modalStateCB(false);
    } else {
      setIsError(true);
    }
  }, [inputValue, modalPayloadCB, modalStateCB]);

  //LISTENS FOR ENTER TO RUN VALIDATION AND SAVE IF VALIDATION PASSES
  const handleEnterKey = useCallback(
    (e) => {
      if (modalState && !_.isEmpty(inputValue) && e.keyCode === 13) {
        handleInputSubmission();
        e.preventDefault(); //Prevents reopening of the modal on Enter keypress
      }
    },
    [modalState, inputValue, handleInputSubmission]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleEnterKey);
    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleEnterKey]);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    setIsError(false);
  };

  const handleCancel = (newStatus: boolean) => {
    setInputValue("");
    modalStateCB(newStatus);
  };

  const modalTextContent = {
    inputHeading: "Add link",
    inputPlaceholder: "Insert link here",
    inputButtonText: "Add link",
    inputClose: "Cancel",
    inputError: "Invalid URL. Please check for typos or extra spaces.",
  };

  return (
    <ModalDeprecated
      isOpen={modalState}
      onClose={() => modalStateCB(false)}
      classNameContent={styles.secondaryModal}
      className={styles.secondaryModalContent}
      dataTestPrefix={`${dataTestIdPrefix}-AddLink`}
      contentLabel={modalTextContent.inputHeading}
      hasEmbeddedHeader={false}
    >
      {modalType === "inputLink" && (
        <div className={styles.contentContainer}>
          <div
            className={styles.modalHeader}
            data-test-id={`${dataTestIdPrefix}-InputLinkModal-Header`}
          >
            <h1>{modalTextContent.inputHeading}</h1>
          </div>
          <div className={styles.modalBody}>
            <input
              value={inputValue}
              onChange={handleChange}
              placeholder={modalTextContent.inputPlaceholder}
              data-test-id={`${dataTestIdPrefix}-InputLinkModal-Input`}
              className={classnames(styles.inputField, {
                [styles.isError]: isError,
              })}
            ></input>
            {isError && (
              <div
                className={styles.errorMessage}
                data-test-id={`${dataTestIdPrefix}-InputLinkModal-ErrorAlert`}
              >
                {modalTextContent.inputError}
              </div>
            )}
          </div>
          <div className={styles.modalFooter}>
            <Button
              label={modalTextContent.inputClose}
              onClick={() => handleCancel(false)}
              type={BUTTON_TYPES.SECONDARY}
              aria-label={modalTextContent.inputClose}
              data-test-id={`${dataTestIdPrefix}-InputLinkModal-Cancel`}
              className={styles.modalTitleClose}
            />
            <Button
              label={modalTextContent.inputButtonText}
              onClick={handleInputSubmission}
              isDisabled={isError}
              type={BUTTON_TYPES.PRIMARY}
              aria-label={modalTextContent.inputButtonText}
              data-test-id={`${dataTestIdPrefix}-InputLinkModal-AddLink`}
              className={styles.modalTitleClose}
            />
          </div>
        </div>
      )}
    </ModalDeprecated>
  );
};

export default InputModal;

const compileLinkPayload = (link: string) => {
  return {
    IconURL: "",
    MimeType: null,
    Role: "Teacher",
    Title: link,
    Type: "URL",
    URI: `urn:url:${link}`,
    __typename: "Artifact",
  };
};
