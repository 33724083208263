import actionTypes from "../../actionTypes";

export const INIT_STATE = {
  lastUploadedFileId: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIBRARY_LAST_UPLOADED_FILE_UPDATE: {
      const { id } = action.payload;
      return {
        ...state,
        lastUploadedFileId: id,
      };
    }
    default:
      return state;
  }
};

export default reducer;
