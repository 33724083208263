import _ from "lodash";
import { createSelector } from "reselect";

import {
  getFilterClassId,
  getFilterCategoryType,
} from "../workspace/myworkspaces/list/selectors";
import { isInsideLibraryCollections } from "../library/collections/selectors";
import { MYWORKSPACES_FILTER_CATEGORY_TYPES } from "../workspace/myworkspaces/list/types";
import { getLocationQuery } from "../router/selectors";
import {
  SHARED_URL_KEYS,
  SHARED_URL_PARAMS_DEFAULT_VALUES,
} from "./navigation-keys";
import { SHARED_FILTER_TYPES, SORT_TYPE } from "./types";

export const getMyClasses = (state) => _.get(state, "shared.myClasses.items");

export const getMyClassesSortedByName = createSelector(getMyClasses, (cl) =>
  _.sortBy(cl, ["class_alias"])
);

export const areMyClassesLoading = (state) =>
  _.get(state, "shared.myClasses.isLoading");

export const areMyClassesLoaded = (state) =>
  _.get(state, "shared.myClasses.isLoaded");

export const isMyClassesLoadingError = (state) =>
  _.get(state, "shared.myClasses.isError");

export const getActiveClassesSubjectFolders = (state) => {
  const currentClass = _.find(getMyClasses(state), { id: getClassId(state) });
  return _.get(currentClass, "subjectFolders");
};

export const getActiveClassesDataSource = (state) => {
  const currentClass = _.find(getMyClasses(state), { id: getClassId(state) });
  return _.get(currentClass, "dataSource");
};

export const getClassSubjectFolders = (state, classId) => {
  const targetClass = _.find(getMyClasses(state), { id: classId });
  const subjectFolders = _.get(targetClass, "subjectFolders");
  return subjectFolders;
};

export const getClassId = createSelector(getLocationQuery, (locationQuery) => {
  const paramName = SHARED_URL_KEYS.classId.key;
  const searchParam = _.get(locationQuery, paramName);

  // classId has two different placements in the URL structure. Sometimes it is as a query, othertimes it is embedded in the URL.
  // URL structure will be made uniform, using the embedded classId rather than search query at some point in the future. Not ticket exists yet.

  const pathName = window.location.pathname;

  // Check if the classId is embedded in the path or in the query parameter
  let classId = null;
  const regexEmbedded =
    /\/dashboard\/(folder|drive|gmail|edublogs)\/([^/]+)\/.*/;
  const regexAlternative = /\/class-info\/([^/]+)/;
  const matchesEmbedded = pathName.match(regexEmbedded);
  const matchesAlternative = pathName.match(regexAlternative);

  if (matchesEmbedded) {
    classId = matchesEmbedded[2];
  } else if (matchesAlternative) {
    classId = matchesAlternative[1];
  } else if (searchParam) {
    classId = decodeURI(searchParam);
  }

  return classId || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
});

export const getCurrentClassName = (state) => {
  const currentClass = _.find(getMyClasses(state), { id: getClassId(state) });
  return _.get(currentClass, "class_alias", "No name");
};

export const notAllowedToShowWorkspaces = (state) => {
  const currentClassId = getClassId(state);
  const filterCategoryType = getFilterCategoryType(state);
  return (
    filterCategoryType === MYWORKSPACES_FILTER_CATEGORY_TYPES.CLASSES &&
    !!currentClassId &&
    !getFilterClassId(state)
  );
};

export const getCurrentPage = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = SHARED_URL_KEYS.page.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = parseInt(rawParam, 10);
    return parsedParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getSearchQuery = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = SHARED_URL_KEYS.query.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = rawParam ? window.decodeURIComponent(rawParam) : "";
    return parsedParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getDocTypeFilter = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = SHARED_URL_KEYS.docType.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = rawParam ? window.decodeURIComponent(rawParam) : "";
    return parsedParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getSelectedResourceId = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = SHARED_URL_KEYS.selectedResourceId.key;
    const rawParam = _.get(locationQuery, paramName);
    return rawParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getFiltersInfo = (state) => _.get(state, "shared.filters");

export const getAllFilters = createSelector(getFiltersInfo, (f) =>
  _.get(f, "allFilters")
);

export const areFiltersLoading = createSelector(getFiltersInfo, (f) =>
  _.get(f, "isLoading")
);

export const areFiltersLoaded = createSelector(getFiltersInfo, (f) =>
  _.get(f, "isLoaded", false)
);

export const getFilters = (typeOfFilters) =>
  createSelector(getAllFilters, (filters) => _.get(filters, typeOfFilters));

export const getSelectedFilters = (typeOfFilters) =>
  createSelector(
    [getLocationQuery, getFilters(typeOfFilters)],
    (locationQuery, allFilters) => {
      const paramName = _.get(
        _.find(SHARED_FILTER_TYPES, { value: typeOfFilters }),
        "paramName"
      );
      // get string of filter keys separated by comma
      const rawParam = _.get(locationQuery, paramName);

      // parse raw string into array
      const rawList = rawParam ? _.split(rawParam, ",") : [];

      // make sure all values in the array match existing filter keys
      const parsedList = [];

      if (!_.isEmpty(allFilters) && !_.isEmpty(rawList)) {
        // only save filters which exist in allFilters
        _.forEach(rawList, (filter) => {
          if (_.findIndex(allFilters, (item) => item.Key === filter) !== -1) {
            parsedList.push(filter);
          }
        });
      }

      return !_.isEmpty(parsedList)
        ? parsedList
        : SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
    }
  );

export const getSelectedFormatFilter = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = SHARED_URL_KEYS.format.key;
    const rawParam = _.get(locationQuery, paramName);
    return rawParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getSelectedSortOrderFilter = createSelector(
  [isInsideLibraryCollections, getLocationQuery],
  (isCollections, locationQuery) => {
    const paramName = SHARED_URL_KEYS.sortOrder.key;
    const rawParam = _.get(locationQuery, paramName);
    const defaultParam = isCollections
      ? SORT_TYPE.TITLE_AZ.type
      : SORT_TYPE.LAST_ADDED.type;
    return rawParam || defaultParam;
  }
);

export const numberSharedFiltersSelected = (state) => {
  let total = 0;
  for (let key in SHARED_FILTER_TYPES) {
    const arrayOfFilters = getSelectedFilters(SHARED_FILTER_TYPES[key].value)(
      state
    );
    total += arrayOfFilters.length;
  }
  return total;
};

export const getJoinedSharedFilterParams = createSelector(
  [
    numberSharedFiltersSelected,
    getSelectedFilters(SHARED_FILTER_TYPES.SUBJECTS.value),
    getFilters(SHARED_FILTER_TYPES.SUBJECTS.value),
    getSelectedFilters(SHARED_FILTER_TYPES.STANDARDS.value),
    getFilters(SHARED_FILTER_TYPES.STANDARDS.value),
    getSelectedFilters(SHARED_FILTER_TYPES.GRADES.value),
    getFilters(SHARED_FILTER_TYPES.GRADES.value),
    getSelectedFilters(SHARED_FILTER_TYPES.LICENCES.value),
    getFilters(SHARED_FILTER_TYPES.LICENCES.value),
    getSelectedFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value),
    getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value),
  ],
  (
    totalSelected,
    selectedSubjects,
    allSubjects,
    selectedStandards,
    allStandards,
    selectedGrades,
    allGrades,
    selectedLicences,
    allLicences,
    selectedResourceTypes,
    allResourceTypes
  ) => {
    if (!totalSelected) return "";

    let listToJoin = [];

    if (!_.isEmpty(selectedSubjects)) {
      _.forEach(selectedSubjects, (subject) => {
        const item = _.find(allSubjects, { Key: subject });
        if (item && item["Value"]) {
          listToJoin.push(item["Value"]);
        }
      });
    }

    if (!_.isEmpty(selectedGrades)) {
      _.forEach(selectedGrades, (grade) => {
        const item = _.find(allGrades, { Key: grade });
        if (item && item["Value"]) {
          switch (grade) {
            case "K":
              listToJoin.push("Kindergarten");
              break;
            case "1":
              listToJoin.push("1st Grade");
              break;
            case "2":
              listToJoin.push("2nd Grade");
              break;
            case "3":
              listToJoin.push("3rd Grade");
              break;
            default:
              listToJoin.push(`${item["Value"]}th Grade`);
          }
        }
      });
    }

    if (!_.isEmpty(selectedStandards)) {
      _.forEach(selectedStandards, (standard) => {
        const item = _.find(allStandards, { Key: standard });
        if (item && item["Value"]) {
          listToJoin.push(item["Value"]);
        }
      });
    }

    if (!_.isEmpty(selectedLicences)) {
      _.forEach(selectedLicences, (licence) => {
        const item = _.find(allLicences, { Key: licence });
        if (item && item["Value"]) {
          listToJoin.push(item["Value"]);
        }
      });
    }

    if (!_.isEmpty(selectedResourceTypes)) {
      _.forEach(selectedResourceTypes, (resourceType) => {
        const item = _.find(allResourceTypes, { Key: resourceType });
        if (item && item["Value"]) {
          listToJoin.push(item["Value"]);
        }
      });
    }

    return listToJoin.join(", ");
  }
);

export const getLeftPanelIsOpen = createSelector(getFiltersInfo, (d) =>
  _.get(d, "isLeftSidePanelOpen")
);
