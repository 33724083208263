import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { colourLookup } from "../../../../../utils";
import { colourRange } from "@hapara/assets/src/colours/colourRange";
import styles from "./GroupFilterBar.module.scss";

interface FilterPropTypes {
  name: string;
  colourCode: number;
  a11y?: string;
  isSelected: boolean;
  isFinal: boolean;
  setActiveFilter: () => void;
}

export const Filter = ({
  name,
  isSelected,
  colourCode,
  isFinal,
  a11y,
  setActiveFilter,
}: FilterPropTypes) => {
  const [colour, setColour] = useState(
    colourLookup(colourRange, true, colourCode)
  );

  useEffect(() => {
    setColour(colourLookup(colourRange, true, colourCode));
  }, [colourCode]);

  const { colourHex, textHex } = colour;
  return (
    <div
      className={classNames(styles.filterBarButtonGroup, {
        [styles.finalGroup]: isFinal,
      })}
    >
      <div
        className={classNames(styles.indicatorBar, {
          [styles.selected]: isSelected,
        })}
        style={{ background: colourHex }}
      />
      <button
        className={styles.filterButton}
        style={{ background: colourHex, color: textHex }}
        onClick={setActiveFilter}
        aria-label={a11y}
        data-test-id="GroupFilterBar-Button"
      >
        {name}
      </button>
    </div>
  );
};

export default Filter;
