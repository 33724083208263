import React from "react";
import styles from "./Heading.module.scss";
export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;
import classnames from "classnames";

type HeadingProps = {
  className?: string;
  /** Specifies the semantic heading level for the content. */
  level: HeadingLevels;
  /** Determines the visual appearance of the heading, while retaining the
   * chosen semantic level. */
  styledAs?: `h${HeadingLevels}`;
  children: React.ReactNode;
  /** Used when the heading is the first element in a container that
   * provides its own padding. */
  removeTopMargin?: boolean;
  /** Likely not used, a heading shouldn't be the last element. */
  removeBottomMargin?: boolean;
  /** Not yet implemented! */
  isCompact?: boolean;
  dataTestIdPrefix?: string;
  id?: string;
};

export const Heading = ({
  id,
  className,
  level,
  children,
  styledAs,
  removeTopMargin,
  removeBottomMargin,
  dataTestIdPrefix,
}: HeadingProps) => {
  const headingClassName = styledAs ? styledAs : `h${level}`;
  const HeadingTag = `h${level}` as React.ElementType;
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-Heading`
    : undefined;
  return (
    <HeadingTag
      className={classnames(
        className,
        styles.heading,
        styles[headingClassName],
        {
          [styles.removeTopMargin]: removeTopMargin,
          [styles.removeBottomMargin]: removeBottomMargin,
        }
      )}
      data-test-id={dataTestId}
      id={id}
    >
      {children}
    </HeadingTag>
  );
};
