import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./StudentCurrentScreen.module.scss";
import {
  STUDENT_BROWSER_TAB_TYPE,
  STUDENT_CURRENT_SCREEN_TYPE,
} from "../../../../state/highlights/students/types";
import { getClassId } from "../../../../state/shared/selectors";
import {
  isIgnoredInTabsUrl,
  isImageData,
  isRestrictedScreenshotUrl,
} from "@hapara/ui/src/components/utils";
import Tippy from "@tippy.js/react";
import StudentBrowserTabs from "../StudentBrowserTabs/StudentBrowserTabs";
import { showCurrentScreenExpandModal } from "../../../../state/highlights/currentScreenExpandModal/action";
import { useIntl } from "react-intl";

const TIMEOUT = 10 * 1000; //10 seconds

const NO_ACTIVE_TAB_URL = "no_active_tab";

const StudentCurrentScreen = ({
  studentId,
  studentEmail,
  studentDisplayName,
  studentLastName,
  instanceId,
  tabs,
  currentScreen,
  showCurrentScreenExpandModal,
  reducedHeight,
}) => {
  const intl = useIntl();
  const [isTimeout, setIsTimeout] = useState(null);

  const dataTestPrefix = "hl-CurrentScreen";

  const activeTab = _.find(tabs, (t) => t.active);
  const activeTabUrl = _.get(activeTab, "url", "");

  const currentScreenUrl = _.get(currentScreen, "url", "");
  const currentScreenImage = _.get(currentScreen, "image", "");

  const SCR = {
    FETCHING: intl.formatMessage({
      defaultMessage: "Fetching screenshot...",
      id: "m8Xa9O",
    }),
    UNAVAILABLE: intl.formatMessage({
      defaultMessage: "Screenshot unavailable",
      id: "ZMwvln",
    }),
    RESTRICTED: intl.formatMessage({
      defaultMessage:
        "Screenshots of this page are blocked to protect student privacy",
      id: "WdHktW",
    }),
  };

  const getTitleByURL = (url) => {
    if (url === NO_ACTIVE_TAB_URL)
      return intl.formatMessage({
        defaultMessage: "No active tab",
        id: "oRfleR",
      });
    return _.get(_.find(tabs, { url }), "title") || url;
  };

  const getIconByURL = (url) => {
    return _.get(_.find(tabs, { url }), "icon");
  };

  useEffect(() => {
    setIsTimeout(false);
    const timer = window.setTimeout(() => {
      if (!currentScreen) {
        setIsTimeout(true);
      }
    }, TIMEOUT);
    return () => {
      window.clearTimeout(timer);
    };
  }, [currentScreen]);

  let unavailableImageText = "";
  let invalidCurrentScreen =
    currentScreen && (!currentScreenImage || !isImageData(currentScreenImage));
  if (invalidCurrentScreen || isTimeout) {
    unavailableImageText = SCR.UNAVAILABLE;
  }
  if (currentScreen && isRestrictedScreenshotUrl(currentScreenUrl)) {
    unavailableImageText = SCR.RESTRICTED;
  }

  const isCloseTabVisible =
    currentScreenUrl && !isIgnoredInTabsUrl(currentScreenUrl);

  return (
    <div
      className={classnames(styles.tabsContainer, {
        [styles.withBorder]: !currentScreen || unavailableImageText,
      })}
    >
      <div className={styles.status}>
        {unavailableImageText && (
          <div
            className={classnames(styles.container, {
              [styles.withCloseTab]: isCloseTabVisible,
              [styles.reducedHeight]: reducedHeight,
            })}
          >
            <div className={styles.title}>
              {getTitleByURL(currentScreenUrl)}
            </div>
            <div className={styles.text}>{unavailableImageText}</div>
          </div>
        )}
        {!currentScreen && !unavailableImageText && (
          <div
            className={classnames(styles.container, {
              [styles.withCloseTab]: isCloseTabVisible,
              [styles.reducedHeight]: reducedHeight,
            })}
          >
            <div className={styles.text}>{SCR.FETCHING}</div>
          </div>
        )}
        {currentScreen && !unavailableImageText && (
          <Tippy content={currentScreenUrl} theme="hsuite">
            <button
              type="button"
              className={classnames(styles.btnLoadScreenshot, {
                [styles.withCloseTab]: isCloseTabVisible,
                [styles.reducedHeight]: reducedHeight,
              })}
              data-test-id={`${dataTestPrefix}-Button-LoadScreenshot`}
              onClick={() =>
                showCurrentScreenExpandModal({
                  studentId,
                  studentEmail,
                  studentLastName,
                  studentDisplayName,
                })
              }
            >
              <img
                className={styles.currentScreen}
                src={currentScreenImage}
                alt={intl.formatMessage(
                  {
                    defaultMessage: "View screenshot for {studentDisplayName}",
                    id: "6SGtNY",
                  },
                  {
                    studentDisplayName,
                  }
                )}
              />
            </button>
          </Tippy>
        )}
        {isCloseTabVisible && (
          <div className={styles.screenLink}>
            <StudentBrowserTabs
              tabs={[
                {
                  ...activeTab,
                  url: currentScreenUrl,
                  icon:
                    currentScreenUrl === activeTabUrl
                      ? activeTab.icon
                      : getIconByURL(currentScreenUrl),
                  title:
                    currentScreenUrl === activeTabUrl
                      ? activeTab.title
                      : getTitleByURL(currentScreenUrl),
                },
              ]}
              studentId={studentId}
              studentEmail={studentEmail}
              studentLastName={studentLastName}
              instanceId={instanceId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

StudentCurrentScreen.propTypes = {
  studentId: PropTypes.string.isRequired,
  studentEmail: PropTypes.string.isRequired,
  studentDisplayName: PropTypes.string.isRequired,
  studentLastName: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(STUDENT_BROWSER_TAB_TYPE),
  currentScreen: STUDENT_CURRENT_SCREEN_TYPE,
  reducedHeight: PropTypes.bool.isRequired,
  // connected props
  classId: PropTypes.string.isRequired,
  showCurrentScreenExpandModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    classId: getClassId(state),
  }),
  (dispatch) => ({
    showCurrentScreenExpandModal: (payload) =>
      dispatch(showCurrentScreenExpandModal(payload)),
  })
)(StudentCurrentScreen);
