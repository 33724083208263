import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Person } from "../../components/partial/ClassInfo/types";

//ACTIONS
import {
  removeStudentsFromClass,
  resetRemoveStudentsFromClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsRemoveStudentsLoading,
  getIsRemoveStudentsError,
  getIsRemoveStudentsLoaded,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  isRemoveStudentError: boolean;
  isRemoveStudentLoaded: boolean;
  isRemoveStudentLoading: boolean;
  handleRemoveStudents: () => void;
};

type UseClassInfoRemoveStudentsProps = {
  selectedStudents: Person[];
  closeModal: () => void;
  isModalOpen: boolean;
};
export const useClassInfoRemoveStudents = ({
  selectedStudents,
  closeModal,
  isModalOpen,
}: UseClassInfoRemoveStudentsProps): ExportedValues => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isRemoveStudentsLoading = useSelector(getIsRemoveStudentsLoading);
  const isRemoveStudentsError = useSelector(getIsRemoveStudentsError);
  const isRemoveStudentsLoaded = useSelector(getIsRemoveStudentsLoaded);

  const handleRemoveStudents = useCallback(() => {
    const studentIds = selectedStudents.map((p) => p.id);
    dispatch(removeStudentsFromClass(classId, studentIds));
  }, [selectedStudents, classId, dispatch]);

  useEffect(() => {
    if (
      !isRemoveStudentsLoading &&
      isRemoveStudentsLoaded &&
      !isRemoveStudentsError
    ) {
      closeModal();
    }
  }, [isRemoveStudentsLoading, isRemoveStudentsLoaded, isRemoveStudentsError]);

  useEffect(() => {
    dispatch(resetRemoveStudentsFromClassState());
  }, [isModalOpen]);

  return {
    isRemoveStudentError: isRemoveStudentsError,
    isRemoveStudentLoaded: isRemoveStudentsLoaded,
    isRemoveStudentLoading: isRemoveStudentsLoading,
    handleRemoveStudents,
  };
};
