import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Person } from "../../components/partial/ClassInfo/types";

//ACTIONS
import {
  removeTeachersFromClass,
  resetRemoveTeachersFromClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsRemoveTeachersLoading,
  getIsRemoveTeachersError,
  getIsRemoveTeachersLoaded,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  isRemoveTeachersError: boolean;
  isRemoveTeachersLoaded: boolean;
  isRemoveTeachersLoading: boolean;
  handleRemoveTeachers: () => Promise<void>;
};

type UseClassInfoRemoveTeachersProps = {
  selectedTeachers: Person[];
  closeModal: () => void;
  isModalOpen: boolean;
};
export const useClassInfoRemoveTeachers = ({
  selectedTeachers,
  closeModal,
  isModalOpen,
}: UseClassInfoRemoveTeachersProps): ExportedValues => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isRemoveTeachersLoading = useSelector(getIsRemoveTeachersLoading);
  const isRemoveTeachersError = useSelector(getIsRemoveTeachersError);
  const isRemoveTeachersLoaded = useSelector(getIsRemoveTeachersLoaded);

  const handleRemoveTeachers = useCallback(async () => {
    const TeachersIds = selectedTeachers.map((p) => p.id);
    await dispatch(removeTeachersFromClass(classId, TeachersIds));
  }, [selectedTeachers, classId, dispatch]);

  useEffect(() => {
    if (
      !isRemoveTeachersLoading &&
      isRemoveTeachersLoaded &&
      !isRemoveTeachersError
    ) {
      closeModal();
    }
  }, [isRemoveTeachersLoading, isRemoveTeachersLoaded, isRemoveTeachersError]);

  useEffect(() => {
    dispatch(resetRemoveTeachersFromClassState());
  }, [isModalOpen]);

  return {
    isRemoveTeachersError,
    isRemoveTeachersLoaded,
    isRemoveTeachersLoading,
    handleRemoveTeachers,
  };
};
