import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ModalDeprecated from "../../../../deprecated/ModalDeprecated/ModalDeprecated";
import styles from "./RecentShareModal.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "../../../../atomic/Button/Button";

import Alert, { ALERT_TYPES } from "../../../../atomic/AlertDeprecated/Alert";
import HapReactIcon from "../../../../atomic/icon/hapReactIcon";
import { shareItemDocType } from "../types";
import { getPlural } from "../../../utils";
import ShareDocItem from "./ShareDocItem";
import { sendReportToSupport } from "../../../../apiCalls/smartshare";

export const getUniqStudents = (docs) => {
  let allStudents = [];

  _.map(docs, (doc) => {
    allStudents = _.concat(allStudents, doc.Students);
  });

  return _.uniqBy(allStudents, "Student");
};

export const getTitleFromArray = (arr, title) => {
  return arr.length > 1
    ? `${getPlural(arr.length, title)}: `
    : arr.length === 1
    ? `${title} : `
    : "";
};

export const getNamesFromArray = (arr, prop) => {
  let res = "";

  _.forEach(arr, (item) => {
    res += `${item[prop]}, `;
  });

  // removing last comma
  return res.substring(0, res.length - 2);
};

export const RecentShareModal = ({
  isOpen,
  documents = [],
  process_ids = [],
  errorText,
  status,
  title,
  classes = [],
  groups = [],
  students = [],
  onClose,
}) => {
  if (!isOpen) return null;

  const intl = useIntl();
  const [inReportErrorsMode, setInReportErrorsMode] = useState(false);
  const [reportErrorText, setReportErrorText] = useState("");
  const [sendingReportInProgress, setSendingReportInProgress] = useState(false);
  const [sendingReportError, setSendingReportError] = useState(false);
  const [sendingReportSuccess, setSendingReportSuccess] = useState(false);
  const reportTitle = "Report errors";

  const uniqueStudents =
    documents.length > 0 ? getUniqStudents(documents) : students;
  const studentsTitle = getPlural(uniqueStudents.length, "student");

  const classesTitle = getTitleFromArray(classes, "Class");
  const groupsTitle = getTitleFromArray(groups, "Group");

  const classesNames = getNamesFromArray(classes, "name");
  const groupsNames = getNamesFromArray(groups, "name");

  const sendReport = async () => {
    const data = {
      Body: reportErrorText,
      IDs: process_ids,
    };

    setSendingReportInProgress(true);
    setSendingReportError(false);
    try {
      sendReportToSupport(data);
      setSendingReportError(false);
      setSendingReportSuccess(true);
    } catch (e) {
      setSendingReportError(true);
      setSendingReportSuccess(false);
    } finally {
      setSendingReportInProgress(false);
    }
  };

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={() => onClose()}
      contentLabel="Share Files Modal"
      hasEmbeddedHeader={false}
      classNameHeader={styles.modalHeader}
      ariaOptions={{ labelledby: "modalSmartShareDetails_heading" }}
      className={styles.modal}
    >
      <div
        className={styles.modalInner}
        data-test-id="td-SmartShare-Recent-Job-Details"
      >
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            {inReportErrorsMode && (
              <Button
                icon="arrow-left"
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.SMALL}
                dataTestId="common-SmartShare-SendReport-Hide"
                onClick={() => setInReportErrorsMode(false)}
              />
            )}
            <h1
              className={styles.title}
              id="modalSmartShareDetails_heading"
              data-test-id="td-SmartShare-DetailsModal-Header"
            >
              {inReportErrorsMode ? reportTitle : title}
            </h1>
          </div>
          <div className={styles.buttonContainer}>
            {!!errorText && !inReportErrorsMode && (
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Report errors",
                  id: "P7ciWQ",
                })}
                icon="flag-fill"
                type={BUTTON_TYPES.OUTLINED}
                size={BUTTON_SIZES.SMALL}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                dataTestId="common-SmartShare-SendReport-Show"
                onClick={() => setInReportErrorsMode(true)}
              />
            )}
            <Button
              icon="cross"
              onClick={onClose}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              className={styles.close}
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
                id: "rbrahO",
              })}
              dataTestId="td-SmartShare-DetailsModal-CloseButton"
            />
          </div>
        </div>

        {!inReportErrorsMode && (
          <div className={styles.block}>
            <h2>For {studentsTitle}</h2>
            {classesTitle && (
              <div className={styles.audience}>
                <span>{classesTitle}</span>
                {classesNames}
              </div>
            )}
            {groupsTitle && (
              <div className={styles.audience}>
                <span>{groupsTitle}</span>
                {groupsNames}
              </div>
            )}
            {documents.length > 0 && (
              <div className={styles.body}>
                {_.map(documents, (doc, idx) => {
                  doc.status = status;
                  return (
                    <ShareDocItem data={doc} key={`${doc.DocumentID}_${idx}`} />
                  );
                })}
              </div>
            )}
            {documents.length === 0 && (
              <div className={styles.loading}>
                <div className={styles.loader}>
                  <HapReactIcon svg="loader" width={16} height={16} />
                </div>
                <div className={styles.text}>
                  <FormattedMessage
                    defaultMessage="Please wait while we prepare to unshare..."
                    id="yWDdSF"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {inReportErrorsMode && !sendingReportSuccess && (
          <div className={styles.block}>
            <p>
              <FormattedMessage
                defaultMessage="Try resetting class folders in the Class Info section to see if this solves the problem."
                id="sx+aCZ"
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="If problems persist, describe what is happening as best you can. This will help our support team investigate more effectively."
                id="DUxyOo"
              />
            </p>
            <textarea
              aria-label={intl.formatMessage({
                defaultMessage: "report share errors here",
                id: "VBfVku",
              })}
              rows="7"
              placeholder={intl.formatMessage({
                defaultMessage: "What is the problem?",
                id: "QlXOUF",
              })}
              data-test-id="common-SmartShare-SendReport-Hide-Input"
              onChange={(e) => {
                setReportErrorText(_.get(e, "target.value", ""));
              }}
              id="smartshare-modal-textarea"
            />

            <Alert type={ALERT_TYPES.FAILURE} isVisible={sendingReportError}>
              <FormattedMessage
                defaultMessage="Sorry, there was a problem sending this report. Please try again."
                id="/8m8oX"
              />
            </Alert>

            <div className={styles.reportButtonsContainer}>
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "47FYwb",
                })}
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.REGULAR}
                dataTestId="common-SmartShare-ReviewSession-SendReport-Cancel"
                onClick={() => setInReportErrorsMode(false)}
              />

              <Button
                label={intl.formatMessage({
                  defaultMessage: "Send",
                  id: "9WRlF4",
                })}
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.REGULAR}
                isLoading={sendingReportInProgress}
                isDisabled={
                  sendingReportInProgress || reportErrorText.trim().length === 0
                }
                dataTestId="common-SmartShare-ReviewSession-SendReport-Confirm"
                onClick={() => sendReport()}
              />
            </div>
          </div>
        )}

        {inReportErrorsMode && sendingReportSuccess && (
          <div className={styles.block}>
            <h2 className={styles.errorTitle}>
              <FormattedMessage defaultMessage="Errors reported" id="2dmInI" />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="This problem has been successfully reported to our support team. We will investigate and reach out as soon as possible."
                id="evdYyN"
              />
            </p>
          </div>
        )}
      </div>
    </ModalDeprecated>
  );
};

RecentShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(shareItemDocType),
  process_ids: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  classes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  students: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })),
  onClose: PropTypes.func.isRequired,
};

export default RecentShareModal;
