// TYPES/CONSTS
import { FEEDBACK_PRODUCT_TYPES } from "../../../state/app/types";

// COMPONENTS
import Page from "../../partial/PageContainer/PageContainer";
import Header from "../../partial/Header/Header";
import Footer from "../../partial/Footer/Footer";
import { ClassInfoContainer } from "../../partial/ClassInfo/ClassInfoContainer";
import useClassInfo from "../../../hooks/classInfo/useClassInfo";

interface ClassInfoPageProps {
  title: string;
}
const dataTestIdPrefix = "class-info";

const ClassInfoPage = ({ title }: ClassInfoPageProps) => {
  const {
    data,
    operations,
    removeStudents,
    addStudents,
    addTeacher,
    removeTeachers,
    renameClass,
    updatePasswords,
    syncClass,
    removeClass,
    resetClass,
  } = useClassInfo();
  const { isLoading, isError, isLoaded } = data;

  return (
    <Page
      header={<Header />}
      footer={<Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.DASHBOARD} />}
      title={title}
      isPageLoaded={isLoaded && !isLoading}
      isPageLoadingError={isError}
      dataTestIdPrefix={dataTestIdPrefix}
    >
      <ClassInfoContainer
        data={data}
        operations={operations}
        addStudents={addStudents}
        addTeacher={addTeacher}
        renameClass={renameClass}
        removeStudents={removeStudents}
        removeTeachers={removeTeachers}
        removeClass={removeClass}
        resetClass={resetClass}
        updatePasswords={updatePasswords}
        syncClass={syncClass}
        dataTestIdPrefix={dataTestIdPrefix}
      />
    </Page>
  );
};

export default ClassInfoPage;
