import { object, Output, safeParse, enumType, url, string } from "valibot";

const HaparaEnvSchema = object({
  HAPARA_ENV: enumType(["production", "test", "local"]),
  HAPARA_AUTH_API_ENDPOINT: string([url()]),
  HAPARA_AUTH_APP_URL: string([url()]),
  HAPARA_API_URL: string([url()]),
});

type HaparaEnv = Output<typeof HaparaEnvSchema>;

// Note, for NextJS apps - these need to be exported in `next.config.js`
const env = {
  HAPARA_ENV: process.env.HAPARA_ENV,
  HAPARA_AUTH_API_ENDPOINT: process.env.HAPARA_AUTH_API_ENDPOINT,
  HAPARA_AUTH_APP_URL: process.env.HAPARA_AUTH_APP_URL,
  HAPARA_API_URL: process.env.HAPARA_API_URL,
} as HaparaEnv;

safeParse(HaparaEnvSchema, env);

export { env };
