import React from "react";
import { ChatRoleType } from "../../types/state";
import { ChatLayout } from "../../components/ChatLayout";
import { ChatHeader } from "../../components/ChatHeader";
import { CloseButton } from "../../../../atomic/CloseButton";
import { ChatFooterLinks } from "../../components/ChatFooterLinks";
import { useIntl } from "react-intl";
import { ChatLoader } from "../../components/ChatLoader";

export type ChatLoaderViewProps = {
  role: ChatRoleType;
  onClose(): void;
};

export const ChatLoaderView = ({ role, onClose }: ChatLoaderViewProps) => {
  const intl = useIntl();
  return (
    <ChatLayout
      header={
        <ChatHeader
          title={intl.formatMessage({ defaultMessage: "Chat", id: "WTrOy3" })}
          actions={<CloseButton onClick={onClose} dataTestIdPrefix="chat" />}
        />
      }
      footer={role === "student" ? <ChatFooterLinks /> : null}
    >
      <ChatLoader role={role} />
    </ChatLayout>
  );
};
