import actionTypes from "../../actionTypes";
import _ from "lodash";
import { SEND_MESSAGE_RECIPIENT_TYPES } from "./types";

const INIT_STATE = {
  modalData: {
    isOpen: false,
    isError: false,
    isSent: false,
    selectedRecipientType: SEND_MESSAGE_RECIPIENT_TYPES.CLASS,
  },
};

const sendMessageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_SHOW: {
      const newModalData = {
        isOpen: true,
        isError: false,
        isSent: false,
        selectedRecipientType: SEND_MESSAGE_RECIPIENT_TYPES.CLASS,
      };
      const selectedStudent = _.get(action, "payload.selectedStudent");
      if (selectedStudent) {
        newModalData.selectedStudent = selectedStudent;
      }
      const recipientType = _.get(action, "payload.recipientType");
      if (recipientType) {
        newModalData.selectedRecipientType = recipientType;
      }
      return {
        ...state,
        modalData: {
          ...newModalData,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_HIDE: {
      return {
        ...state,
        modalData: {
          isOpen: false,
          isError: false,
          isSent: false,
          selectedRecipientType: SEND_MESSAGE_RECIPIENT_TYPES.CLASS,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_ERROR: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          isError: true,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_SUCCESS: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          isSent: true,
        },
      };
    }

    default:
      return state;
  }
};

export default sendMessageReducer;
