import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./SnapsModalFeedback.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import classnames from "classnames";
import { getClassId } from "../../../../state/shared/selectors";
import { getFormattedName } from "../../../../utils";
import { postSnapshotFeedback } from "../../../../state/highlights/snaps/actions";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { SNAPSHOT_TYPE } from "../../../../state/highlights/snaps/types";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import sentSuccessfullyImage from "@hapara/assets/src/images/vector-hl-snaps-feedback-send-success.svg";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import Input from "@hapara/ui/src/atomic/Input/Input";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import _ from "lodash";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { pendoTrack } from "../../../../utils";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const SnapsModalFeedback = ({
  classId,
  students,
  studentNameFormat,
  snapshot,
  closeAction,
  cancelAction,
  postFeedback,
  selectedStudent,
}) => {
  const intl = useIntl();

  const FEEDBACK_TEMPLATES = [
    {
      Name: intl.formatMessage({
        defaultMessage: "No template selected",
        id: "C+ZsI4",
      }),
      Subject: "",
      Text: "",
    },
    {
      Name: intl.formatMessage({
        defaultMessage: "Good Collaboration",
        id: "fVep0r",
      }),
      Subject: intl.formatMessage({
        defaultMessage: "Good Collaboration",
        id: "fVep0r",
      }),
      Text: intl.formatMessage({
        defaultMessage:
          "Great contributions today! Your colleagues truly benefited from your input. Keep up the great work!",
        id: "rxM6sq",
      }),
    },
    {
      Name: intl.formatMessage({
        defaultMessage: "Great Research",
        id: "3o+0T1",
      }),
      Subject: intl.formatMessage({
        defaultMessage: "Great Research",
        id: "3o+0T1",
      }),
      Text: intl.formatMessage({
        defaultMessage:
          "You're becoming a research expert! Great job finding excellent online materials today. Maybe we can share them with other students in our next class?",
        id: "mn7UsU",
      }),
    },
    {
      Name: intl.formatMessage({
        defaultMessage: "Progressing Well",
        id: "HNHxfF",
      }),
      Subject: intl.formatMessage({
        defaultMessage: "Progressing Well",
        id: "HNHxfF",
      }),
      Text: intl.formatMessage({
        defaultMessage:
          "Your good deeds are being noticed! Thank you for making such positive efforts to improve. Keep up the great work!",
        id: "zgoCC/",
      }),
    },
  ];

  const [subject, setSubject] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(
    FEEDBACK_TEMPLATES[0]
  );
  const [message, setMessage] = useState("");
  const [includeTimelineCheckbox, setIncludeTimelineCheckbox] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentsNamesList, setStudentsNamesList] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sentBlockHeight, setSentBlockHeight] = useState(0);
  const rootRef = useRef();
  const goBackRef = useRef();

  const totalAmountOfStudents = _.size(snapshot.Students);

  const studentNames = useMemo(() => {
    return students.reduce((studentsMap, s) => {
      studentsMap[s.Email] = getFormattedName(studentNameFormat, s);
      return studentsMap;
    }, {});
  }, [studentNameFormat, students]);

  const studentCheckboxClicked = (studEmail) => {
    let newStudList = _.clone(selectedStudents);
    if (_.includes(newStudList, studEmail)) {
      _.pull(newStudList, studEmail);
    } else {
      newStudList.push(studEmail);
    }
    setSelectedStudents(newStudList);
    updateStudentsNamesString(newStudList);
  };

  const updateStudentsNamesString = useCallback(
    (studList) => {
      let namesList = "";
      _.forEach(snapshot.Students, (s) => {
        if (_.includes(studList, s.StudentEmail)) {
          if (namesList !== "") {
            namesList += ", ";
          }
          namesList += studentNames[s.StudentEmail];
        }
      });
      setStudentsNamesList(namesList);
    },
    [setStudentsNamesList, snapshot, studentNames]
  );

  const selectAllStudents = () => {
    let newStudList = [];
    if (_.size(selectedStudents) !== totalAmountOfStudents) {
      _.forEach(snapshot.Students, (s) => {
        newStudList.push(s.StudentEmail);
      });
    }
    setSelectedStudents(newStudList);
    updateStudentsNamesString(newStudList);
  };

  useEffect(() => {
    if (selectedStudent && selectedStudents.length === 0) {
      setSelectedStudents([selectedStudent]);
      updateStudentsNamesString([selectedStudent]);
    }
  }, [
    selectedStudent,
    setSelectedStudents,
    updateStudentsNamesString,
    selectedStudents.length,
  ]);

  const selectTemplate = (templ) => {
    setSelectedTemplate(templ);
    setSubject(templ.Subject);
    setMessage(templ.Text);
  };

  const sendFeedback = () => {
    setIsSending(true);
    const blockHeight = rootRef.current.clientHeight;
    setSentBlockHeight(blockHeight); // Keep sent screen the same height as previous screen
    const snapId = snapshot.ID;
    const data = {
      attach_timeline: includeTimelineCheckbox,
      classId: classId,
      email_subject: subject,
      feedback_comment: message,
      id: snapshot.ID,
      student_emails: selectedStudents,
    };

    const timeDiff = moment
      .duration(Date.now() - snapshot.SnapshotTimestamp)
      .asSeconds();
    pendoTrack("Snap - Send Feedback", {
      classId: classId,
      snapTimestamp: new Date(snapshot.SnapshotTimestamp).toISOString(),
      secondsSinceSnapTaken: Math.round(timeDiff),
    });

    postFeedback(data, classId, snapId)
      .then((ok) => {
        if (ok) {
          setIsSent(true);
          _.delay(() => goBackRef.current.focus(), 200);
        } else {
          setIsError(true);
        }
      })
      .catch((e) => {
        setIsError(true);
        setIsSending(false);
      });
  };

  const dataTestPrefix = "hl-SnapModal-Feedback";
  return (
    <div
      className={classnames(styles.root, {
        [styles.sentWrapper]: isSent,
      })}
      ref={rootRef}
      style={{
        minHeight: sentBlockHeight,
      }}
    >
      {!isSent && (
        <>
          <div className={styles.modalBody}>
            <div className={styles.wrapper}>
              <h2 className={styles.title} id={`${dataTestPrefix}-Form-Label`}>
                <FormattedMessage
                  defaultMessage="Send Feedback to Learner(s)"
                  id="MBpiFG"
                />
              </h2>
              <div className={styles.text}>
                <FormattedMessage
                  defaultMessage="Send feedback to help learners improve their digital citizenship and awareness in class"
                  id="YV+4lA"
                />
              </div>
              <form
                className={styles.form}
                aria-labelledby={`${dataTestPrefix}-Form-Label`}
              >
                <fieldset className={styles.fieldset}>
                  <legend className={styles.label}>
                    <FormattedMessage
                      defaultMessage="To<span>(required)</span>"
                      id="H35XSN"
                      values={{
                        span: (chunks) => (
                          <span className={styles.required}>{chunks}</span>
                        ),
                      }}
                    />
                  </legend>
                  {totalAmountOfStudents < 2 && (
                    <div
                      className={styles.readOnlyField}
                      data-test-id={`${dataTestPrefix}-SelectedStudentName`}
                    >
                      {studentsNamesList}
                    </div>
                  )}
                  {totalAmountOfStudents > 1 && (
                    <Dropdown
                      widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                      triggerComponent={(props) => (
                        <DropdownTrigger
                          label={
                            studentsNamesList !== ""
                              ? studentsNamesList
                              : intl.formatMessage({
                                  defaultMessage: "Select students",
                                  id: "loqymb",
                                })
                          }
                          widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                          className={styles.studentsDropdownTrigger}
                          data-test-id={`${dataTestPrefix}-SelectStudentDropdown-TriggerButton`}
                          {...props}
                        />
                      )}
                      itemComponentList={[
                        ({ onClick, className, ...rest }) => {
                          return totalAmountOfStudents > 1 ? (
                            <div className={styles.checkboxItem}>
                              <Checkbox
                                checked={
                                  _.size(selectedStudents) ===
                                  totalAmountOfStudents
                                }
                                onChange={selectAllStudents}
                                label={intl.formatMessage({
                                  defaultMessage: "All students",
                                  id: "zdSuNW",
                                })}
                                dataTestIdPrefix={`${dataTestPrefix}-SelectStudentDropdown-Checkbox-All`}
                              />
                            </div>
                          ) : null;
                        },
                        ...snapshot.Students.map(
                          (s) =>
                            ({ onClick, className, ...rest }) =>
                              (
                                <div className={styles.checkboxItem}>
                                  <Checkbox
                                    checked={_.includes(
                                      selectedStudents,
                                      s.StudentEmail
                                    )}
                                    onChange={() => {
                                      studentCheckboxClicked(s.StudentEmail);
                                    }}
                                    label={studentNames[s.StudentEmail]}
                                    dataTestIdPrefix={`${dataTestPrefix}-SelectStudentDropdown-Checkbox`}
                                  />
                                </div>
                              )
                        ),
                      ]}
                    />
                  )}
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <legend className={styles.label}>
                    <FormattedMessage
                      defaultMessage="Feedback templates"
                      id="9zycEn"
                    />
                  </legend>
                  <Dropdown
                    positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
                    widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                    triggerComponent={(props) => (
                      <DropdownTrigger
                        label={selectedTemplate.Name}
                        widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                        className={styles.studentsDropdownTrigger}
                        {...props}
                      />
                    )}
                    itemComponentList={FEEDBACK_TEMPLATES.map(
                      (templ) =>
                        ({ onClick, className, ...rest }) =>
                          (
                            <button
                              className={classnames(styles.dropdownItem, {
                                [styles.itemSelected]:
                                  templ.Name === selectedTemplate.Name,
                              })}
                              onClick={() => {
                                onClick();
                                selectTemplate(templ);
                              }}
                              tabIndex={0}
                              data-test-id={`${dataTestPrefix}-SelectTemplateDropdown-ItemSelected`}
                            >
                              {templ.Name}
                            </button>
                          )
                    )}
                  />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <legend
                    className={styles.label}
                    id={`${dataTestPrefix}-TextareaField-SubjectLabel`}
                  >
                    <FormattedMessage
                      defaultMessage="Subject<span>(required)</span>"
                      id="1fdqN+"
                      values={{
                        span: (chunks) => (
                          <span className={styles.required}>{chunks}</span>
                        ),
                      }}
                    />
                  </legend>
                  <Input
                    type="text"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Enter the subject of the email",
                      id: "BCkkNZ",
                    })}
                    data-test-id={`${dataTestPrefix}-EditTemplate-TitleInput`}
                    value={subject}
                    onChange={(e) => setSubject(_.get(e, "target.value", ""))}
                    isErrorMode={false}
                    className={styles.textinput}
                    aria-labelledby={`${dataTestPrefix}-TextareaField-SubjectLabel`}
                  />
                </fieldset>

                <fieldset className={styles.fieldset}>
                  <legend
                    className={styles.label}
                    id={`${dataTestPrefix}-TextareaField-Label`}
                  >
                    <FormattedMessage
                      defaultMessage="Message<span>(required)</span>"
                      id="dULqyB"
                      values={{
                        span: (chunks) => (
                          <span className={styles.required}>{chunks}</span>
                        ),
                      }}
                    />
                  </legend>
                  <Textarea
                    placeholder={intl.formatMessage({
                      defaultMessage: "Enter your feedback",
                      id: "m20+/Q",
                    })}
                    data-test-id={`${dataTestPrefix}-TextareaField`}
                    value={message}
                    onChange={(e) => setMessage(_.get(e, "target.value", ""))}
                    className={styles.textarea}
                    aria-labelledby={`${dataTestPrefix}-TextareaField-Label`}
                  />
                </fieldset>
                <div className={styles.bottomPart}>
                  <Checkbox
                    checked={includeTimelineCheckbox}
                    onChange={() => {
                      setIncludeTimelineCheckbox(!includeTimelineCheckbox);
                    }}
                    label={intl.formatMessage({
                      defaultMessage: "Include timeline + current screen",
                      id: "lxuiK/",
                    })}
                    className={styles.checkbox}
                    dataTestIdPrefix={`${dataTestPrefix}-IncludeTimelineAndCurrentScreen-Checkbox`}
                  />

                  <div className={styles.textInfo}>
                    <FormattedMessage
                      defaultMessage="Learners will only see their own activity timeline and current screen."
                      id="rR/aC+"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.footer}>
            <Alert
              type={ALERT_TYPES.FAILURE}
              isVisible={isError}
              className={styles.alert}
            >
              <FormattedMessage
                defaultMessage="Sorry, there was a problem sending feedback. Please try again."
                id="wIOEtG"
              />
            </Alert>
            <div className={styles.actionButtons}>
              <Button
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={cancelAction}
                dataTestId={`${dataTestPrefix}-CancelButton`}
                label={intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "47FYwb",
                })}
                className={styles.cancel}
              />

              <Button
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={sendFeedback}
                dataTestId={`${dataTestPrefix}-SendFeedbackButton`}
                label={intl.formatMessage({
                  defaultMessage: "Send feedback",
                  id: "G5YSJR",
                })}
                className={styles.send}
                isDisabled={
                  _.isEmpty(subject) ||
                  _.isEmpty(message) ||
                  _.isEmpty(selectedStudents)
                }
                isLoading={isSending}
              />
            </div>
          </div>
        </>
      )}

      {isSent && (
        <div className={styles.sendScreen}>
          <img
            src={sentSuccessfullyImage}
            alt=""
            width={240}
            height={240}
            className={styles.sentImage}
          />
          <p className={styles.sentTitle}>
            <FormattedMessage
              defaultMessage="Feedback sent successfully"
              id="Q43iqt"
            />
          </p>
          <button
            className={styles.goBackButton}
            onClick={closeAction}
            data-test-id={`${dataTestPrefix}-SentSuccessfully-Close`}
            ref={goBackRef}
          >
            <FormattedMessage
              defaultMessage="Go back to snap’s browsing activities"
              id="+qFn45"
            />
          </button>
        </div>
      )}
    </div>
  );
};

SnapsModalFeedback.propTypes = {
  selectedStudent: PropTypes.string,
  snapshot: SNAPSHOT_TYPE,
  closeAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  // connected props
  classId: PropTypes.string,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  studentNameFormat: PropTypes.string.isRequired,
  postFeedback: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    classId: getClassId(state),
    students: getStudentConfigsList(state),
    studentNameFormat: getUserNameFormat(state),
  }),
  (dispatch) => ({
    postFeedback: (data, classId, snapId) =>
      dispatch(postSnapshotFeedback(data, classId, snapId)),
  })
)(SnapsModalFeedback);
