import React from "react";
import { useChat } from "./useChat";
import {
  selectContactEvent,
  selectClassEvent,
  setClassStatusEvent,
  sendTextMessageEvent,
  conversationReadEvent,
} from "./chatEventEmitter";
import { ChatClassStatus, ChatRoleType } from "./types/state";
import { ChatClassListView } from "./views/ChatClassListView";
import { ChatChannelView } from "./views/ChatChannelView";
import { ChatChannelListView } from "./views/ChatChannelListView";
import { ChatLoaderView } from "./views/ChatLoaderView";

type ChatContainerProps = {
  roleType: ChatRoleType;
  onClose: () => void;
};

export const ChatContainer = ({ roleType, onClose }: ChatContainerProps) => {
  const {
    contacts,
    channels,
    userId,
    selectedChannelId,
    selectedClassId,
    classes,
    connectionStatus,
    isSchoolHours,
    displayNameFormat,
  } = useChat();

  const selectedChannel = selectedChannelId
    ? channels[selectedChannelId]
    : null;
  const channelContact = selectedChannel
    ? contacts[selectedChannel?.contactId]
    : null;
  const selectedClass = selectedClassId
    ? {
        ...classes[selectedClassId],
        // TODO: PS-1158 Teachers don't get classes from the API
        urn: selectedClassId,
      }
    : null;
  // TODO: PS-1158 Consolidate Chat BFF for teachers and students
  const filteredChannels =
    roleType === "student"
      ? selectedClass
        ? Object.fromEntries(
            selectedClass.channels
              .filter((channelId) => channels[channelId])
              .map((channelId) => [channelId, channels[channelId]])
          )
        : {}
      : channels;

  const handleSendMessage = (message: string) => {
    if (selectedChannel && selectedChannelId && message && userId) {
      sendTextMessageEvent(
        selectedChannelId,
        userId,
        selectedChannel?.contactId,
        message
      );
    }
  };

  const handleConversationClose = () => selectContactEvent(null);

  const handleSelectChannel = (id: string) => selectContactEvent(id);

  const handleSelectClass = (classId: string | null) =>
    selectClassEvent(classId);

  const handleDeselectClass = () => selectClassEvent(null);

  const handleClassStatusChange = (
    classId: string,
    userId: string,
    status: ChatClassStatus
  ) => {
    setClassStatusEvent(classId, userId, status);
  };

  return connectionStatus === "connecting" ? (
    <ChatLoaderView role={roleType} onClose={onClose} />
  ) : userId &&
    selectedClassId &&
    channelContact &&
    selectedClass &&
    selectedChannel ? (
    <ChatChannelView
      role={roleType}
      userId={userId}
      contact={channelContact}
      channel={selectedChannel}
      classId={selectedClassId}
      selectedClass={selectedClass}
      onConversationClose={handleConversationClose}
      onMessageSend={handleSendMessage}
      onConversationRead={conversationReadEvent}
      isSchoolHours={isSchoolHours}
      displayNameFormat={displayNameFormat}
    />
  ) : selectedClass && userId ? (
    <ChatChannelListView
      onBack={handleDeselectClass}
      userId={userId}
      selectedClass={selectedClass}
      contacts={contacts}
      roleType={roleType}
      channels={filteredChannels}
      onSelectContact={handleSelectChannel}
      onClose={onClose}
      onClassStatusChange={handleClassStatusChange}
      isSchoolHours={isSchoolHours}
      displayNameFormat={displayNameFormat}
    />
  ) : (
    <ChatClassListView
      classes={classes}
      onSelectClass={handleSelectClass}
      role={roleType}
      onClose={onClose}
    />
  );
};
