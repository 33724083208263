// TODO: roll-in and remove AlertDeprecated dependency
import AlertDeprecated from "../AlertDeprecated";
import React from "react";
import styles from "./Alert.module.scss";
import classnames from "classnames";
import { type AlertType } from "./types";
import { AlertIcon } from "./AlertIcon/AlertIcon";

type AlertProps = {
  children: React.ReactNode;
  type?: AlertType;
  className?: string;
} & (
  | {
      isAnimated?: boolean;
      // TODO: use convention "removeTopMargin" "removeBottomMargin" so we can
      // still apply top or bottom margins consistently.
      withoutMargin?: boolean;
      inline?: false;
      size?: never;
    }
  | {
      isAnimated?: never;
      withoutMargin?: never;
      inline?: true;
      /** Currently only `sm` is supported */
      size?: "sm";
    }
);

const mapDeprecatedTypes: Record<AlertType, string> = {
  success: "success",
  danger: "failure",
  warning: "warning",
  info: "info",
};

export const Alert = ({
  children,
  className,
  isAnimated = false,
  type = "success",
  withoutMargin,
  inline,
}: AlertProps) =>
  inline ? (
    <span
      className={classnames(styles.inline, className, {
        [styles.inlineDanger]: type === "danger",
      })}
    >
      <AlertIcon type={type} />
      {children}
    </span>
  ) : (
    <AlertDeprecated
      className={classnames(className, {
        [styles.container]: !withoutMargin,
      })}
      animated={isAnimated}
      type={mapDeprecatedTypes[type]}
    >
      <div
        className={classnames(styles.content, {
          [styles.danger]: type === "danger",
        })}
      >
        {children}
      </div>
    </AlertDeprecated>
  );
