import React, { useState, useEffect } from "react";
import styles from "./GuidedBrowsingWebsiteControl.module.scss";
import { Heading } from "@hapara/ui/src/atomic/Heading/Heading";
import { WebsiteInput } from "./WebsiteInput";
import { WebsiteList } from "./WebsiteList";
import { FocusIcon } from "@hapara/ui/src/icons/FocusIcon";
import { FilterIcon } from "@hapara/ui/src/icons/FilterIcon";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE } from "src/state/highlights/sessions/types";
import { normaliseUrl, validateUrl, isDuplicateURL } from "src/utils";

export type GuidedBrowsingWebsiteControlProps = {
  sessionType: "filter" | "focus";
  links: string[];
  onLinksChange: (links: string[]) => void;
  recentTabs: string[];
};

export const GuidedBrowsingWebsiteControlPresentation = ({
  sessionType,
  links,
  onLinksChange,
  recentTabs,
}: GuidedBrowsingWebsiteControlProps) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredRecentTabs, setFilteredRecentTabs] = useState<string[]>([]);

  const maximumSiteCount =
    GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE[sessionType];
  const remainingSiteCount = maximumSiteCount - links.length;

  const remainingSiteCountPlaceholder =
    remainingSiteCount > 0
      ? intl.formatMessage(
          {
            defaultMessage: "Enter up to {allowedSites} links",
            id: "08m+BY",
          },
          {
            allowedSites: remainingSiteCount,
          }
        )
      : intl.formatMessage({
          defaultMessage: "Maximum links reached",
          id: "kEWZW3",
        });

  useEffect(() => {
    updateFilteredRecentTabs();
  }, [links, recentTabs]);

  const updateFilteredRecentTabs = () => {
    const filtered = recentTabs.filter(
      (tab) => !links.includes(normaliseUrl(tab))
    );
    setFilteredRecentTabs(filtered);
  };

  const handleAddLink = (input: string) => {
    setErrorMessage("");

    const splitLinks = input.split(",");
    const trimmedLinks = splitLinks.map((link) => link.trim());
    const normalisedLinks = trimmedLinks.filter(Boolean).map(normaliseUrl);

    const newLinks = [...links];
    const badLinks: string[] = [];
    let isDuplicateError = false;
    let isInvalidError = false;

    normalisedLinks.forEach((link) => {
      if (isDuplicateURL(newLinks, link)) {
        badLinks.push(link);
        isDuplicateError = true;
        return;
      }
      if (!validateUrl(link)) {
        badLinks.push(link);
        isInvalidError = true;
        return;
      }
      if (newLinks.length >= maximumSiteCount) {
        return;
      }
      newLinks.push(link);
    });

    onLinksChange(newLinks);
    updateFilteredRecentTabs();

    if (badLinks.length > 0) {
      setInputValue(badLinks.join(", "));
      if (isInvalidError && !isDuplicateError) {
        setErrorMessage(
          intl.formatMessage({
            defaultMessage:
              "Invalid URL(s). Please check for typos or extra spaces.",
            id: "qWsBLH",
          })
        );
      } else if (!isInvalidError && isDuplicateError) {
        setErrorMessage(
          badLinks.length > 1
            ? intl.formatMessage({
                defaultMessage: "Error: These URLs already exist.",
                id: "lPGmgs",
              })
            : intl.formatMessage({
                defaultMessage: "Error: This URL already exists.",
                id: "wEkAQ5",
              })
        );
      } else {
        setErrorMessage(
          intl.formatMessage({
            defaultMessage:
              "Error: please check for typos, extra spaces or duplicates.",
            id: "LumzRV",
          })
        );
      }
    } else {
      setInputValue("");
    }

    if (newLinks.length >= maximumSiteCount) {
      setErrorMessage(
        intl.formatMessage({
          defaultMessage: "Maximum number of links reached.",
          id: "i6CdIy",
        })
      );
    }
  };

  const handleRemoveLink = (link: string) => {
    const newLinks = links.filter((l) => l !== link);
    if (newLinks.length < maximumSiteCount) {
      setErrorMessage("");
    }
    onLinksChange(newLinks);
    updateFilteredRecentTabs();
  };

  return (
    <GuidedBrowsingSection>
      <div className={styles.headingContainer}>
        <div
          className={classNames(styles.iconWrapper, {
            [styles.focus]: sessionType === "focus",
          })}
        >
          {sessionType === "focus" ? <FocusIcon /> : <FilterIcon />}
        </div>
        <Heading level={2} styledAs="h4" removeTopMargin>
          {sessionType === "focus"
            ? intl.formatMessage({
                defaultMessage: "Allowed website links",
                id: "aacXuB",
              })
            : intl.formatMessage({
                defaultMessage: "Blocked website links",
                id: "8Zqser",
              })}
        </Heading>
      </div>
      <div className={styles.content}>
        <WebsiteInput
          value={inputValue}
          errorMessage={errorMessage}
          recentSiteList={filteredRecentTabs}
          isAtMaxLimit={remainingSiteCount === 0}
          onAddRecentLink={handleAddLink}
          onInputChange={setInputValue}
          onAddLink={handleAddLink}
          placeholder={remainingSiteCountPlaceholder}
        />
        <WebsiteList websiteList={links} onRemove={handleRemoveLink} />
      </div>
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingWebsiteControl = ({
  sessionType,
  links,
  onLinksChange,
  recentTabs,
}: GuidedBrowsingWebsiteControlProps) => {
  return (
    <GuidedBrowsingWebsiteControlPresentation
      sessionType={sessionType}
      links={links}
      onLinksChange={onLinksChange}
      recentTabs={recentTabs}
    />
  );
};
