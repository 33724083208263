import PropTypes from "prop-types";

export const LABELS_VISIBLE_AMOUNT = 3;
export const LABEL_ALLOWED_LENGTH = 30;

export const labelItemType = PropTypes.shape({
  Id: PropTypes.string, // this might be null for All labels filter
  Name: PropTypes.string.isRequired,
  wsAmount: PropTypes.number,
  deleted: PropTypes.bool,
});
