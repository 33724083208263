import { hideAppError, updateAccessError, showAppError } from "../app/actions";
import actionTypes from "../actionTypes";
import { AppDispatch } from "../store";
import {
  fetchClassInfoData,
  postAddStudentsToClass,
  postRemoveStudentsFromClass,
  postAddTeacherToClass,
  postRemoveTeachersFromClass,
  putUpdateClassName,
  postUpdateStudentPasswords,
  postSyncGoogleClassroom,
  postRemoveClass,
  postResetClass,
} from "../../apiCalls/classInfo";
import { ApiError } from "../../apiCalls/types";
import { APP_PAGES } from "../router/types";
import { postUpdateClassGroupName } from "src/apiCalls/classInfo/classInfo";

export const loadClassInfoData =
  (classId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_DATA_LOAD_PENDING });
    dispatch(hideAppError());
    fetchClassInfoData(classId)
      .then((response) => {
        dispatch({
          type: actionTypes.CLASS_INFO_DATA_LOAD_SUCCESS,
          payload: response,
        });
      })
      .catch((error: ApiError) => {
        switch (error.responseStatus) {
          case 403:
            dispatch(updateAccessError(true));
            break;
          case 410:
            window.location.href = `${process.env.REACT_APP_HSUITE_BASE_URL}/ClassNotFound`;
            break;
          default:
            dispatch({
              type: actionTypes.CLASS_INFO_DATA_LOAD_ERROR,
            });
        }
      });
  };

export const addStudentToClass =
  (classId: string, studentEmails: string[]) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_ADD_STUDENTS_PENDING });
    dispatch(hideAppError());
    postAddStudentsToClass(classId, studentEmails)
      .then((response) => {
        dispatch({
          type: actionTypes.CLASS_INFO_ADD_STUDENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_ADD_STUDENTS_ERROR,
          });
        }
      });
  };

export const resetAddStudentsToClassState = () => (dispatch: AppDispatch) =>
  dispatch({ type: actionTypes.CLASS_INFO_ADD_STUDENTS_RESET });

export const removeStudentsFromClass =
  (classId: string, studentIds: string[]) => async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_REMOVE_STUDENTS_PENDING });
    dispatch(hideAppError());
    await postRemoveStudentsFromClass(classId, studentIds)
      .then((response) => {
        dispatch({
          type: actionTypes.CLASS_INFO_REMOVE_STUDENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_REMOVE_STUDENTS_ERROR,
          });
        }
      });
  };

export const resetRemoveStudentsFromClassState =
  () => (dispatch: AppDispatch) =>
    dispatch({ type: actionTypes.CLASS_INFO_REMOVE_STUDENTS_RESET });

export const addTeacherToClass =
  (classId: string, teacherEmail: string) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_ADD_TEACHER_PENDING });
    dispatch(hideAppError());
    postAddTeacherToClass(classId, teacherEmail)
      .then((response) => {
        if (response.classData) {
          dispatch({
            type: actionTypes.CLASS_INFO_ADD_TEACHER_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_ADD_TEACHER_SERVER_VALIDATION_ERROR,
          });
        }
      })
      .catch((error: ApiError) => {
        switch (error.responseStatus) {
          case 403:
            dispatch(updateAccessError(true));
            break;
          case 400:
            dispatch({
              type: actionTypes.CLASS_INFO_ADD_TEACHER_SERVER_VALIDATION_ERROR,
            });
            break;
          default:
            dispatch({
              type: actionTypes.CLASS_INFO_ADD_TEACHER_ERROR,
            });
        }
      });
  };

export const resetAddTeacherToClassState = () => (dispatch: AppDispatch) =>
  dispatch({ type: actionTypes.CLASS_INFO_ADD_TEACHER_RESET });

export const removeTeachersFromClass =
  (classId: string, teacherEmails: string[]) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_REMOVE_TEACHERS_PENDING });
    dispatch(hideAppError());
    await postRemoveTeachersFromClass(classId, teacherEmails)
      .then((response) => {
        dispatch({
          type: actionTypes.CLASS_INFO_REMOVE_TEACHERS_SUCCESS,
          payload: response,
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_REMOVE_TEACHERS_ERROR,
          });
        }
      });
  };

export const resetRemoveTeachersFromClassState =
  () => (dispatch: AppDispatch) =>
    dispatch({ type: actionTypes.CLASS_INFO_RENAME_CLASS_RESET });

export const renameClass =
  ({
    classGroupId,
    newClassName,
    classId,
    classGroupURN,
  }: {
    classGroupId?: string;
    newClassName: string;
    classId: string;
    classGroupURN?: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_RENAME_CLASS_PENDING });
    dispatch(hideAppError());
    await (classGroupId
      ? putUpdateClassName(classGroupId!, newClassName)
      : postUpdateClassGroupName(classGroupURN!, newClassName)
    )
      .then(() => {
        dispatch({
          type: actionTypes.CLASS_INFO_RENAME_CLASS_SUCCESS,
          payload: {
            newClassName,
            classId,
          },
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_RENAME_CLASS_ERROR,
          });
        }
      });
  };

export const resetRenameClassState = () => (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.CLASS_INFO_RENAME_CLASS_RESET });
};

export const updateStudentPasswords = (
  classId: string,
  studentIds: string[],
  forceReset: boolean,
  newPassword: string
) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_PENDING });
    dispatch(hideAppError());
    postUpdateStudentPasswords(classId, studentIds, forceReset, newPassword)
      .then(() => {
        dispatch({
          type: actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_SUCCESS,
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_ERROR,
          });
        }
      });
  };
};

export const resetUpdateStudentPasswordsState =
  () => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_RESET });
  };

export const syncGoogleClassroom =
  (classId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_SYNC_CLASS_PENDING });
    dispatch(hideAppError());
    postSyncGoogleClassroom(classId)
      .then((response) => {
        dispatch({
          type: actionTypes.CLASS_INFO_SYNC_CLASS_SUCCESS,
          payload: response,
        });
        dispatch({
          type: actionTypes.CLASS_INFO_RENAME_CLASS_SUCCESS,
          payload: {
            newClassName: response.className,
            classId,
          },
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({ type: actionTypes.CLASS_INFO_SYNC_CLASS_ERROR });
          dispatch(showAppError(error));
        }
      });
  };

export const resetSyncGoogleClassroom = () => (dispatch: AppDispatch) =>
  dispatch({ type: actionTypes.CLASS_INFO_CLASS_SYNC_RESET });

export const removeClass =
  (classGroupId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.CLASS_INFO_REMOVE_CLASS_PENDING });
    dispatch(hideAppError());
    postRemoveClass(classGroupId)
      .then(() => {
        dispatch({
          type: actionTypes.CLASS_INFO_REMOVE_CLASS_SUCCESS,
        });
        window.location.href = `${process.env.REACT_APP_HSUITE_BASE_URL}${APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}`;
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.CLASS_INFO_REMOVE_CLASS_ERROR,
          });
        }
      });
  };

export const resetRemoveClassState = () => (dispatch: AppDispatch) =>
  dispatch({ type: actionTypes.CLASS_INFO_REMOVE_CLASS_RESET });

export const resetClassAction =
  (classId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: actionTypes.CLASS_INFO_RESET_CLASS_PENDING });
      await postResetClass(classId);
      dispatch({ type: actionTypes.CLASS_INFO_RESET_CLASS_SUCCESS });
    } catch {
      dispatch({ type: actionTypes.CLASS_INFO_RESET_CLASS_ERROR });
    }
  };

export const resetResetClassState = () => (dispatch: AppDispatch) =>
  dispatch({ type: actionTypes.CLASS_INFO_RESET_CLASS_RESET });
