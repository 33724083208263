import { FormattedMessage } from "react-intl";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import styles from "./DiscoverFindStandards.module.scss";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../../state/router/types";
import {
  LANDING_PAGE_SHORTCUT_TYPE,
  FIND_STANDARDS_SHORTCUTS,
} from "../../../../state/library/discover/types";
import { SHARED_FILTER_TYPES } from "../../../../state/shared/types";

const DiscoverFindStandardsItem = ({ item }) => {
  const images = require.context("@hapara/assets/src/images/", false);

  return (
    <Link
      to={{
        pathname: `${APP_PAGES.DISCOVER_SEARCH.path}`,
        search: `${SHARED_FILTER_TYPES.STANDARDS.paramName}=${item.Filter}&page=1`,
      }}
      className={styles.itemRoot}
      data-test-id={`lb-Discover-FindStandards-${item.DataTestPrefix}`}
    >
      <img src={images(`./${item.BackgroundImage}.png`).default} alt="" />
      <div className={styles.onTop}>
        <div className={styles.title}>{item.Title}</div>
      </div>
    </Link>
  );
};

DiscoverFindStandardsItem.propTypes = {
  item: LANDING_PAGE_SHORTCUT_TYPE,
};

const DiscoverFindStandards = () => {
  let shortcutsToShow = FIND_STANDARDS_SHORTCUTS;
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>
          <FormattedMessage
            defaultMessage="Find standards-aligned content"
            id="qPlFxU"
          />
        </h2>
      </div>
      <ul
        className={styles.body}
        data-test-id="lb-Discover-FindStandards-ShortCutsList"
      >
        {_.map(shortcutsToShow, (item) => {
          return (
            <li key={item.Id} className={styles.listItem}>
              <DiscoverFindStandardsItem item={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default connect()(DiscoverFindStandards);
