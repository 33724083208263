import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./SelectDomainAndSchool.module.scss";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { anySchoolHasPermission, hasPermission } from "../../../../utils";
import _ from "lodash";
import classnames from "classnames";
import { Permissions } from "../../../../types/shared";

const SelectDomainAndSchool = ({
  domains,
  onChange,
  defaultDomainValue = "",
  defaultSchoolValue = "",
  permission,
  triggerComponent,
  isRequired,
  dataTestIdPrefix,
  className = null,
}) => {
  const [domain, setDomain] = useState(defaultDomainValue);
  const [school, setSchool] = useState(defaultSchoolValue);
  const selectedDomain = _.find(domains, (d) => d.urn === domain);
  const filteredDomains = domains.filter((d) =>
    anySchoolHasPermission(d, permission)
  );
  const filteredSchools = selectedDomain
    ? selectedDomain.schools.filter((s) => hasPermission(s, permission))
    : [];
  const domainsLabel = domain
    ? domain
    : isRequired
    ? "Select domain"
    : "All domains";
  const isSelected = domain ? true : !isRequired;
  const schoolsLabel =
    _.get(
      _.find(filteredSchools, (s) => s.code === school),
      "name"
    ) || (isRequired ? "Select school" : "All schools");
  const multipleSelection =
    filteredDomains.length > 1 && filteredSchools.length > 1;
  return (
    <div className={classnames(styles.root, className)}>
      {filteredDomains.length > 1 && (
        <div className={styles.dropdownWrapper}>
          <Dropdown
            positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
            widthType={
              triggerComponent
                ? DROPDOWN_WIDTH_TYPES.MIN_CONTENT
                : DROPDOWN_WIDTH_TYPES.FULL_WIDTH
            }
            className={classnames(styles.chooseDropdown, {
              [styles.multiple]: multipleSelection,
              [styles.single]: !multipleSelection,
            })}
            triggerComponent={(props) =>
              triggerComponent ? (
                triggerComponent(domainsLabel, {
                  ...props,
                  className: classnames(props.className, styles.trigger),
                  dataTestId: `${dataTestIdPrefix}-SelectDomain-Dropdown`,
                  ariaLabel: isSelected
                    ? `Selected ${domainsLabel}`
                    : `Select domain`,
                })
              ) : (
                <DropdownTrigger
                  label={domainsLabel}
                  data-test-id={`${dataTestIdPrefix}-SelectDomain-Dropdown`}
                  aria-label={
                    isSelected ? `Selected ${domainsLabel}` : `Select domain`
                  }
                  {...props}
                  widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                />
              )
            }
            itemComponentList={(isRequired
              ? []
              : [
                  ({ onClick, className, ...rest }) => (
                    <button
                      className={classnames(className, {
                        [styles.selected]: !domain,
                      })}
                      data-test-id={`${dataTestIdPrefix}-ChooseAllDomains-Action`}
                      aria-label="Select all domains"
                      onClick={() => {
                        onClick();
                        setDomain("");
                        setSchool("");
                        onChange("", "");
                      }}
                      {...rest}
                    >
                      <FormattedMessage
                        defaultMessage="All domains"
                        id="21lfYf"
                      />
                    </button>
                  ),
                ]
            ).concat(
              _.sortBy(filteredDomains, "urn").map((dom) => {
                return ({ onClick, className, ...rest }) => (
                  <button
                    className={classnames(className, {
                      [styles.selected]: dom.urn === domain,
                    })}
                    data-test-id={`${dataTestIdPrefix}-ChooseDomain-Action`}
                    aria-label={`Select ${dom.urn}`}
                    onClick={() => {
                      setDomain(dom.urn);
                      let school = "";
                      const filtered = dom.schools.filter((s) =>
                        hasPermission(s, permission)
                      );
                      if (filtered.length === 1) {
                        school = filtered[0].code;
                      }
                      setSchool(school);
                      onChange(dom.urn, school);
                      onClick();
                    }}
                    {...rest}
                  >
                    {dom.urn}
                  </button>
                );
              })
            )}
          />
        </div>
      )}
      {selectedDomain && filteredSchools.length > 1 && (
        <div
          className={classnames(styles.chooseDropdown, {
            [styles.multiple]: multipleSelection,
            [styles.single]: !multipleSelection,
          })}
        >
          <Dropdown
            positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
            widthType={
              triggerComponent
                ? DROPDOWN_WIDTH_TYPES.MIN_CONTENT
                : DROPDOWN_WIDTH_TYPES.FULL_WIDTH
            }
            className={styles.chooseDropdown}
            triggerComponent={(props) =>
              triggerComponent ? (
                triggerComponent(schoolsLabel, {
                  ...props,
                  className: classnames(props.className, styles.trigger),
                  dataTestId: `${dataTestIdPrefix}-SelectSchool-Dropdown`,
                  ariaLabel: school
                    ? `Selected ${school}`
                    : isRequired
                    ? `Select school`
                    : `Selected all schools`,
                })
              ) : (
                <DropdownTrigger
                  label={schoolsLabel}
                  aria-label={
                    school
                      ? `Selected ${school}`
                      : isRequired
                      ? `Select school`
                      : `Selected all schools`
                  }
                  data-test-id={`${dataTestIdPrefix}-SelectSchool-Dropdown`}
                  {...props}
                  widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                />
              )
            }
            itemComponentList={(isRequired
              ? []
              : [
                  ({ onClick, className, ...rest }) => (
                    <button
                      className={classnames(className, {
                        [styles.selected]: !school,
                      })}
                      data-test-id={`${dataTestIdPrefix}-ChooseAllSchools-Action`}
                      aria-label="Select all schools"
                      onClick={() => {
                        onClick();
                        setSchool("");
                        onChange(domain, "");
                      }}
                      {...rest}
                    >
                      <FormattedMessage
                        defaultMessage="All schools"
                        id="oYqwXs"
                      />
                    </button>
                  ),
                ]
            ).concat(
              _.sortBy(filteredSchools, (s) => _.lowerCase(s.name)).map(
                (sch) => {
                  return ({ onClick, className, ...rest }) => (
                    <button
                      className={classnames(className, {
                        [styles.selected]: sch.code === school,
                      })}
                      aria-label={`Select ${sch.name || sch.code}`}
                      data-test-id={`${dataTestIdPrefix}-ChooseSchool-Action`}
                      onClick={() => {
                        onClick();
                        setSchool(sch.code);
                        onChange(domain, sch.code);
                      }}
                      {...rest}
                    >
                      {sch.name || sch.code}
                    </button>
                  );
                }
              )
            )}
          />
        </div>
      )}
    </div>
  );
};

SelectDomainAndSchool.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      urn: PropTypes.string,
      schools: PropTypes.arrayOf(
        PropTypes.shape({ code: PropTypes.string, name: PropTypes.string })
      ),
    })
  ).isRequired,
  permission: PropTypes.oneOf(
    [
      Permissions.DASHBOARD_ADD_CLASS,
      Permissions.DASHBOARD_SYNC_GOOGLE_CLASSROOM,
      "",
    ] // empty means we show all domains/schools
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultDomainValue: PropTypes.string,
  defaultSchoolValue: PropTypes.string, //school code
  dataTestIdPrefix: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SelectDomainAndSchool;
