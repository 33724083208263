import { Checkbox } from "../../../atomic/Checkbox";
import styles from "./TableCheckbox.module.scss";

type TableCheckboxProps = {
  hideLabel?: boolean;
  label: string;
  checked?: boolean;
  semi?: boolean;
  dataTestIdPrefix?: string;
  onChange?: (value: boolean) => void;
};

/** This exists because there's some weirdness with the base Checkbox component we need to override */
export const TableCheckbox = (props: TableCheckboxProps) => {
  return <Checkbox {...props} className={styles.root} />;
};
