import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import styles from "./NoAccessPage.module.scss";
import fileSheep from "@hapara/assets/src/images/vector-sheepicorn-over-fence.svg";
import fileLogo from "@hapara/assets/src/images/logo-hapara.svg";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { ButtonLink } from "@hapara/ui/src/atomic/Button/Button";
import { ALT_TEXT, ACCESS_DENIED_TYPES } from "../../../state/app/types";
import { logOut } from "../../../state/app/actions";
import {
  isHaparaStudent,
  getUserPermissions,
  hasAccess,
  isUnknownUser,
} from "../../../state/user/selectors";
import { Helmet } from "react-helmet";
import { APP_PAGES } from "../../../state/router/types";
import { USER_PERMISSIONS, USER_INFO } from "../../../state/user/types";
import _ from "lodash";
import { getFFByName } from "../../../state/app/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { PageActions } from "@hapara/ui/src/components/PageActions";
import classNames from "classnames";

export const NoAccessPage = ({
  logOut,
  isStudent,
  userPermissions,
  hasAccess,
}) => {
  const intl = useIntl();

  const isSharedBaseDomainEnabled = useSelector(
    getFFByName("HAP-11276-ws-subpath")
  );

  const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {};

  const supportLink = (
    <FormattedMessage
      defaultMessage="If problems persist, please <a>contact Support for help.</a> "
      id="4P8wig"
      values={{
        a: (chunks) => (
          <a
            className={styles.footerLink}
            href="https://support.hapara.com/hc/en-us/requests/new"
          >
            {chunks}
          </a>
        ),
      }}
    />
  );

  const handleSwitchAccount = () => {
    logOut();
    window.location.reload();
  };

  const copyVariationsByType = {
    [ACCESS_DENIED_TYPES.KNOWN_BUT_UNAUTHORIZED_USER]: {
      text: (
        <FormattedMessage
          defaultMessage="You may not have permission to view this page."
          id="Yn9z/H"
        />
      ),
      actionLabel: intl.formatMessage({
        defaultMessage: "Go back",
        id: "orvpWh",
      }),
      action: () => window.history.back(),
    },
    [ACCESS_DENIED_TYPES.WRONG_ACCESS_USER]: {
      text: (
        <FormattedMessage
          defaultMessage="<strong>{userEmail}</strong> does not have access to this page.
          Please check that you're using the correct account."
          id="zAHwvF"
          values={{
            userEmail: userInfo?.email,
          }}
        />
      ),
      actionLabel: intl.formatMessage({
        defaultMessage: "Switch account",
        id: "qs1y2y",
      }),
      action: handleSwitchAccount,
    },
    [ACCESS_DENIED_TYPES.STUDENT_USER]: {
      text: (
        <FormattedMessage
          defaultMessage="You don’t have permission to view this page."
          id="NJ1mmz"
        />
      ),
      footerText: "",
      actionLabel: intl.formatMessage({
        defaultMessage: "Go to Student Dashboard",
        id: "fJoXCg",
      }),
      action: () => {
        window.location.href = isSharedBaseDomainEnabled
          ? `${process.env.REACT_APP_HSUITE_BASE_URL}/student`
          : process.env.REACT_APP_STUDENT_DASHBOARD_URL;
      },
    },
  };

  const isUnknown = !userPermissions || !userPermissions.length;

  let type;

  if (isStudent) {
    type = ACCESS_DENIED_TYPES.STUDENT_USER;
  } else if (!hasAccess || isUnknown) {
    type = ACCESS_DENIED_TYPES.WRONG_ACCESS_USER;
  } else {
    type = ACCESS_DENIED_TYPES.KNOWN_BUT_UNAUTHORIZED_USER;
  }

  return (
    <main className={styles.root} data-test-id="Hs-Pages-NoAccessPage">
      <Helmet>
        <title>{APP_PAGES.NO_ACCESS.title}</title>
      </Helmet>
      <div className={styles.header} lang="mi">
        <img src={fileLogo} alt={ALT_TEXT.HAPARA_LOGO} width={100} />
      </div>
      <div
        className={classNames(styles.content, {
          [styles.noFooter]: !copyVariationsByType[type].footerText,
        })}
      >
        <div className={styles.sheepy}>
          <img src={fileSheep} width="100%" height="100%" alt="" />
        </div>
        <div className={styles.copy}>
          <h1 className={styles.whoops}>
            <FormattedMessage
              defaultMessage="Hold up, you can’t jump over this fence!"
              id="4ENUrL"
            />
          </h1>
          <div className={styles.description}>
            {copyVariationsByType[type].text}
          </div>
          <PageActions leftAligned>
            <Button
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              size={BUTTON_SIZES.LARGE}
              label={copyVariationsByType[type].actionLabel}
              className={styles.action}
              onAction={copyVariationsByType[type].action}
              dataTestId="Hs-NoAccessPage-Button-Action"
            />
            {!isStudent && (
              <ButtonLink
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.LARGE}
                label={intl.formatMessage({
                  defaultMessage: "Troubleshoot",
                  id: "uA+F/o",
                })}
                className={styles.action}
                target="_blank"
                href="https://support.hapara.com/hc/en-us/articles/360043110731"
                dataTestId="Hs-NoAccessPage-Button-Troubleshoot"
              />
            )}
          </PageActions>
        </div>
      </div>
      {copyVariationsByType[type].footerText && (
        <div className={styles.footer}>
          <span>{copyVariationsByType[type].footerText}</span>
        </div>
      )}
    </main>
  );
};

NoAccessPage.propTypes = {
  isStudent: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  userPermissions: PropTypes.arrayOf(
    PropTypes.oneOf(_.values(USER_PERMISSIONS))
  ),
};

export default connect(
  (state) => ({
    isStudent: isHaparaStudent(state),
    userPermissions: getUserPermissions(state),
    hasAccess: hasAccess(state),
    isUnknownUser: isUnknownUser(state),
  }),
  (dispatch) => ({
    logOut: () => dispatch(logOut()),
  })
)(NoAccessPage);
