import React from "react";
import { ChatClassesState, ChatRoleType } from "../../types/state";
import { ChatLayout } from "../../components/ChatLayout";
import { ChatHeader } from "../../components/ChatHeader";
import { CloseButton } from "../../../../atomic/CloseButton";
import { ChatFooterLinks } from "../../components/ChatFooterLinks";
import { useIntl } from "react-intl";
import { ChatClassList } from "../../components/ChatClassList";

export type ChatClassListViewProps = {
  role: ChatRoleType;
  onClose: () => void;
  onSelectClass: (classId: string | null) => void;
  classes: ChatClassesState;
};

export const ChatClassListView = ({
  role,
  onClose,
  onSelectClass,
  classes,
}: ChatClassListViewProps) => {
  const intl = useIntl();
  return (
    <ChatLayout
      header={
        <ChatHeader
          title={intl.formatMessage({ defaultMessage: "Chat", id: "WTrOy3" })}
          actions={<CloseButton onClick={onClose} dataTestIdPrefix="chat" />}
        />
      }
      footer={role === "student" ? <ChatFooterLinks /> : null}
    >
      <ChatClassList classes={classes} onSelectClassId={onSelectClass} />
    </ChatLayout>
  );
};
