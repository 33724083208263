import React from "react";
import styles from "./Column.module.scss";
import classnames from "classnames";
import { useTable } from "../../useTable";

type ColumnProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  width?: number | string;
  align?: "left" | "center" | "right";
  _ariaColIndex?: number;
};

export const Column = ({
  children,
  style,
  className,
  width,
  align = "left",
  _ariaColIndex,
}: ColumnProps) => {
  const { dataTestIdPrefix } = useTable();

  const dataTestId =
    dataTestIdPrefix && _ariaColIndex !== undefined
      ? `${dataTestIdPrefix}-table-column-${_ariaColIndex}`
      : undefined;

  const inlineStyles = {
    ...style,
    ...(width ? { width } : undefined),
  };

  return (
    <th
      aria-colindex={_ariaColIndex}
      data-test-id={dataTestId}
      style={inlineStyles}
      className={classnames(className, styles.container, {
        [styles[align]]: true,
      })}
    >
      {children}
    </th>
  );
};

Column.displayName = "Column";
