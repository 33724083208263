import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../../hooks/useIsDimension";
import { Group as GroupType } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import FilterDropDown from "./FilterDropDown";
import Filter from "./Filter";
import styles from "./GroupFilterBar.module.scss";

interface GroupFilterBarProps {
  groups?: GroupType[];
  dataTestIdPrefix: string;
  activateFilter: (value: string | ((prevVar: string) => string)) => void; //Set the selected Filter
}

export const GroupFilterBar = ({
  groups,
  dataTestIdPrefix,
  activateFilter,
}: GroupFilterBarProps) => {
  const ALL_STRING_ID = "";
  const ALL_FILTER_NAME = "All";
  const ALL_COLOUR_ID = 200;

  const [selectedFilter, setSelectedFilter] = useState(""); //ID
  const [activeFilterName, setActiveFilterName] = useState("");
  const { isDimensionOnly: isMediumScreen } = useIsDimension(
    WINDOW_SIZE_TYPES.L
  );
  const buttonBarFilters = _.slice(groups, 0, 3); //Pull buttons for button bar display
  const a11yButtonCount = buttonBarFilters.length + 2; //Used for a11y feedback

  useEffect(() => {
    activateFilter(selectedFilter); //Update Parent with current filter
  }, [selectedFilter, activateFilter]);

  const setDropDownSelect = (id: string, name: string) => {
    setSelectedFilter(id);
    setActiveFilterName(name);
  };

  return (
    <div className={styles.root}>
      <div className={styles.filteredAlert}>
        {!_.isEmpty(selectedFilter) && (
          <>
            <div className={styles.alertHeading}>Filtered by:</div>
            <div className={styles.alertGroup}>{activeFilterName}</div>
          </>
        )}
      </div>
      <div className={styles.filterBarContainer}>
        <div className={styles.filterBarHeading}> Groups </div>
        <div className={styles.filterBarButtonWrapper}>
          <fieldset
            className={classNames(
              { [styles.singleElement]: buttonBarFilters.length === 1 },
              { [styles.doubleElement]: buttonBarFilters.length === 2 }
            )}
          >
            <legend>Show cards assigned to</legend>
            {!isMediumScreen && (
              <Filter
                name={ALL_FILTER_NAME}
                colourCode={ALL_COLOUR_ID}
                isSelected={selectedFilter === ALL_STRING_ID}
                setActiveFilter={() => {
                  setSelectedFilter(ALL_STRING_ID);
                  setActiveFilterName(ALL_FILTER_NAME);
                }}
                isFinal={false}
                data-test-id={`${dataTestIdPrefix}-GroupFilter-All`}
                a11y={`All groups button. Button 1 of ${a11yButtonCount} ${
                  selectedFilter === "" ? "Selected" : ""
                }`}
              />
            )}
            {!_.isEmpty(buttonBarFilters) &&
              !isMediumScreen &&
              buttonBarFilters.map((filter, idx) => {
                const isSelected = filter.ID === selectedFilter ? true : false;
                return (
                  <Filter
                    key={filter.ID}
                    name={filter.Title!}
                    colourCode={filter.ColorIndex!}
                    isSelected={isSelected}
                    setActiveFilter={() => {
                      setSelectedFilter(filter.ID!);
                      setActiveFilterName(filter.Title!);
                    }}
                    isFinal={groups!.length <= 3 && idx === 2}
                    data-test-id={`${dataTestIdPrefix}-GroupFilter-FilterButton`}
                    a11y={`${filter.Title!}. Button ${
                      idx + 2
                    } of ${a11yButtonCount} ${isSelected ? "Selected" : ""}`}
                  />
                );
              })}
            {_.isEmpty(buttonBarFilters) && !isMediumScreen && (
              <div className={styles.noGroupsAlert}>No Groups Found</div>
            )}
            <FilterDropDown
              groups={groups}
              currentSelection={selectedFilter}
              setDropDownSelect={setDropDownSelect}
              dataTestIdPrefix={dataTestIdPrefix}
              a11y={a11yButtonCount}
            />
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default GroupFilterBar;
