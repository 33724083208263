import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { waitForGlobal } from "@hapara/ui/src/components/utils";
import { loadGIS, testLogIn } from "../../../state/app/actions";
import {
  isGISLoaded,
  isGISLoading,
  isInitialLoadError,
} from "../../../state/app/selectors";
import {
  getUserLoggingStatus,
  isUnknownUser,
} from "../../../state/user/selectors";
import styles from "./LoginPage.module.scss";
import logo from "@hapara/assets/src/images/logo-hapara.svg";
import { APP_PAGES, locationType } from "../../../state/router/types";
import AppLoadingPage from "../AppLoadingPage/AppLoadingPage";
import { ALT_TEXT } from "../../../state/app/types";
import NoAccessPage from "../NoAccessPage/NoAccessPage";

export const LoginPage = (props) => {
  const {
    isGISLoaded,
    isGISLoading,
    isUserLoggedIn,
    location,
    loadGIS,
    isInitialLoadError,
    testLogIn,
    isUnknownUser,
  } = props;

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [idToken, setIdToken] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // make sure GIS is loaded
  useEffect(() => {
    if (!isUserLoggedIn && !isGISLoaded && !isGISLoading) {
      waitForGlobal("window.google").then(() => {
        // window.google is ready to use
        loadGIS();
      });
    }
  }, [isUserLoggedIn, isGISLoaded, isGISLoading, loadGIS]);

  if (isUnknownUser) {
    return <NoAccessPage />;
  }

  if (isUserLoggedIn) {
    const testPersonalEmail =
      window.localStorage.getItem("isPersonalEmail") || null;
    if (testPersonalEmail) {
      return <NoAccessPage />;
    } else {
      const from = _.get(location, "state.from", { from: { pathname: "/" } });
      return <Redirect to={from} />;
    }
  }

  if (isGISLoaded && !isInitialLoadError) {
    return (
      <main className={styles.root} data-test-id="Hs-Pages-TestLoginPage">
        <Helmet>
          <title>{APP_PAGES.LOGIN.title}</title>
        </Helmet>
        <div className={styles.loginContainer}>
          <a
            href="http://hapara.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Hapara"
            className={styles.loginLogo}
            data-test-id="Hs-Login-Link-Logo"
          >
            <h1 lang="mi">
              <img src={logo} alt={ALT_TEXT.HAPARA_LOGO} />
            </h1>
          </a>
          <p>
            <input
              id="user-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </p>
          <p>
            <input
              id="user-token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="Token"
            />
          </p>
          <p>
            <input
              id="id-token"
              value={idToken}
              onChange={(e) => setIdToken(e.target.value)}
              placeholder="ID Token"
            />
          </p>
          <p>
            <input
              id="user-first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
            />
          </p>
          <p>
            <input
              id="user-last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
            />
          </p>
          <button
            id="sign-in"
            type="button"
            className={styles.loginButton}
            onClick={() =>
              testLogIn({ email, token, firstName, lastName, idToken })
            }
          >
            <span className={styles.text}>
              <FormattedMessage defaultMessage="Test Sign in" id="Icg+J0" />
            </span>
          </button>
        </div>
      </main>
    );
  } else {
    return <AppLoadingPage isError={isInitialLoadError} />;
  }
};

LoginPage.propTypes = {
  isGISLoaded: PropTypes.bool.isRequired,
  isGISLoading: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  loadGIS: PropTypes.func.isRequired,
  testLogIn: PropTypes.func.isRequired,
  location: locationType,
  isInitialLoadError: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isGISLoaded: isGISLoaded(state),
    isGISLoading: isGISLoading(state),
    isUserLoggedIn: getUserLoggingStatus(state),
    isInitialLoadError: isInitialLoadError(state),
    isUnknownUser: isUnknownUser(state),
  }),
  (dispatch) => ({
    loadGIS: () => dispatch(loadGIS(true)),
    testLogIn: (options) => dispatch(testLogIn(options)),
  })
)(LoginPage);
