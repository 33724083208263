import React from "react";
import styles from "./ResourceTileBody.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  RESOURCE_TILE_ID,
  TILE_ICONS,
  RESOURCE_FORMAT,
  RESOURCE_THUMBNAIL_SIZE,
} from "../../../../state/resources/types";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";
import { getWindowSize } from "../../../../state/app/selectors";
import ResourceTypesGroupList from "../ResourceTypesGroupList/ResourceTypesGroupList";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";

const ResourceTileBody = ({
  id,
  type,
  description,
  title,
  thumbnail,
  resourceTypes,
  windowSize,
}) => {
  let tileIcon = "material-icon-folder";
  if (type === RESOURCE_FORMAT.WORKSPACE) {
    tileIcon = TILE_ICONS.WORKSPACE;
  }
  if (type === RESOURCE_FORMAT.RESOURCE) {
    tileIcon = TILE_ICONS.RESOURCE;
  }

  const thumbnailSrc =
    type === RESOURCE_FORMAT.WORKSPACE
      ? `${thumbnail}&w=${RESOURCE_THUMBNAIL_SIZE * 2}&h=${
          RESOURCE_THUMBNAIL_SIZE * 2
        }`
      : thumbnail;

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.thumbnail, {
          [styles.square]: type === RESOURCE_FORMAT.WORKSPACE,
        })}
      >
        {thumbnail && <img src={thumbnailSrc} className={styles.img} alt="" />}
        {!thumbnail && (
          <div className={styles.noThumbnail}>
            <HapReactIcon className={styles.openBook} svg={tileIcon} />
          </div>
        )}
      </div>
      <div className={styles.main}>
        {windowSize >= WINDOW_SIZE_TYPES.S && (
          <ResourceTypesGroupList itemResourceTypes={resourceTypes} />
        )}
        <div className={styles.name}>
          <button
            className={styles.titleButton}
            id={`${RESOURCE_TILE_ID}${id}`}
            aria-label={`${title}, preview resource for more details`}
            data-test-id="lb-ResourceTile-Body-Title"
          >
            <h2 className={styles.title}>{stripHtmlTagsFromString(title)}</h2>
          </button>
          {windowSize >= WINDOW_SIZE_TYPES.S && description && (
            <div
              className={styles.description}
              data-test-id="lb-ResourceTile-Body-Description"
            >
              {stripHtmlTagsFromString(description)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ResourceTileBody.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(_.values(RESOURCE_FORMAT)),
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string,
  resourceTypes: PropTypes.arrayOf(PropTypes.string),
  // connected
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect(
  (state) => ({
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({})
)(ResourceTileBody);
