import styles from "../../Highlights/StudentTile/StudentTile.module.scss";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

const StudentTileLoadingState = () => {
  return (
    <div className={classnames(styles.tabsContainer, styles.withBorder)}>
      <div className={classnames(styles.status, styles.whiteBackground)}>
        <div className={styles.title}>
          <FormattedMessage defaultMessage="Loading..." id="gjBiyj" />
        </div>
      </div>
    </div>
  );
};

export default StudentTileLoadingState;
