import { User } from "../types/User";
import { defaultPermissions, Permission } from "../types/Permissions";

export const transformPermissions = (
  permissions?: string[]
): User["permissions"] => {
  const permissionsSet = new Set(permissions);
  const result = Object.fromEntries(
    Object.keys(defaultPermissions).map((permission) => [
      permission,
      permissionsSet.has(permission as Permission),
    ])
  ) as User["permissions"];
  return result;
};
