import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./StudentTileContextMenu.module.scss";
import Button, {
  ButtonLink,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import {
  showGuideBrowsingModal,
  startNewSessionSync,
} from "../../../../state/highlights/sessions/actions";
import { showShareLinksModal } from "../../../../state/highlights/shareLinks/actions";
import { userGroupsShowManagementPanel } from "../../../../state/shared/userGroups/actions";
import {
  SESSION_TYPES,
  MODAL_MODE_TYPES,
  SESSION_RECIPIENT_TYPES,
} from "../../../../state/highlights/sessions/types";
import { BASE_GMAIL_URL } from "../../../../utils";
import {
  getPauseScreensDuration,
  getStudentNotesEnabled,
  getActiveViewType,
} from "../../../../state/highlights/config/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import { postTeacherNotes } from "../../../../apiCalls/user";
import { getUserFamilyName } from "../../../../state/user/selectors";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import classnames from "classnames";
import { getScreenshotsEnabled } from "../../../../state/highlights/config/selectors";
import { getUserEmailFormatPreference } from "../../../../state/user/selectors";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import {
  getIsBrowserTabs,
  getIsClassViewPage,
} from "../../../../state/router/selectors";
import { showCurrentScreenExpandModal } from "../../../../state/highlights/currentScreenExpandModal/action";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

const StudentTileContextMenu = ({
  isOnline,
  isInGuideBrowsingSession,
  isOutsideSchoolNetwork,
  isOutsideSchoolHours,
  studentId,
  studentEmail,
  studentDisplayName,
  studentLastName,
  userGroupsShowManagementPanel,
  showGuideBrowsingModal,
  showShareLinksModal,
  isStudentNotesEnabled,
  classId,
  isPaused,
  pauseScreensDuration,
  startNewSession,
  teacherName,
  reportProblemAction,
  isScreenshotsEnabled,
  isBrowserTabs,
  isClassViewPage,
  activeClassViewType,
  showCurrentScreenExpandModal,
  isDriveScreen,
  subjectFolderMenuPayload,
}) => {
  const [isPauseConfirmationOpened, setIsPauseConfirmationOpened] =
    useState(false);
  const [isPauseInProgress, setIsPauseInProgress] = useState(false);
  const [isPauseError, setIsPauseError] = useState(false);
  const emailFormatPreference = useSelector(getUserEmailFormatPreference);
  const intl = useIntl();
  const triggerComponent = useCallback((props) => {
    return (
      <MemorisedFocusButton
        icon="ellipsis"
        className={styles.dropdownTrigger}
        size={BUTTON_SIZES.SMALL}
        type={BUTTON_TYPES.TERTIARY}
        data-test-id="hl-StudentTile-ContextMenuDropdown-Trigger"
        aria-label={intl.formatMessage({
          defaultMessage: "Open context menu",
          id: "K8PKOg",
        })}
        {...props}
      />
    );
  }, []);
  const dropdownItems = [];

  const addViewScreenOption = () => {
    dropdownItems.push(() => (
      <div className={styles.bottomDivider}>
        <Button
          icon="camera"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "View screen",
            id: "dFy4ro",
          })}
          onAction={() =>
            showCurrentScreenExpandModal({
              studentId,
              studentEmail,
              studentLastName,
              studentDisplayName,
            })
          }
          dataTestId="Hl-StudentTile-Dropdown-ViewScreen"
          className={styles.dropdownItem}
        />
      </div>
    ));
  };

  const addOpenFolderOption = (subjectFolderID, isLoading) => {
    const folderLink = `https://drive.google.com/drive/folders/${subjectFolderID}`;
    dropdownItems.push(() => (
      <ButtonLink
        icon={isLoading ? "loader" : "folder"}
        role="button"
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "Open Folder",
          id: "FpLvdT",
        })}
        dataTestId="Db-StudentTile-Dropdown-OpenFolder"
        href={isLoading ? null : folderLink}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.dropdownItem}
        spin={isLoading}
      />
    ));
  };

  const { showFolderOption, subjectFolderID, isLoading } =
    subjectFolderMenuPayload || {};
  if (showFolderOption) {
    addOpenFolderOption(subjectFolderID, isLoading);
  }

  if (isOnline && !isDriveScreen) {
    if (
      isScreenshotsEnabled &&
      (isBrowserTabs || (isClassViewPage && activeClassViewType === "tabs"))
    ) {
      addViewScreenOption();
    }

    dropdownItems.push(() => (
      <div className={styles.bottomDivider}>
        <Button
          icon="link"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "Share links",
            id: "86oph/",
          })}
          onAction={() => {
            showShareLinksModal({
              selectedStudent: {
                Id: studentId,
                Email: studentEmail,
                FullName: studentDisplayName,
              },
            });
          }}
          dataTestId="Hl-StudentTile-Dropdown-Share-Links"
          className={styles.dropdownItem}
        />
      </div>
    ));

    dropdownItems.push(() => (
      <Button
        icon="focused-browsing-on"
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "Start focus session",
          id: "Td6qnA",
        })}
        onAction={() => {
          showGuideBrowsingModal({
            modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
            sessionType: SESSION_TYPES.FOCUS,
            selectedStudent: {
              Id: studentId,
              Email: studentEmail,
              FullName: studentDisplayName,
            },
          });
        }}
        dataTestId="Hl-StudentTile-Dropdown-Start-Focus-Session"
        className={styles.dropdownItem}
      />
    ));

    dropdownItems.push(() => (
      <div
        className={classnames({
          [styles.bottomDivider]:
            isOutsideSchoolNetwork || isOutsideSchoolHours,
        })}
      >
        <Button
          icon="focused-browsing-on"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "Start filter session",
            id: "83fH1Z",
          })}
          onAction={() => {
            showGuideBrowsingModal({
              modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
              sessionType: SESSION_TYPES.FILTER,
              selectedStudent: {
                Id: studentId,
                Email: studentEmail,
                FullName: studentDisplayName,
              },
            });
          }}
          dataTestId="Hl-StudentTile-Dropdown-Start-Filter-Session"
          className={styles.dropdownItem}
        />
      </div>
    ));

    if (!isOutsideSchoolNetwork && !isOutsideSchoolHours) {
      dropdownItems.push(() => (
        <div className={styles.bottomDivider}>
          <Button
            icon="focused-browsing-on"
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            label={intl.formatMessage({
              defaultMessage: "Freeze tabs",
              id: "oU2Nkm",
            })}
            onAction={() => {
              showGuideBrowsingModal({
                modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
                sessionType: SESSION_TYPES.LOCK,
                selectedStudent: {
                  Id: studentId,
                  Email: studentEmail,
                  FullName: studentDisplayName,
                },
              });
            }}
            dataTestId="Hl-StudentTile-Dropdown-Freeze-Tabs"
            className={styles.dropdownItem}
          />
        </div>
      ));
    }
    dropdownItems.push(() => (
      <div className={styles.bottomDivider}>
        <Button
          icon="screen-pause"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "Pause screen",
            id: "fZkH2N",
          })}
          onAction={() => {
            setIsPauseConfirmationOpened(true);
          }}
          dataTestId="Hl-StudentTile-Dropdown-Pause-Screen"
          className={styles.dropdownItem}
          isDisabled={isPaused}
        />
      </div>
    ));
  }

  const emailLink =
    emailFormatPreference === "mailto"
      ? `mailto:${studentEmail}`
      : `${BASE_GMAIL_URL}/u/0/?tf=cm&to=${studentEmail}`;

  dropdownItems.push(() => (
    <ButtonLink
      icon="envelope-o"
      role="button"
      type={BUTTON_TYPES.TERTIARY}
      size={BUTTON_SIZES.SMALL}
      label={intl.formatMessage({ defaultMessage: "Email", id: "sy+pv5" })}
      dataTestId="Hl-StudentTile-Dropdown-Email"
      href={emailLink}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.dropdownItem}
    />
  ));

  dropdownItems.push(() => (
    <div
      className={classnames({
        [styles.bottomDivider]:
          !isStudentNotesEnabled && (isPaused || isInGuideBrowsingSession),
      })}
    >
      <Button
        icon="profile-group"
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "Edit groups",
          id: "vSSHlg",
        })}
        onAction={() => {
          userGroupsShowManagementPanel({
            contentType: "",
            selectedStudent: {
              email: studentEmail,
              fullName: studentDisplayName,
            },
          });
        }}
        dataTestId="Hl-StudentTile-Dropdown-Edit-UserGroups"
        className={styles.dropdownItem}
      />
    </div>
  ));

  if (isStudentNotesEnabled) {
    dropdownItems.push(() => (
      <div
        className={classnames({
          [styles.bottomDivider]: isPaused || isInGuideBrowsingSession,
        })}
      >
        <Button
          icon="label"
          role="button"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "Student notes",
            id: "W9TJIf",
          })}
          className={styles.dropdownItem}
          dataTestId="Hl-StudentTile-Dropdown-Student-Notes"
          onAction={() => {
            const tabWindowId = window.open("", "_blank");
            postTeacherNotes({ studentEmail, classId }).then((notesURL) => {
              if (notesURL) {
                tabWindowId.location.href = notesURL;
              }
            });
          }}
        />
      </div>
    ));
  }

  if (isPaused || isInGuideBrowsingSession) {
    dropdownItems.push(() => (
      <Button
        icon="flag-fill"
        role="button"
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "Report a problem",
          id: "Hf/X9X",
        })}
        className={styles.dropdownItem}
        dataTestId="Hl-StudentTile-Dropdown-ReportProblem"
        onClick={reportProblemAction}
      />
    ));
  }

  return (
    <div className={styles.root}>
      {isPauseConfirmationOpened && (
        <ActionConfirmationDialog
          dataTestPrefix="Hl-StudentTile-Dropdown-PauseIndividualConfirmationModal"
          isOpen={true}
          onClose={() => {
            setIsPauseConfirmationOpened(false);
            setIsPauseError(false);
          }}
          isActionInProgress={isPauseInProgress}
          title={intl.formatMessage(
            {
              defaultMessage: `Pause screen for {studentDisplayName}?`,
              id: "U1Is/u",
            },
            {
              studentDisplayName,
            }
          )}
          error={
            isPauseError
              ? intl.formatMessage({
                  defaultMessage:
                    "Sorry, there was a problem pausing this student's screen. Please try again.",
                  id: "raFkEK",
                })
              : null
          }
          cancelDataTestId="Hl-StudentTile-Dropdown-PauseIndividualConfirmationModal-Button-Cancel"
          actionLabel={intl.formatMessage({
            defaultMessage: "Pause screen",
            id: "fZkH2N",
          })}
          onAction={async () => {
            setIsPauseInProgress(true);
            try {
              await startNewSession({
                sessionDetails: {
                  RecipientType: SESSION_RECIPIENT_TYPES.STUDENTS,
                  ClassURN: classId,
                  Type: SESSION_TYPES.PAUSE,
                  Duration: pauseScreensDuration * 60 * 1000,
                  Students: [studentId],
                  TeacherName: teacherName,
                },
              });
              setIsPauseConfirmationOpened(false);
            } catch (e) {
              setIsPauseError(true);
            } finally {
              setIsPauseInProgress(false);
            }
          }}
          actionDataTestId="Hl-StudentTile-Dropdown-PauseIndividualConfirmationModal-Button-Confirm"
        >
          <p>
            <FormattedMessage
              defaultMessage="This student’s screen will be paused for a maximum of {pauseScreensDuration} minutes."
              id="pOXie4"
              values={{ pauseScreensDuration }}
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="You can unpause their screens anytime before that."
              id="pOj1zH"
            />
          </p>
        </ActionConfirmationDialog>
      )}
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        classNameContent={styles.menuDropdownContent}
        triggerComponent={triggerComponent}
        itemComponentList={dropdownItems}
      />
    </div>
  );
};

StudentTileContextMenu.propTypes = {
  studentId: PropTypes.string.isRequired,
  studentEmail: PropTypes.string.isRequired,
  studentDisplayName: PropTypes.string.isRequired,
  studentLastName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isInGuideBrowsingSession: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  isOutsideSchoolNetwork: PropTypes.bool.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  reportProblemAction: PropTypes.func.isRequired,
  subjectFolderMenuPayload: PropTypes.object,
  // connected props
  userGroupsShowManagementPanel: PropTypes.func.isRequired,
  showGuideBrowsingModal: PropTypes.func.isRequired,
  startNewSession: PropTypes.func.isRequired,
  isStudentNotesEnabled: PropTypes.bool.isRequired,
  classId: PropTypes.string,
  teacherName: PropTypes.string.isRequired,
  pauseScreensDuration: PropTypes.number.isRequired,
  isScreenshotsEnabled: PropTypes.bool.isRequired,
  isBrowserTabs: PropTypes.bool.isRequired,
  isClassViewPage: PropTypes.bool.isRequired,
  activeClassViewType: PropTypes.string,
  isDriveScreen: PropTypes.bool,
  showCurrentScreenExpandModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isStudentNotesEnabled: getStudentNotesEnabled(state),
    classId: getClassId(state),
    pauseScreensDuration: getPauseScreensDuration(state),
    teacherName: getUserFamilyName(state),
    isScreenshotsEnabled: getScreenshotsEnabled(state),
    isBrowserTabs: getIsBrowserTabs(state),
    isClassViewPage: getIsClassViewPage(state),
    activeClassViewType: getActiveViewType(state),
  }),
  (dispatch) => ({
    userGroupsShowManagementPanel: (payload) =>
      dispatch(userGroupsShowManagementPanel(payload)),
    showGuideBrowsingModal: (payload) =>
      dispatch(showGuideBrowsingModal(payload)),
    showShareLinksModal: (payload) => dispatch(showShareLinksModal(payload)),
    startNewSession: ({ sessionDetails }) =>
      dispatch(startNewSessionSync({ sessionDetails })),
    showCurrentScreenExpandModal: (payload) =>
      dispatch(showCurrentScreenExpandModal(payload)),
  })
)(StudentTileContextMenu);
