import React, { ReactNode, CSSProperties } from "react";
import classnames from "classnames";
import styles from "./Box.module.scss";

type Multiplier =
  | "0x"
  | "05x"
  | "1x"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "14x";

export type BoxProps<T extends ReactNode> = {
  asChild?: boolean;
  className?: string;
  style?: CSSProperties;

  children: T;

  mt?: Multiplier;
  mr?: Multiplier;
  mb?: Multiplier;
  ml?: Multiplier;
  mv?: Multiplier;
  mh?: Multiplier;

  pt?: Multiplier;
  pr?: Multiplier;
  pb?: Multiplier;
  pl?: Multiplier;
  pv?: Multiplier;
  ph?: Multiplier;
};

/** `Box` is a versatile wrapper component that provides consistent spacing
 * using our design system `vars`.
 *
 * Use the `asChild` prop to apply the styles directly to the child without an
 * extra wrapping div, useful for overriding defaults in components. */
export const Box = <T extends ReactNode>({
  asChild,
  className,
  style,
  children,

  mt,
  mr,
  mb,
  ml,
  mh,
  mv,

  pt,
  pr,
  pb,
  pl,
  ph,
  pv,
}: BoxProps<T>) => {
  const extraClassNames = {
    [styles.box]: true,
    [styles[`mt-${mt}`]]: mt,
    [styles[`mr-${mr}`]]: mr,
    [styles[`mb-${mb}`]]: mb,
    [styles[`ml-${ml}`]]: ml,
    [styles[`mh-${mh}`]]: mh,
    [styles[`mv-${mv}`]]: mv,

    [styles[`pt-${pt}`]]: pt,
    [styles[`pr-${pr}`]]: pr,
    [styles[`pb-${pb}`]]: pb,
    [styles[`pl-${pl}`]]: pl,
    [styles[`ph-${ph}`]]: ph,
    [styles[`pv-${pv}`]]: pv,
  };

  const allClassNames = classnames(className, extraClassNames);

  if (asChild) {
    if (React.Children.count(children) !== 1) {
      throw new Error(
        "Box component expects a single React element when using `asChild`."
      );
    }
    if (!React.isValidElement(children)) {
      throw new Error(
        "Box component expects the child to be a valid React element."
      );
    }

    return React.cloneElement(children, {
      className: classnames(children.props.className, allClassNames),
      style: style,
    });
  }

  return (
    <div style={style} className={allClassNames}>
      {children}
    </div>
  );
};
