import { AnyAction } from "redux";
import actionTypes from "../../actionTypes";
import { CollectionsState } from "./types";

export const INIT_STATE: CollectionsState = {
  items: [],
  isLoading: false,
  isLoaded: false,
  isLoadingError: false,
  isCollectionsInEditMode: false,
};

const libraryReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.LIBRARY_COLLECTIONS_LOAD_PENDING:
      return { ...state, isLoading: true, isLoadingError: false };

    case actionTypes.LIBRARY_COLLECTIONS_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isLoadingError: true,
      };

    case actionTypes.LIBRARY_COLLECTIONS_LOAD_SUCCESS:
      const collections = action.payload || [];
      const isEditMode =
        collections.length === 0 ? false : state.isCollectionsInEditMode;
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isLoadingError: false,
        items: collections,
        isCollectionsInEditMode: isEditMode,
      };

    case actionTypes.LIBRARY_COLLECTION_SET_EDIT_MODE:
      return { ...state, isCollectionsInEditMode: action.payload };

    default:
      return state;
  }
};

export default libraryReducer;
