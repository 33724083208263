import { UserGroup } from "../../../hooks/useGroups";
import { RootState } from "../../store";
import { USER_GROUP_PREFS_TYPE } from "../../shared/userGroups/types";
import { DashboardClassDataViewgroups } from "./types";
import { filterViewGroupBySubjectFolder } from "./utils";
import { transformDataSource } from "../../../apiCalls/classInfo/classInfoTransforms";
import { ClassDataSourceTypes } from "src/components/containers/Dashboard/DashboardPage/types";

export const getClassDashboard = (state: RootState) => state.dashboard.class;

export const getIsClassDashboardError = (state: RootState): boolean =>
  state.dashboard.class.isError;

export const getIsClassDashboardLoading = (state: RootState): boolean =>
  state.dashboard.class.isLoading;

export const getIsClassDashboardLoaded = (state: RootState): boolean =>
  state.dashboard.class.isLoaded;

export const getClassDashboardUserGroups = (state: RootState) => {
  const userGroups: UserGroup[] =
    state.dashboard.class?.data?.people?.usergroups;
  return userGroups;
};

export const getClassDashboardUserGroupsPreference = (state: RootState) => {
  const groupsPrefs =
    state.dashboard.class?.data?.preferences?.viewlevel_usergroups;
  const groupsPreference = groupsPrefs
    ? USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
    : USER_GROUP_PREFS_TYPE.SHARED_ACROSS_TABS;
  return groupsPreference;
};

export const getCheckedDashboardUserGroups = (state: RootState) => {
  const groups = getClassDashboardUserGroups(state);
  return groups && groups.length > 0 ? groups.filter((g) => g.checked) : [];
};

export const getIsClassActiveSelector = (state: RootState): boolean => {
  return !state.dashboard.class?.data?.is_deactivated;
};

export const getDashboardSubjectFolders = (state: RootState): string[] => {
  const dashboardView: DashboardClassDataViewgroups["views"] =
    state?.dashboard?.class?.data?.viewgroups?.find(
      (viewGroup) => viewGroup.title === "Dashboard"
    )?.views;

  const subjectFolders =
    filterViewGroupBySubjectFolder(dashboardView)?.length > 0
      ? filterViewGroupBySubjectFolder(dashboardView)
      : ["Documents"];

  return subjectFolders;
};

export const getDashboardClassType = (
  state: RootState
): ClassDataSourceTypes => {
  const sourceinfo = state?.dashboard?.class?.data?.sourceinfo;
  return transformDataSource(sourceinfo?.[0] || "");
};
