import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./LibrarySubMenu.module.scss";
import { NavLink, Link } from "react-router-dom";
import { APP_PAGES } from "../../../../state/router/types";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import buttonStyles from "@hapara/ui/src/atomic/Button/Button.module.scss";
import { LIBRARY_CATEGORIES } from "../../../../state/library/library/types";
import SubMenu from "../../SubMenu/SubMenu";
import stylesSubMenu from "../../SubMenu/SubMenu.module.scss";
import { getClassId } from "../../../../state/shared/selectors";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import { MYWORKSPACES_CATEGORIES } from "../../../../state/workspace/myworkspaces/list/types";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { hasResourceUploadAccess } from "../../../../state/library/config/selectors";
import CreateSubMenuButton from "../../Workspace/Create/CreateSubMenuButton/CreateSubMenuButton";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { USER_PERMISSIONS } from "../../../../state/user/types";
import {
  getUserPermissions,
  hasWSAccess,
  hasResourcesAccess,
} from "../../../../state/user/selectors";
import { getLocationPath } from "../../../../state/router/selectors";

const LibrarySubMenu = ({
  selectedClassId,
  hasResourceUploadAccess,
  hasWSAccess,
  hasMyResourcesAccess,
  userPermissions,
  locationPath,
}) => {
  const queryString = selectedClassId
    ? `?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`
    : "";

  const myWorkspacesLink = selectedClassId
    ? `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.CLASSES.urlKey}${queryString}`
    : `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey}`;

  const libraryLink = `${APP_PAGES.LIBRARY_CATEGORIES.path}/${LIBRARY_CATEGORIES.ALL.urlKey}${queryString}`;

  const discoverLink = `${APP_PAGES.DISCOVER_BASE.path}${queryString}`;

  const myResourcesLink = queryString
    ? `${APP_PAGES.MY_RESOURCES_BASE.path}${queryString}`
    : APP_PAGES.MY_RESOURCES_STARRED.path;

  const libraryNewUploadLink = `${APP_PAGES.LIBRARY_UPLOAD.path}${queryString}`;

  const libraryUploadStatusLink = `${APP_PAGES.LIBRARY_UPLOAD_STATUS.path}${queryString}`;

  const hasLibraryAccess =
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_WORKSPACE) ||
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_RESOURCES);

  const showUploadResourceButton =
    hasResourceUploadAccess &&
    _.includes(window.location.pathname, APP_PAGES.LIBRARY_BASE.path);

  const isMyWorkspacesPage = _.startsWith(
    locationPath,
    APP_PAGES.WORKSPACE_MY_WORKSPACE.path
  );
  return (
    <SubMenu
      tabsLabel="Workspace pages"
      toolbarLabel={isMyWorkspacesPage ? "Workspaces toolbar" : null}
      className={styles.root}
      toolbarClassName={styles.toolbar}
      tabs={
        <>
          {hasWSAccess && (
            <NavLink
              to={myWorkspacesLink}
              activeClassName={stylesSubMenu.active}
              data-test-id="hs-WorkspacesSubNav-Link-MyWorkspaces"
              isActive={(match, location) =>
                _.includes(
                  location.pathname,
                  APP_PAGES.WORKSPACE_MY_WORKSPACE.path
                )
              }
            >
              <FormattedMessage defaultMessage="My Workspaces" id="9BWtaE" />
            </NavLink>
          )}
          {hasMyResourcesAccess && (
            <NavLink
              to={myResourcesLink}
              activeClassName={stylesSubMenu.active}
              data-test-id="hs-WorkspacesSubNav-Link-MyResources"
              isActive={(match, location) =>
                _.includes(location.pathname, APP_PAGES.MY_RESOURCES_BASE.path)
              }
            >
              <FormattedMessage defaultMessage="My Resources" id="gMtUZq" />
            </NavLink>
          )}
          {hasLibraryAccess && (
            <NavLink
              to={libraryLink}
              strict
              activeClassName={stylesSubMenu.active}
              data-test-id="hs-WorkspacesSubNav-Link-Library"
              isActive={(match, location) => {
                if (
                  _.includes(location.pathname, APP_PAGES.LIBRARY_BASE.path)
                ) {
                  return true;
                }
              }}
            >
              <FormattedMessage defaultMessage="Our Library" id="xd/lJJ" />
            </NavLink>
          )}
          <NavLink
            to={discoverLink}
            strict
            activeClassName={stylesSubMenu.active}
            data-test-id="hs-WorkspacesSubNav-Link-Discover"
          >
            <FormattedMessage defaultMessage="Discover" id="cE4Hfw" />
          </NavLink>
        </>
      }
      toolbar={
        <>
          {showUploadResourceButton && (
            <div className={styles.uploadsWrapper}>
              <Dropdown
                positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
                triggerComponent={(props) => (
                  <Button
                    label="Uploads"
                    type={BUTTON_TYPES.PRIMARY}
                    rightIcon="arrow-carvet-down"
                    dataTestId="lb-Subnav-UploadDropdown-TriggerButton"
                    aria-label="Uploads"
                    className={styles.uploadsDDTriger}
                    {...props}
                  />
                )}
                itemComponentList={[
                  ({ className, ...rest }) => (
                    <Link
                      to={libraryNewUploadLink}
                      data-test-id="lb-Subnav-Upload-NewUpload_Button"
                      className={classnames(
                        buttonStyles.root,
                        buttonStyles.small,
                        buttonStyles.tertiary,
                        buttonStyles.iconlabel,
                        styles.uploadsListButton
                      )}
                    >
                      <HapReactIcon
                        svg="plus-thick"
                        width={40}
                        height={40}
                        className={classnames(
                          buttonStyles.icon,
                          buttonStyles.leftIcon
                        )}
                      />
                      <span>
                        <FormattedMessage
                          defaultMessage="New upload"
                          id="07/+yV"
                        />
                      </span>
                    </Link>
                  ),
                  ({ className, ...rest }) => (
                    <Link
                      to={libraryUploadStatusLink}
                      data-test-id="lb-Subnav-Button-UploadedContent"
                      className={classnames(
                        buttonStyles.root,
                        buttonStyles.small,
                        buttonStyles.tertiary,
                        buttonStyles.iconlabel,
                        styles.uploadsListButton
                      )}
                    >
                      <HapReactIcon
                        svg="checklist"
                        width={40}
                        height={40}
                        className={classnames(
                          buttonStyles.icon,
                          buttonStyles.leftIcon
                        )}
                      />
                      <span>
                        <FormattedMessage
                          defaultMessage="Check status"
                          id="X+WCbq"
                        />
                      </span>
                    </Link>
                  ),
                ]}
              />
            </div>
          )}

          {hasWSAccess && <CreateSubMenuButton />}
        </>
      }
    />
  );
};

LibrarySubMenu.propTypes = {
  hasResourceUploadAccess: PropTypes.bool.isRequired,
  hasWSAccess: PropTypes.bool.isRequired,
  hasMyResourcesAccess: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  userPermissions: PropTypes.arrayOf(
    PropTypes.oneOf(_.values(USER_PERMISSIONS))
  ),
  locationPath: PropTypes.string.isRequired,
};

export default connect((state) => ({
  selectedClassId: getClassId(state),
  hasResourceUploadAccess: hasResourceUploadAccess(state),
  hasWSAccess: hasWSAccess(state),
  hasMyResourcesAccess: hasResourcesAccess(state),
  userPermissions: getUserPermissions(state),
  locationPath: getLocationPath(state),
}))(LibrarySubMenu);
