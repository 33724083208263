import { Collection } from "./types";
import { RootState } from "../../store";
import { createSelector } from "reselect";
import _ from "lodash";
import { LIBRARY_CATEGORIES } from "../library/types";
import { LIBRARY_URL_KEYS } from "../library/navigation";
import { getLocationQuery, getLocationPath } from "../../router/selectors";
import { APP_PAGES } from "../../router/types";

export const getLibraryCollections = (state: RootState): Collection[] =>
  state.library.collections.items;

export const areCollectionsLoading = (state: RootState): boolean =>
  state.library.collections.isLoading;

export const areCollectionsLoaded = (state: RootState): boolean =>
  state.library.collections.isLoaded;

export const isCollectionsLoadingError = (state: RootState): boolean =>
  state.library.collections.isLoadingError;

export const areCollectionsInEditMode = (state: RootState): boolean =>
  state.library.collections.isCollectionsInEditMode;

/* not a selector, just a func that needed below and in navigaton.js */
export function getCollectionIdFromPath(path: string): string {
  const matchRes = path.match(/\/library\/category\/(\w*)/) || [];
  return (
    matchRes[0]?.replace(`${APP_PAGES.LIBRARY_CATEGORIES.path}/`, "") || ""
  );
}

export const getSelectedCollectionId = createSelector(
  [getLocationQuery, getLocationPath],
  (locationQuery: string, locationPath: string) => {
    /* 
    collection id can be specified in 2 ways:
    - in url as /library/category/${collectionId}
    - in url for search results as /library/search?collection=all&...
    */
    const paramName = LIBRARY_URL_KEYS.collection.key;
    const rawParam = _.get(locationQuery, paramName);
    if (!_.isEmpty(rawParam)) {
      return rawParam;
    }

    if (_.startsWith(locationPath, APP_PAGES.LIBRARY_CATEGORIES.path)) {
      return getCollectionIdFromPath(locationPath);
    }

    return LIBRARY_CATEGORIES.ALL.urlKey;
  }
);

export const getSelectedCollection = createSelector(
  [getSelectedCollectionId, getLibraryCollections],
  (
    selectedCollectionId: string,
    collectionsList: Collection[]
  ): Collection | null => {
    if (selectedCollectionId === LIBRARY_CATEGORIES.ALL.urlKey) {
      return {
        name: LIBRARY_CATEGORIES.ALL.title,
        id: LIBRARY_CATEGORIES.ALL.urlKey,
      };
    } else if (
      selectedCollectionId === LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey
    ) {
      return {
        name: LIBRARY_CATEGORIES.UNCATEGORIZED.title,
        id: LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey,
      };
    }
    // should return null if a collection id specified in the URL but nothing matched in the collections list
    // this is possible if a collection was deleted
    return (
      collectionsList.find((c: Collection) => c.id === selectedCollectionId) ||
      null
    );
  }
);

export const isInsideLibraryCollections = createSelector(
  getSelectedCollectionId,
  (collectionId: string): boolean => {
    if (
      _.isEmpty(collectionId) ||
      LIBRARY_CATEGORIES.ALL.type === collectionId ||
      LIBRARY_CATEGORIES.UNCATEGORIZED.type === collectionId
    ) {
      return false;
    }
    return true;
  }
);
