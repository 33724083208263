import React, {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { InitResponseType, FeatureFlag, FeatureFlags } from "./types";
import { useAuth } from "../AuthProvider";

//TODO: Refactor return types => https://hapara.atlassian.net/browse/HAP-11614
export type FeatureFlagProviderProps = {
  children: ReactNode;
  /** @deprecated Do NOT use yet, still deciding on API! */
  haparaApiUrl?: string;
};

type FeatureFlagContextState =
  | {
      isFeatureFlagEnabled: (featureFlag: FeatureFlag) => boolean | null;
      featureFlags: FeatureFlags | null;
    }
  | undefined;

export const FeatureFlagContext =
  createContext<FeatureFlagContextState>(undefined);

export const FeatureFlagProvider = ({
  children,
  haparaApiUrl,
}: FeatureFlagProviderProps) => {
  const { authFetch, isAuthenticated } = useAuth();
  const [featureFlags, setFeatureFlags] = useState<Record<
    FeatureFlag,
    boolean
  > | null>(null);

  const fetchFeatureFlags = async () => {
    const response = await authFetch<InitResponseType>(
      `${haparaApiUrl ?? process.env.HAPARA_API_URL}/admin/config/init`
    );
    if (response.ok) {
      setFeatureFlags(response.data.flags);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchFeatureFlags();
    }
  }, [isAuthenticated]);

  const isFeatureFlagEnabled = useCallback(
    (flag: FeatureFlag) => {
      return featureFlags !== null ? featureFlags[flag] ?? false : null;
    },
    [featureFlags]
  );

  return (
    <FeatureFlagContext.Provider value={{ isFeatureFlagEnabled, featureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
