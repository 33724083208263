import { env } from "../../../utils/getHaparaEnv";
import { isPassthroughScope } from "../utils/passthroughScopes";

const isEmptyObject = (obj: Record<string, unknown>) =>
  Object.keys(obj).length === 0;

export const fetchToken = async ({
  haparaAuthApiEndpoint,
  scope,
  classId,
}: {
  haparaAuthApiEndpoint?: string;
  scope?: string;
  classId?: string;
}): Promise<Response> => {
  const authEndpointUrl = new URL(
    haparaAuthApiEndpoint ?? env.HAPARA_AUTH_API_ENDPOINT
  );

  if (scope && isPassthroughScope(scope)) {
    authEndpointUrl.searchParams.append("scope", scope);
  }

  const body: Record<string, string> = {};
  const headers: Record<string, string> = {};
  if (classId) {
    body.class = classId;
    headers["Content-Type"] = "application/json";
  }

  const requestBody = isEmptyObject(body) ? {} : { body: JSON.stringify(body) };
  const requestHeaders = isEmptyObject(headers) ? {} : { headers };

  return fetch(authEndpointUrl.toString(), {
    method: "POST",
    credentials: "include",
    ...requestHeaders,
    ...requestBody,
  });
};
