import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";
import styles from "./CreateSubMenuButton.module.scss";
import Button from "@hapara/ui/src/atomic/Button/Button";
import buttonStyles from "@hapara/ui/src/atomic/Button/Button.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import fileBagBooks from "@hapara/assets/src/images/vector-schoolbag-books.svg";
import fileBagGlobe from "@hapara/assets/src/images/vector-schoolbag-globe.svg";
import fileBag from "@hapara/assets/src/images/vector-schoolbag.svg";
import fileCapDocs from "@hapara/assets/src/images/vector-academic-cap-documents.svg";
import fileCapSpeech from "@hapara/assets/src/images/vector-academic-cap-speech-bubbles.svg";
import fileCap from "@hapara/assets/src/images/vector-academic-cap.svg";
import { APP_PAGES } from "../../../../../state/router/types";
import { SHARED_URL_KEYS } from "../../../../../state/shared/navigation-keys";
import { getFFByName } from "../../../../../state/app/selectors";
import { getClassId } from "../../../../../state/shared/selectors";
import { navigateToCreateWsForStudents } from "../../../../../state/workspace/create/navigation";
import { getLocationPath } from "../../../../../state/router/selectors";

const CreateSubMenuButton = ({
  plFF,
  selectedClassId,
  locationPath,
  isInsideInfoPage = false,
}) => {
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);

  const queryString = selectedClassId
    ? `?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`
    : "";

  const createForStudentsLink = `${APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.path}${queryString}`;

  const createForPlLink = `${APP_PAGES.WORKSPACE_CREATE_FOR_PL.path}${queryString}`;

  const isMyWorkspacesPage = _.startsWith(
    locationPath,
    APP_PAGES.WORKSPACE_MY_WORKSPACE.path
  );

  if (!isMyWorkspacesPage) return null;

  return (
    <>
      <Button
        label={isInsideInfoPage ? "Create a Workspace" : "Create"}
        icon={isInsideInfoPage ? null : "plus"}
        onClick={() => {
          if (plFF) {
            setIsCreateNewOpen(true);
          } else {
            navigateToCreateWsForStudents();
          }
        }}
        data-test-id="Ws-Subnav-Button-CreateWorkspace"
      />
      <ModalDeprecated
        isOpen={isCreateNewOpen}
        onClose={() => setIsCreateNewOpen(false)}
        title="What type of Workspace would you like to create?"
        classNameHeader={styles.createWorkspaceModalTitle}
        className={styles.createWorkspaceModal}
        dataTestPrefix="ws-CreateWorkspaceModal"
      >
        <div className={styles.createWorkspaceModalBody}>
          <div className={styles.createWorkspaceModalItem}>
            <div className={styles.createWorkspaceModalImages}>
              <img
                src={fileBagBooks}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.bagBooks
                )}
              />
              <img
                src={fileBagGlobe}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.bagGlobe
                )}
              />
              <img
                src={fileBag}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.bag
                )}
              />
            </div>
            <h2>
              <FormattedMessage defaultMessage="For students" id="7blPqk" />
            </h2>
            <NavLink
              aria-label="Create workspace for students"
              to={createForStudentsLink}
              data-test-id="ws-CreateWorkspaceModal-Button-CreateForStudents"
              className={classnames(
                buttonStyles.root,
                buttonStyles.regular,
                buttonStyles.primary,
                styles.createWorkspaceModalButton
              )}
            >
              <FormattedMessage defaultMessage="Select" id="kQAf2d" />
            </NavLink>
          </div>
          <div className={styles.createWorkspaceModalSpace} />
          <div className={styles.createWorkspaceModalItem}>
            <div className={styles.createWorkspaceModalImages}>
              <img
                src={fileCapDocs}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.capDocs
                )}
              />
              <img
                src={fileCapSpeech}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.capSpeech
                )}
              />
              <img
                src={fileCap}
                alt=""
                className={classnames(
                  styles.createWorkspaceModalImage,
                  styles.cap
                )}
              />
            </div>
            <h2>
              <FormattedMessage
                defaultMessage="For professional learning"
                id="t+3Jpk"
              />
            </h2>
            <NavLink
              aria-label="Create professional learning workspace"
              to={createForPlLink}
              data-test-id="ws-CreateWorkspaceModal-Button-CreateForPL"
              className={classnames(
                buttonStyles.root,
                buttonStyles.regular,
                buttonStyles.primary,
                styles.createWorkspaceModalButton
              )}
            >
              <FormattedMessage defaultMessage="Select" id="kQAf2d" />
            </NavLink>
          </div>
        </div>
      </ModalDeprecated>
    </>
  );
};

CreateSubMenuButton.propTypes = {
  plFF: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  locationPath: PropTypes.string.isRequired,
  isInsideInfoPage: PropTypes.bool,
};

export default connect(
  (state) => ({
    plFF: getFFByName("HAP-7034-professional-learning")(state),
    selectedClassId: getClassId(state),
    locationPath: getLocationPath(state),
  }),
  (dispatch) => ({})
)(CreateSubMenuButton);
