import React from "react";
import { createIcon } from "./utils/createIcon";

export const SearchIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.2 12c-1 .7-2 1-3.2 1a6 6 0 1 1 4.2-1.7l3.6 3.7a.6.6 0 0 1-.8.8l-3.8-3.7ZM8 12A5 5 0 1 0 8 2a5 5 0 0 0 0 10Z"
    fill="currentColor"
  />
);
