import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./StudentTileExpandModalTabs.module.scss";
import { TABS_STATES } from "../../../../state/highlights/currentScreenExpandModal/types";
import LinearProgress from "@mui/material/LinearProgress";
import StudentBrowserTabs from "../StudentBrowserTabs/StudentBrowserTabs";
import {
  INSTANCE_TYPE,
  STUDENT_BROWSER_TAB_TYPE,
} from "../../../../state/highlights/students/types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import StudentTileMultipleDevicesModal from "../StudentTileMultipleDevicesModal/StudentTileMultipleDevicesModal";
import { FormattedMessage, useIntl } from "react-intl";

const TabsLoading = () => {
  return (
    <div className={styles.tabsContainer}>
      <LinearProgress className={styles.progressbar} aria-hidden={true} />
      <div className={styles.loadingText}>
        <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
      </div>
    </div>
  );
};

const TabsOffline = ({ dataTestPrefix }) => {
  const intl = useIntl();

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage defaultMessage="Student is offline" id="2WkMYv" />
        </div>
        <div className={styles.text}>
          <FormattedMessage
            defaultMessage="They may have closed or turned off their device, or are no longer logged into their Chrome browser or Chromebook."
            id="6NnFH3"
          />
        </div>
        <a
          href="https://support.hapara.com/hc/en-us/articles/360057699092"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.helpLink}
          data-test-id={`${dataTestPrefix}-Offline-HelpCenterLink`}
        >
          <FormattedMessage
            defaultMessage="Learn more in this support article"
            id="aktWJU"
          />
          <HapReactIcon
            className={styles.arrowIcon}
            svg="arrow-external-link"
            width={16}
            height={16}
            alt={intl.formatMessage({
              defaultMessage: "opens new window",
              id: "PcoEfO",
            })}
          />
        </a>
      </div>
    </div>
  );
};

const TabsMultipleDevices = ({ dataTestPrefix }) => {
  const intl = useIntl();

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="Multiple devices detected"
            id="zLyuuN"
          />
        </div>
        <div className={styles.text}>
          <FormattedMessage
            defaultMessage="Student is signed into more than one device. Please select the device you’d like to view."
            id="H0iLQb"
          />
        </div>
        <a
          href="https://support.hapara.com/hc/en-us/articles/360057699412"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.helpLink}
          data-test-id={`${dataTestPrefix}-Offline-MultipleDevicesLink`}
        >
          <FormattedMessage
            defaultMessage="Learn more in this support article"
            id="aktWJU"
          />
          <HapReactIcon
            className={styles.arrowIcon}
            svg="arrow-external-link"
            width={16}
            height={16}
            alt={intl.formatMessage({
              defaultMessage: "opens new window",
              id: "PcoEfO",
            })}
          />
        </a>
      </div>
    </div>
  );
};

TabsOffline.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
};

const TabsTeacherAccount = () => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="Teacher or administrator account detected"
            id="0OvOAa"
          />
        </div>
        <div className={styles.text}>
          <FormattedMessage
            defaultMessage="Monitoring will be disabled until they log out of this device."
            id="UjbmkA"
          />
        </div>
      </div>
    </div>
  );
};

const TabsOutsideSchoolNetwork = () => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="Student is outside the school network"
            id="2LVdGw"
          />
        </div>
      </div>
    </div>
  );
};

const TabsOutsideSchoolHours = () => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="School is finished for the day"
            id="0QjGOw"
          />
        </div>
      </div>
    </div>
  );
};

const TabsNotBrowsing = () => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="Student is not currently browsing"
            id="X93dRK"
          />
        </div>
      </div>
    </div>
  );
};

const TabsPaused = () => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <FormattedMessage
            defaultMessage="Student’s device has been paused"
            id="7DxhpM"
          />
        </div>
      </div>
    </div>
  );
};

const StudentTileExpandModalTabs = ({
  tabsState,
  filteredTabs,
  studentId,
  studentEmail,
  studentLastName,
  instanceId,
  dataTestPrefix,
  instanceList,
  studentDisplayName,
  lastSelectedInstance,
}) => {
  const [isMultipleDeviceModalOpen, setIsMultipleDeviceModalOpen] =
    useState(false);
  const intl = useIntl();
  const isMultipleDevices = instanceList.length > 1;
  const showAlert =
    isMultipleDevices &&
    (tabsState === TABS_STATES.ONLINE ||
      tabsState === TABS_STATES.TEACHER_ACCOUNT);

  return (
    <>
      {isMultipleDeviceModalOpen && (
        <StudentTileMultipleDevicesModal
          instanceList={instanceList}
          studentDisplayName={studentDisplayName}
          currentInstance={instanceId}
          lastSelectedInstance={lastSelectedInstance}
          studentId={studentId}
          onModalOpen={setIsMultipleDeviceModalOpen}
          isModalOpen={isMultipleDeviceModalOpen}
        />
      )}
      <div className={styles.root}>
        {showAlert && (
          <div className={styles.alert}>
            <Button
              icon="multiple-devices"
              size={BUTTON_SIZES.SMALL}
              type={BUTTON_TYPES.TERTIARY}
              data-test-id={`${dataTestPrefix}-MultipleDevices-Trigger`}
              label={intl.formatMessage({
                defaultMessage: "Multiple devices detected",
                id: "zLyuuN",
              })}
              className={styles.multipleButton}
              onClick={() => {
                setIsMultipleDeviceModalOpen(true);
              }}
            />
          </div>
        )}
        {tabsState === TABS_STATES.ONLINE && (
          <>
            <div className={styles.tabsHeader}>
              <h2 data-test-id={`${dataTestPrefix}-Header`}>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# tab opened} other {# tabs opened}}"
                  id="DsNR7K"
                  values={{ count: filteredTabs.length }}
                />
              </h2>
            </div>
            <div className={styles.browserTabsContainer}>
              {instanceId && (
                <StudentBrowserTabs
                  isExpandModal={true}
                  tabs={filteredTabs}
                  studentId={studentId}
                  studentEmail={studentEmail}
                  studentLastName={studentLastName}
                  instanceId={instanceId}
                />
              )}
            </div>
          </>
        )}
        {tabsState === TABS_STATES.LOADING && <TabsLoading />}
        {tabsState === TABS_STATES.PAUSED && <TabsPaused />}
        {tabsState === TABS_STATES.NOT_BROWSING && <TabsNotBrowsing />}
        {tabsState === TABS_STATES.TEACHER_ACCOUNT && <TabsTeacherAccount />}
        {tabsState === TABS_STATES.OUTSIDE_SCHOOL_NETWORK && (
          <TabsOutsideSchoolNetwork />
        )}
        {tabsState === TABS_STATES.OFFLINE && (
          <TabsOffline dataTestPrefix={dataTestPrefix} />
        )}
        {tabsState === TABS_STATES.OUTSIDE_SCHOOL_HOURS && (
          <TabsOutsideSchoolHours />
        )}
        {tabsState === TABS_STATES.MULTIPLE_DEVICES && (
          <TabsMultipleDevices dataTestPrefix={dataTestPrefix} />
        )}
      </div>
    </>
  );
};

StudentTileExpandModalTabs.propTypes = {
  tabsState: PropTypes.oneOf(_.values(TABS_STATES)),
  filteredTabs: PropTypes.arrayOf(STUDENT_BROWSER_TAB_TYPE),
  studentId: PropTypes.string,
  studentEmail: PropTypes.string,
  studentLastName: PropTypes.string,
  instanceId: PropTypes.string,
  dataTestPrefix: PropTypes.string.isRequired,
  instanceList: PropTypes.arrayOf(INSTANCE_TYPE),
  studentDisplayName: PropTypes.string.isRequired,
  lastSelectedInstance: PropTypes.string,
};

export default StudentTileExpandModalTabs;
