import { isValidDate } from "../isValidDate";

const daysOfWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as const;

type DayOfWeek = keyof typeof daysOfWeek;

export const isDayOfWeek = (day: DayOfWeek, date: string): boolean => {
  if (!isValidDate(date)) return false;
  const selectedDate = new Date(date);
  return selectedDate.getUTCDay() === daysOfWeek[day];
};
