import React from "react";
import styles from "./ChatClassItem.module.scss";
import { ChatAvatar } from "../ChatAvatar";

export type ChatClassItemProps = {
  className: string;
  classId: string;
  onSelectClassId: (classId: string) => void;
};

export const ChatClassItem = ({
  className,
  classId,
  onSelectClassId,
}: ChatClassItemProps) => {
  const handleClick = () => {
    onSelectClassId(classId);
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      role="button"
      data-test-id="chat-class-list-item"
      title={className}
    >
      <ChatAvatar type="class" />
      <div className={styles.name}>{className}</div>
    </div>
  );
};
