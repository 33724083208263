import React from "react";
import styles from "./WebsiteInput.module.scss";
import { TextField } from "@hapara/ui/src/atomic/TextField/TextField";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import { PlusIcon } from "@hapara/ui/src/icons/PlusIcon";
import { RecentLinksDropdown } from "../RecentLinksDropdown";
import { useIntl } from "react-intl";

type WebsiteInputProps = {
  value: string;
  errorMessage?: string;
  recentSiteList?: string[];
  isAtMaxLimit?: boolean;
  onAddRecentLink: (link: string) => void;
  onInputChange: (value: string) => void;
  onAddLink: (link: string) => void;
  placeholder: string;
};

export const WebsiteInput = ({
  value,
  errorMessage,
  recentSiteList,
  isAtMaxLimit,
  onAddRecentLink,
  onInputChange,
  onAddLink,
  placeholder,
}: WebsiteInputProps) => {
  const intl = useIntl();

  return (
    <div className={styles.inputContainer}>
      <TextField
        aria-label={intl.formatMessage({
          defaultMessage: "Add website link",
          id: "bH+XI1",
        })}
        value={value}
        placeholder={placeholder}
        hintText={intl.formatMessage({
          defaultMessage:
            "Separate multiple links with a comma or by pressing enter.",
          id: "e5tCRT",
        })}
        onChangeText={onInputChange}
        className={styles.textField}
        rightAddon={
          <Button
            dataTestId="add-link-button"
            type="secondary"
            iconComponent={<PlusIcon />}
            isDisabled={isAtMaxLimit}
            aria-label={intl.formatMessage({
              id: "MP5HC+",
              defaultMessage: "add link",
            })}
            onClick={() => onAddLink(value)}
          />
        }
        isDisabled={isAtMaxLimit}
        hasError={!!errorMessage}
        onEnterPress={() => onAddLink(value)}
        errorMessage={errorMessage}
        dataTestId="session-site-input"
      />
      <RecentLinksDropdown
        isAtMaxLimit={isAtMaxLimit}
        recentSiteList={recentSiteList}
        onAddRecentLink={onAddRecentLink}
      />
    </div>
  );
};
