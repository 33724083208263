import { FormattedMessage } from "react-intl";
import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Slider from "@mui/material/Slider";
import Cropper from "react-easy-crop";
import { withStyles, makeStyles } from "@mui/styles";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

import { THUMBNAIL_TYPE } from "../../../../../state/workspace/myworkspaces/thumbnails/types";

import styles from "./WorkspaceThumbnailEdit.module.scss";

export const WorkspaceThumbnailEdit = ({
  item = {},
  isSavingThumbnailInProgress = false,
  isSavingError = false,
  onCancel = () => {},
  onSave = () => {},
  onClose = () => {},
}) => {
  // unsplash url
  const unsplashUrl = "http://unsplash.com";

  // styling to override material-ui styling
  const useStyles = makeStyles({
    colorPrimary: {
      color: styles.colorGray400,
    },
  });

  const classes = useStyles();

  // dimension settings
  const size = 164;
  const max = size * 5;
  const cropSize = { width: size, height: size };

  // zoom settings
  const step = 0.1;
  const zoomMin = 1;
  const zoomMax = max / size;

  // item params
  const w = item.width;
  const h = item.height;
  const ratio = Math.max(w / h, h / w);
  const params = w < h ? `w=${max}` : `h=${max}`;
  const initUrl = `${item.urls.raw}&fit=crop&crop=focalpoint&${params}&fp-x=0.5&fp-y=0.5&fp-z=${zoomMin}`;

  // image container dimensions
  const imageContainerWidth = w > h ? size * ratio : size;
  const imageContainerHeight = h > w ? size * ratio : size;
  const cropperContainerSize = Math.max(
    imageContainerWidth,
    imageContainerHeight
  );

  const getUsefulPart = (url) => {
    const ind = url.indexOf("photos");
    return url.substring(ind);
  };

  // local state
  const [zoom, setZoom] = useState(zoomMin);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [updatedThumbnail, setUpdatedThumbnail] = useState({
    url: "",
    alt: item.alt_description,
    downloadUrl: getUsefulPart(item.links.download_location),
  });

  // reference to zoom out button to set focus on when editing mode renders
  const buttonZoomOut = useRef();

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      // to get the center of the image
      const deltaW = croppedArea.width / 2;
      const deltaH = croppedArea.height / 2;

      // to get cordinates of the center in percentage
      const x = (croppedArea.x + deltaW) / 100;
      const y = (croppedArea.y + deltaH) / 100;

      // formatting url
      const imageUrl = `${item.urls.raw}&fit=crop&crop=focalpoint&fp-x=${x}&fp-y=${y}&fp-z=${zoom}&id=${item.id}`;
      setUpdatedThumbnail({ ...updatedThumbnail, url: imageUrl });
    },
    [item, setUpdatedThumbnail, updatedThumbnail, zoom]
  );

  const zoomOut = () => {
    if (zoom > zoomMin) setZoom(zoom - step);
  };

  const zoomIn = () => {
    if (zoom < zoomMax) setZoom(zoom + step);
  };

  useEffect(() => {
    if (buttonZoomOut) {
      buttonZoomOut.current.focus();
    }
  }, [buttonZoomOut]);

  return (
    <div className={styles.root}>
      <div className={styles.imagePart}>
        <div
          className={styles.cropperContainer}
          style={{ width: cropperContainerSize, height: cropperContainerSize }}
        >
          <div
            className={styles.imageContainer}
            style={{ width: imageContainerWidth, height: imageContainerHeight }}
          >
            <Cropper
              image={initUrl}
              crop={crop}
              zoom={zoom}
              aspect={ratio}
              cropSize={cropSize}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
        <div className={styles.controls}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.REGULAR}
            icon="minus"
            onAction={zoomOut}
            dataTestId="Ws-Thumbnails-Crop-ZoomOut"
            key="minus"
            aria-label="zoom out"
            ref={buttonZoomOut}
          />
          <Slider
            value={zoom}
            min={zoomMin}
            max={zoomMax}
            step={step}
            aria-label="Zoom slider"
            onChange={(e, zoom) => {
              setZoom(zoom);
            }}
            classes={{
              root: styles.sliderRoot,
              colorPrimary: classes.colorPrimary,
              rail: styles.rail,
              track: styles.track,
              thumb: styles.thumb,
            }}
          />
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.REGULAR}
            icon="plus"
            onAction={zoomIn}
            dataTestId="Ws-Thumbnails-Crop-ZoomIn"
            key="plus"
            aria-label="zoom in"
          />
        </div>
        <Button
          type={BUTTON_TYPES.OUTLINED}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
          size={BUTTON_SIZES.REGULAR}
          label="View image library"
          onAction={() => onCancel()}
          dataTestId="Ws-Thumbnails-Button-ViewImageLibrary"
        />
      </div>
      <div className={styles.controlContainer}>
        <div className={styles.descriptionContainer}>
          {isSavingError && (
            <div className={styles.errorContainer}>
              <FormattedMessage
                defaultMessage="Sorry, there was a problem saving this cover image. Please try again."
                id="CxnJC2"
              />
            </div>
          )}
          <div className={styles.text}>
            <div>
              <FormattedMessage defaultMessage="Image alt text:" id="gmFPeV" />
            </div>
            This text helps screen-reading tools describe images to visually
            impaired readers. You can edit it to fit your lesson.
          </div>
          <textarea
            aria-label="input alt text here"
            rows="3"
            defaultValue={item.alt_description}
            onChange={(e) => {
              setUpdatedThumbnail({
                ...updatedThumbnail,
                alt: _.get(e, "target.value", item.alt_description),
              });
            }}
          />
          <div className={styles.creator}>
            Image by{" "}
            <a
              href={`${item.user.links.html}?utm_source=Hapara&utm_medium=referral`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.user.name}
            </a>{" "}
            on{" "}
            <a href={unsplashUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage defaultMessage="Unsplash" id="eTdcPx" />
            </a>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            dataTestId="Ws-BoardThumbnail-Button-CancelEditing"
            label="Cancel"
            size={BUTTON_SIZES.REGULAR}
            type={BUTTON_TYPES.SECONDARY}
            onAction={() => onClose()}
          />

          <Button
            dataTestId="Ws-BoardThumbnail-Button-SaveImage"
            label={isSavingThumbnailInProgress ? "Saving..." : "Save"}
            size={BUTTON_SIZES.REGULAR}
            onAction={() => {
              onSave(updatedThumbnail);
            }}
            isLoading={isSavingThumbnailInProgress}
            isDisabled={isSavingThumbnailInProgress}
          />
        </div>
      </div>
    </div>
  );
};

WorkspaceThumbnailEdit.propTypes = {
  item: THUMBNAIL_TYPE,
  isSavingThumbnailInProgress: PropTypes.bool,
  isSavingError: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(WorkspaceThumbnailEdit);
