import React, { useState, useEffect } from "react";
import { DialogModal } from "@hapara/ui/src/atomic/DialogModal";
import { Button } from "@hapara/ui/src/atomic/Button";
import { CopyButton } from "@hapara/ui/src/atomic/CopyButton";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox";
import { ClassInfoNetworkErrorAlert } from "../ClassInfoNetworkErrorAlert";
import { PasswordField } from "@hapara/ui/src/atomic/PasswordField";
import { useIntl } from "react-intl";

import styles from "./ClassInfoResetPasswordModal.module.scss";

type ClassInfoResetPasswordModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestPasswordReset: (forceReset: boolean, newPassword: string) => void;
  hasError?: boolean;
  errorMessage?: string;
  hasNetworkError?: boolean;
  isProcessing?: boolean;
  numberOfStudents: number;
};

const generateSeedPassword = () => {
  let tokens =
    "23456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWYZ!@#$%^&*()";
  let result = "";

  while (result.length < 8) {
    const index = Math.floor(Math.random() * tokens.length);
    result += tokens[index];
    tokens = tokens.slice(0, index) + tokens.slice(index + 1);
  }

  return result;
};

export const ClassInfoResetPasswordModal = ({
  isOpen,
  onRequestClose,
  onRequestPasswordReset,
  isProcessing,
  numberOfStudents,
  hasError,
  hasNetworkError,
  errorMessage,
}: ClassInfoResetPasswordModalProps) => {
  const intl = useIntl();
  useEffect(() => {
    if (isOpen) {
      setNewPassword(generateSeedPassword());
      setForcePasswordReset(true);
    }
  }, [isOpen]);

  const hasMultipleStudents = numberOfStudents > 1;
  const defaultHintText = intl.formatMessage({
    defaultMessage: "Must be at least 8 characters",
    id: "1M89u0",
  });

  // uncontrolled since the consumer will never pass in an existing password
  const [newPassword, setNewPassword] = useState("");
  const [forcePasswordReset, setForcePasswordReset] = useState(true);

  const trimmedNewPassword = newPassword.trim();
  const isSubmitDisabled = trimmedNewPassword.length < 8 || isProcessing;

  const handleRequestPasswordReset = () =>
    onRequestPasswordReset(forcePasswordReset, trimmedNewPassword);

  const handleCheckboxChange = () => setForcePasswordReset(!forcePasswordReset);

  return (
    <DialogModal
      className={styles.modal}
      dataTestIdPrefix="classinfo-reset-password"
      title={intl.formatMessage(
        {
          defaultMessage:
            "Reset password for {numberOfStudents} {numberOfStudents, plural, one {student} other {students}}",
          id: "SY7Ubs",
        },
        { numberOfStudents }
      )}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      actions={
        <>
          <Button
            type="secondary"
            dataTestId="classinfo-reset-password-Button-Cancel"
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onClick={onRequestClose}
            isDisabled={isProcessing}
          />

          <Button
            dataTestId="classinfo-reset-password-Button-Confirm"
            label={intl.formatMessage({
              defaultMessage: "Reset password",
              id: "Yy/yDL",
            })}
            onClick={handleRequestPasswordReset}
            isLoading={isProcessing}
            isDisabled={isSubmitDisabled}
          />
        </>
      }
    >
      <div className={styles.passwordCopyContainer}>
        <PasswordField
          className={styles.passwordField}
          label={intl.formatMessage({
            defaultMessage: "Password",
            id: "5sg7KC",
          })}
          hintText={hasError && errorMessage ? errorMessage : defaultHintText}
          value={newPassword}
          onChangeText={setNewPassword}
          dataTestId="classinfo-reset-password-PasswordField"
          hasError={hasError}
          isDisabled={isProcessing}
        />
        <CopyButton
          className={styles.copyButton}
          dataTestId="classinfo-reset-password-Button-Copy"
          value={trimmedNewPassword}
        />
      </div>

      <div className={styles.checkbox}>
        <Checkbox
          checked={forcePasswordReset}
          label={intl.formatMessage({
            defaultMessage:
              "Ask students to create a new password on their next login.",
            id: "Bm7vGZ",
          })}
          dataTestIdPrefix="classinfo-reset-password-Checkbox"
          onChange={handleCheckboxChange}
          disabled={isProcessing}
        />
      </div>

      {hasNetworkError && (
        <ClassInfoNetworkErrorAlert className={styles.networkError} />
      )}
    </DialogModal>
  );
};
