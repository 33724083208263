import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CloseTabModal.module.scss";
import { Modal } from "@hapara/ui/src/components/Modal";
import { Radio } from "@hapara/ui/src/atomic/Radio/Radio";
import { Dialog } from "@hapara/ui/src/components/Dialog";
import { updateGuideBrowsingSession } from "../../../state/highlights/sessions/actions";
import { updateNotificationSession } from "src/state/highlights/notification/actions";
import { getActiveGuideBrowsingSessionList } from "src/state/highlights/sessions/selectors";
import { getClassId } from "../../../state/shared/selectors";
import { getStudentsReducedParams } from "../../../state/highlights/students/selectors";
import { StudentBrowserTabType } from "./types";
import { useIntl } from "react-intl";
import { DialogActions } from "./DialogActions";
import { DialogBody } from "./DialogBody";
import { PageActions } from "@hapara/ui/src/components/PageActions";
import { pendoTrack } from "../../../utils";

type CloseTabModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  tab: StudentBrowserTabType;
  closeReasons: string[];
  studentDisplayName?: string;
  studentId?: string;
  onCloseTab: (reason: string) => void;
};

export const CloseTabModal = ({
  isOpen,
  onClose,
  tab,
  closeReasons,
  studentId,
  studentDisplayName,
  onCloseTab,
}: CloseTabModalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const sessions = useSelector(getActiveGuideBrowsingSessionList);
  const classId = useSelector(getClassId);
  const studentsReducedParams = useSelector(getStudentsReducedParams);
  const studentIds = studentsReducedParams?.map((student) => student.Id);
  const [modalView, setModalView] = useState<
    "close" | "block-class" | "block-student"
  >("close");
  const [duration, setDuration] = useState<number | null>(null);

  const activeClassFilterSession = sessions?.find(
    (session) => session.Recipient.Type === "class" && session.Type === "filter"
  );

  const activeOtherSession = sessions?.length > 0 && !activeClassFilterSession;
  const activeGroupSession = sessions?.find(
    (session) =>
      session.Recipient.Type === "groups" &&
      session.Recipient?.Students?.includes(studentId)
  );
  const activeIndividualSession = sessions?.find(
    (session) =>
      session.Recipient.Type === "students" &&
      session.Recipient?.Students?.includes(studentId)
  );

  const handleSave = async () => {
    if (modalView === "block-student") {
      if (activeIndividualSession) {
        const newSession = {
          ID: activeIndividualSession.ID,
          StudentIDS: [studentId],
          EndSessionKeepAllTabs: false,
          EndSessionRestoreOriginal: false,
          Links: [...activeIndividualSession.Links, tab.url],
        };
        pendoTrack("GBE - Update blocked sites for Student", {
          ...newSession,
          ClassURN: classId,
          Duration: `${duration} minutes`,
        });
        dispatch(updateGuideBrowsingSession({ newSession }));
      } else {
        const sessionDetails = {
          ClassURN: classId,
          Type: "filter",
          Duration: duration! * 60 * 1000,
          RecipientType: "students",
          Students: [studentId],
          Links: [tab.url],
        };
        pendoTrack("GBE - Block site for Student", {
          ...sessionDetails,
          ClassURN: classId,
          Duration: `${duration} minutes`,
        });
        dispatch(
          updateNotificationSession({
            sessionDetails: sessionDetails,
            sessionType: "filter",
          })
        );
      }
    } else if (modalView === "block-class") {
      if (activeClassFilterSession) {
        const newSession = {
          ID: activeClassFilterSession.ID,
          StudentIDS: studentIds,
          Links: [...activeClassFilterSession.Links, tab.url],
        };
        pendoTrack("GBE - Update blocked sites for Class", {
          ...newSession,
          ClassURN: classId,
          Duration: `${duration} minutes`,
        });
        dispatch(updateGuideBrowsingSession({ newSession }));
      } else {
        const sessionDetails = {
          ClassURN: classId,
          Type: "filter",
          Duration: duration! * 60 * 1000,
          RecipientType: "class",
          Students: studentIds,
          Links: [tab.url],
        };
        pendoTrack("GBE - Block site for Class", {
          ...sessionDetails,
          Duration: `${duration} minutes`,
        });
        dispatch(
          updateNotificationSession({
            sessionDetails: sessionDetails,
            sessionType: "filter",
          })
        );
      }
    }
    onClose();
  };

  const studentName =
    studentDisplayName ??
    intl.formatMessage({ defaultMessage: "student", id: "Mq3l58" });

  return (
    <Modal
      isNarrow
      isOpen={!!isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      usePortal
    >
      <Dialog
        heading={intl.formatMessage({ defaultMessage: "Close", id: "rbrahO" })}
        onRequestClose={onClose}
        className={styles.closeTabDialog}
        dataTestIdPrefix={`${modalView}-tab`}
        actions={
          modalView === "block-student" || modalView === "block-class" ? (
            <PageActions>
              <DialogActions
                modalView={modalView}
                onClose={onClose}
                onSave={handleSave}
                activeClassFilterSession={!!activeClassFilterSession}
                duration={duration}
                activeOtherSession={activeOtherSession}
              />
            </PageActions>
          ) : undefined
        }
      >
        <div className={styles.radioContainer}>
          <Radio
            name="close-tab"
            label={intl.formatMessage({
              defaultMessage: "Close for the student",
              id: "RKExzT",
            })}
            onChange={() => setModalView("close")}
            checked={modalView === "close"}
            value="close"
            dataTestIdPrefix="close-tab-radio-input"
          />
          <Radio
            name="block-tab-student"
            label={intl.formatMessage({
              defaultMessage: "Block for this student",
              id: "AW9Frv",
            })}
            onChange={() => setModalView("block-student")}
            checked={modalView === "block-student"}
            value="block-student"
            dataTestIdPrefix="block-site-for-student-radio-input"
          />
          <Radio
            name="block-tab"
            label={intl.formatMessage({
              defaultMessage: "Block for the class",
              id: "Ia75cs",
            })}
            onChange={() => setModalView("block-class")}
            checked={modalView === "block-class"}
            value="block-class"
            dataTestIdPrefix="block-site-class-radio-input"
          />
        </div>
        <div className={styles.closeContainer}>
          <DialogBody
            modalView={modalView}
            tab={tab}
            duration={duration}
            activeClassFilterSession={!!activeClassFilterSession}
            activeGroupSession={activeGroupSession}
            activeOtherSession={activeOtherSession}
            activeIndividualSession={!!activeIndividualSession}
            onDurationChange={setDuration}
            studentName={studentName}
            closeReasons={closeReasons}
            onCloseTab={onCloseTab}
          />
        </div>
      </Dialog>
    </Modal>
  );
};
