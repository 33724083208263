import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Input from "@hapara/ui/src/atomic/Input/Input";
import styles from "./UploadForm.module.scss";

const INPUT_MAX_LENGTH = 150;

const UploadFormTitle = ({ id, dataTestIdPrefix, title, onTitleChange }) => {
  return (
    <div className={styles.fieldBlock}>
      <label htmlFor={id} className={styles.fieldLabel}>
        Title
        <span className={styles.required}>
          <FormattedMessage defaultMessage="(required)" id="VG94fP" />
        </span>
      </label>
      <Input
        type="text"
        name={id}
        id={id}
        placeholder="Give this resource a name"
        data-test-id={`${dataTestIdPrefix}-TitleInput`}
        aria-required={true}
        value={title}
        onChange={(e) => onTitleChange(_.get(e, "target.value", ""))}
        maxLength={INPUT_MAX_LENGTH}
        showLimitWarning={true}
      />
    </div>
  );
};

UploadFormTitle.propTypes = {
  title: PropTypes.string,
  onTitleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default UploadFormTitle;
