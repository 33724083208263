import { useRef, useEffect } from "react";

/**
 * Returns a previous value (e.g. state or prop) from a previous render.
 * ```
 * function Counter() {
 *   const [count, setCount] = useState(0);
 *   const prevCount = usePrevious(count);
 *   return (
 *    <div>
 *      <p>Now: {count}, Before: {prevCount}</p>
 *      <button onClick={() => setCount(count + 1)}>Increase</button>
 *    </div>
 *   }
 * );
 * ```
 */
export const usePrevious = <V,>(value: V) => {
  const ref = useRef<V>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
