import React from "react";
import { useSelector } from "react-redux";
import {
  canAddManualClass,
  canDoGoogleClassroomSync,
  getEditedClass,
  getIsAddClassDialogOpened,
  getGoogleClassroomSyncDialogOpened,
  getIsEditColourDialogOpened,
} from "../../../../state/dashboard/manage-classes/selectors";
import ManageClassesSubMenu from "../ManageClassesSubMenu/ManageClassesSubMenu";
import AddClassModal from "../AddClassModal/AddClassModal";
import EditClassModal from "../EditClassModal/EditClassModal";
import SyncGoogleClassroomsModal from "../SyncGoogleClassroomsModal/SyncGoogleClassroomsModal";

const ModalsAndPanels = () => {
  const addClassModelOpened = useSelector(getIsAddClassDialogOpened);
  const gcSyncModalOpened = useSelector(getGoogleClassroomSyncDialogOpened);
  const canAddManually = useSelector(canAddManualClass);
  const canAddGC = useSelector(canDoGoogleClassroomSync);
  const editColorModalOpened = useSelector(getIsEditColourDialogOpened);
  const editedClass = useSelector(getEditedClass);

  return (
    <>
      <ManageClassesSubMenu
        canAddManually={canAddManually}
        canAddGC={canAddGC}
      />
      {addClassModelOpened && <AddClassModal />}
      {gcSyncModalOpened && <SyncGoogleClassroomsModal />}
      {editColorModalOpened && editedClass && <EditClassModal />}
    </>
  );
};

export default ModalsAndPanels;
