import React from "react";
import classnames from "classnames";

import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./LabledDropButton.module.scss";

interface LabeledDropButtonTypes {
  buttonLabel?: string; //CONTENT: Static button label (first text element on button)
  buttonContent?: string; //CONTENT: Dynamic content from parent (second text element on button)
  customStyleName: string; //STYLING: Adds additional styling to the button (customise here if needed)
  ariaControls?: string; //CONTENT: A11y -> Links control button to menu element
  ariaLabel?: string; //CONTENT: A11y -> defaults to 'Drop menu button' when not supplied
  completeDataTestID: string; //CONTENT: Complete data-test-id compiled in parent
  dropMenuOpen?: boolean; // FUNCTIONAL: Current menu status
  menuActionCB?: () => void; // FUNCTIONAL: Menu control callback from parent
}

const LabeledDropButton = ({
  buttonLabel = "Label:",
  buttonContent = "Contents",
  customStyleName,
  ariaControls = "drop_menu",
  ariaLabel = "Menu Button",
  completeDataTestID,
  dropMenuOpen,
  menuActionCB,
}: LabeledDropButtonTypes) => {
  return (
    <button
      onClick={menuActionCB}
      role="combobox"
      aria-label={ariaLabel}
      aria-haspopup="listbox"
      aria-expanded={dropMenuOpen}
      aria-controls={ariaControls}
      data-test-id={completeDataTestID}
      className={classnames(styles.dropMenuButton, {
        [styles[`${customStyleName}`]]: true,
      })}
    >
      <div
        className={classnames(styles.buttonContentLabel, {
          [styles[`${customStyleName}`]]: true,
        })}
      >
        {buttonLabel}
      </div>

      <div
        className={classnames(styles.buttonContentText, {
          [styles[`${customStyleName}`]]: true,
        })}
      >
        {buttonContent}
      </div>

      <HapReactIcon
        svg="arrow-carvet-down"
        height={16}
        width={16}
        alt={dropMenuOpen ? "Menu open" : "Menu closed"}
        className={classnames({
          [styles.rotateClose]: dropMenuOpen,
          [styles.rotateOpen]: !dropMenuOpen,
        })}
      />
    </button>
  );
};

export default LabeledDropButton;
