import { FormattedMessage } from "react-intl";
import React, { useMemo, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./SortOrderFilter.module.scss";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { SORT_TYPE } from "../../../../state/shared/types";
import { pushQueryToHistory } from "../../../../state/router/navigation";
import { DROPDOWN_CONTENT_POSITION_TYPES } from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { isInsideLibraryCollections } from "../../../../state/library/collections/selectors";
import { getWindowSize } from "../../../../state/app/selectors";
import {
  WINDOW_SIZE_TYPES,
  WINDOW_SIZE_TYPE_LIST,
} from "../../../../state/app/types";
import {
  isInsideMyResources,
  isStarredFiltered,
} from "../../../../state/library/myResources/selectors";

export const SortOrderFilter = ({
  dataTestIdPrefix,
  selectedSortOrder,
  windowSize,
  isInsideLibraryCollections,
  isMyResourcesPage,
  isStarredActive,
}) => {
  const isClassOrAllClasses = isMyResourcesPage && !isStarredActive;

  let filterOptionsList = [];

  if (!isInsideLibraryCollections) {
    filterOptionsList.push({
      id: 1,
      name: isClassOrAllClasses ? "Last shared" : "Last added",
      type: SORT_TYPE.LAST_ADDED.type,
      dataTestId: isClassOrAllClasses ? "Last shared" : "Last added",
    });
  }

  filterOptionsList.push(
    {
      id: 2,
      name: "Title A to Z",
      type: SORT_TYPE.TITLE_AZ.type,
      dataTestId: "TitleAZ",
    },
    {
      id: 3,
      name: "Title Z to A",
      type: SORT_TYPE.TITLE_ZA.type,
      dataTestId: "TitleZA",
    }
  );

  const defaultSelection = isInsideLibraryCollections
    ? SORT_TYPE.TITLE_AZ.type
    : SORT_TYPE.LAST_ADDED.type;

  const triggerRef = useRef();
  const selectedOption =
    _.find(filterOptionsList, { type: selectedSortOrder }) ||
    _.find(filterOptionsList, { type: defaultSelection });

  const dropdownPosition =
    windowSize >= WINDOW_SIZE_TYPES.S
      ? DROPDOWN_CONTENT_POSITION_TYPES.RIGHT
      : DROPDOWN_CONTENT_POSITION_TYPES.LEFT;

  const triggerComponent = useMemo(
    () => (props) =>
      (
        <DropdownTrigger
          widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
          className={classnames(styles.dropdownTrigger, {
            [styles.notDefault]: selectedOption.type !== defaultSelection,
          })}
          data-test-id={`${dataTestIdPrefix}Dropdown-Trigger`}
          label={selectedOption.name}
          ref={triggerRef}
          {...props}
        />
      ),
    [
      dataTestIdPrefix,
      defaultSelection,
      selectedOption.name,
      selectedOption.type,
    ]
  );

  return (
    <div className={styles.root} data-test-id={dataTestIdPrefix}>
      <span className={styles.label}>
        <FormattedMessage defaultMessage="Sort by:" id="40bnK/" />
      </span>
      <Dropdown
        widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
        positionType={dropdownPosition}
        triggerComponent={triggerComponent}
        itemComponentList={filterOptionsList.map(
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.type === selectedOption.type;
              return (
                <button
                  onClick={() => {
                    pushQueryToHistory({
                      sortOrder: item.type,
                    });
                    onClick();
                    _.delay(() => triggerRef.current.focus(), 100);
                  }}
                  className={classnames(className, styles.dropdownItem, {
                    [styles.selected]: isSelected,
                  })}
                  data-test-id={`${dataTestIdPrefix}Dropdown-Button-${item.dataTestId}`}
                  aria-label={`${item.name}${isSelected ? ", selected" : ""}`}
                  {...rest}
                >
                  {item.name}
                </button>
              );
            }
        )}
      />
    </div>
  );
};

SortOrderFilter.propTypes = {
  dataTestIdPrefix: PropTypes.string,
  selectedSortOrder: PropTypes.string,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
  isMyResourcesPage: PropTypes.bool.isRequired,
  isInsideLibraryCollections: PropTypes.bool.isRequired,
  isStarredActive: PropTypes.bool,
};

export default connect(
  (state) => ({
    windowSize: getWindowSize(state),
    isInsideLibraryCollections: isInsideLibraryCollections(state),
    isMyResourcesPage: isInsideMyResources(state),
    isStarredActive: isStarredFiltered(state),
  }),
  (dispatch) => ({})
)(SortOrderFilter);
