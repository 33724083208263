import React, { useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@hapara/ui/src/atomic/Button";
import { EllipsisIcon } from "@hapara/ui/src/icons/EllipsisIcon";
import { EditIcon } from "@hapara/ui/src/icons/EditIcon";
import { TrashIcon } from "@hapara/ui/src/icons/TrashIcon";
import { HistoryIcon } from "@hapara/ui/src/icons/HistoryIcon";
import { Popover } from "@hapara/ui/src/atomic/Popover";
import styles from "./GuidedBrowsingTable.module.scss";

interface GuidedBrowsingActionsProps {
  onStart: () => void;
  onEdit: () => void;
  onSchedule: () => void;
  onDelete: () => void;
  sessionType: "filter" | "focus";
}

export const GuidedBrowsingActions: React.FC<GuidedBrowsingActionsProps> = ({
  onStart,
  onDelete,
  onEdit,
  onSchedule,
  sessionType,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const dropdownItemProps = [
    { label: "Edit", icon: <EditIcon />, onClick: onEdit },
    { label: "Delete", icon: <TrashIcon />, onClick: onDelete },
  ];

  const dropdownItems = dropdownItemProps.map((item) => {
    return (
      <Button
        size="small"
        className={styles.dropdownItem}
        dataTestId={`dropdown-item-${item.label}-template`}
        key={item.label}
        type="tertiary"
        label={item.label}
        iconComponent={item.icon}
        isFullWidth
        onAction={() => {
          item.onClick();
          setIsOpen(false);
        }}
      />
    );
  });

  return (
    <div className={styles.actionsContainer}>
      <Button
        className={styles.actionButton}
        dataTestId={`start-guided-browsing-${sessionType}-template`}
        size="small"
        type="outlined"
        outlineType="solid"
        aria-label={intl.formatMessage({
          defaultMessage: "Start guide browsing template",
          id: "+uX4ts",
        })}
        label={<FormattedMessage defaultMessage="Start" id="mOFG3K" />}
        onClick={onStart}
      />
      <Button
        className={styles.actionButton}
        dataTestId={`schedule-guided-browsing-${sessionType}-template`}
        size="small"
        type="outlined"
        outlineType="solid"
        aria-label={intl.formatMessage({
          defaultMessage: "Schedule guide browsing template",
          id: "QyGNZD",
        })}
        label={<FormattedMessage defaultMessage="Schedule" id="hGQqkW" />}
        onClick={onSchedule}
      />
      <Popover
        placement="bottom-end"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        anchor={
          <Button
            aria-expanded={isOpen}
            dataTestId="more-options-trigger-button"
            onClick={() => setIsOpen(!isOpen)}
            className={styles.moreOptionsButton}
            ref={triggerRef}
            size="xsmall"
            iconComponent={<EllipsisIcon />}
            type="tertiary"
            aria-label={intl.formatMessage({
              defaultMessage: "More options",
              id: "IzCVhG",
            })}
          />
        }
      >
        <div className={styles.popover}>{dropdownItems}</div>
      </Popover>
    </div>
  );
};
