import React from "react";

//TYPES | CONSTS
import { ClassInfoState } from "../../../../hooks/classInfo/useClassInfo";
import { MAIN_CONTENT_ID } from "../../../../consts";

//COMPONENTS
import { ClassInfoTable } from "../ClassInfoTable";

import { ClassInfoHeader } from "../ClassInfoHeader";
import {
  ClassInfoInputModalAddTeacher,
  ClassInfoInputModalAddStudents,
  ClassInfoInputModalRenameClass,
} from "../ClassInfoInputModal/index";
import { ClassInfoResetPasswordModal } from "../ClassInfoResetPasswordModal/index";
import { ClassInfoDialogModalRemoveTeacher } from "../ClassInfoDialogModal/ClassInfoDialogModalRemoveTeacher";
import { ClassInfoDialogModalRemoveStudent } from "../ClassInfoDialogModal/ClassInfoDialogModalRemoveStudent";
import { ClassInfoDialogModalRemoveClass } from "../ClassInfoDialogModal/ClassInfoDialogModalRemoveClass";
import { ClassInfoDialogModalEditClass } from "../ClassInfoDialogModal/ClassInfoDialogModalEditClass";
import { ClassInfoDialogModalResetClass } from "../ClassInfoDialogModal/ClassInfoDialogModalResetFolders";

//UTILS | OTHER
import styles from "./ClassInfoContainer.module.scss";

type ClassInfoContainerProps = {
  dataTestIdPrefix: string;
  data: ClassInfoState["data"];
  operations: ClassInfoState["operations"];
  addStudents: ClassInfoState["addStudents"];
  removeStudents: ClassInfoState["removeStudents"];
  addTeacher: ClassInfoState["addTeacher"];
  removeTeachers: ClassInfoState["removeTeachers"];
  renameClass: ClassInfoState["renameClass"];
  updatePasswords: ClassInfoState["updatePasswords"];
  syncClass: ClassInfoState["syncClass"];
  removeClass: ClassInfoState["removeClass"];
  resetClass: ClassInfoState["resetClass"];
};

export const ClassInfoContainer: React.FC<ClassInfoContainerProps> = ({
  data,
  operations,
  addStudents,
  removeStudents,
  updatePasswords,
  addTeacher,
  removeTeachers,
  renameClass,
  syncClass,
  dataTestIdPrefix,
  removeClass,
  resetClass,
}) => {
  const {
    teachers,
    students,
    classDisplayName,
    classIdentifier,
    emailLink,
    activeModal,
    selectedTeachers,
    selectedStudents,
    permissions,
    isDataLoadedClass,
    isGoogleClassroomClass,
    isSelfRosteringClass,
    googleDriveFolderLink,
  } = data;
  const {
    modalControl,
    modalOperations,
    setSelectedStudents,
    setSelectedTeachers,
  } = operations;

  return (
    <main className={styles.container} id={MAIN_CONTENT_ID}>
      <ClassInfoHeader
        onSyncClass={syncClass.requestSyncClass}
        emailLink={emailLink}
        onRemoveClass={modalControl.openRemoveClassModal}
        onEditDisplayName={modalControl.openEditDisplayNameModal}
        onResetFolders={modalOperations.requestResetFolders}
        onOpenClassFolder={undefined}
        canEditDisplayName={permissions?.editDisplayName}
        canRemoveClass={permissions?.classRemove}
        classDisplayName={classDisplayName}
        canResetClass={permissions?.classReset}
        classDisplayIdentifier={classIdentifier}
        dataTestIdPrefix={dataTestIdPrefix}
        displaySyncInProgress={syncClass.displaySyncInProgress}
        isDataLoadedClass={isDataLoadedClass}
        isGoogleClassroomClass={isGoogleClassroomClass}
        isSelfRosteringClass={isSelfRosteringClass}
        googleDriveFolderLink={googleDriveFolderLink}
      />

      <ClassInfoTable
        onAdd={modalControl.openAddTeacherModal}
        onReset={modalControl.openResetPasswordModal}
        onRemove={modalControl.openRemoveTeacherModal}
        selectedPeople={selectedTeachers}
        setSelectedPeople={setSelectedTeachers}
        variant="teacher"
        people={teachers}
        dataTestIdPrefix={dataTestIdPrefix}
        permissions={permissions!}
      />
      <ClassInfoTable
        onAdd={modalControl.openAddStudentModal}
        onReset={modalControl.openResetPasswordModal}
        onRemove={modalControl.openRemoveStudentModal}
        selectedPeople={selectedStudents}
        setSelectedPeople={setSelectedStudents}
        variant="student"
        people={students}
        permissions={permissions}
        dataTestIdPrefix={dataTestIdPrefix}
      />

      {/* MODALS & DIALOGS */}
      <ClassInfoInputModalAddTeacher
        isOpen={activeModal === "addTeacher"}
        isInProgress={addTeacher.isLoading}
        onAddTeacher={addTeacher.handleAddTeacher}
        onRequestClose={modalControl.closeModal}
        hasError={addTeacher.validationError.hasValidationError}
        errorMessage={addTeacher.validationError.validationErrorMessage}
        showNetworkErrorBlock={addTeacher.showNetworkErrorBlock}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoInputModalAddStudents
        isOpen={activeModal === "addStudent"}
        isInProgress={addStudents.isLoading}
        onAddStudent={addStudents.handleAddStudents}
        onRequestClose={modalControl.closeModal}
        unsuccessfulEmails={addStudents.unsuccessfulEmails}
        hasNetworkError={addStudents.isError}
        errorMessage={addStudents.addStudentValidationErrorMessage}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoInputModalRenameClass
        isOpen={activeModal === "renameClass"}
        isInProgress={renameClass.isLoading}
        onRenameClass={renameClass.handleRenameClass}
        onRequestClose={modalControl.closeModal}
        hasNetworkError={renameClass.isError}
        dataTestIdPrefix={dataTestIdPrefix}
        classDisplayName={classDisplayName}
      />
      <ClassInfoResetPasswordModal
        isOpen={activeModal === "resetPassword"}
        isProcessing={updatePasswords.isLoading}
        onRequestClose={modalControl.closeModal}
        onRequestPasswordReset={updatePasswords.handleUpdatePasswords}
        numberOfStudents={selectedStudents.length}
        hasNetworkError={updatePasswords.isError}
      />
      <ClassInfoDialogModalRemoveTeacher
        isOpen={activeModal === "removeTeacher"}
        hasNetworkError={removeTeachers.isError}
        isProcessing={removeTeachers.isLoading}
        modalAction={removeTeachers.handleRemoveTeachers}
        selectedCount={selectedTeachers.length}
        onRequestClose={modalControl.closeModal}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoDialogModalRemoveStudent
        isProcessing={removeStudents.isLoading}
        isOpen={activeModal === "removeStudent"}
        hasNetworkError={removeStudents.isError}
        modalAction={removeStudents.handleRemoveStudents}
        selectedCount={selectedStudents.length}
        onRequestClose={modalControl.closeModal}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoDialogModalRemoveClass
        isOpen={activeModal === "removeClass"}
        hasNetworkError={removeClass.isError}
        modalAction={removeClass.handleRemoveClass}
        onRequestClose={modalControl.closeModal}
        isProcessing={removeClass.isLoading}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoDialogModalEditClass
        isOpen={activeModal === "editClass"}
        modalAction={modalOperations.requestEditGoogleClass}
        onRequestClose={modalControl.closeModal}
        dataTestIdPrefix={dataTestIdPrefix}
      />
      <ClassInfoDialogModalResetClass
        isOpen={activeModal === "resetClass"}
        hasNetworkError={resetClass.hasError}
        modalAction={resetClass.handleResetClass}
        onRequestClose={modalControl.closeModal}
        isProcessing={resetClass.isLoading}
        dataTestIdPrefix={dataTestIdPrefix}
      />
    </main>
  );
};
