import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./StudentTileExpandModal.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import {
  currentScreenExpandModalClearInstances,
  currentScreenExpandModalSetEmptySnapshot,
  hideCurrentScreenExpandModal,
} from "../../../../state/highlights/currentScreenExpandModal/action";
import { getCurrentScreenExpandModalInfo } from "../../../../state/highlights/currentScreenExpandModal/selector";
import { getStudents } from "../../../../state/highlights/students/selectors";
import _ from "lodash";
import { pendoTrack } from "../../../../utils";
import {
  isIgnoredInTabsUrl,
  isRestrictedScreenshotUrl,
} from "@hapara/ui/src/components/utils";
import {
  CURRENT_SCREEN_EXPAND_MODAL_TYPE,
  SCREEN_STATES,
  TABS_STATES,
} from "../../../../state/highlights/currentScreenExpandModal/types";
import { STUDENT_STATUS_TYPES } from "../../../../state/highlights/students/types";
import { getIsOutsideSchoolHours } from "../../../../state/highlights/config/selectors";
import StudentTileExpandModalScreens from "./StudentTileExpandModalScreens";
import StudentTileExpandModalTabs from "./StudentTileExpandModalTabs";
import moment from "moment";

const StudentTileExpandModal = ({
  expandModalInfo,
  onClose,
  students,
  setEmptySnapshot,
  clearInstances,
  isOutsideSchoolHours,
}) => {
  const dataTestPrefix = "hl-StudentTileExpandModal";
  const [timeOpened, setTimeOpened] = useState(moment());

  const handleClose = () => {
    const modalOpenedInSeconds = Math.round(
      moment.duration(moment().diff(timeOpened)).asSeconds()
    );
    setTimeOpened(null);
    pendoTrack("Student tile modal opened time", {
      modalOpenedInSeconds: modalOpenedInSeconds,
    });
    onClose();
  };

  const { studentDisplayName, studentId, studentEmail, studentLastName } =
    expandModalInfo;

  const [screenState, setScreenState] = useState(SCREEN_STATES.LOADING);
  const [tabsState, setTabsState] = useState(TABS_STATES.ONLINE);

  const student = students[studentId];

  const image = _.get(
    expandModalInfo,
    `instances.${student.currentInstance}.image`
  );

  const tabs = _.get(student, `instances.${student.currentInstance}.tabs`);
  const instanceId = _.get(
    student,
    `instances.${student.currentInstance}.instanceId`
  );
  const filteredTabs = _.filter(tabs, (t) => !isIgnoredInTabsUrl(t.url));

  const isOnline = _.get(
    student,
    `instances.${student.currentInstance}.isOnline`,
    false
  );
  const isOffline = !_.get(
    student,
    `instances.${student.currentInstance}.isOnline`,
    false
  );

  const studentStatus = _.get(
    student,
    `instances.${student.currentInstance}.status`
  );
  const isTeacherAccount =
    studentStatus === STUDENT_STATUS_TYPES.TEACHER_LOGGED_IN;

  const isSchoolFinished =
    isOutsideSchoolHours || studentStatus === STUDENT_STATUS_TYPES.INVALID_TIME;

  const isOutsideSchoolNetwork =
    studentStatus === STUDENT_STATUS_TYPES.IP_NOT_IN_RANGE;

  const activeTab = _.find(tabs, (t) => t.active);
  const activeTabUrl = _.get(activeTab, "url", "");
  const isRestricted = isRestrictedScreenshotUrl(activeTabUrl);

  const pauseSessionDetails = _.get(
    student,
    `instances.${student.currentInstance}.lock.pause`,
    null
  );
  const isPaused = _.get(pauseSessionDetails, "active", false);

  const instances = _.get(student, "instances", {}) || {};
  const instanceList = _.filter(_.values(instances), (item) => item.isOnline);
  const lastSelectedInstance = _.get(student, "lastSelectedInstance");

  const isNotBrowsing =
    filteredTabs && !filteredTabs.length && instanceList.length < 2;
  const isMultipleDeviceNotBrowsing =
    filteredTabs && !filteredTabs.length && instanceList.length >= 2;
  const isMultipleDeviceState =
    instanceList.length > 1 && !lastSelectedInstance;

  const isEmptySnapshotState =
    isNotBrowsing ||
    isRestricted ||
    isTeacherAccount ||
    isPaused ||
    isOutsideSchoolNetwork ||
    isSchoolFinished;

  useEffect(() => {
    if (isSchoolFinished) {
      setScreenState(SCREEN_STATES.UNAVAILABLE);
      setTabsState(TABS_STATES.OUTSIDE_SCHOOL_HOURS);
    } else if (isOutsideSchoolNetwork) {
      setScreenState(SCREEN_STATES.UNAVAILABLE);
      setTabsState(TABS_STATES.OUTSIDE_SCHOOL_NETWORK);
    } else if (isOnline) {
      setScreenState(SCREEN_STATES.ONLINE);
      setTabsState(TABS_STATES.ONLINE);

      if (isNotBrowsing) {
        setScreenState(SCREEN_STATES.UNAVAILABLE);
        setTabsState(
          instanceId ? TABS_STATES.NOT_BROWSING : TABS_STATES.LOADING
        );
      }

      if (isRestricted) {
        setScreenState(SCREEN_STATES.RESTRICTED);
      }

      if (isMultipleDeviceState || isMultipleDeviceNotBrowsing) {
        setScreenState(SCREEN_STATES.MULTIPLE_DEVICES);
        setTabsState(TABS_STATES.MULTIPLE_DEVICES);
      }

      if (isTeacherAccount) {
        setScreenState(SCREEN_STATES.UNAVAILABLE);
        setTabsState(TABS_STATES.TEACHER_ACCOUNT);
      }

      if (isPaused) {
        setScreenState(SCREEN_STATES.PAUSED);
        setTabsState(TABS_STATES.PAUSED);
      }
    } else if (isOffline) {
      setScreenState(SCREEN_STATES.UNAVAILABLE);
      setTabsState(TABS_STATES.OFFLINE);
    } else {
      setScreenState(SCREEN_STATES.LOADING);
    }
  }, [
    image,
    isOnline,
    isNotBrowsing,
    isTeacherAccount,
    isRestricted,
    isPaused,
    isOutsideSchoolNetwork,
    isSchoolFinished,
    instanceId,
    isOffline,
    isMultipleDeviceState,
    isMultipleDeviceNotBrowsing,
  ]);

  useEffect(() => {
    if (isEmptySnapshotState && instanceId) {
      setEmptySnapshot({
        instanceId,
      });
    }

    if (isOffline) {
      clearInstances();
    }
  }, [
    instanceId,
    isEmptySnapshotState,
    isOffline,
    clearInstances,
    setEmptySnapshot,
  ]);

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={handleClose}
      title={studentDisplayName}
      dataTestPrefix={dataTestPrefix}
      classNameHeader={styles.modalHeader}
      className={styles.modal}
    >
      <div className={styles.root}>
        <StudentTileExpandModalScreens
          screenState={screenState}
          instanceId={instanceId}
          instanceList={instanceList}
          studentDisplayName={studentDisplayName}
          lastSelectedInstance={lastSelectedInstance}
          studentId={studentId}
          isMultipleDeviceNotBrowsing={isMultipleDeviceNotBrowsing}
          dataTestPrefix={`${dataTestPrefix}-Screens`}
        />
        <StudentTileExpandModalTabs
          tabsState={tabsState}
          filteredTabs={filteredTabs}
          studentId={studentId}
          studentEmail={studentEmail}
          studentLastName={studentLastName}
          instanceList={instanceList}
          studentDisplayName={studentDisplayName}
          lastSelectedInstance={lastSelectedInstance}
          instanceId={instanceId}
          dataTestPrefix={`${dataTestPrefix}-Tabs`}
        />
      </div>
    </ModalDeprecated>
  );
};

StudentTileExpandModal.propTypes = {
  expandModalInfo: CURRENT_SCREEN_EXPAND_MODAL_TYPE,
  onClose: PropTypes.func.isRequired,
  students: PropTypes.object,
  setEmptySnapshot: PropTypes.func.isRequired,
  clearInstances: PropTypes.func.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    expandModalInfo: getCurrentScreenExpandModalInfo(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
    students: getStudents(state),
  }),
  (dispatch) => ({
    onClose: () => dispatch(hideCurrentScreenExpandModal()),
    setEmptySnapshot: (payload) =>
      dispatch(currentScreenExpandModalSetEmptySnapshot(payload)),
    clearInstances: () => dispatch(currentScreenExpandModalClearInstances()),
  })
)(StudentTileExpandModal);
