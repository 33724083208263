import axios from "axios";
import _ from "lodash";
import { isProduction } from "../components/utils";
import { getCurrentAuthHeader } from "./authHeader";
import {
  getToken,
  postSendEvent,
  getErrorStringFromErrorOrResponse,
  initJwtHandling,
} from "./jwtHandler";

const API_URL = isProduction()
  ? "https://api.hapara.com"
  : "https://api-test.hapara.com";

const getErrorEvent = (error) => {
  return {
    name: "fe-error",
    error_message: getErrorStringFromErrorOrResponse(error),
    response_status:
      _.get(error, "responseStatus") || _.get(error, "response.status"),
    api_path: _.get(error, "apiPath"),
  };
};

const getJWTRequestBody = (selectedClassId) => {
  return {
    frontend: `${process.env.REACT_APP_BUILD_NUMBER}`,
    class: selectedClassId,
  };
};

export async function getStudents(classId) {
  initJwtHandling(getJWTRequestBody(classId));
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.get(
      `${API_URL}/admin/config/students?classId=${classId}`,
      config
    );
    const students = res.data;
    return students.map((s) => ({
      FirstName: s.FirstName,
      LastName: s.LastName,
      id: s.ID,
      email: s.PrimaryEmail,
    }));
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/admin/config/students",
      component: "smartshare",
    });
    throw e;
  }
}

export async function getUserGroups(classId) {
  initJwtHandling(getJWTRequestBody(classId));
  let pugsUrl = "https://pugs.teacherdashboard.com/v2";
  if (API_URL.indexOf("-test") > 0) {
    pugsUrl = "https://pugs-test.teacherdashboard.com/v2";
  }
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const students = await getStudents(classId);
    const res = await axios.get(
      `${pugsUrl}/pugs/dashboard/common/usergroups/${classId}/Dashboard`,
      config
    );
    const groups = res.data.groups_list.map((g) => {
      const studentList = _.filter(
        g.Participants,
        (p) => !!_.find(students, (s) => s.email === p)
      ).map((p) => _.find(students, (s) => s.email === p));
      return {
        name: g.DisplayName,
        id: g.URN,
        color: g.Colour,
        student_emails: g.Participants,
        students: studentList,
      };
    });
    return {
      groups,
      viewType: _.get(res.data, "view_type"),
    };
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/pugs/dashboard/highlights/usergroups",
      component: "smartshare",
    });
    throw e;
  }
}

export async function getMyClasses(classId) {
  initJwtHandling(getJWTRequestBody(classId));
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.get(
      `${API_URL}/admin/config/classes?classId=${classId}`,
      config
    );
    const classes = res.data;
    if (classes.length === 0) {
      throw { message: "no-classes-smartshare" };
    }
    //also, run data-sync for each class , so we know all the integrity data is synced
    //TODO Remove after HUGS re-write
    classes.forEach(async (cl) => {
      try {
        await axios.post(
          `${API_URL}/tasks/teacher/active`,
          {
            Name: "data-sync-class",
            Context: {
              class: cl.ID,
            },
          },
          config
        );
      } catch (e) {
        postSendEvent({
          ...getErrorEvent(e),
          api_path: "/tasks/teacher/active",
          component: "smartshare",
        });
      }
    });
    return classes.map((c) => {
      return {
        id: c.ID,
        name: c.Name,
        subject_folders: c.SubjectFolders,
        isGoogleClassroom: c.DataSource === "GoogleClassroom",
      };
    });
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/admin/config/classes",
      component: "smartshare",
    });
    throw e;
  }
}

export const SMARTSHARE_STATUS = {
  SHARE_PROGRESS: "share-inprogress",
  SHARE_COMPLETED: "share-completed",
  UNSHARE_SCHEDULED: "undo-scheduled",
  UNSHARE_PROGRESS: "undo-inprogress",
  UNSHARE_COMPLETED: "undo-complete",
};

export const SMARTSHARE_ITEM_STATUS = {
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  ERROR: "error",
};

const UNDO_TYPE = "undo";

const SERVER_ITEM_COMPLETED = "Completed";
const SERVER_ITEM_SENT = "Sent";

export async function getRecentShares(classId) {
  initJwtHandling(getJWTRequestBody(classId));
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.get(
      `${API_URL}/smartshare-service/teacher/list`,
      config
    );
    const shares = res.data;
    const items = shares.map((s, ind) => {
      let process = {};
      if (s.Type === UNDO_TYPE) {
        process.status =
          s.UndoStatus === SERVER_ITEM_COMPLETED
            ? SMARTSHARE_STATUS.UNSHARE_COMPLETED
            : s.UndoStatus === SERVER_ITEM_SENT
            ? SMARTSHARE_STATUS.UNSHARE_SCHEDULED
            : SMARTSHARE_STATUS.UNSHARE_PROGRESS;
      } else {
        process.status =
          s.ShareStatus === SERVER_ITEM_COMPLETED
            ? SMARTSHARE_STATUS.SHARE_COMPLETED
            : SMARTSHARE_STATUS.SHARE_PROGRESS;
      }
      process.fullProgress = s.FullProgress;
      process.currentProgress = s.CurrentProgress;
      process.documents = s.Documents.map((d, ind) => {
        return {
          DocumentID: d.Docid,
          DocumentTitle: d.Title,
          MimeType: d.MimeType,
          status: s.Type,
          Students: s.Students.map((st) => {
            const student = {
              Student: st.Student.Name || st.Student.Email,
              Status: SMARTSHARE_ITEM_STATUS.COMPLETED,
            };
            if (s.ShareStatus === SERVER_ITEM_COMPLETED) {
              const errors = s.Errors || {};
              const docErrors = errors[`doc-${ind}`];
              if (docErrors) {
                const stError = _.find(
                  docErrors,
                  (er) => er === st.Student.Name
                );
                if (stError) {
                  student.Status = SMARTSHARE_ITEM_STATUS.ERROR;
                }
              }
            } else {
              student.Status = SMARTSHARE_ITEM_STATUS.IN_PROGRESS;
            }
            return student;
          }),
        };
      });
      process.classes = s.Classes.map((c) => ({ name: c }));
      process.groups = s.Groups.map((groups) => ({ name: groups }));
      process.id = s.ID || `${ind}`;
      if (s.Type === UNDO_TYPE) {
        process.timestamp = s.UndoStartedTime;
        process.share_completed_time = s.UndoCompletedTime;
      } else {
        process.timestamp = s.ShareStartedTime;
        process.share_completed_time = s.ShareCompletedTime;
      }
      return process;
    });
    const sorted = _.sortBy(items, (it) => {
      return -Date.parse(it.timestamp);
    });
    return sorted;
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/smartshare-service/teacher/list",
      component: "smartshare",
    });
    throw e;
  }
}
export async function postRunSmartshare(smartshareRequest) {
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    await axios.post(
      `${API_URL}/smartshare-service/teacher/share`,
      {
        SourceDocuments: smartshareRequest.files.map((f) => {
          return {
            Docid: f.id.indexOf("new_file") >= 0 ? "" : f.id,
            MimeType: f.mimeType,
            Title: f.name,
            ReplaceExistingFile: smartshareRequest.replaceStudentFile,
            AppendName: smartshareRequest.filesConfig[f.id].appendName,
            Sharetype: smartshareRequest.filesConfig[f.id].shareType,
          };
        }),
        Destinations: smartshareRequest.folders.map((f) => {
          return {
            ClassURN: f.class_id,
            ClassName: f.class_name,
            FullClass: smartshareRequest.fullClass,
            NameFormat: smartshareRequest.nameFormat,
            Students: smartshareRequest.fullClass
              ? []
              : smartshareRequest.selectedStudents.map((s) => ({
                  Email: s.email,
                  ID: s.id,
                  FirstName: s.FirstName,
                  LastName: s.LastName,
                })),
            Folders: f.name === "root" ? [] : [f.name],
            Groups: smartshareRequest.selectedGroupsName,
          };
        }),
      },
      config
    );
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/smartshare-service/teacher/share",
      component: "smartshare",
    });
    throw e;
  }
}

export async function postRunUnshare(taskId) {
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    await axios.post(
      `${API_URL}/smartshare-service/teacher/undo`,
      { TaskId: taskId },
      config
    );
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/smartshare-service/teacher/undo",
      component: "smartshare",
    });
    throw e;
  }
}

export const sendReportToSupport = async (data) => {
  try {
    let pugsUrl = "https://pugs.teacherdashboard.com/v2";
    if (API_URL.indexOf("-test") > 0) {
      pugsUrl = "https://pugs-test.teacherdashboard.com/v2";
    }
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    await axios.post(`${pugsUrl}/services/support/smartshare`, data, config);
  } catch (e) {
    postSendEvent({
      ...getErrorEvent(e),
      api_path: "/services/support/smartshare",
      component: "smartshare",
    });
    throw e;
  }
};
