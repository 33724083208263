import React from "react";
import { Button, ButtonLink } from "@hapara/ui/src/atomic/Button";
import { ClassInfoHeaderClassIdentifier } from "./ClassInfoHeaderClassIdentifier/ClassInfoHeaderClassIdentifier";
import {
  ClassInfoHeaderDropDown,
  ClassInfoHeaderDropDownItem,
} from "./ClassInfoHeaderDropDown";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../hooks/useIsDimension";
import styles from "./ClassInfoHeader.module.scss";
import classnames from "classnames";
import { useIntl } from "react-intl";

export type ClassInfoHeaderProps = {
  emailLink: string;
  classDisplayName?: string;
  classDisplayIdentifier?: string;
  dataTestIdPrefix: string;
  canEditDisplayName?: boolean;
  canRemoveClass?: boolean;
  canResetClass?: boolean;
  displaySyncInProgress?: boolean;
  onRemoveClass?: () => void;
  onEditDisplayName: () => void;
  onSyncClass?: () => void;
  onOpenClassFolder?: () => void;
  onResetFolders?: () => void;
  isDataLoadedClass: boolean;
  isSelfRosteringClass: boolean;
  isGoogleClassroomClass: boolean;
  googleDriveFolderLink?: string;
};

export const ClassInfoHeader: React.FC<ClassInfoHeaderProps> = ({
  classDisplayName,
  classDisplayIdentifier,
  dataTestIdPrefix,
  canEditDisplayName,
  emailLink,
  canRemoveClass,
  canResetClass,
  onRemoveClass,
  onEditDisplayName,
  onSyncClass,
  onResetFolders,
  googleDriveFolderLink,
  displaySyncInProgress,
  isDataLoadedClass,
  isGoogleClassroomClass,
}: ClassInfoHeaderProps) => {
  const intl = useIntl();
  const { isDimensionOnly: isTabletDown } = useIsDimension(WINDOW_SIZE_TYPES.S);

  const displayEmailClassButton = isDataLoadedClass;
  const isDataLoadedClassContainer = isDataLoadedClass && !isTabletDown;

  return (
    <div className={styles.root} data-test-id={`${dataTestIdPrefix}-header`}>
      <div
        className={classnames(styles.textContainer, {
          [styles.dataloadedClassContainer]: isDataLoadedClassContainer,
        })}
      >
        <div className={styles.classDisplayName}>
          <h1>
            {classDisplayName}
            {canEditDisplayName && (
              <Button
                onClick={onEditDisplayName}
                className={styles.editClassDisplayName}
                dataTestId={`${dataTestIdPrefix}-Header_editDisplayName`}
                icon="pencil-fill"
                type="outlined"
                outlineType="solid"
                size="xsmall"
                aria-label={intl.formatMessage({
                  defaultMessage: "Edit class display name",
                  id: "5YBJth",
                })}
              />
            )}
          </h1>
        </div>
        <ClassInfoHeaderClassIdentifier
          isGoogleClassroomClass={isGoogleClassroomClass}
          dataTestIdPrefix={dataTestIdPrefix}
          classDisplayIdentifier={classDisplayIdentifier}
          onSyncClass={onSyncClass}
          displaySyncInProgress={displaySyncInProgress}
        />
      </div>
      <div className={styles.buttonContainer}>
        {canRemoveClass && !isTabletDown && (
          <Button
            onClick={onRemoveClass}
            className={classnames(styles.headerAction, styles.removeClass)}
            label={intl.formatMessage({
              defaultMessage: "Remove class",
              id: "03IYhy",
            })}
            dataTestId={`${dataTestIdPrefix}-Header_removeClass`}
            type="outlined"
            outlineType="solid"
            size="small"
          />
        )}
        {displayEmailClassButton && !isTabletDown && (
          <ButtonLink
            target="_blank"
            href={emailLink}
            className={classnames(styles.headerAction, styles.emailClass)}
            label={intl.formatMessage({
              defaultMessage: "Email class",
              id: "qLL8MD",
            })}
            dataTestId={`${dataTestIdPrefix}-Header_emailClass`}
            type="primary"
            size="small"
          />
        )}

        <ClassInfoHeaderDropDown dataTestIdPrefix={dataTestIdPrefix}>
          {displayEmailClassButton && isTabletDown && (
            <ClassInfoHeaderDropDownItem
              href={emailLink}
              id="emailClass"
              dataTestIdPrefix={dataTestIdPrefix}
              label={intl.formatMessage({
                defaultMessage: "Email class",
                id: "qLL8MD",
              })}
            />
          )}
          {googleDriveFolderLink && (
            <ClassInfoHeaderDropDownItem
              id="openClassFolder"
              href={googleDriveFolderLink}
              openInNewTab
              dataTestIdPrefix={dataTestIdPrefix}
              label={intl.formatMessage({
                defaultMessage: "Open class folder",
                id: "JCQG/d",
              })}
            />
          )}
          {canResetClass && (
            <ClassInfoHeaderDropDownItem
              id="resetFolders"
              onClick={onResetFolders}
              dataTestIdPrefix={dataTestIdPrefix}
              label={intl.formatMessage({
                defaultMessage: "Reset folders",
                id: "um5HZF",
              })}
            />
          )}
          {canRemoveClass && isTabletDown && (
            <ClassInfoHeaderDropDownItem
              id="removeClass"
              onClick={onRemoveClass}
              dataTestIdPrefix={dataTestIdPrefix}
              label={intl.formatMessage({
                defaultMessage: "Remove class",
                id: "03IYhy",
              })}
              isDangerous
            />
          )}
        </ClassInfoHeaderDropDown>
      </div>
    </div>
  );
};
