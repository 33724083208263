import React from "react";
import PropTypes from "prop-types";
import styles from "./PageContainer.module.scss";
import { Helmet } from "react-helmet";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";

const PageContainer = ({
  header,
  footer,
  title = "Hāpara",
  children,
  isPageLoadingError = false,
  isPageLoaded = false,
  dataTestIdPrefix = "hs-Page",
}) => {
  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestIdPrefix}-PageContainer`}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {header}

      <div className={styles.content}>
        {(!isPageLoaded || isPageLoadingError) && (
          <PageLoading
            isError={isPageLoadingError}
            errorMessageText="Sorry we are having trouble connecting"
            dataTestIdPrefix={`${dataTestIdPrefix}-PageLoading`}
            isGoBackVisible={false}
          />
        )}

        {isPageLoaded && !isPageLoadingError && children}
      </div>

      {footer}
    </div>
  );
};

PageContainer.propTypes = {
  header: PropTypes.element.isRequired,
  footer: PropTypes.element,
  title: PropTypes.string.isRequired,
  isPageLoaded: PropTypes.bool.isRequired,
  isPageLoadingError: PropTypes.bool.isRequired,
  isScrollableHeader: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string,
};

export default PageContainer;
