import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./DashboardSubMenu.module.scss";
import stylesSubMenu from "../../SubMenu/SubMenu.module.scss";

// COMPONENTS
import {
  Button,
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";

// TYPES / OTHER
import { APP_PAGES } from "../../../../state/router/types";
import { getDashboardSubjectFolders } from "../../../../state/dashboard/class/selectors";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../hooks/useIsDimension";

interface SubjectFolderProps {
  dataTestIdPrefix: string;
  classId: string;
  tabName: string;
}

const SubjectFolders: React.FC<SubjectFolderProps> = ({
  dataTestIdPrefix,
  classId,
  tabName,
}) => {
  const folders = useSelector(getDashboardSubjectFolders) || ["Documents"];

  const { isDimensionOnly: isTabletDown } = useIsDimension(WINDOW_SIZE_TYPES.S);

  const getURL = (folderName: string) => {
    try {
      return `${
        APP_PAGES.DASHBOARD_FOLDER.path
      }/${classId}/${encodeURIComponent(folderName)}`;
    } catch (e) {
      return `${APP_PAGES.DASHBOARD_FOLDER.path}/${classId}/${folderName}`;
    }
  };

  const isActiveFolder = (folderName: string) => {
    try {
      return decodeURIComponent(folderName) === tabName;
    } catch {
      return folderName === tabName;
    }
  };

  if (folders.length > 3 && !isTabletDown) {
    const isDropdownActive = folders?.slice(2)?.some((folderName: string) => {
      return isActiveFolder(folderName);
    });

    return (
      <>
        {folders.slice(0, 2).map((folderName: string, index: number) => (
          <NavLink
            key={`${folderName}-${index}`}
            to={getURL(folderName)}
            activeClassName={stylesSubMenu.active}
            data-test-id={`${dataTestIdPrefix}-Link-Folder-${folderName}`}
            isActive={() => isActiveFolder(folderName)}
          >
            <span className={styles.container}>
              <HapReactIcon
                svg="material-icon-folder"
                className={styles.icon}
              />
              <span className={styles.folderName}>
                {decodeURIComponent(folderName)}
              </span>
            </span>
          </NavLink>
        ))}
        <div
          className={`${styles.dropdownContainer} ${
            isDropdownActive ? styles.activeDropdownContainer : ""
          }`}
        >
          <Dropdown
            className={styles.rootOverride}
            positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
            triggerComponent={(props) => {
              const label = `${folders.length - 2} more folders`;
              return (
                <Button
                  label={label}
                  type={BUTTON_TYPES.OUTLINED}
                  size={BUTTON_SIZES.XSMALL}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  rightIcon="arrow-carvet-down"
                  dataTestId={`${dataTestIdPrefix}-Toolbar-DocsTypeFilter-TriggerButton`}
                  {...props}
                />
              );
            }}
            content={() => (
              <ul className={styles.dropdownList}>
                {folders.slice(2).map((folderName: string, index: number) => {
                  return (
                    <li
                      tabIndex={-1}
                      key={`${folderName}-${index}`}
                      className={styles.dropdownItem}
                      data-test-id={`${dataTestIdPrefix}-Toolbar-DocsTypeFilter-Item-${folderName}`}
                    >
                      <NavLink
                        to={getURL(folderName)}
                        data-test-id={`${dataTestIdPrefix}-Link-Folder-${folderName}`}
                        tabIndex={0}
                        activeClassName={styles.active}
                        isActive={() => isActiveFolder(folderName)}
                      >
                        <span className={styles.container}>
                          <HapReactIcon
                            svg="material-icon-folder"
                            className={styles.icon}
                          />
                          <span className={styles.folderName}>
                            {decodeURIComponent(folderName)}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            )}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        {folders.map((folderName: string, index: number) => (
          <NavLink
            key={`${folderName}-${index}`}
            to={getURL(folderName)}
            activeClassName={stylesSubMenu.active}
            data-test-id={`${dataTestIdPrefix}-Link-Folder-${folderName}`}
            isActive={() => isActiveFolder(folderName)}
          >
            <span className={styles.container}>
              <HapReactIcon
                svg="material-icon-folder"
                className={styles.icon}
              />
              <span className={styles.folderName}>
                {decodeURIComponent(folderName)}
              </span>
            </span>
          </NavLink>
        ))}
      </>
    );
  }
};

export default SubjectFolders;
