import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./FeedbackForm.module.scss";
import classnames from "classnames";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { FEEDBACK_PRODUCT_TYPES } from "../../../state/app/types";
import _ from "lodash";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import { Alert } from "@hapara/ui/src/atomic/AlertDeprecated";
import { sendFeedback } from "../../../state/app/actions";
import { useIntl, FormattedMessage } from "react-intl";

const dataTestPrefix = "Footer-FeedbackForm";
const DELAY = 5 * 1000;

export const FeedbackForm = ({ productType, sendFeedback }) => {
  const [message, setMessage] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [isSendError, setIsSendError] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(1);

  const sendSuccessRef = useRef();
  const textareaRef = useRef();
  const delayRef = useRef();

  const getProductFromURL = () => {
    const str = window.location.pathname;
    const match = str.match(/\/([^/]+)\//);
    return match ? match[1] : "";
  };

  const scrollToBottom = () => {
    var height = Math.max(
      window.innerHeight,
      document.documentElement.clientHeight,
      document.body.clientHeight
    );

    setTimeout(() => {
      window.scrollTo({
        top: height,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  const handleSendFeedback = () => {
    setSendingInProgress(true);
    setIsSendError(false);

    sendFeedback({
      body: message,
      browser: window.navigator.userAgent,
      clientDatetime: new Date().toISOString(),
      product: productType,
      url: window.location.href,
      userType: "teacher",
      pageResultion: `${window.innerWidth}x${window.innerHeight}`,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      pageState: getProductFromURL(),
    })
      .then((r) => {
        setSendingInProgress(false);
        setIsSendSuccess(true);
        _.delay(
          () => sendSuccessRef.current && sendSuccessRef.current.focus(),
          200
        );
        delayRef.current = _.delay(() => {
          setIsSendSuccess(false);
          handleFormReset();
        }, DELAY);
      })
      .catch((e) => {
        setSendingInProgress(false);
        setIsSendError(true);
      });
  };

  const handleFormExpand = () => {
    setNumberOfRows(3);
    setIsSendError(false);
    setIsSendSuccess(false);
    setIsExpanded(true);
    scrollToBottom();
  };

  const handleFormReset = () => {
    setMessage("");
    setIsExpanded(false);
    setNumberOfRows(1);
    setIsSendError(false);
  };

  useEffect(() => {
    if (isSendError) {
      scrollToBottom();
    }
  }, [isSendError]);

  // clean up delay, if any, on unmount
  useEffect(() => {
    return () => {
      if (delayRef.current) {
        window.clearTimeout(delayRef.current);
      }
    };
  }, []);

  const handleKeydown = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();

      handleFormReset();
      textareaRef.current.blur();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []); //eslint-disable-line

  const intl = useIntl();

  return (
    <footer
      className={styles.root}
      role="group"
      aria-label={intl.formatMessage({
        defaultMessage: "Send suggestions to Hāpara",
        id: "I0EdME",
      })}
    >
      <div className={styles.title}>
        <FormattedMessage
          defaultMessage="Send suggestions to <span>Hāpara</span>"
          id="BaW0jB"
          values={{
            span: (chunks) => <span lang="mi">{chunks}</span>,
          }}
        />
      </div>
      <form
        className={styles.form}
        aria-label={intl.formatMessage({
          defaultMessage: "Feedback container",
          id: "N5bzCm",
        })}
      >
        {!isSendSuccess && (
          <>
            <textarea
              aria-describedby={isSendError ? "textareaFeedbackAlertId" : null}
              ref={textareaRef}
              rows={numberOfRows}
              value={message}
              placeholder={intl.formatMessage({
                defaultMessage: "Tell us your ideas – big and small!",
                id: "CDoHEi",
              })}
              aria-label={intl.formatMessage({
                defaultMessage: "Type your suggestions",
                id: "srneaz",
              })}
              className={classnames(
                styles[THEME_TYPES.LIGHT],
                styles.textarea,
                {
                  [styles.withoutBottomRadius]: isExpanded,
                }
              )}
              onChange={(e) => {
                setMessage(_.get(e, "target.value", ""));
                handleFormExpand();
              }}
              onClick={handleFormExpand}
              data-test-id={`${dataTestPrefix}-TextareaField`}
            />
            {isExpanded && (
              <div className={styles.actions}>
                <Alerts>
                  {isSendError && (
                    <Alert
                      type="failure"
                      animated={false}
                      className={styles.alert}
                      dataTestId={`${dataTestPrefix}-ErrorMsg`}
                    >
                      <span id="textareaFeedbackAlertId">
                        <FormattedMessage
                          defaultMessage="Sorry, there was a problem sending this suggestion. Please try again."
                          id="r+7pRv"
                        />
                      </span>
                    </Alert>
                  )}
                </Alerts>
                <div className={styles.actionsInner}>
                  <Button
                    label={intl.formatMessage({
                      defaultMessage: "Cancel",
                      id: "47FYwb",
                    })}
                    onClick={handleFormReset}
                    data-test-id={`${dataTestPrefix}-Button-Cancel`}
                    type="secondary"
                    className={styles.cancelButton}
                  />
                  <Button
                    label={
                      sendingInProgress
                        ? intl.formatMessage({
                            defaultMessage: "Sending",
                            id: "C2CIgC",
                          })
                        : intl.formatMessage({
                            defaultMessage: "Send to Hāpara",
                            id: "ZwdbAZ",
                          })
                    }
                    onClick={handleSendFeedback}
                    data-test-id={`${dataTestPrefix}-Button-SendToHapara`}
                    className={styles.sendButton}
                    isLoading={sendingInProgress}
                    isDisabled={_.isEmpty(message)}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </form>
      {isSendSuccess && (
        <div className={styles.msgContainer} tabIndex={-1} ref={sendSuccessRef}>
          <HapReactIcon
            className={styles.successMessageIcon}
            svg="circle-check-fill"
            width="16"
            height="16"
          />
          <div data-test-id={`${dataTestPrefix}-SuccessMsg`}>
            <FormattedMessage
              defaultMessage="Thank you for your suggestion!"
              id="50trJO"
            />
          </div>
        </div>
      )}
    </footer>
  );
};

FeedbackForm.propTypes = {
  productType: PropTypes.oneOf(_.values(FEEDBACK_PRODUCT_TYPES)).isRequired,
  // connected props
  sendFeedback: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    sendFeedback: (body) => dispatch(sendFeedback(body)),
  })
)(FeedbackForm);
