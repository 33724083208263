import React, { CSSProperties } from "react";
import classNames from "classnames";
import { formatDateTimeUtc } from "../formatDateTimeUtc";
import { FormattedMessage } from "react-intl";
import { DateTimeFormat } from "../formats";

type IntlDateTimeUtcProps = {
  value: string;
  style?: CSSProperties;
  className?: string;
  dataTestIdPrefix?: string;
  format?: DateTimeFormat;
};

export const IntlDateTimeUtc = ({
  value,
  style,
  className,
  dataTestIdPrefix,
  format = "default",
}: IntlDateTimeUtcProps) => {
  const formattedTimestamp = formatDateTimeUtc(value, format);

  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-IntlDateTimeUtc`
    : undefined;

  return (
    <span
      style={style}
      data-test-id={dataTestId}
      className={classNames(className)}
    >
      {formattedTimestamp ? (
        formattedTimestamp
      ) : (
        <FormattedMessage defaultMessage="Invalid date" id="t+3/Tz" />
      )}
    </span>
  );
};
