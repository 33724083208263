import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./GuideBrowsingModal.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import GuideBrowsingCreateForm from "../GuideBrowsingCreateForm/GuideBrowsingCreateForm";
import {
  getGuideBrowsingModalMode,
  getGuideBrowsingModalSessionType,
  getGuideBrowsingModalIsHideBackButton,
  getGuideBrowsingModalSelectedStudent,
  getGuideBrowsingTemplateList,
  getGuideBrowsingModalSelectedTemplate,
} from "../../../../../../state/highlights/sessions/selectors";
import {
  SESSION_TYPES,
  GUIDE_BROWSING_TEMPLATE_TYPE,
  MODAL_MODE_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import { STUDENT_GUIDE_BROWSING_TYPE } from "../../../../../../state/highlights/students/types";
import guideBrowsingSvg from "@hapara/assets/src/images/vector-guide-browsing.svg";
import {
  hideGuideBrowsingModal,
  loadGuideBrowsingTemplates,
  deleteGuideBrowsingTemplate,
} from "../../../../../../state/highlights/sessions/actions";
import BodyBlock from "./BodyBlock";
import { getClassId } from "../../../../../../state/shared/selectors";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import { getIsOutsideSchoolHours } from "../../../../../../state/highlights/config/selectors";
import { FocusContext } from "@hapara/ui/src/components/utils";
import { FormattedMessage, useIntl } from "react-intl";

const GuideBrowsingModal = ({
  onModalClose,
  modalModeIn = MODAL_MODE_TYPES.SESSION_TYPE_SELECTION,
  sessionTypeIn,
  isHideBackButton,
  selectedTemplateIn,
  selectedStudent,
  selectedClassId,
  templateList,
  isOutsideSchoolHours,
  loadGuideBrowsingTemplates,
  deleteGuideBrowsingTemplate,
}) => {
  const [modalMode, setModalMode] = useState(modalModeIn);
  const [sessionType, setSessionType] = useState(sessionTypeIn);
  const [selectedTemplate, setSelectedTemplate] = useState(selectedTemplateIn);
  const focusContext = useContext(FocusContext);
  const intl = useIntl();

  const doClose = () => {
    if (focusContext.focusRef && focusContext.focusRef.current) {
      focusContext.focusRef.current.focus();
    }
    onModalClose();
  };

  useEffect(() => {
    setModalMode(modalModeIn);
  }, [modalModeIn]);

  useEffect(() => {
    setSessionType(sessionTypeIn);
  }, [sessionTypeIn]);

  useEffect(() => {
    if (selectedClassId) {
      loadGuideBrowsingTemplates(selectedClassId);
    }
  }, [loadGuideBrowsingTemplates, selectedClassId]);

  let title = "";
  let warningText = intl.formatMessage({
    defaultMessage:
      "School is over for the day. Please schedule this session for later.",
    id: "Byc8xM",
  });
  if (modalMode === MODAL_MODE_TYPES.SESSION_TYPE_SELECTION) {
    title = intl.formatMessage({
      defaultMessage: "How would you like to guide student browsing?",
      id: "F/VO30",
    });
  } else if (sessionType === SESSION_TYPES.FILTER) {
    title = intl.formatMessage({
      defaultMessage: "Filter Session",
      id: "sc0fvm",
    });
  } else if (sessionType === SESSION_TYPES.FOCUS) {
    title = intl.formatMessage({
      defaultMessage: "Focus Session",
      id: "mNzqlF",
    });
  } else if (sessionType === SESSION_TYPES.LOCK) {
    title = intl.formatMessage({
      defaultMessage: "Freeze tabs",
      id: "oU2Nkm",
    });
    warningText = intl.formatMessage({
      defaultMessage:
        "School is over for the day. You can only start this session during school hours.",
      id: "Wnj/y9",
    });
  }

  const dataTestPrefix = "hl-GuideBrowsingModal";
  const mainHeaderId = `${dataTestPrefix}-Header`;

  const handleSessionCreateBack = () => {
    setModalMode(MODAL_MODE_TYPES.SESSION_TYPE_SELECTION);
    setSessionType(null);
    setSelectedTemplate(null);
  };

  const handleSessionCreateCancel = () => {
    handleSessionCreateBack();
    doClose();
  };

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={handleSessionCreateCancel}
      hasEmbeddedHeader={false}
      className={styles.root}
      dataTestPrefix={dataTestPrefix}
      contentLabel={title}
    >
      <>
        <div className={styles.modalTitle}>
          <div className={styles.modalTitleHeaderContainer}>
            {modalMode === MODAL_MODE_TYPES.CREATE_SESSION_FORM &&
              sessionType !== SESSION_TYPES.LOCK &&
              !isHideBackButton && (
                <Button
                  icon="arrow-left"
                  onClick={handleSessionCreateBack}
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Back",
                    id: "cyR7Kh",
                  })}
                  data-test-id={`${dataTestPrefix}-Button-Back`}
                  className={styles.modalTitleBack}
                />
              )}
            <h1
              id={mainHeaderId}
              data-test-id={`${dataTestPrefix}-Header`}
              className={styles.modalTitleHeader}
            >
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {title}{" "}
              {!_.isEmpty(selectedStudent) ? (
                <FormattedMessage
                  defaultMessage="for {selectedStudent}"
                  id="5/c30d"
                  values={{
                    selectedStudent: selectedStudent.FullName,
                  }}
                />
              ) : (
                ""
              )}
            </h1>
            <Button
              icon="cross"
              onClick={handleSessionCreateCancel}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
                id: "rbrahO",
              })}
              data-test-id={`${dataTestPrefix}-Button-Close`}
              className={styles.modalTitleClose}
            />
          </div>
          {sessionType === SESSION_TYPES.LOCK && (
            <div className={styles.subTitle}>
              <FormattedMessage
                defaultMessage="Keep students on the sites they already have open."
                id="PkoAJl"
              />
            </div>
          )}
          <WarningMessage
            isVisible={
              modalMode === MODAL_MODE_TYPES.CREATE_SESSION_FORM &&
              isOutsideSchoolHours
            }
            icon="circle-information"
            className={styles.modalTitleWarning}
            dataTestId={`${dataTestPrefix}-OutOfSchoolHoursWarning`}
          >
            {warningText}
          </WarningMessage>
        </div>

        {modalMode === MODAL_MODE_TYPES.SESSION_TYPE_SELECTION && (
          <div
            className={styles.modalBody}
            style={{ backgroundImage: `url("${guideBrowsingSvg}")` }}
            data-test-id={`${dataTestPrefix}-ChooseTypeModal`}
          >
            <BodyBlock
              title={intl.formatMessage({
                defaultMessage:
                  "Allow students to access only a few, specific websites.",
                id: "Lfu3XK",
              })}
              dataTestPrefix={`${dataTestPrefix}-ChooseTypeModal-FocusSession`}
              onSetUp={({ selectedTemplate }) => {
                setSelectedTemplate(selectedTemplate);
                setSessionType(SESSION_TYPES.FOCUS);
                setModalMode(MODAL_MODE_TYPES.CREATE_SESSION_FORM);
              }}
              setUpLabel={intl.formatMessage({
                defaultMessage: "Set up a Focus Session",
                id: "ZY2PX9",
              })}
              reviewLabel={intl.formatMessage({
                defaultMessage: "Review Focus Session",
                id: "e3FR4h",
              })}
              templateList={_.filter(
                templateList,
                (item) => item.Type === SESSION_TYPES.FOCUS
              )}
              selectedClassId={selectedClassId}
              deleteGuideBrowsingTemplate={deleteGuideBrowsingTemplate}
              loadGuideBrowsingTemplates={loadGuideBrowsingTemplates}
            />

            <BodyBlock
              title={intl.formatMessage({
                defaultMessage:
                  "Prevent students from accessing a few, specific websites.",
                id: "zLPLLw",
              })}
              dataTestPrefix={`${dataTestPrefix}-ChooseTypeModal-FilterSession`}
              onSetUp={({ selectedTemplate }) => {
                setSelectedTemplate(selectedTemplate);
                setSessionType(SESSION_TYPES.FILTER);
                setModalMode(MODAL_MODE_TYPES.CREATE_SESSION_FORM);
              }}
              setUpLabel={intl.formatMessage({
                defaultMessage: "Set up a Filter Session",
                id: "n2BfSS",
              })}
              reviewLabel={intl.formatMessage({
                defaultMessage: "Review Filter Session",
                id: "30ETXg",
              })}
              templateList={_.filter(
                templateList,
                (item) => item.Type === SESSION_TYPES.FILTER
              )}
              selectedClassId={selectedClassId}
              deleteGuideBrowsingTemplate={deleteGuideBrowsingTemplate}
              loadGuideBrowsingTemplates={loadGuideBrowsingTemplates}
            />
          </div>
        )}

        {modalMode === MODAL_MODE_TYPES.CREATE_SESSION_FORM && (
          <GuideBrowsingCreateForm
            onCancel={handleSessionCreateCancel}
            mainHeaderId={mainHeaderId}
            sessionType={sessionType}
            selectedTemplate={selectedTemplate}
            onTemplateSelect={(template) => setSelectedTemplate(template)}
          />
        )}
      </>
    </ModalDeprecated>
  );
};

GuideBrowsingModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  modalModeIn: PropTypes.string,
  sessionTypeIn: PropTypes.string,
  selectedStudent: STUDENT_GUIDE_BROWSING_TYPE,
  selectedClassId: PropTypes.string,
  templateList: PropTypes.arrayOf(GUIDE_BROWSING_TEMPLATE_TYPE),
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  loadGuideBrowsingTemplates: PropTypes.func.isRequired,
  deleteGuideBrowsingTemplate: PropTypes.func.isRequired,
  selectedTemplateIn: GUIDE_BROWSING_TEMPLATE_TYPE,
  isHideBackButton: PropTypes.bool,
};

export default connect(
  (state) => ({
    modalModeIn: getGuideBrowsingModalMode(state),
    sessionTypeIn: getGuideBrowsingModalSessionType(state),
    isHideBackButton: getGuideBrowsingModalIsHideBackButton(state),
    selectedTemplateIn: getGuideBrowsingModalSelectedTemplate(state),
    selectedStudent: getGuideBrowsingModalSelectedStudent(state),
    selectedClassId: getClassId(state),
    templateList: getGuideBrowsingTemplateList(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
  }),
  (dispatch) => ({
    onModalClose: () => dispatch(hideGuideBrowsingModal()),
    loadGuideBrowsingTemplates: (classId) =>
      dispatch(loadGuideBrowsingTemplates({ classId })),
    deleteGuideBrowsingTemplate: (id) =>
      dispatch(deleteGuideBrowsingTemplate({ id })),
  })
)(GuideBrowsingModal);
