import _ from "lodash";
import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../../app/actions";
import actionTypes from "../../../actionTypes";
import { PL_WORKSPACES_PAGE_SIZE } from "../types";
import { fetchProfessionalLearningWorkspacesCall } from "../../../../apiCalls/workspaces";

const plWorkspacesPending = () => ({
  type: actionTypes.PL_WORKSPACES_PENDING,
});

const plWorkspacesLoad = (items, pagesCount) => ({
  type: actionTypes.PL_WORKSPACES_SUCCESS,
  items,
  pagesCount,
});

const plWorkspacesError = () => ({
  type: actionTypes.PL_WORKSPACES_ERROR,
});

/**
 * Updates the list of PL workspaces.
 */

export const loadProfessionalLearningWorkspacesAction =
  (options) => (dispatch) => {
    dispatch(plWorkspacesPending());
    dispatch(hideAppError());
    fetchProfessionalLearningWorkspacesCall(options)
      .then((response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        return {
          items: _.get(data, "Workspaces"),
          wsCount: _.get(data, "Count"),
        };
      })
      .then(({ items, wsCount }) => {
        dispatch(
          plWorkspacesLoad(items, Math.ceil(wsCount / PL_WORKSPACES_PAGE_SIZE))
        );
      })
      .catch((error) => {
        dispatch(plWorkspacesError(error));
        dispatch(showAppError(error));
      });
  };
