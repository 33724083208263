type NestedObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: NestedObject | any[] | any;
};

type DotNotationPath<T> = T extends (infer U)[]
  ? `${number}` | `${number}.${DotNotationPath<U>}`
  : T extends object
  ?
      | {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [K in keyof T & string]: T[K] extends object | any[]
            ? `${K}` | `${K}.${DotNotationPath<T[K]>}`
            : `${K}`;
        }[keyof T & string]
      | ""
  : "";

export const getNestedValue = <
  T extends NestedObject,
  K extends DotNotationPath<T>
>(
  obj: T,
  path: K
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (path === "") return undefined;
  return (
    path
      .split(".")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((acc: any, part: string) => acc && acc[part], obj)
  );
};

export const setNestedValue = <
  T extends NestedObject,
  K extends DotNotationPath<T>,
  V
>(
  obj: T,
  path: K,
  value: V
): void => {
  if (path === "") return;
  const parts = path.split(".");
  const last = parts.pop();
  if (last === undefined) return;
  const parent = parts.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, part: string) => acc && acc[part],
    obj
  );
  if (parent) {
    parent[last] = value;
  }
};
