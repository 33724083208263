import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./Notification.module.scss";
import _ from "lodash";
import {
  getNotificationSession,
  getNotificationData,
} from "../../../../state/highlights/notification/selectors";
import { updateNotificationSession } from "../../../../state/highlights/notification/actions";
import { startNewSession } from "../../../../state/highlights/sessions/actions";
import {
  SESSION_RECIPIENT_TYPES,
  SESSION_TYPES,
} from "../../../../state/highlights/sessions/types";
import { getPauseScreensDuration } from "../../../../state/highlights/config/selectors";
import { FormattedMessage, useIntl } from "react-intl";

const Notification = ({
  sessionType,
  sessionDetails,
  updateNotificationSession,
  startNewSession,
  pauseScreensDuration,
}) => {
  const intl = useIntl();
  const DELAY = 5;
  const [amountOfSecondsLeft, setAmountOfSecondsLeft] = useState(DELAY);
  const [recipientsText, setRecipientsText] = useState();
  const notificationRef = useRef();
  const sessionTypeRef = useRef();

  const getRecepientsText = useCallback(() => {
    if (sessionType && sessionDetails) {
      const { RecipientType, Students, Groups } = sessionDetails;

      switch (RecipientType) {
        case SESSION_RECIPIENT_TYPES.CLASS: {
          return intl.formatMessage({
            defaultMessage: "The class",
            id: "ZqRDOJ",
          });
        }

        case SESSION_RECIPIENT_TYPES.STUDENTS: {
          return intl.formatMessage(
            {
              defaultMessage:
                "{studentsCount, plural, one {1 student} other {{studentsCount} students}}",
              id: "pXwEYs",
            },
            { studentsCount: Students.length }
          );
        }

        case SESSION_RECIPIENT_TYPES.GROUPS: {
          return intl.formatMessage(
            {
              defaultMessage:
                "{groupsCount, plural, one {1 group} other {{groupsCount} groups}}",
              id: "8x6kNR",
            },
            { groupsCount: Groups.length }
          );
        }

        default:
          return null;
      }
    }
  }, [sessionType, sessionDetails]);

  let delayedId = useRef(null);

  const handleNotificationClose = useCallback(() => {
    clearTimeout(delayedId.current);
    updateNotificationSession({ sessionType: null });
    setAmountOfSecondsLeft(DELAY);
  }, [updateNotificationSession]);

  const countDown = useCallback(
    () => setAmountOfSecondsLeft(amountOfSecondsLeft - 1),
    [amountOfSecondsLeft]
  );

  useEffect(() => {
    if (!sessionTypeRef.current && sessionType) {
      _.delay(() => notificationRef.current.focus(), 300);
      setRecipientsText(getRecepientsText(sessionType));
    }
    sessionTypeRef.current = sessionType;
  }, [getRecepientsText, sessionType, sessionTypeRef]);

  useEffect(() => {
    if (sessionType && amountOfSecondsLeft > 0) {
      delayedId.current = _.delay(countDown, 1000);
    } else if (amountOfSecondsLeft === 0) {
      handleNotificationClose();
      startNewSession({ sessionDetails });
    }
  }, [
    amountOfSecondsLeft,
    countDown,
    handleNotificationClose,
    sessionType,
    startNewSession,
    sessionDetails,
  ]);

  useEffect(() => {
    return () => {
      window.clearTimeout(delayedId.current);
    };
  }, []);

  return (
    <div role="alert" aria-live="polite">
      {sessionType && (
        <div
          className={styles.root}
          data-test-id="hl-Notification-Panel"
          tabIndex={-1}
          ref={notificationRef}
        >
          <div className={styles.head}>
            <div
              className={styles.countdownText}
              data-test-id="hl-Notification-CountdownText"
            >
              {sessionType === SESSION_TYPES.FOCUS &&
                intl.formatMessage({
                  defaultMessage: "Focus Session will begin ",
                  id: "41umbj",
                })}
              {sessionType === SESSION_TYPES.FILTER &&
                intl.formatMessage({
                  defaultMessage: "Filter Session will begin ",
                  id: "nWew1k",
                })}
              {sessionType === SESSION_TYPES.LOCK &&
                intl.formatMessage({
                  defaultMessage: "Tabs will be frozen ",
                  id: "s/7O0q",
                })}
              {sessionType === SESSION_TYPES.PAUSE &&
                intl.formatMessage({
                  defaultMessage: "Pausing screens ",
                  id: "xOozGt",
                })}
              <span>
                {intl.formatMessage(
                  {
                    defaultMessage: "in 00:0{amountOfSecondsLeft}",
                    id: "FEPXJh",
                  },
                  { amountOfSecondsLeft }
                )}
              </span>
            </div>
            <div>
              <button
                className={styles.button}
                onClick={() => {
                  handleNotificationClose();
                }}
                data-test-id="hl-Notification-Button-Close"
              >
                {sessionType === SESSION_TYPES.PAUSE
                  ? intl.formatMessage({
                      defaultMessage: "Cancel this action",
                      id: "ZvlRmn",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Cancel this session",
                      id: "9z7W9H",
                    })}
              </button>
            </div>
          </div>

          {sessionType === SESSION_TYPES.FOCUS && (
            <div className={styles.text} data-test-id="hl-Notification-Text">
              <span
                className={styles.bold}
                data-test-id="hl-Notification-Text-Options"
              >
                {recipientsText}
              </span>{" "}
              <FormattedMessage
                defaultMessage="will be focused on the link(s) you've listed, closing all other currently opened tabs."
                id="epvjpW"
              />
            </div>
          )}
          {sessionType === SESSION_TYPES.FILTER && (
            <div className={styles.text} data-test-id="hl-Notification-Text">
              <FormattedMessage
                defaultMessage="<span>{recipientsText}</span> won’t be able to visit the link(s) you’ve listed."
                id="KK8Yl/"
                values={{
                  recipientsText,
                  span: (chunks) => (
                    <span
                      data-test-id="hl-Notification-Text-Options"
                      className={styles.bold}
                    >
                      {chunks}
                    </span>
                  ),
                }}
              />
              <div>
                <FormattedMessage
                  defaultMessage="Tabs containing the link(s) will be closed."
                  id="8IhHs9"
                />
              </div>
            </div>
          )}
          {sessionType === SESSION_TYPES.LOCK && (
            <div className={styles.text} data-test-id="hl-Notification-Text">
              <FormattedMessage
                defaultMessage="<span>{recipientsText}</span> won't be able to open more sites."
                id="UUig76"
                values={{
                  recipientsText,
                  span: (chunks) => (
                    <span
                      className={styles.bold}
                      data-test-id="hl-Notification-Text-Options"
                    >
                      {chunks}
                    </span>
                  ),
                }}
              />
              <div>
                <FormattedMessage
                  defaultMessage="You may continue to open new sites or close irrelevant sites for your students during this session."
                  id="U57hFC"
                />
              </div>
            </div>
          )}
          {sessionType === SESSION_TYPES.PAUSE && (
            <div className={styles.text} data-test-id="hl-Notification-Text">
              <FormattedMessage
                defaultMessage="Students’ screens will be paused for a maximum of <span>{pauseScreensDuration} minutes.</span>"
                id="iV584W"
                values={{
                  pauseScreensDuration,
                  span: (chunks) => (
                    <span
                      className={styles.bold}
                      data-test-id="hl-Notification-Text-Options"
                    >
                      {chunks}
                    </span>
                  ),
                }}
              />
              <div>
                <FormattedMessage
                  defaultMessage="You can unpause their screens anytime before that."
                  id="pOj1zH"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)),
  updateNotificationSession: PropTypes.func.isRequired,
  startNewSession: PropTypes.func.isRequired,
  sessionDetails: PropTypes.shape({
    RecipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES)),
    Students: PropTypes.arrayOf(PropTypes.string),
    Groups: PropTypes.arrayOf(PropTypes.string),
  }),
  pauseScreensDuration: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    sessionType: getNotificationSession(state),
    sessionDetails: getNotificationData(state),
    pauseScreensDuration: getPauseScreensDuration(state),
  }),
  (dispatch) => ({
    updateNotificationSession: (sessionType) => {
      dispatch(updateNotificationSession(sessionType));
    },
    startNewSession: ({ sessionDetails }) =>
      dispatch(startNewSession({ sessionDetails })),
  })
)(Notification);
