import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";

import { connect } from "react-redux";

import {
  getSearchResults,
  getIsSearchLoading,
  getSearchCurrentPage,
  getSearchPagesCount,
  getSearchResultTotalItems,
  getSearchQuery,
} from "../../../../../state/workspace/pl/search/selectors";
import {
  searchPLWorkspaces,
  clearSearch,
  changeSearchPage,
} from "../../../../../state/workspace/pl/search/actions";

import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import styles from "./PLWorkspacesSearchItems.module.scss";
import { PL_WORKSPACES_SEARCH_PAGE_SIZE } from "../../../../../state/workspace/pl/types";
import PLWorkspaceTile from "../PLWorkspaceTile/PLWorkspaceTile";

import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";
import PLWorkspacesSearchEmptyState from "../PLWorkspacesSearchEmptyState/PLWorkspacesSearchEmptyState";

import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";

export const PLWorkspacesSearchItems = ({
  items,
  totalItems,
  currentPage,
  pagesCount,
  searchQuery,
  isLoading,
  clearSearch,
  changeSearchPage,
  searchPLWorkspaces,
}) => {
  const handlePageSelect = (page) => {
    changeSearchPage(page);
  };

  useEffect(() => {
    searchPLWorkspaces({ query: searchQuery, page: currentPage });
  }, [currentPage, searchQuery, searchPLWorkspaces]);

  return (
    <div className={styles.root}>
      {isLoading && (
        <ul className={classnames(styles.list, styles.topMargin)}>
          {_.range(0, PL_WORKSPACES_SEARCH_PAGE_SIZE).map((index) => (
            <li
              className={classnames(styles.listItem, styles.loading)}
              key={index}
            >
              <PLWorkspaceTile />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && items && (
        <React.Fragment>
          <div className={styles.placeholderForTitle}>
            <h1 className={styles.title}>
              {totalItems} search result{totalItems !== 1 ? "s" : ""}
            </h1>
            <Button
              onAction={clearSearch}
              label="Clear"
              aria-label="Clear search results"
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              size={BUTTON_SIZES.SMALL}
              className={styles.clearSearch}
              dataTestId="Ws-PLWorkspacesSearch-SearchResults-ClearSearch"
            />
          </div>

          {items.length === 0 && <PLWorkspacesSearchEmptyState />}

          {items.length > 0 && (
            <ul className={styles.list}>
              {_.map(items, (item) => (
                <li
                  className={styles.listItem}
                  key={item.Id}
                  data-test-id="Hs-PL-WorkspacesSearchListItem-Container"
                >
                  <PLWorkspaceTile
                    item={item}
                    dataTestIdPrefix="Ws-PL-WorkspacesSearchListItem"
                    isShowLabels={false}
                    isShowProgress={false}
                  />
                </li>
              ))}
            </ul>
          )}

          <Pagination
            pages={pagesCount}
            currentPage={currentPage}
            onSelectPage={handlePageSelect}
            dataTestIdPrefix="Ws-PL-WorkspacesSearchPagination"
          />
        </React.Fragment>
      )}
    </div>
  );
};

PLWorkspacesSearchItems.propTypes = {
  items: PropTypes.arrayOf(wsItemType),
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  searchPLWorkspaces: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  changeSearchPage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    items: getSearchResults(state),
    totalItems: getSearchResultTotalItems(state),
    currentPage: getSearchCurrentPage(state),
    pagesCount: getSearchPagesCount(state),
    isLoading: getIsSearchLoading(state),
    searchQuery: getSearchQuery(state),
  }),
  (dispatch) => ({
    searchPLWorkspaces: (options) => dispatch(searchPLWorkspaces(options)),
    clearSearch: () => dispatch(clearSearch()),
    changeSearchPage: (page) => dispatch(changeSearchPage(page)),
  })
)(PLWorkspacesSearchItems);
