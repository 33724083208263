import PropTypes from "prop-types";
import _ from "lodash";
import { labelItemType } from "../labels/types";
import { MYWORKSPACES_GRADING_ACTIVITY_TYPES } from "../../grading/types";

export const MYWORKSPACES_RIGHT_PANEL_ID = "ws-myworkspaces-right-panel";
export const MYWORKSPACES_LEFT_PANEL_ID = "ws-myworkspaces-left-panel";
export const MYWORKSPACES_OPEN_LEFT_PANEL_BUTTON_ID =
  "ws-myworkspaces-open-left-panel-button";
export const MYWORKSPACES_TILE_ID = "ws-myworkspaces-tile-id-";
export const MYWORKSPACES_RIGHT_PANEL_SCROLLABLE_CONTENT_ID =
  "ws-SidePanel-ScrollableContent";

export const MYWORKSPACES_PAGE_SIZE = 20;
export const CLASSES_VISIBLE_AMOUNT = 3;

export const MYWORKSPACES_DEFAULT_LABEL = { id: "all", name: "All labels" };

export const MYWORKSPACES_FILTER_STATUS_TYPES = {
  ACTIVE: "active",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  ARCHIVED: "archived",
  ALL: "all",
};

export const MYWORKSPACES_USER_ROLES = {
  LEARNER: "Learner",
  EDITOR: "Editor",
};

export const MYWORKSPACES_PL_ROLES = {
  ALL: "all",
  FACILITATOR: "facilitator",
  PARTICIPANT: "participant",
};

export const MYWORKSPACES_STATUSES = {
  PUBLISHED: MYWORKSPACES_FILTER_STATUS_TYPES.PUBLISHED,
  UNPUBLISHED: MYWORKSPACES_FILTER_STATUS_TYPES.UNPUBLISHED,
  ARCHIVED: MYWORKSPACES_FILTER_STATUS_TYPES.ARCHIVED,
};

export const MYWORKSPACES_ACTION_TYPES = {
  REPLACE: "replace",
  DELETE: "delete",
  ADD: "add",
};

export const MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS = {
  COPY: "copy",
  COPYPL: "copyPL",
  ARCHIVE: "archive",
  DELETE: "delete",
  RESTORE: "restore",
};

export const MYWORKSPACES_CATEGORY_FEATURES = {
  SEARCH_FIELD: "search_field",
  FILTER_SORT: "filter_sort",
  FILTER_PL_ROLE: "filter_pl_role",
  FILTER_STATUS: "filter_status",
  FILTER_LABELS: "filter_labels",
};

export const MYWORKSPACES_CATEGORIES = {
  OWNED_BY_ME: {
    type: "myboards",
    urlKey: "owned-by-me",
    title: "Owned by me",
    icon: "circle-person-gray",
    iconActive: "circle-person",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
    ],
  },
  CLASSES: {
    type: "myclasses",
    urlKey: "class",
    title: "My classes",
    icon: "circle-blackboard-gray",
    iconActive: "circle-blackboard",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
    ],
  },
  LABELS: {
    type: "mylabels",
    urlKey: "my-labels",
    title: "My labels",
    icon: "circle-labels-gray",
    iconActive: "circle-labels",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_LABELS,
    ],
    feature_filter_labels: {
      hasAllLabels: true,
      canEditLabels: true,
    },
  },
  SHARED_WITH_ME: {
    type: "sharedtome",
    urlKey: "shared-with-me",
    title: "Shared with me",
    icon: "circle-people-gray",
    iconActive: "circle-people",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
    ],
  },
  PL: {
    type: "pd", // professional learning aka professional development
    urlKey: "professional-learning",
    title: "Professional learning",
    icon: "circle-graduate-cap-gray",
    iconActive: "circle-graduate-cap",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_PL_ROLE,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
    ],
  },
  ARCHIVED: {
    type: "archived",
    urlKey: "archived",
    title: "Archived",
    icon: "circle-zipped-file-gray",
    iconActive: "circle-zipped-file",
    features: [
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_LABELS,
    ],
    feature_filter_labels: {
      hasAllLabels: false,
      canEditLabels: false,
    },
  },
  SEARCH: {
    type: "search",
    urlKey: "search",
    title: "Search results",
    features: [MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD],
  },
};

// legacy const, maps categories keys to types:
// { OWNED_BY_ME: 'myboards', CLASSES: 'myclasses' ... }
// TODO replace this const with MYWORKSPACES_CATEGORIES[item].type
export const MYWORKSPACES_FILTER_CATEGORY_TYPES = _.reduce(
  MYWORKSPACES_CATEGORIES,
  (result, value, key) => {
    result[key] = value.type;
    return result;
  },
  {}
);

export const wsGroupType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  ColourIndex: PropTypes.number.isRequired,
});

export const wsCardSummaryItemType = PropTypes.shape({
  TotalCount: PropTypes.number.isRequired,
  StudentsProgress: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Name: PropTypes.shape({
        First: PropTypes.string.isRequired,
        Last: PropTypes.string.isRequired,
      }).isRequired,
      Artifact: PropTypes.shape({
        IconURL: PropTypes.string,
        Id: PropTypes.string,
        MimeType: PropTypes.string,
        Title: PropTypes.string,
        Type: PropTypes.string,
        URI: PropTypes.string,
      }),
    })
  ),
});

export const wsCardType = PropTypes.shape({
  GroupIds: PropTypes.arrayOf(PropTypes.string),
  Id: PropTypes.string.isRequired,
  IsGroupWork: PropTypes.bool.isRequired,
  IsOverdue: PropTypes.bool.isRequired,
  IsCreatedByLearner: PropTypes.bool.isRequired,
  Priority: PropTypes.number,
  Title: PropTypes.string,
  WorkspaceProgressSummary: PropTypes.shape({
    WorkAssessed: wsCardSummaryItemType,
    WorkNotStarted: wsCardSummaryItemType,
    WorkStarted: wsCardSummaryItemType,
    WorkSubmitted: wsCardSummaryItemType,
  }),
  SubmissionDueDates: PropTypes.arrayOf(
    PropTypes.shape({
      Time: PropTypes.string.isRequired,
      Id: PropTypes.string,
      RestrictToUserId: PropTypes.string,
      GroupFilter: PropTypes.arrayOf(PropTypes.string),
    })
  ),
});

export const wsSectionType = PropTypes.shape({
  Cards: PropTypes.arrayOf(wsCardType),
  Priority: PropTypes.number,
  Title: PropTypes.string,
  Id: PropTypes.string,
});

export const wsItemType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  MyAvailableActions: PropTypes.arrayOf(PropTypes.string),
  CreatedDatetime: PropTypes.string,
  ModifiedDatetime: PropTypes.string,
  Title: PropTypes.string,
  Description: PropTypes.string,
  Owner: PropTypes.string,
  Editors: PropTypes.arrayOf(PropTypes.string),
  ClassIds: PropTypes.arrayOf(PropTypes.string),
  Role: PropTypes.string,
  Status: PropTypes.oneOf(_.values(MYWORKSPACES_STATUSES)),
  GroupCount: PropTypes.number,
  StudentCount: PropTypes.number,
  SharedPublic: PropTypes.bool,
  IsPublished: PropTypes.bool.isRequired,
  IsArchived: PropTypes.bool.isRequired,
  BoardLabels: PropTypes.arrayOf(labelItemType),
  Groups: PropTypes.arrayOf(wsGroupType),
  Sections: PropTypes.arrayOf(wsSectionType),
});

export const classItemType = PropTypes.shape({
  email: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
});

export const myClassItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  class_alias: PropTypes.string.isRequired,
});

export const EVIDENCE_CATEGORIES = [
  {
    id: "WorkNotStarted",
    name: "Not started",
    activityType: null,
  },
  {
    id: "WorkStarted",
    name: "Started",
    activityType: MYWORKSPACES_GRADING_ACTIVITY_TYPES.STARTED,
  },
  {
    id: "WorkSubmitted",
    name: "Submitted",
    activityType: MYWORKSPACES_GRADING_ACTIVITY_TYPES.SUBMITTED,
  },
  {
    id: "WorkAssessed",
    name: "Assessed",
    activityType: MYWORKSPACES_GRADING_ACTIVITY_TYPES.ASSESSED,
  },
];
