import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ItemLoading from "../ItemLoading/ItemLoading";

const ContentLoading = ({ amount, className }) => {
  let content = [];
  for (let i = 0; i < amount; i++) {
    content.push(
      <Fragment key={i}>
        <ItemLoading className={className} />
      </Fragment>
    );
  }

  return content;
};

ContentLoading.propTypes = {
  amount: PropTypes.number,
  className: PropTypes.string,
};

export default ContentLoading;
