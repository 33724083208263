import { FormattedMessage } from "react-intl";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./GradingActivityHistory.module.scss";

import { getActivityHistory } from "../../../../../state/workspace/grading/selectors";
import {
  MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE,
  MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES,
} from "../../../../../state/workspace/grading/types";
import { USER_INFO } from "../../../../../state/user/types";

import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import GradingActivityHistoryItem from "../GradingActivityHistoryItem/GradingActivityHistoryItem";

export const GradingActivityHistory = ({ items }) => {
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {};
  const isFinalReturnDone = !!_.find(items, {
    Type: MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES.RETURNED_FINAL.type,
  });
  const editableItem = isFinalReturnDone
    ? {}
    : _.find(items, (item) => !!item.Grade || !!item.Feedback);

  return (
    <div className={styles.root} data-test-id="Ws-Grading-ActivityHistory">
      <div className={styles.title}>
        <div className={styles.avatar}>
          <HapReactIcon
            svg="clock-history"
            width={32}
            height={32}
            className={styles.centered}
          />
        </div>
        <h2>
          <FormattedMessage defaultMessage="Activity history" id="GBqRl1" />
        </h2>
      </div>
      <div className={styles.body}>
        {_.map(items, (item) => {
          const itemEditable =
            _.isEqual(editableItem, item) && item.GradedBy === userInfo?.email;
          return (
            <GradingActivityHistoryItem
              id={item.Id}
              activityDate={item.ActivityDate}
              type={item.Type}
              grade={item.Grade}
              gradeEdited={item.IsGradeModified || false}
              feedbackEdited={item.IsFeedbackModified || false}
              feedback={item.Feedback}
              key={item.Id}
              isItemEditable={itemEditable}
            />
          );
        })}
      </div>
    </div>
  );
};

GradingActivityHistory.propTypes = {
  items: PropTypes.arrayOf(MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE),
};

export default connect(
  (state) => ({
    items: getActivityHistory(state),
  }),
  null
)(GradingActivityHistory);
