import React from "react";
import Toggle from "../Toggle/Toggle";
import styles from "./Switch.module.scss";
import classnames from "classnames";

type SwitchProps = {
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckedChange: (isChecked: boolean) => void;
  onLabel?: string;
  offLabel?: string;
  ariaLabel?: string;
  dataTestIdPrefix?: string;
};

/** This wraps the existing `Toggle` component with limited typed props, it should
 * be re-written when all consumers have switched... 🥁 */
export const Switch = ({
  id,
  isChecked,
  onCheckedChange,
  isDisabled,
  ariaLabel,
  offLabel,
  onLabel,
  dataTestIdPrefix,
}: SwitchProps): JSX.Element => {
  return (
    <div
      className={classnames(styles.container, {
        [styles.withoutLabels]: !offLabel && !onLabel,
      })}
    >
      <Toggle
        id={id}
        // This component is FULLY controlled.
        value={!!isChecked}
        onChange={onCheckedChange}
        className={styles.toggleContainer}
        disabled={isDisabled}
        explanatoryText={ariaLabel}
        // Label to be provided externally or possibly with `withFormField`
        hideExplanatoryText
        dataTestIdPrefix={dataTestIdPrefix}
        toggleValues={{
          left: { label: offLabel ?? null },
          right: { label: onLabel ?? null },
        }}
      />
    </div>
  );
};
