import React from "react";
import PropTypes from "prop-types";

import styles from "./ButtonGroup.module.scss";
import classnames from "classnames";

const ButtonGroup = ({ label, children }) => {
  return (
    <div className={classnames(styles.root)}>
      <label className={styles.label}>{label}</label>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

ButtonGroup.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ButtonGroup;
