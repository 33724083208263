import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../app/actions";
import actionTypes from "../../actionTypes";
import { AppDispatch } from "../../store";
import { fetchDashboardClassData } from "../../../apiCalls/dashboard";
import { ApiError } from "../../../apiCalls/types";
import { UserGroup } from "../../../hooks/useGroups";

export const loadDashboardClassData =
  (classId: string) => (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.DASHBOARD_CLASS_DATA_LOAD_PENDING });
    dispatch(hideAppError());
    fetchDashboardClassData(classId)
      .then((response) => {
        dispatch({
          type: actionTypes.DASHBOARD_CLASS_DATA_LOAD_SUCCESS,
          payload: response,
        });
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          dispatch({
            type: actionTypes.DASHBOARD_CLASS_DATA_LOAD_ERROR,
          });
          dispatch(showAppError(error, "api-error"));
        }
      });
  };

export const dashboardUserGroupSelected = (groups: UserGroup[]) => {
  return {
    type: actionTypes.DASHBOARD_USER_GROUP_SELECTED,
    payload: groups,
  };
};
