import React from "react";
import { connect } from "react-redux";
import styles from "./NoSnapsInfoPanel.module.scss";
import PropTypes from "prop-types";
import { getClassId } from "../../../../state/shared/selectors";
import { APP_PAGES } from "../../../../state/router/types";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import noSnapsImage from "@hapara/assets/src/images/vector-hl-no-snaps.svg";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { NavLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

const NoSnapsInfoPanel = ({ selectedClassId }) => {
  return (
    <div className={styles.root} data-test-id="hs-HighlightsSnaps-NoSpansPanel">
      <div className={styles.image}>
        <img src={noSnapsImage} alt="" width={172} height={98} />
      </div>
      <h2 className={styles.title}>
        <FormattedMessage defaultMessage="There are no snaps" id="NO5HcJ" />
      </h2>
      <div className={styles.text}>
        <FormattedMessage
          defaultMessage='A "snap" is a screengrab of one or more students’ screen views and browsing activities. Snaps are created on the Activity Viewer by hovering over the links and clicking on the <icon>camera icon</icon>'
          id="30mig/"
          values={{
            icon: (chunk) => (
              <HapReactIcon
                svg="camera"
                width="16"
                height="16"
                className={styles.icon}
              >
                {chunk}
              </HapReactIcon>
            ),
          }}
        />
      </div>
      <div className={styles.link}>
        <NavLink
          to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY_VIEWER.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
          strict
          data-test-id="hs-HighlightsSnaps-NoSpansPanel-Link-ActivityViewer"
        >
          <FormattedMessage
            defaultMessage="Take me to Activity Viewer"
            id="7SaxGq"
          />
        </NavLink>
      </div>
    </div>
  );
};

NoSnapsInfoPanel.propTypes = {
  selectedClassId: PropTypes.string,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({})
)(NoSnapsInfoPanel);
