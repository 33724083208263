import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Template } from "src/state/highlights/guideBrowsingTemplates/type";
import {
  fetchTemplatesList,
  hideTemplatesPanel,
} from "src/state/highlights/guideBrowsingTemplates/actions";
import {
  getUserTemplatesList,
  getTemplatesPanelTemplatesOffset,
  getTemplatesPanelIsMoreTemplatesAvailable,
} from "src/state/highlights/guideBrowsingTemplates/selectors";
import { showGuideBrowsingModal } from "src/state/highlights/sessions/actions";
import { deleteGuidedBrowsingTemplate } from "src/apiCalls/highlights";
import { showSuccessToast, showAppError } from "src/state/app/actions";
import { useIntl } from "react-intl";

export const useGuidedBrowsingTemplates = (isOpen: boolean) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [templateForDeletion, setTemplateForDeletion] =
    useState<Template | null>(null);
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [isLoadMoreInProgress, setIsLoadMoreInProgress] = useState(false);
  const sessionTemplates = useSelector(getUserTemplatesList);
  const offset = useSelector(getTemplatesPanelTemplatesOffset);
  const hasMoreTemplates = useSelector(
    getTemplatesPanelIsMoreTemplatesAvailable
  );

  const fetchTemplates = useCallback(
    (currentOffset = 0) => {
      currentOffset === 0 ? setIsLoading(true) : setIsLoadMoreInProgress(true);
      setIsError(false);
      dispatch(fetchTemplatesList(currentOffset)).then((isLoaded: boolean) => {
        if (!isLoaded) {
          setIsError(true);
          dispatch(showAppError());
        }
        setIsLoading(false);
        setIsLoadMoreInProgress(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isOpen) {
      fetchTemplates();
    }
  }, [isOpen, fetchTemplates]);

  const handleLoadMore = () => {
    if (hasMoreTemplates && !isLoadMoreInProgress) {
      fetchTemplates(offset);
    }
  };

  const handleDeleteTemplate = (template: Template) => {
    setTemplateForDeletion(template);
  };

  const confirmDeleteTemplate = async () => {
    if (templateForDeletion) {
      setIsDeletingTemplate(true);
      setDeleteError(false);
      try {
        await deleteGuidedBrowsingTemplate({
          id: templateForDeletion.ID,
        });
        setIsDeletingTemplate(false);
        setTemplateForDeletion(null);
        dispatch(
          showSuccessToast(
            intl.formatMessage({
              defaultMessage: "Delete Successful",
              id: "H6SZkB",
            })
          )
        );

        fetchTemplates();
      } catch (error) {
        setDeleteError(true);
        setIsDeletingTemplate(false);
      }
    }
  };

  const cancelDeleteTemplate = () => {
    setTemplateForDeletion(null);
    setDeleteError(false);
  };

  const handleClose = () => {
    dispatch(hideTemplatesPanel());
  };

  const handleCreateSession = (type: "filter" | "focus") => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "CREATE_SESSION_FORM",
        sessionType: type,
      })
    );
    handleClose();
  };

  const handleStartSession = (template: Template) => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "START_SESSION_FORM",
        sessionType: template.Type,
        selectedTemplate: template,
        isScheduled: false,
        isHideBackButton: true,
      })
    );
    return dispatch(hideTemplatesPanel());
  };

  const handleEditTemplate = (template: Template) => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "EDIT_SESSION_FORM",
        sessionType: template.Type,
        selectedTemplate: template,
        isScheduled: false,
      })
    );
    dispatch(hideTemplatesPanel());
  };

  const handleScheduleTemplate = (template: Template) => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "SCHEDULE_TEMPLATE_FORM",
        sessionType: template.Type,
        selectedTemplate: template,
        isScheduled: false,
      })
    );
    dispatch(hideTemplatesPanel());
  };

  const handleCreateTemplate = () => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "CREATE_TEMPLATE_FORM",
      })
    );
    dispatch(hideTemplatesPanel());
  };

  return {
    isLoading,
    isError,
    templateForDeletion,
    isDeletingTemplate,
    deleteError,
    sessionTemplates,
    hasMoreTemplates,
    isLoadMoreInProgress,
    handleLoadMore,
    handleDeleteTemplate,
    handleEditTemplate,
    handleCreateTemplate,
    confirmDeleteTemplate,
    cancelDeleteTemplate,
    handleClose,
    handleCreateSession,
    handleStartSession,
    handleScheduleTemplate,
  };
};
