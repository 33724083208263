import actionTypes from "../../actionTypes";
import { WEBSOCKET_STATUS_TYPES } from "../HighlightsWebsocket";

const INIT_STATE = {
  status: WEBSOCKET_STATUS_TYPES.OFFLINE,
  isConnectionError: false,
};

const screenshotWSReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case actionTypes.HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_CONNECTION_ERROR: {
      return {
        ...state,
        isConnectionError: action.payload,
      };
    }

    default:
      return state;
  }
};

export default screenshotWSReducer;
