import actionTypes from "../actionTypes";
import { saveUserLabsPrefs } from "../../apiCalls/workspaces";
import { getMyClasses } from "../../apiCalls/config";
import { makeActionCreator } from "../storeUtils";
import { hideAppError, showAppError, updateAccessError } from "../app/actions";

export const getMyClassesAction = () => (dispatch) => {
  dispatch(hideAppError());
  dispatch(myClassesPending());
  getMyClasses()
    .then((classes) => {
      dispatch(myClassesLoad(classes));
    })
    .catch((e) => {
      if (e.responseStatus === 403) {
        dispatch(updateAccessError(true));
        return [];
      }
      dispatch(myClassesError(e));
      dispatch(showAppError(e));
    });
};

export const saveUserLabsPrefsAction = (labsPref) => {
  return (dispatch) => {
    return saveUserLabsPrefs(labsPref)
      .then((response) => {
        if (response.status === 403) {
          // Not a Hapara user, show an error message
          throw Error(response);
        }
        if (!response.ok) {
          throw Error(response);
        }
      })
      .then(dispatch(userLabsPrefsSaved()));
  };
};

export const myClassesPending = makeActionCreator(
  actionTypes.MY_CLASSES_LOAD_PENDING
);
export const myClassesLoad = makeActionCreator(
  actionTypes.MY_CLASSES_LOAD_SUCCESS,
  "myClasses"
);
export const myClassesError = makeActionCreator(
  actionTypes.MY_CLASSES_LOAD_ERROR,
  "error"
);

export const userLabsPrefsSaveErr = makeActionCreator(
  actionTypes.USER_LABS_PREFS_SAVE_ERROR,
  "error"
);
export const userLabsPrefsSaved = makeActionCreator(
  actionTypes.USER_LABS_PREFS_SAVE,
  "payload"
);

export const toggleLeftSidePanel = (isOpen) => ({
  type: actionTypes.RESOURCES_FILTERS_LEFT_PANEL_UPDATE,
  payload: isOpen,
});
