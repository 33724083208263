import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./WorkspacesSearchEmptyState.module.scss";

const WorkspacesSearchEmptyState = ({ isDiscover = false }) => {
  if (!isDiscover) {
    return (
      <div className={styles.root} data-test-id="Ws-WorkspacesList-EmptyState">
        <h1>
          <FormattedMessage
            defaultMessage="There's nothing here!"
            id="7kWlAm"
          />
        </h1>
        <div className={styles.tips}>
          <div>
            <FormattedMessage
              defaultMessage="We can't find any Workspaces based on this search."
              id="Jb9Mkx"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root} data-test-id="Ws-DiscoverList-EmptyState">
      <h1>
        <FormattedMessage
          defaultMessage="Sorry, we couldn’t find any results!"
          id="AxctB8"
        />
      </h1>
      <div className={styles.tipsHeader}>
        <FormattedMessage defaultMessage="Try these search tips:" id="7G713h" />
      </div>
      <div className={styles.tips}>
        Try different filters or keywords related to your topic of interest
        <br />
        Double-check the spelling of your keyword
      </div>
    </div>
  );
};

WorkspacesSearchEmptyState.propTypes = {
  isDiscover: PropTypes.bool,
};

export default WorkspacesSearchEmptyState;
