import React from "react";
import { StyledText } from "../../../../atomic/StyledText";
import styles from "./ChatNoMessages.module.scss";
import { FormattedMessage } from "react-intl";

export const ChatNoMessages = () => {
  return (
    <StyledText size="compact" className={styles.container}>
      <p>
        <strong>
          <FormattedMessage
            defaultMessage="No messages in this conversation yet."
            id="Mr5Rqm"
          />
        </strong>
      </p>
      <p>
        <FormattedMessage
          defaultMessage="Messages sent to you will appear here."
          id="vDWHWN"
        />
      </p>
    </StyledText>
  );
};
