import { FormattedMessage } from "react-intl";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import Button, {
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import styles from "./UploadForm.module.scss";
import { navigateBack } from "../../../../state/workspace/create/navigation";
import { addLibraryResource } from "../../../../state/library/uploadFile/actions";
import { navigateToUploadStatusPage } from "../../../../state/library/uploadFile/navigation";
import { getClassId } from "../../../../state/shared/selectors";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import { updateLastUploadedFileId } from "../../../../state/library/uploadStatus/actions";
import UploadFormLicence from "./UploadFormLicence";
import UploadFormTitle from "./UploadFormTitle";
import UploadFormDescription from "./UploadFormDescription";
import UploadFormFile from "./UploadFormFile";
import UploadFormAcknowledgement from "./UploadFormAcknowledgement";
import UploadFormAuthor from "./UploadFormAuthor";
import UploadFormTags from "./UploadFormTags";

const FORM_IDS = {
  h1: "library-upload-content-heading",
  title: "library-upload-content-title",
  file: "library-upload-content-file",
  description: "library-upload-content-description",
  licence: "library-upload-content-licence-radio-group",
  author: "library-upload-content-author",
};

const UPLOAD_FORM_STEP = {
  STEP1: "library-upload-content-step-1",
  STEP2: "library-upload-content-step-2",
};

const POSITION_TYPES = {
  TITLE: "Title",
  FOOTER: "Footer",
};

const UploadForm = ({
  submitForm,
  selectedClassId,
  updateLastUploadedFileId,
}) => {
  const [currentStep, setCurrentStep] = useState(UPLOAD_FORM_STEP.STEP1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [licenceType, setLicenceType] = useState(null);
  const [author, setAuthor] = useState("");
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileId, setUploadedFileId] = useState();
  const [fileMeta, setFileMeta] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);

  const [submitFormError, setSubmitFormError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const dataTestIdPrefix = "lb-UploadContent";

  const queryString = selectedClassId
    ? `?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`
    : "";

  const step1Ref = useRef();
  const step2Ref = useRef();

  // clean up last uploaded file id on mount
  useEffect(() => {
    updateLastUploadedFileId(null);
  }, [updateLastUploadedFileId]);

  const isSubmitDisabled =
    !title ||
    !isFileUploaded ||
    !licenceType ||
    !isAcknowledged ||
    submitInProgress;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitFormError(false);
    setSubmitInProgress(true);

    const formData = {
      title,
      description,
      fileID: uploadedFileId,
      fileName: fileMeta ? fileMeta.name : null,
      LicenceType: licenceType,
      Author: author,
      Authorities: _.map(selectedStandards, "id"),
      Grades: _.map(selectedGrades, "id"),
      ResourceTypes: _.map(selectedResourceTypes, "id"),
      Subjects: _.map(selectedSubjects, "id"),
    };

    submitForm(formData)
      .then((resp) => {
        updateLastUploadedFileId(_.get(resp, "ID", null));
        navigateToUploadStatusPage(queryString);
      })
      .catch((e) => {
        setSubmitFormError(true);
      });
  };

  const uploadButton = (
    <Button
      label="Upload"
      data-test-id={`${dataTestIdPrefix}-SubmitButton`}
      type={BUTTON_TYPES.PRIMARY}
      isDisabled={isSubmitDisabled}
      isLoading={submitInProgress}
      onClick={() => {
        setShowConfirmationModal(true);
      }}
    />
  );

  const NextButton = ({ position }) => {
    return (
      <Button
        label="Next"
        aria-label="Next step"
        data-test-id={`${dataTestIdPrefix}-${position}-NextButton`}
        type={BUTTON_TYPES.PRIMARY}
        isDisabled={isSubmitDisabled}
        onClick={() => {
          setCurrentStep(UPLOAD_FORM_STEP.STEP2);
          _.delay(() => {
            if (step2Ref && step2Ref.current) {
              step2Ref.current.focus();
            }
          }, 200);
        }}
      />
    );
  };

  NextButton.propTypes = {
    position: PropTypes.oneOf(_.values(POSITION_TYPES)).isRequired,
  };

  const backButton = (
    <Button
      label="Back"
      aria-label="Back to previous step"
      data-test-id={`${dataTestIdPrefix}-BackButton`}
      type={BUTTON_TYPES.OUTLINED}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      onClick={() => {
        setCurrentStep(UPLOAD_FORM_STEP.STEP1);
        _.delay(() => {
          if (step1Ref && step1Ref.current) {
            step1Ref.current.focus();
          }
        }, 200);
      }}
    />
  );

  const cancelButton = (
    <Button
      label="Cancel"
      data-test-id={`${dataTestIdPrefix}-CancelButton`}
      type={BUTTON_TYPES.OUTLINED}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      onClick={() => navigateBack()}
    />
  );

  const stepLegend = (
    <legend
      className={styles.fieldsetLegend}
      data-test-id={`${dataTestIdPrefix}-FormLegend`}
    >
      <span
        className={classnames(styles.step, styles.step1)}
        aria-hidden={currentStep === UPLOAD_FORM_STEP.STEP2}
      >
        <span className={styles.bold}>
          <FormattedMessage defaultMessage="Step 1:" id="D8C+bU" />
        </span>{" "}
        Required details
      </span>
      <span
        className={classnames(styles.step, styles.step2, {
          [styles.inactive]: currentStep === UPLOAD_FORM_STEP.STEP1,
        })}
        aria-hidden={currentStep === UPLOAD_FORM_STEP.STEP1}
      >
        <span className={styles.bold}>
          <FormattedMessage defaultMessage="Step 2:" id="nhL8D+" />
        </span>{" "}
        Optional details
      </span>
      <span className={styles.separator} />
    </legend>
  );

  return (
    <>
      <form
        aria-labelledby={FORM_IDS.h1}
        onSubmit={() => {
          setShowConfirmationModal(true);
        }}
        className={styles.root}
      >
        <div className={styles.body}>
          <div className={styles.bodyInner}>
            <div className={styles.titleContainer}>
              <h1 id={FORM_IDS.h1} className={styles.title}>
                <FormattedMessage
                  defaultMessage="Upload a resource"
                  id="HEoUHw"
                />
              </h1>
              {currentStep === UPLOAD_FORM_STEP.STEP1 && (
                <NextButton position={POSITION_TYPES.TITLE} />
              )}
              {currentStep === UPLOAD_FORM_STEP.STEP2 && backButton}
              {currentStep === UPLOAD_FORM_STEP.STEP2 && uploadButton}
            </div>

            {currentStep === UPLOAD_FORM_STEP.STEP1 && (
              <fieldset
                className={styles.fieldset}
                ref={step1Ref}
                tabIndex={-1}
                data-test-id={`${dataTestIdPrefix}-FormStep1`}
              >
                {stepLegend}

                <UploadFormTitle
                  dataTestIdPrefix={dataTestIdPrefix}
                  title={title}
                  onTitleChange={setTitle}
                  id={FORM_IDS.title}
                />

                <UploadFormFile
                  id={FORM_IDS.file}
                  dataTestIdPrefix={dataTestIdPrefix}
                  selectedFile={selectedFile}
                  onSelectedFileChange={setSelectedFile}
                  onUploadedFileIdChange={setUploadedFileId}
                  onFileMetaChange={setFileMeta}
                  onFileUploadedChange={setIsFileUploaded}
                  onError={setSubmitFormError}
                />

                <UploadFormLicence
                  dataTestIdPrefix={dataTestIdPrefix}
                  licenceType={licenceType}
                  onLicenceTypeSelect={setLicenceType}
                  id={FORM_IDS.licence}
                />

                <UploadFormAcknowledgement
                  dataTestIdPrefix={dataTestIdPrefix}
                  isAcknowledged={isAcknowledged}
                  onIsAcknowledgedChange={setIsAcknowledged}
                />
              </fieldset>
            )}

            {currentStep === UPLOAD_FORM_STEP.STEP2 && (
              <fieldset
                className={styles.fieldset}
                ref={step2Ref}
                tabIndex={-1}
                data-test-id={`${dataTestIdPrefix}-FormStep2`}
              >
                {stepLegend}

                <UploadFormDescription
                  dataTestIdPrefix={dataTestIdPrefix}
                  id={FORM_IDS.description}
                  description={description}
                  onDescriptionChange={setDescription}
                />

                <UploadFormAuthor
                  dataTestIdPrefix={dataTestIdPrefix}
                  id={FORM_IDS.author}
                  author={author}
                  onAuthorChange={setAuthor}
                />

                <UploadFormTags
                  dataTestIdPrefix={dataTestIdPrefix}
                  selectedGrades={selectedGrades}
                  selectedResourceTypes={selectedResourceTypes}
                  selectedStandards={selectedStandards}
                  selectedSubjects={selectedSubjects}
                  onSelectedGradesChange={setSelectedGrades}
                  onSelectedResourceTypesChange={setSelectedResourceTypes}
                  onSelectedStandardsChange={setSelectedStandards}
                  onSelectedSubjectsChange={setSelectedSubjects}
                />
              </fieldset>
            )}

            {submitFormError && (
              <Alert type={ALERT_TYPES.FAILURE} className={styles.errorBlock}>
                <span>
                  <FormattedMessage
                    defaultMessage="Sorry, there was a problem completing this upload. Please try again."
                    id="/7qxRV"
                  />
                </span>
              </Alert>
            )}

            <div className={styles.footer}>
              {cancelButton}
              {currentStep === UPLOAD_FORM_STEP.STEP1 && (
                <NextButton position={POSITION_TYPES.FOOTER} />
              )}
              {currentStep === UPLOAD_FORM_STEP.STEP2 && uploadButton}
            </div>
          </div>
        </div>
      </form>
      {showConfirmationModal && (
        <ActionConfirmationDialog
          dataTestPrefix={`${dataTestIdPrefix}-ConfirmationModal`}
          isOpen={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          title="Upload resource?"
          isActionInProgress={submitInProgress}
          content="The PDF you have selected may take a few days to be converted to our digital format and made available in Our Library."
          error={
            submitFormError
              ? "Sorry, there was a problem completing this upload. Please try again."
              : null
          }
          actionLabel="Upload"
          cancelDataTestId={`${dataTestIdPrefix}-ConfirmationModal-Button-Cancel`}
          onAction={handleSubmit}
          actionDataTestId={`${dataTestIdPrefix}-ConfirmationModal-Button-Delete`}
        />
      )}
    </>
  );
};

UploadForm.propTypes = {
  selectedClassId: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  updateLastUploadedFileId: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({
    submitForm: (payload) => dispatch(addLibraryResource({ payload })),
    updateLastUploadedFileId: (id) =>
      dispatch(updateLastUploadedFileId({ id })),
  })
)(UploadForm);
