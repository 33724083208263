import React from "react";
import styles from "./Modal.module.scss";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { Overlay } from "../../atomic/Overlay";
import { useKeyPress } from "../../hooks/useKeyPress";
import { FocusTrap } from "../FocusTrap";
import classNames from "classnames";
import { renderPortal } from "../Portal";

type ModalProps = {
  isOpen: boolean;
  isNarrow?: boolean;
  onRequestClose?(): void;
  children?: React.ReactNode;
  dataTestIdPrefix?: string;
  className?: string;
  usePortal?: boolean;
  /** https://hapara.atlassian.net/browse/PS-1304 - remove this once we have updated our z-index-modal var */
  withIncreasedZIndex?: boolean;
};

export const Modal = ({
  onRequestClose,
  isNarrow,
  isOpen,
  children,
  dataTestIdPrefix,
  className,
  usePortal = false,
  withIncreasedZIndex,
}: ModalProps) => {
  useLockBodyScroll(isOpen);

  const handleRequestClose = () => {
    onRequestClose?.();
  };

  useKeyPress("Escape", handleRequestClose);

  const dataTestId = dataTestIdPrefix ? `${dataTestIdPrefix}-Modal` : undefined;

  const modalContent = isOpen ? (
    <FocusTrap dataTestIdPrefix={dataTestIdPrefix}>
      <div
        className={classNames(styles.modal, {
          [styles.withIncreasedZIndex]: withIncreasedZIndex,
        })}
        data-test-id={dataTestId}
      >
        <Overlay
          dataTestIdPrefix={dataTestIdPrefix}
          onClick={handleRequestClose}
        />
        <div
          className={classNames(
            styles.content,
            { [styles.isNarrow]: isNarrow },
            className
          )}
        >
          {isOpen ? children : null}
        </div>
      </div>
    </FocusTrap>
  ) : null;

  return usePortal ? renderPortal(modalContent) : modalContent;
};
