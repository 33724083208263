import React, { useState, useEffect } from "react";
import { ModalDeprecated } from "@hapara/ui/src/deprecated/ModalDeprecated";
import { Button } from "@hapara/ui/src/atomic/Button";
import { ClassInfoNetworkErrorAlert } from "../../ClassInfoNetworkErrorAlert";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import { ClassInfoCustomAlert } from "../../ClassInfoCustomAlert";
import { useIntl } from "react-intl";

import styles from "./ClassInfoInputModalAddStudents.module.scss";

export type ClassInfoInputModalAddStudentsProps = {
  isOpen: boolean;
  errorMessage?: string;
  isInProgress?: boolean;
  hasNetworkError?: boolean;
  hasDomainValidationError?: boolean;
  hasInputValidationError?: boolean;
  dataTestIdPrefix: string;
  unsuccessfulEmails?: string[];
  onRequestClose: () => void;
  onAddStudent: (inputValue: string) => void;
};

export const ClassInfoInputModalAddStudents: React.FC<
  ClassInfoInputModalAddStudentsProps
> = ({
  isOpen,
  errorMessage,
  isInProgress,
  hasNetworkError,
  dataTestIdPrefix,
  unsuccessfulEmails,
  onRequestClose,
  onAddStudent,
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue("");
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setInputValue(e.target.value);

  const handleAddAction = () => onAddStudent(inputValue);
  const modalInputText =
    errorMessage ||
    intl.formatMessage({
      defaultMessage: "Example: stu1@school.com, stu2@school.com",
      id: "MkWj0Z",
    });

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={onRequestClose}
      className={styles.modal}
      title={intl.formatMessage({
        defaultMessage: "Add student(s) to class",
        id: "DVU7TD",
      })}
      dataTestPrefix={dataTestIdPrefix}
    >
      <div className={styles.inputContainer}>
        <Textarea
          aria-label={intl.formatMessage({
            defaultMessage: "Student email(s)",
            id: "R1wasB",
          })}
          label={intl.formatMessage({
            defaultMessage: "Student email(s)",
            id: "R1wasB",
          })}
          value={inputValue}
          onChange={handleInputChange}
          isErrorMode={!!errorMessage}
          helperText={modalInputText}
          isAutoExpandable
          data-test-id={`${dataTestIdPrefix}_Input`}
          rows={1}
          maxLength={2500}
          showLimitWarning
        />
        {hasNetworkError && (
          <ClassInfoNetworkErrorAlert className={styles.alertBox} />
        )}
        {!!unsuccessfulEmails?.length && (
          <ClassInfoCustomAlert
            className={styles.alertBox}
            emails={unsuccessfulEmails}
          />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          type="secondary"
          label={intl.formatMessage({ defaultMessage: "Cancel", id: "47FYwb" })}
          isDisabled={isInProgress}
          onClick={onRequestClose}
          dataTestId={`${dataTestIdPrefix}_Cancel`}
        />
        <Button
          type="primary"
          label={intl.formatMessage({
            defaultMessage: "Add student(s)",
            id: "VJc2QI",
          })}
          onClick={handleAddAction}
          isLoading={isInProgress}
          isDisabled={isInProgress || !inputValue}
          dataTestId={`${dataTestIdPrefix}_Submit`}
        />
      </div>
    </ModalDeprecated>
  );
};
