import styles from "./GuidedBrowsingSaveTemplate.module.scss";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { GuidedBrowsingSectionContent } from "../GuidedBrowsingSectionContent";
import { useIntl } from "react-intl";
import { Radio } from "@hapara/ui/src/atomic/Radio/Radio";
import { TextField } from "@hapara/ui/src/atomic/TextField/TextField";

interface GuidedBrowsingSaveTemplateProps {
  value: string;
  sessionType: "focus" | "filter";
  onChangeText: (value: string) => void;
  isDuplicateTemplateName?: boolean;
  onSessionTypeChange: (value: "focus" | "filter") => void;
}

export const GuidedBrowsingSaveTemplatePresentation = ({
  onChangeText,
  value,
  sessionType,
  onSessionTypeChange,
  isDuplicateTemplateName,
}: GuidedBrowsingSaveTemplateProps) => {
  const intl = useIntl();

  return (
    <GuidedBrowsingSection>
      <GuidedBrowsingSectionContent
        title={intl.formatMessage({ defaultMessage: "Template", id: "3JxaKs" })}
      >
        <div className={styles.radioContainer} role="radiogroup">
          <div className={styles.radioItem}>
            <Radio
              dataTestIdPrefix="create-template-focus-session-radio"
              name="focusSession"
              value="focus"
              checked={sessionType === "focus"}
              onChange={() => onSessionTypeChange("focus")}
              label={intl.formatMessage({
                defaultMessage: "Focus session",
                id: "mOW1DU",
              })}
            />
          </div>
          <div className={styles.radioItem}>
            <Radio
              className={styles.radio}
              dataTestIdPrefix="create-template-filter-session-radio"
              name="filterSession"
              value="filter"
              checked={sessionType === "filter"}
              onChange={() => onSessionTypeChange("filter")}
              label={intl.formatMessage({
                defaultMessage: "Filter session",
                id: "kKviVf",
              })}
            />
          </div>
        </div>
        <TextField
          hasError={isDuplicateTemplateName}
          label={intl.formatMessage({
            defaultMessage: "Template name",
            id: "Yj2l3k",
          })}
          errorMessage={intl.formatMessage({
            defaultMessage: "Error: This name already exists.",
            id: "cKX91e",
          })}
          value={value}
          isRequired
          placeholder={intl.formatMessage({
            defaultMessage: "Enter template name",
            id: "dCFxw3",
          })}
          onChangeText={onChangeText}
          maxLength={60}
          showLimitWarning
          dataTestId="guided-browsing-template-name-input"
        />
      </GuidedBrowsingSectionContent>
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingSaveTemplate = ({
  value,
  onChangeText,
  isDuplicateTemplateName,
  sessionType,
  onSessionTypeChange,
}: GuidedBrowsingSaveTemplateProps) => {
  return (
    <GuidedBrowsingSaveTemplatePresentation
      sessionType={sessionType}
      onSessionTypeChange={onSessionTypeChange}
      value={value}
      isDuplicateTemplateName={isDuplicateTemplateName}
      onChangeText={onChangeText}
    />
  );
};
