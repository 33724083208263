import React from "react";
import { TableCell, TableHead, TableRow, Divider } from "@mui/material";
import styles from "./ClassInfoTable.module.scss";
import { Button } from "@hapara/ui/src/atomic/Button";
import { CopyButton } from "@hapara/ui/src/atomic/CopyButton";
import Dropdown from "@hapara/ui/src/atomic/Dropdown/Dropdown";

import Search from "@hapara/ui/src/atomic/Search/Search";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox";
import { Person } from "../types";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../hooks/useIsDimension";
import { ClassInfoPermissionsData } from "../../../../apiCalls/classInfo/classInfoTypes";
import { useIntl } from "react-intl";

interface Props {
  people: Person[];
  searchText: string;
  selectedPeople: Person[];
  dataTestIdPrefix: string;
  sortOrder: "asc" | "desc";
  variant: "student" | "teacher";
  hasNYCSpecificUIFlag?: boolean;
  permissions?: ClassInfoPermissionsData;
  onAdd: () => void;
  onReset: (selectedPeople: Person[]) => void;
  onRemove: (selectedPeople: Person[]) => void;
  onSortStudents: () => void;
  onSearchTextChange: (value: string) => void;
  onSelectedPeopleChange: (selectedPeople: Person[]) => void;
}

export const ClassInfoTableHeader: React.FC<Props> = ({
  people,
  variant,
  sortOrder,
  searchText,
  permissions,
  selectedPeople,
  dataTestIdPrefix,
  hasNYCSpecificUIFlag,
  onAdd,
  onReset,
  onRemove,
  onSortStudents,
  onSearchTextChange,
  onSelectedPeopleChange,
}) => {
  const intl = useIntl();
  const { isDimensionOnly: isSmallScreen } = useIsDimension(
    WINDOW_SIZE_TYPES.S
  );

  const isChecked =
    selectedPeople.length === people.length && people.length > 0;

  const hasNoSelectedPeople = selectedPeople.length === 0;
  const shouldDisplayMobileButtons = isSmallScreen && !hasNoSelectedPeople;
  const isRemoveButtonDisabled =
    selectedPeople.length === people.length && variant === "teacher";
  const peopleCountText =
    selectedPeople.length > 0
      ? selectedPeople.length === people.length
        ? intl.formatMessage({
            defaultMessage: "(All selected)",
            id: "MWd9YM",
          })
        : `(${people.length})`
      : intl.formatMessage(
          {
            defaultMessage: "({totalCount})",
            id: "KYt5Jo",
          },
          { totalCount: people.length }
        );

  const emailsToCopy = selectedPeople.map(({ email }) => email).join(", ");
  const selectedPeopleToCopyCSVFormat = selectedPeople
    .map(
      ({ firstName = "", lastName = "", email = "" }) =>
        `"${firstName}","${lastName}","${email}"`
    )
    .join("\n");

  const handleSelectAllPeople = () => {
    if (selectedPeople.length === people.length) {
      onSelectedPeopleChange([]);
    } else {
      onSelectedPeopleChange(people);
    }
  };

  const title =
    variant === "student"
      ? intl.formatMessage({
          defaultMessage: "Students",
          id: "uhwf+D",
        })
      : intl.formatMessage({
          defaultMessage: "Teachers",
          id: "U3zfeg",
        });
  const selectAllLabel = isChecked
    ? intl.formatMessage(
        {
          defaultMessage: "Deselect all {title}",
          id: "KLlqgO",
        },
        { title }
      )
    : intl.formatMessage(
        {
          defaultMessage: "Select all {title}",
          id: "FtN3u/",
        },
        { title }
      );
  const canEditMembers =
    variant === "student"
      ? permissions?.studentsEdit
      : permissions?.teachersEdit;

  const showSelectAll = hasNYCSpecificUIFlag ? variant === "student" : true;
  const canResetPassword = variant === "student" && permissions?.resetPassword;
  const actionButtons = (
    <>
      {variant === "student" && (
        <Dropdown
          id="avatar-dropdown"
          positionType="right"
          triggerComponent={(props: any) => (
            <Button
              label={intl.formatMessage({
                defaultMessage: "Copy",
                id: "4l6vz1",
              })}
              type="tertiary"
              size="small"
              rightIcon="arrow-carvet-down"
              dataTestId={`${dataTestIdPrefix}-copy-dropdown-trigger`}
              {...props}
            />
          )}
          itemComponentList={[
            ({ onClick }) => (
              <CopyButton
                type="tertiary"
                size="small"
                label={intl.formatMessage({
                  defaultMessage: "Emails",
                  id: "AdAi3x",
                })}
                ariaLabel={intl.formatMessage({
                  defaultMessage: "Copy emails to clipboard",
                  id: "L5UwjK",
                })}
                dataTestId={`${dataTestIdPrefix}-copy-email-button`}
                value={emailsToCopy}
                className={styles.dropdownCopyButton}
                onClick={onClick}
              />
            ),
            ({ onClick }) => (
              <CopyButton
                type="tertiary"
                size="small"
                label={intl.formatMessage({
                  defaultMessage: "Names & emails",
                  id: "LhI/gS",
                })}
                ariaLabel={intl.formatMessage({
                  defaultMessage:
                    "Copy names & emails to clipboard in CSV format",
                  id: "gJ3e+s",
                })}
                dataTestId={`${dataTestIdPrefix}-copy-csv-button`}
                value={selectedPeopleToCopyCSVFormat}
                className={styles.dropdownCopyButton}
                onClick={onClick}
              />
            ),
          ]}
        />
      )}
      <>
        {canResetPassword && (
          <Button
            onClick={() => onReset(selectedPeople)}
            type="tertiary"
            size="small"
            label={intl.formatMessage({
              defaultMessage: "Reset password",
              id: "Yy/yDL",
            })}
            data-test-id={`${dataTestIdPrefix}-reset-button`}
          />
        )}
        {canEditMembers && (
          <Button
            onClick={() => onRemove(selectedPeople)}
            data-test-id={`${dataTestIdPrefix}-remove-button`}
            size="small"
            type="tertiary"
            className={styles.removeButton}
            label={intl.formatMessage({
              defaultMessage: "Remove",
              id: "G/yZLu",
            })}
            isDisabled={isRemoveButtonDisabled}
          />
        )}
      </>
    </>
  );

  return (
    <TableHead className={styles.tableHeader}>
      <TableRow>
        <TableCell colSpan={2} className={styles.topHeaderCell}>
          <div className={styles.tableHeaderCellContent}>
            <h2>{title}</h2> {peopleCountText}
            <div className={styles.buttonContainer}>
              {!hasNoSelectedPeople && (
                <>
                  {!isSmallScreen && (
                    <>
                      {actionButtons}
                      {canEditMembers && (
                        <Divider orientation="vertical" flexItem />
                      )}
                    </>
                  )}
                </>
              )}
              {canEditMembers && (
                <Button
                  onClick={onAdd}
                  type="secondary"
                  size="small"
                  icon="plus"
                  label={intl.formatMessage({
                    defaultMessage: "Add",
                    id: "2/2yg+",
                  })}
                  data-test-id={`${dataTestIdPrefix}-add-button`}
                />
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
      {variant === "student" && !isSmallScreen && (
        <TableRow className={styles.searchContainer}>
          <TableCell colSpan={2}>
            <Search
              dataTestIdPrefix={dataTestIdPrefix}
              onChange={onSearchTextChange}
              placeholder={intl.formatMessage({
                defaultMessage: "Search",
                id: "xmcVZ0",
              })}
              value={searchText}
            />
          </TableCell>
        </TableRow>
      )}
      {shouldDisplayMobileButtons && (
        <TableRow>
          <TableCell className={styles.scrollableCell} colSpan={2}>
            <div className={styles.tableHeaderCellContent}>{actionButtons}</div>
          </TableCell>
        </TableRow>
      )}
      <TableRow className={styles.columnHeaderRow}>
        <TableCell align="left">
          <div className={styles.columnHeaderContent}>
            {showSelectAll && (
              <Checkbox
                label=""
                data-test-id={`${dataTestIdPrefix}-select-all-checkbox`}
                checked={isChecked}
                semi={
                  selectedPeople.length > 0 &&
                  selectedPeople.length < people.length
                }
                onChange={handleSelectAllPeople}
                ariaLabel={selectAllLabel}
              />
            )}
            {intl.formatMessage({
              defaultMessage: "Name",
              id: "HAlOn1",
            })}
            {variant === "student" && (
              <Button
                alt={
                  sortOrder === "desc"
                    ? intl.formatMessage({
                        defaultMessage: "Sort A-Z",
                        id: "pJ8r6l",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Sort Z-A",
                        id: "WotVek",
                      })
                }
                size="small"
                type="tertiary"
                icon={sortOrder === "desc" ? "arrow-up" : "arrow-down"}
                onClick={onSortStudents}
                className={styles.sortButton}
                data-test-id={`${dataTestIdPrefix}-sort-button`}
              />
            )}
          </div>
        </TableCell>
        <TableCell align="left">
          {intl.formatMessage({
            defaultMessage: "Email",
            id: "sy+pv5",
          })}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
