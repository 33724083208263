"use client";
import React, { useState, useEffect } from "react";
import styles from "./Tooltip.module.scss";
import { Popover } from "../../atomic/Popover";
import { isEmpty } from "../../utils/isEmpty";

export type TooltipProps = {
  content: React.ReactNode;
  anchor: React.ReactNode;
  offset?: number;
};

export const Tooltip = ({ anchor, content, offset }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const hideTimeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(hideTimeout);
    }
  }, [isOpen]);

  const handleMouseEnter = () => {
    timeoutId = setTimeout(() => setIsOpen(true), 200);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsOpen(false);
  };

  return (
    <Popover
      offset={offset}
      placement="top"
      anchor={
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {anchor}
        </span>
      }
      isOpen={isVisible}
      onRequestClose={() => setIsOpen(false)}
    >
      {!isEmpty(content) ? (
        <div
          className={styles.tooltip}
          data-show={isOpen}
          data-test-id="tooltip-content"
        >
          {content}
        </div>
      ) : null}
    </Popover>
  );
};
