import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { ClassInfoTableBodyRow } from "./ClassInfoTableBodyRow";
import styles from "./ClassInfoTable.module.scss";
import { Person } from "../types";
import { Button } from "@hapara/ui/src/atomic/Button";
import { ClassInfoPermissionsData } from "../../../../apiCalls/classInfo/classInfoTypes";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  people?: Person[];
  searchText: string;
  selectedPeople: Person[];
  dataTestIdPrefix: string;
  permissions?: ClassInfoPermissionsData;
  hasNYCSpecificUIFlag?: boolean;
  onAdd: () => void;
  onSelectedPeopleChange: (selectedPeople: Person[]) => void;
  variant: "student" | "teacher";
}

export const ClassInfoTableBody: React.FC<Props> = ({
  people,
  searchText,
  selectedPeople,
  dataTestIdPrefix,
  hasNYCSpecificUIFlag,
  permissions,
  variant,
  onAdd,
  onSelectedPeopleChange,
}) => {
  const intl = useIntl();
  const hasNoPeople = people?.length === 0;
  const hasSearchResults = searchText !== "" && hasNoPeople;

  const canSelectPeople = hasNYCSpecificUIFlag
    ? variant === "student"
    : permissions?.teachersEdit ||
      permissions?.studentsEdit ||
      (permissions?.resetPassword && variant === "student");

  const canEditMembers =
    variant === "student"
      ? permissions?.studentsEdit
      : permissions?.teachersEdit;

  const handleCheckboxChange = (person: Person) => {
    const selectedIndex = selectedPeople?.indexOf(person);
    let newSelectedPeople: Person[] = [];

    if (selectedIndex === -1) {
      newSelectedPeople = [...selectedPeople, person];
    } else {
      newSelectedPeople = selectedPeople.filter(
        (selectedPerson) => selectedPerson !== person
      );
    }

    onSelectedPeopleChange(newSelectedPeople);
  };

  return (
    <TableBody>
      {hasNoPeople ? (
        <TableRow>
          <TableCell colSpan={2}>
            <div
              className={styles.noResultsContainer}
              data-test-id={`${dataTestIdPrefix}-empty-state`}
            >
              {!hasSearchResults ? (
                <>
                  <p>
                    {variant === "student" ? (
                      <FormattedMessage
                        defaultMessage="There are no students in this class."
                        id="uZ0yUd"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="There are no teachers in this class."
                        id="ZCZ+w7"
                      />
                    )}
                  </p>
                  {canEditMembers && (
                    <Button
                      onClick={onAdd}
                      type="secondary"
                      size="small"
                      icon="plus"
                      label={intl.formatMessage({
                        defaultMessage: "Add",
                        id: "2/2yg+",
                      })}
                      className={styles.noStudentsButton}
                      data-test-id={`${dataTestIdPrefix}-add-button`}
                    />
                  )}
                </>
              ) : (
                <p>
                  <FormattedMessage
                    defaultMessage={`No results found for "{searchText}".`}
                    id="jm21Xs"
                    values={{ searchText }}
                  />
                </p>
              )}
            </div>
          </TableCell>
        </TableRow>
      ) : (
        people?.map((person) => (
          <ClassInfoTableBodyRow
            person={person}
            key={person.name}
            selectedPeople={selectedPeople}
            dataTestIdPrefix={dataTestIdPrefix}
            onCheckboxChange={handleCheckboxChange}
            isSelectable={!!canSelectPeople}
          />
        ))
      )}
    </TableBody>
  );
};
