import React from "react";
import _ from "lodash";
import styles from "./FocusedSessionSettings.module.scss";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";
import { SESSION_RECIPIENT_TYPES } from "../../../../../../state/highlights/sessions/types";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const OPTION_TYPES = {
  KEEP_LINKS: "keep-links",
  RESTORE_TABS: "restore-tabs",
};

const FocusedSessionSettings = ({
  sessionId,
  sessionKeepAllTabs,
  sessionRestoreOriginal,
  sessionLinks,
  sessionRecipientType,
  sessionRecipientGroups,
  sessionRecipientStudents,
  onSessionUpdate,
}) => {
  const intl = useIntl();
  const handleOptionChange = (value, type) => {
    // construct new session data
    const newSession = {
      ID: sessionId,
      Links: sessionLinks,
      StudentIDS: sessionRecipientStudents,
      EndSessionKeepAllTabs:
        type === OPTION_TYPES.KEEP_LINKS ? value : sessionKeepAllTabs,
      EndSessionRestoreOriginal:
        type === OPTION_TYPES.RESTORE_TABS ? value : sessionRestoreOriginal,
    };

    if (sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      newSession.Groups = sessionRecipientGroups;
    }

    onSessionUpdate(newSession);
  };

  return (
    <div className={styles.root}>
      <div className={styles.settingsHeader}>
        <FormattedMessage defaultMessage="At the end of session:" id="ImEfJP" />
      </div>

      <div className={styles.setting}>
        <Toggle
          explanatoryText={intl.formatMessage({
            defaultMessage:
              "Keep all links that were introduced in this session open.",
            id: "HIiC67",
          })}
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          toggleValues={{
            left: {
              label: intl.formatMessage({
                defaultMessage: "No",
                id: "oUWADl",
              }),
            },
            right: {
              label: intl.formatMessage({
                defaultMessage: "Yes",
                id: "a5msuh",
              }),
            },
          }}
          value={sessionKeepAllTabs}
          onChange={(value) =>
            handleOptionChange(value, OPTION_TYPES.KEEP_LINKS)
          }
          dataTestIdPrefix="Hl-EditActiveSession-KeepAllLinksOpen"
        />
      </div>

      <div className={styles.setting}>
        <Toggle
          explanatoryText="Restore students’ original tabs."
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          toggleValues={{
            left: {
              label: intl.formatMessage({
                defaultMessage: "No",
                id: "oUWADl",
              }),
            },
            right: {
              label: intl.formatMessage({
                defaultMessage: "Yes",
                id: "a5msuh",
              }),
            },
          }}
          value={sessionRestoreOriginal}
          onChange={(value) =>
            handleOptionChange(value, OPTION_TYPES.RESTORE_TABS)
          }
          dataTestIdPrefix="Hl-EditActiveSession-RestoreOriginalTabs"
        />
      </div>
    </div>
  );
};

FocusedSessionSettings.propTypes = {
  sessionId: PropTypes.string.isRequired,
  sessionKeepAllTabs: PropTypes.bool.isRequired,
  sessionRestoreOriginal: PropTypes.bool.isRequired,
  sessionLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES))
    .isRequired,
  sessionRecipientGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientStudents: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSessionUpdate: PropTypes.func.isRequired,
};

export default FocusedSessionSettings;
