import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Picker, BaseEmoji, CategoryName } from "emoji-mart";
import PopoverDeprecated, {
  POPOVER_TOP,
  POPOVER_LEFT,
  POPOVER_BOTTOM,
} from "../../deprecated/PopoverDeprecated/PopoverDeprecated";
import styles from "./Emoji.module.scss";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonOutlineType,
} from "../Button/Button";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "../Button/Button";
import "emoji-mart/css/emoji-mart.css";
import "./Emoji.css";

const EMOJI_FILTER_ALLOW_LIST = [
  "1f44d",
  "1f600",
  "1f606",
  "1f929",
  "1f914",
  "1f92b",
  "1f44f",
  "1f442",
  "1f440",
  "2764-fe0f",
  "1f4af",
  "1f389",
  "231b",
  "23f1-fe0f",
  "1f31f",
  "1f507",
  "1f30f",
  "270d-fe0f",
  "1f9ea",
  "1f3c3",
  "1f3a8",
  "1f3b6",
  "2797",
  "1f5d3-fe0f",
  "1f4da",
];

const ORDERED_CATEGORIES: CategoryName[] = [
  "search",
  "custom",
  "people",
  "nature",
  "foods",
  "activity",
  "places",
  "objects",
  "symbols",
  "flags",
];
interface EmojiProps {
  emojiTitle?: string;
  emojiIcon?: string; // displayed by the side of the emoji title
  showSkinTones?: boolean;
  showPreview?: boolean;
  emojiSize: number;
  skinEmoji?: string;
  onTextChange: (emoji: BaseEmoji) => void;
  buttonIcon: string;
  buttonType: ButtonType;
  buttonSize: ButtonSize;
  buttonOutlineType: ButtonOutlineType;
  buttonLabel: string;
  autoFocus: boolean;
  perLine?: number;
}

const Emoji = ({
  emojiTitle,
  emojiIcon = "hand",
  showSkinTones = true,
  showPreview = true,
  emojiSize = 25,
  skinEmoji = "hand",
  onTextChange = () => null,
  buttonIcon = "smiley-face",
  buttonType = BUTTON_TYPES.OUTLINED,
  buttonOutlineType = BUTTON_OUTLINE_TYPES.SOLID,
  buttonSize = BUTTON_SIZES.SMALL,
  buttonLabel,
  autoFocus = true,
  perLine = 8,
}: EmojiProps): JSX.Element => {
  const intl = useIntl();

  const defaultEmojiTitle = intl.formatMessage({
    defaultMessage: "Emoji",
    id: "34NuzG",
  });

  const defaultButtonLabel = intl.formatMessage({
    defaultMessage: "Add emoji",
    id: "nYSZCW",
  });

  const [open, setOpen] = useState<boolean>(false);

  const handleSelectEmoji = (emoji: BaseEmoji) => {
    setOpen(false);
    onTextChange(emoji);
  };

  const RenderEmojiBox = (
    // @ts-ignore
    <Picker
      title={emojiTitle ?? defaultEmojiTitle}
      emoji={emojiIcon}
      showSkinTones={showSkinTones}
      showPreview={showPreview}
      emojiSize={emojiSize} // this size is used for the emoji window how big the window should be
      skinEmoji={skinEmoji}
      onSelect={handleSelectEmoji}
      useButton={true}
      autoFocus={autoFocus}
      perLine={perLine}
      emojisToShowFilter={(emoji: BaseEmoji) =>
        EMOJI_FILTER_ALLOW_LIST.includes(emoji.unified.toLocaleLowerCase())
      }
      include={ORDERED_CATEGORIES}
    />
  );

  return (
    <PopoverDeprecated
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      content={RenderEmojiBox}
      anchorOrigin={{ vertical: POPOVER_TOP, horizontal: POPOVER_LEFT }}
      transformOrigin={{ vertical: POPOVER_BOTTOM, horizontal: POPOVER_LEFT }}
    >
      <Button
        className={styles.emojiBtn}
        icon={buttonIcon}
        type={buttonType}
        size={buttonSize}
        outlineType={buttonOutlineType}
        label={buttonLabel ?? defaultButtonLabel}
        dataTestId="Popover-Emoji-TriggerButton"
        onClick={() => {
          setOpen(true);
        }}
        aria-label={defaultButtonLabel}
        aria-haspopup={true}
        aria-expanded={open}
      ></Button>
    </PopoverDeprecated>
  );
};
export default Emoji;
export type { EmojiProps };
