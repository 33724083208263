import React, { useEffect } from "react";

type OutsideClickProps = {
  children: React.ReactNode;
  onOutsideClick?(): void;
};

export const OutsideClick = ({
  children,
  onOutsideClick,
}: OutsideClickProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const handleClick = (e: MouseEvent) => {
    if (!ref.current?.contains(e.target as Node)) {
      onOutsideClick?.();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};
