import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./GradingAssigneeInfoPanel.module.scss";
import {
  MYWORKSPACES_GRADING_ACTIVITY_TYPES,
  MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
} from "../../../../../state/workspace/grading/types";
import { getGradingAssigneeData } from "../../../../../state/workspace/grading/actions";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  getGradingActivity,
  getAssigneeDataLoading,
  getGradingSelectedArtefactId,
  getAssigneeData,
  getGradingCard,
  cardNotValid,
  artefactNotValid,
  isActivityEligible,
  isAssigneeListEmpty,
} from "../../../../../state/workspace/grading/selectors";
import { getUserEmail } from "../../../../../state/user/selectors";
import GradingWidgetStarted from "../GradingWidgetStarted/GradingWidgetStarted";
import GradingWidgetSubmitted from "../GradingWidgetSubmitted/GradingWidgetSubmitted";
import GradingWidgetAssessed from "../GradingWidgetAssessed/GradingWidgetAssessed";
import GradingActivityHistory from "../GradingActivityHistory/GradingActivityHistory";
import GradingAssigneeInfoPanelLoading from "../GradingAssigneeInfoPanelLoading/GradingAssigneeInfoPanelLoading";
import GradingDocumentLink from "../GradingDocumentLink/GradingDocumentLink";
import sha256 from "hash.js/lib/hash/sha/256";

export const GradingAssigneeInfoPanel = ({
  activity,
  assignee,
  isAssigneeListEmpty,
  assigneeDataLoading,
  cardNotValid,
  artefactNotValid,
  selectedArtefactId,
  isActivityEligible,
  isCardLoaded,
  getGradingAssigneeData,
  userEmail,
}) => {
  const [localStoageKey, setLocalStoageKey] = useState("");
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  useEffect(() => {
    const key = `${userEmail}_isPanelOpen`;
    const hashedKey = sha256().update(key).digest("hex");
    setLocalStoageKey(hashedKey);
    const isPanelOpenLocalStorage =
      !hashedKey || localStorage.getItem(hashedKey) === null
        ? true
        : JSON.parse(localStorage.getItem(hashedKey));
    setIsPanelOpen(isPanelOpenLocalStorage);
  }, [userEmail]);

  const savePanelState = (st) => {
    localStorage.setItem(localStoageKey, st);
    setIsPanelOpen(st);
  };

  useEffect(() => {
    if (
      isCardLoaded &&
      selectedArtefactId &&
      !artefactNotValid &&
      !assignee &&
      !assigneeDataLoading
    ) {
      getGradingAssigneeData();
    }
  }, [
    selectedArtefactId,
    isCardLoaded,
    assignee,
    assigneeDataLoading,
    getGradingAssigneeData,
    artefactNotValid,
  ]);

  if (
    cardNotValid ||
    !isActivityEligible ||
    artefactNotValid ||
    isAssigneeListEmpty
  )
    return null;

  if (!isCardLoaded || !assignee) {
    return (
      <aside className={styles.root}>
        <div className={styles.collapsePanel}>
          <Button
            icon={isPanelOpen ? "arrow-angle-collapse" : "arrow-angle-expand"}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            aria-label={
              isPanelOpen ? "Collapse grading tool" : "Expand grading tool"
            }
            data-test-id={
              isPanelOpen
                ? "hs-Workspace-Grading-CollapsePanel"
                : "hs-Workspace-Grading-ExpandPanel"
            }
            className={styles.collapseButton}
            onClick={() => {
              savePanelState(!isPanelOpen);
            }}
          />
        </div>
        {isPanelOpen && <GradingAssigneeInfoPanelLoading />}
      </aside>
    );
  }

  const getGradingWidget = () => {
    switch (activity) {
      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.SUBMITTED:
      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.GROUP_SUBMITTED:
      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.REVISED:
      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.GROUP_REVISED:
        return <GradingWidgetSubmitted />;

      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.ASSESSED:
      case MYWORKSPACES_GRADING_ACTIVITY_TYPES.GROUP_ASSESSED:
        return <GradingWidgetAssessed />;

      default:
        return <GradingWidgetStarted />;
    }
  };

  return (
    <aside className={styles.root}>
      <div className={styles.collapsePanel}>
        <Button
          icon={isPanelOpen ? "arrow-angle-collapse" : "arrow-angle-expand"}
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          aria-label={
            isPanelOpen ? "Collapse grading tool" : "Expand grading tool"
          }
          data-test-id={
            isPanelOpen
              ? "hs-Workspace-Grading-CollapsePanel"
              : "hs-Workspace-Grading-ExpandPanel"
          }
          className={styles.collapseButton}
          onClick={() => {
            savePanelState(!isPanelOpen);
          }}
        />
      </div>
      {isPanelOpen && (
        <div className={styles.gradePanel}>
          <GradingDocumentLink />
          {getGradingWidget()}
          <GradingActivityHistory />
        </div>
      )}
    </aside>
  );
};

GradingAssigneeInfoPanel.propTypes = {
  activity: PropTypes.string,
  assignee: MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
  isAssigneeListEmpty: PropTypes.bool,
  assigneeDataLoading: PropTypes.bool.isRequired,
  cardNotValid: PropTypes.bool.isRequired,
  artefactNotValid: PropTypes.bool.isRequired,
  isCardLoaded: PropTypes.bool,
  isActivityEligible: PropTypes.bool.isRequired,
  getGradingAssigneeData: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    activity: getGradingActivity(state),
    assignee: getAssigneeData(state),
    isAssigneeListEmpty: isAssigneeListEmpty(state),
    assigneeDataLoading: getAssigneeDataLoading(state),
    cardNotValid: cardNotValid(state),
    artefactNotValid: artefactNotValid(state),
    isActivityEligible: isActivityEligible(state),
    isCardLoaded: !!getGradingCard(state),
    selectedArtefactId: getGradingSelectedArtefactId(state),
    userEmail: getUserEmail(state),
  }),
  (dispatch) => ({
    getGradingAssigneeData: () => dispatch(getGradingAssigneeData()),
  })
)(GradingAssigneeInfoPanel);
