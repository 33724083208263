import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";
import DatePicker from "@hapara/ui/src/atomic/DatePicker/DatePicker";
import { TimePicker } from "@hapara/ui/src/deprecated/TimePicker/TimePicker";
import { connect } from "react-redux";
import { getMonitoringTime } from "../../../../state/highlights/config/selectors";
import { MONITORING_TIME_TYPE } from "../../../../state/highlights/config/types";
import { PICKER_DISABLED_TYPES } from "../../../../state/highlights/sessions/types";
import {
  getRestrictedMonitoringData,
  pickerDisabledState,
} from "../../../../utils";
import styles from "./ModalFormSchedule.module.scss";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

const ModalFormSchedule = ({
  isScheduledSession,
  onScheduledSessionChange,
  scheduledDate,
  onScheduledDateChange,
  scheduledTime,
  onScheduledTimeChange,
  isScheduledDateError,
  isScheduledTimeError,
  dataTestPrefix,
  monitoringTime,
}) => {
  const intl = useIntl();
  const {
    startOfSchool,
    endOfSchool,
    isSaturdayMonitoringEnabled,
    isSundayMonitoringEnabled,
  } = getRestrictedMonitoringData(monitoringTime);

  const allowedTimeText = _.isEmpty(monitoringTime)
    ? null
    : intl.formatMessage(
        {
          defaultMessage:
            "Sessions can be scheduled {daysOfWeek} to {daysOfWeekEnd}, {startTime} to {endTime}",
          id: "/f6UD3",
        },
        {
          daysOfWeek: isSundayMonitoringEnabled
            ? intl.formatMessage({ defaultMessage: "Sunday", id: "mJR06P" })
            : intl.formatMessage({ defaultMessage: "Monday", id: "azMsfM" }),
          daysOfWeekEnd: isSaturdayMonitoringEnabled
            ? intl.formatMessage({ defaultMessage: "Saturday", id: "WMNHPh" })
            : intl.formatMessage({ defaultMessage: "Friday", id: "QrihTZ" }),
          startTime: startOfSchool.format("LT"),
          endTime: endOfSchool.format("LT"),
        }
      );

  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormScheduleBlock`}
    >
      <fieldset
        className={styles.scheduleToggles}
        aria-label={intl.formatMessage({
          defaultMessage: "Do you want to schedule this session for later?",
          id: "E+Wc4Q",
        })}
      >
        <Toggle
          className={styles.toggle}
          value={isScheduledSession}
          onChange={onScheduledSessionChange}
          explanatoryText={intl.formatMessage({
            defaultMessage: "Do you want to schedule this session for later?",
            id: "E+Wc4Q",
          })}
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          toggleValues={{
            left: {
              label: intl.formatMessage({ defaultMessage: "No", id: "oUWADl" }),
            },
            right: {
              label: intl.formatMessage({
                defaultMessage: "Yes",
                id: "a5msuh",
              }),
            },
          }}
          dataTestIdPrefix={`${dataTestPrefix}-FormScheduleBlock`}
        />

        {isScheduledSession && (
          <>
            <div className={styles.pickers}>
              <div className={styles.datePicker}>
                <DatePicker
                  size="large"
                  format="ddd, D MMM YYYY"
                  value={scheduledDate}
                  onChange={(dateMoment, dateString) =>
                    onScheduledDateChange(dateMoment)
                  }
                  allowClear={false}
                  disabledDate={pickerDisabledState(
                    PICKER_DISABLED_TYPES.DATE,
                    monitoringTime
                  )}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select date",
                    id: "gjwFsT",
                  })}
                  className={classnames({
                    [styles.inputError]: isScheduledDateError,
                  })}
                />
              </div>
              <div className={styles.timePicker}>
                <TimePicker
                  size="large"
                  use12Hours={true}
                  format="h:mm a"
                  value={scheduledTime}
                  onChange={(dateMoment, dateString) =>
                    onScheduledTimeChange(dateMoment)
                  }
                  allowClear={false}
                  disabledDate={pickerDisabledState(
                    PICKER_DISABLED_TYPES.TIME,
                    monitoringTime,
                    scheduledDate
                  )}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className={classnames({
                    [styles.inputError]: isScheduledTimeError,
                  })}
                />
              </div>
            </div>
            {(isScheduledDateError || isScheduledTimeError) && (
              <div className={styles.errorNote}>
                <FormattedMessage
                  defaultMessage="Schedule within school times and on school days"
                  id="qlzC6W"
                />
              </div>
            )}
            <div className={styles.allowedTimeNote}>{allowedTimeText}</div>
          </>
        )}
      </fieldset>
    </div>
  );
};

ModalFormSchedule.propTypes = {
  isScheduledSession: PropTypes.bool.isRequired,
  onScheduledSessionChange: PropTypes.func.isRequired,
  scheduledDate: PropTypes.instanceOf(moment),
  onScheduledDateChange: PropTypes.func.isRequired,
  scheduledTime: PropTypes.instanceOf(moment),
  onScheduledTimeChange: PropTypes.func.isRequired,
  isScheduledDateError: PropTypes.bool.isRequired,
  isScheduledTimeError: PropTypes.bool.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  monitoringTime: MONITORING_TIME_TYPE,
};

export default connect((state) => ({
  monitoringTime: getMonitoringTime(state),
}))(ModalFormSchedule);
