import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { WINDOW_SIZE_TYPE_LIST } from "../../../state/app/types";
import { updateWindowSize } from "../../../state/app/actions";

const WindowResize = ({ updateWindowSize }) => {
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let widthToSet = 0;

      _.forEach(WINDOW_SIZE_TYPE_LIST, (size) => {
        if (width >= size) {
          widthToSet = size;
        }
      });

      updateWindowSize(widthToSet);
    };
    const throttledHandleResize = _.throttle(handleResize, 300);

    window.addEventListener("resize", throttledHandleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, []);

  return null;
};

WindowResize.propTypes = {
  updateWindowSize: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
  updateWindowSize: (size) => dispatch(updateWindowSize(size)),
}))(WindowResize);
