import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./TemplateMode.module.scss";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import Input from "@hapara/ui/src/atomic/Input/Input";
import { createNewGuideBrowsingTemplate } from "../../../../../../state/highlights/sessions/actions";
import { getClassId } from "../../../../../../state/shared/selectors";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  SESSION_RECIPIENT_TYPES,
  SESSION_TYPES,
  parseNewTemplateDataToOldFormat,
} from "../../../../../../state/highlights/sessions/types";
import { fetchTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import { FormattedMessage, useIntl } from "react-intl";

const templateNewTitleInputId =
  "hl-GuideBrowsingCreateSession-Templates-NewTitle";

const TemplateMode = ({
  dataTestPrefix,
  sessionType,
  focusType,
  recipientType,
  links,
  duration,
  isEndSessionKeepAllTabs,
  isEndSessionRestoreOriginal,
  onTemplateSave,
  onTemplateCancel,
  selectedClassId,
  createNewGuideBrowsingTemplate,
  loadTemplatesList,
}) => {
  const [newTemplateName, setNewTemplateName] = useState("");
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [isSavingError, setIsSavingError] = useState(false);
  const intl = useIntl();
  const SESSION_TYPES_NAMES = {
    [SESSION_TYPES.FOCUS]: intl.formatMessage({
      defaultMessage: "Focus",
      id: "hsJlm7",
    }),
    [SESSION_TYPES.FILTER]: intl.formatMessage({
      defaultMessage: "Filter",
      id: "9Obw6C",
    }),
    [SESSION_TYPES.LOCK]: intl.formatMessage({
      defaultMessage: "Freeze",
      id: "EgadwQ",
    }),
    [SESSION_TYPES.SHARE_LINKS]: intl.formatMessage({
      defaultMessage: "Links",
      id: "qCcwo3",
    }),
  };
  const sessionTypeName = SESSION_TYPES_NAMES[sessionType];

  const handleSaveTemplate = (e) => {
    e.preventDefault();

    setIsSavingInProgress(true);
    setIsSavingError(false);
    createNewGuideBrowsingTemplate({
      classId: selectedClassId,
      templateName: newTemplateName,
      sessionData: parseNewTemplateDataToOldFormat({
        Name: newTemplateName,
        Type: sessionType,
        Links: links,
        FocusSessionDetails: {
          FocusType: focusType,
          EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
          EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
        },
        RecipientType: recipientType,
        SelectedTime: duration,
      }),
    })
      .then((data) => {
        setIsSavingInProgress(false);
        onTemplateSave(data);
      })
      .catch(() => {
        setIsSavingInProgress(false);
        setIsSavingError(true);
      })
      .finally(() => {
        loadTemplatesList();
      });
  };

  const isSaveButtonDisabled = useMemo(
    () => !newTemplateName,
    [newTemplateName]
  );

  return (
    <div className={styles.root}>
      <label
        htmlFor={templateNewTitleInputId}
        className={styles.saveNewTemplateFieldLabel}
      >
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        <FormattedMessage defaultMessage="Title" id="9a9+ww" />{" "}
        <span className={styles.required}>
          <FormattedMessage defaultMessage="(required)" id="VG94fP" />
        </span>
      </label>
      <Input
        type="text"
        name={templateNewTitleInputId}
        id={templateNewTitleInputId}
        placeholder={intl.formatMessage({
          defaultMessage: "Enter template name",
          id: "dCFxw3",
        })}
        data-test-id={`${dataTestPrefix}-SaveTemplate-TitleInput`}
        aria-required={true}
        value={newTemplateName}
        onChange={(e) => setNewTemplateName(_.get(e, "target.value", ""))}
        className={styles.saveNewTemplateInput}
      />
      <Alerts className={styles.alerts}>
        <Alert type={ALERT_TYPES.FAILURE} isVisible={isSavingError}>
          <FormattedMessage
            defaultMessage="Sorry, there was a problem saving this template. Please try again."
            id="no0cc9"
          />
        </Alert>
      </Alerts>
      <div className={styles.saveNewTemplateActions}>
        <Button
          label={intl.formatMessage({
            defaultMessage: "Save",
            id: "jvo0vs",
          })}
          onClick={handleSaveTemplate}
          data-test-id={`${dataTestPrefix}-Button-SaveTemplate-Save${sessionTypeName}`}
          isDisabled={isSaveButtonDisabled}
          isLoading={isSavingInProgress}
        />
        <Button
          label={intl.formatMessage({
            defaultMessage: "Cancel",
            id: "47FYwb",
          })}
          onClick={onTemplateCancel}
          data-test-id={`${dataTestPrefix}-Button-SaveTemplate-Cancel`}
          type={BUTTON_TYPES.SECONDARY}
          className={styles.saveNewTemplateCancelButton}
        />
      </div>
    </div>
  );
};

TemplateMode.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  focusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  recipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES)).isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number.isRequired,
  isEndSessionKeepAllTabs: PropTypes.bool.isRequired,
  isEndSessionRestoreOriginal: PropTypes.bool.isRequired,
  onTemplateCancel: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func.isRequired,
  // connected props
  selectedClassId: PropTypes.string,
  createNewGuideBrowsingTemplate: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({
    loadTemplatesList: () => dispatch(fetchTemplatesList()),
    createNewGuideBrowsingTemplate: ({ classId, templateName, sessionData }) =>
      dispatch(
        createNewGuideBrowsingTemplate({ classId, templateName, sessionData })
      ),
  })
)(TemplateMode);
