import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  INPUT_DEFAULT_MAX_LENGTH,
} from "@hapara/ui/src/atomic/Input/Input";
import {
  addClass,
  closeAddClassDialog,
} from "../../../../state/dashboard/manage-classes/actions";
import { Permissions } from "../../../../types/shared";
import styles from "./AddClassModal.module.scss";
import { getDomains } from "../../../../state/dashboard/config/selectors";
import { getDefaultDomainSelection } from "../../../../utils";
import ActionDialog from "@hapara/ui/src/atomic/ActionDialog/ActionDialog";
import classnames from "classnames";
import LocationField from "../LocationField/LocationField";
import { useIntl } from "react-intl";

const AddClassModal = () => {
  const intl = useIntl();
  const domains = useSelector(getDomains);

  const [name, setName] = useState("");
  const defaultDomainSelection = getDefaultDomainSelection(
    domains,
    Permissions.DASHBOARD_ADD_CLASS
  );
  const locationSelectedbyDefault =
    !_.isEmpty(defaultDomainSelection.domain) &&
    !_.isEmpty(defaultDomainSelection.school);

  const [selectedDomain, setSelectedDomain] = useState(
    defaultDomainSelection.domain
  );
  const [selectedSchool, setSelectedSchool] = useState(
    defaultDomainSelection.school
  );
  const dispatch = useDispatch();
  const closeAddClassDialogAction = useCallback(() => {
    dispatch(closeAddClassDialog());
  }, [dispatch]);

  const addClassAction = useCallback(() => {
    return dispatch(addClass(name, selectedDomain, selectedSchool));
  }, [dispatch, name, selectedDomain, selectedSchool]);

  const handleOnDomainSchoolChange = useCallback(
    (domain, school) => {
      setSelectedDomain(domain);
      setSelectedSchool(school);
    },
    [setSelectedDomain, setSelectedSchool]
  );
  const [validationError, setValidationError] = useState(
    _.isEmpty(selectedDomain) || _.isEmpty(selectedSchool)
  );
  useEffect(() => {
    setValidationError(
      _.isEmpty(selectedDomain) || _.isEmpty(selectedSchool) || _.isEmpty(name)
    );
  }, [selectedDomain, selectedSchool, name, setValidationError]);

  return (
    <ActionDialog
      title={intl.formatMessage({
        defaultMessage: "Create a class",
        id: "H9IPTD",
      })}
      isOpen={true}
      actionLabel={intl.formatMessage({
        defaultMessage: "Add class",
        id: "v13dyu",
      })}
      errorMessage={intl.formatMessage({
        defaultMessage:
          "Sorry, there was a problem creating this class. Please try again.",
        id: "CnnMBY",
      })}
      onClose={() => closeAddClassDialogAction()}
      isValidationError={validationError}
      onAction={async () => {
        const updated = await addClassAction();
        if (!updated) {
          return false;
        } else {
          closeAddClassDialogAction();
          return true;
        }
      }}
      isBorderHidden={true}
      actionDataTestId="td-ManageClasses-Button-SetColour"
      cancelDataTestId="td-ManageClasses-Button-CancelSetColour"
      dataTestIdPrefix="td-ManageClasses-AddClassModal"
      className={styles.dialog}
    >
      <div className={classnames(styles.addClassModal)}>
        <div>
          <Input
            type="text"
            name="add-class-name"
            id="add-class-name"
            placeholder={intl.formatMessage({
              defaultMessage: "Give your class a name",
              id: "atbFuQ",
            })}
            data-test-id="td-ManageClasses-AddClass-NameInput"
            aria-required={true}
            aria-label="Give your class a name"
            value={name}
            onChange={(e) => setName(_.get(e, "target.value", ""))}
            maxLength={INPUT_DEFAULT_MAX_LENGTH}
            showLimitWarning={true}
          />
        </div>
        {!locationSelectedbyDefault && (
          <LocationField
            label={intl.formatMessage({
              defaultMessage: "Location",
              id: "rvirM2",
            })}
            required={true}
            domains={domains}
            permission={Permissions.DASHBOARD_ADD_CLASS}
            dataTestIdPrefix="td-ManageClasses-AddClass"
            defaultDomainSelection={defaultDomainSelection}
            onDomainSchoolChange={handleOnDomainSchoolChange}
            className={styles.gap}
          />
        )}
      </div>
    </ActionDialog>
  );
};

AddClassModal.propTypes = {};

export default AddClassModal;
