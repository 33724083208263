import { history } from "../../../store";
import { APP_PAGES } from "../../../router/types";
import {
  MYWORKSPACES_FILTER_STATUS_TYPES,
  MYWORKSPACES_PL_ROLES,
  MYWORKSPACES_DEFAULT_LABEL,
  MYWORKSPACES_CATEGORIES,
} from "./types";
import { WORKSPACE_SORTING_TYPES } from "../../consts";
import { SHARED_URL_KEYS } from "../../../shared/navigation-keys";

// object for using default states for some url params

export const MYWORKSPACES_URL_KEYS = {
  status: {
    key: "status",
    dependantsKeysToReset: ["page"],
  },
  pl_role: {
    key: "pl_role",
    dependantsKeysToReset: ["page"],
  },
  page: {
    key: "page",
    dependantsKeysToReset: ["selected_ws"],
  },
  sort: {
    key: "sort",
    dependantsKeysToReset: ["page"],
  },
  label: {
    key: "label",
    dependantsKeysToReset: ["page"],
  },
  query: {
    key: "query",
  },
  search_page: {
    key: "page",
  },
  selected_ws: {
    key: "selected_ws",
    dependantsKeysToReset: ["selected_card", "selected_group"],
  },
  selected_card: {
    key: "selected_card",
    dependantsKeysToReset: ["selected_activity"],
  },
  selected_activity: {
    key: "selected_activity",
  },
  selected_group: {
    key: "selected_group",
  },
};

export const MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES = {
  status: MYWORKSPACES_FILTER_STATUS_TYPES.ALL,
  pl_role: MYWORKSPACES_PL_ROLES.ALL,
  page: 1,
  sort: WORKSPACE_SORTING_TYPES.LAST,
  label: MYWORKSPACES_DEFAULT_LABEL.id,
  query: "",
  search_page: 1,
  selected_ws: null,
  selected_card: null,
  selected_activity: null,
  selected_group: null,
};

export const navigateToCategory = (category) => {
  history.push({
    pathname: `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${category}`,
    search: "",
  });
};

export const navigateToClass = (classId) => {
  history.push({
    pathname: `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.CLASSES.urlKey}`,
    search: `?${SHARED_URL_KEYS.classId.key}=${classId}`,
  });
};
