import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./MultiSelectPills.module.scss";
import classnames from "classnames";
import DropdownMultiSelect, {
  MULTI_SELECT_ITEM_TYPE,
} from "../DropdownMultiSelect/DropdownMultiSelect";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../Button/Button";
import Pill, { PILL_COLOURS, PILL_TYPES, PILL_SIZES } from "../Pill/Pill";

const MultiSelectPills = ({
  className,
  dataTestIdPrefix = "hs-MultiSelectPills",
  items = [],
  selectedItems = [],
  disabledItems = [],
  onSelectedItemsChange = () => {},
  triggerLabel,
  fieldsetLabel,
  listLabel,
  dropdownSlideUp = false,
}) => {
  const triggerComponent = useMemo(
    () => (props) =>
      (
        <Button
          label={triggerLabel}
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.TERTIARY}
          dataTestId={`${dataTestIdPrefix}-SelectTrigger`}
          className={styles.triggerButton}
          {...props}
        />
      ),
    [triggerLabel, dataTestIdPrefix]
  );

  return (
    <fieldset
      aria-label={fieldsetLabel}
      className={classnames(styles.root, className)}
    >
      {selectedItems && selectedItems.length > 0 && (
        <ul className={styles.pillList} aria-label={listLabel}>
          {_.map(selectedItems, (item) => (
            <li className={styles.pillListItem} key={item.id}>
              <Pill
                size={PILL_SIZES.SMALL}
                colour={PILL_COLOURS.PALE_BLUE}
                type={PILL_TYPES.ROUND}
                hasAction={true}
                actionIcon="cross"
                actionLabel={`Remove ${item.name}`}
                onClick={() =>
                  onSelectedItemsChange(
                    _.filter(selectedItems, (i) => i.id !== item.id)
                  )
                }
                dataTestIdPrefix={dataTestIdPrefix}
              >
                {item.name}
              </Pill>
            </li>
          ))}
        </ul>
      )}

      <DropdownMultiSelect
        triggerComponent={triggerComponent}
        items={items}
        selectedItems={selectedItems}
        disabledItems={disabledItems}
        onApply={(newSelectedItems) => onSelectedItemsChange(newSelectedItems)}
        dataTestIdPrefix={dataTestIdPrefix}
        classNameContent={classnames(styles.dropdownContent, {
          [styles.slideUp]: dropdownSlideUp,
        })}
      />
    </fieldset>
  );
};

MultiSelectPills.propTypes = {
  className: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  items: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  selectedItems: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  disabledItems: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  onSelectedItemsChange: PropTypes.func,
  triggerLabel: PropTypes.string.isRequired,
  fieldsetLabel: PropTypes.string.isRequired,
  listLabel: PropTypes.string.isRequired,
  dropdownSlideUp: PropTypes.bool,
};

export default MultiSelectPills;
