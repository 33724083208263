import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteSessionById } from "../../../../state/highlights/sessions/actions";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";

const dataTestPrefix = "hl-ScheduleSessions-DeleteModal";

const ScheduledSessionsDeleteModal = ({
  session,
  deleteSession,
  reloadSessionsList,
  modalClose,
}) => {
  const [errorDeleting, setErrorDeleting] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const deleteAction = () => {
    setErrorDeleting(false);
    setDeleteInProgress(true);
    deleteSession({ sessionId: session.Id })
      .then((r) => {
        setDeleteInProgress(false);
        modalClose();
        reloadSessionsList();
      })
      .catch((e) => {
        setErrorDeleting(true);
      });
  };
  return (
    <ActionConfirmationDialog
      dataTestPrefix={dataTestPrefix}
      isOpen={true}
      onClose={modalClose}
      title="Delete?"
      subTitle={session.Name || "Untitled session"}
      content="This session will be permanently deleted."
      isActionDanger={true}
      error={
        errorDeleting
          ? "Sorry, there was a problem deleting this session. Please try again."
          : null
      }
      cancelDataTestId={`${dataTestPrefix}-Button-Cancel`}
      actionLabel="Delete"
      onAction={deleteAction}
      isActionInProgress={deleteInProgress && !errorDeleting}
      actionDataTestId={`${dataTestPrefix}-Button-Delete`}
    />
  );
};

ScheduledSessionsDeleteModal.propTypes = {
  session: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  deleteSession: PropTypes.func.isRequired,
  reloadSessionsList: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
};

ScheduledSessionsDeleteModal;

export default connect(
  (state) => ({}),
  (dispatch) => ({
    deleteSession: ({ sessionId }) =>
      dispatch(deleteSessionById({ sessionId })),
  })
)(ScheduledSessionsDeleteModal);
