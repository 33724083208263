import React from "react";
import styles from "./Alerts.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";

const Alerts = ({ children, className }) => {
  return (
    <div className={classnames(styles.root, className)} aria-live="polite">
      {children}
    </div>
  );
};

Alerts.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Alerts;
