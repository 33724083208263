import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  STUDENT_TYPE,
  STUDENT_STATUS_TYPES,
} from "../../../../state/highlights/students/types";
import { WEBSOCKET_STATUS_TYPES } from "../../../../state/highlights/HighlightsWebsocket";
import styles from "./StudentTile.module.scss";
import { getBusWebsocketStatus } from "../../../../state/highlights/busWebsocket/selectors";
import { getScreenshotWebsocketStatus } from "../../../../state/highlights/screenshotWebsocket/selectors";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { getFormattedName } from "../../../../utils";
import { getLocationPath } from "../../../../state/router/selectors";
import { STUDENT_TILE_CONTENT_TYPE } from "../../../../state/highlights/config/types.js";
import StudentTileGroups from "../StudentTileGroups/StudentTileGroups";
import StudentBrowserTabs from "../StudentBrowserTabs/StudentBrowserTabs";
import StudentCurrentScreen from "../StudentCurrentScreen/StudentCurrentScreen";
import StudentTileGuideBrowsing from "../StudentTileGuideBrowsing/StudentTileGuideBrowsing";
import StudentTileContextMenu from "../StudentTileContextMenu/StudentTileContextMenu";
import StudentTilePauseState from "../StudentTilePauseState/StudentTilePauseState";
import StudentTileSendMessage from "../StudentTileSendMessage/StudentTileSendMessage";
import StudentTileMultipleDevicesModal from "../StudentTileMultipleDevicesModal/StudentTileMultipleDevicesModal";
import StudentTileMultipleUsersModal from "../StudentTileMultipleUsersModal/StudentTileMultipleUsersModal";
import StudentTileReportProblemModal, {
  REPORT_PROBLEM_MODAL_TYPES,
} from "../StudentTileReportProblemModal/StudentTileReportProblemModal";
import StudentTileMultipleDevicesState, {
  MULTI_DEVICE_TILE_MODES,
} from "../StudentTileMultipleDevicesState/StudentTileMultipleDevicesState";
import StudentTileOfflineState from "../StudentTileOfflineState/StudentTileOfflineState";
import StudentTileRefresh from "../StudentTileRefresh/StudentTileRefresh";
import { sendEvent } from "../../../../state/app/actions";
import { showSendMessageModal } from "../../../../state/highlights/sendMessage/actions";
import { isIgnoredInTabsUrl } from "@hapara/ui/src/components/utils";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import { FormattedMessage, useIntl } from "react-intl";
import { getFFByName } from "../../../../state/app/selectors";

const TILE_STATES = {
  OFFLINE: "OFFLINE",
  CONNECTING: "CONNECTING",
  DATA: "DATA",
  PAUSE: "PAUSE",
  MULTI_DEVICE: "MULTI_DEVICE",
};

const StudentTile = ({
  nameFormat,
  student,
  busWebsocketStatus,
  screenshotWebsocketStatus,
  draggableHandleClassName,
  tileContentType,
  sendEvent,
  showSendMessageModal,
  className,
  userGroups,
  hasGBETileUpdateFlag,
}) => {
  const intl = useIntl();
  const [isMultipleDeviceModalOpen, setIsMultipleDeviceModalOpen] =
    useState(false);
  const [isMultipleLoginsModalOpen, setIsMultipleLoginsModalOpen] =
    useState(false);
  const [isReportProblemModalOpen, setIsReportProblemModalOpen] =
    useState(false);
  const [reportProblemModalType, setReportProblemModalType] = useState();
  const [isBeingRefreshed, setIsBeingRefreshed] = useState(false);

  const isBrowserTabsPage = tileContentType === STUDENT_TILE_CONTENT_TYPE.TABS;
  const isCurrentScreensPage =
    tileContentType === STUDENT_TILE_CONTENT_TYPE.SCREENS;

  const studentId = student.Id;
  const studentEmail = student.Email;
  const studentLastName = student.LastName;
  const studentDisplayName = getFormattedName(nameFormat, student);
  const isOnline = _.get(
    student,
    `instances.${student.currentInstance}.isOnline`,
    false
  );
  const isFirstDataMessageReceived = _.get(
    student,
    `instances.${student.currentInstance}.isFirstDataMessageReceived`,
    false
  );

  const tabs = _.get(student, `instances.${student.currentInstance}.tabs`);
  const filteredTabs = _.filter(tabs, (t) => !isIgnoredInTabsUrl(t.url));

  const currentScreen = _.get(
    student,
    `instances.${student.currentInstance}.currentScreen`
  );

  const instances = _.get(student, "instances", {}) || {};
  const lastSelectedInstance = _.get(student, "lastSelectedInstance");
  const currentInstance = _.get(student, "currentInstance");
  const instanceList = _.filter(_.values(instances), (item) => item.isOnline);

  const studentStatus = _.get(
    student,
    `instances.${student.currentInstance}.status`
  );
  const instanceId = _.get(
    student,
    `instances.${student.currentInstance}.instanceId`
  );

  const users =
    _.get(student, `instances.${student.currentInstance}.users`, []) || [];

  const pauseSessionDetails = _.get(
    student,
    `instances.${student.currentInstance}.lock.pause`,
    null
  );
  const isPaused = _.get(pauseSessionDetails, "active", false);
  const gbSessionDetails = _.get(
    student,
    `instances.${student.currentInstance}.lock.locking`,
    null
  );
  const isInGuideBrowsingSession = _.get(gbSessionDetails, "active", false);

  const getStatusText = (status) => {
    if (status === STUDENT_STATUS_TYPES.TEACHER_LOGGED_IN) {
      return {
        title: intl.formatMessage({
          defaultMessage: "Teacher or administrator account detected",
          id: "0OvOAa",
        }),
        text: intl.formatMessage({
          defaultMessage:
            "Monitoring will be disabled until they log out of this device.",
          id: "UjbmkA",
        }),
      };
    }
    if (status === STUDENT_STATUS_TYPES.IP_NOT_IN_RANGE) {
      return {
        title: intl.formatMessage({
          defaultMessage: "Outside school network",
          id: "uHaUha",
        }),
      };
    }
    if (status === STUDENT_STATUS_TYPES.INVALID_TIME) {
      return {
        title: intl.formatMessage({
          defaultMessage: "School is finished for the day",
          id: "0QjGOw",
        }),
      };
    }
    return null;
  };

  const tileState = useMemo(() => {
    const getTileStateByWebsocketState = (websocketStatus) => {
      switch (websocketStatus) {
        case WEBSOCKET_STATUS_TYPES.CONNECTING:
          return TILE_STATES.CONNECTING;

        case WEBSOCKET_STATUS_TYPES.OFFLINE:
          return TILE_STATES.OFFLINE;

        case WEBSOCKET_STATUS_TYPES.ONLINE: {
          if (!isOnline) {
            if (isBeingRefreshed) {
              return TILE_STATES.CONNECTING;
            }
            return TILE_STATES.OFFLINE;
          } else {
            const isMultipleDeviceState =
              instanceList.length > 1 && !lastSelectedInstance;

            if (isMultipleDeviceState) {
              return TILE_STATES.MULTI_DEVICE;
            }

            if (isFirstDataMessageReceived) {
              return isPaused ? TILE_STATES.PAUSE : TILE_STATES.DATA;
            }

            return TILE_STATES.CONNECTING;
          }
        }
        default:
          return TILE_STATES.OFFLINE;
      }
    };

    return isCurrentScreensPage
      ? getTileStateByWebsocketState(screenshotWebsocketStatus)
      : getTileStateByWebsocketState(busWebsocketStatus);
  }, [
    busWebsocketStatus,
    screenshotWebsocketStatus,
    isCurrentScreensPage,
    isBeingRefreshed,
    isOnline,
    isFirstDataMessageReceived,
    isPaused,
    lastSelectedInstance,
    instanceList.length,
  ]);

  const statusText = useMemo(
    () => getStatusText(studentStatus),
    [studentStatus]
  );

  const isMultiDeviceState = tileState === TILE_STATES.MULTI_DEVICE;
  const isMultiLoginState = users.length > 1;

  return (
    <div
      className={classnames(styles.root, className)}
      data-test-id="hl-StudentTile"
    >
      <StudentTileGroups userGroups={userGroups} studentEmail={studentEmail} />
      <div className={styles.heading}>
        <div
          className={classnames(styles.name, draggableHandleClassName)}
          data-test-id="hl-StudentTile-StudentName"
        >
          <h2>{studentDisplayName}</h2>
        </div>

        <StudentTileRefresh
          isOnline={isOnline}
          studentId={studentId}
          isBeingRefreshed={isBeingRefreshed}
          onBeingRefreshedChange={(value) => setIsBeingRefreshed(value)}
        />

        {isMultipleDeviceModalOpen && (
          <StudentTileMultipleDevicesModal
            instanceList={instanceList}
            studentDisplayName={studentDisplayName}
            currentInstance={currentInstance}
            lastSelectedInstance={lastSelectedInstance}
            studentId={studentId}
            onModalOpen={setIsMultipleDeviceModalOpen}
            isModalOpen={isMultipleDeviceModalOpen}
          />
        )}
        {isMultipleLoginsModalOpen && (
          <StudentTileMultipleUsersModal
            users={users}
            studentDisplayName={studentDisplayName}
            setIsModalOpen={setIsMultipleLoginsModalOpen}
          />
        )}
        {isReportProblemModalOpen && (
          <StudentTileReportProblemModal
            studentId={studentId}
            studentDisplayName={studentDisplayName}
            setIsModalOpen={setIsReportProblemModalOpen}
            modalState={reportProblemModalType}
            showBackButton={
              reportProblemModalType === REPORT_PROBLEM_MODAL_TYPES.HELP
            }
          />
        )}
        {!isMultiDeviceState && (
          <StudentTileGuideBrowsing
            studentId={studentId}
            gbSessionDetails={gbSessionDetails}
            studentDisplayName={studentDisplayName}
          />
        )}
        <StudentTileSendMessage
          isOnline={isOnline && tileState !== TILE_STATES.CONNECTING}
          isPaused={tileState === TILE_STATES.PAUSE}
          isOutsideSchoolNetwork={
            studentStatus === STUDENT_STATUS_TYPES.IP_NOT_IN_RANGE
          }
          isOutsideSchoolHours={
            studentStatus === STUDENT_STATUS_TYPES.INVALID_TIME
          }
          studentId={studentId}
          studentEmail={studentEmail}
          studentDisplayName={studentDisplayName}
          showSendMessageModal={showSendMessageModal}
          hasGBETileUpdateFlag={hasGBETileUpdateFlag}
        />
        <StudentTileContextMenu
          isOnline={isOnline}
          isInGuideBrowsingSession={isInGuideBrowsingSession}
          isPaused={tileState === TILE_STATES.PAUSE}
          isOutsideSchoolNetwork={
            studentStatus === STUDENT_STATUS_TYPES.IP_NOT_IN_RANGE
          }
          isOutsideSchoolHours={
            studentStatus === STUDENT_STATUS_TYPES.INVALID_TIME
          }
          studentId={studentId}
          studentEmail={studentEmail}
          studentDisplayName={studentDisplayName}
          studentLastName={studentLastName}
          reportProblemAction={() => {
            setReportProblemModalType(
              REPORT_PROBLEM_MODAL_TYPES.REPORT_PROBLEM
            );
            setIsReportProblemModalOpen(true);
          }}
        />
      </div>

      <div className={styles.body}>
        {tileState === TILE_STATES.OFFLINE && (
          <StudentTileOfflineState
            studentId={studentId}
            onSendEvent={sendEvent}
            setIsModalOpen={() => {
              setReportProblemModalType(REPORT_PROBLEM_MODAL_TYPES.HELP);
              setIsReportProblemModalOpen(true);
            }}
          />
        )}
        {tileState === TILE_STATES.CONNECTING && (
          <div className={classnames(styles.tabsContainer, styles.withBorder)}>
            <div className={classnames(styles.status, styles.whiteBackground)}>
              <div className={styles.title}>
                <FormattedMessage defaultMessage="Connecting..." id="5y2qWO" />
              </div>
            </div>
          </div>
        )}
        {tileState === TILE_STATES.PAUSE && (
          <StudentTilePauseState studentId={studentId} />
        )}
        {tileState === TILE_STATES.MULTI_DEVICE && (
          <StudentTileMultipleDevicesState
            mode={MULTI_DEVICE_TILE_MODES.SELECT}
            onModalOpen={setIsMultipleDeviceModalOpen}
            deviceCount={instanceList.length}
          />
        )}
        {tileState === TILE_STATES.DATA && (
          <>
            {statusText ? (
              <div className={styles.status}>
                <div className={styles.statusContainer}>
                  <div className={styles.title}>{statusText.title}</div>
                  {statusText.text && (
                    <div className={styles.text}>{statusText.text}</div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {filteredTabs && filteredTabs.length > 0 && (
                  <>
                    {isBrowserTabsPage && (
                      <div
                        className={classnames(
                          styles.tabsContainer,
                          styles.withScroll
                        )}
                      >
                        <StudentBrowserTabs
                          tabs={filteredTabs}
                          studentId={studentId}
                          studentEmail={studentEmail}
                          studentLastName={studentLastName}
                          studentDisplayName={studentDisplayName}
                          instanceId={instanceId}
                        />
                      </div>
                    )}
                    {isCurrentScreensPage && (
                      <StudentCurrentScreen
                        studentId={studentId}
                        studentEmail={studentEmail}
                        studentDisplayName={studentDisplayName}
                        studentLastName={studentLastName}
                        instanceId={instanceId}
                        tabs={tabs}
                        currentScreen={currentScreen}
                        reducedHeight={
                          instanceList.length > 1 || isMultiLoginState
                        }
                      />
                    )}
                  </>
                )}
                {filteredTabs &&
                  !filteredTabs.length &&
                  instanceList.length < 2 && (
                    <div
                      className={classnames(
                        styles.tabsContainer,
                        styles.withBorder
                      )}
                    >
                      <div
                        className={classnames(
                          styles.status,
                          styles.whiteBackground
                        )}
                      >
                        <div className={styles.title}>
                          <FormattedMessage
                            defaultMessage="Currently not browsing"
                            id="ZoZCGL"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {filteredTabs &&
                  !filteredTabs.length &&
                  instanceList.length >= 2 && (
                    <div
                      className={classnames(
                        styles.tabsContainer,
                        styles.withBorder
                      )}
                    >
                      <StudentTileMultipleDevicesState
                        mode={MULTI_DEVICE_TILE_MODES.NOT_BROWSING}
                        onModalOpen={setIsMultipleDeviceModalOpen}
                        deviceCount={instanceList.length}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>

      {instanceList.length > 1 && (
        <div className={styles.footer}>
          <Button
            icon="multiple-devices"
            size={BUTTON_SIZES.SMALL}
            type={BUTTON_TYPES.TERTIARY}
            data-test-id="hl-StudentTile-MultipleDevices-Trigger"
            label={intl.formatMessage({
              defaultMessage: "Multiple devices detected",
              id: "zLyuuN",
            })}
            className={styles.multipleButton}
            onClick={() => {
              setIsMultipleDeviceModalOpen(true);
            }}
          />
        </div>
      )}
      {isMultiLoginState && !(instanceList.length > 1) && (
        <div className={styles.footer}>
          <Button
            icon="multiple-logins"
            size={BUTTON_SIZES.SMALL}
            type={BUTTON_TYPES.TERTIARY}
            data-test-id="hl-StudentTile-MultipleLogins-Trigger"
            label={intl.formatMessage({
              defaultMessage: "Multiple logins detected",
              id: "iNfvSy",
            })}
            className={styles.multipleButton}
            onClick={() => {
              setIsMultipleLoginsModalOpen(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

StudentTile.propTypes = {
  student: STUDENT_TYPE,
  draggableHandleClassName: PropTypes.string,
  tileContentType: PropTypes.oneOf(_.values(STUDENT_TILE_CONTENT_TYPE))
    .isRequired,
  // connected props
  busWebsocketStatus: PropTypes.oneOf(_.values(WEBSOCKET_STATUS_TYPES)),
  screenshotWebsocketStatus: PropTypes.oneOf(_.values(WEBSOCKET_STATUS_TYPES)),
  nameFormat: PropTypes.string.isRequired,
  sendEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
};

export default connect(
  (state) => ({
    busWebsocketStatus: getBusWebsocketStatus(state),
    screenshotWebsocketStatus: getScreenshotWebsocketStatus(state),
    nameFormat: getUserNameFormat(state),
    locationPath: getLocationPath(state),
    hasGBETileUpdateFlag: getFFByName("GBE-02-nav-bar-button-update")(state),
  }),
  (dispatch) => ({
    sendEvent: (message) => dispatch(sendEvent(message)),
    showSendMessageModal: (payload) => dispatch(showSendMessageModal(payload)),
  })
)(StudentTile);
