import React, { useMemo } from "react";
import styles from "./ModalFormGClassroomShare.module.scss";
import { Divider } from "@mui/material";
import classnames from "classnames";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { FILTER_DESCRIPTORS, FILTER_TYPE } from "./GClassroomTypes";
import { useIntl } from "react-intl";

interface GClassroomFilterProps {
  isDisabled: boolean;
  activeCGFilter: string;
  dataTestPrefix: string;
  noNewFilteredItems: boolean;
  isGCMaterialsLoading: boolean;
  hasGCMaterialsAccess: boolean;
  disableMaterialFilter: boolean;
  setFilteredItemCount: (count: number) => void;
  setActiveGCFilter: (filter: string[]) => void;
}

const GClassroomFilter = ({
  isDisabled,
  activeCGFilter,
  dataTestPrefix,
  setActiveGCFilter,
  noNewFilteredItems,
  isGCMaterialsLoading,
  hasGCMaterialsAccess,
  setFilteredItemCount,
  disableMaterialFilter,
}: GClassroomFilterProps) => {
  const handleClick = (filter: string[]) => {
    setActiveGCFilter(filter);
    noNewFilteredItems && setFilteredItemCount(0);
  };
  const intl = useIntl();

  const contentFilters = useMemo(
    () => [
      [intl.formatMessage(FILTER_DESCRIPTORS.ALL), FILTER_TYPE.ALL],
      [
        intl.formatMessage(FILTER_DESCRIPTORS.ASSIGNMENTS),
        FILTER_TYPE.ASSIGNMENT,
      ],
      [
        intl.formatMessage(FILTER_DESCRIPTORS.QUESTIONS),
        FILTER_TYPE.SHORT_ANSWER_QUESTION,
      ],
    ],
    [intl]
  );

  const materialFilter = useMemo(
    () => [
      intl.formatMessage(FILTER_DESCRIPTORS.MATERIALS),
      FILTER_TYPE.MATERIALS,
    ],
    [intl]
  );
  return (
    <div
      role="menubar"
      aria-label={intl.formatMessage({
        defaultMessage: "Classwork filters",
        id: "9TViDR",
      })}
      className={styles.controlWrapper}
    >
      {contentFilters.map((filter) => {
        const [text, type] = filter;
        const isActive = activeCGFilter === type;
        return (
          <Button
            role="menuitemradio"
            tabIndex={0}
            key={type}
            label={text}
            isDisabled={isDisabled}
            size={BUTTON_SIZES.SMALL}
            type={BUTTON_TYPES.TERTIARY}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onClick={() => handleClick(filter)}
            aria-checked={isActive}
            selected={isActive}
            data-test-id={`${dataTestPrefix}-Filter-${text}`}
            className={classnames(styles.filterButton, {
              [styles.activeFilter]: isActive,
            })}
          />
        );
      })}
      {hasGCMaterialsAccess && (
        <div className={styles.materialsButton}>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ margin: 0, background: "#adadb2" }}
            aria-hidden={true}
          />
          <Button
            role="menuitemradio"
            tabIndex={0}
            key={materialFilter[1]}
            label={materialFilter[0]}
            isDisabled={isGCMaterialsLoading || disableMaterialFilter}
            size={BUTTON_SIZES.SMALL}
            type={BUTTON_TYPES.TERTIARY}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onClick={() => handleClick(materialFilter)}
            aria-checked={activeCGFilter === materialFilter[0]}
            data-test-id={`${dataTestPrefix}-Filter-${materialFilter[0]}`}
            className={classnames(styles.filterButton, styles.materialButton, {
              [styles.activeFilter]: activeCGFilter === materialFilter[0],
            })}
          />
        </div>
      )}
    </div>
  );
};

export default GClassroomFilter;
