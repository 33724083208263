import React from "react";
import ReactDOM from "react-dom";
import { initRaygun, onBeforeSendRUM } from "./raygun";
import App from "./components/App";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_ENABLE_MSW === "true"
) {
  require("./mocks/browser");
}

if (process.env.REACT_APP_BUILD_ENV === "development") {
  console.info("Raygun: not enabled in development");
} else {
  initRaygun({
    apiKey: process.env.REACT_APP_RAYGUN_API_KEY,
    version: process.env.REACT_APP_BUILD_NUMBER,
  });
  onBeforeSendRUM();
}

ReactDOM.render(<App />, document.getElementById("root"));

// Hide the SVG sprite generated by 'svg-sprite-loader' for screen readers
const spriteElement = document.getElementById("__SVG_SPRITE_NODE__");
if (spriteElement) {
  spriteElement.setAttribute("aria-hidden", "true");
}
