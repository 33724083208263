import _ from "lodash";
import { createSelector } from "reselect";

export const getCurrentScreenExpandModalInfo = (state) =>
  _.get(state, "highlights.currentScreenExpandModal");

export const getCurrentScreenExpandModalStatus = createSelector(
  getCurrentScreenExpandModalInfo,
  (modal) => _.get(modal, "isOpen", false)
);
