import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Drawer } from "@hapara/ui/src/components/Drawer";
import { Dialog } from "@hapara/ui/src/components/Dialog";
import { Alert } from "@hapara/ui/src/atomic/Alert";
import { GuidedBrowsingAudiencePicker } from "../GuidedBrowsingAudiencePicker";
import { GuidedBrowsingWebsiteControl } from "../GuidedBrowsingWebsiteControl";
import { GuidedBrowsingDuration } from "../GuidedBrowsingDuration";
import { GuidedBrowsingMoreOptions } from "../GuidedBrowsingMoreOptions";
import { GuidedBrowsingSaveTemplate } from "../GuidedBrowsingSaveTemplate";
import { GuidedBrowsingTemplateName } from "../GuidedBrowsingTemplateName";
import { GuidedBrowsingSessionDrawerActions } from "./GuidedBrowsingSessionDrawerActions";
import { GuidedBrowsingSchedule } from "../GuidedBrowsingSchedule";
import { hideGuideBrowsingModal } from "src/state/highlights/sessions/actions";
import { showTemplatesPanel } from "src/state/highlights/guideBrowsingTemplates/actions";
import {
  getGuideBrowsingModalSessionType,
  getGuideBrowsingModalSelectedTemplate,
  getGuideBrowsingModalMode,
  getGuideBrowsingModalSelectedStudent,
} from "src/state/highlights/sessions/selectors";
import { useGuidedBrowsingSession } from "src/components/partial/Highlights/GuidedBrowsing/hooks/useGuidedBrowsingSession";
import { getFFByName } from "src/state/app/selectors";
import styles from "./GuidedBrowsingSessionDrawer.module.scss";

interface GuidedBrowsingSessionDrawerProps {
  isOpen: boolean;
}

export const GuidedBrowsingSessionDrawer: React.FC<
  GuidedBrowsingSessionDrawerProps
> = ({ isOpen }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const sessionTemplate = useSelector(getGuideBrowsingModalSelectedTemplate);
  const guidedBrowsingModalMode = useSelector(getGuideBrowsingModalMode);
  const modalSessionType =
    useSelector(getGuideBrowsingModalSessionType) || "focus";
  const [sessionType, setSessionType] = useState(modalSessionType);
  const selectedStudent = useSelector(getGuideBrowsingModalSelectedStudent);

  const hasRecurringSessionFeatureEnabled = useSelector(
    getFFByName("GBE-04-recurring-sessions")
  );
  const onClose = () => dispatch(hideGuideBrowsingModal());

  const {
    links,
    focusType,
    duration,
    areAnyStudentsInSession,
    isEndSessionKeepAllTabs,
    isEndSessionRestoreOriginal,
    recipientType,
    studentIds,
    groupUrns,
    templateName,
    hasUpdateError,
    isStartButtonDisabled,
    isSaveTemplateButtonDisabled,
    isConfirmScheduleButtonDisabled,
    isScheduleButtonDisabled,
    isUpdateButtonDisabled,
    isDuplicateTemplateName,
    isUpdating,
    schedulingRange,
    setLinks,
    setFocusType,
    setDuration,
    setIsEndSessionKeepAllTabs,
    setIsEndSessionRestoreOriginal,
    setRecipientType,
    setStudentIds,
    setGroupUrns,
    setTemplateName,
    handleScheduleSession,
    handleStartSession,
    handleSaveTemplate,
    handleUpdateTemplate,
    handleCreateScheduledSession,
    handleCancelSchedule,
    handleSetScheduledDate,
    handleSetScheduledTime,
    handleDaySelect,
    handleScheduleNameChange,
    handleScheduleEndDateChange,
    handleToggleRepeatSchedule,
    studentsList,
    userGroups,
    recentTabs,
    scheduledTime,
    scheduledDate,
    hasInvalidDate,
    hasInvalidEndDate,
    hasInvalidTime,
    hasInvalidName,
    scheduleState,
  } = useGuidedBrowsingSession({
    onCancel: onClose,
    sessionType,
  });

  const getModeFromModalMode = (modalMode: string) => {
    const modeMap = {
      EDIT_SESSION_FORM: "edit",
      START_SESSION_FORM: "start",
      SCHEDULE_SESSION_FORM: "schedule",
      CREATE_SESSION_FORM: "create",
      SCHEDULE_TEMPLATE_FORM: "schedule-template",
      CREATE_TEMPLATE_FORM: "create-template",
    };
    if (selectedStudent && modalMode === "CREATE_SESSION_FORM") {
      return "start-student";
    }
    return modeMap[modalMode] || "create";
  };

  const mode = getModeFromModalMode(guidedBrowsingModalMode);

  const getHeading = () => {
    const headings = {
      edit: intl.formatMessage(
        { defaultMessage: "Edit {templateName}", id: "gC73y1" },
        { templateName: sessionTemplate?.Name }
      ),
      start: intl.formatMessage(
        { defaultMessage: "Start {templateName}", id: "1/myt0" },
        { templateName: sessionTemplate?.Name }
      ),
      schedule: intl.formatMessage({
        defaultMessage: "Schedule session",
        id: "rjBOlB",
      }),
      "schedule-template": intl.formatMessage(
        {
          defaultMessage: "Schedule {templateName}",
          id: "t1OosU",
        },
        {
          templateName: templateName,
        }
      ),
      "create-template": intl.formatMessage({
        defaultMessage: "New template",
        id: "lGJaj2",
      }),
      "start-student":
        sessionType === "focus"
          ? intl.formatMessage(
              {
                defaultMessage: "Focus session for {studentName}",
                id: "uUqJZS",
              },
              {
                studentName: selectedStudent?.FullName,
              }
            )
          : intl.formatMessage(
              {
                defaultMessage: "Filter session for {studentName}",
                id: "1PIDct",
              },
              {
                studentName: selectedStudent?.FullName,
              }
            ),
      create:
        sessionType === "focus"
          ? intl.formatMessage({
              defaultMessage: "Create focus session",
              id: "aXxHOJ",
            })
          : intl.formatMessage({
              defaultMessage: "Create filter session",
              id: "VAJqJq",
            }),
    };

    return headings[mode];
  };
  const heading = getHeading();

  const onBack = () => {
    if (mode === "schedule") {
      return handleCancelSchedule();
    }
    onClose();
    dispatch(showTemplatesPanel({ isScheduledPanel: false }));
  };

  const handleSaveAndClose = () => {
    handleSaveTemplate();
    onBack();
  };
  const handleUpdateTemplateAndBack = async () => {
    const success = await handleUpdateTemplate();
    if (success) {
      onBack();
    }
  };

  const renderContent = () => {
    if (mode === "schedule") {
      return (
        <GuidedBrowsingSchedule
          date={scheduledDate}
          time={scheduledTime}
          duration={duration}
          scheduleState={scheduleState}
          hasInvalidDate={hasInvalidDate}
          hasInvalidTime={hasInvalidTime}
          hasInvalidEndDate={hasInvalidEndDate}
          hasInvalidScheduleName={hasInvalidName}
          schedulingRange={schedulingRange}
          onDateChange={handleSetScheduledDate}
          onTimeChange={handleSetScheduledTime}
          hasRecurringSessionFeatureEnabled={
            !!hasRecurringSessionFeatureEnabled
          }
          onDaySelect={handleDaySelect}
          onToggleRepeatSchedule={handleToggleRepeatSchedule}
          onRepeatEndDateChange={handleScheduleEndDateChange}
          onScheduleNameChange={handleScheduleNameChange}
        />
      );
    }

    return (
      <form>
        {mode === "edit" && (
          <GuidedBrowsingTemplateName
            isDuplicateTemplateName={isDuplicateTemplateName}
            value={templateName}
            onChangeText={setTemplateName}
          />
        )}
        {mode === "create-template" && (
          <GuidedBrowsingSaveTemplate
            sessionType={sessionType}
            onSessionTypeChange={setSessionType}
            isDuplicateTemplateName={isDuplicateTemplateName}
            value={templateName}
            onChangeText={setTemplateName}
          />
        )}
        {mode !== "start-student" && (
          <GuidedBrowsingAudiencePicker
            displayRestrictedOptions={
              mode === "edit" || mode === "create-template"
            }
            onGroupsChange={setGroupUrns}
            onStudentsChange={setStudentIds}
            onRecipientTypeChange={setRecipientType}
            recipientType={recipientType}
            students={studentsList}
            userGroups={userGroups}
            groupUrns={groupUrns}
            studentIds={studentIds}
            areAnyStudentsInSession={areAnyStudentsInSession}
          />
        )}
        <GuidedBrowsingDuration
          duration={duration}
          onDurationChange={setDuration}
        />
        <GuidedBrowsingWebsiteControl
          sessionType={sessionType}
          links={links}
          onLinksChange={setLinks}
          recentTabs={recentTabs}
        />

        {sessionType === "focus" && (
          <GuidedBrowsingMoreOptions
            browsingAccess={focusType}
            onBrowsingAccessChange={setFocusType}
            keepLinksOpen={isEndSessionKeepAllTabs}
            restoreTabs={isEndSessionRestoreOriginal}
            onKeepLinksOpenChange={setIsEndSessionKeepAllTabs}
            onRestoreTabsChange={setIsEndSessionRestoreOriginal}
          />
        )}
      </form>
    );
  };

  return (
    <Drawer isOpen={isOpen} dataTestIdPrefix="guided-browsing-session-drawer">
      <Dialog
        onBack={onBack}
        dataTestIdPrefix="guided-browsing-session-dialog"
        className={styles.dialog}
        heading={heading}
        headingStyledAs="h3"
        fullWidth
        footer={
          hasUpdateError ? (
            <Alert type="danger">
              {intl.formatMessage({
                defaultMessage:
                  "An error occurred while updating the template.",
                id: "vBj+nj",
              })}
            </Alert>
          ) : null
        }
        actions={
          <GuidedBrowsingSessionDrawerActions
            mode={mode}
            onBack={onBack}
            onSchedule={
              mode !== "schedule"
                ? handleScheduleSession
                : handleCreateScheduledSession
            }
            sessionType={sessionType}
            onSaveAndClose={handleSaveAndClose}
            onStart={handleStartSession}
            onCancelSchedule={handleCancelSchedule}
            isUpdating={isUpdating}
            onUpdateTemplate={handleUpdateTemplateAndBack}
            isUpdateTemplateButtonDisabled={isUpdateButtonDisabled}
            isStartButtonDisabled={isStartButtonDisabled}
            isSaveTemplateButtonDisabled={isSaveTemplateButtonDisabled}
            isScheduleButtonDisabled={isScheduleButtonDisabled}
            isConfirmScheduleButtonDisabled={isConfirmScheduleButtonDisabled}
          />
        }
      >
        <div className={styles.sectionsWrapper}>{renderContent()}</div>
      </Dialog>
    </Drawer>
  );
};
