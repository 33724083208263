import React from "react";

import styles from "./ChatLayout.module.scss";

type ChatLayoutProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

export const ChatLayout = ({ header, children, footer }: ChatLayoutProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{header}</div>
      <div className={styles.children}>{children}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
};
