import React from "react";
import { LinearProgress as MuiLinearProgress } from "@mui/material";
import styles from "./LinearProgress.module.scss";
import classnames from "classnames";

type LinearProgressProps = {
  className?: string;
};

export const LinearProgress = ({ className }: LinearProgressProps) => {
  return (
    // MUI's LinearProgress sits at 18.5kB GZIP. We can now easily swap this out
    // with a simpler implementation.
    <MuiLinearProgress
      className={classnames(className, styles.root)}
      aria-hidden={true}
    />
  );
};
