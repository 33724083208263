//LIBRARIES
import React, { useEffect, useState, useRef, useCallback } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import _ from "lodash";

//TYPES/CONSTS
import { RESOURCE_AUTH_URL_KEYS } from "../../../../state/library/myResources/navigation";
import { WINDOW_SIZE_TYPES } from "../../../../state/app/types";
import { APP_PAGES } from "../../../../state/router/types";
import {
  RESOURCE_FORMAT,
  TILE_ICONS,
  RESOURCE_DETAILS_THUMBNAIL_SIZE,
} from "../../../../state/resources/types";

//SELECTORS
import { getResourceDetailsModalData } from "../../../../state/resources/selectors";
import { getFFByName, getWindowSize } from "../../../../state/app/selectors";
import {
  hasResourceAddAccess,
  hasResourceUploadAccess,
  hasWorkspaceAddAccess,
} from "../../../../state/library/config/selectors";

//COMPONENTS
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import ResourceDetailsModalTags from "./ResourceDetailsModalTags";
import ActionAddRemoveFromLibrary from "../ActionAddRemoveFromLibrary/ActionAddRemoveFromLibrary";
import ActionAssignToClasses from "../ActionAssignToClasses/ActionAssignToClasses";
import ActionStarUnstar from "../ActionStarUnstar/ActionStarUnstar";
import { MemorisedFocusButton } from "../../MemorisedFocusButton/MemorisedFocusButton";
import ActionEditDetails from "../ActionEditDetails/ActionEditDetails";
import ActionDelete from "../ActionDelete/ActionDelete";
import {
  ButtonLink,
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";

//UITLS | OTHER
import styles from "./ResourceDetailsModalReadMode.module.scss";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";

interface ResourceDetailsModalReadModeProps {
  dataTestPrefix: string;
  hasResourceAddAccess: boolean;
  hasWorkspaceAddAccess: boolean;
  hasResourceUploadAccess: boolean;
  modalData: ResourceItemType;
  windowSize: number;
  isWorkspaceRewriteFF: boolean;
}

export interface ResourceItemType {
  ID: string;
  Title: string;
  Description?: string;
  Link?: string;
  Thumbnail?: string;
  Author?: string;
  Authorities?: string[];
  Grades?: string[];
  Subjects?: string[];
  ResourceTypes?: string[];
  LicenceType?: string;
  EditedOn?: string;
  Statuses?: {
    IsAddedToLibrary?: boolean;
    IsStarred?: boolean;
    IsUploaded?: boolean;
  };
}

const ResourceDetailsModalReadMode: React.FC<
  ResourceDetailsModalReadModeProps
> = ({
  hasResourceAddAccess,
  hasWorkspaceAddAccess,
  hasResourceUploadAccess,
  modalData,
  windowSize,
  dataTestPrefix,
  isWorkspaceRewriteFF,
}: ResourceDetailsModalReadModeProps) => {
  const [isShowMoreVisible, setIsShowMoreVisible] = useState<boolean>(false);
  const [isShowMoreOpen, setIsShowMoreOpen] = useState<boolean>(false);

  const maxDescriptionHeight = 22 * 6; // consistent with css
  const descriptionInnerRef = useRef<HTMLDivElement>(null);

  const itemType = _.get(modalData, "Type", null);
  const resourceId = _.get(modalData, "ID");
  const publisherId = _.get(modalData, "PublisherID");
  const title = _.get(modalData, "Title");
  const link = _.get(modalData, "Link", "");
  const thumbnail = _.get(modalData, "Thumbnail", undefined);
  const description = _.get(modalData, "Description", null);
  const grades = _.get(modalData, "Grades", []) || [];
  const subjects = _.get(modalData, "Subjects", []) || [];
  const standards = _.get(modalData, "Authorities", []) || [];
  const licenceTypeString = _.get(modalData, "LicenceType", null);
  const licenceType = licenceTypeString ? [licenceTypeString] : [];
  const resourceTypes = _.get(modalData, "ResourceTypes", []) || [];
  const isAddedToLibrary = _.get(modalData, "Statuses.IsAddedToLibrary", false);
  const isItemStarred = _.get(modalData, "Statuses.IsStarred", false);
  const isItemUploaded = _.get(modalData, "Statuses.IsUploaded", false);
  const isShared = _.get(modalData, "Statuses.IsShared", false);

  useEffect(() => {
    //delay needed for div ref to be accessible
    _.delay(() => {
      if (
        descriptionInnerRef.current &&
        descriptionInnerRef.current.clientHeight > maxDescriptionHeight
      ) {
        setIsShowMoreVisible(true);
      } else {
        setIsShowMoreVisible(false);
      }
      setIsShowMoreOpen(false);
    }, 0);
  }, [description, maxDescriptionHeight]);

  const onDiscoverPage = window.location.pathname.startsWith(
    APP_PAGES.DISCOVER_BASE.path
  )
    ? true
    : false;

  const getWsURL = () => {
    //Eventually, users should be loading workspaces using new ws page
    //regardless if it's a public or private workspace. Until, we're fully
    //transitioned into it, we're allowing users to view only the public
    //workspaces using new ws page. For now, only the workspaces from discover
    //shall be loaded into new WS page.
    const discoverPageURL = isWorkspaceRewriteFF
      ? `${APP_PAGES.WORKSPACE_BOARDS.path}/${modalData.ID}`
      : `${process.env.REACT_APP_PUBLIC_WORKSPACE_URL}/#/w/${modalData.ID}`;

    if (onDiscoverPage) {
      return discoverPageURL;
    }
    return link;
  };

  const urlToOpen =
    itemType === RESOURCE_FORMAT.RESOURCE
      ? `${APP_PAGES.RESOURCES_AUTH_BASE.path}/${publisherId}?${
          RESOURCE_AUTH_URL_KEYS.redirectUrl.key
        }=${window.encodeURIComponent(link)}`
      : getWsURL();

  const actionsDropdownTrigger = useCallback(
    (props) => (
      <MemorisedFocusButton
        label="Actions"
        rightIcon="arrow-carvet-down"
        type={BUTTON_TYPES.SECONDARY}
        isFullWidth={true}
        data-test-id={`${dataTestPrefix}-ActionsDropdown-TriggerButton`}
        {...props}
      />
    ),
    [dataTestPrefix]
  );

  const isAddRemoveToLibraryAvailable =
    itemType === RESOURCE_FORMAT.RESOURCE &&
    !isItemUploaded &&
    hasResourceAddAccess;

  const isEditResourceAvailable =
    itemType === RESOURCE_FORMAT.RESOURCE &&
    isItemUploaded &&
    hasResourceUploadAccess;

  const isDeleteResourceAvailable =
    itemType === RESOURCE_FORMAT.RESOURCE &&
    isItemUploaded &&
    hasResourceUploadAccess;

  const actionDropdownItems: Array<(props: any) => JSX.Element> = [];

  if (isEditResourceAvailable) {
    actionDropdownItems.push((props) => (
      <ActionEditDetails
        item={modalData}
        dataTestPrefix={dataTestPrefix}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (itemType === RESOURCE_FORMAT.RESOURCE) {
    actionDropdownItems.push((props) => (
      <ActionAssignToClasses
        dataTestPrefix={dataTestPrefix}
        id={resourceId}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
    actionDropdownItems.push((props) => (
      <ActionStarUnstar
        id={resourceId}
        dataTestPrefix={dataTestPrefix}
        isStarred={isItemStarred}
        iconOnly={false}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (isAddRemoveToLibraryAvailable) {
    actionDropdownItems.push((props) => (
      <ActionAddRemoveFromLibrary
        dataTestPrefix={dataTestPrefix}
        isAddedToLibrary={isAddedToLibrary}
        id={resourceId}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (isDeleteResourceAvailable) {
    actionDropdownItems.push((props) => (
      <ActionDelete
        id={resourceId}
        dataTestPrefix={dataTestPrefix}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  // the dropdown mode is only for DRM
  const isActionsToDropdownMode =
    (hasResourceAddAccess || hasWorkspaceAddAccess) &&
    windowSize < WINDOW_SIZE_TYPES.S;

  const isAddRemoveToLibraryButtonVisible =
    isAddRemoveToLibraryAvailable && !isActionsToDropdownMode;

  const isEditResourceButtonVisible =
    isEditResourceAvailable && !isActionsToDropdownMode;

  const isDeleteResourceButtonVisible =
    isDeleteResourceAvailable && !isActionsToDropdownMode;

  const isAssignToClassesStarButtonsVisible =
    itemType === RESOURCE_FORMAT.RESOURCE && !isActionsToDropdownMode;

  const isActionDropdownVisible =
    isActionsToDropdownMode && actionDropdownItems.length > 0;

  const thumbnailSrc =
    itemType === RESOURCE_FORMAT.WORKSPACE
      ? `${thumbnail}&w=${RESOURCE_DETAILS_THUMBNAIL_SIZE * 2}&h=${
          RESOURCE_DETAILS_THUMBNAIL_SIZE * 2
        }`
      : thumbnail;

  return (
    <>
      <div className={styles.coverSection}>
        <div
          className={classnames(styles.imgContainer, {
            [styles.square]: itemType === RESOURCE_FORMAT.WORKSPACE,
          })}
        >
          {thumbnail && (
            <img className={styles.img} src={thumbnailSrc} alt="" />
          )}
          {!thumbnail && (
            <div className={styles.openBookContainer}>
              <HapReactIcon
                className={styles.openBook}
                svg={
                  itemType === RESOURCE_FORMAT.RESOURCE
                    ? TILE_ICONS.RESOURCE
                    : TILE_ICONS.WORKSPACE
                }
                width="100%"
                height="100%"
              />
            </div>
          )}
        </div>
        <ButtonLink
          label={
            itemType === RESOURCE_FORMAT.RESOURCE
              ? "Open resource"
              : "Open workspace"
          }
          rightIcon="arrow-external-link"
          data-test-id={`${dataTestPrefix}-Link-${
            itemType === RESOURCE_FORMAT.RESOURCE
              ? "OpenResource"
              : "OpenWorkspace"
          }`}
          href={urlToOpen}
          target="_blank"
          rel="noopener noreferrer"
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.REGULAR}
          className={styles.openResourceButton}
          aria-label="Open workspace in a new tab"
        />

        {isAddRemoveToLibraryButtonVisible && (
          <ActionAddRemoveFromLibrary
            dataTestPrefix={dataTestPrefix}
            isAddedToLibrary={isAddedToLibrary}
            id={resourceId}
          />
        )}

        {(isEditResourceButtonVisible || isDeleteResourceButtonVisible) && (
          <div className={styles.uploadedResourceContainer}>
            <div className={styles.uploadedResourceTitle}>
              Uploaded resource
            </div>
            {isEditResourceButtonVisible && (
              <ActionEditDetails
                item={modalData}
                dataTestPrefix={dataTestPrefix}
              />
            )}

            {isDeleteResourceButtonVisible && isShared && (
              <ActionDelete id={resourceId} dataTestPrefix={dataTestPrefix} />
            )}
          </div>
        )}
      </div>

      <div className={styles.infoSection}>
        <h1
          className={styles.mainTitle}
          data-test-id={`${dataTestPrefix}-ReadMode-Title`}
        >
          {stripHtmlTagsFromString(title)}
        </h1>

        {isAssignToClassesStarButtonsVisible && (
          <div className={styles.assignToClassesStarButton}>
            <ActionAssignToClasses
              dataTestPrefix={dataTestPrefix}
              id={resourceId}
            />
            <ActionStarUnstar
              id={resourceId}
              dataTestPrefix={dataTestPrefix}
              isStarred={isItemStarred}
              iconOnly={false}
              isDropdownAction={false}
            />
          </div>
        )}

        {isActionDropdownVisible && (
          <Dropdown
            className={styles.actionsDropdown}
            widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
            triggerComponent={actionsDropdownTrigger}
            itemComponentList={actionDropdownItems}
          />
        )}

        {description && (
          <div className={styles.descriptionSection}>
            <h2 className={styles.descriptionTitle}>Description</h2>
            <div
              className={classnames(styles.descriptionText, {
                [styles.collapsed]: !isShowMoreOpen,
              })}
              id="ResourceDetailsModal-descriptionText"
            >
              <div ref={descriptionInnerRef}>
                {stripHtmlTagsFromString(description)}
              </div>
            </div>
            {isShowMoreVisible && (
              <div className={styles.showMoreContainer}>
                <button
                  type="button"
                  className={styles.showMore}
                  onClick={() => setIsShowMoreOpen(!isShowMoreOpen)}
                  aria-expanded={isShowMoreOpen}
                  aria-controls="ResourceDetailsModal-descriptionText"
                  data-test-id={`${dataTestPrefix}-ShowMore-Button`}
                >
                  {isShowMoreOpen ? "Show less" : "Show more"}
                </button>
              </div>
            )}
          </div>
        )}

        <ResourceDetailsModalTags
          itemGrades={grades}
          itemStandards={standards}
          itemSubjects={subjects}
          itemLicenceType={licenceType}
          itemResourceTypes={resourceTypes}
          dataTestPrefix={dataTestPrefix}
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    hasResourceAddAccess: hasResourceAddAccess(state),
    hasWorkspaceAddAccess: hasWorkspaceAddAccess(state),
    hasResourceUploadAccess: hasResourceUploadAccess(state),
    modalData: getResourceDetailsModalData(state),
    windowSize: getWindowSize(state),
    isWorkspaceRewriteFF: getFFByName("HAP-9580-WS-Rewrite-FE")(state),
  }),
  (dispatch) => ({})
)(ResourceDetailsModalReadMode);
