import React from "react";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

type ClassInfoHeaderDropDownProps = {
  dataTestIdPrefix: string;
  children: React.ReactNode[];
};

export const ClassInfoHeaderDropDown: React.FC<
  ClassInfoHeaderDropDownProps
> = ({ dataTestIdPrefix, children }) => {
  // TODO: make shared `Dropdown` component easier to use
  const mappedChildren = React.Children.toArray(children)
    .filter((child) => child !== null && child !== undefined)
    .map((child) => () => child);
  if (mappedChildren.length === 0) {
    return null;
  }

  const intl = useIntl();

  return (
    <Dropdown
      positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
      triggerComponent={(props) => {
        return (
          <Button
            dataTestId={`${dataTestIdPrefix}-Header_dropDownOpen`}
            icon="ellipsis"
            type="outlined"
            outlineType="solid"
            size="small"
            aria-label={intl.formatMessage({
              defaultMessage: "More Actions",
              id: "A7ugfn",
            })}
            {...props}
          />
        );
      }}
      itemComponentList={mappedChildren}
    />
  );
};
