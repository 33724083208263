import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styles from "./NotFoundPage.module.scss";
import fileLogo from "@hapara/assets/src/images/logo-hapara-light.svg";
import fileSheep from "@hapara/assets/src/images/vector-sheepicorn-astronaut.svg";
import fileSpace from "@hapara/assets/src/images/vector-sheepicorn-astronaut-space.svg";
import { ALT_TEXT } from "../../../state/app/types";
import { APP_PAGES } from "../../../state/router/types";

const NotFoundPage = () => {
  const DashboardLink = () => {
    if (
      window.location.hostname.indexOf("analytics.hapara.com") >= 0 ||
      window.location.hostname.indexOf("highlights.teacherdashboard.com") >=
        0 ||
      window.location.hostname.indexOf("analytics-test.hapara.com") >= 0 ||
      window.location.hostname.indexOf(
        "highlights-test.teacherdashboard.com"
      ) >= 0
    ) {
      return (
        <a
          className={styles.link}
          href={`${process.env.REACT_APP_HSUITE_BASE_URL}${APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}`}
          data-test-id="Hs-NotFoundPage-Link-GoToDashboard"
        >
          Go back to <span lang="mi">Hāpara</span> Dashboard
        </a>
      );
    } else {
      return (
        <Link
          className={styles.link}
          to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
          data-test-id="Hs-NotFoundPage-Link-GoToDashboard"
        >
          Go back to <span lang="mi">Hāpara</span> Dashboard
        </Link>
      );
    }
  };

  return (
    <main className={styles.root} data-test-id="Hs-Pages-NotFoundPage">
      <Helmet>
        <title>{APP_PAGES.NOT_FOUND.title}</title>
      </Helmet>
      <div className={styles.header} lang="mi">
        <img src={fileLogo} alt={ALT_TEXT.HAPARA_LOGO} width={100} />
      </div>
      <div className={styles.content}>
        <div className={styles.image}>
          <div className={styles.space}>
            <img
              src={fileSpace}
              width="100%"
              height="100%"
              alt="An illustration of space with stars."
            />
          </div>
          <div className={styles.sheepy}>
            <img
              src={fileSheep}
              width="100%"
              height="100%"
              alt="An illustration of a unicorn sheep with grey wool also known as Hāpara’s sheepicorn is drifting in space. He is wearing a glass space helmet while surrounded by twinkling stars."
            />
          </div>
        </div>
        <div className={styles.text}>
          <h1 className={styles.whoops}>
            Whoops, you’ve reached the edge of the universe!
          </h1>
          <div className={styles.description}>
            Seems like the page you are looking for is no longer here.
          </div>
          <DashboardLink />
        </div>
      </div>
      <div className={styles.footer} />
    </main>
  );
};

export default NotFoundPage;
