import actionTypes from "../actionTypes";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
} from "../storeUtils";

export const INIT_STATE = {
  myClasses: {
    items: null,
    ...DATA_LOADING_STATE_INIT,
  },
  filters: {
    allFilters: null,
    isLeftSidePanelOpen: false,
    ...DATA_LOADING_STATE_INIT,
  },
};

const sharedReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.MY_CLASSES_LOAD_PENDING:
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          items: [],
          ...DATA_LOADING_STATE_PENDING,
        },
      };

    case actionTypes.MY_CLASSES_LOAD_ERROR:
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          items: [],
          ...DATA_LOADING_STATE_ERROR,
        },
      };

    case actionTypes.MY_CLASSES_LOAD_SUCCESS:
      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          items: action.myClasses,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };

    case actionTypes.USER_LABS_PREFS_SAVE:
      return state;

    case actionTypes.USER_LABS_PREFS_SAVE_ERROR:
      console.error(
        `Error saving user labs preferences: ${JSON.stringify(action.error)}`
      );
      return state;

    case actionTypes.RESOURCES_FILTERS_LOAD_PENDING:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...DATA_LOADING_STATE_PENDING,
        },
      };

    case actionTypes.RESOURCES_FILTERS_LOAD_ERROR:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...DATA_LOADING_STATE_ERROR,
          allFilters: [],
        },
      };

    case actionTypes.RESOURCES_FILTERS_LOAD_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...DATA_LOADING_STATE_SUCCESS,
          allFilters: action.payload,
        },
      };

    case actionTypes.RESOURCES_FILTERS_LEFT_PANEL_UPDATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          isLeftSidePanelOpen: action.payload,
        },
      };

    case actionTypes.CLASS_INFO_RENAME_CLASS_SUCCESS: {
      const updatedClasses = state.myClasses.items.map((item) => {
        if (item.id === action.payload.classId) {
          return {
            ...item,
            class_alias: action.payload.newClassName,
          };
        }
        return item;
      });

      return {
        ...state,
        myClasses: {
          ...state.myClasses,
          items: updatedClasses,
        },
      };
    }

    default:
      return state;
  }
};

export default sharedReducer;
