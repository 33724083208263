import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import styles from "./groupsManagementBlock.module.scss";
import _ from "lodash";
import GroupsList from "../groupsListBlock/groupsListBlock";
import GroupDetails from "../groupDetails/groupDetails";
import GroupPreferences from "../groupPreferences/groupPreferences";
import GroupsListMultiple from "../groupsListMultipleBlock/groupsListMultipleBlock";
import { getRandomInt, generateId } from "../utils";
import {
  INLINE_MESSAGE_DISPLAY_DURATION,
  INLINE_MESSAGE_TYPE,
} from "../slidingGroupPanel/slidingGroupPanel";

const GroupsManagementBlock = (props) => {
  const intl = useIntl();
  const [currentPanelContent, setCurrentPanelContent] = useState(
    props.contentType
  );
  const [selectedURN, setSelectedURN] = useState("");
  const [currentColour, setCurrentColour] = useState(
    `groupColour_${getRandomInt(0, 42)}`
  );
  const [errorOccurred, setErrorOccurred] = useState({ id: null, text: "" });
  const [inlineSuccessMessage, setInlineSuccessMessage] = useState({});
  const panel = useRef(null);

  useEffect(() => {
    handleChangeType(props.contentType);

    if (panel.current) {
      panel.current.focus();
    }
  }, [props.contentType]);

  const onActionBack = () => {
    handleChangeType("");
  };

  const getGroupMembers = (group) => {
    let res = {};
    if (group && group.emails) {
      _.each(group.emails, (email) => {
        res[email] = props.students[email];
      });
    }
    return res;
  };

  const handleChangeType = (newType, urn = undefined) => {
    const newURN = urn || "";

    if (newType === "preferences") {
      props.actions.sendProductEvent({
        event_name: `[mod]:Groups.preference.viewed`,
      });
    }

    setCurrentPanelContent(newType);
    setSelectedURN(newURN);
    setErrorOccurred({ id: null, text: "" });
    setInlineSuccessMessage({});

    if (newType === "details" && newURN === "") {
      const newGroup = {
        name: `Group ${props.groups.length + 1}`,
        id: generateId(),
        colourCode: currentColour,
      };

      props.actions
        .handleAddGroup(newGroup)
        .then((urn) => {
          setSelectedURN(urn);
          setCurrentColour(`groupColour_${getRandomInt(0, 42)}`);

          setInlineSuccessMessage({
            icon: "circle-check-fill",
            text: intl.formatMessage({
              defaultMessage: "New group created",
              id: "WWmpqF",
            }),
            type: INLINE_MESSAGE_TYPE.SUCCESS,
          });

          _.delay(() => {
            setInlineSuccessMessage({});
          }, INLINE_MESSAGE_DISPLAY_DURATION);
        })
        .catch(() => {
          setErrorOccurred({
            id: "createGroup",
            text: intl.formatMessage({
              defaultMessage: "Sorry, there is a problem creating this group.",
              id: "dBDQWI",
            }),
          });
        });
    }
  };

  const handleEditGroup = (group) => {
    if (group) {
      handleChangeType("details", group.urn);
    }
  };

  const {
    handleDeleteGroup,
    handleDeleteStudent,
    saveUpdatedGroup,
    onCloseManager,
    handlePreferenceSave,
    onStudentsAdd,
    handleAddToMultipleGroups,
  } = props.actions;

  const {
    groups,
    placement,
    order,
    preferences,
    students,
    selectedStudent,
    tabName,
    views,
    viewId,
  } = props;

  const nameIndex = props.groups.length + 1;

  const currentGroup = _.find(groups, { urn: selectedURN }) || {
    colourCode: currentColour,
  };

  const deleteGroupFromDetails = (group) => {
    return handleDeleteGroup(group).then(() => {
      handleChangeType();
    });
  };

  const currentGroupMembers = currentGroup ? getGroupMembers(currentGroup) : [];

  const dialogLabelId = "hs-group-management-dialog-title";

  return (
    <div className={styles.root} role="dialog" aria-labelledby={dialogLabelId}>
      {currentPanelContent !== "details" &&
        currentPanelContent !== "preferences" &&
        !props.selectedStudent && (
          <GroupsList
            data={groups}
            onClose={onCloseManager}
            onChangeType={(type) => handleChangeType(type)}
            onEditGroup={(group) => handleEditGroup(group)}
            onDeleteGroup={handleDeleteGroup}
            preferences={preferences}
            placement={placement}
            ref={panel}
            dialogLabelId={dialogLabelId}
            tabName={tabName}
            views={views}
            viewId={viewId}
          />
        )}
      {props.selectedStudent && currentPanelContent !== "details" && (
        <GroupsListMultiple
          groups={_.sortBy(groups, "name")}
          selectedStudent={selectedStudent}
          onClose={onCloseManager}
          onAddToMultipleGroups={handleAddToMultipleGroups}
          onCreateNewGroup={() => handleChangeType("details")}
          preferences={preferences}
          placement={placement}
          dialogLabelId={dialogLabelId}
        />
      )}
      {currentPanelContent === "details" && (
        <GroupDetails
          data={currentGroup}
          allStudents={students}
          nameIndex={nameIndex}
          members={currentGroupMembers}
          placement={placement}
          blocked={!selectedURN}
          order={order}
          error={errorOccurred}
          inlineMessage={inlineSuccessMessage}
          onActionBack={onActionBack}
          onClose={onCloseManager}
          onStudentDelete={handleDeleteStudent}
          updateGroup={(colourCode, title, colourChanged, titleChanged) =>
            saveUpdatedGroup(
              selectedURN,
              colourCode,
              title,
              colourChanged,
              titleChanged
            )
          }
          onStudentsAdd={(students) =>
            onStudentsAdd(currentGroup.urn, students)
          }
          handleDeleteGroup={deleteGroupFromDetails}
          ref={panel}
          dialogLabelId={dialogLabelId}
        />
      )}

      {currentPanelContent === "preferences" && (
        <div className={styles.preferencePanel}>
          <GroupPreferences
            preferences={preferences}
            handleActionBack={onActionBack}
            onClose={onCloseManager}
            placement={placement}
            savePreference={handlePreferenceSave}
            dialogLabelId={dialogLabelId}
          />
        </div>
      )}
    </div>
  );
};

GroupsManagementBlock.propTypes = {
  groups: PropTypes.array.isRequired,
  students: PropTypes.object,
  selectedStudent: PropTypes.object,
  preferences: PropTypes.bool,
  order: PropTypes.string.isRequired,
  views: PropTypes.array,
  viewId: PropTypes.string,
  placement: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  classId: PropTypes.string,
  actions: PropTypes.object,
  tabName: PropTypes.string,
};

export default GroupsManagementBlock;
