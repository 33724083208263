import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  resetClassAction,
  resetResetClassState,
} from "../../state/classInfo/actions";

import {
  getIsResetClassLoading,
  getIsResetClassError,
  getIsResetClassLoaded,
} from "../../state/classInfo/selectors";

type UseClassInfoResetClassProps = {
  closeModal: () => void;
  isModalOpen: boolean;
};
export const useClassInfoResetClass = ({
  closeModal,
  isModalOpen,
}: UseClassInfoResetClassProps) => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isResetClassLoaded = useSelector(getIsResetClassLoaded);
  const isResetClassLoading = useSelector(getIsResetClassLoading);
  const hasResetClassError = useSelector(getIsResetClassError);

  const handleResetClass = useCallback(() => {
    dispatch(resetClassAction(classId));
  }, [classId, dispatch]);

  useEffect(() => {
    if (!isResetClassLoading && isResetClassLoaded && !hasResetClassError) {
      closeModal();
    }
  }, [isResetClassLoading, isResetClassLoaded, hasResetClassError]);

  useEffect(() => {
    dispatch(resetResetClassState());
  }, [isModalOpen]);

  return {
    isResetClassLoaded,
    isResetClassLoading,
    hasResetClassError,
    handleResetClass,
  };
};
