import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import styles from "./GuidedBrowsingCard.module.scss";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import { Paragraph } from "@hapara/ui/src/atomic/Paragraph";
import { Button } from "@hapara/ui/src/atomic/Button";
import { FocusIcon } from "@hapara/ui/src/icons/FocusIcon";
import { FilterIcon } from "@hapara/ui/src/icons/FilterIcon";

interface GuidedBrowsingCardProps {
  variant: "filter" | "focus";
  onClick: () => void;
}
export const GuidedBrowsingCard = ({
  variant,
  onClick,
}: GuidedBrowsingCardProps) => {
  const intl = useIntl();
  return (
    <div className={styles.card}>
      <div
        className={classNames(styles.iconWrapper, {
          [styles.focus]: variant === "focus",
        })}
      >
        {variant === "focus" ? (
          <FocusIcon size="lg" />
        ) : (
          <FilterIcon size="lg" />
        )}
      </div>
      <Heading level={2} styledAs="h4" removeBottomMargin removeTopMargin>
        {variant === "focus" ? (
          <FormattedMessage defaultMessage="Focus (Allow)" id="YujXFS" />
        ) : (
          <FormattedMessage defaultMessage="Filter (Block)" id="NSOnc3" />
        )}
      </Heading>
      <Paragraph size="xs">
        {variant === "focus" ? (
          <FormattedMessage
            defaultMessage="Focus student attention to specific websites"
            id="XwCGoi"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Block student access to distracting websites"
            id="k/kQH0"
          />
        )}
      </Paragraph>
      <Button
        size="small"
        icon="plus"
        type="primary"
        aria-label={
          variant === "filter"
            ? intl.formatMessage({
                defaultMessage: "Create filter session",
                id: "VAJqJq",
              })
            : intl.formatMessage({
                defaultMessage: "Create focus session",
                id: "aXxHOJ",
              })
        }
        dataTestId={`create-${variant}-session-button`}
        label={<FormattedMessage defaultMessage="Create" id="VzzYJk" />}
        onClick={onClick}
      />
    </div>
  );
};
