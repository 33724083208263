import actionTypes from "../actionTypes";

export const INIT_STATE = {
  headerOpen: false,
};

const handleHeaderToggle = (state, action) => ({
  ...state,
  headerOpen: action.payload,
});

const headerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HEADER_TOGGLE:
      return handleHeaderToggle(state, action);
    default:
      return state;
  }
};

export default headerReducer;
