import React from "react";
import { createIcon } from "./utils/createIcon";

export const CircleCheckIcon = createIcon(
  <path
    fill="currentColor"
    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm-1-3.5L4.2 8.8c-1-.9.4-2.2 1.3-1.3L6.9 9l3.3-3.3c1-1 2.3.3 1.3 1.3L7 11.5Z"
    fillRule="evenodd"
  />
);
