import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import styles from "./WorkspaceProgressGroupFilter.module.scss";
import classnames from "classnames";
import {
  getActiveItem,
  getActiveItemSelectedGroup,
  getActiveGroupId,
} from "../../../../../state/workspace/myworkspaces/list/selectors";

import {
  pushQueryToHistory,
  replaceQueryInHistory,
} from "../../../../../state/router/navigation";
import {
  wsItemType,
  wsGroupType,
} from "../../../../../state/workspace/myworkspaces/list/types";
import Dropdown from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

export const WorkspaceProgressGroupFilter = ({
  activeItem,
  activeGroupId,
  selectedGroup,
}) => {
  const allGroupsName = "All groups";
  const groups = _.get(activeItem, "Groups", []);

  if (_.isEmpty(groups) || groups.length < 2) return null;

  const triggerColourBarClass = !activeGroupId
    ? styles.allGroups
    : styles[`wsgroupColour_${selectedGroup.ColourIndex}`];

  const handleChangeSelectedItem = (groupId) => {
    if (activeItem.Id) {
      replaceQueryInHistory({ selected_group: groupId });
    } else {
      pushQueryToHistory({ selected_group: groupId });
    }
  };

  return (
    <div className={styles.root}>
      <Dropdown
        classNameContent={styles.dropdownContent}
        triggerComponent={(props) => (
          <button
            type="button"
            className={styles.dropdownTrigger}
            data-test-id="ws-MyWorkspaces-ProgressPanel-GroupsFilter-Open"
            {...props}
          >
            <span
              className={classnames(styles.colourBar, triggerColourBarClass)}
            />
            <span className={styles.name}>
              {!activeGroupId ? allGroupsName : selectedGroup.Title}
            </span>
            <span className={styles.icon}>
              <HapReactIcon svg="arrow-carvet-down" width={16} height={16} />
            </span>
          </button>
        )}
        itemComponentList={_.concat(
          [
            ({ onClick, className, ...rest }) => (
              <button
                type="button"
                className={classnames(className, styles.dropdownItem, {
                  [styles.selected]: !activeGroupId,
                })}
                onClick={() => {
                  handleChangeSelectedItem(null);
                  onClick();
                }}
                data-test-id="ws-MyWorkspaces-ProgressPanel-GroupsFilter-Select"
                {...rest}
              >
                <span
                  className={classnames(styles.colourBar, styles.allGroups)}
                />
                <span className={styles.name}>{allGroupsName}</span>
              </button>
            ),
          ],
          _.map(groups, (item) => ({ onClick, className, ...rest }) => (
            <button
              type="button"
              className={classnames(className, styles.dropdownItem, {
                [styles.selected]: activeGroupId === item.Id,
              })}
              key={item.Id}
              onClick={() => {
                handleChangeSelectedItem(item.Id);
                onClick();
              }}
              data-test-id="ws-MyWorkspaces-ProgressPanel-GroupsFilter-Select"
              {...rest}
            >
              <span
                className={classnames(
                  styles.colourBar,
                  styles[`wsgroupColour_${item.ColourIndex}`]
                )}
              />
              <span className={styles.name}>{item.Title}</span>
            </button>
          ))
        )}
      />
    </div>
  );
};

WorkspaceProgressGroupFilter.propTypes = {
  activeItem: wsItemType,
  selectedGroup: wsGroupType,
  activeGroupId: PropTypes.string,
};

export default connect(
  (state) => ({
    activeItem: getActiveItem(state),
    selectedGroup: getActiveItemSelectedGroup(state),
    activeGroupId: getActiveGroupId(state),
  }),
  null
)(WorkspaceProgressGroupFilter);
