import actionTypes from "../../actionTypes";
import { v4 as uuidv4 } from "uuid";
import { sendMessageToBusWebsocket } from "../busWebsocket/actions";
import { WEBSOCKET_MESSAGE_TYPES } from "../HighlightsWebsocket";
import { STUDENT_SNAPSHOT_MATCH_TYPES } from "../students/types";

export const showCurrentScreenExpandModal =
  ({ studentId, studentEmail, studentLastName, studentDisplayName }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_EXPAND_MODAL_SHOW,
      payload: {
        studentId,
        studentEmail,
        studentLastName,
        studentDisplayName,
      },
    });
  };

export const hideCurrentScreenExpandModal = () => ({
  type: actionTypes.HIGHLIGHTS_EXPAND_MODAL_HIDE,
});

export const currentScreenExpandModalSetEmptySnapshot =
  ({ instanceId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_EXPAND_MODAL_SET_EMPTY_SNAPSHOT,
      payload: {
        instanceId,
      },
    });
  };

export const currentScreenExpandModalClearInstances = () => ({
  type: actionTypes.HIGHLIGHTS_EXPAND_MODAL_CLEAR_INSTANCES,
});

export const getCurrentScreenSnapshot =
  ({ studentId }) =>
  (dispatch) => {
    dispatch(
      sendMessageToBusWebsocket({
        type: WEBSOCKET_MESSAGE_TYPES.TEACHER_SNAPSHOT,
        students: [studentId],
        data: {
          id: uuidv4(),
          match: STUDENT_SNAPSHOT_MATCH_TYPES.URL,
        },
      })
    );
  };
