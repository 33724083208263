import moment from "moment-timezone";
import { UPREF_NAME_FIRST_LAST } from "./state/user/types";
import {
  ARTIFACT_TYPE,
  GOOGLE_DOCS_HOST,
  GOOGLE_DRIVE_HOST,
  GRADING_EMBEDDABLE_DOCUMENT_TYPES,
  MYWORKSPACES_GRADING_DOCUMENT_TYPE,
} from "./state/workspace/grading/types";
import { PICKER_DISABLED_TYPES } from "./state/highlights/sessions/types";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { logger } from "@hapara/logger";

export const PROTOCOL_REGEXP = new RegExp("^(http|https)://", "i");

export const URL_REGEXP =
  /^(https?):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

export const BASE_GMAIL_URL = "https://mail.google.com/mail";

export const getDataTestIdFromRawString = (rawString) => {
  if (!_.isString(rawString)) return "";
  return rawString
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase())
    .replace(/\s+/g, "-");
};

export const domainFromURL = (url) => {
  var parser = document.createElement("a");
  parser.href = url;
  return parser.hostname;
};

export const domainFromEmail = (email) => {
  const parts = (email || "").split("@");
  if (parts.length > 1) {
    return _.trim(parts[1]);
  }
  return "";
};

export const formatDate = (date) => {
  if (moment(date).isSame(new Date(), "year")) {
    return moment(date).format("MMM D");
  }
  return moment(date).format("MMM D, YYYY");
};

// formatting assignee' name
export const getFormattedName = (nameFormat, assignee) => {
  if (!assignee) return "";

  if (assignee.FirstName || assignee.LastName) {
    return nameFormat === UPREF_NAME_FIRST_LAST
      ? `${assignee.FirstName} ${assignee.LastName}`
      : `${assignee.LastName}, ${assignee.FirstName}`;
  }

  return assignee.Name || assignee.Title || "";
};

export const getDocType = (artifact) => {
  if (!artifact) return null;

  return _.find(GRADING_EMBEDDABLE_DOCUMENT_TYPES, (docType) =>
    _.includes(docType.mimeTypes, artifact.MimeType)
  );
};

// for working with grading artifact
const getHref = (artifact = {}) => {
  const uri = artifact.URI || "";
  let href = uri.replace(/^urn:url:/, "");

  if (!/https?:\/\//.test(href)) {
    href = `http://${href}`;
  }

  return { url: href, type: MYWORKSPACES_GRADING_DOCUMENT_TYPE.EXTERNAL };
};

const getDriveUrl = (artifact) => {
  const fileId = artifact.URI.replace(/^urn:gdrive:/, "");
  const embeddableDocType = getDocType(artifact);

  // for images we use preview mode
  const url = embeddableDocType
    ? embeddableDocType === GRADING_EMBEDDABLE_DOCUMENT_TYPES.IMAGE
      ? `${GOOGLE_DRIVE_HOST}/${embeddableDocType.type}/d/${fileId}/preview`
      : `${GOOGLE_DOCS_HOST}/${embeddableDocType.type}/d/${fileId}/edit`
    : `${GOOGLE_DRIVE_HOST}/file/d/${fileId}/view`;

  return embeddableDocType
    ? {
        url,
        type: MYWORKSPACES_GRADING_DOCUMENT_TYPE.EMBEDED,
      }
    : { url, type: MYWORKSPACES_GRADING_DOCUMENT_TYPE.EXTERNAL };
};

export const getUrl = (artifact) => {
  if (!artifact) {
    return undefined;
  }

  return artifact.Type === ARTIFACT_TYPE.GDRIVE
    ? getDriveUrl(artifact)
    : getHref(artifact);
};

export const parseSearchStringToObject = (searchString) => {
  if (searchString) {
    const cleanedSearch = searchString.replace("?", "");
    if (cleanedSearch) {
      return _.chain(cleanedSearch)
        .split("&")
        .map(_.partial(_.split, _, "=", 2))
        .fromPairs()
        .value();
    }
  }
  return {};
};

export const pendoTrack = (name, data) => {
  logger("hsuite", `Pendo: "${name}"`, data);
  const pendo = window.pendo;
  if (pendo && pendo.isReady && pendo.isReady()) {
    return pendo.track(name, data);
  }
  setTimeout(function () {
    pendoTrack(name, data);
  }, 500);
};

export const populateDiscoverSearchEventData = (searchData) => {
  const payload = {};
  payload.guid = searchData.searchGuid || uuidv4();
  payload.iter = searchData.iterCount + 1;

  payload.subjects = searchData.subjects;
  if (searchData.subjects.length) {
    let subjectsFlat = "," + _.join(searchData.subjects, ",") + ",";
    payload.subjectsFlat = subjectsFlat;
  }

  payload.grades = searchData.grades;
  if (searchData.grades.length) {
    let gradesFlat = "," + _.join(searchData.grades, ",") + ",";
    payload.gradesFlat = gradesFlat;
  }

  payload.resourceTypes = searchData.resourceTypes;
  if (searchData.resourceTypes.length) {
    let resourceTypesFlat = "," + _.join(searchData.resourceTypes, ",") + ",";
    payload.resourceTypesFlat = resourceTypesFlat;
  }

  payload.licenceTypes = searchData.licences;
  if (searchData.licences.length) {
    let licenceTypesFlat = "," + _.join(searchData.licences, ",") + ",";
    payload.licenceTypesFlat = licenceTypesFlat;
  }

  payload.standard = searchData.standards;
  payload.keywords = searchData.query;
  payload.page = searchData.page;
  payload.totalResults = searchData.totalResults;

  return payload;
};

export const populateLibrarySearchEventData = (searchData) => {
  const payload = {};
  payload.guid = searchData.searchGuid || uuidv4();
  payload.iter = searchData.iterCount + 1;
  payload.collectonId = searchData.collectonId;
  payload.collectonName = searchData.collectonName;
  payload.subjects = searchData.subjects;
  if (searchData.subjects.length) {
    let subjectsFlat = "," + _.join(searchData.subjects, ",") + ",";
    payload.subjectsFlat = subjectsFlat;
  }
  payload.grades = searchData.grades;
  if (searchData.grades.length) {
    let gradesFlat = "," + _.join(searchData.grades, ",") + ",";
    payload.gradesFlat = gradesFlat;
  }
  payload.resourceTypes = searchData.resourceTypes;
  if (searchData.resourceTypes.length) {
    let resourceTypesFlat = "," + _.join(searchData.resourceTypes, ",") + ",";
    payload.resourceTypesFlat = resourceTypesFlat;
  }
  payload.licenceTypes = searchData.licences;
  if (searchData.licences.length) {
    let licenceTypesFlat = "," + _.join(searchData.licences, ",") + ",";
    payload.licenceTypesFlat = licenceTypesFlat;
  }
  payload.standard = searchData.standards;
  payload.keywords = searchData.query;
  payload.page = searchData.page;
  payload.totalResults = searchData.totalResults;

  return payload;
};

export const populateMyWSSearchEventData = (searchData) => {
  const payload = {};
  payload.guid = searchData.searchGuid || uuidv4();
  payload.iter = searchData.iterCount + 1;
  payload.keywords = searchData.query;
  payload.page = searchData.page;
  payload.totalResults = searchData.totalResults;

  return payload;
};

export const populateMyResourcesSearchEventData = (searchData) => {
  const payload = {};
  payload.guid = searchData.searchGuid || uuidv4();
  payload.iter = searchData.iterCount + 1;
  payload.classId = searchData.classId;
  payload.className = searchData.className;
  payload.subjects = searchData.subjects;
  if (searchData.subjects.length) {
    let subjectsFlat = "," + _.join(searchData.subjects, ",") + ",";
    payload.subjectsFlat = subjectsFlat;
  }
  payload.grades = searchData.grades;
  if (searchData.grades.length) {
    let gradesFlat = "," + _.join(searchData.grades, ",") + ",";
    payload.gradesFlat = gradesFlat;
  }
  payload.resourceTypes = searchData.resourceTypes;
  if (searchData.resourceTypes.length) {
    let resourceTypesFlat = "," + _.join(searchData.resourceTypes, ",") + ",";
    payload.resourceTypesFlat = resourceTypesFlat;
  }
  payload.licenceTypes = searchData.licences;
  if (searchData.licences.length) {
    let licenceTypesFlat = "," + _.join(searchData.licences, ",") + ",";
    payload.licenceTypesFlat = licenceTypesFlat;
  }
  payload.standard = searchData.standards;
  payload.keywords = searchData.query;
  payload.page = searchData.page;
  payload.totalResults = searchData.totalResults;

  return payload;
};

export const populateSearchEventDataOld = (searchData) => {
  const payload = {};
  payload.guid = searchData.searchGuid || uuidv4();
  payload.iter = searchData.iterCount + 1;

  payload.subjects = searchData.subjects;
  if (searchData.subjects.length) {
    let subjectsFlat = "," + _.join(searchData.subjects, ",") + ",";
    payload.subjectsFlat = subjectsFlat;
  }

  payload.grades = searchData.grades;
  if (searchData.grades.length) {
    let gradesFlat = "," + _.join(searchData.grades, ",") + ",";
    payload.gradesFlat = gradesFlat;
  }

  payload.standard = searchData.standards;
  payload.keywords = searchData.query;
  payload.page = searchData.page;
  payload.totalResults = searchData.totalResults;

  return payload;
};

export const populateActionEventData = (actionData) => {
  const payload = {};
  payload.guid = actionData.searchGuid;
  payload.workspaceId = actionData.Id;
  payload.itemPosition = actionData.itemPosition;
  payload.iter = actionData.searchIterCount;

  return payload;
};

export const getOrderedObject = (obj, field, order = "asc") => {
  const arrFromObject = _.map(obj, (value, key) =>
    !value.key ? { ...value, key: key } : { ...value }
  );
  const orderedArr = _.orderBy(arrFromObject, field, [order]);
  const orderedObj = {};
  _.forEach(orderedArr, (item) => {
    orderedObj[item.key] = item;
  });
  return orderedObj;
};

export const disableScroll = () => {
  // Get the current page scroll position
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  // if any scroll is attempted, set this to the previous value
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
};

export const enableScroll = () => {
  _.delay(() => {
    window.onscroll = function () {};
  }, 100);
};

export const normaliseUrl = (str) =>
  PROTOCOL_REGEXP.test(str) ? str : `http://${str}`;

const normaliseForComparison = (url) =>
  (url || "").replace(/^https?:\/\//, "").replace(/\/$/, "");

export const isDuplicateURL = (links, url) =>
  _.findIndex(
    links,
    (l) => normaliseForComparison(url) === normaliseForComparison(l)
  ) >= 0;

export const validateUrl = (str) => URL_REGEXP.test(str);

export const getHostname = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  try {
    return new URL(url).hostname;
  } catch (e) {
    return url; //could happen for some urls that are not proper urls, for example view-source:file:// , returning the whole url as a fall back option
  }
};

export const downloadScreenshotWithAddedMetaData = (
  screenshotURL,
  studentEmail,
  studentName,
  screenshotDisplayDate,
  screenshotFilenameDate,
  screenshotUrl
) => {
  const img = new Image();

  img.onload = function () {
    const textContainerHeight = 90;
    const textContainerPadding = 32;
    const textContainerInnerPadding = 16;
    const textContainerFullHeight =
      textContainerHeight + textContainerPadding * 2;
    const textOffsetX = textContainerPadding + textContainerInnerPadding;

    const imgWidth = img.width;
    const imgHeight = img.height;
    const cvs = document.createElement("canvas");
    cvs.setAttribute("width", imgWidth);
    cvs.setAttribute("height", imgHeight + textContainerFullHeight);

    const ctx = cvs.getContext("2d");
    // draw text container
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, imgHeight, imgWidth, textContainerFullHeight);
    ctx.fillStyle = "#f2f2f2";
    ctx.fillRect(
      textContainerPadding,
      imgHeight + textContainerPadding,
      imgWidth - textContainerPadding * 2,
      textContainerHeight
    );
    // draw main image
    ctx.drawImage(img, 0, 0);
    ctx.fillStyle = "#121217";
    ctx.font = 'bold 16px "Open Sans"';
    ctx.fillText(
      studentName,
      textOffsetX,
      imgHeight + textContainerPadding + 27
    );
    ctx.font = '13px "Open Sans"';
    ctx.fillText(
      `URL: ${screenshotUrl}`,
      textOffsetX,
      imgHeight + textContainerPadding + 51
    );
    ctx.fillText(
      `Screenshot taken: ${screenshotDisplayDate}`,
      textOffsetX,
      imgHeight + textContainerPadding + 74
    );
    // cover excessive text if any
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(
      imgWidth - textContainerPadding,
      imgHeight,
      textContainerPadding,
      textContainerFullHeight
    );
    ctx.fillStyle = "#f2f2f2";
    ctx.fillRect(
      imgWidth - textContainerPadding - textContainerInnerPadding,
      imgHeight + textContainerPadding,
      textContainerInnerPadding,
      textContainerHeight
    );

    const imgURL = cvs.toDataURL("image/jpeg");

    const fileName = `screenshot-${studentEmail} - ${screenshotFilenameDate}.jpg`;

    // for non-IE
    if (!window.ActiveXObject) {
      const save = document.createElement("a");
      save.href = imgURL;
      save.target = "_blank";
      save.download = fileName;

      document.body.appendChild(save);
      save.click();
      save.remove();
    } else if (!!window.ActiveXObject && document.execCommand) {
      const _window = window.open(imgURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || imgURL);
      _window.close();
    }
  };

  img.src = screenshotURL;
};

export const screenshotTakenTimeHelper = (stamp, format) => {
  const time = moment(stamp);

  if (!time.isValid()) {
    return "Time and date unavailable";
  }

  return time.format(format);
};

export const isOutOfSchoolHours = (monitoringTime) => {
  if (!monitoringTime || monitoringTime === "") {
    return false;
  }

  const SATURDAY = 6;
  const SUNDAY = 0;
  const isSaturdayMonitoringEnabled = _.get(monitoringTime, "Saturday", null);
  const isSundayMonitoringEnabled = _.get(monitoringTime, "Sunday", null);
  const startOfSchool = _.get(monitoringTime, "Start", null);
  const endOfSchool = _.get(monitoringTime, "End", null);
  const serverTime = _.get(monitoringTime, "CurrentTime", null);
  const serverTimezone = _.get(monitoringTime, "Timezone", null);

  const serverTimeMoment = moment.parseZone(serverTime);
  const serverDayOftheWeek = serverTimeMoment.day();

  if (serverDayOftheWeek === SATURDAY && !isSaturdayMonitoringEnabled) {
    return true;
  }
  if (serverDayOftheWeek === SUNDAY && !isSundayMonitoringEnabled) {
    return true;
  }
  if (!startOfSchool || !endOfSchool) {
    //if start or end is not set -> always monitor
    return false;
  }
  const serverDate = serverTime.substring(0, 10);
  const serverStartOfSchoolTime = moment.tz(
    `${serverDate} ${startOfSchool}`,
    serverTimezone
  );
  const serverEndOfSchoolTime = moment.tz(
    `${serverDate} ${endOfSchool}`,
    serverTimezone
  );

  const convertToLocalTime = (schoolTime) => {
    return moment(schoolTime).tz(moment.tz.guess());
  };

  const now = moment();

  if (
    now.isBefore(convertToLocalTime(serverStartOfSchoolTime)) ||
    now.isAfter(convertToLocalTime(serverEndOfSchoolTime))
  ) {
    return true;
  }

  return false;
};

export const formatFileSizeString = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getRestrictedMonitoringData = (monitoringTime) => {
  //default property values below are for the schools without restrict monitoring time set
  return {
    startOfSchool: moment(_.get(monitoringTime, "Start", "00:00"), "hh:mm"),
    endOfSchool: moment(_.get(monitoringTime, "End", "23:59"), "hh:mm"),
    isSaturdayMonitoringEnabled: _.get(monitoringTime, "Saturday", true),
    isSundayMonitoringEnabled: _.get(monitoringTime, "Sunday", true),
  };
};

//This function returns function which is passed as a prop to Time and Date picker component
export const pickerDisabledState = (type, monitoringTime, scheduledDate) => {
  const {
    startOfSchool,
    endOfSchool,
    isSaturdayMonitoringEnabled,
    isSundayMonitoringEnabled,
  } = getRestrictedMonitoringData(monitoringTime);

  return function (current) {
    if (type === PICKER_DISABLED_TYPES.DATE) {
      if (current < moment().startOf("day")) {
        return true;
      }
      if (current > moment().add(1, "month").endOf("day")) {
        return true;
      }
      const SATURDAY = 6;
      const currentDayOfWeek = current.day();
      if (currentDayOfWeek === SATURDAY && !isSaturdayMonitoringEnabled) {
        return true;
      }
      const SUNDAY = 0;
      if (currentDayOfWeek === SUNDAY && !isSundayMonitoringEnabled) {
        return true;
      }
      const isToday = current.isSame(moment(), "day");
      const minsOfToday = moment().minutes() + moment().hours() * 60;
      if (
        isToday &&
        minsOfToday > endOfSchool.minutes() + endOfSchool.hours() * 60
      ) {
        return true;
      }
      return false;
    }

    if (type === PICKER_DISABLED_TYPES.TIME) {
      const minsOfDay = current.minutes() + current.hours() * 60;
      if (minsOfDay > endOfSchool.minutes() + endOfSchool.hours() * 60) {
        return true;
      }
      if (minsOfDay < startOfSchool.minutes() + startOfSchool.hours() * 60) {
        return true;
      }
      const isToday = scheduledDate && scheduledDate.isSame(moment(), "day");
      const minsOfToday = moment().minutes() + moment().hours() * 60;
      if (isToday && minsOfDay < minsOfToday) {
        return true;
      }
      return false;
    }
  };
};

export const getErrorStringFromErrorOrResponse = (error) => {
  return _.get(error, "response")
    ? `${_.get(error, "response.status")}:${_.get(
        error,
        "response.data.error_code"
      )}`
    : `${_.get(error, "name")}: ${_.get(error, "message")}`;
};

export const getErrorEvent = (error) => {
  return {
    name: "fe-error",
    error_message: getErrorStringFromErrorOrResponse(error),
    response_status:
      _.get(error, "responseStatus") || _.get(error, "response.status"),
    api_path: _.get(error, "apiPath"),
  };
};

export const sortStrToNumberIteratee = (string) =>
  string === "K" ? 0 : parseInt(string, 10);

export const hasPermission = (obj, permission) => {
  return !permission || obj.permissions.indexOf(permission) >= 0;
};

export const anySchoolHasPermission = (domain, permission) => {
  const school = _.find(domain.schools, (s) => hasPermission(s, permission));
  return !!school;
};

export const getDefaultDomainSelection = (domains, permission) => {
  let domain = "";
  let school = "";
  const filteredDomains = domains.filter(
    (d) => d.schools.filter((s) => hasPermission(s, permission)).length > 0
  );
  if (filteredDomains.length === 1) {
    domain = filteredDomains[0].urn;
    const filteredSchools = filteredDomains[0].schools.filter((s) =>
      hasPermission(s, permission)
    );
    if (filteredSchools.length === 1) {
      school = filteredSchools[0].code;
    }
  }
  return { domain, school };
};

export const areListsEqual = (list1, list2) =>
  list1.length === list2.length && _.difference(list1, list2).length === 0;

export const extractScheduleTimeFromSession = (existingSession) => {
  const existingScheduleTime = _.get(existingSession, "ScheduledDateTime");
  if (existingScheduleTime) {
    let scheduleTime = moment(existingScheduleTime);
    return moment().set({
      hour: scheduleTime.hours(),
      minute: scheduleTime.minutes(),
    });
  }
  return existingScheduleTime;
};

export const colourLookup = (
  colourRange,
  returnSingle,
  colourIndex = 0,
  colourType = ""
) => {
  if (returnSingle) {
    //Returns single colour to match Colour Index or default set if the index is not recognised
    const colourLookupValue = _.find(colourRange, { colourIndex: colourIndex });
    const colourPayload = !_.isEmpty(colourLookupValue)
      ? colourLookupValue
      : _.find(colourRange, { colourIndex: 202 });
    return colourPayload;
  } else {
    //Returns array of colours that match required colour group type
    const colourTypedPayload = _.filter(colourRange, {
      colourType: colourType,
    });
    return colourTypedPayload;
  }
};

//Checks for video provider names in artifact URLs
export const isContentVideo = (URL) => {
  const sources = ["youtube.com", "youtu.be", "vimeo.com"]; //Wistia is not currently supported
  const isVideo = new RegExp(sources.join("|")).test(URL);
  return isVideo;
};

//Slices string from last occurance of a substring (Adjustable with modifier value).
export const sliceStringFrom = (string, character, modifier = 0) => {
  const sliceIndex = string.lastIndexOf(character) + modifier;
  return string.slice(sliceIndex);
};

//Compiles embedded URL based on URL source. Currently we are only supporting youtube/vimeo
export const embedURLCompiler = (URL) => {
  const isVimeo = new RegExp("vimeo.com").test(URL);

  if (isVimeo) {
    return `https://player.vimeo.com/video/${sliceStringFrom(
      URL,
      "/",
      1
    )}?transparent=0`;
  } else {
    const sliceCharacter = new RegExp("youtu.be").test(URL) ? "/" : "=";
    return `https://www.youtube.com/embed/${sliceStringFrom(
      URL,
      sliceCharacter,
      1
    )}`;
  }
};
