import type { RouteProps } from "react-router";

import PrivateRoute from "./PrivateRoute";

interface DashboardProps extends RouteProps {
  pagePermissions?: Array<string>;
  title?: string;
}

export const DashboardRoute = ({
  component,
  ...rest
}: DashboardProps): JSX.Element => {
  return <PrivateRoute {...rest} component={component} />;
};

export default DashboardRoute;
