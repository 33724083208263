import actionTypes from "../../actionTypes";
import _ from "lodash";

export const INIT_STATE = {
  items: null,
  totalItems: 0,
  isLoading: true,
  pagesCount: 0,
  currentPage: 1,
  isLeftSidePanelOpen: false,
  searchGuid: null,
  searchIteration: 0,
};

export const updateItemStatus = ({ state, itemId, statusName, value }) => {
  const itemIndex = _.findIndex(state.items, { ID: itemId });
  if (itemIndex >= 0) {
    const newItemsArr = _.cloneDeep(state.items);
    const item = newItemsArr[itemIndex];
    item.Statuses[statusName] = value;
    newItemsArr.splice(itemIndex, 1, item);

    return {
      ...state,
      items: newItemsArr,
    };
  } else {
    return state;
  }
};

export const updateItem = ({ state, itemId, newData }) => {
  const itemIndex = _.findIndex(state.items, { ID: itemId });
  if (itemIndex >= 0) {
    const newItemsArr = _.cloneDeep(state.items);

    newItemsArr[itemIndex] = {
      ...newItemsArr[itemIndex],
      ...newData,
    };

    return {
      ...state,
      items: newItemsArr,
    };
  } else {
    return state;
  }
};

const discoverReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIBRARY_DISCOVER_ITEMS_LOAD_SUCCESS:
      const { Results, TotalHits, TotalPages, CurrentPage } = action.payload;
      return {
        ...state,
        isLoading: false,
        items: Results ? Results : [],
        totalItems: TotalHits,
        currentPage: CurrentPage,
        pagesCount: TotalPages,
      };

    case actionTypes.LIBRARY_DISCOVER_ITEMS_LOAD_PENDING:
      return { ...state, isLoading: true };

    case actionTypes.LIBRARY_DISCOVER_LEFT_PANEL_UPDATE:
      return { ...state, isLeftSidePanelOpen: action.payload };

    case actionTypes.LIBRARY_DISCOVER_RESET_ITEMS:
      return INIT_STATE;

    case actionTypes.LIBRARY_DISCOVER_SEARCH_GUID_UPDATE:
      return {
        ...state,
        searchGuid: action.searchGuid,
        searchIteration: action.iterCount,
      };

    case actionTypes.RESOURCES_MARK_AS_ADDED_TO_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: false,
      });

    case actionTypes.RESOURCES_MARK_AS_STARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_UNSTARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: false,
      });

    default:
      return state;
  }
};

export default discoverReducer;
