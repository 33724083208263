import { isValidTime } from "../isValidTime";
export const addDurationToTime = (
  plainTime: string,
  durationMinutes: number
) => {
  if (!isValidTime(plainTime))
    throw new Error("Invalid time format. Use HH:MM");

  const [hours, minutes] = plainTime.split(":").map(Number);
  const date = new Date();

  date.setHours(hours, minutes, 0, 0);
  date.setMinutes(date.getMinutes() + durationMinutes);

  const resultHours = date.getHours().toString().padStart(2, "0");
  const resultMinutes = date.getMinutes().toString().padStart(2, "0");

  return `${resultHours}:${resultMinutes}`;
};
