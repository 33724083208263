import actionTypes from "../../actionTypes";
import {
  fetchUserGroupsCall,
  postUserGroup,
  postUserGroupsPrefs,
  updateUserGroup,
  deleteUserGroup,
  addParticipartToUserGroup,
  removeParticipartFromUserGroup,
  updateUserGroupsList,
} from "../../../apiCalls/highlights";
import { makeActionCreator } from "../../storeUtils";
import { showAppError, updateAccessError } from "../../app/actions";
import _ from "lodash";
import { USER_GROUP_PREFS_TYPE } from "./types";
import { fetchDashboardClassData } from "../../../apiCalls/dashboard";

export const fetchUserGroupsAction = (classId, isDashboard) => (dispatch) => {
  if (isDashboard) {
    return fetchDashboardClassData(classId)
      .then((response) => {
        dispatch({
          type: actionTypes.DASHBOARD_CLASS_DATA_LOAD_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  } else {
    fetchUserGroupsCall(classId)
      .then((response) => {
        if (response.status === 403) {
          // Not a Hapara user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        response
          .json()
          .then((ugResp) => {
            const userGroups = _.get(ugResp, "groups_list");
            const viewType =
              _.get(ugResp, "view_type") ===
              USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
                ? USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
                : USER_GROUP_PREFS_TYPE.SHARED_ACROSS_TABS;
            const groupsResp =
              userGroups && userGroups.length
                ? userGroups.map((g) => {
                    return {
                      name: g.DisplayName,
                      URN: g.URN,
                      color: g.Colour,
                      participants: [...g.Participants],
                      checked: false,
                    };
                  })
                : [];
            return {
              groups: groupsResp,
              viewType: viewType,
            };
          })
          .then((response) => {
            dispatch(userGroupsLoaded(response));
          })
          .catch((error) =>
            console.log(`can't get user groups data: ${error}`)
          );
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  }
};

export const addUserGroupAction = (group, isDashboard) => (dispatch) => {
  return postUserGroup(group)
    .then((resp) => {
      if (resp.status !== 200) {
        throw Error(resp);
      }
      dispatch(fetchUserGroupsAction(group.WorkgroupId, isDashboard));
      return resp.data.Group.URN;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserGroupAction =
  (classId, userGroupUrn, groupUpdate, isDashboard) => (dispatch) => {
    return updateUserGroup(userGroupUrn, groupUpdate)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        throw error;
      });
  };

export const deleteUserGroupAction =
  (classId, userGroupUrn, isDashboard) => (dispatch) => {
    return deleteUserGroup(userGroupUrn)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const saveUserGroupsPrefsAction =
  (classId, groupsPrefs, isDashboard) => (dispatch) => {
    return postUserGroupsPrefs(classId, groupsPrefs)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const addParticipartToGroupAction =
  (classId, userGroupUrn, groupUpdate, isDashboard) => (dispatch) => {
    return addParticipartToUserGroup(userGroupUrn, groupUpdate)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const updateStudentUserGroupsAction =
  (classId, userGroupsUpdate, isDashboard) => (dispatch) => {
    return updateUserGroupsList(userGroupsUpdate)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const removeParticipartFromGroupAction =
  (classId, userGroupUrn, groupUpdate, isDashboard) => (dispatch) => {
    return removeParticipartFromUserGroup(userGroupUrn, groupUpdate)
      .then((resp) => {
        if (resp.status !== 200) {
          throw Error(resp);
        }
        dispatch(fetchUserGroupsAction(classId, isDashboard));
        return true;
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const userGroupsLoaded = makeActionCreator(
  actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_LOAD_SUCCESS,
  "payload"
);

export const userGroupsReset = makeActionCreator(
  actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_RESET
);

export const userGroupSelected = makeActionCreator(
  actionTypes.HIGHLIGHTS_CLASS_USER_GROUP_SELECTED,
  "payload"
);

export const userGroupsShowManagementPanel = makeActionCreator(
  actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_SHOW_MANAGEMENT_PANEL,
  "payload"
);

export const userGroupsHideManagementPanel = makeActionCreator(
  actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_HIDE_MANAGEMENT_PANEL
);
