import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./FiltersStatus.module.scss";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { getFilterStatusType } from "../../../../../state/workspace/myworkspaces/list/selectors";
import { MYWORKSPACES_FILTER_STATUS_TYPES } from "../../../../../state/workspace/myworkspaces/list/types";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../../state/app/types";
import { getWindowSize } from "../../../../../state/app/selectors";
import { focusSafelyByRef } from "@hapara/ui/src/components/utils";

export const FiltersStatus = ({ filterStatusType, windowSize }) => {
  const triggerRef = useRef();

  const filterOptionList = [
    {
      id: 1,
      name: "All status",
      type: MYWORKSPACES_FILTER_STATUS_TYPES.ALL,
      dataTestId: "All",
    },
    {
      id: 2,
      name: "Draft",
      type: MYWORKSPACES_FILTER_STATUS_TYPES.UNPUBLISHED,
      dataTestId: "Draft",
    },
    {
      id: 3,
      name: "Published",
      type: MYWORKSPACES_FILTER_STATUS_TYPES.PUBLISHED,
      dataTestId: "Published",
    },
  ];

  const selectedOption =
    _.find(filterOptionList, { type: filterStatusType }) || {};

  return (
    <div className={styles.root}>
      <Dropdown
        widthType={
          windowSize < WINDOW_SIZE_TYPES.S
            ? DROPDOWN_WIDTH_TYPES.FULL_WIDTH
            : DROPDOWN_WIDTH_TYPES.MIN_CONTENT
        }
        triggerComponent={(props) => (
          <DropdownTrigger
            widthType={
              windowSize < WINDOW_SIZE_TYPES.S
                ? DROPDOWN_WIDTH_TYPES.FULL_WIDTH
                : DROPDOWN_WIDTH_TYPES.MIN_CONTENT
            }
            className={classnames(styles.dropdownTrigger, {
              [styles.notDefault]:
                selectedOption.type !== MYWORKSPACES_FILTER_STATUS_TYPES.ALL,
            })}
            data-test-id="Ws-StatusFilter-Dropdown-Trigger"
            label={selectedOption.name}
            ref={triggerRef}
            {...props}
          />
        )}
        itemComponentList={filterOptionList.map(
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.type === filterStatusType;
              return (
                <button
                  onClick={() => {
                    pushQueryToHistory({
                      status: item.type,
                    });
                    onClick();
                    focusSafelyByRef(triggerRef);
                  }}
                  className={classnames(className, styles.dropdownItem, {
                    [styles.selected]: isSelected,
                  })}
                  data-test-id={`Ws-StatusFilter-Button-${item.dataTestId}`}
                  aria-label={`${item.name}${isSelected ? ", selected" : ""}`}
                  {...rest}
                >
                  {item.name}
                </button>
              );
            }
        )}
      />
    </div>
  );
};

FiltersStatus.propTypes = {
  filterStatusType: PropTypes.oneOf(_.values(MYWORKSPACES_FILTER_STATUS_TYPES)),
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  filterStatusType: getFilterStatusType(state),
  windowSize: getWindowSize(state),
}))(FiltersStatus);
