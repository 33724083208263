import actionTypes from "../../actionTypes";
import {
  fetchMyResources,
  getResourceAuthToken,
} from "../../../apiCalls/library";
import { showAppError } from "../../app/actions";
import _ from "lodash";
import { updateAccessError } from "../../app/actions";
import { DEFAULT_PAGE_SIZE, SORT_TYPE } from "../../shared/types";
import {
  getMyResourcesItemsTotal,
  getMyResourcesSearchGuid,
  getMyResourcesSearchIteration,
} from "./selectors";
import { getClassList } from "../config/selectors";
import { populateMyResourcesSearchEventData, pendoTrack } from "../../../utils";

export const updateMyResourcesLeftPanel = (isOpen) => ({
  type: actionTypes.MY_RESOURCES_LEFT_PANEL_UPDATE,
  payload: isOpen,
});

export const loadMyResources =
  ({
    classId,
    query,
    starred,
    sortOrder,
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
    pageSize,
    page = 1,
  }) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.LIBRARY_MY_RESOURCES_ITEMS_LOAD_PENDING,
    });

    const bodyRequest = {
      Query: query,
      PageSize: pageSize,
      Page: page,
      Grades: grades,
      Subjects: subjects,
      Authorities: standards,
      ResourceTypes: resourceTypes,
      LicenceTypes: licences,
    };

    if (starred) {
      bodyRequest.Starred = true;
    }
    if (classId) {
      bodyRequest.ClassID = classId;
    }

    switch (sortOrder) {
      case SORT_TYPE.TITLE_AZ.type:
        bodyRequest.SortField = SORT_TYPE.TITLE_AZ.field;
        bodyRequest.SortOrder = SORT_TYPE.TITLE_AZ.order;
        break;

      case SORT_TYPE.TITLE_ZA.type:
        bodyRequest.SortField = SORT_TYPE.TITLE_ZA.field;
        bodyRequest.SortOrder = SORT_TYPE.TITLE_ZA.order;
        break;
      default:
        break;
    }

    return fetchMyResources({ bodyRequest })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(showAppError(Error(response.status)));
        } else {
          dispatch({
            type: actionTypes.LIBRARY_MY_RESOURCES_ITEMS_LOAD_SUCCESS,
            payload: response.data,
          });
        }
      })
      .then(() => {
        // send info only if it's a search action
        if (!_.isEmpty(query)) {
          const state = getState();
          const iterCount = getMyResourcesSearchIteration(state);
          const searchGuid = getMyResourcesSearchGuid(state);
          const totalResults = getMyResourcesItemsTotal(state);
          const classList = getClassList(state);
          const className = _.get(
            _.find(classList, { ID: classId }),
            "Name",
            ""
          );
          const searchFilters = populateMyResourcesSearchEventData({
            grades,
            subjects,
            standards,
            resourceTypes,
            licences,
            query,
            className,
            classId,
            page,
            totalResults,
            searchGuid,
            iterCount,
          });
          pendoTrack("My Resources Search", searchFilters);
          dispatch({
            type: actionTypes.LIBRARY_LIBRARY_SEARCH_GUID_UPDATE,
            searchGuid: searchFilters.guid,
            iterCount: searchFilters.iter,
          });
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        } else {
          dispatch(showAppError(error));
        }
      });
  };

export const resetMyResources = () => ({
  type: actionTypes.LIBRARY_MY_RESOURCES_RESET_ITEMS,
});

export const applyMyResourcesSearchParams =
  ({
    classId,
    query,
    starred,
    sortOrder,
    page,
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
  }) =>
  (dispatch) => {
    dispatch(
      loadMyResources({
        classId,
        query,
        starred,
        grades,
        subjects,
        standards,
        resourceTypes,
        licences,
        sortOrder,
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      })
    );
  };

export const loadResourceAuthToken =
  ({ resourceId }) =>
  (dispatch) =>
    getResourceAuthToken({ resourceId });
