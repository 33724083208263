import { FormattedMessage } from "react-intl";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./WorkspaceProgressSidePanel.module.scss";
import PropTypes from "prop-types";
import {
  isActiveLoading,
  getActiveItem,
  getActiveGroupId,
  getActiveCardId,
  isListLoaded,
} from "../../../../../state/workspace/myworkspaces/list/selectors";
import { loadWorkspaceProgressById } from "../../../../../state/workspace/myworkspaces/list/actions";
import {
  wsItemType,
  MYWORKSPACES_RIGHT_PANEL_SCROLLABLE_CONTENT_ID,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "../../../../../state/workspace/myworkspaces/list/navigation";
import emptyThumbnail from "@hapara/assets/src/images/ws-thumbnail-default.svg";
import WorkspaceProgressCard from "../WorkspaceProgressCard/WorkspaceProgressCard";
import WorkspaceProgressGroupFilter from "../WorkspaceProgressGroupFilter/WorkspaceProgressGroupFilter";
import { replaceQueryInHistory } from "../../../../../state/router/navigation";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";

export const WorkspaceProgressSidePanel = ({
  activeCardId,
  loadWorkspaceProgressById,
  isActiveLoading,
  activeItem,
  activeGroupId,
  isListLoaded,
}) => {
  const sections = _.get(activeItem, "Sections", []);
  const cards = _.flatten(_.map(sections, (section) => section.Cards));
  const onlyTeachersCards = _.filter(cards, ["IsCreatedByLearner", false]);

  const activeItemId = _.get(activeItem, "Id", null);
  const activeItemIdRef = useRef();

  // load progress data
  // when activeItemId changes
  // or for the first time
  useEffect(() => {
    if (
      isListLoaded &&
      !isActiveLoading &&
      activeItemId &&
      activeItemId !== activeItemIdRef.current
    ) {
      loadWorkspaceProgressById(activeItemId);
      activeItemIdRef.current = activeItemId;
    }
  }, [isActiveLoading, activeItemId, isListLoaded, loadWorkspaceProgressById]);

  // remove selected_ws param if there is no item found in the current ws list with current activeItemId
  useEffect(() => {
    if (isListLoaded && !activeItem) {
      replaceQueryInHistory({
        selected_ws: MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.selected_ws,
      });
    }
  }, [activeItem, isListLoaded]);

  const isSectionsEmpty = _.isEmpty(onlyTeachersCards);

  const isGroupEmpty =
    activeGroupId &&
    _.isEmpty(
      _.filter(onlyTeachersCards, (card) =>
        _.includes(card.GroupIds, activeGroupId)
      )
    );

  return (
    <div className={styles.root} data-test-id="ws-MyWorkspaces-ProgressPanel">
      {isActiveLoading && (
        <div className={styles.loading}>
          <div className={styles.loading1} />
          <div className={styles.loading2} />
          <div className={styles.loading3} />
          <div className={styles.loading4} />
        </div>
      )}
      {!isActiveLoading &&
        activeItem &&
        activeItem.Id &&
        !_.isEmpty(activeItem) && (
          <>
            <h2 className={styles.title}>
              <FormattedMessage defaultMessage="Progress" id="sIMS7i" />
            </h2>
            <div className={styles.workspace}>
              <div className={styles.workspaceThumbnail}>
                <img
                  className={styles.workspaceImage}
                  src={
                    activeItem.BoardThumbnail
                      ? `${activeItem.BoardThumbnail}&w=48&h=48`
                      : emptyThumbnail
                  }
                  width="24"
                  height="24"
                  alt=""
                />
              </div>
              <div
                className={styles.workspaceTitle}
                data-test-id="ws-MyWorkspaces-ProgressPanel-Title"
              >
                {stripHtmlTagsFromString(activeItem.Title)}
              </div>
            </div>

            <WorkspaceProgressGroupFilter />

            <div
              className={styles.sections}
              id={MYWORKSPACES_RIGHT_PANEL_SCROLLABLE_CONTENT_ID}
            >
              {isSectionsEmpty && (
                <div
                  className={styles.sectionEmpty}
                  data-test-id="ws-MyWorkspaces-ProgressPanel-SectionEmpty"
                >
                  <p>
                    <FormattedMessage
                      defaultMessage="When you create evidence cards, they'll appear here."
                      id="5sCBL6"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      defaultMessage="You can keep track of students' work once your Workspace is published."
                      id="LqgOwQ"
                    />
                  </p>
                </div>
              )}

              {!isSectionsEmpty && isGroupEmpty && (
                <div
                  className={styles.sectionEmpty}
                  data-test-id="ws-MyWorkspaces-ProgressPanel-GroupEmpty"
                >
                  <p>
                    <FormattedMessage
                      defaultMessage="No evidence cards were created for this group."
                      id="+Pw6te"
                    />
                  </p>
                </div>
              )}

              {!isSectionsEmpty &&
                !isGroupEmpty &&
                _.map(sections, (section) => {
                  const onlyTeachersCards = _.filter(section.Cards, [
                    "IsCreatedByLearner",
                    false,
                  ]);
                  const cardsToDisplay = !activeGroupId
                    ? onlyTeachersCards
                    : _.filter(onlyTeachersCards, (card) =>
                        _.includes(card.GroupIds, activeGroupId)
                      );

                  if (_.isEmpty(cardsToDisplay)) return null;

                  return (
                    <div
                      className={styles.section}
                      key={section.Priority === -1 ? 0 : section.Id}
                    >
                      {section.Priority !== -1 && (
                        <h3
                          className={styles.sectionTitle}
                          data-test-id="ws-MyWorkspaces-ProgressPanel-SectionTitle"
                        >
                          {section.Title}
                        </h3>
                      )}
                      <div>
                        {_.map(cardsToDisplay, (card) => (
                          <WorkspaceProgressCard
                            key={card.Id}
                            item={card}
                            isActive={activeCardId === card.Id}
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
    </div>
  );
};

WorkspaceProgressSidePanel.propTypes = {
  activeCardId: PropTypes.string,
  isActiveLoading: PropTypes.bool.isRequired,
  activeItem: wsItemType,
  activeGroupId: PropTypes.string,
  loadWorkspaceProgressById: PropTypes.func.isRequired,
  isListLoaded: PropTypes.bool,
};

export default connect(
  (state) => ({
    activeItem: getActiveItem(state),
    activeCardId: getActiveCardId(state),
    activeGroupId: getActiveGroupId(state),
    isActiveLoading: isActiveLoading(state),
    isListLoaded: isListLoaded(state),
  }),
  (dispatch) => ({
    loadWorkspaceProgressById: (id) => dispatch(loadWorkspaceProgressById(id)),
  })
)(WorkspaceProgressSidePanel);
