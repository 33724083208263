import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./AssignCollectionsModal.module.scss";
import LinearProgress from "@mui/material/LinearProgress";

const AssignCollectionModalLoading = () => {
  return (
    <div className={styles.modalBodyContainer}>
      <div className={styles.container}>
        <LinearProgress className={styles.progressbar} aria-hidden={true} />
        <div className={styles.loadingText}>
          <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
        </div>
      </div>
    </div>
  );
};

export default AssignCollectionModalLoading;
