import actionTypes from "../../actionTypes";
import { postCloseStudentTab } from "../../../apiCalls/highlights";
import { showAppError, sendEvent } from "../../app/actions";
import uuid from "uuid";
import { getStudentsOnlineIdList } from "./selectors";
import { getStudentConfigsList } from "../studentConfigs/selectors";

export const updateStudentOnlineState = ({
  studentId,
  isOnline,
  timestamp,
  instanceId,
}) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_ONLINE_STATE,
  payload: { studentId, isOnline, timestamp, instanceId },
});

export const cleanStudentInstanceData = ({ studentId }) => ({
  type: actionTypes.HIGHLIGHTS_CLEAN_STUDENT_INSTANCE_DATA,
  payload: { studentId },
});

export const cleanAllStudentsInstanceData = () => ({
  type: actionTypes.HIGHLIGHTS_CLEAN_ALL_STUDENTS_INSTANCE_DATA,
});

export const updateStudentData = ({
  studentId,
  tabs,
  lock,
  instanceId,
  users,
}) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_DATA,
  payload: { studentId, tabs, lock, instanceId, users },
});

export const updateStudentStatus = ({ studentId, status, instanceId }) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_STATUS,
  payload: { studentId, status, instanceId },
});

export const updateStudentCurrentScreen = ({
  studentId,
  currentScreen,
  instanceId,
}) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_CURRENT_SCREEN,
  payload: { studentId, currentScreen, instanceId },
});

export const updateStudentSnapshot = ({ studentId, snapshot, instanceId }) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_SNAPSHOT,
  payload: { studentId, snapshot, instanceId },
});

export const updateExpandModalSnapshot = ({
  studentId,
  image,
  instanceId,
  url,
}) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_EXPAND_MODAL_SNAPSHOT,
  payload: { studentId, image, instanceId, url },
});

export const updateStudentSelectedInstance = ({ studentId, instanceId }) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_SELECTED_INSTANCE,
  payload: { studentId, instanceId },
});

export const closeStudentTab =
  ({ userId, userName, tab, reason, instanceId, classId, teacherName }) =>
  async (dispatch) => {
    let closeTabData = {};
    const conversationId = uuid.v4();
    const tabIds = tab.ids && tab.ids.length > 0 ? tab.ids : [tab.id];

    if (reason) {
      closeTabData = {
        student: userId,
        class: classId,
        conversation_id: conversationId,
        data: {
          tab_ids: tabIds,
          tab_urls: [tab.url],
          message: "teacher.tabs.close",
          reason: reason,
          teacherName: teacherName,
          studentName: userName,
          title: tab.title,
          instance_id: instanceId,
        },
      };
    } else {
      closeTabData = {
        student: userId,
        class: classId,
        conversation_id: conversationId,
        data: {
          tab_ids: tabIds,
          tab_urls: [tab.url],
          message: "teacher.tabs.close",
          instance_id: instanceId,
        },
      };
    }
    dispatch(sendEvent({ name: "closeTab", conversation_id: conversationId }));
    try {
      const resp = await postCloseStudentTab({ closeTabData });
      if (resp.status !== 200) {
        throw Error(resp);
      }
      return true;
    } catch (error) {
      dispatch(showAppError(error));
    }
  };

export const sendReactRenderStats =
  ({ componentName, renderAmount, averageRenderDuration }) =>
  (dispatch, getState) => {
    const state = getState();
    const amountOfOnlineStudents = getStudentsOnlineIdList(state).length;
    const amountOfAllStudents = getStudentConfigsList(state).length;

    dispatch(
      sendEvent({
        name: "reactPerformance-" + componentName,
        amount_of_renders: renderAmount,
        average_render_duration: averageRenderDuration,
        students_online: amountOfOnlineStudents,
        students_in_class: amountOfAllStudents,
      })
    );
  };
