// @ts-check
const asciiHipi = [
  "                    =+:",
  "                    ++=",
  "                    +++.",
  "                    ===-",
  "                   .====---::..",
  "                .---====---------",
  "               :==----------------.",
  "              .===============----=",
  "              ---===-------=====---:",
  "             :---:::::::::::====----",
  "            :=:+=::::-+=:::-=====----",
  "           :=---:::-::-:::-=======--=:",
  "             :-****+:::::--===========:",
  "            .::+*-::::::-====++**+++**:",
  "            --::::::::--=========+++==.",
  "          .-==------==================-.",
  "        .==----==================----:-:",
  "     :=++++++=-:------------:-:::--==++-",
  "  .*%#+++**+++++===----------==++++++++=",
  " -@@@@***++++++++++++++++++++++++++++++-",
  " =%@@@@@+++++++++++++++++++++++++++++++:",
  "    .-*+++++++*%%#+++**++++++++++++++++",
  "      +++++++*@@@@***+++++++++++++++++.",
  "    -++++++++*%@@@@@+++++++++++++++++.",
  " -#%#++++++++++++*+++++++++++++++++=",
  "*@@%%++: .=++++++++++++++++++++++-.",
  ".%@@@@@%     :-=++++++++++++++=-.",
  " .-++=.        -%%#++++-...",
  "              #@@@%*+.",
  "             .#@@@@@*",
  "                :-=-",
];

// prettier-ignore
const colors = [
  "#f00", "#f40", "#f80", "#fb0", "#ff0", "#bf0",
  "#8f0", "#4f0", "#0f0", "#0f4", "#0f8", "#0fb",
  "#0ff", "#0bf", "#08f", "#04f", "#00f", "#40f",
  "#80f", "#b0f", "#f0f", "#f0b", "#f08", "#f04"
];

let stylishHipi = "";
asciiHipi.forEach((line) => {
  stylishHipi += `%c${line}\n`;
});
stylishHipi = stylishHipi.slice(0, -1);

const styles = asciiHipi.map(
  (_, index) =>
    `color: ${colors[Math.floor((index / asciiHipi.length) * colors.length)]};`
);

export const hipi = () => {
  // eslint-disable-next-line no-restricted-syntax, no-console
  console.log(stylishHipi, ...styles);
};
