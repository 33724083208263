import { FormattedMessage } from "react-intl";
import React, { useEffect } from "react";
import styles from "./LibraryMainContent.module.scss";
import classnames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CategoryFiltersToggleButton from "../CategoryFiltersToggleButton/CategoryFiltersToggleButton";
import { navigateToLanding } from "../../../../state/shared/navigation";
import CategoryTitle from "../CategoryTitle/CategoryTitle";
import SearchParamsBlock from "../SearchParamsBlock/SearchParamsBlock";
import { MAIN_CONTENT_ID } from "../../../../consts";
import {
  libraryItemType,
  USER_TYPES,
} from "../../../../state/library/library/types";
import { collectionItemType } from "../../../../state/library/collections/types";
import {
  getLibraryLeftPanelIsOpen,
  numberLibraryFiltersSelected,
  getLibraryItemsTotal,
  getLibraryItemsList,
  getLibraryPagesCount,
  getLibraryIsLoading,
} from "../../../../state/library/library/selectors";
import {
  getSearchQuery,
  getSelectedFormatFilter,
  getCurrentPage,
  getJoinedSharedFilterParams,
  numberSharedFiltersSelected,
  getSelectedSortOrderFilter,
} from "../../../../state/shared/selectors";
import { getSelectedCollection } from "../../../../state/library/collections/selectors";
import { toggleLibraryLeftPanel } from "../../../../state/library/library/actions";
import ResourceSearch from "../../Resources/ResourceSearch/ResourceSearch";
import ResourceTileList from "../../Resources/ResourceTileList/ResourceTileList";
import SortFilterBlock from "../../Resources/SortFilterBlock/SortFilterBlock";
import EmptySearchResults from "../../Resources/EmptySearchResults/EmptySearchResults";
import ErrorStatePanel from "@hapara/ui/src/atomic/ErrorStatePanel/ErrorStatePanel";
import { resetLibraryItems } from "../../../../state/library/library/actions";
import {
  hasResourceAddAccess,
  hasWorkspaceAddAccess,
} from "../../../../state/library/config/selectors";
import InformativePage from "@hapara/ui/src/components/InformativePage/InformativePage";
import teacherHoldingBooks from "@hapara/assets/src/images/vector-teacher-holding-books.svg";
import purpleAddFromDiscover from "@hapara/assets/src/images/vector-purple-add-from-discover.svg";
import purpleUploadResource from "@hapara/assets/src/images/vector-purple-upload-a-resource.svg";
import purpleEarthMagnifyingGlass from "@hapara/assets/src/images/vector-purple-earth-magnifying-glass.svg";
import { BACKGROUND_STYLE } from "@hapara/ui/src/deprecated/Card/Card";
import { APP_PAGES } from "../../../../state/router/types";
import { hasResourcesAccess } from "../../../../state/user/selectors";
import ResourcesLoading from "../../Resources/ResourcesLoading/ResourcesLoading";

export const LibraryMainContent = ({
  isLeftPanelOpen,
  toggleLeftPanel,
  selectedCollection,
  libraryQuery,
  formatFilter,
  sortOrderFilter,
  numberLibraryFiltersSelected,
  numberSharedFiltersSelected,
  searchResultsTotal,
  items,
  currentPage,
  pagesCount,
  isLoading,
  joinedSharedFilterParams,
  resetLibraryItems,
  hasWorkspaceAddAccess,
  hasResourceAddAccess,
  hasResourcesAccess,
}) => {
  const dataTestIdPrefix = "lb-Library";

  const hasDigitalBackpackAccess = hasResourcesAccess;
  const isDbDRM = hasResourceAddAccess;
  const isLibraryOnlyDRM = hasWorkspaceAddAccess && !hasResourceAddAccess;

  let infoPageText, infoPageTitle, infoPageItemsDescription;
  let infoPageItems = [];

  const getUserType = () => {
    if (hasDigitalBackpackAccess) {
      if (isDbDRM) {
        return USER_TYPES.DRM_DB;
      }
      return USER_TYPES.TEACHER_DB;
    }

    if (isLibraryOnlyDRM) {
      return USER_TYPES.DRM_PLIB_ONLY;
    }

    return USER_TYPES.TEACHER_PLIB_ONLY;
  };

  switch (getUserType()) {
    case USER_TYPES.TEACHER_PLIB_ONLY:
      infoPageTitle = "Welcome to your school library!";
      infoPageText = `This is where you'll find Workspaces created and shared by teachers in your school or district.`;
      break;

    case USER_TYPES.TEACHER_DB:
      infoPageTitle = "Welcome to your school library!";
      infoPageText = `This is where you'll find resources and lessons that have been curated by your school or district.`;
      infoPageItems = [
        {
          id: 1,
          image: purpleEarthMagnifyingGlass,
          title: "Nothing here yet?",
          text: "Until your school sets up your curated library, you can get started by browsing public, open source novels, textbooks, lessons and more in Discover.",
          link: APP_PAGES.DISCOVER_BASE.path,
          linkLabel: "Explore Discover",
          linkDataTestId: "ExploreDiscover",
        },
      ];
      break;

    case USER_TYPES.DRM_PLIB_ONLY:
      infoPageTitle = "Curate a library for your teachers!";
      infoPageText =
        "Workspaces added to the library will appear here, where you can organize them into collections.";
      break;

    case USER_TYPES.DRM_DB:
      infoPageTitle = "Curate a library for your teachers!";
      infoPageText = `School-approved materials will appear here, so teachers can easily find them.`;
      infoPageItemsDescription =
        "How to add instructional materials to your library:";
      infoPageItems = [
        {
          id: 1,
          image: purpleUploadResource,
          title: "Upload an instructional resource",
          text: "Digitize your own curriculum resources, handouts and materials for teachers and students.",
          link: APP_PAGES.LIBRARY_UPLOAD.path,
          linkLabel: "Upload a resource",
          linkDataTestId: "UploadResource",
        },
        {
          id: 2,
          image: purpleAddFromDiscover,
          title: "Add globally shared materials",
          text: "Organize any of our public lessons, novels, textbooks and more into curated collections for your school.",
          link: APP_PAGES.DISCOVER_BASE.path,
          linkLabel: "Add from Discover",
          linkDataTestId: "AddFromDiscover",
        },
      ];
      break;

    default:
  }

  const LIBRARY_INFO_PAGE = {
    hero: {
      background: BACKGROUND_STYLE.VIOLET,
      image: teacherHoldingBooks,
      title: infoPageTitle,
      text: infoPageText,
    },
    itemsDescription: infoPageItemsDescription,
    items: infoPageItems,
  };

  useEffect(() => {
    return () => {
      //clear data on unmount
      resetLibraryItems();
    };
  }, [resetLibraryItems]);

  let title = null;
  let isCollectionDeleted = false;

  if (!_.isEmpty(selectedCollection)) {
    title = (
      <>
        <h1>{selectedCollection.name}</h1> {`(${searchResultsTotal})`}
      </>
    );
  } else {
    title = (
      <h1>
        <FormattedMessage defaultMessage="Collection deleted" id="BDyCf3" />
      </h1>
    );
    isCollectionDeleted = true;
  }

  const isLibraryEmpty =
    items &&
    items.length === 0 &&
    !isLoading &&
    _.isEmpty(libraryQuery) &&
    numberLibraryFiltersSelected === 0 &&
    numberSharedFiltersSelected === 0 &&
    !isCollectionDeleted;

  let searchFilterEmptyState = (
    <EmptySearchResults dataTestId={`${dataTestIdPrefix}-EmptySearchResults`} />
  );

  if (_.isEmpty(libraryQuery) && !isCollectionDeleted) {
    searchFilterEmptyState = (
      <ErrorStatePanel
        message={"There's nothing here!"}
        dataTestId={`${dataTestIdPrefix}-EmptyState`}
      >
        <p>
          <FormattedMessage
            defaultMessage="There isn’t anything in this collection yet."
            id="d58Dgp"
          />
        </p>
      </ErrorStatePanel>
    );
  }
  if (isCollectionDeleted) {
    searchFilterEmptyState = (
      <ErrorStatePanel
        message="This collection has been deleted"
        dataTestId={`${dataTestIdPrefix}-EmptyState`}
      />
    );
  }

  return (
    <div className={styles.root}>
      <main className={styles.contentMain} id={MAIN_CONTENT_ID} tabIndex="-1">
        {isLibraryEmpty && (
          <>
            <div className={classnames(styles.headerContent, styles.infoPage)}>
              <div className={styles.separator} />
              <div className={styles.filters}>
                <CategoryFiltersToggleButton
                  toggleLeftPanel={toggleLeftPanel}
                  isLeftPanelOpen={isLeftPanelOpen}
                  dataTestIdPrefix={dataTestIdPrefix}
                  numberFiltersSelected={0}
                />
              </div>
            </div>
            <InformativePage
              info={LIBRARY_INFO_PAGE}
              dataTestPrefix={dataTestIdPrefix}
            />
          </>
        )}
        {!isLibraryEmpty && (
          <>
            <div className={styles.headerContent}>
              <CategoryTitle
                dataTestId={`${dataTestIdPrefix}-Title`}
                areFiltersSelected={
                  (!_.isEmpty(libraryQuery) ||
                    numberSharedFiltersSelected > 0) &&
                  !isCollectionDeleted
                }
                searchResultsTotal={searchResultsTotal}
                clearFiltersAction={navigateToLanding}
                title={title}
              />
              <div className={styles.separator} />
              <div className={styles.filters}>
                <CategoryFiltersToggleButton
                  toggleLeftPanel={toggleLeftPanel}
                  isLeftPanelOpen={isLeftPanelOpen}
                  dataTestIdPrefix={dataTestIdPrefix}
                  numberFiltersSelected={numberLibraryFiltersSelected}
                />
                <ResourceSearch dataTestIdPrefix={dataTestIdPrefix} />
              </div>
            </div>
            <SortFilterBlock
              dataTestIdPrefix={`${dataTestIdPrefix}-SortFilterBlock`}
              selectedFormat={formatFilter}
              selectedSortOrder={sortOrderFilter}
            />
            <SearchParamsBlock
              dataTestId={`${dataTestIdPrefix}-SearchParams`}
              areFiltersSelected={
                (!_.isEmpty(libraryQuery) || numberSharedFiltersSelected > 0) &&
                !isCollectionDeleted
              }
              keywordsSearchParams={libraryQuery}
              filtersSearchParams={
                joinedSharedFilterParams ? (
                  <>
                    <span>
                      <FormattedMessage defaultMessage="Filters:" id="XMzOfe" />
                    </span>{" "}
                    {joinedSharedFilterParams}
                  </>
                ) : null
              }
            />

            {isLoading ? (
              <ResourcesLoading hasMarginTop={true} />
            ) : (
              <ResourceTileList
                items={isCollectionDeleted ? [] : items}
                currentPage={currentPage}
                pagesCount={pagesCount}
                emptyState={searchFilterEmptyState}
                dataTestIdPrefix={dataTestIdPrefix}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
};

LibraryMainContent.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  selectedCollection: collectionItemType,
  libraryQuery: PropTypes.string,
  formatFilter: PropTypes.string,
  sortOrderFilter: PropTypes.string,
  numberLibraryFiltersSelected: PropTypes.number.isRequired,
  numberSharedFiltersSelected: PropTypes.number.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(libraryItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  joinedSharedFilterParams: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  resetLibraryItems: PropTypes.func.isRequired,
  hasWorkspaceAddAccess: PropTypes.bool.isRequired,
  hasResourceAddAccess: PropTypes.bool.isRequired,
  hasResourcesAccess: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: getLibraryLeftPanelIsOpen(state),
    selectedCollection: getSelectedCollection(state),
    numberLibraryFiltersSelected: numberLibraryFiltersSelected(state),
    numberSharedFiltersSelected: numberSharedFiltersSelected(state),
    libraryQuery: getSearchQuery(state),
    formatFilter: getSelectedFormatFilter(state),
    sortOrderFilter: getSelectedSortOrderFilter(state),
    searchResultsTotal: getLibraryItemsTotal(state),
    items: getLibraryItemsList(state),
    currentPage: getCurrentPage(state),
    pagesCount: getLibraryPagesCount(state),
    joinedSharedFilterParams: getJoinedSharedFilterParams(state),
    isLoading: getLibraryIsLoading(state),
    hasWorkspaceAddAccess: hasWorkspaceAddAccess(state),
    hasResourceAddAccess: hasResourceAddAccess(state),
    hasResourcesAccess: hasResourcesAccess(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLibraryLeftPanel(isOpen)),
    resetLibraryItems: () => dispatch(resetLibraryItems()),
  })
)(LibraryMainContent);
