import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { COLLECTIONS_MAX_AMOUNT } from "../../../../state/library/collections/types";
import { LIBRARY_CATEGORIES } from "../../../../state/library/library/types";
import { collectionItemType } from "../../../../state/library/collections/types";
import { updateCollectionsEditMode } from "../../../../state/library/collections/actions";
import { areCollectionsInEditMode } from "../../../../state/library/collections/selectors";
import { hasCollectionsManageAccess } from "../../../../state/library/config/selectors";

import styles from "./CollectionsList.module.scss";

import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import CollectionFilterItem from "../CollectionFilterItem/CollectionFilterItem";
import CollectionLoadingItem from "../CollectionLoadingItem/CollectionLoadingItem";
import CollectionActionDialog, {
  COLLECTIONS_DIALOG_MODE,
} from "../CollectionActionDialog/CollectionActionDialog";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

const CollectionsList = ({
  active,
  collections,
  isLoading,
  isError,
  inEditMode,
  hasCollectionsManageAccess,
  updateCollectionsEditMode,
  loadCollections,
  selectedCollection,
}) => {
  const maxCollectionsReached =
    collections && collections.length >= COLLECTIONS_MAX_AMOUNT;
  const dataTestIdPrefix = "lb-Library-Collections";
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [editCollectionItem, setEditCollectionItem] = useState();
  const [actionDialogMode, setActionDialogMode] = useState(
    COLLECTIONS_DIALOG_MODE.CREATE
  );

  if (isLoading) {
    return (
      <div className={classnames(styles.root, styles.loadingState)}>
        <CollectionLoadingItem />
        <CollectionLoadingItem />
        <CollectionLoadingItem />
      </div>
    );
  }

  const createCollectionHandler = () => {
    setEditCollectionItem(null);
    setActionDialogMode(COLLECTIONS_DIALOG_MODE.CREATE);
    setActionDialogOpen(true);
  };

  const editCollectionHandler = (item) => {
    setEditCollectionItem(item);
    setActionDialogMode(COLLECTIONS_DIALOG_MODE.EDIT);
    setActionDialogOpen(true);
  };

  const deleteCollectionHandler = (item) => {
    setEditCollectionItem(item);
    setActionDialogMode(COLLECTIONS_DIALOG_MODE.DELETE);
    setActionDialogOpen(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.titleContent}>
          <div className={styles.icon}>
            <HapReactIcon
              svg={
                active
                  ? LIBRARY_CATEGORIES.COLLECTIONS.iconActive
                  : LIBRARY_CATEGORIES.COLLECTIONS.icon
              }
              width={28}
              height={28}
            />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {LIBRARY_CATEGORIES.COLLECTIONS.title}
            </div>
            <div className={styles.subtitle}>{`${
              collections ? collections.length : 0
            }/${COLLECTIONS_MAX_AMOUNT}`}</div>
          </div>
        </div>
        {hasCollectionsManageAccess && (
          <div className={styles.actionButtons}>
            {collections && collections.length > 0 && (
              <Button
                icon={inEditMode ? null : "cog"}
                label={inEditMode ? "Done" : null}
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.XSMALL}
                onAction={() => {
                  updateCollectionsEditMode(!inEditMode);
                }}
                dataTestId={`${dataTestIdPrefix}-EditMode-Button`}
                alt={
                  inEditMode ? "Exit collection manager" : "Manage collections"
                }
              />
            )}
            {!maxCollectionsReached && !inEditMode && (
              <Button
                icon="plus"
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.XSMALL}
                onAction={createCollectionHandler}
                dataTestId={`${dataTestIdPrefix}-AddCollection-Button`}
                alt="Add collection"
              />
            )}
          </div>
        )}
      </div>
      {collections && collections.length === 0 && (
        <div className={styles.emptyState}>
          <div className={styles.text}>
            <FormattedMessage
              defaultMessage="No collections created yet."
              id="AxeKol"
            />
          </div>
        </div>
      )}
      {isError && (
        <div className={styles.loadingError}>
          <div className={styles.message}>
            <HapReactIcon
              className={styles.icon}
              svg="fill-circle-exclamation"
              width={16}
              height={16}
            />
            Trouble loading collections
          </div>
          <Button
            label="Try again"
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            size={BUTTON_SIZES.SMALL}
            onClick={loadCollections}
            dataTestId={`${dataTestIdPrefix}-TryAgainButton`}
          />
        </div>
      )}
      {!isError && (
        <div className={styles.content}>
          <WarningMessage
            isVisible={maxCollectionsReached}
            dataTestId={`${dataTestIdPrefix}-MaxCollectionsReached-Warning`}
            className={styles.boldMarginOnTop}
          >
            <FormattedMessage
              defaultMessage="Maximum collections reached"
              id="kMLFEd"
            />
          </WarningMessage>
          <ul className={styles.collections}>
            {_.map(collections, (item) => {
              return (
                <CollectionFilterItem
                  key={item.id}
                  item={item}
                  active={selectedCollection === item.id}
                  inEditMode={inEditMode}
                  editCollection={() => editCollectionHandler(item)}
                  deleteCollection={() => deleteCollectionHandler(item)}
                />
              );
            })}
          </ul>
        </div>
      )}
      {actionDialogOpen && (
        <CollectionActionDialog
          isDialogOpen={actionDialogOpen}
          closeDialog={() => {
            setActionDialogOpen(false);
            setEditCollectionItem(null);
          }}
          editCollectionItem={editCollectionItem}
          mode={actionDialogMode}
          collections={collections}
        />
      )}
    </div>
  );
};

CollectionsList.propTypes = {
  active: PropTypes.bool,
  collections: PropTypes.arrayOf(collectionItemType),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  hasCollectionsManageAccess: PropTypes.bool.isRequired,
  updateCollectionsEditMode: PropTypes.func.isRequired,
  loadCollections: PropTypes.func.isRequired,
  selectedCollection: PropTypes.string,
};

export default connect(
  (state) => ({
    inEditMode: areCollectionsInEditMode(state),
    hasCollectionsManageAccess: hasCollectionsManageAccess(state),
  }),
  (dispatch) => ({
    updateCollectionsEditMode: (mode) =>
      dispatch(updateCollectionsEditMode(mode)),
  })
)(CollectionsList);
