import React, { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { ThunkDispatch } from "redux-thunk";
import classnames from "classnames";
import _ from "lodash";
import {
  getCourseID,
  getMaterials,
  getCourseWork,
  getIsCourseworkError,
  getIsCourseworkLoading,
  getIsCourseWorkLoadingMore,
  getIsMaterialsLoadingMore,
  getIsMaterialsLoading,
  hasGCMaterialsAccess,
  getIsMaterialsError,
} from "../../../../state/highlights/googleClassroom/selector";
import {
  fetchCGMaterials,
  fetchGCCourseWork,
  fetchMoreCGMaterials,
  fetchMoreGCCourseWork,
} from "../../../../state/highlights/googleClassroom/actions";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { contentItemShape } from "../../../../state/highlights/googleClassroom/types";
import {
  FILTER_DESCRIPTORS,
  FILTER_TYPE,
  MODAL_DESCRIPTORS,
} from "./GClassroomTypes";

import styles from "./ModalFormGClassroomShare.module.scss";
import GClassroomFilter from "./GClassroomFilter";
import GClassroomLoadMoreItem from "./GClassroomLoadMoreItem";
import GClassroomShareItem from "./GClassroomShareItem";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";

export const pageSize = 20;

export interface ModalFormGClassroomShareProps {
  dataTestPrefix: string;
  addShareLink: (link: string[]) => void;
  addLinkRecipients: (recipients: string[]) => void;
  fetchCGMaterials: (courseID: string) => void;
  fetchMoreCGMaterials: (courseID: string, nextPageToken: string) => void;
  fetchGCCourseWork: (courseID: string) => void;
  fetchMoreGCCourseWork: (courseID: string, nextPageToken: string) => void;
}

const ModalFormGClassroomShare = ({
  addShareLink,
  dataTestPrefix,
  fetchCGMaterials,
  addLinkRecipients,
  fetchGCCourseWork,
  fetchMoreCGMaterials,
  fetchMoreGCCourseWork,
}: ModalFormGClassroomShareProps) => {
  const intl = useIntl();
  const courseID = useSelector(getCourseID);
  const gcMaterialData = useSelector(getMaterials);
  const gcCourseworkData = useSelector(getCourseWork);
  const hasMaterialsError = useSelector(getIsMaterialsError);
  const hasCourseWorkError = useSelector(getIsCourseworkError);
  const isMaterialsLoading = useSelector(getIsMaterialsLoading);
  const isCourseWorkLoading = useSelector(getIsCourseworkLoading);
  const isCGMaterialsAuthorised = useSelector(hasGCMaterialsAccess);
  const isMaterialsLoadingMore = useSelector(getIsMaterialsLoadingMore);
  const isCourseworkLoadingMore = useSelector(getIsCourseWorkLoadingMore);

  const [selectedItemRecipients, setSelectedItemRecipients] = useState<
    Array<string>
  >([]);
  const [noCourseWorkAlert, setNoCourseWorkAlert] = useState(false);
  const [contentDisplayList, setContentDisplayList] = useState([]);
  const [hasCompletedCall, setHasCompletedCall] = useState(false);
  const [isFocusShiftActive, setIsFocusShiftActive] = useState(false);
  const [filteredItemCount, setFilteredItemCount] = useState(0);
  const [selectedItemURL, setSelectedItemURL] = useState("");
  const [focusShiftIndex, setFocusShiftIndex] = useState(pageSize + 1);
  const [isMaterialsActive, setIsMaterialsActive] = useState(false);
  const [courseWorkData, setCourseworkData] = useState([]);
  const [activeGCFilter, setActiveGCFilter] = useState<string[]>([
    intl.formatMessage(FILTER_DESCRIPTORS.ALL),
    FILTER_TYPE.ALL,
  ]);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [materialData, setMaterialData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  //HANDLE ACTIVE NEXT PAGE TOKEN AND FILTERED
  const nptCoursework = gcCourseworkData?.nextPageToken ?? "";
  const nptMaterials = gcMaterialData?.nextPageToken ?? "";
  const activeNextPageToken = isMaterialsActive ? nptMaterials : nptCoursework;
  const noNewFilteredItems = filteredItemCount === contentDisplayList.length;

  //CONTENT BASED TOGGLES
  const loadPageToggle = isMaterialsActive
    ? hasMaterialsError
    : isCourseWorkLoading ||
      (hasCompletedCall && (hasCourseWorkError || noCourseWorkAlert));
  const overFlowToggle =
    isMaterialsActive && hasMaterialsError ? false : hasOverflow;
  const errorUIToggle =
    hasCompletedCall &&
    ((!isCourseWorkLoading && hasCourseWorkError) ||
      (!isCourseWorkLoading && noCourseWorkAlert) ||
      (isMaterialsActive && hasMaterialsError));

  const getGCContentCallback = useCallback(() => {
    fetchCGMaterials(courseID);
    fetchGCCourseWork(courseID);
    setHasCompletedCall(true);
  }, [courseID, fetchCGMaterials, fetchGCCourseWork, setHasCompletedCall]);

  const getMoreGCContentCallback = useCallback(() => {
    if (isMaterialsActive) {
      fetchMoreCGMaterials(courseID, activeNextPageToken);
    } else {
      fetchMoreGCCourseWork(courseID, activeNextPageToken);
    }
  }, [
    fetchMoreGCCourseWork,
    fetchMoreCGMaterials,
    activeNextPageToken,
    isMaterialsActive,
    courseID,
  ]);

  const handleRefresh = () => {
    getGCContentCallback();
    setContentDisplayList([]);
    setCourseworkData([]);
    setMaterialData([]);
    setFilteredItemCount(0);
    setActiveGCFilter([
      intl.formatMessage(FILTER_DESCRIPTORS.ALL),
      FILTER_TYPE.ALL,
    ]);
    setHasOverflow(false);
    setIsFocusShiftActive(false);
    setFocusShiftIndex(pageSize);
  };

  const handleLoadMore = () => {
    getMoreGCContentCallback();
    setIsFocusShiftActive(true);
  };

  //RUN API & RESET ALERT TOGGLE
  useEffect(() => {
    if (!hasCompletedCall) {
      setNoCourseWorkAlert(false);
      getGCContentCallback();
    }
  }, [getGCContentCallback, hasCompletedCall, noCourseWorkAlert]);

  //SENDS SELECTION TO PARENT
  useEffect(() => {
    addShareLink(!_.isEmpty(selectedItemURL) ? [selectedItemURL] : []);
    addLinkRecipients(selectedItemRecipients);
  }, [
    addShareLink,
    addLinkRecipients,
    selectedItemURL,
    selectedItemRecipients,
  ]);

  //HANDLE COURSEWORK DATA
  useEffect(() => {
    if (!isCourseWorkLoading && hasCompletedCall && gcCourseworkData) {
      setCourseworkData(gcCourseworkData.items);
      setNoCourseWorkAlert(false);
    }
  }, [isCourseWorkLoading, hasCompletedCall, gcCourseworkData]);

  //HANDLE MATERIALS DATA
  useEffect(() => {
    if (!isMaterialsLoading && hasCompletedCall && gcMaterialData) {
      setMaterialData(gcMaterialData.items);
    }
  }, [isMaterialsLoading, hasCompletedCall, gcMaterialData]);

  //FILTERING
  useEffect(() => {
    const activeFilterType = activeGCFilter[1];

    switch (activeFilterType) {
      case FILTER_TYPE.ASSIGNMENT: {
        const filteredList = courseWorkData.filter(
          (item: contentItemShape) => item.type === activeFilterType
        );
        setContentDisplayList(filteredList);
        setNoCourseWorkAlert(_.isEmpty(courseWorkData));
        setIsMaterialsActive(false);
        break;
      }
      case FILTER_TYPE.SHORT_ANSWER_QUESTION: {
        const filteredList = courseWorkData.filter(
          (item: contentItemShape) => item.type === activeFilterType
        );
        setContentDisplayList(filteredList);
        setNoCourseWorkAlert(_.isEmpty(courseWorkData));
        setIsMaterialsActive(false);
        break;
      }
      case FILTER_TYPE.MATERIALS: {
        setContentDisplayList(materialData);
        setIsMaterialsActive(true);
        break;
      }
      default: {
        //ALL
        setContentDisplayList(courseWorkData);
        setNoCourseWorkAlert(_.isEmpty(courseWorkData));
        setIsMaterialsActive(false);
        break;
      }
    }
  }, [materialData, courseWorkData, activeGCFilter]);

  //CHECKS DISPLAY LIST LENGTH FOR OVERFLOW
  useEffect(() => {
    const displayItemCount = contentDisplayList.length;
    const shouldDisplayOverflow = displayItemCount > 5;

    if (shouldDisplayOverflow !== hasOverflow) {
      setHasOverflow(shouldDisplayOverflow);
    }
  }, [hasOverflow, activeGCFilter, contentDisplayList, hasCompletedCall]);

  //SETS FOCUS ON FIRST NEW ITEM
  useEffect(() => {
    if (!isCourseworkLoadingMore && isFocusShiftActive) {
      const firstNewItem =
        Math.floor(contentDisplayList.length / pageSize) * pageSize;
      setFocusShiftIndex(firstNewItem);
      setIsFocusShiftActive(false);
    }
  }, [isCourseworkLoadingMore, contentDisplayList, isFocusShiftActive]);

  return (
    <>
      <div
        className={styles.root}
        data-test-id={`${dataTestPrefix}-FormGCLinksBlock`}
      >
        <fieldset className={styles.sessionLinks}>
          <div className={styles.blockHeader}>
            <legend className={styles.title}>
              <FormattedMessage {...MODAL_DESCRIPTORS.TITLE} />
            </legend>
            <div className={styles.listControlContainer}>
              <GClassroomFilter
                isDisabled={isCourseWorkLoading || noCourseWorkAlert}
                hasGCMaterialsAccess={isCGMaterialsAuthorised}
                setFilteredItemCount={setFilteredItemCount}
                isGCMaterialsLoading={isMaterialsLoading}
                noNewFilteredItems={noNewFilteredItems}
                setActiveGCFilter={setActiveGCFilter}
                activeCGFilter={activeGCFilter[1]}
                dataTestPrefix={dataTestPrefix}
                disableMaterialFilter={_.isEmpty(materialData)}
              />
              <Button
                icon="arrow-sync"
                size={BUTTON_SIZES.SMALL}
                onClick={handleRefresh}
                label={<FormattedMessage {...MODAL_DESCRIPTORS.REFRESH} />}
                type={BUTTON_TYPES.TERTIARY}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                dataTestId={`${dataTestPrefix}-GCLinksBlock-Refresh`}
              />
            </div>
          </div>
          <div
            className={classnames(styles.tableScrollContainer, {
              [styles.hasOverflow]: overFlowToggle,
            })}
          >
            {loadPageToggle ? (
              <>
                <table tabIndex={0}>
                  <caption>
                    <FormattedMessage {...MODAL_DESCRIPTORS.TITLE} />
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col" className={styles.colClasswork}>
                        <FormattedMessage {...MODAL_DESCRIPTORS.CLASSWORK} />
                      </th>
                      <th scope="col" className={styles.colAssignees}>
                        <FormattedMessage {...MODAL_DESCRIPTORS.ASSIGNED} />
                      </th>
                      <th scope="col" className={styles.colPosted}>
                        <FormattedMessage {...MODAL_DESCRIPTORS.CREATED} />
                      </th>
                      <th scope="col" className={styles.colLink}>
                        <FormattedMessage {...MODAL_DESCRIPTORS.LINK} />
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className={styles.loadingAlert}>
                  <PageLoading
                    isCenteredAlert={true}
                    isGoBackVisible={false}
                    isTryAgainVisible={false}
                    renderButtonBlock={false}
                    errorMessageText={
                      (!hasCourseWorkError && noCourseWorkAlert) ||
                      (!hasMaterialsError && isMaterialsActive)
                        ? intl.formatMessage(MODAL_DESCRIPTORS.NO_COURSEWORK)
                        : intl.formatMessage(MODAL_DESCRIPTORS.ERROR)
                    }
                    isError={errorUIToggle}
                    dataTestIdPrefix={`${dataTestPrefix}-GCLinksBlock-loadingStatus`}
                    useErrorIcon={
                      !hasCourseWorkError && noCourseWorkAlert ? false : true
                    }
                  />
                </div>
              </>
            ) : (
              <table>
                <caption>
                  <FormattedMessage {...MODAL_DESCRIPTORS.TITLE} />
                </caption>
                <thead>
                  <tr>
                    <th scope="col" className={styles.colClasswork}>
                      <FormattedMessage {...MODAL_DESCRIPTORS.CLASSWORK} />
                    </th>
                    <th scope="col" className={styles.colAssignees}>
                      <FormattedMessage {...MODAL_DESCRIPTORS.ASSIGNED} />
                    </th>
                    <th scope="col" className={styles.colPosted}>
                      <FormattedMessage {...MODAL_DESCRIPTORS.CREATED} />
                    </th>
                    <th scope="col" className={styles.colLink}>
                      <FormattedMessage {...MODAL_DESCRIPTORS.LINK} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hasCompletedCall &&
                    contentDisplayList.map(
                      (cw: contentItemShape, i: number) => {
                        return (
                          <GClassroomShareItem
                            key={i}
                            itemID={cw.id}
                            itemType={cw.type}
                            itemLink={cw.link}
                            itemName={cw.title}
                            hasFocus={i === focusShiftIndex}
                            itemPosted={cw.creationTime}
                            dataTestPrefix={dataTestPrefix}
                            isSelected={cw.id === selectedItem}
                            setSelectedAction={setSelectedItem}
                            setSelectedItemURL={setSelectedItemURL}
                            isAlert={cw.attachmentReqStatus !== 200}
                            itemAttachmentCount={cw.attachments.length}
                            setSelectedItemRecipients={
                              setSelectedItemRecipients
                            }
                            itemAssignees={
                              cw.assignedTo[0] !== "ALL_STUDENTS"
                                ? cw.assignedTo
                                : []
                            }
                          />
                        );
                      }
                    )}
                  <GClassroomLoadMoreItem
                    currentFilterCount={contentDisplayList.length}
                    setFilteredItemCount={setFilteredItemCount}
                    hasMoreContent={!_.isEmpty(activeNextPageToken)}
                    handleLoadMore={handleLoadMore}
                    dataTestPrefix={dataTestPrefix}
                    isLoading={
                      !isMaterialsActive
                        ? isCourseworkLoadingMore
                        : isMaterialsLoadingMore
                    }
                  />
                </tbody>
              </table>
            )}
            {hasOverflow &&
              !isCourseWorkLoading &&
              !hasMaterialsError &&
              !noCourseWorkAlert && <div className={styles.tableBottom} />}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch: ThunkDispatch<Promise<void>, {}, any>) => ({
    fetchGCCourseWork: (courseID: string) =>
      dispatch(fetchGCCourseWork(courseID)),
    fetchCGMaterials: (courseID: string) =>
      dispatch(fetchCGMaterials(courseID)),
    fetchMoreGCCourseWork: (courseID: string, nextPageToken: string) =>
      dispatch(fetchMoreGCCourseWork(courseID, nextPageToken)),
    fetchMoreCGMaterials: (courseID: string, nextPageToken: string) =>
      dispatch(fetchMoreCGMaterials(courseID, nextPageToken)),
  })
)(ModalFormGClassroomShare);
