import { AnyAction } from "redux";
import actionTypes from "../../actionTypes";
import { DashboardConfigState, LoadConfigSuccessAction } from "./types";
import {
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
  DATA_LOADING_STATE_ERROR,
} from "../../storeUtils";

const INIT_STATE: DashboardConfigState = {
  ...DATA_LOADING_STATE_INIT,
  permissions: [],
  domains: [],
  schoolPrefs: [],
};

const configReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_CONFIG_LOAD_PENDING: {
      return {
        ...state,
        permissions: [],
        ...DATA_LOADING_STATE_PENDING,
      };
    }

    case actionTypes.DASHBOARD_CONFIG_LOAD_SUCCESS: {
      const dashAction = action as LoadConfigSuccessAction;
      return {
        ...state,
        permissions: dashAction.payload.permissions,
        domains: dashAction.payload.domains,
        schoolPrefs: dashAction.payload.schoolPrefs,
        ...DATA_LOADING_STATE_SUCCESS,
      };
    }

    case actionTypes.DASHBOARD_CONFIG_LOAD_ERROR: {
      return {
        ...state,
        permissions: [],
        ...DATA_LOADING_STATE_ERROR,
      };
    }

    default:
      return state;
  }
};

export default configReducer;
