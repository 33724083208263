import ReactModal from "react-modal";
import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./ModalDeprecated.module.scss";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../../atomic/Button/Button";

export const ModalDeprecated = ({
  isOpen = false,
  onClose,
  contentLabel = "",
  className = "",
  children,
  appElementSelector = "#root",
  shouldCloseOnOverlayClick = true,
  hasEmbeddedHeader = true,
  ariaOptions = { labelledby: null, describedby: null },
  title = "",
  classNameHeader = "",
  classNameOverlay = "",
  classNameContent = "",
  dataTestPrefix = "",
  externalScrollOnMobile = true,
}) => {
  ReactModal.setAppElement(appElementSelector);

  const dataTestIdProp = {
    "test-id": dataTestPrefix ? `${dataTestPrefix}-Modal` : "Modal",
  };

  const refContent = useRef();

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
      // focus the inner content for screen readers to correctly navigate into a modal
      _.delay(() => refContent.current && refContent.current.focus(), 300);
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.scroll = "yes";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.scroll = "yes";
    };
  }, [isOpen, refContent]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classnames(
        styles.modalScroll,
        {
          [styles.noScrollOnMobile]: !externalScrollOnMobile,
        },
        classNameContent
      )}
      overlayClassName={classnames(styles.overlay, classNameOverlay)}
      contentLabel={contentLabel || title}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      aria={{
        labelledby: ariaOptions.labelledby,
        describedby: ariaOptions.describedby,
        modal: true,
      }}
      data={dataTestIdProp}
      shouldFocusAfterRender={true}
    >
      <div
        className={classnames(styles.content, className)}
        ref={refContent}
        tabIndex={-1}
      >
        {hasEmbeddedHeader && (
          <div className={classnames(styles.topPanel, classNameHeader)}>
            <h1
              id={dataTestPrefix ? `${dataTestPrefix}-Header` : null} // for a11y to use in labeledby
              data-test-id={dataTestPrefix ? `${dataTestPrefix}-Header` : null}
            >
              {title}
            </h1>
            <Button
              icon="cross"
              onClick={onClose}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              aria-label="Close"
              data-test-id={
                dataTestPrefix ? `${dataTestPrefix}-Button-Close` : null
              }
            />
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

ModalDeprecated.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string, // className for inner content
  classNameHeader: PropTypes.string, // for header
  classNameOverlay: PropTypes.string, // for outer overlay
  classNameContent: PropTypes.string, // for outer content
  contentLabel: PropTypes.string, // (optional) Accessibility label for entire modal
  appElementSelector: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  hasEmbeddedHeader: PropTypes.bool,
  ariaOptions: PropTypes.shape({
    labelledby: PropTypes.string,
    describedby: PropTypes.string,
  }),
  dataTestPrefix: PropTypes.string,
  externalScrollOnMobile: PropTypes.bool,
};

/** @deprecated used named export instead */
export default ModalDeprecated;
