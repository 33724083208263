import React from "react";
import Card from "../Card/Card";
import {
  Action,
  Card as CardType,
  ColumnType,
  Maybe,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import styles from "./Column.module.scss";
import _ from "lodash";
import { AddCardButton } from "./addCardButton";
import { openCardModal } from "../../../../containers/Workspace/BoardPage/navigation";
import classnames from "classnames";

interface ColumnProps {
  columnCards?: CardType[];
  columnType: ColumnType;
  singleColumnCardStyle: boolean;
  flexiCardMode: boolean;
  boardId: string;
  sectionId: string;
  sectionTitle: string;
  dataTestIdPrefix: string;
  permissions: Action[];
  setDeleteCardID: (cardId: string) => void;
  setEvidenceModalPayload: (modalResponse: [string, string]) => void;
}

export const Column = ({
  columnCards,
  columnType,
  singleColumnCardStyle,
  flexiCardMode,
  boardId,
  sectionId,
  sectionTitle,
  dataTestIdPrefix,
  permissions,
  setDeleteCardID,
  setEvidenceModalPayload,
}: ColumnProps) => {
  const orderedCards = _.orderBy(columnCards, ["Order"], ["desc"]);

  const addNewCard = (
    beforeCardId: Maybe<string> | undefined,
    afterCardId: Maybe<string> | undefined
  ) => {
    openCardModal({
      cardId: "",
      boardId: boardId,
      sectionId: sectionId,
      columnType: columnType,
      beforeCardId: beforeCardId,
      afterCardId: afterCardId,
    });
  };

  const isPointerDevice = window.matchMedia("all and (hover: hover)").matches;

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.cardSpacer, {
          [styles.addCardButtonFullHeight]: orderedCards.length === 0,
        })}
      >
        {permissions.includes(Action.CanEdit) && (
          <AddCardButton
            className={classnames(styles.addCardButton, {
              [styles.addCardButtonAlwaysOn]: orderedCards.length === 0,
            })}
            circleClassName={
              orderedCards.length === 0 ? styles.lowZIndexCircle : undefined
            }
            dataTestIdPrefix={dataTestIdPrefix}
            boardId={boardId}
            sectionId={sectionId}
            columnType={columnType}
            onClick={() => {
              addNewCard(getCardIdByIndex(orderedCards, 0), null);
            }}
          />
        )}
      </div>
      {orderedCards?.map((card, index) => {
        const isLastCard =
          orderedCards.length === 1 || index === orderedCards.length - 1; //Used to adjust z-index to accommodate section titles
        return (
          <React.Fragment key={card.ID}>
            <Card
              cardData={card}
              permissions={permissions}
              singleColumnCardStyle={singleColumnCardStyle}
              flexiCardMode={flexiCardMode}
              dataTestIdPrefix={dataTestIdPrefix}
              setDeleteCardID={setDeleteCardID}
              isLastCard={isLastCard}
              sectionTitle={sectionTitle}
              setEvidenceModalPayload={setEvidenceModalPayload}
            />
            <div
              className={classnames(styles.cardSpacer, {
                [styles.columnHover]: orderedCards.length === index + 1,
                [styles.addCardButtonFullHeight]:
                  orderedCards.length === index + 1,
              })}
            >
              {permissions.includes(Action.CanEdit) && (
                <AddCardButton
                  className={classnames(styles.addCardButton, {
                    [styles.addCardButtonAlwaysOn]:
                      !isPointerDevice && orderedCards.length === index + 1,
                  })}
                  circleClassName={
                    orderedCards.length === index + 1
                      ? styles.lowZIndexCircle
                      : undefined
                  }
                  dataTestIdPrefix={dataTestIdPrefix}
                  boardId={boardId}
                  sectionId={sectionId}
                  columnType={columnType}
                  onClick={() => {
                    addNewCard(
                      getCardIdByIndex(orderedCards, index + 1),
                      getCardIdByIndex(orderedCards, index)
                    );
                  }}
                />
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

//Simple method to handle when the index is out of bounds, for the first and last card
const getCardIdByIndex = (cards: CardType[], index: number) => {
  let result: Maybe<string> | undefined = null;

  if (index >= 0 && index < cards.length) {
    result = cards[index].ID;
  }

  return result;
};

export default Column;
