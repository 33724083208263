import React from "react";
import styles from "./MyWorkspacesPage.module.scss";
import WorkspacesList from "../../../partial/Workspace/MyWorkspaces/WorkspacesList/WorkspacesList";

export const PageCentralPanel = () => {
  return (
    <div
      className={styles.contentCenter}
      data-test-id="Ws-RightContent-MyWorkspaces"
    >
      <WorkspacesList />
    </div>
  );
};

export default PageCentralPanel;
