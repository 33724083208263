import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./AssignCollectionsModal.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  getIsResourceAddToCollectionsModalOpen,
  getResourceAddToCollectionsModalData,
} from "../../../../state/resources/selectors";
import { collectionItemType } from "../../../../state/library/collections/types";
import {
  getLibraryCollections,
  getSelectedCollectionId,
} from "../../../../state/library/collections/selectors";
import {
  assignResourceToCollections,
  loadAssignedCollectionsList,
  createNewCollection,
  loadLibraryCollections,
} from "../../../../state/library/collections/actions";
import { resetLibraryItems } from "../../../../state/library/library/actions";
import { COLLECTIONS_MAX_AMOUNT } from "../../../../state/library/collections/types";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { hideResourceAddToCollectionsModal } from "../../../../state/resources/actions";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import AssignCollectionModalLoading from "./AssignCollectionModalLoading";
import AssignCollectionModalError from "./AssignCollectionModalError";
import AssignCollectionModalEmpty from "./AssignCollectionModalEmpty";
import { LIBRARY_CATEGORIES } from "../../../../state/library/library/types";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, {
  ALERT_TYPES,
} from "@hapara/ui/src/atomic/AlertDeprecated/Alert";
import {
  showSuccessToast,
  hideSuccessToast,
} from "../../../../state/app/actions";
import Input, {
  INPUT_DEFAULT_MAX_LENGTH,
} from "@hapara/ui/src/atomic/Input/Input";
import { FocusContext } from "@hapara/ui/src/components/utils";

const SAVE_BUTTON_DEFAULT_LABEL = "Apply";

const ACTION_TYPE = {
  CREATE: "create",
  APPLY: "apply",
};
const ELASTIC_INDEX_DELAY = 2000;

const AssignCollectionsModal = ({
  isModalOpen = false,
  collectionsList,
  activeCollectionId = "",
  modalData,
  onModalClose,
  showSuccessMessage,
  hideSuccessMessage,
  loadAssignedCollectionsList,
  saveAssignedCollections,
  createCollection,
  loadLibraryCollections,
  resetLibraryItems,
}) => {
  const { resourceId } = modalData;
  const dataTestPrefix = "lb-Resource-AssignToCollections-Modal";
  const [isLoading, setIsLoading] = useState(true);
  const [collectionSorted, setCollectionSorted] = useState([]);
  const [collectionToShow, setCollectionToShow] = useState([]);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [isAssignedLoadError, setIsAssignedLoadError] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [wasSelectedCollections, setWasSelectedCollections] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [saveButtonLabel, setSaveButtonLabel] = useState(
    collectionsList.length === 0 ? "Add Collection" : SAVE_BUTTON_DEFAULT_LABEL
  );
  const [actionType, setActionType] = useState(ACTION_TYPE.APPLY);
  const focusContext = useContext(FocusContext);

  const closeModal = () => {
    focusContext.restoreFocus();
    onModalClose();
  };

  const isCollectionsLimitReached =
    collectionsList && collectionsList.length >= COLLECTIONS_MAX_AMOUNT;

  useEffect(() => {
    hideSuccessMessage();
    if (collectionsList.length === 0) {
      setIsLoading(false);
    } else {
      loadAssignedCollectionsList({ resourceId })
        .then((resp) => {
          const selectedCols = _.intersection(
            resp,
            _.map(collectionsList, (c) => c.id)
          );
          setSelectedCollections(selectedCols);
          setWasSelectedCollections(selectedCols);
          const sortedCollections = _.sortBy(collectionsList, "name");
          const colsSorted = [
            ...sortedCollections.filter((s) => _.includes(selectedCols, s.id)),
            ...sortedCollections.filter((s) => !_.includes(selectedCols, s.id)),
          ];
          setCollectionSorted(colsSorted);
          setCollectionToShow(colsSorted);
        })
        .catch(() => {
          setIsAssignedLoadError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    collectionsList,
    loadAssignedCollectionsList,
    resourceId,
    hideSuccessMessage,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (actionType === ACTION_TYPE.APPLY) {
      applyAction();
    } else if (actionType === ACTION_TYPE.CREATE) {
      createAndApplyAction();
    }
  };

  const successAction = ({ reloadCollections, reloadResources }) => {
    _.delay(() => {
      showSuccessMessage();
      setIsSaveInProgress(false);
      closeModal();
      if (reloadCollections) {
        loadLibraryCollections();
      }
      if (reloadResources) {
        resetLibraryItems();
      }
    }, ELASTIC_INDEX_DELAY);
  };

  const createAndApplyAction = () => {
    setIsSaveInProgress(true);
    setIsSaveError(false);

    createCollection(inputValue)
      .then((collectionId) => {
        saveAssignedCollections({
          resourceId,
          addCollectionsIds: [collectionId],
          removeCollectionsIds: [],
        })
          .then(() => {
            successAction({
              reloadCollections: true,
              reloadResources:
                activeCollectionId === LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey, // should reload resources only if we add a resource from `uncategorized` to a new collection
            });
          })
          .catch(() => {
            setIsSaveError(true);
            setIsSaveInProgress(false);
          });
      })
      .catch(() => {
        setIsSaveError(true);
        setIsSaveInProgress(false);
      });
  };

  const applyAction = () => {
    setIsSaveInProgress(true);
    setIsSaveError(false);

    const addCollectionsIds = _.difference(
      selectedCollections,
      wasSelectedCollections
    );
    const removeCollectionsIds = _.difference(
      wasSelectedCollections,
      selectedCollections
    );

    if (!_.isEmpty(addCollectionsIds) || !_.isEmpty(removeCollectionsIds)) {
      saveAssignedCollections({
        resourceId,
        addCollectionsIds,
        removeCollectionsIds,
      })
        .then(() => {
          const reloadRes =
            _.includes(removeCollectionsIds, activeCollectionId) ||
            (activeCollectionId === LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey &&
              !_.isEmpty(addCollectionsIds));
          successAction({
            reloadCollections: false,
            reloadResources: reloadRes,
          });
        })
        .catch(() => {
          setIsSaveError(true);
          setIsSaveInProgress(false);
        });
    } else {
      successAction({ reloadCollections: false, reloadResources: false });
    }
  };

  const handleInputValueChange = (e) => {
    const newValue = _.get(e, "target.value", "");
    setInputValue(newValue);

    if (newValue && newValue.length > 0) {
      setSaveButtonLabel(`Create "${newValue}"`);
      setActionType(ACTION_TYPE.CREATE);
    } else {
      setSaveButtonLabel(SAVE_BUTTON_DEFAULT_LABEL);
      setActionType(ACTION_TYPE.APPLY);
    }

    setCollectionToShow(
      _.filter(collectionSorted, (collection) =>
        _.includes(_.toLower(collection.name), _.toLower(newValue))
      )
    );
  };

  const handleSelect = (isChecked, collectionId) => {
    if (isChecked) {
      // remove from selected array
      setSelectedCollections(
        selectedCollections.filter((c) => c !== collectionId)
      );
      setSaveButtonLabel(SAVE_BUTTON_DEFAULT_LABEL);
      setActionType(ACTION_TYPE.APPLY);
    } else {
      // add to selected array
      setSelectedCollections([...selectedCollections, collectionId]);
      setSaveButtonLabel(SAVE_BUTTON_DEFAULT_LABEL);
      setActionType(ACTION_TYPE.APPLY);
    }
  };

  const sameTitleExists = useMemo(
    () =>
      !!_.find(collectionsList, (item) => {
        return (
          item.name.toLowerCase() === inputValue.toLowerCase() && !item.deleted
        );
      }),
    [collectionsList, inputValue]
  );

  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      onClose={closeModal}
      hasEmbeddedHeader={false}
      className={styles.root}
      dataTestPrefix={dataTestPrefix}
      contentLabel="Add to collections"
    >
      <div className={styles.modalTitle}>
        <div className={styles.modalTitleHeaderContainer}>
          <h1
            data-test-id={`${dataTestPrefix}-Header`}
            className={styles.modalTitleHeader}
          >
            <FormattedMessage defaultMessage="Add to collections" id="wpFp98" />
          </h1>
          <Button
            icon="cross"
            onClick={closeModal}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            aria-label="Close"
            data-test-id={`${dataTestPrefix}-Button-Close`}
            className={styles.modalTitleClose}
          />
        </div>
      </div>
      {isLoading && <AssignCollectionModalLoading />}
      {isAssignedLoadError && <AssignCollectionModalError />}
      {!isLoading && !isAssignedLoadError && (
        <>
          <div className={styles.modalBody}>
            <div className={styles.inputWrapper}>
              <Input
                type="text"
                placeholder="Enter a name..."
                data-test-id={`${dataTestPrefix}-Collection-TitleInput`}
                value={inputValue}
                onChange={handleInputValueChange}
                className={styles.inputField}
                maxLength={INPUT_DEFAULT_MAX_LENGTH}
                showLimitWarning={true}
                aria-label="Collection name"
              />
              {sameTitleExists && (
                <div
                  className={styles.sameTitleExists}
                  aria-live="polite"
                  id="error-duplicate-error"
                >
                  <FormattedMessage
                    defaultMessage="This name already exists"
                    id="SWNBxA"
                  />
                </div>
              )}
            </div>

            {collectionSorted.length === 0 && <AssignCollectionModalEmpty />}

            {collectionSorted.length > 0 && (
              <ul className={styles.multiSelect}>
                {collectionToShow.map((i) => {
                  const isChecked = _.indexOf(selectedCollections, i.id) >= 0;
                  return (
                    <li
                      key={i.id}
                      className={classnames({
                        [styles.selectedItem]: isChecked,
                      })}
                    >
                      <Checkbox
                        checked={isChecked}
                        onChange={() => handleSelect(isChecked, i.id)}
                        label={i.name}
                        dataTestIdPrefix={`${dataTestPrefix}-Checkbox-AssignClass`}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className={styles.actions}>
            {isSaveError && (
              <Alerts>
                <Alert
                  type={ALERT_TYPES.FAILURE}
                  isVisible={true}
                  className={styles.alert}
                >
                  <FormattedMessage
                    defaultMessage="Sorry, there was a problem updating. Please try again."
                    id="W4HePf"
                  />
                </Alert>
              </Alerts>
            )}
            <WarningMessage
              isVisible={isCollectionsLimitReached}
              dataTestId={`${dataTestPrefix}-MaxCollectionsReached-Warning`}
              className={styles.warningLimit}
            >
              {`Your organization has reached the maximum number of collections for the library (${COLLECTIONS_MAX_AMOUNT}).`}
            </WarningMessage>
            <div className={styles.actionsInner}>
              <Button
                label="Cancel"
                onClick={closeModal}
                data-test-id={`${dataTestPrefix}-Button-Cancel`}
                type={BUTTON_TYPES.SECONDARY}
                className={styles.cancelButton}
              />
              <Button
                label={saveButtonLabel}
                onClick={handleSubmit}
                data-test-id={`${dataTestPrefix}-Button-Save`}
                className={styles.sendButton}
                isDisabled={
                  isSaveInProgress ||
                  (actionType === ACTION_TYPE.APPLY &&
                    _.isEqual(
                      wasSelectedCollections.sort(),
                      selectedCollections.sort()
                    )) ||
                  (actionType === ACTION_TYPE.CREATE &&
                    (sameTitleExists || isCollectionsLimitReached))
                }
                isLoading={isSaveInProgress && !isSaveError}
              />
            </div>
          </div>
        </>
      )}
    </ModalDeprecated>
  );
};

AssignCollectionsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  collectionsList: PropTypes.arrayOf(collectionItemType),
  activeCollectionId: PropTypes.string,
  modalData: PropTypes.shape({
    resourceId: PropTypes.string,
  }),

  onModalClose: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
  loadAssignedCollectionsList: PropTypes.func.isRequired,
  saveAssignedCollections: PropTypes.func.isRequired,
  createCollection: PropTypes.func.isRequired,
  loadLibraryCollections: PropTypes.func.isRequired,
  resetLibraryItems: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getIsResourceAddToCollectionsModalOpen(state),
    collectionsList: getLibraryCollections(state),
    activeCollectionId: getSelectedCollectionId(state),
    modalData: getResourceAddToCollectionsModalData(state),
  }),
  (dispatch) => ({
    onModalClose: () => dispatch(hideResourceAddToCollectionsModal()),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
    loadAssignedCollectionsList: ({ resourceId }) =>
      dispatch(loadAssignedCollectionsList(resourceId)),
    saveAssignedCollections: ({
      resourceId,
      addCollectionsIds,
      removeCollectionsIds,
    }) =>
      dispatch(
        assignResourceToCollections(
          resourceId,
          addCollectionsIds,
          removeCollectionsIds
        )
      ),
    createCollection: (name) => dispatch(createNewCollection(name)),
    loadLibraryCollections: () => dispatch(loadLibraryCollections()),
    resetLibraryItems: () => dispatch(resetLibraryItems()),
  })
)(AssignCollectionsModal);
