import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import emptyThumbnail from "@hapara/assets/src/images/ws-thumbnail-default.svg";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import Input from "@hapara/ui/src/atomic/Input/Input";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import styles from "./CreateForm.module.scss";
import { navigateBack } from "../../../../../state/workspace/create/navigation";
import {
  clearCreateWorkspace,
  loadCreateWorkspaceInit,
  sendCreateWorkspace,
} from "../../../../../state/workspace/create/actions";
import {
  getCreateWorkspaceInitLoading,
  getCreateWorkspaceInitLoaded,
  getCreateWorkspaceInitId,
  getCreateWorkspaceLoading,
  getCreateWorkspaceLoaded,
  getCreateWorkspaceError,
} from "../../../../../state/workspace/create/selectors";
import { getMyClasses } from "../../../../../state/shared/selectors";
import WorkspaceThumbnailModal from "../../MyWorkspaces/WorkspaceThumbnailModal/WorkspaceThumbnailModal";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getFFByName } from "../../../../../state/app/selectors";
import { myClassItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import { FormattedMessage, useIntl } from "react-intl";

const CreateForm = ({
  isPl,
  clearCreateWorkspace,
  loadCreateWorkspaceInit,
  sendCreateWorkspace,
  isInitLoading,
  isInitLoaded,
  initId,
  isCreateLoading,
  isCreateLoaded,
  isCreateError,
  myClasses,
  plFF,
  isWSSubPathRedirectEnabledFF,
}) => {
  const COVER_IMAGE_SIZE = 150;
  const COVER_IMAGE_INIT = { url: "", alt: "", downloadUrl: "" };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverImage, setCoverImage] = useState(COVER_IMAGE_INIT);
  const coverImageSelectRef = useRef();
  const [isCoverImageModalOpen, setIsCoverImageModalOpen] = useState(false);
  const [isCoverImageSelected, setCoverImageSelected] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState({});
  const [autoCreateGroups, setAutoCreateGroups] = useState(true);

  const h1Id = "workspace-create-page-heading";
  const titleId = "workspace-create-title";
  const descriptionId = "workspace-create-description";
  const autoGroupsId = "workspace-create-auto-groups";
  const coverImageDescriptionId = "workspace-create-cover-image";
  const dataTestIdPrefix = isPl
    ? "ws-CreateWorkspace-PL"
    : "ws-CreateWorkspace-Student";

  const createWs = () => {
    sendCreateWorkspace({
      id: initId,
      title,
      description,
      isPlWorkspace: isPl,
      thumbnail: coverImage.url,
      thumbnailAlt: coverImage.alt,
      classIds: _.keys(selectedClasses),
      isAutoCreateGroupForClasses: autoCreateGroups,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createWs();
  };
  const handleSelectClass = (id) => {
    const updatedSelectedClasses = _.clone(selectedClasses);
    if (updatedSelectedClasses[id]) {
      delete updatedSelectedClasses[id];
    } else {
      updatedSelectedClasses[id] = true;
    }
    setSelectedClasses(updatedSelectedClasses);
  };

  // clean up the store when unmount
  useEffect(() => {
    return () => {
      clearCreateWorkspace();
    };
  }, [clearCreateWorkspace]);

  // init WS if not initialised yet
  useEffect(() => {
    if (!isInitLoaded && !isInitLoading) {
      loadCreateWorkspaceInit();
    }
  }, [loadCreateWorkspaceInit, isInitLoading, isInitLoaded]);

  // listen to create props and redirect to the new WS when finished
  useEffect(() => {
    if (isCreateLoaded && !isCreateError) {
      window.location.href = isWSSubPathRedirectEnabledFF
        ? `${process.env.REACT_APP_HSUITE_BASE_URL}/ws/#/workspace/w/${initId}`
        : `${process.env.REACT_APP_OLD_WORKSPACE_URL}/#/workspace/w/${initId}`;
    }
  }, [isCreateError, isCreateLoaded, initId]);

  const isLoadingError = isInitLoaded && !initId;
  const isLoadingOrLoadingError =
    isInitLoading || (!isInitLoading && isLoadingError);

  const intl = useIntl();

  return (
    <>
      <form
        aria-labelledby={h1Id}
        onSubmit={handleSubmit}
        className={styles.root}
      >
        {isLoadingOrLoadingError && (
          <div className={styles.loadingBody}>
            <PageLoading
              isError={isLoadingError}
              dataTestIdPrefix={dataTestIdPrefix}
            />
          </div>
        )}

        {!isLoadingOrLoadingError && (
          <>
            <div className={styles.body}>
              <div className={styles.bodyInner}>
                <h1 id={h1Id} className={styles.title}>
                  {isPl ? (
                    <FormattedMessage
                      defaultMessage="New professional learning Workspace"
                      id="LAdXJN"
                    />
                  ) : plFF ? (
                    <FormattedMessage
                      defaultMessage="New student Workspace"
                      id="K7/6be"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="New Workspace"
                      id="DfiC3H"
                    />
                  )}
                </h1>

                <div className={styles.alerts}>
                  <Alert
                    type={ALERT_TYPES.FAILURE}
                    isVisible={isCreateError}
                    role=""
                    ariaLive=""
                    ariaAtomic=""
                    focusOnRender={true}
                    dataTestId={`${dataTestIdPrefix}-Error`}
                  >
                    <FormattedMessage
                      defaultMessage="Sorry, there was a problem creating this Workspace. Please try again."
                      id="7eJ/Zr"
                    />
                  </Alert>
                </div>

                {isInitLoaded && initId && (
                  <div className={styles.fieldset}>
                    <div className={styles.fieldBlock}>
                      <label htmlFor={titleId} className={styles.fieldLabel}>
                        {/* // eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        <FormattedMessage defaultMessage="Title" id="9a9+ww" />{" "}
                        <span className={styles.required}>
                          <FormattedMessage
                            defaultMessage="(Required)"
                            id="my7Qsh"
                          />
                        </span>
                      </label>
                      <Input
                        type="text"
                        name={titleId}
                        id={titleId}
                        placeholder="Give your Workspace a name"
                        data-test-id={`${dataTestIdPrefix}-TitleInput`}
                        aria-required={true}
                        value={title}
                        onChange={(e) => setTitle(_.get(e, "target.value", ""))}
                      />
                    </div>
                    {!isPl && (
                      <div className={styles.fieldBlock}>
                        <p className={styles.fieldLabel}>
                          <FormattedMessage
                            defaultMessage="Class"
                            id="nraEHM"
                          />
                        </p>
                        <fieldset
                          data-test-id={`${dataTestIdPrefix}-SetOfClasses`}
                        >
                          <legend className={styles.fieldDescription}>
                            <FormattedMessage
                              defaultMessage="Select the classes that can access this Workspace"
                              id="uZGR3V"
                            />
                          </legend>
                          <ul className={styles.classesContainer}>
                            {_.map(myClasses, (clazz) => (
                              <li key={clazz.id}>
                                <Checkbox
                                  checked={!!selectedClasses[clazz.id]}
                                  id={clazz.id}
                                  onChange={() => {
                                    handleSelectClass(clazz.id);
                                  }}
                                  label={clazz.class_alias}
                                  dataTestIdPrefix={`${dataTestIdPrefix}-SelectClass`}
                                  className={styles.classesCheckbox}
                                />
                              </li>
                            ))}
                          </ul>
                        </fieldset>
                        <Checkbox
                          className={styles.underFieldset}
                          checked={!autoCreateGroups}
                          id={autoGroupsId}
                          onChange={() =>
                            setAutoCreateGroups(!autoCreateGroups)
                          }
                          label="I will create my own groups instead of having a group automatically generated per class."
                          dataTestIdPrefix={`${dataTestIdPrefix}-SelectAutoGroups`}
                        />
                      </div>
                    )}
                    <div className={styles.fieldBlock}>
                      <label
                        htmlFor={descriptionId}
                        className={styles.fieldLabel}
                      >
                        <FormattedMessage
                          defaultMessage="Description"
                          id="Q8Qw5B"
                        />
                      </label>
                      <Textarea
                        name={descriptionId}
                        id={descriptionId}
                        placeholder="What is this Workspace about?"
                        data-test-id={`${dataTestIdPrefix}-DescriptionInput`}
                        value={description}
                        onChange={(e) =>
                          setDescription(_.get(e, "target.value", ""))
                        }
                        rows={2}
                      />
                    </div>
                    <div>
                      <label className={styles.fieldLabel}>
                        <FormattedMessage
                          defaultMessage="Cover image"
                          id="TilRBW"
                        />
                      </label>
                      <p
                        id={coverImageDescriptionId}
                        className={styles.fieldDescription}
                      >
                        <FormattedMessage
                          defaultMessage="Select a cover image to personalize this Workspace"
                          id="gXTuKo"
                        />
                      </p>
                      <div>
                        <button
                          type="button"
                          aria-describedby={coverImageDescriptionId}
                          aria-label={
                            coverImage.url
                              ? intl.formatMessage({
                                  defaultMessage: "Edit existing cover image",
                                  id: "NCZijj",
                                })
                              : intl.formatMessage({
                                  defaultMessage: "Add new cover image",
                                  id: "6IeQqe",
                                })
                          }
                          onClick={() => {
                            setCoverImageSelected(false);
                            setIsCoverImageModalOpen(true);
                          }}
                          className={styles.coverImageSelect}
                          data-test-id={`${dataTestIdPrefix}-SelectCoverImageButton`}
                          ref={coverImageSelectRef}
                        >
                          <img
                            src={
                              coverImage.url
                                ? `${coverImage.url}&w=${
                                    COVER_IMAGE_SIZE * 2
                                  }&h=${COVER_IMAGE_SIZE * 2}`
                                : emptyThumbnail
                            }
                            width={`${COVER_IMAGE_SIZE}`}
                            height={`${COVER_IMAGE_SIZE}`}
                            alt={coverImage.alt || ""}
                          />
                          <span className={styles.coverImageIcon}>
                            <HapReactIcon svg="pencil" width={16} height={16} />
                          </span>
                        </button>
                      </div>

                      {coverImage.url && (
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              setCoverImage(COVER_IMAGE_INIT);
                              setCoverImageSelected(false);
                              coverImageSelectRef.current.focus();
                            }}
                            className={styles.coverImageClear}
                            data-test-id={`${dataTestIdPrefix}-ClearCoverImageButton`}
                          >
                            <FormattedMessage
                              defaultMessage="Remove cover image"
                              id="40T9hx"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className={styles.footer}>
                  <Button
                    label="Cancel"
                    data-test-id={`${dataTestIdPrefix}-CancelButton`}
                    type={BUTTON_TYPES.OUTLINED}
                    outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                    size={BUTTON_SIZES.LARGE}
                    onClick={() => navigateBack()}
                  />
                  <Button
                    label="Save as draft"
                    data-test-id={`${dataTestIdPrefix}-CreateButton`}
                    type={BUTTON_TYPES.PRIMARY}
                    size={BUTTON_SIZES.LARGE}
                    isDisabled={!title || isCreateLoading}
                    isLoading={isCreateLoading}
                    onClick={() => createWs()}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </form>

      <WorkspaceThumbnailModal
        isOpen={isCoverImageModalOpen}
        onClose={() => {
          setIsCoverImageModalOpen(false);
        }}
        onSave={(updatedThumbnail) => {
          setCoverImage(updatedThumbnail);
          setCoverImageSelected(true);
        }}
        predefined={coverImage}
        isSavingThumbnailSuccess={isCoverImageSelected}
      />
    </>
  );
};

CreateForm.propTypes = {
  isPl: PropTypes.bool.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  isInitLoaded: PropTypes.bool.isRequired,
  initId: PropTypes.string,
  isCreateLoading: PropTypes.bool.isRequired,
  isCreateLoaded: PropTypes.bool.isRequired,
  isCreateError: PropTypes.bool.isRequired,
  myClasses: PropTypes.arrayOf(myClassItemType),
  clearCreateWorkspace: PropTypes.func.isRequired,
  loadCreateWorkspaceInit: PropTypes.func.isRequired,
  sendCreateWorkspace: PropTypes.func.isRequired,
  plFF: PropTypes.bool.isRequired,
  isWSSubPathRedirectEnabledFF: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isInitLoading: getCreateWorkspaceInitLoading(state),
    isInitLoaded: getCreateWorkspaceInitLoaded(state),
    initId: getCreateWorkspaceInitId(state),
    isCreateLoading: getCreateWorkspaceLoading(state),
    isCreateLoaded: getCreateWorkspaceLoaded(state),
    isCreateError: getCreateWorkspaceError(state),
    myClasses: getMyClasses(state),
    plFF: getFFByName("HAP-7034-professional-learning")(state),
    isWSSubPathRedirectEnabledFF: getFFByName("HAP-11276-ws-subpath")(state),
  }),
  (dispatch) => ({
    clearCreateWorkspace: () => dispatch(clearCreateWorkspace()),
    loadCreateWorkspaceInit: () => dispatch(loadCreateWorkspaceInit()),
    sendCreateWorkspace: (options) => dispatch(sendCreateWorkspace(options)),
  })
)(CreateForm);
