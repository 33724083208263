import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./GradingDocumentLink.module.scss";
import moment from "moment";
import _ from "lodash";
import {
  GOOGLE_DOCS_ICON_HOST,
  MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  MYWORKSPACES_GRADING_DOCUMENT_TYPE,
  MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
} from "../../../../../state/workspace/grading/types";
import {
  ButtonLink,
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getDocType, getUrl } from "../../../../../utils";
import {
  getGradingArtifact,
  getAssigneeData,
  getGradingActivity,
} from "../../../../../state/workspace/grading/selectors";

export const GradingDocumentLink = ({ artifact, activity, assigneeData }) => {
  const artifactDocument = getUrl(artifact);
  const embeddableDocType = getDocType(artifact);
  const iconUrl = embeddableDocType
    ? `${GOOGLE_DOCS_ICON_HOST}${embeddableDocType.iconAppendix}`
    : null;

  const activityDate = moment(assigneeData.ActivityDate).format("MMM DD, YYYY");

  const label = (
    <span className={styles.buttonLabel}>
      {iconUrl && (
        <img
          src={iconUrl}
          width={16}
          height={16}
          className={styles.artifactIcon}
          alt=""
        />
      )}
      <span>
        <FormattedMessage
          defaultMessage="Open file in new window"
          id="v9p6jI"
        />
      </span>
    </span>
  );

  if (artifactDocument.type === MYWORKSPACES_GRADING_DOCUMENT_TYPE.EXTERNAL) {
    return null;
  } else {
    return (
      <div className={styles.root}>
        <p
          className={styles.submittedDate}
          data-test-id="ws-Workspace-Grading-SubmittedActitivyAndDate"
        >
          <span>{_.upperFirst(activity)}: </span>
          {activityDate}
        </p>
        <ButtonLink
          href={artifactDocument.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.button}
          data-test-id="ws-Workspace-Grading-OpenWithoutIframe"
          label={label}
          rightIcon="arrow-external-link"
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.OUTLINED}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        />
      </div>
    );
  }
};

GradingDocumentLink.propTypes = {
  artifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  activity: PropTypes.string,
  assigneeData: MYWORKSPACES_GRADING_ASSIGNEE_TYPE,
};

export default connect((state) => ({
  artifact: getGradingArtifact(state),
  activity: getGradingActivity(state),
  assigneeData: getAssigneeData(state),
}))(GradingDocumentLink);
