import { FormattedMessage } from "react-intl";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import styles from "./UploadForm.module.scss";
import { getFilters } from "../../../../state/shared/selectors";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";

const UploadFormLicence = ({
  srcLicences,
  licenceType,
  onLicenceTypeSelect,
  dataTestIdPrefix,
  id,
}) => {
  const handleLicenceTypeSelect = (e) => {
    onLicenceTypeSelect(e.target.value);
  };

  const groupedLicences = useMemo(() => {
    return _.groupBy(srcLicences, "GroupKey");
  }, [srcLicences]);

  const licenceGroups = useMemo(() => {
    return _.keyBy(_.uniqBy(srcLicences, "GroupKey"), "GroupKey");
  }, [srcLicences]);

  return (
    <fieldset className={styles.fieldBlock}>
      <div className={styles.fieldHeader}>
        <legend className={styles.fieldLabel}>
          License type
          <span className={styles.required}>
            <FormattedMessage defaultMessage="(required)" id="VG94fP" />
          </span>
        </legend>
        <a
          href="https://support.hapara.com/hc/en-us/articles/4406326324109"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.fieldInfoLink}
          data-test-id={`${dataTestIdPrefix}-LicenseHelpLink`}
        >
          <FormattedMessage defaultMessage="Licenses explained" id="fSPhXg" />
        </a>
      </div>
      {_.map(groupedLicences, (group, groupKey) => (
        <div className={styles.radioGroup} key={groupKey}>
          <div className={styles.radioGroupTitle}>
            {_.get(licenceGroups[groupKey], "Group", "")}
          </div>
          <div className={styles.radioGroupBody}>
            {_.map(group, (licence) => {
              const radioId = _.get(licence, "Key");
              const name = _.get(licence, "Value");
              const description = _.get(licence, "Description");
              const link = _.get(licence, "Link");
              const isSelected = licenceType === radioId;
              return (
                <div key={radioId} className={styles.radioGroupItem}>
                  <Radio
                    id={radioId}
                    value={radioId}
                    name={id}
                    label={name}
                    checked={isSelected}
                    onChange={handleLicenceTypeSelect}
                    dataTestIdPrefix={`${dataTestIdPrefix}-LicenseRadio`}
                  />
                  {description && isSelected && (
                    <dl className={styles.radioGroupDesc}>
                      <dt className={styles.radioGroupDescTerm}>{name}</dt>
                      <dd className={styles.radioGroupDescDef}>
                        {description}{" "}
                        {link && (
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-test-id={`${dataTestIdPrefix}-LicenseLearnMoreLink`}
                          >
                            <FormattedMessage
                              defaultMessage="Learn more."
                              id="hl3R0S"
                            />
                          </a>
                        )}
                      </dd>
                    </dl>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </fieldset>
  );
};

UploadFormLicence.propTypes = {
  licenceType: PropTypes.string,
  dataTestIdPrefix: PropTypes.string.isRequired,
  onLicenceTypeSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  // connected
  srcLicences: PropTypes.arrayOf(filterItemType),
};

export default connect((state) => ({
  srcLicences: getFilters(SHARED_FILTER_TYPES.LICENCES.value)(state),
}))(UploadFormLicence);
