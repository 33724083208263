import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { APP_PAGES } from "../../../../state/router/types";
import SubMenu from "../../SubMenu/SubMenu";
import stylesSubMenu from "../../SubMenu/SubMenu.module.scss";
import styles from "./HLSubMenu.module.scss";
import { getClassId } from "../../../../state/shared/selectors";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import ToolbarGroups from "../ToolbarGroups/ToolbarGroups";
import ToolbarSort from "../ToolbarSort/ToolbarSort";
import ToolbarPause from "../ToolbarPause/ToolbarPause";
import ToolbarRefresh from "../ToolbarRefresh/ToolbarRefresh";
import ToolbarFreezeTabs from "../ToolbarFreezeTabs/ToolbarFreezeTabs";
import ToolbarTilesSize from "../ToolbarTilesSize/ToolbarTilesSize";
import ToolbarToggleView from "../ToolbarToggleView/ToolbarToggleView";
import { ChatTeacher } from "../../ChatTeacher";
import { getScreenshotsEnabled } from "../../../../state/highlights/config/selectors";
import { getLocationPath } from "../../../../state/router/selectors";
import { getWindowSize } from "../../../../state/app/selectors";
import {
  WINDOW_SIZE_TYPES,
  WINDOW_SIZE_TYPE_LIST,
} from "../../../../state/app/types";
import { isSharingViewEnabled } from "../../../../state/user/selectors";
import { updateActiveViewType } from "../../../../state/highlights/config/actions";
// TODO: Delete 'images/google-drive.svg' and use the icon instead
import DriveIcon from "@hapara/assets/src/images/google-drive.svg";
import { STUDENT_ORDER_TYPES } from "../../../../state/highlights/config/types";
import { FormattedMessage } from "react-intl";
import { getFFByName } from "../../../../state/app/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { PAGE_TYPES } from "../../../../hooks/useGroups";
import { getActiveViewType } from "../../../../state/highlights/config/selectors";

const HLSubMenu = ({
  selectedClassId,
  isScreenshotsEnabled = false,
  locationPath,
  windowSize,
  isSharingViewEnabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isCurrentScreens =
    locationPath === APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path;
  const isBrowserTabs = locationPath === APP_PAGES.HIGHLIGHTS_ACTIVITY.path;
  const isDriveScreen = locationPath === APP_PAGES.HIGHLIGHTS_DRIVE.path;
  const isClassView = locationPath === APP_PAGES.HIGHLIGHTS_CLASS_VIEW.path;
  const showToolbar =
    isCurrentScreens || isBrowserTabs || isDriveScreen || isClassView;
  const dataTestIdPrefix = "hl-HighlightsSubNav";
  const hasUpdatedDriveFilter = useSelector(
    getFFByName("HAP-11775-highlights-drive-filter-update")
  );

  const hasClassViewEnabled = useSelector(
    getFFByName("GBE-01-class-view-page")
  );
  const hasNavBarButtonUpdateFlag = useSelector(
    getFFByName("GBE-02-nav-bar-button-update")
  );
  const handleViewToggle = (viewType) => {
    dispatch(updateActiveViewType(viewType));
  };
  const activeStudentView = useSelector(getActiveViewType);

  const STUDENT_ORDER_DROPDOWN_ITEMS_FULL = [
    {
      type: STUDENT_ORDER_TYPES.ONLINE,
      label: intl.formatMessage({
        defaultMessage: "Online first",
        id: "rfHR9Q",
      }),
    },
    {
      type: STUDENT_ORDER_TYPES.OFFLINE,
      label: intl.formatMessage({
        defaultMessage: "Offline first",
        id: "T+ovyj",
      }),
    },
    {
      type: STUDENT_ORDER_TYPES.FIRST_AZ,
      label: intl.formatMessage({
        defaultMessage: "First Name (A-Z)",
        id: "22cP5C",
      }),
    },
    {
      type: STUDENT_ORDER_TYPES.LAST_AZ,
      label: intl.formatMessage({
        defaultMessage: "Last Name (A-Z)",
        id: "YUwFEk",
      }),
    },
  ];

  return (
    <>
      <SubMenu
        tabsLabel="Highlights pages"
        className={styles.root}
        tabsClassName={classnames(styles.tabs, {
          [styles.tabsOnly]: !showToolbar,
        })}
        toolbarClassName={styles.toolbar}
        tabs={
          <>
            {hasClassViewEnabled && (
              <NavLink
                to={`${APP_PAGES.HIGHLIGHTS_CLASS_VIEW.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
                strict
                activeClassName={stylesSubMenu.active}
                data-test-id={`${dataTestIdPrefix}-Link-ClassView`}
              >
                <FormattedMessage defaultMessage="Class View" id="KldUa6" />
              </NavLink>
            )}
            <NavLink
              to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY_VIEWER.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
              strict
              activeClassName={stylesSubMenu.active}
              data-test-id={`${dataTestIdPrefix}-Link-ActivityViewer`}
            >
              <FormattedMessage defaultMessage="Activity Viewer" id="kt+HLR" />
            </NavLink>
            <NavLink
              to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
              strict
              activeClassName={stylesSubMenu.active}
              data-test-id={`${dataTestIdPrefix}-Link-BrowserTabs`}
            >
              <FormattedMessage defaultMessage="Browser Tabs" id="neZYFg" />
            </NavLink>
            {isScreenshotsEnabled && (
              <>
                <NavLink
                  to={`${APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
                  strict
                  activeClassName={stylesSubMenu.active}
                  data-test-id={`${dataTestIdPrefix}-Link-CurrentScreens`}
                >
                  <FormattedMessage
                    defaultMessage="Current Screens"
                    id="JMQ43b"
                  />
                </NavLink>
                <NavLink
                  to={`${APP_PAGES.HIGHLIGHTS_SNAPS.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
                  strict
                  activeClassName={stylesSubMenu.active}
                  data-test-id={`${dataTestIdPrefix}-Link-Snaps`}
                >
                  <FormattedMessage defaultMessage="Snaps" id="dhJ3ab" />
                </NavLink>
              </>
            )}
            <NavLink
              to={`${APP_PAGES.HIGHLIGHTS_SCHEDULE.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`}
              strict
              activeClassName={stylesSubMenu.active}
              data-test-id={`${dataTestIdPrefix}-Link-Schedule`}
            >
              <FormattedMessage defaultMessage="Schedule" id="hGQqkW" />
            </NavLink>
            {isSharingViewEnabled && (
              <NavLink
                to={
                  hasUpdatedDriveFilter
                    ? `${APP_PAGES.HIGHLIGHTS_DRIVE.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}&docType=all`
                    : `${APP_PAGES.HIGHLIGHTS_DRIVE_OLD.path}?${SHARED_URL_KEYS.classId.key}=${selectedClassId}`
                }
                strict
                activeClassName={stylesSubMenu.active}
                data-test-id={`${dataTestIdPrefix}-Link-Drive`}
              >
                <span className={styles.iconContainer}>
                  <img className={styles.icon} src={DriveIcon} alt="" />
                  <FormattedMessage
                    defaultMessage="Drive"
                    id="nJT5gO"
                    description="Google [Drive]"
                  />
                </span>
              </NavLink>
            )}
          </>
        }
        toolbar={
          showToolbar ? (
            <div className={styles.toolbarInner}>
              {hasClassViewEnabled && isClassView && (
                <ToolbarToggleView
                  activeView={activeStudentView}
                  onViewToggle={handleViewToggle}
                />
              )}
              <ToolbarFreezeTabs />
              <ToolbarPause />
              <ToolbarGroups
                dataTestIdPrefix={dataTestIdPrefix}
                pageType={PAGE_TYPES.HIGHLIGHTS}
                hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
              />
              <ToolbarSort
                dataTestIdPrefix={dataTestIdPrefix}
                dropDownList={STUDENT_ORDER_DROPDOWN_ITEMS_FULL}
                hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
              />
              {windowSize >= WINDOW_SIZE_TYPES.S && (
                <ToolbarTilesSize
                  dataTestIdPrefix={dataTestIdPrefix}
                  hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
                />
              )}
              <ToolbarRefresh dataTestIdPrefix={dataTestIdPrefix} />
            </div>
          ) : null
        }
      />
    </>
  );
};

HLSubMenu.propTypes = {
  isSharingViewEnabled: PropTypes.bool,
  selectedClassId: PropTypes.string,
  isScreenshotsEnabled: PropTypes.bool,
  locationPath: PropTypes.string.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  isSharingViewEnabled: isSharingViewEnabled(state),
  selectedClassId: getClassId(state),
  isScreenshotsEnabled: getScreenshotsEnabled(state),
  locationPath: getLocationPath(state),
  windowSize: getWindowSize(state),
}))(HLSubMenu);
