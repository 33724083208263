export class CustomizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "CustomizedError";
  }
}

export const WORKSPACE_ERROR_TYPES = {
  GENERIC: "generic",
  CARD_NOT_VALID: "card not valid",
  ARTEFACT_NOT_VALID: "artefact not valid",
  NOT_WS_USER: "not ws user",
  ACTIVITY_DONE: "no students in this activity",
};
