import React from "react";
import PropTypes from "prop-types";
import styles from "./StudentTileOfflineState.module.scss";
import { FormattedMessage } from "react-intl";

const dataTestPrefix = "hl-StudentTile-UserIsOffline-Modal";

const StudentTileOfflineState = ({
  studentId,
  onSendEvent,
  setIsModalOpen,
}) => {
  return (
    <div className={styles.root}>
      <button
        type="button"
        data-test-id={`${dataTestPrefix}-Trigger`}
        className={styles.triggerButton}
        onClick={() => {
          setIsModalOpen(true);
          onSendEvent({ name: "studentOfflineHelp", channel: studentId });
        }}
      >
        <div className={styles.state}>
          <FormattedMessage defaultMessage="Offline" id="7UOvbT" />
        </div>
        <div className={styles.help}>
          <div>
            <span>
              <FormattedMessage defaultMessage="Need Help?" id="82zaoq" />
            </span>
          </div>
          <div className={styles.helpOnHover}>
            <FormattedMessage
              defaultMessage="Learn why students appear offline"
              id="dQEHiQ"
            />
          </div>
        </div>
      </button>
    </div>
  );
};

StudentTileOfflineState.propTypes = {
  studentId: PropTypes.string.isRequired,
  onSendEvent: PropTypes.func.isRequired,
};

export default StudentTileOfflineState;
