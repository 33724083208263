import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";
import {
  getClasses,
  getIsClassesError,
  getIsClassesLoading,
} from "../../../../state/dashboard/manage-classes/selectors";
import styles from "./ClassesTiles.module.scss";
import _ from "lodash";
import {
  updateClassesOrder,
  updateClassStarStatus,
} from "../../../../state/dashboard/manage-classes/actions";
import ClassTile from "../ClassTile/ClassTile";
import { getSchoolPreferenses } from "../../../../state/dashboard/config/selectors";
import { LandingModules } from "../../../../types/dashboard";
import ClassesTileEmptyState from "./ClassesTilesEmptyState";
import { pendoTrack } from "../../../../utils";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";

const ClassesTiles = ({ allClasses }) => {
  const isClassesLoading = useSelector(getIsClassesLoading);
  const isClassesError = useSelector(getIsClassesError);
  const loadedClasses = useSelector(getClasses);
  const schoolPrefs = useSelector(getSchoolPreferenses);
  const dispatch = useDispatch();

  const sortedClasses = useMemo(() => {
    const cls = _.sortBy(
      loadedClasses,
      allClasses ? (c) => _.lowerCase(c.class_alias) : (c) => -c.order_priority
    );
    return cls;
  }, [loadedClasses, allClasses]);

  const [classList, setClassList] = useState(sortedClasses);

  useEffect(() => {
    setClassList(sortedClasses);
  }, [sortedClasses]);

  const updateOrderAction = useCallback(
    (updates) => {
      return dispatch(updateClassesOrder(updates));
    },
    [dispatch]
  );
  const updateClassStarStatusAction = useCallback(
    (classURN, isStarred) => {
      return dispatch(updateClassStarStatus(classURN, isStarred));
    },
    [dispatch]
  );

  if (isClassesError || isClassesLoading) {
    return (
      <PageLoading
        isError={isClassesError}
        errorMessageText="Sorry we are having trouble loading classes"
        dataTestIdPrefix="td-ManageClasses-Loading-Error"
        isTryAgainVisible={isClassesError}
      />
    );
  }
  const isDraggable = !allClasses;

  const handleLayoutChange = (newOrder) => {
    // calculate and set the new order of the classes
    const sortedByNewOrder = _.sortBy(sortedClasses, (s) =>
      _.findIndex(newOrder, (n) => n === s.id)
    );
    const oddOnesOut = {};
    sortedByNewOrder.forEach((s, i) => {
      sortedByNewOrder.forEach((s1, j) => {
        if (i > j && s.order_priority > s1.order_priority) {
          oddOnesOut[s.id] = (oddOnesOut[s.id] || []).concat([s1]);
          oddOnesOut[s1.id] = (oddOnesOut[s1.id] || []).concat([s]);
        }
      });
    });
    const isEmpty = () => {
      return (
        Object.values(oddOnesOut).reduce((count, v) => count + v.length, 0) ===
        0
      );
    };
    const theOddest = () => {
      return Object.keys(oddOnesOut).reduce(
        (maxId, id) =>
          !maxId || oddOnesOut[maxId].length < oddOnesOut[id].length
            ? id
            : maxId,
        null
      );
    };
    const updates = [];
    while (!isEmpty()) {
      const odd = theOddest();
      let index = _.findIndex(sortedByNewOrder, (n) => n.id === odd);
      let from = new Date().getTime() * 1000000;
      let to = 0;
      if (index > 0) {
        from = sortedByNewOrder[index - 1].order_priority;
      }
      if (index < sortedByNewOrder.length - 1) {
        to = sortedByNewOrder[index + 1].order_priority;
      }
      delete oddOnesOut[odd];
      Object.keys(oddOnesOut).forEach((id) => {
        _.remove(oddOnesOut[id], (s) => s.id === odd);
      });
      updates.push({ id: odd, priority: Math.floor((from + to) / 2) });
    }
    updateOrderAction(updates);
  };

  if (!classList || classList.length === 0) {
    return <ClassesTileEmptyState allClasses={allClasses} />;
  }

  const classGrid = classList.map((theClass) => {
    const clPref = schoolPrefs.filter(
      (sp) => sp.domain === theClass.domain && sp.school === theClass.school
    )[0];
    let landingModule = LandingModules.DASHBOARD;
    if (clPref) {
      landingModule = clPref.landingModule;
    }
    return (
      <div key={theClass.id} className={styles.sortableItem}>
        <ClassTile
          className={styles.tileItem}
          classInfo={theClass}
          draggableHandleClassName={isDraggable ? styles.draggableHandle : ""}
          onStar={async () =>
            updateClassStarStatusAction(theClass.id, !theClass.starred)
          }
          landingModule={landingModule}
        />
      </div>
    );
  });

  return (
    <div className={styles.root}>
      {isDraggable && (
        <ReactSortable
          list={classList}
          setList={setClassList}
          animation={100}
          className={styles.sortableContainer}
          ghostClass={styles.grayBackground}
          onEnd={(evt) => {
            handleLayoutChange(classList.map(({ id }) => id));
            const classDragged = classList[evt.newIndex];
            pendoTrack("Manage Classes drag and drop", {
              classId: _.get(classDragged, "id", "") || "",
              className: _.get(classDragged, "class_alias", "") || "",
            });
          }}
        >
          {classGrid}
        </ReactSortable>
      )}
      {!isDraggable && (
        <div className={styles.sortableContainer}>{classGrid}</div>
      )}
    </div>
  );
};

ClassesTiles.propTypes = {
  allClasses: PropTypes.bool.isRequired,
};

export default connect((state) => ({}))(ClassesTiles);
