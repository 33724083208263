import { FormattedMessage } from "react-intl";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIsSearchActive } from "../../../../../state/workspace/pl/search/selectors";
import {
  getCurrentPage,
  getFilterSortType,
  areThereItems,
} from "../../../../../state/workspace/pl/list/selectors";
import styles from "./PLWorkspacesList.module.scss";

import FiltersSort from "../../MyWorkspaces/FiltersSort/FiltersSort";

import PLWorkspacesListItems from "../PLWorkspacesListItems/PLWorkspacesListItems";
import PLWorkspacesSearchItems from "../PLWorkspacesSearchItems/PLWorkspacesSearchItems";

import { loadProfessionalLearningWorkspacesAction } from "../../../../../state/workspace/pl/list/actions";
import { MAIN_CONTENT_ID } from "../../../../../consts";

const PLWorkspacesList = ({
  areThereItems,
  isSearchActive,
  currentPage,
  sortType,
  loadPLWorkspaces,
}) => {
  useEffect(() => {
    loadPLWorkspaces({ page: currentPage, order: sortType });
  }, [loadPLWorkspaces, currentPage, sortType]);

  return (
    <div className={styles.root}>
      <main className={styles.content} id={MAIN_CONTENT_ID} tabIndex="-1">
        {!isSearchActive && (
          <React.Fragment>
            <div className={styles.placeholderForTitle}>
              <h1>
                <FormattedMessage defaultMessage="Workspaces" id="DVb13U" />
              </h1>
              {areThereItems && (
                <FiltersSort dataTestIdPrefix="ws-ProfessionalLearning-NonTeacher-SortFilter" />
              )}
            </div>

            <PLWorkspacesListItems />
          </React.Fragment>
        )}
        {isSearchActive && <PLWorkspacesSearchItems />}
      </main>
    </div>
  );
};

PLWorkspacesList.propTypes = {
  areThereItems: PropTypes.bool,
  isSearchActive: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  sortType: PropTypes.string.isRequired,
  loadPLWorkspaces: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    areThereItems: areThereItems(state),
    isSearchActive: getIsSearchActive(state),
    currentPage: getCurrentPage(state),
    sortType: getFilterSortType(state),
  }),
  (dispatch) => ({
    loadPLWorkspaces: (options) =>
      dispatch(loadProfessionalLearningWorkspacesAction(options)),
  })
)(PLWorkspacesList);
