type Weekday =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export const getScheduledDays = (
  daysArray: string[]
): Record<Weekday, boolean> =>
  Object.fromEntries(
    [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ].map((day) => [day, daysArray.includes(day.toLowerCase())])
  ) as Record<Weekday, boolean>;
