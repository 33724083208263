import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";
import PropTypes from "prop-types";
import styles from "./ClickToSharePage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { clickToShareAction } from "../../../../state/highlights/clickToShare/actions";
import {
  getDocUrl,
  getEmail,
  getClickToShareState,
} from "../../../../state/highlights/clickToShare/selectors";
import { MAIN_CONTENT_ID } from "../../../../consts";
import AppLoadingPage from "../../AppLoadingPage/AppLoadingPage";
import { getGoogleResourceId } from "@hapara/ui/src/utils/getGoogleResourceId";

const ClickToSharePage = ({
  encodedDocUrl,
  encodedEmail,
  clickToShareState,
  clickToShareAction,
}) => {
  const [isError, setIsError] = useState(false);
  const email = decodeURIComponent(encodedEmail);
  const docUrl = decodeURIComponent(encodedDocUrl);

  // send request to BE for a sharing doc => if doc shared sucessfully, redirect user to the doc url
  useEffect(() => {
    if (
      !clickToShareState.isLoading &&
      !clickToShareState.isError &&
      !clickToShareState.isShared &&
      !_.isEmpty(docUrl) &&
      !_.isEmpty(email)
    ) {
      const docId = getGoogleResourceId(docUrl);
      if (docId === "") {
        setIsError(true);
      } else {
        clickToShareAction(docId, email);
      }
    }

    // if some params are missing or there was a problem with sharing - redirect anyway
    if (clickToShareState.isError || _.isEmpty(docUrl) || _.isEmpty(email)) {
      window.location.href = docUrl;
    }

    if (clickToShareState.isShared) {
      window.location.href = docUrl;
    }
  }, [clickToShareState, docUrl, email, clickToShareAction]);

  return (
    <>
      <Helmet>
        <title>{APP_PAGES.CLICK_TO_SHARE.title}</title>
      </Helmet>

      <main
        className={styles.root}
        data-test-id="Hs-Pages-ClickToSharePage"
        id={MAIN_CONTENT_ID}
        tabIndex="-1"
      >
        <AppLoadingPage isError={isError} />
      </main>
    </>
  );
};

ClickToSharePage.propTypes = {
  encodedDocUrl: PropTypes.string.isRequired,
  encodedEmail: PropTypes.string.isRequired,
  clickToShareState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    isShared: PropTypes.bool.isRequired,
  }),
  clickToShareAction: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    encodedDocUrl: getDocUrl(state),
    encodedEmail: getEmail(state),
    clickToShareState: getClickToShareState(state),
  }),
  (dispatch) => ({
    clickToShareAction: (docId, email) =>
      dispatch(clickToShareAction({ docId, email })),
  })
)(ClickToSharePage);
