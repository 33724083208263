import PropTypes from "prop-types";

export const RESOURCE_TILE_ID = "lb-resource-tile-id-";

export const resourceItemType = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Description: PropTypes.string,
  Link: PropTypes.string,
  Thumbnail: PropTypes.string,
  Statuses: PropTypes.shape({
    IsAddedToLibrary: PropTypes.bool,
    IsStarred: PropTypes.bool,
    IsUploaded: PropTypes.bool,
  }),
  Author: PropTypes.string,
  Authorities: PropTypes.arrayOf(PropTypes.string),
  Grades: PropTypes.arrayOf(PropTypes.string),
  Subjects: PropTypes.arrayOf(PropTypes.string),
  ResourceTypes: PropTypes.arrayOf(PropTypes.string),
  LicenceType: PropTypes.string,
  EditedOn: PropTypes.string,
});

export const RESOURCE_FORMAT = {
  ALL: "",
  WORKSPACE: "workspace",
  RESOURCE: "doc",
};

export const TILE_ICONS = {
  WORKSPACE: "workspace-icon",
  RESOURCE: "book-open",
};

export const dropdownItemPropsType = PropTypes.shape({
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
});

export const RESOURCE_DETAILS_MODAL_MODE = {
  READ: "read",
  EDIT: "edit",
};

export const RESOURCE_THUMBNAIL_SIZE = 86;
export const RESOURCE_DETAILS_THUMBNAIL_SIZE = 232;
