import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEvent,
} from "react";
import _ from "lodash";
import classnames from "classnames";
import styles from "./CardEditDropMenu.module.scss";
import { UpdateCardDocument } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import { useMutation } from "@apollo/client";
import { cardColourPalette } from "@hapara/assets/src/colours/colourCards";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

interface CardEditDropMenuTypes {
  cardID: string;
  cardType: string;
  isLastCard: boolean;
  currentColour: string; //Functional Value
  originalColour: string; //Reference Value
  dataTestIdPrefix: string;
  changeColourAction: (colourCode: string) => void; //Used to change local state colour on Card.tsx
  setDeleteCardID: (cardId: string) => void;
}

export const CardEditDropMenu = ({
  cardID,
  cardType,
  isLastCard,
  currentColour,
  originalColour,
  changeColourAction,
  dataTestIdPrefix,
  setDeleteCardID,
}: CardEditDropMenuTypes) => {
  const [selectedColour, setSelectedColour] = useState<string>(currentColour);
  const [isKeyboardUser, setIsKeyboardUser] = useState<boolean>(false);
  const [isMenuLifted, setIsMenuLifted] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [updateCard] = useMutation(UpdateCardDocument);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const focusRef = useRef<HTMLButtonElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  //CONTENT: Button text
  const BUTTON_TEXT = {
    EDIT: "Edit",
    COPY: "Copy to Clipboard",
    DELETE: "Delete",
  };

  //MENU: Runs mutation if needed and manages UI elements to match results
  const handleClose = useCallback(() => {
    if (selectedColour !== originalColour) {
      updateCard({
        variables: { id: cardID, input: { Colour: selectedColour } },
      })
        .then(() => {
          setMenuOpen(false);
        })
        .catch(() => {
          setMenuOpen(false);
          setSelectedColour(originalColour);
          changeColourAction(originalColour);
        });
    } else {
      setMenuOpen(false);
      setSelectedColour(originalColour);
    }
  }, [cardID, selectedColour, originalColour, updateCard, changeColourAction]);

  //MENU: Close on outside click
  const handleOutsideClick = useCallback(
    (e) => {
      if (isMenuOpen) {
        if (rootRef.current && rootRef.current.contains(e.target)) {
          return;
        }
        handleClose();
      }
    },
    [isMenuOpen, handleClose]
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  // MENU: Handle keypress events
  const handleKeyPress = useCallback(
    (e) => {
      switch (e.keyCode) {
        case 27: //Escape => Close and refocus
          if (isMenuOpen) {
            e.stopPropagation();
            handleClose();
            !_.isEmpty(triggerRef) && triggerRef.current!.focus();
          }
          break;
        case 9: //Tab => Enables the tab based menu focus trap as soon as a user uses tab. Resets on mouse click.
          if (!isKeyboardUser) {
            setIsKeyboardUser(true);
          }
          break;
      }
    },
    [handleClose, isMenuOpen, isKeyboardUser]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, true); //Sets event listener to the Capture phase
    return () => {
      document.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [handleKeyPress]);

  //MENU INTERIM SOLUTION: To prevent edit menu from opening up under the section headings the menu z-index is lifted above the
  //section headings when it is opened. The function below reverts the z-index to below that of the section heading when the user scrolls
  //with the menu open. This does not currently affect the last (or only) card in a card stack. Phase 2 will increase the static card height
  //at which point we can reassess the solution.
  const handleScroll = (e: any) => {
    if (e.deltaY > 10) {
      setIsMenuLifted(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("wheel", handleScroll, true); //Sets event listener to the Capture phase
      return () => {
        document.removeEventListener("wheel", handleScroll, true);
      };
    }
  }, [isMenuOpen]);

  //FUNCTIONAL: Click Handlers
  const handleItemClick = (e: MouseEvent) => {
    e.stopPropagation();
    switch (e.currentTarget.id) {
      case "edit":
        break;
      case "copy":
        break;
      case "delete":
        handleClose();
        setDeleteCardID(cardID);
        break;
    }
  };

  const handleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsKeyboardUser(false);
    setIsMenuLifted(true);
    if (selectedColour !== originalColour) {
      handleClose();
    } else {
      setMenuOpen(!isMenuOpen);
    }
  };

  const handleColourClick = (e: MouseEvent) => {
    e.stopPropagation();
    setSelectedColour(e.currentTarget.id);
    changeColourAction(e.currentTarget.id); //Changes displayed colour on Card (non-persistant)
    setIsKeyboardUser(false);
  };

  const handleMissedClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); //Prevents opening parent modal with off target clicks
  };

  const handleBlur = (idx: number) => {
    //Keeps focus inside the menu when a keyboard user tabs off of the final colour in the colour palette (loops to the first menu item)
    if (isKeyboardUser) {
      if (idx === cardColourPalette.length - 1) {
        focusRef.current?.focus(); //Traps focus inside the dropdown for A11y purposes
      }
    }
  };

  return (
    <div className={styles.root} ref={rootRef}>
      {/* TRIGGER BUTTON */}
      <button
        className={classnames(styles.root, styles.CardEditDropdownButton)}
        onClick={handleMenu}
        aria-label="Card options"
        aria-expanded={isMenuOpen}
        ref={triggerRef}
        data-test-id={`${dataTestIdPrefix}-CardEditButton-${cardID}`}
      >
        <HapReactIcon
          svg="dropdown-button"
          height={10}
          width={10}
          className={styles.buttonSVG}
        />
      </button>

      {/* DROP MENU PANEL */}
      {isMenuOpen && (
        <div
          className={classnames(styles.dropMenuPanel, {
            [styles.openLeft]: cardType === "RUBRIC",
            [styles.lastCard]: isLastCard,
            [styles.isMenuLifted]: isMenuLifted,
          })}
          onClick={handleMissedClick}
          data-test-id={`${dataTestIdPrefix}-CardEditPanel-${cardID}`}
        >
          <ul role="menu" aria-label="">
            <li
              id="edit"
              onClick={handleItemClick}
              data-test-id={`${dataTestIdPrefix}-CardEditButton-${BUTTON_TEXT.EDIT}`}
            >
              <button
                ref={focusRef}
                className={styles.listItem}
                aria-label="Card Option menu, Edit"
                tabIndex={0}
              >
                {BUTTON_TEXT.EDIT}
              </button>
            </li>
            <li
              id="copy"
              onClick={handleItemClick}
              data-test-id={`${dataTestIdPrefix}-CardEditButton-${BUTTON_TEXT.COPY}`}
              tabIndex={1}
            >
              <button className={styles.listItem}>{BUTTON_TEXT.COPY}</button>
            </li>
            <li
              id="delete"
              onClick={handleItemClick}
              data-test-id={`${dataTestIdPrefix}-CardEditButton-${BUTTON_TEXT.DELETE}`}
              tabIndex={2}
            >
              <button
                className={classnames(styles.listItem, styles.deleteItem)}
              >
                {BUTTON_TEXT.DELETE}
              </button>
            </li>
          </ul>
          <ul
            role="menu"
            className={styles.colourPalette}
            data-test-id={`${dataTestIdPrefix}-CardEditPalette`}
          >
            {cardColourPalette.map((colour, idx) => {
              const isSelected = +selectedColour === colour.colourIndex; //Checks selected colour
              const placeCheck = idx === 0 ? "Change Card Colour menu, " : "";
              const compiledA11yText = `${placeCheck} ${
                colour.accessibleName
              } ${isSelected ? ", selected" : ""}`;

              return (
                <button
                  key={colour.colourIndex}
                  id={_.toString(colour.colourIndex)}
                  className={styles.colourSwatch}
                  style={{
                    background: colour.colourHex,
                    color: colour.textHex,
                  }}
                  onClick={handleColourClick}
                  aria-label={compiledA11yText}
                  data-test-id={`${dataTestIdPrefix}-CardEditSwatch-${colour.accessibleName}`}
                  onBlur={() => handleBlur(idx)}
                >
                  {isSelected && <HapReactIcon svg="check" width={15} />}
                </button>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CardEditDropMenu;
