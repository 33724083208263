import { Centrifuge } from "centrifuge";
import {
  serverEventListeners,
  subscriptionEventListeners,
} from "./utils/eventListeners";

type CentrifugePublishResult =
  | { ok: true; code?: never; message?: never; temporary?: never }
  | {
      ok: false;
      /**
       * | Error   | Description                              |
       * | :------ | :--------------------------------------- |
       * | `4000`  | Unexpected error                         |
       * | `4101`  | Participants not in the same class       |
       * | `4102`  | Outside school hours                     |
       * | `4103`  | Chat disabled for school                 |
       * | `4104`  | Recipient unavailable                    |
       * | `4105`  | Recipient offline                        |
       * | `4106`  | Outside allowed IP address range         |
       *
       * https://hapara.atlassian.net/wiki/x/IIBidw
       */
      code: number;
      message?: string;
      temporary?: boolean;
    };

const isProduction = (): boolean => {
  return (
    process.env.HAPARA_ENV === "production" ||
    process.env.REACT_APP_BUILD_ENV === "production"
  );
};

export class CentrifugeClient {
  private static instance: CentrifugeClient | null = null;
  public id: string | null;
  private centrifuge: Centrifuge;
  private authToken: string;
  private readonly websocketEndpoint: string;

  private constructor() {
    this.authToken = "";
    this.websocketEndpoint = isProduction()
      ? "wss://api.hapara.com/messaging/connection/websocket"
      : "wss://api-test.hapara.com/messaging/connection/websocket";

    this.centrifuge = new Centrifuge(this.websocketEndpoint, {
      getToken: async () => this.authToken,
    });
    this.id = null;
    serverEventListeners(this.centrifuge);
  }

  public static getInstance(): CentrifugeClient {
    if (!CentrifugeClient.instance) {
      CentrifugeClient.instance = new CentrifugeClient();
    }
    return CentrifugeClient.instance;
  }

  public setToken(token: string): void {
    this.authToken = token;
    this.centrifuge.setToken(token);
  }

  public async publish(
    channel: string,
    data: unknown
  ): Promise<CentrifugePublishResult> {
    try {
      await this.centrifuge.publish(channel, data);
      return { ok: true };
    } catch (error) {
      const {
        code = 4000,
        message = "Unknown error occurred.",
        temporary = false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } = (error as any) ?? {};
      return {
        ok: false,
        code,
        message,
        temporary,
      };
    }
  }

  public newSubscription(channel: string, options: { recoverable: boolean }) {
    const subscription = this.centrifuge.newSubscription(channel, options);
    subscriptionEventListeners(subscription);
    return subscription;
  }

  public isSubscribed(channel: string) {
    return this.centrifuge.subscriptions().hasOwnProperty(channel);
  }

  public isDisconnected() {
    return this.centrifuge.state === "disconnected";
  }

  public connect() {
    this.centrifuge.connect();
    this.centrifuge.on("connected", (connectedContext) => {
      this.id = connectedContext.client;
    });
  }

  public disconnect() {
    this.centrifuge.disconnect();
  }

  public on: typeof this.centrifuge.on = (event, listener) => {
    return this.centrifuge.on(event, listener);
  };

  public getSubscriptions() {
    return this.centrifuge.subscriptions();
  }

  /** Returns the other clients connected to the channel */
  public async getPresence(channel: string) {
    const channelPresence = await this.centrifuge.presence(channel);
    return Object.values(channelPresence.clients).filter(
      (client) => client.client !== this.id
    );
  }

  public resubscribe() {
    const subscriptions = Object.values(this.centrifuge.subscriptions());
    subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    requestAnimationFrame(() => {
      subscriptions.forEach((subscription) => {
        subscription.subscribe();
      });
    });
  }

  public _restart() {
    this.resubscribe();
    this.connect();
  }
}
