import React from "react";
import styles from "./GradingAssigneeInfoPanelLoading.module.scss";

export const GradingAssigneeInfoPanelLoading = () => {
  return (
    <aside className={styles.root}>
      <div className={styles.header}>
        <div className={styles.shortItem} />
        <div className={styles.longItem} />
      </div>
      <div className={styles.body} />
    </aside>
  );
};

export default GradingAssigneeInfoPanelLoading;
