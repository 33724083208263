export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = {
      type,
    };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

// to use in reducers
export const DATA_LOADING_STATE_INIT = {
  isLoading: false,
  isLoaded: false,
  isError: false,
};

export const DATA_LOADING_STATE_PENDING = {
  isLoading: true,
  isError: false,
};

export const DATA_LOADING_STATE_SUCCESS = {
  isLoading: false,
  isLoaded: true,
  isError: false,
};

export const DATA_LOADING_STATE_ERROR = {
  isLoading: false,
  isLoaded: true,
  isError: true,
};
