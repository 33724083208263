import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./SubMenu.module.scss";

const SubMenu = ({
  className,
  tabs,
  tabsLabel = "Inner pages",
  tabsClassName,
  toolbar,
  toolbarLabel = "Toolbar",
  toolbarClassName,
}) => {
  return (
    <div className={classnames(styles.root, className)}>
      {tabs && (
        <nav
          className={classnames(styles.subNav, tabsClassName)}
          aria-label={tabsLabel}
        >
          <div className={styles.subNavInner}>{tabs}</div>
        </nav>
      )}
      {toolbar && (
        <section
          className={classnames(styles.toolbar, toolbarClassName)}
          aria-label={toolbarLabel}
        >
          {toolbar}
        </section>
      )}
    </div>
  );
};

SubMenu.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.node,
  tabsLabel: PropTypes.string,
  tabsClassName: PropTypes.string,
  toolbar: PropTypes.node,
  toolbarLabel: PropTypes.string,
  toolbarClassName: PropTypes.string,
};

export default SubMenu;
