import React from "react";
import { ModalDeprecated } from "../../deprecated/ModalDeprecated";
import styles from "./DialogModal.module.scss";

type DialogModalProps = {
  dataTestIdPrefix?: string;
  isOpen: boolean;
  title?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onRequestClose(): void;
  className?: string;
};

export const DialogModal = ({
  dataTestIdPrefix,
  isOpen,
  title,
  children,
  actions,
  onRequestClose,
  className,
}: DialogModalProps) => (
  <ModalDeprecated
    className={className}
    title={title}
    dataTestPrefix={dataTestIdPrefix}
    isOpen={isOpen}
    onClose={onRequestClose}
  >
    <div className={styles.body}>
      {children}
      {actions ? <div className={styles.actions}>{actions}</div> : null}
    </div>
  </ModalDeprecated>
);
