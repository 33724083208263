import { AnyAction } from "redux";
import actionTypes from "../../actionTypes";
import { DashboardClassState } from "./types";
import {
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
  DATA_LOADING_STATE_ERROR,
} from "../../storeUtils";

const INIT_STATE: DashboardClassState = {
  ...DATA_LOADING_STATE_INIT,
  data: null,
};

const classDashboardReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_CLASS_DATA_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
      };
    }

    case actionTypes.DASHBOARD_CLASS_DATA_LOAD_SUCCESS: {
      return {
        ...state,
        ...DATA_LOADING_STATE_SUCCESS,
        data: action.payload,
      };
    }

    case actionTypes.DASHBOARD_CLASS_DATA_LOAD_ERROR: {
      return {
        ...state,
        ...DATA_LOADING_STATE_ERROR,
      };
    }

    case actionTypes.DASHBOARD_USER_GROUP_SELECTED: {
      const updatedUserGroups = state.data?.people?.usergroups?.map((group) => {
        const matchingGroup = action.payload.find(
          (updatedGroup) => updatedGroup.URN === group.URN
        );
        return matchingGroup ? matchingGroup : group;
      });

      return {
        ...state,
        data: {
          ...state.data,
          people: {
            ...state.data?.people,
            usergroups: updatedUserGroups,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default classDashboardReducer;
