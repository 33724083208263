import { dateTimeFormatOptions, DateTimeFormat } from "../formats";
import { useLocale } from "../../../providers/LocaleProvider";
import { isUnixTimestamp } from "../isUnixTimestamp";

export const formatDateTimeUtc = (
  utcValue: string,
  format: DateTimeFormat
): string | null => {
  const { locale } = useLocale();

  if (!utcValue.endsWith("Z") && !isUnixTimestamp(utcValue)) {
    return null;
  }

  const date = isUnixTimestamp(utcValue)
    ? new Date(parseInt(utcValue))
    : new Date(utcValue);
  if (isNaN(date.getTime())) {
    return null;
  }
  return new Intl.DateTimeFormat(locale, dateTimeFormatOptions[format]).format(
    date
  );
};
