import React, { useState, useEffect, useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import Tippy from "@tippy.js/react";
import styles from "./groupItem.module.scss";

import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "../../atomic/Button/Button";

/**
 *
 * @param data
 * @param onEditGroup
 * @param onGroupDelete
 * @param errorOccurred
 * @returns {*}
 * @constructor
 */

const GroupItem = ({ data, onEditGroup, onDeleteGroup, errorOccurred }) => {
  const [spinner, setSpinner] = useState(!!errorOccurred.id);
  const [deleting, setDeleting] = useState(!!errorOccurred.id);
  const [blockHeight, setBlockHeight] = useState(0);
  const blockClicked = useRef();
  const deleteConfirmationContainer = useRef();
  const deleteButton = useRef();
  const intl = useIntl();

  const itemCount = data?.emails ? data.emails.length : 0;

  /**
   * handling the deleting of the group and setting spinner
   * @param {Object} group
   */
  function handleDeleteGroup(group) {
    setSpinner(true);
    onDeleteGroup(group);
  }

  useEffect(() => {
    if (!errorOccurred.id) {
      setSpinner(false);
      setDeleting(false);
    }
  }, [errorOccurred]);

  return (
    <article className={styles.root}>
      {!deleting && (
        <div className={styles.main} ref={blockClicked}>
          <div className={classnames(styles.colorCode, [styles[data.color]])} />
          <div className={styles.header}>
            <div className={styles.amount}>
              <FormattedMessage
                defaultMessage="{count, plural, one {1 Student} other {# Students}}"
                id="OknPHq"
                values={{ count: itemCount }}
              />
            </div>
            <h2 className={styles.title}>{data.name}</h2>
          </div>
          <div className={styles.buttons}>
            <Tippy
              content={intl.formatMessage({
                defaultMessage: "Edit",
                id: "wEQDC6",
              })}
              maxWidth={150}
              theme="hsuite"
            >
              <Button
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.SMALL}
                icon="pencil"
                onAction={() => onEditGroup(data)}
                dataTestId="td-GroupsManager-GroupItem-ButtonEdit"
                className={styles.editButton}
                aria-label={intl.formatMessage({
                  defaultMessage: "Edit group",
                  id: "Z/1udv",
                })}
              />
            </Tippy>
            <Tippy
              content={intl.formatMessage({
                defaultMessage: "Delete",
                id: "K3r6DQ",
              })}
              maxWidth={150}
              theme="hsuite"
            >
              <Button
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.SMALL}
                icon="delete"
                onAction={() => {
                  setBlockHeight(blockClicked.current.clientHeight);
                  setDeleting(true);
                  _.delay(() => {
                    if (deleteConfirmationContainer.current) {
                      deleteConfirmationContainer.current.focus();
                    }
                  }, 200);
                }}
                dataTestId="td-GroupsManager-GroupItem-ButtonDelete"
                className={styles.deleteButton}
                aria-label={intl.formatMessage({
                  defaultMessage: "Delete group",
                  id: "BpuMwR",
                })}
                ref={deleteButton}
              />
            </Tippy>
          </div>
        </div>
      )}
      {deleting && (
        <div
          className={styles.deleteConfirmator}
          style={{ height: blockHeight }}
          tabIndex={-1}
          ref={deleteConfirmationContainer}
        >
          {spinner && <div className={styles.blocked} />}
          <div className={styles.label}>
            <FormattedMessage defaultMessage="Delete this group?" id="S0cLdM" />
          </div>
          <div className={styles.buttons}>
            <Button
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              size={BUTTON_SIZES.SMALL}
              label={intl.formatMessage({
                defaultMessage: "No",
                id: "oUWADl",
              })}
              onAction={() => {
                setDeleting(false);
                _.delay(() => {
                  if (deleteButton.current) {
                    deleteButton.current.focus();
                  }
                }, 200);
              }}
              dataTestId="td-GroupsManager-GroupItem-ButtonCancelDelete"
              tabIndex={0}
              className={styles.noButton}
            />
            <Button
              type={BUTTON_TYPES.DANGER}
              size={BUTTON_SIZES.SMALL}
              label={
                !spinner
                  ? intl.formatMessage({
                      defaultMessage: "Delete",
                      id: "K3r6DQ",
                    })
                  : ""
              }
              isLoading={spinner}
              onAction={() => handleDeleteGroup(data)}
              dataTestId="td-GroupsManager-GroupItem-ButtonConfirmDelete"
              className={styles.cofirmDeleteButton}
            />
          </div>
        </div>
      )}
    </article>
  );
};

GroupItem.propTypes = {
  data: PropTypes.object,
  onDeleteGroup: PropTypes.func,
  errorOccurred: PropTypes.object,
  onEditGroup: PropTypes.func,
};

export default GroupItem;
