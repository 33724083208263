import React, { useEffect } from "react";
import styles from "./LibraryPage.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../partial/Header/Header";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";
import Page from "../../../partial/PageContainer/PageContainer";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import PageLeftPanel from "../../../partial/PageLeftPanel/PageLeftPanel";
import { APP_PAGES } from "../../../../state/router/types";
import {
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/library/config/selectors";
import { hasWSAccess } from "../../../../state/user/selectors";
import LocationChangeHandler from "./LocationChangeHandler";
import {
  getLibraryLeftPanelIsOpen,
  getLibraryItemsTotal,
} from "../../../../state/library/library/selectors";
import {
  toggleLibraryLeftPanel,
  resetLibraryItems,
} from "../../../../state/library/library/actions";
import LibrarySideNav from "../../../partial/Library/LibrarySideNav/LibrarySideNav";
import ModalsAndPanels from "../../../partial/Resources/ModalsAndPanels/ModalsAndPanels";
import FiltersLeftPanel from "../../../partial/Resources/FiltersLeftPanel/FiltersLeftPanel";
import LibraryMainContent from "../../../partial/Library/LibraryMainContent/LibraryMainContent";
import ConfigHandler from "../ConfigHandler";
import { getSelectedCollection } from "../../../../state/library/collections/selectors";
import { collectionItemType } from "../../../../state/library/collections/types";
import { getSearchQuery } from "../../../../state/shared/selectors";

export const LibraryPage = ({
  hasWSAccess,
  isLeftPanelOpen,
  toggleLeftPanel,
  resetLibraryItems,
  isConfigLoaded,
  isConfigError,
  selectedCollection,
  searchQuery,
  title,
  searchResultsTotal,
}) => {
  useEffect(() => {
    return () => {
      //clear data on unmount
      resetLibraryItems();
    };
  }, [resetLibraryItems]);

  const dataTestIdPrefix = "lb-Library";

  const headerToShow = hasWSAccess ? (
    <Header />
  ) : (
    <HeaderAccountOnly rootPageLink={APP_PAGES.MY_RESOURCES_STARRED.path} />
  );

  const pageTitle =
    selectedCollection && title !== APP_PAGES.LIBRARY_SEARCH.title
      ? `${selectedCollection.name} (${searchResultsTotal}) - ${title}`
      : searchQuery
      ? APP_PAGES.LIBRARY_SEARCH.title
      : title;

  return (
    <>
      <ConfigHandler />
      <Page
        header={headerToShow}
        isPageLoadingError={isConfigError}
        isPageLoaded={isConfigLoaded}
        title={pageTitle}
      >
        <LocationChangeHandler />
        <ModalsAndPanels />
        <FiltersLeftPanel />

        <div className={styles.root} data-test-id="lb-Pages-LibraryPage">
          <div>
            <LibrarySubMenu />
          </div>
          <div className={styles.content}>
            <PageLeftPanel
              isOpen={isLeftPanelOpen}
              focusableElementId={null}
              dataTestIdPrefix={dataTestIdPrefix}
              landmarkLabel="Library filters"
              closeButtonLabel="Close my library filters panel"
              onClose={() => toggleLeftPanel(false)}
            >
              <LibrarySideNav />
            </PageLeftPanel>
            <LibraryMainContent />
          </div>
        </div>
      </Page>
    </>
  );
};

LibraryPage.propTypes = {
  hasWSAccess: PropTypes.bool.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  resetLibraryItems: PropTypes.func.isRequired,
  selectedCollection: collectionItemType,
  searchQuery: PropTypes.string,
  searchResultsTotal: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: getLibraryLeftPanelIsOpen(state),
    hasWSAccess: hasWSAccess(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    selectedCollection: getSelectedCollection(state),
    searchQuery: getSearchQuery(state),
    searchResultsTotal: getLibraryItemsTotal(state),
  }),
  (dispatch) => ({
    resetLibraryItems: () => dispatch(resetLibraryItems()),
    toggleLeftPanel: (isOpen) => dispatch(toggleLibraryLeftPanel(isOpen)),
  })
)(LibraryPage);
