import { FormattedMessage } from "react-intl";
import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./WorkspaceTileLabels.module.scss";
import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import {
  createAndAssignLabelToWs,
  assignAndUnassignLabelToWS,
  loadLabelsAction,
} from "../../../../../state/workspace/myworkspaces/labels/actions";
import { getLabels } from "../../../../../state/workspace/myworkspaces/labels/selectors";
import { applyAllFilters } from "../../../../../state/workspace/myworkspaces/list/actions";
import { showAppError, hideAppError } from "../../../../../state/app/actions";
import {
  LABEL_ALLOWED_LENGTH,
  labelItemType,
} from "../../../../../state/workspace/myworkspaces/labels/types";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import Pill, {
  PILL_TYPES,
  PILL_COLOURS,
} from "@hapara/ui/src/atomic/Pill/Pill";

export const WorkspaceTileLabels = ({
  item,
  allLabels,
  createAndAssignLabelToWs,
  assignAndUnassignLabelToWS,
  loadLabels,
  loadWorkspaces,
  showAppError,
  hideAppError,
}) => {
  // ref for Dropdown container
  const dropdownNode = useRef();

  // controls dropdown open/close state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // controls show/hide of backend call error
  const [isUpdateError, setIsUpdateError] = useState(false);

  // controls show/hide of action in progress state
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  // WS item ID
  const itemId = _.get(item, "Id", "");

  // Dropdown ID
  const dropdownId = `ws-labels-dropdown-${itemId}`;

  // Labels which are assigned to WS item at the moment
  const itemLabels = _.get(item, "BoardLabels", []);
  const isItemLabels = itemLabels.length > 0;
  const itemLabelsIds = useMemo(
    () => _.map(itemLabels, (label) => label.Id),
    [itemLabels]
  );

  // value a user entered into the text field
  const [inputValue, setInputValue] = useState("");
  const isInputValueMatchesAnExistingLabel = useMemo(
    () =>
      _.findIndex(
        allLabels,
        (item) =>
          _.toLower(item.Name) === _.toLower(inputValue) && !item.deleted
      ) !== -1,
    [allLabels, inputValue]
  );

  // Labels and their state (selected/unselected) which user interact with
  const getDropdownLabelsInitState = useCallback(() => {
    const mappedList = _.map(allLabels, (label) => {
      const { Id, Name, deleted } = label;
      const isSelected = _.includes(itemLabelsIds, Id);
      const lowcasedName = _.toLower(Name);
      return {
        Id,
        Name,
        isSelected,
        sortOrder: isSelected ? "1_" + lowcasedName : "2_" + lowcasedName,
        deleted,
      };
    });

    return _.sortBy(mappedList, ["sortOrder"]);
  }, [allLabels, itemLabelsIds]);

  const [dropdownLabels, setDropdownLabels] = useState(
    getDropdownLabelsInitState()
  );
  const selectedDropdownLabelIds = useMemo(
    () =>
      _.map(
        _.filter(dropdownLabels, (label) => label.isSelected),
        (label) => label.Id
      ),
    [dropdownLabels]
  );
  const unselectedDropdownLabelIds = useMemo(
    () =>
      _.map(
        _.filter(dropdownLabels, (label) => !label.isSelected),
        (label) => label.Id
      ),
    [dropdownLabels]
  );

  // Labels which are shown to the user
  const filteredDropdownLabels = useMemo(
    () =>
      _.filter(
        dropdownLabels,
        (label) =>
          _.includes(_.toLower(label.Name), _.toLower(inputValue)) &&
          !label.deleted
      ),
    [dropdownLabels, inputValue]
  );

  // determines if user has tempered with states of shown labels
  const hasUserChangedLabelList = useMemo(
    () => _.xor(itemLabelsIds, selectedDropdownLabelIds).length > 0,
    [itemLabelsIds, selectedDropdownLabelIds]
  );

  const createButtonLabel = !inputValue
    ? "Create"
    : _.truncate(`Create "${inputValue}"`, { omission: "...", length: 14 });

  const isCreateButtonVisible = () =>
    !allLabels.length || (inputValue && !hasUserChangedLabelList);

  const isCreateButtonDisabled = () =>
    !inputValue ||
    inputValue.length > LABEL_ALLOWED_LENGTH ||
    isInputValueMatchesAnExistingLabel;

  const isApplyButtonDisabled = () => !hasUserChangedLabelList;

  const handleDropdownOpen = useCallback(() => {
    setIsDropdownOpen(true);
    setDropdownLabels(getDropdownLabelsInitState());
  }, [setIsDropdownOpen, setDropdownLabels, getDropdownLabelsInitState]);

  const handleDropdownClose = useCallback(() => {
    setIsDropdownOpen(false);
    // do a clean up
    setInputValue("");
    setIsUpdateError(false);
    setDropdownLabels(getDropdownLabelsInitState());
  }, [
    setIsDropdownOpen,
    setInputValue,
    setIsUpdateError,
    setDropdownLabels,
    getDropdownLabelsInitState,
  ]);

  const handleGenericClick = useCallback(
    (e) => {
      if (dropdownNode.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      handleDropdownClose();
    },
    [handleDropdownClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleGenericClick);

    return () => {
      document.removeEventListener("mousedown", handleGenericClick);
    };
  }, []); //eslint-disable-line

  const handleInputValueChange = (e) => {
    setInputValue(_.get(e, "target.value", ""));
  };

  const handleLabelCheckboxChange = (id, isChecked) => {
    setDropdownLabels(
      _.map(dropdownLabels, (label) => {
        if (label.Id === id)
          return {
            ...label,
            isSelected: isChecked,
          };
        return label;
      })
    );
  };

  const handleLabelCreate = () => {
    setIsUpdateError(false);
    setIsUpdateInProgress(true);

    createAndAssignLabelToWs({
      labelName: inputValue,
      boardId: itemId,
    })
      .then(() => {
        handleDropdownClose();
        setIsUpdateInProgress(false);
        loadLabels();
        loadWorkspaces();
      })
      .catch(() => {
        setIsUpdateError(true);
        setIsUpdateInProgress(false);
      });
  };

  const handleLabelApply = () => {
    setIsUpdateError(false);
    setIsUpdateInProgress(true);

    assignAndUnassignLabelToWS({
      boardId: itemId,
      assignList: selectedDropdownLabelIds,
      unassignList: unselectedDropdownLabelIds,
    })
      .then(() => {
        handleDropdownClose();
        setIsUpdateInProgress(false);
        loadLabels();
        loadWorkspaces();
      })
      .catch(() => {
        setIsUpdateError(true);
        setIsUpdateInProgress(false);
      });
  };

  const handleSingleLabelUnassign = (labelId) => {
    hideAppError();
    assignAndUnassignLabelToWS({
      boardId: itemId,
      assignList: [],
      unassignList: [labelId],
    })
      .then(() => {
        loadWorkspaces();
      })
      .catch((error) => {
        showAppError(error);
      });
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      // do an action on the enter
      if (isCreateButtonVisible() && !isCreateButtonDisabled()) {
        handleLabelCreate();
      }
      if (!isCreateButtonVisible() && !isApplyButtonDisabled()) {
        handleLabelApply();
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.dropdownContainer} ref={dropdownNode}>
        <Button
          label="Add label"
          type={BUTTON_TYPES.OUTLINED}
          size={BUTTON_SIZES.SMALL}
          onAction={() =>
            isDropdownOpen ? handleDropdownClose() : handleDropdownOpen()
          }
          dataTestId="Ws-WorkspacesListItemManageLabels-Button-Open"
          className={classnames(styles.trigger, {
            [styles.triggerActive]: isDropdownOpen,
          })}
          aria-expanded={isDropdownOpen}
          aria-controls={dropdownId}
        />
        {isDropdownOpen && (
          <div className={styles.content} id={dropdownId}>
            <div className={styles.contentFilter}>
              <input
                type="text"
                placeholder="Enter a name"
                aria-label="Enter a name"
                className={styles.field}
                value={inputValue ? inputValue : ""}
                onChange={handleInputValueChange}
                onKeyDown={handleInputKeyDown}
                maxLength={LABEL_ALLOWED_LENGTH}
                data-test-id="Ws-WorkspacesListItemManageLabels-Input-EnterName"
              />
              {inputValue.length === LABEL_ALLOWED_LENGTH && (
                <div className={styles.fieldHelp}>
                  <FormattedMessage
                    defaultMessage="30 character limit reached"
                    id="ws8eBJ"
                  />
                </div>
              )}
              {isInputValueMatchesAnExistingLabel && (
                <div className={styles.sameLabelExists} aria-live="polite">
                  <FormattedMessage
                    defaultMessage="Error: This name already exists"
                    id="tOSgd3"
                  />
                </div>
              )}
            </div>
            {filteredDropdownLabels.length > 0 && (
              <div className={styles.contentList}>
                {_.map(filteredDropdownLabels, (label) => (
                  <div
                    key={label.Id}
                    className={classnames(styles.contentListItem)}
                  >
                    <Checkbox
                      checked={label.isSelected}
                      id={`ws-labels-dropdown-label-item-${label.Id}`}
                      dataTestIdPrefix="Ws-WorkspacesListItemManageLabels-CheckboxItem"
                      onChange={(isChecked) =>
                        handleLabelCheckboxChange(label.Id, isChecked)
                      }
                      label={label.Name}
                      className={classnames(styles.contentListItemLabel, {
                        [styles.highlighted]: _.includes(
                          itemLabelsIds,
                          label.Id
                        ),
                      })}
                    />
                  </div>
                ))}
              </div>
            )}

            <div className={styles.contentActions}>
              {isUpdateError && (
                <div className={styles.actionError}>
                  <FormattedMessage
                    defaultMessage="Sorry, there was a problem updating. Please try again."
                    id="W4HePf"
                  />
                </div>
              )}
              <div className={styles.buttons}>
                <Button
                  label="Cancel"
                  type={BUTTON_TYPES.SECONDARY}
                  onAction={handleDropdownClose}
                  dataTestId="Ws-WorkspacesListItemManageLabels-Button-Cancel"
                />
                {isCreateButtonVisible() && (
                  <Button
                    label={createButtonLabel}
                    type={BUTTON_TYPES.PRIMARY}
                    isLoading={isUpdateInProgress}
                    isDisabled={isCreateButtonDisabled()}
                    onAction={handleLabelCreate}
                    dataTestId="Ws-WorkspacesListItemManageLabels-Button-Create"
                  />
                )}
                {!isCreateButtonVisible() && (
                  <Button
                    label="Apply"
                    type={BUTTON_TYPES.PRIMARY}
                    isLoading={isUpdateInProgress}
                    isDisabled={isApplyButtonDisabled()}
                    onAction={handleLabelApply}
                    dataTestId="Ws-WorkspacesListItemManageLabels-Button-Apply"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isItemLabels && (
        <div className={styles.labelsContainer}>
          <div className={styles.labelsInner}>
            {_.map(itemLabels, (label) => (
              <div
                key={label.Id}
                className={styles.labelsItem}
                data-test-id="Ws-WsTile-LabelItem"
              >
                <Pill
                  type={PILL_TYPES.SQUARE}
                  colour={PILL_COLOURS.PALE_GRAY}
                  hasAction={true}
                  onClick={() => handleSingleLabelUnassign(label.Id)}
                  actionIcon="cross"
                  actionLabel={`Remove ${label.Name} label`}
                  dataTestIdPrefix="Ws-WsTile-LabelItemInner"
                  className={styles.labelsItemTagRoot}
                  classNameBody={styles.labelsItemTag}
                >
                  {label.Name}
                </Pill>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

WorkspaceTileLabels.propTypes = {
  item: wsItemType.isRequired,
  allLabels: PropTypes.arrayOf(labelItemType),
  createAndAssignLabelToWs: PropTypes.func.isRequired,
  assignAndUnassignLabelToWS: PropTypes.func.isRequired,
  loadLabels: PropTypes.func.isRequired,
  loadWorkspaces: PropTypes.func.isRequired,
  showAppError: PropTypes.func.isRequired,
  hideAppError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    allLabels: getLabels(state),
  }),
  (dispatch) => ({
    createAndAssignLabelToWs: (options) =>
      dispatch(createAndAssignLabelToWs(options)),
    assignAndUnassignLabelToWS: (options) =>
      dispatch(assignAndUnassignLabelToWS(options)),
    loadLabels: () => dispatch(loadLabelsAction()),
    loadWorkspaces: () => dispatch(applyAllFilters(true)),
    showAppError: (error) => dispatch(showAppError(error)),
    hideAppError: () => dispatch(hideAppError()),
  })
)(WorkspaceTileLabels);
