import { FormattedMessage } from "react-intl";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  MYWORKSPACES_CATEGORIES,
  MYWORKSPACES_FILTER_STATUS_TYPES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_URL_KEYS } from "../../../../../state/workspace/myworkspaces/list/navigation";
import styles from "./WorkspacesListEmptyStateWithLabelSelected.module.scss";
import { getFilterLabelId } from "../../../../../state/workspace/myworkspaces/list/selectors";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../../../state/router/types";

const WorkspacesListEmptyStateWithLabelSelected = ({ selectedLabelId }) => {
  return (
    <div
      className={styles.root}
      data-test-id="ws-MyWorkspacesLabels-EmptyState"
    >
      <div className={styles.header}>
        <FormattedMessage defaultMessage="There's nothing here!" id="7kWlAm" />
      </div>
      <div className={styles.body}>
        Either this label is not attached to any Workspaces, or only attached to{" "}
        <Link
          to={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.ARCHIVED.urlKey}?${MYWORKSPACES_URL_KEYS.status.key}=${MYWORKSPACES_FILTER_STATUS_TYPES.ARCHIVED}&${MYWORKSPACES_URL_KEYS.label.key}=${selectedLabelId}`}
          data-test-id="ws-MyWorkspacesLabels-Button-GoToArchived"
          className={styles.link}
        >
          <FormattedMessage defaultMessage="archived Workspaces" id="at2DBn" />
        </Link>
      </div>
    </div>
  );
};

WorkspacesListEmptyStateWithLabelSelected.propTypes = {
  selectedLabelId: PropTypes.string.isRequired,
};

export default connect((state) => ({
  selectedLabelId: getFilterLabelId(state),
}))(WorkspacesListEmptyStateWithLabelSelected);
