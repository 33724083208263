import { hideAppError } from "../../app/actions";
import { fetchLibraryConfig } from "../../../apiCalls/library";
import actionTypes from "../../actionTypes";

export const loadConfig = () => (dispatch) => {
  dispatch({
    type: actionTypes.LIBRARY_CONFIG_LOAD_PENDING,
  });
  dispatch(hideAppError());

  fetchLibraryConfig()
    .then((response) => response.data)
    .then((data) => {
      dispatch({
        type: actionTypes.LIBRARY_CONFIG_LOAD_SUCCESS,
        payload: data,
      });

      return data;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIBRARY_CONFIG_LOAD_ERROR,
      });
    });
};
