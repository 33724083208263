import actionTypes from "../actionTypes";

export const INIT_STATE = {
  items: [],
};

const navigationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.NAVIGATION_UPDATE_ITEMS: {
      return {
        ...state,
        items: action.payload,
      };
    }

    default:
      return state;
  }
};

export default navigationReducer;
