import { makeActionCreator } from "../../storeUtils";
import {
  postSendMessageOrShareLinks,
  updateScheduledSession,
  postNewScheduledSession,
} from "../../../apiCalls/highlights";
import { reloadScheduledSessionAfterAddingNew } from "../sessions/actions";
import actionTypes from "../../actionTypes";
import { getStudentEmailsByIds } from "../studentConfigs/selectors";

export const showShareLinksModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_SHOW,
  "payload"
);

export const hideShareLinksModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_HIDE
);

export const shareLinksModalError = makeActionCreator(
  actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_ERROR
);

export const shareLinksModalSuccess = makeActionCreator(
  actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_SUCCESS
);

export const shareLinksAction =
  ({
    links,
    recipientType,
    students,
    classId,
    scheduledTime,
    isScheduled,
    groups,
    sessionName,
    sessionId,
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    const studentEmails = getStudentEmailsByIds(students)(state);
    const messageBody = {
      Links: links,
      Type: "sharelinks",
      RecipientType: recipientType,
      ClassURN: classId,
      Students: students,
      StudentEmails: studentEmails,
      Groups: groups,
    };
    if (isScheduled && scheduledTime) {
      messageBody.ScheduledTime = scheduledTime;
      messageBody.IsScheduled = isScheduled;
    }

    if (sessionName) {
      messageBody.SessionName = sessionName;
    }

    try {
      let resp;
      if (sessionId && sessionId !== "") {
        // update existing session
        messageBody.ID = sessionId;
        resp = await updateScheduledSession({
          classId,
          sessionDetails: messageBody,
        });
      } else {
        // create a new session
        if (isScheduled) {
          resp = await postNewScheduledSession({
            isSession: false,
            messageBody,
          });
        } else {
          resp = await postSendMessageOrShareLinks({ messageBody });
        }
      }
      if (resp.status !== 200) {
        dispatch(shareLinksModalError());
      } else {
        dispatch(shareLinksModalSuccess());
        dispatch(reloadScheduledSessionAfterAddingNew());
      }
      return true;
    } catch (error) {
      console.log(error);
      dispatch(shareLinksModalError());
      return true;
    }
  };
