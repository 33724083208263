import React from "react";

export const ChatButtonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none">
      <path fill="currentColor" d="M25 50a25 25 0 1 0 0-50 25 25 0 0 0 0 50Z" />
      <path
        fill="#fff"
        d="M25 6A19 19 0 0 0 6 24.8a19 19 0 0 0 19 18.9 19 19 0 0 0 19-18.9A19 19 0 0 0 25 6ZM4 24.8A21 21 0 0 1 25 4a21 21 0 0 1 21 20.8 21 21 0 0 1-21 20.9A21 21 0 0 1 4 24.8Z"
      />
      <path
        fill="#fff"
        d="M25 13a12 12 0 0 0-10.4 18L13 36a.9.9 0 0 0 1 1l5.6-1.2A12 12 0 1 0 25 13Zm-3.9 13.3a1.3 1.3 0 1 1 0-2.6 1.3 1.3 0 0 1 0 2.6Zm4.3 0a1.3 1.3 0 1 1 0-2.6 1.3 1.3 0 0 1 0 2.6Zm4.3 0a1.3 1.3 0 1 1 0-2.6 1.3 1.3 0 0 1 0 2.6Z"
      />
    </svg>
  );
};
