import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import ModalDeprecated from "../../deprecated/ModalDeprecated/ModalDeprecated";
import Button, { BUTTON_TYPES } from "../Button/Button";
import styles from "./ActionDialog.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FocusContext } from "../utils";

const ActionDialog = ({
  title,
  subTitle,
  actionLabel,
  actionInProgressLabel = "",
  errorMessage,
  cancelLabel,
  children,
  onAction,
  isOpen = false,
  onClose = () => {},
  isActionDanger = false,
  dataTestIdPrefix,
  content,
  isValidationError = false,
  className,
  classNameContent,
  isBorderHidden = false,
}) => {
  const intl = useIntl();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [actionError, setActionError] = useState(false);
  const focusContext = useContext(FocusContext);

  const defaultCancelLabel = intl.formatMessage({
    defaultMessage: "Cancel",
    id: "47FYwb",
  });

  const doClose = () => {
    if (focusContext.focusRef && focusContext.focusRef.current) {
      focusContext.focusRef.current.focus();
    }
    onClose();
  };
  const action = async (e) => {
    e.preventDefault();
    if (!actionInProgress) {
      setActionInProgress(true);
      try {
        await onAction();
        setActionInProgress(false);
        doClose();
      } catch (error) {
        setActionInProgress(false);
        setActionError(true);
      }
    }
  };
  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={doClose}
      title={title}
      dataTestPrefix={dataTestIdPrefix}
      classNameHeader={styles.title}
      className={classnames(styles.root, className)}
      classNameContent={classNameContent}
    >
      <React.Fragment>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        <form
          className={styles.dialog}
          aria-labelledby={`${dataTestIdPrefix}-Modal-Header`}
          onSubmit={action}
        >
          <div
            className={classnames(styles.dialogContent, {
              [styles.noborder]: isBorderHidden,
            })}
          >
            {content && <p className={styles.content}>{content}</p>}
            {children && <div className={styles.content}>{children}</div>}
          </div>
          <div className={styles.buttonsContent}>
            {actionError && (
              <div className={styles.error}>
                <div className={styles.text}>{errorMessage}</div>
              </div>
            )}
            <div className={styles.buttons}>
              <Button
                label={cancelLabel ?? defaultCancelLabel}
                type={BUTTON_TYPES.SECONDARY}
                onClick={actionInProgress ? () => {} : doClose}
                isDisabled={actionInProgress}
                dataTestId={`${dataTestIdPrefix}-Modal-Cancel`}
              />
              <Button
                label={
                  actionInProgress && actionInProgressLabel
                    ? actionInProgressLabel
                    : actionLabel
                }
                type={
                  isActionDanger ? BUTTON_TYPES.DANGER : BUTTON_TYPES.PRIMARY
                }
                onAction={action}
                isLoading={actionInProgress}
                dataTestId={`${dataTestIdPrefix}-Modal-Submit`}
                isDisabled={actionInProgress || isValidationError}
              />
            </div>
          </div>
        </form>
      </React.Fragment>
    </ModalDeprecated>
  );
};

ActionDialog.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  cancelLabel: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  actionInProgressLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  isActionDanger: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  isValidationError: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  content: PropTypes.string,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  isBorderHidden: PropTypes.bool,
};

export default ActionDialog;
