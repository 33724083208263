import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Panel.module.scss";

const Panel = ({
  children,
  header,
  inversed,
  emptyState,
  className,
  emptyClassName,
}) => {
  return (
    <div
      className={classnames(
        styles.root,
        { [styles.inversedPanel]: inversed },
        className
      )}
    >
      {header && (
        <div
          className={classnames(styles.header, {
            [styles.headerInversed]: inversed,
            [styles.noBorder]: emptyState,
          })}
        >
          {header}
        </div>
      )}
      {emptyState && (
        <div className={classnames(styles.emptyStateWrapper, emptyClassName)}>
          <div
            className={classnames(styles.content, styles.emptyState, {
              [styles.inversed]: inversed,
              [styles.withHeader]: !!header,
            })}
          >
            {children}
          </div>
        </div>
      )}
      {!emptyState && (
        <div
          className={classnames({
            [styles.content]: !!header,
            [className]: !header,
            [styles.inversed]: inversed,
            [styles.withHeader]: !!header,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Panel.propTypes = {
  header: PropTypes.node,
  inversed: PropTypes.bool,
  emptyState: PropTypes.bool,
  className: PropTypes.string,
  emptyClassName: PropTypes.string,
};

export default Panel;
