import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import React from "react";
import PropTypes from "prop-types";

import styles from "./FilesTable.module.scss";
import _ from "lodash";
import HapReactIcon from "../../../../atomic/icon/hapReactIcon";
import { getDocumentIconByMimeType } from "../../../utils";
import Input from "../../../../atomic/Input/Input";
import { SHARE_TYPES, SHARE_TYPE_COPY } from "./types";
import { THEME_TYPES } from "../../../../atomic/consts";
import Checkbox from "../../../../atomic/Checkbox/Checkbox";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "../../../../atomic/Dropdown/Dropdown";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../../../atomic/Button/Button";

const FilesTable = ({ files, filesConfig, setFilesConfig, setFiles }) => {
  const intl = useIntl();
  return (
    <table cellSpacing={0} cellPadding={0} className={styles.root}>
      <thead>
        <tr>
          <th className={styles.filesColumn} colSpan={2} scope="colgroup">
            <FormattedMessage defaultMessage="My Drive Files" id="JEJMVU" />
          </th>
          <th className={styles.shareTypesSelection}>
            <FormattedMessage defaultMessage="File permission" id="g/kVk2" />
          </th>
          <th className={styles.cross}></th>
        </tr>
      </thead>
      <tbody>
        {files.map((f, ind) => {
          const isCopyFile =
            _.get(filesConfig, `${f.id}.shareType`) === SHARE_TYPE_COPY;
          return (
            <tr key={f.id || `new_file_${ind}`}>
              <td colSpan={isCopyFile ? 1 : 2}>
                <div className={styles.filesColumn}>
                  <div>
                    <HapReactIcon
                      svg={getDocumentIconByMimeType(f.mimeType)}
                      width={15}
                      height={15}
                    />
                  </div>
                  <div className={styles.filesInput}>
                    <Input
                      value={f.name}
                      isErrorMode={_.isEmpty(f.name)}
                      disabled={!_.startsWith(f.id, "new_file_") && !isCopyFile}
                      onChange={(e) => {
                        setFiles(
                          files.map((fl) => {
                            if (fl.id !== f.id) {
                              return fl;
                            }
                            return { ...fl, name: e.target.value };
                          })
                        );
                      }}
                      placeholder=""
                      aria-label={intl.formatMessage({
                        defaultMessage: "File name",
                        id: "ppAn7O",
                      })}
                      themeType={THEME_TYPES.MEDIUM}
                      className={styles.fileName}
                      data-test-id="common-Smartshare-Dialog-Input-FileName"
                    />
                  </div>
                </div>
              </td>
              {isCopyFile && (
                <td>
                  <div>
                    <Checkbox
                      label={intl.formatMessage({
                        defaultMessage: "Append name",
                        id: "5S4z57",
                      })}
                      disabled={
                        _.get(filesConfig, `${f.id}.shareType`) !==
                        SHARE_TYPE_COPY
                      }
                      checked={
                        _.get(filesConfig, `${f.id}.shareType`) ===
                          SHARE_TYPE_COPY &&
                        !!_.get(filesConfig, `${f.id}.appendName`)
                      }
                      dataTestIdPrefix="common-Smartshare-Dialog-AppendName-Checkbox"
                      className={styles.appendNameCheckBox}
                      onChange={(isChecked) => {
                        const conf = { ...filesConfig };
                        _.set(conf, `${f.id}.appendName`, isChecked);
                        setFilesConfig(conf);
                      }}
                    />
                  </div>
                </td>
              )}
              <td className={styles.shareTypesSelection}>
                <Dropdown
                  className={styles.fileShareTypes}
                  positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
                  widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                  triggerComponent={(props) => (
                    <DropdownTrigger
                      label={_.get(
                        _.find(
                          SHARE_TYPES,
                          (t) =>
                            t.id === _.get(filesConfig, `${f.id}.shareType`)
                        ),
                        "name"
                      )}
                      widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                      data-test-id="common-Smartshare-Dialog-SelectShareType-DropdownTrigger"
                      className={styles.shareTypesDropdownTrigger}
                      {...props}
                    />
                  )}
                  itemComponentList={SHARE_TYPES.map(
                    (templ) =>
                      ({ onClick, className, ...rest }) =>
                        (
                          <button
                            data-test-id={`common-Smartshare-Dialog-ChooseShareType-Action-${templ?.name}`}
                            aria-label={`Select ${templ.name}`}
                            className={className}
                            onClick={() => {
                              onClick();
                              const conf = { ...filesConfig };
                              _.set(conf, `${f.id}.shareType`, templ.id);
                              setFilesConfig(conf);
                              setFiles(
                                files.map((fl) =>
                                  fl.id === f.id
                                    ? {
                                        ...fl,
                                        name:
                                          _.get(conf, `${f.id}.originalName`) ||
                                          f.name,
                                      }
                                    : fl
                                )
                              );
                            }}
                            {...rest}
                          >
                            {templ.name}
                          </button>
                        )
                  )}
                />
              </td>
              <td className={styles.cross}>
                <div>
                  <Button
                    label=""
                    type={BUTTON_TYPES.TERTIARY}
                    size={BUTTON_SIZES.REGULAR}
                    icon="cross"
                    onClick={() => {
                      setFiles(files.filter((fl) => fl.id !== f.id));
                    }}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

FilesTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  filesConfig: PropTypes.object.isRequired,
  setFiles: PropTypes.func.isRequired,
  setFilesConfig: PropTypes.func.isRequired,
};

export default FilesTable;
