import React from "react";
import styles from "./Overlay.module.scss";
import classnames from "classnames";

type OverlayProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  dataTestIdPrefix?: string;
  onClick?(): void;
};

export const Overlay = ({
  children,
  dataTestIdPrefix,
  className,
  style,
  onClick,
}: OverlayProps) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-Overlay`
    : undefined;

  return (
    <div
      className={classnames(styles.overlay, className)}
      style={style}
      data-test-id={dataTestId}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
