import { FormattedMessage } from "react-intl";
import React from "react";
import classnames from "classnames";
import styles from "./withFormField.module.scss";
import { v4 as uuidv4 } from "uuid"; // TODO: We could use shorter ids with 'nanoid'
import { HintText } from "../HintText";

export type InjectedProps = {
  value: string;
  onChangeText(value: string): void;
  id?: string;
  dataTestId?: string;
  isDisabled?: boolean;
  /** Renders input and `hintText` in red. */
  hasError?: boolean;
  maxLength?: number;
  placeholder?: string;
  isRequired?: boolean;
};

export type FormFieldProps = {
  className?: string;
  label?: string;
  hintText?: string | React.ReactNode;
  /** Optional, replaces `hintText` when `hasError`. */
  errorMessage?: string | React.ReactNode;
} & InjectedProps;

/** For use within `@hapara/ui` ONLY! */
export const withFormField =
  <P extends InjectedProps>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<P & FormFieldProps> =>
  ({
    label,
    hasError,
    errorMessage,
    hintText,
    dataTestId,
    className,
    // id,
    isRequired,
    ...rest
  }) => {
    const inputId = uuidv4();
    const hintTextId = `${inputId}-hintText`;

    const labelTestId = `${dataTestId}-label`;
    const hintTextTestId = `${dataTestId}-hintText`;

    return (
      <div className={classnames(styles.root, className)}>
        {label ? (
          <label
            className={styles.label}
            htmlFor={inputId}
            data-test-id={labelTestId}
          >
            {label}{" "}
            {isRequired && (
              <span>
                <FormattedMessage defaultMessage="(required)" id="VG94fP" />
              </span>
            )}
          </label>
        ) : null}
        <WrappedComponent
          hasError={hasError}
          dataTestId={dataTestId}
          aria-describedby={hintText ? hintTextId : undefined}
          id={inputId}
          {...(rest as P)}
        />
        <HintText
          id={hintTextId}
          dataTestId={hintTextTestId}
          message={hintText}
          errorMessage={errorMessage}
          hasError={hasError}
        />
      </div>
    );
  };
