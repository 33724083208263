import React from "react";
import classnames from "classnames";
import { Box } from "@hapara/ui/src/atomic/Box/Box";
import styles from "./GuidedBrowsingSection.module.scss";

interface GuidedBrowsingSectionProps {
  children: React.ReactNode;
  withoutPadding?: boolean;
}

export const GuidedBrowsingSection = ({
  children,
  withoutPadding,
}: GuidedBrowsingSectionProps) => {
  return (
    <Box
      className={classnames(styles.container, {
        [styles.withoutPadding]: withoutPadding,
      })}
    >
      {children}
    </Box>
  );
};
