import { Alert } from "@hapara/ui/src/atomic/AlertDeprecated";
import styles from "./ClassInfoCustomAlert.module.scss";

type ClassInfoCustomAlertProps = {
  emails: string[];
  className?: string;
};

export const ClassInfoCustomAlert = ({
  emails,
  className,
}: ClassInfoCustomAlertProps) => {
  const emailList = emails.join(", ");

  const title =
    emails.length > 1
      ? `${emails.length} students could not be added to this class`
      : "Student could not be added to this class";
  const subtitle =
    emails.length > 1
      ? "Please check these emails are existing school accounts:"
      : "Please check this email is an existing school account:";

  return (
    <Alert isVisible type="failure" className={className} animated={false}>
      <div className={styles.container}>
        <p className={styles.title}>{title}</p>
        <p className={styles.title}>{subtitle}</p>
        <p>{emailList}</p>
      </div>
    </Alert>
  );
};
