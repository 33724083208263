import React, { useRef, useEffect, useState } from "react";
import { Textarea } from "../../../../atomic/Textarea";
import { Button } from "../../../../atomic/Button";
import styles from "./ChatInput.module.scss";
import { useKeyPress } from "../../../../hooks/useKeyPress";
import { useIntl } from "react-intl";
import { ChatRoleType, ChatContactPresence } from "../../types/state";
import { Alert } from "../../../../atomic/Alert";
import { FormattedMessage } from "react-intl";

export type ChatInputProps = {
  role: ChatRoleType;
  presence?: ChatContactPresence;
  isDisabled?: boolean;
  /** @deprecated TODO: switch to `isSchoolHours` */
  isOutsideSchoolHours?: boolean;
  placeholder?: string;
  onSend(message: string): void;
};

export const ChatInput = ({
  role,
  presence = "offline",
  isDisabled,
  isOutsideSchoolHours,
  placeholder,
  onSend,
}: ChatInputProps) => {
  const intl = useIntl();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [message, setMessage] = useState<string | null>(null);

  const handleSend = () => {
    if (message) {
      onSend(message);
      setMessage(null);
    }
  };

  const handleEnterPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if (message) {
      handleSend();
    }
  };

  useKeyPress("Enter", handleEnterPress);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    const isEmpty = value.length === 0;
    setMessage(!isEmpty ? value : null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        {presence === "offline" || isDisabled || isOutsideSchoolHours ? (
          <Alert type="warning" className={styles.alert} withoutMargin>
            {isOutsideSchoolHours ? (
              <FormattedMessage
                defaultMessage="Chat is only available during school hours."
                id="UVWi0g"
              />
            ) : role === "student" ? (
              <FormattedMessage
                defaultMessage="Your teacher is unavailable. Please try again later."
                id="IX1uKk"
              />
            ) : isDisabled ? (
              <FormattedMessage
                defaultMessage="Chat is off. Enable chat to message students."
                id="yvGbX3"
              />
            ) : (
              <FormattedMessage
                defaultMessage="The student is offline. Please try again later."
                id="WXJuTs"
              />
            )}
          </Alert>
        ) : (
          <Textarea
            ref={textareaRef}
            onChange={handleTextChange}
            value={message ?? ""}
            isAutoExpandable={true}
            rows={1}
            maxRows={2}
            placeholder={placeholder}
            data-test-id="chat-direct-channel-Input"
          />
        )}
        <Button
          onClick={handleSend}
          label={intl.formatMessage({ defaultMessage: "Send", id: "9WRlF4" })}
          isDisabled={
            message === null || message === "" || isOutsideSchoolHours
          }
          // TODO: in future we may have group channels,
          // this shouldn't be fixed to "direct"
          data-test-id="chat-direct-channel-SendButton"
        />
      </div>
    </div>
  );
};
