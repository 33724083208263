import styles from "./ToolbarToggleView.module.scss";
import { STUDENT_TILE_CONTENT_TYPE } from "../../../../state/highlights/config/types";
import { useIntl } from "react-intl";
import { GridIcon } from "@hapara/ui/src/icons/GridIcon";
import { ListIcon } from "@hapara/ui/src/icons/ListIcon";
import {
  Segment,
  SegmentedControl,
} from "@hapara/ui/src/atomic/SegmentedControl";
interface ToolbarToggleViewProps {
  onViewToggle: (viewType: string) => void;
  activeView: "tabs" | "screens";
}

const ToolbarToggleView = ({
  onViewToggle,
  activeView,
}: ToolbarToggleViewProps) => {
  const intl = useIntl();
  const selectedSegment = activeView === "tabs" ? 0 : 1;

  return (
    <SegmentedControl
      iconOnlySegments
      defaultSelectedIndex={selectedSegment}
      dataTestIdPrefix="guided-browsing-viewToggle"
      className={styles.toggleContainer}
    >
      <Segment
        icon={<ListIcon />}
        onClick={() => onViewToggle(STUDENT_TILE_CONTENT_TYPE.TABS)}
        tooltipText={intl.formatMessage({
          defaultMessage: "View browser tabs",
          id: "YfQ/8K",
        })}
        ariaLabel={intl.formatMessage({
          defaultMessage: "View browser tabs",
          id: "YfQ/8K",
        })}
        dataTestId="tabs"
      />
      <Segment
        icon={<GridIcon />}
        onClick={() => onViewToggle(STUDENT_TILE_CONTENT_TYPE.SCREENS)}
        tooltipText={intl.formatMessage({
          defaultMessage: "View current screens",
          id: "jr+DAJ",
        })}
        ariaLabel={intl.formatMessage({
          defaultMessage: "View current screens",
          id: "jr+DAJ",
        })}
        dataTestId="screens"
      />
    </SegmentedControl>
  );
};

export default ToolbarToggleView;
