import actionTypes from "../../../actionTypes";

export const INIT_STATE = {
  itemsLoading: true,
  itemsLoadError: false,
  items: null,
  pagesCount: 0,
  totalItems: 0,
};

const searchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.PL_SEARCH_WORKSPACES_PENDING:
      return { ...state, itemsLoading: true, itemsLoadError: false };

    case actionTypes.PL_SEARCH_WORKSPACES_ERROR:
      return { ...state, itemsLoading: false, itemsLoadError: true, items: [] };

    case actionTypes.PL_SEARCH_WORKSPACES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsLoadError: false,
        items: action.payload.items,
        pagesCount: action.payload.pagesCount,
        totalItems: action.payload.totalItems,
      };

    case actionTypes.PL_SEARCH_WORKSPACES_CLEAR:
      return INIT_STATE;

    default:
      return state;
  }
};

export default searchReducer;
