import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./FiltersPlRole.module.scss";
import Dropdown, {
  DROPDOWN_WIDTH_TYPES,
  DropdownTrigger,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { getFilterPlRole } from "../../../../../state/workspace/myworkspaces/list/selectors";
import { MYWORKSPACES_PL_ROLES } from "../../../../../state/workspace/myworkspaces/list/types";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../../state/app/types";
import { getWindowSize } from "../../../../../state/app/selectors";

export const FiltersPlRole = ({ selectedPLRole, windowSize }) => {
  const triggerRef = useRef();

  const filterOptionList = [
    {
      id: 1,
      name: "All roles",
      type: MYWORKSPACES_PL_ROLES.ALL,
      dataTestId: "All",
    },
    {
      id: 2,
      name: "Facilitator",
      type: MYWORKSPACES_PL_ROLES.FACILITATOR,
      dataTestId: "Owner",
    },
    {
      id: 3,
      name: "Participant",
      type: MYWORKSPACES_PL_ROLES.PARTICIPANT,
      dataTestId: "Participant",
    },
  ];

  const selectedOption =
    _.find(filterOptionList, { type: selectedPLRole }) || {};

  return (
    <div className={styles.root}>
      <Dropdown
        widthType={
          windowSize < WINDOW_SIZE_TYPES.S
            ? DROPDOWN_WIDTH_TYPES.FULL_WIDTH
            : DROPDOWN_WIDTH_TYPES.MIN_CONTENT
        }
        triggerComponent={(props) => (
          <DropdownTrigger
            widthType={
              windowSize < WINDOW_SIZE_TYPES.S
                ? DROPDOWN_WIDTH_TYPES.FULL_WIDTH
                : DROPDOWN_WIDTH_TYPES.MIN_CONTENT
            }
            className={classnames(styles.dropdownTrigger, {
              [styles.notDefault]:
                selectedOption.type !== MYWORKSPACES_PL_ROLES.ALL,
            })}
            data-test-id="ws-MyWorkspaces-PL-Filter-Trigger"
            label={selectedOption.name}
            ref={triggerRef}
            {...props}
          />
        )}
        itemComponentList={filterOptionList.map(
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.type === selectedPLRole;
              return (
                <button
                  onClick={() => {
                    pushQueryToHistory({
                      pl_role: item.type,
                    });
                    onClick();
                    _.delay(() => triggerRef.current.focus(), 100);
                  }}
                  className={classnames(className, styles.dropdownItem, {
                    [styles.selected]: isSelected,
                  })}
                  data-test-id={`ws-MyWorkspaces-PL-Filter${item.dataTestId}`}
                  aria-label={`${item.name}${isSelected ? ", selected" : ""}`}
                  {...rest}
                >
                  {item.name}
                </button>
              );
            }
        )}
      />
    </div>
  );
};

FiltersPlRole.propTypes = {
  selectedPLRole: PropTypes.oneOf(_.values(MYWORKSPACES_PL_ROLES)),
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  selectedPLRole: getFilterPlRole(state),
  windowSize: getWindowSize(state),
}))(FiltersPlRole);
