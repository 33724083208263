import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styles from "./DashboardSubMenu.module.scss";
import stylesSubMenu from "../../SubMenu/SubMenu.module.scss";

// COMPONENTS
import SubMenu from "../../SubMenu/SubMenu";
import SubjectFolders from "./SubjectFolders";
import ToolbarSort from "../../Highlights/ToolbarSort/ToolbarSort";
import ToolbarGroups from "../../Highlights/ToolbarGroups/ToolbarGroups";
import ToolbarRefresh from "../../Highlights/ToolbarRefresh/ToolbarRefresh";
import ToolbarTilesSize from "../../Highlights/ToolbarTilesSize/ToolbarTilesSize";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

// STATE / TYPES
import { getWindowSize } from "../../../../state/app/selectors";
import { WINDOW_SIZE_TYPES } from "../../../../state/app/types";
import { PAGE_TYPES } from "../../../../hooks/useGroups";
import { APP_PAGES } from "../../../../state/router/types";
import { STUDENT_ORDER_TYPES } from "../../../../state/highlights/config/types";
import { getFFByName } from "../../../../state/app/selectors";
import { isSharingViewEnabled } from "../../../../state/user/selectors";
import { useIntl } from "react-intl";
interface Props {
  tabName: string;
  classId: string;
}

const DashboardSubMenu: React.FC<Props> = ({ tabName, classId }) => {
  const intl = useIntl();
  const windowSize = useSelector(getWindowSize);
  const dataTestIdPrefix = "dashboard-page-sub-nav";

  const hasEdublogsEnabled = useSelector(getFFByName("HAP-10024-edublogs"));
  const hasNavBarButtonUpdateFlag = useSelector(
    getFFByName("GBE-02-nav-bar-button-update")
  );
  const hasDriveEnabled = useSelector(isSharingViewEnabled);

  const STUDENT_ORDER_DROPDOWN_ITEMS_NAME_ONLY = [
    {
      type: STUDENT_ORDER_TYPES.FIRST_AZ,
      label: intl.formatMessage({
        defaultMessage: "First Name (A-Z)",
        id: "22cP5C",
      }),
    },
    {
      type: STUDENT_ORDER_TYPES.LAST_AZ,
      label: intl.formatMessage({
        defaultMessage: "Last Name (A-Z)",
        id: "YUwFEk",
      }),
    },
  ];

  const Tabs = (
    <>
      <SubjectFolders
        dataTestIdPrefix={dataTestIdPrefix}
        classId={classId}
        tabName={tabName}
      />
      {hasDriveEnabled && (
        <NavLink
          to={`${APP_PAGES.DASHBOARD_DRIVE.path}/${classId}/all`}
          strict
          activeClassName={stylesSubMenu.active}
          data-test-id={`${dataTestIdPrefix}-Link-Drive`}
          isActive={(_match, location) => {
            return location.pathname.includes(
              `${APP_PAGES.DASHBOARD_DRIVE.path}`
            );
          }}
        >
          <span className={styles.container}>
            <HapReactIcon className={styles.icon} svg="google-drive" />
            Drive
          </span>
        </NavLink>
      )}
      {hasEdublogsEnabled && (
        <NavLink
          to={`${APP_PAGES.DASHBOARD_EDUBLOGS.path}/${classId}/posts`}
          strict
          activeClassName={stylesSubMenu.active}
          data-test-id={`${dataTestIdPrefix}-Link-Edublogs`}
          isActive={(_match, location) => {
            return location.pathname.includes(
              `${APP_PAGES.DASHBOARD_EDUBLOGS.path}`
            );
          }}
        >
          <span className={styles.container}>
            <HapReactIcon svg="edublogs-icon" className={styles.icon} />{" "}
            Edublogs
          </span>
        </NavLink>
      )}
    </>
  );

  return (
    <SubMenu
      tabs={Tabs}
      tabsLabel="Sharing page"
      className={styles.root}
      tabsClassName={classnames(styles.tabs)}
      toolbarClassName={styles.toolbar}
      toolbar={
        <div className={styles.toolbarInner}>
          <ToolbarGroups
            pageType={PAGE_TYPES.DASHBOARD}
            tabName={tabName}
            dataTestIdPrefix={dataTestIdPrefix}
            hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
          />
          <ToolbarSort
            dropDownList={STUDENT_ORDER_DROPDOWN_ITEMS_NAME_ONLY}
            dataTestIdPrefix={dataTestIdPrefix}
            canSortAlphabeticallyOnly={true}
            hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
          />
          {windowSize >= WINDOW_SIZE_TYPES.S && (
            <ToolbarTilesSize
              dataTestIdPrefix={dataTestIdPrefix}
              hasNavBarButtonUpdateFlag={hasNavBarButtonUpdateFlag}
            />
          )}
          <ToolbarRefresh dataTestIdPrefix={dataTestIdPrefix} />
        </div>
      }
    />
  );
};

export default DashboardSubMenu;
