import { ChatState } from "../../types/state";

export const calculateClassesUnread = (channels: ChatState["channels"]) =>
  Object.values(channels).reduce((acc, channel) => {
    if (channel.classStatus) {
      Object.entries(channel.classStatus).forEach(([classId, status]) => {
        if (status) {
          acc[classId] = (acc[classId] || 0) + (status?.unreadCount ?? 0);
        }
      });
    }
    return acc;
  }, {} as Record<string, number>);
