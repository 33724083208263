import React from "react";
import style from "./ChatClassList.module.scss";
import { ChatClassItem } from "../ChatClassItem";
import { ChatSubHeading } from "../ChatSubHeading";
import { FormattedMessage } from "react-intl";
import { ChatClassesState } from "../../types/state";

type ChatClassListProps = {
  classes: ChatClassesState;
  onSelectClassId(id: string): void;
};

export const ChatClassList = ({
  classes,
  onSelectClassId,
}: ChatClassListProps) => {
  return (
    <div className={style.container}>
      <ChatSubHeading>
        <FormattedMessage defaultMessage="Select class" id="V6E2bj" />
      </ChatSubHeading>
      <div className={style.classes}>
        {Object.entries(classes).map(([_classId, { name, urn }]) => (
          <ChatClassItem
            key={urn}
            className={name}
            classId={urn}
            onSelectClassId={onSelectClassId}
          />
        ))}
      </div>
    </div>
  );
};
