import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalFormEndOfSession.module.scss";
import classnames from "classnames";
import Tippy from "@tippy.js/react";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { FormattedMessage, useIntl } from "react-intl";
const ModalFormEndOfSession = ({
  isEndSessionKeepAllTabs,
  onEndSessionKeepAllTabsChange,
  isEndSessionRestoreOriginal,
  onEndSessionRestoreOriginalChange,
  dataTestPrefix,
}) => {
  const intl = useIntl();
  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormEndOfSessionBlock`}
    >
      <fieldset className={styles.endSessionToggles}>
        <legend className={styles.title}>
          <FormattedMessage
            defaultMessage="At the end of the session:"
            id="IzZfsn"
          />
        </legend>
        <Toggle
          dataTestIdPrefix="keep-links"
          className={styles.toggle}
          value={isEndSessionKeepAllTabs}
          onChange={onEndSessionKeepAllTabsChange}
          explanatoryText={intl.formatMessage({
            defaultMessage:
              "Keep all links that were introduced in this session open.",
            id: "HIiC67",
          })}
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          toggleValues={{
            left: {
              label: intl.formatMessage({ defaultMessage: "No", id: "oUWADl" }),
            },
            right: {
              label: intl.formatMessage({
                defaultMessage: "Yes",
                id: "a5msuh",
              }),
            },
          }}
        />
        <div className={classnames(styles.toggle, styles.textSeparated)}>
          <p className={styles.text}>
            <FormattedMessage
              defaultMessage="Restore students’ original tabs."
              id="iAzOeE"
            />{" "}
            <span>
              <Tippy
                content={intl.formatMessage({
                  defaultMessage:
                    "When a Focus Session starts, it will close the tabs students have open. Restoring their tabs after a Focus Session allows them to resume previous work.",
                  id: "11rf/t",
                })}
                theme="hsuite"
                maxWidth={250}
              >
                <span>
                  <FormattedMessage
                    defaultMessage="What does this mean?"
                    id="z59ra6"
                  />{" "}
                  <HapReactIcon
                    svg="circle-information"
                    width={16}
                    height={16}
                    className={styles.icon}
                  />
                </span>
              </Tippy>
            </span>
          </p>
          <Toggle
            dataTestIdPrefix="restore-original-tabs"
            className={styles.noText}
            value={isEndSessionRestoreOriginal}
            onChange={onEndSessionRestoreOriginalChange}
            positionOfToggle={TOGGLE_POSITIONS.RIGHT}
            toggleValues={{
              left: {
                label: intl.formatMessage({
                  defaultMessage: "No",
                  id: "oUWADl",
                }),
              },
              right: {
                label: intl.formatMessage({
                  defaultMessage: "Yes",
                  id: "a5msuh",
                }),
              },
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};

ModalFormEndOfSession.propTypes = {
  isEndSessionKeepAllTabs: PropTypes.bool.isRequired,
  onEndSessionKeepAllTabsChange: PropTypes.func.isRequired,
  isEndSessionRestoreOriginal: PropTypes.bool.isRequired,
  onEndSessionRestoreOriginalChange: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default ModalFormEndOfSession;
