import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./StudentTilePauseState.module.scss";
import { releaseStudentsFromSession } from "../../../../state/highlights/sessions/actions";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { FormattedMessage } from "react-intl";

const StudentTilePauseState = ({ studentId, releaseStudentsFromSession }) => {
  const [isReleaseLoading, setIsReleaseLoading] = useState(false);

  const handleReleaseStudent = () => {
    setIsReleaseLoading(true);

    releaseStudentsFromSession({
      studentIds: [studentId],
    })
      .then(() => {
        setIsReleaseLoading(false);
      })
      .catch(() => {
        setIsReleaseLoading(false);
      });
  };

  return (
    <div className={styles.root}>
      {!isReleaseLoading && (
        <>
          <div
            className={styles.title}
            data-test-id="hl-StudentTile-ScreenPaused"
          >
            <FormattedMessage defaultMessage="Screen is paused" id="3jr93X" />
          </div>
          <button
            onClick={handleReleaseStudent}
            className={styles.releaseStudent}
            data-test-id="hl-StudentTile-ReleaseFromPause"
          >
            <FormattedMessage
              defaultMessage="Release this student"
              id="U9DghJ"
            />
          </button>
        </>
      )}
      {isReleaseLoading && (
        <div className={styles.overlay}>
          <div className={styles.overlayBackdrop} />
          <div className={styles.overlayBody}>
            <HapReactIcon
              svg="loader-light"
              width={24}
              height={24}
              className={styles.overlayIcon}
              spin={true}
            />
            <div className={styles.overlayTitle}>
              <FormattedMessage
                defaultMessage="Updating information"
                id="UAPWJP"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StudentTilePauseState.propTypes = {
  studentId: PropTypes.string.isRequired,
  // connected props
  releaseStudentsFromSession: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    releaseStudentsFromSession: ({ studentIds }) =>
      dispatch(
        releaseStudentsFromSession({
          studentIds,
          isPause: true,
        })
      ),
  })
)(StudentTilePauseState);
