import { RootState } from "../store";
import {
  ClassInfoData,
  ClassInfoAddPersonResponse,
} from "../../apiCalls/classInfo";

export const getClassInfoData = (state: RootState): ClassInfoData | null =>
  state.classInfo.data;

export const getIsClassInfoError = (state: RootState): boolean =>
  state.classInfo.isError;

export const getIsClassInfoLoading = (state: RootState): boolean =>
  state.classInfo.isLoading;

export const getIsClassInfoLoaded = (state: RootState): boolean =>
  state.classInfo.isLoaded;

export const getIsAddStudentLoading = (state: RootState): boolean =>
  state.classInfo.addStudents.isLoading;

export const getIsAddStudentLoaded = (state: RootState): boolean =>
  state.classInfo.addStudents.isLoaded;

export const getIsAddStudentError = (state: RootState): boolean =>
  state.classInfo.addStudents.isError;

export const getUnsuccessfullyAddedStudents = (
  state: RootState
): ClassInfoAddPersonResponse["Bad"] =>
  state.classInfo.addStudents.unsuccessfullyAddedStudents;

export const getIsRemoveStudentsLoading = (state: RootState): boolean =>
  state.classInfo.removeStudents.isLoading;

export const getIsRemoveStudentsLoaded = (state: RootState): boolean =>
  state.classInfo.removeStudents.isLoaded;

export const getIsRemoveStudentsError = (state: RootState): boolean =>
  state.classInfo.removeStudents.isError;

export const getIsAddTeacherLoading = (state: RootState): boolean =>
  state.classInfo.addTeacher.isLoading;

export const getIsAddTeacherLoaded = (state: RootState): boolean =>
  state.classInfo.addTeacher.isLoaded;

export const getIsAddTeacherError = (state: RootState): boolean =>
  state.classInfo.addTeacher.isError;

export const getIsAddTeacherErrorPayload = (
  state: RootState
): ClassInfoAddPersonResponse["Bad"] =>
  state.classInfo.addTeacher.unsuccessfullyAddedTeacher;

export const getIsAddTeacherServerValidationError = (
  state: RootState
): boolean => state.classInfo.addTeacher.hasServerValidationError;

export const getIsRemoveTeachersLoading = (state: RootState): boolean =>
  state.classInfo.removeTeachers.isLoading;

export const getIsRemoveTeachersLoaded = (state: RootState): boolean =>
  state.classInfo.removeTeachers.isLoaded;

export const getIsRemoveTeachersError = (state: RootState): boolean =>
  state.classInfo.removeTeachers.isError;

export const getIsSyncClassLoading = (state: RootState): boolean =>
  state.classInfo.syncGoogleClassroom.isLoading;

export const getIsSyncClassLoaded = (state: RootState): boolean =>
  state.classInfo.syncGoogleClassroom.isLoaded;

export const getIsSyncClassError = (state: RootState): boolean =>
  state.classInfo.syncGoogleClassroom.isError;

export const getIsRenameClassLoading = (state: RootState): boolean =>
  state.classInfo.renameClass.isLoading;

export const getIsRenameClassLoaded = (state: RootState): boolean =>
  state.classInfo.renameClass.isLoaded;

export const getIsRenameClassError = (state: RootState): boolean =>
  state.classInfo.renameClass.isError;

export const getIsUpdatePasswordsLoading = (state: RootState): boolean =>
  state.classInfo.updatePasswords.isLoading;

export const getIsUpdatePasswordsLoaded = (state: RootState): boolean =>
  state.classInfo.updatePasswords.isLoaded;

export const getIsUpdatePasswordsError = (state: RootState): boolean =>
  state.classInfo.updatePasswords.isError;

export const getIsRemoveClassLoading = (state: RootState): boolean =>
  state.classInfo.removeClass.isLoading;

export const getIsRemoveClassLoaded = (state: RootState): boolean =>
  state.classInfo.removeClass.isLoaded;

export const getIsRemoveClassError = (state: RootState): boolean =>
  state.classInfo.removeClass.isError;

// Reset Class
export const getIsResetClassLoading = (state: RootState): boolean =>
  state.classInfo.resetClass.isLoading;

export const getIsResetClassLoaded = (state: RootState): boolean =>
  state.classInfo.resetClass.isLoaded;

export const getIsResetClassError = (state: RootState): boolean =>
  state.classInfo.resetClass.isError;
