import React from "react";
import { Drawer } from "@hapara/ui/src/components/Drawer";
import { Dialog } from "@hapara/ui/src/components/Dialog";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import { LinearProgress } from "@hapara/ui/src/atomic/LinearProgress";
import styles from "./GuidedBrowsingTemplateDrawer.module.scss";
import {
  GuidedBrowsingCard,
  GuidedBrowsingTable,
  GuidedBrowsingConfirmDeleteModal,
} from "../";
import { FormattedMessage, useIntl } from "react-intl";
import { Template } from "src/state/highlights/guideBrowsingTemplates/type";
import { useGuidedBrowsingTemplates } from "src/hooks/useGuidedBrowsingTemplates";
import { Button } from "@hapara/ui/src/atomic/Button";
import { PlusIcon } from "@hapara/ui/src/icons/PlusIcon";
interface GuidedBrowsingTemplateDrawerPresentationProps {
  isOpen: boolean;
  onClose(): void;
  isLoading?: boolean;
  isLoadMoreInProgress?: boolean;
  sessionTemplates: Template[];
  hasMoreTemplates?: boolean;
  handleLoadMore?(): void;
  onCreateFocusSession(): void;
  onCreateFilterSession(): void;
  onCreateTemplate(): void;
  onStartTemplateSession(template: Template): void;
  onEditTemplateSession(template: Template): void;
  onScheduleTemplateSession(template: Template): void;
  onDeleteTemplateSession(template: Template): void;
}

export const GuidedBrowsingTemplateDrawerPresentation = ({
  onCreateFocusSession,
  onCreateFilterSession,
  onDeleteTemplateSession,
  onEditTemplateSession,
  onStartTemplateSession,
  onScheduleTemplateSession,
  onClose,
  hasMoreTemplates,
  isLoadMoreInProgress,
  handleLoadMore,
  onCreateTemplate,
  sessionTemplates,
  isLoading,
  isOpen,
}: GuidedBrowsingTemplateDrawerPresentationProps) => {
  const intl = useIntl();
  return (
    <Drawer
      isOpen={isOpen}
      dataTestIdPrefix="guided-browsing-drawer"
      onRequestClose={onClose}
    >
      <Dialog
        dataTestIdPrefix="guided-browsing-dialog"
        className={styles.dialog}
        heading={intl.formatMessage({
          defaultMessage: "Guide browsing",
          id: "JLAnOx",
        })}
        headingStyledAs="h3"
        onRequestClose={onClose}
        fullWidth
      >
        <div className={styles.cardContainer}>
          <GuidedBrowsingCard variant="focus" onClick={onCreateFocusSession} />
          <GuidedBrowsingCard
            variant="filter"
            onClick={onCreateFilterSession}
          />
        </div>
        <div className={styles.tableHeaderContainer}>
          <Heading level={2} styledAs="h4">
            <FormattedMessage defaultMessage="My Templates" id="5D5ZTU" />
          </Heading>
          <Button
            dataTestId="create-guided-browsing-template-button"
            iconComponent={<PlusIcon />}
            size="small"
            type="primary"
            aria-label={intl.formatMessage({
              defaultMessage: "Create guided browsing template",
              id: "3daY4b",
            })}
            label={intl.formatMessage({
              defaultMessage: "Template",
              id: "3JxaKs",
            })}
            onClick={onCreateTemplate}
          />
        </div>

        {isLoading ? (
          <LinearProgress />
        ) : (
          <GuidedBrowsingTable
            data={sessionTemplates}
            onStart={onStartTemplateSession}
            onEdit={onEditTemplateSession}
            onDelete={onDeleteTemplateSession}
            onSchedule={onScheduleTemplateSession}
          />
        )}
        {hasMoreTemplates && (
          <div className={styles.loadMoreButtonContainer}>
            <Button
              dataTestId="load-more-templates-button"
              size="small"
              type="outlined"
              outlineType="solid"
              label={intl.formatMessage({
                defaultMessage: "Load more",
                id: "00LcfG",
              })}
              onClick={handleLoadMore}
              isLoading={isLoadMoreInProgress}
            />
          </div>
        )}
      </Dialog>
    </Drawer>
  );
};

interface GuidedBrowsingTemplateDrawerProps {
  isOpen: boolean;
}

export const GuidedBrowsingTemplateDrawer = ({
  isOpen,
}: GuidedBrowsingTemplateDrawerProps) => {
  const {
    isLoading,
    templateForDeletion,
    isDeletingTemplate,
    deleteError,
    sessionTemplates,
    hasMoreTemplates,
    handleLoadMore,
    isLoadMoreInProgress,
    handleCreateTemplate,
    handleEditTemplate,
    handleDeleteTemplate,
    confirmDeleteTemplate,
    cancelDeleteTemplate,
    handleClose,
    handleCreateSession,
    handleStartSession,
    handleScheduleTemplate,
  } = useGuidedBrowsingTemplates(isOpen);

  return (
    <>
      <GuidedBrowsingConfirmDeleteModal
        isOpen={templateForDeletion !== null}
        onClose={cancelDeleteTemplate}
        onDelete={confirmDeleteTemplate}
        templateName={templateForDeletion?.Name as string}
        hasError={deleteError}
        isLoading={isDeletingTemplate}
      />
      <GuidedBrowsingTemplateDrawerPresentation
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        sessionTemplates={sessionTemplates}
        hasMoreTemplates={hasMoreTemplates}
        isLoadMoreInProgress={isLoadMoreInProgress}
        handleLoadMore={handleLoadMore}
        onCreateTemplate={handleCreateTemplate}
        onStartTemplateSession={handleStartSession}
        onEditTemplateSession={handleEditTemplate}
        onScheduleTemplateSession={handleScheduleTemplate}
        onDeleteTemplateSession={handleDeleteTemplate}
        onCreateFocusSession={() => handleCreateSession("focus")}
        onCreateFilterSession={() => handleCreateSession("filter")}
      />
    </>
  );
};
