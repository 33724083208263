import React from "react";
import { createIcon } from "./utils/createIcon";

export const CircleWarningIcon = createIcon(
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm0-2.63a.88.88 0 1 0 0-1.75.88.88 0 0 0 0 1.76ZM7.12 4.5a.87.87 0 1 1 1.75 0V8a.87.87 0 1 1-1.74 0V4.5Z"
  />
);
