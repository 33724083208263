import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./AssignClassesMultiSelectList.module.scss";
import classnames from "classnames";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";
import { classItemType } from "../../../../state/library/config/types";

const AssignClassesMultiSelectList = ({
  items,
  dataTestPrefix,
  selectedItemsIds,
  setSelectedItemsIds,
  singleItemName,
  itemsName,
}) => {
  const handleSelect = (isChecked, itemId) => {
    if (isChecked) {
      const newArr = _.without(selectedItemsIds, itemId);
      setSelectedItemsIds([...newArr]);
    } else {
      setSelectedItemsIds([...selectedItemsIds, itemId]);
    }
  };
  let isAnythingSelected, isEverythingSelected;

  const selectableItems = items.filter((i) => i.IsTeaching);
  isAnythingSelected = selectedItemsIds.length > 0;
  isEverythingSelected = selectedItemsIds.length === selectableItems.length;

  let selectAllLabel = "";
  if (selectedItemsIds.length === 0) {
    selectAllLabel = `Select all ${itemsName}`;
  } else if (isEverythingSelected) {
    selectAllLabel = "All selected";
  } else if (selectedItemsIds.length === 1) {
    selectAllLabel = `${selectedItemsIds.length} ${singleItemName} selected`;
  } else {
    selectAllLabel = `${selectedItemsIds.length} ${itemsName} selected`;
  }

  const handleSelectAll = () => {
    if (isEverythingSelected) {
      setSelectedItemsIds([]);
    } else {
      setSelectedItemsIds(items.filter((i) => i.IsTeaching).map((i) => i.ID));
    }
  };

  return (
    <ul className={styles.root}>
      <li>
        <Checkbox
          checked={isAnythingSelected}
          semi={isAnythingSelected && !isEverythingSelected}
          onChange={handleSelectAll}
          label={selectAllLabel}
          dataTestIdPrefix={`${dataTestPrefix}-Checkbox-SelectAllClasses`}
          themeType={THEME_TYPES.DARK}
        />
      </li>
      {items.map((i) => {
        const isChecked = _.indexOf(selectedItemsIds, i.ID) >= 0;
        return (
          <li
            key={i.ID}
            className={classnames({
              [styles.selectedItem]: isChecked,
            })}
          >
            <Checkbox
              checked={isChecked}
              onChange={() => handleSelect(isChecked, i.ID)}
              label={i.Name}
              dataTestIdPrefix={`${dataTestPrefix}-Checkbox-AssignClass`}
              disabled={!i.IsTeaching}
            />
          </li>
        );
      })}
    </ul>
  );
};

AssignClassesMultiSelectList.propTypes = {
  items: PropTypes.arrayOf(classItemType).isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  selectedItemsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedItemsIds: PropTypes.func.isRequired,
  singleItemName: PropTypes.string.isRequired,
  itemsName: PropTypes.string.isRequired,
};

export default connect((state) => ({}))(AssignClassesMultiSelectList);
