import { useContext } from "react";
import { TableContext } from "./TableContext";

export const useTable = () => {
  const context = useContext(TableContext);

  if (context === undefined) {
    throw new Error("useTable hook must be used within a @hapara/ui Table");
  }

  return context;
};
