import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ToolbarPause.module.scss";
import { updateNotificationSession } from "../../../../state/highlights/notification/actions";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";
import {
  getActivePauseSessionList,
  getIsPauseSession,
} from "../../../../state/highlights/sessions/selectors";
import {
  getIsOutsideSchoolHours,
  getPauseScreensDuration,
  getPauseScreensEnabled,
} from "../../../../state/highlights/config/selectors";
import {
  SESSION_TYPES,
  SESSION_RECIPIENT_TYPES,
  GUIDE_BROWSING_SESSION_TYPE,
} from "../../../../state/highlights/sessions/types";
import { getClassId } from "../../../../state/shared/selectors";
import { getStudentsIdList } from "../../../../state/highlights/studentConfigs/selectors";
import { getUserFamilyName } from "../../../../state/user/selectors";
import useUpdateHighlightsSessionsAfterEndTimeHook from "../../../../hooks/useUpdateHighlightsSessionsAfterEndTimeHook";
import {
  loadCurrentSessions,
  releaseSession,
} from "../../../../state/highlights/sessions/actions";
import _ from "lodash";
import { useIntl } from "react-intl";

const ToolbarPause = ({
  isPauseSession,
  isPauseScreensEnabled = false,
  sessionList,
  selectedClassId,
  pauseScreensDuration,
  studentIds,
  teacherName,
  isOutsideSchoolHours,
  updateNotificationSession,
  loadCurrentSessions,
  releaseSession,
}) => {
  const intl = useIntl();
  const [isPauseDisabled, setIsPauseDisabled] = useState(false);

  // effect to check when PAUSE sessions expire and update the list
  const loadSessions = useCallback(() => {
    loadCurrentSessions({ classId: selectedClassId });
  }, [loadCurrentSessions, selectedClassId]);

  useUpdateHighlightsSessionsAfterEndTimeHook(loadSessions, sessionList);

  // disable pause when out of school hours
  useEffect(() => {
    setIsPauseDisabled(isOutsideSchoolHours);
  }, [isOutsideSchoolHours]);

  // release all
  const handleReleaseAll = () => {
    setIsPauseDisabled(true);

    Promise.all(
      _.map(sessionList, (session) => {
        return releaseSession({ sessionId: session.ID });
      })
    )
      .then(() => {
        loadCurrentSessions({ classId: selectedClassId }).finally(() => {
          setIsPauseDisabled(false);
        });
      })
      .catch(() => {
        setIsPauseDisabled(false);
      });
  };

  if (!isPauseScreensEnabled) return null;

  return (
    <div className={styles.root}>
      <Toggle
        explanatoryText={intl.formatMessage({
          defaultMessage: "Pause Screens",
          id: "OJx0tb",
        })}
        positionOfToggle={TOGGLE_POSITIONS.RIGHT}
        value={isPauseSession}
        disabled={isPauseDisabled}
        onChange={(value) => {
          if (value) {
            // when turning on
            updateNotificationSession({
              sessionType: SESSION_TYPES.PAUSE,
              sessionDetails: {
                RecipientType: SESSION_RECIPIENT_TYPES.CLASS,
                ClassURN: selectedClassId,
                Type: SESSION_TYPES.PAUSE,
                Duration: pauseScreensDuration * 60 * 1000,
                Students: studentIds,
                TeacherName: teacherName,
              },
            });
          } else {
            // when turning off
            handleReleaseAll();
          }
        }}
        dataTestIdPrefix="hl-HighlightsSubNav-PauseScreen"
        className={styles.toggle}
      />
    </div>
  );
};

ToolbarPause.propTypes = {
  isPauseSession: PropTypes.bool.isRequired,
  isPauseScreensEnabled: PropTypes.bool,
  sessionList: PropTypes.arrayOf(GUIDE_BROWSING_SESSION_TYPE).isRequired,
  selectedClassId: PropTypes.string,
  pauseScreensDuration: PropTypes.number.isRequired,
  studentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  teacherName: PropTypes.string.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  updateNotificationSession: PropTypes.func.isRequired,
  loadCurrentSessions: PropTypes.func.isRequired,
  releaseSession: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isPauseSession: getIsPauseSession(state),
    isPauseScreensEnabled: getPauseScreensEnabled(state),
    sessionList: getActivePauseSessionList(state),
    selectedClassId: getClassId(state),
    pauseScreensDuration: getPauseScreensDuration(state),
    studentIds: getStudentsIdList(state),
    teacherName: getUserFamilyName(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
  }),
  (dispatch) => ({
    updateNotificationSession: ({ sessionDetails, sessionType }) =>
      dispatch(updateNotificationSession({ sessionDetails, sessionType })),
    loadCurrentSessions: ({ classId }) =>
      dispatch(loadCurrentSessions({ classId })),
    releaseSession: ({ sessionId }) => dispatch(releaseSession({ sessionId })),
  })
)(ToolbarPause);
