import React, { useState, useRef, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import ColourPicker from "../colourPicker/colourPicker";
import { intlGroupColourNames } from "../colourPicker/intlGroupColourNames";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "../../atomic/Button/Button";
import Input from "../../atomic/Input/Input";
import Tippy from "@tippy.js/react";
import styles from "./groupDetails.module.scss";
import StudentsList from "../studentsListBlock/studentsListBlock";
import SlidingGroupPanel, {
  INLINE_MESSAGE_TYPE,
} from "../slidingGroupPanel/slidingGroupPanel";
import StudentGroupParticipant from "../studentGroupParticipant/studentGroupParticipant";
import _ from "lodash";
import { focusSafelyByRef } from "../utils";

const GroupDetails = React.forwardRef(
  (
    {
      data,
      allStudents,
      placement,
      nameIndex,
      order,
      error,
      inlineMessage,
      members,
      blocked,
      onStudentDelete,
      updateGroup,
      onStudentsAdd,
      onActionBack,
      onClose,
      handleDeleteGroup = () => {},
      dialogLabelId,
    },
    ref
  ) => {
    const intl = useIntl();

    const [updatedTitle, setUpdatedTitle] = useState(
      data.name
        ? data.name
        : intl.formatMessage(
            {
              defaultMessage: "Group {nameIndex}",
              id: "VIfFas",
            },
            { nameIndex }
          )
    );

    const [previousTitle, setPreviousTitle] = useState(
      data.name
        ? data.name
        : intl.formatMessage(
            {
              defaultMessage: "Group {nameIndex}",
              id: "VIfFas",
            },
            { nameIndex }
          )
    );

    const [inlineEditMessage, setInlineEditMessage] = useState({});
    const [isTitleEditMode, setIsTitleEditMode] = useState(false);
    const [isTitleUpdateInProgress, setIsTitleUpdateInProgress] =
      useState(false);
    const isTitleSaveButtonDisabled =
      updatedTitle === previousTitle || isTitleUpdateInProgress;
    const [editColourMode, setEditColourMode] = useState(false);
    const [currentColour, setCurrentColor] = useState(data.colourCode);
    const noStudents = isEmpty(members);
    const [currentPanelContent, setCurrentPanelContent] = useState("");
    const [errorOccurred, setError] = useState(error);
    const [prevColour, setPrevColour] = useState(data.colourCode);
    const [deletingGroup, setDeletingGroup] = useState(false);
    const [deletingGroupInProcess, setDeletingGroupInProcess] = useState(false);

    // Handle closing of colour picker.
    const pickerWrapperRef = useRef(null);
    HandlePickerClose(pickerWrapperRef, editColourMode, setEditColourMode);

    const titleInputRef = useRef(null);
    const titleEditModeTriggerRef = useRef();
    const updateDelayRef = useRef();
    const deleteConfirmationContainerRef = useRef();
    const deleteButtonRef = useRef();
    const selectGroupColourButtonRef = useRef();

    // clean up delayed calls if any
    useEffect(() => {
      return () => {
        window.clearTimeout(updateDelayRef.current);
      };
    }, []);

    const showUpdateError = () => {
      setInlineEditMessage({
        text: intl.formatMessage({
          defaultMessage: "Error saving updates",
          id: "uH9p1b",
        }),
        type: INLINE_MESSAGE_TYPE.ERROR,
      });
    };

    const showUpdateSuccess = () => {
      setInlineEditMessage({
        icon: "circle-check-fill",
        text: intl.formatMessage({
          defaultMessage: "Updates saved",
          id: "F8P+7M",
        }),
        type: INLINE_MESSAGE_TYPE.SUCCESS,
      });

      window.clearTimeout(updateDelayRef.current);
      updateDelayRef.current = _.delay(() => {
        setInlineEditMessage({});
      }, 3000);
    };

    function getAmountStudentsString(group) {
      const num = group.emails ? group.emails.length : 0;
      return intl.formatMessage(
        {
          defaultMessage: "{num, plural, one {# Student} other {# Students}}",
          id: "MYUx0G",
        },
        { num }
      );
    }

    const handleColorUpdate = (colourCode) => {
      const colourChanged = currentColour !== colourCode;

      if (colourChanged) {
        setCurrentColor(colourCode);

        setInlineEditMessage({});
        updateGroup(colourCode, previousTitle, colourChanged, false)
          .then(() => {
            setPrevColour(currentColour);
            showUpdateSuccess();
          })
          .catch(() => {
            showUpdateError();
            setCurrentColor(prevColour);
          });
      }
    };

    const handleTitleUpdate = (title) => {
      const titleChanged = title !== previousTitle;

      // BE call to update the group
      if (titleChanged) {
        setInlineEditMessage({});
        setIsTitleUpdateInProgress(true);
        updateGroup(currentColour, title, false, titleChanged)
          .then(() => {
            setIsTitleUpdateInProgress(false);
            setPreviousTitle(title);
            handleTitleEditModeClose();
            showUpdateSuccess();
          })
          .catch(() => {
            setIsTitleUpdateInProgress(false);
            showUpdateError();
          });
      }
    };

    const toSortBy = order === "fname" ? "name" : "lastname";
    members = _.sortBy(members, toSortBy);

    // handling adding students to group
    function handleStudentsAdd(students) {
      onStudentsAdd(students)
        .then(() => setCurrentPanelContent(""))
        .catch(() => {
          setError({
            id: "updateGroup",
            text: intl.formatMessage({
              defaultMessage: "Sorry, there is a problem updating this group.",
              id: "4oLdgp",
            }),
          });
        });
    }

    // handling deleting student from group
    function handleStudentDelete(email, urn) {
      onStudentDelete(email, urn).catch(() => {
        setError({
          id: "updateGroup",
          text: intl.formatMessage({
            defaultMessage: "Sorry, there is a problem updating this group.",
            id: "4oLdgp",
          }),
        });
      });
    }

    // handles pressed Enter key in name input
    function handleTitleInputKeyDown(e) {
      if (e.keyCode === 13 || e.key === "Enter") {
        handleTitleUpdate(updatedTitle);
      }
    }

    const handleTitleEditModeClose = () => {
      setInlineEditMessage({});
      setIsTitleEditMode(false);
      focusSafelyByRef(titleEditModeTriggerRef);
    };

    const handleTitleEditModeOpen = () => {
      setInlineEditMessage({});
      setUpdatedTitle(previousTitle);
      setIsTitleEditMode(true);
      focusSafelyByRef(titleInputRef);
    };

    const studentsNotInGroup = !noStudents
      ? Object.values(allStudents).filter(
          (s) =>
            !Object.values(members).filter(
              (m) => _.get(m, "email") === _.get(s, "email")
            )[0]
        )
      : Object.values(allStudents);

    const allStudentsSelected =
      members.length === Object.keys(allStudents).length;
    const nullError = { id: null, text: "" };

    useEffect(() => {
      setError(error);
    }, [error]);

    if (currentPanelContent === "students") {
      return (
        <StudentsList
          data={studentsNotInGroup}
          onActionBack={() => setCurrentPanelContent("")}
          onClose={() => onClose()}
          placement={placement}
          order={order}
          errorOccurred={errorOccurred}
          onTryAgain={() => setError(nullError)}
          onStudentsAdd={handleStudentsAdd}
          ref={ref}
          dialogLabelId={dialogLabelId}
        />
      );
    }

    const AddButton = (
      <Button
        label={intl.formatMessage({
          defaultMessage: "Add",
          id: "2/2yg+",
        })}
        icon="plus"
        type={BUTTON_TYPES.PRIMARY}
        size={BUTTON_SIZES.SMALL}
        onAction={() => setCurrentPanelContent("students")}
        isDisabled={allStudentsSelected}
        data-test-id="td-GroupsManager-GroupItem-AddStudents"
      />
    );

    const selectedGroupColor = intl.formatMessage(
      intlGroupColourNames[currentColour]
    );

    return (
      <SlidingGroupPanel
        onActionBack={() => onActionBack()}
        onClose={() => onClose()}
        hasOverlay={true}
        placement={placement}
        blocked={blocked}
        withSpinner={true}
        errorOccurred={errorOccurred}
        inlineMessage={
          _.isEmpty(inlineEditMessage) ? inlineMessage : inlineEditMessage
        }
        ref={ref}
        onTryAgain={() => {
          const prevErr = errorOccurred;
          setError(nullError);

          // in case we couldn't create new group and have to go back to GroupsList
          if (prevErr.id === "createGroup") {
            onActionBack();
          }
        }}
      >
        <div className={styles.root}>
          <div className={styles.header}>
            <h1 id={dialogLabelId} className={styles.headerTitle}>
              <FormattedMessage defaultMessage="Edit group" id="Z/1udv" />
            </h1>
            <button
              className={styles.itemColor}
              onClick={() => setEditColourMode(!editColourMode)}
              aria-label={intl.formatMessage(
                {
                  defaultMessage: "Selected group colour: {colour}",
                  id: "QO3OE9",
                },
                { colour: selectedGroupColor }
              )}
              aria-expanded={editColourMode}
              ref={selectGroupColourButtonRef}
              data-test-id="td-GroupsManager-GroupItem-SelectGroupColour"
            >
              <div
                className={classnames(styles.colorCode, styles[currentColour])}
              />
              <div className={styles.square} />
            </button>
            <div
              className={classnames(styles.colourPicker, {
                [styles.visible]: editColourMode,
              })}
              ref={pickerWrapperRef}
            >
              <ColourPicker
                selectedColour={currentColour}
                handleSelectColour={(colourCode) => {
                  setEditColourMode(false);
                  handleColorUpdate(colourCode);
                  focusSafelyByRef(selectGroupColourButtonRef);
                }}
              />
            </div>

            <div className={styles.itemTitleEdit}>
              {!isTitleEditMode && (
                <Button
                  label={previousTitle}
                  aria-label={intl.formatMessage(
                    {
                      defaultMessage: "{updatedTitle} - edit group name",
                      id: "2h9EkV",
                    },
                    { updatedTitle }
                  )}
                  isFullWidth={true}
                  rightIcon="pencil"
                  type={BUTTON_TYPES.TERTIARY}
                  className={styles.editTitleTrigger}
                  onClick={handleTitleEditModeOpen}
                  data-test-id="td-GroupsManager-GroupItem-EditTitleTrigger"
                  ref={titleEditModeTriggerRef}
                />
              )}
              {isTitleEditMode && (
                <div className={styles.editTitleContainer}>
                  <Input
                    value={updatedTitle}
                    onChange={(e) =>
                      setUpdatedTitle(_.get(e, "target.value", ""))
                    }
                    onKeyDown={(e) => {
                      handleTitleInputKeyDown(e);
                    }}
                    className={styles.editTitleSaveInput}
                    data-test-id="td-GroupsManager-GroupItem-EditTitleInput"
                    ref={titleInputRef}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Edit group name",
                      id: "1Aa8eA",
                    })}
                  />
                  <Button
                    aria-label={intl.formatMessage({
                      defaultMessage: "Save group name",
                      id: "oVsEvQ",
                    })}
                    icon="check"
                    type={BUTTON_TYPES.PRIMARY}
                    size={BUTTON_SIZES.SMALL}
                    onClick={() => {
                      handleTitleUpdate(updatedTitle);
                    }}
                    data-test-id="td-GroupsManager-GroupItem-EditTitleSave"
                    isDisabled={isTitleSaveButtonDisabled}
                    isLoading={isTitleUpdateInProgress}
                  />
                  <Button
                    aria-label={intl.formatMessage({
                      defaultMessage: "Cancel group name edit",
                      id: "yl00DS",
                    })}
                    icon="cross"
                    type={BUTTON_TYPES.SECONDARY}
                    size={BUTTON_SIZES.SMALL}
                    onClick={handleTitleEditModeClose}
                    data-test-id="td-GroupsManager-GroupItem-EditTitleCancel"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.subheader}>
            <div className={styles.amount}>{getAmountStudentsString(data)}</div>
            <div className={styles.button}>
              {allStudentsSelected && (
                <Tippy
                  content={intl.formatMessage({
                    defaultMessage:
                      "The whole class is added to this group, you cannot add more students.",
                    id: "K2uCMe",
                  })}
                  maxWidth={150}
                  theme="hsuite"
                >
                  <span>{AddButton}</span>
                </Tippy>
              )}
              {!allStudentsSelected && AddButton}
            </div>
          </div>
          <div>
            {data &&
              !noStudents &&
              _.map(
                _.filter(members, (m) => !_.isEmpty(m)),
                (member) => {
                  return (
                    <StudentGroupParticipant
                      data={member}
                      key={member.email}
                      canDelete={true}
                      order={order}
                      errorOccurred={errorOccurred}
                      onTryAgain={() => setError(nullError)}
                      onStudentDelete={(member) =>
                        handleStudentDelete(member.email, data.urn)
                      }
                    />
                  );
                }
              )}
          </div>
          <div>
            {noStudents && (
              <div className={styles.noStudents}>
                <FormattedMessage
                  defaultMessage="There are no students in this group yet."
                  id="gMzh26"
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={classnames(styles.footer, {
            [styles.footerDeletingGroup]: deletingGroup,
          })}
          ref={deleteConfirmationContainerRef}
          tabIndex={-1}
        >
          {!deletingGroup && (
            <div className={styles.deleteButton}>
              <Button
                dataTestId="delete-group-button"
                label={intl.formatMessage({
                  defaultMessage: "Delete group",
                  id: "BpuMwR",
                })}
                type={BUTTON_TYPES.DANGER}
                size={BUTTON_SIZES.REGULAR}
                onAction={() => {
                  setDeletingGroup(true);
                  focusSafelyByRef(deleteConfirmationContainerRef);
                }}
                ref={deleteButtonRef}
              />
            </div>
          )}

          {deletingGroup && (
            <React.Fragment>
              {deletingGroupInProcess && <div className={styles.blocked} />}
              <div className={styles.label}>
                <FormattedMessage
                  defaultMessage="Delete this group?"
                  id="S0cLdM"
                />
              </div>
              <div className={styles.buttons}>
                <Button
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  size={BUTTON_SIZES.REGULAR}
                  label={intl.formatMessage({
                    defaultMessage: "No",
                    id: "oUWADl",
                  })}
                  onAction={() => {
                    setDeletingGroup(false);
                    focusSafelyByRef(deleteButtonRef);
                  }}
                  data-test-id="td-GroupsManager-GroupItem-ButtonCancelDelete"
                  className={styles.buttonNo}
                />
                <Button
                  type={BUTTON_TYPES.DANGER}
                  size={BUTTON_SIZES.REGULAR}
                  label={
                    !deletingGroupInProcess
                      ? intl.formatMessage({
                          defaultMessage: "Delete",
                          id: "K3r6DQ",
                        })
                      : ""
                  }
                  isLoading={deletingGroupInProcess}
                  onAction={() => {
                    setDeletingGroupInProcess(true);
                    handleDeleteGroup(data);
                  }}
                  dataTestId="td-GroupsManager-GroupItem-ButtonConfirmDelete"
                  className={styles.buttonDelete}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </SlidingGroupPanel>
    );
  }
);

function HandlePickerClose(
  pickerWrapperRef,
  editColourMode,
  setEditColourMode
) {
  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerWrapperRef.current &&
        !pickerWrapperRef.current.contains(event.target) &&
        editColourMode
      ) {
        setEditColourMode(!editColourMode);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [editColourMode, pickerWrapperRef, setEditColourMode]);
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

GroupDetails.propTypes = {
  data: PropTypes.object,
  allStudents: PropTypes.object,
  placement: PropTypes.string.isRequired,
  nameIndex: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  error: PropTypes.object,
  members: PropTypes.object.isRequired,
  blocked: PropTypes.bool,
  onStudentDelete: PropTypes.func,
  updateGroup: PropTypes.func,
  onActionBack: PropTypes.func,
  onClose: PropTypes.func,
  onStudentsAdd: PropTypes.func,
  handleDeleteGroup: PropTypes.func,
  dialogLabelId: PropTypes.string,
  inlineMessage: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(_.values(INLINE_MESSAGE_TYPE)),
  }),
};

export default GroupDetails;
