import { FormattedMessage } from "react-intl";
import React from "react";
import { connect } from "react-redux";

import styles from "./GradingWidgetAssessed.module.scss";

import { MYWORKSPACES_GRADING_ARTIFACT_TYPE } from "../../../../../state/workspace/grading/types";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import classnames from "classnames";
import { returnAssessed } from "../../../../../state/workspace/grading/actions";
import {
  getGradingArtifact,
  getInProgress,
} from "../../../../../state/workspace/grading/selectors";
import PropTypes from "prop-types";
import { getFFByName } from "../../../../../state/app/selectors";

export const GradingWidgetAssessed = ({
  artifact,
  isActionInProgress = true,
  returnAssessed,
  isAssessedReturnForEditOn,
}) => {
  if (!artifact) return null;

  return (
    <div className={styles.root} data-test-id="Ws-GradingWidget-Assessed">
      <div className={styles.body}>
        {!artifact.Grade && !artifact.Feedback && (
          <div className={styles.empty}>
            <FormattedMessage
              defaultMessage="Work has been returned."
              id="cfZ2za"
            />
          </div>
        )}
        {artifact.Grade && (
          <div className={styles.grade}>
            Grade:
            <div className={styles.field}>{artifact.Grade}</div>
          </div>
        )}
        {artifact.Grade && artifact.Feedback && (
          <div className={styles.divider} />
        )}
        {artifact.Feedback && (
          <div className={styles.feedback}>
            Feedback:
            <div className={styles.field}>{artifact.Feedback}</div>
          </div>
        )}
      </div>
      {isAssessedReturnForEditOn && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.REGULAR}
          onAction={() => {
            returnAssessed({ grade: artifact.Grade, feedback: "" });
          }}
          label="Return for edit"
          icon="refresh"
          dataTestId="ws-Grading-ReturnForEdit"
          className={classnames(styles.fullWidth, styles.marginTop)}
          isLoading={isActionInProgress}
          isDisabled={isActionInProgress}
        />
      )}
    </div>
  );
};

GradingWidgetAssessed.propTypes = {
  artifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  isActionInProgress: PropTypes.bool.isRequired,
  returnAssessed: PropTypes.func.isRequired,
  isAssessedReturnForEditOn: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    artifact: getGradingArtifact(state),
    isActionInProgress: getInProgress(state),
    isAssessedReturnForEditOn: getFFByName("HAP-7144-Return-Assessed-Artifact")(
      state
    ),
  }),
  (dispatch) => ({
    returnAssessed: (options) => dispatch(returnAssessed(options)),
  })
)(GradingWidgetAssessed);
