import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./GradingAssigneesLoop.module.scss";
import buttonStyles from "@hapara/ui/src/atomic/Button/Button.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

import {
  getAssigneesOrdered,
  getGradingSelectedArtefactId,
  getGradingCardId,
  getGradingActivity,
} from "../../../../../state/workspace/grading/selectors";
import { MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE } from "../../../../../state/workspace/grading/types";
import { APP_PAGES } from "../../../../../state/router/types";

export const GradingAssigneesLoop = ({
  selectedArtefactId,
  cardId,
  activityType,
  assigneesList = [],
}) => {
  const getPreviousArtefactId = () => {
    const ind = _.findIndex(assigneesList, { ArtifactID: selectedArtefactId });
    const prevInd = ind === 0 ? assigneesList.length - 1 : ind - 1;
    return assigneesList[prevInd] ? assigneesList[prevInd].ArtifactID : null;
  };

  const getNextArtefactId = () => {
    const ind = _.findIndex(assigneesList, { ArtifactID: selectedArtefactId });
    const nextInd = ind === assigneesList.length - 1 ? 0 : ind + 1;
    return assigneesList[nextInd] ? assigneesList[nextInd].ArtifactID : null;
  };

  return (
    <div className={styles.root}>
      {assigneesList.length > 1 && (
        <div className={styles.buttonsContainer}>
          <Link
            to={{
              pathname: `${APP_PAGES.WORKSPACE_GRADING.path}/${cardId}/${activityType}`,
              search: `?artefact=${getPreviousArtefactId()}`,
              state: { from: APP_PAGES.WORKSPACE_MY_WORKSPACE.path },
            }}
            replace
            className={classnames(
              buttonStyles.root,
              buttonStyles.tertiary,
              buttonStyles.solid,
              buttonStyles.regular,
              styles.navButtons
            )}
            data-test-id="ws-Grading-DocumentNav-SelectPrevious"
            aria-label={`Previous ${activityType} document`}
          >
            <HapReactIcon
              svg="arrow-angle-left"
              width={16}
              height={16}
              alt=""
            />
          </Link>
          <Link
            to={{
              pathname: `${APP_PAGES.WORKSPACE_GRADING.path}/${cardId}/${activityType}`,
              search: `?artefact=${getNextArtefactId()}`,
              state: { from: APP_PAGES.WORKSPACE_MY_WORKSPACE.path },
            }}
            replace
            className={classnames(
              buttonStyles.root,
              buttonStyles.tertiary,
              buttonStyles.solid,
              buttonStyles.regular,
              styles.navButtons
            )}
            data-test-id="ws-Grading-DocumentNav-SelectNext"
            aria-label={`Next ${activityType} document`}
          >
            <HapReactIcon
              svg="arrow-angle-right"
              width={16}
              height={16}
              alt=""
            />
          </Link>
        </div>
      )}
    </div>
  );
};

GradingAssigneesLoop.propTypes = {
  selectedArtefactId: PropTypes.string,
  cardId: PropTypes.string,
  activityType: PropTypes.string,
  assigneesList: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE,
};

export default connect((state) => ({
  assigneesList: getAssigneesOrdered(state),
  selectedArtefactId: getGradingSelectedArtefactId(state),
  cardId: getGradingCardId(state),
  activityType: getGradingActivity(state),
}))(GradingAssigneesLoop);
