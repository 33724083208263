// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Unknown = any;
type ChromeEventListener = (data: Unknown) => void;

export const MESSAGE_TYPE = "hapara-chrome-event-emitter";

type IsUndefinedCheck<T> = undefined extends T ? true : false;

const isExtensionContext = () => window.chrome?.extension !== undefined;

/**
 * Provides a unified event emitter for JavaScript environments and Chrome
 * extensions. In Chrome extensions, it enables different parts, such as
 * background scripts and popup scripts, to communicate with each other across
 * isolated contexts as if they were in the same context.
 * */
export class ChromeEventEmitter<L extends Record<string, ChromeEventListener>> {
  private events: { [K in keyof L]: L[K][] };

  constructor() {
    this.events = {} as { [K in keyof L]: L[K][] };

    if (isExtensionContext()) {
      window.chrome?.runtime?.onMessage?.addListener?.((message) => {
        if (message.type === MESSAGE_TYPE) {
          this.chromeEmit(message.event as keyof L, message.data);
        }
      });
    }
  }

  public on<K extends keyof L>(event: K, listener: L[K]): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  public emit<K extends keyof L & string>(
    // conditional type that makes data optional if listener doesn't accept params
    ...args: IsUndefinedCheck<Parameters<L[K]>[0]> extends true
      ? [event: K] | [event: K, data: Parameters<L[K]>[0]]
      : [event: K, data: Parameters<L[K]>[0]]
  ): void {
    const event = args[0];
    const data = args.length > 1 ? args[1] : undefined;

    if (isExtensionContext()) {
      window.chrome?.runtime?.sendMessage?.({
        type: MESSAGE_TYPE,
        event,
        data,
      });
    } else {
      this.chromeEmit(event, data);
    }
  }

  private chromeEmit<K extends keyof L>(
    event: K,
    data: Parameters<L[K]>[0]
  ): void {
    const listeners = this.events[event];
    if (listeners) {
      listeners.forEach((listener) => listener(data));
    }
  }

  public off<K extends keyof L>(event: K, listenerToRemove: L[K]): void {
    if (!this.events[event]) return;

    const index = this.events[event].indexOf(listenerToRemove);
    if (index !== -1) {
      this.events[event].splice(index, 1);
    }
  }
}
