import actionTypes from "../../../actionTypes";
import { MYWORKSPACES_SEARCH_PAGE_SIZE } from "./types";

export const INIT_STATE = {
  items: null,
  totalItems: 0,
  loading: false,
  pagesCount: 0,
  pageSize: MYWORKSPACES_SEARCH_PAGE_SIZE,
  searchGuid: null,
  searchIteration: 0,
};

const searchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.MYWORKSPACES_SEARCH_LOAD_SUCCESS:
      const { items, totalItems, pagesCount } = action.payload;
      return {
        ...state,
        loading: false,
        items,
        totalItems,
        pagesCount,
      };

    case actionTypes.MYWORKSPACES_SEARCH_CLEAR:
      return INIT_STATE;

    case actionTypes.MYWORKSPACES_SEARCH_LOAD_PENDING:
      return { ...state, loading: true };

    case actionTypes.MYWORKSPACES_SEARCH_GUID_UPDATE:
      return {
        ...state,
        searchGuid: action.searchGuid,
        searchIteration: action.iterCount,
      };

    case actionTypes.MYWORKSPACES_SEARCH_LOAD_ERROR:
      return { ...state, loading: false, items: [] };

    default:
      return state;
  }
};

export default searchReducer;
