//LIBRARIES
import { useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import { useIntl } from "react-intl";

//COMPONENTS
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";

//SELECTORS
import { getDocTypeFilter } from "../../../../state/shared/selectors";
import { getWindowSize } from "../../../../state/app/selectors";
import { getFFByName } from "src/state/app/selectors";

//TYPES
import { WINDOW_SIZE_TYPES } from "../../../../state/app/types";
import { DrivePageParamTypes } from "../../../containers/Dashboard/DashboardPage/types";
import {
  DOC_FILTERS,
  EDUBLOGS_FILTER,
  ALL_DOCS_FILTER,
} from "../../../../state/highlights/drive/types";

//UTILS | OTHER
import {
  updateURLParams,
  updateURLDocType,
} from "../../../../state/shared/navigation";
import styles from "./DriveDocTypeFilter.module.scss";

interface DriveDocTypeFilterProps {
  pageParams: DrivePageParamTypes | undefined;
  classIdParams: string | undefined;
  dataTestIdPrefix: string;
}

const DriveDocTypeFilter = ({
  pageParams,
  classIdParams,
  dataTestIdPrefix,
}: DriveDocTypeFilterProps) => {
  const intl = useIntl();
  const hasUpdatedDriveFilter = useSelector(
    getFFByName("HAP-11775-highlights-drive-filter-update")
  );
  const triggerButtonRef = useRef<HTMLElement>();
  const windowSize = useSelector(getWindowSize);
  const selectedDriveDocType = useSelector(getDocTypeFilter);
  const isDriveFilter = classIdParams && pageParams?.[0] === "docType";
  const isEdublogsFilter = classIdParams && pageParams?.[0] === "edublogsType";
  const ariaLabelTrigger = isEdublogsFilter
    ? "Edublogs filter"
    : "Filter documents by type";
  const activeContentFilter =
    isDriveFilter || isEdublogsFilter ? pageParams?.[1] : selectedDriveDocType;

  const menuItems = isEdublogsFilter ? EDUBLOGS_FILTER : DOC_FILTERS;
  const oldMenuItems = isDriveFilter
    ? DOC_FILTERS
    : isEdublogsFilter
    ? EDUBLOGS_FILTER
    : DOC_FILTERS.slice(1);

  const handleApplyFilter = (filterType: string) => {
    isDriveFilter || isEdublogsFilter
      ? updateURLDocType({ currentPage: filterType, classId: classIdParams })
      : updateURLParams({
          docType: filterType,
        });
  };

  const oldLabel = oldMenuItems.find(
    (t) => t.key === activeContentFilter
  )?.title;
  const newLabel = menuItems.find((t) => t.key === activeContentFilter)?.title;

  return (
    <div className={styles.root}>
      <Dropdown
        positionType={
          windowSize >= WINDOW_SIZE_TYPES.S
            ? DROPDOWN_CONTENT_POSITION_TYPES.RIGHT
            : DROPDOWN_CONTENT_POSITION_TYPES.LEFT
        }
        classNameContent={styles.dropdownList}
        triggerComponent={(props) => {
          const label = hasUpdatedDriveFilter
            ? (newLabel && intl.formatMessage(newLabel)) ||
              intl.formatMessage(DOC_FILTERS[0].title)
            : (oldLabel && intl.formatMessage(oldLabel)) ||
              intl.formatMessage(ALL_DOCS_FILTER.title);

          return (
            <Button
              label={label}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.LARGE}
              rightIcon="arrow-carvet-down"
              dataTestId={`${dataTestIdPrefix}-Toolbar-DocsTypeFilter-TriggerButton`}
              aria-label={ariaLabelTrigger}
              ref={triggerButtonRef}
              className={styles.filterTrigger}
              {...props}
            />
          );
        }}
        itemComponentList={_.map(
          hasUpdatedDriveFilter ? menuItems : oldMenuItems,
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.key === activeContentFilter;
              return (
                <button
                  type="button"
                  className={classnames(className, styles.dropdownContentItem, {
                    [styles.dropdownContentItemSelected]: isSelected,
                  })}
                  data-test-id={`${dataTestIdPrefix}-Toolbar-DocsTypeFilter-DropdownOption-${_.toLower(
                    item.key
                  )}`}
                  onClick={() => {
                    handleApplyFilter(item.key);
                    onClick();
                    _.delay(() => triggerButtonRef.current?.focus(), 100);
                  }}
                  key={item.key}
                  aria-label={`${item.title}${isSelected ? ", selected" : ""}`}
                  {...rest}
                >
                  {intl.formatMessage(item.title)}
                  <p className={styles.dropdownItemDesc}>
                    {intl.formatMessage(item.desc)}
                  </p>
                </button>
              );
            }
        )}
      />
    </div>
  );
};

export default DriveDocTypeFilter;
