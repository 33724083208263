import _ from "lodash";
import actionTypes from "../../actionTypes";
import {
  STUDENT_ITEMS_PER_TILE_DEFAULT_VALUE,
  STUDENT_ORDER_TYPES,
  STUDENT_TILES_SIZE_TYPES,
  STUDENT_TILE_CONTENT_TYPE,
} from "./types";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
} from "../../storeUtils";

const INIT_STATE = {
  ...DATA_LOADING_STATE_INIT,
  isOutsideSchoolHours: false,
  classConfig: {},
  studentsOrder: {
    type: STUDENT_ORDER_TYPES.FIRST_AZ,
    customOrder: [],
  },
  itemsPerTile: STUDENT_ITEMS_PER_TILE_DEFAULT_VALUE,
  tileSize: STUDENT_TILES_SIZE_TYPES.REGULAR,
  activeViewType: STUDENT_TILE_CONTENT_TYPE.SCREENS,
};

const configReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
        // this is intentionally different from default
        // as we load a new config not ones but on every class change
        // and need to show the loading state
        isLoaded: false,
      };
    }

    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS: {
      // get the students info out as we keep it separately
      const { Students, SortOrder, TabsNumber, TileSize, ...rest } =
        action.payload;

      const studentsOrder = { ...INIT_STATE.studentsOrder };
      const orderTypes = _.values(STUDENT_ORDER_TYPES);

      if (_.indexOf(orderTypes, SortOrder) !== -1) {
        studentsOrder.type = SortOrder;

        if (SortOrder === STUDENT_ORDER_TYPES.CUSTOM) {
          studentsOrder.customOrder = _.map(Students, (student) =>
            _.get(student, "Id", "")
          );
        }
      }

      return {
        ...state,
        classConfig: { ...rest },
        studentsOrder,
        itemsPerTile: TabsNumber,
        tileSize: TileSize,
        ...DATA_LOADING_STATE_SUCCESS,
      };
    }

    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_ERROR: {
      return {
        ...state,
        ...DATA_LOADING_STATE_ERROR,
      };
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENTS_ORDER: {
      const { type, customOrder } = action.payload;

      return {
        ...state,
        studentsOrder: { type, customOrder },
      };
    }

    case actionTypes.HIGHLIGHTS_UPDATE_ITEMS_PER_TILE_NUMBER: {
      const { itemsNumber } = action.payload;

      return {
        ...state,
        itemsPerTile: itemsNumber,
      };
    }

    case actionTypes.HIGHLIGHTS_UPDATE_OUTSIDE_SCHOOL_HOURS: {
      return {
        ...state,
        isOutsideSchoolHours: action.payload,
      };
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_TILES_SIZE: {
      const { tileSize } = action.payload;

      return {
        ...state,
        tileSize: tileSize,
      };
    }

    case actionTypes.HIGHLIGHTS_UPDATE_ACTIVE_VIEW_TYPE: {
      return {
        ...state,
        activeViewType: action.payload,
      };
    }

    default:
      return state;
  }
};

export default configReducer;
