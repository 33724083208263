import React from "react";
import { Helmet } from "react-helmet";
import styles from "./ProfessionalLearningPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import PLWorkspacesList from "../../../partial/Workspace/PL/PLWorkspacesList/PLWorkspacesList";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";

const ProfessionalLearningPage = () => {
  return (
    <div
      className={styles.root}
      data-test-id="Hs-Pages-ProfessionalLearningPage"
    >
      <Helmet>
        <title>{APP_PAGES.PROFESSIONAL_LEARNING.title}</title>
      </Helmet>

      <HeaderAccountOnly rootPageLink={APP_PAGES.PROFESSIONAL_LEARNING.path} />

      <div className={styles.content}>
        <PLWorkspacesList />
      </div>
    </div>
  );
};

export default ProfessionalLearningPage;
