import { User } from "../types/User";

const USER_KEY = "user";

export const retrieveLocalUser = (): User => {
  const user = localStorage.getItem(USER_KEY);
  return user ? JSON.parse(user) : null;
};

export const storeLocalUser = (user: User) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const redirectToLoginPage = () => {};
