import React, { useRef, useState } from "react";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";
import { useOverflow } from "@hapara/ui/src/hooks/useOverflow";
import styles from "./WorkspaceTitle.module.scss";
import classnames from "classnames";

interface WorkspaceTitleProps {
  title?: string;
  description?: string;
}

export const WorkspaceTitle = ({ title, description }: WorkspaceTitleProps) => {
  const [expanded, setExpanded] = useState(false); //Description display status
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOverflowing: overflow } = useOverflow(containerRef); //Checks description length
  const descriptionType = overflow ? "multiLine" : "singleLine"; //Sets text alignment
  const descriptionState = !expanded ? "clamped" : "unclamped"; //Applies line clamping to multiLines
  const buttonText = !expanded ? "more" : "less";

  return (
    <div className={styles.root}>
      <div
        className={styles.titleBlock}
        data-test-id="WorkspaceTitle-MainPageTitle"
      >
        <h1 className={styles.title}> {stripHtmlTagsFromString(title)} </h1>
        {description && (
          <p
            ref={containerRef}
            className={classnames(
              styles.description,
              styles[descriptionType],
              styles[descriptionState]
            )}
          >
            <span className={styles.visuallyHidden}>
              {" "}
              Workspace Description:{" "}
            </span>
            {stripHtmlTagsFromString(description)}
          </p>
        )}
        {overflow && (
          <button
            className={styles.btnExpand}
            onClick={() => setExpanded(!expanded)}
          >
            Show {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkspaceTitle;
