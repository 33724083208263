import PropTypes from "prop-types";
import { STUDENT_TYPE } from "../students/types";

export const ACTIVITY_DETAILS_STUDENTS = PropTypes.shape({
  current: PropTypes.arrayOf(STUDENT_TYPE),
  previous: PropTypes.arrayOf(STUDENT_TYPE),
  never: PropTypes.arrayOf(STUDENT_TYPE),
});

export const ACTIVITY_DETAILS_TYPE = PropTypes.shape({
  icon: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  current: PropTypes.number,
  previous: PropTypes.number,
  total: PropTypes.number,
  studentInfos: ACTIVITY_DETAILS_STUDENTS,
});

export const UNIQUE_ACTIVITY_TYPE = PropTypes.shape({
  id: PropTypes.number,
  icon: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  instanceId: PropTypes.string,
  studentInfos: ACTIVITY_DETAILS_STUDENTS,
});

export const SNAPSHOT_TYPES = {
  URL: "Url",
  DOMAIN: "Domain",
};
