import actionTypes from "../../actionTypes";

const INIT_STATE = {
  session: null,
  data: {},
};

const notificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_NOTIFICATION_SESSION_UPDATE: {
      const { sessionDetails, sessionType } = action.payload;

      return {
        ...state,
        session: sessionType,
        data: sessionDetails,
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
