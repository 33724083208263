import React from "react";
import { useEffect, useRef } from "react";
import styles from "./ChatMessages.module.scss";

type ChatMessagesProps = {
  children: React.ReactNode;
};

export const ChatMessages = ({ children }: ChatMessagesProps) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView();
  }, [children]);

  return (
    <div className={styles.container}>
      <div className={styles.messages}>
        {children}
        <div ref={endOfMessagesRef} />
      </div>
    </div>
  );
};
