import _ from "lodash";
import { createSelector } from "reselect";

const getCreate = (state) => _.get(state, "workspace.create");

export const getCreateWorkspaceInitLoading = createSelector(
  [getCreate],
  (create) => _.get(create, "isInitLoading")
);

export const getCreateWorkspaceInitLoaded = createSelector(
  [getCreate],
  (create) => _.get(create, "isInitLoaded")
);

export const getCreateWorkspaceInitId = createSelector([getCreate], (create) =>
  _.get(create, "init.Id", null)
);

export const getCreateWorkspaceLoading = createSelector([getCreate], (create) =>
  _.get(create, "isCreateLoading")
);

export const getCreateWorkspaceLoaded = createSelector([getCreate], (create) =>
  _.get(create, "isCreateLoaded")
);

export const getCreateWorkspaceError = createSelector([getCreate], (create) =>
  _.get(create, "isCreateError")
);
