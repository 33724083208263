import React, { useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import styles from "./PageRightPanelModal.module.scss";
import {
  FocusContext,
  focusSafelyByRef,
} from "@hapara/ui/src/components/utils";
import ReactModal from "react-modal";

const rootId = "#root";

export const PageRightPanelModal = ({
  isOpen,
  onClose = () => {},
  children,
  dataTestIdPrefix = "hs-RightPanelModal",
  landmarkLabel,
  closeButtonLabel = "Close",
  focusableElementId,
}) => {
  const focusContext = useContext(FocusContext);
  const sidePanelRef = useRef();
  const focusDelayId = useRef();

  useEffect(() => {
    if (isOpen) {
      focusDelayId.current = focusSafelyByRef(sidePanelRef);
    }
  }, [isOpen]);

  useEffect(() => {
    if (focusDelayId.current) {
      window.clearTimeout(focusDelayId.current);
    }
  }, []);

  const handleClose = () => {
    onClose();
    focusContext.restoreFocus();
  };

  ReactModal.setAppElement(rootId);

  return (
    isOpen && (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className={classnames(styles.root)}
        overlayClassName={styles.overlay}
        contentLabel={landmarkLabel}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        aria={{
          modal: true,
        }}
        data={{ "test-id": dataTestIdPrefix }}
        shouldFocusAfterRender={true}
      >
        <div className={styles.contentLeft} aria-hidden={!isOpen}>
          <div
            className={styles.contentLeftInner}
            ref={sidePanelRef}
            tabIndex={-1}
            id={focusableElementId}
          >
            <div className={styles.controlsTop}>
              <Button
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.SMALL}
                icon="cross"
                onAction={handleClose}
                data-test-id={`${dataTestIdPrefix}-Button-Close`}
                className={styles.closeButton}
                aria-label={closeButtonLabel}
              />
            </div>
            <div className={styles.scrollablePart}>
              <div className={styles.inner}>{children}</div>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  );
};

PageRightPanelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string,
  landmarkLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  focusableElementId: PropTypes.string,
};

export default connect((state) => ({}))(PageRightPanelModal);
