import React from "react";
import useScrollToHook from "../../../../../hooks/useScrollToHook";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./WorkspaceProgressCard.module.scss";
import {
  wsCardType,
  MYWORKSPACES_RIGHT_PANEL_SCROLLABLE_CONTENT_ID,
  EVIDENCE_CATEGORIES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import * as utils from "../../../../../utils";
import WorkspaceProgressCardSection from "../WorkspaceProgressCardSection/WorkspaceProgressCardSection";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";

const getDueDate = (item) => {
  const list = _.get(item, "SubmissionDueDates");
  if (_.isEmpty(list)) return null;

  const date = _.get(list[0], "Time");
  if (_.isEmpty(date)) return null;

  return utils.formatDate(date);
};

export const WorkspaceProgressCard = ({ item, isActive }) => {
  const dueDate = getDueDate(item);
  const scrollableContent = document.getElementById(
    MYWORKSPACES_RIGHT_PANEL_SCROLLABLE_CONTENT_ID
  );
  const { containerRef, topRef } = useScrollToHook(isActive, scrollableContent);

  return (
    <div
      className={styles.root}
      data-test-id="ws-MyWorkspaces-ProgressPanel-Card"
      ref={containerRef}
    >
      <div
        className={styles.type}
        data-test-id="ws-MyWorkspaces-ProgressPanel-Card-Type"
      >
        {_.get(item, "IsGroupWork", false)
          ? "Group evidence"
          : "Individual evidence"}
      </div>
      <h3
        className={styles.title}
        data-test-id="ws-MyWorkspaces-ProgressPanel-Card-Title"
        ref={topRef}
      >
        {stripHtmlTagsFromString(_.get(item, "Title"))}
      </h3>
      {dueDate && (
        <div
          className={styles.dueDate}
          data-test-id="ws-MyWorkspaces-ProgressPanel-Card-DueDate"
        >
          Due: {dueDate}
        </div>
      )}

      <div className={styles.states}>
        {_.map(EVIDENCE_CATEGORIES, (category) => (
          <WorkspaceProgressCardSection
            key={category.id}
            card={item}
            id={category.id}
            name={category.name}
            activityType={category.activityType}
            isActive={isActive}
          />
        ))}
      </div>
    </div>
  );
};

WorkspaceProgressCard.propTypes = {
  item: wsCardType,
  isActive: PropTypes.bool,
};

export default WorkspaceProgressCard;
