import React from "react";

import classnames from "classnames";

import styles from "./ShareDocItem.module.scss";
import HapReactIcon from "../../../../atomic/icon/hapReactIcon";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "../../../../atomic/Button/Button";
import { getDocumentIconByMimeType, getPlural } from "../../../utils";
import { shareItemDocType } from "../types";
import ShareStudentItem from "./ShareStudentItem";
import Reveal, {
  RevealDetails,
  RevealToggle,
} from "../../../../atomic/Reveal/Reveal";

export const getStudentsByStatus = (students, status) => {
  return _.orderBy(_.filter(students, { Status: status }), ["Student"]);
};

export const ShareDocItem = ({ data = {} }) => {
  const isExecuting =
    getStudentsByStatus(data.Students, "in-progress").length > 0;

  const isCompleted =
    getStudentsByStatus(data.Students, "completed").length ===
    data.Students.length;

  const errors = getStudentsByStatus(data.Students, "error") || [];

  const isUnsharing = data.status.indexOf("undo") > -1;

  const icon = isExecuting
    ? "loader"
    : isCompleted
    ? "circle-check-fill"
    : errors.length < data.Students.length
    ? "circle-checkmark"
    : "fill-circle-exclamation";

  return (
    <div className={styles.root} data-test-id="td-SmartShare-DocItem">
      <Reveal>
        <div className={styles.header}>
          <div className={styles.mimetype}>
            <HapReactIcon
              svg={getDocumentIconByMimeType(data.MimeType)}
              width={16}
              height={16}
              alt=""
            />
          </div>
          <div
            className={classnames(styles.title, {
              [styles.unshared]: isUnsharing,
            })}
          >
            {data.DocumentTitle}
          </div>

          {errors.length > 0 && (
            <RevealToggle
              component={Button}
              label={getPlural(errors.length, "error")}
              rightIcon={(isExpanded) =>
                `arrow-carvet-${isExpanded ? "up" : "down"}`
              }
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              className={styles.redButton}
            />
          )}
          <div
            className={classnames(styles.icon, {
              [styles.spinning]: isExecuting,
              [styles.completed]:
                !isExecuting &&
                (isCompleted || errors.length < data.Students.length),
              [styles.terminated]:
                !isExecuting && errors.length === data.Students.length,
            })}
          >
            <HapReactIcon svg={icon} width={24} height={24} />
          </div>
        </div>
        <RevealDetails>
          <div className={styles.body}>
            <h3>{`Problem ${
              isUnsharing ? "unsharing from" : "sharing to"
            } ${getPlural(errors.length, "student")}:`}</h3>
            {_.map(errors, (student) => (
              <ShareStudentItem
                data={student}
                unshared={isUnsharing}
                key={student.Student}
              />
            ))}
          </div>
        </RevealDetails>
      </Reveal>
    </div>
  );
};

ShareDocItem.propTypes = {
  data: shareItemDocType,
};

export default ShareDocItem;
