import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import React, { useState, useMemo } from "react";
import styles from "./SmartshareDialog.module.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ActionDialog from "../../../../atomic/ActionDialog/ActionDialog";
import RecipientPicker, {
  SHARE_RECIPIENT_CLASSES,
  SHARE_RECIPIENT_GROUPS,
  SHARE_RECIPIENT_STUDENTS,
} from "./RecipientPicker";
import _ from "lodash";
import Checkbox from "../../../../atomic/Checkbox/Checkbox";
import { postRunSmartshare } from "../../../../apiCalls/smartshare";
import FormBlock from "../../../FormBlock/FormBlock";
import EmptyState from "../../../FormBlock/EmptyState";
import FilesTable from "./FilesTable";
import { THEME_TYPES } from "../../../../atomic/consts";
import SmartshareFilePicker from "./SmartshareFilePicker";
import { Alert, ALERT_TYPES } from "../../../../atomic/AlertDeprecated/Alert";

export const SmartshareDialog = ({
  onClose,
  nameFormat,
  classUrn,
  monitorShareUnshare,
  tokenClient,
  accessToken,
  hasUpdatedSharedDrive,
}) => {
  const intl = useIntl();
  const [recipientType, setRecipientType] = useState(SHARE_RECIPIENT_CLASSES);
  const [files, setFiles] = useState([]);
  const [filesConfig, setFilesConfig] = useState({});
  const [replaceStudentFile, setReplaceStudentFile] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupsName, setSelectedGroupsName] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folders, setFolders] = useState([]);

  const selectedClasses = useMemo(() => {
    const classes = _.uniq(folders.map((f) => f.class_id));
    if (recipientType === SHARE_RECIPIENT_CLASSES) {
      return classes;
    }
    return [classUrn];
  }, [recipientType, folders]);

  const getActionLabel = () => {
    switch (recipientType) {
      case SHARE_RECIPIENT_CLASSES:
        return `Share to ${selectedClasses.length} ${
          selectedClasses.length == 1 ? "class" : "classes"
        }`;
      case SHARE_RECIPIENT_GROUPS:
        return `Share to ${selectedGroups.length} ${
          selectedGroups.length == 1 ? "group" : "groups"
        }`;
      case SHARE_RECIPIENT_STUDENTS:
        return `Share to ${selectedStudents.length} ${
          selectedStudents.length == 1 ? "student" : "students"
        }`;
    }
  };

  const isValidationError = () => {
    if (files.length === 0 || folders.length === 0) {
      return true;
    }
    const hasEmptyFileName = !!_.find(files, (f) => _.isEmpty(f.name));
    if (hasEmptyFileName) {
      return true;
    }
    switch (recipientType) {
      case SHARE_RECIPIENT_CLASSES:
        return selectedClasses.length === 0;
      case SHARE_RECIPIENT_GROUPS:
        return selectedGroups.length === 0;
      case SHARE_RECIPIENT_STUDENTS:
        return selectedStudents.length === 0;
    }
  };
  return (
    <ActionDialog
      isOpen={true}
      title={intl.formatMessage({
        defaultMessage: "Share files",
        id: "qTznkc",
      })}
      subTitle="Hand out digital work by selecting files from Drive or by sharing new, blank files."
      actionLabel={getActionLabel()}
      errorMessage="There was a problem sharing documents. Please try again."
      onClose={onClose}
      isValidationError={isValidationError()}
      onAction={async () => {
        //Call smartshare endpoint
        const requestData = {
          files,
          filesConfig,
          recipientType,
          selectedStudents,
          selectedGroupsName,
          fullClass: recipientType === SHARE_RECIPIENT_CLASSES,
          selectedClasses: selectedClasses,
          folders,
          replaceStudentFile,
          nameFormat,
        };
        await postRunSmartshare(requestData);
        monitorShareUnshare();
      }}
      dataTestIdPrefix="common-Smartshare-Dialog"
      className={styles.modal}
      // for some reason css cascade does not work as expected in dashboard-ng
      // so we add a class for outer layer for styles.modal to work
      classNameContent={styles.modalOverlay}
    >
      <div className={styles.root}>
        <FormBlock
          variant={THEME_TYPES.LIGHT}
          title={intl.formatMessage({
            defaultMessage: "What are you sharing?",
            id: "+oEpbj",
          })}
          dataTestIdPrefix="common-Smartshare-Dialog-Who-Sharing"
        >
          {files.length === 0 && <EmptyState message="No file chosen yet" />}
          {files.length > 0 && (
            <FilesTable
              files={files}
              filesConfig={filesConfig}
              setFilesConfig={setFilesConfig}
              setFiles={setFiles}
            />
          )}
          {files.length < 5 && (
            <SmartshareFilePicker
              hasUpdatedSharedDrive={hasUpdatedSharedDrive}
              files={files}
              setFiles={setFiles}
              filesConfig={filesConfig}
              accessToken={accessToken}
              tokenClient={tokenClient}
              setFilesConfig={setFilesConfig}
            />
          )}
          {files.length >= 5 && (
            <div className={styles.alertLayout}>
              <Alert type={ALERT_TYPES.WARNING} animated={false}>
                <FormattedMessage
                  defaultMessage="You can only share up to 5 files at a time."
                  id="0JNeba"
                />
              </Alert>
            </div>
          )}
          <div className={styles.block}>
            <Checkbox
              label={intl.formatMessage({
                defaultMessage:
                  "Replace existing student file(s). Replaced files will be moved to Trash in students’ Drive.",
                id: "fE1v7S",
              })}
              checked={replaceStudentFile}
              dataTestIdPrefix="common-Smartshare-Dialog-Replace-StudentFile-Checkbox"
              className={styles.appendNameCheckBox}
              onChange={(isChecked) => setReplaceStudentFile(isChecked)}
            />
          </div>
        </FormBlock>
        <RecipientPicker
          dataTestPrefix="common-Smartshare-Dialog-Recipient-Chooser"
          selectedStudents={selectedStudents}
          groupUrns={selectedGroups}
          classesUrns={selectedClasses}
          folders={folders}
          classUrn={classUrn}
          nameFormat={nameFormat}
          recipientType={recipientType}
          setRecipientType={setRecipientType}
          onSelectedStudentsChange={setSelectedStudents}
          onGroupUrnsChange={(groupUrns, groups, groupsName) => {
            setSelectedGroups(groupUrns);
            setSelectedGroupsName(groupsName || []);
            const sts = _.uniqBy(
              _.reduce(
                groups.filter((g) => groupUrns.indexOf(g.id) >= 0),
                (prev, cur) => {
                  return prev.concat(cur.students);
                },
                []
              ),
              "id"
            );
            setSelectedStudents(sts);
          }}
          onClassFoldersChange={setFolders}
          currentClassSelectedFolder={selectedFolder}
          onCurrentClassSelectedFolderChange={setSelectedFolder}
        />
      </div>
    </ActionDialog>
  );
};

SmartshareDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  nameFormat: PropTypes.string.isRequired,
  classUrn: PropTypes.string.isRequired,
  monitorShareUnshare: PropTypes.func.isRequired,
  tokenClient: PropTypes.object,
  accessToken: PropTypes.object,
  hasUpdatedSharedDrive: PropTypes.bool,
};

export default connect(() => ({}))(SmartshareDialog);
