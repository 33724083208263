import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./LocationField.module.scss";
import SelectDomainAndSchool from "../SelectDomainAndSchool/SelectDomainAndSchool";
import classnames from "classnames";
import { Permissions } from "../../../../types/shared";

const LocationField = ({
  label,
  required = false,
  permission,
  defaultDomainSelection,
  domains,
  onDomainSchoolChange,
  dataTestIdPrefix,
  className = "",
}) => {
  if (defaultDomainSelection.domain && defaultDomainSelection.school) {
    return null;
  }
  return (
    <fieldset className={classnames(styles.root, className)}>
      <div className={styles.label}>
        <legend>
          {label}{" "}
          {required ? (
            <span className={styles.required}>
              <FormattedMessage defaultMessage="(required)" id="VG94fP" />
            </span>
          ) : null}
        </legend>
      </div>
      <div className={styles.content}>
        <SelectDomainAndSchool
          dataTestIdPrefix={`${dataTestIdPrefix}-SelectLocation`}
          onChange={onDomainSchoolChange}
          domains={domains}
          isRequired={true}
          defaultDomainValue={defaultDomainSelection.domain}
          defaultSchoolValue={defaultDomainSelection.school}
          permission={permission}
          className={styles.locationField}
        />
      </div>
    </fieldset>
  );
};

LocationField.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      urn: PropTypes.string,
      schools: PropTypes.arrayOf(
        PropTypes.shape({ code: PropTypes.string, name: PropTypes.string })
      ),
    })
  ).isRequired,
  permission: PropTypes.oneOf(
    [
      Permissions.DASHBOARD_ADD_CLASS,
      Permissions.DASHBOARD_SYNC_GOOGLE_CLASSROOM,
      "",
    ] // empty means we show all domains/schools
  ).isRequired,
  defaultDomainSelection: PropTypes.shape({
    domain: PropTypes.string,
    school: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default LocationField;
