import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isUserInitLoaded } from "../../../state/app/selectors";
import { getUserLoggingStatus } from "../../../state/user/selectors";
import { getClassId } from "../../../state/shared/selectors";
import {
  getIsConfigLoading,
  getIsOutsideSchoolHours,
} from "../../../state/highlights/config/selectors";
import { loadClassConfig } from "../../../state/highlights/config/actions";
import { closeBusWebsocketConnection } from "../../../state/highlights/busWebsocket/actions";
import { fetchUserGroupsAction } from "../../../state/shared/userGroups/actions";
import { closeScreenshotWebsocketConnection } from "../../../state/highlights/screenshotWebsocket/actions";
import {
  getIsCurrentScreens,
  getIsClassViewPage,
} from "../../../state/router/selectors";
import { initJwtHandling } from "../../../apiCalls/jwtHandler";
import { getJWTRequestBody } from "@hapara/ui/src/components/utils";

const ClassChangeHandler = ({
  isUserLoggedIn,
  isUserInitLoaded,
  selectedClassId,
  isConfigLoading,
  isCurrentScreens,
  isClassViewPage,
  loadClassConfig,
  loadUserGroups,
  closeBusWebsocketConnection,
  closeScreenshotWebsocketConnection,
  isOutsideSchoolHours,
}) => {
  let selectedClassIdRef = useRef(null);
  let isOutsideSchoolHoursRef = useRef(null);

  // get config every time class is changed
  useEffect(() => {
    if (
      isUserLoggedIn &&
      isUserInitLoaded &&
      selectedClassId &&
      !isConfigLoading &&
      (selectedClassIdRef.current !== selectedClassId ||
        (!isOutsideSchoolHours &&
          isOutsideSchoolHoursRef.current !== isOutsideSchoolHours)) // open websocket on change from "out of school hours" to "school hours"
    ) {
      initJwtHandling(getJWTRequestBody(selectedClassId));
      loadClassConfig({
        classId: selectedClassId,
        shouldOpenWebsocketConnection: isCurrentScreens || isClassViewPage,
      });
      loadUserGroups(selectedClassId);

      selectedClassIdRef.current = selectedClassId;
      isOutsideSchoolHoursRef.current = isOutsideSchoolHours;
    }

    if (isOutsideSchoolHoursRef.current !== isOutsideSchoolHours) {
      isOutsideSchoolHoursRef.current = isOutsideSchoolHours;
    }
  }, [
    selectedClassId,
    loadClassConfig,
    loadUserGroups,
    isConfigLoading,
    isUserLoggedIn,
    isUserInitLoaded,
    isOutsideSchoolHours,
  ]);

  // close websockets on unmount
  useEffect(() => {
    return () => {
      closeBusWebsocketConnection();
      closeScreenshotWebsocketConnection();
    };
  }, [closeBusWebsocketConnection, closeScreenshotWebsocketConnection]);
  return null;
};

ClassChangeHandler.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isUserInitLoaded: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  isConfigLoading: PropTypes.bool.isRequired,
  isCurrentScreens: PropTypes.bool.isRequired,
  isClassViewPage: PropTypes.bool.isRequired,
  loadClassConfig: PropTypes.func.isRequired,
  closeBusWebsocketConnection: PropTypes.func.isRequired,
  closeScreenshotWebsocketConnection: PropTypes.func.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isUserLoggedIn: getUserLoggingStatus(state),
    isUserInitLoaded: isUserInitLoaded(state),
    selectedClassId: getClassId(state),
    isConfigLoading: getIsConfigLoading(state),
    isCurrentScreens: getIsCurrentScreens(state),
    isClassViewPage: getIsClassViewPage(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
  }),
  (dispatch) => ({
    loadClassConfig: (options) => dispatch(loadClassConfig(options)),
    loadUserGroups: (classId) => dispatch(fetchUserGroupsAction(classId)),
    closeBusWebsocketConnection: () => dispatch(closeBusWebsocketConnection()),
    closeScreenshotWebsocketConnection: () =>
      dispatch(closeScreenshotWebsocketConnection()),
  })
)(ClassChangeHandler);
