import _ from "lodash";
import moment from "moment";
import { postSendEvent } from "./user";
import axios from "axios";
import { getErrorStringFromErrorOrResponse } from "../utils";
import {
  userLoggedOut,
  userLoggedIn,
  userUnauthorisedAccess,
  unknownUser,
  userDetailsUpdated,
} from "../state/user/actions";
import store from "../state/store";
import { USER_INFO } from "../state/user/types";
import { decodeJwt } from "jose";

let jwtToken = null;
let jwtRequestBody = null;
let tokenUpdatePromise = null;

const handleError = () => {
  window.localStorage.removeItem(USER_INFO);
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("login-system");
  window.localStorage.removeItem("id_token");
  store.dispatch(userLoggedOut());
};

const handleUnauthorisedAccess = () => {
  store.dispatch(userUnauthorisedAccess());
};

const handleUnknownUser = () => {
  store.dispatch(unknownUser());
};

export const postJWT = async (requestBody, token) => {
  console.debug("[auth] [postJWT]");
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/auth-service/oauth/token`;
  let tokenToUse = token;
  let mockUser = false;
  let isAutomatedTests = window.localStorage.getItem("login-system") === "test";
  const testToken = isAutomatedTests
    ? window.localStorage.getItem("id_token")
    : null;

  if (testToken) {
    const testPersonalEmail =
      window.localStorage.getItem("isPersonalEmail") || null;
    const userEmail =
      JSON.parse(window.localStorage.getItem("user")).email || null;

    if (userEmail.includes("t3.ptor")) {
      mockUser = true;
    }

    if (testPersonalEmail) {
      const id_token = window.localStorage.getItem("id_token");
      isAutomatedTests = false;
      tokenToUse = id_token;
    } else {
      tokenToUse = `${userEmail}:hap01.kCS8NbaEcGENa2jKG36Zy7Ec5r5EBpd67fPRGYAE`;
    }
  }

  if (tokenToUse) {
    const config = {
      headers: {
        Authorization: `${
          isAutomatedTests ? "HapBearer" : "Bearer"
        } ${tokenToUse}`,
      },
      withCredentials: true,
    };
    return axios
      .post(`${url}?grant_type=refresh_token`, requestBody, config)
      .then((response) => {
        if (isAutomatedTests && !mockUser) {
          const userAuthData = decodeJwt(response?.data?.access_token);
          const user = {
            id: userAuthData.sub,
            email: userAuthData.email,
            name: `${userAuthData.fname} ${userAuthData.lname}`,
            givenName: userAuthData.fname,
            familyName: userAuthData.lname,
            imageUrl: userAuthData.picture,
          };
          store.dispatch(userDetailsUpdated(user));
          localStorage.setItem(USER_INFO, JSON.stringify(user));
        }
        store.dispatch(userLoggedIn());
        return response?.data;
      })
      .catch((e) => {
        if (
          e?.response?.data?.error &&
          e?.response?.data?.error === "Domain is not a Hapara client"
        ) {
          return handleUnknownUser();
        }
        return handleError();
      });
  }

  // Otherwise, call without refresh_token params and use the cookies as credentials.

  return axios
    .post(url, requestBody, { withCredentials: true })
    .then((response) => {
      const userAuthData = decodeJwt(response?.data?.access_token);

      const user = {
        id: userAuthData.sub,
        email: userAuthData.email,
        name: `${userAuthData.fname} ${userAuthData.lname}`,
        givenName: userAuthData.fname,
        familyName: userAuthData.lname,
        imageUrl: userAuthData.picture,
      };
      store.dispatch(userLoggedIn());
      store.dispatch(userDetailsUpdated(user));
      localStorage.setItem(USER_INFO, JSON.stringify(user));
      return response?.data;
    })
    .catch((e) => {
      if (
        e?.response?.data?.error_code &&
        (e.response.data.error_code === "USR_NO_AUTH_CLASS_DOMAIN" ||
          "USR_NO_AUTH_CLASS_SCHOOL")
      ) {
        return handleUnauthorisedAccess();
      }
      return handleError();
    });
};

const updateJwtToken = async () => {
  if (!jwtRequestBody) {
    return;
  }

  jwtToken = await postJWT(jwtRequestBody);

  return _.get(jwtToken, "access_token", "");
};

export const getTokenSync = () => {
  let token = "";
  if (jwtToken) {
    token = _.get(jwtToken, "access_token", "");
    const expired_at = _.get(jwtToken, "at_expires", 0);
    const tokenExpires = moment.unix(parseInt(expired_at, 10));
    const closeToExpire = tokenExpires.isBefore(moment().add(1, "minutes"));
    if (!closeToExpire) {
      return token;
    }
  }

  //we'll request update but return what we have right now
  getToken().catch((e) => {
    //we need to catch here as won't be handled
    postSendEvent({
      name: "load-jwt-token",
      error_message: getErrorStringFromErrorOrResponse(e),
    });
  });
  return token;
};

export const getToken = async () => {
  if (jwtToken) {
    const expired_at = _.get(jwtToken, "at_expires", 0);
    const tokenExpires = moment.unix(parseInt(expired_at, 10));
    const closeToExpire = tokenExpires.isBefore(moment().add(1, "minutes"));
    if (!closeToExpire) {
      return _.get(jwtToken, "access_token", "");
    }
  }

  if (!tokenUpdatePromise) {
    tokenUpdatePromise = updateJwtToken()
      .catch((e) => {
        postSendEvent({
          name: "load-jwt-token",
          error_message: getErrorStringFromErrorOrResponse(e),
        });
        throw e;
      })
      .finally(() => {
        tokenUpdatePromise = null;
      });
  }

  return await tokenUpdatePromise;
};

export const initJwtHandling = async (payload) => {
  if (!_.isEqual(payload, jwtRequestBody)) {
    jwtRequestBody = payload;
    jwtToken = null;
  }
};

export const removeTokenViaLogout = async () => {
  jwtToken = null;
  jwtRequestBody = null;
  window.localStorage.removeItem(USER_INFO);
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/auth-service/logout`;
  return axios
    .post(url, jwtRequestBody, { withCredentials: true })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return handleError();
    });
};
