import { FormattedMessage } from "react-intl";
import React, { useMemo } from "react";
import styles from "./ResourceTileDetails.module.scss";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import { connect } from "react-redux";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";
import { getFilters } from "../../../../state/shared/selectors";
import { sortStrToNumberIteratee } from "../../../../utils";
import { TILE_ICONS, RESOURCE_FORMAT } from "../../../../state/resources/types";
import { hasResourceUploadAccess } from "../../../../state/library/config/selectors";

const getJoinedString = (srcList, keyValueMap, isNumeric) => {
  const valueList = _.sortBy(
    _.map(srcList, (srcItemKey) => {
      return _.get(
        _.find(keyValueMap, (item) => item.Key === srcItemKey),
        "Value",
        srcItemKey
      );
    }),
    isNumeric ? sortStrToNumberIteratee : null
  );
  return _.join(valueList, ", ");
};
const ResourceTileDetails = ({
  type,
  author,
  grades,
  subjects,
  dateModified,
  srcGrades,
  srcSubjects,
  isItemUploaded,
  hasResourceUploadAccess,
}) => {
  let tileName = "Item";
  let tileNameIcon = "material-icon-folder";
  if (type === RESOURCE_FORMAT.WORKSPACE) {
    tileName = "Workspace";
    tileNameIcon = TILE_ICONS.WORKSPACE;
  }
  if (type === RESOURCE_FORMAT.RESOURCE) {
    tileName = "Resource";
    tileNameIcon = TILE_ICONS.RESOURCE;
  }

  const modified = dateModified
    ? moment(dateModified).format("MMM DD, YYYY")
    : null;

  const gradesString = useMemo(
    () => getJoinedString(grades, srcGrades, true),
    [grades, srcGrades]
  );
  const subjectsString = useMemo(
    () => getJoinedString(subjects, srcSubjects),
    [subjects, srcSubjects]
  );

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.detailsTitle, {
          [styles.purple]: type === RESOURCE_FORMAT.RESOURCE,
          [styles.blue]: type === RESOURCE_FORMAT.WORKSPACE,
        })}
      >
        <div className={styles.detailsTitleIcon}>
          <HapReactIcon svg={tileNameIcon} width={20} height={20} />
        </div>
        <div
          className={styles.detailsTitleName}
          data-test-id="lb-ResourceTile-Details-Type"
        >
          {tileName}
        </div>
        {isItemUploaded && hasResourceUploadAccess ? (
          <div className={styles.detailsTitleTag}>
            <FormattedMessage defaultMessage="Uploaded" id="LwdDVa" />
          </div>
        ) : null}
      </div>
      {type === RESOURCE_FORMAT.RESOURCE && author && (
        <div
          className={styles.detailsProps}
          data-test-id="lb-ResourceTile-Details-Author"
        >
          <div className={styles.detailsPropsTitle}>
            <FormattedMessage defaultMessage="Author:" id="Q8XddZ" />
          </div>
          <div className={styles.detailsPropsValue}>{author}</div>
        </div>
      )}
      {gradesString && (
        <div
          className={styles.detailsProps}
          data-test-id="lb-ResourceTile-Details-Grades"
        >
          <div className={styles.detailsPropsTitle}>
            <FormattedMessage defaultMessage="Grades:" id="4YU9rr" />
          </div>
          <div className={styles.detailsPropsValue}>{gradesString}</div>
        </div>
      )}
      {subjectsString && (
        <div
          className={styles.detailsProps}
          data-test-id="lb-ResourceTile-Details-Subjects"
        >
          <div className={styles.detailsPropsTitle}>
            <FormattedMessage defaultMessage="Subjects:" id="vGhtq/" />
          </div>
          <div className={styles.detailsPropsValue}>{subjectsString}</div>
        </div>
      )}
      {type === RESOURCE_FORMAT.WORKSPACE && modified && (
        <div className={styles.detailsProps}>
          <div className={styles.detailsPropsTitle}>
            <FormattedMessage defaultMessage="Modified:" id="DFY8tS" />
          </div>
          <div className={styles.detailsPropsValue}>{modified}</div>
        </div>
      )}
    </div>
  );
};

ResourceTileDetails.propTypes = {
  type: PropTypes.oneOf(_.values(RESOURCE_FORMAT)),
  author: PropTypes.string,
  dateModified: PropTypes.string,
  grades: PropTypes.arrayOf(PropTypes.string),
  subjects: PropTypes.arrayOf(PropTypes.string),
  isItemUploaded: PropTypes.bool.isRequired,
  // connected
  hasResourceUploadAccess: PropTypes.bool.isRequired,
  srcGrades: PropTypes.arrayOf(filterItemType),
  srcSubjects: PropTypes.arrayOf(filterItemType),
};

export default connect(
  (state) => ({
    hasResourceUploadAccess: hasResourceUploadAccess(state),
    srcGrades: getFilters(SHARED_FILTER_TYPES.GRADES.value)(state),
    srcSubjects: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
  }),
  (dispatch) => ({})
)(ResourceTileDetails);
