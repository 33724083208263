import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getIsResourceDeleteModalOpen,
  getResourceDeleteModalData,
} from "../../../../state/resources/selectors";
import {
  showSuccessToast,
  hideSuccessToast,
  hideAppError,
} from "../../../../state/app/actions";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import {
  hideResourceDetailsModal,
  hideResourceDeleteModal,
  removeFromLibraryAction,
} from "../../../../state/resources/actions";
import { FocusContext } from "@hapara/ui/src/components/utils";

const DeleteResourceModal = ({
  isModalOpen = false,
  dataTestPrefix,
  modalData,
  showSuccessMessage,
  hideSuccessMessage,
  removeFromLibraryAction,
  hideErrorMessage,
  hideResourceDetailsModal,
  hideResourceDeleteModal,
}) => {
  const [isErrorRemoving, setIsErrorRemoving] = useState(false);

  const { resourceId } = modalData;

  const focusContext = useContext(FocusContext);

  // on unmount
  useEffect(() => {
    return () => {
      hideResourceDeleteModal();
    };
  }, [hideResourceDeleteModal]);

  const deleteResource = (itemId) => {
    hideErrorMessage();
    hideSuccessMessage();

    removeFromLibraryAction({ itemId })
      .then(() => {
        hideResourceDeleteModal();
        hideResourceDetailsModal();
        showSuccessMessage();
        focusContext.restoreFocus();
      })
      .catch(() => {
        setIsErrorRemoving(true);
      });
  };

  return (
    <ActionConfirmationDialog
      dataTestPrefix={`${dataTestPrefix}-DeleteResourceConfirmationModal`}
      isOpen={isModalOpen}
      onClose={() => {
        hideResourceDeleteModal();
        setIsErrorRemoving(false);
        focusContext.restoreFocus();
      }}
      title="Delete resource?"
      content="This resource will no longer be available in the library for other people in your organization to view and/or copy."
      isActionDanger={true}
      warning={
        !isErrorRemoving
          ? "Deleting a resource will impact teachers and students who are currently using the resource."
          : null
      }
      error={
        isErrorRemoving
          ? "Sorry, there was a problem deleting this resource. Please try again."
          : null
      }
      cancelDataTestId={`${dataTestPrefix}-DeleteResourceConfirmationModal-Button-Cancel`}
      actionLabel="Delete resource"
      onAction={() => {
        deleteResource(resourceId);
      }}
      actionDataTestId={`${dataTestPrefix}-DeleteResourceConfirmationModal-Button-Confirm`}
    />
  );
};

DeleteResourceModal.propTypes = {
  isModalOpen: PropTypes.bool,
  modalData: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
  }),
  hideErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
  removeFromLibraryAction: PropTypes.func.isRequired,
  hideResourceDetailsModal: PropTypes.func.isRequired,
  hideResourceDeleteModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getIsResourceDeleteModalOpen(state),
    modalData: getResourceDeleteModalData(state),
  }),
  (dispatch) => ({
    hideErrorMessage: () => dispatch(hideAppError()),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
    removeFromLibraryAction: ({ itemId }) =>
      dispatch(removeFromLibraryAction({ itemId })),
    hideResourceDetailsModal: () => dispatch(hideResourceDetailsModal()),
    hideResourceDeleteModal: () => dispatch(hideResourceDeleteModal()),
  })
)(DeleteResourceModal);
