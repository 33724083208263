import _ from "lodash";
import actionTypes from "../actionTypes";
import {
  userLoggedIn,
  userLoggedOut,
  userDetailsUpdated,
} from "../user/actions";
import { getGISInfo } from "../user/selectors";
import {
  domainFromEmail,
  getErrorStringFromErrorOrResponse,
} from "../../utils";
import {
  fetchUserInit,
  fetchUserInitConfig,
  postSendEvent,
  postSendFeedback,
} from "../../apiCalls/user";
import { getClassId } from "../shared/selectors";
import { generateId } from "@hapara/ui/src/components/utils";
import { removeTokenViaLogout, postJWT } from "../../apiCalls/jwtHandler";
import { fetchEmailFormatPreference } from "../../apiCalls/config";

let instance_id = "blank";

try {
  instance_id = localStorage.getItem("hapara-instance-id");
  if (!instance_id) {
    instance_id = generateId();
    localStorage.setItem("hapara-instance-id", instance_id);
  }
} catch (e) {
  //just ignoring it for now, it will show up in honeycomb as blank instance and we'll check if it ever happens
}

export const updateWindowSize = (size) => ({
  type: actionTypes.APP_WINDOW_SIZE_UPDATE,
  payload: size,
});

const updateAppError = (isError) => {
  return {
    type: actionTypes.APP_ERROR_UPDATE,
    payload: isError,
  };
};

export const showAppError = (error, errorType) => (dispatch) => {
  dispatch(updateAppError(true));
  const isApiError = errorType === "api-error";
  if (isApiError && error.responseStatus === 403) {
    dispatch(updateAccessError(true));
  }
  if (!isApiError) {
    //api errors already reported on api level
    dispatch(
      sendEvent({
        name: "fe-app-error",
        error_message: isApiError
          ? error.message
          : getErrorStringFromErrorOrResponse(error),
        error_type: errorType || "app-error",
        response_status: isApiError ? error.responseStatus : 0,
        api_path: isApiError ? error.apiPath : "",
      })
    );
  }
};

export const hideAppError = () => (dispatch) => dispatch(updateAppError(false));

const updateSuccessToast = (
  isVisible,
  message = "",
  isExpandModal = false,
  classId = ""
) => ({
  type: actionTypes.APP_SUCCESS_TOAST_UPDATE,
  payload: {
    isVisible,
    message,
    isExpandModal,
    classId,
  },
});

export const showSuccessToast = (message) => (dispatch) =>
  dispatch(updateSuccessToast(true, message));

export const showExpandModalSuccessToast = (classId) => (dispatch) =>
  dispatch(updateSuccessToast(true, "", true, classId));

export const hideSuccessToast = () => (dispatch) =>
  dispatch(updateSuccessToast(false));

export const updateInitLoadError = (isError) => ({
  type: actionTypes.APP_INIT_LOAD_ERROR_UPDATE,
  payload: isError,
});

export const updateInitUserError = (error) => ({
  type: actionTypes.APP_USER_INIT_ERROR,
  error,
});

export const updateAccessError = (isError) => ({
  type: actionTypes.APP_ACCESS_ERROR_UPDATE,
  payload: isError,
});

export const loadGIS = (isInitLoad) => async (dispatch) => {
  dispatch({
    type: actionTypes.APP_GIS_LOAD_PENDING,
  });

  const handleCallback = async (response) => {
    await postJWT(null, response.credential);
  };

  if (window?.google?.accounts?.id?.initialize) {
    await window?.google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallback,
    });

    dispatch({
      type: actionTypes.APP_GIS_LOAD_SUCCESS,
    });
  } else {
    dispatch(
      sendEvent({
        name: "apiError",
        apiPath: "window?.google?.accounts?.id",
        apiError: "GIS not available",
      })
    );
    dispatch({
      type: actionTypes.APP_GIS_LOAD_ERROR,
    });

    if (isInitLoad) {
      dispatch(updateInitLoadError(true));
    }
  }
};

export const testLogIn =
  ({ email, token, firstName, lastName, idToken }) =>
  async (dispatch) => {
    const user = {
      token: {
        token_type: "Bearer",
        access_token: `${token}`,
        id_token: `${idToken}`,
      },
      id: "1",
      email: email,
      givenName: firstName || "Test",
      familyName: lastName || "Student",
      name: `${firstName || "Test"} ${lastName || "Student"}`,
      imageUrl:
        "https://lh6.googleusercontent.com/-rG0GWxtSh4w/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdNtV2NUDC3rB8KOVA66mv1EoD6FA/s96-c/photo.jpg",
    };
    // save token to use in api calls
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("id_token", idToken);
    window.localStorage.setItem("login-system", "test");

    // save user data for automation tests
    window.localStorage.setItem("user", JSON.stringify(user));

    dispatch(userLoggedIn());
    dispatch(userDetailsUpdated(user));
  };

export const logInWithDifferentAccount = async () => {
  await logOut();
};

export const logOut = () => async (dispatch) => {
  await removeTokenViaLogout();
  dispatch(userLoggedOut());
};

export const sendEvent = (data) => (dispatch, getState) => {
  const state = getState();
  const user = getGISInfo(state);
  const email = user.email;
  const classId = getClassId(state);
  postSendEvent({
    ...data,
    service_name: "frontend",
    class_context: classId,
    build_number: parseInt(process.env.REACT_APP_BUILD_NUMBER || 0),
    platform_os: _.get(window, "navigator.platform"),
    browser_version: _.get(window, "navigator.appVersion"),
    user_type: "teacher",
    user_id: user.id,
    user_email: email,
    instance_id,
    domain: domainFromEmail(email),
  }).catch((e) => {
    //it mostly happens when browser goes offline/online,
    // catching error, but maybe we can later introduce savubg error log, that is to be sent when back online
  });
};

export const sendFeedback = (body) => (dispatch) => {
  return postSendFeedback(body);
};

export const loadUserInit = (isInitLoad) => (dispatch) => {
  dispatch({
    type: actionTypes.APP_USER_INIT_PENDING,
  });
  fetchUserInit()
    .then((response) => {
      if (response.status === 403) {
        // Not a Hapara user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }

      return response.json();
    })
    .then((data) => {
      dispatch({
        type: actionTypes.APP_USER_INIT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch(
        sendEvent({
          name: "apiError",
          apiPath: "v2/pugs/user/init",
          apiError: getErrorStringFromErrorOrResponse(error),
        })
      );

      dispatch(updateInitUserError(error));

      if (isInitLoad) {
        dispatch(updateInitLoadError(true));
      }
    });
};

export const loadUserInitConfig =
  ({ isInitLoad, classId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.APP_USER_INIT_CONFIG_PENDING,
    });

    fetchUserInitConfig(classId)
      .then((data) => {
        dispatch({
          type: actionTypes.APP_USER_INIT_CONFIG_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.APP_USER_INIT_CONFIG_ERROR,
        });

        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        } else {
          if (isInitLoad) {
            dispatch(updateInitLoadError(true));
          }
        }
      });
    fetchEmailFormatPreference(classId)
      .then((result) => {
        const emailFormatPreference = result.data.dashboard.email_fmt;
        dispatch({
          type: actionTypes.USER_EMAIL_FORMAT_LOAD_SUCCESS,
          payload: emailFormatPreference,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.USER_EMAIL_FORMAT_LOAD_ERROR,
        });
      });
  };
