import _ from "lodash";
import { createSelector } from "reselect";
import { getFilterLabelId } from "../list/selectors";

export const areLabelsLoading = (state) =>
  _.get(state, "workspace.myworkspaces.labels.loading");
export const areLabelsLoaded = (state) =>
  _.get(state, "workspace.myworkspaces.labels.loaded");
export const getLabels = (state) =>
  _.get(state, "workspace.myworkspaces.labels.items");
export const actionInProgress = (state) =>
  _.get(state, "workspace.myworkspaces.labels.actionInProgress");
export const actionError = (state) =>
  _.get(state, "workspace.myworkspaces.labels.actionError");
export const actionCompleted = (state) =>
  _.get(state, "workspace.myworkspaces.labels.actionCompleted");

export const areThereSomeLabels = (state) => getLabels(state).length > 0;

export const getFilterLabelById = (filterLabelId) =>
  createSelector([filterLabelId, getLabels], (filterLabelId, labels) =>
    _.find(labels, { Id: filterLabelId })
  );

export const isLabelDeleted = (state) => {
  const label = _.find(getLabels(state), { Id: getFilterLabelId(state) });
  return !label || label.deleted;
};

export const areThereAnyLabels = (state) => {
  const existingLabels = _.filter(getLabels(state), { deleted: false });
  return !existingLabels || existingLabels.length > 0;
};
