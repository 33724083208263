import React, { useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetadataFilters from "../MetadataFilters/MetadataFilters";
import styles from "./FiltersLeftPanel.module.scss";
import { getLeftPanelIsOpen } from "../../../../state/shared/selectors";
import { toggleLeftSidePanel } from "../../../../state/shared/actions";
import { FocusContext } from "@hapara/ui/src/components/utils";
import ReactModal from "react-modal";
import _ from "lodash";
import PageLeftPanelModal from "../../PageLeftPanelModal/PageLeftPanelModal";

const rootId = "#root";

export const FiltersLeftPanel = ({ isPanelOpen, toggleLeftPanel }) => {
  const focusContext = useContext(FocusContext);
  const sidePanelRef = useRef();

  useEffect(() => {
    if (isPanelOpen) {
      _.delay(() => sidePanelRef.current && sidePanelRef.current.focus(), 300);
    }
  }, [isPanelOpen]);

  const closeLeftPanel = () => {
    toggleLeftPanel(false);
    focusContext.restoreFocus();
  };

  ReactModal.setAppElement(rootId);

  const dataTestIdPrefix = "lb-LeftSideFiltersPanel";

  return (
    <PageLeftPanelModal
      isOpen={isPanelOpen}
      dataTestIdPrefix={dataTestIdPrefix}
      onClose={closeLeftPanel}
      landmarkLabel="Filters panel"
      closeButtonLabel="Close filters panel"
    >
      <nav
        className={styles.filtersList}
        data-test-id={`${dataTestIdPrefix}-Filters`}
      >
        <MetadataFilters showApplyButton={true} />
      </nav>
    </PageLeftPanelModal>
  );
};

FiltersLeftPanel.propTypes = {
  isPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isPanelOpen: getLeftPanelIsOpen(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLeftSidePanel(isOpen)),
  })
)(FiltersLeftPanel);
