import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import {
  getMyWorkspaces,
  getCurrentPage,
  getPagesCount,
  isLoading,
  getFilterStatusType,
  getFilterCategoryType,
  getFilterLabelId,
  getFilterPlRole,
} from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  isLabelDeleted,
  areLabelsLoaded,
} from "../../../../../state/workspace/myworkspaces/labels/selectors";
import {
  wsItemType,
  MYWORKSPACES_FILTER_STATUS_TYPES,
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
  MYWORKSPACES_DEFAULT_LABEL,
  MYWORKSPACES_PL_ROLES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "../../../../../state/workspace/myworkspaces/list/navigation";
import { getFilterClassId } from "../../../../../state/workspace/myworkspaces/list/selectors";
import styles from "./WorkspacesListItems.module.scss";
import WorkspaceTile from "../WorkspaceTile/WorkspaceTile";
import WorkspacesListEmptyState from "../WorkspacesListEmptyState/WorkspacesListEmptyState";
import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";
import { getCurrentClassName } from "../../../../../state/shared/selectors";
import ResourcesLoading from "../../../Resources/ResourcesLoading/ResourcesLoading";

const WorkspacesListItems = ({
  items,
  currentPage,
  pagesCount,
  isLoading,
  filterStatusType,
  filterClassId,
  filterCategoryType,
  filterLabelId,
  filterPLRole,
  isLabelDeleted,
  areLabelsLoaded,
  currentClassName,
}) => {
  useEffect(() => {
    if (
      filterCategoryType === MYWORKSPACES_FILTER_CATEGORY_TYPES.ARCHIVED &&
      filterLabelId &&
      filterLabelId !== MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.label &&
      isLabelDeleted &&
      areLabelsLoaded
    ) {
      pushQueryToHistory({
        label: MYWORKSPACES_DEFAULT_LABEL.id,
      });
    }
  }, [filterCategoryType, filterLabelId, isLabelDeleted, areLabelsLoaded]);

  const handlePageSelect = (page) => {
    pushQueryToHistory({
      page,
    });
  };

  if (isLoading || items === null) {
    return <ResourcesLoading />;
  }

  return (
    <div className={styles.root}>
      {items.length === 0 && (
        <WorkspacesListEmptyState
          category={filterCategoryType}
          filterStatus={filterStatusType}
          filterPLRole={filterPLRole}
          classITeach={filterClassId}
          classIDoNotTeach={currentClassName}
          filterLabel={
            { Id: filterLabelId, deleted: isLabelDeleted } || { Id: null }
          }
        />
      )}

      {items.length > 0 && (
        <>
          <ul className={styles.list}>
            {_.map(items, (item) => (
              <li
                className={styles.listItem}
                key={item.Id}
                data-test-id="Ws-WorkspacesListItem-Container"
              >
                <WorkspaceTile
                  item={item}
                  dataTestIdPrefix="Ws-WorkspacesListItem"
                />
              </li>
            ))}
          </ul>
          <Pagination
            pages={pagesCount}
            currentPage={currentPage}
            onSelectPage={handlePageSelect}
            dataTestIdPrefix="Ws-WorkspacesListPagination"
          />
        </>
      )}
    </div>
  );
};

WorkspacesListItems.propTypes = {
  items: PropTypes.arrayOf(wsItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filterStatusType: PropTypes.oneOf(_.values(MYWORKSPACES_FILTER_STATUS_TYPES)),
  filterClassId: PropTypes.string,
  filterCategoryType: PropTypes.string,
  filterLabelId: PropTypes.string,
  filterPLRole: PropTypes.oneOf(_.values(MYWORKSPACES_PL_ROLES)),
  isLabelDeleted: PropTypes.bool,
  areLabelsLoaded: PropTypes.bool,
  currentClassName: PropTypes.string,
};

export default connect((state) => ({
  items: getMyWorkspaces(state),
  currentPage: getCurrentPage(state),
  pagesCount: getPagesCount(state),
  isLoading: isLoading(state),
  filterStatusType: getFilterStatusType(state),
  filterClassId: getFilterClassId(state),
  filterCategoryType: getFilterCategoryType(state),
  filterLabelId: getFilterLabelId(state),
  filterPLRole: getFilterPlRole(state),
  isLabelDeleted: isLabelDeleted(state),
  areLabelsLoaded: areLabelsLoaded(state),
  currentClassName: getCurrentClassName(state),
}))(WorkspacesListItems);
