import { useEffect, useRef, useState } from "react";
import _ from "lodash";

const useWorkspaceGradingBulkModalHook = ({ assignees, loadAction }) => {
  const assigneesRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  // trigger assignee list reload on modal open
  useEffect(() => {
    if (isModalOpen) {
      loadAction();
    }
  }, [loadAction, isModalOpen]);

  // reset selected list on modal close
  useEffect(() => {
    if (!isModalOpen) {
      setSelectedList([]);
    }
  }, [setSelectedList, isModalOpen]);

  // reset selected list when assignee list has changed
  useEffect(() => {
    const assigneeArtefactIdList = _.map(assignees, (item) => item.ArtifactID);

    if (!_.isEqual(assigneeArtefactIdList, assigneesRef.current)) {
      setSelectedList([]);
      assigneesRef.current = assigneeArtefactIdList;
    }
  }, [setSelectedList, assignees, assigneesRef]);

  return { isModalOpen, setIsModalOpen, selectedList, setSelectedList };
};

export default useWorkspaceGradingBulkModalHook;
