type DecodedJWT<T> = {
  header: unknown;
  payload: T;
};

/** JWT uses base64url (RFC 4648 §5), so using only atob (which uses base64) isn't enough. */
const parseDecodeBase64Url = (data: string) =>
  JSON.parse(atob(data.replace("-", "+").replace("_", "/")));

/** Simple util to return decoded JWT payload, note - **it does not verify the signature**
 * @throws {Error} Throws an error if the JWT is invalid. */
export const decodeJwt = <T>(token: string): DecodedJWT<T> | null => {
  try {
    const [headerEncoded, payloadEncoded] = token.split(".");
    const header = parseDecodeBase64Url(headerEncoded);
    const payload = parseDecodeBase64Url(payloadEncoded);
    return { payload, header };
  } catch (e) {
    throw new Error("[auth] Invalid JWT token");
  }
};
