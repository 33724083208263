import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { USER_INFO } from "../../../state/user/types";
import { logOut } from "../../../state/app/actions";
import Dropdown, {
  DROPDOWN_CONTENT_COLOUR_TYPES,
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./Personalisation.module.scss";
import { useIntl } from "react-intl";

const Personalisation = ({ logOut }) => {
  const intl = useIntl();
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {};
  return (
    <div className={styles.root}>
      <Dropdown
        classNameContent={styles.dropdownContent}
        className={styles.dropdown}
        colorType={DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY}
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={({ className, ...rest }) => (
          <button
            type="button"
            className={classnames(className, styles.dropdownTrigger)}
            data-test-id="ws-Header-Persona-Open"
            {...rest}
          >
            <span>{userInfo?.email || ""}</span>
            <HapReactIcon svg="arrow-carvet-down" width={16} height={16} />
          </button>
        )}
        itemComponentList={[
          ({ onClick, className, ...rest }) => (
            <button
              type="button"
              className={classnames(className, styles.dropdownContentItem)}
              data-test-id="ws-Header-Persona-Logout"
              onClick={() => {
                logOut();
                onClick();
              }}
              {...rest}
            >
              {intl.formatMessage({ defaultMessage: "Logout", id: "C81/uG" })}
            </button>
          ),
        ]}
      />
    </div>
  );
};

Personalisation.propTypes = {
  logOut: PropTypes.func,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    logOut: () => dispatch(logOut()),
  })
)(Personalisation);
