import { useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMonitoringTime } from "../../../state/highlights/config/selectors";
import { isOutOfSchoolHours } from "../../../utils";
import { MONITORING_TIME_TYPE } from "../../../state/highlights/config/types";
import { updateOutsideSchoolHours } from "../../../state/highlights/config/actions";
import { closeBusWebsocketConnection } from "../../../state/highlights/busWebsocket/actions";
import { closeScreenshotWebsocketConnection } from "../../../state/highlights/screenshotWebsocket/actions";

const SchoolHoursHandler = ({
  monitoringTime = null,
  updateOutsideSchoolHours,
  closeBusWebsocketConnection,
  closeScreenshotWebsocketConnection,
}) => {
  let delayedId = useRef();

  const checkIfOutsideSchoolHours = useCallback(() => {
    return isOutOfSchoolHours(monitoringTime);
  }, [monitoringTime]);

  useEffect(() => {
    const outOfSchoolCheckInterval = () => {
      if (monitoringTime) {
        const isOutOfSchoolHours = checkIfOutsideSchoolHours();
        updateOutsideSchoolHours(isOutOfSchoolHours);

        if (isOutOfSchoolHours) {
          closeBusWebsocketConnection();
          closeScreenshotWebsocketConnection();
        }
      }
      window.clearTimeout(delayedId.current);
      delayedId.current = _.delay(() => {
        outOfSchoolCheckInterval();
      }, 30000);
    };

    outOfSchoolCheckInterval();

    return () => {
      window.clearTimeout(delayedId.current);
    };
  }, [
    checkIfOutsideSchoolHours,
    monitoringTime,
    updateOutsideSchoolHours,
    closeBusWebsocketConnection,
    closeScreenshotWebsocketConnection,
  ]);

  return null;
};

SchoolHoursHandler.propTypes = {
  monitoringTime: MONITORING_TIME_TYPE || PropTypes.null,
  closeBusWebsocketConnection: PropTypes.func.isRequired,
  closeScreenshotWebsocketConnection: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    monitoringTime: getMonitoringTime(state),
  }),
  (dispatch) => ({
    updateOutsideSchoolHours: (options) =>
      dispatch(updateOutsideSchoolHours(options)),
    closeBusWebsocketConnection: () => dispatch(closeBusWebsocketConnection()),
    closeScreenshotWebsocketConnection: () =>
      dispatch(closeScreenshotWebsocketConnection()),
  })
)(SchoolHoursHandler);
