import actionTypes from "../../../actionTypes";
import _ from "lodash";

export const INIT_STATE = {
  items: [],
  loading: false,
  loaded: false,
  actionInProgress: false,
  actionError: false,
  actionCompleted: false,
};

const updateItems = (items, action) => {
  switch (action.action) {
    case "delete":
      return _.map(items, (item) => ({
        ...item,
        deleted: item.Id === action.labelId ? true : item.deleted,
      }));

    case "edit":
      const editedLabel = _.find(items, { Id: action.labelId });
      if (editedLabel) editedLabel.Name = action.newTitle;
      return _.sortBy({ ...items }, (item) => item.Name.toLowerCase());
    default:
      return [];
  }
};

const labelsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.MYWORKSPACES_LABELS_LOAD_PENDING:
      return { ...state, loading: true, loaded: false };

    case actionTypes.MYWORKSPACES_LABELS_LOAD_ERROR:
      return { ...state, loading: false, loaded: true };

    case actionTypes.MYWORKSPACES_LABELS_LOAD_SUCCESS:
      return {
        items: _.get(action, "payload", []),
        loading: false,
        loaded: true,
      };

    case actionTypes.MYWORKSPACES_LABELS_ACTION_PENDING:
      return {
        ...state,
        actionInProgress: true,
        actionError: false,
        actionCompleted: false,
      };

    case actionTypes.MYWORKSPACES_LABELS_ACTION_ERROR:
      return {
        ...state,
        actionInProgress: false,
        actionError: true,
        actionCompleted: false,
      };

    case actionTypes.MYWORKSPACES_LABELS_ACTION_SUCCESS:
      return {
        ...state,
        items: updateItems(state.items, action),
        actionInProgress: false,
        actionError: false,
        actionCompleted: true,
      };

    case actionTypes.MYWORKSPACES_LABELS_DISMISS_ERROR:
      return {
        ...state,
        actionInProgress: false,
        actionError: false,
        actionCompleted: false,
      };

    case actionTypes.MYWORKSPACES_LABELS_CLEAR:
      return { ...state, items: _.filter(state.items, { deleted: false }) };

    default:
      return state;
  }
};

export default labelsReducer;
