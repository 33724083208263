import React from "react";
import { useIntl } from "react-intl";
import { Heading } from "../../atomic/Heading";
import { CloseButton } from "../../atomic/CloseButton";
import { Button } from "../../atomic/Button";
import classnames from "classnames";
import styles from "./Dialog.module.scss";
import { PageActions } from "../PageActions";
import { ArrowLeftIcon } from "../../icons/ArrowLeftIcon";

type DialogProps = {
  children?: React.ReactNode;
  heading?: React.ReactNode;
  headingStyledAs?: "h2" | "h3";
  dataTestIdPrefix?: string;
  onRequestClose?(): void;
  onBack?(): void;
  className?: string;
  footer?: React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean;
};

export const Dialog = ({
  heading,
  children,
  headingStyledAs = "h2",
  dataTestIdPrefix,
  onRequestClose,
  onBack,
  className,
  footer,
  actions,
  fullWidth,
}: DialogProps) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-Dialog`
    : undefined;
  const intl = useIntl();
  return (
    <div
      data-test-id={dataTestId}
      className={classnames(
        styles.dialog,
        className,
        fullWidth && styles.fullWidth
      )}
      role="dialog"
    >
      <div className={styles.header}>
        <div className={styles.headingWrapper}>
          {onBack ? (
            <Button
              aria-label={intl.formatMessage({
                defaultMessage: "Navigate back",
                id: "RJGg0l",
              })}
              iconComponent={<ArrowLeftIcon />}
              onClick={onBack}
              data-test-id={`${dataTestId}-back-button`}
              type="tertiary"
              size="xsmall"
            />
          ) : null}
          {heading ? (
            <Heading
              level={1}
              styledAs={headingStyledAs}
              removeTopMargin
              removeBottomMargin
              dataTestIdPrefix={dataTestId}
            >
              {heading}
            </Heading>
          ) : null}
        </div>
        {onRequestClose ? (
          <CloseButton onClick={onRequestClose} dataTestIdPrefix={dataTestId} />
        ) : null}
      </div>

      <div className={styles.content}>{children}</div>

      {actions || footer ? (
        <div className={styles.footer}>
          {footer ? <div>{footer}</div> : null}
          {actions ? <PageActions>{actions}</PageActions> : null}
        </div>
      ) : null}
    </div>
  );
};
