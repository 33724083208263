import React from "react";
import styles from "./Card.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

export const CARD_TYPE = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const BACKGROUND_STYLE = {
  WHITE: "white",
  BLUE: "blue",
  GREEN: "green",
  VIOLET: "violet",
  ORANGE: "orange",
};

/** @deprecated */
const Card = ({
  type = CARD_TYPE.HORIZONTAL,
  image,
  title: Title,
  text,
  background = BACKGROUND_STYLE.WHITE,
  actionComponent,
  dataTestIdPrefix,
}) => {
  return (
    <div
      style={{
        backgroundImage:
          type === CARD_TYPE.HORIZONTAL ? `url("${image}")` : "none",
      }}
      className={classnames(styles.root, styles[type], styles[background])}
      data-test-id={
        dataTestIdPrefix
          ? `${dataTestIdPrefix}-${_.upperFirst(type)}-Card`
          : null
      }
    >
      <div className={styles.container}>
        {type === CARD_TYPE.VERTICAL && (
          <img className={styles.img} src={image} alt="" />
        )}
        <div className={styles.content}>
          <Title className={styles.title} />
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      {actionComponent && (
        <div className={styles.actionComponent}>{actionComponent}</div>
      )}
    </div>
  );
};

Card.propTypes = {
  type: PropTypes.oneOf(_.values(CARD_TYPE)),
  background: PropTypes.oneOf(_.values(BACKGROUND_STYLE)),
  actionComponent: PropTypes.node,
  image: PropTypes.string.isRequired,
  title: PropTypes.elementType.isRequired,
  text: PropTypes.node.isRequired,
  dataTestIdPrefix: PropTypes.string,
};

export default Card;
