import React, { Profiler } from "react";
import { connect } from "react-redux";
import styles from "./ActivityViewerPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import { MAIN_CONTENT_ID } from "../../../../consts";
import ClassActivity from "../../../partial/Highlights/ClassActivity/ClassActivity";
import UniqueActivity from "../../../partial/Highlights/UniqueActivity/UniqueActivity";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import { ACTIVITY_TYPES } from "../../../../state/highlights/activities/selectors";
import {
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/highlights/config/selectors";
import PropTypes from "prop-types";
import { getFFByName } from "../../../../state/app/selectors";
import { sendReactRenderStats } from "../../../../state/highlights/students/actions";
import usePerformanceStatsSendHook from "../../../../hooks/usePerformanceStatsSendHook";
import { FormattedMessage } from "react-intl";

const ActivityViewerPage = ({
  isConfigLoaded,
  isConfigError,
  isMonitorPerformanceFF,
  sendReactRenderStats,
}) => {
  const onActivityRender = usePerformanceStatsSendHook({
    componentName: "ActivityViewer",
    sendReactRenderStats,
    isMonitorPerformanceFF,
  });

  return (
    <Page
      header={<Header />}
      footer={
        <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
      }
      isPageLoaded={isConfigLoaded}
      isPageLoadingError={isConfigError}
      title={APP_PAGES.HIGHLIGHTS_ACTIVITY_VIEWER.title}
    >
      <ModalsAndPanels />
      <main
        className={styles.root}
        data-test-id="Hs-Pages-HighlightsActivityViewerPage"
        id={MAIN_CONTENT_ID}
        tabIndex="-1"
      >
        <h1>
          <FormattedMessage defaultMessage="Activity Viewer" id="kt+HLR" />
        </h1>
        <Profiler id="ActivityViewer" onRender={onActivityRender}>
          <div className={styles.mainGrid}>
            <div className={styles.classActivity}>
              <ClassActivity activityType={ACTIVITY_TYPES.BY_DOMAIN} />
            </div>
            <div className={styles.uniqueActivity}>
              <UniqueActivity className={styles.gridItem} />
            </div>
            <div className={styles.collaboration}>
              <ClassActivity activityType={ACTIVITY_TYPES.COLLABORATION} />
            </div>
          </div>
        </Profiler>
      </main>
    </Page>
  );
};

ActivityViewerPage.propTypes = {
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  isMonitorPerformanceFF: PropTypes.bool.isRequired,
  sendReactRenderStats: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    isMonitorPerformanceFF: getFFByName("HAP-8438-Monitor-React-Performance")(
      state
    ),
  }),
  (dispatch) => ({
    sendReactRenderStats: (options) => dispatch(sendReactRenderStats(options)),
  })
)(ActivityViewerPage);
