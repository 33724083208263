import {
  HighlightsWebsocket,
  WEBSOCKET_MESSAGE_TYPES,
} from "../HighlightsWebsocket";
import actionTypes from "../../actionTypes";
import _ from "lodash";
import { updateStudentCurrentScreen } from "../students/actions";
import { NO_CURRENT_SCREEN_AVAILABLE } from "./types";
import { getTokenSync } from "../../../apiCalls/jwtHandler";

const handleStudentScreenMessage =
  ({ data, channel }) =>
  (dispatch) => {
    const image = _.get(data, "blob.screen", null);
    const url = _.get(data, "data.url", null);
    const instanceId = _.get(data, "instance_id", null);

    if (image === NO_CURRENT_SCREEN_AVAILABLE) {
      dispatch(
        updateStudentCurrentScreen({
          studentId: channel,
          currentScreen: { url },
          instanceId,
        })
      );
    } else {
      dispatch(
        updateStudentCurrentScreen({
          studentId: channel,
          currentScreen: { image, url },
          instanceId,
        })
      );
    }
  };

// -------------------------
// Screenshot Websocket instance
// -------------------------

const screenshotWebsocket = new HighlightsWebsocket({
  name: "screenshot",
  websocketUrlConfigPropName: "ScreenshotBusURL",
  updateWebsocketStatusActionType:
    actionTypes.HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_STATUS,

  onWebsocketOpen:
    ({ classId, students, teacherId }) =>
    (dispatch) => {
      // send class information
      dispatch(
        screenshotWebsocket.sendMessageToWebsocket({
          students: students,
          user: teacherId,
          instance_id: teacherId,
          class_urn: classId,
          access_token: getTokenSync(),
        })
      );

      // dismiss error state if any
      dispatch(updateScreenshotWebsocketConnectionError(false));
    },
  onWebsocketMessage: (data) => (dispatch) => {
    const type = _.get(data, "type", "");
    const channel = _.get(data, "channel", ""); // this is student ID

    // a packet for individual student is received
    if (channel) {
      if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_SCREEN) {
        dispatch(handleStudentScreenMessage({ data, channel }));
      }
    }
  },

  onWebsocketReconnectStart: () => (dispatch) =>
    dispatch(updateScreenshotWebsocketConnectionError(true)),
});

// exported actions

export const closeScreenshotWebsocketConnection = () => (dispatch) => {
  dispatch(screenshotWebsocket.closeWebSocketConnection());
};

export const openScreenshotWebsocketConnection =
  ({ classId, students, teacherId }) =>
  (dispatch) =>
    dispatch(
      screenshotWebsocket.openWsConnection({ classId, students, teacherId })
    );

export const updateScreenshotWebsocketConnectionError = (isError) => ({
  type: actionTypes.HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_CONNECTION_ERROR,
  payload: isError,
});
