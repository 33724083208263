"use client";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { useIntl } from "react-intl";
import styles from "./Alert.module.scss";
import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../Button/Button";

export const ALERT_TYPES = {
  SUCCESS: "success",
  FAILURE: "failure",
  INFO: "info",
  WARNING: "warning",
};

/** @deprecated use `@hapara/ui/src/atomic/Alert` instead. */
export const Alert = ({
  type = ALERT_TYPES.SUCCESS,
  children,
  className = "",
  role = "alert",
  ariaLive = "assertive",
  ariaAtomic = "true",
  isVisible = true,
  close,
  tabIndex,
  dataTestId,
  focusOnRender = false,
  animated = true,
}) => {
  const intl = useIntl();
  const [shouldRender, setShouldRender] = useState(isVisible);
  const alertRef = useRef();

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
  }, [isVisible]);

  const onAnimationEnd = () => {
    if (!isVisible) {
      setShouldRender(false);
    } else {
      if (focusOnRender) {
        alertRef.current.focus();
      }
    }
  };

  if (!shouldRender) return null;

  return (
    <div
      className={classnames([
        styles.root,
        { [styles.close]: !isVisible, [styles.animated]: animated },
        className,
      ])}
      role={role ? role : null}
      aria-live={ariaLive ? ariaLive : null}
      aria-atomic={ariaAtomic ? ariaAtomic : null}
      onAnimationEnd={animated ? onAnimationEnd : null}
    >
      {close && (
        <Button
          icon="cross"
          alt={intl.formatMessage({
            defaultMessage: "Close",
            id: "rbrahO",
          })}
          ariaLabel={intl.formatMessage({
            defaultMessage: "Close alert",
            id: "cvfzg7",
          })}
          dataTestId={dataTestId ? `${dataTestId}-close` : null}
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.XSMALL}
          className={classnames([styles.closeButton])}
          onAction={close}
          tabIndex={tabIndex || 0}
        />
      )}
      <div
        data-test-id={dataTestId ? `${dataTestId}-text` : null}
        className={classnames(styles.body, {
          [styles.failure]: type === ALERT_TYPES.FAILURE,
          [styles.info]: type === ALERT_TYPES.INFO,
          [styles.warning]: type === ALERT_TYPES.WARNING,
        })}
        ref={alertRef}
        tabIndex={-1}
      >
        {children}
      </div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(_.values(ALERT_TYPES)),
  children: PropTypes.node,
  className: PropTypes.string,
  role: PropTypes.string,
  ariaLive: PropTypes.string,
  ariaAtomic: PropTypes.string,
  isVisible: PropTypes.bool,
  close: PropTypes.func,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
  focusOnRender: PropTypes.bool,
  animated: PropTypes.bool,
};

/** @deprecated use `@hapara/ui/src/atomic/Alert` instead. */
export default Alert;
