import _ from "lodash";
import { createSelector } from "reselect";

export const getApp = (state) => _.get(state, "app");

export const isGISLoading = createSelector(getApp, (app) =>
  _.get(app, "GISLoading")
);

export const isGISLoaded = createSelector(getApp, (app) =>
  _.get(app, "GISLoaded")
);

export const getAllFF = createSelector(getApp, (app) =>
  _.get(app, "featureFlags")
);

export const getFFByName = (name) =>
  createSelector(getAllFF, (ff) => _.get(ff, name, false));

export const isUserInitLoading = createSelector(getApp, (app) =>
  _.get(app, "userInitLoading")
);

export const isUserInitLoaded = createSelector(getApp, (app) =>
  _.get(app, "userInitLoaded")
);

export const isUserInitConfigLoading = createSelector(getApp, (app) =>
  _.get(app, "userInitConfigLoading")
);

export const isUserInitConfigLoaded = createSelector(getApp, (app) =>
  _.get(app, "userInitConfigLoaded")
);

export const getAllLabPrefs = createSelector(getApp, (app) =>
  _.get(app, "labPrefs")
);

export const getLabPrefByName = (name) =>
  createSelector(getAllLabPrefs, (prefs) => {
    const pref = _.find(prefs, (item) => item.pref === name);
    return pref ? _.get(pref, "enabled", false) : false;
  });

export const isAppError = createSelector(getApp, (app) =>
  _.get(app, "appError")
);

const getSuccessToast = createSelector(getApp, (app) =>
  _.get(app, "successToast")
);

export const isShowSuccessToast = createSelector(getSuccessToast, (st) =>
  _.get(st, "isVisible", false)
);

export const isShowExpandModalSuccessToast = createSelector(
  getSuccessToast,
  (st) => _.get(st, "isExpandModal", false)
);

export const getSuccessToastClassId = createSelector(getSuccessToast, (st) =>
  _.get(st, "classId")
);

export const getSuccessToastMessage = createSelector(getSuccessToast, (st) =>
  _.get(st, "message")
);

export const isInitialLoadError = createSelector(getApp, (app) =>
  _.get(app, "initialLoadError")
);

export const isAccessError = createSelector(getApp, (app) =>
  _.get(app, "accessError")
);

export const getWindowSize = createSelector(getApp, (app) =>
  _.get(app, "windowSize")
);
