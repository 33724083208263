import React, { useState, ForwardedRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./DurationInput.module.scss";

const createDurationOption = (minutes: number) => ({
  value: minutes,
  label:
    minutes < 90 ? (
      <FormattedMessage
        defaultMessage="{minutes} min"
        id="Rt7ew6"
        values={{ minutes }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{minutes} min ({hours} hrs)"
        id="UT1ZEg"
        values={{
          minutes,
          hours: (minutes / 60).toFixed(1),
        }}
      />
    ),
});

const DURATION_OPTIONS = [
  5, 10, 15, 30, 40, 50, 60, 70, 80, 90, 120, 150, 180,
].map(createDurationOption);

const DURATION_OPTIONS_EXTENDED = [
  ...DURATION_OPTIONS,
  ...Array.from({ length: 14 }, (_, i) => createDurationOption((i + 7) * 30)),
];

const MAX_DURATION = 180;
const MAX_DURATION_EXTENDED = 600;

interface DurationInputProps {
  duration: number | null;
  onDurationChange: (duration: number) => void;
  dataTestPrefix: string;
  hasExtendedOptions?: boolean;
}

export const DurationInput = ({
  duration,
  onDurationChange,
  dataTestPrefix,
  hasExtendedOptions = false,
}: DurationInputProps) => {
  const [focused, setFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = _.get(e, "target.value", null);
    const newDuration = inputVal ? _.toNumber(inputVal) : null;

    if (!_.isNaN(newDuration)) {
      onDurationChange(newDuration!);
    }
  };

  const ACTIVE_OPTIONS = hasExtendedOptions
    ? DURATION_OPTIONS_EXTENDED
    : DURATION_OPTIONS;
  const ACTIVE_MAX_DURATION = hasExtendedOptions
    ? MAX_DURATION_EXTENDED
    : MAX_DURATION;

  return (
    <div className={styles.inputField}>
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
        widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
        className={styles.recentTabsDropdown}
        classNameContent={classnames({
          [styles.hasExtendedOptions]: hasExtendedOptions,
        })}
        triggerComponent={(props) => (
          <InputDropdownTrigger
            className={styles.dropdownInput}
            handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e);
              setFocused(true);
            }}
            duration={duration}
            dataTestPrefix={dataTestPrefix}
            focused={focused}
            onBlur={() => {
              if (duration && duration > ACTIVE_MAX_DURATION) {
                onDurationChange(ACTIVE_MAX_DURATION);
              }
              if (duration && duration < 1 && duration !== null) {
                onDurationChange(1);
              }
              setFocused(false);
            }}
            {...props}
          />
        )}
        itemComponentList={ACTIVE_OPTIONS.map(
          ({ value, label }) =>
            ({ onClick, className }: any) =>
              (
                <div
                  className={classnames(styles.dropdownItem, {
                    [styles.itemSelected]: duration === value,
                  })}
                  onClick={() => {
                    onClick();
                    if (!_.isNaN(value)) {
                      onDurationChange(value);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                      onClick();
                      if (!_.isNaN(value)) {
                        onDurationChange(value);
                      }
                    }
                  }}
                  tabIndex={0}
                  data-test-id={`${dataTestPrefix}-Dropdown-Duration-Item`}
                >
                  {label}
                </div>
              )
        )}
      />
      <span className={styles.text}>
        <FormattedMessage defaultMessage="minutes" id="FFKIHh" />
      </span>
    </div>
  );
};

interface InputDropdownTriggerProps {
  className: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  duration: number | null;
  dataTestPrefix: string;
  focused: boolean;
  onBlur: () => void;
}

const InputDropdownTrigger = React.forwardRef(
  (props: InputDropdownTriggerProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      handleInputChange,
      duration,
      dataTestPrefix,
      focused,
      onBlur,
      ...rest
    } = props;

    const intl = useIntl();

    return (
      <span {...rest}>
        <input
          ref={ref}
          type="text"
          onChange={handleInputChange}
          data-test-id={`${dataTestPrefix}-Input-SessionDuration`}
          aria-label={intl.formatMessage({
            defaultMessage: "minutes",
            id: "FFKIHh",
          })}
          value={_.toString(duration)}
          className={className}
          onFocus={(e) => {
            if (!focused) {
              e.target.select();
            }
          }}
          autoFocus={focused}
          onBlur={onBlur}
          maxLength={3}
        />
      </span>
    );
  }
);
