import _ from "lodash";

export const getThumbnails = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.items");
export const isSavingThumbnailInProgress = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.savingThumbnailInProgress");
export const isSavingThumbnailError = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.savingThumbnailError");
export const isSavingThumbnailSuccess = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.savingThumbnailSuccess");
export const getThumbnailsPagesCount = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.pagesCount");
export const getThumbnailsCurrentPage = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.currentPage");
export const getInitialSearchTerm = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.initialSearchTerm");
export const areThumbnailsLoading = (state) =>
  _.get(state, "workspace.myworkspaces.thumbnails.itemsAreLoading");
