import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../app/actions";
import _ from "lodash";
import {
  postNewSession,
  postNewScheduledSession,
  getSessions,
  postReleaseSession,
  postReleaseStudentsFromSession,
  getGuideBrowsingRecentTabsHistory,
  postGuideBrowsingRecentTabsHistory,
  postEditGuideBrowsingSession,
  getGuideBrowsingTemplates,
  postNewGuideBrowsingTemplate,
  putExistingGuideBrowsingTemplate,
  deleteExistingGuideBrowsingTemplate,
  getScheduledSessionsList,
  updateScheduledSession,
  postScheduledSessionName,
  deleteSession,
  postNewRecurringScheduledSession,
} from "../../../apiCalls/highlights";
import { makeActionCreator } from "../../storeUtils";
import actionTypes from "../../actionTypes";
import { getActiveSessionListLoadingState } from "./selectors";
import { parseOldTemplateDataToNewFormat } from "./types";
import {
  getStudentEmailsByIds,
  getStudentIdsByEmails,
} from "../studentConfigs/selectors";
import { getUserFamilyName } from "../../user/selectors";
import { showSuccessToast } from "../../../state/app/actions";

export const startNewSession =
  ({ sessionDetails }) =>
  (dispatch) => {
    dispatch(hideAppError());

    postNewSession({ sessionDetails })
      .then((response) => {
        const classId = _.get(sessionDetails, "Class");
        if (classId) {
          dispatch(loadCurrentSessions({ classId }));
        }
        return response.data;
      })
      .catch((error) => {
        dispatch(showAppError(error));

        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };

export const startNewSessionSync =
  ({ sessionDetails }) =>
  (dispatch) => {
    return postNewSession({ sessionDetails }).then((response) => {
      const classId = _.get(sessionDetails, "Class");
      if (classId) {
        dispatch(loadCurrentSessions({ classId }));
      }
      return response.data;
    });
  };

export const loadCurrentSessions =
  ({ classId }) =>
  (dispatch, getState) => {
    const state = getState();
    const isActiveSessionListLoading = getActiveSessionListLoadingState(state);

    // because we poll this api in different places and periodically
    // do not load if a request is already in progress or jwt token is missing
    // to avoid multiple requests and non-legit 403s
    if (!isActiveSessionListLoading) {
      dispatch(hideAppError());
      dispatch({
        type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_PENDING,
      });

      return getSessions({ classId })
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_SUCCESS,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch(showAppError(error));
          dispatch({
            type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_ERROR,
          });

          if (_.get(error, "response.status") === 403) {
            // Not a valid user, show 403 screen
            dispatch(updateAccessError(true));
          }
        });
    } else {
      return Promise.resolve();
    }
  };

export const releaseSession =
  ({ sessionId }) =>
  (dispatch) => {
    dispatch(hideAppError());

    return postReleaseSession({ sessionId })
      .then((response) => response.data)
      .catch((error) => {
        dispatch(showAppError(error));

        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };

export const releaseStudentsFromSession =
  ({ studentIds, isPause }) =>
  (dispatch) =>
    postReleaseStudentsFromSession({ studentIds, isPause }).catch((error) => {
      dispatch(showAppError(error));

      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
    });

export const updateGuideBrowsingSession =
  ({ newSession }) =>
  (dispatch) =>
    postEditGuideBrowsingSession({ newSession });

export const loadGuideBrowsingRecentTabsHistory = () => (dispatch) => {
  return getGuideBrowsingRecentTabsHistory()
    .then((response) => response.data)
    .then((data) => {
      const urls = _.map(data, "url");
      dispatch({
        type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_RECENT_TABS_HISTORY_LOAD_SUCCESS,
        payload: urls,
      });
    })
    .catch((error) => {
      dispatch(showAppError(error));

      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
    });
};

export const addGuideBrowsingRecentTabsHistory =
  ({ urls }) =>
  (dispatch) => {
    return postGuideBrowsingRecentTabsHistory({ urls })
      .then((response) => response.data)
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };

export const showGuideBrowsingModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_GUIDE_BROWSING_MODAL_SHOW,
  "payload"
);

export const hideGuideBrowsingModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_GUIDE_BROWSING_MODAL_HIDE
);

export const loadGuideBrowsingTemplates =
  ({ classId }) =>
  (dispatch) => {
    dispatch(hideAppError());
    dispatch({
      type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_PENDING,
    });

    getGuideBrowsingTemplates({ classId })
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_SUCCESS,
          payload: _.map(data, (item) => parseOldTemplateDataToNewFormat(item)),
        });
      })
      .catch((error) => {
        dispatch(showAppError(error));
        dispatch({
          type: actionTypes.HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_ERROR,
        });

        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };

export const createNewGuideBrowsingTemplate =
  ({ classId, templateName, sessionData }) =>
  (dispatch) =>
    postNewGuideBrowsingTemplate({ classId, templateName, sessionData }).then(
      (response) => parseOldTemplateDataToNewFormat(response.data)
    );

export const updateGuideBrowsingTemplate =
  ({ id, classId, templateName, sessionData }) =>
  (dispatch) =>
    putExistingGuideBrowsingTemplate({
      id,
      classId,
      templateName,
      sessionData,
    }).then((response) => parseOldTemplateDataToNewFormat(response.data));

export const deleteGuideBrowsingTemplate =
  ({ id }) =>
  (dispatch) =>
    deleteExistingGuideBrowsingTemplate({ id });

export const loadScheduledSessionsList =
  ({ classId, fromTs, toTs, sortOrder, pageSize, page, showLoadingState }) =>
  (dispatch, getState) => {
    if (showLoadingState) {
      dispatch({
        type: actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_PENDING,
      });
    }
    const state = getState();
    return getScheduledSessionsList({
      classId,
      fromTs,
      toTs,
      sortOrder,
      pageSize,
      page,
    })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        } else {
          let resp = [];
          response.data.forEach((session) => {
            if (
              !session.Recipient.Students &&
              session.Recipient.StudentEmails &&
              session.Recipient.StudentEmails.length > 0
            ) {
              let rs = { ...session };
              rs.Recipient.Students = getStudentIdsByEmails(
                session.Recipient.StudentEmails
              )(state);
              resp.push(rs);
            } else {
              resp.push(session);
            }
          });

          if (page > 0) {
            dispatch({
              type: actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_MORE_SUCCESS,
              payload: resp,
            });
          } else {
            dispatch({
              type: actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_SUCCESS,
              payload: resp,
            });
          }
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const updateScheduledSessionName =
  ({ classId, sessionId, sessionName }) =>
  (dispatch) => {
    const sessionDetails = {
      ID: sessionId,
      SessionName: sessionName,
    };
    return postScheduledSessionName({
      classId,
      sessionDetails,
    })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
        dispatch({
          type: actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_UPDATE_SESSION_NAME_LOCALLY,
          payload: { sessionId, sessionName },
        });
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const deleteSessionById =
  ({ sessionId }) =>
  (dispatch) => {
    return deleteSession({
      sessionId,
    })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const scheduleSession =
  ({ sessionDetails, classId }) =>
  (dispatch, getState) => {
    const state = getState();
    const studentEmails = getStudentEmailsByIds(sessionDetails.Students)(state);
    const updatedDetails = {
      ...sessionDetails,
      StudentEmails: studentEmails,
      TeacherName: getUserFamilyName(state),
    };
    if (updatedDetails.ID && updatedDetails.ID !== "") {
      return updateScheduledSession({
        classId,
        sessionDetails: updatedDetails,
      });
    } else {
      return postNewScheduledSession({
        isSession: true,
        messageBody: updatedDetails,
      });
    }
  };

//TODO: Make this the main scheduleSession function when GBE-01-class-view-page is fully rolled out
export const scheduleSessionGBE =
  ({ sessionDetails, classId, successToastMessage }) =>
  (dispatch, getState) => {
    const state = getState();
    const studentEmails = getStudentEmailsByIds(sessionDetails.Students)(state);
    const updatedDetails = {
      ...sessionDetails,
      StudentEmails: studentEmails,
      TeacherName: getUserFamilyName(state),
    };
    if (updatedDetails.ID && updatedDetails.ID !== "") {
      return updateScheduledSession({
        classId,
        sessionDetails: updatedDetails,
      });
    } else {
      return postNewScheduledSession({
        isSession: true,
        messageBody: updatedDetails,
      })
        .then(() => {
          dispatch(showSuccessToast(successToastMessage));
          dispatch(reloadScheduledSessionAfterAddingNew());
        })
        .catch((error) => {
          dispatch(showAppError(error));
          throw Error(error);
        });
    }
  };

export const scheduleRecurringSession =
  ({ sessionDetails, successToastMessage }) =>
  (dispatch, getState) => {
    const state = getState();
    const studentEmails = getStudentEmailsByIds(sessionDetails.students)(state);
    const updatedDetails = {
      ...sessionDetails,
      studentEmails: studentEmails,
      teacherName: getUserFamilyName(state),
    };
    return postNewRecurringScheduledSession({
      messageBody: updatedDetails,
    })
      .then(() => {
        dispatch(showSuccessToast(successToastMessage));
        dispatch(reloadScheduledSessionAfterAddingNew());
      })
      .catch((error) => {
        dispatch(showAppError(error));
      });
  };

export const reloadScheduledSessionAfterAddingNew = makeActionCreator(
  actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_RELOAD_REQUEST
);
