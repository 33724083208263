import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ContentLoading.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import HapReactIcon from "../../atomic/icon/hapReactIcon";

const ContentLoading = ({
  className,
  loadFunc,
  errorMessageText,
  emptyMessageText,
  children,
}) => {
  const intl = useIntl();
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultErrorMessageText = intl.formatMessage({
    defaultMessage: "Sorry, there was a problem loading items.",
    id: "7Ir9Eo",
  });

  const defaultEmptyMessageText = intl.formatMessage({
    defaultMessage: "No items found",
    id: "WSCjCg",
  });

  const handleLoad = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    setEmpty(false);
    try {
      const hasResults = await loadFunc();
      setEmpty(!hasResults);
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setError, setEmpty, loadFunc]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);
  return (
    <>
      {(isError || isLoading || isEmpty) && (
        <div className={classnames(styles.root, className)}>
          {isError && (
            <>
              <div className={styles.text}>
                <HapReactIcon
                  className={styles.textIcon}
                  svg="fill-circle-exclamation"
                  width={16}
                  height={16}
                />
                <div>{errorMessageText ?? defaultErrorMessageText}</div>
              </div>
            </>
          )}
          {isLoading && (
            <div className={styles.container}>
              <LinearProgress
                className={styles.progressbar}
                aria-hidden={true}
              />
              <div className={styles.text}>
                <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
              </div>
            </div>
          )}
          {isEmpty && (
            <>
              <div className={styles.text}>
                {emptyMessageText ?? defaultEmptyMessageText}
              </div>
            </>
          )}
        </div>
      )}
      {!isLoading && !isEmpty && !isError && <>{children}</>}
    </>
  );
};

ContentLoading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loadFunc: PropTypes.func.isRequired,
  isTryAgainVisible: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string.isRequired,
  errorMessageText: PropTypes.string,
  emptyMessageText: PropTypes.string,
};

export default ContentLoading;
