import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import classnames from "classnames";
import { APP_PAGES } from "../../../../../state/router/types";
import styles from "./LeftSidePanel.module.scss";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  Action,
  Section as SectionType,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { TILE_ICONS } from "../../../../../state/resources/types";
import {
  CollapsableNavItem,
  CollapsableNavItemDivider,
} from "./CollapsableNavItem";
import { NavigationLocation } from "../../../../containers/Workspace/BoardPage/BoardPage";

type LeftSidePanelProps = {
  sections?: (SectionType | null)[];
  dataTestIdPrefix: string;
  boardImage: string;
  imageAltText: string;
  isOpen: boolean;
  setIsOpen: () => void;
  permissions: Action[];
};

export const LeftSidePanel = ({
  sections,
  boardImage,
  imageAltText,
  isOpen,
  setIsOpen,
  dataTestIdPrefix,
  permissions,
}: LeftSidePanelProps) => {
  const { boardId } = useParams<{ boardId?: string }>();
  const sectionsSorted = _.sortBy(sections, ["Order"]);
  if (_.isEmpty(sectionsSorted[0]?.Title.trim())) {
    sectionsSorted.shift();
  }

  const [panelTopRef, hasPanelReachedWindowTop] = useInView({ threshold: 0 });
  const navigationPage = NavigationLocation(window.location.pathname);

  return (
    <div
      className={classnames(styles.root, {
        [styles.collapsed]: !isOpen,
      })}
      data-test-id={`${dataTestIdPrefix}-LeftSidePanel-root`}
    >
      {/* The intended use of this container is to make the left panel sticky and of
      full page height when scrolling down the page (aka not the sections scroll bar).
      Currently, has no effect as the header bar always sticks at the top when scrolling
      down or up the page. In future, we may make the header not to stick around when scrolling,
      so that's when this container be of more use. Please have a look at the myworkspaces/discover
      page to see how it works currently.
      */}
      <div ref={panelTopRef} />
      {/* div tag for shadowing the main area when left panel is expanded */}
      {isOpen && (
        <div
          className={styles.overlay}
          onClick={setIsOpen}
          data-test-id={`${dataTestIdPrefix}-LeftSidePanel-overlay`}
        ></div>
      )}
      <div
        className={classnames(styles.rootInner, {
          [styles.sticky]: !hasPanelReachedWindowTop,
          [styles.collapse]: !isOpen,
        })}
      >
        <Button
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.XSMALL}
          data-test-id={`${dataTestIdPrefix}-LeftSidePanel-Collapse-menu`}
          onClick={setIsOpen}
          icon={isOpen ? "arrow-angle-expand" : "arrow-angle-collapse"}
          label={isOpen ? "Collapse menu" : ""}
          aria-label={isOpen ? "" : "Expand menu"}
        />

        <div
          className={classnames(styles.thumbnail, {
            [styles.icon]: !isOpen,
          })}
          data-test-id={`${dataTestIdPrefix}-LeftSidePanel-image`}
        >
          {boardImage && (
            <img className={styles.img} src={boardImage} alt={imageAltText} />
          )}
          {!boardImage && (
            <div
              className={classnames(styles.openBookContainer, {
                [styles.icon]: !isOpen,
              })}
            >
              <HapReactIcon
                className={classnames(styles.openBook, {
                  [styles.resizedSvg]: !isOpen,
                })}
                svg={TILE_ICONS.WORKSPACE}
                width="100%"
                height="100%"
              />
            </div>
          )}
        </div>
        {permissions.includes(Action.CanEdit) && (
          <>
            <div role="navigation" aria-label="Workspace navigation, 5 items">
              <CollapsableNavItem
                title="Board"
                isExpanded={isOpen}
                icon="workspace-icon-gradient"
                selectedIcon="workspace-icon-gradient-white"
                size="16"
                dataTestId={dataTestIdPrefix + "-NavLink-Boards"}
                href={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}`}
                isSelected={navigationPage === "board"}
              />
              <CollapsableNavItem
                title="Members"
                isExpanded={isOpen}
                icon="members-gradient"
                selectedIcon="members-gradient-white"
                size="16"
                dataTestId={dataTestIdPrefix + "-NavLink-Members"}
                href={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/members`}
                isSelected={navigationPage === "members"}
              />
              <CollapsableNavItem
                title="Groups"
                isExpanded={isOpen}
                icon="profile-group-gradient"
                selectedIcon="profile-group-gradient-white"
                size="16"
                dataTestId={dataTestIdPrefix + "-NavLink-Groups"}
                href={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/groups`}
                isSelected={navigationPage === "groups"}
              />
              {/*Update App.tsx to navigation to the correnct component */}
              <CollapsableNavItem
                title="Learning evidence"
                isExpanded={isOpen}
                icon="checklist-gradient"
                selectedIcon="checklist-gradient-white"
                size="16"
                dataTestId={dataTestIdPrefix + "-NavLink-LearningEvidence"}
                href={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/learningevidence`}
                isSelected={navigationPage === "learningevidence"}
              />
              {/*Update App.tsx to navigation to the correnct component */}
              <CollapsableNavItem
                title="Settings"
                isExpanded={isOpen}
                icon="cog-fill"
                selectedIcon="cog-fill-gradient-white"
                size="16"
                dataTestId={dataTestIdPrefix + "-NavLink-Settings"}
                href={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/settings`}
                isSelected={navigationPage === "settings"}
              />
            </div>
            <CollapsableNavItemDivider />
          </>
        )}

        {isOpen &&
          navigationPage === "board" &&
          sections &&
          sectionsSorted.length > 0 && (
            <div data-test-id={`${dataTestIdPrefix}-LeftSidePanel-sections`}>
              <h1
                className={styles.sectionsTitle}
              >{`Sections (${sectionsSorted.length})`}</h1>
              <ul className={styles.sectionsList}>
                {sectionsSorted.map((section, ind) => {
                  const title = section?.Title.trim() || "Untitled";
                  return (
                    <li key={section?.ID} className={styles.navItem}>
                      <Link
                        to={`${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/s/${section?.ID}`}
                        className={styles.sectionTitle}
                        aria-label={title}
                      >
                        {title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
      </div>
    </div>
  );
};

export default LeftSidePanel;
