import _ from "lodash";
import { createSelector } from "reselect";
import { SESSION_TYPES } from "./types";

const getGuideBrowsing = (state) => _.get(state, "highlights.sessions");

const getSessionList = createSelector(getGuideBrowsing, (gb) =>
  _.get(gb, "sessionList", [])
);

export const getActiveGuideBrowsingSessionList = createSelector(
  getSessionList,
  (list) =>
    _.filter(
      list,
      (item) =>
        !!item.Active &&
        (item.Type === SESSION_TYPES.FILTER ||
          item.Type === SESSION_TYPES.FOCUS ||
          item.Type === SESSION_TYPES.LOCK)
    )
);

export const getActiveGuideBrowsingSessionIds = createSelector(
  getActiveGuideBrowsingSessionList,
  (activeSessions) => _.map(activeSessions, (item) => item.ID)
);

export const getActivePauseSessionList = createSelector(
  getSessionList,
  (list) =>
    _.filter(list, (item) => !!item.Active && item.Type === SESSION_TYPES.PAUSE)
);

export const getIsPauseSession = createSelector(
  getActivePauseSessionList,
  (list) => list.length > 0
);

export const getRecentTabsHistory = createSelector(getGuideBrowsing, (gb) =>
  _.get(gb, "recentTabs", [])
);

const getGuideBrowsingModalData = createSelector(getGuideBrowsing, (gb) =>
  _.get(gb, "modalData", {})
);

export const getGuideBrowsingModalStatus = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "isOpen", false)
);

export const getGuideBrowsingModalMode = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "modalMode")
);

export const getGuideBrowsingModalSelectedTemplate = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "selectedTemplate")
);

export const getGuideBrowsingModalIfScheduled = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "isScheduled", false)
);

export const getGuideBrowsingModalIsHideBackButton = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "isHideBackButton", false)
);

export const getGuideBrowsingModalSessionType = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "sessionType")
);

export const getGuideBrowsingModalSessionData = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "sessionData")
);

export const isGuideBrowsingDuplicateSession = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "isDuplicateSession")
);

export const getGuideBrowsingModalSelectedStudent = createSelector(
  getGuideBrowsingModalData,
  (gbm) => _.get(gbm, "selectedStudent")
);

export const getActiveSessionListLoadingState = createSelector(
  getGuideBrowsing,
  (gb) => _.get(gb, "isSessionListLoading", false)
);

export const getGuideBrowsingTemplateList = createSelector(
  getGuideBrowsing,
  (gb) => _.get(gb, "templates", [])
);

export const getScheduledSessionListLoadingState = createSelector(
  getGuideBrowsing,
  (gb) => _.get(gb, "isScheduledSessionsLoading", false)
);

export const getScheduledSessionList = createSelector(getGuideBrowsing, (gb) =>
  _.get(gb, "scheduledSessions", [])
);

export const getScheduledSessionReloadRequest = createSelector(
  getGuideBrowsing,
  (gb) => _.get(gb, "isReloadScheduledSessionsRequest", false)
);

export const getScheduledSessionById = (sessionId) =>
  createSelector(getScheduledSessionList, (sessions) => {
    return _.find(sessions, (s) => s.ID === sessionId);
  });
