interface TemplatesAction {
  GET_TEMPLATES: string;
  APPEND_TEMPLATES: string;
  HIDE_TEMPLATES_PANEL: string;
  SHOW_TEMPLATES_PANEL: string;
}

const TEMPLATES: TemplatesAction = {
  GET_TEMPLATES: "GET_TEMPLATE",
  APPEND_TEMPLATES: "APPEND_TEMPLATES",
  HIDE_TEMPLATES_PANEL: "HIDE_TEMPLATES_PANEL",
  SHOW_TEMPLATES_PANEL: "SHOW_TEMPLATES_PANEL",
};

type GetTemplatesType = typeof TEMPLATES.GET_TEMPLATES;
type HideTemplatesPanelType = typeof TEMPLATES.HIDE_TEMPLATES_PANEL;
type ShowTemplatesPanelType = typeof TEMPLATES.SHOW_TEMPLATES_PANEL;

interface Template {
  ID: string;
  Creator: string;
  CreatedDatetime: string;
  ClassURN: string;
  Name: string;
  Type: "focus" | "filter";
  Links: string[];
  FocusSessionDetails: {
    FocusType: "whole_site" | "links";
    EndSessionKeepAllTabs: boolean;
    EndSessionRestoreOriginal: boolean;
  };
  RecipientType: "class" | "students" | "groups";
  SelectedTime: number;
}

interface InitState {
  templatesList: Template[];
  showTemplatesPanel: boolean;
  templatesPanelScheduled: boolean;
  isMoreTemplatesAvailable: boolean;
  offset: number;
}

interface Templates {
  templates: Template[];
}

interface FetchTemplatesListActionType {
  type: GetTemplatesType;
  payload: Templates;
}
interface ShowTemplatesPanelActionType {
  type: ShowTemplatesPanelType;
}

interface HideTemplatesPanelActionType {
  type: HideTemplatesPanelType;
}

type Actions =
  | FetchTemplatesListActionType
  | ShowTemplatesPanelActionType
  | HideTemplatesPanelActionType;

export const TEMPLATE_TILE_ID = "hl-guidebrowsing-template-id-";

export { TEMPLATES };

export type {
  InitState,
  Template,
  Templates,
  GetTemplatesType,
  HideTemplatesPanelType,
  ShowTemplatesPanelType,
  Actions,
};
