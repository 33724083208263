import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./WorkspaceTile.module.scss";
import useScrollToHook from "../../../../../hooks/useScrollToHook";
import WsTileContextMenuDropdown from "../WsTileContextMenuDropdown/WsTileContextMenuDropdown";
import WSStatusDropdown from "../WSStatusDropdown/WSStatusDropdown";
import WorkspaceTileLabels from "../WorkspaceTileLabels/WorkspaceTileLabels";
import WorkspaceThumbnailModal from "../WorkspaceThumbnailModal/WorkspaceThumbnailModal";
import {
  wsItemType,
  MYWORKSPACES_USER_ROLES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "../../../../../state/workspace/myworkspaces/list/navigation";
import { THUMBNAIL_SIZE } from "../../../../../state/workspace/myworkspaces/thumbnails/types";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { formatDate } from "../../../../../utils";
import {
  pushQueryToHistory,
  replaceQueryInHistory,
} from "../../../../../state/router/navigation";
import { getWindowSize } from "../../../../../state/app/selectors";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../../state/app/types";
import { getActiveItemId } from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  MYWORKSPACES_RIGHT_PANEL_ID,
  MYWORKSPACES_TILE_ID,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { saveBoardThumbnail } from "../../../../../state/workspace/myworkspaces/thumbnails/actions";
import {
  isSavingThumbnailError,
  isSavingThumbnailInProgress,
  isSavingThumbnailSuccess,
} from "../../../../../state/workspace/myworkspaces/thumbnails/selectors";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { TILE_ICONS } from "../../../../../state/resources/types";
import { getFFByName } from "../../../../../state/app/selectors";

export const WorkspaceTile = ({
  item,
  dataTestIdPrefix = "Ws-Tile",
  isShowLabels = true,
  isShowProgress = true,
  windowSize,
  activeItemId,
  saveThumbnail,
  isSavingThumbnailInProgress = false,
  isSavingThumbnailError = false,
  isSavingThumbnailSuccess = false,
  isWSRFF,
  isWSSubPathRedirectEnabledFF,
}) => {
  const [modalIsOpen, setModalOpen] = useState(false);
  const isActive = item && activeItemId === item.Id;
  const { containerRef, topRef } = useScrollToHook(isActive, window);

  const eligibleToEdit = item.Role === MYWORKSPACES_USER_ROLES.EDITOR;
  const tileId = `${MYWORKSPACES_TILE_ID}${item.Id}`;

  const closeRightPanel = () => {
    const tileElement = document.getElementById(tileId);
    if (tileElement) tileElement.focus();
    replaceQueryInHistory({
      selected_ws: MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.selected_ws,
    });
  };

  const openRightPanel = () => {
    if (activeItemId) {
      replaceQueryInHistory({
        selected_ws: item.Id,
      });
    } else {
      pushQueryToHistory({
        selected_ws: item.Id,
      });
    }
    const panelElement = document.getElementById(MYWORKSPACES_RIGHT_PANEL_ID);
    if (panelElement) panelElement.focus();
  };

  const ModifiedDate = (
    <div className={styles.modifiedDate}>
      <span>
        <FormattedMessage defaultMessage="Modified:" id="DFY8tS" />
      </span>
      <span className={styles.modifiedDateFormatted}>
        {formatDate(item.ModifiedDatetime)}
      </span>
    </div>
  );

  const getWsURL = () => {
    return isWSRFF
      ? `${process.env.REACT_APP_HSUITE_BASE_URL}/workspace/w/${item.Id}`
      : isWSSubPathRedirectEnabledFF
      ? `${process.env.REACT_APP_HSUITE_BASE_URL}/ws/#/workspace/w/${item.Id}`
      : `${process.env.REACT_APP_OLD_WORKSPACE_URL}/#/workspace/w/${item.Id}`;
  };

  return (
    <article
      className={classnames(styles.root, { [styles.active]: isActive })}
      ref={containerRef}
    >
      <div className={styles.content}>
        <div className={styles.imgContainer} data-test-id="Ws-BoardThumbnail">
          <div
            className={classnames(styles.sectionImage, {
              [styles.pointer]: eligibleToEdit,
            })}
            ref={topRef}
            onClick={() => {
              if (eligibleToEdit) setModalOpen(true);
            }}
          >
            {item.BoardThumbnail && (
              <img
                src={`${item.BoardThumbnail}&w=${THUMBNAIL_SIZE * 2}&h=${
                  THUMBNAIL_SIZE * 2
                }`}
                width="100%"
                height="100%"
                alt=""
              />
            )}
            {!item.BoardThumbnail && (
              <div className={styles.noThumbnail}>
                <HapReactIcon
                  className={styles.icon}
                  svg={TILE_ICONS.WORKSPACE}
                />
              </div>
            )}
            {eligibleToEdit && (
              <div className={styles.editThumbnailBtnContainer}>
                <Button
                  icon="pencil"
                  size={BUTTON_SIZES.SMALL}
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  dataTestId={`Ws-EditPanelThumbnail-SelectThumbnail-${
                    item.BoardThumbnail ? "Change" : "Add"
                  }`}
                  alt="Edit thumbnail"
                  aria-label="Edit thumbnail"
                  className={styles.whiteBg}
                  onAction={() => {
                    setModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.sectionText}>
          <div className={styles.section1}>
            {isShowProgress && eligibleToEdit && (
              <div>
                <Button
                  icon="checklist"
                  size={BUTTON_SIZES.SMALL}
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  dataTestId="ws-MyWorkspaces-ProgressButton-OpenPanel"
                  label={windowSize >= WINDOW_SIZE_TYPES.S ? "Progress" : null}
                  onAction={() => {
                    if (isActive) {
                      closeRightPanel();
                    } else {
                      openRightPanel();
                    }
                  }}
                  id={tileId}
                />
              </div>
            )}
            {item.MyAvailableActions && item.MyAvailableActions.length > 0 && (
              <WsTileContextMenuDropdown item={item} />
            )}
          </div>
          <div className={styles.section2}>
            <div className={styles.s2_content}>
              <a
                className={classnames(styles.s2_titleLink, {
                  [styles.s2_titleOnly]: !item.Description,
                })}
                href={getWsURL()}
                data-test-id={`${dataTestIdPrefix}-Link-Header`}
              >
                <h2
                  className={styles.s2_titleHeading}
                  data-test-id={`${dataTestIdPrefix}-Title`}
                >
                  {stripHtmlTagsFromString(item.Title)}
                </h2>
              </a>
              {windowSize >= WINDOW_SIZE_TYPES.S && item.Description && (
                <div
                  className={styles.s2_description}
                  data-test-id={`${dataTestIdPrefix}-Description`}
                >
                  {stripHtmlTagsFromString(item.Description)}
                </div>
              )}
            </div>
            {windowSize >= WINDOW_SIZE_TYPES.S && isShowLabels && (
              <WorkspaceTileLabels item={item} />
            )}
          </div>
          <div className={styles.section3}>
            {!item.IsArchived && eligibleToEdit && (
              <WSStatusDropdown item={item} />
            )}
            {item.IsArchived && (
              <div className={styles.s3_statusLabel}>
                <FormattedMessage defaultMessage="Archived" id="0HT+Ib" />
              </div>
            )}
            {windowSize >= WINDOW_SIZE_TYPES.S && ModifiedDate}
          </div>
        </div>
      </div>
      <div className={styles.colourBorder} />
      {modalIsOpen && (
        <WorkspaceThumbnailModal
          isOpen={modalIsOpen}
          onClose={() => setModalOpen(false)}
          onSave={(updatedThumbnail) =>
            saveThumbnail(item.Id, updatedThumbnail)
          }
          predefined={{ url: item.BoardThumbnail, alt: item.BoardThumbnailAlt }}
          isSavingThumbnailInProgress={isSavingThumbnailInProgress}
          isSavingThumbnailError={isSavingThumbnailError}
          isSavingThumbnailSuccess={isSavingThumbnailSuccess}
        />
      )}
    </article>
  );
};

WorkspaceTile.propTypes = {
  item: wsItemType.isRequired,
  dataTestIdPrefix: PropTypes.string,
  isShowLabels: PropTypes.bool,
  isShowProgress: PropTypes.bool,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
  activeItemId: PropTypes.string,
  isSavingThumbnailInProgress: PropTypes.bool,
  isSavingThumbnailError: PropTypes.bool,
  isSavingThumbnailSuccess: PropTypes.bool,
  saveThumbnail: PropTypes.func.isRequired,
  isWSRFF: PropTypes.bool.isRequired,
  isWSSubPathRedirectEnabledFF: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    windowSize: getWindowSize(state),
    activeItemId: getActiveItemId(state),
    isSavingThumbnailInProgress: isSavingThumbnailInProgress(state),
    isSavingThumbnailError: isSavingThumbnailError(state),
    isSavingThumbnailSuccess: isSavingThumbnailSuccess(state),
    isWSRFF: getFFByName("HAP-9580-WS-Rewrite-FE")(state),
    isWSSubPathRedirectEnabledFF: getFFByName("HAP-11276-ws-subpath")(state),
  }),
  (dispatch) => ({
    saveThumbnail: (boardId, updatedThumbnail) =>
      dispatch(saveBoardThumbnail(boardId, updatedThumbnail)),
  })
)(WorkspaceTile);
