import React from "react";
import { connect } from "react-redux";
import styles from "./CreateForStudentsPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import Header from "../../../partial/Header/Header";
import Page from "../../../partial/PageContainer/PageContainer";
import CreateForm from "../../../partial/Workspace/Create/CreateForm/CreateForm";
import { MAIN_CONTENT_ID } from "../../../../consts";
import ConfigHandler from "../../Library/ConfigHandler";

const CreateForStudentsPage = () => {
  return (
    <>
      <ConfigHandler />
      <Page
        header={<Header />}
        title={APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.title}
        isPageLoaded={true}
        isPageLoadingError={false}
      >
        <main
          className={styles.root}
          data-test-id="Hs-Pages-WorkspaceCreateForStudentsPage"
          id={MAIN_CONTENT_ID}
          tabIndex="-1"
        >
          <CreateForm isPl={false} />
        </main>
      </Page>
    </>
  );
};

CreateForStudentsPage.propTypes = {};

export default connect()(CreateForStudentsPage);
