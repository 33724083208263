import { useEffect, useState } from "react";

/** This hook provides a convenient interface for storing and retrieving values
 * from `localStorage`. It listens for 'storage' events and automatically updates
 * the state if the associated value in localStorage is changed externally. */
export const useLocalStorage = <T>(key: string) => {
  const storedValue = getStoredValue(key);
  const [value, setValue] = useState<T | null>(storedValue);

  const handleSetValue = (newValue: T) => {
    localStorage.setItem(key, JSON.stringify(newValue));
    setValue(newValue);
  };

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === key) {
      if (event.newValue) {
        try {
          setValue(JSON.parse(event.newValue));
        } catch (error) {
          console.error("[useLocalStorage] Error parsing new value:", error);
          setValue(null);
        }
      } else {
        setValue(null);
      }
    }
  };

  useEffect(() => {
    setValue(getStoredValue(key));

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return [value, handleSetValue] as [T | null, (newValue: T | null) => void];
};

const getStoredValue = (key: string) => {
  try {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null && storedValue !== undefined) {
      return JSON.parse(storedValue);
    }
  } catch (error) {
    console.error("[useLocalStorage] Error parsing stored value:", error);
  }
  return null;
};
