import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import styles from "./ActionAddRemoveFromLibrary.module.scss";
import { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import {
  hideAppError,
  showAppError,
  showSuccessToast,
  hideSuccessToast,
} from "../../../../state/app/actions";
import {
  addToLibraryAction,
  showResourceRemoveFromLibraryModal,
} from "../../../../state/resources/actions";
import { dropdownItemPropsType } from "../../../../state/resources/types";
import { FocusContext } from "@hapara/ui/src/components/utils";

const ActionAddRemoveFromLibrary = ({
  id,
  dataTestPrefix,
  isAddedToLibrary,
  isDropdownAction = false,
  dropdownItemProps,
  addToLibraryAction,
  showSuccessMessage,
  hideSuccessMessage,
  showErrorMessage,
  hideErrorMessage,
  showRemoveFromLibraryModal,
}) => {
  const [isAddInProgress, setIsAddInProgress] = useState(false);

  const actionLabel = isAddedToLibrary
    ? "Remove from Library"
    : "Add to Library";
  const actionIcon = isAddedToLibrary ? "circle-minus" : "circle-plus";
  const actionDataTestId = isAddedToLibrary
    ? `${dataTestPrefix}-RemoveFromLibrary-Button`
    : `${dataTestPrefix}-AddToLibrary-Button`;

  const focusContext = useContext(FocusContext);

  const addToLibrary = (itemId) => {
    setIsAddInProgress(true);
    hideErrorMessage();
    hideSuccessMessage();
    addToLibraryAction({ itemId })
      .then(() => {
        if (isDropdownAction) {
          showSuccessMessage();
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => {
        if (!isDropdownAction) {
          setIsAddInProgress(false);
        }
        focusContext.restoreFocus();
      });
  };

  const handleActionOnClick = () => {
    if (isAddedToLibrary) {
      showRemoveFromLibraryModal({
        resourceId: id,
      });
    } else {
      addToLibrary(id);
    }
  };

  return (
    <>
      {!isDropdownAction && (
        <MemorisedFocusButton
          label={actionLabel}
          icon={actionIcon}
          type={BUTTON_TYPES.SECONDARY}
          data-test-id={actionDataTestId}
          onClick={handleActionOnClick}
          isLoading={!isAddedToLibrary && isAddInProgress}
          isDisabled={!isAddedToLibrary && isAddInProgress}
          isFullWidth={true}
        />
      )}

      {isDropdownAction && (
        <button
          type="button"
          data-test-id={actionDataTestId}
          {...dropdownItemProps}
          onClick={(e) => {
            e.stopPropagation();
            handleActionOnClick();
            dropdownItemProps.onClick();
          }}
          className={classnames(dropdownItemProps.className, {
            [styles.removeDropdownAction]: isAddedToLibrary,
          })}
        >
          {actionLabel}
        </button>
      )}
    </>
  );
};

ActionAddRemoveFromLibrary.propTypes = {
  id: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  isAddedToLibrary: PropTypes.bool.isRequired,
  isDropdownAction: PropTypes.bool,
  dropdownItemProps: dropdownItemPropsType,
  // connected
  showErrorMessage: PropTypes.func.isRequired,
  hideErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
  addToLibraryAction: PropTypes.func.isRequired,
  showRemoveFromLibraryModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showErrorMessage: (error) => dispatch(showAppError(error)),
    hideErrorMessage: () => dispatch(hideAppError()),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
    addToLibraryAction: ({ itemId }) =>
      dispatch(addToLibraryAction({ itemId })),
    showRemoveFromLibraryModal: (options) =>
      dispatch(showResourceRemoveFromLibraryModal(options)),
  })
)(ActionAddRemoveFromLibrary);
