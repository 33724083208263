import PropTypes from "prop-types";
import { MY_RESOURCES_URL_KEYS } from "./navigation";

export const myResourcesItemType = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Description: PropTypes.string,
  Link: PropTypes.string,
  Thumbnail: PropTypes.string,
});

export const MY_RESOURCES_RIGHT_PANEL_ID = "lb-my-resources-right-panel";
export const MY_RESOURCES_TILE_ID = "lb-my-resources-tile-id-";

export const MY_RESOURCES_FILTER_TYPES = {
  CLASS: {
    title: "Class",
    value: "classId",
    paramName: MY_RESOURCES_URL_KEYS.class.key,
  },
};

export const RESOURCE_AUTH_ERRORS = {
  SYSTEM: "We're having trouble loading this resource.",
  FORBIDDEN: "This resource is no longer available.",
};
