import React from "react";
import { useSelector } from "react-redux";

// SELECTORS
import { getClassId } from "../../../../state/shared/selectors";
import { getFFByName } from "../../../../state/app/selectors";

// UTILS | OTHER
import styles from "./NoStudentsInfoPanel.module.scss";
import { FormattedMessage } from "react-intl";

export const NoStudentsInfoPanel: React.FC = () => {
  const selectedClassId = useSelector(getClassId);

  const classInfoHref = `${process.env.REACT_APP_HSUITE_BASE_URL}/class-info/${selectedClassId}`;

  return (
    <div className={styles.root} data-test-id="hs-NoStudentsInfoPanel">
      <div className={styles.title}>
        <FormattedMessage
          defaultMessage="There are no students in this class"
          id="rYTmUD"
        />
      </div>
      <div className={styles.text}>
        <FormattedMessage
          defaultMessage="You can add students to this class in <a>Class Info</a>"
          id="S2TTi/"
          values={{
            a: (chunk) => (
              <a
                href={classInfoHref}
                data-test-id="hs-NoStudentsInfoPanel-ClassInfoLink"
              >
                {chunk}
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};
