import React from "react";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

type BlockForClassActionsProps = {
  onSave: () => void;
  onClose: () => void;
  isSaveDisabled: boolean;
  dataTestIdPrefix: string;
  hasActiveClassSession: boolean;
};

export const BlockForClassActions = ({
  onSave,
  onClose,
  isSaveDisabled,
  dataTestIdPrefix,
  hasActiveClassSession,
}: BlockForClassActionsProps) => {
  const intl = useIntl();
  return (
    <>
      <Button
        label={intl.formatMessage({ defaultMessage: "Cancel", id: "47FYwb" })}
        type="secondary"
        onAction={onClose}
        dataTestId={`${dataTestIdPrefix}-cancel`}
      />
      <Button
        onClick={onSave}
        isDisabled={isSaveDisabled}
        type="danger"
        label={
          hasActiveClassSession
            ? intl.formatMessage({
                defaultMessage: "Add site to existing filter session",
                id: "rZireu",
              })
            : intl.formatMessage({
                defaultMessage: "Block for the class",
                id: "Ia75cs",
              })
        }
        dataTestId={`${dataTestIdPrefix}-confirm`}
      />
    </>
  );
};
