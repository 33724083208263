import PropTypes from "prop-types";

export const LIBRARY_PERMISSIONS = {
  LIBRARY_RESOURCES_UPLOAD: "access.library.resource.upload",
  LIBRARY_RESOURCES_ADD: "access.library.resource.add",
  LIBRARY_WORKSPACE_ADD: "access.library.workspace.add",
  LIBRARY_COLLECTIONS_MANAGE: "access.library.collections.manage",
};

export const classItemType = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  IsTeaching: PropTypes.bool,
});
