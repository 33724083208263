import React, { ReactChild, ReactFragment, ReactPortal } from "react";

export const isComponentTypeOf = (
  child: ReactChild | ReactFragment | ReactPortal,
  componentName: string
) =>
  React.isValidElement(child) &&
  typeof child.type !== "string" &&
  (child.type.name === componentName ||
    // TODO: type.name is altered during production build, implement a more robust way of counting children.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child.type as any).displayName === componentName);
