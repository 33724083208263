import _ from "lodash";
import { createSelector } from "reselect";
import { APP_PAGES } from "./types";

export const getRouter = (state) => _.get(state, "router");

export const getLocation = createSelector(getRouter, (router) =>
  _.get(router, "location")
);

export const getLocationPath = createSelector(getLocation, (location) =>
  _.get(location, "pathname")
);

export const getLocationSearch = createSelector(getLocation, (location) =>
  _.get(location, "search")
);

export const getLocationQuery = createSelector(getLocation, (location) =>
  _.get(location, "query")
);

export const getIsCurrentScreens = createSelector(getLocationPath, (path) => {
  return _.startsWith(path, APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path);
});

export const getIsBrowserTabs = createSelector(getLocationPath, (path) => {
  return (
    _.startsWith(path, APP_PAGES.HIGHLIGHTS_ACTIVITY.path) &&
    !_.startsWith(path, APP_PAGES.HIGHLIGHTS_ACTIVITY_VIEWER.path)
  );
});

export const getIsHighlightsBase = createSelector(getLocationPath, (path) => {
  return _.startsWith(path, APP_PAGES.HIGHLIGHTS_BASE.path);
});

export const getIsClassViewPage = createSelector(getLocationPath, (path) => {
  return _.startsWith(path, APP_PAGES.HIGHLIGHTS_CLASS_VIEW.path);
});
