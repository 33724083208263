import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./SnapsPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import SnapsTiles from "../../../partial/Highlights/SnapsTiles/SnapsTiles";
import { MAIN_CONTENT_ID } from "../../../../consts";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import NoSnapsInfoPanel from "../../../partial/Highlights/NoSnapsInfoPanel/NoSnapsInfoPanel";
import { getClassId } from "../../../../state/shared/selectors";
import { loadSnapsList } from "../../../../state/highlights/snaps/actions";
import {
  getSnapshots,
  getSnapshotsLoadingState,
} from "../../../../state/highlights/snaps/selectors";
import { SNAPSHOT_TYPE } from "../../../../state/highlights/snaps/types";
import {
  getClassConfig,
  getIsConfigError,
  getIsConfigLoaded,
  getScreenshotsEnabled,
} from "../../../../state/highlights/config/selectors";
import { CLASS_CONFIG_TYPE } from "../../../../state/highlights/config/types";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";
import { getCurrentScreenExpandModalStatus } from "../../../../state/highlights/currentScreenExpandModal/selector";
import { hideCurrentScreenExpandModal } from "../../../../state/highlights/currentScreenExpandModal/action";
import { FormattedMessage, useIntl } from "react-intl";

const VIEW_TYPES = {
  NO_SNAPS: "NO_SNAPS",
  SNAPS_TILES: "SNAPS_TILES",
};

const SnapsPage = ({
  classId,
  snapshots,
  isSnapsLoading,
  loadSnapshotsList,
  isScreenshotsEnabled = false,
  classConfig = {},
  isConfigError,
  isConfigLoaded,
  isExpandModalOpen = false,
  closeExpandModal,
}) => {
  const intl = useIntl();
  const [isSnapsError, setIsSnapsError] = useState(false);
  const [isConfigAvailable, setConfigAvailable] = useState(false);

  const viewType = !snapshots.length
    ? VIEW_TYPES.NO_SNAPS
    : VIEW_TYPES.SNAPS_TILES;

  useEffect(() => {
    loadSnapshotsList(classId).catch((e) => {
      setIsSnapsError(true);
    });
  }, [classId, loadSnapshotsList]);

  useEffect(() => {
    if (!_.isEmpty(classConfig)) {
      setConfigAvailable(true);
    } else {
      setConfigAvailable(false);
    }
  }, [classConfig]);

  useEffect(() => {
    //Close Expand Modal when redirected from Current Screens to Snaps Page
    if (isExpandModalOpen) {
      closeExpandModal();
    }
  }, [isExpandModalOpen, closeExpandModal]);
  return (
    <>
      {isConfigAvailable && !isScreenshotsEnabled ? (
        <Redirect
          from={APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path}
          to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY.path}?classId=${classId}`}
        />
      ) : (
        <Page
          header={<Header />}
          footer={
            <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
          }
          title={APP_PAGES.HIGHLIGHTS_SNAPS.title}
          isPageLoaded={isConfigLoaded}
          isPageLoadingError={isConfigError}
        >
          <ModalsAndPanels />
          <main
            className={styles.root}
            data-test-id="Hs-Pages-HighlightsSnapsPage"
            id={MAIN_CONTENT_ID}
            tabIndex="-1"
          >
            <h1>
              <FormattedMessage defaultMessage="Snaps" id="dhJ3ab" />
            </h1>
            {(isSnapsLoading || isSnapsError) && (
              <PageLoading
                isError={isSnapsError}
                errorMessageText={intl.formatMessage({
                  defaultMessage: "Sorry we are having trouble connecting",
                  id: "I/51DU",
                })}
                isGoBackVisible={false}
              />
            )}

            {!isSnapsLoading && !isSnapsError && (
              <>
                {viewType === VIEW_TYPES.NO_SNAPS && <NoSnapsInfoPanel />}

                {viewType === VIEW_TYPES.SNAPS_TILES && <SnapsTiles />}
              </>
            )}
          </main>
        </Page>
      )}
    </>
  );
};

SnapsPage.propTypes = {
  classId: PropTypes.string.isRequired,
  snapshots: PropTypes.arrayOf(SNAPSHOT_TYPE),
  loadSnapshotsList: PropTypes.func.isRequired,
  isScreenshotsEnabled: PropTypes.bool,
  classConfig: CLASS_CONFIG_TYPE,
  isSnapsLoading: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  isExpandModalOpen: PropTypes.bool,
  closeExpandModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    classId: getClassId(state),
    snapshots: getSnapshots(state),
    isSnapsLoading: getSnapshotsLoadingState(state),
    isScreenshotsEnabled: getScreenshotsEnabled(state),
    classConfig: getClassConfig(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    isExpandModalOpen: getCurrentScreenExpandModalStatus(state),
  }),
  (dispatch) => ({
    loadSnapshotsList: (classId) => dispatch(loadSnapsList(classId, true)),
    closeExpandModal: () => dispatch(hideCurrentScreenExpandModal()),
  })
)(SnapsPage);
