import actionTypes from "../../actionTypes";
import _ from "lodash";

const INIT_STATE = {
  isLoading: true,
  snaps: [],
};

const snapsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_SNAPSHOTS_LOAD_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.HIGHLIGHTS_SNAPSHOTS_LOAD_SUCCESS: {
      const snaps = _.get(action, "payload", []);

      return {
        ...state,
        isLoading: false,
        snaps: _.orderBy(snaps, ["SnapshotTimestamp"], "desc"),
      };
    }

    case actionTypes.HIGHLIGHTS_SNAPSHOT_DETAILS_LOAD_SUCCESS: {
      const updatedSnap = _.get(action, "payload", []);
      updatedSnap.DetailsLoaded = true;
      const newSnaps = _.cloneDeep(state.snaps);
      // Find item index by snap ID
      const snapIndex = _.findIndex(newSnaps, { ID: updatedSnap.ID });
      // Replace item at index with new snap object
      newSnaps.splice(snapIndex, 1, updatedSnap);

      return {
        ...state,
        snaps: newSnaps,
      };
    }

    default:
      return state;
  }
};

export default snapsReducer;
