import { Modal } from "@hapara/ui/src/components/Modal";
import { Dialog } from "@hapara/ui/src/components/Dialog";
import { useIntl, FormattedMessage } from "react-intl";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import { Alert } from "@hapara/ui/src/atomic/Alert";
import { Button } from "@hapara/ui/src/atomic/Button";
import { Paragraph } from "@hapara/ui/src/atomic/Paragraph";

interface GuidedBrowsingConfirmDeleteModalProps {
  isOpen: boolean;
  templateName: string;
  onClose(): void;
  onDelete(): void;
  hasError?: boolean;
  isLoading?: boolean;
}
export const GuidedBrowsingConfirmDeleteModal = ({
  isOpen,
  templateName,
  onClose,
  onDelete,
  hasError,
  isLoading,
}: GuidedBrowsingConfirmDeleteModalProps) => {
  const intl = useIntl();
  return (
    <Modal
      withIncreasedZIndex
      isNarrow
      isOpen={isOpen}
      onRequestClose={onClose}
      dataTestIdPrefix="guided-browsing-delete-template-modal"
    >
      <Dialog
        dataTestIdPrefix="guided-browsing-delete-template-modal"
        fullWidth
        heading={intl.formatMessage({
          defaultMessage: "Confirm delete",
          id: "DnX0aY",
        })}
        onRequestClose={onClose}
        footer={
          hasError ? (
            <Alert type="danger">
              <FormattedMessage
                defaultMessage="The template could not be deleted. Please try again."
                id="cwQeTl"
              />
            </Alert>
          ) : null
        }
        actions={[
          <Button
            dataTestId="cancel-delete-template"
            onClick={onClose}
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            type="secondary"
          />,
          <Button
            dataTestId="confirm-delete-template"
            label={intl.formatMessage({
              defaultMessage: "Delete",
              id: "K3r6DQ",
            })}
            type="danger"
            isLoading={isLoading}
            onClick={onDelete}
          />,
        ]}
      >
        <Heading level={3} removeTopMargin>
          {templateName}
        </Heading>
        <Paragraph>
          <FormattedMessage
            defaultMessage="Are you sure you want to permanently delete this template?"
            id="cmbK0k"
          />
        </Paragraph>
      </Dialog>
    </Modal>
  );
};
