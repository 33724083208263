import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getUserLoggingStatus,
  pendoIdentitySelector,
} from "../../../state/user/selectors";
import { PENDO_DATA_TYPE } from "@hapara/ui/src/components/utils";

const PendoHandler = ({ isSignedIn, pendoID }) => {
  const pendoIDRef = useRef(null);

  useEffect(() => {
    const notLocal = process.env.REACT_APP_BUILD_ENV !== "development";

    if (isSignedIn && notLocal) {
      // as soon as we get user data
      if (pendoID) {
        if (!pendoIDRef.current) {
          // if it is the first time after logging in
          // load pendo and initialize the user
          const apiKey = `'f900cd67-3165-4eb2-4356-d7b998ff3184'`;
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.text = `(function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
            v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                y=e.createElement(n);y.async=!0;y.src='https://content.hsuite.activity.hapara.com/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
            })(${apiKey});`;

          document.head.appendChild(script);
          window.pendo.initialize(pendoID);
        } else if (!_.isEqual(pendoID, pendoIDRef.current)) {
          // if any user data changed
          // update data in Pendo
          window.pendo.identify(pendoID);
        }

        pendoIDRef.current = pendoID;
      }
    } else {
      // stop Pendo from reporting after logging out
      // Pendo still keeps user data in localStorage
      // so it will assign same visitor id if user logs back in
      window.pendo = undefined;
      pendoIDRef.current = null;
    }
  }, [pendoID, isSignedIn]);

  return null;
};

PendoHandler.propTypes = {
  pendoID: PENDO_DATA_TYPE,
  isSignedIn: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  pendoID: pendoIdentitySelector(state),
  isSignedIn: getUserLoggingStatus(state),
}))(PendoHandler);
