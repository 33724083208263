import React from "react";
import HLSubMenu from "../HLSubMenu/HLSubMenu";
import GroupsManagementPanel from "../GroupsManagementPanel/GroupsManagementPanel";
import GuideBrowsingModal from "../GuidedBrowsing/Deprecated/GuideBrowsingModal/GuideBrowsingModal";
import SendMessageModal from "../SendMessageModal/SendMessageModal";
import ShareLinksModal from "../ShareLinksModal/ShareLinksModal";
import ViewScreenshotModal from "../ViewScreenshotModal/ViewScreenshotModal";
import StudentTileExpandModal from "../StudentTileExpandModal/StudentTileExpandModal";
import GuideBrowsingTemplatesPanel from "../GuidedBrowsing/Deprecated/GuideBrowsingTemplatesPanel/GuideBrowsingTemplatesPanel";
import {
  GuidedBrowsingTemplateDrawer,
  GuidedBrowsingSessionDrawer,
} from "../GuidedBrowsing";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import GuideBrowsingActiveSessionsBarDeprecated from "../GuidedBrowsing/Deprecated/GuideBrowsingActiveSessionsBar/GuideBrowsingActiveSessionsBarDeprecated";
import { GuidedBrowsingSessionBar } from "../GuidedBrowsing";
import { getShareLinksModalStatus } from "../../../../state/highlights/shareLinks/selectors";
import {
  getGuideBrowsingModalStatus,
  getGuideBrowsingModalSessionType,
} from "../../../../state/highlights/sessions/selectors";
import { getSendMessageModalStatus } from "../../../../state/highlights/sendMessage/selectors";
import { getViewScreenshotModalStatus } from "../../../../state/highlights/viewScreenshot/selectors";
import { getCurrentScreenExpandModalStatus } from "../../../../state/highlights/currentScreenExpandModal/selector";
import { getTemplatesPanelStatus } from "../../../../state/highlights/guideBrowsingTemplates/selectors";
import { getFFByName } from "../../../../state/app/selectors";
import { getIsClassViewPage } from "../../../../state/router/selectors";

const ModalsAndPanels = ({
  isShareLinksModalOpen,
  isGuideBrowsingModalOpen,
  isSendMessageModalOpen,
  isViewScreenshotModalOpen,
  isExtendModalOpen,
  isTemplatesPanelOpen,
  isClassViewPageEnabled,
  isClassViewPage,
  sessionType,
}) => {
  return (
    <>
      <HLSubMenu />
      <GroupsManagementPanel />
      {isClassViewPageEnabled ? (
        <GuidedBrowsingSessionBar />
      ) : (
        <GuideBrowsingActiveSessionsBarDeprecated />
      )}
      {isGuideBrowsingModalOpen && isClassViewPage && sessionType !== "lock" ? (
        <GuidedBrowsingSessionDrawer isOpen={isGuideBrowsingModalOpen} />
      ) : (
        isGuideBrowsingModalOpen && <GuideBrowsingModal />
      )}
      {isSendMessageModalOpen && <SendMessageModal />}
      {isShareLinksModalOpen && <ShareLinksModal />}
      {isViewScreenshotModalOpen && <ViewScreenshotModal />}
      {isExtendModalOpen && <StudentTileExpandModal />}
      {isClassViewPageEnabled && isClassViewPage ? (
        <GuidedBrowsingTemplateDrawer isOpen={isTemplatesPanelOpen} />
      ) : (
        isTemplatesPanelOpen && <GuideBrowsingTemplatesPanel />
      )}
    </>
  );
};

ModalsAndPanels.propTypes = {
  isShareLinksModalOpen: PropTypes.bool.isRequired,
  isGuideBrowsingModalOpen: PropTypes.bool.isRequired,
  isSendMessageModalOpen: PropTypes.bool.isRequired,
  isViewScreenshotModalOpen: PropTypes.bool.isRequired,
  isExtendModalOpen: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isClassViewPageEnabled: getFFByName("GBE-01-class-view-page")(state),
    isClassViewPage: getIsClassViewPage(state),
    isShareLinksModalOpen: getShareLinksModalStatus(state),
    isGuideBrowsingModalOpen: getGuideBrowsingModalStatus(state),
    isSendMessageModalOpen: getSendMessageModalStatus(state),
    isViewScreenshotModalOpen: getViewScreenshotModalStatus(state),
    isExtendModalOpen: getCurrentScreenExpandModalStatus(state),
    isTemplatesPanelOpen: getTemplatesPanelStatus(state),
    sessionType: getGuideBrowsingModalSessionType(state),
  }),
  (dispatch) => ({})
)(ModalsAndPanels);
