import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./GuideBrowsingTemplatesList.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import LinearProgress from "@mui/material/LinearProgress";
import { getUserTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/selectors";
import { showGuideBrowsingModal } from "../../../../../../state/highlights/sessions/actions";
import {
  SESSION_TYPES,
  MODAL_MODE_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import { getTemplatesPanelScheduledStatus } from "../../../../../../state/highlights/guideBrowsingTemplates/selectors";
import TemplatesList from "./TemplatesList";
import { GUIDE_BROWSING_TEMPLATE_TYPE } from "../../../../../../state/highlights/sessions/types";
import { FormattedMessage } from "react-intl";

function GuideBrowsingTemplatesList({
  isLoading,
  isError,
  userTemplates,
  dataTestPrefix,
  deleteTemplateAction,
  showGuideBrowsingModal,
  ifSessionIsScheduled,
}) {
  const showGuideFilter = (selectedTemplate, isScheduled) => {
    showGuideBrowsingModal({
      modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
      sessionType: SESSION_TYPES.FILTER,
      selectedTemplate: selectedTemplate,
      isScheduled: isScheduled || ifSessionIsScheduled,
      isHideBackButton: true,
    });
  };

  const showGuideFocus = (selectedTemplate, isScheduled) => {
    showGuideBrowsingModal({
      modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
      sessionType: SESSION_TYPES.FOCUS,
      selectedTemplate: selectedTemplate,
      isScheduled: isScheduled || ifSessionIsScheduled,
      isHideBackButton: true,
    });
  };

  const LoadingState = () => {
    return (
      <div className={styles.loadingComponent}>
        <LinearProgress className={styles.progressBar} aria-hidden={true} />
        <div className={styles.text}>
          <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
        </div>
      </div>
    );
  };

  const ErrorState = () => {
    return (
      <div className={styles.loadingComponent}>
        <div className={styles.warningText}>
          <div className={styles.textIcon}>
            <HapReactIcon
              svg="fill-circle-exclamation"
              width={16}
              height={16}
            />
          </div>
          <span className={styles.textContent}>
            <FormattedMessage
              defaultMessage="Sorry, there was a problem loading templates, please try again later."
              id="kNcdSP"
            />
          </span>
        </div>
      </div>
    );
  };

  const EmptyState = () => {
    return (
      <div className={styles.loadingComponent}>
        <div
          className={styles.empty}
          data-test-id="hl-GuideBrowsingDrawer-Template-EmptyState"
        >
          <span className={styles.emptyHeader}>
            <FormattedMessage
              defaultMessage="Your templates will appear here!"
              id="Xdgccd"
            />
          </span>
          <span className={styles.emptyContent}>
            <FormattedMessage
              defaultMessage="Saved guided browsing sessions are accessible in all of your classes."
              id="u+itGx"
            />
          </span>
        </div>
      </div>
    );
  };

  const createNewSessionAction = (selectedTemplate, isScheduled) => {
    selectedTemplate.Type === "focus"
      ? showGuideFocus(selectedTemplate, isScheduled)
      : showGuideFilter(selectedTemplate, isScheduled);
  };

  return (
    <div className={styles.root}>
      <div className={styles.panelHeader}>
        <h2>
          <FormattedMessage defaultMessage="My templates" id="ovr52i" />
        </h2>
      </div>
      {isLoading && <LoadingState />}
      {!isLoading && isError && <ErrorState />}
      {!isLoading && !isError && userTemplates.length === 0 && <EmptyState />}
      {!isLoading && !isError && userTemplates.length > 0 && (
        <TemplatesList
          templates={userTemplates}
          dataTestPrefix={dataTestPrefix}
          createNewSessionAction={createNewSessionAction}
          deleteTemplateAction={deleteTemplateAction}
        />
      )}
    </div>
  );
}

GuideBrowsingTemplatesList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  userTemplates: PropTypes.arrayOf(GUIDE_BROWSING_TEMPLATE_TYPE),
  ifSessionIsScheduled: PropTypes.bool.isRequired,
  deleteTemplateAction: PropTypes.func.isRequired,
  showGuideBrowsingModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    userTemplates: getUserTemplatesList(state),
    ifSessionIsScheduled: getTemplatesPanelScheduledStatus(state),
  }),
  (dispatch) => ({
    showGuideBrowsingModal: (payload) =>
      dispatch(showGuideBrowsingModal(payload)),
  })
)(GuideBrowsingTemplatesList);
