import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import styles from "./SideNavItem.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

interface SideNavSubItemProps {
  item: {
    to: string;
    isActive?: boolean;
    icon?: string;
    title: string;
  };
  color: "green" | "blue" | "violet";
}

export const SideNavSubItem: React.FC<SideNavSubItemProps> = ({
  item,
  color,
}) => {
  const { to, isActive, icon, title, ...rest } = item;
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimerId = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (hoverTimerId.current) {
        window.clearTimeout(hoverTimerId.current);
        hoverTimerId.current = null;
      }
      setIsHovered(false);
    };
  }, [setIsHovered]);

  return (
    <Link
      to={to}
      className={classnames(styles.subItemLink, styles[color], {
        [styles.active]: isActive,
        [styles.notHovered]: !isHovered,
      })}
      onMouseEnter={() => {
        hoverTimerId.current = window.setTimeout(
          () => setIsHovered(true),
          1000
        );
      }}
      onMouseLeave={() => {
        if (hoverTimerId.current) {
          window.clearTimeout(hoverTimerId.current);
          hoverTimerId.current = null;
        }
        setIsHovered(false);
      }}
      aria-label={
        isActive
          ? intl.formatMessage(
              {
                defaultMessage: "{linkName}, selected",
                id: "AfHFcM",
              },
              { linkName: title }
            )
          : title
      }
      {...rest}
    >
      <div className={styles.subItemIcon}>
        <HapReactIcon
          svg={icon || "profile-group-filled"}
          width={16}
          height={16}
        />
      </div>
      <div className={styles.subItemTitle}>{title}</div>
    </Link>
  );
};
