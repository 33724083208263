import { TEMPLATES } from "./type";
import type { InitState } from "./type";
import { parseOldTemplateDataToNewFormat } from "../sessions/types";
import _ from "lodash";

const initState: InitState = {
  templatesList: [],
  showTemplatesPanel: false,
  templatesPanelScheduled: false,
  isMoreTemplatesAvailable: false,
  offset: 0,
};

const templatesReducer = (state = initState, action: any) => {
  switch (action.type) {
    case TEMPLATES.GET_TEMPLATES:
      return {
        ...state,
        templatesList: _.map(action.payload.templatesList, (item) =>
          parseOldTemplateDataToNewFormat(item)
        ),
        offset: action.payload.offset,
        isMoreTemplatesAvailable: action.payload.isMoreTemplatesAvailable,
      };

    case TEMPLATES.APPEND_TEMPLATES:
      return {
        ...state,
        templatesList: [
          ...state.templatesList,
          ..._.map(action.payload.templatesList, (item) =>
            parseOldTemplateDataToNewFormat(item)
          ),
        ],
        offset: action.payload.offset,
        isMoreTemplatesAvailable: action.payload.isMoreTemplatesAvailable,
      };

    case TEMPLATES.SHOW_TEMPLATES_PANEL:
      return {
        ...state,
        showTemplatesPanel: true,
        templatesPanelScheduled: action.payload,
      };

    case TEMPLATES.HIDE_TEMPLATES_PANEL:
      return {
        ...state,
        showTemplatesPanel: false,
        templatesPanelScheduled: false,
        isMoreTemplatesAvailable: false,
        offset: 0,
      };

    default:
      return state;
  }
};

export default templatesReducer;
