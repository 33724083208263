import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SearchClassesBar.module.scss";
import Search from "@hapara/ui/src/atomic/Search/Search";
import {
  loadClassesList,
  setSearchClassesQuery,
} from "../../../../state/dashboard/manage-classes/actions";
import {
  getIsClassesLoading,
  getSearchClassesQuery,
} from "../../../../state/dashboard/manage-classes/selectors";
import { getDomains } from "../../../../state/dashboard/config/selectors";
import SelectDomainAndSchool from "../SelectDomainAndSchool/SelectDomainAndSchool";
import { getDefaultDomainSelection } from "../../../../utils";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

const SearchClassesBarContent = ({ allClasses, domains }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isClassesLoading = useSelector(getIsClassesLoading);
  const [domain, setDomain] = useState(
    domains.length === 1 ? domains[0].urn : null
  );
  const [school, setSchool] = useState("");

  const query = useSelector(getSearchClassesQuery);
  const [tempQuery, setTempQuery] = useState("");
  useEffect(() => {
    setTempQuery(query);
  }, [query, setTempQuery]);

  useEffect(() => {
    dispatch(loadClassesList(query, domain, school, allClasses));
  }, [query, domain, school, allClasses, dispatch]);

  const searchClassesAction = useCallback(
    (q) => {
      return dispatch(setSearchClassesQuery(q, domain, school, allClasses));
    },
    [dispatch, domain, school, allClasses]
  );

  const handleDomainSelection = useCallback(
    (domain, school) => {
      setDomain(domain);
      setSchool(school);
    },
    [setDomain, setSchool]
  );

  const defaultDomainSelection = getDefaultDomainSelection(domains, "");
  const locationTrigger = useCallback(
    (label, props) => (
      <Button
        label={label}
        type={BUTTON_TYPES.OUTLINED}
        size={BUTTON_SIZES.LARGE}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        rightIcon="arrow-carvet-down"
        {...props}
      />
    ),
    []
  );

  return (
    <div className={styles.root}>
      <SelectDomainAndSchool
        dataTestIdPrefix="td-ManageClasses-SelectLocation"
        onChange={handleDomainSelection}
        domains={domains}
        defaultDomainValue={defaultDomainSelection.domain}
        defaultSchoolValue={defaultDomainSelection.school}
        permission=""
        triggerComponent={locationTrigger}
      />
      <div className={styles.gap} />
      <Search
        className={styles.search}
        placeholder={intl.formatMessage({
          defaultMessage: "Find classes or teachers",
          id: "5E83cm",
        })}
        onSearch={async (val) => {
          await searchClassesAction(val);
        }}
        dataTestIdPrefix="td-ManageClassesSearch"
        value={tempQuery}
        disabled={isClassesLoading}
        onChange={setTempQuery}
      />
    </div>
  );
};

SearchClassesBarContent.propTypes = {
  allClasses: PropTypes.bool.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object),
};

const SearchClassesBar = ({ allClasses }) => {
  const domains = useSelector(getDomains);
  if (domains && domains.length > 0) {
    return (
      <SearchClassesBarContent allClasses={allClasses} domains={domains} />
    );
  }
  return <div />;
};

SearchClassesBar.propTypes = {
  allClasses: PropTypes.bool.isRequired,
};
export default SearchClassesBar;
