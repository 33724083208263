import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./UploadForm.module.scss";
import {
  Textarea,
  TEXTAREA_DEFAULT_MAX_LENGTH,
} from "@hapara/ui/src/atomic/Textarea";

const UploadFormDescription = ({
  id,
  dataTestIdPrefix,
  description,
  onDescriptionChange,
}) => {
  return (
    <div className={styles.fieldBlock}>
      <label htmlFor={id} className={styles.fieldLabel}>
        <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
      </label>
      <Textarea
        name={id}
        id={id}
        placeholder="Describe this resource and how it can be used by teachers"
        data-test-id={`${dataTestIdPrefix}-DescriptionInput`}
        value={description}
        onChange={(e) => onDescriptionChange(_.get(e, "target.value", ""))}
        rows={2}
        maxLength={TEXTAREA_DEFAULT_MAX_LENGTH}
        showLimitWarning={true}
      />
    </div>
  );
};

UploadFormDescription.propTypes = {
  description: PropTypes.string,
  onDescriptionChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default UploadFormDescription;
