import React from "react";
import { createIcon } from "./utils/createIcon";

export const TrashIcon = createIcon(
  <path
    d="M2.1 4.3h11.7m-1.3 0V13c0 .6-.6 1.3-1.3 1.3H4.8c-.6 0-1.3-.7-1.3-1.3V4.2m2 0V3c0-.6.6-1.3 1.3-1.3h2.6c.6 0 1.3.7 1.3 1.3v1.3m-3.9 3v3.8m2.6-3.7V11"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
