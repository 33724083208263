import React from "react";
import { ClassInfoDialogModal } from "./ClassInfoDialogModal";
import styles from "./ClassInfoDialogModal.module.scss";
import { FormattedMessage, useIntl } from "react-intl";

type ClassInfoDialogModalRemoveStudentProps = {
  isOpen: boolean;
  hasNetworkError?: boolean;
  selectedCount: number;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  modalAction: () => void;
  isProcessing?: boolean;
};

export const ClassInfoDialogModalRemoveStudent: React.FC<
  ClassInfoDialogModalRemoveStudentProps
> = ({
  isOpen,
  hasNetworkError,
  selectedCount,
  dataTestIdPrefix,
  isProcessing,
  onRequestClose,
  modalAction,
}) => {
  const intl = useIntl();

  const modalSubject = selectedCount > 1 ? "students" : "student";
  const modalPronoun = selectedCount > 1 ? "these" : "this";

  return (
    <ClassInfoDialogModal
      dataTestIdPrefix={dataTestIdPrefix}
      isOpen={isOpen}
      title={intl.formatMessage(
        {
          defaultMessage: "Remove {modalSubject}",
          id: "VwELUF",
        },
        { modalSubject }
      )}
      onRequestClose={onRequestClose}
      onConfirm={modalAction}
      confirmLabel={intl.formatMessage({
        defaultMessage: "Yes, remove",
        id: "KpV+N+",
      })}
      isConfirmDangerous
      isProcessing={isProcessing}
      hasNetworkError={hasNetworkError}
    >
      <div className={styles.text}>
        <FormattedMessage
          defaultMessage="Are you sure you want to remove {modalPronoun} {modalSubject} from this class?"
          id="SfSjqp"
          values={{ modalPronoun, modalSubject }}
        />
      </div>
    </ClassInfoDialogModal>
  );
};
