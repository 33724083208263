import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import {
  INSTANCE_TYPE,
  STUDENT_STATUS_TYPES,
} from "../../../../state/highlights/students/types";
import styles from "./StudentTileMultipleDevicesModal.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { updateStudentSelectedInstance } from "../../../../state/highlights/students/actions";
import { FormattedMessage, useIntl } from "react-intl";

const StudentTileMultipleDevicesModal = ({
  instanceList,
  studentDisplayName,
  studentId,
  currentInstance,
  lastSelectedInstance,
  isModalOpen,
  onModalOpen,
  updateStudentSelectedInstance,
}) => {
  const intl = useIntl();
  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      title={intl.formatMessage({
        defaultMessage: "Multiple devices detected",
        id: "zLyuuN",
      })}
      onClose={() => {
        onModalOpen(false);
      }}
      dataTestPrefix="hl-StudentTile-MultipleDevices"
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <div
          className={styles.studentName}
          data-test-id="hl-StudentTile-MultipleDevices-StudentName"
        >
          {studentDisplayName}
        </div>
        <p data-test-id="hl-StudentTile-MultipleDevices-Title">
          <FormattedMessage
            defaultMessage="This student is signed into more than one device. Please select the device you’d like to view."
            id="UInAkM"
          />
        </p>
        <p>
          <a
            href="https://support.hapara.com/hc/en-us/articles/360057699412"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.helpLink}
            data-test-id="hl-StudentTile-MultipleDevices-HelpLink"
          >
            <FormattedMessage
              defaultMessage="Need help? Visit our help center"
              id="LbZWna"
            />
          </a>
        </p>
        <div className={styles.devices}>
          <h2
            className={styles.devicesTitle}
            data-test-id="hl-StudentTile-MultipleDevices-DevicesTitle"
          >
            <FormattedMessage
              defaultMessage="Select a device to view"
              id="hAZ5Ex"
            />
          </h2>
          {_.map(instanceList, (instance) => {
            if (!instance.instanceId) return null;

            const isSelected =
              instance.instanceId === currentInstance && lastSelectedInstance;
            const tabs = _.get(instance, "tabs", []) || [];
            const amountOfTabs = tabs.length;

            const activeTab = _.find(tabs, (tab) => tab.active);

            const status = _.get(instance, "status", "");
            const isTeacherStatus =
              status === STUDENT_STATUS_TYPES.TEACHER_LOGGED_IN;

            return (
              <button
                key={instance.instanceId}
                className={classnames(styles.deviceItem, {
                  [styles.deviceItemSelected]: isSelected,
                })}
                data-test-id="hl-StudentTile-MultipleDevices-SelectDeviceFromList"
                onClick={() => {
                  updateStudentSelectedInstance({
                    studentId: studentId,
                    instanceId: instance.instanceId,
                  });
                  onModalOpen(false);
                }}
              >
                <div className={styles.deviceItemIcon}>
                  <HapReactIcon
                    svg="single-device"
                    width={32}
                    height={21}
                    viewWidth={32}
                    viewHeight={21}
                  />
                </div>
                {amountOfTabs > 0 && activeTab && (
                  <div className={styles.deviceTabIcon}>
                    {activeTab.icon && (
                      <img
                        src={activeTab.icon}
                        width={16}
                        height={16}
                        alt=""
                        className={styles.image}
                      />
                    )}
                    {!activeTab.icon && (
                      <HapReactIcon svg="globe" width={16} height={16} />
                    )}
                  </div>
                )}
                <div className={styles.deviceItemName}>
                  {isTeacherStatus && (
                    <FormattedMessage
                      defaultMessage="Teacher or administrator account detected"
                      id="0OvOAa"
                    />
                  )}
                  {!isTeacherStatus &&
                    amountOfTabs > 0 &&
                    activeTab &&
                    (activeTab.title || activeTab.url)}
                  {!isTeacherStatus && amountOfTabs > 0 && !activeTab && (
                    <FormattedMessage
                      defaultMessage="No active tab"
                      id="oRfleR"
                    />
                  )}
                  {!isTeacherStatus && !amountOfTabs && (
                    <FormattedMessage
                      defaultMessage="Currently not browsing on this device"
                      id="nxEPuu"
                    />
                  )}
                </div>
                {amountOfTabs > 1 && (
                  <div className={styles.deviceTabCount}>
                    <FormattedMessage
                      defaultMessage="+ {count, plural, one {# more tab} other {# more tabs}}"
                      id="QVEcxc"
                      values={{ count: amountOfTabs - 1 }}
                    />
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </ModalDeprecated>
  );
};

StudentTileMultipleDevicesModal.propTypes = {
  instanceList: PropTypes.arrayOf(INSTANCE_TYPE),
  studentDisplayName: PropTypes.string.isRequired,
  currentInstance: PropTypes.string,
  lastSelectedInstance: PropTypes.string,
  studentId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  // connected props
  updateStudentSelectedInstance: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    updateStudentSelectedInstance: ({ studentId, instanceId }) =>
      dispatch(updateStudentSelectedInstance({ studentId, instanceId })),
  })
)(StudentTileMultipleDevicesModal);
