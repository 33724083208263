import { IanaCode } from "../getTimeZones/IanaCode";

/** Gets the current plain time in a specified time zone or local time. */
export const getCurrentTime = (timeZone?: IanaCode): string => {
  const now = new Date();

  try {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return new Intl.DateTimeFormat("en-US", options).format(now);
  } catch (error) {
    throw new Error(`Invalid time zone: ${timeZone}`);
  }
};
