import { STUDENT_ORDER_TYPES } from "../../../../state/highlights/config/types";
import { UPREF_NAME_FIRST_LAST } from "../../../../state/user/types";
import _ from "lodash";

export const getStudentsOrder = ({
  studentsList,
  checkedGroupsList,
  studentOrderType,
  studentCustomOrder,
  nameFormat,
}) => {
  // filter by groups
  let filteredList = [];

  if (checkedGroupsList.length === 0) {
    filteredList = [...studentsList]; // show all students if no group selected
  } else {
    let usersList = [];
    checkedGroupsList.forEach((ug) => {
      usersList.push(...ug.participants);
    });
    filteredList = [
      ...studentsList.filter((s) => _.includes(usersList, s.Email)),
    ];
  }

  // order by order type
  if (studentOrderType !== STUDENT_ORDER_TYPES.CUSTOM) {
    // for non-custom order types
    let iteratees = ["Email"];
    let orders = ["asc"];

    if (studentOrderType === STUDENT_ORDER_TYPES.FIRST_AZ) {
      iteratees = ["FirstName", "LastName"];
      orders = ["asc", "asc"];
    }

    if (studentOrderType === STUDENT_ORDER_TYPES.LAST_AZ) {
      iteratees = ["LastName", "FirstName"];
      orders = ["asc", "asc"];
    }

    if (studentOrderType === STUDENT_ORDER_TYPES.ONLINE) {
      iteratees = [
        "isOnline",
        nameFormat === UPREF_NAME_FIRST_LAST ? "FirstName" : "LastName",
      ];
      orders = ["desc", "asc"];
    }

    if (studentOrderType === STUDENT_ORDER_TYPES.OFFLINE) {
      iteratees = [
        "isOnline",
        nameFormat === UPREF_NAME_FIRST_LAST ? "FirstName" : "LastName",
      ];
      orders = ["asc", "asc"];
    }

    const sortedList = _.orderBy(filteredList, iteratees, orders);

    return _.map(sortedList, (item) => item.Id);
  } else {
    // for custom order type
    const filteredListOfIds = _.map(filteredList, (item) => item.Id);

    const orderedPortion = [];

    // go through the list of provided custom order
    _.forEach(studentCustomOrder, (studentFromCustomOrder) => {
      if (_.indexOf(filteredListOfIds, studentFromCustomOrder) !== -1) {
        // if this student exists in the list of filtered students
        // add it to the ordered list
        orderedPortion.push(studentFromCustomOrder);
        // and remove from filtered list
        _.pull(filteredListOfIds, studentFromCustomOrder);
      }
    });

    // now combine the ordered portion with the rest of the students we need to display
    return [...orderedPortion, ...filteredListOfIds];
  }
};
