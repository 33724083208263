import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Action {
  CanCreate = "CAN_CREATE",
  CanDelete = "CAN_DELETE",
  CanEdit = "CAN_EDIT",
  CanView = "CAN_VIEW",
}

/** Attachments associated with cards */
export type Artifact = {
  __typename?: "Artifact";
  ID?: Maybe<Scalars["ID"]>;
  /** URL of the icon of the attachment */
  IconURL?: Maybe<Scalars["String"]>;
  /** MimeType of the attachment */
  MimeType?: Maybe<Scalars["String"]>;
  /**
   * The role of the artifact, limited to one of the following
   * "Template"
   * "Teacher"
   * TODO: Refactor this into the enum ArtifactRole
   */
  Role?: Maybe<Scalars["String"]>;
  /** Short description of the artifact */
  Title?: Maybe<Scalars["String"]>;
  /** The type of the artifact */
  Type?: Maybe<ArtifactType>;
  /** URI of the attachment, a google resource, a link, an image, etc */
  URI?: Maybe<Scalars["String"]>;
};

/** The parameters that are required to create an artifact */
export type ArtifactInput = {
  IconURL?: InputMaybe<Scalars["String"]>;
  MimeType?: InputMaybe<Scalars["String"]>;
  /**
   * See note about the role in the artifact type
   * this should be one of 'Teacher' or 'Template'
   */
  Role: Scalars["String"];
  Title?: InputMaybe<Scalars["String"]>;
  Type: ArtifactType;
  URI?: InputMaybe<Scalars["String"]>;
};

/** The role of the artifact */
export enum ArtifactRole {
  TeacherArtifact = "TeacherArtifact",
  TemplateArtifact = "TemplateArtifact",
}

/** The type of the artifact associated with a */
export enum ArtifactType {
  GDrive = "GDrive",
  Url = "URL",
}

/**
 * The access types a Board can have
 * The following will be added when/if required
 * ARCHIVED
 * DELETED
 */
export enum BoardAccessStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

/** A card that represents a unit of information in a workspace */
export type Card = {
  __typename?: "Card";
  /** Attachments associated with the card */
  Artifacts: Array<Artifact>;
  /** The background colour of the card */
  Colour?: Maybe<Scalars["String"]>;
  /**
   * The Hapara ID, a 12 char hex number
   * TODO: Turn this into a column type see HAP-9688
   */
  ColumnType?: Maybe<Scalars["String"]>;
  /** The description of the card */
  Description: Scalars["String"];
  /** Represents timestamp in milliseconds when the assignment is due */
  DueDate?: Maybe<Scalars["Int"]>;
  /** Groups assigned to the card */
  Groups?: Maybe<Array<Maybe<Group>>>;
  /** ID of Groups assigned to the card */
  GroupsFilter: Array<GroupFilter>;
  /** The Hapara ID, a 12 char hex number */
  ID?: Maybe<Scalars["ID"]>;
  /** URL to the boards thumbnail image */
  Image?: Maybe<Scalars["String"]>;
  /** Alt text for the thumbnail */
  ImageAltText?: Maybe<Scalars["String"]>;
  /** Indicates the ordering within a column */
  Order?: Maybe<Scalars["Float"]>;
  /** The Hapara ID, a 12 char hex number */
  SectionID?: Maybe<Scalars["ID"]>;
  /** Represents timestamp in milliseconds when the assignment starts */
  StartDate?: Maybe<Scalars["Int"]>;
  /** The cards title. */
  Title: Scalars["String"];
  /** The type of card. */
  Type?: Maybe<CardType>;
};

export type CardInput = {
  Colour?: InputMaybe<Scalars["String"]>;
  Description?: InputMaybe<Scalars["String"]>;
  GroupsFilter?: InputMaybe<Array<Scalars["String"]>>;
  ImageUrl?: InputMaybe<Scalars["String"]>;
  /**
   * This field is used to add artifacts only
   * See deleteArtifactFromCard for removal
   */
  NewArtifacts?: InputMaybe<Array<ArtifactInput>>;
  Position?: InputMaybe<CardPositionInput>;
  Title?: InputMaybe<Scalars["String"]>;
  Type?: InputMaybe<CardType>;
};

/**
 * Where to position the card on the board
 * NOTE: The are interactions between the position and the column type and section that are not
 * verified yet, i.e. ensuring the before and after cards are in the defined column type and section
 */
export type CardPositionInput = {
  AfterCard?: InputMaybe<Scalars["ID"]>;
  BeforeCard?: InputMaybe<Scalars["ID"]>;
  ColumnType?: InputMaybe<Scalars["String"]>;
  SectionID?: InputMaybe<Scalars["ID"]>;
};

/** The type of card. */
export enum CardType {
  Evidence = "EVIDENCE",
  Goal = "GOAL",
  Resource = "RESOURCE",
  Rubric = "RUBRIC",
}

/** Collates cards into verticals */
export type Column = {
  __typename?: "Column";
  /** Short summary of the column */
  Title: Scalars["String"];
  /** The type of the column */
  Type: ColumnType;
};

/** The type of column */
export enum ColumnType {
  Evidence = "EVIDENCE",
  Goal = "GOAL",
  Resource = "RESOURCE",
  Rubric = "RUBRIC",
}

/** Sets of people who can access the cards and boards */
export type Group = {
  __typename?: "Group";
  /** The index of the color applied to a group from the color pallete */
  ColorIndex?: Maybe<Scalars["Int"]>;
  ID?: Maybe<Scalars["ID"]>;
  /**
   * IDs of who is a member of this group, currently email addresses
   * Not available on the public view
   */
  Members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Short IDentifier of the group, often the class name */
  Title?: Maybe<Scalars["String"]>;
};

/** ID of a Group */
export type GroupFilter = {
  __typename?: "GroupFilter";
  ID?: Maybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Adds a new artifact to a card */
  addArtifactToCard?: Maybe<Artifact>;
  /** Deletes an artifact from a card */
  deleteArtifactsFromCard?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /**
   * Delete a card from a workspace
   * This is a hard delete
   */
  deleteCardById?: Maybe<Scalars["ID"]>;
  /** Current just returns the ID, for auth testing until the delete story is acutally done. */
  deleteWorkspaceByID?: Maybe<Scalars["ID"]>;
  /** Updates a card and returns the updated card */
  updateCard?: Maybe<Card>;
};

export type MutationAddArtifactToCardArgs = {
  cardId: Scalars["ID"];
  input: ArtifactInput;
};

export type MutationDeleteArtifactsFromCardArgs = {
  artifactIds: Array<Scalars["ID"]>;
};

export type MutationDeleteCardByIdArgs = {
  cardId?: InputMaybe<Scalars["ID"]>;
};

export type MutationDeleteWorkspaceByIdArgs = {
  boardId?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateCardArgs = {
  boardId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  input: CardInput;
};

/** Permission object */
export type Permission = {
  __typename?: "Permission";
  /** List of actions that can be performed (edit/create/delete/view) */
  Actions: Array<Action>;
  /** The id of the object to perform actions on (workspace/artifact/card) */
  Object?: Maybe<Scalars["String"]>;
  /** The id of the logged-in user (email/google-id) */
  UserId?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  getWorkspaceByID?: Maybe<Workspace>;
};

export type QueryGetWorkspaceByIdArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export enum Role {
  Anonymous = "ANONYMOUS",
  DbTeacher = "DB_TEACHER",
  DistrictLeader = "DISTRICT_LEADER",
  DistrictSysAdmin = "DISTRICT_SYS_ADMIN",
  Drm = "DRM",
  HaparaAdmin = "HAPARA_ADMIN",
  SchoolLeader = "SCHOOL_LEADER",
  SchoolSysAdmin = "SCHOOL_SYS_ADMIN",
  Student = "STUDENT",
  Teacher = "TEACHER",
}

/** Collates cards into horizontals */
export type Section = {
  __typename?: "Section";
  /** The Hapara ID, a 12 char hex number */
  ID?: Maybe<Scalars["ID"]>;
  /** Used to order the sections. If the value is -1 then it is the default/first section. If the value is null then the order is undefined and the array order can be used. */
  Order?: Maybe<Scalars["Int"]>;
  /** Short summary of the section */
  Title: Scalars["String"];
};

/** The main workspace container */
export type Workspace = {
  __typename?: "Workspace";
  /** The status of the workspace */
  AccessStatus: BoardAccessStatus;
  /** Collection of all cards */
  Cards: Array<Card>;
  /** Collects cards in a vertical space */
  Columns: Array<Column>;
  /** Full description of the board */
  Description: Scalars["String"];
  /** Workspace editors */
  Editors?: Maybe<Array<Scalars["String"]>>;
  /** Collection of groups and their information */
  Groups: Array<Maybe<Group>>;
  /** The Hapara ID, a 12 char hex number */
  ID?: Maybe<Scalars["ID"]>;
  /** URL to the board's thumbnail image */
  Image: Scalars["String"];
  /** Alt text for the board's thumbnail */
  ImageAltText: Scalars["String"];
  /** Indicates if the workspace is shared to the public */
  IsVisibleGlobally: Scalars["Boolean"];
  /** Indicates if the workspace is shared to the customer domain */
  IsVisibleToSchoolDistrict: Scalars["Boolean"];
  /**
   * The ID that uniquely identifies a person, an email at the moment
   * Not avialbe on the public view
   */
  Owner: Scalars["String"];
  /** Set of permissions what the user can do to an object */
  Permissions: Array<Permission>;
  /** Collects cards in a horizontal space */
  Sections: Array<Section>;
  /**
   * List of how this workspace is being shared
   * @deprecated Not used anymore
   */
  ShareTypes?: Maybe<Array<Scalars["String"]>>;
  /** Short description of the board */
  Title: Scalars["String"];
};

export type DeleteCardByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
}>;

export type DeleteCardByIdMutation = {
  __typename?: "Mutation";
  deleteCardById?: string | null;
};

export type GetWorkspaceByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
}>;

export type GetWorkspaceByIdQuery = {
  __typename?: "Query";
  getWorkspaceByID?: {
    __typename?: "Workspace";
    ID?: string | null;
    Title: string;
    Description: string;
    Owner: string;
    Image: string;
    ImageAltText: string;
    AccessStatus: BoardAccessStatus;
    IsVisibleGlobally: boolean;
    IsVisibleToSchoolDistrict: boolean;
    Groups: Array<{
      __typename?: "Group";
      ID?: string | null;
      Members?: Array<string | null> | null;
      Title?: string | null;
      ColorIndex?: number | null;
    } | null>;
    Columns: Array<{ __typename?: "Column"; Type: ColumnType; Title: string }>;
    Sections: Array<{
      __typename?: "Section";
      ID?: string | null;
      Title: string;
      Order?: number | null;
    }>;
    Cards: Array<{
      __typename?: "Card";
      ID?: string | null;
      Title: string;
      Description: string;
      Type?: CardType | null;
      SectionID?: string | null;
      ColumnType?: string | null;
      Order?: number | null;
      Image?: string | null;
      ImageAltText?: string | null;
      Colour?: string | null;
      StartDate?: number | null;
      DueDate?: number | null;
      Artifacts: Array<{
        __typename?: "Artifact";
        ID?: string | null;
        Role?: string | null;
        Title?: string | null;
        Type?: ArtifactType | null;
        URI?: string | null;
        IconURL?: string | null;
        MimeType?: string | null;
      }>;
      GroupsFilter: Array<{ __typename?: "GroupFilter"; ID?: string | null }>;
    }>;
    Permissions: Array<{
      __typename?: "Permission";
      UserId?: string | null;
      Object?: string | null;
      Actions: Array<Action>;
    }>;
  } | null;
};

export type UpdateCardMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  input: CardInput;
  boardId?: InputMaybe<Scalars["ID"]>;
}>;

export type UpdateCardMutation = {
  __typename?: "Mutation";
  updateCard?: {
    __typename?: "Card";
    ID?: string | null;
    Title: string;
    Description: string;
    Type?: CardType | null;
    SectionID?: string | null;
    ColumnType?: string | null;
    Order?: number | null;
    Image?: string | null;
    ImageAltText?: string | null;
    Colour?: string | null;
    Artifacts: Array<{
      __typename?: "Artifact";
      ID?: string | null;
      Role?: string | null;
      Title?: string | null;
      Type?: ArtifactType | null;
      URI?: string | null;
      IconURL?: string | null;
      MimeType?: string | null;
    }>;
    GroupsFilter: Array<{ __typename?: "GroupFilter"; ID?: string | null }>;
  } | null;
};

export const DeleteCardByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteCardById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCardById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCardByIdMutation,
  DeleteCardByIdMutationVariables
>;
export const GetWorkspaceByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWorkspaceByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getWorkspaceByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ID" } },
                { kind: "Field", name: { kind: "Name", value: "Title" } },
                { kind: "Field", name: { kind: "Name", value: "Description" } },
                { kind: "Field", name: { kind: "Name", value: "Owner" } },
                { kind: "Field", name: { kind: "Name", value: "Image" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ImageAltText" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "AccessStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "IsVisibleGlobally" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "IsVisibleToSchoolDistrict" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Groups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ID" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Members" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ColorIndex" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Columns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "Type" } },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Sections" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ID" } },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                      { kind: "Field", name: { kind: "Name", value: "Order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Cards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ID" } },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SectionID" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ColumnType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "Order" } },
                      { kind: "Field", name: { kind: "Name", value: "Image" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ImageAltText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Colour" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "StartDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DueDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Artifacts" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ID" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Role" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "URI" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "IconURL" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "MimeType" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "GroupsFilter" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ID" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Permissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "UserId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Object" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Actions" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceByIdQuery,
  GetWorkspaceByIdQueryVariables
>;
export const UpdateCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CardInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boardId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "boardId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "boardId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ID" } },
                { kind: "Field", name: { kind: "Name", value: "Title" } },
                { kind: "Field", name: { kind: "Name", value: "Description" } },
                { kind: "Field", name: { kind: "Name", value: "Type" } },
                { kind: "Field", name: { kind: "Name", value: "SectionID" } },
                { kind: "Field", name: { kind: "Name", value: "ColumnType" } },
                { kind: "Field", name: { kind: "Name", value: "Order" } },
                { kind: "Field", name: { kind: "Name", value: "Image" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ImageAltText" },
                },
                { kind: "Field", name: { kind: "Name", value: "Colour" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Artifacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ID" } },
                      { kind: "Field", name: { kind: "Name", value: "Role" } },
                      { kind: "Field", name: { kind: "Name", value: "Title" } },
                      { kind: "Field", name: { kind: "Name", value: "Type" } },
                      { kind: "Field", name: { kind: "Name", value: "URI" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "IconURL" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MimeType" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "GroupsFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ID" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCardMutation, UpdateCardMutationVariables>;
