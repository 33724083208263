import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getDiscoverItemsList,
  getDiscoverPagesCount,
  getDiscoverIsLoading,
} from "../../../../state/library/discover/selectors";
import { getCurrentPage } from "../../../../state/shared/selectors";
import { resetDiscoverItems } from "../../../../state/library/discover/actions";
import { resourceItemType } from "../../../../state/resources/types";
import ResourceTileList from "../../Resources/ResourceTileList/ResourceTileList";
import EmptySearchResults from "../../Resources/EmptySearchResults/EmptySearchResults";
import ResourcesLoading from "../../Resources/ResourcesLoading/ResourcesLoading";

export const DiscoverList = ({
  items,
  currentPage,
  pagesCount,
  isLoading,
  resetDiscoverItems,
}) => {
  useEffect(() => {
    return () => {
      //clear data on unmount
      resetDiscoverItems();
    };
  }, [resetDiscoverItems]);

  if (isLoading) {
    return <ResourcesLoading hasMarginTop={true} />;
  }

  return (
    <ResourceTileList
      items={items}
      currentPage={currentPage}
      pagesCount={pagesCount}
      emptyState={
        <EmptySearchResults dataTestId="lb-DiscoverList-EmptyState" />
      }
      dataTestIdPrefix="lb-Discover"
    />
  );
};

DiscoverList.propTypes = {
  items: PropTypes.arrayOf(resourceItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetDiscoverItems: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    items: getDiscoverItemsList(state),
    currentPage: getCurrentPage(state),
    pagesCount: getDiscoverPagesCount(state),
    isLoading: getDiscoverIsLoading(state),
  }),
  (dispatch) => ({
    resetDiscoverItems: () => dispatch(resetDiscoverItems()),
  })
)(DiscoverList);
