import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import classnames from "classnames";
import { SearchIcon } from "@hapara/ui/src/icons/SearchIcon";
import styles from "./Pickers.module.scss";

interface Student {
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  instances: {
    [key: string]: {
      isOnline: boolean;
      lock: {
        locking: {
          active: boolean;
        };
      };
    };
  };
  currentInstance: string;
}

interface StudentPickerProps {
  students: Student[];
  studentIds: string[];
  onStudentIdsChange: (ids: string[]) => void;
}

const isStudentOffline = (student: Student): boolean => {
  if (!student || !student.instances || !student.currentInstance) {
    return true; // Assume offline if data is missing
  }
  const instance = student.instances[student.currentInstance];
  return instance ? !instance.isOnline : true;
};

const isStudentInSession = (student: Student): boolean => {
  if (!student || !student.instances || !student.currentInstance) {
    return false; // Assume not in session if data is missing
  }
  const instance = student.instances[student.currentInstance];
  return instance?.lock?.locking?.active ?? false;
};

export const StudentPicker: React.FC<StudentPickerProps> = ({
  students = [],
  studentIds = [],
  onStudentIdsChange,
}) => {
  const intl = useIntl();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllSelectedIndeterminate, setIsAllSelectedIndeterminate] =
    useState(false);
  const [search, setSearch] = useState("");

  const isStudentSelected = (id: string) => studentIds?.includes(id);
  const handleStudentSelect = (isChecked: boolean, id: string) => {
    if (isChecked) {
      onStudentIdsChange([...studentIds, id]);
    } else {
      onStudentIdsChange(studentIds?.filter((studentId) => studentId !== id));
    }
  };

  const handleAllSelect = (isChecked: boolean) => {
    if (isChecked) {
      onStudentIdsChange(students?.map((student) => student.Id));
    } else {
      onStudentIdsChange([]);
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const amountOfSelected = studentIds?.length;
    const amountOfAll = students?.length;
    const isIndeterminate =
      amountOfSelected >= 1 && amountOfSelected < amountOfAll;

    setIsAllSelected(
      amountOfAll > 0 ? amountOfSelected === amountOfAll : false
    );
    setIsAllSelectedIndeterminate(isIndeterminate);
  }, [students?.length, studentIds]);

  const getFormattedName = (student: Student) => {
    return `${student.FirstName} ${student.LastName}`;
  };

  const filteredStudents = useMemo(() => {
    return students
      ?.filter((student) =>
        getFormattedName(student).toLowerCase().includes(search.toLowerCase())
      )
      ?.sort((a, b) =>
        getFormattedName(a)
          .toLowerCase()
          .localeCompare(getFormattedName(b).toLowerCase())
      );
  }, [students, search]);

  return (
    <div className={styles.secondaryGroup}>
      <div className={styles.secondaryGroupTitle}>
        <div className={styles.secondaryGroupTitleCheckboxContainer}>
          <Checkbox
            label={intl.formatMessage({
              defaultMessage: "All students",
              id: "zdSuNW",
            })}
            checked={isAllSelected}
            semi={isAllSelectedIndeterminate}
            onChange={handleAllSelect}
            dataTestIdPrefix="guided-browsing-audience-all-students"
          />
        </div>
        <div className={styles.secondaryGroupTitleSearchContainer}>
          <input
            type="text"
            className={styles.secondaryGroupTitleSearchField}
            placeholder={intl.formatMessage({
              defaultMessage: "Search students",
              id: "xkKwUn",
            })}
            onChange={handleSearchInputChange}
            value={search}
            data-test-id="guided-browsing-audience-student-search"
          />
          <SearchIcon />
        </div>
      </div>
      {filteredStudents?.length === 0 ? (
        <div className={styles.secondaryGroupBody}>
          <div className={styles.secondaryGroupBodyEmpty}>
            {intl.formatMessage({
              defaultMessage: "No student found.",
              id: "Wf+sE0",
            })}
          </div>
        </div>
      ) : (
        <ul className={styles.secondaryGroupBody}>
          {filteredStudents?.map((student) => {
            const isOffline = isStudentOffline(student);
            const isInASession = isStudentInSession(student);

            const nameText = getFormattedName(student);

            const statusText = isOffline
              ? intl.formatMessage({
                  defaultMessage: "(offline)",
                  id: "bLm0E3",
                })
              : isInASession
              ? intl.formatMessage({
                  defaultMessage: "(active in a session)",
                  id: "tMUcpz",
                })
              : null;

            return (
              <li key={student.Id} className={styles.secondaryGroupBodyItem}>
                <Checkbox
                  label={
                    <span
                      className={styles.secondaryGroupBodyItemCheckboxInner}
                    >
                      <span
                        className={classnames(styles.name, {
                          [styles.offline]: isOffline,
                        })}
                      >
                        {nameText}
                      </span>
                      {statusText && (
                        <span className={styles.status}>{statusText}</span>
                      )}
                    </span>
                  }
                  checked={isStudentSelected(student.Id)}
                  onChange={(isChecked: boolean) =>
                    handleStudentSelect(isChecked, student.Id)
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
