import {
  postCreateSnapshot,
  fetchSnapshotsList,
  deleteSnap,
  fetchSnapshotDetails,
  postSnapFeedback,
  postSendCopyOnEmail,
} from "../../../apiCalls/highlights";
import { parseOldTSnapsDataToNewFormat } from "./types";
import actionTypes from "../../actionTypes";
import _ from "lodash";
import { updateAccessError } from "../../app/actions";
import { showAppError } from "../../app/actions";

export const createSnapshot =
  (classId, activityData, snapshotType, url, icon, title) =>
  async (dispatch) => {
    const resp = await postCreateSnapshot({
      class: classId,
      studentEmails: activityData.map((a) => a.email),
      studentIds: activityData.map((a_1) => a_1.id),
      snapshot_timestamp: new Date().getTime(),
      "capture-type": snapshotType,
      name: title || url,
      icon: icon,
      "capture-match": url,
      students: activityData,
    });
    if (resp.status !== 200) {
      throw Error(resp);
    }
    return true;
  };

export const loadSnapsList = (classId, showLoadingState) => (dispatch) => {
  if (showLoadingState) {
    dispatch({
      type: actionTypes.HIGHLIGHTS_SNAPSHOTS_LOAD_PENDING,
    });
  }

  return fetchSnapshotsList(classId)
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      } else {
        dispatch({
          type: actionTypes.HIGHLIGHTS_SNAPSHOTS_LOAD_SUCCESS,
          payload: _.map(response.data, (item) =>
            parseOldTSnapsDataToNewFormat(item)
          ),
        });
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};

export const deleteSnapshot = (snapId, classId) => (dispatch) => {
  return deleteSnap(snapId, classId)
    .then((response) => {
      if (response.status !== 200) {
        dispatch(showAppError(Error(response.status)));
      } else {
        dispatch(loadSnapsList(classId, false));
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      dispatch(showAppError(error));
    });
};

export const postSnapshotFeedback = (data, classId, snapId) => (dispatch) => {
  return postSnapFeedback(data, classId, snapId)
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      } else {
        return true;
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};

export const sendSnapOnEmail = (classId, snapId) => (dispatch) => {
  return postSendCopyOnEmail(classId, snapId)
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      } else {
        return true;
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};

export const loadSnapshotDeatils = (snapId, classId) => (dispatch) => {
  return fetchSnapshotDetails(snapId, classId)
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      } else {
        dispatch({
          type: actionTypes.HIGHLIGHTS_SNAPSHOT_DETAILS_LOAD_SUCCESS,
          payload: parseOldTSnapsDataToNewFormat(response.data),
        });
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};
