import React from "react";
import styles from "./WebsiteList.module.scss";
import { Chip } from "@hapara/ui/src/atomic/Chip/Chip";

type WebsiteListProps = {
  websiteList?: string[];
  onRemove: (website: string) => void;
};

export const WebsiteList = ({ websiteList, onRemove }: WebsiteListProps) => {
  return websiteList ? (
    <div className={styles.container}>
      {websiteList?.map((website, index) => (
        <Chip
          maxWidth={260}
          key={index}
          label={website}
          type="info"
          onRemove={() => onRemove(website)}
          dataTestIdPrefix="session-site"
        />
      ))}
    </div>
  ) : null;
};
