import React from "react";
import { TableCheckbox } from "../../TableCheckbox";
import { injectProps } from "../../utils/injectProps";
import { useTable } from "../../useTable";
import { Cell } from "../Cell";
import classnames from "classnames";
import styles from "../Row/Row.module.scss";

export type RowType = "success" | "danger" | "warning" | "info";

export type RowProps = {
  children?: React.ReactNode;
  id?: string | number;
  type?: RowType;
  _ariaRowIndex?: number;
};
export const Row = ({ id, children, type, _ariaRowIndex }: RowProps) => {
  const { dataTestIdPrefix, onSelect, selected } = useTable();

  const selectableTestId = onSelect ? "selectable-" : "";
  const dataTestId =
    dataTestIdPrefix && _ariaRowIndex !== undefined
      ? `${dataTestIdPrefix}-table-${selectableTestId}row-${_ariaRowIndex}`
      : undefined;

  const handleSelect = () => {
    if (onSelect && id) {
      onSelect(id);
    }
  };

  const isSelected = id ? selected?.includes(id) : undefined;

  return (
    <tr
      data-test-id={dataTestId}
      aria-rowindex={_ariaRowIndex}
      aria-selected={onSelect ? isSelected : undefined}
      className={classnames(type ? styles[type] : undefined)}
    >
      {onSelect ? (
        <Cell align="right">
          <TableCheckbox
            hideLabel
            label={isSelected ? "Deselect row" : "Select row"}
            checked={isSelected}
            onChange={handleSelect}
            dataTestIdPrefix={dataTestId}
          />
        </Cell>
      ) : null}
      {injectProps(children, (index) => ({
        _ariaRowIndex,
        _ariaColIndex: index,
      }))}
    </tr>
  );
};

Row.displayName = "Row";
