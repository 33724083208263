import _ from "lodash";
import moment from "moment";
import { SMARTSHARE_STATUS } from "../../../../apiCalls/smartshare";
import { getPlural } from "../../../utils";

export const AVAILABLE_TO_UNSHARE_MINUTES = 5;

type Doc = {
  Students: Array<{
    Status: string;
  }>;
};

export const getAmountOf = (docs: Doc[], status: string) => {
  let total = 0;

  _.forEach(docs, (doc) => {
    total += _.filter(doc.Students, { Status: status }).length;
  });

  return total;
};

export const getReadyToRenderData = (process: {
  id: string;
  classes: string[];
  documents: Doc[];
  status: string;
  students: string[];
  currentProgress: number;
  fullProgress: number;
  process_ids: string[];
  created_time: string;
  groups: string[];
  share_completed_time: moment.MomentInput;
}) => {
  let icon = "loader";
  let spinIcon = true;
  let shareText = "";
  let showUnshare = false;
  let iconColourStyle = "grey";
  let highlighted = false;
  let modalTitle = "";
  let documents: Doc[] = [];
  let students: string[] = [];
  let showProgressBar = false;
  let numErrors = 0;

  switch (process.status) {
    case SMARTSHARE_STATUS.SHARE_PROGRESS:
      documents = process.documents;
      numErrors = getAmountOf(documents, "error");
      shareText = "Sharing";
      modalTitle = "Sharing in progress";
      showUnshare = true;
      showProgressBar = true;
      break;

    case SMARTSHARE_STATUS.UNSHARE_SCHEDULED:
      shareText = `Unsharing ${getPlural(process.documents.length, "file")}`;
      students = process.students;
      documents = [];
      numErrors = 0;
      highlighted = true;
      modalTitle = "Unsharing in progress";
      break;

    case SMARTSHARE_STATUS.UNSHARE_PROGRESS:
      shareText = "Unsharing";
      documents = process.documents;
      numErrors = getAmountOf(documents, "error");
      highlighted = true;
      modalTitle = "Unsharing in progress";
      break;

    case SMARTSHARE_STATUS.SHARE_COMPLETED:
      documents = process.documents;
      numErrors = getAmountOf(documents, "error");
      icon = !numErrors ? "circle-check-fill" : "circle-checkmark";
      spinIcon = false;
      showUnshare =
        moment().diff(moment(process.share_completed_time), "minutes") <=
        AVAILABLE_TO_UNSHARE_MINUTES;
      shareText = "Shared";
      iconColourStyle = "green";
      modalTitle = "Sharing is complete";
      break;

    case SMARTSHARE_STATUS.UNSHARE_COMPLETED:
      documents = process.documents;
      numErrors = getAmountOf(documents, "error");
      icon = !numErrors ? "circle-check-fill" : "circle-checkmark";
      spinIcon = false;
      shareText = "Unshared";
      highlighted = true;
      modalTitle = "Unsharing is complete";
      break;

    default:
      break;
  }
  const errorText = numErrors ? `${getPlural(numErrors, "error")} found` : "";

  return {
    classes: process.classes,
    documents,
    errorText,
    groups: process.groups,
    highlighted,
    icon,
    iconColourStyle,
    id: process.id,
    modalTitle,
    percentage: showProgressBar
      ? ((process.currentProgress || 0) * 1.0) / (process.fullProgress || 100.0)
      : null,
    process_ids: process.process_ids,
    shareText: `${shareText} ${getPlural(documents.length, "file")}`,
    showProgressBar,
    showUnshare,
    spinIcon,
    status: process.status,
    students,
    timestamp: process.created_time,
  };
};
