import actionTypes from "../../actionTypes";
import {
  getGClassroomContent,
  getGCCourseWorkAttachments,
} from "../../../apiCalls/highlights";
import { AppDispatch } from "../../store";
import _ from "lodash";

export const REQ_TYPES = {
  COURSEWORK: "coursework",
  MATERIALS: "material",
};

//GET COURSEWORK ITEMS FOR GCLASSROOM COURSE
export const fetchGCCourseWork =
  (courseID: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_PENDING,
    });
    getGClassroomContent(courseID, REQ_TYPES.COURSEWORK)
      .then((res) => {
        const cwResponse = res.data;
        return cwResponse;
      })
      .then((cwResponse) => {
        const itemArr = !_.isEmpty(cwResponse.courseWork)
          ? cwResponse.courseWork
          : [];
        return Promise.all(getItemAttachments(courseID, itemArr))
          .then((res) => {
            const attachments = !_.isEmpty(res) ? res : [];
            return attachments;
          })
          .then((attachments) => {
            dispatch({
              type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_SUCCESS,
              payload: {
                items: attachments,
                nextPageToken: cwResponse.nextPageToken,
              },
            });
          })
          .catch((err) => {
            dispatch({
              type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
              payload: err,
            });
          });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
        });
      });
  };

//LOADS MORE COURSEWORK ITEMS USING NEXT PAGE TOKEN
export const fetchMoreGCCourseWork =
  (courseID: string, nextPageToken: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_PENDING,
    });
    getGClassroomContent(courseID, REQ_TYPES.COURSEWORK, nextPageToken)
      .then((res) => {
        const cwResponse = res.data;
        return cwResponse;
      })
      .then((cwResponse) => {
        const itemArr = !_.isEmpty(cwResponse.courseWork)
          ? cwResponse.courseWork
          : [];
        return Promise.all(getItemAttachments(courseID, itemArr))
          .then((res) => {
            const attachments = !_.isEmpty(res) ? res : [];
            return attachments;
          })
          .then((attachments) => {
            dispatch({
              type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_SUCCESS,
              payload: {
                items: attachments,
                nextPageToken: cwResponse.nextPageToken,
              },
            });
          })
          .catch((err) => {
            dispatch({
              type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
              payload: err,
            });
          });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
        });
      });
  };

//GET MATERIALS FOR GCLASSROOM COURSE
export const fetchCGMaterials =
  (courseID: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_PENDING,
    });
    getGClassroomContent(courseID, REQ_TYPES.MATERIALS)
      .then((res) => {
        const materialResponse = res.data;
        if (res.status === 407) {
          dispatch({
            type: actionTypes.HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_NOT_AUTHORISED,
          });
        } else {
          dispatch({
            type: actionTypes.HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_AUTHORISED,
          });
        }
        return materialResponse;
      })
      .then((materialResponse) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_SUCCESS,
          payload: {
            items: materialResponse.coursework,
            nextPageToken: materialResponse.nextPageToken,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_ERROR,
          payload: err,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_ERROR,
        });
      });
  };

//LOADS MORE MATERIALS FOR GCLASSROOM COURSE
export const fetchMoreCGMaterials =
  (courseID: string, nextPageToken: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_PENDING,
    });
    getGClassroomContent(courseID, REQ_TYPES.MATERIALS, nextPageToken)
      .then((res) => {
        const materialResponse = res.data;
        return materialResponse;
      })
      .then((materialResponse) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_SUCCESS,
          payload: {
            items: materialResponse.coursework,
            nextPageToken: materialResponse.nextPageToken,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
          payload: err,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR,
        });
      });
  };

//GETS ATTACHMENTS FOR EACH COURSEWORK ITEM
const getItemAttachments = (courseID: string, courseWork: any) => {
  const courseWorkPayload = courseWork.map((item: any) => {
    const itemPayload = getGCCourseWorkAttachments(courseID, item.id).then(
      (res) => {
        const itemAttachments = {
          attachments: res.data,
          attachmentReqStatus: res.status,
        };
        const expandedItem = {
          ...item,
          ...itemAttachments,
        };
        return expandedItem;
      }
    );
    return itemPayload;
  });

  return courseWorkPayload;
};
