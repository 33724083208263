import { SHARED_URL_KEYS } from "./navigation-keys";
import PropTypes from "prop-types";

export const DEFAULT_PAGE_SIZE = 20;

export const SHARED_FILTER_TYPES = {
  GRADES: {
    title: "Grades",
    value: "Grades",
    paramName: SHARED_URL_KEYS?.grades.key,
  },
  SUBJECTS: {
    title: "Subjects",
    value: "Subjects",
    paramName: SHARED_URL_KEYS.subjects.key,
  },
  STANDARDS: {
    title: "Standards",
    value: "Standards",
    paramName: SHARED_URL_KEYS.standards.key,
  },
  LICENCES: {
    title: "Licence type",
    value: "Licences",
    paramName: SHARED_URL_KEYS.licences.key,
  },
  RESOURCE_TYPE: {
    title: "Resource type",
    value: "ResourceTypes",
    paramName: SHARED_URL_KEYS.resourceType.key,
  },
  FORMAT: {
    title: "Format",
    value: "format",
    paramName: SHARED_URL_KEYS.format.key,
  },
};

export const filterItemType = PropTypes.shape({
  Key: PropTypes.string.isRequired,
  Value: PropTypes.string.isRequired,
  Group: PropTypes.string,
});

export const allFiltersType = PropTypes.shape({
  [SHARED_FILTER_TYPES.GRADES.value]: PropTypes.arrayOf(filterItemType),
  [SHARED_FILTER_TYPES.SUBJECTS.value]: PropTypes.arrayOf(filterItemType),
  [SHARED_FILTER_TYPES.STANDARDS.value]: PropTypes.arrayOf(filterItemType),
  [SHARED_FILTER_TYPES.RESOURCE_TYPE.value]: PropTypes.arrayOf(filterItemType),
  [SHARED_FILTER_TYPES.LICENCES.value]: PropTypes.arrayOf(filterItemType),
  [SHARED_FILTER_TYPES.FORMAT.value]: PropTypes.arrayOf(filterItemType),
});

export const SORT_TYPE = {
  LAST_ADDED: {
    type: "lastAdded",
    field: "date",
    order: "desc",
  },
  FIRST_ADDED: {
    type: "firstAdded",
    field: "date",
    order: "asc",
  },
  TITLE_AZ: {
    type: "titleAZ",
    field: "title",
    order: "asc",
  },
  TITLE_ZA: {
    type: "titleZA",
    field: "title",
    order: "desc",
  },
};
