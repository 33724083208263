import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ModalFormTextField.module.scss";
import _ from "lodash";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import Emoji from "@hapara/ui/src/atomic/Emoji/Emoji";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

const ModalFormTextField = ({
  text,
  onTextChange,
  hasModalCopyUpdateFlag,
  dataTestPrefix,
}) => {
  const intl = useIntl();
  const maxLength = 170;
  const [msgLength, setMsgLength] = useState(0);
  const textAreaRef = useRef(null);
  //manually calculate the characters length
  useEffect(() => {
    //some emojis take 8 characters, when it is splitted into an array, it will take 2 or 4 indexes
    const UNICODE_FILTER_LIST = [
      8205, 65039, 127998, 127999, 9760, 9992, 9794, 9792, 128536, 128103,
      128104, 128105,
    ];

    let a = 0;
    for (const ch of text) {
      const code = ch.codePointAt(0);
      if (!UNICODE_FILTER_LIST.includes(code)) {
        a++;
      }
    }
    setMsgLength(a);
  }, [text]);

  const handleTextArea = (e) => {
    const textStr = _.get(e, "target.value", "");
    if (msgLength < maxLength || textStr.length < text.length) {
      onTextChange(textStr);
    }
  };

  const RenderTextArea = (
    <Textarea
      ref={textAreaRef}
      placeholder={
        hasModalCopyUpdateFlag
          ? intl.formatMessage({
              defaultMessage: "Type announcement (Maximum 170 characters)",
              id: "/FmonM",
            })
          : intl.formatMessage({
              defaultMessage: "Send a message (Maximum 170 characters)",
              id: "mv8E5x",
            })
      }
      data-test-id={`${dataTestPrefix}-TextareaField`}
      value={text}
      onChange={(e) => handleTextArea(e)}
      className={classnames(styles.textInput, styles.textArea)}
      aria-describedby="textareaCharsCounterId"
      aria-label={intl.formatMessage({
        defaultMessage: "Announcement",
        id: "Sj+TN8",
      })}
      focusOnTextarea={true}
    />
  );
  const RenderEmoji = (
    <Emoji
      onTextChange={(emojiStr) => {
        if (msgLength < maxLength) {
          let startPos = textAreaRef?.current?.selectionStart;
          let endPos = textAreaRef?.current?.selectionEnd;
          text =
            text.substring(0, startPos) +
            emojiStr.native +
            text.substring(endPos, text.length);
          onTextChange(text);
          _.delay(() => {
            textAreaRef.current.focus();
            textAreaRef.current.selectionEnd =
              startPos + emojiStr.native.length;
          }, 10);
        }
      }}
    />
  );

  const calculateCharacterLength = () => {
    return msgLength ? maxLength - msgLength : maxLength;
  };
  return (
    <div className={styles.root}>
      <fieldset
        className={styles.textForm}
        aria-label={intl.formatMessage({
          defaultMessage: "Send announcement to students",
          id: "NJpEFV",
        })}
      >
        <div className={styles.textareaContainer}>
          {RenderTextArea}
          <div
            date-test-id={`${dataTestPrefix}-TextareaSubSection`}
            className={styles.textareaSubSection}
          >
            {/* counter for the characters in the text area */}
            <div
              data-test-id={`${dataTestPrefix}-TextareaCharsCounter`}
              className={styles.charsCounter}
              id="textareaCharsCounterId"
            >
              <FormattedMessage
                values={{ characterLength: calculateCharacterLength() }}
                defaultMessage="{characterLength} characters remaining"
                id="VkH4J/"
              />
            </div>

            {RenderEmoji}
          </div>
        </div>
      </fieldset>
    </div>
  );
};

ModalFormTextField.propTypes = {
  text: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  hasModalUpdateFlag: PropTypes.bool,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default ModalFormTextField;
