import React from "react";
import styles from "./OutsideSchoolHoursInfoPanel.module.scss";
import emptyDeskImage from "@hapara/assets/src/images/vector-emptydesk.svg";
import { FormattedMessage, useIntl } from "react-intl";

const OutsideSchoolHoursInfoPanel = () => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <img
        src={emptyDeskImage}
        alt={intl.formatMessage({
          defaultMessage: "Empty student desk",
          id: "1WKll4",
        })}
        width={240}
        height={240}
      />
      <h1 className={styles.header}>
        <FormattedMessage
          defaultMessage="School is finished for the day"
          id="0QjGOw"
        />
      </h1>
      <div className={styles.text}>
        <FormattedMessage
          defaultMessage="Students' online activity is only available during school hours."
          id="L2oXqu"
        />
      </div>
    </div>
  );
};

OutsideSchoolHoursInfoPanel.propTypes = {};

export default OutsideSchoolHoursInfoPanel;
