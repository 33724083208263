import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import Section from "../Section/Section";
import ColumnsHeaders from "../ColumnsHeaders/ColumnsHeaders";
import Title from "../WorkspaceTitle/WorkspaceTitle";
import GroupFilterBar from "../GroupFilterBar/GroupFilterBar";
import styles from "./BoardLayout.module.scss";
import classnames from "classnames";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";

import {
  Workspace,
  Column as ColumnType,
  Card as CardType,
  Group as GroupType,
  Action,
  ColumnType as ColumnTypeEnum,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";

type BoardLayoutProps = {
  workspace: Workspace;
  dataTestIdPrefix: string;
  setDeleteCardID: (cardId: string) => void;
  setEvidenceModalPayload: (modalResponse: [string, string]) => void;
};

export const BoardLayout = ({
  workspace,
  dataTestIdPrefix,
  setDeleteCardID,
  setEvidenceModalPayload,
}: BoardLayoutProps) => {
  // section id from URL
  const { sectionId } = useParams<{ sectionId?: string }>();
  const [activeFilter, setActiveFilter] = useState(""); //Filters cards by group ID
  const [activeCards, setActiveCards] = useState(workspace.Cards);
  const [showFlexiCards, setShowFlexiCards] = useState(true);

  const permissions = workspace?.Permissions[0].Actions;
  const allColumnTypes = [
    ColumnTypeEnum.Goal,
    ColumnTypeEnum.Evidence,
    ColumnTypeEnum.Rubric,
    ColumnTypeEnum.Resource,
  ];

  const sectionsSorted = _.sortBy(workspace.Sections, ["Order"]);
  const nonEmptyColumnTypes = permissions.includes(Action.CanEdit)
    ? allColumnTypes
    : _.uniq(workspace.Cards.map((c) => c.ColumnType));
  const nonEmptyColumns = permissions.includes(Action.CanEdit)
    ? workspace.Columns
    : workspace.Columns.filter((c) => nonEmptyColumnTypes.includes(c.Type));

  useEffect(() => {
    // scroll to selected section
    if (!_.isEmpty(sectionId)) {
      const selectedSection = document.getElementById(sectionId!);
      if (selectedSection) {
        selectedSection.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [sectionId]);

  useEffect(() => {
    //Filter cards when a filter is activated and reset on All
    if (!_.isEmpty(activeFilter)) {
      const filteredCards = workspace.Cards.filter((card) => {
        const assignedGroups = card.GroupsFilter.map((group) => group!.ID);
        return assignedGroups.includes(activeFilter) ||
          _.isEmpty(assignedGroups)
          ? card
          : null;
      });
      setActiveCards(filteredCards);
    } else {
      setActiveCards(workspace.Cards);
    }
  }, [activeFilter, workspace.Cards]);

  const columnsWidthStyle = ((colNumber) => {
    switch (colNumber) {
      case 1:
        return styles.oneColumn;
      case 2:
        return styles.twoColumns;
      case 3:
        return styles.threeColumns;
      default:
        return styles.fourColumns;
    }
  })(nonEmptyColumns.length);

  return (
    <div className={classnames(styles.root, columnsWidthStyle)}>
      <Title title={workspace?.Title} description={workspace?.Description} />
      <div className={styles.setting}>
        <Toggle
          className={styles.toggle}
          explanatoryText="FlexiCard Mode"
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          toggleValues={{
            left: { label: "Off" },
            right: { label: "On" },
          }}
          value={showFlexiCards}
          onChange={() => {
            setShowFlexiCards(!showFlexiCards);
          }}
          dataTestIdPrefix={`${dataTestIdPrefix}-ShowFlexiCards`}
        />
      </div>

      <div className={classnames(styles.stickyWrapper, columnsWidthStyle)}>
        <GroupFilterBar
          groups={workspace?.Groups as GroupType[]}
          activateFilter={setActiveFilter}
          dataTestIdPrefix={dataTestIdPrefix}
        />
      </div>
      <ColumnsHeaders columns={nonEmptyColumns as ColumnType[]} />

      {sectionsSorted.map((section, ind) => {
        //Organises cards into sections (including default section)
        const sectionCards = _.filter(activeCards, (c) => {
          if (_.isEmpty(section.ID)) {
            return c.SectionID === null || c.SectionID === "";
          } else {
            return c.SectionID === section?.ID;
          }
        });

        return (
          <React.Fragment key={section?.ID}>
            <Section
              columns={nonEmptyColumns as ColumnType[]}
              hideSectionName={ind === 0 && _.isEmpty(section?.Title)}
              sectionData={section}
              sectionCards={sectionCards as CardType[]}
              singleColumnCardStyle={nonEmptyColumns.length === 1}
              flexiCardMode={showFlexiCards}
              boardId={workspace?.ID ?? ""}
              dataTestIdPrefix={dataTestIdPrefix}
              permissions={permissions}
              setDeleteCardID={setDeleteCardID}
              setEvidenceModalPayload={setEvidenceModalPayload}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BoardLayout;
