import { fetchLibraryResources } from "../../../apiCalls/library";
import actionTypes from "../../actionTypes";
import _ from "lodash";
import { updateAccessError, showAppError } from "../../app/actions";
import { LIBRARY_PAGE_SIZE, LIBRARY_CATEGORIES } from "./types";
import { SORT_TYPE } from "../../shared/types";
import { RESOURCE_FORMAT } from "../../resources/types";
import {
  getLibraryItemsTotal,
  getLibrarySearchGuid,
  getLibrarySearchIteration,
} from "./selectors";
import { getSelectedCollection } from "../collections/selectors";
import { populateLibrarySearchEventData, pendoTrack } from "../../../utils";

export const loadLibraryItems =
  ({
    query,
    collectionId,
    format,
    sortOrder,
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
    pageSize,
    page = 1,
  }) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.LIBRARY_LIBRARY_ITEMS_LOAD_PENDING,
    });

    const bodyRequest = {
      PageSize: pageSize,
      Page: page,
      Query: query,
      Grades: grades,
      Subjects: subjects,
      Authorities: standards,
      ResourceTypes: resourceTypes,
      LicenceTypes: licences,
    };

    switch (format) {
      case RESOURCE_FORMAT.WORKSPACE:
        bodyRequest.Format = RESOURCE_FORMAT.WORKSPACE;
        break;

      case RESOURCE_FORMAT.RESOURCE:
        bodyRequest.Format = RESOURCE_FORMAT.RESOURCE;
        break;

      default:
        break;
    }

    switch (collectionId) {
      case LIBRARY_CATEGORIES.ALL.urlKey:
        break;

      case LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey:
        bodyRequest.CollectionIDs = ["none"];
        break;

      default:
        bodyRequest.CollectionIDs = [collectionId];
        break;
    }

    switch (sortOrder) {
      case SORT_TYPE.TITLE_AZ.type:
        bodyRequest.SortField = SORT_TYPE.TITLE_AZ.field;
        bodyRequest.SortOrder = SORT_TYPE.TITLE_AZ.order;
        break;

      case SORT_TYPE.TITLE_ZA.type:
        bodyRequest.SortField = SORT_TYPE.TITLE_ZA.field;
        bodyRequest.SortOrder = SORT_TYPE.TITLE_ZA.order;
        break;
      default:
        break;
    }

    return fetchLibraryResources({ bodyRequest })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(showAppError(Error(response.status)));
        } else {
          dispatch({
            type: actionTypes.LIBRARY_LIBRARY_ITEMS_LOAD_SUCCESS,
            payload: response.data,
          });
        }
      })
      .then(() => {
        // send info only if it's a search action
        if (!_.isEmpty(query)) {
          const state = getState();
          const iterCount = getLibrarySearchIteration(state);
          const searchGuid = getLibrarySearchGuid(state);
          const totalResults = getLibraryItemsTotal(state);
          const collecton = getSelectedCollection(state);
          const searchFilters = populateLibrarySearchEventData({
            grades,
            subjects,
            standards,
            resourceTypes,
            licences,
            query,
            collectonId: collecton.id,
            collectonName: collecton.name,
            page,
            totalResults,
            searchGuid,
            iterCount,
          });
          pendoTrack("Our Library Search", searchFilters);
          dispatch({
            type: actionTypes.LIBRARY_LIBRARY_SEARCH_GUID_UPDATE,
            searchGuid: searchFilters.guid,
            iterCount: searchFilters.iter,
          });
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        } else {
          dispatch(showAppError(error));
        }
      });
  };

export const resetLibraryItems = () => ({
  type: actionTypes.LIBRARY_LIBRARY_RESET_ITEMS,
});

export const applyLibraryLibrarySearchParams =
  ({
    page,
    query,
    collectionId,
    format,
    sortOrder,
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
  }) =>
  (dispatch) => {
    dispatch(
      loadLibraryItems({
        query,
        collectionId,
        format,
        sortOrder,
        grades,
        subjects,
        standards,
        resourceTypes,
        licences,
        page,
        pageSize: LIBRARY_PAGE_SIZE,
      })
    );
  };

export const toggleLibraryLeftPanel = (isOpen) => ({
  type: actionTypes.LIBRARY_LIBRARY_LEFT_PANEL_UPDATE,
  payload: isOpen,
});
