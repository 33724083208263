import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import styles from "./CollectionFilterItem.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { collectionItemType } from "../../../../state/library/collections/types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";

export const CollectionFilterItem = ({
  active,
  item,
  inEditMode,
  editCollection,
  deleteCollection,
}) => {
  const dataTestIdPrefix = "lb-Library-Collections-LeftPanelFilter";

  if (!inEditMode)
    return (
      <li className={styles.root}>
        <NavLink
          to={`${APP_PAGES.LIBRARY_CATEGORIES.path}/${item.id}`}
          className={classnames(styles.name, styles.navLink)}
          activeClassName={styles.active}
          data-test-id={`${dataTestIdPrefix}-Link`}
          aria-label={`${item.name}${active ? ", Selected" : ""}`}
          isActive={() => active}
          aria-current={null}
        >
          <HapReactIcon
            svg="material-icon-folder"
            width={20}
            height={20}
            className={classnames(styles.icon, {
              [styles.iconActive]: active,
            })}
          />
          <div className={styles.title}>{item.name}</div>
        </NavLink>
      </li>
    );

  if (inEditMode)
    return (
      <div className={classnames(styles.root, styles.editMode)}>
        <div className={styles.name}>
          <HapReactIcon
            svg="material-icon-folder"
            width={20}
            height={20}
            className={classnames(styles.icon, styles.iconEdit)}
          />
          <div className={styles.title}>{item.name}</div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            icon="pencil"
            onAction={editCollection}
            dataTestId={`${dataTestIdPrefix}-Button-Edit`}
            aria-label={`Edit collection ${item.name}`}
            className={styles.updateButton}
          />
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            icon="delete"
            onAction={deleteCollection}
            dataTestId={`${dataTestIdPrefix}-Button-Delete`}
            aria-label={`Delete collection ${item.name}`}
            className={styles.updateButton}
          />
        </div>
      </div>
    );
};

CollectionFilterItem.propTypes = {
  active: PropTypes.bool.isRequired,
  item: collectionItemType.isRequired,
  inEditMode: PropTypes.bool,
  editCollection: PropTypes.func.isRequired,
  deleteCollection: PropTypes.func.isRequired,
};

export default CollectionFilterItem;
