import { Redirect } from "react-router-dom";
import type { RouteProps } from "react-router";
import { APP_PAGES } from "../../state/router/types";
import PrivateRoute from "./PrivateRoute";
import ClassChangeHandler from "../containers/Highlights/ClassChangeHandler";
import ScreenshotWebsocketHandler from "../containers/Highlights/ScreenshotWebsocketHandler";
import StudentRequestsHandler from "../containers/Highlights/StudentRequestsHandler";

import SchoolHoursHandler from "../containers/Highlights/SchoolHoursHandler";
import { getClassId } from "../../state/shared/selectors";
import { useAppSelector } from "../../state/hooks";

interface HighlightsRouteProps extends RouteProps {
  pagePermissions?: Array<string>;
}

export const HighlightsRoute = ({
  component,
  ...rest
}: HighlightsRouteProps): JSX.Element => {
  const selectedClassId = useAppSelector((state) => getClassId(state));

  // if classId is not present, redirect to Manage classes
  if (!selectedClassId) {
    return <Redirect to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path} />;
  }
  return (
    <>
      <ClassChangeHandler />
      <ScreenshotWebsocketHandler />
      <StudentRequestsHandler />
      <SchoolHoursHandler />
      <PrivateRoute {...rest} component={component} />
    </>
  );
};

export default HighlightsRoute;
