import { FormattedMessage } from "react-intl";
import React from "react";
import _ from "lodash";
import styles from "./DiscoverExploreSubjects.module.scss";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../../state/router/types";
import {
  EXPLORE_SUBJECTS_SHORTCUTS,
  LANDING_PAGE_SHORTCUT_TYPE,
} from "../../../../state/library/discover/types";
import { SHARED_FILTER_TYPES } from "../../../../state/shared/types";

const DiscoverExploreSubjectsItem = ({ item }) => {
  const images = require.context("@hapara/assets/src/images/", false);

  return (
    <Link
      to={{
        pathname: `${APP_PAGES.DISCOVER_SEARCH.path}`,
        search: `${SHARED_FILTER_TYPES.SUBJECTS.paramName}=${item.Filter}&page=1`,
      }}
      className={styles.itemRoot}
      data-test-id={`lb-Discover-ExploreSubjects-${item.DataTestPrefix}`}
    >
      <img src={images(`./${item.BackgroundImage}.png`).default} alt="" />
      <div className={styles.onTop}>
        <div className={styles.title}>{item.Title}</div>
      </div>
    </Link>
  );
};

DiscoverExploreSubjectsItem.propTypes = {
  item: LANDING_PAGE_SHORTCUT_TYPE,
};

const DiscoverExploreSubjects = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>
          <FormattedMessage defaultMessage="Explore subjects" id="BcOGH8" />
        </h2>
      </div>
      <ul
        className={styles.body}
        data-test-id="lb-Discover-ExploreSubjects-ShortCutsList"
      >
        {_.map(EXPLORE_SUBJECTS_SHORTCUTS, (item) => {
          return (
            <li key={item.Id} className={styles.listItem}>
              <DiscoverExploreSubjectsItem item={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DiscoverExploreSubjects;
