import actionTypes from "../../actionTypes";
import { gcContentShape, gcMaterialItemShape } from "./types";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_SUCCESS,
  DATA_LOADING_STATE_PENDING,
} from "../../storeUtils";

interface gcCourseWorkState {
  courseWork: gcContentShape | null;
  materials: gcContentShape | null;
}

const INIT_STATE: gcCourseWorkState = {
  courseWork: null,
  materials: null,
};

// TODO: ensure this reduce is returning the correct state with `gcCourseWorkState` https://hapara.atlassian.net/browse/HAP-10884
const gcContentReducer = (
  state = INIT_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_PENDING: {
      return {
        ...state,
        courseWork: {
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        courseWork: {
          ...payload,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_PENDING: {
      return {
        ...state,
        courseWork: {
          ...state.courseWork,
          isCourseWorkLoadingMore: true,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_SUCCESS: {
      const { payload } = action;
      const updatedPayload = {
        items: [...state.courseWork!.items, ...payload.items],
        nextPageToken: payload.nextPageToken,
      };
      return {
        ...state,
        courseWork: {
          ...updatedPayload,
          ...DATA_LOADING_STATE_SUCCESS,
          isCourseWorkLoadingMore: false,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR: {
      return {
        ...state,
        courseWork: {
          items: [],
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
        materials: {
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_SUCCESS: {
      const { payload } = action;
      const updatedPayload = compileMaterialPayload(payload.items);
      return {
        ...state,
        materials: {
          items: updatedPayload,
          nextPageToken: payload.nextPageToken,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_ERROR: {
      return {
        ...state,
        materials: {
          items: [],
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_PENDING: {
      return {
        ...state,
        materials: {
          ...state.materials,
          isMaterialsLoadingMore: true,
        },
        isLoadMoreLoading: true,
      };
    }
    case actionTypes.HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_SUCCESS: {
      const { payload } = action;
      const updatedPayload = compileMaterialPayload(payload.items);
      return {
        ...state,
        materials: {
          items: [...state.materials!.items, ...updatedPayload],
          nextPageToken: payload.nextPageToken,
          isMaterialsLoadingMore: false,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_AUTHORISED: {
      return {
        ...state,
        hasGCMaterialsAccess: true,
      };
    }
    case actionTypes.HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_NOT_AUTHORISED: {
      return {
        ...state,
        hasGCMaterialsAccess: false,
      };
    }
    default:
      return state;
  }
};

export default gcContentReducer;

const compileMaterialPayload = (payload: gcMaterialItemShape[]) => {
  const compiledPayload = payload.map((item: gcMaterialItemShape) => {
    const updatedItem = {
      ...item,
      link: item.parent,
      assignedTo: item.students,
      attachments: item.attachments ? item.attachments : [],
      attachmentReqStatus: 200,
    };
    delete updatedItem.parent;
    delete updatedItem.students;
    return updatedItem;
  });
  return compiledPayload;
};
