import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  STUDENT_BROWSER_TAB_TYPE,
  STUDENT_TAB_CLOSE_STATUS,
} from "../../../../state/highlights/students/types";
import {
  getClassTabCloseReasonsEnabled,
  getClassTabCloseReasons,
} from "../../../../state/highlights/config/selectors";
import CloseTabReason from "../CloseTabReason/CloseTabReason";
import { closeStudentTab } from "../../../../state/highlights/students/actions";
import { getUserFamilyName } from "../../../../state/user/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import { CloseTabModal } from "../../CloseTabModal/CloseTabModal";
import { getFFByName } from "../../../../state/app/selectors";
import { pendoTrack } from "../../../../utils";

const FIVE_SECONDS = 1000 * 5;

const CloseTabHandler = ({
  tabs,
  tabsLimit,
  closeTab,
  closeWithReasonEnabled = false,
  closeReasons = [],
  tabComponent,
  getStudentInfo,
  studentDisplayName,
  classId,
  teacherName,
  duplicateTabConsolidated = false,
  isCloseTabEnhancementEnabled,
}) => {
  const [showCloseTabModal, setShowCloseTabModal] = useState(false);
  const [tabToBeClosedWithReason, setTabToBeClosedWithReason] = useState({});
  const [closeModalPosition, setCloseModalPosition] = useState({});
  const [closeTabStatuses, setCloseTabStatuses] = useState({});
  const [tabsToShow, setTabsToShow] = useState([]);

  useEffect(() => {
    if (!tabToBeClosedWithReason) {
      return;
    }

    // filter out "closed" tabs
    const tabsFiltered = tabs.filter((t) => {
      if (
        _.get(closeTabStatuses, `${t.id}.status`) ===
        STUDENT_TAB_CLOSE_STATUS.PROGRESS
      ) {
        if (
          _.get(closeTabStatuses, `${t.id}.timestamp`) + FIVE_SECONDS >
          Date.now()
        ) {
          return false;
        } else {
          // closing tab for more than 5 seconds ?
          setCloseTabStatuses(_.omit(closeTabStatuses, [t.id])); // remove object key
          return true;
        }
      } else {
        return true;
      }
    });

    // consolidate duplicates
    let tabsConsolidated = [];

    if (duplicateTabConsolidated) {
      const groupedTabs = _.groupBy(tabsFiltered, "url");

      _.forEach(groupedTabs, (groupList) => {
        if (groupList.length > 1) {
          const consolidatedTab = { ...groupList[0] };
          consolidatedTab.ids = _.map(groupList, "id");
          tabsConsolidated.push(consolidatedTab);
        } else {
          tabsConsolidated.push(groupList[0]);
        }
      });
    } else {
      tabsConsolidated = tabsFiltered;
    }

    setTabsToShow(
      tabsLimit > 0 ? tabsConsolidated.slice(0, tabsLimit) : tabsConsolidated
    );
  }, [
    tabs,
    closeTabStatuses,
    tabToBeClosedWithReason,
    tabsLimit,
    duplicateTabConsolidated,
  ]);

  const closeTabAction = (tab, e, reason = null) => {
    if (
      closeWithReasonEnabled &&
      closeReasons.length > 0 &&
      showCloseTabModal === false &&
      e !== null &&
      e.target
    ) {
      const crossPosition = e.target.getBoundingClientRect();
      const cellPosition = crossPosition;
      setCloseModalPosition({
        crossPositionTop: crossPosition.top,
        crossPositionLeft: crossPosition.left,
        cellPositionLeft: cellPosition.left,
      });
      setTabToBeClosedWithReason(tab);
      setShowCloseTabModal(true);
    } else {
      const newStatuses = {};
      _.forEach(tab.ids && tab.ids.length > 0 ? tab.ids : [tab.id], (id) => {
        newStatuses[id] = {
          status: STUDENT_TAB_CLOSE_STATUS.PROGRESS,
          timestamp: Date.now(),
        };
      });
      setCloseTabStatuses({
        ...closeTabStatuses,
        ...newStatuses,
      });
      let tabsFiltered = tabsToShow.filter((t) => t.id !== tab.id);
      if (tabsLimit > 0) {
        tabsFiltered = tabsFiltered.slice(0, tabsLimit);
      }
      setTabsToShow(tabsFiltered);
      const st = getStudentInfo(tab);
      const closeTabData = {
        userId: st.Id,
        userName: st.LastName,
        tab,
        reason,
        instanceId: st.instanceId,
        classId,
        teacherName,
      };
      const { instanceId, ...pendoData } = closeTabData;
      pendoTrack("Close student tab", {
        ...pendoData,
        tab: tab?.url,
      });
      closeTab(closeTabData);
    }
  };

  const handleCloseModal = () => {
    setShowCloseTabModal(false);
    setTabToBeClosedWithReason({});
  };

  return (
    <>
      {_.map(tabsToShow, (tab) =>
        tabComponent(
          tab,
          (e) => closeTabAction(tab, e),
          closeWithReasonEnabled && tab.id === tabToBeClosedWithReason.id
        )
      )}
      {isCloseTabEnhancementEnabled
        ? showCloseTabModal && (
            <CloseTabModal
              isOpen={showCloseTabModal}
              tab={tabToBeClosedWithReason}
              onClose={handleCloseModal}
              onCloseTab={(reason) => {
                setShowCloseTabModal(false);
                closeTabAction(tabToBeClosedWithReason, null, reason);
              }}
              closeReasons={closeReasons}
              studentId={getStudentInfo(tabToBeClosedWithReason).Id}
              studentDisplayName={studentDisplayName}
            />
          )
        : showCloseTabModal && (
            <CloseTabReason
              closeTab={(reason) => {
                setShowCloseTabModal(false);
                closeTabAction(tabToBeClosedWithReason, null, reason);
              }}
              isOpen={showCloseTabModal}
              appElementSelector="#mainContent"
              closeReasons={closeReasons}
              closeModal={handleCloseModal}
              closeModalPosition={closeModalPosition}
            />
          )}
    </>
  );
};

CloseTabHandler.propTypes = {
  tabs: PropTypes.arrayOf(STUDENT_BROWSER_TAB_TYPE),
  tabsLimit: PropTypes.number.isRequired,
  getStudentInfo: PropTypes.func.isRequired,
  tabComponent: PropTypes.func.isRequired,
  closeTab: PropTypes.func.isRequired,
  closeWithReasonEnabled: PropTypes.bool,
  closeReasons: PropTypes.arrayOf(PropTypes.string),
  classId: PropTypes.string,
  teacherName: PropTypes.string,
  duplicateTabConsolidated: PropTypes.bool,
  isCloseTabEnhancementEnabled: PropTypes.bool,
};

export default connect(
  (state) => ({
    closeWithReasonEnabled: getClassTabCloseReasonsEnabled(state),
    closeReasons: getClassTabCloseReasons(state),
    classId: getClassId(state),
    teacherName: getUserFamilyName(state),
    isCloseTabEnhancementEnabled: getFFByName("PS-700-close-block-tabs")(state),
  }),
  (dispatch) => ({
    closeTab: ({
      userId,
      userName,
      tab,
      reason,
      instanceId,
      classId,
      teacherName,
    }) =>
      dispatch(
        closeStudentTab({
          userId,
          userName,
          tab,
          reason,
          instanceId,
          classId,
          teacherName,
        })
      ),
  })
)(CloseTabHandler);
