import { FormattedMessage } from "react-intl";
import React, { useEffect } from "react";
import _ from "lodash";
import styles from "./MyResourcesMainContent.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResourceSearch from "../../Resources/ResourceSearch/ResourceSearch";
import CategoryFiltersToggleButton from "../../Library/CategoryFiltersToggleButton/CategoryFiltersToggleButton";
import { navigateToLibraryMyResourcesLanding } from "../../../../state/library/myResources/navigation";
import CategoryTitle from "../../Library/CategoryTitle/CategoryTitle";
import SearchParamsBlock from "../../Library/SearchParamsBlock/SearchParamsBlock";
import { MAIN_CONTENT_ID } from "../../../../consts";
import {
  getMyResourcesItemsList,
  getMyResourcesPagesCount,
  getMyResourcesIsLoading,
  isMyResourcesLeftPanelOpen,
  getMyResourcesItemsTotal,
} from "../../../../state/library/myResources/selectors";
import {
  getCurrentPage,
  getSearchQuery,
  getClassId,
  getJoinedSharedFilterParams,
  numberSharedFiltersSelected,
  getSelectedSortOrderFilter,
} from "../../../../state/shared/selectors";
import { classItemType } from "../../../../state/library/config/types";
import { isStarredFiltered } from "../../../../state/library/myResources/selectors";
import { getClassList } from "../../../../state/library/config/selectors";
import {
  resetMyResources,
  updateMyResourcesLeftPanel,
} from "../../../../state/library/myResources/actions";
import { myResourcesItemType } from "../../../../state/library/myResources/types";
import ResourceTileList from "../../Resources/ResourceTileList/ResourceTileList";
import SortFilterBlock from "../../Resources/SortFilterBlock/SortFilterBlock";
import EmptySearchResults from "../../Resources/EmptySearchResults/EmptySearchResults";
import ErrorStatePanel from "@hapara/ui/src/atomic/ErrorStatePanel/ErrorStatePanel";
import InformativePage from "@hapara/ui/src/components/InformativePage/InformativePage";
import teacherPlacingBooks from "@hapara/assets/src/images/vector-teacher-placing-books-in-backpack.svg";
import teacherStarringResource from "@hapara/assets/src/images/vector-teacher-starring-a-resource.svg";
import greenSchool from "@hapara/assets/src/images/vector-green-school.svg";
import orangeSchool from "@hapara/assets/src/images/vector-orange-school.svg";
import greenEarthMagnifier from "@hapara/assets/src/images/vector-green-earth-magnifying-glass.svg";
import orangeEarthMagnifier from "@hapara/assets/src/images/vector-orange-earth-magnifying-glass.svg";
import { BACKGROUND_STYLE } from "@hapara/ui/src/deprecated/Card/Card";
import { APP_PAGES } from "../../../../state/router/types";
import ResourcesLoading from "../../Resources/ResourcesLoading/ResourcesLoading";

export const MyResourcesMainContent = ({
  isLeftPanelOpen,
  toggleLeftPanel,
  classList,
  selectedClassId,
  isStarredActive,
  myResourcesQuery,
  searchResultsTotal,
  items,
  currentPage,
  pagesCount,
  isLoading,
  numberSharedFiltersSelected,
  joinedSharedFilterParams,
  resetMyResources,
  sortOrderFilter,
}) => {
  useEffect(() => {
    return () => {
      //clear data on unmount
      resetMyResources();
    };
  }, [resetMyResources]);

  const selectedClass = _.find(classList, { ID: selectedClassId });
  const dataTestIdPrefix = "lb-MyResources";
  const isAllClassesActive = !selectedClassId && !isStarredActive;
  const showEmptyState =
    items &&
    items.length === 0 &&
    !isLoading &&
    _.isEmpty(myResourcesQuery) &&
    numberSharedFiltersSelected === 0 &&
    (isAllClassesActive || isStarredActive);

  const areFiltersApplied =
    !_.isEmpty(myResourcesQuery) || numberSharedFiltersSelected > 0;

  const MY_RESOURCES_INFO_PAGE = {
    hero: isStarredActive
      ? {
          background: BACKGROUND_STYLE.ORANGE,
          image: teacherStarringResource,
          title: "Save resources by starring!",
          text: "You can star resources as you browse to save them without sharing to a class. Find starred resources here.",
        }
      : {
          background: BACKGROUND_STYLE.GREEN,
          image: teacherPlacingBooks,
          title: "Share resources for your lessons!",
          text: "Shared novels, textbooks and instructional materials will appear here for you, and in Student Dashboard for your students.",
        },
    itemsDescription: "Where to find resources:",
    items: [
      {
        id: 1,
        image: isStarredActive ? orangeSchool : greenSchool,
        title: "School resources",
        text: "Find materials that were curated by your school.",
        link: APP_PAGES.LIBRARY_BASE.path,
        linkLabel: "Explore Our Library",
        linkDataTestId: "ExploreOurLibrary",
      },
      {
        id: 2,
        image: isStarredActive ? orangeEarthMagnifier : greenEarthMagnifier,
        title: "Globally shared resources",
        text: "Find public, open source novels, textbooks and more.",
        link: APP_PAGES.DISCOVER_BASE.path,
        linkLabel: "Explore Discover",
        linkDataTestId: "ExploreDiscover",
      },
    ],
  };

  let title = (
    <>
      <h1>
        <FormattedMessage defaultMessage="All Classes" id="HwTqox" />
      </h1>{" "}
      {`(${searchResultsTotal})`}
    </>
  );
  if (!_.isEmpty(selectedClassId)) {
    title = (
      <>
        <h1>{_.get(selectedClass, "Name")}</h1> {`(${searchResultsTotal})`}
      </>
    );
  } else if (isStarredActive) {
    title = (
      <>
        <h1>
          <FormattedMessage defaultMessage="Starred" id="V7cUPv" />
        </h1>{" "}
        {`(${searchResultsTotal})`}
      </>
    );
  }

  return (
    <div className={styles.root}>
      <main className={styles.contentMain} id={MAIN_CONTENT_ID} tabIndex="-1">
        {showEmptyState && (
          <>
            <div className={classnames(styles.headerContent, styles.infoPage)}>
              <div className={styles.separator} />
              <div className={styles.filters}>
                <CategoryFiltersToggleButton
                  toggleLeftPanel={toggleLeftPanel}
                  isLeftPanelOpen={isLeftPanelOpen}
                  dataTestIdPrefix={dataTestIdPrefix}
                  numberFiltersSelected={0}
                />
              </div>
            </div>
            <InformativePage
              info={MY_RESOURCES_INFO_PAGE}
              dataTestPrefix={dataTestIdPrefix}
            />
          </>
        )}
        {!showEmptyState && (
          <>
            <div className={styles.headerContent}>
              <CategoryTitle
                title={title}
                areFiltersSelected={areFiltersApplied}
                searchResultsTotal={searchResultsTotal}
                dataTestId={`${dataTestIdPrefix}-Title`}
                clearFiltersAction={navigateToLibraryMyResourcesLanding}
              />
              <div className={styles.separator} />
              <div className={styles.filters}>
                <CategoryFiltersToggleButton
                  toggleLeftPanel={toggleLeftPanel}
                  isLeftPanelOpen={isLeftPanelOpen}
                  dataTestIdPrefix={dataTestIdPrefix}
                  numberFiltersSelected={0}
                />
                <ResourceSearch dataTestIdPrefix={dataTestIdPrefix} />
              </div>
            </div>
            <SortFilterBlock
              dataTestIdPrefix={`${dataTestIdPrefix}-SortFilterBlock`}
              selectedSortOrder={sortOrderFilter}
            />
            <SearchParamsBlock
              dataTestId={`${dataTestIdPrefix}-SearchParams`}
              areFiltersSelected={areFiltersApplied}
              keywordsSearchParams={myResourcesQuery}
              filtersSearchParams={
                joinedSharedFilterParams ? (
                  <>
                    <span>
                      <FormattedMessage defaultMessage="Filters:" id="XMzOfe" />
                    </span>{" "}
                    {joinedSharedFilterParams}
                  </>
                ) : null
              }
            />
            {isLoading ? (
              <ResourcesLoading hasMarginTop={true} />
            ) : (
              <ResourceTileList
                items={items}
                currentPage={currentPage}
                pagesCount={pagesCount}
                dataTestIdPrefix="lb-MyResources"
                emptyState={
                  areFiltersApplied ? (
                    <EmptySearchResults
                      dataTestId={`${dataTestIdPrefix}-EmptySearchResults`}
                    />
                  ) : (
                    <ErrorStatePanel
                      message="No resources found"
                      dataTestId={`${dataTestIdPrefix}-EmptyState`}
                    >
                      <p>
                        <FormattedMessage
                          defaultMessage="Once you share resources to this class, you'll see them here."
                          id="GXzd3f"
                        />
                      </p>
                    </ErrorStatePanel>
                  )
                }
              />
            )}
          </>
        )}
      </main>
    </div>
  );
};

MyResourcesMainContent.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  selectedClassId: PropTypes.string,
  classList: PropTypes.arrayOf(classItemType),
  isStarredActive: PropTypes.bool.isRequired,
  myResourcesQuery: PropTypes.string.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(myResourcesItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  numberSharedFiltersSelected: PropTypes.number.isRequired,
  joinedSharedFilterParams: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  resetMyResources: PropTypes.func.isRequired,
  sortOrderFilter: PropTypes.string,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: isMyResourcesLeftPanelOpen(state),
    selectedClassId: getClassId(state),
    classList: getClassList(state),
    isStarredActive: isStarredFiltered(state),
    myResourcesQuery: getSearchQuery(state),
    searchResultsTotal: getMyResourcesItemsTotal(state),
    items: getMyResourcesItemsList(state),
    currentPage: getCurrentPage(state),
    pagesCount: getMyResourcesPagesCount(state),
    numberSharedFiltersSelected: numberSharedFiltersSelected(state),
    joinedSharedFilterParams: getJoinedSharedFilterParams(state),
    isLoading: getMyResourcesIsLoading(state),
    sortOrderFilter: getSelectedSortOrderFilter(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(updateMyResourcesLeftPanel(isOpen)),
    resetMyResources: () => dispatch(resetMyResources()),
  })
)(MyResourcesMainContent);
