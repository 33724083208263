import React from "react";
import _ from "lodash";
import Column from "../Column/Column";
import {
  Column as ColumnType,
  Section as SectionType,
  Card as CardType,
  Action,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import styles from "./Section.module.scss";

interface SectionProps {
  sectionData?: SectionType | null;
  columns?: ColumnType[];
  hideSectionName?: boolean;
  singleColumnCardStyle: boolean;
  sectionCards?: CardType[];
  flexiCardMode: boolean;
  dataTestIdPrefix: string;
  boardId: string;
  permissions: Action[];
  setDeleteCardID: (cardId: string) => void;
  setEvidenceModalPayload: (modalResponse: [string, string]) => void;
}

export const Section = ({
  sectionData,
  sectionCards,
  columns = [],
  hideSectionName,
  singleColumnCardStyle,
  flexiCardMode,
  dataTestIdPrefix,
  boardId,
  permissions,
  setDeleteCardID,
  setEvidenceModalPayload,
}: SectionProps) => {
  const columnCards = _.groupBy(sectionCards, "ColumnType");

  //Editors get to see the columns that are empty
  if (
    hideSectionName &&
    sectionCards?.length === 0 &&
    !permissions.includes(Action.CanEdit)
  ) {
    return null;
  }

  return (
    <div className={styles.root} id={sectionData?.ID || ""}>
      {!hideSectionName && (
        <div className={styles.name}>
          {sectionData?.Title && <h2>{sectionData?.Title}</h2>}
          <div className={styles.roundedCornerMask}>
            <div className={styles.maskCoverImage} />
          </div>
          <div className={styles.roundedCornerMask}>
            <div className={styles.maskCoverImage} />
          </div>
        </div>
      )}
      <div className={styles.columns}>
        {columns.map((column, ind) => {
          return (
            <React.Fragment key={ind}>
              <Column
                columnType={column.Type}
                columnCards={columnCards[column.Type]}
                singleColumnCardStyle={singleColumnCardStyle}
                flexiCardMode={flexiCardMode}
                dataTestIdPrefix={dataTestIdPrefix}
                boardId={boardId}
                sectionId={sectionData?.ID || ""}
                sectionTitle={sectionData?.Title || ""}
                permissions={permissions}
                setDeleteCardID={setDeleteCardID}
                setEvidenceModalPayload={setEvidenceModalPayload}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Section;
