import React from "react";
import { injectProps } from "../utils/injectProps";
import { useTable } from "../useTable";

type TableBodyProps = {
  children?: React.ReactNode;
};

export const TableBody = ({ children }: TableBodyProps) => {
  const { dataTestIdPrefix } = useTable();

  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-table-body`
    : undefined;

  return (
    <tbody data-test-id={dataTestId}>
      {injectProps(children, (index) => ({
        _ariaRowIndex: index,
      }))}
    </tbody>
  );
};
