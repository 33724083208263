/** Returns the resource ID from a Google URL:
 * https://docs.google.com/forms/d/e/SOME_ID/viewform -> "SOME_ID" */
export const getGoogleResourceId = (url: string) => {
  const patterns = [
    /^[^?]*docs\.google\.com\/forms\/d\/e\/([^/]*)\//,
    /^[^?]*docs\.google\.com\/forms\/u\/0\/d\/e\/([^/]*)\//,
    /^[^?]*docs\.google\.com.*d\/([^/]*)\//,
    /^[^?]*jamboard\.google\.com.*d\/([^/]*)\//,
    /^[^?]*drive\.google\.com\/open\?id=([^/]*)/,
    /^[^?]*drive\.google\.com\/file\/d\/([^/]*)/,
    /^[^?]*sites\.google\.com\/d\/([^/?]+)/,
    /^[^?]*google\.com\/maps\/d\/edit\?.*?mid=([^&]*)/,
  ];

  for (const pattern of patterns) {
    const match = (url + "/").match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  return "";
};
