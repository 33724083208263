import React, { useState, useEffect, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../Button/Button";
import styles from "./SearchWithDropdown.module.scss";

const SearchWithDropdown = ({
  className,
  placeholder = "",
  itemsForDD = [],
  forceClearSearchTerm = false,
  dataTestIdPrefix = "",
  tabIndex = 0,
  onChange = () => {},
  onClear = () => {},
  onSelect = () => {},
  onEnter = () => {},
}) => {
  const intl = useIntl();
  const [value, setValue] = useState("");
  const [eligibleToShowDD, setEligibleToShowDD] = useState(false);
  const [eligibleToShowCrossButton, setEligibleToShowCrossButton] =
    useState(false);
  const [hasSelectedItem, setHasSelectedItem] = useState(false);
  const inputRef = useRef();
  const ddRef = useRef();

  const handleValueChange = (e) => {
    const newValue = _.get(e, "target.value", "");
    setValue(newValue);
    setHasSelectedItem(false);
    onSelect(null);

    if (newValue.length > 2) {
      onChange(newValue);
    } else onChange("");
  };

  // keep looking for result item to focus until we reach the end of the list
  const focusNextSearchItem = (children, index) => {
    const nextIndex = index + 1;
    if (nextIndex < children.length) {
      if (!children[nextIndex].disabled) {
        children[nextIndex].focus();
      } else {
        focusNextSearchItem(children, nextIndex);
      }
    }
  };

  // keep looking for result item to focus until we reach the beginning of the list
  // when focus input field
  const focusPreviousSearchItem = (children, index) => {
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      if (!children[previousIndex].disabled) {
        children[previousIndex].focus();
      } else {
        focusPreviousSearchItem(children, previousIndex);
      }
    }
    if (previousIndex === -1) {
      inputRef.current.focus();
    }
  };

  const handleInputKeyDown = (e) => {
    // on Enter
    if (e.keyCode === 13) {
      onEnter();
    }
    // on Arrow Down
    if (e.keyCode === 40) {
      e.preventDefault();
      const children = _.get(ddRef, "current.childNodes");
      if (children && children.length) {
        focusNextSearchItem(children, -1);
      }
    }
  };

  const handleButtonKeyDown = (e, index) => {
    // on Arrow Down
    if (e.keyCode === 40) {
      e.preventDefault();
      focusNextSearchItem(ddRef.current.childNodes, index);
    }

    // on Arrow Up
    if (e.keyCode === 38) {
      e.preventDefault();
      focusPreviousSearchItem(ddRef.current.childNodes, index);
    }
  };

  const handleSelectItem = (item) => {
    setValue(item.label);
    setHasSelectedItem(true);
    onSelect(item);
    inputRef.current.focus();
  };

  const handleClear = useCallback(() => {
    setValue("");
    setEligibleToShowDD(false);
    onClear();
    inputRef.current.focus();
  }, [setValue, setEligibleToShowDD, onClear]);

  useEffect(() => {
    if (itemsForDD && itemsForDD.length > 0 && value.length > 2) {
      setEligibleToShowDD(true);
    } else {
      setEligibleToShowDD(false);
    }
  }, [itemsForDD, value, setEligibleToShowDD]);

  useEffect(() => {
    if (value.length > 0) {
      setEligibleToShowCrossButton(true);
    } else {
      setEligibleToShowCrossButton(false);
    }
  }, [value, setEligibleToShowCrossButton]);

  // TODO get rid of forceClearSearchTerm and make the component to be controlled instead
  const forceClearSearchTermRef = useRef();

  useEffect(() => {
    if (forceClearSearchTermRef.current !== forceClearSearchTerm) {
      if (forceClearSearchTerm) {
        handleClear();
      }
      forceClearSearchTermRef.current = forceClearSearchTerm;
    }
  }, [forceClearSearchTerm, handleClear]);

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.searchField}>
        <input
          type="text"
          placeholder={placeholder}
          className={classnames(styles.textField, {
            [styles.noBottomBorderRadius]: eligibleToShowDD && !hasSelectedItem,
            [styles.active]: hasSelectedItem || eligibleToShowDD,
          })}
          value={value ? value : ""}
          onChange={handleValueChange}
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
          data-test-id={`${dataTestIdPrefix}-Input-SearchField`}
          aria-label={placeholder}
          tabIndex={tabIndex}
        />

        {eligibleToShowCrossButton && (
          <div>
            <Button
              icon="cross"
              onAction={handleClear}
              size={BUTTON_SIZES.XSMALL}
              type={BUTTON_TYPES.TERTIARY}
              className={styles.clearButton}
              dataTestId={`${dataTestIdPrefix}-ClearSearch`}
              aria-label={intl.formatMessage({
                defaultMessage: "Clear search",
                id: "4YJHut",
              })}
            />
          </div>
        )}
      </div>
      {eligibleToShowDD && !hasSelectedItem && (
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} ref={ddRef}>
            {_.map(itemsForDD, (item, index) => {
              return (
                <button
                  type="button"
                  key={item.id}
                  onClick={() => handleSelectItem(item)}
                  onKeyDown={(e) => handleButtonKeyDown(e, index)}
                  disabled={item.disabled}
                  tabIndex={tabIndex}
                  data-test-id={`${dataTestIdPrefix}-Button-SearchResultItem`}
                >
                  {item.displayElement}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

SearchWithDropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  itemsForDD: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      displayElement: PropTypes.node,
      disabled: PropTypes.bool,
    })
  ),
  forceClearSearchTerm: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  onEnter: PropTypes.func,
};

export default SearchWithDropdown;
