import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./StudentTileMultipleDevicesState.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";

export const MULTI_DEVICE_TILE_MODES = {
  SELECT: "SELECT",
  NOT_BROWSING: "NOT_BROWSING",
};

const StudentTileMultipleDevicesState = ({
  mode = MULTI_DEVICE_TILE_MODES.SELECT,
  deviceCount,
  onModalOpen,
  expandModalStyle = false,
}) => {
  const intl = useIntl();
  return (
    <div
      className={classnames(styles.root, {
        [styles.notBrowsing]:
          mode === MULTI_DEVICE_TILE_MODES.NOT_BROWSING && !expandModalStyle,
      })}
    >
      {mode === MULTI_DEVICE_TILE_MODES.SELECT && (
        <div
          className={classnames(styles.title, {
            [styles.expandModalStyle]: expandModalStyle,
          })}
        >
          <FormattedMessage
            defaultMessage="{deviceCount} devices detected"
            id="RUJbax"
            values={{ deviceCount }}
          />
        </div>
      )}
      {mode === MULTI_DEVICE_TILE_MODES.NOT_BROWSING && (
        <div
          className={classnames(styles.title, {
            [styles.expandModalStyle]: expandModalStyle,
          })}
        >
          <FormattedMessage
            defaultMessage="Currently not browsing on this device"
            id="nxEPuu"
          />
        </div>
      )}
      <Button
        icon="single-device"
        size={expandModalStyle ? BUTTON_SIZES.REGULAR : BUTTON_SIZES.XSMALL}
        type={BUTTON_TYPES.PRIMARY}
        data-test-id="hl-StudentTile-MultipleDevicesState-SelectDevice"
        label={intl.formatMessage({
          defaultMessage: "Select a device",
          id: "1Dnw1Q",
        })}
        onClick={() => onModalOpen(true)}
      />
    </div>
  );
};

StudentTileMultipleDevicesState.propTypes = {
  mode: PropTypes.oneOf(_.values(MULTI_DEVICE_TILE_MODES)),
  deviceCount: PropTypes.number.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  expandModalStyle: PropTypes.bool,
};

export default StudentTileMultipleDevicesState;
