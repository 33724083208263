import { gql } from "@apollo/client";

const UpdateCardMutation = (deletedArtifacts: string[]) => {
  const deleteMutations = () => {
    if (deletedArtifacts.length > 0) {
      return "deleteArtifactsFromCard(artifactIds: $artifactIds)";
    }
    return "";
  };

  const deleteParameters = () => {
    if (deletedArtifacts.length > 0) {
      return ", $artifactIds: [ID!]!";
    }
    return "";
  };

  const mutation = `mutation updateCard($id: ID, $input: CardInput!, $boardId: ID ${deleteParameters()} ) {
            updateCard(id: $id, input: $input, boardId: $boardId) {
                ID
                Title
                Description
                Type
                SectionID
                ColumnType
                Order
                Image
                ImageAltText
                Colour
                Artifacts {
                    ID
                    Role
                    Title
                    Type
                    URI
                    IconURL
                    MimeType
                }
                GroupsFilter {
                    ID
                }
            }
            ${deleteMutations()}
        }`;

  return gql`
    ${mutation}
  `;
};

export default UpdateCardMutation;
