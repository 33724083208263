import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import Tippy from "@tippy.js/react";
import styles from "./BodyBlock.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_WIDTH_TYPES,
  DropdownTrigger,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { GUIDE_BROWSING_TEMPLATE_TYPE } from "../../../../../../state/highlights/sessions/types";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import { FormattedMessage, useIntl } from "react-intl";

const defaultTriggerTitleNoTemplates = (
  <FormattedMessage defaultMessage="No templates saved yet" id="PQiPGb" />
);
const defaultTriggerTitleWithTemplates = (
  <>
    <FormattedMessage
      defaultMessage="Choose a template <span>(optional)</span>"
      id="yzE1Fx"
      values={{
        span: (chunk) => (
          <span className={styles.dropdownTriggerSub}>{chunk}</span>
        ),
      }}
    />
  </>
);
const noTemplateItemText = (
  <FormattedMessage defaultMessage="Use no template" id="fuB2by" />
);

const BodyBlock = ({
  dataTestPrefix,
  onSetUp,
  setUpLabel,
  reviewLabel,
  title,
  templateList,
  selectedClassId,
  deleteGuideBrowsingTemplate,
  loadGuideBrowsingTemplates,
}) => {
  const [dropdownTriggerTitle, setDropdownTriggerTitle] = useState(
    templateList.length
      ? defaultTriggerTitleWithTemplates
      : defaultTriggerTitleNoTemplates
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [isDeleteTemplateInProgress, setIsDeleteTemplateInProgress] =
    useState(false);
  const [isDeleteTemplateError, setIsDeleteTemplateError] = useState(false);

  const templatesLengthRef = useRef();
  const intl = useIntl();

  // reset selected template when templates are loaded or updated
  useEffect(() => {
    if (templatesLengthRef.current !== templateList.length) {
      setDropdownTriggerTitle(
        templateList.length
          ? defaultTriggerTitleWithTemplates
          : defaultTriggerTitleNoTemplates
      );
      setSelectedTemplate(null);
      templatesLengthRef.current = templateList.length;
    }
  }, [templateList.length, setDropdownTriggerTitle, setSelectedTemplate]);

  const handleDeleteTemplateModalClose = () => {
    setIsDeleteTemplateError(false);
    setTemplateToDelete(null);
    setIsDeleteTemplateModalOpen(false);
  };

  const handleDeleteTemplate = () => {
    const id = _.get(templateToDelete, "ID");
    if (id) {
      setIsDeleteTemplateInProgress(true);
      setIsDeleteTemplateError(false);

      deleteGuideBrowsingTemplate(id)
        .then((data) => {
          setIsDeleteTemplateInProgress(false);
          loadGuideBrowsingTemplates(selectedClassId);
          handleDeleteTemplateModalClose();
        })
        .catch(() => {
          setIsDeleteTemplateInProgress(false);
          setIsDeleteTemplateError(true);
        });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className={styles.bodyBlockTitle}>{title}</h2>
        <Dropdown
          className={styles.dropdown}
          widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
          triggerComponent={({ className, ...rest }) => (
            <DropdownTrigger
              label={dropdownTriggerTitle}
              widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
              data-test-id={`${dataTestPrefix}-SelectTemplate-Dropdown-Trigger`}
              disabled={!templateList.length}
              className={classnames(className, styles.dropdownTrigger)}
              {...rest}
            />
          )}
          itemComponentList={_.concat(
            _.map(templateList, (item) => ({ onClick, className, ...rest }) => (
              <div
                className={classnames(className, styles.dropdownContentItem, {
                  [styles.dropdownContentItemSelected]:
                    _.get(selectedTemplate, "ID") === item.ID,
                })}
              >
                <button
                  type="button"
                  data-test-id={`${dataTestPrefix}-SelectTemplate-Dropdown-Item`}
                  onClick={() => {
                    setDropdownTriggerTitle(item.Name);
                    setSelectedTemplate(item);
                    onClick();
                  }}
                  className={styles.dropdownContentItemSelect}
                  {...rest}
                >
                  {item.Name}
                </button>
                <Tippy
                  content={intl.formatMessage({
                    defaultMessage: "Delete this template",
                    id: "d8QrTG",
                  })}
                  theme="hsuite"
                >
                  <Button
                    type={BUTTON_TYPES.TERTIARY}
                    size={BUTTON_SIZES.SMALL}
                    data-test-id={`${dataTestPrefix}-SelectTemplate-Dropdown-Delete`}
                    onClick={() => {
                      setTemplateToDelete(item);
                      setIsDeleteTemplateModalOpen(true);
                      onClick();
                    }}
                    icon="delete"
                    aria-label={intl.formatMessage({
                      defaultMessage: "Delete",
                      id: "K3r6DQ",
                    })}
                    className={styles.dropdownContentItemDelete}
                  />
                </Tippy>
              </div>
            )),
            [
              ({ onClick, className, ...rest }) => (
                <button
                  type="button"
                  data-test-id={`${dataTestPrefix}-SelectTemplate-Dropdown-Item`}
                  onClick={() => {
                    setDropdownTriggerTitle(noTemplateItemText);
                    setSelectedTemplate(null);
                    onClick();
                  }}
                  className={classnames(className, styles.dropdownContentItem)}
                  {...rest}
                >
                  {noTemplateItemText}
                </button>
              ),
            ]
          )}
        />
        <Button
          label={selectedTemplate ? reviewLabel : setUpLabel}
          onClick={() => onSetUp({ selectedTemplate })}
          data-test-id={`${dataTestPrefix}-Button-${
            selectedTemplate ? "ReviewSession" : "SetSession"
          }`}
        />
        {isDeleteTemplateModalOpen && (
          <ActionConfirmationDialog
            isOpen={isDeleteTemplateModalOpen}
            title={intl.formatMessage({
              defaultMessage: "Delete?",
              id: "XkPeTP",
            })}
            subTitle={_.get(templateToDelete, "Name", "")}
            content={intl.formatMessage({
              defaultMessage:
                "This template will be removed from this class and will not be retrievable.",
              id: "CCHLMG",
            })}
            isActionDanger={true}
            actionLabel={intl.formatMessage({
              defaultMessage: "Delete",
              id: "K3r6DQ",
            })}
            onClose={handleDeleteTemplateModalClose}
            error={
              isDeleteTemplateError
                ? intl.formatMessage({
                    defaultMessage:
                      "Sorry, there was a problem deleting this template. Please try again.",
                    id: "GyAISp",
                  })
                : null
            }
            onAction={handleDeleteTemplate}
            isActionButtonDisabled={isDeleteTemplateInProgress}
            isActionInProgress={isDeleteTemplateInProgress}
            actionDataTestId={`${dataTestPrefix}-Button-TemplateDelete`}
            cancelDataTestId={`${dataTestPrefix}-Button-TemplateCancelDelete`}
            dataTestPrefix={dataTestPrefix}
          />
        )}
      </div>
    </div>
  );
};

BodyBlock.propTypes = {
  title: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  setUpLabel: PropTypes.string.isRequired,
  reviewLabel: PropTypes.string.isRequired,
  onSetUp: PropTypes.func.isRequired,
  templateList: PropTypes.arrayOf(GUIDE_BROWSING_TEMPLATE_TYPE),
  selectedClassId: PropTypes.string,
  deleteGuideBrowsingTemplate: PropTypes.func.isRequired,
  loadGuideBrowsingTemplates: PropTypes.func.isRequired,
};

export default BodyBlock;
