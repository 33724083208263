import { isValidTime } from "../isValidTime";

/** Checks if a given time is within a specified time range. */
export const isWithinTimeRange = (
  time: string,
  startTime: string,
  endTime: string
): boolean => {
  if (!isValidTime(time) || !isValidTime(startTime) || !isValidTime(endTime)) {
    throw new Error("Invalid time format. Use HH:MM");
  }

  const toMinutes = (t: string): number => {
    const [hours, minutes] = t.split(":").map(Number);
    return hours * 60 + minutes;
  };

  let timeMinutes = toMinutes(time);
  const startMinutes = toMinutes(startTime);
  let endMinutes = toMinutes(endTime);

  // Handle midnight crossing
  if (endMinutes < startMinutes) {
    endMinutes += 24 * 60;
    if (timeMinutes < startMinutes) {
      timeMinutes += 24 * 60;
    }
  }

  return timeMinutes >= startMinutes && timeMinutes < endMinutes;
};
