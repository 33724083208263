import React from "react";
import { createIcon } from "./utils/createIcon";

export const LinkOutlineIcon = createIcon(
  <path
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M6.7 8.7a3.3 3.3 0 0 0 5 .3l2-2A3.3 3.3 0 0 0 9 2.3L7.8 3.5m1.5 3.8a3.3 3.3 0 0 0-5-.3l-2 2A3.3 3.3 0 0 0 7 13.7l1.2-1.2"
  />
);
