import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ClassActivity.module.scss";
import {
  getClassActivity,
  ACTIVITY_TYPES,
} from "../../../../state/highlights/activities/selectors";
import Panel from "@hapara/ui/src/atomic/Panel/Panel";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import SimpleChart from "@hapara/ui/src/atomic/SimpleChart/SimpleChart";
import Reveal, {
  RevealDetails,
  RevealToggle,
} from "@hapara/ui/src/atomic/Reveal/Reveal";
import Legend, { LEGEND_COLOURS } from "@hapara/ui/src/atomic/Legend/Legend";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { withExpanded } from "@hapara/ui/src/atomic/Reveal/Reveal";
import classnames from "classnames";
import {
  ACTIVITY_DETAILS_TYPE,
  SNAPSHOT_TYPES,
} from "../../../../state/highlights/activities/types";
import FormattedStudentName from "../../FormattedStudentName/FormattedStudentName";
import Tippy from "@tippy.js/react";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import {
  FEEDBACK_TYPES,
  withFeedback,
} from "@hapara/ui/src/atomic/InlineFeedback/InlineFeedback";
import { APP_PAGES } from "../../../../state/router/types";
import { getClassId } from "../../../../state/shared/selectors";
import { Link } from "react-router-dom";
import { createSnapshot } from "../../../../state/highlights/snaps/actions";
import { getScreenshotsEnabled } from "../../../../state/highlights/config/selectors";
import { isRestrictedScreenshotUrl } from "@hapara/ui/src/components/utils";
import { pendoTrack } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";

const ClassActivityStudentList = ({ list, legend, listId }) => {
  return (
    <section aria-labelledby={listId}>
      <h3 id={listId}>{legend}</h3>
      <ul className={styles.studentList}>
        {list.map((l) => (
          <li className={styles.studentListItem} key={l.Id}>
            <FormattedStudentName student={l} />
          </li>
        ))}
      </ul>
    </section>
  );
};

ClassActivityStudentList.propTypes = {
  list: PropTypes.arrayOf(STUDENT_TYPE).isRequired,
  legend: PropTypes.node,
  listId: PropTypes.string.isRequired,
};

const ClassActivityDetails = ({ details, detailsId }) => {
  return (
    <Panel inversed className={styles.details}>
      <div className={styles.column}>
        <ClassActivityStudentList
          listId={`hl-ActivityViewer-ClassActivity-StudentList-Currently-${detailsId}`}
          list={details.studentInfos.current}
          legend={
            <Legend colour={LEGEND_COLOURS.BLUE}>
              <FormattedMessage defaultMessage="Currently active" id="HyPpv0" />
            </Legend>
          }
        />
      </div>
      <div className={styles.column}>
        <ClassActivityStudentList
          listId={`hl-ActivityViewer-ClassActivity-StudentList-Previously-${detailsId}`}
          list={details.studentInfos.previous}
          legend={
            <Legend colour={LEGEND_COLOURS.ORANGE}>
              <FormattedMessage
                defaultMessage="Previously active"
                id="Nj8hVH"
              />
            </Legend>
          }
        />
      </div>
      <div className={styles.column}>
        <ClassActivityStudentList
          listId={`hl-ActivityViewer-ClassActivity-StudentList-Never-${detailsId}`}
          list={details.studentInfos.never}
          legend={
            <Legend colour={LEGEND_COLOURS.GRAY}>
              <FormattedMessage defaultMessage="Never active" id="wR/Z/o" />
            </Legend>
          }
        />
      </div>
    </Panel>
  );
};

ClassActivityDetails.propTypes = {
  details: ACTIVITY_DETAILS_TYPE,
  detailsId: PropTypes.string,
};

const ClassActivityList = ({
  activities,
  activityType,
  classId,
  createSnapshot,
  isScrEnabled,
}) => {
  const dataTestPrefix =
    activityType === ACTIVITY_TYPES.BY_DOMAIN
      ? "hl-ActivityViewer-ClassActivity"
      : "hl-ActivityViewer-Collaboration";
  const intl = useIntl();

  const EMPTY_STATE_MESSAGES = {
    [ACTIVITY_TYPES.BY_DOMAIN]: intl.formatMessage({
      defaultMessage: "No class activity available right now.",
      id: "DcphzC",
    }),
    [ACTIVITY_TYPES.COLLABORATION]: intl.formatMessage({
      defaultMessage: "No collaboration happening right now.",
      id: "TSgwCa",
    }),
  };

  if (activities.length === 0) {
    return (
      <div className={styles.center}>{EMPTY_STATE_MESSAGES[activityType]}</div>
    );
  }

  return (
    <ul className={styles.list}>
      {activities.map((details) => {
        const studentEmail = () => {
          if (details.current.length > 0) {
            return details.current[0].Email;
          }
          if (details.previous.length > 0) {
            return details.previous[0].Email;
          }
          return "";
        };
        return (
          <Reveal key={details.url}>
            {withExpanded((isExpanded) => {
              return (
                <li
                  className={classnames(styles.listItem, {
                    [styles.expandedItem]: isExpanded,
                  })}
                >
                  <div className={styles.summary}>
                    {withFeedback(
                      ({
                        feedback,
                        isInProgress,
                        setInProgress,
                        triggerRef,
                      }) => {
                        return (
                          <div className={styles.listItemContent}>
                            <div className={styles.summaryDecription}>
                              <div className={styles.link}>
                                <ExternalLink
                                  url={details.url}
                                  icon={details.icon}
                                  title={details.title}
                                  studentEmail={studentEmail()}
                                  classId={classId}
                                />
                              </div>
                              <RevealToggle
                                className={styles.revealButton}
                                aria-label={intl.formatMessage({
                                  defaultMessage:
                                    "see who has visited this site",
                                  id: "4mGoG0",
                                })}
                                data-test-id={
                                  isExpanded
                                    ? `${dataTestPrefix}-SiteDetails-ShowLess`
                                    : `${dataTestPrefix}-SiteDetails-ShowMore`
                                }
                              >
                                <div className={styles.chart}>
                                  <SimpleChart
                                    values={[
                                      details.current,
                                      details.previous,
                                      details.total -
                                        (details.current + details.previous),
                                    ]}
                                    totalValue={details.current}
                                    showTotal
                                  />
                                  <HapReactIcon
                                    svg={
                                      isExpanded
                                        ? "arrow-angle-up"
                                        : "arrow-angle-down"
                                    }
                                    width={18}
                                    height={18}
                                    alt=""
                                  />
                                </div>
                              </RevealToggle>
                            </div>

                            {isScrEnabled &&
                              !isRestrictedScreenshotUrl(details.url) && (
                                <div className={styles.snap}>
                                  <Tippy
                                    content={intl.formatMessage({
                                      defaultMessage:
                                        "Take a snap of this activity to review and compare",
                                      id: "4Fbo71",
                                    })}
                                    theme="hsuite"
                                    maxWidth={140}
                                  >
                                    <Button
                                      ref={triggerRef}
                                      icon="camera"
                                      type={BUTTON_TYPES.TERTIARY}
                                      size={BUTTON_SIZES.XSMALL}
                                      data-test-id={`${dataTestPrefix}-Button-TakeSnap`}
                                      aria-label={intl.formatMessage({
                                        defaultMessage: "Take a snap",
                                        id: "ldZA+V",
                                      })}
                                      onAction={async () => {
                                        setInProgress();
                                        try {
                                          await createSnapshot({
                                            classId,
                                            activityData:
                                              details.convertToSnapActivity(),
                                            snapshotType:
                                              activityType ===
                                              ACTIVITY_TYPES.BY_DOMAIN
                                                ? SNAPSHOT_TYPES.DOMAIN
                                                : SNAPSHOT_TYPES.URL,
                                            icon: details.icon,
                                            title: details.title,
                                            url: details.url,
                                          });

                                          pendoTrack(
                                            "Snap - Take a snap from Activity Viewer",
                                            {
                                              classId: classId,
                                              snapTimestamp:
                                                new Date().toISOString(),
                                            }
                                          );

                                          feedback(
                                            FEEDBACK_TYPES.SUCCESS,
                                            <>
                                              <FormattedMessage
                                                defaultMessage="Activity captured. Review in"
                                                id="BdnZyy"
                                              />
                                              <span className={styles.snapLink}>
                                                <Link
                                                  to={`${APP_PAGES.HIGHLIGHTS_SNAPS.path}?classId=${classId}`}
                                                  data-test-id={`${dataTestPrefix}-SnapsLink`}
                                                  aria-label={intl.formatMessage(
                                                    {
                                                      defaultMessage:
                                                        "Go to snaps",
                                                      id: "S7Hers",
                                                    }
                                                  )}
                                                >
                                                  <FormattedMessage
                                                    defaultMessage="Snaps"
                                                    id="dhJ3ab"
                                                  />
                                                </Link>
                                              </span>
                                            </>
                                          );
                                        } catch (e) {
                                          console.error(e);
                                          feedback(
                                            FEEDBACK_TYPES.ERROR,
                                            "Some error occured"
                                          );
                                        }
                                      }}
                                      isLoading={isInProgress}
                                    />
                                  </Tippy>
                                </div>
                              )}
                            {isScrEnabled &&
                              isRestrictedScreenshotUrl(details.url) && (
                                <div className={styles.spaceMaintainer}></div>
                              )}
                          </div>
                        );
                      },
                      5000
                    )}
                  </div>
                  <RevealDetails>
                    <div className={styles.revealSection}>
                      <ClassActivityDetails
                        details={details}
                        detailsId={details.url}
                      />
                    </div>
                  </RevealDetails>
                </li>
              );
            })}
          </Reveal>
        );
      })}
    </ul>
  );
};

ClassActivityList.propTypes = {
  activities: PropTypes.arrayOf(ACTIVITY_DETAILS_TYPE).isRequired,
  activityType: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
  onBlockTab: PropTypes.func,
};

const ClassActivity = ({
  activities,
  activityType,
  classId,
  createSnapshot,
  isScreenshotsEnabled = false,
}) => {
  const intl = useIntl();

  const TITLES = {
    [ACTIVITY_TYPES.BY_DOMAIN]: intl.formatMessage({
      defaultMessage: "Class Activity",
      id: "8pYJtn",
    }),
    [ACTIVITY_TYPES.COLLABORATION]: intl.formatMessage({
      defaultMessage: "Collaboration",
      id: "xw/qaA",
    }),
  };

  return (
    <Panel
      className={styles.root}
      emptyClassName={styles.emptyStyle}
      emptyState={activities.length === 0}
      header={
        <div className={styles.header}>
          <div className={styles.text}>
            <h2>{TITLES[activityType]}</h2>
          </div>
          <div className={styles.legend}>
            <Legend colour={LEGEND_COLOURS.BLUE} className={styles.gap}>
              <FormattedMessage defaultMessage="Currently" id="Czw6Yl" />
            </Legend>
            <Legend colour={LEGEND_COLOURS.ORANGE} className={styles.gap}>
              <FormattedMessage defaultMessage="Previously" id="osq4q8" />
            </Legend>
            <Legend colour={LEGEND_COLOURS.GRAY} className={styles.gap}>
              <FormattedMessage defaultMessage="Never" id="du1laW" />
            </Legend>
          </div>
        </div>
      }
    >
      <ClassActivityList
        isScrEnabled={isScreenshotsEnabled}
        activities={activities}
        activityType={activityType}
        classId={classId}
        createSnapshot={(data) => createSnapshot(data)}
      />
    </Panel>
  );
};

ClassActivity.propTypes = {
  activityType: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(ACTIVITY_DETAILS_TYPE).isRequired,
  classId: PropTypes.string.isRequired,
  createSnapshot: PropTypes.func.isRequired,
  isScreenshotsEnabled: PropTypes.bool,
};

export default connect(
  (state, props) => ({
    activities: getClassActivity(state, props.activityType),
    classId: getClassId(state),
    isScreenshotsEnabled: getScreenshotsEnabled(state),
  }),
  (dispatch) => ({
    createSnapshot: ({
      classId,
      activityData,
      snapshotType,
      url,
      icon,
      title,
    }) => {
      return dispatch(
        createSnapshot(classId, activityData, snapshotType, url, icon, title)
      );
    },
  })
)(ClassActivity);
