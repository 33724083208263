import React, { useRef } from "react";
import { Tooltip } from "@hapara/ui/src/components/Tooltip";
import { useOverflow } from "@hapara/ui/src/hooks/useOverflow";
import styles from "./GuidedBrowsingTable.module.scss";

interface GuidedBrowsingTemplateNameProps {
  name: string;
}

export const GuidedBrowsingTemplateName = ({
  name,
}: GuidedBrowsingTemplateNameProps) => {
  const nameCellRef = useRef<HTMLDivElement>(null);
  const { isOverflowing } = useOverflow(nameCellRef);

  return (
    <div ref={nameCellRef} className={styles.name}>
      {isOverflowing ? (
        <Tooltip
          content={name}
          anchor={<span className={styles.templateNameAnchor}>{name}</span>}
        />
      ) : (
        name
      )}
    </div>
  );
};
