import React, { useRef } from "react";
import styles from "./TimePickerField.module.scss";
import { ClockIcon } from "../../icons/ClockIcon";
import { ChevronDownIcon } from "../../icons/ChevronDownIcon";
import { TextField } from "../TextField";
import { formatDateTimeLocal } from "../../intl/dateTime/formatDateTimeLocal";
import { timeInputToISOString } from "./timeInputToISOString";
import classNames from "classnames";

type TimePickerFieldProps = {
  value: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
  errorMessage?: string;
  placeholder?: string;
  hintText?: string;
  onSelectTime: (selectedTime: string) => void;
};

export const TimePickerField = ({
  value,
  label,
  className,
  isRequired,
  placeholder,
  errorMessage,
  hintText,
  onSelectTime,
}: TimePickerFieldProps) => {
  const timeInputRef = useRef<HTMLInputElement>(null);

  const timeFormattedToLocale = value
    ? formatDateTimeLocal(timeInputToISOString(value), "timeOnly")
    : "";

  const handleTextInputFocus = () => {
    if (timeInputRef.current) {
      timeInputRef.current.showPicker();
    }
  };

  const noop = () => {};

  return (
    <div className={classNames(styles.container, className)}>
      <TextField
        value={timeFormattedToLocale ? timeFormattedToLocale : value}
        label={label}
        placeholder={placeholder}
        hintText={hintText}
        hasError={!!errorMessage}
        isRequired={isRequired}
        errorMessage={errorMessage}
        onFocus={handleTextInputFocus}
        className={styles.timeInput}
        onChangeText={noop}
        dataTestId="time-picker"
        leftAddon={
          <div
            onClick={handleTextInputFocus}
            className={classNames(styles.iconWrapper, styles.left)}
          >
            <ClockIcon />
          </div>
        }
        rightAddon={
          <div
            onClick={handleTextInputFocus}
            className={classNames(styles.iconWrapper, styles.right)}
          >
            <ChevronDownIcon />
          </div>
        }
      />
      <input
        type="time"
        value={value}
        onChange={(e) => onSelectTime(e.currentTarget.value)}
        ref={timeInputRef}
        className={styles.nativeTimeInput}
        data-test-id="native-time-input"
      />
    </div>
  );
};
