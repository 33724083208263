import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Input, {
  INPUT_DEFAULT_MAX_LENGTH,
} from "@hapara/ui/src/atomic/Input/Input";
import styles from "./UploadForm.module.scss";

const UploadFormAuthor = ({ id, dataTestIdPrefix, author, onAuthorChange }) => {
  return (
    <div className={styles.fieldBlock}>
      <label htmlFor={id} className={styles.fieldLabel}>
        <FormattedMessage defaultMessage="Author" id="tWkQ2J" />
      </label>
      <Input
        type="text"
        name={id}
        id={id}
        placeholder="Provide the author’s names separated by a comma"
        data-test-id={`${dataTestIdPrefix}-AuthorInput`}
        value={author}
        onChange={(e) => onAuthorChange(_.get(e, "target.value", ""))}
        maxLength={INPUT_DEFAULT_MAX_LENGTH}
        showLimitWarning={true}
      />
    </div>
  );
};

UploadFormAuthor.propTypes = {
  author: PropTypes.string,
  onAuthorChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default UploadFormAuthor;
