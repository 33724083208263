export const LIBRARY_URL_KEYS = {
  collection: {
    key: "collection",
    dependantsKeysToReset: ["page"],
  },
};

export const LIBRARY_URL_PARAMS_DEFAULT_VALUES = {
  [LIBRARY_URL_KEYS.collection.key]: "",
};
