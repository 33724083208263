import React from "react";
import styles from "./ChatAvatar.module.scss";
import { ChatBadge } from "../ChatBadge";
import { UserGroupIcon } from "../../../../icons/UserGroupIcon";
import classnames from "classnames";
import { ChatContactPresence } from "../../types/state";

type ChatAvatarProps = {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  presence?: ChatContactPresence;
  isDisabled?: boolean;
  type?: "class";
};

const getInitials = (firstName = "", lastName = "") =>
  `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();

const colorOptions = [
  "#b4daec",
  "#dccee8",
  "#ffbab1",
  "#bce8db",
  "#f8e68a",
  "#6a6a6a",
  "#1672a0",
  "#945594",
  "#c83f1a",
  "#008761",
  "#915900",
];

const stringToNumericValue = (str: string): number => {
  return str.split("").reduce((accum, val) => val.charCodeAt(0) + accum, 0);
};

const getColorOptionForString = (str: string): string => {
  const numericValue = stringToNumericValue(str);
  return colorOptions[numericValue % colorOptions.length];
};

export const ChatAvatar = ({
  firstName,
  lastName,
  presence,
  type,
}: ChatAvatarProps) => {
  const initials = getInitials(firstName, lastName);
  const backgroundColor = !type ? getColorOptionForString(initials) : undefined;
  return (
    <div
      className={classnames(styles.avatar, type ? styles[type] : undefined)}
      style={{ backgroundColor }}
    >
      {type === "class" ? <UserGroupIcon size="md" /> : null}
      {presence ? (
        <div className={styles.status}>
          <ChatBadge presence={presence} />
        </div>
      ) : null}
      {initials}
    </div>
  );
};
