import { updateAccessError } from "../../app/actions";
import actionTypes from "../../actionTypes";
import {
  fetchInitCreateWorkspace,
  fetchCreateWorkspace,
} from "../../../apiCalls/workspaces";

export const clearCreateWorkspace = () => ({
  type: actionTypes.CREATE_WORKSPACE_CLEAR,
});

export const loadCreateWorkspaceInit = () => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE_WORKSPACE_INIT_PENDING,
  });

  return fetchInitCreateWorkspace()
    .then((response) => {
      if (response.status === 403) {
        // Not a Hapara user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({
        type: actionTypes.CREATE_WORKSPACE_INIT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE_WORKSPACE_INIT_ERROR,
      });
    });
};

export const sendCreateWorkspace = (options) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE_WORKSPACE_PENDING,
  });

  return fetchCreateWorkspace(options)
    .then((response) => {
      if (response.status === 403) {
        // Not a Hapara user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }

      return response.json();
    })
    .then((data) => {
      dispatch({
        type: actionTypes.CREATE_WORKSPACE_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE_WORKSPACE_ERROR,
      });
    });
};
