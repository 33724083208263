import axios from "axios";
import _ from "lodash";
import { getToken } from "./jwtHandler";
import { ApiError } from "./types";
import { getErrorEvent, getErrorStringFromErrorOrResponse } from "../utils";
import { postSendEvent } from "./user";

export const getLibraryFileUploadURI = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/docs/signedURL`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const fetchLibraryConfig = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/config/library`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const postLibraryResource = async ({ payload }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/docs/`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, payload, config);
};

export const putLibraryResource = async ({ payload, resourceId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/docs/update/${resourceId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.put(url, payload, config);
};

export const getFiltersList = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/filters/list`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const fetchDiscoverResources = async ({ bodyRequest }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/discover`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, bodyRequest, config);
};

export const fetchLibraryResources = async ({ bodyRequest }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/library`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, bodyRequest, config);
};

export const fetchMyResources = async ({ bodyRequest }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/mine`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, bodyRequest, config);
};

export const addItemToLibrary = async ({ itemId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/assign/toLibrary/${itemId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.put(url, null, config);
};

export const putStar = async ({ itemId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/assign/toMe/${itemId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.put(url, null, config);
};

export const deleteStar = async ({ itemId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/assign/toMe/${itemId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.delete(url, config);
};

export const removeItemFromLibrary = async ({ itemId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/assign/toLibrary/${itemId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.delete(url, config, config);
};

export const patchAssignedClasses = async ({
  resourceId,
  addClasses,
  removeClasses,
}) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/assign/toClasses/${resourceId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  const data = {};

  if (!_.isEmpty(addClasses)) {
    data.add = addClasses;
  }
  if (!_.isEmpty(removeClasses)) {
    data.del = removeClasses;
  }

  return await axios.patch(url, data, config);
};

export const getClassesAssignedToDoc = async ({ resourceId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/assigned/byDoc/${resourceId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const getUploadStatus = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/status`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const getResourceAuthToken = async ({ resourceId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/docs/auth/${resourceId}`;

  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const fetchLibraryCollections = async () => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/collections/list`;

    const config = {
      headers: { Authorization: await getToken() },
    };
    const resp = await axios.get(url, config);
    return _.sortBy(resp.data, (item) => item.name.toLowerCase());
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/library/collections/list"
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const fetchNumberOfResourcesInCollection = async ({ collectionId }) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/library`;
    const config = {
      headers: { Authorization: await getToken() },
    };
    const bodyRequest = {
      CollectionIDs: [collectionId],
    };

    const resp = await axios.post(url, bodyRequest, config);
    return _.get(resp, "data.TotalHits");
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/library/search/library"
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postNewCollection = async ({ collectionName }) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/collections/`;
    const config = {
      headers: { Authorization: await getToken() },
    };
    const bodyRequest = {
      name: collectionName,
    };

    const resp = await axios.post(url, bodyRequest, config);
    return _.get(resp, "data");
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/library/collections/"
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const patchCollection = async ({ collectionName, collectionId }) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/collections/${collectionId}`;
    const config = {
      headers: { Authorization: await getToken() },
    };
    const bodyRequest = {
      name: collectionName,
    };

    await axios.patch(url, bodyRequest, config);
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      `/library/collections/${collectionId}`
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const deleteCollection = async ({ collectionId }) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/collections/${collectionId}`;
    const config = {
      headers: { Authorization: await getToken() },
    };

    await axios.delete(url, config);
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      `/library/collections/${collectionId}`
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postResourceCollections = async ({
  resourceId,
  addCollectionsIds,
  removeCollectionsIds,
}) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/collections/assign/${resourceId}`;

    const config = {
      headers: { Authorization: await getToken() },
    };

    const data = {};

    if (!_.isEmpty(addCollectionsIds)) {
      data.Add = addCollectionsIds;
    }
    if (!_.isEmpty(removeCollectionsIds)) {
      data.Del = removeCollectionsIds;
    }

    return await axios.post(url, data, config);
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      `/library/collections/assign/${resourceId}`
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const getResourceCollections = async ({ resourceId }) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/library/search/collections/byDoc/${resourceId}`;
    const config = {
      headers: { Authorization: await getToken() },
    };

    const resp = await axios.get(url, config);
    return _.get(resp, "data");
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      `/library/search/collections/byDoc/${resourceId}`
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};
