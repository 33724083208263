import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./DiscoverBrowseContent.module.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResourceSearch from "../../Resources/ResourceSearch/ResourceSearch";
import shelfOfBooks from "@hapara/assets/src/images/vector-shelf-of-books.svg";
import { getWindowSize } from "../../../../state/app/selectors";
import {
  WINDOW_SIZE_TYPES,
  WINDOW_SIZE_TYPE_LIST,
} from "../../../../state/app/types";

export const DiscoverBrowseContent = ({ windowSize }) => {
  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.container}>
          <h1>
            <FormattedMessage defaultMessage="Discover" id="cE4Hfw" />
          </h1>
          <div className={styles.description}>
            <FormattedMessage
              defaultMessage="Browse public, open source content from around the world that can be freely used with your class"
              id="XW9/jS"
            />
          </div>
        </div>
        <ResourceSearch
          className={styles.landingSearch}
          dataTestIdPrefix="lb-Discover"
        />
      </div>
      {windowSize >= WINDOW_SIZE_TYPES.S && (
        <div className={styles.imgContainer}>
          <img
            src={shelfOfBooks}
            alt="Books on a shelf with a magnifying glass"
          />
        </div>
      )}
    </div>
  );
};

DiscoverBrowseContent.propTypes = {
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  windowSize: getWindowSize(state),
}))(DiscoverBrowseContent);
