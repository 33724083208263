import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  updateGradingCardId,
  updateGradingActivity,
  updateGradingArtefact,
  getGradingData,
} from "../../../../state/workspace/grading/actions";
import {
  getGradingActivity,
  getGradingCardId,
  getGradingSelectedArtefactId,
} from "../../../../state/workspace/grading/selectors";
import _ from "lodash";

export const LocationChangeHandler = ({
  gradingCardId,
  gradingActivity,
  gradingSelectedArtefactId,

  updateGradingCardId,
  updateGradingActivity,
  updateGradingArtefact,
  getGradingData,
}) => {
  const { grading_card_id, grading_activity } = useParams();

  // listen to Card ID change and update it in store
  useEffect(() => {
    if (grading_card_id) {
      updateGradingCardId(grading_card_id);
    }
  }, [grading_card_id, updateGradingCardId]);

  // listen to Activity change and update it in store
  useEffect(() => {
    if (grading_activity) {
      updateGradingActivity(grading_activity);
    }
  }, [grading_activity, updateGradingActivity]);

  const dataFetchParamsRef = useRef();

  // listen to params change and load new data
  useEffect(() => {
    const newDataFetchParams = {
      gradingCardId,
      gradingActivity,
      gradingSelectedArtefactId,
    };
    let shouldLoadData = false;

    // check all the params which should cause re-render;
    // load should happen if any given param was changed since last change
    _.forEach(newDataFetchParams, (value, key) => {
      const shouldDoTheLoad = !_.isEqual(
        _.get(dataFetchParamsRef.current, key),
        value
      );
      if (shouldDoTheLoad) {
        shouldLoadData = true;
      }
    });

    // card ID and activity should be present before we fetch data
    // artefactId could be missing
    const isAllParamsPresent = gradingCardId && gradingActivity;

    // update the state and do the load
    if (isAllParamsPresent && shouldLoadData) {
      getGradingData();
      updateGradingArtefact();
    }

    // keep previous params values for the reference
    // so we can compare with the current ones
    dataFetchParamsRef.current = { ...newDataFetchParams };
  }, [
    gradingCardId,
    gradingActivity,
    gradingSelectedArtefactId,
    getGradingData,
    updateGradingArtefact,
  ]);

  return null;
};

LocationChangeHandler.propTypes = {
  gradingCardId: PropTypes.string,
  gradingActivity: PropTypes.string,
  gradingSelectedArtefactId: PropTypes.string,

  updateGradingCardId: PropTypes.func.isRequired,
  updateGradingActivity: PropTypes.func.isRequired,
  updateGradingArtefact: PropTypes.func.isRequired,
  getGradingData: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    gradingCardId: getGradingCardId(state),
    gradingActivity: getGradingActivity(state),
    gradingSelectedArtefactId: getGradingSelectedArtefactId(state),
  }),
  (dispatch) => ({
    updateGradingCardId: (param) => dispatch(updateGradingCardId(param)),
    updateGradingActivity: (param) => dispatch(updateGradingActivity(param)),
    updateGradingArtefact: () => dispatch(updateGradingArtefact()),
    getGradingData: () => dispatch(getGradingData()),
  })
)(LocationChangeHandler);
