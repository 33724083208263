import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsHighlightsBase } from "../../../state/router/selectors";
import { getStudentRequests } from "../../../state/highlights/studentRequests/actions";
import { getClassTeacherId } from "../../../state/highlights/config/selectors";

const StudentRequestsHandler = () => {
  const isHighlightsPageRef = useRef(false);
  const dispatch = useDispatch();
  const isHighlightsPage = useSelector(getIsHighlightsBase);
  const teacherId = useSelector(getClassTeacherId);

  useEffect(() => {
    if (teacherId) {
      if (isHighlightsPage !== isHighlightsPageRef.current) {
        if (isHighlightsPage) {
          // First Request to get the list of notifications
          dispatch(getStudentRequests());
        }
        isHighlightsPageRef.current = isHighlightsPage;
      }
    }
  }, [dispatch, isHighlightsPage, teacherId]);

  return null;
};

export default StudentRequestsHandler;
