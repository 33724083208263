import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import {
  getPlWorkspaces,
  getCurrentPage,
  getPagesCount,
  isLoading,
} from "../../../../../state/workspace/pl/list/selectors";

import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import styles from "./PLWorkspacesListItems.module.scss";
import PLWorkspaceTile from "../PLWorkspaceTile/PLWorkspaceTile";
import PLWorkspacesListEmptyState from "../PLWorkspacesListEmptyState/PLWorkspacesListEmptyState";
import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";

const PLWorkspacesListItems = ({
  items,
  currentPage,
  pagesCount,
  isLoading,
}) => {
  const handlePageSelect = (page) => {
    pushQueryToHistory({
      page,
    });
  };

  return (
    <div className={styles.root}>
      {isLoading && (
        <ul className={styles.list}>
          {_.range(0, 3).map((index) => (
            <li
              className={classnames(styles.listItem, styles.loading)}
              key={index}
            >
              <PLWorkspaceTile />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && items && items.length === 0 && (
        <PLWorkspacesListEmptyState />
      )}

      {!isLoading && items.length > 0 && (
        <React.Fragment>
          <ul className={styles.list}>
            {_.map(items, (item) => (
              <li
                className={styles.listItem}
                key={item.Id}
                data-test-id="Hs-PL-WorkspacesListItem-Container"
              >
                <PLWorkspaceTile
                  item={item}
                  dataTestIdPrefix="Ws-PL-WorkspacesListItem"
                />
              </li>
            ))}
          </ul>

          <Pagination
            pages={pagesCount}
            currentPage={currentPage}
            onSelectPage={handlePageSelect}
            dataTestIdPrefix="Ws-PL-WorkspacesListPagination"
          />
        </React.Fragment>
      )}
    </div>
  );
};

PLWorkspacesListItems.propTypes = {
  items: PropTypes.arrayOf(wsItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  items: getPlWorkspaces(state),
  currentPage: getCurrentPage(state),
  pagesCount: getPagesCount(state),
  isLoading: isLoading(state),
}))(PLWorkspacesListItems);
