import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Personalisation from "../Personalisation/Personalisation";
import PersonalisationMobile from "../Personalisation/PersonalisationMobile";
import styles from "./HeaderAccountOnly.module.scss";
import { ALT_TEXT } from "../../../state/app/types";
import { MAIN_CONTENT_ID } from "../../../consts";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";

export const HeaderAccountOnly = ({ rootPageLink }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <a
          href={`#${MAIN_CONTENT_ID}`}
          className={classnames(
            styles.visibleOnFocusOnly,
            styles.skipToContent
          )}
        >
          <FormattedMessage defaultMessage="Skip to content" id="ZKtRoA" />
        </a>
        <Link
          to={rootPageLink}
          aria-label="Hapara"
          data-test-id="Hs-Header-Link-HaparaLogoMain"
        >
          <div className={classnames(styles.onDesktopOnly, styles.logo)}>
            <HapReactIcon
              svg="logo-hapara-small-light"
              width={40}
              height={40}
              alt={ALT_TEXT.HAPARA_LOGO}
            />
          </div>
        </Link>
        <div className={styles.onMobileOnly}>
          <Button
            icon="hamburger"
            iconWidth={32}
            iconHeight={32}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            onAction={() => setSideMenuOpen(true)}
            alt="Open"
            aria-label="Open side menu"
            dataTestId="lb-Header-Link-OpenMobileNav"
            className={styles.openPanelTrigger}
          />
        </div>
        <div className={styles.levels}>
          <div
            className={classnames(styles.overlay, {
              [styles.hidden]: !sideMenuOpen,
            })}
          />
          <div className={styles.levelOne}>
            <div className={styles.onDesktopOnly}>
              <Personalisation />
            </div>
          </div>
          <div
            className={classnames(styles.levelTwo, {
              [styles.visible]: sideMenuOpen,
            })}
          >
            <div className={classnames(styles.onMobileOnly, styles.header)}>
              <Button
                icon="close"
                iconWidth={16}
                iconHeight={16}
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.SMALL}
                onAction={() => setSideMenuOpen(false)}
                aria-label="Close side menu"
                dataTestId="Hs-Header-Link-CloseMobileNav"
                className={styles.closePanelTrigger}
              />
              <Link
                to={rootPageLink}
                aria-label="Hapara"
                data-test-id="Hs-Header-Link-HaparaLogoMain"
              >
                <div className={styles.logoMobile}>
                  <HapReactIcon
                    svg="logo-hapara-small-light"
                    width={32}
                    height={32}
                    alt={ALT_TEXT.HAPARA_LOGO}
                  />
                </div>
              </Link>
            </div>
            <div className={styles.levelTwoInner}>
              <div className={styles.onMobileOnly}>
                <PersonalisationMobile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderAccountOnly.propTypes = {
  rootPageLink: PropTypes.string.isRequired,
};

export default HeaderAccountOnly;
