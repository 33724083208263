import { SyntheticEvent } from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import styles from "./StudentDriveTablePopup.module.scss";

// COMPONENTS
import Loader from "@hapara/ui/src/atomic/LoadIndicators/Loader/Loader";

// TYPES
import { DocumentShape, Permissions } from "../StudentTileDrive/types";
import { WINDOW_SIZE_TYPES } from "../../../../state/app/types";

// ASSETS
import troubleLoadingImage from "@hapara/assets/src/images/image-trouble-loading-image.svg";
import loadingImage from "@hapara/assets/src/images/image-loading-image.svg";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "unset",
    maxWidth: "unset",
    fontSize: "unset",
    border: "1px solid #dadde9",
    [theme.breakpoints.down(WINDOW_SIZE_TYPES.S)]: {
      display: "none",
    },
  },
}));

interface StudentDriveTablePopupProps {
  popoverData: DocumentShape | null;
  permissions: Permissions[] | null;
  isLoading?: boolean;
  isReadOnly?: boolean;
  children: React.ReactElement;
}

const MAX_NUMBER_OF_PERMISSIONS_TO_DISPLAY = 4;
const MAX_NUMBER_OF_PERMISSIONS_WITH_MORE = 3;

const StudentDriveTablePopup: React.FC<StudentDriveTablePopupProps> = ({
  popoverData,
  children,
  permissions,
  isLoading,
  isReadOnly,
}) => {
  const formatDate = (date?: string) => {
    if (!date) {
      return "";
    }
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString("default", { month: "short" });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    const minuteStr = minute < 10 ? `0${minute}` : minute;
    return `${month} ${day}, ${year} | ${hour12}:${minuteStr} ${ampm}`;
  };

  const Permissions = (
    <div className={styles.permissionsColumn}>
      {permissions &&
        permissions?.length > 0 &&
        permissions.map((permission, index) => {
          if (
            index < MAX_NUMBER_OF_PERMISSIONS_WITH_MORE ||
            (index === MAX_NUMBER_OF_PERMISSIONS_WITH_MORE &&
              permissions.length === MAX_NUMBER_OF_PERMISSIONS_TO_DISPLAY)
          ) {
            return (
              <h2 className={styles.itemValue} key={index}>
                {permission.name}
              </h2>
            );
          }
          if (
            index === MAX_NUMBER_OF_PERMISSIONS_WITH_MORE &&
            permissions.length > MAX_NUMBER_OF_PERMISSIONS_TO_DISPLAY
          ) {
            return (
              <h2 className={styles.itemValue} key={index}>
                {`+${
                  permissions.length - MAX_NUMBER_OF_PERMISSIONS_WITH_MORE
                } more`}
              </h2>
            );
          }
          return null;
        })}
    </div>
  );

  return (
    <CustomTooltip
      followCursor
      disableFocusListener
      disableTouchListener
      enterDelay={600}
      enterNextDelay={600}
      key={popoverData?.id}
      title={
        <div className={styles.wrapper}>
          <div className={styles.headerContainer}>
            <img
              src={popoverData?.iconLink}
              alt=""
              className={styles.documentIcon}
            />
            <h2 className={styles.title}>{popoverData?.title}</h2>
          </div>
          <div className={styles.columnContainer}>
            <div className={styles.column}>
              {isLoading ? (
                <img
                  src={loadingImage}
                  alt="document preview"
                  className={styles.documentPreview}
                />
              ) : (
                <img
                  src={popoverData?.thumbnailurl}
                  alt="document preview"
                  className={styles.documentPreview}
                  onError={(e: SyntheticEvent<HTMLImageElement, Event>) =>
                    (e.currentTarget.src = troubleLoadingImage)
                  }
                />
              )}
            </div>
            <div className={styles.column}>
              <div className={styles.itemContainer}>
                <h2 className={styles.itemName}>Author:</h2>
                <h2 className={styles.itemValue}>{popoverData?.ownerNames}</h2>
              </div>
              <div className={styles.itemContainer}>
                <h2 className={styles.itemName}>Updated by:</h2>
                <h2 className={styles.itemValue}>
                  {popoverData?.lastModifyingUserName}
                </h2>
              </div>
              <div className={styles.itemContainer}>
                <h2 className={styles.itemName}>Updated date:</h2>
                <h2 className={styles.itemValue}>
                  {formatDate(popoverData?.modifiedDate)}
                </h2>
              </div>
              <div className={styles.itemContainer}>
                <h2 className={styles.itemName}>Last view date:</h2>
                <h2 className={styles.itemValue}>
                  {formatDate(popoverData?.lastviewed)}
                </h2>
              </div>
              <div className={styles.itemContainer}>
                <h2 className={styles.accessTitle}>Who has access</h2>
              </div>
              <hr className={styles.hr} />
              <div className={styles.itemContainer}>
                <h2 className={styles.itemName}>Owned by:</h2>
                <h2 className={styles.itemValue}>{popoverData?.ownerNames}</h2>
              </div>
              <div className={styles.itemContainer}>
                {isReadOnly ? (
                  <h2 className={styles.itemName}>Read only</h2>
                ) : (
                  <>
                    <h2 className={styles.itemName}>Writable by:</h2>
                    {isLoading ? (
                      <Loader height={20} width={20} visible />
                    ) : (
                      Permissions
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </CustomTooltip>
  );
};

export default StudentDriveTablePopup;
