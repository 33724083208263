import { history } from "../../store";
import { APP_PAGES } from "../../router/types";

export const MY_RESOURCES_URL_KEYS = {
  query: {
    key: "query",
    dependantsKeysToReset: ["page"],
  },
  class: {
    key: "classId",
    dependantsKeysToReset: ["page"],
  },
  page: {
    key: "page",
    shouldDefaultBeInUrl: true,
  },
  starred: {
    key: "starred",
    dependantsKeysToReset: ["page"],
  },
};

export const MY_RESOURCES_URL_PARAMS_DEFAULT_VALUES = {
  page: 1,
  query: "",
  classId: null,
};

export const RESOURCE_AUTH_URL_KEYS = {
  redirectUrl: {
    key: "redirect_url",
  },
  token: {
    key: "access_token",
  },
};

export const navigateToLibraryMyResourcesLanding = () => {
  history.push({
    pathname: APP_PAGES.MY_RESOURCES_BASE.path,
  });
};

export const navigateToMyResourcesSearchResults = ({
  query,
  page,
  classId,
  starred,
}) => {
  const getSeparator = (s) => (!s ? "?" : "&");

  let search = "";

  if (query) {
    search += `${getSeparator(search)}${
      MY_RESOURCES_URL_KEYS.query.key
    }=${window.encodeURIComponent(query)}`;
  }

  if (classId) {
    search += `${getSeparator(search)}${
      MY_RESOURCES_URL_KEYS.class.key
    }=${classId}`;
  }

  if (starred) {
    search += `${getSeparator(search)}${
      MY_RESOURCES_URL_KEYS.starred.key
    }=true`;
  }

  search += `${getSeparator(search)}${MY_RESOURCES_URL_KEYS.page.key}=${
    page || MY_RESOURCES_URL_PARAMS_DEFAULT_VALUES.page
  }`;

  history.push({
    pathname: APP_PAGES.MY_RESOURCES_SEARCH.path,
    search,
  });
};
