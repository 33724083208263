import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./groupsFilter.module.scss";
import { getColorCode } from "../utils";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../../atomic/Button/Button";
import Checkbox from "../../atomic/Checkbox/Checkbox";
import _ from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

const GroupsFilter = ({
  groups,
  groupSelected,
  setall,
  editGroups,
  newGroup,
  openPreferences,
  isSharedGroups,
  dataTestIdCore,
  setParentButtonFocus = () => {},
}) => {
  const intl = useIntl();
  const filteredGroups = _.sortBy(
    groups.filter((g) => g.URN),
    "name"
  );

  const hasGroups = filteredGroups.length !== 0;

  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestIdCore}-GroupsFilter-Dropdown`}
    >
      {!hasGroups && (
        <div className={styles.noGroups}>
          <div
            className={styles.noGroupsDescription}
            data-test-id={`${dataTestIdCore}-GroupsFilter-NoGroups`}
          >
            {intl.formatMessage({
              defaultMessage: "You haven't created any Groups yet.",
              id: "q1uocP",
            })}
          </div>
          <div className={styles.noGroupsAction}>
            {isSharedGroups
              ? intl.formatMessage({
                  defaultMessage: "Groups will be available across all tabs.",
                  id: "UnxLeh",
                })
              : intl.formatMessage({
                  defaultMessage: "Groups will be specific to each tab.",
                  id: "VZVwef",
                })}{" "}
            <FormattedMessage
              defaultMessage="You may <button>update group preference</button> first before creating new groups."
              values={{
                button: (chunks) => (
                  <button
                    className={styles.noGroupsLink}
                    onClick={openPreferences}
                  >
                    {chunks}
                  </button>
                ),
              }}
              id="cAp4xI"
            />
          </div>
        </div>
      )}
      {hasGroups && (
        <div className={styles.dropDownItemsDiv}>
          <div className={styles.clearFilter}>
            <div
              className={styles.filterText}
              data-test-id={`${dataTestIdCore}-GroupsFilter-SelectedGroupsCounter`}
            >
              {(() => {
                const selectedCount = groups.filter((g) => g.checked).length;
                return selectedCount === 0
                  ? intl.formatMessage({
                      defaultMessage: "None selected",
                      id: "7MYtB4",
                    })
                  : intl.formatMessage(
                      {
                        defaultMessage: "{selectedCount} selected",
                        id: "cSxbY8",
                      },
                      { selectedCount }
                    );
              })()}
            </div>
            <Button
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              label={intl.formatMessage({
                defaultMessage: "Clear filter",
                id: "OhgOkH",
              })}
              onAction={() => setall(false)}
              dataTestId={`${dataTestIdCore}-GroupsFilter-ClearFilterButton`}
              className={styles.clearFilterBtn}
            />
          </div>
          <ul className={styles.dropDownItems}>
            {filteredGroups.map((group) => (
              <li key={group.URN}>
                <Checkbox
                  id={`groupdropdown_chck_${group.URN}`}
                  key={group.URN}
                  checked={group.checked}
                  onChange={(checked) => {
                    groupSelected(group.URN, checked);
                    setParentButtonFocus();
                  }}
                  className={styles.groupName}
                  dataTestIdPrefix={`${dataTestIdCore}-GroupsFilter-SelectedGroup`}
                  label={
                    <div className={styles.groupNameWithBadge}>
                      <div
                        className={classnames(styles.groupBadge, {
                          [styles[getColorCode(group)]]: true,
                        })}
                      />
                      <span className={styles.name}>{group.name}</span>
                    </div>
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.actionButtons}>
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.SMALL}
          label={intl.formatMessage({
            defaultMessage: "New Group",
            id: "mx9Iyw",
          })}
          onAction={newGroup}
          dataTestId={`${dataTestIdCore}-GroupsFilter-ButtonNewGroup`}
          className={styles.fullWidth}
        />
        {hasGroups && (
          <Button
            type={BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.SMALL}
            label={intl.formatMessage({
              defaultMessage: "Edit Groups",
              id: "CEM3EH",
            })}
            onAction={editGroups}
            dataTestId={`${dataTestIdCore}-GroupsFilter-ButtonEditGroups`}
            className={styles.fullWidth}
          />
        )}
      </div>
    </div>
  );
};

GroupsFilter.propTypes = {
  groups: PropTypes.array,
  groupSelected: PropTypes.func,
  setall: PropTypes.func,
  editGroups: PropTypes.func,
  newGroup: PropTypes.func,
  openPreferences: PropTypes.func,
  isSharedGroups: PropTypes.bool,
  dataTestIdCore: PropTypes.string,
  setParentButtonFocus: PropTypes.func,
};

export default GroupsFilter;
