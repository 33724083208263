import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./FocusedSessionList.module.scss";
import Button, { BUTTON_SIZES } from "@hapara/ui/src/atomic/Button/Button";
import SearchWithDropdown from "@hapara/ui/src/atomic/SearchWithDropdown/SearchWithDropdown";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE,
  SESSION_RECIPIENT_TYPES,
  SESSION_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import { getRecentTabsHistory } from "../../../../../../state/highlights/sessions/selectors";
import {
  getHostname,
  normaliseUrl,
  validateUrl,
  isDuplicateURL,
} from "../../../../../../utils";
import { useIntl } from "react-intl";

const FocusedSessionListWhat = ({
  sessionId,
  sessionType,
  sessionFocusType,
  sessionKeepAllTabs,
  sessionRestoreOriginal,
  sessionLinks,
  sessionRecipientType,
  sessionRecipientGroups,
  sessionRecipientStudents,
  onSessionUpdate,
  recentTabs,
}) => {
  const intl = useIntl();
  const [newLink, setNewLink] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [forceClearSearchTerm, setForceClearSearchTerm] = useState(false);

  const handleSearchTermClear = () => {
    setSearchResults([]);
    setNewLink(null);
  };

  const handleSearchTermChange = (searchTerm) => {
    if (!searchTerm) {
      setSearchResults([]);
      setNewLink(null);
    } else {
      // validate the input and update newLink if valid
      const newUrl = normaliseUrl(searchTerm);

      setNewLink(newUrl);

      // search in last 20 and update search results
      const allUrlsToSearch = _.filter(
        recentTabs,
        (item) => !_.includes(sessionLinks, item)
      );

      const allUrlsToSearchFormatted = _.map(allUrlsToSearch, (url) => {
        const normalisedUrl = normaliseUrl(url);
        return {
          id: normalisedUrl,
          label: normalisedUrl,
          displayElement: <span>{normalisedUrl}</span>,
          disabled: false,
        };
      });

      setSearchResults(
        _.filter(allUrlsToSearchFormatted, (item) => {
          const labelInsensitive = item.label.toLowerCase();
          const searchTermInsensitive = searchTerm.toLowerCase();
          return labelInsensitive.indexOf(searchTermInsensitive) > -1;
        })
      );
    }
  };

  const debouncedHandleSearchTermChange = _.debounce(
    handleSearchTermChange,
    500
  );

  const handleSearchResultSelect = (item) => {
    if (item && item.id) {
      setNewLink(normaliseUrl(item.id));
    } else {
      setNewLink(null);
    }
  };

  const handleAddLink = () => {
    // construct new session data
    const newSession = {
      ID: sessionId,
      StudentIDS: sessionRecipientStudents,
      EndSessionKeepAllTabs: sessionKeepAllTabs,
      EndSessionRestoreOriginal: sessionRestoreOriginal,
    };

    if (sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      newSession.Groups = sessionRecipientGroups;
    }

    newSession.Links = _.concat(sessionLinks, [newLink]);

    // force to clear the search term and set the force setting back to false
    setForceClearSearchTerm(true);
    _.delay(() => setForceClearSearchTerm(false), 10);

    onSessionUpdate(newSession);
  };

  const handleRemoveLink = (link) => {
    const updatedLinks = sessionLinks.filter(
      (existingLink) => existingLink !== link
    );
    const updatedSession = {
      ID: sessionId,
      StudentIDS: sessionRecipientStudents,
      EndSessionKeepAllTabs: sessionKeepAllTabs,
      EndSessionRestoreOriginal: sessionRestoreOriginal,
      Links: updatedLinks,
    };

    if (sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      updatedSession.Groups = sessionRecipientGroups;
    }

    onSessionUpdate(updatedSession);
  };

  // reverse the array of the urls
  const reversedUrls = useMemo(() => {
    const urls = _.clone(sessionLinks);
    _.reverse(urls); // mutates the array
    return urls;
  }, [sessionLinks]);

  const isAddUrlVisible =
    GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE[sessionType];

  const isAddUrlValid =
    validateUrl(newLink) && !isDuplicateURL(sessionLinks, newLink);

  const handleEnterPressInSearch = () => {
    if (isAddUrlValid) {
      handleAddLink();
    }
  };

  const getCloseTabAriaLabel = (siteAddress) => {
    sessionType === SESSION_TYPES.FILTER
      ? intl.formatMessage(
          {
            defaultMessage:
              "Remove {siteAddress} from this session to allow access",
            id: "QR64Q2",
          },
          { siteAddress: siteAddress }
        )
      : intl.formatMessage(
          {
            defaultMessage:
              "Remove {siteAddress} from this session to prevent access",
            id: "NosDxS",
          },
          { siteAddress: siteAddress }
        );
  };

  return (
    <div className={styles.root}>
      <div
        className={classnames([
          styles.list,
          { [styles.listExtended]: !isAddUrlVisible },
        ])}
      >
        {reversedUrls.length &&
          _.map(reversedUrls, (item) => {
            const displayName =
              sessionType === SESSION_TYPES.FILTER ||
              sessionFocusType === GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
                ? getHostname(item)
                : item;
            return (
              <div className={styles.item} key={item}>
                <div className={styles.itemName}>{displayName}</div>
                <Button
                  isDisabled={sessionLinks.length === 1}
                  icon="cross"
                  onClick={() => handleRemoveLink(item)}
                  type="tertiary"
                  size="small"
                  aria-label={getCloseTabAriaLabel(item)}
                  data-test-id={`remove-url-from-${sessionType}-session-button`}
                />
              </div>
            );
          })}
      </div>

      {isAddUrlVisible && (
        <div className={styles.addAnItem}>
          <SearchWithDropdown
            placeholder={intl.formatMessage({
              defaultMessage: "Enter a URL",
              id: "MHl4RB",
            })}
            itemsForDD={searchResults}
            onChange={debouncedHandleSearchTermChange}
            onSelect={handleSearchResultSelect}
            onClear={handleSearchTermClear}
            onEnter={handleEnterPressInSearch}
            forceClearSearchTerm={forceClearSearchTerm}
            dataTestIdPrefix="Hl-EditActiveSession-EditLinks"
          />
          <Button
            className={styles.addAnItemButton}
            onAction={() => handleAddLink()}
            label={intl.formatMessage({
              defaultMessage: "Add link",
              id: "JnxkKM",
            })}
            size={BUTTON_SIZES.XSMALL}
            isDisabled={!isAddUrlValid}
            dataTestId="Hl-EditActiveSession-EditLinks-Add"
          />
        </div>
      )}
    </div>
  );
};

FocusedSessionListWhat.propTypes = {
  sessionId: PropTypes.string.isRequired,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  sessionFocusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)),
  sessionKeepAllTabs: PropTypes.bool.isRequired,
  sessionRestoreOriginal: PropTypes.bool.isRequired,
  sessionLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES))
    .isRequired,
  sessionRecipientGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientStudents: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSessionUpdate: PropTypes.func.isRequired,
  // connected props
  recentTabs: PropTypes.arrayOf(PropTypes.string),
};

export default connect(
  (state) => ({
    recentTabs: getRecentTabsHistory(state),
  }),
  (dispatch) => ({})
)(FocusedSessionListWhat);
