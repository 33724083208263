import { useState } from "react";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import { MUTATION_STATUS } from "../../../../containers/Workspace/BoardPage/BoardPage";
import { DeleteCardByIdDocument } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import styles from "./ConfirmationDialog.module.scss";

import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import { Button, BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";

interface ConfirmationDialogTypes {
  cardId: string | null;
  boardId: string;
  isOpen: boolean;
  dataTestIdPrefix: string;
  closeModalAction?: () => void;
  setDeleteCardID: (cardId: string | null) => void;
  mutationStatusCB: (status: string) => void; //Used to display toast on BoardPage.tsx
}

const DIALOG_TEXT = {
  HEADING: "Delete card?",
  CONTENT:
    "You and your students will not have the option to edit or interact with this card again.",
  CANCEL: "Cancel",
  CONFIRM: "Delete card",
  ERROR: "Sorry, there was a problem deleting this card. Please try again.",
};

const ConfirmationDialog = ({
  cardId = "none",
  boardId,
  isOpen,
  dataTestIdPrefix,
  closeModalAction,
  setDeleteCardID,
  mutationStatusCB,
}: ConfirmationDialogTypes) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [deleteCard, { loading }] = useMutation(DeleteCardByIdDocument, {
    update: (cache, { data }) => {
      if (data) {
        //Removes the card from the list of cards in the workspace cache
        cache.modify({
          id: boardId, //Which cache item(workspace) to update
          fields: {
            Cards(existingCards, { readField }) {
              if (
                //Check that the cardID exisits in the workspace cache
                existingCards.some(
                  (ref: Reference | StoreObject | undefined) =>
                    readField("ID", ref) === data.deleteCardById
                )
              ) {
                const updatedCards = existingCards.filter(
                  (item: any) => item.__ref !== data.deleteCardById
                );
                //Update the Cards field in the workspace cache with the filtered array
                return updatedCards;
              }
              //Return existing array if the cardId does not exist
              return existingCards;
            },
          },
        });
      }
    },
  });

  const handleMutation = () => {
    deleteCard({
      variables: { id: cardId },
    })
      .then(() => {
        setDeleteCardID(null);
        mutationStatusCB(MUTATION_STATUS.SUCCESS);
        if (closeModalAction) {
          closeModalAction();
        }
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const handleClose = () => {
    setIsError(false);
    setDeleteCardID(null);
  };

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={handleClose}
      classNameContent={styles.root}
      className={styles.dialogContent}
      dataTestPrefix={`${dataTestIdPrefix}-DeleteCardModal`}
      contentLabel={DIALOG_TEXT.HEADING}
      hasEmbeddedHeader={false}
    >
      <div className={styles.contentContainer}>
        <div className={styles.dialogHeader}>
          <h1>{DIALOG_TEXT.HEADING}</h1>
        </div>
        <Button
          icon="cross"
          onClick={handleClose}
          type={BUTTON_TYPES.TERTIARY}
          aria-label="Close"
          data-test-id={`${dataTestIdPrefix}-DeleteCardModal-Close`}
          className={styles.dialogClose}
        />
        <div className={styles.dialogBody}>
          {DIALOG_TEXT.CONTENT}
          {isError && (
            <div
              className={styles.errorMessage}
              data-test-id={`${dataTestIdPrefix}-DeleteCardModal-ErrorAlert`}
            >
              {DIALOG_TEXT.ERROR}
            </div>
          )}
        </div>
        <div className={styles.dialogFooter}>
          <Button
            label={DIALOG_TEXT.CANCEL}
            onClick={handleClose}
            type={BUTTON_TYPES.SECONDARY}
            aria-label={DIALOG_TEXT.CANCEL}
            data-test-id={`${dataTestIdPrefix}-DeleteCardModal-Cancel`}
          />
          <Button
            label={DIALOG_TEXT.CONFIRM}
            onClick={handleMutation}
            isLoading={loading}
            type={BUTTON_TYPES.DANGER}
            aria-label={DIALOG_TEXT.CONFIRM}
            data-test-id={`${dataTestIdPrefix}-DeleteCardModal-Delete`}
          />
        </div>
      </div>
    </ModalDeprecated>
  );
};

export default ConfirmationDialog;
