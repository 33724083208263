import React from "react";
import { createIcon } from "./utils/createIcon";

export const PlusIcon = createIcon(
  <path
    fillRule="evenodd"
    fill="currentColor"
    d="M8 8H4s-.5 0-.5.5.5.5.5.5h4v4s0 .5.5.5.5-.5.5-.5V9h4s.5 0 .5-.5S13 8 13 8H9V4s0-.5-.5-.5S8 4 8 4v4Z"
    clipRule="evenodd"
  />
);
