import React from "react";

type DefaultRichTextElements = {
  [key: string]: (chunk: React.ReactNode[]) => JSX.Element;
};

export const defaultRichTextElements: DefaultRichTextElements = {
  strong: (chunk) => <strong>{chunk}</strong>,
  b: (chunk) => <strong>{chunk}</strong>,
};
