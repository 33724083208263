import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Button, {
  ButtonLink,
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { openEditColourDialog } from "../../../../state/dashboard/manage-classes/actions";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import styles from "./ClassTile.module.scss";

const ClassTileContextMenu = ({
  classId,
  isDisabled = false,
  color,
  classType,
  openEditColourDialog,
}) => {
  const intl = useIntl();
  const triggerComponent = useCallback(
    ({ ...rest }) => (
      <MemorisedFocusButton
        icon="ellipsis"
        type={BUTTON_TYPES.OUTLINED}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        size={BUTTON_SIZES.SMALL}
        data-test-id="Mc-ClassTile-EditMenuDropdown-Trigger"
        aria-label={intl.formatMessage({
          defaultMessage: "More options",
          id: "IzCVhG",
        })}
        isDisabled={isDisabled}
        {...rest}
      />
    ),
    [isDisabled]
  );

  const viewClassInfoLink = `${process.env.REACT_APP_HSUITE_BASE_URL}/class-info/${classId}`;

  const dropdownItems = [
    ({ onClick }) => (
      <Button
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "Edit class color",
          id: "jKUnPy",
        })}
        onClick={() => {
          openEditColourDialog({
            id: classId,
            color: color,
            classType: classType,
          });
          onClick();
        }}
        dataTestId="Mc-ClassTile-Dropdown-EditClassColour"
        className={styles.dropDownItem}
        isFullWidth
      />
    ),
    () => (
      <ButtonLink
        type={BUTTON_TYPES.TERTIARY}
        size={BUTTON_SIZES.SMALL}
        label={intl.formatMessage({
          defaultMessage: "View class info",
          id: "Q3iVq2",
        })}
        href={viewClassInfoLink}
        dataTestId="Mc-ClassTile-Dropdown-ViewClassInfo"
        className={styles.dropDownItem}
        isFullWidth
      />
    ),
  ];

  return (
    <Dropdown
      positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
      triggerComponent={triggerComponent}
      itemComponentList={dropdownItems}
    />
  );
};

ClassTileContextMenu.propTypes = {
  isDisabled: PropTypes.bool,
  classId: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  classType: PropTypes.string.isRequired,
  openEditColourDialog: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    openEditColourDialog: (payload) => dispatch(openEditColourDialog(payload)),
  })
)(ClassTileContextMenu);
