import { ClassTypeEnum, Domain, Permissions } from "./shared";

export type TeacherInfo = {
  name: string;
};

export type SchoolPrefs = {
  domain: string;
  school: string;
  landingModule: LandingModules;
};

export type ClassInfo = {
  id: string; //urn
  classType: ClassTypeEnum;
  color: string;
  googleClassroomId?: string;
  starred: boolean;
  teachers: TeacherInfo[];
  order_priority: number;
  class_alias: string; //display name (for compatibility)
  domain: string;
  school: string;
  isClassSyncInProgress: boolean;
};

/* eslint-disable no-unused-vars */
export enum LandingModules {
  HIGHLIGHTS = "highlights",
  DASHBOARD = "dashboard",
}
/* eslint-enable no-unused-vars */

export type DashboardConfig = {
  permissions: Permissions[];
  domains: Domain[];
  schoolPrefs: SchoolPrefs[];
};
