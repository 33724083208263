import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./FocusedSessionList.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import SearchWithDropdown from "@hapara/ui/src/atomic/SearchWithDropdown/SearchWithDropdown";
import {
  SESSION_RECIPIENT_TYPES,
  SESSION_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import { STUDENT_TYPE } from "../../../../../../state/highlights/students/types";
import { USER_GROUP_TYPE } from "../../../../../../state/shared/userGroups/types";
import { getFormattedName } from "../../../../../../utils";
import { getUserNameFormat } from "../../../../../../state/user/selectors";
import { getStudentList } from "../../../../../../state/highlights/students/selectors";
import {
  getGroupStatusInfo,
  getUserGroups,
} from "../../../../../../state/shared/userGroups/selectors";
import { FormattedMessage, useIntl } from "react-intl";

const FocusedSessionListWho = ({
  sessionId,
  sessionType,
  sessionKeepAllTabs,
  sessionRestoreOriginal,
  sessionLinks,
  sessionRecipientType,
  sessionRecipientGroups,
  sessionRecipientStudents,
  students,
  userGroups,
  nameFormat,
  getGroupStatusInfo,
  onSessionUpdate,
}) => {
  const [entityToAdd, setEntityToAdd] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [forceClearSearchTerm, setForceClearSearchTerm] = useState(false);
  const [entityReleaseState, setEntityReleaseState] = useState({});
  const intl = useIntl();

  const isGroupLockType =
    sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS;

  const handleSearchTermClear = () => {
    setSearchResults([]);
    setEntityToAdd(null);
  };

  const getFormattedStudentsToSearch = () => {
    const allStudentsToSearch = _.filter(
      students,
      (student) => !_.includes(sessionRecipientStudents, student.Id)
    );

    return _.map(allStudentsToSearch, (student) => {
      const displayName = getFormattedName(nameFormat, student);
      const isLocked = _.get(
        student,
        `instances.${student.currentInstance}.lock.locking.active`,
        false
      );

      return {
        id: student.Id,
        label: displayName,
        displayElement: (
          <div className={styles.searchResultContainer}>
            <div className={styles.searchResultName}>{displayName} </div>
            <div className={styles.searchResultSuffix}>
              {isLocked ? (
                <span>
                  <FormattedMessage
                    defaultMessage="(is active in a session)"
                    id="muUVCC"
                  />
                </span>
              ) : (
                ""
              )}
              {!_.get(
                student,
                `instances.${student.currentInstance}.isOnline`
              ) ? (
                <span className={styles.itemNameSuffix}>
                  <FormattedMessage defaultMessage="(offline)" id="bLm0E3" />
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        ),
        disabled: isLocked,
      };
    });
  };

  const getFormattedGroupsToSearch = () => {
    const allGroupsToSearch = _.filter(
      userGroups,
      (group) => !_.includes(sessionRecipientGroups, _.toString(group.URN))
    );

    return _.map(allGroupsToSearch, (group) => {
      const displayName = _.get(group, "name", "");
      const studentStatus = getGroupStatusInfo(group);
      return {
        id: _.toString(group.URN),
        label: displayName,
        displayElement: (
          <div className={styles.searchResultContainer}>
            <div className={styles.searchResultName}>{displayName}</div>
            <div className={styles.searchResultSuffix}>
              {studentStatus.message && (
                <span className={styles.itemNameSuffix}>
                  <FormattedMessage
                    defaultMessage="({message})"
                    id="NdWKNC"
                    values={{ message: studentStatus.message }}
                  />
                </span>
              )}
            </div>
          </div>
        ),
        disabled: studentStatus.areAllLocked,
      };
    });
  };

  const handleSearchTermChange = (searchTerm) => {
    if (!searchTerm) {
      setSearchResults([]);
      setEntityToAdd(null);
    } else {
      const formattedEntitiesToSearch = isGroupLockType
        ? getFormattedGroupsToSearch()
        : getFormattedStudentsToSearch();

      setSearchResults(
        _.filter(formattedEntitiesToSearch, (item) => {
          const labelInsensitive = item.label.toLowerCase();
          const searchTermInsensitive = searchTerm.toLowerCase();
          return labelInsensitive.indexOf(searchTermInsensitive) > -1;
        })
      );
    }
  };

  const debouncedHandleSearchTermChange = _.debounce(
    handleSearchTermChange,
    500
  );

  const handleSearchResultSelect = (item) => {
    if (item && item.id) {
      setEntityToAdd(item.id);
    } else {
      setEntityToAdd(null);
    }
  };

  const getGroupParticipantsIds = useCallback(
    (groupId) => {
      const group = _.find(userGroups, (item) => item.URN === groupId);
      const studentEmails = _.get(group, "participants", []);
      return _.map(
        _.filter(students, (student) =>
          _.includes(studentEmails, student.Email)
        ),
        (item) => item.Id
      );
    },
    [userGroups, students]
  );

  const handleAddEntity = () => {
    // construct new session data
    const newSession = {
      ID: sessionId,
      Links: sessionLinks,
    };

    if (sessionType === SESSION_TYPES.FOCUS) {
      newSession.EndSessionKeepAllTabs = sessionKeepAllTabs;
      newSession.EndSessionRestoreOriginal = sessionRestoreOriginal;
    }

    if (isGroupLockType) {
      newSession.Groups = _.concat(sessionRecipientGroups, [entityToAdd]);
      newSession.StudentIDS = _.concat(
        sessionRecipientStudents,
        getGroupParticipantsIds(entityToAdd)
      );
    } else {
      newSession.StudentIDS = _.concat(sessionRecipientStudents, [entityToAdd]);
    }

    // force to clear the search term and set the force setting back to false
    setForceClearSearchTerm(true);
    _.delay(() => setForceClearSearchTerm(false), 10);

    onSessionUpdate(newSession);
  };

  const handleRemoveEntity = (entityId) => {
    // construct new session data
    const newSession = {
      ID: sessionId,
      Links: sessionLinks,
    };

    if (sessionType === SESSION_TYPES.FOCUS) {
      newSession.EndSessionKeepAllTabs = sessionKeepAllTabs;
      newSession.EndSessionRestoreOriginal = sessionRestoreOriginal;
    }

    if (isGroupLockType) {
      // remove the group from the final list
      newSession.Groups = _.difference(sessionRecipientGroups, [entityId]);

      // get list of students in the remaining groups
      let studentsToStay = [];
      _.forEach(newSession.Groups, (groupId) => {
        studentsToStay = _.union(
          studentsToStay,
          getGroupParticipantsIds(groupId)
        );
      });

      // get list of students to be removed
      const studentsToBeRemoved = _.difference(
        getGroupParticipantsIds(entityId),
        studentsToStay
      );

      // remove students from the final list
      newSession.StudentIDS = _.difference(
        sessionRecipientStudents,
        studentsToBeRemoved
      );
    } else {
      // remove the student from the final list
      newSession.StudentIDS = _.difference(sessionRecipientStudents, [
        entityId,
      ]);
    }

    turnOffReleaseStateForAnEntity(entityId);

    onSessionUpdate(newSession);
  };

  const handleEnterPressInSearch = () => {
    if (entityToAdd) {
      handleAddEntity();
    }
  };

  const turnOnReleaseStateForAnEntity = (entityId) => {
    setEntityReleaseState(
      Object.assign({}, entityReleaseState, { [entityId]: true })
    );
  };

  const turnOffReleaseStateForAnEntity = (entityId) => {
    setEntityReleaseState(
      Object.assign({}, entityReleaseState, { [entityId]: false })
    );
  };

  const entityList = isGroupLockType
    ? sessionRecipientGroups
    : sessionRecipientStudents;
  const allEntityLength = isGroupLockType ? userGroups.length : students.length;
  const dataTestIdPrefix = isGroupLockType ? "EditGroup" : "EditStudent";

  const isAddEntityVisible = entityList.length < allEntityLength;

  return (
    <div className={styles.root}>
      <div
        className={classnames([
          styles.list,
          { [styles.listExtended]: !isAddEntityVisible },
        ])}
      >
        {entityList.length > 0 &&
          _.map(entityList, (entityId) => {
            const entity = isGroupLockType
              ? _.find(userGroups, (group) => group.URN === entityId)
              : _.find(students, (student) => student.Id === entityId);
            const displayName = isGroupLockType
              ? _.get(entity, "name", "Unknown Group")
              : getFormattedName(nameFormat, entity) || "Unknown Student";
            const isOffline = isGroupLockType
              ? false
              : !_.get(
                  entity,
                  `instances.${_.get(entity, "currentInstance")}.isOnline`
                );

            return (
              <div
                className={classnames([
                  styles.item,
                  {
                    [styles.itemOffline]: isOffline,
                    [styles.itemHighlighted]: entityReleaseState[entityId],
                  },
                ])}
                key={entityId}
              >
                {isGroupLockType && !entityReleaseState[entityId] && entity && (
                  <div
                    className={classnames([
                      styles.itemColorDot,
                      styles[entity.color],
                    ])}
                  />
                )}
                <div className={styles.itemName}>
                  {!entityReleaseState[entityId] && (
                    <React.Fragment>
                      {/* eslint-disable formatjs/no-literal-string-in-jsx */}
                      {displayName}{" "}
                      {isOffline && (
                        <span className={styles.itemNameSuffix}>
                          {" "}
                          <FormattedMessage
                            defaultMessage="(offline)"
                            id="bLm0E3"
                          />
                        </span>
                      )}
                      {/* eslint-enable formatjs/no-literal-string-in-jsx */}
                    </React.Fragment>
                  )}
                  {entityReleaseState[entityId] && (
                    <React.Fragment>
                      <FormattedMessage
                        defaultMessage="Release {displayName} from this session?"
                        id="cV4tVm"
                        values={{ displayName }}
                      />
                    </React.Fragment>
                  )}
                </div>
                {!isOffline &&
                  entityList.length > 1 &&
                  entity && ( //entity  can be null when group was deleted after session was scheduled but before it was executed
                    <React.Fragment>
                      {!entityReleaseState[entityId] && (
                        <Button
                          onAction={() =>
                            turnOnReleaseStateForAnEntity(entityId)
                          }
                          size={BUTTON_SIZES.XSMALL}
                          type={BUTTON_TYPES.TERTIARY}
                          icon="cross"
                          dataTestId={`Hl-EditActiveSession-${dataTestIdPrefix}-OpenRemoveConfirmation`}
                          aria-label={intl.formatMessage({
                            defaultMessage:
                              "Release {displayName} from this session",
                            values: { displayName },
                            id: "C6d/+q",
                          })}
                        />
                      )}
                      {entityReleaseState[entityId] && (
                        <React.Fragment>
                          <Button
                            onAction={() =>
                              turnOffReleaseStateForAnEntity(entityId)
                            }
                            size={BUTTON_SIZES.XSMALL}
                            type={BUTTON_TYPES.OUTLINED}
                            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                            label={intl.formatMessage({
                              defaultMessage: "No",
                              id: "oUWADl",
                            })}
                            aria-label={intl.formatMessage({
                              defaultMessage:
                                "No, I don't want to release {displayName}",
                              values: { displayName },
                              id: "unKeQo",
                            })}
                            aria0label
                            className={styles.itemButton}
                            dataTestId={`Hl-EditActiveSession-${dataTestIdPrefix}-CloseRemoveConfirmation`}
                          />
                          <Button
                            onAction={() => handleRemoveEntity(entityId)}
                            size={BUTTON_SIZES.XSMALL}
                            type={BUTTON_TYPES.DANGER}
                            label={intl.formatMessage({
                              defaultMessage: "Yes",
                              id: "a5msuh",
                            })}
                            aria-label={intl.formatMessage({
                              defaultMessage: "Yes, release {displayName}",
                              id: "CYkU+W",
                              values: { displayName },
                            })}
                            className={styles.itemButton}
                            dataTestId={`Hl-EditActiveSession-${dataTestIdPrefix}-Remove`}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
              </div>
            );
          })}
      </div>
      {isAddEntityVisible && (
        <div className={styles.addAnItem}>
          <SearchWithDropdown
            placeholder={
              isGroupLockType
                ? intl.formatMessage({
                    defaultMessage: "Enter group name",
                    id: "e88+CD",
                  })
                : intl.formatMessage({
                    defaultMessage: "Enter student's name",
                    id: "QIYcyj",
                  })
            }
            itemsForDD={searchResults}
            onChange={debouncedHandleSearchTermChange}
            onSelect={handleSearchResultSelect}
            onClear={handleSearchTermClear}
            onEnter={handleEnterPressInSearch}
            forceClearSearchTerm={forceClearSearchTerm}
            dataTestIdPrefix={`Hl-EditActiveSession-${dataTestIdPrefix}`}
          />
          <Button
            className={styles.addAnItemButton}
            onAction={() => handleAddEntity()}
            label={
              isGroupLockType
                ? intl.formatMessage({
                    defaultMessage: "Add group",
                    id: "RZJPnT",
                  })
                : intl.formatMessage({
                    defaultMessage: "Add student",
                    id: "2v/Wk3",
                  })
            }
            size={BUTTON_SIZES.XSMALL}
            isDisabled={!entityToAdd}
            dataTestId={`Hl-EditActiveSession-${dataTestIdPrefix}-Add`}
          />
        </div>
      )}
    </div>
  );
};

FocusedSessionListWho.propTypes = {
  sessionId: PropTypes.string.isRequired,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  sessionKeepAllTabs: PropTypes.bool.isRequired,
  sessionRestoreOriginal: PropTypes.bool.isRequired,
  sessionLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES))
    .isRequired,
  sessionRecipientGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  sessionRecipientStudents: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSessionUpdate: PropTypes.func.isRequired,
  // connected props
  nameFormat: PropTypes.string,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  getGroupStatusInfo: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    nameFormat: getUserNameFormat(state),
    students: getStudentList(state),
    userGroups: getUserGroups(state),
    getGroupStatusInfo: (group) => getGroupStatusInfo(group)(state),
  }),
  (dispatch) => ({})
)(FocusedSessionListWho);
