import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./AssignCollectionsModal.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

const AssignCollectionModalError = () => {
  return (
    <div className={styles.modalBodyContainer}>
      <div className={styles.container}>
        <div className={styles.errorText}>
          <span>
            <HapReactIcon
              svg="fill-circle-exclamation"
              className={styles.errorIcon}
              width={16}
              height={16}
              alt=""
            />
            Trouble loading collections.
          </span>
          <p>
            <FormattedMessage
              defaultMessage="Please check your connection and try again."
              id="ld995y"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssignCollectionModalError;
