import { history } from "../../../../state/store";
import { APP_PAGES } from "../../../../state/router/types";

interface openCardModalProps {
  boardId: string;
  cardId: string;
  sectionId?: string;
  columnType?: string;
  afterCardId?: string | null;
  beforeCardId?: string | null;
}

export const openCardModal = ({
  boardId,
  cardId,
  sectionId,
  columnType,
  afterCardId,
  beforeCardId,
}: openCardModalProps) => {
  history.push({
    pathname: `${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}/c/${cardId}`,
    search: "",
    state: {
      sectionId: sectionId,
      columnType: columnType,
      afterCardId: afterCardId,
      beforeCardId: beforeCardId,
    },
  });
};

interface closeCardModalProps {
  boardId: string;
}

export const closeCardModal = ({ boardId }: closeCardModalProps) => {
  history.push({
    pathname: `${APP_PAGES.WORKSPACE_BOARDS.path}/${boardId}`,
    search: "",
  });
};
