import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./GroupsManagementPanel.module.scss";
import { getClassURN } from "../../../../state/highlights/config/selectors";
import { getUserNameFormat } from "../../../../state/user/selectors";
import {
  STUDENT_TYPE,
  STUDENT_TYPE_OLD,
} from "../../../../state/highlights/students/types";
import { UPREF_NAME_FIRST_LAST } from "../../../../state/user/types";
import { USER_GROUP_PREFS_TYPE } from "../../../../state/shared/userGroups/types";
import { userGroupsHideManagementPanel } from "../../../../state/shared/userGroups/actions";
import {
  getUserGroupsManagementPanelSelectedStudent,
  getUserGroupsManagementPanelState,
  getUserGroupsManagementPanelContentType,
} from "../../../../state/shared/userGroups/selectors";
import GroupsManagementBlock from "@hapara/ui/src/components/groupsManagementBlock/groupsManagementBlock";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import useGroups from "../../../../hooks/useGroups";

const GroupsManagementPanel = ({
  classURN = "",
  showUserGroupsManagementPanel = false,
  userGroupsHideManagementPanel,
  panelContentType = "",
  panelSelectedStudent = {
    email: "",
  },
  nameFormat,
  students,
  tabName,
  pageType,
}) => {
  const { data, operations } = useGroups({ pageType, selectedView: tabName });
  const { userGroups, userGroupsViewType } = data;
  const {
    addUserGroup,
    updateUserGroup,
    saveUserGroupsPrefs,
    deleteUserGroup,
    addUserToGroup,
    updateStudentUserGroups,
    removeUserFromGroup,
  } = operations;
  // 'CB_1_{classUrn}' - hardcoded BE value, used for "Highlights->Browser Tabs" view id
  // - for Dataloaded classes the part after '@' symbol should be dropped
  // - for GC synced or manually created classes, with ID started with 'urn:wg::', the whole ID should be used
  const viewId = tabName
    ? tabName
    : _.startsWith(classURN, "urn:wg::")
    ? `CB_1_${classURN}`
    : `CB_1_${classURN.split("@")[0]}`;

  const viewsList = [
    {
      id: viewId,
    },
  ];

  const handleAddGroup = (group) => {
    return addUserGroup({
      Name: group.name,
      WorkgroupId: classURN,
      Key: group.id,
      ViewId:
        userGroupsViewType === USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
          ? viewId
          : null,
      Colour: group.colourCode,
      Participants: [],
    });
  };

  const handleGroupUpdate = (
    groupURN,
    colourCode,
    title,
    colourChanged,
    titleChanged
  ) => {
    const groupUpdate = {
      Colour: colourCode,
      DisplayName: title,
    };
    return updateUserGroup(classURN, groupURN, groupUpdate);
  };

  const handlePrefsSave = (option) => {
    const userGroupPrefs = option
      ? USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
      : USER_GROUP_PREFS_TYPE.SHARED_ACROSS_TABS;
    return saveUserGroupsPrefs(classURN, userGroupPrefs);
  };

  const handleDeleteUserGroup = (group) => {
    return deleteUserGroup(classURN, group.urn);
  };

  const handleAddStudentToGroup = (groupUrn, students) => {
    const studEmails = students.map((s) => s.email);
    const groupUpdate = {
      EmailAddresses: studEmails,
    };
    return addUserToGroup(classURN, groupUrn, groupUpdate);
  };

  const handleAddStudentToMultipleGroups = (studentEmail, groups) => {
    const addTo = [];
    const deleteFrom = [];
    groups.forEach((group) => {
      if (group.selected && !group.emails.includes(studentEmail)) {
        // selected but doesn't contain email so it's new selection
        addTo.push(group.urn);
      }

      if (!group.selected && group.emails.includes(studentEmail)) {
        // if not selected but email - remove
        deleteFrom.push(group.urn);
      }
    });
    const groupUpdate = {
      AddGroups: addTo,
      RemoveGroups: deleteFrom,
      UserEmail: studentEmail,
    };
    return updateStudentUserGroups(classURN, groupUpdate);
  };

  const handleRemoveStudentFromGroup = (studEmail, groupUrn) => {
    const groupUpdate = {
      EmailAddresses: [studEmail],
    };
    return removeUserFromGroup(classURN, groupUrn, groupUpdate);
  };

  const actions = {
    handleDeleteGroup: handleDeleteUserGroup,
    handleDeleteStudent: handleRemoveStudentFromGroup,
    saveUpdatedGroup: handleGroupUpdate,
    onCloseManager: userGroupsHideManagementPanel,
    handlePreferenceSave: handlePrefsSave,
    onStudentsAdd: handleAddStudentToGroup,
    handleAddGroup: handleAddGroup,
    handleAddToMultipleGroups: handleAddStudentToMultipleGroups,
    sendProductEvent: () => {},
  };

  if (!userGroups) {
    return null;
  }

  const formattedGroups = userGroups.map((ug) => {
    return {
      name: ug.name,
      urn: ug?.URN,
      color: ug.color,
      colourCode: ug.color,
      emails: ug.participants,
    };
  });

  let studentsFormatted = _.map(students, (s) => {
    return {
      email: s.Email,
      name: s.FirstName,
      lastname: s.LastName,
    };
  });
  studentsFormatted = _.keyBy(studentsFormatted, "email");

  const nameFormatOrder =
    nameFormat === UPREF_NAME_FIRST_LAST ? "fname" : "lname";

  return (
    showUserGroupsManagementPanel &&
    classURN && (
      <div className={styles.root}>
        <GroupsManagementBlock
          tabName={tabName}
          groups={formattedGroups}
          order={nameFormatOrder}
          placement="right"
          actions={actions}
          classId={classURN}
          contentType={panelContentType ? panelContentType : ""}
          students={studentsFormatted}
          views={viewsList}
          viewId={viewId}
          selectedStudent={
            _.get(panelSelectedStudent, "email") !== ""
              ? panelSelectedStudent
              : null
          }
          preferences={
            userGroupsViewType === USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
          }
        />
      </div>
    )
  );
};

GroupsManagementPanel.propTypes = {
  classURN: PropTypes.string,
  showUserGroupsManagementPanel: PropTypes.bool,
  userGroupsHideManagementPanel: PropTypes.func.isRequired,
  panelContentType: PropTypes.string,
  panelSelectedStudent: STUDENT_TYPE_OLD,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  tabName: PropTypes.string,
  pageType: PropTypes.string,
};

export default connect(
  (state) => ({
    classURN: getClassURN(state),
    showUserGroupsManagementPanel: getUserGroupsManagementPanelState(state),
    panelContentType: getUserGroupsManagementPanelContentType(state),
    panelSelectedStudent: getUserGroupsManagementPanelSelectedStudent(state),
    nameFormat: getUserNameFormat(state),
    students: getStudentConfigsList(state),
  }),
  (dispatch) => ({
    userGroupsHideManagementPanel: () =>
      dispatch(userGroupsHideManagementPanel()),
  })
)(GroupsManagementPanel);
