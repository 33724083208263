import type { ChatMessage, MessageGenerated } from "../types";
import { getCurrentDateTime } from "./getCurrentDateTime";
import { v4 as uuidv4 } from "uuid";
import packageJson from "../../package.json";

export function createMessage<T>(
  message: T extends Omit<ChatMessage, keyof MessageGenerated> ? T : never
): ChatMessage {
  return {
    ...message,
    id: uuidv4(),
    timestamp: getCurrentDateTime(),
    version: packageJson.version,
  } as ChatMessage;
}
