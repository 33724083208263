import _ from "lodash";
import actionTypes from "../../../actionTypes";

import { MYWORKSPACES_PAGE_SIZE, EVIDENCE_CATEGORIES } from "./types";

export const INIT_STATE = {
  actionError: false,
  actionInProgress: false,
  activeLoading: false,
  copy: {
    modalVisible: false,
    ws: {
      id: "",
      title: "",
    },
  },
  currentWSCount: 0,
  selectedClassName: "",
  items: null,
  leftSidePanelOpen: false,
  rightSidePanelOpen: false,
  listLoaded: false,
  loading: true,
  pageSize: MYWORKSPACES_PAGE_SIZE,
  pagesCount: 0,
  filterCategoryType: null,
};

const updateItems = (items, action, workspace) => {
  switch (action) {
    case "replace":
      return items.map((item) => (item.Id === workspace.Id ? workspace : item));
    case "add":
      return [workspace].concat(items);
    case "delete":
      return items.filter((item) => item.Id !== workspace.Id);
    default:
      throw new Error("unsupported action");
  }
};

const updateItemsActiveItem = (items, id, payload = {}) => {
  if (id && !_.isEmpty(items)) {
    const activeItemIndex = _.findIndex(items, (item) => item.Id === id);
    if (activeItemIndex !== -1) {
      items[activeItemIndex].Groups = _.get(payload, "Groups", []);
      items[activeItemIndex].Sections = _.get(payload, "Sections", []);
    }
  }

  return items;
};

const updateItemsActiveArtifact = (
  items,
  activeItemId,
  activeActivityType,
  activeCardId,
  artifactId,
  mimeType
) => {
  if (artifactId && mimeType && !_.isEmpty(items)) {
    const activeItem = _.find(items, (item) => item.Id === activeItemId);
    const activeGroup = _.find(
      EVIDENCE_CATEGORIES,
      (category) => category.activityType === activeActivityType
    );
    let activeCard;
    let activeStudents;
    let activeStudent;

    if (activeItem && activeGroup) {
      _.map(activeItem.Sections, (section) => {
        if (!activeCard) {
          activeCard = _.find(
            section.Cards,
            (card) => card.Id === activeCardId
          );
        }
      });

      if (activeCard) {
        activeStudents = _.get(
          activeCard,
          `WorkspaceProgressSummary.${activeGroup.id}.StudentsProgress`
        );

        if (activeStudents) {
          activeStudent = _.find(
            activeStudents,
            (student) => student.Artifact && student.Artifact.Id === artifactId
          );

          if (activeStudent) {
            activeStudent.Artifact.MimeType = mimeType;
          }
        }
      }
    }
  }
  return items;
};

const listReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.MYWORKSPACES_DISMISS_ERROR:
      return { ...state, actionError: false, actionInProgress: false };

    case actionTypes.MYWORKSPACES_ACTION_PENDING:
      return { ...state, actionInProgress: true, actionError: false };

    case actionTypes.MYWORKSPACES_ACTION_SUCCESS:
      return {
        ...state,
        actionInProgress: false,
        items: updateItems(state.items, action.action, action.workspace),
      };

    case actionTypes.MYWORKSPACES_ACTION_ERROR:
      return { ...state, actionInProgress: false, actionError: true };

    case actionTypes.MYWORKSPACES_LOAD_SUCCESS:
      return {
        ...state,
        items: action.items,
        currentWSCount: action.wsCount,
        selectedClassName: action.selectedClassName,
        pagesCount: action.pagesCount,
        loading: false,
        listLoaded: true,
      };

    case actionTypes.MYWORKSPACES_LOAD_PENDING:
      return { ...state, loading: true, listLoaded: false };

    case actionTypes.MYWORKSPACES_LOAD_ERROR:
      return { ...state, loading: false, items: [], listLoaded: true };

    case actionTypes.MYWORKSPACES_CLEAR:
      return INIT_STATE;

    case actionTypes.MYWORKSPACES_LEFT_PANEL_UPDATE:
      return { ...state, leftSidePanelOpen: action.payload };

    case actionTypes.MYWORKSPACES_RIGHT_PANEL_UPDATE:
      return { ...state, rightSidePanelOpen: action.payload };

    case actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL:
      const selectedInd = _.findIndex(state.items, {
        Id: action.payload.boardId,
      });
      const updatedBoard = {
        ...state.items[selectedInd],
        BoardThumbnail: action.payload.url,
        BoardThumbnailAlt: action.payload.alt,
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, selectedInd),
          updatedBoard,
          ...state.items.slice(selectedInd + 1),
        ],
      };

    case actionTypes.URL_PARAM_UPDATE_CATEGORY:
      return {
        ...state,
        filterCategoryType: action.param,
      };

    case actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_SUCCESS: {
      const items = updateItemsActiveItem(
        _.cloneDeep(state.items),
        action.id,
        action.payload
      );

      return {
        ...state,
        items,
        activeLoading: false,
      };
    }

    case actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_PENDING:
      return { ...state, activeLoading: true };

    case actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_ERROR:
      return { ...state, activeLoading: false };

    case actionTypes.MYWORKSPACES_ARTIFACT_UPDATE_MIMETYPE:
      const items = updateItemsActiveArtifact(
        _.cloneDeep(state.items),
        action.activeItemId,
        action.activeActivityType,
        action.activeCardId,
        action.artifactId,
        action.mimeType
      );

      return { ...state, items };

    default:
      return state;
  }
};

export default listReducer;
