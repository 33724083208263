import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "@hapara/ui/src/atomic/Alert";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import styles from "../CloseTabModal.module.scss";
import { StudentBrowserTabType } from "../types";
import { useIntl } from "react-intl";
import { DurationInput } from "../../Highlights/DurationInput";
import { getFFByName } from "../../../../state/app/selectors";

type BlockForClassProps = {
  tab: StudentBrowserTabType;
  duration: number | null;
  dataTestIdPrefix: string;
  activeClassFilterSession: boolean;
  activeOtherSession: boolean;
  onDurationChange: (value: number) => void;
};

export const BlockForClass = ({
  tab,
  duration,
  activeClassFilterSession,
  activeOtherSession,
  onDurationChange,
  dataTestIdPrefix,
}: BlockForClassProps) => {
  const intl = useIntl();
  const hasExtendedOptions = useSelector(
    getFFByName("GBE-03-extended-duration-options")
  );
  return (
    <>
      <Heading level={2} styledAs="h5" removeTopMargin>
        {intl.formatMessage({ defaultMessage: "Site to block", id: "QUSVfb" })}
      </Heading>
      <div className={styles.linkWrapper}>
        <ExternalLink
          url={tab.url}
          title={tab.title}
          icon={tab.icon}
          className={styles.link}
          dataTestId={`${dataTestIdPrefix}-link`}
        />
      </div>
      {!activeClassFilterSession && (
        <div className={styles.durationContainer}>
          <Heading level={2} styledAs="h5" removeTopMargin>
            {intl.formatMessage({
              defaultMessage: "How long to block access?",
              id: "3ZGCtI",
            })}
          </Heading>
          <DurationInput
            hasExtendedOptions={hasExtendedOptions}
            duration={duration}
            onDurationChange={onDurationChange}
            dataTestPrefix={dataTestIdPrefix}
          />
        </div>
      )}
      {activeClassFilterSession && (
        <Alert type="info">
          {intl.formatMessage({
            defaultMessage:
              "This site will be added to a Filter Session that is already active for this class",
            id: "uL4Oc1",
          })}
        </Alert>
      )}
      {activeOtherSession && (
        <Alert type="warning">
          {intl.formatMessage({
            defaultMessage:
              "Proceeding will override active Guided Sessions that were started for individual students or groups.",
            id: "HY++aj",
          })}
        </Alert>
      )}
    </>
  );
};
