import { useEffect } from "react";

export const useLockBodyScroll = (isActive: boolean) => {
  useEffect(() => {
    const initialDocumentOverflow = window.getComputedStyle(
      document.documentElement
    ).overflow;
    const initialBodyOverflow = window.getComputedStyle(document.body).overflow;

    const setOverflow = (value: string) => {
      document.body.style.overflow = value;
      document.documentElement.style.overflow = value;
    };

    const resetOverflow = () => {
      document.documentElement.style.overflow = initialDocumentOverflow;
      document.body.style.overflow = initialBodyOverflow;
    };

    if (isActive) {
      setOverflow("hidden");
    } else {
      resetOverflow();
    }

    return () => {
      resetOverflow();
    };
  }, [isActive]);
};
