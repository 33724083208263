import React from "react";
import { useIntl } from "react-intl";
import ModalDeprecated from "../../deprecated/ModalDeprecated/ModalDeprecated";
import Button, { BUTTON_TYPES } from "../Button/Button";
import styles from "./ActionConfirmationDialog.module.scss";
import PropTypes from "prop-types";
import WarningMessage from "../WarningMessage/WarningMessage";

/** @deprecated
 * - This component has been deprecated in favour of DialogModal component: packages/ui/src/atomic/DialogModal/DialogModal.tsx
 * */
const ActionConfirmationDialog = ({
  title,
  subTitle,
  actionLabel,
  cancelLabel,
  isCancelDisabled = false,
  children,
  onAction,
  isActionInProgress = false,
  isOpen = false,
  onClose = () => {},
  error,
  warning,
  isActionDanger = false,
  isActionButtonDisabled = false,
  cancelDataTestId,
  actionDataTestId,
  dataTestPrefix,
  content,
  rightIcon,
}) => {
  const intl = useIntl();
  const defaultCancelLabel = intl.formatMessage({
    defaultMessage: "Cancel",
    id: "47FYwb",
  });

  const dataTestPrefixProp = dataTestPrefix
    ? { dataTestPrefix: dataTestPrefix }
    : {};

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      classNameHeader={styles.title}
      className={styles.fixedWidth}
      {...dataTestPrefixProp}
    >
      <div className={styles.dialog}>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        {content && <p className={styles.content}>{content}</p>}
        {children && <div className={styles.content}>{children}</div>}
        {warning && !error && (
          <WarningMessage isVisible={true} className={styles.warning}>
            {warning}
          </WarningMessage>
        )}
        {error && (
          <div className={styles.error}>
            <div className={styles.text}>{error}</div>
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            label={cancelLabel ?? defaultCancelLabel}
            type={BUTTON_TYPES.SECONDARY}
            onAction={isCancelDisabled ? () => {} : onClose}
            isDisabled={isCancelDisabled}
            dataTestId={cancelDataTestId}
          />
          <Button
            label={actionLabel}
            type={isActionDanger ? BUTTON_TYPES.DANGER : BUTTON_TYPES.PRIMARY}
            onAction={isActionInProgress ? () => {} : onAction}
            isLoading={isActionInProgress}
            dataTestId={actionDataTestId}
            isDisabled={isActionButtonDisabled}
            rightIcon={rightIcon}
          />
        </div>
      </div>
    </ModalDeprecated>
  );
};

ActionConfirmationDialog.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  cancelLabel: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  isActionInProgress: PropTypes.bool,
  isActionDanger: PropTypes.bool,
  isActionButtonDisabled: PropTypes.bool,
  error: PropTypes.string,
  warning: PropTypes.string,
  onClose: PropTypes.func,
  onAction: PropTypes.func.isRequired,
  cancelDataTestId: PropTypes.string,
  actionDataTestId: PropTypes.string,
  dataTestPrefix: PropTypes.string,
  content: PropTypes.node,
  rightIcon: PropTypes.string,
};

export default ActionConfirmationDialog;
