import React, { useRef } from "react";
import styles from "./DatePickerField.module.scss";
import classNames from "classnames";
import { CalendarIcon } from "../../../icons/CalendarIcon";
import { ChevronDownIcon } from "../../../icons/ChevronDownIcon";
import { formatDateTimeLocal } from "../../../intl/dateTime/formatDateTimeLocal";
import { TextField } from "../../TextField";

type DatePickerFieldProps = {
  value: string;
  label?: string;
  minDate?: string;
  maxDate?: string;
  isRequired?: boolean;
  errorMessage?: string;
  placeholder?: string;
  hintText?: string;
  className?: string;
  onSelectDate: (selectedDate: string) => void;
};

export const DatePickerField = ({
  value,
  label,
  minDate,
  maxDate,
  isRequired,
  placeholder,
  errorMessage,
  hintText,
  className,
  onSelectDate,
}: DatePickerFieldProps) => {
  const dateInputRef = useRef<HTMLInputElement>(null);

  const dateFormattedToLocale = value
    ? formatDateTimeLocal(value, "dateOnly")
    : "";

  const handleTextInputFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const noop = () => {};

  return (
    <div className={classNames(styles.container, className)}>
      <TextField
        value={dateFormattedToLocale ? dateFormattedToLocale : value}
        label={label}
        placeholder={placeholder}
        hintText={hintText}
        hasError={!!errorMessage}
        isRequired={isRequired}
        errorMessage={errorMessage}
        onFocus={handleTextInputFocus}
        className={styles.dateInput}
        onChangeText={noop}
        dataTestId="date-picker"
        leftAddon={
          <div
            onClick={handleTextInputFocus}
            className={classNames(styles.iconWrapper, styles.left)}
          >
            <CalendarIcon />
          </div>
        }
        rightAddon={
          <div
            onClick={handleTextInputFocus}
            className={classNames(styles.iconWrapper, styles.right)}
          >
            <ChevronDownIcon />
          </div>
        }
      />
      <input
        type="date"
        value={value}
        onChange={(e) => onSelectDate(e.currentTarget.value)}
        ref={dateInputRef}
        className={styles.nativeDateInput}
        data-test-id="native-date-input"
        min={minDate}
        max={maxDate}
      />
    </div>
  );
};
