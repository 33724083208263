import _ from "lodash";
import { createSelector } from "reselect";

export const getNotification = (state) =>
  _.get(state, "highlights.notification", []);

export const getNotificationSession = createSelector(
  getNotification,
  (notificationInfo) => _.get(notificationInfo, "session")
);

export const getNotificationData = createSelector(
  getNotification,
  (notificationInfo) => _.get(notificationInfo, "data")
);
