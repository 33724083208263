import React from "react";
import styles from "./Drawer.module.scss";
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";
import { Overlay } from "../../atomic/Overlay";
import { useKeyPress } from "../../hooks/useKeyPress";
import { FocusTrap } from "../FocusTrap";

type DrawerProps = {
  isOpen: boolean;
  onRequestClose?(): void;
  children?: React.ReactNode;
  dataTestIdPrefix?: string;
  /** Default `max-width` is set to 648px */
  maxWidth?: number;
};

export const Drawer = ({
  onRequestClose,
  isOpen,
  children,
  dataTestIdPrefix,
  maxWidth = 648,
}: DrawerProps) => {
  useLockBodyScroll(isOpen);

  const handleRequestClose = () => {
    onRequestClose?.();
  };

  useKeyPress("Escape", handleRequestClose);

  return isOpen ? (
    <FocusTrap dataTestIdPrefix={dataTestIdPrefix}>
      <div className={styles.drawer}>
        <Overlay
          dataTestIdPrefix={dataTestIdPrefix}
          onClick={handleRequestClose}
        />
        <div className={styles.content} style={{ maxWidth }}>
          {isOpen ? children : null}
        </div>
      </div>
    </FocusTrap>
  ) : null;
};
