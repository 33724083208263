import React from "react";
import PropTypes from "prop-types";
import styles from "./UploadPage.module.scss";
import { connect } from "react-redux";
import { APP_PAGES } from "../../../../state/router/types";
import {
  getIsConfigError,
  getIsConfigLoaded,
  hasResourceUploadAccess,
} from "../../../../state/library/config/selectors";
import { hasWSAccess } from "../../../../state/user/selectors";
import Header from "../../../partial/Header/Header";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import Page from "../../../partial/PageContainer/PageContainer";
import UploadForm from "../../../partial/Library/UploadForm/UploadForm";
import { MAIN_CONTENT_ID } from "../../../../consts";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import ConfigHandler from "../ConfigHandler";

const UploadPage = ({
  hasWSAccess,
  hasResourceUploadAccess,
  isConfigLoaded,
  isConfigError,
}) => {
  const headerToShow = hasWSAccess ? (
    <Header />
  ) : (
    <HeaderAccountOnly rootPageLink={APP_PAGES.MY_RESOURCES_STARRED.path} />
  );

  return (
    <>
      <ConfigHandler />
      <Page
        header={headerToShow}
        title={APP_PAGES.LIBRARY_UPLOAD.title}
        isPageLoaded={isConfigLoaded}
        isPageLoadingError={isConfigError}
      >
        {!hasResourceUploadAccess && <NoAccessPage />}
        {hasResourceUploadAccess && (
          <>
            <div>
              <LibrarySubMenu />
            </div>
            <main
              className={styles.root}
              data-test-id="lb-Pages-Library-UploadContent"
              id={MAIN_CONTENT_ID}
              tabIndex="-1"
            >
              <UploadForm />
            </main>
          </>
        )}
      </Page>
    </>
  );
};

UploadPage.propTypes = {
  hasWSAccess: PropTypes.bool.isRequired,
  hasResourceUploadAccess: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  hasWSAccess: hasWSAccess(state),
  hasResourceUploadAccess: hasResourceUploadAccess(state),
  isConfigLoaded: getIsConfigLoaded(state),
  isConfigError: getIsConfigError(state),
}))(UploadPage);
