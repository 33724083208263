import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Tippy from "@tippy.js/react";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { showResourceDetailsModal } from "../../../../state/resources/actions";
import {
  dropdownItemPropsType,
  RESOURCE_DETAILS_MODAL_MODE,
  resourceItemType,
} from "../../../../state/resources/types";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";

const ActionEditDetails = ({
  item,
  dataTestPrefix,
  isDropdownAction = false,
  dropdownItemProps,
  showResourceDetailsModal,
  buttonType = BUTTON_TYPES.SECONDARY,
  label = "Edit details",
  tooltipLabel,
}) => {
  const actionDataTestId = `${dataTestPrefix}-EditDetails-Button`;

  const handleActionOnClick = (e) => {
    e.stopPropagation();
    showResourceDetailsModal(item);
  };

  const actionButton = (
    <MemorisedFocusButton
      label={label}
      icon="pencil"
      type={buttonType}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      onClick={handleActionOnClick}
      data-test-id={actionDataTestId}
      isFullWidth={true}
    />
  );

  const dropdownButton = (
    <button
      type="button"
      data-test-id={actionDataTestId}
      {...dropdownItemProps}
      onClick={(e) => {
        handleActionOnClick(e);
        dropdownItemProps.onClick();
      }}
    >
      {label}
    </button>
  );

  return (
    <>
      {!isDropdownAction && tooltipLabel && (
        <Tippy content={tooltipLabel} theme="hsuite">
          {actionButton}
        </Tippy>
      )}

      {!isDropdownAction && !tooltipLabel && actionButton}

      {isDropdownAction && dropdownButton}
    </>
  );
};

ActionEditDetails.propTypes = {
  item: resourceItemType,
  dataTestPrefix: PropTypes.string.isRequired,
  isDropdownAction: PropTypes.bool,
  dropdownItemProps: dropdownItemPropsType,
  label: PropTypes.string,
  tooltipLabel: PropTypes.string,
  buttonType: PropTypes.oneOf(_.values(BUTTON_TYPES)),
  // connected
  showResourceDetailsModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showResourceDetailsModal: (item) =>
      dispatch(
        showResourceDetailsModal({
          item,
          mode: RESOURCE_DETAILS_MODAL_MODE.EDIT,
        })
      ),
  })
)(ActionEditDetails);
