import _ from "lodash";
import { createSelector } from "reselect";
import {
  getStudentsInSessionEmailList,
  getStudentsOfflineEmailList,
} from "../../highlights/students/selectors";

export const getGroupsInfo = (state) =>
  _.get(state, "highlights.userGroups", []);

export const getUserGroups = createSelector(getGroupsInfo, (userGroupsInfo) =>
  _.get(userGroupsInfo, "groupsList")
);

export const getUserGroupsViewType = createSelector(
  getGroupsInfo,
  (userGroupsInfo) => _.get(userGroupsInfo, "viewType")
);

export const getUserGroupsManagementPanelState = createSelector(
  getGroupsInfo,
  (userGroupsInfo) => _.get(userGroupsInfo, "showManagementPanel")
);

export const getCheckedUserGroups = createSelector(getUserGroups, (groups) =>
  groups && groups.length > 0 ? groups.filter((g) => g.checked) : []
);

export const getUserGroupsManagementPanelContentType = createSelector(
  getGroupsInfo,
  (userGroupsInfo) => _.get(userGroupsInfo, "managementPanelContentType")
);

export const getUserGroupsManagementPanelSelectedStudent = createSelector(
  getGroupsInfo,
  (userGroupsInfo) => _.get(userGroupsInfo, "managementPanelSelectedStudent")
);

export const getGroupStatusInfo = (group) =>
  createSelector(
    getStudentsInSessionEmailList,
    getStudentsOfflineEmailList,
    (allLockedStudentEmailList, allOfflineStudentEmailList) => {
      const groupParticipants = _.get(group, "participants", []);

      const lockedAmount = _.get(
        _.intersection(allLockedStudentEmailList, groupParticipants),
        "length",
        0
      );
      const offlineAmount = _.get(
        _.intersection(allOfflineStudentEmailList, groupParticipants),
        "length",
        0
      );
      const totalAmount = groupParticipants.length;

      const activeMessage = lockedAmount
        ? `${
            lockedAmount === totalAmount
              ? "all"
              : `${lockedAmount}/${totalAmount}`
          } active in a session`
        : "";
      const offlineMessage = offlineAmount
        ? `${
            offlineAmount === totalAmount
              ? "all"
              : `${offlineAmount}/${totalAmount}`
          } offline`
        : "";

      return {
        areAllLocked: lockedAmount > 0 && lockedAmount === totalAmount,
        areAllOffline: offlineAmount > 0 && offlineAmount === totalAmount,
        message: `${activeMessage}${
          activeMessage && offlineMessage ? " • " : ""
        }${offlineMessage}`,
      };
    }
  );
