import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = () => {
  return <div data-test-id="ui-Portal" id="ui-Portal-root" />;
};

const PortalContent = ({ content }: { content: ReactNode }) => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const portalRoot = document.getElementById("ui-Portal-root");
    if (!portalRoot) {
      throw new Error("Portal root is not rendered.");
    }
    setPortalElement(portalRoot);
  }, []);

  if (!portalElement) {
    return null;
  }

  return createPortal(content, portalElement);
};

export const renderPortal = (content: ReactNode) => {
  return <PortalContent content={content} />;
};
