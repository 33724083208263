import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./ShareTile.module.scss";
import classnames from "classnames";
import HapReactIcon from "../../../../atomic/icon/hapReactIcon";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../../../atomic/Button/Button";
import Alert, { ALERT_TYPES } from "../../../../atomic/AlertDeprecated/Alert";
import { getPlural } from "../../../utils";
import { smartShareItemType } from "../types";
import { postRunUnshare } from "../../../../apiCalls/smartshare";

const SmartShareFakeTile = () => {
  return (
    <div className={styles.root} id="dummyTile">
      <div className={styles.dot}></div>
      <div className={styles.text}></div>
    </div>
  );
};

const ShareTile = ({
  data,
  onOpenRecentShareDialog,
  monitorShareUnshare = () => {},
}) => {
  const intl = useIntl();
  const [unshareDialog, setUnshareDialog] = useState(false);
  const [progressBarStyle, setProgressBarStyle] = useState({
    width: `${data.percentage * 100}%`,
  });
  const [isUndoError, setIsUndoError] = useState(false);
  const [buttonLoadingState, setButtonLoadingState] = useState(false);
  const [unshareInProgress, setUnshareInProgress] = useState(false);

  useEffect(() => {
    setProgressBarStyle({ width: `${data.percentage * 100}%` });
  }, [data.percentage, setProgressBarStyle]);

  const unshareHandler = async () => {
    setButtonLoadingState(true);
    setUnshareInProgress(true);
    try {
      await postRunUnshare(data.id);
      monitorShareUnshare();
      setIsUndoError(false);
      setUnshareDialog(false);
    } catch (e) {
      setIsUndoError(true);
    } finally {
      setButtonLoadingState(false);
    }
  };

  useEffect(() => {
    if (data.status.indexOf("undo") > -1) {
      setUnshareInProgress(false);
    }
  }, [data.status, setUnshareInProgress]);

  if (unshareInProgress) {
    return <SmartShareFakeTile />;
  }

  return (
    <>
      {unshareDialog && (
        <div
          className={classnames(styles.root, styles.unshareDialog)}
          data-test-id="common-SmartShare-Dropdown-Item-UnshareDialog"
        >
          <div className={styles.sharingText}>
            <div className={styles.unshareTitle}>
              Unshare {getPlural(data.documents.length, "file")}?
            </div>
            <div className={styles.notificationText}>
              <FormattedMessage
                defaultMessage="Students will no longer have access to these files. Any student work will be deleted."
                id="J52wuE"
              />
            </div>

            <Alert type={ALERT_TYPES.FAILURE} isVisible={isUndoError}>
              <FormattedMessage
                defaultMessage="Sorry! There was a problem unsharing. Please try again."
                id="ZHV2pl"
              />
            </Alert>
            <div className={styles.buttonsGroup}>
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "47FYwb",
                })}
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.SMALL}
                dataTestId="common-SmartShare-ReviewSession-UnShare-Cancel"
                onClick={() => setUnshareDialog(false)}
              />

              <Button
                label={intl.formatMessage({
                  defaultMessage: "Unshare",
                  id: "bEq+NW",
                })}
                type={BUTTON_TYPES.DANGER}
                size={BUTTON_SIZES.SMALL}
                isLoading={buttonLoadingState}
                isDisabled={buttonLoadingState}
                onClick={() => unshareHandler()}
                dataTestId="common-SmartShare-ReviewSession-UnShare-confirmed"
              />
            </div>
          </div>
        </div>
      )}
      {!unshareDialog && (
        <div
          className={classnames(styles.root, {
            [styles.highlighted]: data.highlighted,
          })}
        >
          <div
            className={styles.main}
            data-test-id="common-SmartShare-Dropdown-Item"
          >
            <div
              className={classnames(styles.icon, {
                [styles.green]: data.iconColourStyle === "green",
                [styles.grey]: data.iconColourStyle === "grey",
              })}
            >
              <HapReactIcon
                svg={data.icon}
                width={24}
                height={24}
                spin={data.spinIcon}
              />
            </div>
            <div className={styles.sharingText}>
              <span className={styles.shareTitle}>{data.shareText}</span>
              {data.errorText && (
                <span className={styles.errorText}>{data.errorText}</span>
              )}
            </div>
            {data.showUnshare && (
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Unshare",
                  id: "bEq+NW",
                })}
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.SMALL}
                onClick={() => setUnshareDialog(true)}
                dataTestId="common-SmartShare-ReviewSession-UndoShare"
              />
            )}
            <Button
              label={intl.formatMessage({
                defaultMessage: "Details",
                id: "Lv0zJu",
              })}
              type={BUTTON_TYPES.SECONDARY}
              size={BUTTON_SIZES.SMALL}
              onClick={() => {
                onOpenRecentShareDialog(data.id);
              }}
              dataTestId="common-SmartShare-ReviewSession-ViewDetails"
            />
          </div>
          {data.showProgressBar && (
            <div className={styles.progressBar}>
              <div
                className={classnames(styles.bar, {
                  [styles.whole]: data.percentage === 1,
                })}
                style={progressBarStyle}
              ></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ShareTile.propTypes = {
  data: smartShareItemType.isRequired,
  onOpenRecentShareDialog: PropTypes.func.isRequired,
  monitorShareUnshare: PropTypes.func,
};

export default ShareTile;
