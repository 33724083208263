import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../app/actions";
import actionTypes from "../../actionTypes";
import { AppDispatch } from "../../store";
import { fetchDashboardConfig } from "../../../apiCalls/dashboard";
import { DashboardConfig } from "../../../types/dashboard";
import { LoadConfigSuccessAction } from "./types";
import { ApiError } from "../../../apiCalls/types";

export const loadConfig = () => (dispatch: AppDispatch) => {
  dispatch({
    type: actionTypes.DASHBOARD_CONFIG_LOAD_PENDING,
  });
  dispatch(hideAppError());
  fetchDashboardConfig()
    .then((data: DashboardConfig) => {
      dispatch<LoadConfigSuccessAction>({
        type: actionTypes.DASHBOARD_CONFIG_LOAD_SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      } else {
        dispatch({
          type: actionTypes.DASHBOARD_CONFIG_LOAD_ERROR,
        });
        dispatch(showAppError(error, "api-error"));
      }
    });
};
