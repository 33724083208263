import { useEffect, useCallback, useRef } from "react";
import { useInView } from "react-intersection-observer";
import _ from "lodash";

const useScrollToHook = (isActive, scrollableElement) => {
  const [topRef, isContainerInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const containerRef = useRef();

  const scrollTo = useCallback(
    (yCoord) => {
      if (scrollableElement) {
        scrollableElement.scrollTo({
          top: yCoord,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    [scrollableElement]
  );

  useEffect(() => {
    if (
      !isContainerInView &&
      isActive &&
      containerRef &&
      containerRef.current
    ) {
      _.delay(scrollTo, 20, [containerRef.current.offsetTop]);
    }
  }, [isContainerInView, isActive, containerRef, scrollTo]);

  return { containerRef, topRef };
};

export default useScrollToHook;
