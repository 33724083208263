import { getUploadStatus } from "../../../apiCalls/library";
import _ from "lodash";
import { updateAccessError } from "../../app/actions";
import actionTypes from "../../actionTypes";

const dataConversion = (obj, status) => {
  return {
    ID: obj.ID,
    FileName: obj.FileName,
    InitiatedDate: obj.CreatedOn,
    Status: status,
    Error: obj.PubStatusMessage || null,
    CompletedDate: obj.EditedOn || null, // TODO: EditedOn is not the best one, but this is only we have for now
    ProgessDescription: obj.PubStatus || null,
  };
};

export const fetchUploadStatus = () => (dispatch) => {
  return getUploadStatus()
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      }

      const respObj = response.data;
      const completed = respObj.ready;
      const withError = respObj.error;
      const inProgress = respObj.wait;
      const result = [];
      if (withError && withError.length > 0) {
        result.push(...withError.map((r) => dataConversion(r, "error")));
      }
      if (completed && completed.length > 0) {
        result.push(...completed.map((r) => dataConversion(r, "completed")));
      }
      if (inProgress && inProgress.length > 0) {
        result.push(...inProgress.map((r) => dataConversion(r, "in-progress")));
      }
      return result;
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};

export const updateLastUploadedFileId = ({ id }) => ({
  type: actionTypes.LIBRARY_LAST_UPLOADED_FILE_UPDATE,
  payload: { id },
});
