import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ToolbarRefresh.module.scss";
import Tippy from "@tippy.js/react";
import { CONNECTION_SUCCESS_DELAY } from "../../../../state/highlights/HighlightsWebsocket";
import {
  getIsCurrentScreens,
  getIsClassViewPage,
} from "../../../../state/router/selectors";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { loadClassConfig } from "../../../../state/highlights/config/actions";
import { getClassId } from "../../../../state/shared/selectors";
import { useIntl } from "react-intl";

const ToolbarRefresh = ({
  isCurrentScreens,
  isClassViewPage,
  selectedClassId,
  loadClassConfig,
  dataTestIdPrefix,
}) => {
  const delayRef = useRef();
  const intl = useIntl();
  // clean up delay, if any, on unmount
  useEffect(() => {
    return () => {
      if (delayRef.current) {
        window.clearTimeout(delayRef.current);
      }
    };
  }, []);

  const [isInProgress, setIsInProgress] = useState(false);

  const refreshConnection = () => {
    // request new class config on user refresh
    setIsInProgress(true);
    loadClassConfig({
      classId: selectedClassId,
      shouldOpenWebsocketConnection: isCurrentScreens || isClassViewPage,
    });
    delayRef.current = _.delay(
      () => setIsInProgress(false),
      CONNECTION_SUCCESS_DELAY
    );
  };

  return (
    <div className={styles.root}>
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "Refresh",
          id: "rELDbB",
        })}
        theme="hsuite"
      >
        <Button
          icon="arrow-sync"
          size={BUTTON_SIZES.XSMALL}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id={`${dataTestIdPrefix}-Toolbar-Refresh-Trigger`}
          aria-label={intl.formatMessage({
            defaultMessage: "Refresh student tiles",
            id: "rutfbT",
          })}
          onClick={refreshConnection}
          isDisabled={isInProgress}
          isLoading={isInProgress}
        />
      </Tippy>
    </div>
  );
};

ToolbarRefresh.propTypes = {
  isCurrentScreens: PropTypes.bool.isRequired,
  isClassViewPage: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  loadClassConfig: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    isCurrentScreens: getIsCurrentScreens(state),
    isClassViewPage: getIsClassViewPage(state),
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({
    loadClassConfig: (options) => dispatch(loadClassConfig(options)),
  })
)(ToolbarRefresh);
