import React from "react";
import { Switch } from "../../../../atomic/Switch";
import { useIntl } from "react-intl";
import { Chip } from "../../../../atomic/Chip";
import styles from "./ChatSwitch.module.scss";

type ChatSwitchProps = {
  isEnabled: boolean;
  onClassStatusChange: (isEnabled: boolean) => void;
};

export const ChatSwitch = ({
  onClassStatusChange,
  isEnabled,
}: ChatSwitchProps) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      {/* To keep height of container consistent when hidden and inline style to appease tests. */}
      <div
        aria-hidden={!isEnabled}
        style={{ visibility: isEnabled ? "hidden" : undefined }}
      >
        <Chip
          type="warning"
          label={intl.formatMessage({
            defaultMessage: "Chat is off",
            id: "yAb1YH",
          })}
        />
      </div>
      <Switch
        dataTestIdPrefix="chat-toggle-class"
        isChecked={isEnabled}
        onCheckedChange={onClassStatusChange}
        onLabel={intl.formatMessage({
          defaultMessage: "On",
          id: "Zh+5A6",
        })}
        offLabel={intl.formatMessage({
          defaultMessage: "Off",
          id: "OvzONl",
        })}
      />
    </div>
  );
};
