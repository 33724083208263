import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserLoggingStatus } from "../../../state/user/selectors";
import {
  getIsConfigLoading,
  getIsConfigLoaded,
} from "../../../state/library/config/selectors";
import { loadConfig } from "../../../state/library/config/actions";
import { loadFiltersList } from "../../../state/library/discover/actions";
import {
  areFiltersLoaded,
  areFiltersLoading,
} from "../../../state/shared/selectors";

const ConfigHandler = ({
  isUserLoggedIn,
  isConfigLoading,
  isConfigLoaded,
  loadConfig,
  areFiltersLoading,
  areFiltersLoaded,
  loadFiltersList,
}) => {
  // load library config
  useEffect(() => {
    if (isUserLoggedIn && !isConfigLoading && !isConfigLoaded) {
      loadConfig();
    }
  }, [loadConfig, isConfigLoading, isUserLoggedIn, isConfigLoaded]);

  // load filters config
  useEffect(() => {
    if (isUserLoggedIn && !areFiltersLoading && !areFiltersLoaded) {
      loadFiltersList();
    }
  }, [loadFiltersList, areFiltersLoading, areFiltersLoaded, isUserLoggedIn]);
  return null;
};

ConfigHandler.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isConfigLoading: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  areFiltersLoading: PropTypes.bool.isRequired,
  areFiltersLoaded: PropTypes.bool.isRequired,
  loadConfig: PropTypes.func.isRequired,
  loadFiltersList: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isUserLoggedIn: getUserLoggingStatus(state),
    isConfigLoading: getIsConfigLoading(state),
    isConfigLoaded: getIsConfigLoaded(state),
    areFiltersLoading: areFiltersLoading(state),
    areFiltersLoaded: areFiltersLoaded(state),
  }),
  (dispatch) => ({
    loadConfig: (options) => dispatch(loadConfig(options)),
    loadFiltersList: () => dispatch(loadFiltersList()),
  })
)(ConfigHandler);
