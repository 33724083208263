import {
  sliceStringFrom,
  getUrl,
  embedURLCompiler,
} from "../../../../../utils";
import { Artifact as ArtifactType } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { isContentVideo } from "../../../../../utils";
import React, { MouseEvent } from "react";
import styles from "./Card.module.scss";
import classnames from "classnames";

interface CardArtifactProps {
  artifact: ArtifactType;
  isSingleColumn: boolean;
  idx: number;
}

export const CardArtifact = ({
  artifact,
  isSingleColumn,
  idx,
}: CardArtifactProps) => {
  const artifactLink = getUrl(artifact);
  const isVideo = isContentVideo(artifact.URI);

  //Assign video icon for video attachments or slices type from URL
  const iconType = isVideo ? "video-play" : attachmentIcon(artifact.IconURL!);

  function attachmentIcon(URL: string) {
    return URL.includes("google-apps")
      ? `google-${sliceStringFrom(URL, ".", 1)}` //Corresponds to svg filenames
      : "attachment";
  }

  //Creates embedded video URLs based on the artifact source
  const videoEmbedUrl = isVideo ? embedURLCompiler(artifact.URI) : "";
  const VIDEO_WIDTH = 224;
  const VIDEO_HEIGHT = 141;

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    window.open(artifactLink!.url, "_blank", "noopener, noreferrer");
  };

  return (
    <>
      {isVideo && (
        <div
          className={classnames(styles.artifactVideo, {
            [styles.firstVideo]: idx === 0,
          })}
        >
          <iframe
            title="Artifact Video"
            width={VIDEO_WIDTH}
            height={VIDEO_HEIGHT}
            src={videoEmbedUrl}
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            data-test-id="WS-Board-Card-ArtifactVideo"
          ></iframe>
        </div>
      )}
      <a
        className={classnames(styles.artifactPanel, {
          [styles.withVideo]: isVideo,
        })}
        onClick={handleClick}
        href={artifactLink!.url}
        target="_blank"
        rel="noreferrer"
        data-test-id="WS-Board-Card-Attachments"
      >
        <HapReactIcon svg={iconType} width={16} alt="Decorative Image" />
        <div
          className={classnames(styles.artifactTitle, {
            [styles.singleColumnTitle]: isSingleColumn,
          })}
        >
          {artifact.Title}
        </div>
      </a>
    </>
  );
};

export default CardArtifact;
