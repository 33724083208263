import React, { useEffect, useRef, ReactNode } from "react";

type FocusTrapProps = {
  children: ReactNode;
  dataTestIdPrefix?: string;
};

// TODO: PS-1434 Switch to using `focus-trap-react` package
export const FocusTrap: React.FC<FocusTrapProps> = ({
  children,
  dataTestIdPrefix,
}) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-FocusTrap`
    : undefined;

  const focusTrapRef = useRef<HTMLDivElement>(null);
  const firstFocusableElementRef = useRef<HTMLElement | null>(null);
  const lastFocusableElementRef = useRef<HTMLElement | null>(null);
  const previouslyFocusedElementRef = useRef<HTMLElement | null>(
    document.activeElement as HTMLElement
  );

  const updateFocusableElements = () => {
    if (!focusTrapRef.current) return;

    const focusableSelectors = [
      'a[href]:not([tabindex="-1"]):not([disabled])',
      'button:not([disabled]):not([tabindex="-1"])',
      'textarea:not([disabled]):not([tabindex="-1"])',
      'input[type="text"]:not([disabled]):not([tabindex="-1"])',
      'input[type="radio"]:not([disabled]):not([tabindex="-1"])',
      'input[type="checkbox"]:not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([tabindex="-1"]):not([disabled])',
    ];

    const focusableElements =
      focusTrapRef.current.querySelectorAll<HTMLElement>(
        focusableSelectors.join(",")
      );

    firstFocusableElementRef.current = focusableElements[0] || null;
    lastFocusableElementRef.current =
      focusableElements[focusableElements.length - 1] || null;
  };

  useEffect(() => {
    const setInitialFocus = () => {
      if (firstFocusableElementRef.current) {
        firstFocusableElementRef.current.focus();
      } else {
        focusTrapRef.current?.focus();
      }
    };

    requestAnimationFrame(setInitialFocus);
  }, []);

  useEffect(() => {
    updateFocusableElements();
  }, [children]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== "Tab") return;

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElementRef.current) {
        e.preventDefault();
        lastFocusableElementRef.current?.focus();
      }
    } else {
      if (document.activeElement === lastFocusableElementRef.current) {
        e.preventDefault();
        firstFocusableElementRef.current?.focus();
      }
    }
  };

  const handleFocusIn = (e: FocusEvent) => {
    if (!focusTrapRef.current?.contains(e.target as Node)) {
      if (firstFocusableElementRef.current) {
        firstFocusableElementRef.current.focus();
      } else {
        focusTrapRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    const currentFocusTrap = focusTrapRef.current;
    if (!currentFocusTrap) return;

    currentFocusTrap.addEventListener("keydown", handleKeyDown);
    document.addEventListener("focusin", handleFocusIn);

    const observer = new MutationObserver(() => {
      updateFocusableElements();
    });

    observer.observe(currentFocusTrap, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["tabindex", "disabled"],
    });

    return () => {
      currentFocusTrap.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("focusin", handleFocusIn);
      observer.disconnect();

      if (
        previouslyFocusedElementRef.current &&
        document.contains(previouslyFocusedElementRef.current)
      ) {
        previouslyFocusedElementRef.current.focus();
      }
    };
  }, []);

  return (
    <div
      ref={focusTrapRef}
      data-test-id={dataTestId}
      tabIndex={-1}
      aria-modal="true"
    >
      {children}
    </div>
  );
};
