import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  getMyClassesSortedByName,
  areMyClassesLoaded,
  getClassId,
  getClassSubjectFolders,
} from "../../../state/shared/selectors";
import {
  myClassItemType,
  MYWORKSPACES_CATEGORIES,
} from "../../../state/workspace/myworkspaces/list/types";
import {
  navigateToCategory,
  navigateToClass,
} from "../../../state/workspace/myworkspaces/list/navigation";
import styles from "./ClassesListDropDown.module.scss";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_WIDTH_TYPES,
  DROPDOWN_CONTENT_COLOUR_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { pushQueryToHistory } from "../../../state/router/navigation";
import { SHARED_URL_KEYS } from "../../../state/shared/navigation-keys";
import { getLocationPath } from "../../../state/router/selectors";
import { APP_PAGES } from "../../../state/router/types";

const ClassesListDropDown = ({
  classes,
  isLoaded,
  selectedClassId,
  locationPath,
}) => {
  const intl = useIntl();
  const allClassesItem = {
    class_alias: intl.formatMessage({
      defaultMessage: "All classes",
      id: "FG+DJj",
    }),
    id: null,
  };

  const [selectedItem, setSelectedItem] = useState(allClassesItem);
  const location = useLocation();
  const history = useHistory();
  const state = useSelector((state) => state);
  const getSubjectFolders = (classId) => getClassSubjectFolders(state, classId);

  const teacherDashboardUpdateClassId = (newClassId) => {
    const subjectFolders = getSubjectFolders(newClassId);
    const defaultSubjectFolder = subjectFolders?.length
      ? subjectFolders[0]
      : "Documents";
    const isDashboardFolders = location.pathname.includes(
      APP_PAGES.DASHBOARD_FOLDER.path
    );

    const newLocation = {
      ...location,
      pathname: isDashboardFolders
        ? `${APP_PAGES.DASHBOARD_FOLDER.path}/${newClassId}/${defaultSubjectFolder}`
        : location.pathname.replace(selectedClassId, newClassId),
    };

    history.push(newLocation);
  };

  const handleClassSelect = (item) => {
    setSelectedItem(item);
    // if inside My Workspaces
    if (_.startsWith(locationPath, APP_PAGES.WORKSPACE_MY_WORKSPACE.path)) {
      item.id
        ? navigateToClass(item.id)
        : navigateToCategory(MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey);
    } else if (_.startsWith(locationPath, APP_PAGES.DASHBOARD_BASE.path)) {
      if (item.class_alias === allClassesItem.class_alias) {
        return history.push(APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path);
      }
      return teacherDashboardUpdateClassId(item.id);
    } else if (_.startsWith(locationPath, APP_PAGES.CLASSINFO.path)) {
      if (item.class_alias === allClassesItem.class_alias) {
        return history.push(APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path);
      }
      return history.push(`${APP_PAGES.CLASSINFO.path}/${item.id}`);
    } else {
      pushQueryToHistory({ [SHARED_URL_KEYS.classId.key]: item.id || null });
    }
  };

  // set a selected item in the dropdown if value changed in the url
  useEffect(() => {
    if (selectedClassId) {
      const selectedClass = _.find(classes, { id: selectedClassId });
      setSelectedItem(selectedClass || allClassesItem);
    } else {
      setSelectedItem(allClassesItem);
    }
  }, [setSelectedItem, selectedClassId, classes]);

  const memoizedTriggerComponent = useMemo(() => {
    return ({ className, ...rest }) => (
      <DropdownTrigger
        label={selectedItem.class_alias}
        widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
        data-test-id="Ws-ClassList-Dropdown-Trigger"
        className={classnames(className, styles.dropdownTrigger)}
        {...rest}
      />
    );
  }, [selectedItem]);

  if (!isLoaded) {
    return <div className={styles.root} />;
  }

  return (
    <div className={styles.root}>
      <Dropdown
        className={styles.dropdown}
        classNameContent={styles.dropdownContent}
        colorType={DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY}
        triggerComponent={memoizedTriggerComponent}
        itemComponentList={_.concat(
          [
            ({ onClick, className, ...rest }) => (
              <button
                type="button"
                data-test-id="Ws-ClassList-Dropdown-AllClasses"
                onClick={() => {
                  handleClassSelect(allClassesItem);
                  onClick();
                }}
                className={classnames(className, styles.dropdownContentItem)}
                {...rest}
              >
                {allClassesItem.class_alias}
              </button>
            ),
          ],
          _.map(classes, (item) => ({ onClick, className, ...rest }) => (
            <button
              type="button"
              data-test-id="Ws-ClassList-Dropdown-Class"
              onClick={() => {
                handleClassSelect(item);
                onClick();
              }}
              className={classnames(className, styles.dropdownContentItem)}
              {...rest}
            >
              {item.class_alias}
            </button>
          ))
        )}
      />
    </div>
  );
};

ClassesListDropDown.propTypes = {
  classes: PropTypes.arrayOf(myClassItemType),
  isLoaded: PropTypes.bool,
  selectedClassId: PropTypes.string,
  locationPath: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    classes: getMyClassesSortedByName(state),
    isLoaded: areMyClassesLoaded(state),
    selectedClassId: getClassId(state),
    locationPath: getLocationPath(state),
  }),
  (dispatch) => ({})
)(ClassesListDropDown);
