import _ from "lodash";

export const ALT_TEXT = {
  TD_MANAGE_CLASSES: "Manage classes",
  HAPARA_LOGO: "Hāpara logo",
};

export const ACCESS_DENIED_TYPES = {
  UNKNOWN_USER: "UNKNOWN_USER",
  STUDENT_USER: "STUDENT_USER",
  KNOWN_BUT_UNAUTHORIZED_USER: "KNOWN_BUT_UNAUTHORIZED_USER",
};

// Sizes are consistent with scss ones in /src/styles/_vars.scss
export const WINDOW_SIZE_TYPES = {
  XS: 0, // window size < 768
  S: 768, // >= 768 but < 873
  SM: 873, // >= 873 but < 1024
  M: 1024, // >= 1024 but < 1217
  L: 1217, // >= 1217 but < 1366
  XL: 1366, // >= 1366 but < 1640
  "2XL": 1640, // >= 1640 but < 1920
  "3XL": 1920, // >= 1920
};

export const WINDOW_SIZE_TYPE_LIST = _.sortBy(_.values(WINDOW_SIZE_TYPES));

export const FEEDBACK_PRODUCT_TYPES = {
  HIGHLIGHTS: "highlights",
  DASHBOARD: "dashboard",
  WORKSPACE: "workspace",
  CLASSINFO: "classinfo",
};
