/** Retrieves the current time represented as a timestamp in seconds since the Unix epoch (January 1, 1970). */
export const getCurrentEpochTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

/** Calculates the relative difference in seconds between the current time and a given epoch timestamp. */
export const getRelativeSecondsTo = (epochTimestamp: number) => {
  const currentSeconds = getCurrentEpochTimestamp();
  return epochTimestamp - currentSeconds;
};
