import { dateTimeFormatOptions, DateTimeFormat } from "../formats";
import { useLocale } from "../../../providers/LocaleProvider";
import { isUnixTimestamp } from "../isUnixTimestamp";

export const formatDateTimeLocal = (
  timestamp: string,
  format: DateTimeFormat
): string | null => {
  const { locale } = useLocale();

  if (timestamp.endsWith("Z")) {
    return null;
  }

  // TODO: I've kept this in here to reduce changes, but this should ONLY be
  // handled in `formatDateTimeUtc`. This should return null if a unix timestamp
  // is provided.
  const date = isUnixTimestamp(timestamp)
    ? new Date(parseInt(timestamp))
    : new Date(timestamp);
  if (isNaN(date.getTime())) {
    return null;
  }
  return new Intl.DateTimeFormat(locale, dateTimeFormatOptions[format]).format(
    date
  );
};
