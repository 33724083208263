import React from "react";
import styles from "./StyledText.module.scss";
import classnames from "classnames";

type StyledTextProps = {
  className?: string;
  children: React.ReactNode;
  /** Removes the `margin-top` on the **first element** and the `margin-bottom` on the **last element**. */
  removeOuterMargins?: boolean;
  size?: "compact";
};

export const StyledText = ({
  children,
  className,
  removeOuterMargins,
  size,
}: StyledTextProps) => (
  <div
    className={classnames(
      {
        [styles.compact]: size === "compact",
        [styles.removeOuterMargins]: removeOuterMargins,
      },
      styles.container,
      className
    )}
  >
    {children}
  </div>
);
