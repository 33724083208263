import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./UploadStatusPage.module.scss";
import {
  getIsConfigError,
  getIsConfigLoaded,
  hasResourceUploadAccess,
} from "../../../../state/library/config/selectors";
import { hasWSAccess } from "../../../../state/user/selectors";
import Header from "../../../partial/Header/Header";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";
import Page from "../../../partial/PageContainer/PageContainer";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import { APP_PAGES } from "../../../../state/router/types";
import { MAIN_CONTENT_ID } from "../../../../consts";
import { toggleLibraryLeftPanel } from "../../../../state/library/library/actions";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import { getLibraryLeftPanelIsOpen } from "../../../../state/library/library/selectors";
import UploadStatus from "../../../partial/Library/UploadStatus/UploadStatus";
import LibrarySideNav from "../../../partial/Library/LibrarySideNav/LibrarySideNav";
import ConfigHandler from "../ConfigHandler";
import PageLeftPanel from "../../../partial/PageLeftPanel/PageLeftPanel";

export const UploadStatusPage = ({
  isLeftPanelOpen,
  toggleLeftPanel,
  hasWSAccess,
  hasResourceUploadAccess,
  isConfigLoaded,
  isConfigError,
}) => {
  const headerToShow = hasWSAccess ? (
    <Header />
  ) : (
    <HeaderAccountOnly rootPageLink={APP_PAGES.MY_RESOURCES_STARRED.path} />
  );

  return (
    <>
      <ConfigHandler />
      <Page
        header={headerToShow}
        title={APP_PAGES.LIBRARY_UPLOAD_STATUS.title}
        isPageLoadingError={isConfigError}
        isPageLoaded={isConfigLoaded}
      >
        {!hasResourceUploadAccess && <NoAccessPage />}
        {hasResourceUploadAccess && (
          <div
            className={styles.root}
            data-test-id="Hs-Pages-WorkspaceDiscoverPage"
          >
            <div>
              <LibrarySubMenu />
            </div>
            <div className={styles.content}>
              <PageLeftPanel
                isOpen={isLeftPanelOpen}
                focusableElementId={null}
                dataTestIdPrefix="lb-Library-uploadStatus"
                landmarkLabel="Library filters"
                closeButtonLabel="Close my library filters panel"
                onClose={() => toggleLeftPanel(false)}
              >
                <LibrarySideNav />
              </PageLeftPanel>
              <div className={styles.contentCenter}>
                <main
                  className={styles.contentMain}
                  id={MAIN_CONTENT_ID}
                  tabIndex="-1"
                >
                  <UploadStatus />
                </main>
              </div>
            </div>
          </div>
        )}
      </Page>
    </>
  );
};

UploadStatusPage.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  hasWSAccess: PropTypes.bool.isRequired,
  hasResourceUploadAccess: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: getLibraryLeftPanelIsOpen(state),
    hasWSAccess: hasWSAccess(state),
    hasResourceUploadAccess: hasResourceUploadAccess(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLibraryLeftPanel(isOpen)),
  })
)(UploadStatusPage);
