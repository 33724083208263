import axios from "axios";
import { getToken } from "./jwtHandler";
import { ClassTypeEnum, GoogleClassroomCourse } from "../types/shared";
import { ClassInfo, LandingModules, SchoolPrefs } from "../types/dashboard";
import { DashboardConfig } from "../types/dashboard";
import { ApiError, EdublogsData } from "./types";
import { getErrorEvent, getErrorStringFromErrorOrResponse } from "../utils";
import _ from "lodash";
import { getCurrentAuthHeader } from "./authHeader";
import { postSendEvent } from "./user";
import { DashboardUserGroup } from "../state/dashboard/class/types";

type GetConfigFn = () => Promise<DashboardConfig>;

type GetClassesListFn = (
  query: string,
  domain: string,
  school: string,
  allClasses: boolean
) => Promise<ClassInfo[]>;

type SchoolInfo = {
  Code: string;
  Name: string;
  SchoolPermissions: string[];
  LandingModule: LandingModules;
};

type DomainInfo = {
  URN: string;
  Schools: SchoolInfo[];
};

type DashboardConfigServer = {
  Permissions: string[];
  Domains: DomainInfo[];
};

export const fetchDashboardConfig: GetConfigFn = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/dashboard/teacher-config`;

  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const resp = await axios.get(url, config);
    const serverData: DashboardConfigServer = resp.data;
    const schoolPrefs: SchoolPrefs[] = [];
    serverData.Domains.forEach((d) => {
      d.Schools.forEach((s) => {
        schoolPrefs.push({
          domain: d.URN,
          school: s.Code,
          landingModule: s.LandingModule,
        });
      });
    });
    return {
      schoolPrefs,
      permissions: serverData.Permissions,
      domains: serverData.Domains.map((d) => {
        return {
          urn: d.URN,
          schools: d.Schools.map((s) => {
            return {
              code: s.Code,
              name: s.Name,
              permissions: s.SchoolPermissions,
            };
          }),
        };
      }),
    } as DashboardConfig;
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/dashboard/teacher-config"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

type ServerTeacherInfo = {
  FirstName: string;
  LastName: string;
};

type ServerClassInfo = {
  URN: string;
  isMyClass: boolean;
  SourceType: ClassTypeEnum;
  Colour: string;
  Pinned: boolean;
  Teachers: ServerTeacherInfo[];
  Name: string;
  Domain: string;
  School: string;
  OrderPriority: number;
};

export const fetchClassesList: GetClassesListFn = async (
  query,
  domain,
  school,
  allClasses
) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/dashboard/search-classes`;

  const getSearchResults = async () => {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.post(
      url,
      {
        Query: _.trim(query),
        Domain: _.trim(domain),
        School: _.trim(school),
        AllClasses: allClasses,
        PageNumber: 1,
        PageSize: 400,
      },
      config
    );
    const serverData: ServerClassInfo[] = res.data;
    return serverData;
  };
  const getSyncResults = async () => {
    try {
      const allJobsDone = await getIsAllJobsCompleted();
      if (allJobsDone) {
        return [];
      }
      const syncStatus = await searchMyClasses();
      return syncStatus;
    } catch (e) {
      // it's not so critical, letting teachers to see the list of classes even if pugs sync endpoint is down
      // the error will be reported in the API package
      return [];
    }
  };
  try {
    const results = await Promise.all([getSearchResults(), getSyncResults()]);
    const serverData: ServerClassInfo[] = results[0];
    const serverClassStatusSata: ClassStatus[] = results[1];
    return serverData.map((cl) => {
      return {
        id: cl.URN,
        class_alias: cl.Name,
        order_priority: cl.OrderPriority,
        classType: cl.SourceType,
        starred: cl.Pinned,
        color: cl.Colour,
        teachers: cl.Teachers.map((t) => {
          return { name: `${t.FirstName} ${t.LastName}` };
        }),
        domain: cl.Domain,
        school: cl.School,
        isClassSyncInProgress:
          serverClassStatusSata.filter(
            (st) => st.classUrn === cl.URN && st.isSyncInProcess
          ).length > 0,
      } as ClassInfo;
    });
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/dashboard/search-classes"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postUpdateClassesOrder = async (
  changedItem: string,
  newPriority: number
) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/dashboard/update-order`;
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    await axios.post(
      url,
      { ClassURN: changedItem, Priority: newPriority },
      config
    );
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/dashboard/update-order"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postUpdateClassStarStatus = async (
  classURN: string,
  isStarred: boolean
) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/dashboard/set-pinned`;
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    await axios.post(url, { Pinned: isStarred, ClassURN: classURN }, config);
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/dashboard/set-pinned"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postUpdateClassColour = async (
  classURN: string,
  newColour: string
) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/dashboard/set-colour`;
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    await axios.post(url, { Colour: newColour, ClassURN: classURN }, config);
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/dashboard/set-colour"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

//HUGS

export const postAddClass = async (
  name: string,
  domain: string,
  school: string
): Promise<ClassInfo> => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/create`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const resp = await axios.post(
      url,
      {
        DomainId: domain,
        Name: name,
        SiteId: `urn:site::${domain}:${school}`,
      },
      config
    );
    const r = resp.data;
    return {
      class_alias: name,
      id: _.get(r, "Group.URN"),
      color: "",
      starred: true,
      teachers: _.get(r, "Managers", []).map((m: any) => {
        return { name: `${m.Fname} ${m.Lname}` };
      }),
      order_priority: new Date().getTime() * 1000000,
      classType: ClassTypeEnum.MANUAL,
      domain,
      school,
      isClassSyncInProgress: false,
    };
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/pugs/workgroup/create"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

//HUGS
export const postGCSync = async (
  domain: string,
  school: string,
  classes: GoogleClassroomCourse[]
): Promise<ClassInfo[]> => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/create-gclassroom`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const resp = await axios.post(
      url,
      {
        DomainId: domain,
        GoogleClassroomReq: classes.map((cl) => {
          return {
            GoogleClassroomId: cl.id,
            GoogleClassroomOwnerId: cl.owner,
            GoogleClassroomTeacherFolderId: cl.teacherFolderID,
            Name: cl.name,
          };
        }),
        SiteId: `urn:site::${domain}:${school}`,
      },
      config
    );
    const data = resp.data;
    return data.map((d: any, ind: number) => {
      return {
        class_alias: _.get(d, "Title"),
        id: _.get(d, "Group.URN"),
        order_priority: (new Date().getTime() - ind * 1000) * 1000000,
        classType: ClassTypeEnum.GOOGLE_CLASSROOM,
        color: "",
        starred: true,
        teachers: _.get(d, "Managers", []).map((m: any) => {
          return { name: `${m.Fname} ${m.Lname}` };
        }),
        isClassSyncInProgress: true,
      } as ClassInfo;
    });
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/pugs/workgroup/create-gclassroom"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

//from HUGS

type ClassStatus = {
  isSyncInProcess: boolean;
  classUrn: string;
};

type ServerGroupDetails = {
  URN: string;
  SyncStatus: string;
};

type ServerGroup = {
  Group: ServerGroupDetails;
};

export const searchMyClasses = async (): Promise<ClassStatus[]> => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/list/search?all=false&page=0&pageSize=400&q=`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const resp = await axios.get(url, config);
    if (!resp.data.Groups || resp.data.Groups.length === 0) {
      return [];
    }
    const data = resp.data;
    return data.Groups.map((g: ServerGroup) => ({
      classUrn: g.Group.URN,
      isSyncInProcess: g.Group.SyncStatus !== "done",
    }));
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "pugs/workgroup/list/search?all=false&page=0&pageSize=400&q="
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

type Job = {
  Status: string;
};

const getIsAllJobsCompleted = async (): Promise<boolean> => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/sync/status`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const resp = await axios.get(url, config);
    if (!resp.data.Jobs || resp.data.Jobs.length === 0) {
      return true;
    }
    const data = resp.data;
    return data.Jobs.filter((j: Job) => j.Status !== "Completed").length === 0;
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/sync/status"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const getUserGoogleClassrooms = async (): Promise<
  GoogleClassroomCourse[]
> => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/list/list-gclassrooms`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const resp = await axios.get(url, config);
    if (!resp.data.Courses) {
      return [];
    }
    const data = resp.data.Courses;
    return data.map((d: any) => ({
      id: _.get(d, "Course.id"),
      name: _.get(d, "Course.name"),
      teacherFolderID: _.get(d, "Course.teacherFolder.id"),
      haparaClassId: _.get(d, "HaparaURN"),
      owner: _.get(d, "Course.ownerId"),
      isHaparaClass: _.get(d, "IsHaparaClass"),
    })) as GoogleClassroomCourse[];
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/pugs/workgroup/list/list-gclassrooms"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const fetchDashboardClassData = async (classId: string) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/urn/${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .get(url, config)
    .then((response) => {
      if (!response?.data) {
        return {};
      }
      const userGroups: DashboardUserGroup[] =
        response?.data?.dashboard?.people?.usergroups;

      // TODO: More of the data from this endpoint could be required when we build the new teacher dashboard,
      // The data is being shaped here to match existing userGroup data structure
      // Other parts of the data may be required and need to be shaped here

      const formattedUserGroups = userGroups?.map(
        (userGroup: DashboardUserGroup) => {
          const {
            DisplayName,
            Colour,
            Participants,
            Tab,
            checked = false,
            URN,
          } = userGroup;
          return {
            name: DisplayName,
            color: Colour,
            participants: Participants,
            tab: Tab,
            checked: checked,
            URN: URN,
          };
        }
      );

      const data = {
        ...response.data.dashboard,
        people: {
          ...response.data.dashboard.people,
          usergroups: formattedUserGroups,
        },
      };

      return data;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        _.get(e, "response.status"),
        `/v2/pugs/dashboard/urn/${classId}`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const getStudentEdublogsData = async (
  studentEmail: string,
  type?: string
) => {
  const url = `${
    process.env.REACT_APP_API_HAPARA_URL
  }/teacher-dashboard/v1/student/${studentEmail}/blog/${
    type === "comments" ? "comments" : "posts"
  }`;

  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };

  return await axios.get(url, config);
};
