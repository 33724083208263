import actionTypes from "../../actionTypes";
import { NO_CURRENT_SCREEN_AVAILABLE } from "../screenshotWebsocket/types";

const INIT_STATE = {
  isOpen: false,
  studentId: null,
  studentEmail: null,
  studentLastName: null,
  studentDisplayName: null,
  instances: null,
};

const currentScreenExpandModalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_EXPAND_MODAL_SHOW: {
      const { studentId, studentEmail, studentLastName, studentDisplayName } =
        action.payload;

      return {
        ...INIT_STATE,
        isOpen: true,
        studentId,
        studentEmail,
        studentLastName,
        studentDisplayName,
      };
    }

    case actionTypes.HIGHLIGHTS_EXPAND_MODAL_HIDE: {
      return INIT_STATE;
    }

    case actionTypes.HIGHLIGHTS_UPDATE_EXPAND_MODAL_SNAPSHOT: {
      const { studentId, image, url, instanceId } = action.payload;

      if (
        state.isOpen &&
        state.studentId === studentId &&
        image !== NO_CURRENT_SCREEN_AVAILABLE
      ) {
        return {
          ...state,
          instances: {
            ...state.instances,
            [instanceId]: {
              image,
              url,
              isNoImage: false,
            },
          },
        };
      } else {
        if (image === NO_CURRENT_SCREEN_AVAILABLE) {
          return {
            ...state,
            instances: {
              ...state.instances,
              [instanceId]: {
                ...(state.instances ? state.instances[instanceId] : null),
                isNoImage: true,
              },
            },
          };
        } else {
          return state;
        }
      }
    }

    case actionTypes.HIGHLIGHTS_EXPAND_MODAL_SET_EMPTY_SNAPSHOT: {
      const { instanceId } = action.payload;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...(state.instances ? state.instances[instanceId] : null),
            image: NO_CURRENT_SCREEN_AVAILABLE,
          },
        },
      };
    }

    case actionTypes.HIGHLIGHTS_EXPAND_MODAL_CLEAR_INSTANCES: {
      return {
        ...state,
        instances: null,
      };
    }

    default:
      return state;
  }
};

export default currentScreenExpandModalReducer;
