import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./UploadForm.module.scss";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";

const UploadFormAcknowledgement = ({
  dataTestIdPrefix,
  isAcknowledged,
  onIsAcknowledgedChange,
}) => {
  return (
    <div className={styles.fieldBlock}>
      <div className={styles.fieldLabel2}>
        <FormattedMessage
          defaultMessage="Acknowledgement of permission to upload"
          id="TJirJW"
        />
      </div>
      <Checkbox
        checked={isAcknowledged}
        onChange={onIsAcknowledgedChange}
        label="By checking this box I confirm that I, whether personally or through the organization for which I work, have the full rights to use, share and re-produce through the Digital Backpack, all of the content inside this resource with the original creator’s agreement, permission(s) and consent."
        dataTestIdPrefix={`${dataTestIdPrefix}-Acknowledgement`}
      />
    </div>
  );
};

UploadFormAcknowledgement.propTypes = {
  isAcknowledged: PropTypes.bool,
  onIsAcknowledgedChange: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default UploadFormAcknowledgement;
