import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  labelItemType,
  LABEL_ALLOWED_LENGTH,
} from "../../../../../state/workspace/myworkspaces/labels/types";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import {
  deleteLabelAction,
  editLabelAction,
  dismissError,
} from "../../../../../state/workspace/myworkspaces/labels/actions";
import styles from "./LabelItemInsideCategory.module.scss";
import { getFilterLabelId } from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  actionInProgress,
  actionError,
  actionCompleted,
  getLabels,
} from "../../../../../state/workspace/myworkspaces/labels/selectors";
import { MYWORKSPACES_DEFAULT_LABEL } from "../../../../../state/workspace/myworkspaces/list/types";
import EditableDeletableItem from "../../../EditableDeletableItem/EditableDeletableItem";

const LabelItemInsideCategory = ({
  item,
  inEditMode = false,
  selectedLabelId = "",
  actionInProgress = false,
  actionError = false,
  actionCompleted = false,
  labels = [],
  deleteLabel = () => {},
  editLabel = () => {},
  dismissError,
}) => {
  const isSelected = item.Id === selectedLabelId;

  if (!inEditMode) {
    if (item.deleted) {
      if (isSelected) {
        pushQueryToHistory({
          label: MYWORKSPACES_DEFAULT_LABEL.id,
        });
      }
      return null;
    }

    const handleLabelFilterSelect = () => {
      pushQueryToHistory({
        label: item.Id,
      });
    };

    return (
      <button
        className={classnames(styles.root, {
          [styles.clear]: item.clear,
          [styles.active]: isSelected,
        })}
        onClick={handleLabelFilterSelect}
        data-test-id="Ws-LabelInsideCategory-Filter"
        type="button"
        aria-label={`${
          item.Id === MYWORKSPACES_DEFAULT_LABEL.id ? "" : "label"
        } ${item.Name}${isSelected ? ", Selected" : ""}`}
      >
        {item.Name}
      </button>
    );
  }

  if (item.deleted) return null;

  const handleEditLabel = (newLabelName) => {
    editLabel(item.Id, newLabelName);
  };

  return (
    <div className={classnames(styles.root, styles.editMode)}>
      <EditableDeletableItem
        className={styles.fullWidth}
        item={item}
        actionInProgress={actionInProgress}
        actionError={actionError}
        actionCompleted={actionCompleted}
        items={labels}
        editItem={handleEditLabel}
        deleteItem={deleteLabel}
        itemNaming="label"
        maxAllowedLength={LABEL_ALLOWED_LENGTH}
        dataTestIdCore="Ws-ManageLabels"
        actionToGetTotalAmount={() =>
          new Promise((resolve, reject) => {
            resolve({ status: 200, data: { TotalHits: item.wsAmount } });
          })
        }
        dischargeActionError={dismissError}
      />
    </div>
  );
};

LabelItemInsideCategory.propTypes = {
  item: labelItemType,
  inEditMode: PropTypes.bool,
  selectedLabelId: PropTypes.string,
  actionInProgress: PropTypes.bool,
  actionError: PropTypes.bool,
  actionCompleted: PropTypes.bool,
  labels: PropTypes.arrayOf(labelItemType),
  deleteLabel: PropTypes.func.isRequired,
  editLabel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedLabelId: getFilterLabelId(state),
    actionInProgress: actionInProgress(state),
    actionError: actionError(state),
    actionCompleted: actionCompleted(state),
    labels: getLabels(state),
  }),
  (dispatch) => ({
    deleteLabel: (labelId) => dispatch(deleteLabelAction(labelId)),
    editLabel: (labelId, newLabelTitle) =>
      dispatch(editLabelAction(labelId, newLabelTitle)),
    dismissError: () => dispatch(dismissError()),
  })
)(LabelItemInsideCategory);
