import PropTypes from "prop-types";

export const REFRESH_SECONDS = 5;
export const AVAILABLE_TO_UNSHARE_MINUTES = 5;

export const SMARTSHARE_STATUS = {
  SHARE_PROGRESS: "share-inprogress",
  SHARE_COMPLETED: "share-completed",
  UNSHARE_SCHEDULED: "undo-scheduled",
  UNSHARE_PROGRESS: "undo-inprogress",
  UNSHARE_COMPLETED: "undo-complete",
};

export const studentItemType = PropTypes.shape({
  Student: PropTypes.string,
  Status: PropTypes.string,
});

export const shareItemDocType = PropTypes.shape({
  DocumentID: PropTypes.string.isRequired,
  DocumentTitle: PropTypes.string.isRequired,
  Students: PropTypes.arrayOf(studentItemType),
  MimeType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

export const smartShareItemType = PropTypes.shape({
  classes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  documents: PropTypes.arrayOf(shareItemDocType),
  errorText: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  highlighted: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  iconColourStyle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  modalTitle: PropTypes.string.isRequired,
  percentage: PropTypes.number,
  shareText: PropTypes.string.isRequired,
  showUnshare: PropTypes.bool.isRequired,
  spinIcon: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
});
