import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { applyLibraryDiscoverSearchParams } from "../../../../state/library/discover/actions";
import {
  areFiltersLoading,
  getAllFilters,
  getCurrentPage,
  getSearchQuery,
  getSelectedFilters,
  getSelectedFormatFilter,
  getSelectedResourceId,
} from "../../../../state/shared/selectors";
import {
  getDiscoverIsLoading,
  areDiscoverResultsNull,
  getDiscoverSelectedItem,
} from "../../../../state/library/discover/selectors";
import { showResourceDetailsModal } from "../../../../state/resources/actions";
import {
  resourceItemType,
  RESOURCE_DETAILS_MODAL_MODE,
} from "../../../../state/resources/types";
import {
  SHARED_FILTER_TYPES,
  allFiltersType,
} from "../../../../state/shared/types";

export const LocationChangeHandler = ({
  areDiscoverResultsNull,
  isDiscoverLoading,
  areFiltersLoading,
  allFilters,
  searchParamPage,
  searchParamQuery,
  searchParamGrades,
  searchParamSubjects,
  searchParamStandards,
  searchParamResourceTypes,
  searchParamLicences,
  searchParamFormat,
  applyLibraryDiscoverSearchParams,
  activeResourceId,
  showResourceDetailsModal,
  selectedItem,
}) => {
  const dataFetchParamsRef = useRef();

  // listen to search params change and load new data if necessary
  useEffect(() => {
    // don't do anything before all necessary data is loaded
    if (!areFiltersLoading && !_.isEmpty(allFilters)) {
      const newDataFetchParams = {
        searchParamGrades,
        searchParamSubjects,
        searchParamStandards,
        searchParamResourceTypes,
        searchParamLicences,
        searchParamFormat,
        searchParamQuery,
        searchParamPage,
      };

      // load should happen if it is a first load
      let shouldLoadWorkspaces = areDiscoverResultsNull && !isDiscoverLoading;

      // check all the params which should cause re-render;
      // load should happen if any given param was changed since last change
      _.forEach(newDataFetchParams, (value, key) => {
        const shouldDoTheLoad = !_.isEqual(
          _.get(dataFetchParamsRef.current, key),
          value
        );
        if (shouldDoTheLoad) {
          shouldLoadWorkspaces = true;
        }
      });

      // update the state and do the load
      if (shouldLoadWorkspaces) {
        applyLibraryDiscoverSearchParams({
          grades: searchParamGrades,
          subjects: searchParamSubjects,
          standards: searchParamStandards,
          resourceTypes: searchParamResourceTypes,
          licences: searchParamLicences,
          format: searchParamFormat,
          query: searchParamQuery,
          page: searchParamPage,
        });
      }

      // keep previous params values for the reference
      // so we can compare with the current ones
      dataFetchParamsRef.current = { ...newDataFetchParams };
    }
  }, [
    applyLibraryDiscoverSearchParams,
    areFiltersLoading,
    allFilters,
    areDiscoverResultsNull,
    isDiscoverLoading,
    searchParamPage,
    searchParamQuery,
    searchParamGrades,
    searchParamSubjects,
    searchParamResourceTypes,
    searchParamLicences,
    searchParamFormat,
    searchParamStandards,
  ]);

  const activeItemIdRef = useRef();
  // listen to rId params change and open the modal window
  useEffect(() => {
    const currentActiveItemId = activeResourceId;
    const prevActiveItemId = activeItemIdRef.current;

    // do not do anything until search results are loaded
    if (!areDiscoverResultsNull && !isDiscoverLoading) {
      // open or reload the modal if
      // - activeResourceId param is present
      // - and ether it was not present before
      // - or it has changed
      if (
        currentActiveItemId &&
        (!prevActiveItemId || prevActiveItemId !== currentActiveItemId)
      ) {
        if (!_.isEmpty(selectedItem)) {
          showResourceDetailsModal({ item: selectedItem });
        }
      }

      // keep prev state of modal
      // so we can compare with the current one
      activeItemIdRef.current = currentActiveItemId;
    }
  }, [
    activeResourceId,
    areDiscoverResultsNull,
    isDiscoverLoading,
    showResourceDetailsModal,
    selectedItem,
  ]);

  return null;
};

LocationChangeHandler.propTypes = {
  areFiltersLoading: PropTypes.bool.isRequired,
  allFilters: allFiltersType,
  areDiscoverResultsNull: PropTypes.bool.isRequired,
  isDiscoverLoading: PropTypes.bool.isRequired,
  searchParamPage: PropTypes.number.isRequired,
  searchParamQuery: PropTypes.string,
  activeResourceId: PropTypes.string,
  searchParamGrades: PropTypes.arrayOf(PropTypes.string),
  searchParamSubjects: PropTypes.arrayOf(PropTypes.string),
  searchParamStandards: PropTypes.arrayOf(PropTypes.string),
  searchParamResourceTypes: PropTypes.arrayOf(PropTypes.string),
  searchParamLicences: PropTypes.arrayOf(PropTypes.string),
  searchParamFormat: PropTypes.string,
  applyLibraryDiscoverSearchParams: PropTypes.func.isRequired,
  showResourceDetailsModal: PropTypes.func.isRequired,
  selectedItem: resourceItemType,
};

export default connect(
  (state) => ({
    areFiltersLoading: areFiltersLoading(state),
    allFilters: getAllFilters(state),
    areDiscoverResultsNull: areDiscoverResultsNull(state),
    isDiscoverLoading: getDiscoverIsLoading(state),
    searchParamPage: getCurrentPage(state),
    searchParamQuery: getSearchQuery(state),
    activeResourceId: getSelectedResourceId(state),
    selectedItem: getDiscoverSelectedItem(state),
    searchParamGrades: getSelectedFilters(SHARED_FILTER_TYPES.GRADES.value)(
      state
    ),
    searchParamSubjects: getSelectedFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(
      state
    ),
    searchParamStandards: getSelectedFilters(
      SHARED_FILTER_TYPES.STANDARDS.value
    )(state),
    searchParamResourceTypes: getSelectedFilters(
      SHARED_FILTER_TYPES.RESOURCE_TYPE.value
    )(state),
    searchParamLicences: getSelectedFilters(SHARED_FILTER_TYPES.LICENCES.value)(
      state
    ),
    searchParamFormat: getSelectedFormatFilter(state),
  }),
  (dispatch) => ({
    applyLibraryDiscoverSearchParams: (options) =>
      dispatch(applyLibraryDiscoverSearchParams(options)),
    showResourceDetailsModal: ({ item }) =>
      dispatch(
        showResourceDetailsModal({
          item,
          mode: RESOURCE_DETAILS_MODAL_MODE.READ,
        })
      ),
  })
)(LocationChangeHandler);
