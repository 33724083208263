import { useIntl } from "react-intl";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import styles from "./DropdownMultiSelect.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../Button/Button";

export const DROPDOWN_MULTISELECT_CONTENT_POSITION_TYPES = {
  LEFT: "left",
  RIGHT: "right",
};
export const DROPDOWN_MULTISELECT_WIDTH_TYPES = {
  MIN_CONTENT: "min-content",
  FULL_WIDTH: "full-width",
};

const DropdownMultiSelect = ({
  className,
  classNameContent,
  positionType = DROPDOWN_MULTISELECT_CONTENT_POSITION_TYPES.LEFT,
  widthType = DROPDOWN_MULTISELECT_WIDTH_TYPES.MIN_CONTENT,
  triggerComponent: TriggerComponent,
  items = [],
  selectedItems = [],
  disabledItems = [],
  dataTestIdPrefix = "hs-DropdownMultiSelect",
  onApply = () => {},
  onCancel = () => {},
}) => {
  const intl = useIntl();
  const [triggerId] = useState(uuidv4());
  const rootRef = useRef();
  const contentRef = useRef();
  const [open, setOpen] = useState(false);
  const [newSelectedItems, setNewSelectedItems] = useState(selectedItems);

  const focusTriggerElement = () => {
    const rootNodes = _.get(rootRef, "current.childNodes");
    if (rootNodes && rootNodes.length > 0) {
      rootNodes[0].focus();
    }
  };

  // reset to init state when DD is closed
  const handleCancel = useCallback(() => {
    setOpen(false);
    setNewSelectedItems(selectedItems);
    onCancel();
  }, [selectedItems, onCancel]);

  // close Dropdown if clicked outside
  const handleOutsideClick = useCallback(
    (e) => {
      if (open) {
        if (rootRef.current && rootRef.current.contains(e.target)) {
          // inside click
          return;
        }
        // outside click
        handleCancel();
      }
    },
    [open, handleCancel]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);
  // end

  // close Dropdown and focus trigger button if Esc is pressed
  const handleEscKey = useCallback(
    (e) => {
      if (open && e.keyCode === 27) {
        handleCancel();
        focusTriggerElement();
      }
    },
    [open, handleCancel]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handleEscKey]);
  // end

  // listen for selectedItems changes
  useEffect(() => {
    setNewSelectedItems(selectedItems);
  }, [selectedItems]);

  const handleTriggerKeyDown = (e) => {
    // on Arrow Down
    if (e.keyCode === 40) {
      e.preventDefault();
      setOpen(true);

      const children = _.get(contentRef, "current.childNodes");
      if (children && children.length) {
        contentRef.current.focus();
      }
    }
  };

  const handleItemSelect = (isSelected, item) => {
    let updatedSelectedItems = _.cloneDeep(newSelectedItems);
    if (isSelected) {
      updatedSelectedItems = _.unionBy(updatedSelectedItems, [item], "id");
    } else {
      _.pullAllBy(updatedSelectedItems, [item], "id");
    }
    setNewSelectedItems(updatedSelectedItems);
  };

  const isApplyDisabled = useMemo(
    () => !_.xorBy(selectedItems, newSelectedItems, "id").length,
    [selectedItems, newSelectedItems]
  );

  return (
    <div className={classnames(styles.root, className)} ref={rootRef}>
      <TriggerComponent
        onClick={() => {
          setOpen(!open);
        }}
        aria-haspopup="true"
        aria-expanded={open}
        id={triggerId}
        onKeyDown={(e) => handleTriggerKeyDown(e)}
      />
      {open && (
        <div
          className={classnames(
            styles.content,
            {
              [styles.right]:
                positionType ===
                DROPDOWN_MULTISELECT_CONTENT_POSITION_TYPES.RIGHT,
              [styles.fullWidth]:
                widthType === DROPDOWN_MULTISELECT_WIDTH_TYPES.FULL_WIDTH,
            },
            classNameContent
          )}
        >
          <div className={styles.summary}>
            {newSelectedItems.length ? newSelectedItems.length : "None"}{" "}
            selected
          </div>
          <fieldset
            className={styles.fieldset}
            aria-labelledby={triggerId}
            ref={contentRef}
            tabIndex={-1}
          >
            {items.map((item) => {
              const isChecked =
                newSelectedItems.filter((s) => s.id === item.id).length > 0;
              const isDisabled =
                disabledItems.filter((s) => s.id === item.id).length > 0;
              return (
                <Checkbox
                  key={item.id}
                  checked={isChecked}
                  onChange={(state) => handleItemSelect(state, item)}
                  label={item.name || item.id}
                  disabled={isDisabled}
                  className={styles.checkboxItem}
                  dataTestIdPrefix={`${dataTestIdPrefix}-Checkbox`}
                />
              );
            })}
          </fieldset>
          <div className={styles.actions}>
            <Button
              label={intl.formatMessage({
                defaultMessage: "Cancel",
                id: "47FYwb",
              })}
              size={BUTTON_SIZES.SMALL}
              type={BUTTON_TYPES.SECONDARY}
              onClick={() => {
                handleCancel();
                focusTriggerElement();
              }}
              data-test-id={`${dataTestIdPrefix}-CancelButton`}
            />
            <Button
              label={intl.formatMessage({
                defaultMessage: "Apply",
                id: "EWw/tK",
              })}
              size={BUTTON_SIZES.SMALL}
              type={BUTTON_TYPES.PRIMARY}
              isDisabled={isApplyDisabled}
              onClick={() => {
                onApply(newSelectedItems);
                setOpen(false);
                focusTriggerElement();
              }}
              data-test-id={`${dataTestIdPrefix}-ApplyButton`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const MULTI_SELECT_ITEM_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
});

DropdownMultiSelect.propTypes = {
  triggerComponent: PropTypes.elementType.isRequired,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  positionType: PropTypes.oneOf(
    _.values(DROPDOWN_MULTISELECT_CONTENT_POSITION_TYPES)
  ),
  widthType: PropTypes.oneOf(_.values(DROPDOWN_MULTISELECT_WIDTH_TYPES)),
  dataTestIdPrefix: PropTypes.string,
  items: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  selectedItems: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  disabledItems: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DropdownMultiSelect;
