import { useMemo, ReactNode } from "react";
import classnames from "classnames";
import styles from "../../Highlights/StudentTile/StudentTile.module.scss";
import { useIntl } from "react-intl";

interface Props {
  isFilter: boolean;
  isSearch: boolean;
  isFolder: boolean;
  edublogsType?: string;
}

const StudentTileEmptyState: React.FC<Props> = ({
  isFilter,
  isSearch,
  isFolder,
  edublogsType,
}) => {
  const intl = useIntl();

  const EMPTY_TILE_TITLE = useMemo(
    () => ({
      DEFAULT: intl.formatMessage({
        id: "G/15GI",
        defaultMessage: "No documents here",
      }),
      SEARCH: intl.formatMessage({
        id: "KJ4Wtu",
        defaultMessage: "No documents found",
      }),
      EDUBLOGS_POSTS: intl.formatMessage({
        id: "ZSmxhz",
        defaultMessage: "No blogs",
      }),
      EDUBLOGS_COMMENTS: intl.formatMessage({
        id: "84807T",
        defaultMessage: "No comments",
      }),
    }),
    [intl]
  );

  const EMPTY_TILE_TEXT = useMemo(
    () => ({
      DEFAULT: intl.formatMessage({
        id: "2WOtjT",
        defaultMessage: "The student does not have any files in their Drive",
      }),
      FILTERED: intl.formatMessage({
        id: "FY3h3H",
        defaultMessage:
          "The student does not have any files in this area of Drive",
      }),
      FOLDER: intl.formatMessage({
        id: "TOR09+",
        defaultMessage: "The student does not have any files in this Folder",
      }),
      EDUBLOGS_POSTS: intl.formatMessage({
        id: "MHyKec",
        defaultMessage: "The student does not have any blogs",
      }),
      EDUBLOGS_COMMENTS: intl.formatMessage({
        id: "vwZs3a",
        defaultMessage: "The student does not have any comments on their posts",
      }),
      SEARCH: null,
    }),
    [intl]
  );

  let title: ReactNode = EMPTY_TILE_TITLE.DEFAULT;
  let text: ReactNode | null = EMPTY_TILE_TEXT.DEFAULT;

  if (isSearch) {
    title = EMPTY_TILE_TITLE.SEARCH;
    text = EMPTY_TILE_TEXT.SEARCH;
  } else if (isFilter) {
    text = EMPTY_TILE_TEXT.FILTERED;
  } else if (isFolder) {
    text = EMPTY_TILE_TEXT.FOLDER;
  } else if (edublogsType === "posts") {
    title = EMPTY_TILE_TITLE.EDUBLOGS_POSTS;
    text = EMPTY_TILE_TEXT.EDUBLOGS_POSTS;
  } else if (edublogsType === "comments") {
    title = EMPTY_TILE_TITLE.EDUBLOGS_COMMENTS;
    text = EMPTY_TILE_TEXT.EDUBLOGS_COMMENTS;
  }

  return (
    <div className={classnames(styles.tabsContainer, styles.withBorder)}>
      <div className={styles.status}>
        <div className={styles.statusContainer}>
          <div className={styles.title} data-test-id="studentTileEmpty-title">
            {title}
          </div>
          {text && (
            <div className={styles.text} data-test-id="studentTileEmpty-text">
              {text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentTileEmptyState;
