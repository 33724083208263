import React from "react";
import styles from "./ErrorStatePanel.module.scss";
import PropTypes from "prop-types";

const ErrorStatePanel = ({ message, children, dataTestId }) => {
  return (
    <div className={styles.root} data-test-id={dataTestId}>
      <h2 className={styles.title}>{message}</h2>
      {children && <div className={styles.text}>{children}</div>}
    </div>
  );
};

ErrorStatePanel.propTypes = {
  message: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default ErrorStatePanel;
