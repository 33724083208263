import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./WarningMessage.module.scss";
import HapReactIcon from "../icon/hapReactIcon";

const WarningMessage = ({
  children = "",
  className,
  isVisible = false,
  dataTestId = "warning-",
  role = "",
  ariaLive = "",
  ariaAtomic = "",
  icon,
}) => {
  if (!isVisible) return null;

  return (
    <div
      className={classnames([
        styles.root,
        { [styles.close]: !isVisible },
        className,
      ])}
      role={role ? role : null}
      aria-live={ariaLive ? ariaLive : null}
      aria-atomic={ariaAtomic ? ariaAtomic : null}
      data-test-id={dataTestId ? dataTestId : null}
    >
      {icon && (
        <HapReactIcon
          svg={icon}
          width={16}
          height={16}
          className={styles.icon}
        />
      )}
      <div
        data-test-id={dataTestId ? `${dataTestId}-text` : null}
        tabIndex={-1}
      >
        {children}
      </div>
    </div>
  );
};

WarningMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  dataTestId: PropTypes.string,
  role: PropTypes.string,
  ariaLive: PropTypes.string,
  ariaAtomic: PropTypes.string,
  icon: PropTypes.string,
};

export default WarningMessage;
