const emailRegex = /^[\w'+-]+(\.[\w'+-]+)*@\w+([.-]\w+)*(\.\w{2,})+$/;

export const containsOnlyValidEmails = (emailList?: string[]) => {
  if (emailList?.length === 0) {
    return false;
  }
  return !emailList?.some((email: string) => !emailRegex.test(email));
};

export const singleEmailInputValidation = (input: string) => {
  const wordsArray = input
    .trim()
    .split(/[\s,]+/)
    .filter((item) => item.length >= 1);

  const containsMultipleAdditions = wordsArray.length > 1;
  const containsInvalidEmails = !emailRegex.test(wordsArray[0]);
  const errorMessage = containsMultipleAdditions
    ? "Please enter only one email address"
    : containsInvalidEmails
    ? "Please enter a valid email address"
    : null;

  return {
    hasError: containsMultipleAdditions || containsInvalidEmails,
    trimmedInput: wordsArray[0],
    ...(errorMessage && { errorMessage }),
  };
};
