import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./Search.module.scss";
import Button from "../Button/Button";

const Search = ({
  onSearch = () => {},
  placeholder,
  className,
  value = "",
  onChange,
  dataTestIdPrefix,
  disabled = false,
  tabIndex,
}) => {
  const intl = useIntl();

  const defaultPlaceholder = intl.formatMessage({
    defaultMessage: "Search",
    id: "xmcVZ0",
  });

  const handleValueChange = (e) => {
    onChange(_.get(e, "target.value", ""));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(value);
  };

  const isActiveState = !!value;

  return (
    <form
      className={classnames(
        styles.root,
        {
          [styles.active]: isActiveState,
        },
        className
      )}
      onSubmit={handleSubmit}
      role="search"
    >
      <input
        type="text"
        placeholder={placeholder ?? defaultPlaceholder}
        className={styles.textField}
        value={value ? value : ""}
        onChange={handleValueChange}
        data-test-id={dataTestIdPrefix + "-Input-SearchField"}
        aria-label={placeholder ?? defaultPlaceholder}
        tabIndex={tabIndex}
      />

      <Button
        className={styles.searchButton}
        type="primary"
        icon="search"
        dataTestId={dataTestIdPrefix + "-Button-SubmitSearch"}
        aria-label={intl.formatMessage({
          defaultMessage: "Search",
          id: "xmcVZ0",
        })}
        onAction={() => onSearch(value)}
        tabIndex={tabIndex}
        disabled={disabled}
      />
    </form>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  dataTestIdPrefix: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default Search;
