import _ from "lodash";
import moment from "moment";
import { getClassId } from "../shared/selectors";
import { getUserEmail } from "../user/selectors";
import { getClassConfig } from "./config/selectors";
import { sendEvent } from "../app/actions";
import { getStudentsIdList } from "./studentConfigs/selectors";

const HEARTBEAT_TIMEOUT = 5 * 1000; // 5 seconds
const PONG_IS_MISSING_TIMEOUT = 10 * 1000; // 10 seconds
const MAX_RETRY_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export const CONNECTION_SUCCESS_DELAY = 3 * 1000; // 3 seconds

export const WEBSOCKET_STATUS_TYPES = {
  CONNECTING: "connecting",
  OFFLINE: "offline",
  ONLINE: "online",
};

export const WEBSOCKET_MESSAGE_TYPES = {
  CONNECTION_SUCCESSFUL: "connection.successful",
  CONNECTION_PING: "connection.ping",
  CONNECTION_PONG: "connection.pong",

  STUDENT_ONLINE: "student.online",
  STUDENT_OFFLINE: "student.offline",
  STUDENT_DATA: "student.data",
  STUDENT_SCREEN: "student.screen",
  STUDENT_SNAPSHOT: "student.snapshot",

  SYSTEM_GUIDEBROWSING: "system.guidebrowsing",
  SYSTEM_PAUSE: "system.pausesession",
  SYSTEM_CLOSETAB: "system.closetab",
  SYSTEM_MESSAGE: "system.message",

  TEACHER_REQUEST: "teacher.request",
  TEACHER_SNAPSHOT: "teacher.snapshot",
  TEACHER_ONLINE: "teacher.online",

  STUDENT_REQUEST: "deledao.student.request",
};

export const WEBSOCKET_TEACHER_REQUEST_DATA_TYPES = {
  TABS: "tabs",
  SCREEN: "screen",
};

export const WEBSOCKET_TEACHER_REQUEST_TABS_ONLY = [
  WEBSOCKET_TEACHER_REQUEST_DATA_TYPES.TABS,
];

export const WEBSOCKET_TEACHER_REQUEST_SCREEN = [
  WEBSOCKET_TEACHER_REQUEST_DATA_TYPES.TABS,
  WEBSOCKET_TEACHER_REQUEST_DATA_TYPES.SCREEN,
];

export class HighlightsWebsocket {
  constructor(options) {
    // instance name to use in logging
    this.name = options.name || "";

    // WebSocket ref
    this.ws = null;

    // WebSocket lifecycle props to keep connected
    this.wsHeartbeatDelayId = null;
    this.wsRetryDelayId = null;
    this.wsLastPongMoment = null;

    // prop name to get the url from the store
    this.websocketUrlConfigPropName = options.websocketUrlConfigPropName || "";

    // redux action type for status updates
    this.updateWebsocketStatusActionType =
      options.updateWebsocketStatusActionType || "";

    // custom redux actions
    this.onWebsocketOpen = options.onWebsocketOpen || null;
    this.onWebsocketMessage = options.onWebsocketMessage || null;
    this.onWebsocketOffline = options.onWebsocketOffline || null;
    this.onWebsocketReconnectStart = options.onWebsocketReconnectStart || null;
  }

  doWebsocketClose() {
    const self = this;
    if (
      self.ws &&
      self.ws.readyState !== WebSocket.CLOSING &&
      self.ws.readyState !== WebSocket.CLOSED
    ) {
      self.ws.close();
    }
  }

  updateWebsocketStatus(status) {
    const self = this;
    return {
      type: self.updateWebsocketStatusActionType,
      payload: status,
    };
  }

  sendMessageToWebsocket(message) {
    const self = this;
    return (dispatch) => {
      if (self.ws && self.ws.readyState === WebSocket.OPEN) {
        console.debug(
          `[${self.name} ws] message sent`,
          _.get(message, "type", "")
        );
        self.ws.send(JSON.stringify(message));
      }
    };
  }

  sendPingToWebsocket() {
    const self = this;
    return (dispatch) =>
      dispatch(
        self.sendMessageToWebsocket({
          type: WEBSOCKET_MESSAGE_TYPES.CONNECTION_PING,
        })
      );
  }

  closeWebSocketConnection() {
    const self = this;
    return (dispatch) => {
      // stop monitoring socket status
      window.clearTimeout(self.wsHeartbeatDelayId);
      window.clearTimeout(self.wsRetryDelayId);
      self.wsLastPongMoment = null;

      // set socket and students status to OFFLINE
      dispatch(self.updateWebsocketStatus(WEBSOCKET_STATUS_TYPES.OFFLINE));
      if (self.onWebsocketOffline) {
        dispatch(self.onWebsocketOffline());
      }

      // close the socket
      self.doWebsocketClose();
    };
  }

  handleWebsocketRetries(delay) {
    const self = this;
    return (dispatch, getState) =>
      _.delay(() => {
        console.debug(
          `[${self.name} ws] retrying to open the socket, current delay is`,
          delay
        );
        if (delay > 0) {
          dispatch(
            sendEvent({ name: "websocketConnectionRetry", message: "" + delay })
          );
        }
        // attempt to reopen the socket
        const state = getState();
        const classId = getClassId(state);
        const students = getStudentsIdList(state);
        const teacherId = getUserEmail(state); //that is email as we use it for logging only in backend
        dispatch(self.openWsConnection({ classId, students, teacherId }));

        // schedule a new retry
        const newDelay = !delay
          ? 5000
          : delay * 2 > MAX_RETRY_TIMEOUT
          ? MAX_RETRY_TIMEOUT
          : delay * 2;

        self.wsRetryDelayId = dispatch(self.handleWebsocketRetries(newDelay));
      }, delay);
  }

  handleWebsocketHeartbeat() {
    const self = this;
    return (dispatch) =>
      _.delay(() => {
        console.debug(`[${self.name} ws] checking state`);

        // check if socket is alive and send a "ping" packet
        dispatch(self.sendPingToWebsocket());

        // check if last pong was more than PONG_IS_MISSING_TIMEOUT ms ago
        // AND handleWebsocketRetries is not started yet
        if (
          self.wsLastPongMoment &&
          moment().valueOf() - self.wsLastPongMoment.valueOf() >=
            PONG_IS_MISSING_TIMEOUT &&
          !self.wsRetryDelayId
        ) {
          console.debug(
            `[${self.name} ws] last pong was`,
            moment().valueOf() - self.wsLastPongMoment.valueOf(),
            "ms ago"
          );

          // set socket status to CONNECTING
          // and students to OFFLINE
          dispatch(
            self.updateWebsocketStatus(WEBSOCKET_STATUS_TYPES.CONNECTING)
          );
          if (self.onWebsocketOffline) {
            dispatch(self.onWebsocketOffline());
          }

          // start retries
          dispatch(self.handleWebsocketRetries(0));
          if (self.onWebsocketReconnectStart) {
            dispatch(self.onWebsocketReconnectStart());
          }
        }

        // schedule a new check
        self.wsHeartbeatDelayId = dispatch(self.handleWebsocketHeartbeat());
      }, HEARTBEAT_TIMEOUT);
  }

  openWsConnection({ classId, students, teacherId }) {
    const self = this;
    return (dispatch, getState) => {
      const doWebsocketOpen = () => {
        // get the websocket url
        const hLWebsocketURL = _.get(
          getClassConfig(getState()),
          self.websocketUrlConfigPropName,
          ""
        );

        // attempt to open a new websocket
        self.ws = new WebSocket(hLWebsocketURL);

        // set the new last pong time to now
        // so heartbeat handler can check against it
        self.wsLastPongMoment = moment();

        // start monitoring socket status
        window.clearTimeout(self.wsHeartbeatDelayId);
        self.wsHeartbeatDelayId = dispatch(self.handleWebsocketHeartbeat());

        self.ws.onopen = () => {
          console.debug(`[${self.name} ws] websocket opened`);

          // custom onOpen action
          if (self.onWebsocketOpen) {
            dispatch(self.onWebsocketOpen({ classId, students, teacherId }));
          }

          // send a ping message
          dispatch(self.sendPingToWebsocket());
        };

        self.ws.onclose = async () => {
          console.debug(`[${self.name} ws] websocket closed`);
        };

        self.ws.onerror = (error) => {
          console.debug(`[${self.name} ws] websocket error`, error);
        };

        self.ws.onmessage = (frame) => {
          const message = JSON.parse(frame.data || "{}") || {};

          const type = _.get(message, "type", "");
          const channel = _.get(message, "channel", "");

          console.debug(`[${self.name} ws] message received`, type, channel);

          // "socket is connected" confirmation is received
          if (type === WEBSOCKET_MESSAGE_TYPES.CONNECTION_SUCCESSFUL) {
            // delay status change from connecting to online
            // to give data from extension time to arrive before showing offline state
            _.delay(
              () =>
                dispatch(
                  self.updateWebsocketStatus(WEBSOCKET_STATUS_TYPES.ONLINE)
                ),
              CONNECTION_SUCCESS_DELAY
            );
          }

          self.wsLastPongMoment = moment();

          // clear retry timeouts
          window.clearTimeout(self.wsRetryDelayId);
          self.wsRetryDelayId = null;

          if (self.onWebsocketMessage) {
            dispatch(self.onWebsocketMessage(message));
          }
        };
      };

      // set socket status to CONNECTING
      dispatch(self.updateWebsocketStatus(WEBSOCKET_STATUS_TYPES.CONNECTING));

      // make sure previous socket is closed
      self.doWebsocketClose();

      doWebsocketOpen();
    };
  }
}
