import React, { useState, useEffect } from "react";
import { ModalDeprecated } from "@hapara/ui/src/deprecated/ModalDeprecated";
import { Button } from "@hapara/ui/src/atomic/Button";
import { ClassInfoNetworkErrorAlert } from "../../ClassInfoNetworkErrorAlert/index";
import { TextField } from "@hapara/ui/src/atomic/TextField";
import { useIntl } from "react-intl";

import styles from "./ClassInfoInputModalAddTeacher.module.scss";

export type ClassInfoInputModalAddTeacherProps = {
  isOpen: boolean;
  hasError?: boolean;
  errorMessage?: string;
  isInProgress?: boolean;
  showNetworkErrorBlock?: boolean;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  onAddTeacher: (inputValue: string) => void;
};

export const ClassInfoInputModalAddTeacher: React.FC<
  ClassInfoInputModalAddTeacherProps
> = ({
  isOpen,
  hasError,
  errorMessage,
  isInProgress,
  showNetworkErrorBlock,
  dataTestIdPrefix,
  onRequestClose,
  onAddTeacher,
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue("");
  }, [isOpen]);

  const handleAddAction = () => onAddTeacher(inputValue);

  const modalInputText = hasError
    ? errorMessage
    : intl.formatMessage({
        defaultMessage: "Example: email@domain.com",
        id: "QkTONR",
      });

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={onRequestClose}
      className={styles.modal}
      title={intl.formatMessage({
        defaultMessage: "Add teacher to class",
        id: "Xc+kwa",
      })}
      dataTestPrefix={dataTestIdPrefix}
    >
      <div className={styles.inputContainer}>
        <TextField
          value={inputValue}
          onChangeText={(e) => setInputValue(e)}
          hasError={hasError}
          label={intl.formatMessage({
            defaultMessage: "Teacher email",
            id: "YeMhfJ",
          })}
          hintText={modalInputText}
          dataTestId={`${dataTestIdPrefix}_Input`}
        />
        {showNetworkErrorBlock && (
          <ClassInfoNetworkErrorAlert className={styles.alertBox} />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          type="secondary"
          label={intl.formatMessage({ defaultMessage: "Cancel", id: "47FYwb" })}
          onClick={onRequestClose}
          dataTestId={`${dataTestIdPrefix}_Cancel`}
        />
        <Button
          type="primary"
          label={intl.formatMessage({
            defaultMessage: "Add teacher",
            id: "GSv1YV",
          })}
          onClick={handleAddAction}
          isLoading={isInProgress}
          dataTestId={`${dataTestIdPrefix}_Submit`}
          isDisabled={!inputValue}
        />
      </div>
    </ModalDeprecated>
  );
};
