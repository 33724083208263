import React from "react";
import styles from "./ChatBadge.module.scss";
import classnames from "classnames";
import { ChatContactPresence } from "../../types/state";

type ChatBadgeProps = { className?: string } & (
  | {
      unreadCount?: number;
      hideOutline?: boolean;
      presence?: never;
    }
  | {
      presence: ChatContactPresence;
      unreadCount?: never;
      hideOutline?: never;
    }
);

export const ChatBadge = ({
  unreadCount,
  presence,
  className,
  hideOutline,
}: ChatBadgeProps) => {
  if (!unreadCount && !presence) {
    return null;
  }

  const unreadCountLabel =
    unreadCount && unreadCount > 99 ? "99+" : unreadCount;
  const dataTestId = unreadCount
    ? "chat-badge-unread"
    : `chat-badge-${presence}`;
  const statusStyles = presence
    ? { [presence && styles[presence]]: true }
    : null;

  return (
    <div
      aria-hidden
      data-test-id={dataTestId}
      className={classnames(className, styles.container, {
        ...statusStyles,
        [styles.small]: presence,
        [styles.hideOutline]: hideOutline,
      })}
    >
      {unreadCountLabel}
    </div>
  );
};
