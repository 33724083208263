import { fetchClassesList } from "../../../apiCalls/dashboard";
import { ClassInfo } from "../../../types/dashboard";
import actionTypes from "../../actionTypes";
import { showAppError } from "../../app/actions";
import { AppDispatch } from "../../store";
import { LoadClassesSuccessAction } from "./types";
import _ from "lodash";

const SYNC_STATUS_UPDATE_INTERVAL = 3000;
let pollDelayId = 0;

const fetchSyncStatus = () => (dispatch: AppDispatch) => {
  fetchClassesList("", "", "", false)
    .then((data: ClassInfo[]) => {
      dispatch<LoadClassesSuccessAction>({
        type: actionTypes.DASHBOARD_CLASS_LIST_SYNC_SUCCESS,
        payload: data,
      });
      if (data.filter((cl) => cl.isClassSyncInProgress).length > 0) {
        dispatch(updateClassesListSyncStatus());
      } else {
        pollDelayId = 0;
      }
      return data;
    })
    .catch((error) => {
      dispatch(showAppError(error, "api-error"));
    });
};

export const updateClassesListSyncStatus = () => (dispatch: AppDispatch) => {
  pollDelayId = _.delay(
    () => dispatch(fetchSyncStatus()),
    SYNC_STATUS_UPDATE_INTERVAL
  );
};

export const stopStatusPolling = () => (dispatch: AppDispatch) => {
  if (pollDelayId) {
    window.clearTimeout(pollDelayId);
    pollDelayId = 0;
  }
};
