import React from "react";
import { withFormField, InjectedProps } from "../withFormField";
import { Input, InputProps } from "../Input";

const InputBridge = ({
  isDisabled,
  ...rest
}: Omit<InputProps, "onChange" | "isErrorMode"> & InjectedProps) => {
  return <Input {...rest} disabled={isDisabled} />;
};

export const TextField = withFormField(InputBridge);
