import _ from "lodash";
import actionTypes from "../../actionTypes";

const INIT_STATE = null;

const studentConfigsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS: {
      const { Students } = action.payload;
      return Students && Students.length ? _.keyBy(Students, "Id") : {};
    }

    default:
      return state;
  }
};

export default studentConfigsReducer;
