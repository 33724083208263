import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import Pill from "../Pill/Pill";
import style from "./Chip.module.scss";

export const chipTypes = ["success", "danger", "warning", "info"] as const;
type ChipType = (typeof chipTypes)[number];

type ChipProps = {
  label: string;
  icon?: React.ReactElement;
  dataTestIdPrefix?: string;
  onRemove?: () => void;
  isSmall?: boolean;
  type?: ChipType;
  className?: string;
  maxWidth?: number;
};

export const Chip = ({
  label,
  icon,
  dataTestIdPrefix,
  onRemove,
  isSmall,
  type,
  className,
  maxWidth,
}: ChipProps) => {
  const intl = useIntl();

  const removeAriaLabel = intl.formatMessage({
    defaultMessage: "Remove",
    id: "G/yZLu",
  });

  const colorMap: Record<ChipType, string> = {
    success: "success",
    danger: "danger",
    warning: "warning",
    info: "pale_blue",
  } as const;

  const dataTestId = dataTestIdPrefix ? `${dataTestIdPrefix}-Chip` : undefined;

  return (
    <div className={style.container}>
      <Pill
        className={classNames(className)}
        colour={type ? colorMap[type] : undefined}
        dataTestIdPrefix={dataTestId}
        actionLabel={onRemove ? removeAriaLabel : undefined}
        hasAction={!!onRemove}
        onClick={onRemove}
        removeBottomMargin
        size={isSmall ? "xsmall" : "small"}
        type="round"
        maxWidth={maxWidth}
        icon={icon}
      >
        {label}
      </Pill>
    </div>
  );
};
