import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./MultiSelectList.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import { THEME_TYPES } from "../consts";
import classnames from "classnames";
import { useIntl, FormattedMessage } from "react-intl";

const MultiSelectList = ({
  items = [],
  dataTestPrefix,
  selectedItems = [],
  disabledItems = [],
  onItemsSelect,
  singleItemName = <FormattedMessage defaultMessage="item" id="9vMZ1r" />,
  itemsName = <FormattedMessage defaultMessage="items" id="5rh4Wi" />,
}) => {
  const intl = useIntl();
  const handleSelect = (isChecked, item) => {
    if (isChecked) {
      onItemsSelect(selectedItems.filter((i) => i.id !== item.id));
    } else {
      onItemsSelect(selectedItems.concat([item]));
    }
  };

  const isAnythingSelected = selectedItems.length > 0;
  const isEverythingSelected = selectedItems.length === items.length;

  let selectAllLabel = "";
  if (selectedItems.length === 0) {
    selectAllLabel = intl.formatMessage(
      {
        defaultMessage: `Select all {itemsName}`,
        id: "ocGgbP",
        description:
          "itemsName could be items, classes, items, groups or students ",
      },
      { itemsName }
    );
  } else if (isEverythingSelected) {
    selectAllLabel = intl.formatMessage({
      defaultMessage: "All selected",
      id: "wdBMUQ",
    });
  } else if (selectedItems.length === 1) {
    selectAllLabel = intl.formatMessage(
      {
        defaultMessage: `1 {singleItemName} selected`,
        id: "AOh8HR",
        description: "singleItemName could be item, class, group or student",
      },
      { singleItemName }
    );
  } else {
    selectAllLabel = intl.formatMessage(
      {
        defaultMessage: `{selectedItemsCount} {itemsName} selected`,
        id: "2aKD3m",
        description:
          "itemsName could be items, classes, items, groups or students",
      },
      { selectedItemsCount: selectedItems.length, itemsName }
    );
  }

  const handleSelectAll = () => {
    if (isEverythingSelected) {
      onItemsSelect(
        selectedItems.filter((s) => _.find(disabledItems, (d) => d.id === s.id))
      );
    } else {
      onItemsSelect(items);
    }
  };

  return (
    <ul className={styles.multiSelect}>
      <li>
        <Checkbox
          checked={isAnythingSelected}
          semi={isAnythingSelected && !isEverythingSelected}
          onChange={handleSelectAll}
          label={selectAllLabel}
          dataTestIdPrefix={`${dataTestPrefix}-Checkbox`}
          themeType={THEME_TYPES.DARK}
          disabled={
            (selectedItems.length === disabledItems.length) === items.length
          }
        />
      </li>
      {items.map((i) => {
        const isChecked = selectedItems.filter((s) => s.id === i.id).length > 0;
        const isDisabled =
          disabledItems.filter((s) => s.id === i.id).length > 0;
        return (
          <li
            key={i.id}
            className={classnames({
              [styles.selectedItem]: isChecked && !isDisabled,
            })}
          >
            <Checkbox
              checked={isChecked}
              onChange={() => handleSelect(isChecked, i)}
              label={i.name || i.id}
              disabled={isDisabled}
              className={styles.checkboxLabel}
              dataTestIdPrefix={`${dataTestPrefix}-Checkbox`}
            />
          </li>
        );
      })}
    </ul>
  );
};

MultiSelectList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string })
  ),
  dataTestPrefix: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string })
  ),
  disabledItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string })
  ),
  onItemsSelect: PropTypes.func.isRequired,
  singleItemName: PropTypes.string,
  itemsName: PropTypes.string,
};

export default MultiSelectList;
