import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import classnames from "classnames";
import styles from "./Pickers.module.scss";

interface Group {
  URN: string;
  name: string;
  color: string;
  participants: string[];
  checked: boolean;
}

interface GroupStatus {
  areAllOffline: boolean;
  message: string | null;
}

interface GroupPickerProps {
  userGroups: Group[];
  groupUrns: string[];
  onGroupUrnsChange: (urns: string[]) => void;
  getGroupStatusInfo: (group: Group) => GroupStatus;
}

export const GroupPicker: React.FC<GroupPickerProps> = ({
  userGroups,
  groupUrns,
  onGroupUrnsChange,
  getGroupStatusInfo,
}) => {
  const intl = useIntl();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllSelectedIndeterminate, setIsAllSelectedIndeterminate] =
    useState(false);

  const isGroupSelected = (urn: string) => groupUrns.includes(urn);

  const handleGroupSelect = (isChecked: boolean, urn: string) => {
    if (isChecked) {
      onGroupUrnsChange([...groupUrns, urn]);
    } else {
      onGroupUrnsChange(groupUrns.filter((groupUrn) => groupUrn !== urn));
    }
  };

  const handleAllSelect = (isChecked: boolean) => {
    if (isChecked) {
      onGroupUrnsChange(userGroups.map((group) => group.URN));
    } else {
      onGroupUrnsChange([]);
    }
  };

  useEffect(() => {
    const amountOfSelected = groupUrns.length;
    const amountOfAll = userGroups.length;
    const allGroupsURNs = userGroups.map((g) => g.URN);
    const isIndeterminate =
      amountOfSelected >= 1 && amountOfSelected < amountOfAll;

    const isAllSelected =
      amountOfAll > 0
        ? amountOfSelected === amountOfAll &&
          JSON.stringify(groupUrns.sort()) ===
            JSON.stringify(allGroupsURNs.sort())
        : false;
    setIsAllSelected(isAllSelected);
    setIsAllSelectedIndeterminate(isIndeterminate);
  }, [userGroups, groupUrns]);

  const sortedGroups = userGroups.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  return (
    <div className={styles.secondaryGroup}>
      <div className={styles.secondaryGroupTitle}>
        <Checkbox
          label={intl.formatMessage({
            defaultMessage: "All groups",
            id: "V3nIzA",
          })}
          checked={isAllSelected}
          semi={isAllSelectedIndeterminate}
          onChange={(isChecked) => handleAllSelect(isChecked)}
          dataTestIdPrefix="guided-browsing-audience-all-groups"
        />
      </div>
      {sortedGroups.length === 0 ? (
        <div className={styles.secondaryGroupBody}>
          <div className={styles.secondaryGroupBodyEmpty}>
            {intl.formatMessage({
              defaultMessage: "No group(s) found.",
              id: "RTeYWv",
            })}
          </div>
        </div>
      ) : (
        <ul className={styles.secondaryGroupBody}>
          {sortedGroups.map((group) => {
            const groupStatus = getGroupStatusInfo(group);
            return (
              <li key={group.URN} className={styles.secondaryGroupBodyItem}>
                <Checkbox
                  label={
                    <span
                      className={styles.secondaryGroupBodyItemCheckboxInner}
                    >
                      <span
                        className={classnames(
                          styles.group,
                          styles[group.color]
                        )}
                      />
                      <span
                        className={classnames(styles.name, {
                          [styles.offline]: groupStatus?.areAllOffline,
                        })}
                      >
                        {group.name}
                      </span>
                      {groupStatus.message && (
                        <span className={styles.status}>
                          ({groupStatus.message})
                        </span>
                      )}
                    </span>
                  }
                  checked={isGroupSelected(group.URN)}
                  onChange={(isChecked: boolean) =>
                    handleGroupSelect(isChecked, group.URN)
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
