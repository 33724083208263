import React from "react";
import classnames from "classnames";
import styles from "./Cell.module.scss";
import { useTable } from "../../useTable";

type CellProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  align?: "left" | "center" | "right";
  _ariaRowIndex?: number;
  _ariaColIndex?: number;
};

export const Cell = ({
  children,
  style,
  align = "left",
  className,
  _ariaRowIndex,
  _ariaColIndex,
}: CellProps) => {
  const { dataTestIdPrefix } = useTable();
  const dataTestId =
    dataTestIdPrefix &&
    _ariaRowIndex !== undefined &&
    _ariaColIndex !== undefined
      ? `${dataTestIdPrefix}-table-row-${_ariaRowIndex}-cell-${_ariaColIndex}`
      : undefined;
  return (
    <td
      data-test-id={dataTestId}
      style={style}
      className={classnames(styles.container, className, {
        [styles[align]]: true,
      })}
    >
      {children}
    </td>
  );
};
