import _ from "lodash";
import { createSelector } from "reselect";

export const getViewScreenshotModalInfo = (state) =>
  _.get(state, "highlights.viewScreenshot");

export const getViewScreenshotModalStatus = createSelector(
  getViewScreenshotModalInfo,
  (modal) => _.get(modal, "isOpen", false)
);
