import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ResourceDetailsModalEditMode.module.scss";
import _ from "lodash";
import { connect } from "react-redux";
import { getResourceDetailsModalData } from "../../../../state/resources/selectors";
import {
  RESOURCE_DETAILS_MODAL_MODE,
  resourceItemType,
} from "../../../../state/resources/types";
import UploadFormTitle from "../../Library/UploadForm/UploadFormTitle";
import UploadFormDescription from "../../Library/UploadForm/UploadFormDescription";
import UploadFormAuthor from "../../Library/UploadForm/UploadFormAuthor";
import UploadFormTags from "../../Library/UploadForm/UploadFormTags";
import UploadFormLicence from "../../Library/UploadForm/UploadFormLicence";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { updateLibraryResource } from "../../../../state/library/uploadFile/actions";
import { showSuccessToast } from "../../../../state/app/actions";
import {
  updateResourceAfterEdit,
  updateResourceDetailsModalMode,
} from "../../../../state/resources/actions";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";
import { getFilters } from "../../../../state/shared/selectors";
import { areListsEqual } from "../../../../utils";
import ActionDelete from "../ActionDelete/ActionDelete";

const FORM_IDS = {
  h1: "library-edit-content-heading",
  title: "library-edit-content-title",
  description: "library-edit-content-description",
  licence: "library-edit-content-licence-radio-group",
  author: "library-edit-content-author",
};

const mapForInitMultiselect = (idsList, fullList) =>
  _.map(
    _.filter(fullList, (item) => _.includes(idsList, item.Key)),
    (item) => ({ id: item.Key, name: item.Value })
  );

const mapForSubmit = (multiSelectedDataList) =>
  _.map(multiSelectedDataList, "id");

const ResourceDetailsModal = ({
  modalData,
  grades,
  subjects,
  standards,
  resourceTypes,
  updateLibraryResource,
  dataTestPrefix,
  showSuccessMessage,
  goToReadMode,
  updateResourceAfterEdit,
}) => {
  const resourceId = _.get(modalData, "ID");

  const initTitle = _.get(modalData, "Title", "");
  const initDescription = _.get(modalData, "Description", "");
  const initLicenceType = _.get(modalData, "LicenceType", null);
  const initAuthor = _.get(modalData, "Author", "");
  const initSelectedGrades = _.get(modalData, "Grades", []) || [];
  const initSelectedSubjects = _.get(modalData, "Subjects", []) || [];
  const initSelectedStandards = _.get(modalData, "Authorities", []) || [];
  const initSelectedResourceTypes = _.get(modalData, "ResourceTypes", []) || [];
  const isShared = _.get(modalData, "Statuses.IsShared", false);

  const [title, setTitle] = useState(initTitle);
  const [description, setDescription] = useState(initDescription);
  const [licenceType, setLicenceType] = useState(initLicenceType);
  const [author, setAuthor] = useState(initAuthor);
  const [selectedGrades, setSelectedGrades] = useState(
    mapForInitMultiselect(initSelectedGrades, grades)
  );
  const [selectedSubjects, setSelectedSubjects] = useState(
    mapForInitMultiselect(initSelectedSubjects, subjects)
  );
  const [selectedStandards, setSelectedStandards] = useState(
    mapForInitMultiselect(initSelectedStandards, standards)
  );
  const [selectedResourceTypes, setSelectedResourceTypes] = useState(
    mapForInitMultiselect(initSelectedResourceTypes, resourceTypes)
  );

  const [submitFormError, setSubmitFormError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const isSubmitDisabled =
    !title ||
    !licenceType ||
    (_.isEqual(initTitle, title) &&
      _.isEqual(initDescription, description) &&
      _.isEqual(initLicenceType, licenceType) &&
      _.isEqual(initAuthor, author) &&
      areListsEqual(initSelectedGrades, mapForSubmit(selectedGrades)) &&
      areListsEqual(initSelectedSubjects, mapForSubmit(selectedSubjects)) &&
      areListsEqual(initSelectedStandards, mapForSubmit(selectedStandards)) &&
      areListsEqual(
        initSelectedResourceTypes,
        mapForSubmit(selectedResourceTypes)
      ));

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitFormError(false);
    setSubmitInProgress(true);

    const formData = {
      title,
      description,
      LicenceType: licenceType,
      Author: author,
      Standards: mapForSubmit(selectedStandards),
      Grades: mapForSubmit(selectedGrades),
      ResourceTypes: mapForSubmit(selectedResourceTypes),
      Subjects: mapForSubmit(selectedSubjects),
    };

    updateLibraryResource({ payload: formData, resourceId })
      .then((resp) => {
        if (!_.isEmpty(resp)) {
          const newData = {
            Title: _.get(resp, "title"),
            Description: _.get(resp, "description"),
            Author: _.get(resp, "author"),
            LicenceType: _.get(resp, "LicenceType"),
            Grades: _.get(resp, "Grades"),
            Subjects: _.get(resp, "Subjects"),
            Authorities: _.get(resp, "Authorities"),
            ResourceTypes: _.get(resp, "ResourceTypes"),
          };
          updateResourceAfterEdit({ itemId: resourceId, newData });
        }

        setSubmitInProgress(false);
        goToReadMode();
        showSuccessMessage();
      })
      .catch((e) => {
        setSubmitFormError(true);
        setSubmitInProgress(false);
      });
  };

  const uploadButton = (
    <Button
      label="Save changes"
      data-test-id={`${dataTestPrefix}-SubmitButton`}
      type={BUTTON_TYPES.PRIMARY}
      isDisabled={isSubmitDisabled}
      isLoading={submitInProgress}
      onClick={handleSubmit}
    />
  );

  const cancelButton = (
    <Button
      label="Cancel"
      data-test-id={`${dataTestPrefix}-CancelButton`}
      type={BUTTON_TYPES.OUTLINED}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      onClick={() => goToReadMode()}
    />
  );

  const errorAlert = (
    <Alert type={ALERT_TYPES.FAILURE} className={styles.errorBlock}>
      <span>
        <FormattedMessage
          defaultMessage="Sorry, there was a problem updating this resource. Please try again."
          id="PRIzM+"
        />
      </span>
    </Alert>
  );

  return (
    <form
      aria-labelledby={FORM_IDS.h1}
      onSubmit={handleSubmit}
      className={styles.root}
    >
      <div className={styles.header}>
        <h1
          data-test-id={`${dataTestPrefix}-EditMode-Title`}
          id={FORM_IDS.h1}
          className={styles.title}
        >
          <FormattedMessage
            defaultMessage="Edit uploaded resource"
            id="pB4Aj0"
          />
        </h1>

        <div className={styles.actions}>
          {cancelButton}
          {uploadButton}
        </div>
      </div>
      {submitFormError && errorAlert}
      <UploadFormTitle
        dataTestIdPrefix={dataTestPrefix}
        title={title}
        onTitleChange={setTitle}
        id={FORM_IDS.title}
      />
      <UploadFormDescription
        dataTestIdPrefix={dataTestPrefix}
        id={FORM_IDS.description}
        description={description}
        onDescriptionChange={setDescription}
      />
      <UploadFormAuthor
        dataTestIdPrefix={dataTestPrefix}
        id={FORM_IDS.author}
        author={author}
        onAuthorChange={setAuthor}
      />
      <UploadFormTags
        dataTestIdPrefix={dataTestPrefix}
        selectedGrades={selectedGrades}
        selectedResourceTypes={selectedResourceTypes}
        selectedStandards={selectedStandards}
        selectedSubjects={selectedSubjects}
        onSelectedGradesChange={setSelectedGrades}
        onSelectedResourceTypesChange={setSelectedResourceTypes}
        onSelectedStandardsChange={setSelectedStandards}
        onSelectedSubjectsChange={setSelectedSubjects}
      />
      <UploadFormLicence
        dataTestIdPrefix={dataTestPrefix}
        licenceType={licenceType}
        onLicenceTypeSelect={setLicenceType}
        id={FORM_IDS.licence}
      />
      {submitFormError && errorAlert}
      <div className={styles.footer}>
        {!isShared && (
          <ActionDelete
            id={resourceId}
            dataTestPrefix={dataTestPrefix}
            buttonItemProps={{ isFullWidth: false, type: BUTTON_TYPES.DANGER }}
          />
        )}
        <div className={styles.actions}>
          {cancelButton}
          {uploadButton}
        </div>
      </div>
    </form>
  );
};

ResourceDetailsModal.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  // connected
  modalData: resourceItemType,
  grades: PropTypes.arrayOf(filterItemType),
  subjects: PropTypes.arrayOf(filterItemType),
  standards: PropTypes.arrayOf(filterItemType),
  resourceTypes: PropTypes.arrayOf(filterItemType),
  updateLibraryResource: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  goToReadMode: PropTypes.func.isRequired,
  updateResourceAfterEdit: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    modalData: getResourceDetailsModalData(state),
    grades: getFilters(SHARED_FILTER_TYPES.GRADES.value)(state),
    subjects: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
    standards: getFilters(SHARED_FILTER_TYPES.STANDARDS.value)(state),
    resourceTypes: getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value)(state),
  }),
  (dispatch) => ({
    updateLibraryResource: ({ payload, resourceId }) =>
      dispatch(updateLibraryResource({ payload, resourceId })),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    goToReadMode: () =>
      dispatch(
        updateResourceDetailsModalMode(RESOURCE_DETAILS_MODAL_MODE.READ)
      ),
    updateResourceAfterEdit: ({ itemId, newData }) =>
      dispatch(updateResourceAfterEdit({ itemId, newData })),
  })
)(ResourceDetailsModal);
