export type School = {
  code: string;
  name: string;
  permissions: Permissions[];
};

export type Domain = {
  urn: string;
  schools: School[];
  permissions: Permissions[];
};

/* eslint-disable no-unused-vars */
export enum Permissions {
  DASHBOARD_ADD_CLASS = "dashboard:add:class",
  DASHBOARD_SYNC_GOOGLE_CLASSROOM = "dashboard:gc:sync",
  DASHBOARD_SHOW_ALL_CLASSES = "dashboard:manage:all",
}

/** @deprecated  */
export enum ClassTypeEnum {
  DATALOADED = "Dataloaded",
  GOOGLE_CLASSROOM = "GoogleClassroom",
  MANUAL = "SelfRostering",
}

export type ClassDataSourceType = `${ClassTypeEnum}`;

/* eslint-enable no-unused-vars */

export type GoogleClassroomCourse = {
  haparaClassId: string; //URN
  isHaparaClass: boolean;
  id: string;
  name: string;
  teacherFolderID: string;
  owner: string;
};
