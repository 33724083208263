import _ from "lodash";
import PropTypes from "prop-types";
import { SESSION_TYPES } from "../sessions/types";

export const REACT_PERFORMANCE_STATS_FREQUENCY = 1000 * 30; // 30 sec

export const STUDENT_STATUS_TYPES = {
  TEACHER_LOGGED_IN: "teacher_logged_in",
  INVALID_TIME: "invalid_time",
  IP_NOT_IN_RANGE: "ip_not_in_range",
};

export const STUDENT_GUIDE_BROWSING_TYPE = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Email: PropTypes.string,
  FullName: PropTypes.string.isRequired,
});

export const STUDENT_SEND_MESSAGE_TYPE = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Email: PropTypes.string,
  FullName: PropTypes.string.isRequired,
});

export const STUDENT_TYPE_OLD = PropTypes.shape({
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
  lastname: PropTypes.string,
});

export const STUDENT_TAB_CLOSE_STATUS = {
  CLOSED: "closed",
  PROGRESS: "progress",
  ERROR: "error",
};

export const STUDENT_SNAPSHOT_MATCH_TYPES = {
  URL: "url",
  DOMAIN: "domain",
};

export const STUDENT_BROWSER_TAB_TYPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool,
  when: PropTypes.number,
});

export const STUDENT_LOCK_LOCKING_TYPE = PropTypes.shape({
  active: PropTypes.bool,
  sessionID: PropTypes.string,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)),
  start: PropTypes.number,
  end: PropTypes.number,
  teacherFirstName: PropTypes.string,
  teacherLastName: PropTypes.string,
});

export const STUDENT_LOCK_PAUSE_TYPE = PropTypes.shape({
  active: PropTypes.bool,
  teacher: PropTypes.string,
  duration: PropTypes.number,
  lastMessage: PropTypes.number,
  id: PropTypes.string,
});

export const STUDENT_CURRENT_SCREEN_TYPE = PropTypes.shape({
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
});

export const STUDENT_SNAPSHOT_MATCH_TYPE = PropTypes.shape({
  match: PropTypes.oneOf(_.values(STUDENT_SNAPSHOT_MATCH_TYPES)),
});

export const CURRENT_SCREEN_SNAPSHOT_TYPE = PropTypes.shape({
  image: PropTypes.string,
  stamp: PropTypes.number,
  url: PropTypes.string,
});

export const INSTANCE_TYPE = PropTypes.shape({
  isOnline: PropTypes.bool,
  status: PropTypes.oneOf(_.values(STUDENT_STATUS_TYPES)),
  isFirstDataMessageReceived: PropTypes.bool,
  tabs: PropTypes.arrayOf(STUDENT_BROWSER_TAB_TYPE),
  instanceId: PropTypes.string,
  lock: PropTypes.shape({
    locking: STUDENT_LOCK_LOCKING_TYPE,
    pause: STUDENT_LOCK_PAUSE_TYPE,
  }),
  currentScreen: STUDENT_CURRENT_SCREEN_TYPE,
  users: PropTypes.arrayOf(PropTypes.string.isRequired),
  snapshot: CURRENT_SCREEN_SNAPSHOT_TYPE,
  onlineTimestamp: PropTypes.number,
});

export const STUDENT_TYPE = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Email: PropTypes.string.isRequired,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  instances: PropTypes.objectOf(INSTANCE_TYPE),
  currentInstance: PropTypes.string,
  lastSelectedInstance: PropTypes.string,
});

export const STUDENT_TYPE_REDUCED = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Email: PropTypes.string.isRequired,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  isOnline: PropTypes.bool,
});
