import { AppDispatch } from "../../store";
import actionTypes from "../../actionTypes";
import {
  fetchLibraryCollections,
  fetchNumberOfResourcesInCollection,
  postResourceCollections,
  getResourceCollections,
  postNewCollection,
  patchCollection,
  deleteCollection,
} from "../../../apiCalls/library";
import { updateAccessError } from "../../app/actions";
import { ApiError } from "../../../apiCalls/types";
import { Collection } from "./types";

export const loadLibraryCollections = () => (dispatch: AppDispatch) => {
  dispatch({
    type: actionTypes.LIBRARY_COLLECTIONS_LOAD_PENDING,
  });

  return fetchLibraryCollections()
    .then((data: Collection[]) => {
      dispatch({
        type: actionTypes.LIBRARY_COLLECTIONS_LOAD_SUCCESS,
        payload: data,
      });
    })
    .catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      }
      dispatch({
        type: actionTypes.LIBRARY_COLLECTIONS_LOAD_ERROR,
      });
    });
};

export const updateCollectionsEditMode = (inEditMode: boolean) => ({
  type: actionTypes.LIBRARY_COLLECTION_SET_EDIT_MODE,
  payload: inEditMode,
});

export const getNumberOfResourcesInCollection =
  (collectionId: string) => (dispatch: AppDispatch) => {
    return fetchNumberOfResourcesInCollection({ collectionId })
      .then((data: number) => {
        return data;
      })
      .catch((error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        }
        throw new Error(error.message);
      });
  };

export const createNewCollection =
  (collectionName: string) => (dispatch: AppDispatch) => {
    return postNewCollection({ collectionName }).catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      }
      throw new Error(error.message);
    });
  };

export const updateCollection =
  (collectionName: string, collectionId: string) => (dispatch: AppDispatch) => {
    return patchCollection({ collectionName, collectionId }).catch(
      (error: ApiError) => {
        if (error.responseStatus === 403) {
          dispatch(updateAccessError(true));
        }
        throw new Error(error.message);
      }
    );
  };

export const removeCollection =
  (collectionId: string) => (dispatch: AppDispatch) => {
    return deleteCollection({ collectionId }).catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      }
      throw new Error(error.message);
    });
  };

export const assignResourceToCollections =
  (
    resourceId: string,
    addCollectionsIds: string[],
    removeCollectionsIds: string[]
  ) =>
  (dispatch: AppDispatch) => {
    return postResourceCollections({
      resourceId,
      addCollectionsIds,
      removeCollectionsIds,
    }).catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      }
      throw new Error(error.message);
    });
  };

export const loadAssignedCollectionsList =
  (resourceId: string) => (dispatch: AppDispatch) => {
    return getResourceCollections({ resourceId }).catch((error: ApiError) => {
      if (error.responseStatus === 403) {
        dispatch(updateAccessError(true));
      }
      throw new Error(error.message);
    });
  };
