import React from "react";
import { connect } from "react-redux";
import styles from "./NoStudentsInGroupInfoPanel.module.scss";
import { userGroupsShowManagementPanel } from "../../../../state/shared/userGroups/actions";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const NoStudentsInGroupInfoPanel = ({ userGroupsShowManagementPanel }) => {
  return (
    <div className={styles.root} data-test-id="hs-NoStudentsInGroupInfoPanel">
      <h1 className={styles.title}>
        <FormattedMessage
          defaultMessage="There are no students in this group"
          id="blRBPw"
        />
      </h1>
      <div className={styles.text}>
        <FormattedMessage
          defaultMessage="You can add students to this group in <button>Edit Groups</button>"
          id="zozxLO"
          values={{
            button: (chunk) => (
              <button
                onClick={userGroupsShowManagementPanel}
                data-test-id="hs-NoStudentsInGroupInfoPanel-EditGroupsLink"
              >
                {chunk}
              </button>
            ),
          }}
        />
      </div>
    </div>
  );
};

NoStudentsInGroupInfoPanel.propTypes = {
  userGroupsShowManagementPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    userGroupsShowManagementPanel: (payload) =>
      dispatch(userGroupsShowManagementPanel(payload)),
  })
)(NoStudentsInGroupInfoPanel);
