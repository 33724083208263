import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getIsResourceDetailsModalOpen,
  getIsResourceAssignClassModalOpen,
  getIsResourceAddToCollectionsModalOpen,
  getIsResourceRemoveFromLibraryModalOpen,
  getIsResourceDeleteModalOpen,
} from "../../../../state/resources/selectors";
import ResourceDetailsModal from "../ResourceDetailsModal/ResourceDetailsModal";
import AssignClassesModal from "../AssignClassesModal/AssignClassesModal";
import AssignCollectionsModal from "../AssignCollectionsModal/AssignCollectionsModal";
import RemoveFromLibraryModal from "../RemoveFromLibraryModal/RemoveFromLibraryModal";
import DeleteResourceModal from "../DeleteResourceModal/DeleteResourceModal";

const ModalsAndPanels = ({
  isResourceDetailsModalOpen = false,
  isAssignClassesModalOpen = false,
  isResourceAddToCollectionsModalOpen = false,
  isResourceRemoveFromLibraryModalOpen = false,
  isResourceDeleteModalOpen = false,
}) => {
  return (
    <>
      {isResourceDetailsModalOpen && <ResourceDetailsModal />}
      {isAssignClassesModalOpen && <AssignClassesModal />}
      {isResourceAddToCollectionsModalOpen && <AssignCollectionsModal />}
      {isResourceRemoveFromLibraryModalOpen && <RemoveFromLibraryModal />}
      {isResourceDeleteModalOpen && <DeleteResourceModal />}
    </>
  );
};

ModalsAndPanels.propTypes = {
  isResourceDetailsModalOpen: PropTypes.bool,
  isAssignClassesModalOpen: PropTypes.bool,
  isResourceAddToCollectionsModalOpen: PropTypes.bool,
  isResourceRemoveFromLibraryModalOpen: PropTypes.bool,
  isResourceDeleteModalOpen: PropTypes.bool,
};

export default connect(
  (state) => ({
    isResourceDetailsModalOpen: getIsResourceDetailsModalOpen(state),
    isAssignClassesModalOpen: getIsResourceAssignClassModalOpen(state),
    isResourceAddToCollectionsModalOpen:
      getIsResourceAddToCollectionsModalOpen(state),
    isResourceRemoveFromLibraryModalOpen:
      getIsResourceRemoveFromLibraryModalOpen(state),
    isResourceDeleteModalOpen: getIsResourceDeleteModalOpen(state),
  }),
  (dispatch) => ({})
)(ModalsAndPanels);
