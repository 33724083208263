import { addDurationToTime } from "@hapara/ui/src/utils/addDurationToTime";
import { formatDateTimeLocal } from "@hapara/ui/src/intl/dateTime/formatDateTimeLocal";
import { timeInputToISOString } from "@hapara/ui/src/atomic/TimePickerField/timeInputToISOString";

/** TODO Current template endpoints expect and returns duration in ms, but will change to minutes in the future.
 * Once our Endpoints and UI has been updated to handle minutes durationInMinutes needs to be removed **/

export const getSessionEndTime = (scheduledTime: string, duration: number) => {
  const durationInMinutes = Math.round(duration / 60_000);
  return addDurationToTime(scheduledTime, durationInMinutes);
};

export const getLocalizedSessionEndTime = (
  scheduledTime: string,
  duration: number
) => {
  const durationInMinutes = Math.round(duration / 60_000);
  const endTime = addDurationToTime(scheduledTime, durationInMinutes);
  const endTimeLocal = formatDateTimeLocal(
    timeInputToISOString(endTime),
    "timeOnly"
  );

  return endTimeLocal ? endTimeLocal : endTime;
};
