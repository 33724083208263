import styles from "./HeaderContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getUserNameFormat } from "../../../../state/user/selectors";
import { loadClassConfig } from "../../../../state/highlights/config/actions";

import SmartshareButtons from "@hapara/ui/src/components/partial/Smartshare/SmartshareButtons/SmartshareButtons";
import { getFFByName } from "src/state/app/selectors";

const HeaderContent = () => {
  const nameFormat = useSelector(getUserNameFormat);
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const hasUpdatedSharedDrive = useSelector(
    getFFByName("PS-1264-shared-drive-picker-fix")
  );

  const handleSharesUpdate = () => {
    dispatch(
      loadClassConfig({ classId, shouldOpenWebsocketConnection: false })
    );
  };

  return (
    <div className={styles.root}>
      <SmartshareButtons
        hasUpdatedSharedDrive={hasUpdatedSharedDrive}
        onSharesUpdate={handleSharesUpdate}
        nameFormat={nameFormat}
        classUrn={classId}
      />
    </div>
  );
};
export default HeaderContent;
