import _ from "lodash";
import actionTypes from "../../actionTypes";
import {
  HighlightsWebsocket,
  WEBSOCKET_MESSAGE_TYPES,
  WEBSOCKET_TEACHER_REQUEST_TABS_ONLY,
  WEBSOCKET_TEACHER_REQUEST_SCREEN,
} from "../HighlightsWebsocket";
import {
  cleanAllStudentsInstanceData,
  updateStudentOnlineState,
  updateStudentStatus,
  updateStudentData,
  updateStudentSnapshot,
  updateExpandModalSnapshot,
} from "../students/actions";
import {
  getIsCurrentScreens,
  getIsBrowserTabs,
  getIsClassViewPage,
} from "../../router/selectors";
import {
  getBusWebsocketProcessedGuideBrowsing,
  getLastProcessedPause,
} from "./selectors";
import { getClassId } from "../../shared/selectors";
import { loadCurrentSessions } from "../sessions/actions";
import {
  getOnlineTime,
  isStudentOnline,
  getReconnectionTime,
} from "../students/selectors";
import { sendEvent } from "../../app/actions";
import moment from "moment";
import { getTokenSync } from "../../../apiCalls/jwtHandler";
import { getFFByName } from "../../app/selectors";
import { getCurrentScreenExpandModalStatus } from "../currentScreenExpandModal/selector";
import { getViewScreenshotModalStatus } from "../viewScreenshot/selectors";
import store from "../../store";

const updateWebsocketProcessedGBSession = ({
  sessionId,
  modificationTime,
}) => ({
  type: actionTypes.HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_GB_SESSION,
  payload: { sessionId, modificationTime },
});

const updateLastProcessedPause = ({ modificationTime }) => ({
  type: actionTypes.HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_PAUSE,
  payload: { modificationTime },
});

const handleUpdateStudentRequests =
  ({ data }) =>
  (dispatch) => {
    const studentRequests = _.get(
      store.getState(),
      "highlights.studentRequests.requests"
    );
    const activeTeacherEmail = _.get(
      store.getState(),
      "user.userMetadata.Email"
    );
    const studentRequest = _.find(studentRequests, { id: data.id });
    const {
      studentFirstName,
      studentLastName,
      url,
      category,
      id,
      studentEmail,
      teacherEmail,
      comment,
    } = data;
    const newRequest = {
      studentFirstName,
      studentLastName,
      url,
      category,
      id,
      studentEmail,
      teacherEmail,
      comment,
    };

    if (!studentRequest && teacherEmail === activeTeacherEmail) {
      return dispatch({
        type: actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_UPDATE,
        payload: [newRequest, ...studentRequests],
      });
    }
  };

const handleStudentDataMessage =
  ({ data, channel }) =>
  (dispatch, getState) => {
    const tabs = _.get(data, "data.tabs", null);
    const lock = _.get(data, "data.lock", null);
    const users = _.get(data, "data.users", []);
    const invalidReason = _.get(data, "data.invalid", null);
    const instanceId = _.get(data, "instance_id", null);

    if (!tabs) {
      if (!invalidReason) {
        dispatch(
          updateStudentStatus({ studentId: channel, status: null, instanceId })
        );
      } else {
        dispatch(
          updateStudentStatus({
            studentId: channel,
            status: invalidReason,
            instanceId,
          })
        );
      }
    } else {
      const state = getState();
      const isOnline = isStudentOnline(channel)(state);
      if (!isOnline) {
        //something is off, student should be online
        dispatch(sendEvent({ name: "studentDataOffline" }));
        dispatch(
          updateStudentOnlineState({
            studentId: channel,
            isOnline: true,
            instanceId,
          })
        );
      }
    }
    dispatch(
      updateStudentData({ studentId: channel, tabs, lock, instanceId, users })
    );
  };

const handleStudentOnlineMessage =
  ({ data, channel }) =>
  (dispatch, getState) => {
    const timestamp = _.get(data, "data.onlineTime", "");
    const instanceId = _.get(data, "instance_id", null);

    const state = getState();
    const isCurrentScreens = getIsCurrentScreens(state);
    const isBrowserTabs = getIsBrowserTabs(state);
    const isClassViewPage = getIsClassViewPage(state);
    const currentScreensFF = getFFByName("HAP-9105-Current-screens-default")(
      state
    );
    const requestData =
      isCurrentScreens || (isBrowserTabs && currentScreensFF) || isClassViewPage
        ? WEBSOCKET_TEACHER_REQUEST_SCREEN
        : WEBSOCKET_TEACHER_REQUEST_TABS_ONLY;
    dispatch(
      sendEvent({
        name: "handleStudentOnlineMessage",
        conversation_id: data.conversation_id,
        channel,
      })
    );
    dispatch(
      sendMessageToBusWebsocket({
        students: [channel],
        type: WEBSOCKET_MESSAGE_TYPES.TEACHER_REQUEST,
        data: { request: requestData },
      })
    );
    const onlineTime = Date.parse(timestamp);
    dispatch(
      updateStudentOnlineState({
        studentId: channel,
        isOnline: true,
        timestamp: isNaN(onlineTime) ? 0 : onlineTime,
        instanceId,
      })
    );
  };

const handleStudentOfflineMessage =
  ({ data, channel }) =>
  (dispatch, getState) => {
    const timestamp = _.get(data, "data.onlineTime", "");
    const instanceId = _.get(data, "instance_id", null);
    const state = getState();
    //we record the time for the student required to reconnect and if it's less than 20 sec,
    // we delay offline update to let the student to reconnect w/o it being visible for teachers
    const reconnectionTime = getReconnectionTime(channel)(state);
    dispatch(
      sendEvent({
        name: "handleStudentOfflineMessage",
        conversation_id: data.conversation_id,
        channel,
        reconnect_time: reconnectionTime,
      })
    );
    _.delay(() => {
      const state = getState();
      const onlineTime = Date.parse(timestamp);
      const currentOnlineTime = getOnlineTime(channel)(state);
      if (
        onlineTime &&
        !isNaN(currentOnlineTime) &&
        onlineTime < currentOnlineTime
      ) {
        //ignore old messages
        dispatch(sendEvent({ name: "ignoreOldOfflineMessage" }));
        return;
      }
      dispatch(
        updateStudentOnlineState({
          studentId: channel,
          isOnline: false,
          instanceId,
        })
      );
    }, reconnectionTime);
  };

const handleSystemGuidebrowsingMessage =
  ({ data }) =>
  (dispatch, getState) => {
    const sessionId = _.get(data, "data.Session.ID");
    const modificationTime = _.get(data, "data.Session.ModificationTime");
    const processedGuideBrowsing = getBusWebsocketProcessedGuideBrowsing(
      getState()
    );
    const lastProcessedModificationTime = _.get(
      processedGuideBrowsing,
      sessionId,
      null
    );
    dispatch(
      sendEvent({
        name: "handleSystemGuidebrowsingMessage",
        conversation_id: data.conversation_id,
      })
    );

    // ignore messages with same or earlier ModificationTime
    if (
      !lastProcessedModificationTime ||
      modificationTime > lastProcessedModificationTime
    ) {
      const classId = getClassId(getState());

      // update store with new ModificationTime
      dispatch(
        updateWebsocketProcessedGBSession({ sessionId, modificationTime })
      );
      // check guide browsing sessions
      dispatch(loadCurrentSessions({ classId }));
    }
  };

const handleSystemPause =
  ({ data }) =>
  (dispatch, getState) => {
    const modificationTimeStr = _.get(data, "data.TimeStamp");
    const modificationTime = moment(modificationTimeStr).toDate().getTime();
    const lastProcessedPause = getLastProcessedPause(getState());
    if (modificationTime > lastProcessedPause) {
      dispatch(updateLastProcessedPause({ modificationTime }));
      const classId = getClassId(getState());
      dispatch(loadCurrentSessions({ classId }));
    }
  };

const handleStudentSnapshot =
  ({ data, channel }) =>
  (dispatch, getState) => {
    const image = _.get(data, "data.image", null);
    const stamp = _.get(data, "data.stamp", null);
    const url = _.get(data, "data.url", null);
    const instanceId = _.get(data, "instance_id", null);

    const state = getState();

    if (
      getCurrentScreenExpandModalStatus(state) &&
      !getViewScreenshotModalStatus(state)
    ) {
      dispatch(
        updateExpandModalSnapshot({
          studentId: channel,
          image,
          url,
          instanceId,
        })
      );
    }

    if (
      getViewScreenshotModalStatus(state) &&
      !getCurrentScreenExpandModalStatus(state)
    ) {
      dispatch(
        updateStudentSnapshot({
          studentId: channel,
          snapshot: { image, stamp, url },
          instanceId,
        })
      );
    }
  };

// -------------------------
// Bus Websocket instance
// -------------------------

const busWebsocket = new HighlightsWebsocket({
  name: "bus",
  websocketUrlConfigPropName: "HLBusURL",
  updateWebsocketStatusActionType:
    actionTypes.HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_STATUS,

  onWebsocketOpen:
    ({ classId, students, teacherId }) =>
    (dispatch) => {
      dispatch(sendEvent({ name: "websocketConnect" }));
      // send class information
      dispatch(
        busWebsocket.sendMessageToWebsocket({
          students: students,
          user: teacherId,
          instance_id: teacherId,
          class_urn: classId,
          access_token: getTokenSync(),
        })
      );

      // check guide browsing sessions
      dispatch(loadCurrentSessions({ classId }));

      dispatch(
        busWebsocket.sendMessageToWebsocket({
          type: WEBSOCKET_MESSAGE_TYPES.TEACHER_ONLINE,
        })
      );

      // dismiss error state if any
      dispatch(updateBusWebsocketConnectionError(false));
    },

  onWebsocketOffline: () => (dispatch) => {
    dispatch(sendEvent({ name: "websocketOffline" }));
    dispatch(cleanAllStudentsInstanceData());
  },

  onWebsocketMessage: (data) => (dispatch) => {
    const type = _.get(data, "type", "");
    const channel = _.get(data, "channel", ""); // this is student ID

    // a packet for individual student is received
    if (channel) {
      if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_DATA) {
        dispatch(handleStudentDataMessage({ data, channel }));
      }

      if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_ONLINE) {
        dispatch(handleStudentOnlineMessage({ data, channel }));
      }

      if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_OFFLINE) {
        dispatch(handleStudentOfflineMessage({ data, channel }));
      }

      if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_SNAPSHOT) {
        dispatch(handleStudentSnapshot({ data, channel }));
      }
    }

    // guide browsing session update is received
    if (type === WEBSOCKET_MESSAGE_TYPES.SYSTEM_GUIDEBROWSING) {
      dispatch(handleSystemGuidebrowsingMessage({ data }));
    }
    // pause update is received
    if (type === WEBSOCKET_MESSAGE_TYPES.SYSTEM_PAUSE) {
      dispatch(handleSystemPause({ data }));
    }

    if (type === WEBSOCKET_MESSAGE_TYPES.STUDENT_REQUEST) {
      const requestData = data.data;
      dispatch(handleUpdateStudentRequests({ data: requestData }));
    }
  },

  onWebsocketReconnectStart: () => (dispatch) =>
    dispatch(updateBusWebsocketConnectionError(true)),
});

// exported actions

export const sendMessageToBusWebsocket = (message) => (dispatch) =>
  dispatch(busWebsocket.sendMessageToWebsocket(message));

export const closeBusWebsocketConnection = () => (dispatch) => {
  dispatch(busWebsocket.closeWebSocketConnection());
};

export const openBusWebsocketConnection =
  ({ classId, students, teacherId }) =>
  (dispatch) =>
    dispatch(busWebsocket.openWsConnection({ classId, students, teacherId }));

export const updateBusWebsocketConnectionError = (isError) => ({
  type: actionTypes.HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_CONNECTION_ERROR,
  payload: isError,
});
