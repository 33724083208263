import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import _ from "lodash";

import {
  getUserLoggingStatus,
  getUserPermissions,
} from "../../state/user/selectors";
import {
  isGISLoaded as getisGISLoaded,
  isGISLoading as getisGISLoading,
  isUserInitConfigLoaded as getIsUserInitConfigLoaded,
  isUserInitConfigLoading as getIsUserInitConfigLoading,
  isInitialLoadError as getIsInitialLoadError,
} from "../../state/app/selectors";
import { waitForGlobal } from "@hapara/ui/src/components/utils";
import { loadGIS, loadUserInitConfig } from "../../state/app/actions";
import { userDetailsUpdated } from "../../state/user/actions";
import NoAccessPage from "../containers/NoAccessPage/NoAccessPage";
import AppLoadingPage from "../containers/AppLoadingPage/AppLoadingPage";
import { initJwtHandling } from "../../apiCalls/jwtHandler";
import { getJWTRequestBody } from "@hapara/ui/src/components/utils";
import type { RouteProps, RouteComponentProps } from "react-router";
import { useAppSelector, useAppDispatch } from "../../state/hooks";

interface PublicRouteProps extends RouteProps {
  errorContent?: React.ReactNode;
  loadingContent?: React.ReactNode;
  pagePermissions?: Array<string>;
  title?: string;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const {
    component: Component,
    errorContent,
    loadingContent,
    pagePermissions,
    title,
  } = props;

  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector((state) => getUserLoggingStatus(state));
  const isGISLoaded = useAppSelector((state) => getisGISLoaded(state));
  const isGISLoading = useAppSelector((state) => getisGISLoading(state));
  const isUserInitConfigLoaded = useAppSelector((state) =>
    getIsUserInitConfigLoaded(state)
  );
  const isUserInitConfigLoading = useAppSelector((state) =>
    getIsUserInitConfigLoading(state)
  );
  const userPermissions = useAppSelector((state) => getUserPermissions(state));
  const isInitialLoadError = useAppSelector((state) =>
    getIsInitialLoadError(state)
  );

  initJwtHandling(getJWTRequestBody(null));

  // make sure GIS is loaded
  useEffect(() => {
    if (!isUserLoggedIn && !isGISLoaded && !isGISLoading) {
      waitForGlobal("window.google").then(() => {
        // window.google is ready to use
        dispatch(loadGIS(true));
      });
    }
  }, [dispatch, isUserLoggedIn, isGISLoaded, isGISLoading]);

  // make sure User Init Config is loaded on init
  useEffect(() => {
    if (isUserLoggedIn && !isUserInitConfigLoading && !isUserInitConfigLoaded) {
      dispatch(loadUserInitConfig({ isInitLoad: true, classId: null }));
    }
  }, [
    dispatch,
    isUserLoggedIn,
    isUserInitConfigLoading,
    isUserInitConfigLoaded,
  ]);

  // get user info from local storage and put in store for automation tests only
  useEffect(() => {
    const isAutomatedTests =
      window.localStorage.getItem("login-system") === "test";

    if (isAutomatedTests) {
      dispatch(
        userDetailsUpdated(
          JSON.parse(window.localStorage.getItem("user") || "[]")
        )
      );
    }
  }, [dispatch]);

  const isUserDataLoaded = isUserInitConfigLoaded;

  const getRouteComponent = ({
    title,
  }: {
    title: string | undefined;
    routerProps: RouteComponentProps;
  }) => {
    const LoadingComponent = (
      <AppLoadingPage
        isError={false}
        errorContent={errorContent}
        loadingContent={loadingContent}
      />
    );

    const NoAccessComponent = <NoAccessPage />;

    const ProtectedComponent = (
      // @ts-ignore

      <Component title={title} isUserDataLoaded={isUserDataLoaded} />
    );

    // let user with at least one permission listed in pagePermissions
    // to view the page
    const isUserHasNoAccess =
      pagePermissions &&
      pagePermissions.length > 0 &&
      !_.intersection(userPermissions, pagePermissions).length;

    if (isUserLoggedIn) {
      if (!isUserDataLoaded || isInitialLoadError) return LoadingComponent;
      if (isUserHasNoAccess) return NoAccessComponent;
    } else {
      if (!isGISLoaded || isInitialLoadError) return LoadingComponent;
    }

    return ProtectedComponent;
  };

  const { exact, path } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(routerProps) => getRouteComponent({ routerProps, title })}
    />
  );
};

PublicRoute.defaultTypes = {
  isLoginOnly: false,
  isNoAccess: false,
  errorContent: null,
  loadingContent: null,
  pagePermissions: null,
};

export default PublicRoute;
