import React from "react";
import PropTypes from "prop-types";

import styles from "./EmptyState.module.scss";
import classnames from "classnames";

const EmptyState = ({ message }) => {
  return <div className={classnames(styles.root)}>{message}</div>;
};

EmptyState.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyState;
