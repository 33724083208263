import { ClassInfo } from "../../../types/dashboard";
import { Domain, Permissions } from "../../../types/shared";
import { hasPermission } from "../../../utils";
import { RootState } from "../../store";
import { createSelector } from "reselect";

export const getClasses = (state: RootState): ClassInfo[] =>
  state.dashboard.manageClasses.classesList;

export const getIsClassesError = (state: RootState): boolean =>
  state.dashboard.manageClasses.isErrorLoading;

export const getIsClassesLoading = (state: RootState): boolean =>
  state.dashboard.manageClasses.isLoadingClasses;

export const getIsClassesLoaded = (state: RootState): boolean =>
  state.dashboard.manageClasses.isLoadedClasses;

export const getIsEditColourDialogOpened = (state: RootState): boolean =>
  state.dashboard.manageClasses.ui.editColourDialogOpened;

export const getEditedClass = (state: RootState): ClassInfo | undefined =>
  state.dashboard.manageClasses.ui.editedClass;

export const getIsAddClassDialogOpened = (state: RootState): boolean =>
  state.dashboard.manageClasses.ui.addClassDialogOpened;

export const getGoogleClassroomSyncDialogOpened = (state: RootState): boolean =>
  state.dashboard.manageClasses.ui.googleClassroomSyncOpened;

export const getSearchClassesQuery = (state: RootState): string =>
  state.dashboard.manageClasses.ui.searchClassesQuery;

export const getDomains = (state: RootState): Domain[] =>
  state.dashboard.config.domains;

// user permissions selectors
export const canAddManualClass = createSelector(
  getDomains,
  (domains: Domain[]): boolean => {
    let anySchoolHasPermission = false;
    domains.forEach((d) => {
      d.schools.forEach((s) => {
        if (hasPermission(s, Permissions.DASHBOARD_ADD_CLASS)) {
          anySchoolHasPermission = true;
        }
      });
    });
    return anySchoolHasPermission;
  }
);

export const canDoGoogleClassroomSync = createSelector(
  getDomains,
  (domains: Domain[]): boolean => {
    let anySchoolHasPermission = false;
    domains.forEach((d) => {
      d.schools.forEach((s) => {
        if (hasPermission(s, Permissions.DASHBOARD_SYNC_GOOGLE_CLASSROOM)) {
          anySchoolHasPermission = true;
        }
      });
    });
    return anySchoolHasPermission;
  }
);
