import React from "react";
import styles from "./TableHeader.module.scss";
import { Column } from "./Column";
import { TableCheckbox } from "../TableCheckbox";
import { useTable } from "../useTable";
import { injectProps } from "../utils/injectProps";

type TableHeaderProps = {
  children?: React.ReactNode;
};

export const TableHeader = ({ children }: TableHeaderProps) => {
  const { dataTestIdPrefix, onSelectAll, selected, rowCount } = useTable();

  const selectableTestId = onSelectAll ? "selectable-" : "";
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-table-${selectableTestId}head`
    : undefined;

  const handleSelectAll = () => {
    if (onSelectAll) {
      onSelectAll();
    }
  };

  const allSelected = selected?.length === rowCount;
  const someSelected = (selected?.length ?? 0) > 0 && !allSelected;

  return (
    <thead data-test-id={dataTestId}>
      <tr className={styles.container}>
        {onSelectAll ? (
          <Column width="46px" align="right">
            <TableCheckbox
              label={allSelected ? "Deselect all rows" : "Select all rows"}
              hideLabel
              checked={allSelected}
              semi={someSelected}
              dataTestIdPrefix={dataTestId}
              onChange={handleSelectAll}
            />
          </Column>
        ) : null}
        {injectProps(children, (index) => ({
          _ariaColIndex: index,
        }))}
      </tr>
    </thead>
  );
};
