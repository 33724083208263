import React, { useState, useReducer, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styles from "./groupsListMultipleBlock.module.scss";
import { generateId } from "../utils";
import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../../atomic/Button/Button";
import Checkbox from "../../atomic/Checkbox/Checkbox";
import SlidingGroupPanel from "../slidingGroupPanel/slidingGroupPanel";
import GroupItemSelect from "../groupItemSelect/groupItemSelect";

/**
 * Used to initialise state.
 * @param initValues Initial values needed to create new state based on groups and selected student.
 * @returns {array} Initial state.
 */
function init(initValues) {
  let initState = {
    groups: initValues.groups.map((group) => {
      group.selected = group.emails.includes(initValues.selectedStudent.email);
      return group;
    }),
    edited: false,
  };
  return initState;
}

/**
 * Reducer handling actions.
 * @param state {array} Current state
 * @param action {object} Current action holding a type and a payload.
 * @returns {array} New state.
 */
function reducer(state, action) {
  let newState;

  switch (action.type) {
    case "add":
      newState = Object.assign({}, state, {
        edited: true,
        groups: state.groups.map((item) => {
          if (item.urn === action.group.urn) {
            item.selected = true;
          }

          return item;
        }),
      });

      return newState;
    case "remove":
      newState = Object.assign({}, state, {
        edited: true,
        groups: state.groups.map((item) => {
          if (item.urn === action.group.urn) {
            item.selected = false;
          }

          return item;
        }),
      });

      return newState;
    case "reset":
      return Object.assign({}, state, {
        edited: false,
        groups: init(action.payload),
      });
    case "all":
      newState = Object.assign({}, state, {
        edited: true,
        groups: state.groups.map((item) => {
          item.selected = action.payload.add;

          return item;
        }),
      });

      return newState;
    default:
      throw new Error();
  }
}

/**
 *
 * @param selectedStudent
 * @param groups
 * @param placement
 * @param onClose
 * @param onAddToMultipleGroups
 * @returns {*}
 * @constructor
 */
const GroupsListMultipleBlock = ({
  selectedStudent,
  groups,
  placement,
  onClose,
  onAddToMultipleGroups,
  onCreateNewGroup,
  error,
  dialogLabelId,
}) => {
  // Because checkbox feature creates a way to add and delete in one place we need to keep track of what to add and what to delete.
  // We also need to keep track of checkbox states and master checkbox state (3 states one)
  // To address that, create a copy of data and keep track of selections. Before making the BE call filter out needed ids for add and for remove...
  const [state, dispatch] = useReducer(
    reducer,
    { groups, selectedStudent },
    init
  );
  const [loading, setLoading] = useState(false);
  const [errorOccurred, setError] = useState(error);

  const intl = useIntl();

  useEffect(() => {
    setError(error);

    return () => {
      setError({ id: null, text: "" });
    };
  }, [error]);

  if (!selectedStudent) {
    return null;
  }

  const masterCheckboxId = generateId();

  let handleAdd = () => {
    setLoading(true);
    onAddToMultipleGroups(selectedStudent.email, state.groups)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setError({
          id: "multipleAdd",
          text: intl.formatMessage({
            defaultMessage: "Sorry, there is a problem saving selection.",
            id: "6/svwY",
          }),
        });
        setLoading(false);
      });
  };

  return (
    <SlidingGroupPanel
      onClose={() => onClose()}
      hasOverlay={true}
      placement={placement}
      blocked={loading}
      theme="gray"
      errorOccurred={errorOccurred}
      onTryAgain={() => {
        setError({ id: null, text: "" });

        handleAdd();
      }}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <h1 className={styles.title} id={dialogLabelId}>
            <FormattedMessage
              defaultMessage="Groups assigned to {selectedStudent}"
              id="/b1j2B"
              values={{ selectedStudent: selectedStudent.fullName }}
            />
          </h1>
        </div>
        {!state.groups.length && (
          <div className={styles.emptyList}>
            <div>
              <FormattedMessage
                defaultMessage="You haven't created any groups yet."
                id="0xwGOr"
              />
            </div>
            <div className={styles.emptyListLink}>
              <Button
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.REGULAR}
                label={intl.formatMessage({
                  defaultMessage: "Create a group first.",
                  id: "nb9G7f",
                })}
                className={styles.buttonAsLink}
                onAction={() => {
                  onCreateNewGroup();
                }}
              />
            </div>
          </div>
        )}
        {state.groups.length > 0 && (
          <div className={styles.body}>
            <div className={styles.masterControl}>
              <Checkbox
                checked={
                  state.groups.filter((group) => group.selected).length ===
                  state.groups.length
                }
                id={masterCheckboxId}
                semi={
                  state.groups.filter((group) => group.selected).length !==
                    state.groups.length &&
                  state.groups.filter((group) => group.selected).length > 0
                }
                onChange={() =>
                  dispatch({
                    type: "all",
                    payload: {
                      add:
                        state.groups.filter((group) => group.selected)
                          .length === 0,
                    },
                  })
                }
                label={intl.formatMessage({
                  defaultMessage: "All Groups",
                  id: "/f7CTb",
                })}
                className={styles.masterCheckbox}
              />
            </div>
            <ul className={styles.optionList}>
              {state.groups.map((group) => {
                return (
                  <li className={styles.optionItem} key={group.urn}>
                    <GroupItemSelect
                      group={group}
                      selected={group.selected}
                      onChange={dispatch}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {state.edited && (
        <div className={styles.footer}>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            type={BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={() => {
              dispatch({
                type: "reset",
                payload: { groups: state.groups, selectedStudent },
              });
              onClose();
            }}
            data-test-id="GroupList-MultipleBlock-Edit-Cancel"
          />
          <Button
            label={intl.formatMessage({
              defaultMessage: "Save",
              id: "jvo0vs",
            })}
            isLoading={loading}
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={handleAdd}
            data-test-id="GroupList-MultipleBlock-Edit-Save"
          />
        </div>
      )}
    </SlidingGroupPanel>
  );
};

GroupsListMultipleBlock.propTypes = {
  selectedStudent: PropTypes.object,
  groups: PropTypes.array,
  preferences: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onAddToMultipleGroups: PropTypes.func,
  onCreateNewGroup: PropTypes.func,
  error: PropTypes.object,
  dialogLabelId: PropTypes.string,
};

export default GroupsListMultipleBlock;
