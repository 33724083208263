import React, { useContext, useRef } from "react";
import Button from "@hapara/ui/src/atomic/Button/Button";
import { FocusContext } from "@hapara/ui/src/components/utils";

export const MemorisedFocusButton = React.forwardRef(
  ({ onClick, ...props }, ref) => {
    const bRef = useRef();
    const buttonRef = ref || bRef;
    const focusContext = useContext(FocusContext);
    return (
      <Button
        {...props}
        ref={buttonRef}
        onClick={(e) => {
          focusContext.saveFocus(buttonRef);
          if (onClick) {
            onClick(e);
          }
        }}
      />
    );
  }
);

export default MemorisedFocusButton;
