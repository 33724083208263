import PropTypes from "prop-types";

export const GRID_BREAKPOINT_TYPES = {
  XXL: "xxl",
  XL: "xl",
  L: "l",
  M: "m",
  SM: "sm",
  S: "s",
  XS: "xs",
};

export const GRID_BREAKPOINTS = {
  [GRID_BREAKPOINT_TYPES.XXL]: 3199,
  [GRID_BREAKPOINT_TYPES.XL]: 1919,
  [GRID_BREAKPOINT_TYPES.L]: 1439,
  [GRID_BREAKPOINT_TYPES.M]: 1217,
  [GRID_BREAKPOINT_TYPES.SM]: 766,
  [GRID_BREAKPOINT_TYPES.S]: 515,
  [GRID_BREAKPOINT_TYPES.XS]: 0,
};

export const GRID_COLS = {
  [GRID_BREAKPOINT_TYPES.XXL]: 7,
  [GRID_BREAKPOINT_TYPES.XL]: 6,
  [GRID_BREAKPOINT_TYPES.L]: 5,
  [GRID_BREAKPOINT_TYPES.M]: 4,
  [GRID_BREAKPOINT_TYPES.SM]: 3,
  [GRID_BREAKPOINT_TYPES.S]: 2,
  [GRID_BREAKPOINT_TYPES.XS]: 1,
};

export const STUDENT_ORDER_TYPES = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  FIRST_AZ: "FIRST_AZ",
  LAST_AZ: "LAST_AZ",
  CUSTOM: "CUSTOM",
};

export const STUDENT_TILES_SIZE_TYPES = {
  REGULAR: "REGULAR",
  COMPACT: "COMPACT",
};

export const STUDENT_TILE_CONTENT_TYPE = {
  TABS: "tabs",
  SCREENS: "screens",
  SNAPS: "snaps",
  DRIVE: "drive",
  EDUBLOGS: "edublogs",
};

/// Remove with HAP-9292 HLR - Retire Items dropdown
export const STUDENT_ITEMS_PER_TILE_DROPDOWN_ITEMS = [3, 5, 15, 25];
export const STUDENT_ITEMS_PER_TILE_DEFAULT_VALUE = 5;
// end of removal

export const MONITORING_TIME_TYPE = PropTypes.shape({
  CurrentTime: PropTypes.string,
  Timezone: PropTypes.string,
  Start: PropTypes.string,
  End: PropTypes.string,
  Saturday: PropTypes.bool,
  Sunday: PropTypes.bool,
});

export const CLASS_CONFIG_TYPE = PropTypes.shape({
  Email: PropTypes.string,
  HLBusURL: PropTypes.string,
  ScreenshotBusURL: PropTypes.string,
  LogURL: PropTypes.string,
  Valid: PropTypes.bool,
  InvalidCode: PropTypes.string,
  MonitoringTime: MONITORING_TIME_TYPE,
  ClassURN: PropTypes.string,
  enableCloseReason: PropTypes.bool,
  PauseDuration: PropTypes.number,
  PauseScreenEnabled: PropTypes.bool,
  ScreenshotsEnabled: PropTypes.bool,
  StudentNotesEnabled: PropTypes.bool,
});

export const hlDriveDataTestIdPrefix = "hl-StudentDrive";
