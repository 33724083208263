import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./WSLeftSidePanel.module.scss";
import { getFilterCategoryType } from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
  MYWORKSPACES_CATEGORIES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import {
  getMyClassesSortedByName,
  getClassId,
  areMyClassesLoading,
  isMyClassesLoadingError,
} from "../../../../../state/shared/selectors";
import { myClassItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import SideNavItem, { SideNavDivider } from "../../../SideNavItem/SideNavItem";
import { APP_PAGES } from "../../../../../state/router/types";
import { getDataTestIdFromRawString } from "../../../../../utils";
import { SHARED_URL_KEYS } from "../../../../../state/shared/navigation-keys";
import { getMyClassesAction } from "../../../../../state/shared/actions";

export const WSLeftSidePanel = ({
  filterCategoryType,
  selectedClassId,
  isLoadingMyClasses,
  myClasses,
  isMyClassesLoadingError,
  getMyClassesAction,
}) => {
  const [isArchivedActive, setIsArchivedActive] = useState(false);
  const [isClassesActive, setIsClassesActive] = useState(false);

  useEffect(() => {
    setIsArchivedActive(
      filterCategoryType === MYWORKSPACES_FILTER_CATEGORY_TYPES.ARCHIVED
    );
    setIsClassesActive(
      filterCategoryType === MYWORKSPACES_FILTER_CATEGORY_TYPES.CLASSES
    );
  }, [filterCategoryType, setIsArchivedActive, setIsClassesActive]);

  const childlessPanelItems = _.pick(MYWORKSPACES_CATEGORIES, [
    "OWNED_BY_ME",
    "SHARED_WITH_ME",
    "LABELS",
    "PL",
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.legacyContainer}>
        <div className={styles.rootInner}>
          {_.map(childlessPanelItems, (panelItem, key) => {
            const active =
              filterCategoryType === MYWORKSPACES_FILTER_CATEGORY_TYPES[key];
            return (
              <SideNavItem
                to={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES[key].urlKey}`}
                icon={active ? panelItem.iconActive : panelItem.icon}
                title={panelItem.title}
                color="blue"
                isActive={active}
                key={key}
                data-test-id={`Ws-LeftPanel-Filter-${getDataTestIdFromRawString(
                  panelItem.title
                )}`}
              />
            );
          })}

          <SideNavDivider />
          <SideNavItem
            title={MYWORKSPACES_CATEGORIES.CLASSES.title}
            icon={
              isClassesActive
                ? MYWORKSPACES_CATEGORIES.CLASSES.iconActive
                : MYWORKSPACES_CATEGORIES.CLASSES.icon
            }
            color="blue"
            data-test-id="Ws-LeftPanel-ClassesArea"
            type="with-sub-items"
            emptyStateText="No classes found."
            showAllText="Show all classes"
            isLoading={isLoadingMyClasses}
            isError={isMyClassesLoadingError}
            onTryAgain={() => {
              getMyClassesAction();
            }}
            subItemsList={_.map(
              (myClasses || []).map((c) => ({
                email: c.id,
                display_name: c.class_alias,
              })),
              (item) => {
                const isSelectedClass = selectedClassId === item.email;
                return {
                  to: `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.CLASSES.urlKey}?${SHARED_URL_KEYS.classId.key}=${item.email}`,
                  title: item.display_name,
                  icon: "profile-group-filled",
                  isActive: isSelectedClass,
                  "data-test-id": "Ws-ClassList-Filter-Class",
                  "aria-label": `${item.display_name}${
                    isSelectedClass ? ", Selected" : ""
                  }`,
                };
              }
            )}
          />
          <SideNavDivider />
          <SideNavItem
            to={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES["ARCHIVED"].urlKey}`}
            icon={
              isArchivedActive
                ? MYWORKSPACES_CATEGORIES.ARCHIVED.iconActive
                : MYWORKSPACES_CATEGORIES.ARCHIVED.icon
            }
            title={MYWORKSPACES_CATEGORIES.ARCHIVED.title}
            color="blue"
            isActive={isArchivedActive}
            data-test-id={`Ws-LeftPanel-Filter-${getDataTestIdFromRawString(
              MYWORKSPACES_CATEGORIES.ARCHIVED.title
            )}`}
          />
        </div>
      </div>
    </div>
  );
};

WSLeftSidePanel.propTypes = {
  filterCategoryType: PropTypes.string,
  myClasses: PropTypes.arrayOf(myClassItemType),
  selectedClassId: PropTypes.string,
  isLoadingMyClasses: PropTypes.bool.isRequired,
  isMyClassesLoadingError: PropTypes.bool.isRequired,
  getMyClassesAction: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    filterCategoryType: getFilterCategoryType(state),
    myClasses: getMyClassesSortedByName(state),
    selectedClassId: getClassId(state),
    isLoadingMyClasses: areMyClassesLoading(state),
    isMyClassesLoadingError: isMyClassesLoadingError(state),
  }),
  (dispatch) => ({
    getMyClassesAction: () => dispatch(getMyClassesAction()),
  })
)(WSLeftSidePanel);
