import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./Pill.module.scss";
import HapReactIcon from "../icon/hapReactIcon";
import { Tooltip } from "../../components/Tooltip";
import { useOverflow } from "../../hooks/useOverflow";

export const PILL_COLOURS = {
  PALE_BLUE: "pale_blue",
  PALE_GRAY: "pale_gray",
  PALE_VIOLET: "pale_violet",
  NAVY: "navy",
  LIGHT_BLUE: "light_blue",
  DARK_BLUE: "dark_blue",
  GREEN: "green",
  MAGENTA: "magenta",

  // New colors for chip
  WARNING: "warning",
  DANGER: "danger",
  SUCCESS: "success",
  INFO: "info",
};

export const PILL_SIZES = {
  XSMALL: "xsmall",
  SMALL: "small",
};

export const PILL_MIN_HEIGHT = {
  [PILL_SIZES.XSMALL]: 28,
  [PILL_SIZES.SMALL]: 32,
};

export const PILL_TYPES = {
  SQUARE: "square",
  ROUND: "round",
};

const Pill = ({
  colour = PILL_COLOURS.PALE_BLUE,
  children,
  size = PILL_SIZES.SMALL,
  type = PILL_TYPES.ROUND,
  className = "",
  classNameBody = null,
  dataTestIdPrefix = "hs-Pill",
  hasAction = false,
  actionLabel = "",
  actionIcon = "cross",
  onClick = () => {},
  removeBottomMargin = false,
  maxWidth,
  icon,
}) => {
  const textRef = useRef(null);
  const { isOverflowing } = useOverflow(textRef);

  const pillContent = (
    <div
      className={classnames(
        styles.root,
        styles[type],
        styles[size],
        {
          [styles.removeBottomMargin]: removeBottomMargin,
        },
        className
      )}
      data-test-id={dataTestIdPrefix}
      style={maxWidth ? { maxWidth: `${maxWidth}px` } : undefined}
    >
      <div
        className={classnames(
          styles.body,
          styles[type],
          styles[size],
          styles[colour],
          classNameBody
        )}
      >
        {icon && <div className={styles.iconWrapper}>{icon}</div>}

        <span
          ref={textRef}
          className={classnames(styles.text, { [styles.ellipsis]: maxWidth })}
        >
          {children}
        </span>

        {hasAction && (
          <button
            type="button"
            onClick={onClick}
            className={styles.actionButton}
            aria-label={actionLabel}
            data-test-id={`${dataTestIdPrefix}-ActionButton`}
          >
            <HapReactIcon
              svg={actionIcon}
              className={classnames(styles.icon)}
            />
          </button>
        )}
      </div>
    </div>
  );

  return isOverflowing && maxWidth ? (
    <Tooltip offset={14} content={children} anchor={pillContent} />
  ) : (
    pillContent
  );
};

Pill.propTypes = {
  colour: PropTypes.oneOf(_.values(PILL_COLOURS)),
  size: PropTypes.oneOf(_.values(PILL_SIZES)),
  type: PropTypes.oneOf(_.values(PILL_TYPES)),
  children: PropTypes.node,
  icon: PropTypes.element,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  hasAction: PropTypes.bool,
  actionIcon: PropTypes.string,
  actionLabel: PropTypes.string,
  onClick: PropTypes.func,
  removeBottomMargin: PropTypes.bool,
  maxWidth: PropTypes.number,
};

/** @deprecated use the new `Chip` component instead. */
export default Pill;
