import { useState } from "react";
import classnames from "classnames";
import styles from "./EvidenceTypeSelection.module.scss";
import { Radio } from "@hapara/ui/src/atomic/Radio";

interface EvidenceTypeSelectionTypes {
  hasEvidence: boolean;
  dataTestId: string;
}

const EvidenceTypeSelection = ({
  hasEvidence,
  dataTestId,
}: EvidenceTypeSelectionTypes) => {
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const OPTION_CONTENT = {
    HEADING: "Sharing options:",
    STUDENT: "Copy per student",
    GROUP: "Copy per group",
  };

  return (
    <div
      className={classnames(styles.evidenceTypeContainer, {
        [styles.hasEvidence]: hasEvidence,
      })}
    >
      <fieldset aria-labelledby="fieldsetLabel">
        <div id="fieldsetLabel" className={styles.title}>
          {OPTION_CONTENT.HEADING}
        </div>
        <Radio
          className={styles.radio}
          name="evidenceTypeRadio"
          value="student"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          label={OPTION_CONTENT.STUDENT}
          dataTestIdPrefix={`${dataTestId}-student`}
        />
        <Radio
          name="evidenceTypeRadio"
          value="group"
          checked={!isChecked}
          onChange={() => setIsChecked(!isChecked)}
          label={OPTION_CONTENT.GROUP}
          dataTestIdPrefix={`${dataTestId}-group`}
        />
      </fieldset>
    </div>
  );
};

export default EvidenceTypeSelection;
