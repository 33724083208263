import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TemplatesList.module.scss";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_CONTENT_COLOUR_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { TEMPLATE_TILE_ID } from "../../../../../../state/highlights/guideBrowsingTemplates/type";
import { GUIDE_BROWSING_TEMPLATE_TYPE } from "../../../../../../state/highlights/sessions/types";
import {
  getTemplatesPanelTemplatesOffset,
  getTemplatesPanelIsMoreTemplatesAvailable,
} from "../../../../../../state/highlights/guideBrowsingTemplates/selectors";
import MemorisedFocusButton from "../../../../MemorisedFocusButton/MemorisedFocusButton";
import { fetchTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

const TemplatesList = ({
  templates,
  createNewSessionAction,
  dataTestPrefix,
  deleteTemplateAction,
  loadMoreTemplates,
  isMoreTemplatesAvailable,
  offset,
}) => {
  const intl = useIntl();
  const [isLoadMoreInProgress, setIsLoadMoreInProgress] = useState(false);
  const prevLastTemplateId = useRef();

  const actionsDropdownTrigger = useCallback(
    ({ onClick, ...props }) => (
      <MemorisedFocusButton
        icon="ellipsis"
        type={BUTTON_TYPES.OUTLINED}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        size={BUTTON_SIZES.XSMALL}
        {...props}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        data-test-id={`${dataTestPrefix}-template-openDropdown`}
        aria-label={intl.formatMessage({
          defaultMessage: "More actions for this template.",
          id: "3mbxQI",
        })}
      />
    ),
    [dataTestPrefix]
  );

  useEffect(() => {
    // set focus on newly loaded templates if "Load More" button clicked
    if (templates.length > 20) {
      const prevLastIndex = _.findIndex(templates, [
        "ID",
        prevLastTemplateId.current,
      ]);
      if (templates.length > prevLastIndex + 1) {
        const nextElementToFocus = templates[prevLastIndex + 1].ID;
        document
          .getElementById(`${TEMPLATE_TILE_ID}${nextElementToFocus}`)
          .focus();
      }
    }
  }, [templates]);

  const loadMoreHandler = async () => {
    setIsLoadMoreInProgress(true);
    prevLastTemplateId.current = templates.slice(-1)[0].ID;
    await loadMoreTemplates(offset);
    setIsLoadMoreInProgress(false);
  };

  return (
    <div className={styles.root}>
      <ul
        className={classnames(styles.templateList, {
          [styles.bottomPadding]: !isMoreTemplatesAvailable,
        })}
      >
        {templates.map((item) => (
          <li className={styles.templateItem} key={item.ID}>
            <article
              className={styles.templateContainer}
              onClick={() => {
                document
                  .getElementById(`${TEMPLATE_TILE_ID}${item.ID}`)
                  .click();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  document
                    .getElementById(`${TEMPLATE_TILE_ID}${item.ID}`)
                    .click();
                }
              }}
              data-test-id={`${dataTestPrefix}-template-openDetailsModal`}
            >
              <div className={styles.templateBullet}>
                <div
                  className={
                    item.Type === "focus"
                      ? styles.focusBullet
                      : styles.filterBullet
                  }
                ></div>
              </div>
              <section className={styles.templateSection}>
                <MemorisedFocusButton
                  className={styles.titleButton}
                  onClick={() => {
                    createNewSessionAction(item, false);
                  }}
                  aria-label={intl.formatMessage(
                    {
                      defaultMessage: "{name}, review template",
                      id: "eaG5GM",
                    },
                    { name: item.Name }
                  )}
                  data-test-id={`${dataTestPrefix}-templateTitle-openDetailsModal`}
                  label={<h3 className={styles.templateHeader}>{item.Name}</h3>}
                  id={`${TEMPLATE_TILE_ID}${item.ID}`}
                ></MemorisedFocusButton>
                <div className={styles.templateContent}>
                  {item.Type === "focus"
                    ? intl.formatMessage({
                        defaultMessage: "Focus session",
                        id: "mOW1DU",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Filter session",
                        id: "kKviVf",
                      })}
                </div>
              </section>
              <div className={styles.templateButtons}>
                <div className={styles.buttonWrap}>
                  <Dropdown
                    positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
                    colorType={DROPDOWN_CONTENT_COLOUR_TYPES.LIGHT_GREY}
                    triggerComponent={actionsDropdownTrigger}
                    itemComponentList={[
                      ({ onClick, ...rest }) => (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            createNewSessionAction(item, true);
                            onClick();
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            createNewSessionAction(item, true);
                            onClick();
                          }}
                          data-test-id={`${dataTestPrefix}-selectTemplate-dropdown-scheduleSession`}
                          {...rest}
                        >
                          <FormattedMessage
                            defaultMessage="Schedule session"
                            id="rjBOlB"
                          />
                        </button>
                      ),
                      ({ onClick, ...rest }) => (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteTemplateAction(item);
                            onClick();
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            deleteTemplateAction(item);
                            onClick();
                          }}
                          {...rest}
                          data-test-id={`${dataTestPrefix}-selectTemplate-dropdown-deleteTemplate`}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Delete",
                            id: "K3r6DQ",
                          })}
                        >
                          <span className={styles.delete}>
                            <FormattedMessage
                              defaultMessage="Delete template"
                              id="AghqfS"
                            />
                          </span>
                        </button>
                      ),
                    ]}
                  />
                </div>
              </div>
            </article>
          </li>
        ))}
      </ul>

      {isMoreTemplatesAvailable && (
        <Button
          className={styles.loadMoreBtn}
          label={intl.formatMessage({
            defaultMessage: "Load more",
            id: "00LcfG",
          })}
          data-test-id={`${dataTestPrefix}-LoadMoreButton`}
          type={BUTTON_TYPES.SECONDARY}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
          onClick={loadMoreHandler}
          isDisabled={isLoadMoreInProgress}
          isLoading={isLoadMoreInProgress}
        />
      )}
    </div>
  );
};

TemplatesList.propTypes = {
  templates: PropTypes.arrayOf(GUIDE_BROWSING_TEMPLATE_TYPE),
  createNewSessionAction: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  deleteTemplateAction: PropTypes.func.isRequired,
  loadMoreTemplates: PropTypes.func.isRequired,
  isMoreTemplatesAvailable: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    isMoreTemplatesAvailable: getTemplatesPanelIsMoreTemplatesAvailable(state),
    offset: getTemplatesPanelTemplatesOffset(state),
  }),
  (dispatch) => ({
    loadMoreTemplates: (offset) => dispatch(fetchTemplatesList(offset)),
  })
)(TemplatesList);
