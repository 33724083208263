import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown, {
  DROPDOWN_CONTENT_COLOUR_TYPES,
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { Button, ButtonProps } from "@hapara/ui/src/atomic/Button/Button";
import styles from "./PageRequests.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../hooks/useIsDimension";
import DropdownItem from "./DropdownItem";
import { selectStudentRequests } from "../../../state/highlights/studentRequests/selectors";
import { studentRequest } from "../../../state/highlights/studentRequests/types";
import { getIsHighlightsBase } from "../../../state/router/selectors";
import classnames from "classnames";

const STUDENT_REQUESTS_DATA_ID = "student-requests";

const loadingContent = () => {
  return (
    <div className={styles.fullHeightListItem}>
      <LinearProgress className={styles.progressbar} aria-hidden={true} />
      <p className={styles.listItemText}>Loading</p>
    </div>
  );
};

const emptyState = () => {
  return (
    <div className={styles.fullHeightListItem}>
      <p className={styles.listItemText}>There are no pending requests.</p>
    </div>
  );
};

const PagesRequests = () => {
  const requestData = useSelector(selectStudentRequests);
  const { isLoading, requests: studentRequests } = requestData;
  const [requestsNum, setRequestsNum] = useState(studentRequests.length || 0);
  const { isDimensionOnly: isMobileOnly } = useIsDimension(WINDOW_SIZE_TYPES.S);
  const isHighlightsPage = useSelector(getIsHighlightsBase);
  const [hasDisplayedRequests, setHasDisplayedRequests] = useState(false);

  useEffect(() => {
    setRequestsNum(studentRequests.length);
  }, [studentRequests]);

  useEffect(() => {
    if (studentRequests.length > 0 && hasDisplayedRequests === false) {
      setHasDisplayedRequests(true);
    }
  }, [studentRequests, hasDisplayedRequests]);

  const dropdownList = () => {
    const sortedList = studentRequests.sort(
      (a: studentRequest, b: studentRequest) => {
        const aDate = new Date(a.createdDateTime);
        const bDate = new Date(b.createdDateTime);
        return bDate.getTime() - aDate.getTime();
      }
    );

    return (
      <ul
        className={`${
          styles[sortedList.length > 1 ? "dropdownList" : "dropdownSingleItem"]
        }`}
      >
        {sortedList.map((studentRequest: studentRequest, index: number) => {
          return (
            <DropdownItem
              dataId={STUDENT_REQUESTS_DATA_ID}
              key={`${studentRequest.id}-${index}`}
              {...studentRequest}
            />
          );
        })}
      </ul>
    );
  };

  const triggerButton = ({ ...props }: ButtonProps) => {
    return (
      <Button
        dataTestId={`${STUDENT_REQUESTS_DATA_ID}-menu-button`}
        className={classnames(
          styles.requestButton,
          !requestsNum && styles.noRequests
        )}
        label={
          <span className={styles.words}>
            {requestsNum} {`${isMobileOnly ? "" : "student"}`}{" "}
            {!requestsNum || requestsNum > 1 ? "requests" : "request"}
          </span>
        }
        {...props}
      />
    );
  };

  const dropdownTitle = () => {
    const isPlural = requestsNum !== 1;
    const title = `Student ${isPlural ? "requests" : "request"} to unblock ${
      isPlural ? "sites" : "site"
    }`;
    return (
      <div className={styles.dropdownTitle}>
        <span className={styles.title}>{title}</span>
      </div>
    );
  };

  const contentToDisplay = useMemo(() => {
    if (isLoading) {
      return loadingContent;
    }
    if (studentRequests.length === 0) {
      return emptyState;
    }
    return dropdownList;
  }, [isLoading, studentRequests]);

  return (
    <div className={styles.root}>
      {isHighlightsPage && hasDisplayedRequests && (
        <Dropdown
          classNameContent={styles.dropdownContent}
          className={styles.dropdown}
          colorType={DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY}
          positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
          triggerComponent={triggerButton}
          dropdownTitle={dropdownTitle}
          itemComponentList={[contentToDisplay]}
        />
      )}
    </div>
  );
};

export default PagesRequests;
