import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationQuery } from "../../../router/selectors";
import { getFilterCategoryType } from "../list/selectors";
import {
  MYWORKSPACES_URL_KEYS,
  MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../list/navigation";
import { MYWORKSPACES_CATEGORIES } from "../list/types";

const getSearch = (state) => _.get(state, "workspace.myworkspaces.search");
export const getSearchResults = (state) =>
  _.get(state, "workspace.myworkspaces.search.items");
export const getSearchResultTotalItems = (state) =>
  _.get(state, "workspace.myworkspaces.search.totalItems");
export const getSearchPagesCount = (state) =>
  _.get(state, "workspace.myworkspaces.search.pagesCount");
export const getSearchPageSize = (state) =>
  _.get(state, "workspace.myworkspaces.search.pageSize");
export const getIsSearchLoading = (state) =>
  _.get(state, "workspace.myworkspaces.search.loading");

export const getIsSearchActive = createSelector(
  [getSearch, getLocationQuery, getFilterCategoryType],
  (search, locationQuery, category) => {
    const paramName = MYWORKSPACES_URL_KEYS.query.key;
    return (
      category === MYWORKSPACES_CATEGORIES.SEARCH.type &&
      !!_.get(locationQuery, paramName)
    );
  }
);

export const getSearchQuery = createSelector(
  [getSearch, getLocationQuery],
  (search, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.query.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = rawParam ? window.decodeURIComponent(rawParam) : "";
    return parsedParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getSearchCurrentPage = createSelector(
  [getSearch, getLocationQuery],
  (search, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.search_page.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = parseInt(rawParam, 10);
    return parsedParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getMyWSSearchGuid = createSelector(getSearch, (d) =>
  _.get(d, "searchGuid", null)
);

export const getMyWSSearchIteration = createSelector(getSearch, (d) =>
  _.get(d, "searchIteration", 0)
);
