import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import styles from "./GuideBrowsingCreateForm.module.scss";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../../../state/shared/selectors";
import {
  STUDENT_TYPE,
  STUDENT_GUIDE_BROWSING_TYPE,
} from "../../../../../../state/highlights/students/types";
import { updateNotificationSession } from "../../../../../../state/highlights/notification/actions";
import {
  loadGuideBrowsingRecentTabsHistory,
  addGuideBrowsingRecentTabsHistory,
  loadGuideBrowsingTemplates,
  updateGuideBrowsingTemplate,
  scheduleSession,
  reloadScheduledSessionAfterAddingNew,
} from "../../../../../../state/highlights/sessions/actions";
import ModalFormLinks from "../../../ModalFormLinks/ModalFormLinks";
import ModalFormDuration from "../../../ModalFormDuration/ModalFormDuration";
import ModalFormEndOfSession from "../../../ModalFormEndOfSession/ModalFormEndOfSession";
import ModalFormStudentPicker from "../../../ModalFormStudentPicker/ModalFormStudentPicker";
import ModalFormSchedule from "../../../ModalFormSchedule/ModalFormSchedule";
import ModalFormFreezeTypeSelection from "../../../ModalFormFreezeTypeSelection/ModalFormFreezeTypeSelection";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  SESSION_RECIPIENT_TYPES,
  GUIDE_BROWSING_DUPLICATION_SESSION_TYPE,
  SESSION_TYPES,
  GUIDE_BROWSING_TEMPLATE_TYPE,
  parseNewTemplateDataToOldFormat,
  PICKER_DISABLED_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import { fetchTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import {
  getGuideBrowsingModalSelectedStudent,
  getGuideBrowsingModalIfScheduled,
  getGuideBrowsingModalSessionData,
  isGuideBrowsingDuplicateSession,
  getRecentTabsHistory,
} from "../../../../../../state/highlights/sessions/selectors";
import { getUserNameFormat } from "../../../../../../state/user/selectors";
import {
  getGroupStatusInfo,
  getUserGroups,
} from "../../../../../../state/shared/userGroups/selectors";
import { USER_GROUP_TYPE } from "../../../../../../state/shared/userGroups/types";
import TemplateMode from "./TemplateMode";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Input from "@hapara/ui/src/atomic/Input/Input";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { getIsOutsideSchoolHours } from "../../../../../../state/highlights/config/selectors";
import {
  pickerDisabledState,
  extractScheduleTimeFromSession,
} from "../../../../../../utils";
import { getMonitoringTime } from "../../../../../../state/highlights/config/selectors";
import { MONITORING_TIME_TYPE } from "../../../../../../state/highlights/config/types";
import { getStudentConfigsList } from "../../../../../../state/highlights/studentConfigs/selectors";
import { hideTemplatesPanel } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { pendoTrack } from "../../../../../../utils";

const dataTestPrefix = "hl-GuideBrowsingCreateSession";

const fieldsValues = (
  selectedTemplate,
  existingSession,
  isDuplicateSession,
  students,
  userGroups,
  isScheduledInitValue
) => {
  if (!_.isEmpty(selectedTemplate)) {
    const sessionData = {
      TemplateName: _.get(selectedTemplate, "Name", ""),
      Links: _.get(selectedTemplate, "Links", []),
      FocusType: _.get(
        selectedTemplate,
        "FocusSessionDetails.FocusType",
        GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
      ),

      Duration: _.get(selectedTemplate, "SelectedTime", null),
      IsEndSessionKeepAllTabs: _.get(
        selectedTemplate,
        "FocusSessionDetails.EndSessionKeepAllTabs",
        true
      ),
      IsEndSessionRestoreOriginal: _.get(
        selectedTemplate,
        "FocusSessionDetails.EndSessionRestoreOriginal",
        true
      ),
      RecipientType: _.get(
        selectedTemplate,
        "RecipientType",
        SESSION_RECIPIENT_TYPES.CLASS
      ),
      IsScheduledSession: isScheduledInitValue,
      ScheduledDate: moment(),
      ScheduledTime: moment().startOf("hour").add(1, "hour"),
    };
    if (sessionData.RecipientType === SESSION_RECIPIENT_TYPES.CLASS) {
      sessionData.StudentIds = _.map(students, (student) => student.Id);
    }
    return sessionData;
  }

  if (!_.isEmpty(existingSession)) {
    const sessionId = _.get(existingSession, "SessionId");
    const scheduledDateTime = isDuplicateSession
      ? extractScheduleTimeFromSession(existingSession)
      : _.get(existingSession, "ScheduledDateTime");
    const recipientType = _.get(
      existingSession,
      "Recipient.Type",
      SESSION_RECIPIENT_TYPES.CLASS
    );
    let groupUrns = _.get(existingSession, "Recipient.Groups", []);
    let studentIds = _.get(existingSession, "Recipient.Students", []) || [];
    if (recipientType === SESSION_RECIPIENT_TYPES.CLASS) {
      studentIds = _.map(students, (student) => student.Id);
    } else if (recipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      groupUrns = groupUrns.filter((g) =>
        _.find(userGroups, (item) => item.URN === g)
      ); //filtering out groups that do not exist
      _.forEach(groupUrns, (urn) => {
        const group = _.find(userGroups, (item) => item.URN === urn);
        const groupParticipants = _.get(group, "participants", []);
        studentIds = _.union(studentIds, groupParticipants);
      });
    }
    // sessionId not empty - Edit session, show provided date
    // sessionId is null and scheduledDateTime provided -> duplicate existing. Date selector should show placeholder
    // no sessionID and no scheduledDateTime -> create a new session. Date selector should show today's date
    const scheduledDate =
      scheduledDateTime && sessionId
        ? moment(scheduledDateTime)
        : scheduledDateTime
        ? null
        : moment();

    return {
      Links: _.get(existingSession, "Links", []),
      RecipientType: recipientType,
      StudentIds: studentIds,
      GroupUrns: groupUrns,
      IsScheduledSession: _.get(existingSession, "IsScheduledSession", true),
      ScheduledDate: scheduledDate,
      ScheduledTime: scheduledDateTime
        ? moment(scheduledDateTime)
        : moment().startOf("hour").add(1, "hour"),
      FocusType: _.get(
        existingSession,
        "FocusSessionDetails.FocusType",
        GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
      ),
      IsEndSessionKeepAllTabs: _.get(
        existingSession,
        "FocusSessionDetails.EndSessionKeepAllTabs",
        true
      ),
      IsEndSessionRestoreOriginal: _.get(
        existingSession,
        "FocusSessionDetails.EndSessionRestoreOriginal",
        true
      ),
      Duration: _.get(existingSession, "Duration", null),
      SessionName: _.get(existingSession, "SessionName"),
    };
  }
  return {
    Links: [],
    FocusType: GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE,
    Duration: null,
    IsEndSessionKeepAllTabs: true,
    IsEndSessionRestoreOriginal: true,
    RecipientType: SESSION_RECIPIENT_TYPES.CLASS,
    StudentIds: _.map(students, (student) => student.Id),
    GroupUrns: [],
    IsScheduledSession: isScheduledInitValue,
    ScheduledDate: moment(),
    ScheduledTime: moment().startOf("hour").add(1, "hour"),
  };
};

const GuideBrowsingCreateForm = ({
  mainHeaderId,
  onCancel,
  sessionType,
  selectedTemplate,
  isScheduledInitValue,
  onTemplateSelect,
  selectedClassId,
  existingSessionData,
  students = [],
  nameFormat,
  userGroups,
  recentTabs,
  updateNotificationSession,
  scheduleSession,
  loadRecentTabsHistory,
  reloadScheduledSessionAfterAddingNew,
  addRecentTabsHistory,
  selectedStudent,
  getGroupStatusInfo,
  loadGuideBrowsingTemplates,
  updateGuideBrowsingTemplate,
  isOutsideSchoolHours,
  monitoringTime,
  isDuplicateSession,
  hideTemplatesPanel,
  loadTemplatesList,
}) => {
  const intl = useIntl();
  const SESSION_TYPES_NAMES = {
    [SESSION_TYPES.FOCUS]: intl.formatMessage({
      defaultMessage: "Focus",
      id: "hsJlm7",
    }),
    [SESSION_TYPES.FILTER]: intl.formatMessage({
      defaultMessage: "Filter",
      id: "9Obw6C",
    }),
    [SESSION_TYPES.LOCK]: intl.formatMessage({
      defaultMessage: "Freeze",
      id: "EgadwQ",
    }),
    [SESSION_TYPES.SHARE_LINKS]: intl.formatMessage({
      defaultMessage: "Links",
      id: "qCcwo3",
    }),
  };

  const selectedTemplateId = _.get(selectedTemplate, "ID", "");
  const initVals = fieldsValues(
    selectedTemplate,
    existingSessionData,
    isDuplicateSession,
    students,
    userGroups,
    isScheduledInitValue
  );

  const [links, setLinks] = useState(initVals.Links || []);
  const [focusType, setFocusType] = useState(
    initVals.FocusType || GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
  );
  const [duration, setDuration] = useState(initVals.Duration || null);
  const [isEndSessionKeepAllTabs, setIsEndSessionKeepAllTabs] = useState(
    initVals.IsEndSessionKeepAllTabs
  );
  const [isEndSessionRestoreOriginal, setIsEndSessionRestoreOriginal] =
    useState(initVals.IsEndSessionRestoreOriginal);
  const [recipientType, setRecipientType] = useState(
    initVals.RecipientType || SESSION_RECIPIENT_TYPES.CLASS
  );
  const [studentIds, setStudentIds] = useState(initVals.StudentIds || []);
  const [groupUrns, setGroupUrns] = useState(initVals.GroupUrns || []);
  const [templateName, setTemplateName] = useState(initVals.TemplateName);

  const [isSaveTemplateMode, setIsSaveTemplateMode] = useState(false);
  const [isTemplateSaveSuccess, setIsTemplateSaveSuccess] = useState(false);
  const [isTemplateSaveInProgress, setIsTemplateSaveInProgress] =
    useState(false);
  const [isTemplateSaveError, setIsTemplateSaveError] = useState(false);

  const [isScheduledSession, setIsScheduledSession] = useState(
    initVals.IsScheduledSession
  );
  const [scheduledDate, setScheduledDate] = useState(initVals.ScheduledDate);
  const [scheduledTime, setScheduledTime] = useState(initVals.ScheduledTime);
  const [isScheduleInProgress, setIsScheduleInProgress] = useState(false);
  const [isScheduleError, setIsScheduleError] = useState(false);

  const [isScheduledDateError, setIsScheduledDateError] = useState(false);
  const [isScheduledTimeError, setIsScheduledTimeError] = useState(false);

  const templateSaveSuccessRef = useRef();

  const sessionName = initVals.SessionName;

  const sessionTypeName = SESSION_TYPES_NAMES[sessionType];

  // load resent tabs on mount
  useEffect(() => {
    loadRecentTabsHistory();
  }, [loadRecentTabsHistory]);

  // update session data when individual student is selected
  useEffect(() => {
    if (!_.isEmpty(selectedStudent)) {
      setRecipientType(SESSION_RECIPIENT_TYPES.STUDENTS);
      setStudentIds([selectedStudent.Id]);
    }
  }, [selectedStudent]);

  // hide success message after 2 seconds
  useEffect(() => {
    let timerId = null;
    if (isTemplateSaveSuccess) {
      timerId = _.delay(() => setIsTemplateSaveSuccess(false), 2000);
    }
    return () => window.clearTimeout(timerId);
  }, [isTemplateSaveSuccess, setIsTemplateSaveSuccess]);

  // update template name on selectedTemplate change
  useEffect(() => {
    setTemplateName(initVals.TemplateName);
  }, [initVals.TemplateName, setTemplateName]);

  // set schedule toggle ON if out of school hours
  useEffect(() => {
    if (
      isOutsideSchoolHours &&
      (sessionType === SESSION_TYPES.FOCUS ||
        sessionType === SESSION_TYPES.FILTER)
    ) {
      setIsScheduledSession(true);
    }
  }, [isOutsideSchoolHours, sessionType, setIsScheduledSession]);

  const getSessionDetails = () => {
    const sessionDetails = {
      ClassURN: selectedClassId,
      Type: sessionType,
      Duration: duration * 60 * 1000,
      RecipientType: recipientType,
      Students: studentIds,
    };
    if (
      sessionType === SESSION_TYPES.FOCUS ||
      sessionType === SESSION_TYPES.FILTER
    ) {
      sessionDetails.Links = links;
    }
    if (sessionType === SESSION_TYPES.FOCUS) {
      sessionDetails.FocusSessionDetails = {
        FocusType: focusType,
        EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
        EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
      };
    }
    if (sessionType === SESSION_TYPES.LOCK) {
      sessionDetails.FocusSessionDetails = {
        FocusType: focusType,
      };
    }
    if (recipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      sessionDetails.Groups = groupUrns;
    }
    if (isScheduledSession) {
      const hour = scheduledTime.format("HH");
      const minute = scheduledTime.format("mm");
      const formattedDate = scheduledDate.hour(hour).minute(minute);
      sessionDetails.ScheduledTime = formattedDate.toISOString();
    }
    const sessionId = _.get(existingSessionData, "SessionId");
    if (sessionId && sessionId !== "") {
      sessionDetails.ID = sessionId;
    }

    if (sessionName) {
      sessionDetails.SessionName = sessionName;
    }

    return sessionDetails;
  };

  const handleStartSession = (e) => {
    e.preventDefault();

    const sessionDetails = getSessionDetails();
    const pendoObject = {
      classURN: sessionDetails?.ClassURN,
      sessionType: sessionDetails?.Type,
      duration: sessionDetails?.Duration
        ? `${Math.floor(sessionDetails?.Duration / 60000)} minutes`
        : undefined,
      recipientType: sessionDetails?.RecipientType,
      students: sessionDetails?.Students?.join(", "),
      links: sessionDetails?.Links?.join(", "),
      focusType: sessionDetails?.FocusSessionDetails?.FocusType,
      isEndSessionKeepAllTabs:
        sessionDetails?.FocusSessionDetails?.EndSessionKeepAllTabs,
      isEndSessionRestoreOriginal:
        sessionDetails?.FocusSessionDetails?.EndSessionRestoreOriginal,
    };
    selectedTemplate
      ? pendoTrack("Guided Browsing Start Session From Template", pendoObject)
      : pendoTrack(
          `Guided Browsing Session Start - ${sessionType}`,
          pendoObject
        );
    addRecentTabsHistory({ urls: links });
    updateNotificationSession({ sessionDetails, sessionType });
    hideTemplatesPanel();
    onCancel();
  };

  const handleScheduleSession = (e) => {
    e.preventDefault();

    const sessionDetails = getSessionDetails();

    addRecentTabsHistory({ urls: links });

    setIsScheduleInProgress(true);
    setIsScheduleError(false);

    const isSelectedDateOutOfSchoolHours = pickerDisabledState(
      PICKER_DISABLED_TYPES.DATE,
      monitoringTime
    )(scheduledDate);
    const isSelectedTimeOutOfSchoolHours = pickerDisabledState(
      PICKER_DISABLED_TYPES.TIME,
      monitoringTime,
      scheduledDate
    )(scheduledTime);

    const pendoData = {
      ClassURN: sessionDetails?.ClassURN,
      Type: sessionDetails?.Type,
      Duration: sessionDetails?.Duration
        ? `${Math.floor(sessionDetails?.Duration / 60000)} minutes`
        : undefined,
      RecipientType: sessionDetails?.RecipientType,
      Students: sessionDetails?.Students?.join(", "),
      Links: sessionDetails?.Links?.join(", "),
      FocusType: sessionDetails?.FocusSessionDetails?.FocusType,
      EndSessionKeepAllTabs:
        sessionDetails?.FocusSessionDetails?.EndSessionKeepAllTabs,
      EndSessionRestoreOriginal:
        sessionDetails?.FocusSessionDetails?.EndSessionRestoreOriginal,
      ScheduledTime: sessionDetails?.ScheduledTime
        ? new Date(sessionDetails?.ScheduledTime).toLocaleString()
        : undefined,
    };

    pendoTrack("Guided Browsing Schedule Session", pendoData);

    if (isSelectedDateOutOfSchoolHours || isSelectedTimeOutOfSchoolHours) {
      if (isSelectedDateOutOfSchoolHours) setIsScheduledDateError(true);
      if (isSelectedTimeOutOfSchoolHours) setIsScheduledTimeError(true);
      setIsScheduleInProgress(false);
    } else if (
      scheduledTime.add(1, "minutes").isBefore() &&
      moment(scheduledDate).isSame(moment(), "day")
    ) {
      setIsScheduledTimeError(true);
    } else if (scheduledDate.endOf("day").isBefore()) {
      setIsScheduledDateError(true);
    } else {
      scheduleSession({ sessionDetails, classId: selectedClassId })
        .then((data) => {
          setIsScheduleInProgress(false);
          reloadScheduledSessionAfterAddingNew();
          onCancel();
        })
        .catch(() => {
          setIsScheduleInProgress(false);
          setIsScheduleError(true);
        });
    }
    hideTemplatesPanel();
  };

  const handleTemplateCreate = (template) => {
    setIsSaveTemplateMode(false);
    onTemplateSelect(template);
    setIsTemplateSaveSuccess(true);
    _.delay(
      () =>
        templateSaveSuccessRef.current &&
        templateSaveSuccessRef.current.focus(),
      200
    );
    loadGuideBrowsingTemplates(selectedClassId);
    const pendoData = {
      templateId: template.ID,
      creatorEmail: template.Creator,
      createdDateTime: new Date(template.CreatedDatetime).toLocaleString(),
      classURN: template.ClassURN,
      templateName: template.Name,
      sessionType: template.Type,
      links: template?.Links?.join(", "),
      focusType: template?.FocusSessionDetails.FocusType,
      isEndSessionKeepAllTabs:
        template.FocusSessionDetails.EndSessionKeepAllTabs,
      isEndSessionRestoreOriginal:
        template.FocusSessionDetails.EndSessionRestoreOriginal,
      recipientType: template.RecipientType,
      duration: `${template.SelectedTime} minutes`,
    };
    pendoTrack("Guided Browsing Create Template", pendoData);
  };

  const handleTemplateSave = (e) => {
    e.preventDefault();
    setIsTemplateSaveInProgress(true);
    setIsTemplateSaveError(false);
    const guidedBrowsingTemplateData = {
      id: selectedTemplateId,
      classId: selectedClassId,
      templateName: templateName,
      sessionData: parseNewTemplateDataToOldFormat({
        Name: templateName,
        Type: sessionType,
        Links: links,
        FocusSessionDetails: {
          FocusType: focusType,
          EndSessionKeepAllTabs: isEndSessionKeepAllTabs,
          EndSessionRestoreOriginal: isEndSessionRestoreOriginal,
        },
        RecipientType: recipientType,
        SelectedTime: duration,
      }),
    };
    updateGuideBrowsingTemplate(guidedBrowsingTemplateData)
      .then((data) => {
        setIsTemplateSaveInProgress(false);
        handleTemplateCreate(data);
      })
      .catch(() => {
        setIsTemplateSaveInProgress(false);
        setIsTemplateSaveError(true);
      })
      .finally(() => {
        loadTemplatesList();
      });
  };

  const switchRecipientType = (rt) => {
    setRecipientType(rt);
    // when recipientType changes, update studentIds for "class" type
    if (rt === SESSION_RECIPIENT_TYPES.CLASS) {
      setStudentIds(_.map(students, (student) => student.Id));
    }
    // when recipientType changes, update studentIds for "students" type
    if (rt === SESSION_RECIPIENT_TYPES.STUDENTS) {
      setStudentIds([]);
    }
    // when recipientType changes, update studentIds for "groups" type
    if (rt === SESSION_RECIPIENT_TYPES.GROUPS) {
      setStudentIds([]);
    }
  };

  const isStartButtonDisabled = useMemo(() => {
    if (
      !links.length &&
      (sessionType === SESSION_TYPES.FILTER ||
        sessionType === SESSION_TYPES.FOCUS)
    ) {
      return true;
    }
    if (
      recipientType === SESSION_RECIPIENT_TYPES.STUDENTS &&
      !studentIds.length
    ) {
      return true;
    }
    if (
      recipientType === SESSION_RECIPIENT_TYPES.GROUPS &&
      (!studentIds.length || !groupUrns.length)
    ) {
      return true;
    }
    if (isScheduledSession && (!scheduledDate || !scheduledTime)) {
      return true;
    }
    if (isOutsideSchoolHours && !isScheduledSession) {
      return true;
    }
    if (!duration) {
      return true;
    }
    return false;
  }, [
    links,
    recipientType,
    studentIds,
    groupUrns,
    isScheduledSession,
    scheduledDate,
    scheduledTime,
    isOutsideSchoolHours,
    sessionType,
    duration,
  ]);

  const isSaveTemplateButtonDisabled = useMemo(() => !links.length, [links]);

  const isUpdateTemplateButtonDisabled = useMemo(() => {
    return !(
      (_.xor(_.get(selectedTemplate, "Links"), links).length > 0 &&
        links.length > 0) ||
      _.get(selectedTemplate, "SelectedTime") !== duration ||
      _.get(selectedTemplate, "RecipientType") !== recipientType ||
      _.get(selectedTemplate, "FocusType") !== focusType ||
      _.get(selectedTemplate, "EndSessionKeepAllTabs") !==
        isEndSessionKeepAllTabs ||
      _.get(selectedTemplate, "EndSessionRestoreOriginal") !==
        isEndSessionRestoreOriginal ||
      (_.get(selectedTemplate, "Name") !== templateName && templateName)
    );
  }, [
    links,
    duration,
    recipientType,
    focusType,
    isEndSessionKeepAllTabs,
    isEndSessionRestoreOriginal,
    templateName,
    selectedTemplate,
  ]);

  return (
    <form className={styles.root} aria-labelledby={mainHeaderId}>
      <div className={styles.fields}>
        {selectedTemplate && (
          <div className={styles.templateName}>
            <Input
              type="text"
              placeholder={intl.formatMessage({
                defaultMessage: "Enter template name",
                id: "dCFxw3",
              })}
              data-test-id={`${dataTestPrefix}-EditTemplate-TitleInput`}
              aria-required={true}
              aria-label={intl.formatMessage({
                defaultMessage: "Title",
                id: "9a9+ww",
              })}
              aria-describedby={`${dataTestPrefix}-EditTemplate-TitleError`}
              value={templateName}
              onChange={(e) => setTemplateName(_.get(e, "target.value", ""))}
              isErrorMode={!templateName}
              className={styles.templateNameInput}
            />
            {!templateName && (
              <div
                className={styles.templateNameError}
                id={`${dataTestPrefix}-EditTemplate-TitleError`}
              >
                <FormattedMessage
                  defaultMessage="This template needs a title"
                  id="sAw2Qn"
                />
              </div>
            )}
          </div>
        )}

        {(sessionType === SESSION_TYPES.FOCUS ||
          sessionType === SESSION_TYPES.FILTER) && (
          <ModalFormLinks
            links={links}
            onLinksChange={setLinks}
            focusType={focusType}
            onFocusTypeChange={setFocusType}
            sessionType={sessionType}
            dataTestPrefix={dataTestPrefix}
            recentTabs={recentTabs}
          />
        )}
        {sessionType === SESSION_TYPES.LOCK && (
          <ModalFormFreezeTypeSelection
            focusType={focusType}
            onFocusTypeChange={setFocusType}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        <ModalFormDuration
          duration={duration}
          onDurationChange={setDuration}
          dataTestPrefix={dataTestPrefix}
        />
        {sessionType === SESSION_TYPES.FOCUS && (
          <ModalFormEndOfSession
            isEndSessionKeepAllTabs={isEndSessionKeepAllTabs}
            onEndSessionKeepAllTabsChange={setIsEndSessionKeepAllTabs}
            isEndSessionRestoreOriginal={isEndSessionRestoreOriginal}
            onEndSessionRestoreOriginalChange={setIsEndSessionRestoreOriginal}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {_.isEmpty(selectedStudent) && (
          <ModalFormStudentPicker
            recipientType={recipientType}
            onRecipientTypeChange={switchRecipientType}
            studentIds={studentIds}
            onStudentIdsChange={setStudentIds}
            groupUrns={groupUrns}
            onGroupUrnsChange={setGroupUrns}
            dataTestPrefix={dataTestPrefix}
            nameFormat={nameFormat}
            userGroups={userGroups}
            getGroupStatusInfo={getGroupStatusInfo}
            blockTitle={intl.formatMessage({
              defaultMessage: "Who is this session for?",
              id: "Vq2Dd1",
            })}
            studentsInGbSessionWarning={intl.formatMessage({
              defaultMessage:
                "Some students are already in an active Guided Session. A new session will override the active session.",
              id: "HtpT8t",
            })}
          />
        )}
        {(sessionType === SESSION_TYPES.FOCUS ||
          sessionType === SESSION_TYPES.FILTER) && (
          <ModalFormSchedule
            dataTestPrefix={dataTestPrefix}
            isScheduledSession={isScheduledSession}
            onScheduledSessionChange={setIsScheduledSession}
            scheduledDate={scheduledDate}
            onScheduledDateChange={(ts) => {
              setIsScheduledDateError(false);
              setScheduledDate(ts);
            }}
            scheduledTime={scheduledTime}
            onScheduledTimeChange={(ts) => {
              setIsScheduledTimeError(false);
              setScheduledTime(ts);
            }}
            isScheduledDateError={isScheduledDateError}
            isScheduledTimeError={isScheduledTimeError}
          />
        )}
      </div>

      <div className={styles.actions}>
        <Alerts>
          <Alert
            type={ALERT_TYPES.FAILURE}
            isVisible={isTemplateSaveError}
            className={styles.alert}
          >
            <FormattedMessage
              defaultMessage="Sorry, there was a problem saving this template. Please try again."
              id="no0cc9"
            />
          </Alert>
          <Alert
            type={ALERT_TYPES.FAILURE}
            isVisible={isScheduleError}
            className={styles.alert}
          >
            <FormattedMessage
              defaultMessage="Sorry, there was a problem scheduling this session. Please try again."
              id="fhgTf7"
            />
          </Alert>
        </Alerts>
        <div className={styles.actionsInner}>
          {!isSaveTemplateMode && (
            <>
              {sessionType !== SESSION_TYPES.LOCK && (
                <>
                  {!selectedTemplate && (
                    <Button
                      label={intl.formatMessage({
                        defaultMessage: "Save as a template",
                        id: "x/FRit",
                      })}
                      onClick={() => setIsSaveTemplateMode(true)}
                      data-test-id={`${dataTestPrefix}-Button-SaveTemplate-Open-${sessionTypeName}`}
                      type={BUTTON_TYPES.OUTLINED}
                      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                      isDisabled={isSaveTemplateButtonDisabled}
                    />
                  )}
                  {selectedTemplate && (
                    <Button
                      label={intl.formatMessage({
                        defaultMessage: "Save changes",
                        id: "X0ha1a",
                      })}
                      onClick={handleTemplateSave}
                      data-test-id={`${dataTestPrefix}-Button-UpdateTemplate-${sessionTypeName}`}
                      type={BUTTON_TYPES.OUTLINED}
                      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                      isDisabled={isUpdateTemplateButtonDisabled}
                      isLoading={isTemplateSaveInProgress}
                    />
                  )}
                  {selectedTemplate && isTemplateSaveSuccess && (
                    <div
                      className={styles.successMessage}
                      tabIndex={-1}
                      ref={templateSaveSuccessRef}
                    >
                      <HapReactIcon
                        className={styles.successMessageIcon}
                        svg="circle-check-fill"
                        width="16"
                        height="16"
                      />
                      <span>
                        <FormattedMessage
                          defaultMessage="Template saved"
                          id="0fwVtw"
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "47FYwb",
                })}
                onClick={onCancel}
                data-test-id={`${dataTestPrefix}-Button-Cancel`}
                type={BUTTON_TYPES.SECONDARY}
                className={styles.cancelButton}
              />
              {!isScheduledSession && (
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Start Session",
                    id: "OC45ne",
                  })}
                  onClick={handleStartSession}
                  data-test-id={`${dataTestPrefix}-Button-Start${sessionTypeName}`}
                  className={styles.startButton}
                  isDisabled={isStartButtonDisabled}
                />
              )}
              {isScheduledSession && (
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Schedule Session",
                    id: "8Rz5NF",
                  })}
                  onClick={handleScheduleSession}
                  data-test-id={`${dataTestPrefix}-Button-Schedule${sessionTypeName}`}
                  className={styles.startButton}
                  isDisabled={isStartButtonDisabled || isScheduleInProgress}
                  isLoading={isScheduleInProgress}
                />
              )}
            </>
          )}
          {isSaveTemplateMode && (
            <TemplateMode
              dataTestPrefix={dataTestPrefix}
              onTemplateCancel={() => setIsSaveTemplateMode(false)}
              onTemplateSave={handleTemplateCreate}
              sessionType={sessionType}
              focusType={focusType}
              recipientType={recipientType}
              links={links}
              duration={duration}
              isEndSessionKeepAllTabs={isEndSessionKeepAllTabs}
              isEndSessionRestoreOriginal={isEndSessionRestoreOriginal}
            />
          )}
        </div>
      </div>
    </form>
  );
};

GuideBrowsingCreateForm.propTypes = {
  mainHeaderId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  selectedTemplate: GUIDE_BROWSING_TEMPLATE_TYPE,
  onTemplateSelect: PropTypes.func.isRequired,
  // connected props
  selectedClassId: PropTypes.string,
  isScheduledInitValue: PropTypes.bool,
  existingSessionData: GUIDE_BROWSING_DUPLICATION_SESSION_TYPE,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  nameFormat: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  recentTabs: PropTypes.arrayOf(PropTypes.string),
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  updateNotificationSession: PropTypes.func.isRequired,
  scheduleSession: PropTypes.func.isRequired,
  loadRecentTabsHistory: PropTypes.func.isRequired,
  reloadScheduledSessionAfterAddingNew: PropTypes.func.isRequired,
  addRecentTabsHistory: PropTypes.func.isRequired,
  selectedStudent: STUDENT_GUIDE_BROWSING_TYPE,
  getGroupStatusInfo: PropTypes.func.isRequired,
  loadGuideBrowsingTemplates: PropTypes.func.isRequired,
  updateGuideBrowsingTemplate: PropTypes.func.isRequired,
  monitoringTime: MONITORING_TIME_TYPE,
  hideTemplatesPanel: PropTypes.func.isRequired,
  loadTemplatesList: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
    students: getStudentConfigsList(state),
    nameFormat: getUserNameFormat(state),
    userGroups: getUserGroups(state),
    recentTabs: getRecentTabsHistory(state),
    selectedStudent: getGuideBrowsingModalSelectedStudent(state),
    isScheduledInitValue: getGuideBrowsingModalIfScheduled(state),
    existingSessionData: getGuideBrowsingModalSessionData(state),
    getGroupStatusInfo: (group) => getGroupStatusInfo(group)(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
    monitoringTime: getMonitoringTime(state),
    isDuplicateSession: isGuideBrowsingDuplicateSession(state),
  }),
  (dispatch) => ({
    updateNotificationSession: ({ sessionDetails, sessionType }) => {
      dispatch(updateNotificationSession({ sessionDetails, sessionType }));
    },
    scheduleSession: ({ sessionDetails, classId }) =>
      dispatch(scheduleSession({ sessionDetails, classId })),
    loadRecentTabsHistory: () => dispatch(loadGuideBrowsingRecentTabsHistory()),
    reloadScheduledSessionAfterAddingNew: () =>
      dispatch(reloadScheduledSessionAfterAddingNew()),
    addRecentTabsHistory: ({ urls }) => {
      dispatch(addGuideBrowsingRecentTabsHistory({ urls }));
    },
    loadGuideBrowsingTemplates: (classId) =>
      dispatch(loadGuideBrowsingTemplates({ classId })),
    updateGuideBrowsingTemplate: ({ id, classId, templateName, sessionData }) =>
      dispatch(
        updateGuideBrowsingTemplate({ id, classId, templateName, sessionData })
      ),
    hideTemplatesPanel: () => dispatch(hideTemplatesPanel()),
    loadTemplatesList: () => dispatch(fetchTemplatesList()),
  })
)(GuideBrowsingCreateForm);
