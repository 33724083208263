import actionTypes from "../../actionTypes";
import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../app/actions";
import { getMimeType } from "../myworkspaces/list/actions";
import {
  getGradingCardId,
  getGradingSelectedAssigneeId,
  getSelectedAssigneeArtifactId,
  getGradingActivity,
  getNextAvailableArtefactId,
  getSelectedAssigneeName,
  getAssigneeData,
  isAssigneeGroup,
  getGradingSelectedArtefactId,
} from "./selectors";
import {
  fetchGradingActivityUsers,
  fetchArtifactInfoCall,
  submitForAssigneeCall,
  returnArtifactsCall,
  gradeArtifactsCall,
  feedbackArtifactsCall,
  updateGradeFeedbackOfArtifactCall,
  returnArtifactsCallWithReturnType,
} from "../../../apiCalls/workspaces";
import { replaceQueryInHistory } from "../../router/navigation";

import { ARTIFACT_TYPE, RETURN_ARTIFACT_TYPES } from "./types";
import _ from "lodash";

import { CustomizedError, WORKSPACE_ERROR_TYPES } from "./errors";
import { getFFByName } from "../../app/selectors";

const gradingCardPending = () => ({
  type: actionTypes.GRADING_CARD_LOAD_PENDING,
});

const gradingCardError = () => ({
  type: actionTypes.GRADING_CARD_LOAD_ERROR,
});

const gradingCardNotValidError = () => ({
  type: actionTypes.GRADING_CARD_NOT_VALID,
});

const gradingArtefactNotValidError = () => ({
  type: actionTypes.GRADING_ARTEFACT_NOT_VALID,
});

const gradingActivityDone = () => ({
  type: actionTypes.GRADING_ACTIVITY_DONE,
});

const gradingCardLoad = (card) => ({
  type: actionTypes.GRADING_CARD_LOAD_SUCCESS,
  card,
});

const gradingAssigneePending = () => ({
  type: actionTypes.GRADING_ASSIGNEE_LOAD_PENDING,
});

const gradingAssigneeLoad = (assigneeData, requiresExtraAction) => ({
  type: actionTypes.GRADING_ASSIGNEE_LOAD_SUCCESS,
  assigneeData,
  requiresExtraAction,
});

const updateActionProgress = (value) => ({
  type: actionTypes.GRADING_ACTION_IN_PROGRESS,
  value,
});

const updateAutoSavingProgress = (value) => ({
  type: actionTypes.GRADING_AUTO_SAVING_IN_PROGRESS,
  value,
});

const resetActionError = () => ({
  type: actionTypes.GRADING_RESET_ACTION_ERROR,
});

const updateActionSuccess = () => ({
  type: actionTypes.GRADING_ACTION_SUCCESS,
});

const updateActionError = () => ({
  type: actionTypes.GRADING_ACTION_ERROR,
});

const updateAutoSavingError = () => ({
  type: actionTypes.GRADING_AUTO_SAVING_ERROR,
});

const updateBulkActionInitProgress = (value) => ({
  type: actionTypes.GRADING_BULK_ACTION_INIT_IN_PROGRESS,
  value,
});

const updateBulkActionInitError = (value) => ({
  type: actionTypes.GRADING_BULK_ACTION_INIT_ERROR,
  value,
});

const updateBulkReturnActionProgress = (value) => ({
  type: actionTypes.GRADING_BULK_RETURN_ACTION_IN_PROGRESS,
  value,
});

const updateBulkReturnActionError = (value) => ({
  type: actionTypes.GRADING_BULK_RETURN_ACTION_ERROR,
  value,
});

const updateBulkSubmitActionProgress = (value) => ({
  type: actionTypes.GRADING_BULK_SUBMIT_ACTION_IN_PROGRESS,
  value,
});

const updateBulkSubmitActionError = (value) => ({
  type: actionTypes.GRADING_BULK_SUBMIT_ACTION_ERROR,
  value,
});

const updateAssigneeCausedAction = (opts) => ({
  type: actionTypes.GRADING_UPDATE_CAUSED_ACTION,
  assigneeName: opts.assigneeName,
  mode: opts.mode,
  assigneeId: opts.assigneeId,
});

const updateHistoryUpdateProgress = (value) => ({
  type: actionTypes.GRADING_HISTORY_UPDATE_IN_PROGRESS,
  value,
});

const updateHistoryUpdateSuccess = (data) => ({
  type: actionTypes.GRADING_HISTORY_UPDATE_SUCCESS,
  payload: data,
});

const updateHistoryUpdateError = () => ({
  type: actionTypes.GRADING_HISTORY_UPDATE_ERROR,
});

export const resetHistoryUpdateError = () => ({
  type: actionTypes.GRADING_RESET_HISTORY_UPDATE_ERROR,
});

export const hideGradingSuccess = () => (dispatch) => {
  dispatch(resetActionError());
};

export const updateGradingCardId = (id) => ({
  type: actionTypes.URL_PARAM_UPDATE_GRADING_CARD_ID,
  param: id,
});

export const updateGradingActivity = (activity) => ({
  type: actionTypes.URL_PARAM_UPDATE_GRADING_ACTIVITY,
  param: activity,
});

export const updateGradingArtefact = () => ({
  type: actionTypes.URL_PARAM_UPDATE_ARTEFACT,
});

const handleGradingActivityUsersResponse = (response) => {
  if (!response.ok) {
    if (response.status === 400 || response.status === 404) {
      throw new CustomizedError(WORKSPACE_ERROR_TYPES.CARD_NOT_VALID);
    } else if (response.status === 403) {
      throw new CustomizedError(WORKSPACE_ERROR_TYPES.NOT_WS_USER);
    } else {
      throw new CustomizedError(WORKSPACE_ERROR_TYPES.GENERIC);
    }
  }

  return response.json();
};

export const getGradingData = () => (dispatch, getState) => {
  const state = getState();
  const cardId = getGradingCardId(state);
  const activity = getGradingActivity(state);

  dispatch(
    getGradingDataAction({
      cardId,
      activity,
    })
  );
};

export const getGradingAssigneeData = () => (dispatch, getState) => {
  const state = getState();
  const cardId = getGradingCardId(state);
  const activity = getGradingActivity(state);
  const artefactId = getGradingSelectedArtefactId(state);

  dispatch(
    getAssigneeDataAction({
      cardId,
      activity,
      artefactId,
    })
  );
};

/**
 * Updates grading data - card.
 *
 * @param {Object} opts
 * @param {String} opts.cardId Card Id for backend call
 * @param {String} opts.activity activity for backend call
 *
 */
export const getGradingDataAction =
  ({ cardId, activity }) =>
  (dispatch) => {
    dispatch(gradingCardPending());
    dispatch(hideAppError());

    fetchGradingActivityUsers({
      cardId,
      activity,
    })
      .then(handleGradingActivityUsersResponse)
      .then((card) => dispatch(gradingCardLoad(card)))
      .catch((error) => {
        switch (error.message) {
          case WORKSPACE_ERROR_TYPES.NOT_WS_USER:
            dispatch(updateAccessError(true));
            break;
          case WORKSPACE_ERROR_TYPES.CARD_NOT_VALID:
            dispatch(gradingCardNotValidError());
            break;

          default:
            dispatch(gradingCardError());
            dispatch(showAppError(error));
            break;
        }
      });
  };

/**
 * Updates grading data - assignee.
 *
 * @param {Object} opts
 * @param {String} opts.cardId Card Id for backend call
 * @param {String} opts.activity activity for backend call
 * @param {String} opts.artefactId selectedArtefactId for backend call
 *
 */

export const getAssigneeDataAction =
  ({ cardId, activity, artefactId }) =>
  (dispatch) => {
    dispatch(gradingAssigneePending());
    dispatch(hideAppError());

    fetchArtifactInfoCall({
      cardId,
      activity,
      artefactId,
    })
      .then((response) => {
        if (!response.ok) {
          switch (response.status) {
            case 400:
            case 500:
              throw new CustomizedError(
                WORKSPACE_ERROR_TYPES.ARTEFACT_NOT_VALID
              );

            case 404:
              throw new CustomizedError(WORKSPACE_ERROR_TYPES.ACTIVITY_DONE);

            case 403:
              throw new CustomizedError(WORKSPACE_ERROR_TYPES.NOT_WS_USER);

            default:
              throw new CustomizedError(WORKSPACE_ERROR_TYPES.GENERIC);
          }
        }
        return response.json();
      })
      .then((assigneeData) => {
        const requiresExtraAction =
          assigneeData.Artifact &&
          !assigneeData.Artifact.MimeType &&
          assigneeData.Artifact.Type === ARTIFACT_TYPE.GDRIVE;
        dispatch(gradingAssigneeLoad(assigneeData, requiresExtraAction));

        if (requiresExtraAction) {
          dispatch(getMimeType(assigneeData.Artifact));
        }
      })
      .catch((error) => {
        switch (error.message) {
          case WORKSPACE_ERROR_TYPES.NOT_WS_USER:
            dispatch(updateAccessError(true));
            break;
          case WORKSPACE_ERROR_TYPES.ARTEFACT_NOT_VALID:
            dispatch(gradingArtefactNotValidError());
            break;

          case WORKSPACE_ERROR_TYPES.ACTIVITY_DONE:
            dispatch(gradingActivityDone());
            break;

          default:
            dispatch(showAppError(error));
            break;
        }
      });
  };

export const submitForAssignee = () => (dispatch, getState) => {
  const state = getState();
  const cardId = getGradingCardId(state);
  const assigneeId = getGradingSelectedAssigneeId(state);
  const assigneeName = getSelectedAssigneeName(state);
  const assigneeIsGroup = isAssigneeGroup(state);
  const nextArtefactId = getNextAvailableArtefactId(state);

  dispatch(updateActionProgress(true));
  dispatch(
    updateAssigneeCausedAction({ assigneeName, mode: "submitted", assigneeId })
  );

  submitForAssigneeCall({ cardId, assigneeId, assigneeIsGroup })
    .then((response) => {
      if (response.status === 403) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return [];
      }

      if (!response.ok) {
        throw Error(response);
      }

      dispatch(updateActionSuccess());
      replaceQueryInHistory({ artefact: nextArtefactId });
    })
    .catch((error) => {
      dispatch(updateActionError());
    });
};

/** This is a old version that takes extra parameters to allow return for edit for assessed evidence **/
/** to be removed when the ff HAP-7144-Return-Assessed-Artifact **/
const returnArtifact =
  ({ grade, feedback, final = false }) =>
  (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const artifactId = getSelectedAssigneeArtifactId(state);
    const assigneeId = getGradingSelectedAssigneeId(state);
    const assigneeName = getSelectedAssigneeName(state);
    const nextArtefactId = getNextAvailableArtefactId(state);

    dispatch(updateActionProgress(true));
    dispatch(
      updateAssigneeCausedAction({
        assigneeName,
        mode: final ? "final" : "edit",
        assigneeId,
      })
    );

    const promisesList = [];

    promisesList.push(gradeArtifactsCall({ cardId, artifactId, grade }));
    promisesList.push(feedbackArtifactsCall({ cardId, artifactId, feedback }));

    Promise.all(promisesList)
      .then((response) => {
        const noAccess = !!_.find(response, { status: 403 });
        if (noAccess) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        const notOk = !!_.find(response, { ok: false });
        if (notOk) {
          throw Error(response);
        }

        returnArtifactsCall({
          cardId,
          artifactIdList: [artifactId],
          final,
        }).then((response) => {
          if (!response.ok) {
            throw Error(response);
          }

          dispatch(updateActionSuccess());
          replaceQueryInHistory({ artefact: nextArtefactId });
        });
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(updateActionError());
      });
  };
/** This is a new version that takes extra parameters to allow return for edit for assessed evidence **/
const returnArtifactWithReturnType =
  ({ grade, feedback, returnType }) =>
  (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const artifactId = getSelectedAssigneeArtifactId(state);
    const assigneeId = getGradingSelectedAssigneeId(state);
    const assigneeName = getSelectedAssigneeName(state);
    const nextArtefactId = getNextAvailableArtefactId(state);

    dispatch(updateActionProgress(true));
    dispatch(
      updateAssigneeCausedAction({
        assigneeName,
        mode: returnType === RETURN_ARTIFACT_TYPES.FINAL ? "final" : "edit",
        assigneeId,
      })
    );

    const promisesList = [];

    promisesList.push(gradeArtifactsCall({ cardId, artifactId, grade }));
    promisesList.push(feedbackArtifactsCall({ cardId, artifactId, feedback }));

    Promise.all(promisesList)
      .then((response) => {
        const noAccess = !!_.find(response, { status: 403 });
        if (noAccess) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        const notOk = !!_.find(response, { ok: false });
        if (notOk) {
          throw Error(response);
        }

        returnArtifactsCallWithReturnType({
          cardId,
          artifactIdList: [artifactId],
          returnType,
        }).then((response) => {
          if (!response.ok) {
            throw Error(response);
          }

          dispatch(updateActionSuccess());
          replaceQueryInHistory({ artefact: nextArtefactId });
        });
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(updateActionError());
      });
  };

export const returnFinal = (options) => (dispatch, getState) => {
  const state = getState();
  if (getFFByName("HAP-7144-Return-Assessed-Artifact")(state)) {
    dispatch(
      returnArtifactWithReturnType({
        ...options,
        returnType: RETURN_ARTIFACT_TYPES.FINAL,
      })
    );
  } else {
    dispatch(returnArtifact({ ...options, final: true })); // true equals final
  }
};

export const returnForEdit = (options) => (dispatch, getState) => {
  const state = getState();
  if (getFFByName("HAP-7144-Return-Assessed-Artifact")(state)) {
    dispatch(
      returnArtifactWithReturnType({
        ...options,
        returnType: RETURN_ARTIFACT_TYPES.UNASSESSED,
      })
    );
  } else {
    dispatch(returnArtifact(options)); // no parameter equals it's not final return
  }
};

export const returnAssessed = (options) => (dispatch) => {
  dispatch(
    returnArtifactWithReturnType({
      ...options,
      returnType: RETURN_ARTIFACT_TYPES.ASSESSED,
    })
  );
};

export const saveGrade = (artifactId, grade) => (dispatch, getState) => {
  const state = getState();
  const cardId = getGradingCardId(state);

  dispatch(updateAutoSavingProgress(true));

  gradeArtifactsCall({ cardId, artifactId, grade })
    .then((response) => {
      const noAccess = !!_.find(response, { status: 403 });
      if (noAccess) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return [];
      }

      const notOk = !!_.find(response, { ok: false });
      if (notOk) {
        throw Error(response);
      }
      dispatch(updateAutoSavingProgress(false));

      return response.json();
    })
    .catch((error) => {
      console.log("error: ", error);
      dispatch(updateAutoSavingError());
    });
};

export const saveFeedback = (artifactId, feedback) => (dispatch, getState) => {
  const state = getState();
  const cardId = getGradingCardId(state);

  dispatch(updateAutoSavingProgress(true));

  feedbackArtifactsCall({ cardId, artifactId, feedback })
    .then((response) => {
      const noAccess = !!_.find(response, { status: 403 });
      if (noAccess) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return [];
      }

      const notOk = !!_.find(response, { ok: false });
      if (notOk) {
        throw Error(response);
      }
      dispatch(updateAutoSavingProgress(false));
    })
    .catch((error) => {
      console.log("error: ", error);
      dispatch(updateAutoSavingError());
    });
};

export const updateGradeFeedback =
  ({ grade, feedback, activityId }) =>
  (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const activityType = getGradingActivity(state);
    const artifactId = getSelectedAssigneeArtifactId(state);
    const assigneeId = getAssigneeData(state).Id;
    const assigneeIsGroup = isAssigneeGroup(state);

    dispatch(resetHistoryUpdateError());
    dispatch(updateHistoryUpdateProgress(true));

    updateGradeFeedbackOfArtifactCall({
      activityId,
      activityType,
      cardId,
      artifactId,
      assigneeId,
      grade,
      feedback,
      assigneeIsGroup,
    })
      .then((response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) =>
        dispatch(updateHistoryUpdateSuccess(data.ActivityHistory))
      )
      .catch((error) => {
        console.log("error: ", error);
        dispatch(updateHistoryUpdateError());
      });
  };

export const loadGradingActivityUsersForBulkActions =
  () => (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const activity = getGradingActivity(state);

    dispatch(updateBulkActionInitError(false));
    dispatch(updateBulkActionInitProgress(true));
    dispatch(updateBulkReturnActionError(false));
    dispatch(updateBulkSubmitActionError(false));

    fetchGradingActivityUsers({
      cardId,
      activity,
    })
      .then(handleGradingActivityUsersResponse)
      .then((card) => {
        dispatch(gradingCardLoad(card));
        dispatch(updateBulkActionInitProgress(false));
      })
      .catch((error) => {
        dispatch(updateBulkActionInitError(true));
        dispatch(updateBulkActionInitProgress(false));
      });
  };

export const doBulkReturn =
  ({ artifactIdList, isFinal }) =>
  (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const activity = getGradingActivity(state);

    dispatch(updateBulkReturnActionError(false));
    dispatch(updateBulkReturnActionProgress(true));

    returnArtifactsCall({ cardId, artifactIdList, final: isFinal })
      .then((response) => {
        if (!response.ok) {
          throw Error(response);
        }

        fetchGradingActivityUsers({
          cardId,
          activity,
        })
          .then(handleGradingActivityUsersResponse)
          .then((card) => {
            dispatch(gradingCardLoad(card));
            dispatch(updateBulkReturnActionProgress(false));
          })
          .catch((error) => {
            dispatch(updateBulkReturnActionError(true));
            dispatch(updateBulkReturnActionProgress(false));
          });
      })
      .catch((error) => {
        dispatch(updateBulkReturnActionError(true));
        dispatch(updateBulkReturnActionProgress(false));
      });
  };

export const doBulkSubmit =
  ({ assigneeIdList }) =>
  (dispatch, getState) => {
    const state = getState();
    const cardId = getGradingCardId(state);
    const activity = getGradingActivity(state);
    const isGroup = isAssigneeGroup(state);

    dispatch(updateBulkSubmitActionError(false));
    dispatch(updateBulkSubmitActionProgress(true));

    Promise.all(
      _.map(assigneeIdList, (assigneeId) =>
        submitForAssigneeCall({
          cardId,
          assigneeIsGroup: isGroup,
          assigneeId,
        }).then((response) => {
          if (!response.ok) {
            throw Error(response);
          }
        })
      )
    )
      .then(() => {
        fetchGradingActivityUsers({
          cardId,
          activity,
        })
          .then(handleGradingActivityUsersResponse)
          .then((card) => {
            dispatch(gradingCardLoad(card));
            dispatch(updateBulkSubmitActionProgress(false));
          })
          .catch((error) => {
            dispatch(updateBulkSubmitActionError(true));
            dispatch(updateBulkSubmitActionProgress(false));
          });
      })
      .catch((error) => {
        dispatch(updateBulkSubmitActionError(true));
        dispatch(updateBulkSubmitActionProgress(false));
      });
  };
