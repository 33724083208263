import React, { CSSProperties } from "react";
import classNames from "classnames";
import { formatDateTimeLocal } from "../formatDateTimeLocal";
import { FormattedMessage } from "react-intl";
import { DateTimeFormat } from "../formats";

type IntlDateTimeLocalProps = {
  timestamp: string;
  style?: CSSProperties;
  className?: string;
  dataTestIdPrefix?: string;
  format?: DateTimeFormat;
};

export const IntlDateTimeLocal = ({
  timestamp: timeStamp,
  style,
  className,
  dataTestIdPrefix,
  format = "default",
}: IntlDateTimeLocalProps) => {
  const formattedTimestamp = formatDateTimeLocal(timeStamp, format);

  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-IntlDateTimeLocal`
    : undefined;

  return (
    <span
      style={style}
      data-test-id={dataTestId}
      className={classNames(className)}
    >
      {formattedTimestamp ? (
        formattedTimestamp
      ) : (
        <FormattedMessage defaultMessage="Invalid date" id="t+3/Tz" />
      )}
    </span>
  );
};
