import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./GuidedBrowsingSessionBar.module.scss";
import Button from "@hapara/ui/src/atomic/Button/Button";
import { TargetIcon } from "@hapara/ui/src/icons/TargetIcon";
import { getActiveGuideBrowsingSessionList } from "../../../../../state/highlights/sessions/selectors";
import {
  loadCurrentSessions,
  releaseStudentsFromSession,
} from "../../../../../state/highlights/sessions/actions";
import { showTemplatesPanel } from "../../../../../state/highlights/guideBrowsingTemplates/actions";
import { getClassURN } from "../../../../../state/highlights/config/selectors";
import GuideBrowsingActiveSessionsModal from "../Deprecated/GuideBrowsingActiveSessionsModal/GuideBrowsingActiveSessionsModal";
import useUpdateHighlightsSessionsAfterEndTimeHook from "../../../../../hooks/useUpdateHighlightsSessionsAfterEndTimeHook";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import { areSomeStudentsInSession } from "../../../../../state/highlights/students/selectors";
import { getStudentsIdList } from "../../../../../state/highlights/studentConfigs/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { getIsClassViewPage } from "../../../../../state/router/selectors";
import { GUIDE_BROWSING_SESSION_TYPE } from "src/state/highlights/sessions/types";

const dataTestPrefix = "hl-GuideBrowsingModal-SessionsBar";

export type GuideBrowsingBarPresentationalProps = {
  noActiveSessions: boolean;
  isOtherTeacherSession: boolean;
  isErrorReleasing: boolean;
  isConfirmationModalOpen: boolean;
  handleStartSession: () => void;
  handleReleaseAll: () => void;
  setIsConfirmationModalOpen: (value: boolean) => void;
  setIsErrorReleasing: (value: boolean) => void;
  setIsReviewModalOpen: (value: boolean) => void;
  sessionList: (typeof GUIDE_BROWSING_SESSION_TYPE)[];

  guideBrowsingActiveSessionsModal: React.ReactNode;
};

export const GuidedBrowsingSessionBarPresentation = ({
  noActiveSessions,
  handleStartSession,
  isOtherTeacherSession,
  handleReleaseAll,
  setIsConfirmationModalOpen,
  setIsErrorReleasing,
  sessionList,
  setIsReviewModalOpen,
  isErrorReleasing,
  isConfirmationModalOpen,
  guideBrowsingActiveSessionsModal,
}: GuideBrowsingBarPresentationalProps) => {
  const intl = useIntl();
  return (
    <>
      <section
        className={`${styles.root} ${noActiveSessions ? styles.inactive : ""}`}
        aria-label={
          noActiveSessions
            ? intl.formatMessage({
                defaultMessage: "Guided sessions",
                id: "IxMbqV",
              })
            : intl.formatMessage({
                defaultMessage: "Active guided sessions",
                id: "BaVjar",
              })
        }
        tabIndex={-1}
        data-test-id={dataTestPrefix}
        id={dataTestPrefix}
      >
        <TargetIcon />
        {noActiveSessions ? (
          <>
            <div className={styles.text}>
              <FormattedMessage
                defaultMessage="No active guided browsing"
                id="P8TH6d"
              />
            </div>
            <Button
              label={intl.formatMessage({
                defaultMessage: "Start",
                id: "mOFG3K",
              })}
              aria-label={intl.formatMessage({
                defaultMessage: "Start guided browsing session",
                id: "TPLUAZ",
              })}
              type="secondary"
              size="xsmall"
              onClick={handleStartSession}
              data-test-id={`${dataTestPrefix}-StartSession`}
            />
          </>
        ) : (
          <>
            {!isOtherTeacherSession && (
              <>
                <div className={styles.text}>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# Active guided session} other {# Active guided sessions}}"
                    id="FYw2GM"
                    values={{ count: sessionList.length }}
                  />
                </div>
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Review",
                    id: "R+J5ox",
                  })}
                  type="tertiary"
                  size="xsmall"
                  onClick={() => setIsReviewModalOpen(true)}
                  data-test-id={`${dataTestPrefix}-OpenReviewModal`}
                  className={styles.button}
                />
              </>
            )}
            {isOtherTeacherSession && (
              <div className={styles.text}>
                <FormattedMessage
                  defaultMessage="Some students are currently in a Guided Session"
                  id="iJZKN+"
                />
              </div>
            )}
            <Button
              label={intl.formatMessage({
                defaultMessage: "Release all",
                id: "jVEnps",
              })}
              type="tertiary"
              size="xsmall"
              onClick={() => setIsConfirmationModalOpen(true)}
              data-test-id={`${dataTestPrefix}-ReleaseAllSessions`}
              className={styles.button}
            />
          </>
        )}
        {guideBrowsingActiveSessionsModal}
      </section>
      <ActionConfirmationDialog
        dataTestPrefix={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal`}
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setIsErrorReleasing(false);
        }}
        title={intl.formatMessage({
          defaultMessage: "End session for all students?",
          id: "Y1/l5Q",
        })}
        content={intl.formatMessage({
          defaultMessage:
            "When all student are released, they will be able to browse the web without restrictions",
          id: "/DOLua",
        })}
        isActionDanger={true}
        error={
          isErrorReleasing
            ? intl.formatMessage({
                defaultMessage:
                  "Sorry, there was a problem releasing all students. Please try again.",
                id: "7iKzux",
              })
            : null
        }
        cancelDataTestId={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal-Button-Cancel`}
        actionLabel={intl.formatMessage({
          defaultMessage: "Release all students",
          id: "XSRI1F",
        })}
        onAction={handleReleaseAll}
        actionDataTestId={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal-Button-ReleaseAllStudents`}
      />
    </>
  );
};

export const GuidedBrowsingSessionBar = () => {
  const dispatch = useDispatch();

  const sessionList = useSelector(getActiveGuideBrowsingSessionList);
  const classId = useSelector(getClassURN);
  const areSomeStudentsInGBSession = useSelector(areSomeStudentsInSession);
  const studentIds = useSelector(getStudentsIdList);
  const isClassViewPage = useSelector(getIsClassViewPage);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isErrorReleasing, setIsErrorReleasing] = useState(false);

  const shouldBeRendered =
    (sessionList && sessionList.length > 0) ||
    areSomeStudentsInGBSession ||
    isClassViewPage;

  const isOtherTeacherSession =
    areSomeStudentsInGBSession && sessionList.length === 0;

  const noActiveSessions =
    sessionList.length === 0 && !areSomeStudentsInGBSession;

  const loadSessions = useCallback(() => {
    dispatch(loadCurrentSessions({ classId }));
  }, [dispatch, classId]);

  useUpdateHighlightsSessionsAfterEndTimeHook(loadSessions, sessionList);

  useEffect(() => {
    if (sessionList.length === 0) {
      setIsReviewModalOpen(false);
    }
  }, [sessionList.length]);

  const handleReleaseAll = () => {
    setIsErrorReleasing(false);

    Promise.resolve(
      dispatch(releaseStudentsFromSession({ studentIds, isPause: false }))
    )
      .then(() => {
        setIsConfirmationModalOpen(false);
        loadSessions();
      })
      .catch(() => {
        setIsErrorReleasing(true);
      });
  };

  const handleStartSession = () => {
    dispatch(showTemplatesPanel({ isScheduledPanel: false }));
  };

  if (!shouldBeRendered) {
    return null;
  }

  return (
    <GuidedBrowsingSessionBarPresentation
      noActiveSessions={noActiveSessions}
      isOtherTeacherSession={isOtherTeacherSession}
      isErrorReleasing={isErrorReleasing}
      isConfirmationModalOpen={isConfirmationModalOpen}
      handleStartSession={handleStartSession}
      handleReleaseAll={handleReleaseAll}
      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      setIsErrorReleasing={setIsErrorReleasing}
      setIsReviewModalOpen={setIsReviewModalOpen}
      sessionList={sessionList}
      guideBrowsingActiveSessionsModal={
        <GuideBrowsingActiveSessionsModal
          isModalOpen={isReviewModalOpen}
          onModalClose={() => setIsReviewModalOpen(false)}
        />
      }
    />
  );
};
