import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./AssignCollectionsModal.module.scss";
import classnames from "classnames";

const AssignCollectionModalEmpty = () => {
  return (
    <div
      className={classnames(
        styles.modalBodyContainer,
        styles.emptyCollsContainer
      )}
    >
      <div className={styles.container}>
        <div className={styles.emptyText}>
          <span>
            <FormattedMessage defaultMessage="No collections yet" id="qvfFO9" />
          </span>
          <p>
            <FormattedMessage
              defaultMessage="Create your first collection to get started!"
              id="tH7awb"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssignCollectionModalEmpty;
