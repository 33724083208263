import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../../atomic/Button/Button";
import styles from "./groupsListBlock.module.scss";
import SlidingGroupPanel from "../slidingGroupPanel/slidingGroupPanel";
import GroupItem from "../groupItem/groupItem";
import _ from "lodash";

/**
 *
 * @param data
 * @param onDeleteGroup
 * @param onGroupEdit
 * @param onClose
 * @returns {*}
 * @constructor
 */

const GroupsListBlock = React.forwardRef(
  (
    {
      data,
      preferences,
      placement,
      views,
      viewId,
      tabName,
      onDeleteGroup,
      onEditGroup,
      onClose,
      onChangeType,
      dialogLabelId,
    },
    ref
  ) => {
    const intl = useIntl();

    data = _.sortBy(data, "name");
    const [errorOccurred, setError] = useState({ id: null, text: "" });

    function handleDeleteGroup(group) {
      onDeleteGroup(group).catch(() => {
        setError({
          id: "deleteGroup",
          text: intl.formatMessage({
            defaultMessage: "Sorry, there is a problem deleting this group.",
            id: "v+zo4O",
          }),
        });
      });
    }

    function getViewName({ views, viewId = null, tabName }) {
      const item = _.find(views, { id: viewId }) || views[0];
      if (tabName) {
        return tabName;
      }

      if (viewId.indexOf("S_") === 0) {
        if (viewId === "S_5") {
          return intl.formatMessage({
            defaultMessage: "Dashboard Sharing (Deleted Docs)",
            id: "QbtC4Y",
          });
        }
        return intl.formatMessage(
          {
            defaultMessage: "Dashboard Sharing ({title})",
            id: "4vPfQA",
          },
          { title: item.title }
        );
      }
      if (viewId.indexOf("CB_") === 0) {
        return intl.formatMessage({
          defaultMessage: "Highlights",
          id: "KGmQjH",
        });
      }
      if (viewId.indexOf("G_") === 0) {
        return intl.formatMessage(
          {
            defaultMessage: "Dashboard Gmail ({title})",
            id: "Kv3cPV",
          },
          { title: item.title }
        );
      }
      return intl.formatMessage(
        {
          defaultMessage: "Dashboard ({title})",
          id: "FB3Gpa",
        },
        { title: item.title }
      );
    }

    const textNoGroups = preferences
      ? intl.formatMessage({
          defaultMessage: "You haven’t created any groups for this tab yet",
          id: "PnZRUl",
        })
      : intl.formatMessage({
          defaultMessage: "You haven’t created any groups yet",
          id: "83X4XM",
        });

    const viewName = getViewName({
      views,
      viewId,
      tabName,
    });

    return (
      <SlidingGroupPanel
        onClose={() => onClose()}
        hasOverlay={true}
        placement={placement}
        errorOccurred={errorOccurred}
        onTryAgain={() => setError({ id: null, text: "" })}
        ref={ref}
      >
        <div className={styles.root}>
          <div className={styles.header}>
            <h1 className={styles.title} id={dialogLabelId}>
              <FormattedMessage defaultMessage="Class Groups" id="bmvOAe" />
            </h1>
            <div className={styles.button}>
              <Button
                label={intl.formatMessage({
                  defaultMessage: "New",
                  id: "bW7B87",
                })}
                icon="plus"
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.SMALL}
                onAction={() => onChangeType("details")}
              />
            </div>
          </div>
          <article className={styles.preference}>
            {preferences ? (
              <span>
                <FormattedMessage
                  defaultMessage="Groups are specific to each tab"
                  id="6KFxNu"
                />
              </span>
            ) : (
              <FormattedMessage
                defaultMessage="Groups are shared across tabs"
                id="vShDQG"
              />
            )}
            <Button
              dataTestId="change-group-button"
              label={intl.formatMessage({
                defaultMessage: "Change",
                id: "BY343C",
              })}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              className={styles.buttonAsLink}
              onAction={() => onChangeType("preferences")}
            />
          </article>
          <div className={styles.body}>
            {preferences && (
              <div className={styles.location}>
                <FormattedMessage
                  defaultMessage="In {viewName}"
                  id="t6VpAr"
                  values={{ viewName }}
                />
              </div>
            )}
            {data && data.length === 0 && (
              <div className={styles.noGroups}>{textNoGroups}</div>
            )}
            {data &&
              data.map((group) => {
                return (
                  <GroupItem
                    data={group}
                    key={group.urn}
                    onEditGroup={(group) => onEditGroup(group)}
                    onDeleteGroup={(group) => handleDeleteGroup(group)}
                    errorOccurred={errorOccurred}
                  />
                );
              })}
          </div>
        </div>
      </SlidingGroupPanel>
    );
  }
);

GroupsListBlock.propTypes = {
  data: PropTypes.array,
  preferences: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  onDeleteGroup: PropTypes.func,
  onClose: PropTypes.func,
  onChangeType: PropTypes.func,
  onEditGroup: PropTypes.func,
  dialogLabelId: PropTypes.string,
  views: PropTypes.array,
  viewId: PropTypes.string,
  tabName: PropTypes.string,
};

export default GroupsListBlock;
