import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getIsResourceRemoveFromLibraryModalOpen,
  getResourceRemoveFromLibraryModalData,
} from "../../../../state/resources/selectors";
import {
  showSuccessToast,
  hideSuccessToast,
  hideAppError,
} from "../../../../state/app/actions";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import {
  hideResourceDetailsModal,
  hideResourceRemoveFromLibraryModal,
  removeFromLibraryAction,
} from "../../../../state/resources/actions";
import { isInsideLibrary } from "../../../../state/library/library/selectors";
import { FocusContext } from "@hapara/ui/src/components/utils";

const RemoveFromLibraryModal = ({
  isModalOpen = false,
  dataTestPrefix,
  modalData,
  showSuccessMessage,
  hideSuccessMessage,
  removeFromLibraryAction,
  isInsideLibrary,
  hideErrorMessage,
  hideResourceDetailsModal,
  hideRemoveFromLibraryModal,
}) => {
  const [isErrorRemoving, setIsErrorRemoving] = useState(false);

  const { resourceId } = modalData;

  const focusContext = useContext(FocusContext);

  // on unmount
  useEffect(() => {
    return () => {
      hideRemoveFromLibraryModal();
    };
  }, [hideRemoveFromLibraryModal]);

  const removeFromLibrary = (itemId) => {
    hideErrorMessage();
    hideSuccessMessage();
    removeFromLibraryAction({ itemId })
      .then(() => {
        hideRemoveFromLibraryModal();
        focusContext.restoreFocus();

        if (isInsideLibrary) {
          hideResourceDetailsModal();
          showSuccessMessage();
        }
      })
      .catch(() => {
        setIsErrorRemoving(true);
      });
  };

  return (
    <ActionConfirmationDialog
      dataTestPrefix={`${dataTestPrefix}-RemoveFromLibraryConfirmationModal`}
      isOpen={isModalOpen}
      onClose={() => {
        hideRemoveFromLibraryModal();
        setIsErrorRemoving(false);
        focusContext.restoreFocus();
      }}
      title="Remove from library?"
      content="This will no longer be available in the library for other people in your organization to view and/or copy."
      isActionDanger={true}
      error={
        isErrorRemoving
          ? "Sorry, there was a problem removing from library. Please try again."
          : null
      }
      cancelDataTestId={`${dataTestPrefix}-RemoveFromLibraryConfirmationModal-Button-Cancel`}
      actionLabel="Remove from library"
      onAction={() => {
        removeFromLibrary(resourceId);
      }}
      actionDataTestId={`${dataTestPrefix}-RemoveFromLibraryConfirmationModal-Button-Confirm`}
    />
  );
};

RemoveFromLibraryModal.propTypes = {
  isModalOpen: PropTypes.bool,
  modalData: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
  }),
  isInsideLibrary: PropTypes.bool.isRequired,
  hideErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
  removeFromLibraryAction: PropTypes.func.isRequired,
  hideResourceDetailsModal: PropTypes.func.isRequired,
  hideRemoveFromLibraryModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getIsResourceRemoveFromLibraryModalOpen(state),
    modalData: getResourceRemoveFromLibraryModalData(state),
    isInsideLibrary: isInsideLibrary(state),
  }),
  (dispatch) => ({
    hideErrorMessage: () => dispatch(hideAppError()),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
    removeFromLibraryAction: ({ itemId }) =>
      dispatch(removeFromLibraryAction({ itemId })),
    hideResourceDetailsModal: () => dispatch(hideResourceDetailsModal()),
    hideRemoveFromLibraryModal: () =>
      dispatch(hideResourceRemoveFromLibraryModal()),
  })
)(RemoveFromLibraryModal);
