import axios from "axios";
import _ from "lodash";
import { getCurrentAuthHeader } from "./authHeader";
import { getToken } from "./jwtHandler";
import { ApiError } from "./types";
import { getErrorEvent, getErrorStringFromErrorOrResponse } from "../utils";

export async function fetchUserInit() {
  return fetch(`${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/user/init`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: await getToken(),
      Accept: "application/json, text/plain, */*",
    },
  });
}

export const fetchUserInitConfig = async (classId) => {
  try {
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/config/init${
      classId ? `?class=${classId}` : ""
    }`;
    const config = {
      headers: { Authorization: await getToken() },
    };
    const resp = await axios.get(url, config);
    return {
      permissions: _.get(resp, "data.Permissions", []) || [],
      isStudent: _.get(resp, "data.isStudent", false),
      metadata: _.get(resp, "data.Metadata", {}) || {},
      featureFlags: _.get(resp, "data.flags", {}) || {},
      isSharingViewEnabled: _.get(resp, "data.isSharingViewEnabled", true),
    };
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/config/init"
    );
    await postSendEvent(getErrorEvent(err));
    throw err;
  }
};

export const postSendEvent = (data) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/troubleshooting/send`;
  return axios.post(url, { data }).catch((e) => {
    return null;
  });
};

export const postSendFeedback = async (requestBody) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/services/feedback`;

  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };

  return axios.post(url, requestBody, config);
};

export const postTeacherNotes = async ({ classId, studentEmail }) => {
  const url = `${process.env.REACT_APP_HAPARAVIEW_URL}/_ah/api/hapara/v1/teachernotes_get?alt=json`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  const baseHref = "https://haparaview.appspot.com";
  return axios
    .post(url, { dash_id: classId, student_id: studentEmail }, config)
    .then((resp) => {
      // Prevent redirect to teacherdashboard.com when teacher notes is missing
      const notes = _.get(resp, "data.teacher_notes_url");
      if (notes) {
        if (notes.indexOf("/trg/") > -1) {
          return baseHref + notes;
        } else {
          return notes;
        }
      }
    });
};
