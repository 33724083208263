import React from "react";
import { Redirect } from "react-router-dom";
import { APP_PAGES } from "../../state/router/types";
import { getUserPermissions } from "../../state/user/selectors";
import { getFFByName } from "../../state/app/selectors";
import PrivateRoute from "./PrivateRoute";
import _ from "lodash";
import { USER_PERMISSIONS } from "../../state/user/types";
import type { RouteProps } from "react-router";
import { useAppSelector } from "../../state/hooks";

export const BaseRoute = (props: RouteProps) => {
  const userPermissions = useAppSelector((state) => getUserPermissions(state));
  const isPlFF = useAppSelector((state) =>
    getFFByName("HAP-7034-professional-learning")(state)
  );
  const { component, ...rest } = props;

  const { location } = props;

  const RedirectToParticipantsOnlyPlPageComponent = () => (
    <Redirect
      to={{
        pathname: APP_PAGES.PROFESSIONAL_LEARNING.path,
        state: { from: location },
      }}
    />
  );

  const RedirectToMyResources = () => (
    <Redirect
      to={{
        pathname: APP_PAGES.MY_RESOURCES_STARRED.path,
        state: { from: location },
      }}
    />
  );

  const RedirectToOurLibrary = () => (
    <Redirect
      to={{
        pathname: APP_PAGES.LIBRARY_BASE.path,
        state: { from: location },
      }}
    />
  );

  const RedirectToManageClasses = () => (
    <Redirect
      to={{
        pathname: APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path,
        state: { from: location },
      }}
    />
  );

  const isNoAccess = !userPermissions || !userPermissions.length;

  // by default redirect to Manage Classes
  let componentToRender = RedirectToManageClasses;

  // if user have no access to Manage classes
  if (!_.includes(userPermissions, USER_PERMISSIONS.MANAGE_CLASSES)) {
    // check if he has access to DB Library
    if (_.includes(userPermissions, USER_PERMISSIONS.LIBRARY_RESOURCES)) {
      // it is a DB only user
      componentToRender = RedirectToMyResources;
      // otherwise check if he has access to Library
    } else if (
      _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_RESOURCES) ||
      _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_WORKSPACE)
    ) {
      // it is a DRM user with no classes
      componentToRender = RedirectToOurLibrary;
      // otherwise check if he has access to PL Workspaces
    } else if (
      isPlFF &&
      _.includes(userPermissions, USER_PERMISSIONS.PROFESSIONAL_LEARNING)
    ) {
      // it is a PL participant only user
      componentToRender = RedirectToParticipantsOnlyPlPageComponent;
    }
  }

  return (
    <PrivateRoute
      {...rest}
      isNoAccess={isNoAccess}
      component={componentToRender}
    />
  );
};

export default BaseRoute;
