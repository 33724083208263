import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ModalFormLinks.module.scss";
import _ from "lodash";
import classnames from "classnames";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE,
  SESSION_TYPES,
} from "../../../../state/highlights/sessions/types";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Tippy from "@tippy.js/react";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
  DropdownTrigger,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import {
  getHostname,
  normaliseUrl,
  validateUrl,
  isDuplicateURL,
} from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { getFFByName } from "../../../../state/app/selectors";

const invalidUrlTextId = "invalid-url-text-id";

const ModalFormLinks = ({
  links,
  onLinksChange,
  focusType,
  onFocusTypeChange,
  sessionType,
  dataTestPrefix,
  recentTabs,
}) => {
  const intl = useIntl();

  const ERROR_MESSAGES = {
    DUPLICATE_ONLY_SINGLE: intl.formatMessage({
      defaultMessage: "You've already entered this URL.",
      id: "2BCosw",
    }),
    DUPLICATE_ONLY_PLURAL: intl.formatMessage({
      defaultMessage: "You've already entered these URLs.",
      id: "QtIJx0",
    }),
    INVALID_ONLY: intl.formatMessage({
      defaultMessage: "Invalid URL(s). Please check for typos or extra spaces.",
      id: "qWsBLH",
    }),
    MIXED_ERRORS: intl.formatMessage({
      defaultMessage:
        "Error: please check for typos, extra spaces or duplicates.",
      id: "LumzRV",
    }),
  };

  const [inputLinkText, setInputLinkText] = useState("");
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [invalidUrlText, setInvalidUrlText] = useState("");

  const title =
    sessionType === SESSION_TYPES.FILTER
      ? intl.formatMessage({
          defaultMessage: "Prevent access to the following link(s):",
          id: "hluoQB",
        })
      : intl.formatMessage({
          defaultMessage: "Open and focus the following link(s):",
          id: "hOj6Vk",
        });

  const maxLinksNumber =
    GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE[sessionType];

  const handleInputChange = (e) => {
    setInvalidUrl(false);
    setInputLinkText(_.get(e, "target.value", ""));
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      // do an action on the enter
      e.preventDefault();
      e.stopPropagation();
      const newLink = _.get(e, "target.value", "");
      if (newLink) {
        addNewLinkHandler();
      }
    }
  };

  const addNewLinkHandler = () => {
    setInvalidUrl(false);

    const splitLinks = _.split(inputLinkText, ",");
    const trimmedLinks = _.map(splitLinks, _.trim);
    const normalisedLinks = _.map(_.compact(trimmedLinks), (url) =>
      normaliseUrl(url)
    );

    // add links
    const newLinks = _.concat(links, []);
    const badLinks = [];
    let isDuplicateError = false;
    let isInvalidError = false;

    _.forEach(normalisedLinks, (link) => {
      if (isDuplicateURL(newLinks, link)) {
        badLinks.push(link);
        isDuplicateError = true;
        return;
      }
      if (!validateUrl(link)) {
        badLinks.push(link);
        isInvalidError = true;
        return;
      }
      if (newLinks.length >= maxLinksNumber) {
        return;
      }
      newLinks.push(link);
    });

    onLinksChange(newLinks);

    if (badLinks.length > 0) {
      setInputLinkText(_.join(badLinks, ", "));
      setInvalidUrl(true);
      if (isInvalidError && !isDuplicateError) {
        // invalid only
        setInvalidUrlText(ERROR_MESSAGES.INVALID_ONLY);
      } else if (!isInvalidError && isDuplicateError) {
        // duplicate only
        setInvalidUrlText(
          badLinks.length > 1
            ? ERROR_MESSAGES.DUPLICATE_ONLY_PLURAL
            : ERROR_MESSAGES.DUPLICATE_ONLY_SINGLE
        );
      } else {
        // both
        setInvalidUrlText(ERROR_MESSAGES.MIXED_ERRORS);
      }
    } else {
      setInputLinkText("");
    }
  };

  const removeLinkHandler = (link) => {
    const filtered = _.filter(links, (l) => l !== link);
    onLinksChange(filtered);
    setInvalidUrl(false);
  };

  const handleRadioChange = (e) => {
    onFocusTypeChange(_.get(e, "target.value", ""));
  };

  const linkCanBeAdded = (link) => {
    if (links.length >= maxLinksNumber) return false;
    if (_.indexOf(links, link) >= 0) return false;
    return true;
  };

  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormLinksBlock`}
    >
      <fieldset className={styles.sessionLinks}>
        {sessionType !== SESSION_TYPES.SHARE_LINKS && (
          <legend className={styles.title}>{title}</legend>
        )}
        <div className={styles.linksBlock}>
          <div className={styles.addNewLink}>
            <input
              type="text"
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              data-test-id={`${dataTestPrefix}-Input-SessionLinks`}
              aria-label={intl.formatMessage({
                defaultMessage: "Enter links",
                id: "Yuy84o",
              })}
              placeholder={
                links.length !== maxLinksNumber
                  ? intl.formatMessage(
                      {
                        defaultMessage: "Enter up to {max} links...",
                        id: "85wg5D",
                      },
                      { max: maxLinksNumber - links.length }
                    )
                  : intl.formatMessage({
                      defaultMessage: "Maximum links reached",
                      id: "kEWZW3",
                    })
              }
              className={classnames(styles.textInput, {
                [styles.textInputError]: invalidUrl,
              })}
              value={inputLinkText}
              disabled={links.length >= maxLinksNumber}
              {...(invalidUrl && { "aria-describedby": invalidUrlTextId })}
            />

            <Button
              icon="arrow-enter"
              type={BUTTON_TYPES.PRIMARY}
              size={BUTTON_SIZES.SMALL}
              label={intl.formatMessage({
                defaultMessage: "Enter",
                id: "H8KGyc",
              })}
              onAction={addNewLinkHandler}
              className={styles.addLinkButton}
              isDisabled={inputLinkText.length === 0}
              dataTestId={`${dataTestPrefix}-Add-New-Session-Link`}
            />
          </div>
          {invalidUrl && (
            <div
              className={styles.invalidUrlText}
              id={invalidUrlTextId}
              data-test-id={`${dataTestPrefix}-Session-Link-InvalidUrlText`}
            >
              {invalidUrlText}
            </div>
          )}
          {links.length > 0 && (
            <div className={styles.linksList}>
              {_.map(links, (link, ind) => (
                <div
                  key={`${link}${ind}`}
                  className={classnames(styles.linkSquare, {
                    [styles.grayColoured]: sessionType === SESSION_TYPES.FILTER,
                  })}
                  data-test-id={`${dataTestPrefix}-Session-LinkSquare`}
                >
                  <Tippy
                    content={link}
                    theme="oneline"
                    placement="top"
                    distance={4}
                    maxWidth={480}
                  >
                    <span
                      data-test-id={`${dataTestPrefix}-Session-LinkSquareText`}
                    >
                      {getHostname(link)}
                    </span>
                  </Tippy>
                  <Button
                    icon="cross"
                    onClick={() => removeLinkHandler(link)}
                    type={BUTTON_TYPES.TERTIARY}
                    size={BUTTON_SIZES.SMALL}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Remove",
                      id: "G/yZLu",
                    })}
                    data-test-id={`${dataTestPrefix}-Remove-Session-Link`}
                    className={styles.deleteLink}
                  />
                </div>
              ))}
            </div>
          )}
          {recentTabs.length > 0 && (
            <div className={styles.recentTabs}>
              <Dropdown
                positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
                widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
                className={styles.recentTabsDropdown}
                classNameContent={styles.ddContent}
                triggerComponent={(props) => (
                  <Tippy
                    content={intl.formatMessage({
                      defaultMessage:
                        "We store your 20 most recently used links for 7 days to reuse.",
                      id: "5CALLT",
                    })}
                    theme="hsuite"
                    placement="top"
                    distance={4}
                    maxWidth={162}
                  >
                    <DropdownTrigger
                      label={intl.formatMessage({
                        defaultMessage: "Recent Tabs",
                        id: "KAGPjQ",
                      })}
                      widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
                      className={styles.addLinkButton}
                      data-test-id={`${dataTestPrefix}-Dropdown-Recent-Tabs`}
                      precedingIcon="clock-history"
                      hideArrowDown={true}
                      {...props}
                    />
                  </Tippy>
                )}
                itemComponentList={recentTabs.map(
                  (link) =>
                    ({ onClick, className, ...rest }) => {
                      const newLink = normaliseUrl(link);

                      // do not show the links which are already added
                      if (_.indexOf(links, newLink) >= 0) return null;

                      return (
                        <button
                          className={styles.dropdownLink}
                          onClick={() => {
                            onClick();
                            if (linkCanBeAdded(newLink)) {
                              setInvalidUrl(false);
                              onLinksChange(_.concat(links, [newLink]));
                            }
                          }}
                          tabIndex={0}
                          data-test-id={`${dataTestPrefix}-Dropdown-Recent-Tabs-Link`}
                        >
                          {newLink}
                        </button>
                      );
                    }
                )}
              />
            </div>
          )}
        </div>
      </fieldset>

      {sessionType === SESSION_TYPES.FOCUS && (
        <fieldset className={styles.sessionType}>
          <legend>
            <FormattedMessage
              defaultMessage="Where can students go within the link(s)?"
              id="CIJBMy"
            />
          </legend>
          <div className={styles.sessionOptions}>
            <div className={styles.radioButton}>
              <Radio
                name="hl-GuideBrowsingCreateSession-FocusTypesRadio"
                id="hl-GuideBrowsingCreateSession-FocusTypesRadioRadio-Whole"
                value={GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE}
                checked={focusType === GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE}
                onChange={handleRadioChange}
                label={intl.formatMessage({
                  defaultMessage: "The whole site(s)",
                  id: "lVxSVH",
                })}
                dataTestIdPrefix={`${dataTestPrefix}-Radio-Whole-Sites`}
              />
            </div>
            <div className={styles.radioButton}>
              <Radio
                name="hl-GuideBrowsingCreateSession-FocusTypesRadio"
                id="hl-GuideBrowsingCreateSession-FocusTypesRadioRadio-Links"
                value={GUIDE_BROWSING_FOCUS_TYPES.LINKS}
                checked={focusType === GUIDE_BROWSING_FOCUS_TYPES.LINKS}
                onChange={handleRadioChange}
                label={intl.formatMessage({
                  defaultMessage: "Only the page(s)",
                  id: "QOMAak",
                })}
                dataTestIdPrefix={`${dataTestPrefix}-Radio-Pages-Only`}
              />
            </div>
          </div>
        </fieldset>
      )}

      {sessionType === SESSION_TYPES.FILTER && (
        <div>
          <FormattedMessage
            defaultMessage="Students will not be able to access the whole site(s)."
            id="FVDoal"
          />
        </div>
      )}
    </div>
  );
};

ModalFormLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLinksChange: PropTypes.func.isRequired,
  focusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  onFocusTypeChange: PropTypes.func.isRequired,
  sessionType: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  recentTabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ModalFormLinks;
