import { FormattedMessage } from "react-intl";
import React, { useCallback } from "react";
import styles from "./ResourceTileToolbar.module.scss";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { showResourceAddToCollectionsModal } from "../../../../state/resources/actions";
import {
  RESOURCE_FORMAT,
  resourceItemType,
} from "../../../../state/resources/types";
import { isInsideLibrary } from "../../../../state/library/library/selectors";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import {
  hasResourceAddAccess,
  hasCollectionsManageAccess,
  hasWorkspaceAddAccess,
  hasResourceUploadAccess,
} from "../../../../state/library/config/selectors";
import { getWindowSize } from "../../../../state/app/selectors";
import ActionAssignToClasses from "../ActionAssignToClasses/ActionAssignToClasses";
import ActionStarUnstar from "../ActionStarUnstar/ActionStarUnstar";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";
import ActionAddRemoveFromLibrary from "../ActionAddRemoveFromLibrary/ActionAddRemoveFromLibrary";
import ActionEditDetails from "../ActionEditDetails/ActionEditDetails";
import ActionDelete from "../ActionDelete/ActionDelete";

const ResourceTileToolbar = ({
  item,
  id,
  type,
  isItemInLibrary,
  isItemStarred,
  isItemUploaded,
  dataTestIdPrefix,
  isInsideLibrary,
  hasResourceAddAccess,
  hasWorkspaceAddAccess,
  hasResourceUploadAccess,
  hasCollectionsManageAccess,
  showResourceAddToCollectionsModal,
  windowSize,
  isCopiedToLibrary,
  isShared,
  dataTestIdPrefixWithResourceTile,
}) => {
  const dropdownActions = [];

  const actionsDropdownTrigger = useCallback(
    ({ onClick, ...props }) => (
      <MemorisedFocusButton
        label="Actions"
        rightIcon="arrow-carvet-down"
        data-test-id={`${dataTestIdPrefixWithResourceTile}-ActionsDropdown-TriggerButton`}
        type={BUTTON_TYPES.OUTLINED}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        isFullWidth={true}
        {...props}
      />
    ),
    [dataTestIdPrefixWithResourceTile]
  );

  if (
    type === RESOURCE_FORMAT.RESOURCE &&
    isItemUploaded &&
    hasResourceUploadAccess
  ) {
    dropdownActions.push((props) => (
      <ActionEditDetails
        item={item}
        dataTestPrefix={dataTestIdPrefix}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (hasCollectionsManageAccess && isInsideLibrary) {
    // Save to collections action available only on Our Library page
    dropdownActions.push(({ onClick, ...rest }) => (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onClick();
          showResourceAddToCollectionsModal({ resourceId: id });
        }}
        data-test-id={`${dataTestIdPrefix}-SaveToCollections-Button`}
        {...rest}
      >
        <FormattedMessage defaultMessage="Add to collections" id="wpFp98" />
      </button>
    ));
  }

  if (type === RESOURCE_FORMAT.RESOURCE) {
    dropdownActions.push((props) => (
      <ActionAssignToClasses
        dataTestPrefix={dataTestIdPrefix}
        dataTestIdPrefixWithResourceTile={dataTestIdPrefixWithResourceTile}
        isAccessFromTile={true}
        id={id}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (
    type === RESOURCE_FORMAT.RESOURCE &&
    !isItemUploaded &&
    hasResourceAddAccess
  ) {
    dropdownActions.push((props) => (
      <ActionAddRemoveFromLibrary
        dataTestPrefix={dataTestIdPrefix}
        isAddedToLibrary={isItemInLibrary}
        id={id}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  if (
    type === RESOURCE_FORMAT.RESOURCE &&
    isItemUploaded &&
    hasResourceUploadAccess &&
    !isShared
  ) {
    dropdownActions.push((props) => (
      <ActionDelete
        id={id}
        dataTestPrefix={dataTestIdPrefix}
        isDropdownAction={true}
        dropdownItemProps={props}
      />
    ));
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!hasResourceAddAccess &&
          !hasWorkspaceAddAccess &&
          type === RESOURCE_FORMAT.RESOURCE && (
            <ActionAssignToClasses
              dataTestPrefix={dataTestIdPrefix}
              dataTestIdPrefixWithResourceTile={
                dataTestIdPrefixWithResourceTile
              }
              isAccessFromTile={true}
              id={id}
              buttonType={BUTTON_TYPES.OUTLINED}
              label="Share"
              tooltipLabel="Share to classes"
            />
          )}
        {(hasResourceAddAccess || hasWorkspaceAddAccess) &&
          dropdownActions.length > 0 && (
            <Dropdown
              positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
              triggerComponent={actionsDropdownTrigger}
              itemComponentList={dropdownActions}
              widthType={
                windowSize < WINDOW_SIZE_TYPES.S
                  ? DROPDOWN_WIDTH_TYPES.FULL_WIDTH
                  : DROPDOWN_WIDTH_TYPES.MIN_CONTENT
              }
            />
          )}

        {type === RESOURCE_FORMAT.RESOURCE && (
          <ActionStarUnstar
            id={id}
            dataTestPrefix={dataTestIdPrefix}
            isStarred={isItemStarred}
            iconOnly={true}
            isDropdownAction={false}
            dataTestIdPrefixWithResourceTile={dataTestIdPrefixWithResourceTile}
          />
        )}
      </div>
    </div>
  );
};

ResourceTileToolbar.propTypes = {
  item: resourceItemType,
  id: PropTypes.string,
  type: PropTypes.oneOf(_.values(RESOURCE_FORMAT)),
  isItemInLibrary: PropTypes.bool.isRequired,
  isItemStarred: PropTypes.bool.isRequired,
  isItemUploaded: PropTypes.bool.isRequired,
  isCopiedToLibrary: PropTypes.bool.isRequired,
  isShared: PropTypes.bool.isRequired,
  dataTestIdPrefix: PropTypes.string,
  dataTestIdPrefixWithResourceTile: PropTypes.string.isRequired,
  // connected
  isInsideLibrary: PropTypes.bool.isRequired,
  hasResourceAddAccess: PropTypes.bool.isRequired,
  hasWorkspaceAddAccess: PropTypes.bool.isRequired,
  hasResourceUploadAccess: PropTypes.bool.isRequired,
  hasCollectionsManageAccess: PropTypes.bool.isRequired,
  showResourceAddToCollectionsModal: PropTypes.func.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect(
  (state) => ({
    hasResourceAddAccess: hasResourceAddAccess(state),
    hasWorkspaceAddAccess: hasWorkspaceAddAccess(state),
    hasResourceUploadAccess: hasResourceUploadAccess(state),
    hasCollectionsManageAccess: hasCollectionsManageAccess(state),
    isInsideLibrary: isInsideLibrary(state),
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({
    showResourceAddToCollectionsModal: ({ resourceId }) =>
      dispatch(showResourceAddToCollectionsModal({ resourceId })),
  })
)(ResourceTileToolbar);
