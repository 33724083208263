import React, { Fragment } from "react";
import styles from "./TagsLoading.module.scss";
import classnames from "classnames";

import ItemLoading from "@hapara/ui/src/atomic/LoadIndicators/ItemLoading/ItemLoading";

const TagsLoading = () => {
  let content = [];
  for (let i = 0; i < 9; i++) {
    content.push(
      <Fragment key={i}>
        <ItemLoading className={classnames(styles.tag, styles[`tag_${i}`])} />
      </Fragment>
    );
  }

  return <div className={styles.root}>{content}</div>;
};

export default TagsLoading;
