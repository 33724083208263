import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import classnames from "classnames";
import styles from "./ModalFormGClassroomShare.module.scss";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { LOAD_MORE_DESCRIPTORS } from "./GClassroomTypes";

interface GClassroomLoadMoreItemProps {
  isLoading: boolean;
  dataTestPrefix: string;
  hasMoreContent: boolean;
  currentFilterCount: number;
  handleLoadMore: () => void;
  setFilteredItemCount: (count: number) => void;
}

const GClassroomLoadMoreItem = ({
  isLoading,
  handleLoadMore,
  dataTestPrefix,
  hasMoreContent,
  currentFilterCount,
  setFilteredItemCount,
}: GClassroomLoadMoreItemProps) => {
  const intl = useIntl();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  return (
    <tr className={classnames(styles.tableItem, styles.loadMoreRow)}>
      <td className={classnames(styles.itemClasswork, styles.itemLoadMore)}>
        {/* INITIAL LOAD WITH NO MATCHING ITEMS, NO MORE CONTENT TO LOAD */}
        {isInitialFetch && !hasMoreContent && (
          <div className={classnames(styles.loadMoreFeedback)}>
            <FormattedMessage {...LOAD_MORE_DESCRIPTORS.ALL_ITEMS_LOADED} />
          </div>
        )}
        {/* NO MORE CONTENT TO LOAD  */}
        {!isInitialFetch && !hasMoreContent && (
          <div className={classnames(styles.loadMoreFeedback)}>
            <FormattedMessage {...LOAD_MORE_DESCRIPTORS.ALL_ITEMS_LOADED} />
          </div>
        )}
        {/* NO MATCHING ITEMS, MORE AVAILABLE TO LOAD */}
        {hasMoreContent && currentFilterCount === 0 && (
          <div
            className={classnames(styles.loadMoreFeedback, styles.canLoadMore)}
          >
            <FormattedMessage {...LOAD_MORE_DESCRIPTORS.NO_ITEMS} />
          </div>
        )}

        {hasMoreContent && (
          <Button
            isLoading={isLoading}
            onClick={() => {
              handleLoadMore();
              setFilteredItemCount(currentFilterCount);
              setIsInitialFetch(false);
            }}
            label={<FormattedMessage {...LOAD_MORE_DESCRIPTORS.BUTTON} />}
            size={BUTTON_SIZES.REGULAR}
            type={BUTTON_TYPES.SECONDARY}
            alt={intl.formatMessage(LOAD_MORE_DESCRIPTORS.BUTTON_ALT)}
            dataTestId={`${dataTestPrefix}-GCLinksItem-LoadMoreButton`}
          />
        )}
      </td>
      <td className={styles.itemAssignees}></td>
      <td className={styles.itemPosted}></td>
      <td className={styles.itemLinkLoadMore}></td>
    </tr>
  );
};

export default GClassroomLoadMoreItem;
