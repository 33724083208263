import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./SearchParamsBlock.module.scss";

export const SearchParamsBlock = ({
  dataTestId,
  areFiltersSelected,
  keywordsSearchParams,
  filtersSearchParams,
}) => {
  return (
    areFiltersSelected && (
      <div className={styles.root}>
        <div
          className={styles.searchParamList}
          data-test-id={`${dataTestId}-SearchParamList`}
        >
          {filtersSearchParams && (
            <span className={styles.filtersList}>{filtersSearchParams}</span>
          )}
          {filtersSearchParams && keywordsSearchParams && (
            <span className={styles.pipe}>
              <FormattedMessage defaultMessage="|" id="v8m94Z" />
            </span>
          )}
          {keywordsSearchParams && (
            <span className={styles.keywordsList}>
              <span>
                <FormattedMessage defaultMessage="Keywords:" id="ePX8Td" />
              </span>{" "}
              {keywordsSearchParams}
            </span>
          )}
        </div>
      </div>
    )
  );
};

SearchParamsBlock.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  areFiltersSelected: PropTypes.bool.isRequired,
  keywordsSearchParams: PropTypes.string,
  filtersSearchParams: PropTypes.node,
};

export default SearchParamsBlock;
