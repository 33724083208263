import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  MYWORKSPACES_FILTER_STATUS_TYPES,
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
  MYWORKSPACES_DEFAULT_LABEL,
  MYWORKSPACES_PL_ROLES,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_EMPTY_STATES } from "../../../../../state/workspace/myworkspaces/list/empty_states";
import WorkspacesListEmptyStateWithLabelSelected from "../WorkspacesListEmptyStateWithLabelSelected/WorkspacesListEmptyStateWithLabelSelected";
import { connect } from "react-redux";
import { getFFByName } from "../../../../../state/app/selectors";

import styles from "./WorkspacesListEmptyState.module.scss";
import { labelItemType } from "../../../../../state/workspace/myworkspaces/labels/types";

const WorkspacesListEmptyState = ({
  filterStatus,
  category,
  classITeach,
  classIDoNotTeach,
  filterLabel,
  filterPLRole,
  plFF,
}) => {
  const key =
    filterStatus !== MYWORKSPACES_FILTER_STATUS_TYPES.ALL
      ? _.findKey(
          MYWORKSPACES_FILTER_STATUS_TYPES,
          _.partial(_.isEqual, filterStatus)
        )
      : _.findKey(
          MYWORKSPACES_FILTER_CATEGORY_TYPES,
          _.partial(_.isEqual, category)
        );

  let data = MYWORKSPACES_EMPTY_STATES[key];

  switch (key) {
    case "CLASSES":
      data = classITeach
        ? MYWORKSPACES_EMPTY_STATES[key].classITeach
        : MYWORKSPACES_EMPTY_STATES[key].classIDoNotTeach;
      break;

    case "ARCHIVED":
      data = filterLabel.Id
        ? MYWORKSPACES_EMPTY_STATES[key].withLabelSelected
        : MYWORKSPACES_EMPTY_STATES[key].noLabelSelected;
      break;

    case "LABELS":
      data =
        filterLabel.Id === MYWORKSPACES_DEFAULT_LABEL.id
          ? MYWORKSPACES_EMPTY_STATES[key].noLabelSelected
          : filterLabel.deleted
          ? MYWORKSPACES_EMPTY_STATES[key].withLabelDeleted
          : "withLabelSelected"; // the string for execptional case
      break;

    case "PL": {
      switch (filterPLRole) {
        case MYWORKSPACES_PL_ROLES.ALL:
          data = plFF
            ? MYWORKSPACES_EMPTY_STATES[key].filteredByAll
            : MYWORKSPACES_EMPTY_STATES[key].noFilters;
          break;
        case MYWORKSPACES_PL_ROLES.FACILITATOR:
          data = MYWORKSPACES_EMPTY_STATES[key].filteredByFacilitator;
          break;
        case MYWORKSPACES_PL_ROLES.PARTICIPANT:
          data = MYWORKSPACES_EMPTY_STATES[key].filteredByParticipant;
          break;
        default:
          break;
      }
      break;
    }
    default:
      break;
  }

  const getHeader = (header) => {
    return header.replace("<current class name>", classIDoNotTeach);
  };

  if (data === "withLabelSelected") {
    return <WorkspacesListEmptyStateWithLabelSelected />;
  }

  return (
    <div className={styles.root} data-test-id="Ws-WorkspacesList-EmptyState">
      {data && <div className={styles.header}>{getHeader(data.header)}</div>}
      {data && <div className={styles.body}>{data.body}</div>}
    </div>
  );
};

WorkspacesListEmptyState.propTypes = {
  filterStatus: PropTypes.oneOf(_.values(MYWORKSPACES_FILTER_STATUS_TYPES)),
  filterPLRole: PropTypes.oneOf(_.values(MYWORKSPACES_PL_ROLES)),
  category: PropTypes.string,
  classITeach: PropTypes.string,
  classIDoNotTeach: PropTypes.string,
  filterLabelId: labelItemType,
  plFF: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  plFF: getFFByName("HAP-7034-professional-learning")(state),
}))(WorkspacesListEmptyState);
