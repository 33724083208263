import _ from "lodash";
import { history } from "../store";
import { getUpdatedParamList } from "../router/navigation";
import { APP_PAGES } from "../router/types";
import { getCollectionIdFromPath } from "../library/collections/selectors";
import { LIBRARY_URL_KEYS } from "../library/library/navigation";
import { MY_RESOURCES_URL_KEYS } from "../library/myResources/navigation";
import { MYWORKSPACES_CATEGORIES } from "../workspace/myworkspaces/list/types";
import { SHARED_URL_KEYS } from "./navigation-keys";

export const navigateToLanding = () => {
  let pathname = history.location.pathname;

  if (_.startsWith(pathname, APP_PAGES.DISCOVER_BASE.path)) {
    pathname = APP_PAGES.DISCOVER_BASE.path;
  }

  if (_.startsWith(pathname, APP_PAGES.MY_RESOURCES_BASE.path)) {
    pathname = APP_PAGES.MY_RESOURCES_BASE.path;
  }

  if (_.startsWith(pathname, APP_PAGES.LIBRARY_BASE.path)) {
    pathname = APP_PAGES.LIBRARY_BASE.path;
  }

  history.push({
    pathname: pathname,
  });
};

export const driveClearFilters = (classId, hasUpdatedDriveFilter) => {
  if (hasUpdatedDriveFilter) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("query");
    searchParams.delete("classId");
    const updatedSearch = searchParams.toString();

    const newURL = `${APP_PAGES.HIGHLIGHTS_DRIVE.path}?classId=${classId}&${updatedSearch}`;

    return history.push(newURL);
  }

  history.push({
    pathname: `${APP_PAGES.HIGHLIGHTS_DRIVE_OLD.path}`,
    search: `?${SHARED_URL_KEYS.classId.key}=${classId}`,
  });
};

export const updateURLParams = (params) => {
  const cleanedSearch = history.location.search.replace("?", "");
  let pathname = history.location.pathname;
  const newParams = _.cloneDeep(params);

  if (_.startsWith(pathname, APP_PAGES.DISCOVER_BASE.path)) {
    pathname = APP_PAGES.DISCOVER_SEARCH.path;
  } else if (_.startsWith(pathname, APP_PAGES.MY_RESOURCES_BASE.path)) {
    if (_.startsWith(pathname, APP_PAGES.MY_RESOURCES_STARRED.path)) {
      newParams[MY_RESOURCES_URL_KEYS.starred.key] = true;
    }

    pathname = APP_PAGES.MY_RESOURCES_SEARCH.path;
  } else if (_.startsWith(pathname, APP_PAGES.LIBRARY_BASE.path)) {
    if (_.startsWith(pathname, APP_PAGES.LIBRARY_CATEGORIES.path)) {
      const selectedCollectionId = getCollectionIdFromPath(pathname);
      newParams[LIBRARY_URL_KEYS.collection.key] = selectedCollectionId;
    }

    pathname = APP_PAGES.LIBRARY_SEARCH.path;
  } else if (_.startsWith(pathname, APP_PAGES.WORKSPACE_MY_WORKSPACE.path)) {
    pathname = `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.SEARCH.urlKey}`;
  }
  // normalize params for sections where we control query param list
  const newParamList = getUpdatedParamList({
    search: cleanedSearch,
    newParams,
    pathname,
  });

  history.push({
    pathname,
    search: newParamList.length > 0 ? `?${newParamList.join("&")}` : "",
  });
};

export const updateURLDocType = ({ currentPage, classId, clear = false }) => {
  const activePageType = `DASHBOARD_${history.location.pathname
    .split("/")[2]
    .toUpperCase()}`;
  const pathname = `${APP_PAGES[activePageType].path}/${classId}/${currentPage}`;
  const persistedSearch = clear ? null : history.location.search;

  history.push({
    pathname,
    search: persistedSearch,
  });
};
