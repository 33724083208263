import { FormattedMessage } from "react-intl";
// import { useEffect, useRef } from 'react'
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { toggleMobileHeader } from "../../../state/header/actions";
import { getHeaderState } from "../../../state/header/selectors";
import { ALT_TEXT } from "../../../state/app/types";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import Navigation from "../Navigation/Navigation";
import Personalisation from "../Personalisation/Personalisation";
import PersonalisationMobile from "../Personalisation/PersonalisationMobile";
import ClassesListDropDown from "../ClassesListDropDown/ClassesListDropDown";
import Settings from "../Settings/Settings";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./Header.module.scss";
import { MAIN_CONTENT_ID } from "../../../consts";
import { getLocationPath } from "../../../state/router/selectors";
import HighlightsHeaderContent from "../Highlights/HeaderContent/HeaderContent";
import TeacherDashoardHeaderContent from "../Dashboard/HeaderContent/HeaderContent";
import { APP_PAGES } from "../../../state/router/types";
import PagesRequests from "../PagesRequests/PagesRequests";

export const Header = ({
  headerOpen,
  onToggleHeader,
  locationPath,
  isClassDropDownHidden = false,
}) => {
  let sectionSpecificContent = null;

  if (_.startsWith(locationPath, APP_PAGES.HIGHLIGHTS_BASE.path)) {
    sectionSpecificContent = <HighlightsHeaderContent />;
  } else if (_.startsWith(locationPath, APP_PAGES.DASHBOARD_BASE.path)) {
    sectionSpecificContent = <TeacherDashoardHeaderContent />;
  }

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <a
          href={`#${MAIN_CONTENT_ID}`}
          className={classnames(
            styles.visibleOnFocusOnly,
            styles.skipToContent
          )}
        >
          <FormattedMessage defaultMessage="Skip to content" id="ZKtRoA" />
        </a>
        <Link
          to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
          aria-label={ALT_TEXT.TD_MANAGE_CLASSES}
          className={classnames(styles.onDesktopOnly, styles.logo)}
          data-test-id="Hs-Header-Link-HaparaLogoMain"
        >
          <HapReactIcon svg="logo-hapara-small-light" width={40} height={40} />
        </Link>
        <div className={styles.onMobileOnly}>
          <Button
            icon="hamburger"
            iconWidth={32}
            iconHeight={32}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            onAction={() => onToggleHeader(true)}
            alt="Open"
            aria-label="Open side menu"
            dataTestId="Hs-Header-Link-OpenMobileNav"
            className={styles.openPanelTrigger}
          />
        </div>
        <div className={styles.levels}>
          <div
            className={classnames(styles.overlay, {
              [styles.hidden]: !headerOpen,
            })}
          />
          <div className={styles.levelOne}>
            <div className={styles.left}>
              {!isClassDropDownHidden && <ClassesListDropDown />}
              {isClassDropDownHidden && <div />}
              {!isClassDropDownHidden && <PagesRequests />}
            </div>
            <div className={styles.onDesktopOnly}>
              <Personalisation />
              <Settings />
            </div>
          </div>
          <div
            className={classnames(styles.levelTwo, {
              [styles.visible]: headerOpen,
            })}
          >
            <div className={classnames(styles.onMobileOnly, styles.header)}>
              <Button
                icon="close"
                iconWidth={16}
                iconHeight={16}
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.SMALL}
                onAction={() => onToggleHeader(false)}
                aria-label="Close side menu"
                dataTestId="Hs-Header-Link-CloseMobileNav"
                className={styles.closePanelTrigger}
              />
              <Link
                to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
                aria-label={ALT_TEXT.TD_MANAGE_CLASSES}
                data-test-id="Hs-Header-Link-HaparaLogoMobile"
                className={styles.logoMobile}
              >
                <HapReactIcon
                  svg="logo-hapara-small-light"
                  width={32}
                  height={32}
                />
              </Link>
            </div>
            <div className={styles.levelTwoInner}>
              <Navigation />
              {sectionSpecificContent}
              <div className={styles.onMobileOnly}>
                <PersonalisationMobile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  headerOpen: PropTypes.bool.isRequired,
  locationPath: PropTypes.string.isRequired,
  onToggleHeader: PropTypes.func.isRequired,
  isClassDropDownHidden: PropTypes.bool,
};

export default connect(
  (state) => ({
    headerOpen: getHeaderState(state),
    locationPath: getLocationPath(state),
  }),
  (dispatch) => ({
    onToggleHeader: (payload) => dispatch(toggleMobileHeader(payload)),
  })
)(Header);
