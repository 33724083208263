import React from "react";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

type BlockForStudentActionsProps = {
  onSave: () => void;
  onClose: () => void;
  isSaveDisabled: boolean;
  dataTestIdPrefix: string;
};

export const BlockForStudentActions = ({
  onSave,
  onClose,
  isSaveDisabled,
  dataTestIdPrefix,
}: BlockForStudentActionsProps) => {
  const intl = useIntl();
  return (
    <>
      <Button
        label={intl.formatMessage({ defaultMessage: "Cancel", id: "47FYwb" })}
        type="secondary"
        onAction={onClose}
        dataTestId={`${dataTestIdPrefix}-cancel`}
      />
      <Button
        onClick={onSave}
        isDisabled={isSaveDisabled}
        type="danger"
        label={intl.formatMessage({
          defaultMessage: "Block for the student",
          id: "pB9SYz",
        })}
        dataTestId={`${dataTestIdPrefix}-confirm`}
      />
    </>
  );
};
