import _ from "lodash";
import actionTypes from "../../actionTypes";

const INIT_STATE = null;

export const INSTANCE_DEFAULT_PROPS = {
  isOnline: false,
  isFirstDataMessageReceived: false,
  tabs: null,
  instanceId: null,
  status: null,
  lock: null,
  users: [],
  currentScreen: null,
  onlineTimestamp: 0,
};

const STUDENT_DEFAULT_PROPS = {
  instances: {},
  currentInstance: null,
  lastSelectedInstance: null,
};

const getReconnectionTime = (isOnline, studentState) => {
  if (!isOnline || _.get(studentState, "isOnline")) {
    //only modifying if it's a transition from offline to online
    return _.get(studentState, "reconnectionTime");
  }
  if (!_.get(studentState, "offlineTimestamp")) {
    return null; //avoid NaN
  }
  return new Date().getTime() - _.get(studentState, "offlineTimestamp");
};

const studentsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS: {
      const { Students } = action.payload;
      return Students && Students.length
        ? _.keyBy(
            _.map(Students, (student) => ({
              ...STUDENT_DEFAULT_PROPS,
              ...student,
            })),
            "Id"
          )
        : {};
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_ONLINE_STATE: {
      const { studentId, isOnline, timestamp, instanceId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        const instances = state[studentId].instances;
        const otherOnlineInstanceList = _.filter(
          _.values(instances),
          (item) => item.instanceId !== instanceId && item.isOnline
        );
        const currentInstance = state[studentId].currentInstance;

        // set it to the current one by default
        let newCurrentInstance = currentInstance;

        if (!currentInstance && isOnline) {
          // if no current device yet, set it to this one if it's online message
          newCurrentInstance = instanceId;
        } else if (currentInstance === instanceId && !isOnline) {
          // if this device is the current one
          // AND it goes offline
          if (otherOnlineInstanceList.length > 0) {
            // if there are other online devices
            // switch to the next online one
            newCurrentInstance = otherOnlineInstanceList[0].instanceId;
          } else {
            // otherwise clear the selected instance
            newCurrentInstance = null;
          }
        }

        // if device goes offline
        // RESET instance to default props
        // if online, do nothing
        const newInstanceProps = !isOnline
          ? INSTANCE_DEFAULT_PROPS
          : state[studentId].instances[instanceId];

        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            currentInstance: newCurrentInstance,
            instances: {
              ...state[studentId].instances,
              [instanceId]: {
                ...newInstanceProps,
                isOnline,
                offlineTimestamp: !isOnline
                  ? new Date().getTime()
                  : _.get(
                      state[studentId].instances[instanceId],
                      "offlineTimestamp"
                    ),
                //time between the last offline and the online
                reconnectionTime: getReconnectionTime(
                  isOnline,
                  state[studentId].instances[instanceId]
                ),
                onlineTimestamp:
                  isOnline && timestamp
                    ? timestamp
                    : _.get(
                        state[studentId].instances[instanceId],
                        "onlineTimestamp"
                      ),
              },
            },
          },
        };
      } else {
        return state;
      }
    }

    case actionTypes.HIGHLIGHTS_CLEAN_ALL_STUDENTS_INSTANCE_DATA: {
      const studentIds = _.keys(state);
      const newState = _.cloneDeep(state);

      _.forEach(studentIds, (studentId) => {
        newState[studentId] = {
          ...newState[studentId], // get the current sate
          ...STUDENT_DEFAULT_PROPS, // RESET to default props
        };
      });

      return newState;
    }

    case actionTypes.HIGHLIGHTS_CLEAN_STUDENT_INSTANCE_DATA: {
      const { studentId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            ...STUDENT_DEFAULT_PROPS, // RESET to default props
          },
        };
      } else {
        return state;
      }
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_DATA: {
      const { studentId, tabs, lock, users, instanceId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        // if no currentInstance yet, set it to this one
        const currentInstance = state[studentId].currentInstance
          ? state[studentId].currentInstance
          : instanceId;

        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            currentInstance,
            instances: {
              ...state[studentId].instances,
              [instanceId]: {
                ...state[studentId].instances[instanceId],
                isFirstDataMessageReceived: true,
                tabs,
                lock,
                instanceId,
                users,
              },
            },
          },
        };
      } else {
        return state;
      }
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_STATUS: {
      const { studentId, status, instanceId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        // if no currentInstance yet, set it to this one
        const currentInstance = state[studentId].currentInstance
          ? state[studentId].currentInstance
          : instanceId;

        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            currentInstance,
            instances: {
              ...state[studentId].instances,
              [instanceId]: {
                ...state[studentId].instances[instanceId],
                isFirstDataMessageReceived: true,
                status,
              },
            },
          },
        };
      } else {
        return state;
      }
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_CURRENT_SCREEN: {
      const { studentId, currentScreen, instanceId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        // if no currentInstance yet, set it to this one
        const currentInstance = state[studentId].currentInstance
          ? state[studentId].currentInstance
          : instanceId;
        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            currentInstance,
            instances: {
              ...state[studentId].instances,
              [instanceId]: {
                ...state[studentId].instances[instanceId],
                isFirstDataMessageReceived: true,
                currentScreen,
              },
            },
          },
        };
      } else {
        return state;
      }
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_SELECTED_INSTANCE: {
      const { studentId, instanceId } = action.payload;
      const studentIds = _.keys(state);
      if (_.indexOf(studentIds, studentId) !== -1) {
        return {
          ...state,
          [studentId]: {
            ...state[studentId],
            currentInstance: instanceId,
            lastSelectedInstance: instanceId,
          },
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default studentsReducer;
