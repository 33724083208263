import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import {
  removeClass,
  resetRemoveClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsRemoveClassLoading,
  getIsRemoveClassError,
  getIsRemoveClassLoaded,
  getClassInfoData,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  isRemoveClassError: boolean;
  isRemoveClassLoaded: boolean;
  isRemoveClassLoading: boolean;
  handleRemoveClass: () => void;
};

type UseClassInfoRemoveClassProps = {
  closeModal: () => void;
  isModalOpen: boolean;
};

export const useClassInfoRemoveClass = ({
  closeModal,
  isModalOpen,
}: UseClassInfoRemoveClassProps): ExportedValues => {
  const dispatch = useDispatch();
  const isRemoveClassLoading = useSelector(getIsRemoveClassLoading);
  const isRemoveClassError = useSelector(getIsRemoveClassError);
  const isRemoveClassLoaded = useSelector(getIsRemoveClassLoaded);
  const classGroupId = useSelector(getClassInfoData)?.classGroupId;

  const handleRemoveClass = () => {
    classGroupId && dispatch(removeClass(classGroupId));
  };

  useEffect(() => {
    if (!isRemoveClassLoading && isRemoveClassLoaded && !isRemoveClassError) {
      closeModal();
    }
  }, [isRemoveClassLoading, isRemoveClassLoaded, isRemoveClassError]);

  useEffect(() => {
    dispatch(resetRemoveClassState());
  }, [isModalOpen]);

  return {
    isRemoveClassError,
    isRemoveClassLoaded,
    isRemoveClassLoading,
    handleRemoveClass,
  };
};
