import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import {
  addTeacherToClass,
  resetAddTeacherToClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsAddTeacherLoading,
  getIsAddTeacherLoaded,
  getIsAddTeacherError,
  getIsAddTeacherErrorPayload,
  getIsAddTeacherServerValidationError,
} from "../../state/classInfo/selectors";

//UTILS
import { singleEmailInputValidation } from "@hapara/ui/src/utils/emailValidation/emailValidation";

type ExportedValues = {
  isAddTeacherError: boolean;
  isAddTeacherLoaded: boolean;
  isAddTeacherLoading: boolean;
  validationErrorPayload: ServerInputValidationErrorType;
  showNetworkErrorBlock: boolean;
  handleAddTeacher: (teacher: string) => void;
};

type UseClassInfoAddTeacherProps = {
  teacherEmail: string;
  closeModal: () => void;
  isModalOpen: boolean;
};

type ServerInputValidationErrorType = {
  hasValidationError: boolean;
  validationErrorMessage?: string;
};

type ClientInputValidationType = {
  hasError: boolean;
  trimmedInput: string;
  errorMessage?: string;
};

export const useClassInfoAddTeacher = ({
  closeModal,
  isModalOpen,
}: UseClassInfoAddTeacherProps): ExportedValues => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isAddTeacherLoading = useSelector(getIsAddTeacherLoading);
  const isAddTeacherError = useSelector(getIsAddTeacherError);
  const isAddTeacherLoaded = useSelector(getIsAddTeacherLoaded);
  const serverDomainValidationError = useSelector(getIsAddTeacherErrorPayload);
  const hasServerNameValidationError = useSelector(
    getIsAddTeacherServerValidationError
  );
  const [validationErrorPayload, setValidationErrorPayload] =
    useState<ServerInputValidationErrorType>({
      hasValidationError: false,
    });

  const hasVerboseServerError =
    isAddTeacherLoaded &&
    (serverDomainValidationError.length > 0 || hasServerNameValidationError);
  const serverErrorMessage =
    serverDomainValidationError.length > 0
      ? serverDomainValidationError[0].Reason
      : "User email address not valid in this domain";

  useEffect(() => {
    if (hasVerboseServerError) {
      setValidationErrorPayload({
        hasValidationError: true,
        validationErrorMessage: serverErrorMessage,
      });
    }
  }, [isAddTeacherLoaded]);

  const handleAddTeacher = (teacher: string) => {
    if (hasServerNameValidationError) dispatch(resetAddTeacherToClassState());
    setValidationErrorPayload({ hasValidationError: false });
    const clientInputValidation: ClientInputValidationType =
      singleEmailInputValidation(teacher);
    clientInputValidation.hasError
      ? setValidationErrorPayload({
          hasValidationError: true,
          validationErrorMessage: clientInputValidation.errorMessage,
        })
      : dispatch(
          addTeacherToClass(classId, clientInputValidation.trimmedInput)
        );
  };

  const shouldCloseModal =
    !isAddTeacherLoading &&
    isAddTeacherLoaded &&
    !isAddTeacherError &&
    serverDomainValidationError.length === 0;

  const shouldShowErrorBlock =
    isAddTeacherError && !hasServerNameValidationError;

  useEffect(() => {
    if (shouldCloseModal) {
      closeModal();
    }
  }, [
    isAddTeacherLoading,
    isAddTeacherLoaded,
    isAddTeacherError,
    serverDomainValidationError,
  ]);

  useEffect(() => {
    dispatch(resetAddTeacherToClassState());
    setValidationErrorPayload({ hasValidationError: false });
  }, [isModalOpen]);

  return {
    isAddTeacherError: isAddTeacherError,
    isAddTeacherLoaded: isAddTeacherLoaded,
    isAddTeacherLoading: isAddTeacherLoading,
    validationErrorPayload: validationErrorPayload,
    showNetworkErrorBlock: shouldShowErrorBlock,
    handleAddTeacher,
  };
};
