import React from "react";
import PropTypes from "prop-types";

import styles from "./ShareStudentItem.module.scss";
import { studentItemType } from "../types";

export const ShareStudentItem = ({ data = {}, unshared = false }) => {
  const defaultMessage = unshared
    ? "file was not unshared"
    : "file was not shared";
  return (
    <div className={styles.root}>
      <div>
        <span>{data.Student}</span>
        &nbsp;-&nbsp;
        {data.error_message || defaultMessage}
      </div>
    </div>
  );
};

ShareStudentItem.propTypes = {
  data: studentItemType,
  unshared: PropTypes.bool,
};

export default ShareStudentItem;
