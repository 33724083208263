import React from "react";
import styles from "./VisuallyHidden.module.scss";

type VisuallyHiddenProps = {
  children: React.ReactNode;
};

export const VisuallyHidden = ({ children }: VisuallyHiddenProps) => (
  <span className={styles.root}>{children}</span>
);
