import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./EmptySearchResults.module.scss";

export const EmptySearchResults = ({ dataTestId }) => {
  return (
    <div className={styles.root} data-test-id={dataTestId}>
      <h1>
        <FormattedMessage
          defaultMessage="Sorry, we couldn’t find any results!"
          id="AxctB8"
        />
      </h1>
      <div className={styles.tipsHeader}>
        <FormattedMessage defaultMessage="Try these search tips:" id="7G713h" />
      </div>
      <div className={styles.tips}>
        Try different filters or keywords related to your topic of interest
        <br />
        Double-check the spelling of your keyword
      </div>
    </div>
  );
};

EmptySearchResults.propTypes = {
  dataTestId: PropTypes.string.isRequired,
};

export default EmptySearchResults;
