import React from "react";
import { ChatLayout } from "../../components/ChatLayout";
import { ChatHeader } from "../../components/ChatHeader";
import { CloseButton } from "../../../../atomic/CloseButton";
import { ChatChannelList } from "../../components/ChatChannelList/ChatChannelList";
import { ChatFooterLinks } from "../../components/ChatFooterLinks";
import { ChatSwitch } from "../../components/ChatSwitch";
import { BackButton } from "../../../../atomic/BackButton";
import {
  ChatContactsState,
  ChatChannelsState,
  ChatRoleType,
  ChatClassState,
  ChatClassStatus,
} from "../../types/state";
import { useIntl } from "react-intl";
import { DisplayNameFormat } from "../../../DisplayName";

type ChatContactsViewProps = {
  userId: string;
  selectedClass: ChatClassState;
  roleType: ChatRoleType;
  contacts: ChatContactsState | null;
  channels: ChatChannelsState | null;
  onSelectContact(id: string): void;
  onBack?(): void;
  onClose(): void;
  onClassStatusChange?: (
    classId: string,
    userId: string,
    status: ChatClassStatus
  ) => void;
  isSchoolHours?: boolean;
  displayNameFormat?: DisplayNameFormat;
};

export const ChatChannelListView = ({
  userId,
  contacts,
  channels,
  onSelectContact,
  onClose,
  onBack,
  selectedClass,
  roleType,
  onClassStatusChange,
  isSchoolHours,
  displayNameFormat,
}: ChatContactsViewProps) => {
  const intl = useIntl();

  const isClassEnabled = selectedClass?.status?.[userId] === "enabled";

  const handleClassStatusChange = () => {
    if (selectedClass.urn) {
      onClassStatusChange?.(
        selectedClass.urn,
        userId,
        isClassEnabled ? "disabled" : "enabled"
      );
    }
  };

  const hasChannels = channels && Object.keys(channels).length > 0;
  const isTeacher = roleType === "teacher";
  const showChatSwitch = isTeacher && hasChannels && isSchoolHours;

  const title =
    selectedClass.name && roleType === "student"
      ? selectedClass.name
      : intl.formatMessage({ defaultMessage: "Chat", id: "WTrOy3" });

  return (
    <ChatLayout
      header={
        <ChatHeader
          title={title}
          leading={
            onBack && roleType === "student" ? (
              <BackButton
                onClick={onBack}
                dataTestIdPrefix="chat-channel-list"
              />
            ) : null
          }
          actions={
            <>
              {showChatSwitch ? (
                <ChatSwitch
                  onClassStatusChange={handleClassStatusChange}
                  isEnabled={isClassEnabled}
                />
              ) : null}
              <CloseButton onClick={onClose} dataTestIdPrefix="chat" />
            </>
          }
        />
      }
      footer={roleType === "student" ? <ChatFooterLinks /> : null}
    >
      <ChatChannelList
        classId={selectedClass.urn}
        role={roleType}
        contacts={contacts}
        selectedClass={selectedClass}
        channels={channels}
        onSelectChannelId={onSelectContact}
        isClassEnabled={isClassEnabled}
        isSchoolHours={isSchoolHours}
        displayNameFormat={displayNameFormat}
      />
    </ChatLayout>
  );
};
