import _ from "lodash";
import { createSelector } from "reselect";

export const getResources = (state) => _.get(state, "resources");

export const getResourceDetails = createSelector(getResources, (res) =>
  _.get(res, "resourceDetails")
);

export const getResourceAddToCollections = createSelector(getResources, (res) =>
  _.get(res, "resourceAddToCollections")
);

export const getResourceAssignClass = createSelector(getResources, (res) =>
  _.get(res, "resourceAssignClass")
);

export const getResourceRemoveFromLibrary = createSelector(
  getResources,
  (res) => _.get(res, "resourceRemoveFromLibrary")
);

export const getResourceDelete = createSelector(getResources, (res) =>
  _.get(res, "resourceDelete")
);

export const getIsResourceDetailsModalOpen = createSelector(
  getResourceDetails,
  (res) => _.get(res, "isModalOpen")
);

export const getResourceDetailsModalData = createSelector(
  getResourceDetails,
  (res) => _.get(res, "modalData")
);

export const getResourceDetailsModalMode = createSelector(
  getResourceDetails,
  (res) => _.get(res, "mode")
);

export const getIsResourceAssignClassModalOpen = createSelector(
  getResourceAssignClass,
  (res) => _.get(res, "isModalOpen")
);

export const getResourceAssignClassModalData = createSelector(
  getResourceAssignClass,
  (res) => _.get(res, "modalData")
);

export const getIsResourceAddToCollectionsModalOpen = createSelector(
  getResourceAddToCollections,
  (res) => _.get(res, "isModalOpen")
);

export const getResourceAddToCollectionsModalData = createSelector(
  getResourceAddToCollections,
  (res) => _.get(res, "modalData")
);

export const getIsResourceRemoveFromLibraryModalOpen = createSelector(
  getResourceRemoveFromLibrary,
  (res) => _.get(res, "isModalOpen")
);

export const getResourceRemoveFromLibraryModalData = createSelector(
  getResourceRemoveFromLibrary,
  (res) => _.get(res, "modalData")
);

export const getIsResourceDeleteModalOpen = createSelector(
  getResourceDelete,
  (res) => _.get(res, "isModalOpen")
);

export const getResourceDeleteModalData = createSelector(
  getResourceDelete,
  (res) => _.get(res, "modalData")
);
