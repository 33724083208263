const lightText = '#FFF'
const darkText = '#000'


//TODO: Expand on the accessible colour names already added

export const colourRange = [
  {
    colourIndex: 200, //Used: Group Filters - All Button
    colourType: 'utility',
    colourHex: '#2D2D47',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 201, //Used: Group Filters - Dropdown
    colourType: 'utility',
    colourHex: '#D6D6D8',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 202, //Used: Group Filters - Default Group Colour Set
    colourType: 'utility',
    colourHex: '#FFF',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 0, //Group Colours Start
    colourType: 'group',
    colourHex: '#ffbbbc',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 1,
    colourType: 'group',
    colourHex: '#ffb6f5',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 2,
    colourType: 'group',
    colourHex: '#d0a1fe',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 3,
    colourType: 'group',
    colourHex: '#b4bdfe',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 4,
    colourType: 'group',
    colourHex: '#abe4ff',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 5,
    colourType: 'group',
    colourHex: '#adffff',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 6,
    colourType: 'group',
    colourHex: '#a5ffb4',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 7,
    colourType: 'group',
    colourHex: '#ddff85',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 8,
    colourType: 'group',
    colourHex: '#ffff89',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 9,
    colourType: 'group',
    colourHex: '#ffe8b0',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 10,
    colourType: 'group',
    colourHex: '#ffcbad',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 11,
    colourType: 'group',
    colourHex: '#ff6769',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 12,
    colourType: 'group',
    colourHex: '#ff7ded',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 13,
    colourType: 'group',
    colourHex: '#b76dfd',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 14,
    colourType: 'group',
    colourHex: '#8191fe',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 15,
    colourType: 'group',
    colourHex: '#60cbfc',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 16,
    colourType: 'group',
    colourHex: '#47ffff',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 17,
    colourType: 'group',
    colourHex: '#44f56b',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 18,
    colourType: 'group',
    colourHex: '#c4f74e',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 19,
    colourType: 'group',
    colourHex: '#f3f636',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 20,
    colourType: 'group',
    colourHex: '#ffd16d',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 21,
    colourType: 'group',
    colourHex: '#ff975c',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 22,
    colourType: 'group',
    colourHex: '#ea0000',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 23,
    colourType: 'group',
    colourHex: '#d100b5',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 24,
    colourType: 'group',
    colourHex: '#8509e6',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 25,
    colourType: 'group',
    colourHex: '#3346d8',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 26,
    colourType: 'group',
    colourHex: '#0b9ad4',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 27,
    colourType: 'group',
    colourHex: '#00d3d4',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 28,
    colourType: 'group',
    colourHex: '#00c12c',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 29,
    colourType: 'group',
    colourHex: '#97cd21',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 30,
    colourType: 'group',
    colourHex: '#d1d319',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 31,
    colourType: 'group',
    colourHex: '#ecab1f',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 32,
    colourType: 'group',
    colourHex: '#e36621',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 33,
    colourType: 'group',
    colourHex: '#941f22',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 34,
    colourType: 'group',
    colourHex: '#781069',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 35,
    colourType: 'group',
    colourHex: '#53128d',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 36,
    colourType: 'group',
    colourHex: '#142397',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 37,
    colourType: 'group',
    colourHex: '#007cad',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 38,
    colourType: 'group',
    colourHex: '#008080',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 39,
    colourType: 'group',
    colourHex: '#06892d',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 40,
    colourType: 'group',
    colourHex: '#78a419',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 41,
    colourType: 'group',
    colourHex: '#a6a81c',
    textHex: darkText,
    accessibleName:'' ,
  },
  {
    colourIndex: 42,
    colourType: 'group',
    colourHex: '#bd5920',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 43,
    colourType: 'group',
    colourHex: '#04106b',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 44,
    colourType: 'group',
    colourHex: '#004465',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 45,
    colourType: 'group',
    colourHex: '#5a2105',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 46,
    colourType: 'group',
    colourHex: '#3b5110',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 47,
    colourType: 'group',
    colourHex: '#9f0088',
    textHex: lightText,
    accessibleName:'' ,
  },
  {
    colourIndex: 1, //Card Subset Colours Start
    colourType: 'card',
    colourHex: '#fff',
    textHex: darkText,
    accessibleName:'White',
    displayOrder: 11, //Used to determine the position in the palette array
  },
  {
    colourIndex: 2,
    colourType: 'card',
    colourHex: '#b4daec',
    textHex: darkText,
    accessibleName:'Light Blue',
    displayOrder: 6, //Used to determine the position in the palette array
  },
  {
    colourIndex: 3,
    colourType: 'card',
    colourHex: '#dccee8',
    textHex: darkText,
    accessibleName:'Light Purple' ,
    displayOrder: 4, //Used to determine the position in the palette array
  },
  {
    colourIndex: 4,
    colourType: 'card',
    colourHex: '#ffbab1',
    textHex: darkText,
    accessibleName:'Light Red' ,
    displayOrder: 2, //Used to determine the position in the palette array
  },
  {
    colourIndex: 5,
    colourType: 'card',
    colourHex: '#bce8db',
    textHex: darkText,
    accessibleName:'Light Green' ,
    displayOrder: 8, //Used to determine the position in the palette array
  },
  {
    colourIndex: 6,
    colourType: 'card',
    colourHex: '#f8e68a',
    textHex: darkText,
    accessibleName:'Light Mustard' ,
    displayOrder: 9, //Used to determine the position in the palette array
  },
  {
    colourIndex: 7,
    colourType: 'card',
    colourHex: '#6a6a6a',
    textHex: lightText,
    accessibleName:'Dark Gray' ,
    displayOrder: 10, //Used to determine the position in the palette array
  },
  {
    colourIndex: 8,
    colourType: 'card',
    colourHex: '#1672a0',
    textHex: lightText,
    accessibleName:'Dark Blue' ,
    displayOrder: 5, //Used to determine the position in the palette array
  },
  {
    colourIndex: 9,
    colourType: 'card',
    colourHex: '#945594',
    textHex: lightText,
    accessibleName:'Dark Purple' ,
    displayOrder: 3, //Used to determine the position in the palette array
  },
  {
    colourIndex: 10,
    colourType: 'card',
    colourHex: '#c83f1a',
    textHex: lightText,
    accessibleName:'Dark Red' ,
    displayOrder: 1, //Used to determine the position in the palette array
  },
  {
    colourIndex: 11,
    colourType: 'card',
    colourHex: '#008761',
    textHex: lightText,
    accessibleName:'Dark Green' ,
    displayOrder: 7, //Used to determine the position in the palette array
  },
  {
    colourIndex: 12,
    colourType: 'card',
    colourHex: '#915900',
    textHex: lightText,
    accessibleName:'Dark Mustard' ,
    displayOrder: 12, //Used to determine the position in the palette array
  },

]
