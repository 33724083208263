import actionTypes from "../../../actionTypes";
import _ from "lodash";
import {
  fetchMyWorkspacesCall,
  setWorkspacePublished,
  archiveWorkspace,
  copyWorkspace,
  deleteWorkspace,
  restoreWorkspace,
  fetchWorkspaceProgressById,
  fetchMimeType,
} from "../../../../apiCalls/workspaces";
import { makeActionCreator } from "../../../storeUtils";
import {
  getCurrentPage,
  getFilterStatusType,
  getFilterCategoryType,
  getFilterClassId,
  getFilterSortType,
  getFilterLabelId,
  getFilterPlRole,
  getActiveItemId,
  getActiveActivityType,
  getActiveCardId,
} from "./selectors";

import {
  MYWORKSPACES_PAGE_SIZE,
  MYWORKSPACES_ACTION_TYPES,
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
} from "./types";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "./navigation";
import { notAllowedToShowWorkspaces } from "../../../shared/selectors";
import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../../app/actions";
import { pushQueryToHistory } from "../../../router/navigation";

const updateArtifactMimeType = (
  artifactId,
  mimeType,
  activeItemId,
  activeCardId,
  activeActivityType
) => ({
  type: actionTypes.MYWORKSPACES_ARTIFACT_UPDATE_MIMETYPE,
  artifactId,
  mimeType,
  activeItemId,
  activeCardId,
  activeActivityType,
});

export const updateLeftPanel = (isOpen) => ({
  type: actionTypes.MYWORKSPACES_LEFT_PANEL_UPDATE,
  payload: isOpen,
});

export const updateRightPanel = (isOpen) => ({
  type: actionTypes.MYWORKSPACES_RIGHT_PANEL_UPDATE,
  payload: isOpen,
});

export const dismissError = () => {
  return {
    type: actionTypes.MYWORKSPACES_DISMISS_ERROR,
  };
};

export const clearList = () => ({
  type: actionTypes.MYWORKSPACES_CLEAR,
});

export const updateCategory = (category) => {
  return {
    type: actionTypes.URL_PARAM_UPDATE_CATEGORY,
    param: category,
  };
};

export const getMyWorkspacesAction =
  ({
    category,
    filterPlRole,
    classId,
    page,
    filterStatusType,
    isSilentUpdate,
    filterSortType,
    filterLabelId,
  }) =>
  (dispatch) => {
    if (!isSilentUpdate) {
      dispatch(myWorkspacesPending());
    }

    dispatch(hideAppError());

    fetchMyWorkspacesCall({
      category,
      filterPlRole,
      classId,
      page,
      filterStatusType,
      filterSortType,
      filterLabelId,
    })
      .then((response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        return {
          items: _.get(data, "Workspaces"),
          wsCount: _.get(data, "Count"),
          selectedClassName: _.get(data, "SelectedClassName"),
        };
      })
      .then(({ items, wsCount, selectedClassName }) =>
        dispatch(
          myWorkspacesLoad(
            items,
            wsCount,
            Math.ceil(wsCount / MYWORKSPACES_PAGE_SIZE),
            selectedClassName
          )
        )
      )
      .catch((error) => {
        dispatch(myWorkspacesError(error));
        dispatch(showAppError(error));
      });
  };

export const applyAllFilters =
  (isSilentUpdate = false) =>
  (dispatch, getState) => {
    const state = getState();
    const classId = getFilterClassId(state);
    const filterStatusType = getFilterStatusType(state);
    const filterPlRole = getFilterPlRole(state);
    const filterCategoryType = getFilterCategoryType(state);
    const filterSortType = getFilterSortType(state);
    const filterLabelId = getFilterLabelId(state);
    const page = getCurrentPage(state);
    const notAllowedToShowContent = notAllowedToShowWorkspaces(state);
    switch (filterCategoryType) {
      case MYWORKSPACES_FILTER_CATEGORY_TYPES.CLASSES:
        if (notAllowedToShowContent) {
          dispatch(myWorkspacesLoad([], 0, 0));
        } else if (classId) {
          dispatch(
            getMyWorkspacesAction({
              category: filterCategoryType,
              classId,
              filterStatusType,
              page,
              filterSortType,
              isSilentUpdate,
            })
          );
        }

        break;

      case MYWORKSPACES_FILTER_CATEGORY_TYPES.OWNED_BY_ME:
      case MYWORKSPACES_FILTER_CATEGORY_TYPES.SHARED_WITH_ME:
        dispatch(
          getMyWorkspacesAction({
            category: filterCategoryType,
            filterStatusType,
            page,
            filterSortType,
            isSilentUpdate,
          })
        );
        break;
      case MYWORKSPACES_FILTER_CATEGORY_TYPES.PL:
        dispatch(
          getMyWorkspacesAction({
            category: filterCategoryType,
            filterPlRole,
            filterStatusType,
            page,
            filterSortType,
            isSilentUpdate,
          })
        );
        break;

      case MYWORKSPACES_FILTER_CATEGORY_TYPES.ARCHIVED:
        dispatch(
          getMyWorkspacesAction({
            category: filterCategoryType,
            page,
            filterSortType,
            filterLabelId,
            isSilentUpdate,
          })
        );
        break;

      case MYWORKSPACES_FILTER_CATEGORY_TYPES.LABELS:
        // makes sense to make this call only we have a label selected
        // in some cases last label might be deleted and filterLabelId set to null
        // then we display empty state and stay in Labels category
        if (filterLabelId)
          dispatch(
            getMyWorkspacesAction({
              category: filterCategoryType,
              page,
              filterStatusType,
              filterSortType,
              filterLabelId,
              isSilentUpdate,
            })
          );
        break;

      default:
        break;
    }
  };

export const makeWorkspaceUpdateAction = (apiCall, action, select) => {
  return function (item, ...args) {
    return (dispatch, getState) => {
      dispatch({ type: actionTypes.MYWORKSPACES_ACTION_PENDING });
      return apiCall(item, ...args)
        .then((response) => {
          if (!response.ok) {
            throw Error(response);
          } else {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json();
            }
            return response.text();
          }
        })
        .then((data) => {
          dispatch({
            type: actionTypes.MYWORKSPACES_ACTION_SUCCESS,
            workspace: select(item, data),
            action,
          });

          dispatch(applyAllFilters(true)); // isSilentUpdate is a parameter
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: actionTypes.MYWORKSPACES_ACTION_ERROR, error });
        });
    };
  };
};

export const myWorkspacesPending = makeActionCreator(
  actionTypes.MYWORKSPACES_LOAD_PENDING
);
export const myWorkspacesLoad = makeActionCreator(
  actionTypes.MYWORKSPACES_LOAD_SUCCESS,
  "items",
  "wsCount",
  "pagesCount",
  "selectedClassName"
);
export const myWorkspacesError = makeActionCreator(
  actionTypes.MYWORKSPACES_LOAD_ERROR,
  "error"
);
export const setPublishedAction = makeWorkspaceUpdateAction(
  setWorkspacePublished,
  MYWORKSPACES_ACTION_TYPES.REPLACE,
  (_, data) => data
);
export const archiveWorkspaceAction = makeWorkspaceUpdateAction(
  archiveWorkspace,
  MYWORKSPACES_ACTION_TYPES.DELETE,
  (_, data) => data
);
export const copyWorkspaceAction = makeWorkspaceUpdateAction(
  copyWorkspace,
  MYWORKSPACES_ACTION_TYPES.ADD,
  (_, data) => data.Board
);
export const deleteWorkspaceAction = makeWorkspaceUpdateAction(
  deleteWorkspace,
  MYWORKSPACES_ACTION_TYPES.DELETE,
  (item, _) => item
);
export const restoreWorkspaceAction = makeWorkspaceUpdateAction(
  restoreWorkspace,
  MYWORKSPACES_ACTION_TYPES.DELETE,
  (_, data) => data
);

export const loadWorkspaceProgressById = (boardId) => (dispatch) => {
  dispatch(hideAppError());
  dispatch({
    type: actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_PENDING,
  });

  return fetchWorkspaceProgressById(boardId)
    .then((response) => {
      if (response.status === 403) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }

      return response.json();
    })
    .then((data) => {
      dispatch({
        type: actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_SUCCESS,
        id: boardId,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.MYWORKSPACES_ACTIVE_ITEM_LOAD_ERROR,
      });
      pushQueryToHistory({
        selected_ws: MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.selected_ws,
      });
      dispatch(showAppError(error));
    });
};

export const getMimeType = (artifact) => (dispatch, getState) => {
  const fileId = artifact.URI.replace(/^urn:gdrive:/, "");

  if (fileId) {
    const state = getState();
    const activeItemId = getActiveItemId(state);
    const activeActivityType = getActiveActivityType(state);
    const activeCardId = getActiveCardId(state);

    // Get mime type from Hapara Backend
    fetchMimeType(fileId)
      .then((response) => {
        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        const mimeType = _.get(data, "mimeType");
        dispatch(
          updateArtifactMimeType(
            artifact.Id,
            mimeType,
            activeItemId,
            activeCardId,
            activeActivityType
          )
        );
      })
      .catch((err) => console.log("getting mimeType error: ", err));
  }
};
