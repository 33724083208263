import { useState, useEffect } from "react";

export const useInjectScript = (src: string) => {
  const [isLoaded, setLoaded] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      setLoaded(true);
    };

    script.onerror = () => {
      setError(true);
    };
  }, []);

  return { isLoaded, hasError };
};
