import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import styles from "./PageRequests.module.scss";
import { studentRequest } from "../../../state/highlights/studentRequests/types";
import {
  approveStudentRequest,
  rejectStudentRequest,
  dismissSuccessNotification,
} from "../../../state/highlights/studentRequests/actions";
import { useSelector, useDispatch } from "react-redux";
import { getActionedStudentRequests } from "../../../state/highlights/studentRequests/selectors";
import { actionedRequest } from "../../../state/highlights/studentRequests/types";
import DropdownSuccessItem from "./DropdownSuccessItem";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";

const DROPDOWN_ITEM_DATA_ID = "dropdown-item";

interface props extends studentRequest {
  dataId: string;
}

const DropdownItem: React.FC<props> = (props) => {
  const {
    studentFirstName,
    studentLastName,
    url,
    category,
    id,
    dataId,
    comment,
  } = props;
  const dispatch = useDispatch();
  const handleApprove = () => dispatch(approveStudentRequest(id));
  const handleReject = () => dispatch(rejectStudentRequest(id));
  const handleDismiss = () => dispatch(dismissSuccessNotification(id));
  const actionedRequests = useSelector(getActionedStudentRequests);
  const requestData = actionedRequests.find(
    (request: actionedRequest) => request.id === id
  );
  const { isLoading, isError, isLoaded, approved } = requestData || {};

  const isApprovalLoading = isLoading && approved;
  const isApprovalSuccess = isLoaded && approved && !isError;
  const isRejectionLoading = isLoading && !approved;
  const isRejectionSuccess = isLoaded && !approved && !isError;

  const studentFullName = `${studentFirstName} ${studentLastName}`;

  if (isApprovalSuccess || isRejectionSuccess) {
    return (
      <DropdownSuccessItem
        dataId={`${dataId}-${DROPDOWN_ITEM_DATA_ID}`}
        isApproved={approved}
        handleDismiss={handleDismiss}
        studentFullName={studentFullName}
      />
    );
  }

  return (
    <li
      tabIndex={0}
      aria-label={`${studentFullName}'s request`}
      className={styles.item}
    >
      <div className={styles.name}>
        <span>{studentFullName}</span>
      </div>
      {comment && (
        <div className={styles.infoBlock}>
          <span
            tabIndex={0}
            className={styles.comment}
            aria-label={`comment: ${comment}`}
          >
            {comment}
          </span>
        </div>
      )}
      <div className={styles.infoBlock}>
        <div className={styles.webTitle}>Website</div>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.webLink}
          aria-label={`${url} opens in new tab`}
          data-test-id={`${dataId}-${DROPDOWN_ITEM_DATA_ID}-url`}
        >
          <span className={styles.urlText}>{url}</span>
          <HapReactIcon
            alt="external-link"
            className={styles.icon}
            svg="arrow-external-link"
            aria-label="opens new window"
            width={16}
            height={16}
          />
        </a>
        <div className={styles.category}>
          Category:{" "}
          <span className={styles.value}>{category || "Not specified"}</span>
        </div>
      </div>
      {isError && (
        <div className={styles.alertContainer}>
          <Alert type={ALERT_TYPES.FAILURE} animated={false}>
            <span>
              Sorry, there was a problem completing this task. Please try again.
            </span>
          </Alert>
        </div>
      )}
      <div className={styles.buttonGrp}>
        <Button
          icon="check"
          size={BUTTON_SIZES.REGULAR}
          type={BUTTON_TYPES.PRIMARY}
          data-test-id={`${dataId}-${DROPDOWN_ITEM_DATA_ID}-approve-button`}
          label="Approve"
          className={styles.approve}
          onClick={handleApprove}
          isLoading={isApprovalLoading}
          isDisabled={isRejectionLoading}
        />
        <Button
          className={styles.decline}
          icon="cross"
          size={BUTTON_SIZES.REGULAR}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id={`${dataId}-${DROPDOWN_ITEM_DATA_ID}-decline-button`}
          label="Decline"
          onClick={handleReject}
          isLoading={isRejectionLoading}
          isDisabled={isApprovalLoading}
        />
      </div>
    </li>
  );
};

export default DropdownItem;
