import { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  getUserGroupsViewType,
  getUserGroups,
} from "../state/shared/userGroups/selectors";
import {
  userGroupSelected,
  userGroupsShowManagementPanel,
} from "../state/shared/userGroups/actions";
import { dashboardUserGroupSelected } from "../state/dashboard/class/actions";
import {
  USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE,
  USER_GROUP_PREFS_TYPE,
} from "../state/shared/userGroups/types";
import {
  getClassDashboardUserGroups,
  getClassDashboardUserGroupsPreference,
} from "../state/dashboard/class/selectors";
import {
  addUserGroupAction,
  saveUserGroupsPrefsAction,
  updateUserGroupAction,
  deleteUserGroupAction,
  addParticipartToGroupAction,
  updateStudentUserGroupsAction,
  removeParticipartFromGroupAction,
} from "../state/shared/userGroups/actions";

export const PAGE_TYPES = {
  DASHBOARD: "DASHBOARD",
  HIGHLIGHTS: "HIGHLIGHTS",
};

type UseGroupsProps = {
  pageType?: typeof PAGE_TYPES.DASHBOARD | typeof PAGE_TYPES.HIGHLIGHTS;
  selectedView?: string;
};

export type UserGroup = {
  name: string;
  URN: string;
  color?: string;
  tab: string;
  checked: boolean;
  participants: string[];
};

type GroupUpdate = {
  EmailAddresses: string[];
};

interface Props {
  data: {
    userGroups: UserGroup[];
    userGroupsViewType: string;
    isGroupFilterOpened: boolean;
    dashboardUserGroups: UserGroup[];
  };
  operations: {
    addUserGroup: (classId: string) => void;
    selectAllGroups: (checkState: boolean) => void;
    groupSelected: (groupURN: string, checkState: boolean) => void;
    setIsGroupFilterOpened: (isGroupFilterOpened: boolean) => void;
    saveUserGroupsPrefs: (classId: string, option: string) => void;
    deleteUserGroup: (classId: string, userGroupUrn: string) => void;
    updateStudentUserGroups: (
      classId: string,
      groupUpdate: GroupUpdate
    ) => void;
    editGroupsPanel: (
      contentType: typeof USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE
    ) => void;
    addUserToGroup: (
      classId: string,
      userGroupUrn: string,
      groupUpdate: GroupUpdate
    ) => void;
    updateUserGroup: (
      classId: string,
      userGroupUrn: string,
      groupUpdate: GroupUpdate
    ) => void;
    removeUserFromGroup: (
      classId: string,
      userGroupUrn: string,
      groupUpdate: GroupUpdate
    ) => void;
  };
}

const useGroups = ({ pageType, selectedView }: UseGroupsProps): Props => {
  const dispatch = useDispatch();
  const highlightsUserGroups = useSelector(getUserGroups);
  const rawDashboardUserGroups = useSelector(getClassDashboardUserGroups);
  const [isGroupFilterOpened, setIsGroupFilterOpened] = useState(false);
  const dashboardViewType = useSelector(getClassDashboardUserGroupsPreference);
  const highlightsViewType = useSelector(getUserGroupsViewType);

  const isDashboard = pageType === PAGE_TYPES.DASHBOARD;

  const userGroupsViewType = isDashboard
    ? dashboardViewType
    : highlightsViewType;

  // We are only able to discern what view a usergroup belongs to by disceting a URN string, if the BE updates how this is handled then we can update the belongsToView function to be more robust.

  const belongsToView = useCallback(
    (urn: string) => {
      if (userGroupsViewType !== USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB) {
        return (
          urn.split(":").length === 5 ||
          (urn.startsWith("---") && urn.split(":").length === 2)
        );
      }
      const parts = urn.split(":");
      const embeddedView = parts[parts.length - 2].toLowerCase();
      // Handle groups that were matched in old dashboard with the URN parts value of 's_1'
      if (embeddedView === "s_1" && selectedView?.toLowerCase() === "sharing") {
        return true;
      }

      return selectedView && embeddedView.includes(selectedView.toLowerCase());
    },
    [userGroupsViewType, selectedView]
  );

  const filterDashboardGroupsByView = useCallback(
    (groups: UserGroup[]) => {
      if (!groups) {
        return [];
      }
      return groups.filter((g: UserGroup) => {
        return belongsToView(g.URN);
      });
    },
    [belongsToView]
  );

  const dashboardUserGroups = useMemo(() => {
    return filterDashboardGroupsByView(rawDashboardUserGroups);
  }, [filterDashboardGroupsByView, rawDashboardUserGroups]);

  const userGroups = isDashboard ? dashboardUserGroups : highlightsUserGroups;

  const userGroupSelectedHandler = (groups: UserGroup[]) =>
    isDashboard
      ? dispatch(dashboardUserGroupSelected(groups))
      : dispatch(userGroupSelected(groups));

  const userGroupsShowManagementPanelHandler = (payload: {
    contentType: typeof USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE;
  }) => {
    dispatch(userGroupsShowManagementPanel(payload));
  };

  const editGroupsPanel = (
    contentType: typeof USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE
  ) => {
    userGroupsShowManagementPanelHandler({ contentType });
    setIsGroupFilterOpened(false);
  };

  const groupSelected = (groupURN: string, checkState: boolean) => {
    const clonedUserGroups = _.cloneDeep(userGroups);
    clonedUserGroups.forEach((ug: UserGroup) => {
      if (ug.URN === groupURN) {
        ug.checked = checkState;
      }
    });
    userGroupSelectedHandler(clonedUserGroups);
  };

  const selectAllGroups = (checkState: boolean) => {
    const clonedUserGroups = _.cloneDeep(userGroups);
    let selectedItemsCount = 0;

    clonedUserGroups.forEach((ug: UserGroup) => {
      if (ug.checked) {
        selectedItemsCount++;
      }
    });

    let newCheckState = false;
    if (
      selectedItemsCount === clonedUserGroups.length ||
      selectedItemsCount === 0
    ) {
      newCheckState = checkState;
    }

    clonedUserGroups.forEach((ug: UserGroup) => {
      ug.checked = newCheckState;
    });

    userGroupSelectedHandler(clonedUserGroups);
  };

  const saveUserGroupsPrefs = (classId: string, option: string) =>
    dispatch(saveUserGroupsPrefsAction(classId, option, isDashboard));

  const updateUserGroup = (
    classId: string,
    userGroupUrn: string,
    groupUpdate: GroupUpdate
  ) =>
    dispatch(
      updateUserGroupAction(classId, userGroupUrn, groupUpdate, isDashboard)
    );

  const addUserGroup = (classId: string) =>
    dispatch(addUserGroupAction(classId, isDashboard));

  const deleteUserGroup = (classId: string, userGroupUrn: string) =>
    dispatch(deleteUserGroupAction(classId, userGroupUrn, isDashboard));

  const addUserToGroup = (
    classId: string,
    userGroupUrn: string,
    groupUpdate: GroupUpdate
  ) =>
    dispatch(
      addParticipartToGroupAction(
        classId,
        userGroupUrn,
        groupUpdate,
        isDashboard
      )
    );

  const updateStudentUserGroups = (classId: string, groupUpdate: GroupUpdate) =>
    dispatch(updateStudentUserGroupsAction(classId, groupUpdate, isDashboard));

  const removeUserFromGroup = (
    classId: string,
    userGroupUrn: string,
    groupUpdate: GroupUpdate
  ) =>
    dispatch(
      removeParticipartFromGroupAction(
        classId,
        userGroupUrn,
        groupUpdate,
        isDashboard
      )
    );

  useEffect(() => {
    if (
      isDashboard &&
      userGroupsViewType === USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB
    ) {
      const uncheckedUserGroups = rawDashboardUserGroups?.map((group) => {
        return {
          ...group,
          checked: false,
        };
      });

      userGroupSelectedHandler(uncheckedUserGroups);
    }
  }, [selectedView, userGroupsViewType, isDashboard]);

  return {
    data: {
      userGroups,
      userGroupsViewType,
      dashboardUserGroups,
      isGroupFilterOpened,
    },
    operations: {
      removeUserFromGroup,
      updateStudentUserGroups,
      addUserToGroup,
      deleteUserGroup,
      addUserGroup,
      updateUserGroup,
      saveUserGroupsPrefs,
      groupSelected,
      editGroupsPanel,
      selectAllGroups,
      setIsGroupFilterOpened,
    },
  };
};

export default useGroups;
