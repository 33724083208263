import actionTypes from "../../actionTypes";

const INIT_STATE = {
  isOpen: false,
  studentId: null,
  studentEmail: null,
  studentDisplayName: null,
  activeTabUrl: null,
  activeTabIcon: null,
  snapshot: null,
};

const viewScreenshotReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_SHOW: {
      const {
        studentId,
        studentEmail,
        studentDisplayName,
        activeTabUrl,
        activeTabIcon,
      } = action.payload;

      return {
        isOpen: true,
        studentId,
        studentEmail,
        studentDisplayName,
        activeTabUrl,
        activeTabIcon,
        snapshot: null,
      };
    }

    case actionTypes.HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_HIDE: {
      return INIT_STATE;
    }

    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_SNAPSHOT: {
      const { studentId, snapshot } = action.payload;

      if (state.isOpen && state.studentId === studentId) {
        return {
          ...state,
          snapshot,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default viewScreenshotReducer;
