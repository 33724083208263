import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import styles from "./UploadForm.module.scss";
import { getFilters } from "../../../../state/shared/selectors";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";
import { MULTI_SELECT_ITEM_TYPE } from "@hapara/ui/src/atomic/DropdownMultiSelect/DropdownMultiSelect";
import MultiSelectPills from "@hapara/ui/src/atomic/MultiSelectPills/MultiSelectPills";

const mapForMultiSelect = (items) =>
  _.map(items, (item) => ({ id: item.Key, name: item.Value }));

const UploadFormTags = ({
  dataTestIdPrefix,
  selectedGrades,
  selectedSubjects,
  selectedStandards,
  selectedResourceTypes,
  onSelectedGradesChange,
  onSelectedSubjectsChange,
  onSelectedResourceTypesChange,
  onSelectedStandardsChange,
  grades,
  subjects,
  standards,
  resourceTypes,
}) => {
  return (
    <>
      <div className={styles.fieldBlock}>
        <div className={styles.fieldLabel}>
          <FormattedMessage defaultMessage="Grades" id="bRxa7J" />
        </div>
        <MultiSelectPills
          items={mapForMultiSelect(grades)}
          selectedItems={selectedGrades}
          onSelectedItemsChange={(newSelectedItems) =>
            onSelectedGradesChange(newSelectedItems)
          }
          fieldsetLabel="Grades"
          triggerLabel="Select grades"
          listLabel="Selected grades"
          dataTestIdPrefix={`${dataTestIdPrefix}-Grades`}
        />
      </div>
      <div className={styles.fieldBlock}>
        <div className={styles.fieldLabel}>
          <FormattedMessage defaultMessage="Subjects" id="ay+crl" />
        </div>
        <MultiSelectPills
          items={mapForMultiSelect(subjects)}
          selectedItems={selectedSubjects}
          onSelectedItemsChange={(newSelectedItems) =>
            onSelectedSubjectsChange(newSelectedItems)
          }
          fieldsetLabel="Subjects"
          triggerLabel="Select subjects"
          listLabel="Selected subjects"
          dataTestIdPrefix={`${dataTestIdPrefix}-Subjects`}
        />
      </div>
      <div className={styles.fieldBlock}>
        <div className={styles.fieldLabel}>
          <FormattedMessage defaultMessage="Standards/outcomes" id="DeXIwi" />
        </div>
        <MultiSelectPills
          items={mapForMultiSelect(standards)}
          selectedItems={selectedStandards}
          onSelectedItemsChange={(newSelectedItems) =>
            onSelectedStandardsChange(newSelectedItems)
          }
          fieldsetLabel="Standards"
          triggerLabel="Select standards"
          listLabel="Selected standards"
          dataTestIdPrefix={`${dataTestIdPrefix}-Standards`}
        />
      </div>
      <div className={styles.fieldBlock}>
        <div className={styles.fieldLabel}>
          <FormattedMessage defaultMessage="Resource type" id="WHleoJ" />
        </div>
        <MultiSelectPills
          items={mapForMultiSelect(resourceTypes)}
          selectedItems={selectedResourceTypes}
          onSelectedItemsChange={(newSelectedItems) =>
            onSelectedResourceTypesChange(newSelectedItems)
          }
          fieldsetLabel="Resource type"
          triggerLabel="Select resource types"
          listLabel="Selected resource types"
          dataTestIdPrefix={`${dataTestIdPrefix}-ResourceType`}
          dropdownSlideUp={true}
        />
      </div>
    </>
  );
};

UploadFormTags.propTypes = {
  dataTestIdPrefix: PropTypes.string.isRequired,
  selectedGrades: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  selectedSubjects: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  selectedStandards: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  selectedResourceTypes: PropTypes.arrayOf(MULTI_SELECT_ITEM_TYPE),
  onSelectedGradesChange: PropTypes.func.isRequired,
  onSelectedSubjectsChange: PropTypes.func.isRequired,
  onSelectedStandardsChange: PropTypes.func.isRequired,
  onSelectedResourceTypesChange: PropTypes.func.isRequired,
  // connected
  grades: PropTypes.arrayOf(filterItemType),
  subjects: PropTypes.arrayOf(filterItemType),
  standards: PropTypes.arrayOf(filterItemType),
  resourceTypes: PropTypes.arrayOf(filterItemType),
};

export default connect((state) => ({
  grades: getFilters(SHARED_FILTER_TYPES.GRADES.value)(state),
  subjects: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
  standards: getFilters(SHARED_FILTER_TYPES.STANDARDS.value)(state),
  resourceTypes: getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value)(state),
}))(UploadFormTags);
