import React, { useState } from "react";
import { Input } from "../Input";
import { PasswordInputVisibility } from "./PasswordInputVisibility";

export type PasswordInputProps = {
  id?: string;
  value: string;
  onChangeText: (text: string) => void;
  className?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  dataTestId?: string;
};

export const PasswordInput = React.forwardRef<null, PasswordInputProps>(
  (
    {
      id,
      value,
      isDisabled,
      className,
      hasError,
      maxLength,
      dataTestId,
      placeholder,
      onChangeText,
      ...rest
    },
    ref
  ) => {
    const [isMasked, setMasked] = useState(true);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
      onChangeText(e.target.value);

    const handleVisibilityClick = () => setMasked(!isMasked);

    return (
      <Input
        ref={ref}
        id={id}
        className={className}
        isErrorMode={hasError}
        maxLength={maxLength}
        value={value}
        isMasked={isMasked}
        dataTestId={dataTestId}
        onChange={handleChange}
        disabled={isDisabled}
        placeholder={placeholder}
        rightAddon={
          !isDisabled ? (
            <PasswordInputVisibility
              isMasked={isMasked}
              dataTestIdPrefix={dataTestId}
              onClick={handleVisibilityClick}
            />
          ) : null
        }
        {...rest}
      />
    );
  }
);
