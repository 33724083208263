import { DialogModal } from "@hapara/ui/src/atomic/DialogModal";
import { ClassInfoNetworkErrorAlert } from "../ClassInfoNetworkErrorAlert/index";
import { Button } from "@hapara/ui/src/atomic/Button";
import styles from "./ClassInfoDialogModal.module.scss";
import classnames from "classnames";
import { useIntl } from "react-intl";

type ClassInfoDialogModalProps = {
  className?: string;
  isOpen: boolean;
  title: string;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  onConfirm?: () => void;
  confirmLabel: string;
  isDisabled?: boolean;
  isConfirmDangerous?: boolean;
  // TODO: We should probably pass in an `externalConfimLink` prop instead
  isExternalConfirm?: boolean;
  isProcessing?: boolean;
  children?: React.ReactNode;
  hasNetworkError?: boolean;
};

export const ClassInfoDialogModal: React.FC<ClassInfoDialogModalProps> = ({
  isOpen,
  title,
  confirmLabel,
  isExternalConfirm,
  dataTestIdPrefix,
  onRequestClose,
  onConfirm,
  children,
  className,
  isConfirmDangerous,
  isDisabled,
  isProcessing,
  hasNetworkError,
}) => {
  const intl = useIntl();
  const handleRequestClose = () => {
    if (!isDisabled && !isProcessing) {
      onRequestClose();
    }
  };
  return (
    <DialogModal
      dataTestIdPrefix={dataTestIdPrefix}
      isOpen={isOpen}
      title={title}
      actions={
        <>
          <Button
            dataTestId={`${dataTestIdPrefix}-button-cancel`}
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onAction={handleRequestClose}
            type="secondary"
            isDisabled={isProcessing || isDisabled}
          />
          <Button
            dataTestId={`${dataTestIdPrefix}-button-confirm`}
            label={confirmLabel}
            onAction={onConfirm}
            rightIcon={isExternalConfirm ? "arrow-external-link" : undefined}
            type={isConfirmDangerous ? "danger" : undefined}
            isLoading={isProcessing}
            isDisabled={isProcessing || isDisabled}
          />
        </>
      }
      onRequestClose={handleRequestClose}
      className={classnames(
        className,
        styles.dialogModal,
        styles.classInfoModal
      )}
    >
      <div className={styles.text}>{children}</div>
      {hasNetworkError && (
        <ClassInfoNetworkErrorAlert className={styles.alertBox} />
      )}
    </DialogModal>
  );
};
