import React from "react";
import { Button } from "../Button";

type CloseButtonProps = {
  onClick(): void;
  dataTestIdPrefix?: string;
  style?: React.CSSProperties;
  className?: string;
  ariaLabel?: string;
  /** `ariaControls` links the button to the ID of an element it controls, such
   * as a modal or drawer. This association aids accessibility by helping users
   * understand the button's function. */
  ariaControls?: string;
  isDisabled?: boolean;
};

export const CloseButton = ({
  onClick,
  dataTestIdPrefix,
  style,
  className,
  ariaLabel = "Close",
  ariaControls,
  isDisabled,
}: CloseButtonProps) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-CloseButton`
    : null;
  return (
    <Button
      isDisabled={isDisabled}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      onClick={onClick}
      data-test-id={dataTestId}
      icon="cross"
      type="tertiary"
      style={style}
      className={className}
      size="small"
    />
  );
};
