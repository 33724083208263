import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import HapReactIcon from "../../atomic/icon/hapReactIcon";
import { useIntl } from "react-intl";
import { intlGroupColourNames } from "./intlGroupColourNames";

import styles from "./colourPicker.module.scss";

/**
 *
 * @param selectedColour
 * @param onSelectColour
 * @returns {*}
 * @constructor
 */
const ColourPicker = ({
  selectedColour,
  handleSelectColour,
  //handleOffClick,
  direction = "vertical",
  flat = false,
  ws = false,
}) => {
  const intl = useIntl();
  const data = getDataOfColours(selectedColour, ws);

  return (
    <div className={styles.root}>
      <div
        role="radiogroup"
        className={classnames(styles.container, styles[direction], [
          { [styles.hasShadow]: !flat },
        ])}
      >
        {data.map((item) => {
          return (
            <button
              role="radio"
              id={item.colourCode}
              key={item.colourCode}
              className={classnames([styles.tile], [styles[item.colourCode]])}
              onClick={() => handleSelectColour(item.colourCode)}
              aria-checked={item.colourCode === selectedColour}
              aria-label={intl.formatMessage(
                intlGroupColourNames[item.colourCode]
              )}
            >
              <div
                className={classnames([styles.check], {
                  [styles.visible]: item.selected,
                })}
              >
                <HapReactIcon svg="check" width="16" height="16" />
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

/**
 * creating the data to show coloured tiles
 * @param selectedColour to show check on selected colour
 */
function getDataOfColours(selectedColour, ws) {
  let i,
    res = [];

  let total = ws ? 48 : 43;
  let prefix = ws ? "wsgroupColour_" : "groupColour_";

  for (i = 0; i < total; i++) {
    res.push({
      colourCode: prefix + i,
      selected: selectedColour === prefix + i,
    });
  }
  return res;
}

ColourPicker.propTypes = {
  selectedColour: PropTypes.string,
  handleSelectColour: PropTypes.func,
  handleOffClick: PropTypes.func,
  direction: PropTypes.string,
  flat: PropTypes.bool,
};

export default ColourPicker;
