import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./GradingDocumentContainer.module.scss";

import { getUrl } from "../../../../../utils";

import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

import {
  getGradingActivity,
  getGradingArtifact,
  getAssigneesByActivity,
  getAssigneeDataLoading,
  isAssigneeDataInitiallyLoading,
  getAssigneeItemTitle,
  cardNotValid,
  artefactNotValid,
  isActivityEligible,
  getCardDataLoading,
} from "../../../../../state/workspace/grading/selectors";

import {
  MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE,
  MYWORKSPACES_GRADING_DOCUMENT_TYPE,
} from "../../../../../state/workspace/grading/types";

import GradingWorkLoading from "../GradingWorkLoading/GradingWorkLoading";
import GradingDataError from "../GradingDataError/GradingDataError";
import GradingWorkDone from "../GradingWorkDone/GradingWorkDone";

export const GradingDocumentContainer = ({
  activity,
  artifact = {},
  assigneeDataLoading,
  cardDataLoading,
  isAssigneeDataInitiallyLoading,
  assigneeItemTitle,
  assignees = [],
  cardNotValid,
  artefactNotValid,
  isActivityEligible,
}) => {
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (artifact) {
      const { url, type } = getUrl(artifact);
      setType(type);
      setUrl(url);
    } else {
      setType(null);
      setUrl(null);
    }
  }, [artifact]);

  if (cardDataLoading || assigneeDataLoading)
    return (
      <GradingWorkLoading
        next={!isAssigneeDataInitiallyLoading}
        assigneeItemTitle={assigneeItemTitle}
      />
    );

  if (cardNotValid || !isActivityEligible || artefactNotValid) {
    return <GradingDataError />;
  }

  if (assignees.length === 0) return <GradingWorkDone activity={activity} />;

  return (
    <div className={styles.root} data-test-id="Ws-Grading-DocumentContainer">
      {type === MYWORKSPACES_GRADING_DOCUMENT_TYPE.EMBEDED && (
        <iframe
          src={url}
          title={`${assigneeItemTitle} work`}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      )}
      {type === MYWORKSPACES_GRADING_DOCUMENT_TYPE.EXTERNAL && (
        <div className={styles.linkContainer}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            data-test-id="ws-Grading-ExternalUrl"
          >
            <div className={styles.iconContainer}>
              <HapReactIcon svg="link" width={24} height={24} />
            </div>
            <span>{url}</span>
            <div className={styles.arrowContainer}>
              <HapReactIcon svg="arrow-external-link" width={16} height={16} />
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

GradingDocumentContainer.propTypes = {
  activity: PropTypes.string,
  artifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  assigneeDataLoading: PropTypes.bool.isRequired,
  cardDataLoading: PropTypes.bool.isRequired,
  isAssigneeDataInitiallyLoading: PropTypes.bool.isRequired,
  assigneeItemTitle: PropTypes.string.isRequired,
  assignees: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE,
  cardNotValid: PropTypes.bool.isRequired,
  artefactNotValid: PropTypes.bool.isRequired,
  isActivityEligible: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    activity: getGradingActivity(state),
    artifact: getGradingArtifact(state),
    assigneeDataLoading: getAssigneeDataLoading(state),
    cardDataLoading: getCardDataLoading(state),
    isAssigneeDataInitiallyLoading: isAssigneeDataInitiallyLoading(state),
    assigneeItemTitle: getAssigneeItemTitle()(state),
    assignees: getAssigneesByActivity(state),
    cardNotValid: cardNotValid(state),
    artefactNotValid: artefactNotValid(state),
    isActivityEligible: isActivityEligible(state),
  }),
  null
)(GradingDocumentContainer);
