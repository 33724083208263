import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { DatePicker as AntDatePicker } from "antd";
import styles from "./DatePicker.module.scss";
import "./DateTimePicker.css";

const DatePicker = ({ className, ...rest }) => {
  return (
    <AntDatePicker
      size="large"
      className={classnames(styles.root, className)}
      {...rest}
    />
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
};

export default DatePicker;
