import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./groupItemSelect.module.scss";
import { generateId } from "../utils";
import Checkbox from "../../atomic/Checkbox/Checkbox";

/**
 *
 * @param group
 * @param selected
 * @param onChange
 * @returns {*}
 * @constructor
 */
const GroupItemSelect = ({ group, selected, onChange }) => {
  const id = generateId();

  return (
    <div className={styles.root}>
      <Checkbox
        checked={selected}
        id={id}
        semi={false}
        onChange={(e) => onChange({ type: e ? "add" : "remove", group })}
        label={
          <div className={styles.label} data-test-id="GroupItem-Select-Group">
            <div
              className={classnames(styles.colorCode, [
                styles[group.colourCode],
              ])}
            />
            <div>
              <div className={styles.title}>{group.name}</div>
            </div>
          </div>
        }
      />
    </div>
  );
};

GroupItemSelect.propTypes = {
  group: PropTypes.object,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

export default GroupItemSelect;
