import { createContext } from "react";

export type TableConfig<T = string | number> = {
  onSelectAll?(): void;
  onSelect?(id: T): void;
  selected?: T[];
  dataTestIdPrefix?: string;
  rowCount?: number;
};

export const TableContext = createContext<TableConfig | undefined>(undefined);

export const TableProvider = TableContext.Provider;
