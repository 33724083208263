import { FormattedMessage } from "react-intl";
import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import styles from "./PLWorkspaceTile.module.scss";
import emptyThumbnail from "@hapara/assets/src/images/ws-thumbnail-default.svg";
import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import { THUMBNAIL_SIZE } from "../../../../../state/workspace/myworkspaces/thumbnails/types";
import { formatDate } from "../../../../../utils";
import { getFFByName } from "../../../../../state/app/selectors";

export const PLWorkspaceTile = ({ item }) => {
  const isWSSubPathRedirectEnabledFF = useSelector(
    getFFByName("HAP-11276-ws-subpath")
  );
  if (!item) {
    return (
      <div className={styles.root}>
        <div className={styles.section1}>
          <img src={emptyThumbnail} width="100%" height="100%" alt="" />
        </div>
        <div className={styles.section2}>
          <div className={styles.s2_content}>
            <div className={styles.s2_titleLoading} />
            <div className={styles.s2_descriptionLoading} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <article className={styles.root}>
      <div className={styles.section1}>
        <img
          src={
            item.BoardThumbnail
              ? `${item.BoardThumbnail}&w=${THUMBNAIL_SIZE * 2}&h=${
                  THUMBNAIL_SIZE * 2
                }`
              : emptyThumbnail
          }
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div className={styles.section2}>
        <div className={styles.s2_content}>
          <a
            className={classnames(styles.s2_titleLink, {
              [styles.s2_titleOnly]: !item.Description,
            })}
            href={
              isWSSubPathRedirectEnabledFF
                ? `${process.env.REACT_APP_HSUITE_BASE_URL}/ws/#/workspace/w/${item.Id}`
                : `${process.env.REACT_APP_OLD_WORKSPACE_URL}/#/workspace/w/${item.Id}`
            }
            data-test-id="ws-ProfessionalLearning-NonTeacher-SelectWorkspacePanel"
          >
            <h2 className={styles.s2_titleHeading}>{item.Title}</h2>
          </a>
          {item.Description && (
            <div className={styles.s2_description}>{item.Description}</div>
          )}
        </div>
      </div>
      <div className={styles.section3}>
        <div className={styles.modifiedDate}>
          <span>
            <FormattedMessage defaultMessage="Modified:" id="DFY8tS" />
          </span>
          <span className={styles.modifiedDateFormatted}>
            {formatDate(item.ModifiedDatetime)}
          </span>
        </div>
      </div>
    </article>
  );
};

PLWorkspaceTile.propTypes = {
  item: wsItemType,
};

export default PLWorkspaceTile;
