import React from "react";
import { Heading } from "../../../../atomic/Heading";
import styles from "./ChatSubHeading.module.scss";

type ChatSubHeadingProps = {
  children: React.ReactNode;
};

export const ChatSubHeading = ({ children }: ChatSubHeadingProps) => {
  return (
    <Heading
      level={2}
      styledAs="h4"
      removeBottomMargin
      removeTopMargin
      className={styles.container}
    >
      {children}
    </Heading>
  );
};
