import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./SimpleChart.module.scss";

export const SIMPLECHART_COLOURS = {
  NAVY: "navy",
  LIGHT_BLUE: "light_blue",
  BLUE: "blue",
  ORANGE: "orange",
  GRAY: "gray",
};

const defaultCoulours = [
  SIMPLECHART_COLOURS.BLUE,
  SIMPLECHART_COLOURS.ORANGE,
  SIMPLECHART_COLOURS.GRAY,
  SIMPLECHART_COLOURS.NAVY,
  SIMPLECHART_COLOURS.LIGHT_BLUE,
];

const calculatePercents = (points, totalPoints) => {
  const percents = Math.round(((points * 1.0) / totalPoints) * 100);
  return percents;
};

const SimpleChart = ({
  values,
  colours = defaultCoulours,
  showTotal = false,
  totalValue = 0,
}) => {
  const all = values.reduce((acc, v) => acc + v, 0);
  const percents = values.map((v) => calculatePercents(v, all)); //transform numbers to percents for our flex
  const painted = percents.reduce((acc, v) => acc + v, 0); //in case it was rounded to 99, we need to repaint the last %
  return (
    <div className={styles.root}>
      {showTotal && <div className={styles.total}>{totalValue}</div>}
      {percents.map((percent, index) => {
        return (
          <div
            key={index}
            style={{ width: `${percent}%` }}
            className={classnames(styles.value, styles[colours[index]])}
          ></div>
        );
      })}
      {painted < 100 && (
        <div
          style={{ width: `${100 - painted}%` }}
          className={classnames(
            styles.value,
            styles[colours[values.length - 1]]
          )}
        ></div>
      )}
    </div>
  );
};

SimpleChart.propTypes = {
  colours: PropTypes.arrayOf(PropTypes.oneOf(_.values(SIMPLECHART_COLOURS))),
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  showTotal: PropTypes.bool,
  totalValue: PropTypes.number,
};

export default SimpleChart;
