import _ from "lodash";
import { createSelector } from "reselect";

const getShareLinksModalInfo = (state) =>
  _.get(state, "highlights.shareLinks.modalData");

export const getShareLinksModalStatus = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "isOpen", false)
);

export const getShareLinksModalErrorStatus = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "isError", false)
);

export const getShareLinksModalSuccessStatus = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "isSent", false)
);

export const getShareLinksSelectedStudent = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "selectedStudent")
);

export const getShareLinksSessionData = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "sessionData", {})
);

export const isShareLinksDuplicateSession = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "isDuplicateSession")
);

export const getIsGClassroomShare = createSelector(
  getShareLinksModalInfo,
  (sm) => _.get(sm, "isGClassroomShare", false)
);
