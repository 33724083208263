import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";
import styles from "./StudentTileRefresh.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { sendMessageToBusWebsocket } from "../../../../state/highlights/busWebsocket/actions";
import {
  WEBSOCKET_MESSAGE_TYPES,
  WEBSOCKET_STATUS_TYPES,
  CONNECTION_SUCCESS_DELAY,
} from "../../../../state/highlights/HighlightsWebsocket";
import { getBusWebsocketStatus } from "../../../../state/highlights/busWebsocket/selectors";
import { sendEvent } from "../../../../state/app/actions";
import { cleanStudentInstanceData } from "../../../../state/highlights/students/actions";
import { useIntl } from "react-intl";

const StudentTileRefresh = ({
  isOnline,
  studentId,
  isBeingRefreshed,
  busWebsocketStatus,
  onBeingRefreshedChange,
  sendMessageToBusWebsocket,
  sendEvent,
  cleanStudentInstanceData,
}) => {
  const intl = useIntl();
  const delayRef = useRef();

  // clean up delay, if any, on unmount
  useEffect(() => {
    return () => {
      if (delayRef.current) {
        window.clearTimeout(delayRef.current);
      }
    };
  }, []);

  if (
    isOnline ||
    isBeingRefreshed ||
    busWebsocketStatus !== WEBSOCKET_STATUS_TYPES.ONLINE
  ) {
    return null;
  }

  const handleRefresh = () => {
    onBeingRefreshedChange(true);
    cleanStudentInstanceData(studentId);
    sendMessageToBusWebsocket({
      students: [studentId],
      type: WEBSOCKET_MESSAGE_TYPES.TEACHER_ONLINE,
    });
    sendEvent({ name: "studentRefresh", channel: studentId });
    delayRef.current = _.delay(
      () => onBeingRefreshedChange(false),
      CONNECTION_SUCCESS_DELAY
    );
  };

  return (
    <div className={styles.root}>
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "Reconnect to student device",
          id: "6gIbyG",
        })}
        theme="hsuite"
      >
        <Button
          icon="arrow-sync"
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id="hl-StudentTile-Refresh-Trigger"
          aria-label={intl.formatMessage({
            defaultMessage: "Reconnect to student device",
            id: "6gIbyG",
          })}
          className={styles.triggerButton}
          onClick={handleRefresh}
        />
      </Tippy>
    </div>
  );
};

StudentTileRefresh.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  studentId: PropTypes.string.isRequired,
  isBeingRefreshed: PropTypes.bool.isRequired,
  busWebsocketStatus: PropTypes.oneOf(_.values(WEBSOCKET_STATUS_TYPES)),
  onBeingRefreshedChange: PropTypes.func.isRequired,
  sendMessageToBusWebsocket: PropTypes.func.isRequired,
  sendEvent: PropTypes.func.isRequired,
  cleanStudentInstanceData: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    busWebsocketStatus: getBusWebsocketStatus(state),
  }),
  (dispatch) => ({
    sendMessageToBusWebsocket: (message) =>
      dispatch(sendMessageToBusWebsocket(message)),
    sendEvent: (message) => dispatch(sendEvent(message)),
    cleanStudentInstanceData: (studentId) =>
      dispatch(cleanStudentInstanceData({ studentId })),
  })
)(StudentTileRefresh);
