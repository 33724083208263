import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import {
  UPREF_NAME_FIRST_LAST,
  UPREF_NAME_LAST_FIRST,
} from "../../../state/user/types";
import styles from "./Settings.module.scss";
import {
  getUserConfigurableSchools,
  getUserPrefs,
} from "../../../state/user/selectors";
import { saveUserPreferences } from "../../../state/user/actions";
import Dropdown, {
  DROPDOWN_CONTENT_COLOUR_TYPES,
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { useIntl } from "react-intl";

export const Settings = ({ prefs, saveUserPref, configurableSchools }) => {
  const intl = useIntl();
  const nameFormatPref = prefs.find(
    (userPref) => userPref.key === "NameFormat"
  );
  const defaultNameFormat = UPREF_NAME_FIRST_LAST;
  const [modalOpen, setModalOpen] = React.useState(false); // do not change to useState as test fails
  const [nameFormat, setNameFormat] = React.useState("");
  const [saveEnabled, setSaveEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const cogButtonRef = useRef();

  const handleCloseModal = () => {
    setModalOpen(false);
    if (cogButtonRef.current) {
      _.delay(() => cogButtonRef.current.focus(), 100);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await saveUserPref(nameFormat);
      handleCloseModal();
    } catch (error) {
      // TODO: setEnabled to true
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (nameFormat) => {
    setNameFormat(nameFormat);
    setSaveEnabled(
      nameFormatPref
        ? nameFormatPref.value !== nameFormat
        : nameFormat !== defaultNameFormat
    );
  };

  useEffect(() => {
    setNameFormat(nameFormatPref ? nameFormatPref.value : defaultNameFormat);
    return () => {
      if (!modalOpen) {
        setSaveEnabled(false);
        setIsLoading(false);
        setNameFormat("");
      }
    };
  }, [modalOpen, nameFormatPref, defaultNameFormat]);

  const getHacLinksList = () => {
    if (!_.isEmpty(configurableSchools)) {
      const uriList = [];
      _.forEach(configurableSchools, (configurableSchool) => {
        const { domain, sites } = configurableSchool;
        if (_.isEmpty(sites)) {
          uriList.push(domain);
        } else {
          _.forEach(sites, (site) => {
            uriList.push(`${domain}/${site}`);
          });
        }
      });
      return uriList;
    }
    return [];
  };

  return (
    <div className={styles.root}>
      <Dropdown
        classNameContent={styles.dropdownContent}
        className={styles.dropdown}
        colorType={DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY}
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={({ className, ...rest }) => (
          <Tippy
            content={intl.formatMessage({
              defaultMessage: "Settings",
              id: "D3idYv",
            })}
            maxWidth={150}
            placement="bottom"
            theme="hsuite"
          >
            <button
              type="button"
              className={classnames(className, styles.dropdownTrigger)}
              aria-label={intl.formatMessage({
                defaultMessage: "Open settings",
                id: "iubKfx",
              })}
              ref={cogButtonRef}
              data-test-id="Ws-UserSettings-Button-Settings"
              {...rest}
            >
              <HapReactIcon svg="cog" width={16} height={16} alt="Settings" />
            </button>
          </Tippy>
        )}
        itemComponentList={_.concat(
          [
            ({ onClick, className, ...rest }) => (
              <button
                type="button"
                data-test-id="Ws-SettingsMenuItem-Button-OpenSettings"
                onClick={() => {
                  setModalOpen(true);
                  onClick();
                }}
                className={classnames(className, styles.dropdownContentItem)}
                {...rest}
              >
                {intl.formatMessage({
                  defaultMessage: "Settings",
                  id: "D3idYv",
                })}
              </button>
            ),
          ],
          _.map(
            getHacLinksList(),
            (uri) =>
              ({ onClick, className, ...rest }) =>
                (
                  <a
                    data-test-id="Ws-SettingsMenuItem-HacLink-OpenHacPage"
                    href={`${process.env.REACT_APP_HACR_BASE_URL}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={onClick}
                    className={classnames(
                      className,
                      styles.dropdownContentItem
                    )}
                    {...rest}
                  >
                    {uri}
                  </a>
                )
          )
        )}
      />

      <ModalDeprecated
        isOpen={modalOpen}
        onClose={() => handleCloseModal()}
        contentLabel="User Settings Modal"
        title={intl.formatMessage({ defaultMessage: "Settings", id: "D3idYv" })}
        classNameHeader={styles.header}
      >
        <div className={styles.block}>
          <div className={styles.title} id="studentNameFormat">
            {intl.formatMessage({
              defaultMessage: "Student Name Format",
              id: "qY5iGl",
            })}
          </div>
          <div className={styles.description}>
            {intl.formatMessage({
              defaultMessage:
                "Choose how your students' names appear in student panels.",
              id: "UE/lam",
            })}
          </div>
          <div
            className={styles.options}
            role="radiogroup"
            aria-labelledby="studentNameFormat"
          >
            <div
              data-test-id="Ws-NameFormat-Option-FirstLast"
              className={styles.option}
            >
              <Radio
                value={UPREF_NAME_FIRST_LAST}
                name="studentNameFormatRadio"
                checked={nameFormat === UPREF_NAME_FIRST_LAST}
                onChange={() => onChange(UPREF_NAME_FIRST_LAST)}
                label={intl.formatMessage({
                  defaultMessage: "First Name Last Name",
                  id: "n5cKVW",
                })}
                dataTestIdPrefix="Ws-NameFormat-RadioButton-FirstLast"
                className={styles.optionRadio}
              />
            </div>
            <div
              data-test-id="Ws-NameFormat-Option-LastFirst"
              className={styles.option}
            >
              <Radio
                value={UPREF_NAME_LAST_FIRST}
                name="studentNameFormatRadio"
                checked={nameFormat === UPREF_NAME_LAST_FIRST}
                onChange={() => onChange(UPREF_NAME_LAST_FIRST)}
                label={intl.formatMessage({
                  defaultMessage: "Last Name, First Name",
                  id: "8Flq31",
                })}
                dataTestIdPrefix="Ws-NameFormat-RadioButton-LastFirst"
                className={styles.optionRadio}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            dataTestId="Ws-NameFormat-Button-CancelSettings"
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            size={BUTTON_SIZES.LARGE}
            onAction={() => handleCloseModal()}
            type={BUTTON_TYPES.SECONDARY}
          />
          <Button
            dataTestId="Ws-NameFormat-Button-SaveSettings"
            label={intl.formatMessage({
              defaultMessage: "Save changes",
              id: "X0ha1a",
            })}
            size={BUTTON_SIZES.LARGE}
            onAction={() => onSave()}
            isDisabled={!saveEnabled}
            isLoading={isLoading}
          />
        </div>
      </ModalDeprecated>
    </div>
  );
};

Settings.propTypes = {
  prefs: PropTypes.array.isRequired,
  saveUserPref: PropTypes.func.isRequired,
  configurableSchools: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({
    prefs: getUserPrefs(state),
    configurableSchools: getUserConfigurableSchools(state),
  }),
  (dispatch) => ({
    saveUserPref: (nameFormatPref) =>
      dispatch(saveUserPreferences(nameFormatPref)),
  })
)(Settings);
