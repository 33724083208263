import React from "react";
import { createIcon } from "./utils/createIcon";

export const GridIcon = createIcon(
  <path
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M6 2H2.7c-.4 0-.7.3-.7.7V6c0 .4.3.7.7.7H6c.4 0 .7-.3.7-.7V2.7c0-.4-.3-.7-.7-.7ZM13.3 2H10c-.4 0-.7.3-.7.7V6c0 .4.3.7.7.7h3.3c.4 0 .7-.3.7-.7V2.7c0-.4-.3-.7-.7-.7ZM13.3 9.3H10c-.4 0-.7.3-.7.7v3.3c0 .4.3.7.7.7h3.3c.4 0 .7-.3.7-.7V10c0-.4-.3-.7-.7-.7ZM6 9.3H2.7c-.4 0-.7.3-.7.7v3.3c0 .4.3.7.7.7H6c.4 0 .7-.3.7-.7V10c0-.4-.3-.7-.7-.7Z"
  />
);
