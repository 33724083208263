import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./SortFilterBlock.module.scss";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { RESOURCE_FORMAT } from "../../../../state/resources/types";
import { pushQueryToHistory } from "../../../../state/router/navigation";
import { isInsideDiscover } from "../../../../state/library/discover/selectors";
import { isInsideMyResources } from "../../../../state/library/myResources/selectors";
import { isInsideLibrary } from "../../../../state/library/library/selectors";
import {
  hasWSAccess,
  hasPrivateLibraryAccess,
  hasResourcesAccess,
} from "../../../../state/user/selectors";
import { getLeftPanelIsOpen } from "../../../../state/shared/selectors";
import { toggleLeftSidePanel } from "../../../../state/shared/actions";
import SortOrderFilter from "../SortOrderFilter/SortOrderFilter";

const filterOptionsList = [
  {
    id: 1,
    name: "All formats",
    type: RESOURCE_FORMAT.ALL,
    dataTestId: "AllFormats",
  },
  {
    id: 2,
    name: "Workspaces",
    type: RESOURCE_FORMAT.WORKSPACE,
    dataTestId: "Workspaces",
  },
  {
    id: 3,
    name: "Resources",
    type: RESOURCE_FORMAT.RESOURCE,
    dataTestId: "Resources",
  },
];

export const SortFilterBlock = ({
  dataTestIdPrefix,
  selectedFormat,
  selectedSortOrder,
  isDiscoverPage,
  isLibraryPage,
  isMyResourcesPage,
  hasWSAccess,
  hasPrivateLibraryAccess,
  hasResourcesAccess,
  isLeftPanelOpen,
  toggleLeftPanel,
}) => {
  const triggerRef = useRef();
  const selectedOption =
    _.find(filterOptionsList, { type: selectedFormat }) ||
    _.find(filterOptionsList, { type: RESOURCE_FORMAT.ALL }); // default value

  // on Discover page you must have access to DB + (WS or Pvt Lib)
  // on Our Library page you must have access to DB + Pvt Lib
  // on My Resources page - no format filter
  const showFormatFilter =
    hasResourcesAccess &&
    ((isLibraryPage && hasPrivateLibraryAccess) ||
      (isDiscoverPage && (hasWSAccess || hasPrivateLibraryAccess))) &&
    !isMyResourcesPage;

  const showMetadataFilter = isMyResourcesPage; // show this filter only on My Resources page for now
  const showSortOrderFilter = !isDiscoverPage;

  return (
    <div className={styles.root} data-test-id={dataTestIdPrefix}>
      <div className={styles.leftGroup}>
        {showMetadataFilter && (
          <MemorisedFocusButton
            icon="filter-lines"
            type={BUTTON_TYPES.OUTLINED}
            size={BUTTON_SIZES.REGULAR}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onClick={() => toggleLeftPanel(!isLeftPanelOpen)}
            label="Filter"
            dataTestId={`${dataTestIdPrefix}-ShowFilterPanel-Button`}
            aria-label="Filter results"
            className={styles.filterButton}
          />
        )}
        {showFormatFilter && (
          <Dropdown
            widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
            triggerComponent={(props) => (
              <DropdownTrigger
                widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
                className={classnames(styles.dropdownTrigger, {
                  [styles.notDefault]:
                    selectedOption.type !== RESOURCE_FORMAT.ALL,
                })}
                data-test-id={`${dataTestIdPrefix}Dropdown-Trigger`}
                label={selectedOption.name}
                ref={triggerRef}
                {...props}
              />
            )}
            itemComponentList={filterOptionsList.map(
              (item) =>
                ({ onClick, className, ...rest }) => {
                  const isSelected = item.type === selectedFormat;
                  return (
                    <button
                      onClick={() => {
                        pushQueryToHistory({
                          format: item.type,
                        });
                        onClick();
                        _.delay(() => triggerRef.current.focus(), 100);
                      }}
                      className={classnames(className, styles.dropdownItem, {
                        [styles.selected]: isSelected,
                      })}
                      data-test-id={`${dataTestIdPrefix}Dropdown-Button-${item.dataTestId}`}
                      aria-label={`${item.name}${
                        isSelected ? ", selected" : ""
                      }`}
                      {...rest}
                    >
                      {item.name}
                    </button>
                  );
                }
            )}
          />
        )}
      </div>
      <div className={styles.rightGroup}>
        {showSortOrderFilter && (
          <SortOrderFilter
            dataTestIdPrefix={`${dataTestIdPrefix}-SortOrderFilter`}
            selectedSortOrder={selectedSortOrder}
          />
        )}
      </div>
    </div>
  );
};

SortFilterBlock.propTypes = {
  isDiscoverPage: PropTypes.bool.isRequired,
  isLibraryPage: PropTypes.bool.isRequired,
  isMyResourcesPage: PropTypes.bool.isRequired,
  dataTestIdPrefix: PropTypes.string,
  selectedFormat: PropTypes.string,
  selectedSortOrder: PropTypes.string,
  hasWSAccess: PropTypes.bool.isRequired,
  hasPrivateLibraryAccess: PropTypes.bool.isRequired,
  hasResourcesAccess: PropTypes.bool.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isDiscoverPage: isInsideDiscover(state),
    isLibraryPage: isInsideLibrary(state),
    isMyResourcesPage: isInsideMyResources(state),
    hasWSAccess: hasWSAccess(state),
    hasPrivateLibraryAccess: hasPrivateLibraryAccess(state),
    hasResourcesAccess: hasResourcesAccess(state),
    isLeftPanelOpen: getLeftPanelIsOpen(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLeftSidePanel(isOpen)),
  })
)(SortFilterBlock);
