export class ApiError {
  message: string;
  responseStatus?: number;
  apiPath?: string;

  constructor(message: string, responseStatus?: number, apiPath?: string) {
    this.message = message;
    this.responseStatus = responseStatus;
    this.apiPath = apiPath;
  }
}

export type EdublogsData = {
  dash_id: string;
  tz: string;
  email: string;
  emails: string[];
  type: string;
  searchfilter: string;
  searchfiltertype: string;
  total: string;
};
