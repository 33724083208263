import actionTypes from "../../actionTypes";
import _ from "lodash";

const INIT_STATE = {
  isSessionListLoading: false,
  isScheduledSessionsLoading: false,
  isReloadScheduledSessionsRequest: false,
  isSessionListError: false,
  sessionList: [],
  recentTabs: [],
  modalData: {
    isOpen: false,
    modalMode: null,
    sessionType: null,
    selectedStudent: null,
    isDuplicateSession: false,
  },
  templates: [],
  scheduledSessions: [],
};

const sessionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_PENDING: {
      return {
        ...state,
        isSessionListLoading: true,
        isSessionListError: false,
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_ERROR: {
      return {
        ...state,
        isSessionListLoading: false,
        isSessionListError: true,
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_SUCCESS: {
      return {
        ...state,
        isSessionListLoading: false,
        isSessionListError: false,
        sessionList: _.get(action, "payload", []),
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_RECENT_TABS_HISTORY_LOAD_SUCCESS: {
      return {
        ...state,
        recentTabs: _.get(action, "payload", []),
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_MODAL_SHOW: {
      const newModalData = {
        isOpen: true,
      };
      const modalMode = _.get(action, "payload.modalMode");
      const sessionType = _.get(action, "payload.sessionType");
      const selectedStudent = _.get(action, "payload.selectedStudent");
      const sessionData = _.get(action, "payload.sessionData");
      const isDuplicateSession = _.get(action, "payload.isDuplicateSession");
      const selectedTemplate = _.get(action, "payload.selectedTemplate");
      const isScheduled = _.get(action, "payload.isScheduled");
      const isHideBackButton = _.get(action, "payload.isHideBackButton");
      if (modalMode) {
        newModalData.modalMode = modalMode;
      }
      if (selectedStudent) {
        newModalData.selectedStudent = selectedStudent;
      }
      if (sessionType) {
        newModalData.sessionType = sessionType;
      }
      if (sessionData) {
        newModalData.sessionData = sessionData;
      }
      if (isDuplicateSession) {
        newModalData.isDuplicateSession = isDuplicateSession;
      }
      if (selectedTemplate) {
        newModalData.selectedTemplate = selectedTemplate;
      }
      if (isScheduled) {
        newModalData.isScheduled = isScheduled;
      }
      // TODO: This can be removed once we clean up feature flags as it only relates to the previous iteration of creating templates - GBE-01-class-view-page
      if (isHideBackButton) {
        newModalData.isHideBackButton = isHideBackButton;
      }
      return {
        ...state,
        modalData: {
          ...newModalData,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_MODAL_HIDE: {
      return {
        ...state,
        modalData: {
          isOpen: false,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_SUCCESS: {
      return {
        ...state,
        templates: _.get(action, "payload", []),
      };
    }

    case actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_PENDING: {
      return {
        ...state,
        isScheduledSessionsLoading: true,
      };
    }

    case actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_SUCCESS: {
      return {
        ...state,
        isScheduledSessionsLoading: false,
        isReloadScheduledSessionsRequest: false,
        scheduledSessions: _.get(action, "payload", []),
      };
    }

    case actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        isScheduledSessionsLoading: false,
        isReloadScheduledSessionsRequest: false,
        scheduledSessions: [
          ...state.scheduledSessions.slice(
            0,
            state.scheduledSessions.length - 1
          ), // last item will be duplicated as BE always sends "pageSize + 1", so we can show/hide "Show more" button
          ..._.get(action, "payload", []),
        ],
      };
    }

    case actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_RELOAD_REQUEST: {
      return {
        ...state,
        isReloadScheduledSessionsRequest: true,
      };
    }

    case actionTypes.HIGHLIGHTS_SCHEDULED_SESSIONS_UPDATE_SESSION_NAME_LOCALLY: {
      const { sessionId, sessionName } = _.get(action, "payload");
      const scheduledSessionsNew = _.map(state.scheduledSessions, (s) => {
        if (s.ID === sessionId) {
          return {
            ...s,
            SessionName: sessionName,
          };
        } else {
          return s;
        }
      });

      return {
        ...state,
        scheduledSessions: scheduledSessionsNew,
      };
    }

    default:
      return state;
  }
};

export default sessionsReducer;
