import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw Error("useAuth hook must be used within an AuthProvider");
  }

  return context;
};
