import React from "react";
import { connect } from "react-redux";
import styles from "./HeaderContent.module.scss";
import _ from "lodash";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import classnames from "classnames";
import { showGuideBrowsingModal } from "../../../../state/highlights/sessions/actions";
import { showSendMessageModal } from "../../../../state/highlights/sendMessage/actions";
import { showShareLinksModal } from "../../../../state/highlights/shareLinks/actions";
import { showTemplatesPanel } from "../../../../state/highlights/guideBrowsingTemplates/actions";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { SEND_MESSAGE_RECIPIENT_TYPES } from "../../../../state/highlights/sendMessage/types";
import SmartshareButtons from "@hapara/ui/src/components/partial/Smartshare/SmartshareButtons/SmartshareButtons";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import { getClassList } from "../../../../state/library/config/selectors";
import { classItemType } from "../../../../state/library/config/types";
import { FormattedMessage, useIntl } from "react-intl";

import { TargetIcon } from "@hapara/ui/src/icons/TargetIcon";
import { AnnounceIcon } from "@hapara/ui/src/icons/AnnounceIcon";
import { LinkOutlineIcon } from "@hapara/ui/src/icons/LinkOutlineIcon";

import { useSelector } from "react-redux";
import { getFFByName } from "../../../../state/app/selectors";

const HeaderContent = ({
  showGuideBrowsingModal,
  showSendMessageModal,
  showShareLinksModal,
  isNewSmartshare,
  showTemplatePanel,
  nameFormat,
  classId,
  classList,
}) => {
  const intl = useIntl();

  const hasNavBarButtonUpdateFlag = useSelector(
    getFFByName("GBE-02-nav-bar-button-update")
  );
  const buttonType = hasNavBarButtonUpdateFlag
    ? BUTTON_TYPES.SECONDARY
    : BUTTON_TYPES.PRIMARY;

  const getGoogleClassroomID = () => {
    //Checks if current class is a GClassroom class and returns the GC ID where present
    const gcClass = _.find(classList, {
      ID: classId,
      DataSource: "GoogleClassroom",
    });
    return gcClass ? gcClass.ExternalID : null;
  };

  const googleClassroomID = getGoogleClassroomID();
  return (
    <div className={styles.root}>
      {isNewSmartshare && (
        <SmartshareButtons
          onSharesUpdate={() => {}}
          nameFormat={nameFormat}
          classUrn={classId}
          apiUrl={process.env.REACT_APP_API_HAPARA_URL}
        />
      )}
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        className={classnames(styles.dropdown, styles.announce)}
        triggerComponent={(props) => (
          <MemorisedFocusButton
            label={
              hasNavBarButtonUpdateFlag
                ? intl.formatMessage({
                    defaultMessage: "Announce",
                    id: "uXce8A",
                  })
                : intl.formatMessage({
                    defaultMessage: "Message",
                    id: "T7Ry38",
                  })
            }
            type={buttonType}
            size={BUTTON_SIZES.XSMALL}
            icon={hasNavBarButtonUpdateFlag ? null : "message-text-fill"}
            iconComponent={hasNavBarButtonUpdateFlag ? <AnnounceIcon /> : null}
            rightIcon="arrow-carvet-down"
            data-test-id="hl-SendMessageModal-Button-Trigger"
            {...props}
          />
        )}
        itemComponentList={[
          ({ onClick, className, ...rest }) => (
            <button
              className={classnames(className, styles.dropdownContentItem)}
              data-test-id="hl-SendMessageModal-Button-Trigger-Class"
              onClick={() => {
                onClick();
                showSendMessageModal({
                  recipientType: SEND_MESSAGE_RECIPIENT_TYPES.CLASS,
                });
              }}
              {...rest}
            >
              <FormattedMessage defaultMessage="Class" id="nraEHM" />
            </button>
          ),
          ({ onClick, className, ...rest }) => (
            <button
              className={classnames(className, styles.dropdownContentItem)}
              data-test-id="hl-SendMessageModal-Button-Trigger-Students"
              onClick={() => {
                onClick();
                showSendMessageModal({
                  recipientType: SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS,
                });
              }}
              {...rest}
            >
              <FormattedMessage defaultMessage="Students" id="uhwf+D" />
            </button>
          ),
          ({ onClick, className, ...rest }) => (
            <button
              className={classnames(className, styles.dropdownContentItem)}
              data-test-id="hl-SendMessageModal-Button-Trigger-Groups"
              onClick={() => {
                onClick();
                showSendMessageModal({
                  recipientType: SEND_MESSAGE_RECIPIENT_TYPES.GROUPS,
                });
              }}
              {...rest}
            >
              <FormattedMessage defaultMessage="Groups" id="hzmswI" />
            </button>
          ),
        ]}
      />
      {googleClassroomID ? (
        <Dropdown
          positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
          className={styles.dropdown}
          triggerComponent={(props) => (
            <MemorisedFocusButton
              label={intl.formatMessage({
                defaultMessage: "Share links",
                id: "86oph/",
              })}
              type={buttonType}
              size={BUTTON_SIZES.XSMALL}
              iconComponent={
                hasNavBarButtonUpdateFlag ? <LinkOutlineIcon /> : null
              }
              rightIcon="arrow-carvet-down"
              data-test-id="hl-ShareLinksModal-Button-Trigger"
              {...props}
            />
          )}
          itemComponentList={[
            ({ onClick, className, ...rest }) => (
              <button
                className={classnames(className, styles.dropdownContentItem)}
                data-test-id="hl-ShareLinksModal-Button-Share-Links"
                onClick={showShareLinksModal}
                {...rest}
              >
                <FormattedMessage defaultMessage="Custom URL" id="wEUEyc" />
              </button>
            ),
            ({ onClick, className, ...rest }) => (
              <button
                className={classnames(className, styles.dropdownContentItem)}
                data-test-id="hl-ShareLinksModal-Button-Share-GC-Links"
                onClick={() =>
                  showShareLinksModal({
                    isGClassroomShare: true,
                    GClassroomID: googleClassroomID,
                  })
                }
                {...rest}
              >
                <FormattedMessage
                  defaultMessage="Google Classroom"
                  id="ahFZVA"
                />
              </button>
            ),
          ]}
        />
      ) : (
        <Button
          label={intl.formatMessage({
            defaultMessage: "Share links",
            id: "86oph/",
          })}
          type={buttonType}
          size={BUTTON_SIZES.XSMALL}
          iconComponent={hasNavBarButtonUpdateFlag ? <LinkOutlineIcon /> : null}
          onClick={showShareLinksModal}
          data-test-id="hl-ShareLinksModal-Button-Trigger"
        />
      )}
      <Button
        label={intl.formatMessage({
          defaultMessage: "Guide browsing",
          id: "JLAnOx",
        })}
        type={BUTTON_TYPES.PRIMARY}
        size={BUTTON_SIZES.XSMALL}
        iconComponent={hasNavBarButtonUpdateFlag ? <TargetIcon /> : null}
        onClick={showTemplatePanel}
        data-test-id="hl-GuideBrowsingModal-Button-Trigger"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    isNewSmartshare: getFFByName("HAP-8772-smartshare-rewrite")(state),
    nameFormat: getUserNameFormat(state),
    classId: getClassId(state),
    classList: getClassList(state),
  }),
  (dispatch) => ({
    showGuideBrowsingModal: () => dispatch(showGuideBrowsingModal()),
    showSendMessageModal: ({ recipientType }) =>
      dispatch(showSendMessageModal({ recipientType })),
    showShareLinksModal: (payload) => dispatch(showShareLinksModal(payload)),
    showTemplatePanel: () =>
      dispatch(showTemplatesPanel({ isScheduledPanel: false })),
  })
)(HeaderContent);
