import { useEffect, useState } from "react";
import { useInjectScript } from "../useInjectScript";
import { useGoogleAuth } from "../useGoogleAuth";
import { MimeType } from "../../atomic/FileInput/fileExtensions";

export type GoogleDriveFile = {
  iconUrl: string;
  id: string;
  mimeType: string;
  name: string;
};

const GooglePickerViewIdMap = {
  all: "DOCS",
  "docs-images": "DOCS_IMAGES",
  "docs-images-and-videos": "DOCS_IMAGES_AND_VIDEOS",
  "docs-videos": "DOCS_VIDEOS",
  documents: "DOCUMENTS",
  drawings: "DRAWINGS",
  folders: "FOLDERS",
  forms: "FORMS",
  pdfs: "PDFS",
  presentations: "PRESENTATIONS",
  spreadsheets: "SPREADSHEETS",
  recent: "RECENTLY_PICKED",
} as const;

const GooglePickerDocsViewModeMap = {
  grid: "GRID",
  list: "LIST",
} as const;

export type GooglePickerTab =
  | {
      type: "upload";
    }
  | {
      type: keyof typeof GooglePickerViewIdMap;
      label?: string;
      mode?: keyof typeof GooglePickerDocsViewModeMap;
      ownedByMe?: boolean;
      includeFolders?: boolean;
      enableSharedDrives?: boolean;
    };

type GooglePickerOptions = {
  tabs?: GooglePickerTab[];
  mimeTypes?: MimeType[];
  onSelect?(file: GoogleDriveFile): void;
  onCancel?(): void;
};

export const useGooglePicker = (options?: GooglePickerOptions) => {
  const [isPickerReady, setPickerReady] = useState(false);
  const [isReady, setReady] = useState(false);
  const { isLoaded: isGoogleApiLoaded } = useInjectScript(
    "https://apis.google.com/js/api.js"
  );

  useEffect(() => {
    if (isGoogleApiLoaded) {
      gapi.load("picker", () => setPickerReady(true));
    }
  }, [isGoogleApiLoaded]);

  const { requestAccessToken } = useGoogleAuth([
    "https://www.googleapis.com/auth/drive.file",
  ]);

  useEffect(() => {
    setReady(isGoogleApiLoaded && isPickerReady);
  }, [isGoogleApiLoaded, isPickerReady]);

  const launchPicker = async () => {
    const accessToken = await requestAccessToken();
    if (accessToken) {
      const picker = new google.picker.PickerBuilder();

      if (!options?.tabs) {
        picker.addView(
          new google.picker.DocsView(
            google.picker.ViewId[GooglePickerViewIdMap.all]
          ).setMode(google.picker.DocsViewMode.LIST)
        );
      }
      /* https://developers.google.com/drive/picker/reference#docs-view */
      options?.tabs?.forEach((tab) => {
        if (tab.type === "upload") {
          picker.addView(new google.picker.DocsUploadView());
        } else {
          const docsView = new google.picker.DocsView(
            google.picker.ViewId[GooglePickerViewIdMap[tab.type]]
          );

          if (tab.mode) {
            docsView.setMode(
              google.picker.DocsViewMode[GooglePickerDocsViewModeMap[tab.mode]]
            );
          }

          if (options.mimeTypes) {
            docsView.setMimeTypes(options.mimeTypes.join(","));
          }

          if (tab.ownedByMe !== undefined) {
            docsView.setOwnedByMe(tab.ownedByMe);
          }

          if (tab.includeFolders) {
            docsView.setIncludeFolders(tab.includeFolders);
          }

          if (tab.enableSharedDrives !== undefined) {
            docsView.setEnableDrives(tab.enableSharedDrives);
          }

          if (tab.label) {
            // @ts-ignore incorrectly does not exist in types definition
            docsView.setLabel(tab.label);
          }

          picker.addView(docsView);
        }
      });

      picker
        .setOAuthToken(accessToken)
        .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .setCallback((data) => {
          if (data.action === "picked") {
            const document = data.docs[0];
            options?.onSelect?.({
              id: document.id,
              name: document.name,
              iconUrl: document.iconUrl,
              mimeType: document.mimeType,
            });
          }
          if (data.action === "cancel") {
            options?.onCancel?.();
          }
        })
        .build()
        .setVisible(true);
    }
  };

  return { launchPicker, isReady };
};
