import { history } from "../../store";
import { APP_PAGES } from "../../router/types";

export const navigateBack = () => {
  history.goBack();
};

export const navigateToCreateWsForStudents = () => {
  history.push({
    pathname: APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.path,
  });
};
