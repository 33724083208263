import React, { Profiler } from "react";
import { connect } from "react-redux";
import styles from "./BrowserTabsPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import { MAIN_CONTENT_ID } from "../../../../consts";
import StudentTiles from "../../../partial/Highlights/StudentTiles/StudentTiles";
import PropTypes from "prop-types";
import {
  getIsConfigLoaded,
  getIsConfigError,
  getIsOutsideSchoolHours,
} from "../../../../state/highlights/config/selectors";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import { NoStudentsInfoPanel } from "../../../partial/Highlights/NoStudentsInfoPanel";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import { STUDENT_TILE_CONTENT_TYPE } from "../../../../state/highlights/config/types";
import OutsideSchoolHoursInfoPanel from "../../../partial/Highlights/OutsideSchoolHoursInfoPanel/OutsideSchoolHoursInfoPanel";
import { getFFByName } from "../../../../state/app/selectors";
import Toast, { TOAST_TYPES } from "@hapara/ui/src/deprecated/Toast/Toast";
import { getBusWebsocketConnectionError } from "../../../../state/highlights/busWebsocket/selectors";
import { updateBusWebsocketConnectionError } from "../../../../state/highlights/busWebsocket/actions";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { sendReactRenderStats } from "../../../../state/highlights/students/actions";
import usePerformanceStatsSendHook from "../../../../hooks/usePerformanceStatsSendHook";
import { FormattedMessage } from "react-intl";

const VIEW_TYPES = {
  NO_STUDENTS: "NO_STUDENTS",
  OUTSIDE_SCHOOL_HOURS: "OUTSIDE_SCHOOL_HOURS",
  STUDENT_TILES: "STUDENT_TILES",
};

const BrowserTabsPage = ({
  isConfigLoaded,
  isConfigError,
  isOutsideSchoolHours,
  students,
  isBusWebsocketConnectionError,
  updateWebsocketConnectionError,
  isMonitorPerformanceFF,
  sendReactRenderStats,
}) => {
  const viewType = isOutsideSchoolHours
    ? VIEW_TYPES.OUTSIDE_SCHOOL_HOURS
    : !students.length
    ? VIEW_TYPES.NO_STUDENTS
    : VIEW_TYPES.STUDENT_TILES;

  const onStudentTilesRender = usePerformanceStatsSendHook({
    componentName: "StudentTilesTabs",
    sendReactRenderStats,
    isMonitorPerformanceFF,
  });

  return (
    <Page
      header={<Header />}
      footer={
        <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
      }
      title={APP_PAGES.HIGHLIGHTS_ACTIVITY.title}
      isPageLoaded={isConfigLoaded}
      isPageLoadingError={isConfigError}
    >
      <ModalsAndPanels />

      <main
        className={styles.root}
        data-test-id="Hs-Pages-HighlightsBrowserTabsPage"
        id={MAIN_CONTENT_ID}
        tabIndex="-1"
      >
        <h1 className={styles.title}>
          <FormattedMessage defaultMessage="Browser Tabs" id="neZYFg" />
        </h1>

        {viewType === VIEW_TYPES.OUTSIDE_SCHOOL_HOURS && (
          <OutsideSchoolHoursInfoPanel />
        )}

        {viewType === VIEW_TYPES.NO_STUDENTS && <NoStudentsInfoPanel />}

        {isMonitorPerformanceFF && viewType === VIEW_TYPES.STUDENT_TILES && (
          <Profiler id="StudentTilesTabs" onRender={onStudentTilesRender}>
            <StudentTiles tileContentType={STUDENT_TILE_CONTENT_TYPE.TABS} />
          </Profiler>
        )}

        {!isMonitorPerformanceFF && viewType === VIEW_TYPES.STUDENT_TILES && (
          <StudentTiles tileContentType={STUDENT_TILE_CONTENT_TYPE.TABS} />
        )}
      </main>

      <div className={styles.toast} aria-live="polite" aria-atomic="true">
        {isBusWebsocketConnectionError && (
          <Toast
            icon="fill-circle-exclamation"
            type={TOAST_TYPES.ERROR}
            onClose={() => updateWebsocketConnectionError(false)}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div>
              <FormattedMessage
                defaultMessage="<strong>Trouble connecting.</strong> Try reloading the page."
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
                id="xwMBM4"
              />
            </div>
          </Toast>
        )}
      </div>
    </Page>
  );
};

BrowserTabsPage.propTypes = {
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  isBusWebsocketConnectionError: PropTypes.bool.isRequired,
  updateWebsocketConnectionError: PropTypes.func.isRequired,
  isMonitorPerformanceFF: PropTypes.bool.isRequired,
  sendReactRenderStats: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    students: getStudentConfigsList(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
    isBusWebsocketConnectionError: getBusWebsocketConnectionError(state),
    isMonitorPerformanceFF: getFFByName("HAP-8438-Monitor-React-Performance")(
      state
    ),
  }),
  (dispatch) => ({
    updateWebsocketConnectionError: (isError) =>
      dispatch(updateBusWebsocketConnectionError(isError)),
    sendReactRenderStats: (options) => dispatch(sendReactRenderStats(options)),
  })
)(BrowserTabsPage);
