import PropTypes from "prop-types";

export const APP_PAGES = {
  // General
  NOT_FOUND: {
    title: "Page not found - Hāpara",
  },
  NO_ACCESS: {
    title: "Forbidden - Hāpara",
  },
  LOGIN: {
    path: "/login",
    testPath: "/dfg2ert25hgkj-test-login",
    title: "Hāpara classroom management solution login for teachers",
  },

  // Workspace
  WORKSPACE_BASE: {
    path: "/workspace",
    title: "Workspaces - Hāpara",
  },
  PROFESSIONAL_LEARNING: {
    path: "/workspace/professional-learning",
    title: "Professional Learning - Workspaces - Hāpara",
  },
  WORKSPACE_MY_WORKSPACE: {
    path: "/workspace/myworkspaces",
    title: "My Workspaces - Hāpara",
  },
  /* old pages, left for redirects only */
  WORKSPACE_LIBRARY: {
    path: "/workspace/library",
    title: "Library - Workspaces - Hāpara",
  },
  WORKSPACE_DISCOVER: {
    path: "/workspace/discover",
    title: "Discover - Hāpara",
  },
  WORKSPACE_DISCOVER_SEARCH: {
    path: "/workspace/discover/search",
    title: "Search Results - Discover - Hāpara",
  },
  /* end of old pages */
  WORKSPACE_GRADING: {
    path: "/workspace/grading",
    title: "Grading - Workspace - Hāpara",
  },
  WORKSPACE_CREATE_FOR_STUDENTS: {
    path: "/workspace/create/students",
    title: "Create - Workspace - Hāpara",
  },
  WORKSPACE_CREATE_FOR_PL: {
    path: "/workspace/create/professional-learning",
    title: "Create - Workspace - Hāpara",
  },
  WORKSPACE_BOARDS: {
    path: "/workspace/w",
    title: "Workspace - Hāpara",
  },

  // My Resources
  MY_RESOURCES_BASE: {
    path: "/my-resources",
    title: "My Resources - Hāpara",
  },
  MY_RESOURCES_STARRED: {
    path: "/my-resources/starred",
    title: "My Resources - Hāpara",
  },
  MY_RESOURCES_SEARCH: {
    path: "/my-resources/search",
    title: "Search Results - My Resources - Hāpara",
  },

  // Resource Auth
  RESOURCES_AUTH_BASE: {
    path: "/resource",
    title: "Hāpara",
  },

  // Library
  LIBRARY_BASE: {
    path: "/library",
    title: "Our Library - Hāpara",
  },
  LIBRARY_SEARCH: {
    path: "/library/search",
    title: "Search Results - Our Library - Hāpara",
  },
  LIBRARY_CATEGORIES: {
    path: "/library/category",
    title: "Our Library - Hāpara",
  },
  LIBRARY_UPLOAD: {
    path: "/library/upload",
    title: "Upload - Our Library - Hāpara",
  },
  LIBRARY_UPLOAD_STATUS: {
    path: "/library/upload-status",
    title: "Upload status - Our Library - Hāpara",
  },

  // Discover
  DISCOVER_BASE: {
    path: "/discover",
    title: "Discover - Hāpara",
  },
  DISCOVER_SEARCH: {
    path: "/discover/search",
    title: "Search Results - Discover - Hāpara",
  },

  // Dashboard
  DASHBOARD: {
    path: "/dashboard",
    title: "Hāpara Dashboard",
  },

  // Manage classes
  DASHBOARD_MANAGE_CLASSES: {
    path: "/classes",
    title: "Hāpara - Manage classes",
  },
  DASHBOARD_MANAGE_CLASSES_ALL: {
    path: "/classes/all",
    title: "All classes",
  },
  DASHBOARD_MANAGE_CLASSES_MY: {
    path: "/classes/my",
    title: "My classes",
  },

  // Highlights
  HIGHLIGHTS_BASE: {
    path: "/highlights",
    title: "Highlights - Hāpara",
  },
  HIGHLIGHTS_CLASS_VIEW: {
    path: "/highlights/class-view",
    title: "Class view - Highlights - Hāpara",
    viewGroupKey: "Highlights - Class view",
  },
  HIGHLIGHTS_ACTIVITY: {
    path: "/highlights/activity",
    title: "Browser Tabs - Highlights - Hāpara",
    viewGroupKey: "Highlights - Browser Tabs",
  },
  HIGHLIGHTS_ACTIVITY_VIEWER: {
    path: "/highlights/activity-viewer",
    title: "Activity Viewer - Highlights - Hāpara",
  },
  HIGHLIGHTS_CURRENT_SCREENS: {
    path: "/highlights/current-screens",
    title: "Current screens - Highlights - Hāpara",
    viewGroupKey: "Highlights - Current Screen",
  },
  HIGHLIGHTS_SNAPS: {
    path: "/highlights/snapshots",
    title: "Snaps - Highlights - Hāpara",
  },
  HIGHLIGHTS_SCHEDULE: {
    path: "/highlights/schedule",
    title: "Scheduled Sessions - Highlights - Hāpara",
  },
  HIGHLIGHTS_DRIVE: {
    path: "/highlights/drive",
    title: "Drive - Highlights - Hāpara",
    searchResultsTitle: "All Documents search results - Highlights - Hapara",
  },
  HIGHLIGHTS_DRIVE_OLD: {
    path: "/highlights/all-docs",
    title: "Drive - Highlights - Hāpara",
    searchResultsTitle: "All Documents search results - Highlights - Hapara",
  },
  CLICK_TO_SHARE: {
    path: "/click-to-share",
    title: "Redirecting...",
  },

  //Teacher Dashboard
  DASHBOARD_BASE: {
    path: "/dashboard",
    title: "Dashboard - Hāpara",
  },
  DASHBOARD_FOLDER: {
    path: "/dashboard/folder",
    title: "Documents - Dashboard - Hāpara",
  },
  DASHBOARD_DRIVE: {
    path: "/dashboard/drive",
    title: "Drive - Dashboard - Hāpara",
  },
  DASHBOARD_GMAIL: {
    path: "/dashboard/gmail",
    title: "Gmail - Dashboard - Hāpara",
  },
  DASHBOARD_EDUBLOGS: {
    path: "/dashboard/edublogs",
    title: "Edublogs - Dashboard - Hāpara",
  },
  CLASSINFO: {
    path: "/class-info",
    title: "Class Info - Hāpara",
  },
};

export const locationType = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  key: PropTypes.string,
});

export const routerType = PropTypes.shape({
  location: locationType,
  action: PropTypes.string,
});
