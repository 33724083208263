import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./ResourceSearch.module.scss";
import Search from "@hapara/ui/src/atomic/Search/Search";

import { getSearchQuery } from "../../../../state/shared/selectors";
import { updateURLParams } from "../../../../state/shared/navigation";

export const ResourceSearch = ({
  searchQuery,
  className,
  dataTestIdPrefix,
  placeholder = "Search by keyword...",
}) => {
  const [tempQuery, setTempQuery] = useState(searchQuery);

  useEffect(() => {
    setTempQuery(searchQuery);
  }, [searchQuery, setTempQuery]);

  const handleSearch = (query) => {
    if (!query) return false;
    updateURLParams({
      query,
    });
  };

  return (
    <div className={classnames(styles.root, className)}>
      <Search
        placeholder={placeholder}
        onSearch={handleSearch}
        dataTestIdPrefix={`${dataTestIdPrefix}Search`}
        value={tempQuery}
        onChange={setTempQuery}
      />
    </div>
  );
};

ResourceSearch.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  placeholder: PropTypes.string,
};

export default connect((state) => ({
  searchQuery: getSearchQuery(state),
}))(ResourceSearch);
