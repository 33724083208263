// LIBRARIES
import { useEffect, useRef, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// TYPES/CONSTS
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import {
  DATA_TEST_ID_PREFIX,
  ACTIVE_TAB_NAMES,
  URLParamTypes,
  DrivePageParamTypes,
} from "./types";

// SELECTORS
import {
  getIsConfigLoaded,
  getIsConfigError,
} from "../../../../state/highlights/config/selectors";
import { isUserInitLoaded as isUserInitLoadedSelector } from "../../../../state/app/selectors";
import { getUserLoggingStatus as getUserLoggingStatusSelector } from "../../../../state/user/selectors";
import { getIsConfigLoading as getIsConfigLoadingSelector } from "../../../../state/highlights/config/selectors";
import {
  getIsClassActiveSelector,
  getDashboardSubjectFolders,
  getIsClassDashboardLoaded,
  getIsClassDashboardLoading,
  getIsClassDashboardError,
  getDashboardClassType,
} from "../../../../state/dashboard/class/selectors";

// ACTIONS
import { loadClassConfig } from "../../../../state/highlights/config/actions";
import { loadDashboardClassData } from "../../../../state/dashboard/class/actions";

// COMPONENTS
import Page from "../../../partial/PageContainer/PageContainer";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import DriveMainContent from "../../../partial/Drive/DriveMainContent/DriveMainContent";
import DashboardSubMenu from "../../../partial/Dashboard/DashboardSubMenu/DashboardSubMenu";
import GroupsManagementPanel from "../../../partial/Highlights/GroupsManagementPanel/GroupsManagementPanel"; // TODO: HAP-10656 Update component location

// UTILS | OTHER
import { initJwtHandling } from "../../../../apiCalls/jwtHandler";
import { getJWTRequestBody } from "@hapara/ui/src/components/utils";
import { PAGE_TYPES } from "../../../../hooks/useGroups";
import { decodeURIString } from "@hapara/ui/src/components/utils";
interface DashboardPageProps {
  title: string;
}

const DashboardPage = ({ title }: DashboardPageProps) => {
  const { classId, docType, gmailType, folderType, edublogsType } =
    useParams<URLParamTypes>();

  const isConfigLoaded = useSelector(getIsConfigLoaded);
  const isConfigError = useSelector(getIsConfigError);
  const isUserLoggedIn = useSelector(getUserLoggingStatusSelector);
  const isUserInitLoaded = useSelector(isUserInitLoadedSelector);
  const isConfigLoading = useSelector(getIsConfigLoadingSelector);
  const isClassActive = useSelector(getIsClassActiveSelector);
  const isDashboardLoading = useSelector(getIsClassDashboardLoading);
  const isDashboardLoaded = useSelector(getIsClassDashboardLoaded);
  const isDashboardError = useSelector(getIsClassDashboardError);
  const activeClassFolderList = useSelector(getDashboardSubjectFolders);
  const classDatasource = useSelector(getDashboardClassType);
  const selectedClassIdRef = useRef<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const activeURLParam: DrivePageParamTypes = docType
    ? ["docType", docType]
    : gmailType
    ? ["gmailType", gmailType]
    : folderType
    ? ["folderType", folderType]
    : edublogsType
    ? ["edublogsType", edublogsType]
    : null;

  const dataTestIdPrefix =
    (activeURLParam && DATA_TEST_ID_PREFIX[activeURLParam![0]]) ||
    "dashboard-page";

  function checkURLParamMatchFolderType(
    activeURLParam: DrivePageParamTypes
  ): boolean {
    if (!activeURLParam) {
      return false;
    }

    const decodedParam = decodeURIComponent(activeURLParam[1]);

    const decodedArrayStrings = activeClassFolderList.map((string) =>
      decodeURIComponent(string)
    );

    return decodedArrayStrings.includes(decodedParam);
  }
  /** Users that navigate here from the all classes page will first navigate to /Documents if they are not a teacher of the class
   This handles redirecting them to the first folder in the class
   */
  const navigateToFirstFolder = useCallback(
    (firstFolder: string) => {
      const currentPath = window.location.pathname;
      const firstFolderPath = `/dashboard/folder/${classId}/${firstFolder}`;
      const doesURLParamMatchFolderType =
        checkURLParamMatchFolderType(activeURLParam);

      const shouldNavigateToFirstFolder =
        folderType &&
        !doesURLParamMatchFolderType &&
        currentPath !== firstFolderPath;

      if (shouldNavigateToFirstFolder) {
        return history.push(firstFolderPath);
      }
    },
    [activeClassFolderList]
  );

  useEffect(() => {
    if (isDashboardLoaded) {
      activeClassFolderList && navigateToFirstFolder(activeClassFolderList[0]);
    }
  }, [isDashboardLoading, folderType]);

  useEffect(() => {
    if (
      isUserLoggedIn &&
      isUserInitLoaded &&
      classId &&
      !isConfigLoading &&
      selectedClassIdRef.current !== classId &&
      !isDashboardLoading
    ) {
      initJwtHandling(getJWTRequestBody(classId));
      dispatch(
        loadClassConfig({
          classId: classId,
          shouldOpenWebsocketConnection: false,
        })
      );
      dispatch(loadDashboardClassData(classId));
      selectedClassIdRef.current = classId;
    }
  }, [
    dispatch,
    classId,
    loadClassConfig,
    isConfigLoading,
    isUserLoggedIn,
    isUserInitLoaded,
  ]);

  const activeTab = folderType
    ? decodeURIString(folderType)
    : (activeURLParam && ACTIVE_TAB_NAMES[activeURLParam[0]]) || "tab";

  return (
    <>
      {isConfigLoaded && !isClassActive && isDashboardLoaded ? (
        <NoAccessPage />
      ) : (
        <Page
          header={<Header />}
          footer={
            <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.DASHBOARD} />
          }
          title={title}
          isPageLoaded={isConfigLoaded && isDashboardLoaded}
          isPageLoadingError={isConfigError || isDashboardError}
          dataTestIdPrefix={dataTestIdPrefix}
        >
          <DashboardSubMenu classId={classId} tabName={activeTab} />
          <GroupsManagementPanel
            tabName={activeTab}
            pageType={PAGE_TYPES.DASHBOARD}
          />
          <DriveMainContent
            tabName={activeTab}
            dataTestIdPrefix={dataTestIdPrefix}
            pageType={PAGE_TYPES.DASHBOARD}
            pageParams={activeURLParam}
            activeClassFolderList={activeClassFolderList}
            classDataSource={classDatasource}
          />
        </Page>
      )}
    </>
  );
};

export default DashboardPage;
