import { isValidDate } from "../isValidDate";

export const isWithinOneMonth = (date: string) => {
  if (!isValidDate(date)) return false;

  const selectedDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const oneMonthFromNow = new Date(today);
  oneMonthFromNow.setHours(23, 59, 59, 999);
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

  return selectedDate >= today && selectedDate <= oneMonthFromNow;
};
