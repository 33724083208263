import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styles from "./Checkbox.module.scss";
import HapReactIcon from "../icon/hapReactIcon";
import { THEME_TYPES } from "../consts";
import { VisuallyHidden } from "../VisuallyHidden";

export const Checkbox = ({
  checked = false,
  disabled = false,
  onChange,
  id = null,
  label,
  hideLabel,
  semi = false,
  className,
  dataTestIdPrefix,
  themeType,
  tabIndex,
  hasFocus = false,
  ariaLabel,
}) => {
  const inputRef = useRef();
  const [uniqId] = useState(uuidv4());
  const inputId = id || uniqId;

  useEffect(() => {
    inputRef.current.indeterminate = semi;
  }, [semi, inputRef]);

  const iconName = semi ? "minus-sm" : checked ? "check" : "fallback-icon";

  return (
    <React.Fragment>
      <input
        className={classnames(
          styles.root,
          {
            [styles.indeterminate]: semi,
          },
          styles[themeType],
          className
        )}
        type="checkbox"
        onChange={(e) => {
          onChange(!!e.target.checked);
        }}
        id={inputId}
        checked={checked}
        data-test-id={dataTestIdPrefix ? `${dataTestIdPrefix}-Input` : null}
        disabled={disabled}
        ref={inputRef}
        tabIndex={tabIndex}
        autoFocus={hasFocus}
        aria-label={ariaLabel}
      />
      <label htmlFor={inputId} data-test-id={dataTestIdPrefix}>
        <HapReactIcon
          svg={iconName}
          width={16}
          height={16}
          className={classnames(styles.labelIcon, {
            [styles.labelHidden]: hideLabel,
          })}
        />
        {hideLabel ? (
          <VisuallyHidden>{label}</VisuallyHidden>
        ) : (
          <span>{label}</span>
        )}
      </label>
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  semi: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  themeType: PropTypes.oneOf(_.values(THEME_TYPES)),
  tabIndex: PropTypes.number, //Pass in -1 to prevent tabbing into a nested child checkbox
  hasFocus: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

/** @deprecated use named export, default export will be removed in a future version */
export default Checkbox;
