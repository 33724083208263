// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/raygun4js/index.d.ts
// - We're not installing `raygun4js` as a dependency, since it's relatively
//   large and we're already loading it from the CDN as suggested by Raygun.
// - `@types/raygun4js` is not 100% correct, and we only want to surface
//   `window.rg4js` through these utils.
declare const window: {
  rg4js?: (
    type: "send",

    data: {
      error: Error;
      customData?:
        | object
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | any[];
      tags?: string[];
    }
  ) => void;
} & Window;

type CustomData =
  | ({
      /** WARNING: This overrides the default error message in Raygun */
      message?: string;
    } & Record<string, unknown>)
  | unknown[];

const getError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  if (typeof error === "object" && error !== null) {
    try {
      return new Error(JSON.stringify(error));
    } catch (error) {
      return getError(error);
    }
  }
  return new Error(String(error));
};

export const trackError = (error: unknown, data?: CustomData) => {
  if (window.rg4js) {
    window.rg4js("send", {
      error: getError(error),
      customData: data,
    });
  } else {
    // eslint-disable-next-line no-console
    console.error("Raygun not yet initiated, error not sent.");
  }
  // eslint-disable-next-line no-console
  console.error(error);
};
