import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalOfflineStateHelp.module.scss";
import helpImage from "@hapara/assets/src/images/vector-chromebrowser-extension.svg";
import Button, {
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

import { FormattedMessage, useIntl } from "react-intl";

const ModalOfflineStateHelp = ({ dataTestPrefix, onReportProblem }) => {
  const intl = useIntl();
  return (
    <>
      <div className={styles.modalBody}>
        <p>
          <FormattedMessage
            defaultMessage="A student might appear offline if they are not signed in with their school account on their Chromebook or Chrome browser."
            id="UGfzZe"
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="Ask your student to click on the <span>Hāpara</span> extension to see how they are signed in."
            id="i3Ot+r"
            values={{ span: (chunk) => <span lang="mi">{chunk}</span> }}
          />
        </p>
        <div className={styles.imageContainer}>
          <img
            src={helpImage}
            alt={intl.formatMessage({
              defaultMessage:
                "The Hāpara extension is highlighted on the right side of a Chrome Browser",
              id: "p2GxC/",
            })}
            className={styles.helpImage}
          />
        </div>
        <div>
          <FormattedMessage
            values={{
              a: (chunk) => (
                <a
                  href="https://support.hapara.com/hc/en-us/articles/360057699092"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.helpLink}
                  data-test-id={`${dataTestPrefix}-HelpCenterLink`}
                >
                  {chunk}
                </a>
              ),
            }}
            defaultMessage="Can't see the student extension or want to learn more? <a>Visit our help center</a>"
            id="lK7OiI"
          />
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.container}>
          <div>
            <FormattedMessage
              defaultMessage="Is your student still appearing offline?"
              id="jDjUKI"
            />
          </div>
        </div>
        <Button
          icon="flag-fill"
          label={intl.formatMessage({
            defaultMessage: "Report a problem",
            id: "Hf/X9X",
          })}
          onClick={onReportProblem}
          data-test-id={`${dataTestPrefix}-Button-ReportProblem`}
          type={BUTTON_TYPES.OUTLINED}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        />
      </div>
    </>
  );
};

ModalOfflineStateHelp.propTypes = {
  onReportProblem: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default ModalOfflineStateHelp;
