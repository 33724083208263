import actionTypes from "../../actionTypes";
import { USER_GROUP_PREFS_TYPE } from "./types";

const INIT_STATE = {
  groupsList: [],
  viewType: USER_GROUP_PREFS_TYPE.SHARED_ACROSS_TABS,
  showManagementPanel: false,
};

const userGroupsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_LOAD_SUCCESS:
      return {
        ...state,
        groupsList: action.payload.groups,
        viewType: action.payload.viewType,
      };

    case actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_RESET:
      return INIT_STATE;

    case actionTypes.HIGHLIGHTS_CLASS_USER_GROUP_SELECTED:
      return {
        ...state,
        groupsList: action.payload,
      };

    case actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_SHOW_MANAGEMENT_PANEL:
      return {
        ...state,
        showManagementPanel: true,
        managementPanelContentType: action.payload.contentType,
        managementPanelSelectedStudent: action.payload.selectedStudent,
      };

    case actionTypes.HIGHLIGHTS_CLASS_USER_GROUPS_HIDE_MANAGEMENT_PANEL:
      return {
        ...state,
        showManagementPanel: false,
      };

    case actionTypes.DASHBOARD_USER_GROUP_UPDATED:
      return {
        ...state,
        groupsList: action.payload,
      };

    default:
      return state;
  }
};

export default userGroupsReducer;
