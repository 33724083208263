import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { USER_INFO } from "../../../state/user/types";
import { logOut } from "../../../state/app/actions";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import styles from "./PersonalisationMobile.module.scss";
import { useIntl } from "react-intl";

const PersonalisationMobile = ({ logOut }) => {
  const intl = useIntl();
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {};
  return (
    <div className={styles.root}>
      <div className={styles.userName}>{userInfo?.email || ""}</div>
      <Button
        label={intl.formatMessage({ defaultMessage: "Logout", id: "C81/uG" })}
        className={styles.logout}
        data-test-id="ws-Header-Persona-Logout"
        onClick={() => {
          logOut();
        }}
        size={BUTTON_SIZES.XSMALL}
        type={BUTTON_TYPES.OUTLINED}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      />
    </div>
  );
};

PersonalisationMobile.propTypes = {
  logOut: PropTypes.func,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    logOut: () => dispatch(logOut()),
  })
)(PersonalisationMobile);
