import React, { useState, useEffect } from "react";
import styles from "./GroupDropDown.module.scss";
import { colourLookup } from "../../../../../utils";
import { colourRange } from "@hapara/assets/src/colours/colourRange";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import classnames from "classnames";

interface ListItemTypes {
  itemPayload: {
    ID: string;
    Title?: string;
    ColorIndex?: number;
    isSelected: boolean;
    isSemiState?: boolean;
    dataTestId?: string;
  };
  handleSelect: (itemID: string) => void;
}

const ListItem = ({ itemPayload, handleSelect }: ListItemTypes) => {
  const { ID, Title, ColorIndex, isSelected, isSemiState, dataTestId } =
    itemPayload;
  const [colour, setColour] = useState(
    colourLookup(colourRange, true, ColorIndex)
  );
  const { colourHex } = colour;

  const isRenderingBar = Title === "All Groups" ? false : true;

  useEffect(() => {
    setColour(colourLookup(colourRange, true, ColorIndex));
  }, [ColorIndex]);

  return (
    <li id={`groups_element_${ID}`} aria-selected={isSelected} role="option">
      <button
        className={classnames(styles.listItem, {
          [styles.listSelected]: isSelected,
        })}
        aria-label={Title === "All Groups" ? Title : ""}
        data-test-id={dataTestId}
        onClick={() => handleSelect(ID)}
      >
        <div className={styles.checkboxCover} />
        <Checkbox
          tabIndex={-1}
          checked={isSelected}
          semi={isSemiState}
          label=""
          themeType="medium"
          dataTestIdPrefix={`${"dataTestPrefix"}-Checkbox`}
        />
        <div
          className={styles.listContentContainer}
          onClick={() => handleSelect(ID)}
        >
          {isRenderingBar && (
            <div
              className={styles.groupColourBar}
              style={{ background: colourHex }}
            />
          )}
          <div className={styles.groupTitle}> {Title} </div>
        </div>
      </button>
    </li>
  );
};

export default ListItem;
