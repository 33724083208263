import React from 'react'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { getClassId, getCurrentClassName, getMyClasses } from '../../../shared/selectors'
import {
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
  MYWORKSPACES_CATEGORIES,
  MYWORKSPACES_FILTER_STATUS_TYPES,
  MYWORKSPACES_PL_ROLES,
} from './types'
import { WORKSPACE_SORTING_TYPES } from '../../consts'
import { getFilterLabelById } from '../labels/selectors'
import { getLocationQuery } from '../../../router/selectors'
import { MYWORKSPACES_URL_KEYS, MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from './navigation'
import { SHARED_URL_KEYS, SHARED_URL_PARAMS_DEFAULT_VALUES } from '../../../shared/navigation-keys'

const getMyWorkspacesList = state => _.get(state, 'workspace.myworkspaces.list')
export const getMyWorkspaces = state => _.get(state, 'workspace.myworkspaces.list.items')
export const getWSToCopy = state => _.get(state, 'workspace.myworkspaces.list.copy')
export const getPagesCount = state => _.get(state, 'workspace.myworkspaces.list.pagesCount')
export const getPageSize = state => _.get(state, 'workspace.myworkspaces.list.pageSize')
export const isLoading = state => _.get(state, 'workspace.myworkspaces.list.loading')
export const isListLoaded = state => _.get(state, 'workspace.myworkspaces.list.listLoaded')
export const actionInProgress = state =>
  _.get(state, 'workspace.myworkspaces.list.actionInProgress')
export const actionError = state => _.get(state, 'workspace.myworkspaces.list.actionError')
export const getLeftPanelOpenState = state =>
  _.get(state, 'workspace.myworkspaces.list.leftSidePanelOpen')
export const getRightPanelOpenState = state =>
  _.get(state, 'workspace.myworkspaces.list.rightSidePanelOpen')

export const getCurrentPage = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.page.key
    const rawParam = _.get(locationQuery, paramName)
    const parsedParam = parseInt(rawParam, 10)
    return parsedParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterClassId = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = SHARED_URL_KEYS.classId.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || SHARED_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterStatusType = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.status.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam === MYWORKSPACES_FILTER_STATUS_TYPES.PUBLISHED ||
      rawParam === MYWORKSPACES_FILTER_STATUS_TYPES.UNPUBLISHED
      ? rawParam
      : MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterPlRole = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.pl_role.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam === MYWORKSPACES_PL_ROLES.FACILITATOR ||
      rawParam === MYWORKSPACES_PL_ROLES.PARTICIPANT
      ? rawParam
      : MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterSortType = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.sort.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam === WORKSPACE_SORTING_TYPES.TITLE_AZ ||
      rawParam === WORKSPACE_SORTING_TYPES.TITLE_ZA
      ? rawParam
      : MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterLabelId = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.label.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getActiveItemId = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.selected_ws.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getActiveCardId = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.selected_card.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getActiveActivityType = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.selected_activity.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getActiveGroupId = createSelector(
  [getMyWorkspacesList, getLocationQuery],
  (myWorkspacesList, locationQuery) => {
    const paramName = MYWORKSPACES_URL_KEYS.selected_group.key
    const rawParam = _.get(locationQuery, paramName)
    return rawParam || MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName]
  }
)

export const getFilterCategoryType = state =>
  _.get(state, 'workspace.myworkspaces.list.filterCategoryType')

// ----------------------------------------------------------------------------

export const getActiveItem = createSelector(
  [getActiveItemId, getMyWorkspaces],
  (activeItemId, items) => {
    if (!activeItemId || _.isEmpty(items)) return null
    return _.find(items, item => item.Id === activeItemId)
  }
)

export const getActiveItemSelectedGroup = createSelector(
  [getActiveItem, getActiveGroupId],
  (activeItem, activeGroupId) => {
    if (!activeGroupId) return null
    const groups = _.get(activeItem, 'Groups', [])
    return _.find(groups, item => item.Id === activeGroupId)
  }
)
export const isActiveLoading = state => _.get(state, 'workspace.myworkspaces.list.activeLoading')

export const getCurrentAmountOfWorkspaces = state => {
  return _.get(state, 'workspace.myworkspaces.list.currentWSCount')
}

export const getSelectedClassName = state => {
  return _.get(state, 'workspace.myworkspaces.list.selectedClassName')
}

export const getFilterClassName = state => {
  const filterClass = _.find(getMyClasses(state), { id: getFilterClassId(state) })
  return _.get(filterClass, 'class_alias', '')
}

const currentClassId = state => getClassId(state)
const currentClassName = state => getCurrentClassName(state)
const filterLabelId = state => getFilterLabelId(state)
const getFilterLabel = getFilterLabelById(filterLabelId)
const amountOfWorkspaces = state => getCurrentAmountOfWorkspaces(state)
const wsAreLoading = state => isLoading(state)
const selectedClassName = state => getSelectedClassName(state)

export const getTitleForWorkspacesList = createSelector(
  [
    getFilterCategoryType,
    currentClassId,
    getFilterClassId,
    getFilterClassName,
    getFilterLabel,
    currentClassName,
    amountOfWorkspaces,
    wsAreLoading,
    selectedClassName,
  ],
  (
    category,
    currentClassId,
    filterClassId,
    filterClassName,
    filterLabel,
    currentClassName,
    amountOfWorkspaces,
    wsAreLoading,
    selectedClassName
  ) => {
    const key = _.findKey(MYWORKSPACES_FILTER_CATEGORY_TYPES, _.partial(_.isEqual, category))
    if (!key || !MYWORKSPACES_CATEGORIES[key]) return ''

    if (key === 'CLASSES') {
      if (filterClassId)
        return (
          <>
            {filterClassName || selectedClassName}&nbsp;&nbsp;
            {!wsAreLoading && <span>({amountOfWorkspaces})</span>}
          </>
        )
      if (currentClassId)
        return (
          <>
            {currentClassName || selectedClassName}&nbsp;&nbsp;
            {!wsAreLoading && <span>({amountOfWorkspaces})</span>}
          </>
        )
    }

    if (key === 'LABELS') {
      if (filterLabel) {
        return (
          <>
            <span>Label: </span>
            &#8220;{filterLabel.Name}&#8221;&nbsp;&nbsp;
            {!wsAreLoading && <span>({filterLabel.deleted ? 'deleted' : amountOfWorkspaces})</span>}
          </>
        )
      }
    }

    return (
      <>
        {MYWORKSPACES_CATEGORIES[key].title}&nbsp;&nbsp;
        {!wsAreLoading && <span>({amountOfWorkspaces})</span>}
      </>
    )
  }
)

export const getPageTitle = createSelector(
  [
    getFilterCategoryType,
    currentClassId,
    getFilterClassId,
    getFilterClassName,
    getFilterLabel,
    currentClassName,
    amountOfWorkspaces,
    wsAreLoading,
  ],
  (
    category,
    currentClassId,
    filterClassId,
    filterClassName,
    filterLabel,
    currentClassName,
    amountOfWorkspaces,
    wsAreLoading
  ) => {
    const key = _.findKey(MYWORKSPACES_FILTER_CATEGORY_TYPES, _.partial(_.isEqual, category))
    if (!key || !MYWORKSPACES_CATEGORIES[key]) return ''

    if (key === 'CLASSES') {
      if (filterClassId)
        return wsAreLoading ? `${filterClassName}` : `${filterClassName} (${amountOfWorkspaces})`

      if (currentClassId) return `${currentClassName} (0)`
    }

    if (key === 'LABELS') {
      if (filterLabel)
        return wsAreLoading
          ? `Label: ${filterLabel.Name}`
          : `Label: ${filterLabel.Name} (${amountOfWorkspaces})`
    }

    if (key === 'SEARCH') {
      return `${MYWORKSPACES_CATEGORIES[key].title}`
    }
    return wsAreLoading
      ? `${MYWORKSPACES_CATEGORIES[key].title}`
      : `${MYWORKSPACES_CATEGORIES[key].title} (${amountOfWorkspaces})`
  }
)
