import actionTypes from "../actionTypes";
import { saveUserPrefs } from "../../apiCalls/workspaces";
import { makeActionCreator } from "../storeUtils";
import assign from "lodash/assign";
import map from "lodash/map";
import { showAppError } from "../app/actions";

export const userLoggedIn = () => ({
  type: actionTypes.USER_LOGGED_IN,
});

export const userLoggedOut = () => ({
  type: actionTypes.USER_LOGGED_OUT,
});

export const userUnauthorisedAccess = () => ({
  type: actionTypes.USER_UNAUTHORISED_ACCESS,
});

export const unknownUser = () => ({
  type: actionTypes.USER_UNKNOWN,
});

export const userDetailsUpdated = (payload) => ({
  type: actionTypes.USER_DETAILS_UPDATED,
  payload,
});

export const userPrefsSaveErr = makeActionCreator(
  actionTypes.USER_PREFS_SAVE_ERROR,
  "error"
);
export const userPrefsSaved = makeActionCreator(
  actionTypes.USER_PREFS_SAVE_SUCCESS,
  "payload"
);

// Reformat [{pref: 'label', value: 'some'}] -> {label:some}
export const reformatPrefs = (prefs) => {
  return assign({}, ...map(prefs, (p) => ({ [p.pref]: p.value })));
};

export const saveUserPreferences = (nameFormatPref) => {
  return (dispatch) => {
    saveUserPrefs(nameFormatPref)
      .then((response) => {
        if (response.status === 403) {
          // Not a Hapara user, show an error message
          throw Error(response);
        }
        if (!response.ok) {
          throw Error(response);
        }
        return response;
      })
      .then(dispatch(userPrefsSaved(nameFormatPref)))
      .catch((error) => {
        dispatch(userPrefsSaveErr(error));
        dispatch(showAppError(error));
      });
  };
};
