import { createSelector } from "reselect";
import { getNestedValue } from "@hapara/ui/src/utils/nestedValueHelper";

const getConfig = (state) => getNestedValue(state, "highlights.config");

export const getIsConfigLoading = createSelector(getConfig, (config) =>
  getNestedValue(config, "isLoading")
);

export const getIsConfigLoaded = createSelector(getConfig, (config) =>
  getNestedValue(config, "isLoaded")
);

export const getIsConfigError = createSelector(getConfig, (config) =>
  getNestedValue(config, "isError")
);

export const getClassConfig = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig")
);

export const getClassURN = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.ClassURN")
);

export const getClassTeacherId = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.Email")
);

export const getStudentsOrderType = createSelector(getConfig, (config) =>
  getNestedValue(config, "studentsOrder.type")
);

export const getStudentTilesSizeType = createSelector(getConfig, (config) =>
  getNestedValue(config, "tileSize")
);

export const getStudentsCustomOrder = createSelector(getConfig, (config) =>
  getNestedValue(config, "studentsOrder.customOrder")
);

export const getClassTabCloseReasonsEnabled = createSelector(
  getConfig,
  (config) => getNestedValue(config, "classConfig.enableCloseReason")
);

export const getClassTabCloseReasons = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.closeReasons")
);

export const getItemsPerTile = createSelector(getConfig, (config) =>
  getNestedValue(config, "itemsPerTile")
);

export const getMonitoringTime = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.MonitoringTime")
);

export const getIsOutsideSchoolHours = createSelector(getConfig, (config) =>
  getNestedValue(config, "isOutsideSchoolHours")
);

export const getPauseScreensEnabled = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.PauseScreenEnabled")
);

export const getPauseScreensDuration = createSelector(
  getConfig,
  (config) => getNestedValue(config, "classConfig.PauseDuration") || 15
);

export const getStudentNotesEnabled = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.StudentNotesEnabled")
);

export const getScreenshotsEnabled = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.ScreenshotsEnabled")
);

export const getClassEmailFormatLink = createSelector(getConfig, (config) =>
  getNestedValue(config, "classConfig.emailFormatLink")
);

export const getActiveViewType = createSelector(getConfig, (config) =>
  getNestedValue(config, "activeViewType")
);
