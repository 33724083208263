import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./CloseTabReason.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";

const CloseTabReason = ({
  closeTab,
  isOpen,
  closeReasons,
  closeModal,
  appElementSelector,
  closeModalPosition,
}) => {
  const [modalStyle, setModalStyle] = useState({
    top: 0,
    left: 0,
    opacity: 0,
  });
  ReactModal.setAppElement(appElementSelector);

  const refContent = useRef();
  const intl = useIntl();

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
      // focus the inner content for screen readers to correctly navigate into a modal
      _.delay(() => refContent.current && refContent.current.focus(), 300);
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.scroll = "yes";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.scroll = "yes";
    };
  }, [isOpen, refContent]);

  useEffect(() => {
    _.delay(() => {
      let topPos = closeModalPosition.crossPositionTop - 26;
      let leftPos = closeModalPosition.crossPositionLeft + 25;

      const modalWidth = refContent.current
        ? refContent.current.offsetWidth
        : 0;
      const modalHeight = refContent.current
        ? refContent.current.offsetHeight
        : 0;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const modalMargin = 30;

      if (leftPos + modalWidth + modalMargin > windowWidth) {
        leftPos = closeModalPosition.cellPositionLeft - modalWidth;
      }
      if (leftPos < modalMargin) {
        leftPos = modalMargin;
      }
      if (topPos + modalHeight + modalMargin > windowHeight) {
        topPos = windowHeight - modalMargin - modalHeight;
      }
      if (topPos < modalMargin) {
        topPos = modalMargin;
      }
      if (windowWidth < 768) {
        leftPos = (windowWidth - modalWidth) / 2;
        topPos = 16;
      }

      setModalStyle({
        top: topPos + "px",
        left: leftPos + "px",
        opacity: 1,
      });
    }, 1);
  }, [closeModalPosition, refContent]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.root}
      overlayClassName={styles.overlay}
      contentLabel="Why close this tab?"
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      aria={{
        modal: true,
      }}
      data={{ "test-id": `Hl-StudentTile-BrowserTab-CloseWithReasonModal` }}
      shouldFocusAfterRender={true}
    >
      <div
        className={classnames(styles.content)}
        ref={refContent}
        tabIndex={-1}
        style={modalStyle}
      >
        <div className={classnames(styles.title)}>
          <h1
            className={styles.header}
            data-test-id="Hl-StudentTile-BrowserTab-CloseWithReasonModal-CloseHeader"
          >
            <FormattedMessage
              defaultMessage="Why close this tab?"
              id="vo40Om"
            />
          </h1>
          <Button
            icon="cross"
            onClick={closeModal}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.XSMALL}
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              id: "rbrahO",
            })}
            data-test-id="Hl-StudentTile-BrowserTab-CloseWithReasonModal-CloseModal"
            className={styles.closeButton}
          />
        </div>

        <div className={styles.body}>
          <p className={styles.infoText}>
            <FormattedMessage
              defaultMessage="The reason you select will be shown on the student’s screen."
              id="973CyU"
            />
          </p>
          <ul>
            {closeReasons.map((r) => {
              return (
                <li key={r} className={styles.reasonTile}>
                  <span
                    className={styles.reasonTitle}
                    data-test-id="Hl-StudentTile-BrowserTab-CloseWithReasonModal-Reasons"
                  >
                    {r}
                  </span>
                  <span>
                    <Button
                      label={intl.formatMessage({
                        defaultMessage: "Close tab",
                        id: "HqPKrA",
                      })}
                      type={BUTTON_TYPES.SECONDARY}
                      onAction={() => closeTab(r)}
                      size={BUTTON_SIZES.SMALL}
                      dataTestId="Hl-StudentTile-BrowserTab-CloseWithReasonModal-ConfirmClose"
                    />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </ReactModal>
  );
};

CloseTabReason.propTypes = {
  closeTab: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeReasons: PropTypes.arrayOf(PropTypes.string),
  closeModal: PropTypes.func.isRequired,
  appElementSelector: PropTypes.string,
  closeModalPosition: PropTypes.shape({
    crossIconTop: PropTypes.number,
    crossIconLeft: PropTypes.number,
    studentTileLeft: PropTypes.number,
  }),
  status: PropTypes.string,
};

export default CloseTabReason;
