import _ from "lodash";
import { Template } from "./type";

export const getTemplatesPanelStatus: (state: any) => boolean = (state: any) =>
  _.get(state, "highlights.guideBrowsingTemplates.showTemplatesPanel");

export const getUserTemplatesList: (state: any) => Template[] = (state: any) =>
  _.get(state, "highlights.guideBrowsingTemplates.templatesList");

export const getTemplatesPanelScheduledStatus: (state: any) => boolean = (
  state: any
) => _.get(state, "highlights.guideBrowsingTemplates.templatesPanelScheduled");

export const getTemplatesPanelTemplatesOffset: (state: any) => number = (
  state: any
) => _.get(state, "highlights.guideBrowsingTemplates.offset", 0);

export const getTemplatesPanelIsMoreTemplatesAvailable: (
  state: any
) => boolean = (state: any) =>
  _.get(state, "highlights.guideBrowsingTemplates.isMoreTemplatesAvailable");
