import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import {
  addStudentToClass,
  resetAddStudentsToClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsAddStudentLoading,
  getIsAddStudentError,
  getIsAddStudentLoaded,
  getUnsuccessfullyAddedStudents,
} from "../../state/classInfo/selectors";

import { containsOnlyValidEmails } from "@hapara/ui/src/utils/emailValidation/emailValidation";

type ExportedValues = {
  isAddStudentError: boolean;
  isAddStudentLoaded: boolean;
  isAddStudentLoading: boolean;
  handleAddStudents: (students: string) => void;
  unsuccessfulEmails: string[];
  addStudentValidationErrorMessage: string;
};

type UseClassInfoAddStudentsProps = {
  closeModal: () => void;
  isModalOpen: boolean;
};

export const validationErrorMessage =
  "Please input valid emails: email1@domain.com, email2@d...";

export const useClassInfoAddStudents = ({
  closeModal,
  isModalOpen,
}: UseClassInfoAddStudentsProps): ExportedValues => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isAddStudentsLoading = useSelector(getIsAddStudentLoading);
  const isAddStudentsError = useSelector(getIsAddStudentError);
  const isAddStudentsLoaded = useSelector(getIsAddStudentLoaded);
  const unsuccessfulStudents = useSelector(getUnsuccessfullyAddedStudents);
  const unsuccessfulEmails = unsuccessfulStudents.map(
    (student) => student.EmailAddress
  );
  const [
    addStudentValidationErrorMessage,
    setAddStudentValidationErrorMessage,
  ] = useState<string>("");

  const handleAddStudents = (students: string) => {
    setAddStudentValidationErrorMessage("");
    dispatch(resetAddStudentsToClassState());
    const studentEmails = students.split(",").map((student) => student.trim());

    if (!containsOnlyValidEmails(studentEmails)) {
      return setAddStudentValidationErrorMessage(validationErrorMessage);
    }

    dispatch(addStudentToClass(classId, studentEmails));
  };

  useEffect(() => {
    if (
      !isAddStudentsLoading &&
      isAddStudentsLoaded &&
      !isAddStudentsError &&
      !unsuccessfulEmails?.length
    ) {
      closeModal();
    }
  }, [isAddStudentsLoading, isAddStudentsLoaded, isAddStudentsError]);

  useEffect(() => {
    setAddStudentValidationErrorMessage("");
    dispatch(resetAddStudentsToClassState());
  }, [isModalOpen]);

  return {
    isAddStudentError: isAddStudentsError,
    isAddStudentLoaded: isAddStudentsLoaded,
    isAddStudentLoading: isAddStudentsLoading,
    handleAddStudents,
    unsuccessfulEmails,
    addStudentValidationErrorMessage,
  };
};
