import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./SchedulePage.module.scss";
import classnames from "classnames";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import NoSessionsInfoPanel from "../../../partial/Highlights/NoSessionsInfoPanel/NoSessionsInfoPanel";
import ScheduledSessionsList from "../../../partial/Highlights/ScheduledSessionsList/ScheduledSessionsList";
import { MAIN_CONTENT_ID } from "../../../../consts";
import {
  GUIDE_BROWSING_SESSION_TYPE,
  SCHEDULED_SESSIONS_TIMEFRAMES,
  SESSION_TYPES,
  MODAL_MODE_TYPES,
} from "../../../../state/highlights/sessions/types";
import {
  getIsConfigLoaded,
  getIsConfigError,
  getMonitoringTime,
} from "../../../../state/highlights/config/selectors";
import { MONITORING_TIME_TYPE } from "../../../../state/highlights/config/types";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import { showGuideBrowsingModal } from "../../../../state/highlights/sessions/actions";
import { showShareLinksModal } from "../../../../state/highlights/shareLinks/actions";
import { getClassId } from "../../../../state/shared/selectors";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import {
  getScheduledSessionListLoadingState,
  getScheduledSessionList,
  getScheduledSessionReloadRequest,
} from "../../../../state/highlights/sessions/selectors";
import { loadScheduledSessionsList } from "../../../../state/highlights/sessions/actions";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import moment from "moment";
import _ from "lodash";
import PageLoading from "@hapara/ui/src/atomic/LoadIndicators/PageLoading/PageLoading";
import { showTemplatesPanel } from "../../../../state/highlights/guideBrowsingTemplates/actions";
import { FormattedMessage, useIntl } from "react-intl";

const dataTestPrefix = "hl-ScheduleSessions-Page";
const PAGE_SIZE = 10;
const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const LoadingBlock = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.block}>
        <div className={styles.image} />
        <div className={styles.text} />
        <div className={styles.longText} />
      </div>
      <div className={styles.block}>
        <div className={styles.image} />
        <div className={styles.text} />
        <div className={styles.longText} />
      </div>
    </div>
  );
};

// TODO needs refactoring
// move module logic out from page container one
const SchedulePage = ({
  isScheduledSessionListLoading,
  scheduledSessionList,
  loadScheduledSessions,
  showGuideBrowsingModal,
  showShareLinksModal,
  isReloadRequestAfterSessionAdded,
  classId = "",
  isConfigLoaded,
  isConfigError,
  domainTimeAndTimeZone,
  showTemplatePanel,
}) => {
  const intl = useIntl();
  const SCHEDULED_SESSIONS_VIEWS = [
    {
      Id: SCHEDULED_SESSIONS_TIMEFRAMES.CURRENT_WEEK,
      MenuTitle: intl.formatMessage({
        defaultMessage: "This week",
        id: "PtxhIN",
      }),
      EmptyStateText: intl.formatMessage({
        defaultMessage: "There are no scheduled sessions this week",
        id: "bFeV72",
      }),
    },
    {
      Id: SCHEDULED_SESSIONS_TIMEFRAMES.UPCOMING,
      MenuTitle: intl.formatMessage({
        defaultMessage: "All upcoming sessions",
        id: "K4I+qM",
      }),
      EmptyStateText: intl.formatMessage({
        defaultMessage: "There are no upcoming sessions",
        id: "1Pmttn",
      }),
      DataTestPrefix: "Upcoming",
    },
    {
      Id: SCHEDULED_SESSIONS_TIMEFRAMES.PAST,
      MenuTitle: intl.formatMessage({
        defaultMessage: "Past sessions",
        id: "5tBNqY",
      }),
      EmptyStateText: intl.formatMessage({
        defaultMessage: "There are no past sessions",
        id: "rDpCVV",
      }),
      DataTestPrefix: "PastSessions",
    },
  ];
  const [viewSelected, setViewSelected] = useState(SCHEDULED_SESSIONS_VIEWS[0]);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoadMoreInProgress, setIsLoadMoreInProgress] = useState(false);
  let reloadSessionsTimerId = useRef(null);
  let ddButtonRef = useRef(null);

  const getDatesRangeAndOrder = useCallback(() => {
    let fromTs, toTs, sortOrder;
    if (viewSelected.Id === SCHEDULED_SESSIONS_TIMEFRAMES.CURRENT_WEEK) {
      const serverTime = _.get(domainTimeAndTimeZone, "CurrentTime", null);
      const serverTimezone = _.get(domainTimeAndTimeZone, "Timezone", null);
      const ts = moment.tz(serverTime, serverTimezone);
      fromTs = ts.weekday(0).startOf("day").toISOString();
      toTs = ts.weekday(0).startOf("day").add(1, "weeks").toISOString();
      sortOrder = SORT_ORDER.ASC;
    }
    if (viewSelected.Id === SCHEDULED_SESSIONS_TIMEFRAMES.UPCOMING) {
      fromTs = moment().toISOString();
      toTs = moment().add(1, "year").toISOString();
      sortOrder = SORT_ORDER.ASC;
    }
    if (viewSelected.Id === SCHEDULED_SESSIONS_TIMEFRAMES.PAST) {
      fromTs = moment().subtract(1, "year").toISOString();
      toTs = moment().toISOString();
      sortOrder = SORT_ORDER.DESC;
    }
    return { fromTs, toTs, sortOrder };
  }, [viewSelected, domainTimeAndTimeZone]);

  const reloadSessionsList = useCallback(() => {
    const { fromTs, toTs, sortOrder } = getDatesRangeAndOrder();
    const showLoadingState = true;
    loadScheduledSessions({
      classId,
      fromTs,
      toTs,
      sortOrder,
      pageSize: PAGE_SIZE * (pageNumber + 1),
      page: 0,
      showLoadingState,
    }).catch((e) => {
      setIsLoadingError(true);
    });
  }, [classId, getDatesRangeAndOrder, loadScheduledSessions, pageNumber]);

  useEffect(() => {
    if (isReloadRequestAfterSessionAdded) {
      // a session was added using modal, need to reload the sessions list
      reloadSessionsList();
    }
  }, [isReloadRequestAfterSessionAdded, reloadSessionsList]);

  useEffect(() => {
    const { fromTs, toTs, sortOrder } = getDatesRangeAndOrder();
    const pageSize = PAGE_SIZE;
    setPageNumber(0); // re-init values after viewSelected changed
    setIsLoadingError(false);
    setIsLoadMoreInProgress(false);

    const showLoadingState = true;
    if (
      !isScheduledSessionListLoading &&
      !isReloadRequestAfterSessionAdded &&
      isConfigLoaded &&
      !_.isEmpty(domainTimeAndTimeZone) &&
      !isConfigError
    ) {
      loadScheduledSessions({
        classId,
        fromTs,
        toTs,
        sortOrder,
        page: 0,
        pageSize,
        showLoadingState,
      }).catch((e) => {
        setIsLoadingError(true);
      });
    }
  }, [
    loadScheduledSessions,
    classId,
    domainTimeAndTimeZone,
    viewSelected,
    isConfigLoaded,
    isConfigError,
    getDatesRangeAndOrder,
  ]);

  const loadMoreSessions = () => {
    const { fromTs, toTs, sortOrder } = getDatesRangeAndOrder();
    const showLoadingState = false;
    setIsLoadMoreInProgress(true);
    loadScheduledSessions({
      classId,
      fromTs,
      toTs,
      sortOrder,
      pageSize: PAGE_SIZE,
      page: pageNumber + 1,
      showLoadingState,
    })
      .then((r) => {
        setIsLoadMoreInProgress(false);
        setPageNumber(pageNumber + 1);
      })
      .catch((e) => {
        setIsLoadingError(true);
        setIsLoadMoreInProgress(false);
      });
  };

  const editOrDuplicateSession = ({ sessionId, isDuplicate }) => {
    const session = _.find(scheduledSessionList, { ID: sessionId });
    if (!session) {
      return null;
    }

    if (
      session.Type === SESSION_TYPES.FOCUS ||
      session.Type === SESSION_TYPES.FILTER
    ) {
      const modalData = {
        modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
        sessionType: session.Type,
        isDuplicateSession: isDuplicate,
        sessionData: {
          Links: session.Links,
          Recipient: session.Recipient,
          IsScheduledSession: true,
          ScheduledDateTime: session.Start,
          FocusSessionDetails: session.FocusSessionDetails,
          Duration: Math.round((session.Duration - 10) / 60),
          SessionName: session.SessionName,
        },
      };
      if (isDuplicate) {
        modalData.sessionData.SessionName = session.SessionName
          ? `Copy of ${session.SessionName}`
          : null;
      }
      if (!isDuplicate) {
        modalData.sessionData.SessionId = sessionId;
      }
      showGuideBrowsingModal(modalData);
    } else if (session.Type === SESSION_TYPES.SHARE_LINKS) {
      const modalData = {
        isDuplicateSession: isDuplicate,
        sessionData: {
          Links: session.Links,
          Recipient: session.Recipient,
          IsScheduledSession: true,
          ScheduledDateTime: session.Start,
          SessionName: session.SessionName,
        },
      };
      if (isDuplicate) {
        modalData.sessionData.SessionName = session.SessionName
          ? `Copy of ${session.SessionName}`
          : null;
      }
      if (!isDuplicate) {
        modalData.sessionData.SessionId = sessionId;
      }
      showShareLinksModal(modalData);
    }
  };

  useEffect(() => {
    // go thought all sessions planed and find the nearest after "now"
    // when the session will start - move it into past list by reloading the whole sessions list
    if (!isScheduledSessionListLoading && scheduledSessionList.length > 0) {
      if (
        viewSelected.Id === SCHEDULED_SESSIONS_TIMEFRAMES.CURRENT_WEEK ||
        viewSelected.Id === SCHEDULED_SESSIONS_TIMEFRAMES.UPCOMING
      ) {
        let nearestSession = moment().add(1, "day");
        const sessionsSorted = _.orderBy(
          scheduledSessionList,
          ["Start"],
          ["asc"]
        );
        const currectTime = moment();

        for (const sess of sessionsSorted) {
          const startTime = moment(sess.Start);
          const diffTime = moment
            .duration(startTime.diff(currectTime))
            .asMilliseconds();
          if (diffTime > 0) {
            nearestSession = startTime;
            break;
          }
        }
        const diffTime = moment
          .duration(nearestSession.diff(currectTime))
          .asMilliseconds();
        if (diffTime > 0) {
          window.clearTimeout(reloadSessionsTimerId.current);
          reloadSessionsTimerId.current = _.delay(
            () => reloadSessionsList(),
            diffTime
          );
        }
      }
    }
  }, [scheduledSessionList, viewSelected]);

  // clean up on unMount
  useEffect(() => {
    return () => {
      window.clearTimeout(reloadSessionsTimerId.current);
    };
  }, []);

  return (
    <Page
      header={<Header />}
      footer={
        <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
      }
      isPageLoadingError={isConfigError}
      isPageLoaded={isConfigLoaded}
      title={APP_PAGES.HIGHLIGHTS_SCHEDULE.title}
    >
      <ModalsAndPanels />

      <main
        className={styles.root}
        data-test-id="Hs-Pages-HighlightsSchedulePage"
        id={MAIN_CONTENT_ID}
        tabIndex="-1"
      >
        <h1>
          <FormattedMessage defaultMessage="Scheduled Sessions" id="3xPmzu" />
        </h1>

        <div className={styles.subNavigation}>
          <div className={styles.viewToggle}>
            <ul>
              {SCHEDULED_SESSIONS_VIEWS.map((v, ind) => {
                return (
                  <li key={v.Id}>
                    <button
                      className={classnames(styles.menuItem, {
                        [styles.selected]: v.Id === viewSelected.Id,
                      })}
                      onClick={() =>
                        setViewSelected(SCHEDULED_SESSIONS_VIEWS[ind])
                      }
                      data-test-id={`${dataTestPrefix}-ScheduleSession-TabNav-${v.DataTestPrefix}`}
                      aria-label={`${v.MenuTitle}${
                        v.Id === viewSelected.Id ? ", selected" : ""
                      }`}
                    >
                      {v.MenuTitle}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.scheduleSession}>
            <Dropdown
              positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
              widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
              className={styles.scheduleSessionDropdown}
              triggerComponent={(props) => (
                <Button
                  label={intl.formatMessage({
                    defaultMessage: "Schedule a session",
                    id: "rd3923",
                  })}
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.XSMALL}
                  rightIcon="arrow-carvet-down"
                  dataTestId={`${dataTestPrefix}-ScheduleSessionDropdown-Trigger`}
                  className={styles.scheduleSessionButton}
                  ref={ddButtonRef}
                  {...props}
                />
              )}
              itemComponentList={[
                ({ onClick, className, ...rest }) => (
                  <button
                    className={classnames(
                      className,
                      styles.dropdownContentItem
                    )}
                    data-test-id={`${dataTestPrefix}-ScheduleSessionDropdown-ShareLinks`}
                    onClick={() => {
                      onClick();
                      if (ddButtonRef.current) {
                        ddButtonRef.current.focus();
                      }
                      showShareLinksModal({
                        sessionData: {
                          IsScheduledSession: true,
                        },
                      });
                    }}
                    {...rest}
                  >
                    <FormattedMessage
                      defaultMessage="Share links"
                      id="86oph/"
                    />
                  </button>
                ),
                ({ onClick, className, ...rest }) => {
                  return (
                    <button
                      className={classnames(
                        className,
                        styles.dropdownContentItem
                      )}
                      data-test-id={`${dataTestPrefix}-ScheduleSessionDropdown-GuideBrowsing`}
                      onClick={() => {
                        onClick();
                        if (ddButtonRef.current) {
                          ddButtonRef.current.focus();
                        }
                        showTemplatePanel();
                      }}
                      {...rest}
                    >
                      <FormattedMessage
                        defaultMessage="Guide browsing"
                        id="JLAnOx"
                      />
                    </button>
                  );
                },
              ]}
            />
          </div>
        </div>

        {isScheduledSessionListLoading && !isLoadingError && <LoadingBlock />}

        {isLoadingError && (
          <PageLoading
            isError={isLoadingError}
            errorMessageText={intl.formatMessage({
              defaultMessage: "Sorry we are having trouble connecting",
              id: "I/51DU",
            })}
            isGoBackVisible={false}
          />
        )}

        {!isScheduledSessionListLoading &&
          !isLoadingError &&
          scheduledSessionList.length === 0 && (
            <NoSessionsInfoPanel noSessionsText={viewSelected.EmptyStateText} />
          )}

        {!isScheduledSessionListLoading &&
          !isLoadingError &&
          scheduledSessionList.length > 0 && (
            <ScheduledSessionsList
              sessions={scheduledSessionList.slice(
                0,
                (pageNumber + 1) * PAGE_SIZE
              )}
              loadMoreSessions={loadMoreSessions}
              isLoadMoreInProgress={isLoadMoreInProgress}
              showLoadMoreButton={
                scheduledSessionList.length > (pageNumber + 1) * PAGE_SIZE
              }
              reloadSessionsList={reloadSessionsList}
              editOrDuplicateSession={editOrDuplicateSession}
            />
          )}
      </main>
    </Page>
  );
};

SchedulePage.propTypes = {
  isScheduledSessionListLoading: PropTypes.bool.isRequired,
  isReloadRequestAfterSessionAdded: PropTypes.bool.isRequired,
  scheduledSessionList: PropTypes.arrayOf(GUIDE_BROWSING_SESSION_TYPE),
  showGuideBrowsingModal: PropTypes.func.isRequired,
  showShareLinksModal: PropTypes.func.isRequired,
  loadScheduledSessions: PropTypes.func.isRequired,
  classId: PropTypes.string,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  domainTimeAndTimeZone: MONITORING_TIME_TYPE,
};

export default connect(
  (state) => ({
    isScheduledSessionListLoading: getScheduledSessionListLoadingState(state),
    scheduledSessionList: getScheduledSessionList(state),
    isReloadRequestAfterSessionAdded: getScheduledSessionReloadRequest(state),
    classId: getClassId(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    domainTimeAndTimeZone: getMonitoringTime(state),
  }),
  (dispatch) => ({
    showGuideBrowsingModal: (payload) =>
      dispatch(showGuideBrowsingModal(payload)),
    showShareLinksModal: (payload) => dispatch(showShareLinksModal(payload)),
    loadScheduledSessions: ({
      classId,
      fromTs,
      toTs,
      sortOrder,
      pageSize,
      page,
      showLoadingState,
    }) =>
      dispatch(
        loadScheduledSessionsList({
          classId,
          fromTs,
          toTs,
          sortOrder,
          pageSize,
          page,
          showLoadingState,
        })
      ),
    showTemplatePanel: () =>
      dispatch(showTemplatesPanel({ isScheduledPanel: true })),
  })
)(SchedulePage);
