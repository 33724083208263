import classnames from "classnames";
import styles from "./GuidedBrowsingSectionContent.module.scss";
import { Heading } from "@hapara/ui/src/atomic/Heading/Heading";

interface GuidedBrowsingSectionContentProps {
  title: string;
  children: React.ReactNode;
  withCentredHeading?: boolean;
  withPersistedLayout?: boolean;
}

export const GuidedBrowsingSectionContent = ({
  title,
  children,
  withCentredHeading,
  withPersistedLayout,
}: GuidedBrowsingSectionContentProps) => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.withPersistedLayout]: withPersistedLayout,
      })}
    >
      <div
        className={classnames(styles.headingContainer, {
          [styles.centredHeading]: withCentredHeading,
        })}
      >
        <Heading level={2} styledAs="h4" removeBottomMargin removeTopMargin>
          {title}
        </Heading>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
