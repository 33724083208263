import PropTypes from "prop-types";

export const EXPAND_MODAL_INSTANCE_TYPE = PropTypes.shape({
  image: PropTypes.string,
  url: PropTypes.string,
  isNoImage: PropTypes.bool,
});

export const CURRENT_SCREEN_EXPAND_MODAL_TYPE = PropTypes.shape({
  isOpen: PropTypes.bool.isRequired,
  studentId: PropTypes.string,
  studentEmail: PropTypes.string,
  studentLastName: PropTypes.string,
  studentDisplayName: PropTypes.string,
  instances: PropTypes.objectOf(EXPAND_MODAL_INSTANCE_TYPE),
});

export const SCREEN_STATES = {
  ONLINE: "ONLINE",
  LOADING: "LOADING",
  UNAVAILABLE: "UNAVAILABLE",
  RESTRICTED: "RESTRICTED",
  PAUSED: "PAUSED",
  MULTIPLE_DEVICES: "MULTIPLE_DEVICES",
};

export const TABS_STATES = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  LOADING: "LOADING",
  NOT_BROWSING: "NOT_BROWSING",
  OUTSIDE_SCHOOL_NETWORK: "OUTSIDE_SCHOOL_NETWORK",
  OUTSIDE_SCHOOL_HOURS: "OUTSIDE_SCHOOL_HOURS",
  TEACHER_ACCOUNT: "TEACHER_ACCOUNT",
  PAUSED: "PAUSED",
  MULTIPLE_DEVICES: "MULTIPLE_DEVICES",
};
