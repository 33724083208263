import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CategoryTitle.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

export const CategoryTitle = ({
  title,
  areFiltersSelected,
  searchResultsTotal,
  dataTestId,
  clearFiltersAction,
}) => {
  const searchHeader = `${searchResultsTotal} search result${
    searchResultsTotal !== 1 ? "s" : ""
  }`;

  return (
    <div className={styles.root}>
      {!areFiltersSelected && (
        <div className={styles.titleContainer} data-test-id={dataTestId}>
          {title ? title : <h1>{`All (${searchResultsTotal})`}</h1>}
        </div>
      )}
      {areFiltersSelected && (
        <>
          <div className={styles.titleContainer} data-test-id={dataTestId}>
            <div className={classnames(styles.title, styles.toTheLeft)}>
              <div className={styles.header}>
                <h1>{searchHeader}</h1>
                <Button
                  type={BUTTON_TYPES.OUTLINED}
                  size={BUTTON_SIZES.SMALL}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  onAction={clearFiltersAction}
                  label="Clear"
                  dataTestId={`${dataTestId}-SearchResults-ClearSearch`}
                  aria-label="Clear search results"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

CategoryTitle.propTypes = {
  title: PropTypes.node,
  dataTestId: PropTypes.string.isRequired,
  areFiltersSelected: PropTypes.bool.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
};

export default CategoryTitle;
