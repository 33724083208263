import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationPath } from "../../router/selectors";
import { getSelectedResourceId } from "../../shared/selectors";
import { APP_PAGES } from "../../router/types";
import { DEFAULT_PAGE_SIZE } from "../../shared/types";

const getDiscover = (state) => _.get(state, "library.discover");

export const getDiscoverItemsList = createSelector(getDiscover, (d) =>
  _.get(d, "items")
);

export const getDiscoverSelectedItem = createSelector(
  [getDiscoverItemsList, getSelectedResourceId],
  (items, selectedId) => _.find(items, { ID: selectedId })
);

export const getDiscoverItemsTotal = createSelector(getDiscover, (d) =>
  _.get(d, "totalItems", 0)
);

export const getDiscoverPagesCount = createSelector(getDiscover, (d) =>
  _.get(d, "pagesCount", 0)
);

export const getDiscoverSearchGuid = createSelector(getDiscover, (d) =>
  _.get(d, "searchGuid", null)
);

export const getDiscoverSearchIteration = createSelector(getDiscover, (d) =>
  _.get(d, "searchIteration", 0)
);

export const getDiscoverPageSize = createSelector(getDiscover, (d) =>
  _.get(d, "pageSize", DEFAULT_PAGE_SIZE)
);

export const getDiscoverIsLoading = createSelector(getDiscover, (d) =>
  _.get(d, "isLoading", false)
);

export const getDiscoverLeftPanelIsOpen = createSelector(getDiscover, (d) =>
  _.get(d, "isLeftSidePanelOpen")
);

export const areDiscoverResultsNull = (state) =>
  getDiscoverItemsList(state) === null;

export const isInsideDiscover = createSelector(
  [getLocationPath],
  (locationPath) => !!_.includes(locationPath, APP_PAGES.DISCOVER_BASE.path)
);
