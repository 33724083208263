 const darkText = '#000'
 const lightText = '#fff'

export const cardColourPalette = [
  {
    displayOrder: 1,
    colourIndex: 4, //legacy
    colourType: 'card-full',
    colourHex: '#FFBAB1',
    accessibleName: 'Pastel Red',
    textHex: darkText,
    isDarkCard: false, //used to determine scroll bar and border colours
  },
  {
    displayOrder: 2,
    colourIndex: 14,
    colourType: 'card-full',
    colourHex: '#FFB6F5',
    accessibleName: 'Pastel Purple',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 3,
    colourIndex: 3, //legacy
    colourType: 'card-full',
    colourHex: '#DCCEE8',
    accessibleName: 'Pastel Violette',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 4,
    colourIndex: 16,
    colourType: 'card-full',
    colourHex: '#B4BDFE',
    accessibleName: 'Pastel Indigo',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 5,
    colourIndex: 2, //legacy
    colourType: 'card-full',
    colourHex: '#B4DAEC',
    accessibleName: 'Pastel Blue',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 6,
    colourIndex: 18,
    colourType: 'card-full',
    colourHex: '#DDFFFF',
    accessibleName: 'Pastel Turquoise ',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 7,
    colourIndex: 19,
    colourType: 'card-full',
    colourHex: '#A5FFB4',
    accessibleName: 'Pastel Green',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 8,
    colourIndex: 20,
    colourType: 'card-full',
    colourHex: '#DDFF85',
    accessibleName: 'Pastel Lime',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 9,
    colourIndex: 6, //legacy
    colourType: 'card-full',
    colourHex: '#F8E68A',
    accessibleName: 'Pastel Yellow',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 10,
    colourIndex: 21,
    colourType: 'card-full',
    colourHex: '#FFD6B0',
    accessibleName: 'Pastel Orange',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 11,
    colourIndex: 22,
    colourType: 'card-full',
    colourHex: '#EAD8CE',
    accessibleName: 'Pastel Brown',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 12,
    colourIndex: 13,
    colourType: 'card-full',
    colourHex: '#FF7C7E',
    accessibleName: 'Light Red',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 13,
    colourIndex: 15,
    colourType: 'card-full',
    colourHex: '#FF7DED',
    accessibleName: 'Light Purple',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 14,
    colourIndex: 23,
    colourType: 'card-full',
    colourHex: '#C486FD',
    accessibleName: 'Light Violette',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 15,
    colourIndex: 24,
    colourType: 'card-full',
    colourHex: '#909FFE',
    accessibleName: 'Light Indigo',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 16,
    colourIndex: 17,
    colourType: 'card-full',
    colourHex: '#60CBFC',
    accessibleName: 'Light Blue',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 17,
    colourIndex: 5, //legacy
    colourType: 'card-full',
    colourHex: '#BCE8DB',
    accessibleName: 'Light Turquoise ',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 18,
    colourIndex: 25,
    colourType: 'card-full',
    colourHex: '#6EDD6C',
    accessibleName: 'Light Green',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 19,
    colourIndex: 26,
    colourType: 'card-full',
    colourHex: '#C4F74E',
    accessibleName: 'Light Lime',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 20,
    colourIndex: 27,
    colourType: 'card-full',
    colourHex: '#FFE500',
    accessibleName: 'Light Yellow',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 21,
    colourIndex: 28,
    colourType: 'card-full',
    colourHex: '#FFAA6D',
    accessibleName: 'Light Orange',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 22,
    colourIndex: 29,
    colourType: 'card-full',
    colourHex: '#C1967B',
    accessibleName: 'Light Brown',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 23,
    colourIndex: 10, //legacy
    colourType: 'card-full',
    colourHex: '#C83F1A',
    accessibleName: 'Medium Red',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 24,
    colourIndex: 9, //legacy
    colourType: 'card-full',
    colourHex: '#945594',
    accessibleName: 'Medium Purple',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 25,
    colourIndex: 32,
    colourType: 'card-full',
    colourHex: '#7E09D7',
    accessibleName: 'Medium Violette',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 26,
    colourIndex: 33,
    colourType: 'card-full',
    colourHex: '#3145D8',
    accessibleName: 'Medium Indigo',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 27,
    colourIndex: 34,
    colourType: 'card-full',
    colourHex: '#0CAAE9',
    accessibleName: 'Medium Blue',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 28,
    colourIndex: 35,
    colourType: 'card-full',
    colourHex: '#00D3D4',
    accessibleName: 'Medium Turquoise ',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 29,
    colourIndex: 36,
    colourType: 'card-full',
    colourHex: '#00C12C',
    accessibleName: 'Medium Green',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 30,
    colourIndex: 37,
    colourType: 'card-full',
    colourHex: '#97CD21',
    accessibleName: 'Medium Lime',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 31,
    colourIndex: 38,
    colourType: 'card-full',
    colourHex: '#E6CF00',
    accessibleName: 'Medium Yellow',
    textHex: darkText,
  },
  {
    displayOrder: 32,
    colourIndex: 39,
    colourType: 'card-full',
    colourHex: '#F38621',
    accessibleName: 'Medium Orange',
    textHex: darkText,
    isDarkCard: false,
  },
  {
    displayOrder: 33,
    colourIndex: 12, //legacy
    colourType: 'card-full',
    colourHex: '#915900',
    accessibleName: 'Medium Brown',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 34,
    colourIndex: 30,
    colourType: 'card-full',
    colourHex: '#7C0104',
    accessibleName: 'Dark Red',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 35,
    colourIndex: 31,
    colourType: 'card-full',
    colourHex: '#781069',
    accessibleName: 'Dark Purple',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 36,
    colourIndex: 40,
    colourType: 'card-full',
    colourHex: '#53128D',
    accessibleName: 'Dark Violette',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 37,
    colourIndex: 41,
    colourType: 'card-full',
    colourHex: '#142397',
    accessibleName: 'Dark Indigo',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 38,
    colourIndex: 8, //legacy
    colourType: 'card-full',
    colourHex: '#1672A0',
    accessibleName: 'Dark Blue',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 39,
    colourIndex: 11, //legacy
    colourType: 'card-full',
    colourHex: '#008761',
    accessibleName: 'Dark Turquoise ',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 40,
    colourIndex: 42,
    colourType: 'card-full',
    colourHex: '#066522',
    accessibleName: 'Dark Green',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 41,
    colourIndex: 43,
    colourType: 'card-full',
    colourHex: '#46610F',
    accessibleName: 'Dark Lime',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 42,
    colourIndex: 44,
    colourType: 'card-full',
    colourHex: '#615700',
    accessibleName: 'Dark Yellow',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 43,
    colourIndex: 45,
    colourType: 'card-full',
    colourHex: '#993800',
    accessibleName: 'Dark Orange',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 44,
    colourIndex: 46,
    colourType: 'card-full',
    colourHex: '#441800',
    accessibleName: 'Dark Brown',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 45,
    colourIndex: 7, //legacy
    colourType: 'card-full',
    colourHex: '#6A6A6A',
    accessibleName: 'Medium Gray',
    textHex: lightText,
    isDarkCard: true,
  },
  {
    displayOrder: 46,
    colourIndex: 1,  //legacy
    colourType: 'card-full',
    colourHex: '#ffffff',
    accessibleName: 'White',
    textHex: darkText,
    isDarkCard: false,
  },
]
