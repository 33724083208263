import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import styles from "./WorkspaceProgressCardSection.module.scss";
import { wsCardType } from "../../../../../state/workspace/myworkspaces/list/types";
import {
  getActiveActivityType,
  getActiveCardId,
} from "../../../../../state/workspace/myworkspaces/list/selectors";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getUserNameFormat } from "../../../../../state/user/selectors";
import { getFormattedName, getDocType } from "../../../../../utils";
import {
  pushQueryToHistory,
  replaceQueryInHistory,
} from "../../../../../state/router/navigation";
import { APP_PAGES } from "../../../../../state/router/types";
import {
  MYWORKSPACES_GRADING_ACTIVITY_TYPES,
  GOOGLE_DOCS_ICON_HOST,
  ARTIFACT_TYPE,
} from "../../../../../state/workspace/grading/types";
import { getMimeType } from "../../../../../state/workspace/myworkspaces/list/actions";
import Reveal, {
  RevealToggle,
  RevealDetails,
} from "@hapara/ui/src/atomic/Reveal/Reveal";

export const WorkspaceProgressCardSection = ({
  card,
  id,
  name,
  nameFormat,
  activityType,
  activeActivityType,
  activeCardId,
  isActive,
  getMimeType,
}) => {
  const isExpanded = activityType === activeActivityType && isActive;
  const totalCount = _.get(
    card,
    `WorkspaceProgressSummary.${id}.TotalCount`,
    0
  );
  const isGroupCard = _.get(card, "IsGroupWork", false);
  const progressList = _.get(
    card,
    `WorkspaceProgressSummary.${id}.StudentsProgress`,
    []
  );

  const getNonExistingMimeTypes = useCallback(() => {
    _.map(progressList, (item) => {
      const artifact = item.Artifact;

      if (
        artifact &&
        !artifact.MimeType &&
        artifact.Type === ARTIFACT_TYPE.GDRIVE
      ) {
        getMimeType(artifact);
      }
    });
  }, [getMimeType, progressList]);

  const mapProgressData = () => {
    const listToDisplay = _.map(progressList, (item) => {
      const embeddableDocType = getDocType(_.get(item, "Artifact"));
      const iconUrl = embeddableDocType
        ? `${GOOGLE_DOCS_ICON_HOST}${embeddableDocType.iconAppendix}`
        : null;

      return {
        id: item.Id,
        artefactId: _.get(item, "Artifact.Id", null),
        name: isGroupCard
          ? _.get(item, "Name.First")
          : getFormattedName(nameFormat, {
              FirstName: _.get(item, "Name.First"),
              LastName: _.get(item, "Name.Last"),
            }),
        iconUrl: iconUrl,
      };
    });
    return _.sortBy(listToDisplay, ["name"]);
  };

  useEffect(() => {
    if (isExpanded) {
      getNonExistingMimeTypes();
    }
  }, [isExpanded, getNonExistingMimeTypes]);

  const handleExpansion = () => {
    if (isExpanded) {
      replaceQueryInHistory({ selected_card: null });
    } else {
      if (activeCardId) {
        replaceQueryInHistory({
          selected_card: card.Id,
          selected_activity: activityType,
        });
      } else {
        pushQueryToHistory({
          selected_card: card.Id,
          selected_activity: activityType,
        });
      }
    }
  };

  return (
    <Reveal
      disabled={!totalCount}
      expanded={isExpanded}
      onChange={handleExpansion}
    >
      <div
        className={classnames(styles.root, {
          [styles.expanded]: isExpanded,
          [styles.disabled]: !totalCount,
        })}
        data-test-id="ws-MyWorkspaces-ProgressPanel-CardSection"
      >
        <RevealToggle
          className={styles.head}
          data-test-id={`ws-MyWorkspaces-ProgressPanel-OpenList-${id}`}
        >
          <div className={styles.title}>{name}</div>
          <div className={styles.amount}>{totalCount}</div>
          <div className={styles.expandIcon}>
            {totalCount > 0 && (
              <HapReactIcon
                svg={isExpanded ? "arrow-angle-up" : "arrow-angle-down"}
                width={18}
                height={18}
                alt=""
              />
            )}
          </div>
        </RevealToggle>
        <RevealDetails>
          <div className={styles.body}>
            {_.map(mapProgressData(), (item) => (
              <div
                className={styles.bodyItem}
                key={item.id}
                data-test-id="ws-MyWorkspaces-ProgressPanel-CardSection-BodyItem"
              >
                {!activityType && (
                  <div className={styles.bodyItemInner}>
                    <span className={styles.bodyItemName}>{item.name}</span>
                  </div>
                )}
                {activityType && (
                  <Link
                    to={{
                      pathname: `${APP_PAGES.WORKSPACE_GRADING.path}/${card.Id}/${activityType}`,
                      search: `?artefact=${item.artefactId}`,
                      state: { from: APP_PAGES.WORKSPACE_MY_WORKSPACE.path },
                    }}
                    className={classnames(styles.bodyItemInner, styles.link)}
                    data-test-id={`ws-MyWorkspaces-ProgressPanel-OpenGradingPage-${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.iconUrl && (
                      <img
                        src={item.iconUrl}
                        width={16}
                        height={16}
                        className={styles.bodyItemIcon}
                        alt=""
                      />
                    )}
                    {!item.iconUrl && (
                      <HapReactIcon svg="link" width={16} height={16} alt="" />
                    )}
                    <span className={styles.bodyItemName}>{item.name}</span>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </RevealDetails>
      </div>
    </Reveal>
  );
};

WorkspaceProgressCardSection.propTypes = {
  card: wsCardType.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activityType: PropTypes.oneOf(_.values(MYWORKSPACES_GRADING_ACTIVITY_TYPES)),
  activeActivityType: PropTypes.oneOf(
    _.values(MYWORKSPACES_GRADING_ACTIVITY_TYPES)
  ),
  activeCardId: PropTypes.string,
  isActive: PropTypes.bool,
  nameFormat: PropTypes.string,
};

export default connect(
  (state) => ({
    activeActivityType: getActiveActivityType(state),
    activeCardId: getActiveCardId(state),
    nameFormat: getUserNameFormat(state),
  }),
  (dispatch) => ({
    getMimeType: (artifact) => dispatch(getMimeType(artifact)),
  })
)(WorkspaceProgressCardSection);
