import React from 'react'
import PropTypes from 'prop-types'
import styles from './ActionDialog.module.scss'
import ActionConfirmationDialog from '@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog'

export const ActionDialog = ({
  actionInProgress,
  title,
  action,
  isOpen,
  close,
  error,
  actionConfig,
  isActionButtonDisabled,
  dataTestPrefix,
  children,
}) => {
  const cancelLabel = actionInProgress ? actionConfig.cancelInProgressLabel || 'Cancel' : 'Cancel'
  const dataTestPrefixProp = dataTestPrefix ? { dataTestPrefix: dataTestPrefix } : {}
  return (
    isOpen && (
      <ActionConfirmationDialog
        actionLabel={
          (!actionInProgress ? actionConfig.actionLabel : actionConfig.actionInProgressLabel) || ''
        }
        cancelLabel={cancelLabel}
        isActionInProgress={actionInProgress}
        onAction={action}
        isOpen={isOpen}
        onClose={close}
        error={error ? actionConfig.errorMessage : ''}
        isActionDanger={Boolean(actionConfig.isActionDanger)}
        isCancelDisabled={actionInProgress && !actionConfig.enableCancelInProgress}
        title={actionConfig.title ? actionConfig.title : ''}
        subTitle={title}
        cancelDataTestId={actionConfig.cancelDataTestId}
        actionDataTestId={actionConfig.actionDataTestId}
        isActionButtonDisabled={isActionButtonDisabled}
        key={actionConfig.title}
        {...dataTestPrefixProp}
      >
        <div className={styles.description}>{actionConfig.description}</div>
        {children && <div className={styles.content}>{children}</div>}
      </ActionConfirmationDialog>
    )
  )
}

ActionDialog.propTypes = {
  title: PropTypes.string,
  actionInProgress: PropTypes.bool,
  action: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isActionButtonDisabled: PropTypes.bool,
  dataTestPrefix: PropTypes.string,
  actionConfig: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.node,
    actionLabel: PropTypes.string,
    actionInProgressLabel: PropTypes.string,
    errorMessage: PropTypes.string,
    isActionDanger: PropTypes.bool,
    enableCancelInProgress: PropTypes.bool,
    cancelInProgressLabel: PropTypes.string,
    cancelDataTestId: PropTypes.string,
    actionDataTestId: PropTypes.string,
  }),
}

export default ActionDialog
