import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./Pagination.module.scss";
import HapReactIcon from "../icon/hapReactIcon";
import { MOBILE_VIEW_BREAKPOINT } from "../utils";

export const PAGINATION_TYPE = {
  WITHOUT_ELLIPSIS: "without_ellipsis",
  WITH_ELLIPSIS: "with_ellipsis",
};

const Pagination = ({
  pages = 0,
  currentPage = 1,
  onSelectPage = () => {},
  dataTestIdPrefix = "Hs-Pagination",
  showTotalPages = true,
  tabIndex,
  type = PAGINATION_TYPE.WITH_ELLIPSIS,
}) => {
  const intl = useIntl();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scroll(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (pages < 2) {
    return null;
  }

  const pageButton = (page) => {
    return (
      <button
        type="button"
        onClick={() => onSelectPage(page + 1)}
        key={page}
        className={
          page === currentPage - 1
            ? `${styles.circle} ${styles.page} ${styles.activePage}`
            : `${styles.circle} ${styles.page}`
        }
        data-test-id={dataTestIdPrefix + "-Button-PageNumber"}
        aria-label={intl.formatMessage(
          {
            defaultMessage: "Page {pageNumber}",
            id: "f/HiG6",
          },
          { pageNumber: page + 1 }
        )}
        tabIndex={tabIndex}
      >
        {page + 1}
      </button>
    );
  };
  const moreThanTenPages = () => {
    return (
      <React.Fragment>
        {currentPage >= 5 && (
          <React.Fragment>
            <button
              type="button"
              className={`${styles.circle} ${styles.page}`}
              onClick={() => onSelectPage(1)}
              data-test-id={dataTestIdPrefix + "-Button-PageNumber"}
              tabIndex={tabIndex}
            >
              {1}
            </button>

            <button
              className={styles.circle}
              aria-label={intl.formatMessage({
                defaultMessage: "more pages",
                id: "do2qzv",
              })}
            >
              <HapReactIcon svg="ellipsis" width={16} height={16} />
            </button>
          </React.Fragment>
        )}
        {currentPage < 5 && _.range(0, 7).map(pageButton)}
        {currentPage >= 5 &&
          currentPage < pages - 5 &&
          _.range(currentPage - 2, currentPage + 3).map(pageButton)}
        {currentPage >= pages - 5 && _.range(pages - 7, pages).map(pageButton)}
        {currentPage < pages - 5 && (
          <React.Fragment>
            <button
              className={styles.circle}
              aria-label={intl.formatMessage({
                defaultMessage: "more pages",
                id: "do2qzv",
              })}
            >
              <HapReactIcon svg="ellipsis" width={16} height={16} />
            </button>

            {showTotalPages && (
              <button
                type="button"
                onClick={() => onSelectPage(pages)}
                className={`${styles.circle} ${styles.page}`}
                data-test-id={dataTestIdPrefix + "-Button-PageNumber"}
                tabIndex={tabIndex}
              >
                {pages}
              </button>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const moreThanTenPagesNoEllipsis = () => {
    const pageFrom = currentPage - 5 > 0 ? currentPage - 5 : 0;
    return <>{_.range(pageFrom, pageFrom + 10).map(pageButton)}</>;
  };

  const mobileView = () => {
    let start = Math.max(0, currentPage - 2);
    const end = Math.min(start + 5, pages);
    if (end - start === 5 && currentPage !== 0 && currentPage !== pages - 1) {
      start = start + 1;
    }
    return (
      <React.Fragment>{_.range(start, end).map(pageButton)}</React.Fragment>
    );
  };
  const desktopView = () => {
    return (
      <React.Fragment>
        {pages < 10 && _.range(0, pages).map(pageButton)}
        {pages >= 10 &&
          type === PAGINATION_TYPE.WITH_ELLIPSIS &&
          moreThanTenPages()}
        {pages >= 10 &&
          type === PAGINATION_TYPE.WITHOUT_ELLIPSIS &&
          moreThanTenPagesNoEllipsis()}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.root}>
      <button
        type="button"
        className={`${styles.circle} ${styles.page}`}
        onClick={() => onSelectPage(currentPage - 1)}
        data-test-id={dataTestIdPrefix + "-Button-Prev"}
        aria-label={intl.formatMessage({
          defaultMessage: "Previous page",
          id: "k9hDFZ",
        })}
        tabIndex={tabIndex}
        disabled={currentPage === 1}
      >
        <HapReactIcon
          svg="arrow-left"
          width={16}
          height={16}
          alt={intl.formatMessage({
            defaultMessage: "Previous",
            id: "JJNc3c",
          })}
        />
      </button>

      {width < MOBILE_VIEW_BREAKPOINT && mobileView()}

      {width >= MOBILE_VIEW_BREAKPOINT && desktopView()}

      <button
        type="button"
        className={`${styles.circle} ${styles.page}`}
        onClick={() => onSelectPage(currentPage + 1)}
        data-test-id={dataTestIdPrefix + "-Button-Next"}
        aria-label={intl.formatMessage({
          defaultMessage: "Next page",
          id: "rBj9Ib",
        })}
        tabIndex={tabIndex}
        disabled={currentPage === pages}
      >
        <HapReactIcon
          svg="arrow-right"
          width={16}
          height={16}
          alt={intl.formatMessage({
            defaultMessage: "Next",
            id: "9+Ddtu",
          })}
        />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  dataTestIdPrefix: PropTypes.string,
  showTotalPages: PropTypes.bool,
  onSelectPage: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
};

export default Pagination;
