import PropTypes from "prop-types";

export const DISCOVER_RIGHT_PANEL_ID = "lb-discover-right-panel";

export const LANDING_PAGE_SHORTCUT_TYPE = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  BackgroundImage: PropTypes.string,
  Filter: PropTypes.string.isRequired,
  DataTestPrefix: PropTypes.string.isRequired,
  Icon: PropTypes.string,
  BackgroundColor: PropTypes.string,
  AltText: PropTypes.string,
});

export const EXPLORE_SUBJECTS_SHORTCUTS = [
  {
    Id: "1",
    Title: "English and\nLanguage Arts",
    BackgroundImage: "image-english",
    Filter: "LANG_ARTS",
    DataTestPrefix: "english",
  },
  {
    Id: "2",
    Title: "Social Studies",
    BackgroundImage: "image-social-studies",
    Filter: "SOCIAL",
    DataTestPrefix: "social-studies",
  },
  {
    Id: "3",
    Title: "Science",
    BackgroundImage: "image-science",
    Filter: "SCI",
    DataTestPrefix: "science",
  },
  {
    Id: "4",
    Title: "Mathematics",
    BackgroundImage: "image-math",
    Filter: "MATH",
    DataTestPrefix: "math",
  },
];

export const FIND_STANDARDS_SHORTCUTS = [
  {
    Id: "1",
    Title: "CCSS",
    BackgroundImage: "image-books-open",
    Filter: "CCSS",
    DataTestPrefix: "ccss",
  },
  {
    Id: "2",
    Title: "NGSS",
    BackgroundImage: "image-books-on-shelf",
    Filter: "NGSS",
    DataTestPrefix: "ngss",
  },
  {
    Id: "3",
    Title: "New York State Standards",
    BackgroundImage: "image-books-open",
    Filter: "NY",
    DataTestPrefix: "ny",
  },
  {
    Id: "4",
    Title: "Ohio",
    BackgroundImage: "image-books-on-shelf",
    Filter: "OH",
    DataTestPrefix: "oh",
  },
  {
    Id: "5",
    Title: "Texas Essential\nKnowledge and Skills",
    BackgroundImage: "image-books-open",
    Filter: "TEKS",
    DataTestPrefix: "teks",
  },
  {
    Id: "6",
    Title: "Alberta Program of\nStudies",
    BackgroundImage: "image-books-on-shelf",
    Filter: "ALB",
    DataTestPrefix: "alb",
  },
  {
    Id: "7",
    Title: "British Columbia",
    BackgroundImage: "image-books-open",
    Filter: "BC",
    DataTestPrefix: "bc",
  },
  {
    Id: "8",
    Title: "Ontario Curriculum",
    BackgroundImage: "image-books-on-shelf",
    Filter: "ONT",
    DataTestPrefix: "ont",
  },
  {
    Id: "9",
    Title: "Saskatchewan",
    BackgroundImage: "image-books-open",
    Filter: "SA",
    DataTestPrefix: "sa",
  },
  {
    Id: "10",
    Title: "Australian\nCurriculum",
    BackgroundImage: "image-books-on-shelf",
    Filter: "AUS",
    DataTestPrefix: "au",
  },
  {
    Id: "11",
    Title: "New Zealand\nCurriculum",
    BackgroundImage: "image-books-open",
    Filter: "NZ",
    DataTestPrefix: "nz",
  },
];

export const EXPLORE_BY_TYPE_SHORTCUTS = [
  {
    Id: "1",
    Title: "Literary fiction",
    Filter: "R_LIT",
    Icon: "hand-holding-pen",
    BackgroundColor: "Violet400",
    DataTestPrefix: "literary_fiction",
  },
  {
    Id: "2",
    Title: "Informational text",
    Filter: "R_INFO",
    Icon: "open-book",
    BackgroundColor: "Blue500",
    DataTestPrefix: "informational_text",
  },
  {
    Id: "3",
    Title: "Classroom resource",
    Filter: "A_CR",
    Icon: "school-bag",
    BackgroundColor: "Orange400",
    DataTestPrefix: "classroom_resource",
  },
  {
    Id: "4",
    Title: "Textbooks",
    Filter: "R_TEXT",
    Icon: "books-stack",
    BackgroundColor: "CategoryGreen",
    DataTestPrefix: "textbooks",
    AltText: "Stack of four books",
  },
  {
    Id: "5",
    Title: "Teacher resource",
    Filter: "T_TR",
    Icon: "teacher",
    BackgroundColor: "CategoryMagenta",
    DataTestPrefix: "teacher_resource",
  },
];
