import actionTypes from "../../actionTypes";
import _ from "lodash";

export const INIT_STATE = {
  isInitLoading: false,
  isInitLoaded: false,
  isCreateLoading: false,
  isCreateLoaded: false,
  isCreateError: false,
  init: {},
};

const createReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE_WORKSPACE_INIT_PENDING:
      return { ...state, isInitLoading: true, isInitLoaded: false };

    case actionTypes.CREATE_WORKSPACE_INIT_ERROR:
      return { ...state, isInitLoading: false, isInitLoaded: true };

    case actionTypes.CREATE_WORKSPACE_INIT_SUCCESS:
      return {
        ...state,
        isInitLoading: false,
        isInitLoaded: true,
        init: _.get(action, "payload", {}),
      };

    case actionTypes.CREATE_WORKSPACE_PENDING:
      return {
        ...state,
        isCreateLoading: true,
        isCreateLoaded: false,
        isCreateError: false,
      };

    case actionTypes.CREATE_WORKSPACE_ERROR:
      return {
        ...state,
        isCreateLoading: false,
        isCreateLoaded: true,
        isCreateError: true,
      };

    case actionTypes.CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        isCreateLoaded: true,
        isCreateError: false,
      };

    case actionTypes.CREATE_WORKSPACE_CLEAR:
      return {
        ...INIT_STATE,
      };

    default:
      return state;
  }
};

export default createReducer;
