import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { SNAPSHOT_TYPE } from "../../../../state/highlights/snaps/types";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import styles from "./SnapTile.module.scss";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import Tippy from "@tippy.js/react";
import { getFormattedName } from "../../../../utils";
import { deleteSnapshot } from "../../../../state/highlights/snaps/actions";
import {
  ACTIVITY_TYPES,
  NO_SCREENSHOT_AVAILABLE,
} from "../../../../state/highlights/snaps/types";
import { isRestrictedScreenshotUrl } from "@hapara/ui/src/components/utils";
import noSnapsImage from "@hapara/assets/src/images/vector-hl-no-snaps.svg";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { IntlDateTimeLocal } from "@hapara/ui/src/intl/dateTime";

const SnapTile = ({
  snapshot,
  students,
  classId,
  studentNameFormat,
  deleteSnapshot,
  showModal,
}) => {
  const intl = useIntl();
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const deleteViewRef = useRef();

  useEffect(() => {
    if (isDeleteDialog) {
      setIsDeleteInProgress(false);
    }
  }, [isDeleteDialog]);

  const screenshotUrl = snapshot.Students[0].Screenshot.Image;
  const screenshotAvailable = screenshotUrl !== NO_SCREENSHOT_AVAILABLE;

  const studentNames = [];
  students.forEach((s) => {
    studentNames[s.Email] = getFormattedName(studentNameFormat, s);
  });
  const tippyContent = [];
  if (snapshot.ActivityType !== ACTIVITY_TYPES.UNIQUE_ACTIVITY) {
    snapshot.Students.forEach((s) => {
      tippyContent.push(studentNames[s.StudentEmail]);
    });
  }

  const activityMessage =
    snapshot.ActivityType === ACTIVITY_TYPES.UNIQUE_ACTIVITY
      ? intl.formatMessage({ defaultMessage: "Unique Activity:", id: "bf8Naj" })
      : snapshot.ActivityType === ACTIVITY_TYPES.CLASS_ACTIVITY
      ? intl.formatMessage({ defaultMessage: "Class Activity:", id: "5epydq" })
      : intl.formatMessage({ defaultMessage: "Collaboration:", id: "8lVFB1" });

  return (
    <div className={styles.root} data-test-id="hl-SnapTile">
      {!isDeleteDialog && (
        <>
          <h2 className={styles.title} data-test-id="hl-SnapTile-DateTitle">
            <IntlDateTimeLocal
              timestamp={`${snapshot.DateTitle}`}
              format="longDate"
            />
          </h2>
          <div className={styles.siteUrl}>
            <ExternalLink
              url={snapshot.SiteUrl}
              icon={snapshot.IconUrl}
              studentEmail={snapshot.Students[0].StudentEmail}
              classId={classId}
              dataTestId="hl-SnapTile-SiteUrl-Link"
            />
          </div>
          <div className={styles.screenshot}>
            <button
              className={styles.screenshotButton}
              aria-label={intl.formatMessage({
                defaultMessage: "Display snapshot details",
                id: "NYqbyJ",
              })}
              onClick={() => showModal(snapshot)}
              data-test-id="hl-SnapTile-ShowDetailsModal"
            >
              {screenshotAvailable &&
                !isRestrictedScreenshotUrl(snapshot.SiteUrl) && (
                  <img
                    src={screenshotUrl}
                    alt={snapshot.Name}
                    className={styles.image}
                  />
                )}
              {(!screenshotAvailable ||
                isRestrictedScreenshotUrl(snapshot.SiteUrl)) && (
                <div className={styles.noScreenshot}>
                  <img src={noSnapsImage} alt="" width={123} height={78} />
                  <p>
                    <FormattedMessage
                      defaultMessage="No screenshot available"
                      id="JA2gFS"
                    />
                  </p>
                </div>
              )}
            </button>
          </div>
          <div className={styles.bottom}>
            <div className={styles.activityType}>
              <span className={styles.activityName}>{activityMessage}</span>
              {snapshot.ActivityType === ACTIVITY_TYPES.UNIQUE_ACTIVITY && (
                <span className={styles.studentName}>
                  {studentNames[snapshot.Students[0].StudentEmail]}
                </span>
              )}
              {snapshot.ActivityType !== ACTIVITY_TYPES.UNIQUE_ACTIVITY && (
                <Tippy
                  content={
                    <>
                      {tippyContent.map((name) => {
                        return (
                          <span key={name}>
                            {name}
                            <br />
                          </span>
                        );
                      })}
                    </>
                  }
                  theme="snaps"
                  maxWidth={162}
                >
                  <span className={styles.studentName} role="note">
                    <FormattedMessage
                      defaultMessage="{count, plural, one {1 Learner} other {# Learners}}"
                      id="8kYhKC"
                      values={{ count: snapshot.Students.length }}
                    />
                  </span>
                </Tippy>
              )}
            </div>
            <Button
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              onAction={() => {
                setIsDeleteDialog(true);
                _.delay(() => deleteViewRef.current.focus(), 200);
              }}
              icon="delete"
              dataTestId="hl-SnapTile-DeleteButton"
              aria-label={intl.formatMessage({
                defaultMessage: "Delete snapshot",
                id: "kp5kSP",
              })}
            />
          </div>
        </>
      )}
      {isDeleteDialog && (
        <div className={styles.deleteConfirmation}>
          <Button
            icon="cross"
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            onAction={() => {
              setIsDeleteDialog(false);
            }}
            dataTestId="hl-SnapTile-DeleteConfirmation-Close"
            alt="Close"
            className={styles.close}
          />
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Delete this snap?" id="ve6g2J" />
          </div>
          <div className={styles.actions}>
            <Button
              type={BUTTON_TYPES.SECONDARY}
              size={BUTTON_SIZES.REGULAR}
              data-test-id="hl-SnapTile-DeleteConfirmation-Cancel"
              onClick={() => {
                setIsDeleteDialog(false);
              }}
              label={intl.formatMessage({
                defaultMessage: "Cancel",
                id: "47FYwb",
              })}
              aria-label={intl.formatMessage({
                defaultMessage: "Cancel",
                id: "47FYwb",
              })}
              className={styles.cancelButton}
              ref={deleteViewRef}
            />
            <Button
              type={BUTTON_TYPES.DANGER}
              size={BUTTON_SIZES.REGULAR}
              dataTestId="hl-SnapTile-DeleteConfirmation-Delete"
              onClick={() => {
                setIsDeleteInProgress(true);
                deleteSnapshot(snapshot.ID, classId);
              }}
              label={intl.formatMessage({
                defaultMessage: "Delete",
                id: "K3r6DQ",
              })}
              aria-label={intl.formatMessage({
                defaultMessage: "Delete",
                id: "K3r6DQ",
              })}
              className={styles.deleteButton}
              isLoading={isDeleteInProgress}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SnapTile.propTypes = {
  snapshot: SNAPSHOT_TYPE,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  classId: PropTypes.string.isRequired,
  studentNameFormat: PropTypes.string.isRequired,
  deleteSnapshot: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    students: getStudentConfigsList(state),
    classId: getClassId(state),
    studentNameFormat: getUserNameFormat(state),
  }),
  (dispatch) => ({
    deleteSnapshot: (snapId, classId) =>
      dispatch(deleteSnapshot(snapId, classId)),
  })
)(SnapTile);
