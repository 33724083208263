import _ from "lodash";
import { createSelector } from "reselect";
import store from "../../store";

export const selectStudentRequests = (): any =>
  _.get(store.getState(), "highlights.studentRequests");

export const getStudentRequests = createSelector(
  selectStudentRequests,
  (studentRequestsInfo) => _.get(studentRequestsInfo, "requests")
);

export const getActionedStudentRequests = createSelector(
  selectStudentRequests,
  (studentRequestsInfo) => _.get(studentRequestsInfo, "actionedRequests")
);
