import styles from "./ClassInfoHeaderDropDownItem.module.scss";
import classnames from "classnames";

interface ClassInfoHeaderDropDownItemProps {
  id: string;
  onClick?: () => void;
  href?: string;
  openInNewTab?: boolean;
  isDangerous?: boolean;
  dataTestIdPrefix: string;
  label: string;
}

export const ClassInfoHeaderDropDownItem = ({
  id,
  onClick,
  isDangerous,
  dataTestIdPrefix,
  label,
  href,
}: ClassInfoHeaderDropDownItemProps) => {
  const Tag = onClick ? "button" : "a";

  return (
    <Tag
      onClick={onClick}
      href={href}
      target={href && "_blank"}
      className={classnames(styles.dropdownContentItem, {
        [styles.danger]: isDangerous,
      })}
      data-test-id={`${dataTestIdPrefix}-dropMenuItem-${id}`}
      aria-label={label}
      type="button"
    >
      {label}
    </Tag>
  );
};
