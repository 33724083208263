import { useInjectScript } from "../useInjectScript";
import { getGoogleClientId } from "../../utils/getGoogleClientId";
import { useLocalStorage } from "../useLocalStorage";
import { verifyGoogleAccessToken } from "./utils";
import { useState } from "react";

export type GoogleAuthScope =
  | "https://www.googleapis.com/auth/drive.file"
  | "https://www.googleapis.com/auth/admin.directory.user.readonly";

export const sensitiveScopes: GoogleAuthScope[] = [
  "https://www.googleapis.com/auth/admin.directory.user.readonly",
];

export const hasSensitiveScopes = (scopes: GoogleAuthScope[]): boolean => {
  return scopes.some((scope) => sensitiveScopes.includes(scope));
};

export const useGoogleAuth = (scopes: GoogleAuthScope[]) => {
  const [accessToken, setAccessToken] = useLocalStorage<string>("gis");
  const [accessTokenExpiresAt, setAccessTokenExpiresAt] =
    useLocalStorage<number>("gis-expiry");
  const [sensitiveAccessToken, setSensitiveAccessToken] = useState<
    string | null
  >(null);
  const [sensitiveAccessTokenExpiresAt, setSensitiveAccessTokenExpiresAt] =
    useState<number | null>(null);
  const { isLoaded } = useInjectScript(
    "https://accounts.google.com/gsi/client"
  );

  const handleTokenResponse = async ({
    access_token,
    expires_in, // 3599 (~1 hour) returned from `initTokenClient` callback
  }: google.accounts.oauth2.TokenResponse) => {
    const expiresAt = parseInt(expires_in) * 1000 + Date.now();
    if (hasSensitiveScopes(scopes)) {
      setSensitiveAccessToken(access_token);
      setSensitiveAccessTokenExpiresAt(expiresAt);
    } else {
      setAccessToken(access_token);
      setAccessTokenExpiresAt(expiresAt);
    }
  };

  const requestAccessToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const getToken = () => {
        const tokenRenewalLeadTime = 5 * 60_000;
        const currentToken = hasSensitiveScopes(scopes)
          ? sensitiveAccessToken
          : accessToken;
        const currentExpiresAt = hasSensitiveScopes(scopes)
          ? sensitiveAccessTokenExpiresAt
          : accessTokenExpiresAt;
        const tokenExpiresIn = currentExpiresAt
          ? currentExpiresAt - Date.now()
          : 0;

        if (currentToken === null || tokenExpiresIn < tokenRenewalLeadTime) {
          const tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: getGoogleClientId(),
            scope: scopes.join(" "),
            callback: (response) => {
              handleTokenResponse(response);
              resolve(response.access_token);
            },
            error_callback: (error) => {
              reject(
                new Error(
                  "[useGoogleAuth] could not initialize token client",
                  error
                )
              );
            },
          });
          tokenClient.requestAccessToken();
        } else {
          verifyGoogleAccessToken(currentToken)
            .then(() => resolve(currentToken))
            .catch(() => {
              // TODO: rather try `tokenClient.requestAccessToken()` again?
              if (hasSensitiveScopes(scopes)) {
                setSensitiveAccessToken(null);
                setSensitiveAccessTokenExpiresAt(null);
              } else {
                setAccessToken(null);
                setAccessTokenExpiresAt(null);
              }
              reject(new Error("Failed to verify token"));
            });
        }
      };

      if (isLoaded) {
        getToken();
      } else {
        // The consumer of `requestAccessToken` shouldn't need to check if the
        // GIS script is loaded. If the script isn't loaded yet, we'll keep
        // checking until it is, then call `getToken`.
        // TODO: add a timeout if `isLoaded` never resolves.
        const scriptLoadedInterval = setInterval(() => {
          if (isLoaded) {
            clearInterval(scriptLoadedInterval);
            getToken();
          }
        }, 100);
      }
    });
  };

  return { requestAccessToken };
};
