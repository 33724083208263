import React from "react";
import styles from "./TableContent.module.scss";
import classnames from "classnames";

type TableContentProps = {
  className?: string;
  children?: React.ReactNode;
  dataTestIdPrefix?: string;
};

export const TableContent = ({
  className,
  children,
  dataTestIdPrefix,
}: TableContentProps) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-TableContent`
    : undefined;
  return (
    <div
      className={classnames(className, styles.tableContent)}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
};
