import { ClassDataSourceTypes } from "../../../containers/Dashboard/DashboardPage/types";
import { APP_PAGES } from "../../../../state/router/types";
import _ from "lodash";

export const formatDate = (date: string) => {
  const dateObj = new Date(date);
  const today = new Date();
  if (
    dateObj.getDate() === today.getDate() &&
    dateObj.getMonth() === today.getMonth() &&
    dateObj.getFullYear() === today.getFullYear()
  ) {
    return dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  return dateObj.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const shouldShowFolderOption = (
  classDataSource: ClassDataSourceTypes,
  folderList: string[] = []
) => {
  const isAutoDocumentsFolder = folderList[0] === "Documents";
  const isEdublogsPage = location.pathname.includes(
    `${APP_PAGES.DASHBOARD_EDUBLOGS.path}`
  );

  if (isEdublogsPage) return false;

  if (classDataSource === "Dataloaded") {
    return folderList.length > 1 || !isAutoDocumentsFolder;
  }

  if (classDataSource === "GoogleClassroom") {
    return true;
  }

  return false;
};
