import { defineMessages, MessageDescriptor } from "react-intl";

interface stringIndex {
  [key: string]: MessageDescriptor;
}

//CONSTANTS: GClassroomLoadMoreItem
export const LOAD_MORE_DESCRIPTORS: stringIndex = defineMessages({
  BUTTON: {
    id: "00LcfG",
    defaultMessage: "Load more",
  },
  BUTTON_ALT: {
    id: "utiUxo",
    defaultMessage: "Load more items",
  },
  NO_ITEMS: {
    id: "OWid66",
    defaultMessage: "Load more to display items for this filter.",
  },
  ALL_ITEMS_LOADED: {
    id: "88iNty",
    defaultMessage: "No more classwork to load.",
  },
});

//CONSTANTS: GClassroomFilter
export const FILTER_TYPE = {
  ALL: "ALL",
  ASSIGNMENT: "ASSIGNMENT",
  SHORT_ANSWER_QUESTION: "SHORT_ANSWER_QUESTION",
  MATERIALS: "MATERIALS",
};
export const FILTER_DESCRIPTORS = defineMessages({
  LABEL: {
    id: "aJ+fy5",
    defaultMessage: "Classroom filters:",
  },
  ALL: {
    id: "zQvVDJ",
    defaultMessage: "All",
  },
  ASSIGNMENTS: {
    id: "oBtgwy",
    defaultMessage: "Assignments",
  },
  QUESTIONS: {
    id: "KV/9Hv",
    defaultMessage: "Questions",
  },
  MATERIALS: {
    id: "0dI1MW",
    defaultMessage: "Materials",
  },
});

//CONSTANTS: GClassroomSharingModal
export const MODAL_DESCRIPTORS = defineMessages({
  LINK: {
    id: "JBWS0c",
    defaultMessage: "Link",
  },
  CREATED: {
    id: "QYYHH3",
    defaultMessage: "Posted",
  },
  REFRESH: {
    id: "rELDbB",
    defaultMessage: "Refresh",
  },
  CLASSWORK: {
    id: "lv9wZa",
    defaultMessage: "Classwork",
  },
  ASSIGNED: {
    id: "ONVN5F",
    defaultMessage: "Assigned to",
  },
  TITLE: {
    id: "MN+hG1",
    defaultMessage: "Select classwork from Google Classroom",
  },
  NO_COURSEWORK: {
    id: "xlSTl0",
    defaultMessage: "No classwork found. Assigned classwork will appear here.",
  },
  ERROR: {
    id: "Qxrw/N",
    defaultMessage:
      "Trouble loading classwork. Please check your connection and try again.",
  },
});
