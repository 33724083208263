import { useEffect, useState } from "react";
import {
  useChatStateChangeListener,
  requestChatStateEvent,
} from "./chatEventEmitter";
import { ChatState } from "./types/state";
import { initialState } from "./useChatReducer";

export const useChat = () => {
  const [chatState, setChatState] = useState<ChatState>(initialState);

  const {
    userId,
    connectionStatus,
    channels,
    totalUnread,
    contacts,
    selectedChannelId,
    featureFlags,
    classes,
    selectedClassId,
    isSchoolHours,
    displayNameFormat,
  } = chatState;

  useChatStateChangeListener(setChatState);
  useEffect(requestChatStateEvent, []);

  return {
    userId,
    contacts,
    connectionStatus,
    channels,
    totalUnread,
    selectedChannelId,
    selectedClassId,
    featureFlags,
    classes,
    isSchoolHours,
    displayNameFormat,
  };
};
