import _ from "lodash";
import { createSelector } from "reselect";

export const getStudentConfigs = (state) =>
  _.get(state, "highlights.studentConfigs");

export const getStudentConfigsList = createSelector(
  getStudentConfigs,
  (students) => {
    return _.isEmpty(students) ? [] : _.values(students);
  }
);

export const getStudentsIdList = createSelector(
  getStudentConfigsList,
  (students) => _.map(students, (student) => student.Id)
);

export const getStudentEmailsByIds = (studentIDs) =>
  createSelector(getStudentConfigsList, (students) => {
    const requiredStudents = _.filter(
      students,
      (student) => studentIDs.indexOf(student.Id) >= 0
    );
    return _.map(requiredStudents, (student) => student.Email);
  });

export const getStudentIdsByEmails = (studentEmails) =>
  createSelector(getStudentConfigsList, (students) => {
    const requiredStudents = _.filter(
      students,
      (student) => studentEmails.indexOf(student.Email) >= 0
    );
    return _.map(requiredStudents, (student) => student.Id);
  });
