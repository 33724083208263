import actionTypes from "../../actionTypes";
import _ from "lodash";

export const INIT_STATE = {
  actionError: null,
  actionInProgress: false,
  artefactNotValid: false,
  assigneeCausedAction: { assigneeName: "", mode: "", id: "" },
  assigneeData: null,
  assigneeDataLoading: false,
  autoSavingError: false,
  autoSavingInProgress: false,
  card: null,
  cardDataLoading: true,
  cardNotValid: false,
  historyUpdateError: null,
  historyUpdateInProgress: false,
  isAssigneeDataInitiallyLoading: true,
  selectedActivity: null,
  selectedCardId: null,
  selectedAssigneeId: null,
  bulkActionInitInProgress: false,
  bulkActionInitError: false,
  bulkReturnActionInProgress: false,
  bulkReturnActionError: false,
  bulkSubmitActionInProgress: false,
  bulkSubmitActionError: false,
};

const updateActiveArtifact = (assigneeData, artifactId, mimeType) => {
  if (assigneeData && artifactId && mimeType) {
    if (assigneeData.Artifact.Id === artifactId) {
      assigneeData.Artifact.MimeType = mimeType;
    }
  }
  return assigneeData;
};

const isArtefactNotValid = (state) => {
  const assignees = _.get(state, "card.Assignees", []);
  const lastStudentCausedActionId = _.get(state, "assigneeCausedAction.id", "");

  return !(
    assignees.length === 0 ||
    (assignees.length === 1 &&
      _.find(assignees, { Id: lastStudentCausedActionId }))
  );
};

const gradingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.URL_PARAM_UPDATE_GRADING_CARD_ID:
      return { ...state, selectedCardId: action.param };

    case actionTypes.URL_PARAM_UPDATE_GRADING_ACTIVITY:
      return { ...state, selectedActivity: action.param };

    case actionTypes.URL_PARAM_UPDATE_ARTEFACT:
      return {
        ...state,
        assigneeData: null,
        assigneeDataLoading: false,
      };

    case actionTypes.GRADING_CARD_LOAD_PENDING:
      return {
        ...state,
        cardDataLoading: true,
        cardNotValid: false,
        artefactNotValid: false,
      };

    case actionTypes.GRADING_CARD_LOAD_ERROR:
      return { ...state, card: {}, cardDataLoading: false };

    case actionTypes.GRADING_CARD_NOT_VALID:
      return {
        ...state,
        cardNotValid: true,
        assigneeDataLoading: false,
        cardDataLoading: false,
      };

    case actionTypes.GRADING_ARTEFACT_NOT_VALID:
      return {
        ...state,
        artefactNotValid: true,
        assigneeDataLoading: false,
        cardDataLoading: false,
      };

    case actionTypes.GRADING_ACTIVITY_DONE:
      return {
        ...state,
        artefactNotValid: isArtefactNotValid(state),
        assigneeDataLoading: false,
        cardDataLoading: false,
        assigneeData: {},
      };

    case actionTypes.GRADING_CARD_LOAD_SUCCESS:
      return {
        ...state,
        card: action.card,
        cardDataLoading: false,
      };

    case actionTypes.GRADING_ASSIGNEE_LOAD_PENDING:
      return { ...state, assigneeDataLoading: true };

    case actionTypes.GRADING_ASSIGNEE_LOAD_SUCCESS:
      return {
        ...state,
        selectedAssigneeId: action.assigneeData.Id,
        assigneeData: action.assigneeData,
        assigneeDataLoading: action.requiresExtraAction,
        isAssigneeDataInitiallyLoading: false,
      };

    case actionTypes.GRADING_ACTION_IN_PROGRESS:
      return {
        ...state,
        actionError: null,
        actionInProgress: action.value,
      };

    case actionTypes.GRADING_ACTION_SUCCESS:
      return {
        ...state,
        actionError: false,
        actionInProgress: false,
      };

    case actionTypes.GRADING_ACTION_ERROR:
      return {
        ...state,
        actionError: true,
        actionInProgress: false,
      };

    case actionTypes.GRADING_RESET_ACTION_ERROR:
      return {
        ...state,
        actionError: null,
        assigneeCausedAction: { assigneeName: "", mode: "", id: "" },
      };

    case actionTypes.GRADING_UPDATE_CAUSED_ACTION:
      return {
        ...state,
        assigneeCausedAction: {
          assigneeName: action.assigneeName,
          mode: action.mode,
          id: action.assigneeId,
        },
      };

    case actionTypes.GRADING_HISTORY_UPDATE_IN_PROGRESS:
      return {
        ...state,
        historyUpdateInProgress: true,
        historyUpdateError: null,
      };

    case actionTypes.GRADING_HISTORY_UPDATE_SUCCESS:
      return {
        ...state,
        historyUpdateInProgress: false,
        historyUpdateError: false,
        assigneeData: {
          ...state.assigneeData,
          ActivityHistory: action.payload,
        },
      };

    case actionTypes.GRADING_HISTORY_UPDATE_ERROR:
      return {
        ...state,
        historyUpdateInProgress: false,
        historyUpdateError: true,
      };

    case actionTypes.GRADING_RESET_HISTORY_UPDATE_ERROR:
      return { ...state, historyUpdateError: null };

    case actionTypes.MYWORKSPACES_ARTIFACT_UPDATE_MIMETYPE:
      const assigneeData = updateActiveArtifact(
        _.cloneDeep(state.assigneeData),
        action.artifactId,
        action.mimeType
      );

      return { ...state, assigneeData, assigneeDataLoading: false };

    case actionTypes.GRADING_AUTO_SAVING_IN_PROGRESS:
      return {
        ...state,
        autoSavingError: false,
        autoSavingInProgress: action.value,
      };

    case actionTypes.GRADING_AUTO_SAVING_ERROR:
      return { ...state, autoSavingError: true, autoSavingInProgress: false };

    case actionTypes.GRADING_BULK_ACTION_INIT_IN_PROGRESS:
      return { ...state, bulkActionInitInProgress: action.value };

    case actionTypes.GRADING_BULK_ACTION_INIT_ERROR:
      return { ...state, bulkActionInitError: action.value };

    case actionTypes.GRADING_BULK_RETURN_ACTION_IN_PROGRESS:
      return { ...state, bulkReturnActionInProgress: action.value };

    case actionTypes.GRADING_BULK_RETURN_ACTION_ERROR:
      return { ...state, bulkReturnActionError: action.value };

    case actionTypes.GRADING_BULK_SUBMIT_ACTION_IN_PROGRESS:
      return { ...state, bulkSubmitActionInProgress: action.value };

    case actionTypes.GRADING_BULK_SUBMIT_ACTION_ERROR:
      return { ...state, bulkSubmitActionError: action.value };

    default:
      return state;
  }
};

export default gradingReducer;
