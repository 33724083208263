import PropTypes from "prop-types";
import _ from "lodash";
import { getHostname } from "../../../utils";

export const SESSION_TYPES = {
  FILTER: "filter",
  FOCUS: "focus",
  LOCK: "lock",
  SHARE_LINKS: "sharelinks",
  PAUSE: "pausescreen",
};

export const SESSION_RECIPIENT_TYPES = {
  STUDENTS: "students",
  GROUPS: "groups",
  CLASS: "class",
};

export const GUIDE_BROWSING_FOCUS_TYPES = {
  WHOLE_SITE: "whole_site",
  LINKS: "links",
};

export const GUIDE_BROWSING_LINKS_LIMIT_BY_SESSION_TYPE = {
  [SESSION_TYPES.FOCUS]: 10,
  [SESSION_TYPES.FILTER]: 50,
  [SESSION_TYPES.SHARE_LINKS]: 10,
};

const RECIPIENT_TYPE = PropTypes.shape({
  Type: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES)),
  Class: PropTypes.string,
  Students: PropTypes.arrayOf(PropTypes.string),
  Groups: PropTypes.arrayOf(PropTypes.string),
});

export const SHARE_LINKS_SESSION_TYPE = PropTypes.shape({
  SessionID: PropTypes.string,
  Links: PropTypes.arrayOf(PropTypes.string),
  Recipient: RECIPIENT_TYPE,
  IsScheduledSession: PropTypes.bool,
  ScheduledDateTime: PropTypes.number,
  SessionName: PropTypes.string,
});

export const GUIDE_BROWSING_SESSION_TYPE = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  TeacherID: PropTypes.string.isRequired,
  TeacherFirstName: PropTypes.string.isRequired,
  TeacherLastName: PropTypes.string.isRequired,
  Start: PropTypes.number.isRequired,
  End: PropTypes.number.isRequired,
  ModificationTime: PropTypes.number.isRequired,
  Type: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  Links: PropTypes.arrayOf(PropTypes.string),
  FocusSessionDetails: PropTypes.shape({
    EndSessionKeepAllTabs: PropTypes.bool.isRequired,
    EndSessionRestoreOriginal: PropTypes.bool.isRequired,
    FocusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  }),
  Recipient: RECIPIENT_TYPE,
  ClassURN: PropTypes.string,
  Active: PropTypes.bool.isRequired,
  RemovedStudents: PropTypes.arrayOf(PropTypes.string),
  AppliedToIDS: PropTypes.arrayOf(PropTypes.string),
});

export const GUIDE_BROWSING_DUPLICATION_SESSION_TYPE = PropTypes.shape({
  SessionID: PropTypes.string,
  Links: PropTypes.arrayOf(PropTypes.string),
  Recipient: RECIPIENT_TYPE,
  IsScheduledSession: PropTypes.bool,
  ScheduledDateTime: PropTypes.number,
  FocusSessionDetails: PropTypes.shape({
    EndSessionKeepAllTabs: PropTypes.bool.isRequired,
    EndSessionRestoreOriginal: PropTypes.bool.isRequired,
    FocusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  }),
  Duration: PropTypes.number,
  SessionName: PropTypes.string,
});

export const MODAL_MODE_TYPES = {
  SESSION_TYPE_SELECTION: "SESSION_TYPE_SELECTION",
  CREATE_SESSION_FORM: "CREATE_SESSION_FORM",
};

const GUIDE_BROWSING_OLD_SESSION_TYPES = {
  FILTER: "filter",
  FOCUS: "focused",
};

const GUIDE_BROWSING_OLD_RECIPIENT_TYPES = {
  STUDENTS: "individuals",
  GROUPS: "groups",
  CLASS: "wholeClass",
};

const GUIDE_BROWSING_OLD_FOCUS_TYPES = {
  WHOLE_SITE: "limitDomain",
  LINKS: "limitUrl",
};

export const SCHEDULED_SESSIONS_TIMEFRAMES = {
  CURRENT_WEEK: "CURRENT_WEEK",
  UPCOMING: "UPCOMING",
  PAST: "PAST",
};

export const GUIDE_BROWSING_TEMPLATE_TYPE = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Creator: PropTypes.string.isRequired,
  CreatedDatetime: PropTypes.string.isRequired,
  ClassURN: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Type: PropTypes.oneOf(_.values(SESSION_TYPES)).isRequired,
  Links: PropTypes.arrayOf(PropTypes.string),
  FocusSessionDetails: PropTypes.shape({
    EndSessionKeepAllTabs: PropTypes.bool.isRequired,
    EndSessionRestoreOriginal: PropTypes.bool.isRequired,
    FocusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  }),
  RecipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES)),
  SelectedTime: PropTypes.number,
});

export const parseOldTemplateDataToNewFormat = (data) => {
  const Type =
    _.get(data, "session.mode") === GUIDE_BROWSING_OLD_SESSION_TYPES.FOCUS
      ? SESSION_TYPES.FOCUS
      : SESSION_TYPES.FILTER;

  const FocusType =
    _.get(data, "session.browserLock") ===
    GUIDE_BROWSING_OLD_FOCUS_TYPES.WHOLE_SITE
      ? GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
      : GUIDE_BROWSING_FOCUS_TYPES.LINKS;

  const lockType = _.get(data, "session.lockType");
  const RecipientType =
    lockType === GUIDE_BROWSING_OLD_RECIPIENT_TYPES.CLASS
      ? SESSION_RECIPIENT_TYPES.CLASS
      : lockType === GUIDE_BROWSING_OLD_RECIPIENT_TYPES.STUDENTS
      ? SESSION_RECIPIENT_TYPES.STUDENTS
      : SESSION_RECIPIENT_TYPES.GROUPS;

  return {
    ID: _.get(data, "id", "") || "",
    Creator: _.get(data, "Creator", "") || "",
    CreatedDatetime: _.get(data, "CreatedDatetime", "") || "",
    ClassURN: _.get(data, "class", "") || "",
    Name: _.get(data, "name", "") || "",
    Type,
    Links: _.get(data, "session.urls", []) || [],
    FocusSessionDetails: {
      FocusType,
      EndSessionKeepAllTabs: !_.get(data, "session.closeTabs", true),
      EndSessionRestoreOriginal: _.get(data, "session.restoreTabs", true),
    },
    RecipientType,
    SelectedTime: _.get(data, "session.selectedTime", 15) || 15,
  };
};

export const parseNewTemplateDataToOldFormat = (data) => {
  const mode =
    _.get(data, "Type") === SESSION_TYPES.FOCUS
      ? GUIDE_BROWSING_OLD_SESSION_TYPES.FOCUS
      : GUIDE_BROWSING_OLD_SESSION_TYPES.FILTER;

  const browserLock =
    _.get(data, "FocusSessionDetails.FocusType") ===
    GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
      ? GUIDE_BROWSING_OLD_FOCUS_TYPES.WHOLE_SITE
      : GUIDE_BROWSING_OLD_FOCUS_TYPES.LINKS;

  const RecipientType = _.get(data, "RecipientType");

  const lockType =
    RecipientType === SESSION_RECIPIENT_TYPES.CLASS
      ? GUIDE_BROWSING_OLD_RECIPIENT_TYPES.CLASS
      : RecipientType === SESSION_RECIPIENT_TYPES.STUDENTS
      ? GUIDE_BROWSING_OLD_RECIPIENT_TYPES.STUDENTS
      : GUIDE_BROWSING_OLD_RECIPIENT_TYPES.GROUPS;

  const urls = _.get(data, "Links", []) || [];

  return {
    mode,
    browserLock,
    lockType,
    currentTemplate: "",
    currentTemplateName: _.get(data, "Name", "") || "",
    selectedTime: _.get(data, "SelectedTime", 15) || 15,
    restoreTabs: _.get(
      data,
      "FocusSessionDetails.EndSessionRestoreOriginal",
      true
    ),
    closeTabs: !_.get(data, "FocusSessionDetails.EndSessionKeepAllTabs", true),
    urls,
    domains: _.map(urls, (url) => getHostname(url)),
  };
};

export const PICKER_DISABLED_TYPES = {
  TIME: "time",
  DATE: "date",
};
