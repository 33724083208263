import React, { useEffect, useRef, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";
import { APP_PAGES } from "../../state/router/types";
import {
  getUserLoggingStatus,
  getUserPermissions,
} from "../../state/user/selectors";
import {
  isGISLoaded as getisGISLoaded,
  isGISLoading as getisGISLoading,
  isInitialLoadError as getIsInitialLoadError,
  isAccessError as getIsAccessError,
  isUserInitLoaded as getIsUserInitLoaded,
  isUserInitLoading as getIsUserInitLoading,
  isUserInitConfigLoaded as getIsUserInitConfigLoaded,
  isUserInitConfigLoading as getIsUserInitConfigLoading,
} from "../../state/app/selectors";
import { waitForGlobal } from "@hapara/ui/src/components/utils";
import {
  loadUserInit,
  loadGIS,
  loadUserInitConfig,
} from "../../state/app/actions";
import { userDetailsUpdated } from "../../state/user/actions";
import NoAccessPage from "../containers/NoAccessPage/NoAccessPage";
import AppLoadingPage from "../containers/AppLoadingPage/AppLoadingPage";
import {
  areMyClassesLoaded as getAreMyClassesLoaded,
  areMyClassesLoading as getAreMyClassesLoading,
  getClassId,
} from "../../state/shared/selectors";
import { initJwtHandling } from "../../apiCalls/jwtHandler";
import { getJWTRequestBody } from "@hapara/ui/src/components/utils";
import type { RouteProps, RouteComponentProps } from "react-router";
import { useAppSelector, useAppDispatch } from "../../state/hooks";
import { getMyClassesAction } from "../../state/shared/actions";
import { getToken } from "../../apiCalls/jwtHandler";
import { hasAccess } from "../../state/user/selectors";

interface PrivateRouteProps extends RouteProps {
  isNoAccess?: boolean;
  isLoginOnly?: boolean;
  errorContent?: React.ReactNode;
  loadingContent?: React.ReactNode;
  pagePermissions?: Array<string>;
  title?: string;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    location,
    component: Component,
    isNoAccess,
    isLoginOnly,
    errorContent,
    loadingContent,
    pagePermissions,
    title,
  } = props;
  const [initialTokenCheck, setInitialTokenCheck] = useState("");

  useEffect(() => {
    const checkToken = async () => {
      if (!initialTokenCheck) {
        setInitialTokenCheck("loading");
        await getToken();
        setInitialTokenCheck("loaded");
      }
    };

    checkToken();
  }, []);

  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector((state) => getUserLoggingStatus(state));
  const isKnownButUnauthorisedUser = useAppSelector(
    (state) => !hasAccess(state)
  );
  const isGISLoaded = useAppSelector((state) => getisGISLoaded(state));
  const isGISLoading = useAppSelector((state) => getisGISLoading(state));
  const selectedClassId = useAppSelector((state) => getClassId(state));
  const isUserInitLoaded = useAppSelector((state) =>
    getIsUserInitLoaded(state)
  );
  const isUserInitLoading = useAppSelector((state) =>
    getIsUserInitLoading(state)
  );
  const isUserInitConfigLoaded = useAppSelector((state) =>
    getIsUserInitConfigLoaded(state)
  );
  const isUserInitConfigLoading = useAppSelector((state) =>
    getIsUserInitConfigLoading(state)
  );
  const isInitialLoadError = useAppSelector((state) =>
    getIsInitialLoadError(state)
  );
  const isAccessError = useAppSelector((state) => getIsAccessError(state));
  const userPermissions = useAppSelector((state) => getUserPermissions(state));
  const areMyClassesLoaded = useAppSelector((state) =>
    getAreMyClassesLoaded(state)
  );
  const areMyClassesLoading = useAppSelector((state) =>
    getAreMyClassesLoading(state)
  );

  const selectedClassIdRef = useRef(selectedClassId);

  initJwtHandling(getJWTRequestBody(selectedClassId));

  // make sure GIS is loaded
  useEffect(() => {
    if (!isUserLoggedIn && !isGISLoaded && !isGISLoading) {
      waitForGlobal("window.google").then(() => {
        // window.google is ready to use
        dispatch(loadGIS(true));
      });
    }
  }, [dispatch, isUserLoggedIn, isGISLoaded, isGISLoading, selectedClassId]);

  // make sure User Init Info is loaded
  // but do not load if "isLoginOnly"

  useEffect(() => {
    if (
      !isLoginOnly &&
      isUserLoggedIn &&
      !isUserInitLoading &&
      !isUserInitLoaded
    ) {
      dispatch(loadUserInit(true));
    }
  }, [
    dispatch,
    isUserLoggedIn,
    isUserInitLoading,
    isUserInitLoaded,
    isLoginOnly,
  ]);

  // make sure User Init Config is loaded on init
  // but do not load if "isLoginOnly"
  useEffect(() => {
    if (
      !isLoginOnly &&
      isUserLoggedIn &&
      !isUserInitConfigLoading &&
      !isUserInitConfigLoaded
    ) {
      dispatch(
        loadUserInitConfig({ isInitLoad: true, classId: selectedClassId })
      );
    }
  }, [
    dispatch,
    isUserLoggedIn,
    isUserInitConfigLoading,
    isUserInitConfigLoaded,
    isLoginOnly,
    selectedClassId,
  ]);

  // make sure new User Init Config is loaded every time class is changed
  useEffect(() => {
    if (
      isUserLoggedIn &&
      !isUserInitConfigLoading &&
      isUserInitConfigLoaded &&
      selectedClassIdRef.current !== selectedClassId
    ) {
      dispatch(
        loadUserInitConfig({ isInitLoad: false, classId: selectedClassId })
      );
      selectedClassIdRef.current = selectedClassId;
    }
  }, [
    dispatch,
    isUserLoggedIn,
    isUserInitConfigLoading,
    isUserInitConfigLoaded,
    selectedClassId,
  ]);

  // get user info from local storage and put in store for automation tests only
  useEffect(() => {
    const isAutomatedTests =
      window.localStorage.getItem("login-system") === "test";

    if (isAutomatedTests) {
      dispatch(
        userDetailsUpdated(
          JSON.parse(window.localStorage.getItem("user") || "[]")
        )
      );
    }
  }, [dispatch]);

  // load classes
  useEffect(() => {
    if (
      isUserLoggedIn &&
      isUserInitLoaded &&
      !areMyClassesLoaded &&
      !areMyClassesLoading
    ) {
      dispatch(getMyClassesAction());
    }
  }, [
    areMyClassesLoaded,
    areMyClassesLoading,
    dispatch,
    isUserInitLoaded,
    isUserLoggedIn,
  ]);

  const isUserDataLoaded = isUserInitLoaded && isUserInitConfigLoaded;

  const getRouteComponent = ({
    title,
  }: {
    title: string | undefined;
    routerProps: RouteComponentProps;
  }) => {
    const LoadingComponent = (
      <AppLoadingPage
        isError={isInitialLoadError}
        errorContent={errorContent}
        loadingContent={loadingContent}
      />
    );

    const NoAccessComponent = <NoAccessPage />;

    const RedirectToLoginPageComponent = (
      <Redirect
        to={{
          pathname: APP_PAGES.LOGIN.path,
          state: { from: location },
        }}
      />
    );

    const ProtectedComponent = (
      // @ts-ignore
      <Component title={title} isUserDataLoaded={isUserDataLoaded} />
    );

    // if no 403 api errors or no isNoAccess passed from outside
    // let user with at least one permission listed in pagePermissions
    // to view the page
    const isUserHasNoAccess =
      isNoAccess ||
      isAccessError ||
      (pagePermissions &&
        pagePermissions.length > 0 &&
        !_.intersection(userPermissions, pagePermissions).length);

    if (isUserLoggedIn) {
      if (
        (!isLoginOnly && !isUserDataLoaded) ||
        (isInitialLoadError && !isKnownButUnauthorisedUser)
      ) {
        return LoadingComponent;
      }

      if (isUserHasNoAccess || isKnownButUnauthorisedUser) {
        return NoAccessComponent;
      }

      return ProtectedComponent;
    } else {
      if (
        !isGISLoaded ||
        isInitialLoadError ||
        initialTokenCheck === "loading"
      ) {
        return LoadingComponent;
      }

      return RedirectToLoginPageComponent;
    }
  };

  const { exact, path } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(routerProps) => getRouteComponent({ routerProps, title })}
    />
  );
};

PrivateRoute.defaultTypes = {
  isLoginOnly: false,
  isNoAccess: false,
  errorContent: null,
  loadingContent: null,
  pagePermissions: null,
  isKnownButUnauthorisedUser: false,
};

export default PrivateRoute;
