import actionTypes from "../../../actionTypes";
import {
  fetchThumbnailsByQuery,
  fetchPhotoById,
  saveBoardThumb,
  trackDownloadFromUnsplash,
} from "../../../../apiCalls/workspaces";
import { showAppError } from "../../../app/actions";

export const getPhotosByQuery =
  ({ query, page, isFirstImagePredefined }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_PENDING,
    });

    fetchThumbnailsByQuery({ query, page, isFirstImagePredefined })
      .then((response) => {
        if (response.status === 403) {
          console.log("403 error getting images: ");
          return [];
        }

        if (!response.ok) {
          console.log("Error getting images: ", Error(response));
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        dispatch({
          type: actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_ERROR,
        });
        dispatch(showAppError(error));
      });
  };

export const getPhotoItemById = (id) => fetchPhotoById(id);

export const saveBoardThumbnail = (boardId, updatedThumbnail) => {
  return (dispatch) => {
    dispatch(updateLoading(true));

    // saving thumbnail
    saveBoardThumb(boardId, updatedThumbnail)
      .then((response) => {
        if (response.status === 403) {
          // Not a Hapara user, show an error message
          throw Error(response);
        }
        if (!response.ok) {
          throw Error(response);
        }
      })
      .then(() => {
        dispatch(thumbnailSavingSuccess(true));

        // optimistic view
        dispatch(updateBoardThumbnail(boardId, updatedThumbnail));

        // trigger download event on Unsplash
        trackDownloadFromUnsplash(updatedThumbnail.downloadUrl);
      })
      .catch(() => {
        dispatch(thumbnailSavingError(true));
      });
  };
};

export const updateBoardThumbnail = (boardId, updatedThumbnail) => ({
  type: actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL,
  payload: {
    boardId,
    url: updatedThumbnail.url,
    alt: updatedThumbnail.alt,
  },
});

export const updateLoading = (isLoading) => ({
  type: actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_PENDING,
  isLoading,
});

export const thumbnailSavingError = (isSavingThumbnailError) => (dispatch) => {
  dispatch({
    type: actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_ERROR,
    isSavingThumbnailError,
  });
};

export const thumbnailSavingSuccess =
  (isSavingThumbnailSuccess) => (dispatch) => {
    dispatch({
      type: actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_SUCCESS,
      isSavingThumbnailSuccess,
    });
  };

export const updateCurrentPage = (page) => ({
  type: actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_CURRENT_PAGE,
  page,
});
