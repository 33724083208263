import { useEffect, useState } from "react";

interface Ret {
  isDimensionOnly: boolean;
}

export const WINDOW_SIZE_TYPES = {
  XS: 0,
  S: 768,
  SM: 873,
  M: 1024,
  L: 1217,
  XL: 1366,
  XXL: 1640,
  XXXL: 1920,
};

const useIsDimension: (windowSize: number) => Ret = (windowSize) => {
  const [isDimensionOnly, setisDimensionOnly] = useState<boolean>(false);

  const getWindowDimensions: () => { width: number; height: number } = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowDimensions().width <= windowSize) {
        setisDimensionOnly(true);
      } else {
        setisDimensionOnly(false);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize(); //Get screen size on load

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return {
    isDimensionOnly,
  };
};

export default useIsDimension;
