import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import styles from "./GuideBrowsingActiveSession.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import FocusedSessionListWho from "./FocusedSessionListWho";
import FocusedSessionListWhat from "./FocusedSessionListWhat";
import FocusedSessionSettings from "./FocusedSessionSettings";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  SESSION_RECIPIENT_TYPES,
  GUIDE_BROWSING_SESSION_TYPE,
  SESSION_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import {
  loadCurrentSessions,
  releaseSession,
  updateGuideBrowsingSession,
} from "../../../../../../state/highlights/sessions/actions";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { FormattedMessage, useIntl } from "react-intl";

const TABS = {
  WHO: "who",
  WHAT: "what",
};

const GuideBrowsingActiveSession = ({
  session,
  updateGuideBrowsingSession,
  releaseGuideBrowsingSession,
  loadCurrentSessions,
}) => {
  const intl = useIntl();
  const getFormattedRemainingTime = (sessionEnds) => {
    const totalSeconds = moment(sessionEnds).diff(moment(), "seconds");
    const min = Math.floor(totalSeconds / 60);
    const sec = _.padStart(String(totalSeconds % 60), 2, "0");
    return `${min}:${sec} min`;
  };

  const sessionId = _.get(session, "ID", "");
  const sessionClassId = _.get(session, "ClassURN", "");
  const sessionType = _.get(session, "Type", "");
  const sessionFocusType = _.get(
    session,
    "FocusSessionDetails.FocusType",
    GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE
  );
  const sessionRecipientType = _.get(session, "Recipient.Type", "");
  const sessionRecipientStudents = useMemo(() => {
    return _.get(session, "Recipient.Students", []);
  }, [session]);
  const sessionRecipientGroups = _.get(session, "Recipient.Groups", []) || [];
  const sessionLinks = _.get(session, "Links", []) || [];
  const sessionEnd = _.get(session, "End", 0);
  const sessionKeepAllTabs =
    _.get(session, "FocusSessionDetails.EndSessionKeepAllTabs", false) || false;
  const sessionRestoreOriginal =
    _.get(session, "FocusSessionDetails.EndSessionRestoreOriginal", false) ||
    false;

  const [selectedTab, setSelectedTab] = useState(TABS.WHO);
  const [remainingTime, setRemainingTime] = useState(
    getFormattedRemainingTime(sessionEnd)
  );
  const [refreshCount, setRefreshCount] = useState(0);
  const [isEndSessionLoading, setIsEndSessionLoading] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);

  const tabRefs = {
    [TABS.WHO]: useRef(null),
    [TABS.WHAT]: useRef(null),
  };

  // update refreshCount every second
  useEffect(() => {
    const doTimeout = () =>
      _.delay(() => setRefreshCount(refreshCount + 1), 1000);
    // start timeouts
    const timeoutId = doTimeout();

    return () => clearTimeout(timeoutId);
  }, [refreshCount, setRefreshCount]);

  // update remainingTime every second
  useEffect(() => {
    setRemainingTime(getFormattedRemainingTime(sessionEnd));
  }, [refreshCount, sessionEnd, setRemainingTime]);

  // remove success alert after 5 sec
  useEffect(() => {
    let timeoutId;
    if (isUpdateSuccess)
      timeoutId = _.delay(() => {
        setIsUpdateSuccess(false);
      }, 5000);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isUpdateSuccess, setIsUpdateSuccess]);

  const getInfo = () => {
    if (sessionType === SESSION_TYPES.FOCUS) {
      if (sessionFocusType === GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE) {
        return (
          <React.Fragment>
            <FormattedMessage
              defaultMessage="Where can students go within the link(s)? <b>The whole site(s)</b>."
              id="58Drgd"
            />
          </React.Fragment>
        );
      }
      if (sessionFocusType === GUIDE_BROWSING_FOCUS_TYPES.LINKS) {
        return (
          <React.Fragment>
            <FormattedMessage
              defaultMessage="Where can students go within the link(s)? <b>Only the page(s)</b>."
              id="kdHeIJ"
            />
          </React.Fragment>
        );
      }
    }
    if (sessionType === SESSION_TYPES.FILTER) {
      return (
        <React.Fragment>
          <FormattedMessage
            defaultMessage="Students will not be able to access <b>the whole site(s)</b>"
            id="7jAbGk"
          />
        </React.Fragment>
      );
    }
    if (sessionType === SESSION_TYPES.LOCK) {
      return (
        <React.Fragment>
          <FormattedMessage
            defaultMessage="Students can only access site(s) opened before the session began, and those shared by teachers."
            id="MQmf07"
          />
        </React.Fragment>
      );
    }

    return "";
  };

  const getWhoTabTitle = () => {
    const who =
      sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS ? (
        <FormattedMessage id="JsQ2Bn" defaultMessage="group" />
      ) : (
        <FormattedMessage id="Mq3l58" defaultMessage="student" />
      );
    const count =
      sessionRecipientType === SESSION_RECIPIENT_TYPES.GROUPS
        ? sessionRecipientGroups.length
        : sessionRecipientStudents.length;

    return (
      <React.Fragment>
        <FormattedMessage
          id="AozL1h"
          defaultMessage="{count, plural, one {# {who}} other {# {who}s}}"
          values={{
            count,
            who,
          }}
        />
      </React.Fragment>
    );
  };

  const getWhatTabTitle = () => {
    const count = sessionLinks.length;

    const action =
      sessionType === SESSION_TYPES.FILTER ? (
        <FormattedMessage id="HAez8V" defaultMessage="prevented" />
      ) : (
        <FormattedMessage id="aD7Sn1" defaultMessage="allowed" />
      );
    return (
      <React.Fragment>
        <FormattedMessage
          defaultMessage="{count, plural, one {{count} link} other {{count} links}} {action}"
          id="UJzTcO"
          values={{
            count,
            action,
          }}
        />
      </React.Fragment>
    );
  };

  // left/right arrow keys support for tabs
  const handleTabKeyDown = (e, tab) => {
    if (e.keyCode === 39 || e.keyCode === 37) {
      if (tab === TABS.WHO) {
        tabRefs[TABS.WHAT].current.focus();
      }
      if (tab === TABS.WHAT) {
        tabRefs[TABS.WHO].current.focus();
      }
    }
  };

  const handleEndSession = () => {
    setIsEndSessionLoading(true);
    releaseGuideBrowsingSession({ sessionId }).finally(() => {
      setIsEndSessionLoading(false);
      loadCurrentSessions({
        classId: sessionClassId,
      });
    });
  };

  const handleSessionUpdate = (newSession) => {
    setIsUpdateSuccess(false);
    setIsUpdateError(false);
    setIsUpdateInProgress(true);

    updateGuideBrowsingSession({ newSession }).then(
      () => {
        loadCurrentSessions({
          classId: sessionClassId,
        }).finally(() => {
          setIsUpdateInProgress(false);
          setIsUpdateSuccess(true);
        });
      },
      () => {
        setIsUpdateInProgress(false);
        setIsUpdateError(true);
      }
    );
  };

  let buttonLabel = intl.formatMessage({
    defaultMessage: "End Session",
    id: "NT0pVZ",
  });
  if (sessionType === SESSION_TYPES.FILTER) {
    buttonLabel = intl.formatMessage({
      defaultMessage: "End Filter Session",
      id: "lrpknG",
    });
  } else if (sessionType === SESSION_TYPES.FOCUS) {
    buttonLabel = intl.formatMessage({
      defaultMessage: "End Focus Session",
      id: "HvXBVR",
    });
  } else if (sessionType === SESSION_TYPES.LOCK) {
    buttonLabel = intl.formatMessage({
      defaultMessage: "End Frozen Tabs",
      id: "hrSByR",
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <HapReactIcon
          svg="fill-focused-browsing"
          width={32}
          height={32}
          className={styles.headerIcon}
        />
        <div className={styles.headerTime}>
          <FormattedMessage
            id="8vUnyM"
            defaultMessage="{remainingTime} remaining"
            values={{
              remainingTime,
            }}
          />
        </div>
        <Button
          className={styles.headerEndSession}
          onClick={handleEndSession}
          type={BUTTON_TYPES.DANGER}
          label={buttonLabel}
          data-test-id="Hl-EditActiveSession-Session-EndSession"
          isDisabled={isEndSessionLoading}
          isLoading={isEndSessionLoading}
        />
      </div>

      <Alerts className={styles.alerts}>
        <Alert type={ALERT_TYPES.SUCCESS} isVisible={isUpdateSuccess}>
          <FormattedMessage
            defaultMessage="This session was updated successfully."
            id="WtbpGx"
          />
        </Alert>
        <Alert type={ALERT_TYPES.FAILURE} isVisible={isUpdateError}>
          <FormattedMessage
            defaultMessage="Sorry, there was a problem updating this session. Please try again."
            id="71DUFp"
          />
        </Alert>
      </Alerts>

      <div className={styles.body}>
        <div
          className={styles.bodyTitle}
          role="tablist"
          aria-label={intl.formatMessage({
            defaultMessage: "Session details",
            id: "v52hoK",
          })}
        >
          <button
            className={classnames([
              styles.bodyTitleItem,
              {
                [styles.bodyTitleItemSelected]: selectedTab === TABS.WHO,
                [styles.fullWidth]: sessionType === SESSION_TYPES.LOCK,
              },
            ])}
            type="button"
            onClick={() => setSelectedTab(TABS.WHO)}
            data-test-id="Hl-EditActiveSession-Tabs-SelectStudents"
            role="tab"
            aria-selected={selectedTab === TABS.WHO}
            id={`FocusedSessionTabsTab-${TABS.WHO}-${sessionId}`}
            aria-controls={`FocusedSessionTabsPanel-${TABS.WHO}-${sessionId}`}
            ref={tabRefs[TABS.WHO]}
            onKeyDown={(e) => handleTabKeyDown(e, TABS.WHO)}
          >
            {getWhoTabTitle()}
          </button>
          {(sessionType === SESSION_TYPES.FILTER ||
            sessionType === SESSION_TYPES.FOCUS) && (
            <button
              className={classnames([
                styles.bodyTitleItem,
                { [styles.bodyTitleItemSelected]: selectedTab === TABS.WHAT },
              ])}
              type="button"
              onClick={() => setSelectedTab(TABS.WHAT)}
              data-test-id="Hl-EditActiveSession-Tabs-SelectLinks"
              role="tab"
              aria-selected={selectedTab === TABS.WHAT}
              id={`FocusedSessionTabsTab-${TABS.WHAT}-${sessionId}`}
              aria-controls={`FocusedSessionTabsPanel-${TABS.WHAT}-${sessionId}`}
              ref={tabRefs[TABS.WHAT]}
              onKeyDown={(e) => handleTabKeyDown(e, TABS.WHAT)}
            >
              {getWhatTabTitle()}
            </button>
          )}
        </div>
        <div className={styles.bodyContent}>
          <div className={styles.bodyContentItem}>
            {selectedTab === TABS.WHO && (
              <div
                id={`FocusedSessionTabsPanel-${TABS.WHO}-${sessionId}`}
                role="tabpanel"
                aria-labelledby={`FocusedSessionTabsTab-${TABS.WHO}-${sessionId}`}
                tabIndex={0}
              >
                <FocusedSessionListWho
                  sessionId={sessionId}
                  sessionType={sessionType}
                  sessionKeepAllTabs={sessionKeepAllTabs}
                  sessionRestoreOriginal={sessionRestoreOriginal}
                  sessionLinks={sessionLinks}
                  sessionRecipientType={sessionRecipientType}
                  sessionRecipientGroups={sessionRecipientGroups}
                  sessionRecipientStudents={sessionRecipientStudents}
                  onSessionUpdate={handleSessionUpdate}
                />
              </div>
            )}
            {selectedTab === TABS.WHAT && (
              <div
                id={`FocusedSessionTabsPanel-${TABS.WHO}-${sessionId}`}
                role="tabpanel"
                aria-labelledby={`FocusedSessionTabsTab-${TABS.WHO}-${sessionId}`}
                tabIndex={0}
              >
                <FocusedSessionListWhat
                  sessionId={sessionId}
                  sessionType={sessionType}
                  sessionFocusType={sessionFocusType}
                  sessionKeepAllTabs={sessionKeepAllTabs}
                  sessionRestoreOriginal={sessionRestoreOriginal}
                  sessionLinks={sessionLinks}
                  sessionRecipientType={sessionRecipientType}
                  sessionRecipientGroups={sessionRecipientGroups}
                  sessionRecipientStudents={sessionRecipientStudents}
                  onSessionUpdate={handleSessionUpdate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.info}>{getInfo()}</div>

      {sessionType === SESSION_TYPES.FOCUS && (
        <FocusedSessionSettings
          sessionId={sessionId}
          sessionKeepAllTabs={sessionKeepAllTabs}
          sessionRestoreOriginal={sessionRestoreOriginal}
          sessionLinks={sessionLinks}
          sessionRecipientType={sessionRecipientType}
          sessionRecipientGroups={sessionRecipientGroups}
          sessionRecipientStudents={sessionRecipientStudents}
          onSessionUpdate={handleSessionUpdate}
        />
      )}

      <div
        className={classnames([
          styles.overlay,
          { [styles.overlayVisible]: isUpdateInProgress },
        ])}
      >
        {isUpdateInProgress && (
          <React.Fragment>
            <div className={styles.overlayBackdrop} />
            <div className={styles.overlayBody}>
              <HapReactIcon
                svg="loader-light"
                width={32}
                height={32}
                className={styles.overlayIcon}
                spin={true}
              />
              <div className={styles.overlayTitle}>
                <FormattedMessage
                  defaultMessage="Updating session"
                  id="70323z"
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

GuideBrowsingActiveSession.propTypes = {
  session: GUIDE_BROWSING_SESSION_TYPE,
  // connected props
  releaseGuideBrowsingSession: PropTypes.func.isRequired,
  updateGuideBrowsingSession: PropTypes.func.isRequired,
  loadCurrentSessions: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    releaseGuideBrowsingSession: ({ sessionId }) =>
      dispatch(releaseSession({ sessionId })),
    updateGuideBrowsingSession: ({ newSession }) =>
      dispatch(
        updateGuideBrowsingSession({
          newSession,
        })
      ),
    loadCurrentSessions: ({ classId }) =>
      dispatch(loadCurrentSessions({ classId })),
  })
)(GuideBrowsingActiveSession);
