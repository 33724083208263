import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationQuery } from "../../router/selectors";
import { CLICK_TO_SHARE_URL_KEYS } from "./navigation";

export const getDocUrl = createSelector([getLocationQuery], (locationQuery) => {
  const paramName = CLICK_TO_SHARE_URL_KEYS.docUrl.key;
  const rawParam = _.get(locationQuery, paramName);
  return rawParam;
});

export const getEmail = createSelector([getLocationQuery], (locationQuery) => {
  const paramName = CLICK_TO_SHARE_URL_KEYS.email.key;
  const rawParam = _.get(locationQuery, paramName);
  return rawParam;
});

export const getClickToShareState = (state) =>
  _.get(state, "highlights.clickToShare");
