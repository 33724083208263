import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ResourceDetailsModalTags.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import { getFilters } from "../../../../state/shared/selectors";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";
import Pill, { PILL_COLOURS } from "@hapara/ui/src/atomic/Pill/Pill";
import { sortStrToNumberIteratee } from "../../../../utils";

const getValueByKey = (srcList, key) => {
  return _.get(
    _.find(srcList, (item) => item.Key === key),
    "Value",
    key
  );
};

const TagsSection = ({
  srcList,
  itemList,
  title,
  dataTestPrefix,
  isNumeric,
}) => {
  const [isShowMoreOpen, setIsShowMoreOpen] = useState(false);

  if (!itemList || !itemList.length) return null;

  const isShowMore = itemList.length > 5;

  const sortedList = _.sortBy(
    _.map(itemList, (key) => getValueByKey(srcList, key)),
    isNumeric ? sortStrToNumberIteratee : null
  );
  const listToDisplay = isShowMoreOpen ? sortedList : _.slice(sortedList, 0, 5);

  return (
    <div className={styles.tagsContent}>
      <h2 className={styles.tagsContentHeading}>{title}</h2>
      {_.map(listToDisplay, (key) => (
        <Pill
          key={key}
          colour={PILL_COLOURS.PALE_GRAY}
          classNameBody={styles.pill}
        >
          {getValueByKey(srcList, key)}
        </Pill>
      ))}

      {isShowMore && (
        <div className={styles.showMoreContainer}>
          <button
            type="button"
            className={styles.showMore}
            onClick={() => setIsShowMoreOpen(!isShowMoreOpen)}
            aria-expanded={isShowMoreOpen}
            data-test-id={`${dataTestPrefix}-TagList-Button-ShowMore`}
          >
            {isShowMoreOpen ? "Show less" : "Show more"}
          </button>
        </div>
      )}
    </div>
  );
};

TagsSection.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.string),
  srcList: PropTypes.arrayOf(filterItemType),
  title: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  isNumeric: PropTypes.bool.isRequired,
};

const ResourceDetailsModalTags = ({
  grades,
  subjects,
  standards,
  licences,
  resourceTypes,
  itemGrades,
  itemSubjects,
  itemStandards,
  itemLicenceType,
  itemResourceTypes,
  dataTestPrefix,
}) => {
  return (
    <div className={styles.root}>
      <TagsSection
        title="Subjects"
        itemList={itemSubjects}
        srcList={subjects}
        dataTestPrefix={dataTestPrefix}
        isNumeric={false}
      />
      <TagsSection
        title="Grades"
        itemList={itemGrades}
        srcList={grades}
        dataTestPrefix={dataTestPrefix}
        isNumeric={true}
      />
      <TagsSection
        title="Resource type"
        itemList={itemResourceTypes}
        srcList={resourceTypes}
        dataTestPrefix={dataTestPrefix}
        isNumeric={false}
      />
      <TagsSection
        title="Standards"
        itemList={itemStandards}
        srcList={standards}
        dataTestPrefix={dataTestPrefix}
        isNumeric={false}
      />
      <TagsSection
        title="Licence type"
        itemList={itemLicenceType}
        srcList={licences}
        dataTestPrefix={dataTestPrefix}
        isNumeric={false}
      />
    </div>
  );
};

ResourceDetailsModalTags.propTypes = {
  itemGrades: PropTypes.arrayOf(PropTypes.string),
  itemSubjects: PropTypes.arrayOf(PropTypes.string),
  itemStandards: PropTypes.arrayOf(PropTypes.string),
  itemLicenceType: PropTypes.arrayOf(PropTypes.string),
  itemResourceTypes: PropTypes.arrayOf(PropTypes.string),
  dataTestPrefix: PropTypes.string.isRequired,
  // connected
  grades: PropTypes.arrayOf(filterItemType),
  subjects: PropTypes.arrayOf(filterItemType),
  standards: PropTypes.arrayOf(filterItemType),
  licences: PropTypes.arrayOf(filterItemType),
  resourceTypes: PropTypes.arrayOf(filterItemType),
};

export default connect((state) => ({
  grades: getFilters(SHARED_FILTER_TYPES.GRADES.value)(state),
  subjects: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
  standards: getFilters(SHARED_FILTER_TYPES.STANDARDS.value)(state),
  licences: getFilters(SHARED_FILTER_TYPES.LICENCES.value)(state),
  resourceTypes: getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value)(state),
}))(ResourceDetailsModalTags);
