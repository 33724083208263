import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import styles from "./studentsListBlock.module.scss";

import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../../atomic/Button/Button";
import Checkbox from "../../atomic/Checkbox/Checkbox";

import SlidingGroupPanel from "../slidingGroupPanel/slidingGroupPanel";
import StudentGroupParticipant from "../studentGroupParticipant/studentGroupParticipant";
import _ from "lodash";
/**
 *
 * @param data
 * @param onStudentSelect
 * @returns {*}
 * @constructor
 */
const StudentsListBlock = React.forwardRef(
  (
    {
      data,
      placement,
      order,
      errorOccurred,
      onActionBack,
      onStudentsAdd,
      onTryAgain,
      onClose,
      dialogLabelId,
    },
    ref
  ) => {
    const intl = useIntl();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [spinner, setSpinner] = useState(false);

    /**
     * handler for adding students and settinf=g spinner
     * @param {array} students
     */
    function handleStudentsAdd(students) {
      setSpinner(true);
      onStudentsAdd(students);
    }

    useEffect(() => {
      if (!errorOccurred.id) {
        setSpinner(false);
      }
    }, [errorOccurred]);

    if (order === "fname") {
      data = _.sortBy(data, "name");
    } else {
      data = _.sortBy(data, "lastname");
    }

    return (
      <SlidingGroupPanel
        onActionBack={() => onActionBack()}
        onClose={() => onClose()}
        hasOverlay={true}
        theme="gray"
        placement={placement}
        blocked={spinner}
        errorOccurred={errorOccurred}
        onTryAgain={onTryAgain}
        ref={ref}
      >
        <div className={styles.root}>
          <h1 className={styles.title} id={dialogLabelId}>
            <FormattedMessage defaultMessage="Add Students" id="tWXdPI" />
          </h1>
          <div className={styles.list}>
            <div className={styles.allGroups}>
              <Checkbox
                onChange={(checked) => {
                  if (checked) {
                    setSelectedStudents(data);
                  } else {
                    setSelectedStudents([]);
                  }
                }}
                id="chck_all_groups_student_list"
                checked={selectedStudents.length === data.length}
                semi={
                  selectedStudents.length !== data.length &&
                  selectedStudents.length > 0
                }
                label={intl.formatMessage({
                  defaultMessage: "All Students",
                  id: "8i/vc6",
                })}
                className={styles.allGroupsCheckbox}
              />
            </div>
            <ul className={styles.optionList}>
              {data &&
                _.map(data, (member) => {
                  return (
                    <li className={styles.optionItem} key={member.email}>
                      <StudentGroupParticipant
                        data={member}
                        selected={
                          !!selectedStudents.filter(
                            (s) => s.email === member.email
                          )[0]
                        }
                        canDelete={false}
                        order={order}
                        errorOccurred={errorOccurred}
                        onStudentSelect={(selected) => {
                          if (selected) {
                            setSelectedStudents(
                              selectedStudents.concat(member)
                            );
                          } else {
                            setSelectedStudents(
                              selectedStudents.filter(
                                (s) => s.email !== member.email
                              )
                            );
                          }
                        }}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {selectedStudents.length > 0 && (
          <div className={styles.footer}>
            <div className={styles.cancelButton}>
              <Button
                label={intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "47FYwb",
                })}
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={() => onActionBack()}
              />
            </div>
            <div className={styles.addStudents}>
              <Button
                label={intl.formatMessage(
                  {
                    defaultMessage:
                      "{studentCount, plural, one {Add # student} other {Add # students}}",
                    id: "OFHWNz",
                  },
                  { studentCount: selectedStudents.length }
                )}
                isLoading={spinner}
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={() => handleStudentsAdd(selectedStudents)}
              />
            </div>
          </div>
        )}
      </SlidingGroupPanel>
    );
  }
);

StudentsListBlock.propTypes = {
  data: PropTypes.array,
  placement: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  onStudentsAdd: PropTypes.func,
  onActionBack: PropTypes.func,
  errorOccurred: PropTypes.object,
  onTryAgain: PropTypes.func,
  onClose: PropTypes.func,
};

export default StudentsListBlock;
