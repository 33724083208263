import { createContext } from "react";

type SegmentedControlContextType = {
  index: number | undefined;
  isSelectedSegment: boolean;
  onSegmentSelect: () => void;
  dataTestIdPrefix: string;
};

export const SegmentedControlContext =
  createContext<SegmentedControlContextType>({
    index: undefined,
    isSelectedSegment: false,
    onSegmentSelect: () => {},
    dataTestIdPrefix: "",
  });
