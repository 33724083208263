import React from "react";
import { createIcon } from "./utils/createIcon";

export const CircleInfoIcon = createIcon(
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1Zm0 2.6a.9.9 0 1 1 0 1.8.9.9 0 0 1 0-1.8Zm-.9 7.9a.9.9 0 0 0 1.8 0V8A.9.9 0 1 0 7 8v3.5Z"
  />
);
