import React from "react";
import { ChatButtonIcon } from "./ChatButtonIcon";
import { ChatBadge } from "../ChatBadge";
import classnames from "classnames";
import { useIntl } from "react-intl";

import styles from "./ChatButton.module.scss";

type ChatNotificationButtonProps = {
  className?: string;
  messageCount: number;
  onClick(): void;
};

/** Component to trigger opening the chat drawer, currently in HSuite Highlights */
export const ChatButton = ({
  messageCount,
  onClick,
  className,
}: ChatNotificationButtonProps) => {
  const intl = useIntl();
  return (
    <button
      className={classnames(styles.container, className)}
      onClick={onClick}
      aria-label={intl.formatMessage({
        defaultMessage: "Open chat",
        id: "un14bs",
      })}
      data-test-id="chat-AccessButton"
    >
      <ChatBadge unreadCount={messageCount} className={styles.badge} />
      <ChatButtonIcon />
    </button>
  );
};
