import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import styles from "./groupPreferences.module.scss";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../../atomic/Button/Button";
import { Radio } from "../../atomic/Radio";
import SlidingGroupPanel from "../slidingGroupPanel/slidingGroupPanel";

/**
 *
 * @param preferences
 * @param handlePreferenceChange
 * @returns {*}
 * @constructor
 */
const GroupPreferences = ({
  preferences,
  placement,
  handleActionBack,
  savePreference,
  onClose,
  dialogLabelId,
}) => {
  const intl = useIntl();
  const [option, setOption] = useState(preferences);
  const [edited, setEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorOccurred, setError] = useState({ id: null, text: "" });

  /**
   * handling saving preferences
   * @param option
   */
  function handlePreferenceSave(option) {
    setIsLoading(true);
    savePreference(option)
      .then(() => {
        setIsLoading(false);
        handleActionBack();
      })
      .catch(() => {
        setError({
          id: "updatePref",
          text: intl.formatMessage({
            defaultMessage:
              "Sorry, there is a problem updating the group preference.",
            id: "1JFd2K",
          }),
        });
        setIsLoading(false);
      });
  }

  return (
    <SlidingGroupPanel
      onActionBack={() => handleActionBack()}
      onClose={onClose}
      hasOverlay={true}
      placement={placement}
      theme="gray"
      blocked={isLoading}
      errorOccurred={errorOccurred}
      onTryAgain={() => setError({ id: null, text: "" })}
    >
      <div className={styles.root}>
        <h1 className={styles.title} id={dialogLabelId}>
          <FormattedMessage defaultMessage="Group preference" id="dSrmKI" />
        </h1>
        <div className={styles.subTitle}>
          <FormattedMessage
            defaultMessage="Select how groups are organised for this class."
            id="jNM5tw"
          />
        </div>
        <ul>
          <li>
            <Radio
              name="groupsPreferences"
              value="shared-across-tabs"
              label={intl.formatMessage({
                defaultMessage: "Shared across tabs",
                id: "bWnJ3j",
              })}
              checked={!option}
              onChange={() => {
                setEdited(true);
                setOption(false);
              }}
              className={styles.optionTitle}
              dataTestIdPrefix="td-GroupsManager-GroupPreferences-SharedAcrossTabsOption"
            />
            <div className={styles.radioExtraText}>
              <div className={styles.optionText}>
                <FormattedMessage
                  defaultMessage="The same groups are available on all pages for this class."
                  id="0qX3Vc"
                />
              </div>
              <div className={styles.optionText}>
                <FormattedMessage
                  defaultMessage="Use this option when you want to use the same student groups across Hapara."
                  id="Q++C+/"
                />
              </div>
            </div>
          </li>
          {!option && edited && (
            <li className={styles.highlight}>
              <FormattedMessage
                defaultMessage='Previously created groups will not show in this preference, but they will be available if you switch back to <semiBold>"Specific to each tab"</semiBold>.'
                id="BJaL76"
                description="References: 'Specific to each tab'  (id: samqA/)"
                values={{
                  semiBold: (chunks) => (
                    <span className={styles.semiBold}>{chunks}</span>
                  ),
                }}
              />
            </li>
          )}
          <li>
            <Radio
              name="groupsPreferences"
              value="specific-to-each-tab"
              label={intl.formatMessage({
                defaultMessage: "Specific to each tab",
                id: "samqA/",
              })}
              checked={option}
              onChange={() => {
                setEdited(true);
                setOption(true);
              }}
              className={styles.optionTitle}
              dataTestIdPrefix="td-GroupsManager-GroupPreferences-SpecificToEachTabOption"
            />
            <div className={styles.radioExtraText}>
              <div className={styles.optionText}>
                <FormattedMessage
                  defaultMessage="The groups are specific to the page in which they are set."
                  id="6cFVyP"
                />
              </div>
              <div className={styles.optionText}>
                <FormattedMessage
                  defaultMessage="Use this option if you group your class differently across
                  Hapara."
                  id="AUOG3y"
                />
              </div>
            </div>
          </li>
          {option && edited && (
            <li className={styles.highlight}>
              <FormattedMessage
                defaultMessage='Previously created groups will not show in this preference, but they will be available if you switch back to <semiBold>"Shared across tabs"</semiBold>.'
                id="rna2N+"
                description="References: 'Shared across tabs'  (id: bWnJ3j/)"
                values={{
                  semiBold: (chunks) => (
                    <span className={styles.semiBold}>{chunks}</span>
                  ),
                }}
              />
            </li>
          )}
        </ul>
      </div>
      {edited && (
        <div className={styles.footer}>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.SMALL}
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onAction={() => handleActionBack()}
            dataTestId="td-GroupsManager-GroupPreferences-ButtonCancel"
          />
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.SMALL}
            label={intl.formatMessage({
              defaultMessage: "Save",
              id: "jvo0vs",
            })}
            isLoading={isLoading}
            onAction={() => handlePreferenceSave(option)}
            dataTestId="td-GroupsManager-GroupPreferences-ButtonSave"
          />
        </div>
      )}
    </SlidingGroupPanel>
  );
};

GroupPreferences.propTypes = {
  preferences: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  handlePreferenceChange: PropTypes.func,
  handleActionBack: PropTypes.func,
  onClose: PropTypes.func,
  dialogLabelId: PropTypes.string,
};

export default GroupPreferences;
