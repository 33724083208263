import { FormattedMessage } from "react-intl";
import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./WSStatusDropdown.module.scss";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import Button, { BUTTON_SIZES } from "@hapara/ui/src/atomic/Button/Button";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import {
  actionInProgress,
  actionError,
} from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  setPublishedAction,
  dismissError,
} from "../../../../../state/workspace/myworkspaces/list/actions";
import { connect } from "react-redux";
import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";

const dialogPropTypes = {
  actionInProgress: PropTypes.bool,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

const SaveAsDraftDialog = ({
  actionInProgress,
  title,
  action,
  isOpen,
  close,
  error,
}) => {
  return (
    isOpen && (
      <ActionConfirmationDialog
        actionLabel={actionInProgress ? "Saving as Draft" : "Save as Draft"}
        isActionInProgress={actionInProgress}
        onAction={action}
        isOpen={isOpen}
        onClose={close}
        isCancelDisabled={actionInProgress}
        error={
          error
            ? "Sorry, there is a problem updating this Workspace. Please try again."
            : ""
        }
        title="Save as draft?"
        subTitle={title}
        cancelDataTestId="Ws-TileStatusChangeConfirmDialog-Button-CancelSaveAsDraft"
        actionDataTestId="Ws-TileStatusChangeConfirmDialog-Button-ConfirmSaveAsDraft"
      >
        <p>
          <FormattedMessage
            defaultMessage="When you revert this Workspace to draft, it will no longer be visible to the class/es you have assigned it to."
            id="2A8rKo"
          />
        </p>
      </ActionConfirmationDialog>
    )
  );
};
SaveAsDraftDialog.propTypes = dialogPropTypes;

const PublishDialog = ({
  actionInProgress,
  title,
  action,
  isOpen,
  close,
  error,
}) => {
  return (
    isOpen && (
      <ActionConfirmationDialog
        actionLabel={actionInProgress ? "Publishing" : "Publish"}
        isActionInProgress={actionInProgress}
        onAction={action}
        isOpen={isOpen}
        onClose={close}
        isCancelDisabled={actionInProgress}
        error={
          error
            ? "Sorry, there is a problem publishing this Workspace. Please try again."
            : ""
        }
        title="Publish?"
        subTitle={title}
        cancelDataTestId="Ws-TileStatusChangeConfirmDialog-Button-CancelPublish"
        actionDataTestId="Ws-TileStatusChangeConfirmDialog-Button-ConfirmPublish"
      >
        <p>
          <FormattedMessage
            defaultMessage="When you publish this Workspace, it will be visible to the class/es you have assigned it to."
            id="Lyaa1k"
          />
        </p>
      </ActionConfirmationDialog>
    )
  );
};
PublishDialog.propTypes = dialogPropTypes;

const WSStatusDropdown = ({
  setPublished,
  item,
  actionInProgress,
  actionError,
  dismissError,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [startedAction, setStartedAction] = useState(false);

  const isPublished = item.IsPublished;
  const triggerButtonRef = useRef();

  const setFocusToButton = useCallback(() => {
    if (triggerButtonRef && triggerButtonRef.current) {
      triggerButtonRef.current.focus();
    }
  }, [triggerButtonRef]);

  const close = useCallback(() => {
    setOpenConfirm(false);
    setStartedAction(false);
    dismissError();
    _.delay(setFocusToButton, 200);
  }, [dismissError, setOpenConfirm, setStartedAction, setFocusToButton]);

  const open = () => {
    dismissError();
    setOpenConfirm(true);
  };

  useEffect(() => {
    if (startedAction && !actionInProgress && !actionError) {
      close();
    }
  }, [startedAction, actionInProgress, actionError, close]);

  return (
    <div className={styles.root}>
      {isPublished && (
        <SaveAsDraftDialog
          title={item.Title}
          actionInProgress={actionInProgress}
          action={() => {
            setStartedAction(true);
            setPublished(item, false);
          }}
          isOpen={openConfirm}
          close={close}
          error={actionError}
        />
      )}
      {!isPublished && (
        <PublishDialog
          title={item.Title}
          actionInProgress={actionInProgress}
          action={() => {
            setStartedAction(true);
            setPublished(item, true);
          }}
          isOpen={openConfirm}
          close={close}
          error={actionError}
        />
      )}
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={(props) => (
          <Button
            label={isPublished ? "Published" : "Draft"}
            rightIcon="arrow-carvet-down"
            size={BUTTON_SIZES.SMALL}
            data-test-id="Ws-TileStatusChange-Dropdown-Trigger"
            className={
              isPublished ? styles.triggerPublished : styles.triggerDraft
            }
            ref={triggerButtonRef}
            {...props}
          />
        )}
        itemComponentList={[
          ({ onClick, className, ...rest }) => (
            <button
              onClick={() => {
                open();
                onClick();
              }}
              data-test-id={`Ws-TileStatusChange-Button-${
                isPublished ? "Draft" : "Published"
              }`}
              className={classnames(className, styles.dropdownContentItem)}
              {...rest}
            >
              {isPublished ? "Draft" : "Published"}
            </button>
          ),
        ]}
      />
    </div>
  );
};

WSStatusDropdown.propTypes = {
  item: wsItemType,
  actionInProgress: PropTypes.bool,
  actionError: PropTypes.bool,
  setPublished: PropTypes.func.isRequired,
  dismissError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    actionInProgress: actionInProgress(state),
    actionError: actionError(state),
  }),
  (dispatch) => ({
    setPublished: (item, isPublished) =>
      dispatch(setPublishedAction(item, isPublished)),
    dismissError: () => dispatch(dismissError()),
  })
)(WSStatusDropdown);
