import actionTypes from "../../../actionTypes";
import _ from "lodash";
import { MYWORKSPACES_THUMBNAIL_PAGE_SIZE } from "./types";

export const INIT_STATE = {
  items: null,
  itemsAreLoading: true,
  itemsFetchError: false,
  savingThumbnailInProgress: false,
  savingThumbnailSuccess: false,
  savingThumbnailError: false,
  selectedAltText: "",
  currentPage: 1,
  pagesCount: 0,
  pageSize: MYWORKSPACES_THUMBNAIL_PAGE_SIZE,
  initialSearchTerm: "",
};

const thumbnailsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_SUCCESS:
      return {
        ...state,
        pagesCount: action.payload.total_pages,
        items: action.payload.results,
        itemsAreLoading: false,
        itemsFetchError: false,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_PENDING:
      return {
        ...state,
        itemsAreLoading: true,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_FETCH_ERROR:
      return {
        ...state,
        itemsAreLoading: false,
        itemsFetchError: true,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_PENDING:
      return {
        ...state,
        savingThumbnailInProgress: action.isLoading,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_SUCCESS:
      return {
        ...state,
        savingThumbnailInProgress: false,
        savingThumbnailSuccess: action.isSavingThumbnailSuccess,
        savingThumbnailError: false,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_ERROR:
      return {
        ...state,
        savingThumbnailInProgress: false,
        savingThumbnailError: action.isSavingThumbnailError,
      };

    case actionTypes.MYWORKSPACES_THUMBNAILS_UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case actionTypes.RESOURCES_FILTERS_LOAD_SUCCESS:
      let combinedSearchTerm = "";
      _.forEach(action.payload.Subjects, (item, idx) => {
        combinedSearchTerm += `${idx === 0 ? "" : ","}${item.Value}`;
      });
      return { ...state, initialSearchTerm: combinedSearchTerm };

    default:
      return state;
  }
};

export default thumbnailsReducer;
