import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Button, ButtonSize, ButtonType } from "../Button";

type CopyButtonProps = {
  className?: string;
  dataTestId?: string;
  value: string;
  label?: string;
  ariaLabel?: string;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: () => void;
};

export const CopyButton = ({
  value,
  label,
  size,
  type = "secondary",
  ariaLabel,
  dataTestId,
  className,
  onClick,
}: CopyButtonProps) => {
  const intl = useIntl();
  const ref = useRef<HTMLButtonElement>();

  const defaultLabel = intl.formatMessage({
    defaultMessage: "Copy",
    id: "4l6vz1",
  });

  const defaultAriaLabel = intl.formatMessage({
    defaultMessage: "Copy value to clipboard",
    id: "Q/3+xP",
  });

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    onClick?.();
    setTimeout(() => {
      ref.current?.blur();
    }, 100);
  };

  return (
    <Button
      className={className}
      dataTestId={dataTestId}
      ref={ref}
      size={size}
      type={type}
      onClick={handleClick}
      label={label ?? defaultLabel}
      aria-label={ariaLabel ?? defaultAriaLabel}
    />
  );
};
