import React, { Profiler, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import _ from "lodash";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styles from "./StudentTiles.module.scss";
import {
  getStudents,
  getStudentsReducedParams,
} from "../../../../state/highlights/students/selectors";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import StudentTile from "../StudentTile/StudentTile";
import NoStudentsInGroupInfoPanel from "../NoStudentsInGroupInfoPanel/NoStudentsInGroupInfoPanel";
import {
  STUDENT_ITEMS_PER_TILE_DEFAULT_VALUE,
  STUDENT_ORDER_TYPES,
  STUDENT_TILES_SIZE_TYPES,
  STUDENT_TILE_CONTENT_TYPE,
} from "../../../../state/highlights/config/types";
import { STUDENT_TYPE_REDUCED } from "../../../../state/highlights/students/types";
import {
  getStudentsCustomOrder,
  getStudentsOrderType,
  getStudentTilesSizeType,
} from "../../../../state/highlights/config/selectors";
import { getUserNameFormat } from "../../../../state/user/selectors";
import {
  UPREF_NAME_FIRST_LAST,
  UPREF_NAME_LAST_FIRST,
} from "../../../../state/user/types";
import { updateStudentsOrder } from "../../../../state/highlights/config/actions";
import {
  getClassId,
  getCurrentClassName,
} from "../../../../state/shared/selectors";
import { sendEvent } from "../../../../state/app/actions";
import { sendReactRenderStats } from "../../../../state/highlights/students/actions";
import usePerformanceStatsSendHook from "../../../../hooks/usePerformanceStatsSendHook";
import { ReactSortable } from "react-sortablejs";
import { pendoTrack } from "../../../../utils";
import { getFFByName } from "../../../../state/app/selectors";
import StudentDriveTile from "../../Drive/StudentTileDrive/StudentTileDrive";
import useGroups from "../../../../hooks/useGroups";
import { getStudentsOrder } from "./utils";

const GRID_ROW_MINIMUM_HEIGHT = 60;
const GRID_ROW_HEIGHT_DEFAULT = 200;
const TAB_ITEM_TILE_HEIGHT = 28;

const StudentTiles = ({
  students,
  studentsReduced,
  studentOrderType,
  studentCustomOrder = [],
  nameFormat,
  selectedClassId,
  selectedClassName,
  updateStudentsOrder,
  itemsPerTile,
  tileContentType,
  isMonitorPerformanceFF,
  sendEvent,
  sendReactRenderStats,
  studentTilesSizeType,
  pageType,
  tabName,
  showFolderOption = false,
}) => {
  const [studentsList, setStudentsList] = useState([]);
  const isCompactSize =
    studentTilesSizeType === STUDENT_TILES_SIZE_TYPES.COMPACT;

  const {
    data: { userGroups },
  } = useGroups({ pageType, selectedView: tabName });

  const checkedUserGroups = useMemo(
    () =>
      userGroups && userGroups.length > 0
        ? userGroups.filter((group) => group.checked)
        : [],
    [userGroups]
  );

  useEffect(() => {
    if (
      studentOrderType !== STUDENT_ORDER_TYPES.OFFLINE &&
      studentOrderType !== STUDENT_ORDER_TYPES.ONLINE
    ) {
      // no need to reorder students if it's a Custom / Name sorting
      const studentsOrder = getStudentsOrder({
        studentsList: studentsReduced,
        checkedGroupsList: checkedUserGroups,
        studentOrderType,
        studentCustomOrder,
        nameFormat,
      });
      setStudentsList(
        studentsOrder.map((id) => {
          return {
            id: id,
          };
        })
      );
    }
  }, [checkedUserGroups, studentOrderType, nameFormat, tabName, pageType]);

  useEffect(() => {
    if (
      studentOrderType === STUDENT_ORDER_TYPES.OFFLINE ||
      studentOrderType === STUDENT_ORDER_TYPES.ONLINE
    ) {
      // if students sorted by online/offline status -> watch for students data updates
      const studentsOrder = getStudentsOrder({
        studentsList: studentsReduced,
        checkedGroupsList: checkedUserGroups,
        studentOrderType,
        studentCustomOrder,
        nameFormat,
      });
      setStudentsList(
        studentsOrder.map((id) => {
          return {
            id: id,
          };
        })
      );
    }
  }, [
    studentsReduced,
    checkedUserGroups,
    studentOrderType,
    nameFormat,
    tabName,
    pageType,
  ]);

  const onStudentTilesRender = usePerformanceStatsSendHook({
    componentName: "StudentTile",
    sendReactRenderStats,
    isMonitorPerformanceFF,
  });

  const tileHeight =
    tileContentType === STUDENT_TILE_CONTENT_TYPE.TABS
      ? GRID_ROW_MINIMUM_HEIGHT + TAB_ITEM_TILE_HEIGHT * itemsPerTile
      : GRID_ROW_HEIGHT_DEFAULT;

  const handleLayoutChange = (studentsIdsSorted) => {
    updateStudentsOrder({
      type: STUDENT_ORDER_TYPES.CUSTOM,
      customOrder: studentsIdsSorted,
      classId: selectedClassId,
    });
    sendEvent({ name: "uiAction", action: "dragStop" });
  };

  return (
    <>
      <div className={styles.root}>
        {studentsList && studentsList.length > 0 && (
          <ReactSortable
            list={studentsList}
            setList={setStudentsList}
            animation={100}
            className={classnames(styles.sortableContainer, {
              [styles.compactSize]: isCompactSize,
            })}
            ghostClass={styles.grayBackground}
            onEnd={() => {
              handleLayoutChange(studentsList.map(({ id }) => id));
              pendoTrack("HL drag and drop", {
                classId: selectedClassId,
                className: selectedClassName,
              });
            }}
          >
            {studentsList.map(({ id }) => {
              const student = students[id];

              const isOnline = _.get(
                student,
                `instances.${student?.currentInstance}.isOnline`,
                false
              );
              const studentTile =
                tileContentType === STUDENT_TILE_CONTENT_TYPE.DRIVE ? (
                  <StudentDriveTile
                    userGroups={userGroups}
                    student={students[id]}
                    draggableHandleClassName={styles.draggableHandle}
                    tileContentType={tileContentType}
                    className={styles.tileItem}
                    dataTestIdPrefix="student-drive-tile"
                    showFolderOption={showFolderOption}
                  />
                ) : (
                  <StudentTile
                    userGroups={userGroups}
                    student={students[id]}
                    draggableHandleClassName={styles.draggableHandle}
                    tileContentType={tileContentType}
                    className={styles.tileItem}
                  />
                );

              return (
                <div
                  key={id}
                  className={styles.sortableItem}
                  style={{
                    height: tileHeight,
                  }}
                >
                  {isMonitorPerformanceFF && isOnline && (
                    <Profiler id="StudentTile" onRender={onStudentTilesRender}>
                      {studentTile}
                    </Profiler>
                  )}
                  {(!isMonitorPerformanceFF ||
                    (isMonitorPerformanceFF && !isOnline)) &&
                    studentTile}
                </div>
              );
            })}
          </ReactSortable>
        )}
      </div>

      {studentsList && studentsList.length === 0 && (
        <NoStudentsInGroupInfoPanel />
      )}
    </>
  );
};

StudentTiles.propTypes = {
  students: PropTypes.object,
  studentsReduced: PropTypes.arrayOf(STUDENT_TYPE_REDUCED),
  checkedUserGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  studentOrderType: PropTypes.oneOf(_.values(STUDENT_ORDER_TYPES)),
  studentCustomOrder: PropTypes.arrayOf(PropTypes.string),
  nameFormat: PropTypes.oneOf([UPREF_NAME_FIRST_LAST, UPREF_NAME_LAST_FIRST]),
  selectedClassId: PropTypes.string,
  selectedClassName: PropTypes.string,
  updateStudentsOrder: PropTypes.func.isRequired,
  itemsPerTile: PropTypes.number.isRequired,
  tileContentType: PropTypes.oneOf(_.values(STUDENT_TILE_CONTENT_TYPE))
    .isRequired,
  isMonitorPerformanceFF: PropTypes.bool.isRequired,
  sendEvent: PropTypes.func.isRequired,
  sendReactRenderStats: PropTypes.func.isRequired,
  studentTilesSizeType: PropTypes.oneOf(_.values(STUDENT_TILES_SIZE_TYPES))
    .isRequired,
  tabName: PropTypes.string,
  pageType: PropTypes.string,
  showFolderOption: PropTypes.bool,
};

export default connect(
  (state) => ({
    students: getStudents(state),
    studentsReduced: getStudentsReducedParams(state),
    studentOrderType: getStudentsOrderType(state),
    studentCustomOrder: getStudentsCustomOrder(state),
    nameFormat: getUserNameFormat(state),
    itemsPerTile: STUDENT_ITEMS_PER_TILE_DEFAULT_VALUE,
    selectedClassId: getClassId(state),
    selectedClassName: getCurrentClassName(state),
    studentTilesSizeType: getStudentTilesSizeType(state),
    isMonitorPerformanceFF: getFFByName("HAP-8438-Monitor-React-Performance")(
      state
    ),
  }),
  (dispatch) => ({
    sendEvent: (message) => dispatch(sendEvent(message)),
    updateStudentsOrder: ({ type, customOrder, classId }) =>
      dispatch(updateStudentsOrder({ type, customOrder, classId })),
    sendReactRenderStats: (options) => dispatch(sendReactRenderStats(options)),
  })
)(StudentTiles);
