import PropTypes from "prop-types";
import { CURRENT_SCREEN_SNAPSHOT_TYPE } from "../students/types";

export const VIEW_SCREENSHOT_MODAL_INFO_TYPE = PropTypes.shape({
  isOpen: PropTypes.bool.isRequired,
  studentId: PropTypes.string,
  studentEmail: PropTypes.string,
  studentDisplayName: PropTypes.string,
  activeTabUrl: PropTypes.string,
  activeTabIcon: PropTypes.string,
  snapshot: CURRENT_SCREEN_SNAPSHOT_TYPE,
});
