import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./StudentTileReportProblemModal.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import ModalOfflineStateHelp from "../ModalOfflineStateHelp/ModalOfflineStateHelp";
import ModalReportProblem from "../ModalReportProblem/ModalReportProblem";
import ModalProblemReported from "../ModalProblemReported/ModalProblemReported";
import { FocusContext } from "@hapara/ui/src/components/utils";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

const dataTestPrefix = "hl-StudentTile-UserIsOffline-Modal";
const mainHeaderId = `${dataTestPrefix}-Header`;

export const REPORT_PROBLEM_MODAL_TYPES = {
  HELP: "help",
  REPORT_PROBLEM: "report problem",
  PROBLEM_REPORTED: "problem reported",
};

const StudentTileReportProblemModal = ({
  studentId,
  studentDisplayName,
  setIsModalOpen,
  modalState,
  showBackButton,
}) => {
  const [modalMode, setModalMode] = useState(modalState);
  const focusContext = useContext(FocusContext);
  const intl = useIntl();

  const getTitle = (modalType) => {
    switch (modalType) {
      case REPORT_PROBLEM_MODAL_TYPES.HELP:
        return intl.formatMessage({
          defaultMessage: "Why do students appear offline?",
          id: "DpIXZl",
        });

      case REPORT_PROBLEM_MODAL_TYPES.PROBLEM_REPORTED:
        return intl.formatMessage({
          defaultMessage: "Problem reported",
          id: "HfnVJH",
        });

      case REPORT_PROBLEM_MODAL_TYPES.REPORT_PROBLEM:
        return intl.formatMessage(
          {
            defaultMessage: `Report a problem for {studentDisplayName}`,
            id: "UCtHN2",
          },
          {
            studentDisplayName,
          }
        );
      default:
        return intl.formatMessage({
          defaultMessage: "Why do students appear offline?",
          id: "DpIXZl",
        });
    }
  };

  const handleCloseModal = () => {
    focusContext.restoreFocus();

    setIsModalOpen(false);
  };

  return (
    <div className={styles.root}>
      <ModalDeprecated
        isOpen={true}
        hasEmbeddedHeader={false}
        onClose={handleCloseModal}
        dataTestPrefix={dataTestPrefix}
        className={styles.modal}
        contentLabel={intl.formatMessage({
          defaultMessage: "Student is appearing offline",
          id: "MkhSeu",
        })}
      >
        <>
          <div className={styles.modalTitle}>
            {modalMode === REPORT_PROBLEM_MODAL_TYPES.REPORT_PROBLEM &&
              showBackButton && (
                <Button
                  icon="arrow-left"
                  onClick={() => {
                    setModalMode(REPORT_PROBLEM_MODAL_TYPES.HELP);
                  }}
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Back",
                    id: "cyR7Kh",
                  })}
                  data-test-id={`${dataTestPrefix}-Button-Back`}
                  className={styles.modalTitleBack}
                />
              )}
            <h1
              id={mainHeaderId}
              data-test-id={`${dataTestPrefix}-Header`}
              className={styles.modalTitleHeader}
            >
              {getTitle(modalMode)}
            </h1>
            <Button
              icon="cross"
              onClick={handleCloseModal}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
                id: "rbrahO",
              })}
              data-test-id={`${dataTestPrefix}-Button-Close`}
              className={styles.modalTitleClose}
            />
          </div>

          {modalMode === REPORT_PROBLEM_MODAL_TYPES.HELP && (
            <ModalOfflineStateHelp
              onReportProblem={() => {
                setModalMode(REPORT_PROBLEM_MODAL_TYPES.REPORT_PROBLEM);
              }}
              dataTestPrefix={`${dataTestPrefix}-Help`}
            />
          )}

          {modalMode === REPORT_PROBLEM_MODAL_TYPES.REPORT_PROBLEM && (
            <ModalReportProblem
              studentId={studentId}
              onReportIssue={() => {
                setModalMode(REPORT_PROBLEM_MODAL_TYPES.PROBLEM_REPORTED);
              }}
              onCancel={handleCloseModal}
              dataTestPrefix={`${dataTestPrefix}-ReportProblem`}
            />
          )}

          {modalMode === REPORT_PROBLEM_MODAL_TYPES.PROBLEM_REPORTED && (
            <ModalProblemReported
              onDone={handleCloseModal}
              dataTestPrefix={`${dataTestPrefix}-ProblemReported`}
            />
          )}
        </>
      </ModalDeprecated>
    </div>
  );
};

StudentTileReportProblemModal.propTypes = {
  studentId: PropTypes.string.isRequired,
  studentDisplayName: PropTypes.string.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  modalState: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
};

export default connect((state) => ({}))(StudentTileReportProblemModal);
