import React, { Profiler, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./ManageClassesPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import PropTypes from "prop-types";
import { getFFByName } from "../../../../state/app/selectors";
import { sendReactRenderStats } from "../../../../state/highlights/students/actions";
import usePerformanceStatsSendHook from "../../../../hooks/usePerformanceStatsSendHook";
import ModalsAndPanels from "../../../partial/Dashboard/ModalsAndPanels/ModalsAndPanels";
import { getLocationPath } from "../../../../state/router/selectors";
import {
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/dashboard/config/selectors";
import SearchClassesBar from "../../../partial/Dashboard/SearchClassesBar/SearchClassesBar";
import ClassesTiles from "../../../partial/Dashboard/ClassesTiles/ClassesTiles";
import { getSearchClassesQuery } from "../../../../state/dashboard/manage-classes/selectors";
import _ from "lodash";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { setSearchClassesQuery } from "../../../../state/dashboard/manage-classes/actions";
import { stopStatusPolling } from "../../../../state/dashboard/manage-classes/statusPollService";
import Page from "../../../partial/PageContainer/PageContainer";
import { MAIN_CONTENT_ID } from "../../../../consts";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import ConfigHandler from "../ConfigHandler";
import { useIntl } from "react-intl";

const ManageClassesPage = ({
  isMonitorPerformanceFF,
  sendReactRenderStats,
  locationPath,
  query = "",
  clearSearch,
  stopStatusPoll,
  isConfigLoaded,
  isConfigError,
}) => {
  const intl = useIntl();
  useEffect(() => {
    //stop polling for classes status updates if required
    return () => {
      stopStatusPoll();
    };
  }, [stopStatusPoll]);
  const onActivityRender = usePerformanceStatsSendHook({
    componentName: "ManageClasses",
    sendReactRenderStats,
    isMonitorPerformanceFF,
  });

  const isAllClasses =
    locationPath === APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.path;
  const page = isAllClasses
    ? APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL
    : APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY;

  return (
    <>
      <ConfigHandler />
      <Page
        title={page.title}
        header={<Header isClassDropDownHidden={true} />}
        footer={
          <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.DASHBOARD} />
        }
        isPageLoadingError={isConfigError}
        isPageLoaded={isConfigLoaded}
      >
        <ModalsAndPanels />

        <main
          className={styles.root}
          data-test-id={
            "td-Pages-ManageClasses-" + (isAllClasses ? "all" : "my")
          }
          id={MAIN_CONTENT_ID}
          tabIndex="-1"
        >
          <div className={styles.titleRow}>
            <div className={styles.titlePart}>
              <div className={styles.titleAndSearch}>
                <h1 className={styles.title}>
                  {isAllClasses
                    ? intl.formatMessage({
                        defaultMessage: "All Classes",
                        id: "HwTqox",
                      })
                    : intl.formatMessage({
                        defaultMessage: "My Classes",
                        id: "DJiFrq",
                      })}
                </h1>
                <SearchClassesBar allClasses={isAllClasses} />
              </div>

              {!_.isEmpty(query) && (
                <div className={styles.keywords}>
                  <span className={styles.keywordsList}>
                    <span>
                      {intl.formatMessage({
                        defaultMessage: "Search results for:",
                        id: "gc3R1T",
                      })}
                    </span>{" "}
                    {query}
                  </span>
                  <Button
                    label={intl.formatMessage({
                      defaultMessage: "Clear",
                      id: "/GCoTA",
                    })}
                    size={BUTTON_SIZES.SMALL}
                    type={BUTTON_TYPES.OUTLINED}
                    outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                    data-test-id="td-Pages-ManageClasses-Clear-Search"
                    onAction={() => {
                      clearSearch();
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <Profiler id="ManageClasses" onRender={onActivityRender}>
            <ClassesTiles allClasses={isAllClasses} />
          </Profiler>
        </main>
      </Page>
    </>
  );
};

ManageClassesPage.propTypes = {
  isMonitorPerformanceFF: PropTypes.bool.isRequired,
  sendReactRenderStats: PropTypes.func.isRequired,
  stopStatusPoll: PropTypes.func.isRequired,
  query: PropTypes.string,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isMonitorPerformanceFF: getFFByName("HAP-8438-Monitor-React-Performance")(
      state
    ),
    locationPath: getLocationPath(state),
    query: getSearchClassesQuery(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
  }),
  (dispatch) => ({
    sendReactRenderStats: (options) => dispatch(sendReactRenderStats(options)),
    clearSearch: () => dispatch(setSearchClassesQuery("")),
    stopStatusPoll: () => dispatch(stopStatusPolling()),
  })
)(ManageClassesPage);
