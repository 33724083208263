import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./StudentTileErrorState.module.scss";
import { FormattedMessage } from "react-intl";

interface Props {
  getData: () => void;
}

const StudentTileErrorState = ({ getData }: Props) => {
  return (
    <div className={styles.root}>
      <button className={styles.triggerButton} onClick={getData}>
        <div className={styles.state}>
          <FormattedMessage
            defaultMessage="Sorry, there was a problem loading this list."
            id="X7tfmr"
          />
        </div>
        <div className={styles.container}>
          <HapReactIcon svg="arrow-sync" height={14} width={14} />
          <div className={styles.reloadText}>
            <FormattedMessage defaultMessage="Reload list" id="pUyNZW" />
          </div>
        </div>
      </button>
    </div>
  );
};

export default StudentTileErrorState;
