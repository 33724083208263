import { FormattedMessage } from "react-intl";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import styles from "./UploadStatus.module.scss";
import {
  fetchUploadStatus,
  updateLastUploadedFileId,
} from "../../../../state/library/uploadStatus/actions.js";
import LinearProgress from "@mui/material/LinearProgress";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getLastUploadedFileId } from "../../../../state/library/uploadStatus/selectors";

const UPLOAD_STATUS = {
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  ERROR: "error",
};

const RESOURCES_RE_FETCH_TIMEOUT = 1500;

const ResourcesTable = ({
  title,
  desc,
  columns,
  resources,
  isLoading,
  isError,
  emptyText,
}) => {
  const [uniqId] = useState(uuidv4());

  return (
    <div className={styles.statusBlock}>
      <h2 className={styles.title} id={uniqId}>
        {title}
      </h2>
      <div className={styles.subTitle}>{desc}</div>
      {isLoading && !isError && (
        <div className={styles.loadingBlock}>
          <LinearProgress className={styles.progressbar} aria-hidden={true} />
          <div className={styles.text}>
            <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
          </div>
        </div>
      )}
      {!isLoading && isError && (
        <div className={styles.emptyBlock}>
          <HapReactIcon
            svg="fill-circle-exclamation"
            width={16}
            height={16}
            className={styles.icon}
          />
          <div className={styles.text}>
            <span>
              <FormattedMessage
                defaultMessage="Cannot fetch status."
                id="Nt74La"
              />
            </span>{" "}
            Please check your connection and try again.
          </div>
        </div>
      )}
      {!isLoading && !isError && resources.length > 0 && (
        <div className={styles.tableBlock} tabIndex={0}>
          <table cellSpacing={0} cellPadding={0} aria-labelledby={uniqId}>
            <thead>
              <tr>
                {columns.map((c, index) => (
                  <th key={index} scope="col">
                    {c}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resources.map((r) => (
                <tr key={r.ID}>
                  <td className={styles.fileName}>{r.FileName}</td>
                  <td>
                    {r.InitiatedDate
                      ? moment(r.InitiatedDate).format("MMM DD")
                      : "-"}
                  </td>
                  {r.Status === UPLOAD_STATUS.IN_PROGRESS && (
                    <td>{r.ProgessDescription}</td>
                  )}
                  {r.Status === UPLOAD_STATUS.COMPLETED && (
                    <td>
                      {r.CompletedDate
                        ? moment(r.CompletedDate).format("MMM DD")
                        : "-"}
                    </td>
                  )}
                  {r.Status === UPLOAD_STATUS.ERROR && <td>{r.Error}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && !isError && resources.length === 0 && (
        <div className={styles.emptyBlock}>
          <div className={styles.text}>
            <span>{emptyText}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const UploadStatus = ({
  fetchUploadStatus,
  lastUploadedFileId,
  updateLastUploadedFileId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchCount, setFetchCount] = useState(1);
  const [isError, setIsError] = useState(false);
  const [resourcesList, setResourcesList] = useState([]);

  const fetchCountRef = useRef(0);
  const fetchDelayRef = useRef(null);

  // clean-up timout on unmount
  useEffect(() => {
    return () => {
      if (fetchDelayRef.current) {
        window.clearTimeout(fetchDelayRef.current);
      }
    };
  }, []);

  // load the status data on load AND
  // if we came here after new file upload, keep loading until we get data about this file
  useEffect(() => {
    if (fetchCountRef.current !== fetchCount) {
      setIsLoading(true);

      fetchCountRef.current = fetchCount;

      fetchUploadStatus({})
        .then((resp) => {
          setResourcesList(resp);
          setIsLoading(false);

          if (lastUploadedFileId) {
            if (_.includes(_.map(resp, "ID"), lastUploadedFileId)) {
              // file info is found, clean up the last file id
              updateLastUploadedFileId(null);
            } else {
              // not found yet, load again
              fetchDelayRef.current = _.delay(
                () => setFetchCount(fetchCount + 1),
                RESOURCES_RE_FETCH_TIMEOUT
              );
            }
          }
        })
        .catch(() => {
          setIsError(true);
          if (lastUploadedFileId) {
            // clean up the last file id on error
            updateLastUploadedFileId(null);
          }
        });
    }
  }, [
    fetchUploadStatus,
    fetchCount,
    updateLastUploadedFileId,
    lastUploadedFileId,
  ]);

  if (lastUploadedFileId && !isError) {
    return (
      <div className={styles.waitingForFileRoot}>
        <LinearProgress
          className={styles.loadingProgressbar}
          aria-hidden={true}
        />
        <h1 className={styles.loadingTitle}>
          <FormattedMessage
            defaultMessage="Preparing to upload this file, please wait a few moments"
            id="bF15Pz"
          />
        </h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.mainTitle}>
        <FormattedMessage defaultMessage="Conversion status" id="St5aR3" />
      </h1>
      <div className={styles.mainDesc}>
        <FormattedMessage
          defaultMessage="Uploaded files are converted into an accessible, responsive and easy-to-read digital format."
          id="4afUZU"
        />
      </div>
      <ResourcesTable
        title="In progress"
        desc="The file conversion process may take a few days depending upon the number of pages submitted for processing."
        columns={["File name", "Initiated date", "Current status"]}
        resources={_.filter(
          resourcesList,
          (r) => r.Status === UPLOAD_STATUS.IN_PROGRESS
        )}
        isLoading={isLoading}
        isError={isError}
        emptyText="No files are being converted right now."
      />
      <ResourcesTable
        title="Incomplete with errors"
        desc="These files could not be converted. Please check the error before trying again. Incomplete files will stay on this list for 28 days."
        columns={["File name", "Initiated date", "Error description"]}
        resources={_.filter(
          resourcesList,
          (r) => r.Status === UPLOAD_STATUS.ERROR
        )}
        isLoading={isLoading}
        isError={isError}
        emptyText="There are no incomplete uploads."
      />
      <ResourcesTable
        title="Complete"
        desc="These files were successfully uploaded and converted! Files remain on this list for 28 days."
        columns={["File name", "Initiated date", "Completed date"]}
        resources={_.filter(
          resourcesList,
          (r) => r.Status === UPLOAD_STATUS.COMPLETED
        )}
        isLoading={isLoading}
        isError={isError}
        emptyText="There are no recent uploads."
      />
    </div>
  );
};

UploadStatus.propTypes = {
  lastUploadedFileId: PropTypes.string,
  fetchUploadStatus: PropTypes.func.isRequired,
  updateLastUploadedFileId: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    lastUploadedFileId: getLastUploadedFileId(state),
  }),
  (dispatch) => ({
    fetchUploadStatus: () => dispatch(fetchUploadStatus()),
    updateLastUploadedFileId: (id) =>
      dispatch(updateLastUploadedFileId({ id })),
  })
)(UploadStatus);
