import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ToolbarFreezeTabs.module.scss";
import Tippy from "@tippy.js/react";
import { showGuideBrowsingModal } from "../../../../state/highlights/sessions/actions";
import {
  SESSION_TYPES,
  MODAL_MODE_TYPES,
} from "../../../../state/highlights/sessions/types";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

const ToolbarFreezeTabs = ({ showGuideBrowsingModal }) => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "Freeze students on their current tabs",
          id: "T+6938",
        })}
        theme="hsuite"
      >
        <Button
          label={intl.formatMessage({
            defaultMessage: "Freeze tabs",
            id: "oU2Nkm",
          })}
          size={BUTTON_SIZES.XSMALL}
          type={BUTTON_TYPES.TERTIARY}
          className={styles.button}
          onClick={() => {
            showGuideBrowsingModal({
              modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
              sessionType: SESSION_TYPES.LOCK,
            });
          }}
          data-test-id="hl-HighlightsSubNav-Toolbar-FreezeTabs-Button"
        />
      </Tippy>
    </div>
  );
};

ToolbarFreezeTabs.propTypes = {
  showGuideBrowsingModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showGuideBrowsingModal: ({ modalMode, sessionType }) =>
      dispatch(showGuideBrowsingModal({ modalMode, sessionType })),
  })
)(ToolbarFreezeTabs);
