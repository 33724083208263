import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import styles from "./Textarea.module.scss";
import { THEME_TYPES } from "../consts";
import { focusSafelyByRef } from "../utils";

export const TEXTAREA_DEFAULT_MAX_LENGTH = 3600;

type TextareaProps = {
  label?: string;
  value: string;
  disabled?: boolean;
  rows?: number;
  className?: string;
  isErrorMode?: boolean;
  isAutoExpandable: boolean;
  themeType?: string;
  maxLength?: number;
  showLimitWarning?: boolean;
  focusOnTextarea?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  helperText?: string;
  isRequired?: boolean;
  dataTestId?: string;
  maxRows?: number;
};

const defaultProps: Partial<TextareaProps> = {
  value: "",
  disabled: false,
  rows: 3,
  isErrorMode: false,
  isAutoExpandable: false,
  maxLength: undefined,
  showLimitWarning: false,
  focusOnTextarea: false,
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const mergedProps = { ...defaultProps, ...props };
    const {
      label,
      value,
      disabled,
      rows,
      className,
      isErrorMode,
      isAutoExpandable,
      themeType,
      maxLength,
      helperText,
      showLimitWarning,
      focusOnTextarea,
      isRequired,
      dataTestId,
      maxRows,
      onBlur,
      ...rest
    } = mergedProps;

    const [textAreaRows, setTextAreaRows] = useState<number | undefined>(rows);
    const textAreaSpareRef = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = ref || textAreaSpareRef;

    const [uniqueHelperTextId] = useState(uuidv4());
    const [textareaUniqueId] = useState(uuidv4());

    const shouldShowLimitWarning =
      showLimitWarning &&
      value.length >= (maxLength || TEXTAREA_DEFAULT_MAX_LENGTH);
    const shouldShowHelperTextOrLimitWarning =
      shouldShowLimitWarning || helperText;

    const ariaDescribedbyId =
      _.get(rest, "aria-describedby") || shouldShowHelperTextOrLimitWarning
        ? uniqueHelperTextId
        : undefined;

    function isCallbackRef(
      ref: unknown
    ): ref is (instance: HTMLTextAreaElement | null) => void {
      return typeof ref === "function";
    }

    useEffect(() => {
      const isTextareaScrollable = !isCallbackRef(textAreaRef)
        ? textAreaRef.current &&
          textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight
        : false;

      if (
        isAutoExpandable &&
        (textAreaRows === undefined || isTextareaScrollable)
      ) {
        setTextAreaRows((prevRows) =>
          prevRows !== undefined
            ? maxRows
              ? Math.min(prevRows + 1, maxRows)
              : prevRows + 1
            : rows
        );
      }
    }, [value, textAreaRef, textAreaRows, rows, isAutoExpandable, maxRows]);

    useEffect(() => {
      if (focusOnTextarea) {
        focusSafelyByRef(textAreaRef, 300);
      }
    }, [focusOnTextarea]);

    return (
      <>
        {label && (
          <label htmlFor={textareaUniqueId} className={styles.label}>
            {label}{" "}
            {isRequired && (
              <span>
                <FormattedMessage defaultMessage="(required)" id="VG94fP" />
              </span>
            )}
          </label>
        )}
        <textarea
          id={textareaUniqueId}
          onBlur={onBlur}
          rows={textAreaRows}
          value={value}
          disabled={disabled}
          className={classnames(
            styles.root,
            styles[themeType || THEME_TYPES.LIGHT],
            { [styles.auto]: isAutoExpandable },
            { [styles.error]: isErrorMode },
            { [styles.disabled]: disabled },
            className
          )}
          ref={textAreaRef}
          maxLength={maxLength !== undefined ? maxLength : undefined}
          aria-describedby={ariaDescribedbyId}
          data-test-id={dataTestId}
          {...rest}
        />
        {shouldShowHelperTextOrLimitWarning ? (
          <div
            id={uniqueHelperTextId}
            data-test-id={`${dataTestId}-helper-text`}
          >
            {helperText && (
              <div
                className={classnames(
                  styles.lengthWarning,
                  styles[themeType || THEME_TYPES.LIGHT],
                  { [styles.errorText]: isErrorMode }
                )}
              >
                {helperText}
              </div>
            )}
            {showLimitWarning &&
              value.length >= (maxLength || TEXTAREA_DEFAULT_MAX_LENGTH) && (
                <div
                  className={classnames(
                    styles.lengthWarning,
                    styles[themeType || THEME_TYPES.LIGHT]
                  )}
                >
                  <FormattedMessage
                    defaultMessage="{maxLength} characters limit reached"
                    id="Ttjs0/"
                    values={{ maxLength }}
                  />
                </div>
              )}
          </div>
        ) : null}
      </>
    );
  }
);
