import { useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";

import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { NAVIGATION_ITEM_TYPE } from "../../../state/navigation/types";
import { connect } from "react-redux";
import { getNavigationItems } from "../../../state/navigation/selectors";
import styles from "./Navigation.module.scss";
import {
  getClassId,
  getActiveClassesSubjectFolders,
} from "../../../state/shared/selectors";
import { setNavigationByClassId } from "../../../state/navigation/actions";
import { getLocationPath } from "../../../state/router/selectors";
import { getDataTestIdFromRawString } from "../../../utils";
import {
  getUserPermissions,
  isSharingViewEnabled,
} from "../../../state/user/selectors";
import { useSelector } from "react-redux";

export const Navigation = ({
  items,
  permissions,
  selectedClassId,
  locationPath,
  setNavigationByClassId,
  isSharingViewEnabled,
}) => {
  const subjectFolders = useSelector(getActiveClassesSubjectFolders);

  const { classId } = useParams();
  const activeClassId = selectedClassId || classId;

  useEffect(() => {
    const defaultFolder =
      subjectFolders?.length > 0 ? subjectFolders[0] : "Documents";
    setNavigationByClassId(activeClassId, defaultFolder);
  }, [selectedClassId, activeClassId, subjectFolders]);

  const getNavigationItem = useCallback(
    (item) => {
      if (
        item.permissions &&
        item.permissions.length &&
        !_.intersection(permissions, item.permissions).length
      ) {
        return null;
      }

      const isItemActive =
        (!item.isExternal &&
          item.basePaths &&
          item.basePaths.length &&
          item.basePaths.some((path) => locationPath.startsWith(path))) ||
        item.path === locationPath;

      if (item.isExternal)
        return (
          <a
            href={item.path}
            key={item.id}
            className={styles.navigationItem}
            data-test-id={`Ws-Navigation-${getDataTestIdFromRawString(
              item.testId
            )}`}
          >
            {item.label}
          </a>
        );

      return (
        <Link
          to={item.path}
          key={item.id}
          className={classnames(styles.navigationItem, {
            [styles.active]: isItemActive,
          })}
          data-test-id={`Ws-Navigation-${getDataTestIdFromRawString(
            item.testId
          )}`}
        >
          {item.label}
        </Link>
      );
    },
    [locationPath, permissions]
  );
  return (
    <div className={styles.root}>
      <nav
        className={styles.content}
        aria-label="Main"
        data-test-id="Ws-Navigation-Container"
      >
        {_.map(items, (item) => {
          if (item.id !== "TD_SH") {
            return getNavigationItem(item);
          } else if (isSharingViewEnabled) {
            return getNavigationItem(item);
          }
        })}
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(NAVIGATION_ITEM_TYPE),
  permissions: PropTypes.arrayOf(PropTypes.string),
  selectedClassId: PropTypes.string,
  locationPath: PropTypes.string.isRequired,
  setNavigationByClassId: PropTypes.func.isRequired,
  isSharingViewEnabled: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    items: getNavigationItems(state),
    permissions: getUserPermissions(state),
    selectedClassId: getClassId(state),
    locationPath: getLocationPath(state),
    isSharingViewEnabled: isSharingViewEnabled(state),
  }),
  (dispatch) => ({
    setNavigationByClassId: (classId, defaultSubjectFolder) =>
      dispatch(setNavigationByClassId(classId, defaultSubjectFolder)),
  })
)(Navigation);
