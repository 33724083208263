import { decodeAuthToken } from "./decodeAuthToken";

export const getUserFromAuthToken = (token: string) => {
  try {
    const { email, fname, lname, picture, sub, masq, roles } =
      decodeAuthToken(token).payload;

    const name = `${fname} ${lname}`;
    return {
      email,
      name,
      givenName: fname,
      familyName: lname,
      avatarUrl: picture,
      id: sub,
      masqueradeEmail: masq ?? null,
      roles,
    };
  } catch (err) {
    throw new Error("[auth] Cannot get user from invalid auth token.");
  }
};
