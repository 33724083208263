"use client";
import React, { createContext, useEffect, useState } from "react";
import {
  retrieveLocalUser,
  storeLocalUser,
  clearLocalStorage,
} from "./utils/localUser";
import { env } from "../../utils/getHaparaEnv";
import { User } from "./types/User";
import { AuthScope } from "./types/AuthScope";
import { ApiResponse } from "./types/ApiResponse";
import { transformUser } from "./utils/transformUser";
import { MasqueradeIndicator } from "./MasqueradeIndicator";
import { httpStatus } from "./httpStatus";
import { InitResponseType } from "../FeatureFlagProvider/types";
import { getRelativeSecondsTo } from "./utils/timeUtils";
import { usePrevious } from "../../hooks/usePrevious";
import { fetchToken } from "./api/fetchToken";

type AuthFetchOptions = RequestInit & {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
};

// TODO: Check why this is redeclared below and isn't used.
export type AuthFetch = <T = {}>(
  url: string,
  options?: AuthFetchOptions
) => Promise<ApiResponse<T>>;

export type AuthContextState = {
  user: User | null;
  isAuthenticated: boolean;
  isMasquerading: boolean | null;
  authToken?: string | null;
  /**
   * ### Common `40X` HTTP Status Codes
   * | Code  | Status           | Description                                        |
   * | ----- | ---------------- | -------------------------------------------------- |
   * | `401` | `Unauthorized*`  | _Who are you?_ Please log in.                      |
   * | `403` | `Forbidden*`     | I know who you are, but _you're not allowed here_. |
   * | `404` | `Not Found`      | That _never_ existed.                              |
   * | `410` | `Gone`           | That _used to be here_, but not anymore.           |
   *
   * [* Unauthenticated vs unauthorized confusion](https://codecoach.co.nz/wrongly-named-http-status-code/)
   */
  authFetch: <T>(
    url: string,
    options?: AuthFetchOptions
  ) => Promise<ApiResponse<T>>;
  signOut: (options?: { redirectUri?: string }) => void;
};

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);

export type AuthProviderProps = {
  children: React.ReactNode;
  scope?: AuthScope;
  /** @deprecated This is used for older apps that have not migrated to the new
   * micro-frontend auth and handle redirects to their custom auth flows. */
  skipAuthRedirect?: boolean;
  /** There's the assumption we can't include all classes for a teacher in the
   * JWT due to size limitations, so currently we need to retrieve a special JWT
   * for class context aware endpoints. */
  classId?: string;
  // In order to simplify providing environment variables, we are considering
  // using props instead. This will mean we won't have to configure React App /
  // NextJS config to use non prefixed environment variables.
  /** @deprecated Do NOT use yet, still deciding on API! */
  haparaAuthAppUrl?: string;
  /** @deprecated Do NOT use yet, still deciding on API! */
  haparaApiUrl?: string;
  /** @deprecated Do NOT use yet, still deciding on API! */
  haparaAuthApiEndpoint?: string;
};

export const AuthProvider = ({
  children,
  scope,
  classId,
  skipAuthRedirect,
  haparaAuthAppUrl,
  haparaApiUrl,
  haparaAuthApiEndpoint,
}: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const previousAuthToken = usePrevious(authToken);
  const [authExpiresAt, setAuthExpiresAt] = useState<number | null>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [isMasquerading, setMasquerading] = useState<boolean | null>(null);
  const [featureFlags, setFeatureFlags] = useState<
    InitResponseType["flags"] | null
  >(null);
  const previousClassId = usePrevious(classId);

  const handleAuthRedirect = () => {
    if (!skipAuthRedirect) {
      const url = new URL(haparaAuthAppUrl ?? env.HAPARA_AUTH_APP_URL);

      url.searchParams.append("redirect_uri", window.location.href);
      if (scope) {
        url.searchParams.append("scope", scope);
      }

      window.location.assign(url.toString());
    }
  };

  const handleTokenRefresh = async () => {
    if (classId !== previousClassId) {
      setAuthenticated(false);
    }
    const response = await fetchToken({
      haparaAuthApiEndpoint,
      scope,
      classId,
    });

    // TODO: handle 403. Replace AuthProvider children with generic unauthorized
    // message if a 403 is received when trying to get a token.

    if (response.ok) {
      const data = await response.json();
      const refreshedAuthToken = data.access_token;
      if (refreshedAuthToken !== previousAuthToken) {
        setAuthToken(refreshedAuthToken);
        setAuthExpiresAt(data.at_expires);
        setAuthenticated(true);
      }
    } else {
      handleClearLocalStorage();
      handleAuthRedirect();
    }
  };

  const handleFetchUser = async () => {
    if (authToken !== null && authToken !== undefined) {
      try {
        const response = await authFetch<InitResponseType>(
          `${haparaApiUrl ?? env.HAPARA_API_URL}/admin/config/init`
        );

        // We can't use the FeatureFlagsProvider as the user needs to be authenticated
        // first, this relies on "/admin/config/init" providing the feature flags.
        if (response.ok) {
          setFeatureFlags(response.data.flags);
        }

        const user = transformUser(response.data, authToken);
        setUser(user);
        storeLocalUser(user);
        setMasquerading(
          user.masqueradeEmail !== null && user.masqueradeEmail !== ""
        );
      } catch (error) {
        setUser(null);
        clearLocalStorage();
      }
    }
  };

  const handleClearLocalStorage = () => {
    clearLocalStorage();
    setUser(null);
  };

  useEffect(() => {
    setUser(retrieveLocalUser());
    handleTokenRefresh();
  }, [classId]);

  useEffect(() => {
    handleFetchUser();
  }, [authToken]);

  useEffect(() => {
    // TODO: add tests BEFORE removing this feature flag!
    if (
      authExpiresAt &&
      featureFlags?.["PS-1075-token-refresh-before-expiry"]
    ) {
      const interval = setInterval(() => {
        const timeLeft = getRelativeSecondsTo(authExpiresAt);
        const timeThreshold = 60 * 2;
        if (authExpiresAt && timeLeft < timeThreshold) {
          console.info("[auth] Refreshing token before expiry (FF PS-1075).");
          handleTokenRefresh();
        }
      }, 15_000);
      return () => clearInterval(interval);
    }
  }, [authExpiresAt, featureFlags]);

  const authFetch: AuthFetch = async (url, options) => {
    const authorizationHeader = authToken ? `Bearer ${authToken}` : "";

    let response: Response | undefined = undefined;

    const performAuthFetch = async () => {
      return await fetch(url, {
        ...options,
        method: options?.method || "GET",
        headers: {
          ...options?.headers,
          Authorization: authorizationHeader,
        },
      });
    };

    response = await performAuthFetch();

    // TODO: possibly check if JWT has expired before performing fetch and if so request a new refresh token.
    if (response.status === httpStatus.unauthenticated) {
      await handleTokenRefresh();
      response = await performAuthFetch();
    }

    const data =
      response.status === httpStatus.successNoContent
        ? {}
        : await response.json();

    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      isUnauthorized: response.status === httpStatus.unauthorized,
      headers: response.headers,
      data,
    };
  };

  const signOut = ({ redirectUri }: { redirectUri?: string } = {}) => {
    window.location.assign(
      `${haparaAuthAppUrl ?? env.HAPARA_AUTH_APP_URL}/sign-out${
        redirectUri ? `?redirect_uri=${redirectUri}` : ""
      }`
    );
    handleClearLocalStorage();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        authToken,
        authFetch,
        signOut,
        isMasquerading,
      }}
    >
      {isMasquerading ? <MasqueradeIndicator /> : null}
      {children}
    </AuthContext.Provider>
  );
};
