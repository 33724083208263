import { useIntl } from "react-intl";
import { DisplayNameProps } from "./DisplayName.types";

export const useDisplayName = ({
  firstName,
  lastName,
  role,
  order = "firstName-lastName",
}: DisplayNameProps): string => {
  const intl = useIntl();

  if (role === "teacher") {
    return intl.formatMessage(
      {
        defaultMessage: "Teacher {surname}",
        id: "HGFoAi",
      },
      { surname: lastName }
    );
  } else {
    return order === "firstName-lastName"
      ? [firstName, lastName].filter(Boolean).join(" ")
      : [lastName, firstName].filter(Boolean).join(", ");
  }
};

export const DisplayName = (props: DisplayNameProps) => {
  const displayName = useDisplayName(props);
  return displayName;
};
