import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
// TODO: replace with `utils/generateHash`
import sha256 from "hash.js/lib/hash/sha/256";
import { getGoogleResourceId } from "../utils/getGoogleResourceId";

export const MOBILE_VIEW_BREAKPOINT = 768;

/**
 * generating the random integer in the range
 */
export function getRandomInt(min, max) {
  return min + Math.floor(Math.random() * (max - min + 1));
}

/**
 * getting the color code for the group
 * @param group group object
 */
export function getColorCode(group) {
  if (group.color) {
    return group.color;
  }

  if (group.css) {
    return group.css;
  }

  let parts;
  let code;

  if (group.urn) {
    parts = group.urn.split(":");
  }
  if (group.referenceGroup) {
    parts = group.referenceGroup.split(":");
  }

  if (parts) {
    code = parseInt(parts[parts.length - 1]) + 1;
    return "sharedgroup_10_" + code;
  }

  code = getRandomInt(0, 42);
  return "groupColour_" + code;
}

export function generateId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/// returns the view by reading the location OR (if supplied) by viewId
export function getView(arrViews, viewId = null) {
  const item = _.find(arrViews, { id: viewId }) || arrViews[0];

  if (viewId.indexOf("S_") === 0) {
    if (viewId === "S_5") {
      return `Dashboard Sharing (Deleted Docs)`;
    }
    return `Dashboard Sharing (${item.title})`;
  }

  if (viewId.indexOf("CB_") === 0) {
    return "Highlights";
  }

  if (viewId.indexOf("G_") === 0) {
    return `Dashboard Gmail (${item.title})`;
  }

  return `Dashboard (${item.title})`;
}

// returns a Promise when a global var from <path> gets available
export const waitForGlobal = (path) =>
  new Promise((resolve) => {
    const doCheck = () => {
      if (!_.get(window, path)) {
        _.delay(doCheck, 0);
      } else {
        resolve();
      }
    };

    doCheck();
  });

export const PENDO_DATA_TYPE = PropTypes.shape({
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    has_feature_Analytics: PropTypes.bool,
    has_feature_Dashboard: PropTypes.bool,
    has_feature_DigitalBackpack: PropTypes.bool,
    has_feature_GCAnalytics: PropTypes.bool,
    has_feature_Highlights: PropTypes.bool,
    has_feature_StudentDashboard: PropTypes.bool,
    has_feature_Workspace: PropTypes.bool,
    has_feature_WorkspacePrivateLibrary: PropTypes.bool,
  }),
  visitor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    mayTeach_DataLoader: PropTypes.bool,
    mayTeach_GoogleClassroom: PropTypes.bool,
    mayTeach_SelfRostering: PropTypes.bool,
    role_AnalyticsUser: PropTypes.bool,
    role_DRM: PropTypes.bool,
    role_DistrictAdmin: PropTypes.bool,
    role_SchoolAdmin: PropTypes.bool,
    role_Student: PropTypes.bool,
    role_SuperAdmin: PropTypes.bool,
    role_SysAdmin: PropTypes.bool,
    role_Teacher: PropTypes.bool,
    teaches_DataLoader: PropTypes.bool,
    teaches_GoogleClassroom: PropTypes.bool,
    school_code: PropTypes.string,
    school_name: PropTypes.string,
  }),
});

export const formatForPendo = (id, email, userFeatures) => {
  const data = userFeatures || {};
  const visitor = {};
  visitor.id =
    id ||
    _.get(data, "MasqUser") ||
    _.get(data, "ID") ||
    sha256().update(email).digest("hex") ||
    "";

  visitor.email = _.toLower(email);
  visitor.name = `${_.get(data, "FirstName", "")} ${_.get(
    data,
    "LastName",
    ""
  )}`;
  visitor.firstName = _.get(data, "FirstName", "");
  visitor.lastName = _.get(data, "LastName", "");

  const userSchools = _.get(data, "Schools", []);
  if (!_.isEmpty(userSchools)) {
    visitor.school_code = userSchools[0].Code;
    visitor.school_name = userSchools[0].Name;
  }

  const roles = _.get(data, "Roles");

  if (_.isObject(roles)) {
    let roleKeys = _.keys(roles);

    roleKeys.forEach((key) => {
      visitor[`role_${key}`] = roles[key];
    });
  }

  const classTypesTaught = _.get(data, "ClassTypesTaught");

  if (_.isObject(classTypesTaught)) {
    const classTypesT = _.keys(classTypesTaught);
    classTypesT.forEach((key) => {
      visitor[`teaches_${key}`] = classTypesTaught[key];
    });
  }

  const classTypesAvailable = _.get(data, "ClassTypesAvailable");

  if (_.isObject(classTypesAvailable)) {
    const classTypesA = _.keys(classTypesAvailable);
    classTypesA.forEach((key) => {
      visitor[`mayTeach_${key}`] = classTypesAvailable[key];
    });
  }

  const domains = _.get(data, "Domains");

  const account = {
    id: _.isArray(domains) && domains.length > 0 ? domains[0] : "",
  };

  const features = _.get(data, "Features");

  if (_.isObject(features)) {
    const featureKeys = _.keys(data.Features);
    featureKeys.forEach((key) => {
      account[`has_feature_${key}`] = data.Features[key];
    });
  }

  return { visitor, account };
};

export const getDataTestIdFromTitle = (title) => {
  return title
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase())
    .replace(/\s+/g, "-");
};

export const stripHtmlTagsFromString = (rawString) => {
  const regExp = /(<([^>]+)>)/gi;
  return rawString ? rawString.replace(regExp, "") : "";
};

export const stripNonWhitelistedTagsFromString = (rawString) => {
  const blackListedRegExpTags =
    /(<u>|<\/u>|<center>|<\/center>|<br>|<\/br>|<hr>|<\/hr>|<h1.*?>|<\/h1>|<h2.*?>|<\/h2>|<h3.*?>|<\/h3>|<h4.*?>|<\/h4>|<marquee.*?>|<\/marquee>|<sup.*?>|<\/sup>|<sub.*?>|<\/sub>|<font>|<\/font>|<span.*?>|<\/span>|<a.*?>|<\/a>)/gi;

  return rawString.replace(blackListedRegExpTags, "");
};

export const getGoogleDocId = (url) => {
  console.warn(
    "'getGoogleDocId' is deprecated, use 'getGoogleResourceId' instead"
  );
  return getGoogleResourceId(url);
};

export const isLink = (url) => {
  return _.startsWith(url, "http://") || _.startsWith(url, "https://");
};

export const isImageData = (image) => {
  return _.startsWith(image, "data:image");
};

export const isInternalLink = (url) => {
  return (
    url.indexOf("file://") === 0 ||
    url.indexOf("chrome://") === 0 ||
    url.indexOf("chrome-extension://") === 0 ||
    url === "extensions" ||
    url === "settings"
  );
};

export const isRestrictedScreenshotUrl = (url) => {
  const blockedHostnames = ["meet.google.com", "hangouts.google.com"];

  let urlToCheck = url;

  if (!urlToCheck) return false;

  if (!/^https?:\/\//i.test(urlToCheck)) {
    urlToCheck = "http://" + urlToCheck;
  }
  try {
    const domain = new URL(urlToCheck).hostname;

    return _.includes(blockedHostnames, domain);
  } catch (e) {
    return false; //could happen for some urls that are not proper urls, for example view-source:file://
  }
};

export const isBlacklistedUrl = (url) => {
  return url.indexOf("https://chrome.google.com/webstore") === 0;
};

export const isIgnoredInTabsUrl = (url) => {
  return !(
    url.indexOf("https://") === 0 ||
    url.indexOf("http://") === 0 ||
    url.indexOf("file://") === 0 ||
    url.indexOf("chrome://") === 0 ||
    (url.indexOf("chrome-extension://") === 0 &&
      !/[a-z]{32}\/(closed|popup-message)\.html.*$/.test(url))
  );
};

export const getJWTRequestBody = (selectedClassId) => {
  return {
    frontend: `${process.env.REACT_APP_BUILD_NUMBER}`,
    class: selectedClassId,
  };
};

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const isBlackFont = (color) => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    return true;
  }
  return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 100;
};

export const FocusContext = React.createContext({});

export const DOCUMENT_TYPES = {
  PDF: {
    mimeTypes: ["application/pdf"],
    type: "pdf",
    iconAppendix: "pdf",
  },
  DOC: {
    mimeTypes: [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.google-apps.document",
    ],
    type: "document",
    iconAppendix: "document",
  },
  SITE: {
    mimeTypes: ["application/vnd.google-apps.drive-sdk.897606708560"],
    type: "site",
    iconAppendix: "site",
  },
  FORM: {
    mimeTypes: ["application/vnd.google-apps.form"],
    type: "form",
    iconAppendix: "form",
  },
  SHEET: {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.google-apps.spreadsheet",
      "application/vnd.ms-excel",
    ],
    type: "spreadsheets",
    iconAppendix: "spreadsheet",
  },
  PRESO: {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.google-apps.presentation",
      "application/vnd.ms-powerpoint",
    ],
    type: "presentation",
    iconAppendix: "presentation",
  },
  DRAWING: {
    mimeTypes: [
      "application/vnd.google-apps.drawing",
      "application/vnd.google-apps.drawing",
    ],
    type: "drawings",
    iconAppendix: "drawing",
  },
  IMAGE: {
    mimeTypes: ["image/jpg", "image/jpeg", "image/png", "image/gif"],
    type: "file",
    iconAppendix: "drawing",
  },
};

export const getDocumentIconByMimeType = (mimeType) => {
  let icon = "google-document"; //default
  Object.keys(DOCUMENT_TYPES).forEach((t) => {
    const mimeTypes = DOCUMENT_TYPES[t].mimeTypes;
    if (mimeTypes.indexOf(mimeType) >= 0) {
      icon = `google-${DOCUMENT_TYPES[t].iconAppendix}`;
    }
  });
  return icon;
};

export const getPlural = (amount, word) => {
  const appendix = word.substr(-1) === "s" ? "es" : "s";
  return amount === 0 ? "" : `${amount} ${word}${amount > 1 ? appendix : ""}`;
};

export const focusSafelyByRef = (ref, delay) =>
  _.delay(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, delay || 200);

const TEST_ENV = "test";
const PROD_ENV = "production";
const DEVELOPMENT_ENV = "development";

const getCurrentEnv = () => {
  const host = window.location.hostname || ""; // eslint-disable-line
  if (host.match(/-test/)) {
    return TEST_ENV;
  } else if (host.match(/localhost/)) {
    return DEVELOPMENT_ENV;
  }
  return PROD_ENV;
};

export const isProduction = () => {
  return getCurrentEnv() === PROD_ENV;
};
