import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//ACTIONS
import {
  renameClass,
  resetRenameClassState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsRenameClassLoading,
  getIsRenameClassError,
  getIsRenameClassLoaded,
  getClassInfoData,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  isRenameClassError: boolean;
  isRenameClassLoaded: boolean;
  isRenameClassLoading: boolean;
  handleRenameClass: (name: string) => void;
};

type UseClassInfoRenameClasssProps = {
  closeModal: () => void;
  isModalOpen: boolean;
};

export const useClassInfoRenameClass = ({
  closeModal,
  isModalOpen,
}: UseClassInfoRenameClasssProps): ExportedValues => {
  const dispatch = useDispatch();
  const isRenameClassLoading = useSelector(getIsRenameClassLoading);
  const isRenameClassError = useSelector(getIsRenameClassError);
  const isRenameClassLoaded = useSelector(getIsRenameClassLoaded);
  const classGroupId = useSelector(getClassInfoData)?.classGroupId;
  const classGroupURN = useSelector(getClassInfoData)?.classGroupURN;
  const { classId } = useParams<{ classId: string }>();
  const classDataSource = useSelector(getClassInfoData)?.classDataSource;

  const handleRenameClass = (newClassName: string) => {
    dispatch(
      renameClass(
        classDataSource === "SelfRostered"
          ? { classGroupURN, newClassName, classId }
          : { classGroupId, newClassName, classId }
      )
    );
  };

  useEffect(() => {
    if (!isRenameClassLoading && isRenameClassLoaded && !isRenameClassError) {
      closeModal();
    }
  }, [isRenameClassLoading, isRenameClassLoaded, isRenameClassError]);

  useEffect(() => {
    dispatch(resetRenameClassState());
  }, [isModalOpen]);

  return {
    isRenameClassError,
    isRenameClassLoaded,
    isRenameClassLoading,
    handleRenameClass,
  };
};
