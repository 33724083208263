import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./ItemLoading.module.scss";

const ItemLoading = ({ className }) => {
  return <div className={classnames(styles.default, className)} />;
};

ItemLoading.propTypes = {
  className: PropTypes.string,
};

export default ItemLoading;
