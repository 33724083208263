import styles from "./SideNavItem.module.scss";

export const LoadingState = () => {
  return (
    <div className={styles.loadingState}>
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
    </div>
  );
};
