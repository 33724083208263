import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./GradingWorkLoading.module.scss";

export const GradingWorkLoading = ({ next, assigneeItemTitle }) => {
  return (
    <div className={styles.root}>
      <LinearProgress className={styles.progressbar} aria-hidden={true} />
      <div className={styles.body}>
        Loading the {next ? "next" : ""} {assigneeItemTitle}'s work
      </div>
    </div>
  );
};

GradingWorkLoading.props = {
  next: PropTypes.bool,
  assigneeItemTitle: PropTypes.string.isRequired,
};

export default GradingWorkLoading;
