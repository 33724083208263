import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./StudentTileMultipleUsersModal.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { FormattedMessage, useIntl } from "react-intl";

const StudentTileMultipleUsersModal = ({
  users,
  studentDisplayName,
  setIsModalOpen,
}) => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <ModalDeprecated
        isOpen={true}
        title={intl.formatMessage({
          defaultMessage: "Multiple logins detected",
          id: "iNfvSy",
        })}
        onClose={() => {
          setIsModalOpen(false);
        }}
        dataTestPrefix="hl-StudentTile-MultipleUsers"
        className={styles.modal}
      >
        <div className={styles.modalBody}>
          <div className={styles.studentName}>
            <span>{studentDisplayName}</span>
          </div>
          <p>
            <FormattedMessage
              defaultMessage="Multiple students are signed into the same Chromebook or Chrome browser."
              id="xjGSdx"
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="To see activity for one student, ask that student to sign all other accounts out of Google on their device."
              id="5WQwls"
            />
          </p>
          <p>
            <a
              href="https://support.hapara.com/hc/en-us/articles/360057699312"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.helpLink}
            >
              <FormattedMessage
                defaultMessage="Need help? Visit our help center"
                id="LbZWna"
              />
            </a>
          </p>
          <div className={styles.accounts}>
            <h2 className={styles.accountsTitle}>
              <FormattedMessage
                defaultMessage="Accounts detected on this device:"
                id="fvB1b3"
              />
            </h2>
            {_.map(users, (email) => (
              <div key={email} className={styles.accountItem}>
                <div className={styles.accountItemIcon}>
                  <HapReactIcon svg="profile-solo" width={16} height={16} />
                </div>
                <div className={styles.accountItemEmail}>{email}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalDeprecated>
    </div>
  );
};

StudentTileMultipleUsersModal.propTypes = {
  users: PropTypes.arrayOf(PropTypes.string),
  studentDisplayName: PropTypes.string.isRequired,
};

export default StudentTileMultipleUsersModal;
