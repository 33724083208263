import React from "react";
import styles from "./DiscoverPage.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../partial/Header/Header";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";
import Page from "../../../partial/PageContainer/PageContainer";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import MetadataFilters from "../../../partial/Resources/MetadataFilters/MetadataFilters";
import PageLeftPanel from "../../../partial/PageLeftPanel/PageLeftPanel";
import { APP_PAGES } from "../../../../state/router/types";
import {
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/library/config/selectors";
import { hasWSAccess } from "../../../../state/user/selectors";
import { getDiscoverLeftPanelIsOpen } from "../../../../state/library/discover/selectors";
import { toggleDiscoverLeftPanel } from "../../../../state/library/discover/actions";
import ModalsAndPanels from "../../../partial/Resources/ModalsAndPanels/ModalsAndPanels";
import FiltersLeftPanel from "../../../partial/Resources/FiltersLeftPanel/FiltersLeftPanel";
import DiscoverLanding from "../../../partial/Discover/DiscoverLanding/DiscoverLanding";
import ConfigHandler from "../../Library/ConfigHandler";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";
import { getWindowSize } from "../../../../state/app/selectors";

export const DiscoverPage = ({
  hasWSAccess,
  isLeftPanelOpen,
  toggleLeftPanel,
  isConfigLoaded,
  isConfigError,
  windowSize,
}) => {
  const dataTestIdPrefix = "lb-Discover";

  const headerToShow = hasWSAccess ? (
    <Header />
  ) : (
    <HeaderAccountOnly rootPageLink={APP_PAGES.MY_RESOURCES_STARRED.path} />
  );
  return (
    <>
      <ConfigHandler />
      <Page
        header={headerToShow}
        title={APP_PAGES.WORKSPACE_DISCOVER.title}
        isPageLoaded={isConfigLoaded}
        isPageLoadingError={isConfigError}
      >
        <ModalsAndPanels />
        {windowSize < WINDOW_SIZE_TYPES.M && <FiltersLeftPanel />}

        <div className={styles.root} data-test-id="lb-Pages-DiscoverPage">
          <div>
            <LibrarySubMenu />
          </div>
          <div className={styles.content}>
            {windowSize >= WINDOW_SIZE_TYPES.M && (
              <PageLeftPanel
                isOpen={isLeftPanelOpen}
                focusableElementId={null}
                dataTestIdPrefix={dataTestIdPrefix}
                landmarkLabel="Discover filters"
                closeButtonLabel="Close my discover filters panel"
                onClose={() => toggleLeftPanel(false)}
              >
                <MetadataFilters showApplyButton={false} />
              </PageLeftPanel>
            )}
            <DiscoverLanding />
          </div>
        </div>
      </Page>
    </>
  );
};

DiscoverPage.propTypes = {
  hasWSAccess: PropTypes.bool.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
  toggleLeftPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    hasWSAccess: hasWSAccess(state),
    isLeftPanelOpen: getDiscoverLeftPanelIsOpen(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleDiscoverLeftPanel(isOpen)),
  })
)(DiscoverPage);
