// TODO: Lift and share these utils https://hapara.atlassian.net/browse/HAP-10821
const rg4js = (...args) => {
  if (window.rg4js) {
    window.rg4js(...args);
  }
};

export function initRaygun({ apiKey, version }) {
  rg4js("apiKey", apiKey);
  rg4js("enableCrashReporting", true);
  rg4js("setVersion", version);
  rg4js("options", {
    ignore3rdPartyErrors: true,
  });
}

export function setRaygunUser(user) {
  if (user && user.signedIn) {
    rg4js("setUser", {
      identifier: user.email,
      isAnonymous: false,
      email: user.email,
      firstName: user.givenName,
      // TODO: This doesn't seem correct. This implementation is the same in student-dashboard.
      fullName: user.familyName,
    });
  } else {
    rg4js("setUser", {
      isAnonymous: true,
    });
  }
}

export function onBeforeSendRUM() {
  rg4js("onBeforeSendRUM", filterBeforeSend);
}

export function sendError(error, tags) {
  rg4js("send", {
    error,
    tags,
  });
}

export function filterBeforeSend(payload) {
  return payload;
}
