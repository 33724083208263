import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./LibrarySideNav.module.scss";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import SideNavItem, { SideNavDivider } from "../../SideNavItem/SideNavItem";
import { APP_PAGES } from "../../../../state/router/types";
import { LIBRARY_CATEGORIES } from "../../../../state/library/library/types";
import { collectionItemType } from "../../../../state/library/collections/types";
import { getLibraryLeftPanelIsOpen } from "../../../../state/library/library/selectors";
import CollectionsList from "../CollectionsList/CollectionsList";
import {
  areCollectionsLoading,
  areCollectionsLoaded,
  isCollectionsLoadingError,
  getLibraryCollections,
  getSelectedCollectionId,
} from "../../../../state/library/collections/selectors";
import { loadLibraryCollections } from "../../../../state/library/collections/actions";

const LibrarySideNav = ({
  collections,
  areCollectionsLoading,
  areCollectionsLoaded,
  isCollectionsLoadingError,
  loadLibraryCollections,
  isLeftSidePanelOpen,
  selectedCollectionId,
}) => {
  const allLink = `${APP_PAGES.LIBRARY_CATEGORIES.path}/${LIBRARY_CATEGORIES.ALL.urlKey}`;
  const isAllActive = selectedCollectionId === LIBRARY_CATEGORIES.ALL.urlKey;

  const uncategorizedLink = `${APP_PAGES.LIBRARY_CATEGORIES.path}/${LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey}`;
  const isUncategorizedActive =
    selectedCollectionId === LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey;

  const isCollectionActive =
    !_.isEmpty(selectedCollectionId) && !isAllActive && !isUncategorizedActive;

  useEffect(() => {
    if (!areCollectionsLoading && !areCollectionsLoaded) {
      loadLibraryCollections(); // init on page load
    }
  }, [areCollectionsLoading, areCollectionsLoaded, loadLibraryCollections]);

  return (
    <div
      className={classnames(styles.root, {
        [styles.open]: isLeftSidePanelOpen,
      })}
    >
      <div className={styles.scrollableArea}>
        <div className={styles.content}>
          <SideNavItem
            to={allLink}
            icon={
              isAllActive
                ? LIBRARY_CATEGORIES.ALL.iconActive
                : LIBRARY_CATEGORIES.ALL.icon
            }
            title="All"
            color="violet"
            isActive={isAllActive}
          />
          <SideNavItem
            to={uncategorizedLink}
            icon={
              isUncategorizedActive
                ? LIBRARY_CATEGORIES.UNCATEGORIZED.iconActive
                : LIBRARY_CATEGORIES.UNCATEGORIZED.icon
            }
            title="Uncategorized"
            color="violet"
            isActive={isUncategorizedActive}
          />
          <SideNavDivider />
          <CollectionsList
            active={
              isCollectionActive && !isAllActive && !isUncategorizedActive
            }
            selectedCollection={selectedCollectionId}
            isLoading={areCollectionsLoading}
            isError={isCollectionsLoadingError}
            collections={collections}
            loadCollections={loadLibraryCollections}
          />
        </div>
      </div>
    </div>
  );
};

LibrarySideNav.propTypes = {
  collections: PropTypes.arrayOf(collectionItemType),
  areCollectionsLoading: PropTypes.bool.isRequired,
  areCollectionsLoaded: PropTypes.bool.isRequired,
  isCollectionsLoadingError: PropTypes.bool.isRequired,
  loadLibraryCollections: PropTypes.func.isRequired,
  isLeftSidePanelOpen: PropTypes.bool.isRequired,
  selectedCollectionId: PropTypes.string,
};

export default connect(
  (state) => ({
    collections: getLibraryCollections(state),
    areCollectionsLoading: areCollectionsLoading(state),
    areCollectionsLoaded: areCollectionsLoaded(state),
    isCollectionsLoadingError: isCollectionsLoadingError(state),
    isLeftSidePanelOpen: getLibraryLeftPanelIsOpen(state),
    selectedCollectionId: getSelectedCollectionId(state),
  }),
  (dispatch) => ({
    loadLibraryCollections: () => dispatch(loadLibraryCollections()),
  })
)(LibrarySideNav);
