import React from "react";
import { createIcon } from "./utils/createIcon";

export const ArrowLeftIcon = createIcon(
  <path
    d="M14 8H2m0 0 4.3 4M2 8l4.2-4"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
