import { isValidDate } from "../isValidDate";
import { getCurrentDate } from "../getCurrentDate";
import { IanaCode } from "../getTimeZones/IanaCode";

export const isDateToday = (
  plainDate: string,
  timeZone?: IanaCode
): boolean => {
  if (!isValidDate(plainDate)) {
    throw new Error("Invalid date format. Use YYYY-MM-DD");
  }
  return plainDate === getCurrentDate(timeZone);
};
