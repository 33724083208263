import React from "react";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { Divider } from "@mui/material";
import styles from "./ClassInfoHeaderClassIdentifier.module.scss";
import { FormattedMessage, useIntl } from "react-intl";

type ClassInfoHeaderClassIdentifierProps = {
  isGoogleClassroomClass: boolean;
  dataTestIdPrefix: string;
  classDisplayIdentifier?: string;
  displaySyncInProgress?: boolean;
  onSyncClass?: () => void;
};

export const ClassInfoHeaderClassIdentifier: React.FC<
  ClassInfoHeaderClassIdentifierProps
> = ({
  isGoogleClassroomClass,
  dataTestIdPrefix,
  classDisplayIdentifier,
  displaySyncInProgress,
  onSyncClass,
}) => {
  const intl = useIntl();
  return (
    <>
      {isGoogleClassroomClass ? (
        <div className={styles.googleClassroomIdentifier}>
          <HapReactIcon svg="google-classroom-round" height={22} width={22} />
          <p>
            <FormattedMessage defaultMessage="Google Classroom" id="ahFZVA" />
          </p>
          {!!onSyncClass && (
            <>
              <Divider
                className={styles.divider}
                orientation="vertical"
                variant="middle"
                flexItem
              />
              <Button
                onClick={onSyncClass}
                className={styles.buttonSyncClass}
                dataTestId={`${dataTestIdPrefix}-Header_syncClass`}
                icon="arrow-sync"
                type="outlined"
                outlineType="solid"
                size="small"
                label={intl.formatMessage({
                  defaultMessage: "Sync class",
                  id: "YAyuZ9",
                })}
                isLoading={displaySyncInProgress}
              />
            </>
          )}
        </div>
      ) : (
        classDisplayIdentifier && (
          <p>
            <span>
              <FormattedMessage defaultMessage="Class ID:" id="YzcSr7" />
            </span>
            {classDisplayIdentifier}
          </p>
        )
      )}
    </>
  );
};
