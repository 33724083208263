import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styles from "./ClassViewPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import { MAIN_CONTENT_ID } from "../../../../consts";
import {
  getIsConfigLoaded,
  getIsConfigError,
  getIsOutsideSchoolHours,
  getActiveViewType,
} from "../../../../state/highlights/config/selectors";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import OutsideSchoolHoursInfoPanel from "../../../partial/Highlights/OutsideSchoolHoursInfoPanel/OutsideSchoolHoursInfoPanel";
import { NoStudentsInfoPanel } from "../../../partial/Highlights/NoStudentsInfoPanel";
import StudentTiles from "../../../partial/Highlights/StudentTiles/StudentTiles";
import { VisuallyHidden } from "@hapara/ui/src/atomic/VisuallyHidden";

const ClassViewPage = ({}) => {
  const isConfigLoaded = useSelector(getIsConfigLoaded);
  const isConfigError = useSelector(getIsConfigError);
  const isOutsideSchoolHours = useSelector(getIsOutsideSchoolHours);
  const students = useSelector(getStudentConfigsList);
  const activeStudentView = useSelector(getActiveViewType);

  const viewType = isOutsideSchoolHours
    ? "OUTSIDE_SCHOOL_HOURS"
    : !students.length
    ? "NO_STUDENTS"
    : "STUDENT_TILES";

  return (
    <Page
      header={<Header />}
      footer={
        <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
      }
      title={APP_PAGES.HIGHLIGHTS_CLASS_VIEW.title}
      isPageLoaded={isConfigLoaded}
      isPageLoadingError={isConfigError}
    >
      <ModalsAndPanels />
      <main
        className={styles.root}
        data-test-id="Hs-Pages-HighlightsClassViewPage"
        id={MAIN_CONTENT_ID}
      >
        <VisuallyHidden>
          <h1>
            <FormattedMessage defaultMessage="Class View" id="KldUa6" />
          </h1>
        </VisuallyHidden>
        {viewType === "OUTSIDE_SCHOOL_HOURS" && <OutsideSchoolHoursInfoPanel />}
        {viewType === "NO_STUDENTS" && <NoStudentsInfoPanel />}
        {viewType === "STUDENT_TILES" && (
          <StudentTiles tileContentType={activeStudentView} />
        )}
      </main>
    </Page>
  );
};

export default ClassViewPage;
