import React from "react";
import PropTypes from "prop-types";
import styles from "./ResourcesLoading.module.scss";
import classnames from "classnames";

const ResourcesLoading = ({ hasMarginTop }) => {
  return (
    <div
      className={classnames(styles.root, { [styles.marginTop]: hasMarginTop })}
    >
      <div className={classnames(styles.container, styles.first)}>
        <div className={styles.imgLoading} />
        <div className={styles.textLoading}>
          <div className={styles.titleLoading}></div>
          <div className={styles.descriptionLoading}></div>
        </div>
      </div>
      <div className={classnames(styles.container, styles.second)}>
        <div className={styles.imgLoading} />
        <div className={styles.textLoading}>
          <div className={styles.titleLoading}></div>
          <div className={styles.descriptionLoading}></div>
        </div>
      </div>
    </div>
  );
};

ResourcesLoading.propTypes = {
  hasMarginTop: PropTypes.bool,
};

export default ResourcesLoading;
