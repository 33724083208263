import _ from "lodash";
import { createSelector } from "reselect";

export const getStudents = (state) => _.get(state, "highlights.students");

// IMPORTANT
// use this selector and selectors based on it ONLY if you require dynamic student data
// for props like Id, Email, FirstName, LastName
// please use Student Configs selectors from apps/hsuite/src/state/highlights/studentConfigs/selectors.js
export const getStudentList = createSelector(getStudents, (students) => {
  if (_.isEmpty(students)) return [];

  return _.values(students);
});

export const getStudentsReducedParams = createSelector(
  getStudents,
  (students) => {
    if (_.isEmpty(students)) return [];

    return _.values(students).map((s) => {
      return {
        Email: s.Email,
        Id: s.Id,
        FirstName: s.FirstName,
        isOnline: _.get(s, `instances.${s.currentInstance}.isOnline`, false),
        LastName: s.LastName,
      };
    });
  }
);

const getStudentsInSession = createSelector(getStudentList, (students) => {
  return (
    _.filter(students, (student) =>
      _.get(
        student,
        `instances.${student.currentInstance}.lock.locking.active`,
        false
      )
    ) || []
  );
});

export const areSomeStudentsInSession = createSelector(
  getStudentsInSession,
  (lockedStudents) => {
    return lockedStudents.length > 0;
  }
);

export const getStudentsInSessionEmailList = createSelector(
  getStudentList,
  getStudentsInSession,
  (students, lockedStudentsList) =>
    _.map(lockedStudentsList, (student) => student.Email)
);

export const getStudentsOfflineEmailList = createSelector(
  getStudentList,
  (students) => {
    const offlineStudents = _.filter(
      students,
      (student) =>
        !_.get(student, `instances.${student.currentInstance}.isOnline`, false)
    );
    return _.map(offlineStudents, (student) => student.Email);
  }
);

export const getStudentsOnlineIdList = createSelector(
  getStudentList,
  (students) => {
    const onlineStudents = _.filter(students, (student) =>
      _.get(student, `instances.${student.currentInstance}.isOnline`, false)
    );
    return _.map(onlineStudents, (student) => student.Id);
  }
);

export const getOnlineTime = (studentId) =>
  createSelector(getStudents, (students) => {
    const student = students[studentId];

    return _.get(
      student,
      `instances.${_.get(student, "currentInstance")}.onlineTimestamp`
    );
  });

export const getReconnectionTime = (studentId) =>
  createSelector(getStudents, (students) => {
    const student = students[studentId];
    const studentReconnect =
      _.get(student, `instances.${student.currentInstance}.reconnectionTime`) ||
      0;
    if (!studentReconnect) {
      return 0; //we start with updating immediatly
    }
    //if the time is more than 20 seconds, we assume it is not re-connect after network fail or something
    //if it's less - we give it another 3 seconds to make sure the statuses do not flick if reconnect happens again
    return studentReconnect > 20000 ? 0 : studentReconnect + 3000;
  });

export const isStudentOnline = (studentId) =>
  createSelector(getStudents, (students) => {
    const student = students[studentId];

    return _.get(student, `instances.${student.currentInstance}.isOnline`);
  });
