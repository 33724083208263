import { hot } from "react-hot-loader/root";
import React, { useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import "./App.scss";

import store, { history } from "../state/store";
import { APP_PAGES } from "../state/router/types";
import { MYWORKSPACES_CATEGORIES } from "../state/workspace/myworkspaces/list/types";

import BaseRoute from "./routes/BaseRoute";
import HighlightsRoute from "./routes/HighlightsRoute";
import WorkspaceRoute from "./routes/WorkspaceRoute";
import DashboardPage from "./containers/Dashboard/DashboardPage/DashboardPage";
import ClassInfoPage from "./containers/ClassInfoPage/ClassInfoPage";
import DashboardRoute from "./routes/DashboardRoute";
import MyWorkspacesPage from "./containers/Workspace/MyWorkspacesPage/MyWorkspacesPage";
import LoginPage from "./containers/LoginPage/LoginPage";
import TestLoginPage from "./containers/LoginPage/TestLoginPage";
import NotFoundPage from "./containers/NotFoundPage/NotFoundPage";
import GradingPage from "./containers/Workspace/GradingPage/GradingPage";
import CreateForStudentsPage from "./containers/Workspace/CreateForStudentsPage/CreateForStudentsPage";
import CreateForPlPage from "./containers/Workspace/CreateForPlPage/CreateForPlPage";
import DiscoverPage from "./containers/Discover/DiscoverPage/DiscoverPage";
import DiscoverSearchPage from "./containers/Discover/DiscoverSearchPage/DiscoverSearchPage";
import LibraryPage from "./containers/Library/LibraryPage/LibraryPage";
import MyResourcesPage from "./containers/MyResources/MyResourcesPage/MyResourcesPage";
import LibraryUploadStatusPage from "./containers/Library/UploadStatusPage/UploadStatusPage";
import LibraryUploadPage from "./containers/Library/UploadPage/UploadPage";
import BrowserTabsPage from "./containers/Highlights/BrowserTabsPage/BrowserTabsPage";
import ClassViewPage from "./containers/Highlights/ClassViewPage/ClassViewPage";
import ActivityViewerPage from "./containers/Highlights/ActivityViewerPage/ActivityViewerPage";
import CurrentScreensPage from "./containers/Highlights/CurrentScreensPage/CurrentScreensPage";
import ClickToSharePage from "./containers/Highlights/ClickToSharePage/ClickToSharePage";
import BoardPage from "./containers/Workspace/BoardPage/BoardPage";
import SnapsPage from "./containers/Highlights/SnapsPage/SnapsPage";
import SchedulePage from "./containers/Highlights/SchedulePage/SchedulePage";
import DrivePage from "./containers/Highlights/DrivePage/DrivePage";
import PendoHandler from "./partial/PendoHandler/PendoHandler";
import Toasts from "./partial/Toasts/Toasts";
import WindowResize from "./partial/WindowResize/WindowResize";
import ProfessionalLearningPage from "./containers/Workspace/ProfessionalLearningPage/ProfessionalLearningPage";
import Notification from "./partial/Highlights/Notification/Notification";
import ManageClassesPage from "./containers/Dashboard/ManageClassesPage/ManageClassesPage";
import ResourceAuthPage from "./containers/ResourceAuthPage/ResourceAuthPage";
import { FocusContext } from "@hapara/ui/src/components/utils";
import { FocusRef } from "@hapara/ui/src/components/utilsTypes";
import PrivateRoute from "./routes/PrivateRoute";
import { USER_PERMISSIONS } from "../state/user/types";
import { RESOURCE_AUTH_ERRORS } from "../state/library/myResources/types";
import { ConditionalLocaleProvider } from "./partial/ConditionalLocaleProvider";
import { Portal } from "@hapara/ui/src/components/Portal";
import { InitChat } from "./partial/ChatTeacher/InitChat";
const App = () => {
  console.debug("BUILD_NUMBER", process.env.REACT_APP_BUILD_NUMBER);

  const [focusRef, setFocusRef] = useState<FocusRef>(null);

  const saveFocus = (ref: FocusRef) => {
    setFocusRef(ref);
  };

  const restoreFocus = () => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus();
      setFocusRef(null);
    }
  };

  if (
    window.location.hostname.indexOf("analytics.hapara.com") >= 0 ||
    window.location.hostname.indexOf("highlights.teacherdashboard.com") >= 0 ||
    window.location.hostname.indexOf("analytics-test.hapara.com") >= 0 ||
    window.location.hostname.indexOf("highlights-test.teacherdashboard.com") >=
      0
  ) {
    return <NotFoundPage />;
  }

  const focusContext = {
    focusRef: focusRef,
    saveFocus: saveFocus,
    restoreFocus: restoreFocus,
  };

  return (
    <FocusContext.Provider value={focusContext}>
      {/* @ts-ignore React 18 removed the "children" prop. TODO: PS-946 */}
      <Provider store={store}>
        <ConditionalLocaleProvider>
          <PendoHandler />
          <WindowResize />
          {/* @ts-ignore React 18 removed the "children" prop. TODO: PS-946 */}
          <ConnectedRouter history={history}>
            <InitChat />
            <Switch>
              <BaseRoute exact path="/" />
              <Route exact path={APP_PAGES.LOGIN.path} component={LoginPage} />
              <Route
                exact
                path={APP_PAGES.LOGIN.testPath}
                component={TestLoginPage}
              />

              {/* My Workspaces */}

              <Redirect
                exact
                from={APP_PAGES.WORKSPACE_BASE.path}
                to={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey}`}
              />
              <Redirect
                exact
                from={APP_PAGES.WORKSPACE_MY_WORKSPACE.path}
                to={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey}`}
              />
              <PrivateRoute
                exact
                path={`${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/:category`}
                component={MyWorkspacesPage}
                pagePermissions={[USER_PERMISSIONS.WORKSPACE]}
              />
              <PrivateRoute
                exact
                path={`${APP_PAGES.WORKSPACE_GRADING.path}/:grading_card_id/:grading_activity`}
                component={GradingPage}
                pagePermissions={[USER_PERMISSIONS.WORKSPACE]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.path}
                component={CreateForStudentsPage}
                pagePermissions={[USER_PERMISSIONS.WORKSPACE]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.WORKSPACE_CREATE_FOR_PL.path}
                component={CreateForPlPage}
                pagePermissions={[USER_PERMISSIONS.WORKSPACE]}
              />

              {/* PL participants only */}

              <PrivateRoute
                exact
                path={APP_PAGES.PROFESSIONAL_LEARNING.path}
                component={ProfessionalLearningPage}
                pagePermissions={[USER_PERMISSIONS.PROFESSIONAL_LEARNING]}
              />

              {/* Discover old */}
              <Redirect
                exact
                from={APP_PAGES.WORKSPACE_DISCOVER.path}
                to={APP_PAGES.DISCOVER_BASE.path}
              />
              <Redirect
                exact
                from={APP_PAGES.WORKSPACE_DISCOVER_SEARCH.path}
                to={APP_PAGES.DISCOVER_SEARCH.path}
              />

              {/* Discover new */}
              <PrivateRoute
                exact
                path={APP_PAGES.DISCOVER_BASE.path}
                component={DiscoverPage}
                // page is available for ether WS users OR DB users OR Pvt Library (for DRM users)
                pagePermissions={[
                  USER_PERMISSIONS.WORKSPACE,
                  USER_PERMISSIONS.LIBRARY_RESOURCES,
                  USER_PERMISSIONS.LIBRARY_WORKSPACE,
                ]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.DISCOVER_SEARCH.path}
                component={DiscoverSearchPage}
                // page is available for ether WS users OR DB users OR Pvt Library (for DRM users)
                pagePermissions={[
                  USER_PERMISSIONS.WORKSPACE,
                  USER_PERMISSIONS.LIBRARY_RESOURCES,
                  USER_PERMISSIONS.LIBRARY_WORKSPACE,
                ]}
              />

              {/* Library old */}
              <Redirect
                exact
                from={APP_PAGES.WORKSPACE_LIBRARY.path}
                to={APP_PAGES.LIBRARY_BASE.path}
              />
              <Redirect
                exact
                from={`${APP_PAGES.WORKSPACE_LIBRARY.path}/:category`}
                to={`${APP_PAGES.LIBRARY_CATEGORIES.path}/:category`}
              />

              {/* Library new */}
              <Redirect
                exact
                from={APP_PAGES.LIBRARY_BASE.path}
                to={`${APP_PAGES.LIBRARY_CATEGORIES.path}/all`}
              />
              <PrivateRoute
                exact
                path={`${APP_PAGES.LIBRARY_CATEGORIES.path}/:category`}
                component={LibraryPage}
                // page is available for ether WS Private Library users OR DB users
                pagePermissions={[
                  USER_PERMISSIONS.LIBRARY_RESOURCES,
                  USER_PERMISSIONS.LIBRARY_WORKSPACE,
                ]}
                title={APP_PAGES.LIBRARY_CATEGORIES.title}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.LIBRARY_SEARCH.path}
                component={LibraryPage}
                // page is available for ether WS Private Library users OR DB users
                pagePermissions={[
                  USER_PERMISSIONS.LIBRARY_RESOURCES,
                  USER_PERMISSIONS.LIBRARY_WORKSPACE,
                ]}
                title={APP_PAGES.LIBRARY_SEARCH.title}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.LIBRARY_UPLOAD.path}
                component={LibraryUploadPage}
                pagePermissions={[USER_PERMISSIONS.LIBRARY_RESOURCES]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.LIBRARY_UPLOAD_STATUS.path}
                component={LibraryUploadStatusPage}
                pagePermissions={[USER_PERMISSIONS.LIBRARY_RESOURCES]}
              />

              {/* My Resources */}

              <PrivateRoute
                exact
                path={APP_PAGES.MY_RESOURCES_BASE.path}
                component={MyResourcesPage}
                pagePermissions={[USER_PERMISSIONS.LIBRARY_RESOURCES]}
                title={APP_PAGES.MY_RESOURCES_BASE.title}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.MY_RESOURCES_STARRED.path}
                component={MyResourcesPage}
                pagePermissions={[USER_PERMISSIONS.LIBRARY_RESOURCES]}
                title={APP_PAGES.MY_RESOURCES_STARRED.title}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.MY_RESOURCES_SEARCH.path}
                component={MyResourcesPage}
                pagePermissions={[USER_PERMISSIONS.LIBRARY_RESOURCES]}
                title={APP_PAGES.MY_RESOURCES_BASE.title}
              />

              {/* Resource Auth  */}

              <PrivateRoute
                exact
                path={`${APP_PAGES.RESOURCES_AUTH_BASE.path}/:resourceId`}
                component={ResourceAuthPage}
                errorContent={RESOURCE_AUTH_ERRORS.SYSTEM}
                loadingContent="Loading"
                isLoginOnly={true}
              />

              {/* Manage classes */}

              <Redirect
                exact
                from={APP_PAGES.DASHBOARD.path}
                to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
                component={ManageClassesPage}
                pagePermissions={[USER_PERMISSIONS.MANAGE_CLASSES]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.path}
                component={ManageClassesPage}
                pagePermissions={[USER_PERMISSIONS.MANAGE_CLASSES]}
              />

              {/* Highlights */}

              <Redirect
                exact
                from={APP_PAGES.HIGHLIGHTS_BASE.path}
                to={APP_PAGES.HIGHLIGHTS_ACTIVITY.path}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_CLASS_VIEW.path}
                component={ClassViewPage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_ACTIVITY.path}
                component={BrowserTabsPage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_ACTIVITY_VIEWER.path}
                component={ActivityViewerPage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path}
                component={CurrentScreensPage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_SNAPS.path}
                component={SnapsPage}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_SCHEDULE.path}
                component={SchedulePage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_DRIVE.path}
                component={DrivePage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <HighlightsRoute
                exact
                path={APP_PAGES.HIGHLIGHTS_DRIVE_OLD.path}
                component={DrivePage}
                pagePermissions={[USER_PERMISSIONS.HIGHLIGHTS]}
              />
              <PrivateRoute
                exact
                path={APP_PAGES.CLICK_TO_SHARE.path}
                component={ClickToSharePage}
                pagePermissions={[
                  USER_PERMISSIONS.HIGHLIGHTS,
                  USER_PERMISSIONS.TEACHER_DASHBOARD,
                ]}
              />

              {/* Workspaces (with GraphQL client) */}
              <WorkspaceRoute
                exact={true}
                path={[
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/groups`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/members`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/settings`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/learningevidence`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/c/:cardId?`,
                  `${APP_PAGES.WORKSPACE_BOARDS.path}/:boardId/s/:sectionId?`,
                ]}
                component={BoardPage}
              />

              {/* Teacher Dashboard */}
              <DashboardRoute
                exact
                path={`${APP_PAGES.DASHBOARD_BASE.path}/folder/:classId/:folderType`}
                component={DashboardPage}
                pagePermissions={[USER_PERMISSIONS.TEACHER_DASHBOARD]}
                title={APP_PAGES.DASHBOARD_FOLDER.title}
              />
              <DashboardRoute
                exact
                path={`${APP_PAGES.DASHBOARD_BASE.path}/drive/:classId/:docType`}
                component={DashboardPage}
                pagePermissions={[USER_PERMISSIONS.TEACHER_DASHBOARD]}
                title={APP_PAGES.DASHBOARD_DRIVE.title}
              />
              <DashboardRoute
                exact
                path={`${APP_PAGES.DASHBOARD_BASE.path}/gmail/:classId/:gmailType`}
                component={DashboardPage}
                pagePermissions={[USER_PERMISSIONS.TEACHER_DASHBOARD]}
                title={APP_PAGES.DASHBOARD_GMAIL.title}
              />
              <DashboardRoute
                exact
                path={`${APP_PAGES.DASHBOARD_BASE.path}/edublogs/:classId/:edublogsType`}
                component={DashboardPage}
                pagePermissions={[USER_PERMISSIONS.TEACHER_DASHBOARD]}
                title={APP_PAGES.DASHBOARD_EDUBLOGS.title}
              />
              <PrivateRoute
                exact
                path={`${APP_PAGES.CLASSINFO.path}/:classId`}
                component={ClassInfoPage}
                pagePermissions={[
                  USER_PERMISSIONS.MANAGE_CLASSES,
                  USER_PERMISSIONS.TEACHER_DASHBOARD,
                ]}
                title={APP_PAGES.CLASSINFO.title}
              />

              {/* Not found */}
              <Route component={NotFoundPage} />
            </Switch>
            <Toasts />
          </ConnectedRouter>
          <Notification />
          <Portal />
        </ConditionalLocaleProvider>
      </Provider>
    </FocusContext.Provider>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
