import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ResourceDetailsModal.module.scss";
import _ from "lodash";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import { connect } from "react-redux";
import {
  getIsResourceDetailsModalOpen,
  getResourceDetailsModalData,
  getResourceDetailsModalMode,
} from "../../../../state/resources/selectors";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import { updateURLParams } from "../../../../state/shared/navigation";
import { hideResourceDetailsModal } from "../../../../state/resources/actions";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import {
  resourceItemType,
  RESOURCE_TILE_ID,
  RESOURCE_DETAILS_MODAL_MODE,
} from "../../../../state/resources/types";

import ResourceDetailsModalReadMode from "./ResourceDetailsModalReadMode";
import ResourceDetailsModalEditMode from "./ResourceDetailsModalEditMode";
import { BUTTON_OUTLINE_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import { APP_PAGES } from "../../../../state/router/types";
import { getLocationPath } from "../../../../state/router/selectors";
import { getDiscoverItemsList } from "../../../../state/library/discover/selectors";
import { getLibraryItemsList } from "../../../../state/library/library/selectors";
import { getMyResourcesItemsList } from "../../../../state/library/myResources/selectors";
import { libraryItemType } from "../../../../state/library/library/types";
import { myResourcesItemType } from "../../../../state/library/myResources/types";
import { focusSafelyByRef } from "@hapara/ui/src/components/utils";

const ResourceDetailsModal = ({
  isModalOpen,
  mode,
  hideResourceDetailsModal,
  modalData,
  locationPath,
  discoverItems,
  libraryItems,
  myResourcesItems,
}) => {
  const previousBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const dataTestPrefix = "ResourceDetailsModal";

  const resourceId = _.get(modalData, "ID");

  const currentPageItems = () => {
    switch (locationPath) {
      case APP_PAGES.DISCOVER_SEARCH.path:
        return discoverItems;
      case APP_PAGES.LIBRARY_SEARCH.path:
        return libraryItems;
      case APP_PAGES.MY_RESOURCES_SEARCH.path:
        return myResourcesItems;
      default:
    }
  };

  const items = currentPageItems();

  const itemIndex = _.findIndex(items, (item) => item.ID === resourceId);

  const getResourceId = (queryIndex) => {
    return (
      items && items[itemIndex + queryIndex] && items[itemIndex + queryIndex].ID
    );
  };

  const previousResourceId = getResourceId(-1);
  const nextResourceId = getResourceId(1);

  // left/right arrow keys support for navigating between resources
  const handleKeydown = useCallback(
    (e) => {
      if (e.keyCode === 37 && previousResourceId) {
        e.preventDefault();
        e.stopPropagation();
        updateURLParams({ rId: previousResourceId });
      }

      if (e.keyCode === 39 && nextResourceId) {
        e.preventDefault();
        e.stopPropagation();
        updateURLParams({ rId: nextResourceId });
      }
    },
    [nextResourceId, previousResourceId]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  useEffect(() => {
    return () => {
      updateURLParams({ [SHARED_URL_KEYS.selectedResourceId.key]: null });
      hideResourceDetailsModal();
    };
  }, [hideResourceDetailsModal]);

  const handleCloseModal = () => {
    const tileElement = document.getElementById(
      `${RESOURCE_TILE_ID}${resourceId}`
    );
    if (tileElement) tileElement.focus();
    hideResourceDetailsModal();
  };

  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      hasEmbeddedHeader={false}
      classNameContent={styles.modal}
      className={styles.modalContent}
      dataTestPrefix={dataTestPrefix}
      contentLabel="Preview resource"
    >
      <div className={styles.modalTitle}>
        <Button
          icon="cross"
          onClick={handleCloseModal}
          type={BUTTON_TYPES.TERTIARY}
          aria-label="Close"
          data-test-id={`${dataTestPrefix}-Button-Close`}
          className={styles.modalTitleClose}
        />
      </div>

      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.content}>
            {mode === RESOURCE_DETAILS_MODAL_MODE.READ && (
              <ResourceDetailsModalReadMode dataTestPrefix={dataTestPrefix} />
            )}

            {mode === RESOURCE_DETAILS_MODAL_MODE.EDIT && (
              <ResourceDetailsModalEditMode dataTestPrefix={dataTestPrefix} />
            )}
          </div>
        </div>

        <div className={styles.previousResource}>
          {!!previousResourceId && (
            <Button
              icon="arrow-left"
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              data-test-id={`${dataTestPrefix}-Button-PreviousResource`}
              alt="Previous"
              ref={previousBtnRef}
              onClick={() => {
                updateURLParams({ rId: previousResourceId });
                // focus on nextButton if previous resource is the first one on the list
                if (!getResourceId(-2)) {
                  focusSafelyByRef(nextBtnRef, 100);
                }
              }}
            />
          )}
        </div>

        <div className={styles.nextResource}>
          {!!nextResourceId && (
            <Button
              icon="arrow-right"
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              data-test-id={`${dataTestPrefix}-Button-NextResource`}
              alt="Next"
              ref={nextBtnRef}
              onClick={() => {
                updateURLParams({ rId: nextResourceId });
                // focus on previousButton if next resource is the last one the list
                if (!getResourceId(2)) {
                  focusSafelyByRef(previousBtnRef, 100);
                }
              }}
            />
          )}
        </div>
      </div>
    </ModalDeprecated>
  );
};

ResourceDetailsModal.propTypes = {
  discoverItems: PropTypes.arrayOf(resourceItemType),
  libraryItems: PropTypes.arrayOf(libraryItemType),
  myResourcesItems: PropTypes.arrayOf(myResourcesItemType),
  isModalOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(_.values(RESOURCE_DETAILS_MODAL_MODE)),
  modalData: resourceItemType,
  locationPath: PropTypes.string.isRequired,
  hideResourceDetailsModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getIsResourceDetailsModalOpen(state),
    mode: getResourceDetailsModalMode(state),
    modalData: getResourceDetailsModalData(state),
    locationPath: getLocationPath(state),
    discoverItems: getDiscoverItemsList(state),
    libraryItems: getLibraryItemsList(state),
    myResourcesItems: getMyResourcesItemsList(state),
  }),
  (dispatch) => ({
    hideResourceDetailsModal: () => dispatch(hideResourceDetailsModal()),
  })
)(ResourceDetailsModal);
