import { FormattedMessage } from "react-intl";
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./GradingSubmitMultiple.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import {
  getAssigneesByActivity,
  isAssigneeGroup,
  getGradingSelectedArtefactId,
  isBulkActionInitInProgress,
  isBulkActionInitError,
  isBulkSubmitActionInProgress,
  isBulkSubmitActionError,
  getAssigneeIdByArtifactId,
} from "../../../../../state/workspace/grading/selectors";
import {
  loadGradingActivityUsersForBulkActions,
  doBulkSubmit,
} from "../../../../../state/workspace/grading/actions";
import PropTypes from "prop-types";
import { MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE } from "../../../../../state/workspace/grading/types";
import { replaceQueryInHistory } from "../../../../../state/router/navigation";
import GradingMultipleSelectTable, {
  TABLE_TYPES,
} from "../GradingMultipleSelectTable/GradingMultipleSelectTable";
import useWorkspaceGradingBulkModalHook from "../../../../../hooks/useWorkspaceGradingBulkModalHook";

export const GradingSubmitMultiple = ({
  assignees,
  isAssigneeGroup,
  selectedArtefactId,
  isBulkActionInitInProgress,
  isBulkActionInitError,
  isBulkSubmitActionInProgress,
  isBulkSubmitActionError,
  loadGradingActivityUsersForBulkActions,
  doBulkSubmit,
  getAssigneeIdByArtifactId,
}) => {
  const modalDataTestPrefix = "ws-Grading-BulkSubmitModal";

  const { isModalOpen, setIsModalOpen, selectedList, setSelectedList } =
    useWorkspaceGradingBulkModalHook({
      assignees,
      loadAction: loadGradingActivityUsersForBulkActions,
    });

  return (
    <div className={styles.root}>
      {assignees.length > 1 && (
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.REGULAR}
          onAction={() => setIsModalOpen(true)}
          label="Submit multiple"
          dataTestId="ws-Grading-BulkSubmit-SubmitMultiple"
          className={styles.submitMultipleButton}
        />
      )}
      <ModalDeprecated
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (
            assignees.length > 0 &&
            _.findIndex(
              assignees,
              (item) => item.ArtifactID === selectedArtefactId
            ) === -1
          ) {
            // if current assignee is not in the list anymore, go to the next one
            replaceQueryInHistory({ artefact: assignees[0].ArtifactID });
          }
        }}
        title={`Select ${isAssigneeGroup ? "group" : "individual"} evidence`}
        classNameHeader={styles.bulkSubmitModalTitle}
        className={styles.bulkSubmitModal}
        dataTestPrefix={modalDataTestPrefix}
      >
        <form className={styles.bulkSubmitModalBody}>
          <div className={styles.scrollArea}>
            <Alerts>
              {isBulkActionInitError && (
                <div className={styles.initLoadError}>
                  <Alert type={ALERT_TYPES.FAILURE}>
                    <FormattedMessage
                      defaultMessage="There was a problem. Please try again."
                      id="QHNLuA"
                    />
                  </Alert>
                </div>
              )}
              {!isBulkActionInitError && (
                <GradingMultipleSelectTable
                  type={TABLE_TYPES.SUBMIT}
                  assignees={assignees}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                  isAssigneeGroup={isAssigneeGroup}
                  isActionInProgress={isBulkSubmitActionInProgress}
                  isInitInProgress={isBulkActionInitInProgress}
                  tableTitleId={`${modalDataTestPrefix}-Header`}
                  dataTestIdPrefix="ws-Grading-BulkSubmit"
                />
              )}
            </Alerts>
          </div>

          <Alerts>
            {isBulkSubmitActionError && (
              <div
                className={styles.actionAlerts}
                data-test-id="ws-Grading-BulkSubmit-Error"
              >
                <Alert type={ALERT_TYPES.FAILURE}>
                  <FormattedMessage
                    defaultMessage="There was a problem submitting work. Please try again."
                    id="BaAfWp"
                  />
                </Alert>
              </div>
            )}
          </Alerts>

          <div className={styles.actionButtons}>
            {!isBulkActionInitInProgress && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={() => {
                  doBulkSubmit({
                    assigneeIdList: _.map(selectedList, (artifactId) =>
                      getAssigneeIdByArtifactId(artifactId)
                    ),
                  });
                }}
                label={`Submit for ${isAssigneeGroup ? "group" : "student"}(s)`}
                dataTestId="ws-Grading-BulkSubmit-SubmitForStudents"
                isDisabled={
                  !selectedList.length || isBulkSubmitActionInProgress
                }
                isLoading={isBulkSubmitActionInProgress}
              />
            )}
          </div>
        </form>
      </ModalDeprecated>
    </div>
  );
};

GradingSubmitMultiple.propTypes = {
  assignees: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE.isRequired,
  isAssigneeGroup: PropTypes.bool.isRequired,
  selectedArtefactId: PropTypes.string,
  isBulkActionInitInProgress: PropTypes.bool.isRequired,
  isBulkActionInitError: PropTypes.bool.isRequired,
  isBulkSubmitActionInProgress: PropTypes.bool.isRequired,
  isBulkSubmitActionError: PropTypes.bool.isRequired,
  loadGradingActivityUsersForBulkActions: PropTypes.func.isRequired,
  doBulkSubmit: PropTypes.func.isRequired,
  getAssigneeIdByArtifactId: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    assignees: getAssigneesByActivity(state),
    isAssigneeGroup: isAssigneeGroup(state),
    selectedArtefactId: getGradingSelectedArtefactId(state),
    isBulkActionInitInProgress: isBulkActionInitInProgress(state),
    isBulkActionInitError: isBulkActionInitError(state),
    isBulkSubmitActionInProgress: isBulkSubmitActionInProgress(state),
    isBulkSubmitActionError: isBulkSubmitActionError(state),
    getAssigneeIdByArtifactId: (artifactId) =>
      getAssigneeIdByArtifactId(artifactId)(state),
  }),
  (dispatch) => ({
    loadGradingActivityUsersForBulkActions: () =>
      dispatch(loadGradingActivityUsersForBulkActions()),
    doBulkSubmit: (options) => dispatch(doBulkSubmit(options)),
  })
)(GradingSubmitMultiple);
