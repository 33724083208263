import React from "react";
import { createIcon } from "./utils/createIcon";

export const ClockIcon = createIcon(
  <path
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M8 4v4l3 1m4-1A6 6 0 1 1 2 8a6 6 0 0 1 13 0Z"
  />
);
