import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationPath, getLocationQuery } from "../../router/selectors";
import { getClassId } from "../../shared/selectors";
import { MY_RESOURCES_URL_KEYS, RESOURCE_AUTH_URL_KEYS } from "./navigation";
import { getSelectedResourceId } from "../../shared/selectors";
import { APP_PAGES } from "../../router/types";
import { getClassList } from "../config/selectors";
import { DEFAULT_PAGE_SIZE } from "../../shared/types";

const getMyResources = (state) => _.get(state, "library.myResources");

export const isMyResourcesLeftPanelOpen = createSelector(getMyResources, (r) =>
  _.get(r, "leftSidePanelOpen")
);

export const getMyResourcesItemsList = createSelector(getMyResources, (r) =>
  _.get(r, "items")
);

export const getMyResourcesSelectedItem = createSelector(
  [getMyResourcesItemsList, getSelectedResourceId],
  (items, selectedId) => _.find(items, { ID: selectedId })
);

export const areMyResourcesResultsNull = (state) =>
  getMyResourcesItemsList(state) === null;

export const getMyResourcesItemsTotal = createSelector(getMyResources, (r) =>
  _.get(r, "totalItems", 0)
);

export const getMyResourcesSearchGuid = createSelector(getMyResources, (d) =>
  _.get(d, "searchGuid", null)
);

export const getMyResourcesSearchIteration = createSelector(
  getMyResources,
  (d) => _.get(d, "searchIteration", 0)
);

export const getMyResourcesPagesCount = createSelector(getMyResources, (r) =>
  _.get(r, "pagesCount", 0)
);

export const getMyResourcesPageSize = createSelector(getMyResources, (r) =>
  _.get(r, "pageSize", DEFAULT_PAGE_SIZE)
);

export const getMyResourcesIsLoading = createSelector(getMyResources, (r) =>
  _.get(r, "isLoading", false)
);

export const isInsideMyResources = createSelector(
  [getLocationPath],
  (locationPath) => !!_.includes(locationPath, APP_PAGES.MY_RESOURCES_BASE.path)
);

export const isStarredFiltered = createSelector(
  [getLocationPath, getLocationQuery],
  (locationPath, locationQuery) => {
    const ifStarred = _.get(locationQuery, MY_RESOURCES_URL_KEYS.starred.key);
    return (
      _.startsWith(locationPath, APP_PAGES.MY_RESOURCES_STARRED.path) ||
      ifStarred === "true"
    );
  }
);

export const getJoinedFilterParams = createSelector(
  [getClassId, getClassList],
  (selectedClass, allClasses) => {
    let listToJoin = [];

    if (!_.isEmpty(selectedClass)) {
      const item = _.find(allClasses, { ID: selectedClass });
      if (item && item["Name"]) {
        listToJoin.push(item["Name"]);
      }
    }

    return listToJoin.join(", ");
  }
);

export const getResourceRedirectUrlQuery = createSelector(
  getLocationQuery,
  (locationQuery) => {
    const paramName = RESOURCE_AUTH_URL_KEYS.redirectUrl.key;
    const rawParam = _.get(locationQuery, paramName);
    return rawParam ? window.decodeURIComponent(rawParam) : "";
  }
);
