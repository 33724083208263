import { SchoolPrefs } from "../../../types/dashboard";
import { Domain, Permissions } from "../../../types/shared";
import { RootState } from "../../store";

export const getPermissions = (state: RootState): Permissions[] =>
  state.dashboard.config.permissions;

export const getIsConfigError = (state: RootState): boolean =>
  state.dashboard.config.isError;

export const getIsConfigLoading = (state: RootState): boolean =>
  state.dashboard.config.isLoading;

export const getIsConfigLoaded = (state: RootState): boolean =>
  state.dashboard.config.isLoaded;

export const getHasAccessToAllClasses = (state: RootState): boolean =>
  state.dashboard.config.permissions.indexOf(
    Permissions.DASHBOARD_SHOW_ALL_CLASSES
  ) >= 0;

export const getDomains = (state: RootState): Domain[] =>
  state.dashboard.config.domains;

export const getSchoolPreferenses = (state: RootState): SchoolPrefs[] =>
  state.dashboard.config.schoolPrefs;
