import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { BUTTON_OUTLINE_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../state/shared/selectors";
import { STUDENT_BROWSER_TAB_TYPE } from "../../../../state/highlights/students/types";
import classnames from "classnames";
import styles from "./StudentBrowserTabs.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import CloseTabHandler from "../CloseTabHandler/CloseTabHandler";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import { FormattedMessage, useIntl } from "react-intl";

const StudentBrowserTabs = ({
  tabs,
  studentId,
  studentLastName,
  studentDisplayName,
  studentEmail,
  instanceId,
  classId,
  isExpandModal = false,
}) => {
  const intl = useIntl();
  const sortedTabs = _.orderBy(tabs, ["active", "when"], ["desc", "desc"]);

  return (
    <div className={styles.root}>
      <CloseTabHandler
        tabs={sortedTabs}
        getStudentInfo={(_tab) => ({
          Id: studentId,
          LastName: studentLastName,
          instanceId: instanceId,
        })}
        studentDisplayName={studentDisplayName}
        tabsLimit={0}
        duplicateTabConsolidated={true}
        tabComponent={(tab, closeTab, isClosing) => {
          const isConsolidatedTab = tab.ids && tab.ids.length > 1;

          if (isExpandModal) {
            return (
              <div
                key={tab.id}
                className={classnames(styles.expandModalTabItem, {
                  [styles.tabItemToBeClosed]: isClosing,
                })}
              >
                <div className={styles.tabContainer}>
                  <div
                    className={styles.websiteName}
                    data-test-id="hl-StudentTileExpandModalCurrentScreen-WebsiteName"
                  >
                    <div className={styles.linkContainer}>
                      <ExternalLink
                        url={tab.url}
                        title={tab.title}
                        icon={tab.icon}
                        active={tab.active}
                        studentEmail={studentEmail}
                        classId={classId}
                        className={tab.active ? styles.bold : styles.regular}
                        dataTestId="StudentBrowserTabs_ExternalLink"
                      />
                    </div>
                    {isConsolidatedTab && (
                      <div
                        className={styles.duplicatedTabs}
                        data-test-id="hl-StudentTileExpandModalCurrentScreen-DuplicateWarning"
                      >
                        <FormattedMessage
                          defaultMessage="({tabCount} duplicate pages)"
                          id="6ptdvh"
                          values={{ tabCount: tab.ids.length }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.websiteUrl}>{tab.url}</div>
                </div>

                <Button
                  icon="cross"
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  size={BUTTON_SIZES.SMALL}
                  dataTestId="hl-StudentTileExpandModalCurrentScreen-Button-CloseTab"
                  alt={intl.formatMessage({
                    defaultMessage: "Close",
                    id: "rbrahO",
                  })}
                  className={styles.button}
                  onAction={(e) => closeTab(e)}
                />
              </div>
            );
          } else {
            return (
              <div
                key={tab.id}
                className={classnames(styles.tabItem, {
                  [styles.tabItemToBeClosed]: isClosing,
                })}
              >
                <div className={styles.linkContainer}>
                  <ExternalLink
                    url={tab.url}
                    title={tab.title}
                    icon={tab.icon}
                    active={tab.active}
                    studentEmail={studentEmail}
                    classId={classId}
                    className={tab.active ? styles.bold : null}
                    dataTestId="StudentBrowserTabs_ExternalLink"
                  />
                </div>
                {isConsolidatedTab && (
                  <div
                    className={styles.duplicatedTabs}
                    data-test-id="hl-StudentTile-DuplicateWarning"
                  >
                    <FormattedMessage
                      defaultMessage="({tabCount} duplicate pages)"
                      id="6ptdvh"
                      values={{ tabCount: tab.ids.length }}
                    />
                  </div>
                )}
                <Button
                  icon="cross"
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  dataTestId="hl-StudentTile-Button-CloseTab"
                  alt={intl.formatMessage({
                    defaultMessage: "Close",
                    id: "rbrahO",
                  })}
                  className={styles.btnBgColor}
                  onAction={(e) => closeTab(e)}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
};

StudentBrowserTabs.propTypes = {
  tabs: PropTypes.arrayOf(STUDENT_BROWSER_TAB_TYPE).isRequired,
  studentId: PropTypes.string.isRequired,
  studentEmail: PropTypes.string.isRequired,
  studentLastName: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,
  isExpandModal: PropTypes.bool,
  // connected
  classId: PropTypes.string.isRequired,
};

export default connect((state) => ({
  classId: getClassId(state),
}))(StudentBrowserTabs);
