import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import {
  syncGoogleClassroom,
  resetSyncGoogleClassroom,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsSyncClassLoading,
  getIsSyncClassLoaded,
  getIsSyncClassError,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  handleSyncClass: (() => void) | undefined;
  displaySyncInProgress: boolean;
};

export const useClassInfoSyncClass = (
  classId: string,
  canSync: boolean | undefined
): ExportedValues => {
  const dispatch = useDispatch();
  const isSyncClassLoading = useSelector(getIsSyncClassLoading);
  const isSyncClassLoaded = useSelector(getIsSyncClassLoaded);
  const isSyncClassError = useSelector(getIsSyncClassError);

  const handleSyncClass = useCallback(() => {
    dispatch(syncGoogleClassroom(classId));
  }, [classId, dispatch]);

  useEffect(() => {
    if (!isSyncClassLoading && isSyncClassLoaded && !isSyncClassError) {
      dispatch(resetSyncGoogleClassroom());
    }
  }, [isSyncClassLoading, isSyncClassLoaded, isSyncClassError]);

  return {
    handleSyncClass: canSync ? handleSyncClass : undefined,
    displaySyncInProgress: isSyncClassLoading,
  };
};
