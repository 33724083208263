import { FormattedMessage } from "react-intl";
import React from "react";
import { APP_PAGES } from "../../../router/types";
import { Link } from "react-router-dom";

export const MYWORKSPACES_EMPTY_STATES = {
  CLASSES: {
    classITeach: {
      header: `You haven't created any Workspaces for this class yet!`,
      body: (
        <React.Fragment>
          When you{" "}
          <Link
            to={{
              pathname: APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.path,
            }}
            data-test-id="ws-MyWorkspaces-CategoryPanel-CreateNewWS"
          >
            create a new Workspace
          </Link>{" "}
          and link it to this class, it will appear here.
        </React.Fragment>
      ),
    },
    classIDoNotTeach: {
      header: `There are no Workspaces that you can view for
  "<current class name>"`,
      body: "",
    },
  },
  OWNED_BY_ME: {
    header: `You haven't created any Workspaces yet!`,
    body: (
      <React.Fragment>
        <Link
          to={{
            pathname: APP_PAGES.WORKSPACE_CREATE_FOR_STUDENTS.path,
          }}
          data-test-id="ws-MyWorkspaces-CategoryPanel-CreateNewWS"
        >
          <FormattedMessage
            defaultMessage="Create a new Workspace"
            id="uqG+Ww"
          />
        </Link>
      </React.Fragment>
    ),
  },
  LABELS: {
    withLabelSelected: { content: "customised component" },
    withLabelDeleted: {
      // this option won't exist once -6648 FF released, because we will use All labels as default state
      // so remove it when cleaning up -6648 FF
      header: `This label has been deleted`,
      body: ``,
    },
    noLabelSelected: {
      header: `Workspaces with labels attached will appear here`,
      body: `Add categories to your Workspaces by using labels.`,
    },
  },
  SHARED_WITH_ME: {
    header: `There's nothing here!`,
    body: `When others add you as a teacher to their Workspaces, you'll find them here.`,
  },
  PL: {
    // remove "noFilters" option when cleaning HAP-7034-professional-learning
    noFilters: {
      header: `There's nothing here!`,
      body: `When you’re added as a learner to Workspaces, you'll find them here.`,
    },
    filteredByAll: {
      header: `There's nothing here!`,
      body: `Once you create or are added to professional learning Workspaces, you'll find them here.`,
    },
    filteredByFacilitator: {
      header: `There's nothing here!`,
      body: `Once you create professional learning Workspaces, you'll find them here.`,
    },
    filteredByParticipant: {
      header: `There's nothing here!`,
      body: `Once you are added to professional learning Workspaces, you'll find them here.`,
    },
  },
  UNPUBLISHED: {
    header: `There's nothing here!`,
    body: `We can't find any Workspaces based on this filter.`,
  },
  PUBLISHED: {
    header: `There's nothing here!`,
    body: `We can't find any Workspaces based on this filter.`,
  },
  ARCHIVED: {
    withLabelSelected: {
      header: `There's nothing here!`,
      body: `Either this label is not attached to any Workspaces, or only attached to unarchived Workspaces.`,
    },
    noLabelSelected: {
      header: `There's nothing here!`,
      body: `We can't find any Workspaces based on this filter.`,
    },
  },
};
