import { SessionDetailsType } from "../useGuidedBrowsingSession";
import { getScheduledDays } from "../utils/getScheduledDays";

type transformScheduleSessionPayloadProps = {
  sessionDetails: SessionDetailsType;
  scheduleName: string;
  scheduledDate: string;
  scheduleEndDate: string | null;
  scheduledTime: string;
  scheduleEndTime: string;
  domainTimeZone: string;
  selectedDays: string[];
};

export const transformScheduleSessionPayload = ({
  sessionDetails,
  scheduleName,
  scheduledDate,
  scheduleEndDate,
  scheduledTime,
  scheduleEndTime,
  domainTimeZone,
  selectedDays,
}: transformScheduleSessionPayloadProps) => {
  const isRecurringSchedule = selectedDays.length > 0;
  const isGroupsSession = sessionDetails.RecipientType === "groups";
  const recurringSessionDays = getScheduledDays(selectedDays);

  const focusSessionDetails = sessionDetails.FocusSessionDetails && {
    focusType: sessionDetails.FocusSessionDetails.FocusType,
    endSessionKeepAllTabs:
      sessionDetails.FocusSessionDetails.EndSessionKeepAllTabs,
    endSessionRestoreOriginal:
      sessionDetails.FocusSessionDetails.EndSessionRestoreOriginal,
  };

  const scheduledSessionDetails = {
    classUrn: sessionDetails.ClassURN,
    type: sessionDetails.Type,
    recipientType: sessionDetails.RecipientType,
    students: sessionDetails.Students,
    groups: sessionDetails.Groups,
    links: sessionDetails.Links,
    sessionName: scheduleName,
    schedule: {
      startDate: scheduledDate,
      endDate: scheduleEndDate,
      startTime: {
        timeZone: domainTimeZone,
        time: scheduledTime,
      },
      endTime: {
        timeZone: domainTimeZone,
        time: scheduleEndTime,
      },
      daysOfWeek: recurringSessionDays,
    },
    ...(isGroupsSession && sessionDetails.Groups),
    ...(focusSessionDetails && { focusSessionDetails }),
    ...(isRecurringSchedule && { isRecurringSchedule }),
  };

  return scheduledSessionDetails;
};
