import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./InformativePage.module.scss";
import buttonStyles from "../../atomic/Button/Button.module.scss";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import Card, { BACKGROUND_STYLE, CARD_TYPE } from "../../deprecated/Card/Card";

const InformativePage = ({ info, dataTestPrefix }) => {
  const { hero, items, itemsDescription } = info;

  const dataTestIdPrefix = `${dataTestPrefix}-InfoPage`;

  return (
    <div className={styles.root} data-test-id={`${dataTestIdPrefix}-Main`}>
      <div className={styles.hero}>
        <Card
          type={CARD_TYPE.HORIZONTAL}
          dataTestIdPrefix={dataTestIdPrefix}
          background={hero.background}
          image={hero.image}
          title={({ className }) => <h1 className={className}>{hero.title}</h1>}
          text={hero.text}
        />
      </div>
      {itemsDescription && (
        <div
          className={styles.description}
          data-test-id={`${dataTestIdPrefix}-Description`}
        >
          {itemsDescription}
        </div>
      )}
      <div className={styles.container}>
        {_.map(items, (item) => {
          return (
            <div key={item.id}>
              <Card
                type={CARD_TYPE.VERTICAL}
                dataTestIdPrefix={`${dataTestIdPrefix}-Item${item.id}`}
                image={item.image}
                title={({ className }) => (
                  <h2 className={className}>{item.title}</h2>
                )}
                text={item.text}
                actionComponent={
                  item.link && item.linkLabel ? (
                    <NavLink
                      to={item.link}
                      data-test-id={`${dataTestIdPrefix}-${item.linkDataTestId}`}
                      className={classnames(
                        buttonStyles.root,
                        buttonStyles.regular,
                        buttonStyles.primary
                      )}
                    >
                      {item.linkLabel}
                    </NavLink>
                  ) : null
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

InformativePage.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  info: PropTypes.shape({
    hero: PropTypes.shape({
      background: PropTypes.oneOf(_.values(BACKGROUND_STYLE)),
      image: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      text: PropTypes.node.isRequired,
    }),
    itemsDescription: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.node.isRequired,
        text: PropTypes.node.isRequired,
        link: PropTypes.string,
        linkLabel: PropTypes.string,
        linkDataTestId: PropTypes.string,
      })
    ),
  }),
};

export default InformativePage;
