import PropTypes from "prop-types";
import _ from "lodash";
import { USER_PERMISSIONS } from "../user/types";

export const NAVIGATION_ITEMS_IDS = {
  MANAGE_CLASSES: "TD_MC",
  DASHBOARD: "TD_DB",
  GMAIL: "TD_GM",
  SHARING: "TD_SH",
  HIGHLIGHTS: "HL",
  WORKSPACE: "WS",
  CLASS_INFO: "TD_CI",
};

export const NAVIGATION_ITEM_TYPE = PropTypes.shape({
  active: PropTypes.bool,
  id: PropTypes.oneOf(_.values(NAVIGATION_ITEMS_IDS)).isRequired,
  isExternal: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  basePaths: PropTypes.arrayOf(PropTypes.string.isRequired),
  permissions: PropTypes.arrayOf(PropTypes.oneOf(_.values(USER_PERMISSIONS))),
});
