import { RESOURCE_FORMAT } from "../resources/types";

export const SHARED_URL_KEYS = {
  classId: {
    key: "classId",
    dependantsKeysToReset: ["page"],
  },
  query: {
    key: "query",
    dependantsKeysToReset: ["page"],
  },
  grades: {
    key: "grades",
    dependantsKeysToReset: ["page"],
  },
  subjects: {
    key: "subjects",
    dependantsKeysToReset: ["page"],
  },
  standards: {
    key: "standards",
    dependantsKeysToReset: ["page"],
  },
  licences: {
    key: "licences",
    dependantsKeysToReset: ["page"],
  },
  resourceType: {
    key: "resourceTypes",
    dependantsKeysToReset: ["page"],
  },
  format: {
    key: "format",
    dependantsKeysToReset: ["page"],
  },
  sortOrder: {
    key: "sortOrder",
    dependantsKeysToReset: ["page"],
  },
  selectedResourceId: {
    key: "rId",
  },
  docType: {
    key: "docType",
  },
  page: {
    key: "page",
    shouldDefaultBeInUrl: true,
  },
};

export const SHARED_URL_PARAMS_DEFAULT_VALUES = {
  [SHARED_URL_KEYS.classId.key]: null,
  [SHARED_URL_KEYS.query.key]: "",
  [SHARED_URL_KEYS.docType.key]: null,
  [SHARED_URL_KEYS.page.key]: 1,
  [SHARED_URL_KEYS.grades.key]: [],
  [SHARED_URL_KEYS.subjects.key]: [],
  [SHARED_URL_KEYS.standards.key]: [],
  [SHARED_URL_KEYS.licences.key]: [],
  [SHARED_URL_KEYS.resourceType.key]: [],
  [SHARED_URL_KEYS.selectedResourceId.key]: null,
  [SHARED_URL_KEYS.format.key]: RESOURCE_FORMAT.ALL,
};
