import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { APP_PAGES } from "../../../../state/router/types";
import SubMenu from "../../SubMenu/SubMenu";
import stylesSubMenu from "../../SubMenu/SubMenu.module.scss";
import styles from "./ManageClassesSubMenu.module.scss";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { getHasAccessToAllClasses } from "../../../../state/dashboard/config/selectors";
import {
  openAddClassDialog,
  openGCSyncDialog,
} from "../../../../state/dashboard/manage-classes/actions";
import PropTypes from "prop-types";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import { useIntl } from "react-intl";

const ManageClassesSubMenu = ({ canAddManually, canAddGC }) => {
  const intl = useIntl();
  const hasAccessToAllClasses = useSelector(getHasAccessToAllClasses);
  const dispatch = useDispatch();
  const openAddDialogAction = useCallback(() => {
    dispatch(openAddClassDialog());
  }, [dispatch]);
  const openGCSyncDialogAction = useCallback(() => {
    dispatch(openGCSyncDialog());
  }, [dispatch]);
  const dropdownTrigger = useCallback(
    (props) => (
      <MemorisedFocusButton
        label={intl.formatMessage({
          defaultMessage: "Add new class",
          id: "lHgOfl",
        })}
        aria-label="Add new class"
        dataTestId="Td-ManageClasses-AddClass-Trigger"
        icon="plus"
        {...props}
      />
    ),
    []
  );
  const itemList = [];
  if (canAddGC && canAddManually) {
    itemList.push(({ onClick, className, ...rest }) => (
      <button
        className={classnames(className)}
        data-test-id="Td-ManageClasses-AddClassDropdown-GCSync"
        aria-label="Add from Google Classroom"
        onClick={() => {
          openGCSyncDialogAction();
          onClick();
        }}
        {...rest}
      >
        {intl.formatMessage({
          defaultMessage: "Add from Google Classroom",
          id: "V6fofW",
        })}
      </button>
    ));
    itemList.push(({ onClick, className, ...rest }) => (
      <button
        className={classnames(className)}
        aria-label="Create a class"
        data-test-id="Td-ManageClasses-AddClassDropdown-AddManual"
        onClick={() => {
          openAddDialogAction();
          onClick();
        }}
        {...rest}
      >
        {intl.formatMessage({
          defaultMessage: "Create a class",
          id: "H9IPTD",
        })}
      </button>
    ));
  }
  return (
    <SubMenu
      tabsLabel="Manage Classes pages"
      className={styles.root}
      tabsClassName={classnames(styles.tabs)}
      toolbarClassName={styles.toolbar}
      tabs={
        <>
          <NavLink
            to={`${APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}`}
            strict
            activeClassName={stylesSubMenu.active}
            data-test-id="td-DashboardSubNav-Link-MyClasses"
          >
            {intl.formatMessage({
              defaultMessage: "My Classes",
              id: "DJiFrq",
            })}
          </NavLink>
          {hasAccessToAllClasses && (
            <NavLink
              to={`${APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.path}`}
              strict
              activeClassName={stylesSubMenu.active}
              data-test-id="td-DashboardSubNav-Link-AllClasses"
            >
              {intl.formatMessage({
                defaultMessage: "All Classes",
                id: "HwTqox",
              })}
            </NavLink>
          )}
        </>
      }
      toolbar={
        canAddManually && canAddGC ? (
          <Dropdown
            widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
            className={styles.addClassTrigger}
            positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
            triggerComponent={dropdownTrigger}
            itemComponentList={itemList}
          />
        ) : canAddManually || canAddGC ? (
          <MemorisedFocusButton
            label={intl.formatMessage({
              defaultMessage: "Add new class",
              id: "lHgOfl",
            })}
            aria-label="Add new class"
            dataTestId="Td-ManageClasses-AddClass-Trigger"
            icon="plus"
            className={styles.addClassTrigger}
            onClick={() => {
              if (canAddManually) {
                openAddDialogAction();
              } else {
                openGCSyncDialogAction();
              }
            }}
          />
        ) : null
      }
    />
  );
};

ManageClassesSubMenu.propTypes = {
  canAddManually: PropTypes.bool.isRequired,
  canAddGC: PropTypes.bool.isRequired,
};

export default ManageClassesSubMenu;
