import React from "react";
import PublicRoute from "./PublicRoute";
import type { RouteProps } from "react-router";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
//TODO: Confirm that we use the apiCalls jwtHandler and not the react components jwtHandler
import { getToken } from "../../apiCalls/jwtHandler";

interface WorkspaceRouteProps extends RouteProps {
  pagePermissions?: Array<string>;
}

//Add JWT bearer token see: https://www.apollographql.com/docs/react/networking/authentication/#header
const uri = createHttpLink({
  uri: `${process.env.REACT_APP_API_HAPARA_URL}/workspace/query`,
});
const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${await getToken()}`,
    },
  };
});

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case "Workspace":
        return object.ID; // use `ID` as the primary key
      case "Card":
        return object.ID; // use `ID` as the primary key
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

const graphqlClient = new ApolloClient({
  link: authLink.concat(uri),
  cache: cache,
});

export const WorkspaceRoute = ({
  component,
  ...rest
}: WorkspaceRouteProps): JSX.Element => {
  return (
    <ApolloProvider client={graphqlClient}>
      <PublicRoute {...rest} component={component} />
    </ApolloProvider>
  );
};

export default WorkspaceRoute;
