import React, { useRef } from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import styles from "./ClassInfoTable.module.scss";
import { Person } from "../types";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox";
import { useOverflow } from "@hapara/ui/src/hooks/useOverflow";

interface Props {
  person: Person;
  selectedPeople: Person[];
  dataTestIdPrefix: string;
  isSelectable: boolean;
  onCheckboxChange: (person: Person) => void;
}

export const ClassInfoTableBodyRow: React.FC<Props> = ({
  person,
  selectedPeople,
  dataTestIdPrefix,
  isSelectable,
  onCheckboxChange,
}) => {
  const emailCellRef = useRef<HTMLTableCellElement>(null);
  const nameCellRef = useRef<HTMLTableCellElement>(null);
  const { isOverflowing: isEmailOverflowing } = useOverflow(emailCellRef);
  const { isOverflowing: isNameOverflowing } = useOverflow(nameCellRef);

  const handleCheckboxChange = () => onCheckboxChange(person);

  return (
    <TableRow
      key={person.name}
      className={selectedPeople.includes(person) ? styles.selectedRow : ""}
      data-test-id={`${dataTestIdPrefix}-row`}
    >
      <TableCell
        ref={nameCellRef}
        align="left"
        data-test-id={`${dataTestIdPrefix}-name`}
      >
        {isSelectable ? (
          <Checkbox
            label=""
            ariaLabel={person.name}
            dataTestIdPrefix={`${dataTestIdPrefix}-checkbox-row`}
            checked={selectedPeople.includes(person)}
            onChange={handleCheckboxChange}
          />
        ) : null}
        {isNameOverflowing ? (
          <Tooltip
            title={person.name}
            placement="top"
            classes={{ tooltip: styles.tooltip }}
          >
            <span>{person.name}</span>
          </Tooltip>
        ) : (
          <span className={styles.name}>{person.name}</span>
        )}
      </TableCell>
      <TableCell
        ref={emailCellRef}
        data-test-id={`${dataTestIdPrefix}-email`}
        align="left"
      >
        {isEmailOverflowing ? (
          <Tooltip
            title={person.email}
            placement="top"
            classes={{ tooltip: styles.tooltip }}
          >
            <span>{person.email}</span>
          </Tooltip>
        ) : (
          person.email
        )}
      </TableCell>
    </TableRow>
  );
};
