import React, { useState, useEffect } from "react";
import { ModalDeprecated } from "@hapara/ui/src/deprecated/ModalDeprecated";
import { Button } from "@hapara/ui/src/atomic/Button";
import { ClassInfoNetworkErrorAlert } from "../../ClassInfoNetworkErrorAlert/index";
import { TextField } from "@hapara/ui/src/atomic/TextField";
import { useIntl } from "react-intl";

import styles from "./ClassInfoInputModal.module.scss";

export type ClassInfoInputModalRenameClassProps = {
  classDisplayName?: string;
  isOpen: boolean;
  isInProgress?: boolean;
  hasNetworkError?: boolean;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  onRenameClass: (newName: string) => void;
};

export const ClassInfoInputModalRenameClass: React.FC<
  ClassInfoInputModalRenameClassProps
> = ({
  isOpen,
  isInProgress,
  hasNetworkError,
  classDisplayName = "",
  onRequestClose,
  onRenameClass,
  dataTestIdPrefix,
}) => {
  const intl = useIntl();
  useEffect(() => {
    setInputValue(classDisplayName);
  }, [isOpen]);

  const [inputValue, setInputValue] = useState<string>("");
  const isSubmitDisabled = inputValue.length === 0 || isInProgress;

  const handleRenameAction = () => onRenameClass(inputValue);

  return (
    <ModalDeprecated
      isOpen={isOpen}
      onClose={onRequestClose}
      className={styles.modal}
      title={intl.formatMessage({
        defaultMessage: "Edit display name",
        id: "+djbRW",
      })}
      dataTestPrefix={dataTestIdPrefix}
    >
      <div className={styles.inputContainer}>
        <TextField
          value={inputValue}
          onChangeText={(e) => setInputValue(e)}
          aria-label={intl.formatMessage({
            defaultMessage: "New class name",
            id: "qqHFUV",
          })}
          dataTestId={`${dataTestIdPrefix}_Input`}
          maxLength={255}
          showLimitWarning
        />
        {hasNetworkError && (
          <ClassInfoNetworkErrorAlert className={styles.alertBox} />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          type="secondary"
          label={intl.formatMessage({ defaultMessage: "Cancel", id: "47FYwb" })}
          onClick={onRequestClose}
          isDisabled={isInProgress}
          dataTestId={`${dataTestIdPrefix}_Cancel`}
        />
        <Button
          type="primary"
          label={intl.formatMessage({
            defaultMessage: "Update display name",
            id: "gvd2HB",
          })}
          onClick={handleRenameAction}
          isLoading={isInProgress}
          isDisabled={isSubmitDisabled}
          dataTestId={`${dataTestIdPrefix}_Submit`}
        />
      </div>
    </ModalDeprecated>
  );
};
