import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserLoggingStatus } from "../../../state/user/selectors";
import {
  getIsConfigLoaded,
  getIsConfigLoading,
} from "../../../state/dashboard/config/selectors";
import { loadConfig } from "../../../state/dashboard/config/actions";

const ConfigHandler = () => {
  const dispatch = useDispatch();
  const loadConfigAction = useCallback(
    () => dispatch(loadConfig()),
    [dispatch]
  );
  const isUserLoggedIn = useSelector(getUserLoggingStatus);
  const isConfigLoading = useSelector(getIsConfigLoading);
  const isConfigLoaded = useSelector(getIsConfigLoaded);
  useEffect(() => {
    if (isUserLoggedIn && !isConfigLoading && !isConfigLoaded) {
      loadConfigAction();
    }
  }, [loadConfigAction, isConfigLoading, isUserLoggedIn, isConfigLoaded]);

  return null;
};

export default ConfigHandler;
