import React from 'react'
import styles from './Footer.module.scss'
import FeedbackForm from '../FeedbackForm/FeedbackForm'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FEEDBACK_PRODUCT_TYPES } from '../../../state/app/types'

export const Footer = ({ feedbackProductType }) => {
  return (
    <footer className={styles.root}>
      <div className={styles.container}>
        <FeedbackForm productType={feedbackProductType} />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  feedbackProductType: PropTypes.oneOf(_.values(FEEDBACK_PRODUCT_TYPES)).isRequired,
}

export default Footer
