import { ClassInfoDialogModal } from "./ClassInfoDialogModal";
import { useIntl, FormattedMessage } from "react-intl";

type ClassInfoDialogModalEditClassProps = {
  isOpen: boolean;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  modalAction?: () => void;
};

export const ClassInfoDialogModalEditClass: React.FC<
  ClassInfoDialogModalEditClassProps
> = ({ isOpen, dataTestIdPrefix, onRequestClose, modalAction }) => {
  const intl = useIntl();
  return (
    <ClassInfoDialogModal
      dataTestIdPrefix={dataTestIdPrefix}
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Edit class",
        id: "DDDipw",
      })}
      onRequestClose={onRequestClose}
      onConfirm={modalAction}
      confirmLabel={intl.formatMessage({
        defaultMessage: "Edit in Google Classroom",
        id: "kip5qx",
      })}
      isExternalConfirm
    >
      <FormattedMessage
        defaultMessage="To edit class details, please make changes directly in Google Classroom. Then, press the Sync Class button in the Class Info tab."
        id="eJPgEb"
      />
    </ClassInfoDialogModal>
  );
};
