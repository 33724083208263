import _ from "lodash";
import {
  hideAppError,
  showAppError,
  updateAccessError,
} from "../../../app/actions";
import actionTypes from "../../../actionTypes";
import { getSearchQuery } from "./selectors";
import { navigateToPLPage, navigateToPLSearchResults } from "../navigation";
import { fetchWorkspaces } from "../../../../apiCalls/workspaces";
import { PL_WORKSPACES_SEARCH_PAGE_SIZE } from "../types";

const plSearchWorkspacesPending = () => ({
  type: actionTypes.PL_SEARCH_WORKSPACES_PENDING,
});

const plSearchWorkspacesLoad = (payload) => ({
  type: actionTypes.PL_SEARCH_WORKSPACES_SUCCESS,
  payload,
});

const plSearchWorkspacesError = () => ({
  type: actionTypes.PL_SEARCH_WORKSPACES_ERROR,
});

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: actionTypes.PL_SEARCH_WORKSPACES_CLEAR,
  });
  navigateToPLPage();
};

export const changeSearchPage = (page) => (dispatch, getState) => {
  const state = getState();
  const searchQuery = getSearchQuery(state);
  navigateToPLSearchResults({ query: searchQuery, page: page });
};

/**
 * Updates the list of searched PL workspaces.
 */

export const searchPLWorkspaces =
  ({ query, page = 1 }) =>
  (dispatch) => {
    dispatch(plSearchWorkspacesPending());
    dispatch(hideAppError());

    return fetchWorkspaces({
      query,
      page,
      pageSize: PL_WORKSPACES_SEARCH_PAGE_SIZE,
    })
      .then((response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        return {
          items: _.get(data, "Results"),
          totalItems: _.get(data, "TotalHits"),
          pagesCount: _.get(data, "TotalPages"),
        };
      })
      .then(({ items, totalItems, pagesCount }) => {
        const payload = {
          items: _.map(items, (item) => ({
            ...item,
            Id: item.BoardId,
            IsArchived: false,
            MyAvailableActions: [],
            ModifiedDatetime: item.EditedOn,
          })),
          totalItems,
          pagesCount,
        };

        dispatch(plSearchWorkspacesLoad(payload));
      })
      .catch((error) => {
        dispatch(plSearchWorkspacesError());
        dispatch(showAppError(error));
      });
  };
