import React from "react";
import { TableContext } from "./TableContext";
import styles from "./TableView.module.scss";
import { countComponentsInChildren } from "./utils/countComponentsInChildren";
import { TableContent } from "./TableContent";
import classnames from "classnames";

export type TableViewProps = {
  dataTestIdPrefix?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
  onSelectAll?(): void;
  onSelect?(index: number | string): void;
  selected?: number[] | string[];
  minWidth?: number;
  emptyComponent?: React.ReactNode;
  footerComponent?: React.ReactNode | string;
  className?: string;
};

export const TableView = ({
  children,
  ariaLabel,
  // TODO: ariaDescribedBy
  dataTestIdPrefix,
  onSelectAll,
  onSelect,
  selected,
  minWidth,
  emptyComponent,
  footerComponent,
  className,
}: TableViewProps) => {
  // TODO: accept `totalRows` so we can work with pagination
  const rowCount = countComponentsInChildren(children, "Row");
  const dataTestId = dataTestIdPrefix ? `${dataTestIdPrefix}-table` : undefined;

  const createDataTestId = (suffix: string) =>
    dataTestId ? `${dataTestId}-${suffix}` : undefined;

  const isEmpty = rowCount === 0;
  return (
    <TableContext.Provider
      value={{
        dataTestIdPrefix,
        onSelectAll,
        onSelect,
        selected,
        rowCount,
      }}
    >
      {isEmpty && emptyComponent ? (
        <TableContent dataTestIdPrefix={dataTestIdPrefix}>
          {emptyComponent}
        </TableContent>
      ) : (
        <div className={styles.tableView}>
          <div
            className={classnames(
              { [styles.overflowContainer]: !isEmpty },
              className
            )}
            data-test-id={createDataTestId("overflowContainer")}
          >
            <table
              className={styles.table}
              aria-label={ariaLabel}
              aria-rowcount={rowCount}
              data-test-id={dataTestId}
              style={{ minWidth }}
            >
              {children}
            </table>
          </div>

          {footerComponent ? (
            <TableContent
              dataTestIdPrefix={dataTestIdPrefix}
              className={styles.footerComponent}
            >
              {footerComponent}
            </TableContent>
          ) : null}
        </div>
      )}
    </TableContext.Provider>
  );
};
