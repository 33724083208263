import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { waitForGlobal } from "@hapara/ui/src/components/utils";
import { loadGIS } from "../../../state/app/actions";
import {
  isGISLoaded,
  isGISLoading,
  isInitialLoadError,
} from "../../../state/app/selectors";
import {
  getUserLoggingStatus,
  isUnknownUser,
} from "../../../state/user/selectors";
import styles from "./LoginPage.module.scss";
import logo from "@hapara/assets/src/images/logo-hapara.svg";
import { APP_PAGES, locationType } from "../../../state/router/types";
import AppLoadingPage from "../AppLoadingPage/AppLoadingPage";
import { ALT_TEXT } from "../../../state/app/types";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import { MonitorLoginAttempts } from "@hapara/ui/src/components/MonitorLoginAttempts";

export const LoginPage = (props) => {
  const {
    isGISLoaded,
    isGISLoading,
    isUserLoggedIn,
    location,
    loadGIS,
    isInitialLoadError,
    isUnknownUser,
  } = props;

  // make sure GIS is loaded
  useEffect(() => {
    if (!isUserLoggedIn && !isGISLoaded && !isGISLoading) {
      waitForGlobal("window.google").then(() => {
        // window.google is ready to use
        loadGIS();
      });
    }
  }, [isUserLoggedIn, isGISLoaded, isGISLoading, loadGIS]);

  useEffect(() => {
    window?.google?.accounts?.id?.renderButton(
      document.getElementById("google-sign-in-button"),
      {
        theme: "outline",
        size: "large",
        logo_alignment: "center",
      }
    );
  }, [isGISLoaded, isGISLoading]);

  if (isUnknownUser) {
    return <NoAccessPage />;
  }

  if (isUserLoggedIn) {
    const from = _.get(location, "state.from", { from: { pathname: "/" } });
    return <Redirect to={from} />;
  }

  if (isGISLoaded && !isInitialLoadError) {
    return (
      <main className={styles.root} data-test-id="Hs-Pages-LoginPage">
        <Helmet>
          <title>{APP_PAGES.LOGIN.title}</title>
        </Helmet>
        <div className={styles.loginContainer}>
          <a
            href="http://hapara.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Hapara"
            className={styles.loginLogo}
            data-test-id="Hs-Login-Link-Logo"
          >
            <h1 lang="mi">
              <img src={logo} alt={ALT_TEXT.HAPARA_LOGO} />
            </h1>
          </a>
          <div className={styles.loginButtonContainer}>
            <div
              data-test-id="Hs-Login-Button-Login"
              id="google-sign-in-button"
            />
          </div>
        </div>
        <MonitorLoginAttempts />
      </main>
    );
  } else {
    return <AppLoadingPage isError={isInitialLoadError} />;
  }
};

LoginPage.propTypes = {
  isGISLoaded: PropTypes.bool.isRequired,
  isGISLoading: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  loadGIS: PropTypes.func.isRequired,
  location: locationType,
  isInitialLoadError: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isGISLoaded: isGISLoaded(state),
    isGISLoading: isGISLoading(state),
    isUserLoggedIn: getUserLoggingStatus(state),
    isInitialLoadError: isInitialLoadError(state),
    isUnknownUser: isUnknownUser(state),
  }),
  (dispatch) => ({
    loadGIS: () => dispatch(loadGIS(true)),
  })
)(LoginPage);
