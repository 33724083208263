import { FormattedMessage } from "react-intl";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import classnames from "classnames";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Input from "@hapara/ui/src/atomic/Input/Input";
import styles from "./UploadForm.module.scss";
import { formatFileSizeString } from "../../../../utils";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getFileUploadURI } from "../../../../state/library/uploadFile/actions";

const FILE_INPUT_STATE = {
  SELECT: "SELECT",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
  WRONG_FILE_SIZE: "WRONG_FILE_SIZE",
};

const FILE_SIZE_LIMIT = {
  size: 5e8, // 500 MB in bytes
  text: "500MB",
};

const PDF_EXTENSION = ".pdf";

const UploadFormFile = ({
  id,
  dataTestIdPrefix,
  selectedFile,
  onSelectedFileChange,
  onUploadedFileIdChange,
  onFileUploadedChange,
  onFileMetaChange,
  onError,
  requestUploadURI,
}) => {
  const [fileInputState, setFileInputState] = useState(
    !selectedFile ? FILE_INPUT_STATE.SELECT : FILE_INPUT_STATE.LOADED
  );
  const fileInputRef = useRef();
  const cancelRequest = useRef();

  const cancelTokenSource = axios.CancelToken.source();

  // clean up on unmount
  useEffect(() => {
    return () => {
      // cancel file upload on component unmount
      if (cancelRequest.current) {
        cancelRequest.current("Put request canceled by user");
      }
    };
  }, []);

  const handleFileUpload = (e) => {
    onError(false);
    const file = (_.get(e, "target.files") || [])[0];
    onFileMetaChange(file);
    if (file.size > FILE_SIZE_LIMIT.size) {
      setFileInputState(FILE_INPUT_STATE.WRONG_FILE_SIZE);
      return;
    }

    requestUploadURI()
      .then((resp) => {
        const fileId = _.get(resp, "fileID");
        const bucketUrl = _.get(resp, "url");

        if (!fileId || !bucketUrl) {
          setFileInputState(FILE_INPUT_STATE.ERROR);
          return;
        }

        onUploadedFileIdChange(fileId);

        onSelectedFileChange(file);
        setFileInputState(FILE_INPUT_STATE.LOADING);

        const putConfig = {
          headers: {
            "Content-Type": file.type,
          },
          cancelToken: cancelTokenSource.token,
        };
        axios
          .put(bucketUrl, file, putConfig)
          .then(() => {
            setFileInputState(FILE_INPUT_STATE.LOADED);
            onFileUploadedChange(true);
          })
          .catch((thrown) => {
            if (axios.isCancel(thrown)) {
              console.log("Request canceled", thrown.message);
            } else {
              setFileInputState(FILE_INPUT_STATE.ERROR);
            }
          });

        cancelRequest.current = cancelTokenSource.cancel;
      })
      .catch(() => {
        setFileInputState(FILE_INPUT_STATE.ERROR);
      });
  };

  const cancelUpload = () => {
    cancelRequest.current("Put request canceled by user");
    setFileInputState(FILE_INPUT_STATE.SELECT);
  };

  const removeFile = () => {
    onFileUploadedChange(false);
    onSelectedFileChange(null);
    setFileInputState(FILE_INPUT_STATE.SELECT);
  };

  const labelText = (
    <>
      File
      <span className={styles.required}>
        <FormattedMessage defaultMessage="(required)" id="VG94fP" />
      </span>
    </>
  );

  const infoText = (
    <p className={styles.infoText}>
      <FormattedMessage
        defaultMessage="The file will be converted into an accessible, responsive and easy-to-read digital format. This process may take a few days depending upon the number of pages submitted for processing. Progress can be checked on the conversion status page."
        id="viGpl1"
      />
    </p>
  );

  return (
    <div className={styles.fileBlock}>
      {(fileInputState === FILE_INPUT_STATE.SELECT ||
        fileInputState === FILE_INPUT_STATE.WRONG_FILE_SIZE ||
        fileInputState === FILE_INPUT_STATE.ERROR) && (
        <>
          <div className={styles.fieldLabel}>
            <label htmlFor={id}>{labelText}</label>
            {infoText}
            <div
              className={classnames(styles.fileDropZone, {
                [styles.incorrectFile]:
                  fileInputState === FILE_INPUT_STATE.WRONG_FILE_SIZE ||
                  fileInputState === FILE_INPUT_STATE.ERROR,
              })}
            >
              <Button
                label="Select a PDF"
                data-test-id={`${dataTestIdPrefix}-SelectPdfButton`}
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.REGULAR}
                className={styles.selectPdfButton}
                onClick={() => {
                  if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              />
              {fileInputState === FILE_INPUT_STATE.WRONG_FILE_SIZE && (
                <p className={styles.errorNote}>
                  <span>
                    <FormattedMessage defaultMessage="Error:" id="5elgIe" />
                  </span>{" "}
                  The file selected was over {FILE_SIZE_LIMIT.text}. Please
                  select a smaller file and try again.
                </p>
              )}
              {fileInputState === FILE_INPUT_STATE.ERROR && (
                <p className={styles.errorNote}>
                  <span>
                    <FormattedMessage defaultMessage="Error:" id="5elgIe" />
                  </span>{" "}
                  There was a problem uploading this file. Please try again.
                </p>
              )}
            </div>
          </div>
          <Input
            type="file"
            name={id}
            id={id}
            data-test-id={`${dataTestIdPrefix}-FileInput`}
            aria-required={true}
            accept={PDF_EXTENSION}
            onChange={handleFileUpload}
            className={styles.fileInput}
            ref={fileInputRef}
          />
        </>
      )}
      {fileInputState === FILE_INPUT_STATE.LOADING && (
        <>
          <div className={styles.fieldLabel}>
            {labelText}
            {infoText}
            <div className={styles.fileLoading}>
              <HapReactIcon
                svg="loader"
                width={32}
                height={32}
                spin={true}
                alt="Uploading PDF"
              />
              <div className={styles.fileInfo}>
                <div className={styles.name}>{selectedFile.name}</div>
                <div className={styles.size}>
                  {formatFileSizeString(selectedFile.size)}
                </div>
              </div>
              <Button
                label="Cancel"
                data-test-id={`${dataTestIdPrefix}-CancelUpload`}
                type={BUTTON_TYPES.OUTLINED}
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                size={BUTTON_SIZES.REGULAR}
                className={styles.cancelUpload}
                onClick={cancelUpload}
              />
            </div>
          </div>
        </>
      )}
      {fileInputState === FILE_INPUT_STATE.LOADED && (
        <>
          <div className={styles.fieldLabel}>
            {labelText}
            {infoText}
            <div className={styles.fileLoaded}>
              <HapReactIcon svg="google-pdf" width={32} height={32} />
              <div className={styles.fileInfo}>
                <div
                  className={styles.name}
                  data-test-id={`${dataTestIdPrefix}-FileName`}
                >
                  {selectedFile.name}
                </div>
                <div className={styles.size}>
                  {formatFileSizeString(selectedFile.size)}
                </div>
              </div>
              <Button
                icon="cross"
                type={BUTTON_TYPES.TERTIARY}
                size={BUTTON_SIZES.REGULAR}
                onClick={removeFile}
                data-test-id={`${dataTestIdPrefix}-RemoveUploadedFile`}
                alt="Remove file"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

UploadFormFile.propTypes = {
  id: PropTypes.string.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
  onSelectedFileChange: PropTypes.func.isRequired,
  onUploadedFileIdChange: PropTypes.func.isRequired,
  onFileMetaChange: PropTypes.func.isRequired,
  onFileUploadedChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // connected
  requestUploadURI: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    requestUploadURI: () => dispatch(getFileUploadURI({})),
  })
)(UploadFormFile);
