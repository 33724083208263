import actionTypes from "../../../actionTypes";

export const INIT_STATE = {
  itemsLoading: true,
  itemsLoadError: false,
  items: null,
  pagesCount: 0,
};

const listReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.PL_WORKSPACES_PENDING:
      return { ...state, itemsLoading: true, itemsLoadError: false };

    case actionTypes.PL_WORKSPACES_ERROR:
      return { ...state, itemsLoading: false, itemsLoadError: true, items: [] };

    case actionTypes.PL_WORKSPACES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsLoadError: false,
        items: action.items,
        pagesCount: action.pagesCount,
      };
    default:
      return state;
  }
};

export default listReducer;
