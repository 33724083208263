import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { areMyClassesLoaded } from "../../../../state/shared/selectors";
import {
  getCurrentPage,
  getPagesCount,
  isLoading,
  isListLoaded,
  getFilterCategoryType,
  getFilterClassId,
  getFilterStatusType,
  getFilterPlRole,
  getFilterSortType,
  getFilterLabelId,
  getActiveItemId,
} from "../../../../state/workspace/myworkspaces/list/selectors";
import {
  getIsSearchActive,
  getSearchPagesCount,
  getIsSearchLoading,
  getSearchCurrentPage,
} from "../../../../state/workspace/myworkspaces/search/selectors";
import {
  updateCategory,
  applyAllFilters,
  updateRightPanel,
} from "../../../../state/workspace/myworkspaces/list/actions";
import {
  MYWORKSPACES_FILTER_STATUS_TYPES,
  MYWORKSPACES_PL_ROLES,
  MYWORKSPACES_CATEGORIES,
} from "../../../../state/workspace/myworkspaces/list/types";
import { WORKSPACE_SORTING_TYPES } from "../../../../state/workspace/consts";
import { pushQueryToHistory } from "../../../../state/router/navigation";

export const LocationChangeHandler = ({
  areMyClassesLoaded,
  isUserDataLoaded,
  currentPage,
  pagesCount,
  isLoading,
  isListLoaded,
  updateCategory,
  filterCategoryType,
  filterClassId,
  filterStatusType,
  filterSortType,
  filterPlRole,
  filterLabelId,
  activeItemId,
  isSearchActive,
  isSearchLoading,
  searchCurrentPage,
  searchPagesCount,
  applyAllFilters,
  updateRightPanel,
}) => {
  const { category } = useParams();

  const dataFetchParamsRef = useRef();

  // listen to category change and update it in store
  useEffect(() => {
    const categoryType = _.get(
      _.find(MYWORKSPACES_CATEGORIES, { urlKey: category }),
      "type"
    );
    if (categoryType) {
      updateCategory(categoryType);
    }
  }, [category, updateCategory]);

  // listen to search params change and load new data if necessary
  useEffect(() => {
    if (isUserDataLoaded && areMyClassesLoaded) {
      const newDataFetchParams = {
        currentPage,
        filterCategoryType,
        filterClassId,
        filterStatusType,
        filterPlRole,
        filterSortType,
        filterLabelId,
      };

      if (filterCategoryType) {
        // load should happen if it is a first load
        let shouldLoadWorkspaces = !isListLoaded && !isLoading;

        // check all the params which should cause re-render;
        // load should happen if any given param was changed since last change
        _.forEach(newDataFetchParams, (value, key) => {
          const shouldDoTheLoad = !_.isEqual(
            _.get(dataFetchParamsRef.current, key),
            value
          );
          if (shouldDoTheLoad) {
            shouldLoadWorkspaces = true;
          }
        });

        // update the state and do the load
        if (shouldLoadWorkspaces) {
          applyAllFilters();
        }
      }

      // keep previous params values for the reference
      // so we can compare with the current ones
      dataFetchParamsRef.current = { ...newDataFetchParams };
    }
  }, [
    isUserDataLoaded,
    isLoading,
    isListLoaded,
    filterCategoryType,
    filterClassId,
    filterStatusType,
    filterPlRole,
    filterSortType,
    filterLabelId,
    applyAllFilters,
    areMyClassesLoaded,
    currentPage,
  ]);

  const activeItemIdRef = useRef();

  // listen to search params change and open/close the right panel
  useEffect(() => {
    // do not do anything until search results are loaded
    if (isListLoaded && !isLoading) {
      const currentActiveItemId = activeItemId;
      const prevActiveItemId = activeItemIdRef.current;

      if (
        currentActiveItemId &&
        (!prevActiveItemId || prevActiveItemId !== currentActiveItemId)
      ) {
        updateRightPanel(true);
      }

      // close the panel if
      // - selected_ws param was present before but now it is not
      if (prevActiveItemId && !currentActiveItemId) {
        updateRightPanel(false);
      }

      activeItemIdRef.current = activeItemId;
    }
  }, [activeItemId, isLoading, isListLoaded, updateRightPanel]);

  // resetting to the last page if page number bigger than total amount of pages;
  // helps when the last item on a page was deleted/archived etc;
  // for myworkspases list and search results
  useEffect(() => {
    const isListPageShouldChange =
      !isSearchActive &&
      !isLoading &&
      currentPage > pagesCount &&
      pagesCount !== 0;

    const isSearchPageShouldChange =
      isSearchActive &&
      !isSearchLoading &&
      searchCurrentPage > searchPagesCount &&
      searchPagesCount !== 0;

    if (isListPageShouldChange || isSearchPageShouldChange) {
      pushQueryToHistory({ page: pagesCount });
    }
  }, [
    isLoading,
    pagesCount,
    currentPage,
    isSearchActive,
    isSearchLoading,
    searchCurrentPage,
    searchPagesCount,
  ]);

  return null;
};

LocationChangeHandler.propTypes = {
  isUserDataLoaded: PropTypes.bool.isRequired,
  currentPage: PropTypes.number,
  pagesCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  isListLoaded: PropTypes.bool.isRequired,
  filterCategoryType: PropTypes.oneOf(
    _.map(MYWORKSPACES_CATEGORIES, (item) => item.type)
  ),
  filterClassId: PropTypes.string,
  filterStatusType: PropTypes.oneOf(_.values(MYWORKSPACES_FILTER_STATUS_TYPES)),
  filterSortType: PropTypes.oneOf(_.values(WORKSPACE_SORTING_TYPES)),
  filterPlRole: PropTypes.oneOf(_.values(MYWORKSPACES_PL_ROLES)),
  filterLabelId: PropTypes.string,
  activeItemId: PropTypes.string,
  updateCategory: PropTypes.func.isRequired,
  applyAllFilters: PropTypes.func.isRequired,
  updateRightPanel: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  searchCurrentPage: PropTypes.number,
  searchPagesCount: PropTypes.number,
};

export default connect(
  (state) => ({
    areMyClassesLoaded: areMyClassesLoaded(state),
    currentPage: getCurrentPage(state),
    pagesCount: getPagesCount(state),
    isLoading: isLoading(state),
    isListLoaded: isListLoaded(state),
    filterCategoryType: getFilterCategoryType(state),
    filterClassId: getFilterClassId(state),
    filterStatusType: getFilterStatusType(state),
    filterSortType: getFilterSortType(state),
    filterPlRole: getFilterPlRole(state),
    filterLabelId: getFilterLabelId(state),
    activeItemId: getActiveItemId(state),
    isSearchActive: getIsSearchActive(state),
    isSearchLoading: getIsSearchLoading(state),
    searchCurrentPage: getSearchCurrentPage(state),
    searchPagesCount: getSearchPagesCount(state),
  }),
  (dispatch) => ({
    updateCategory: (category) => dispatch(updateCategory(category)),
    applyAllFilters: () => dispatch(applyAllFilters()),
    updateRightPanel: (isOpen) => dispatch(updateRightPanel(isOpen)),
  })
)(LocationChangeHandler);
