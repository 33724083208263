import React from "react";
import PropTypes from "prop-types";
import styles from "./Reveal.module.scss";
import classnames from "classnames";
import _ from "lodash";

const RevealContext = React.createContext();

const Reveal = ({ children, disabled = false, expanded = false, onChange }) => {
  const [opened, setOpened] = React.useState(expanded);
  React.useEffect(() => {
    //for controlled components
    if (onChange) {
      setOpened(expanded);
    }
  }, [expanded, setOpened, onChange]);
  const ctx = {
    isDisabled: disabled,
    isExpanded: opened,
    toggleReveal: () => {
      if (onChange) {
        onChange(!opened);
      } else {
        setOpened(!opened);
      }
    },
  };
  return (
    <RevealContext.Provider value={ctx}>{children}</RevealContext.Provider>
  );
};

export const withExpanded = (f) => {
  return (
    <RevealContext.Consumer>
      {({ isExpanded }) => f(isExpanded)}
    </RevealContext.Consumer>
  );
};

export const RevealToggle = (props) => {
  const { toggleReveal, isDisabled, isExpanded } =
    React.useContext(RevealContext);
  const { children, className, component, _ariaLabel, ...rest } = props;
  const dataProps = _.mapValues(rest, function (o) {
    return typeof o === "function" ? o(isExpanded) : o;
  });
  if (component) {
    const Component = component;
    return (
      <Component
        className={className}
        onClick={() => {
          toggleReveal();
        }}
        disabled={isDisabled}
        aria-expanded={isExpanded}
        {...dataProps}
      >
        {children}
      </Component>
    );
  }
  return (
    <button
      className={classnames(styles.naked, styles.control, className)}
      onClick={(e) => {
        e.preventDefault();
        toggleReveal();
      }}
      disabled={isDisabled}
      aria-expanded={isExpanded}
      {...dataProps}
    >
      {children}
    </button>
  );
};

export const RevealDetails = ({ children }) => {
  const opened = React.useContext(RevealContext).isExpanded;
  if (!opened) {
    return null;
  }
  return <div className={classnames(styles.naked)}>{children}</div>;
};

Reveal.propTypes = {
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Reveal;
