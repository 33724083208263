import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalFormFreezeTypeSelection.module.scss";
import _ from "lodash";
import { GUIDE_BROWSING_FOCUS_TYPES } from "../../../../state/highlights/sessions/types";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import { FormattedMessage, useIntl } from "react-intl";

const ModalFormFreezeTypeSelection = ({
  focusType,
  onFocusTypeChange,
  dataTestPrefix,
}) => {
  const intl = useIntl();
  const handleRadioChange = (e) => {
    onFocusTypeChange(_.get(e, "target.value", ""));
  };

  const radioName = `${dataTestPrefix}-FreezeTypeRadio`;

  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormFreezeTypeBlock`}
    >
      <fieldset className={styles.sessionType}>
        <legend>
          <FormattedMessage
            defaultMessage="Where can students go?"
            id="KIUWpt"
          />
        </legend>
        <div className={styles.sessionOptions}>
          <div className={styles.radioButton}>
            <Radio
              name={radioName}
              id="hl-GuideBrowsingCreateSession-FreezeTypeRadio-Whole-Sites"
              value={GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE}
              checked={focusType === GUIDE_BROWSING_FOCUS_TYPES.WHOLE_SITE}
              onChange={handleRadioChange}
              label={intl.formatMessage({
                defaultMessage: "The whole site(s)",
                id: "lVxSVH",
              })}
              dataTestIdPrefix={`${dataTestPrefix}-Radio-Whole-Sites`}
            />
          </div>
          <div className={styles.radioButton}>
            <Radio
              name={radioName}
              id="hl-GuideBrowsingCreateSession-FreezeTypeRadio-Pages-Only"
              value={GUIDE_BROWSING_FOCUS_TYPES.LINKS}
              checked={focusType === GUIDE_BROWSING_FOCUS_TYPES.LINKS}
              onChange={handleRadioChange}
              label={intl.formatMessage({
                defaultMessage: "Only the page(s)",
                id: "QOMAak",
              })}
              dataTestIdPrefix={`${dataTestPrefix}-Radio-Pages-Only`}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

ModalFormFreezeTypeSelection.propTypes = {
  focusType: PropTypes.oneOf(_.values(GUIDE_BROWSING_FOCUS_TYPES)).isRequired,
  onFocusTypeChange: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default ModalFormFreezeTypeSelection;
