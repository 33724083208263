import { IanaCode } from "../getTimeZones/IanaCode";

/** Gets the current plain date in a specified time zone or otherwise local time. */
export const getCurrentDate = (timeZone?: IanaCode): string => {
  const currentDate = new Date();

  try {
    const { year, month, day } = new Intl.DateTimeFormat("en-US", {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
      .formatToParts(currentDate)
      .reduce((parts, part) => {
        parts[part.type] = part.value;
        return parts;
      }, {} as Record<keyof Intl.DateTimeFormatPartTypesRegistry, string>);

    return `${year}-${month}-${day}`;
  } catch (error) {
    throw new Error(`Invalid time zone: ${timeZone}`);
  }
};
