// https://github.com/Hapara/golang-microservices/blob/master/admin/pkg/shared/access_control.go#L50
export const defaultPermissions = {
  "access.library.resource.upload": false,
  "access.library.resource.add": false,
  "access.library.workspace.add": false,
  "access.library.collections.manage": false,

  "menu.show.gmail": false,
  "menu.show.highlights": false,
  "menu.show.workspace": false,

  "dashboard:add:class": false,
  "dashboard:gc:sync": false,
  "dashboard:manage:all": false,

  "access.gmail": false,
  "access.highlights": false,
  "access.workspace": false,
  "access.dashboard": false,
  "access.manage.classes": false,
  "access.student.library": false,
  "access.library": false,
  "access.workspace.private.library": false,
  "access.workspace.professional.learning": false,
  "access.student.dashboard": false,
  "access.class.students": false,
  "access.class.config": false,
  "access.chat": false,
} as const;

export type Permission = keyof typeof defaultPermissions;

export type Permissions = {
  [key in Permission]: boolean;
};
