import type { Centrifuge, Subscription } from "centrifuge";
import { logger } from "@hapara/logger";

/** Provide extra debugging for Centrifugo server */
export const serverEventListeners = (centrifuge: Centrifuge) =>
  centrifuge
    .on("state", (state) => {
      logger("messaging", "server state", state);
    })
    .on("message", (message) => {
      logger("messaging", "server message", message);
    })
    .on("error", (error) => {
      logger("messaging", "server error", error);
    });

/** Provide extra debugging for Centrifugo server */
export const subscriptionEventListeners = (subscription: Subscription) =>
  subscription
    .on("state", (subscriptionState) => {
      logger("messaging", "subscription state", subscriptionState);
    })
    .on("leave", (subscriptionLeave) => {
      logger("messaging", "subscription leave", subscriptionLeave);
    })
    .on("join", (subscriptionJoin) => {
      logger("messaging", "subscription join", subscriptionJoin);
    })
    .on("error", (subscriptionError) => {
      logger("messaging", "subscription error", subscriptionError);
    });
