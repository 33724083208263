import { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { useIntl } from "react-intl";
// SELECTORS
import { getStudentTilesSizeType } from "../../../../state/highlights/config/selectors";
import { STUDENT_TILES_SIZE_TYPES } from "../../../../state/highlights/config/types.js";
import { getUserFullName } from "src/state/user/selectors";
import { getFFByName } from "src/state/app/selectors";

// TYPES
import {
  DocumentShape,
  MonitoringTimeShape,
  Permissions,
  GetPermissionsPayloadShape,
} from "../StudentTileDrive/types";

// COMPONENTS
import StudentDriveTablePopup from "../StudentDriveTablePopup/StudentDriveTablePopup";

// API CALLS
import { getStudentDrivePermissions } from "../../../../apiCalls/highlights";

// UTILS
import { isLink } from "@hapara/ui/src/components/utils";
import { formatDate } from "../DriveMainContent/utils";

// HOOKS
import { useDebounce } from "@hapara/ui/src/hooks/useDebounce";

import styles from "./StudentTileDriveTable.module.scss";

interface Column {
  id: "Document" | "Last Modified";
  label: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  align?: "right" | "left" | "center";
}

interface Props {
  studentDisplayName: string;
  studentTileSize: string;
  tableData: DocumentShape[];
  classId: string;
  monitoringTime: MonitoringTimeShape;
  email: string;
  dataTestIdPrefix: string;
  userFullName: string | null;
}

const StudentTileDriveTable: React.FC<Props> = ({
  studentDisplayName,
  studentTileSize,
  tableData,
  email,
  monitoringTime,
  classId,
  dataTestIdPrefix,
  userFullName,
}) => {
  const intl = useIntl();
  const [popoverData, setPopoverData] = useState<DocumentShape | null>(null);
  const debouncedPopoverData = useDebounce(popoverData);
  const hasPermissionCheckClickToShare = useSelector(
    getFFByName("HAP-11164-CheckPermissionsBeforeClickToShare")
  );
  const [permissionData, setPermissionData] = useState<Permissions[] | null>(
    null
  );
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const getPermissions = async (payload: GetPermissionsPayloadShape) => {
    setIsPermissionsLoading(true);
    const permissions = await getStudentDrivePermissions(payload);
    if (permissions.data) {
      setIsReadOnly(permissions.data.is_read_only);
      const filteredPermissions = permissions.data.permissions.filter(
        (permission: Permissions) => permission.role !== "owner"
      );
      setPermissionData(filteredPermissions);
    } else {
      setPermissionData(null);
    }
    setIsPermissionsLoading(false);
  };

  const handlePopoverOpen = async (
    _event: React.MouseEvent<HTMLElement>,
    rowData: DocumentShape
  ) => setPopoverData(rowData);

  useEffect(() => {
    if (debouncedPopoverData) {
      getPermissions({
        dash_id: classId,
        tz: monitoringTime.Timezone,
        email: email,
        emails: [email],
        subject: "Documents",
        doc_id: debouncedPopoverData?.id,
      });
    }
  }, [debouncedPopoverData]);

  const navigateToUrl = (url: string, id: string) => {
    if (isLink(url) && id !== "" && email !== "" && classId !== "") {
      const encodedUrl = encodeURIComponent(url);
      const encodedEmail = encodeURIComponent(email);
      const domain = window.location.origin;
      const linkToShow = `${domain}/click-to-share?classId=${classId}&email=${encodedEmail}&docUrl=${encodedUrl}`;
      return window.open(linkToShow, "_blank");
    }
    return window.open(url, "_blank");
  };

  const navigateToUrlWithPermissionsCheck = useCallback(
    (url: string, id: string) => {
      const hasPermission = permissionData?.some(
        (permission) => permission.name === userFullName
      );
      if (
        isLink(url) &&
        id !== "" &&
        email !== "" &&
        classId !== "" &&
        !hasPermission
      ) {
        const encodedUrl = encodeURIComponent(url);
        const encodedEmail = encodeURIComponent(email);
        const domain = window.location.origin;
        const linkToShow = `${domain}/click-to-share?classId=${classId}&email=${encodedEmail}&docUrl=${encodedUrl}`;
        return window.open(linkToShow, "_blank");
      }
      return window.open(url, "_blank");
    },
    [permissionData, userFullName, email, classId]
  );

  const handleClick = (rowData: DocumentShape) =>
    hasPermissionCheckClickToShare
      ? navigateToUrlWithPermissionsCheck(rowData.url, rowData.id)
      : navigateToUrl(rowData.url, rowData.id);

  const handleEnter = (
    e: React.KeyboardEvent<HTMLDivElement>,
    rowData: DocumentShape
  ) => {
    if (e.key === "Enter") {
      navigateToUrl(rowData.url, rowData.id);
    }
  };

  const columns: readonly Column[] = [
    {
      id: "Document",
      label: intl.formatMessage({ defaultMessage: "Document", id: "wmirkP" }),
      width:
        studentTileSize === STUDENT_TILES_SIZE_TYPES.REGULAR ? "60%" : "70%",
      align: "left",
    },
    {
      id: "Last Modified",
      label: intl.formatMessage({
        defaultMessage: "Last Modified",
        id: "J8u7Xg",
      }),
      width:
        studentTileSize === STUDENT_TILES_SIZE_TYPES.REGULAR ? "40%" : "30%",
      align: "left",
    },
  ];

  const sortedData = tableData.sort((a, b) => {
    const dateA = new Date(a.modifiedDate);
    const dateB = new Date(b.modifiedDate);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <>
      <div className={styles.tableContainer}>
        <table tabIndex={0} className={styles.table} cellSpacing={0}>
          <caption className={styles.caption}>
            {intl.formatMessage(
              {
                defaultMessage: "{studentDisplayName}'s drive documents",
                id: "WiBqs/",
              },
              { studentDisplayName }
            )}
          </caption>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.id}
                  align={column.align}
                  style={{
                    width: column.width,
                    maxWidth: column.maxWidth,
                  }}
                  className={styles.tableHeader}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((row: DocumentShape, index) => (
              <StudentDriveTablePopup
                permissions={permissionData}
                isLoading={isPermissionsLoading}
                isReadOnly={isReadOnly}
                popoverData={debouncedPopoverData}
                key={row.id}
              >
                <tr
                  onClick={() => handleClick(row)}
                  onKeyDown={(e) => handleEnter(e, row)}
                  data-test-id={`${dataTestIdPrefix}-Document-Link`}
                  onMouseOver={(e) => handlePopoverOpen(e, row)}
                  tabIndex={0}
                  key={row.id}
                  className={styles.tableRow}
                  aria-label={`Row ${index + 1} of ${tableData.length}. ${
                    columns[0].label
                  }: ${row.title}, ${columns[1].label}: ${
                    row.viewed
                      ? `${formatDate(row.modifiedDate)}, ${
                          row.lastModifyingUserName
                        }`
                      : intl.formatMessage({
                          defaultMessage: "Not opened yet",
                          id: "xOXnlQ",
                        })
                  }`}
                >
                  <td
                    className={styles.tableCell}
                    style={{
                      width: columns[0].width,
                      maxWidth: columns[0].maxWidth,
                    }}
                  >
                    <span
                      className={styles.firstColumnContainer}
                      data-test-id={`${dataTestIdPrefix}-Document-Title`}
                    >
                      <img
                        className={styles.docIcon}
                        src={row.iconLink}
                        alt=""
                        data-test-id={`${dataTestIdPrefix}-Document-Icon`}
                      />
                      {row.title}
                    </span>
                  </td>
                  <td
                    className={styles.tableCell}
                    style={{
                      width: columns[1].width,
                      maxWidth: columns[1].maxWidth,
                    }}
                    data-test-id={`${dataTestIdPrefix}-Document-LastModified`}
                  >
                    {row.viewed
                      ? `${formatDate(row.modifiedDate)}, ${
                          row.lastModifyingUserName
                        }`
                      : intl.formatMessage({
                          defaultMessage: "Not opened yet",
                          id: "xOXnlQ",
                        })}
                  </td>
                </tr>
              </StudentDriveTablePopup>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect((state) => ({
  studentTileSize: getStudentTilesSizeType(state),
  userFullName: getUserFullName(state),
}))(StudentTileDriveTable);
