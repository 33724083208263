import { FormattedMessage } from "react-intl";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditedClass,
  getIsEditColourDialogOpened,
} from "../../../../state/dashboard/manage-classes/selectors";
import {
  closeEditColourDialog,
  updateClassColour,
} from "../../../../state/dashboard/manage-classes/actions";
import styles from "./EditClassModal.module.scss";
import ColorPicker from "@hapara/ui/src/components/ColorPicker/ColorPicker";
import ActionDialog from "@hapara/ui/src/atomic/ActionDialog/ActionDialog";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { TILE_DEFAULT_COLOUR } from "../ClassTile/ClassTile";
import _ from "lodash";
import { ClassTypeEnum } from "../../../../types/shared";

const EditClassModal = () => {
  const editColorModalOpened = useSelector(getIsEditColourDialogOpened);
  const editedClass = useSelector(getEditedClass);

  const dispatch = useDispatch();
  const closeColourModalDialog = useCallback(() => {
    dispatch(closeEditColourDialog());
  }, [dispatch]);
  const setClassColourAction = useCallback(
    (classURN, newColour) => {
      return dispatch(updateClassColour(classURN, newColour));
    },
    [dispatch]
  );
  const [selectedColor, setSelectedColor] = useState(
    editedClass && editedClass.color ? editedClass.color : TILE_DEFAULT_COLOUR
  );

  const classInfoURL = `${
    process.env.REACT_APP_HSUITE_BASE_URL
  }/class-info/${_.get(editedClass, "id")}`;

  return (
    <ActionDialog
      isOpen={editColorModalOpened}
      title="Edit class color"
      className={styles.root}
      actionLabel="Save"
      onClose={() => closeColourModalDialog()}
      errorMessage="Sorry, there was a problem making this change. Please try again."
      onAction={async () => {
        await setClassColourAction(editedClass.id, selectedColor);
      }}
      dataTestIdPrefix="td-ManageClasses-SetColour"
    >
      <div className={styles.content}>
        <ColorPicker
          flat={true}
          dataTestId="td-ManageClasses-SetColour-Picker"
          selectedColour={selectedColor}
          onSelectColour={(color) => setSelectedColor(color)}
        />
        <div className={styles.gap} />
        <Alert
          type={ALERT_TYPES.WARNING}
          className={styles.infoMessage}
          animated={false}
          role="Settings note"
        >
          <div>
            {editedClass.classType === ClassTypeEnum.GOOGLE_CLASSROOM && (
              <>
                <p>
                  If you wish to edit the details of this class, you will need
                  to make updates from
                  <a
                    href={`https://classroom.google.com/u/0/c/${_.get(
                      editedClass,
                      "googleClassroomId",
                      ""
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    data-test-id="td-ManageClasses-EditColor-GoogleClassroom-Link"
                    className={styles.classInfoLink}
                  >
                    <FormattedMessage
                      defaultMessage="Google Classroom"
                      id="ahFZVA"
                    />
                  </a>
                </p>
                <div className={styles.gap} />
                <p>
                  You can view your class details in
                  <a
                    href={classInfoURL}
                    data-test-id="td-ManageClasses-EditColor-ClassInfo-Link"
                    className={styles.classInfoLink}
                  >
                    <FormattedMessage defaultMessage="Class Info" id="mE2oyy" />
                  </a>
                </p>
              </>
            )}
            {editedClass.classType !== ClassTypeEnum.GOOGLE_CLASSROOM && (
              <p>
                You can manage your class settings in
                <a
                  href={classInfoURL}
                  data-test-id="td-ManageClasses-EditColor-ClassInfo-Link"
                  className={styles.classInfoLink}
                >
                  <FormattedMessage defaultMessage="Class Info" id="mE2oyy" />
                </a>
              </p>
            )}
          </div>
        </Alert>
      </div>
    </ActionDialog>
  );
};

export default EditClassModal;
