import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./Legend.module.scss";

export const LEGEND_COLOURS = {
  NAVY: "navy",
  LIGHT_BLUE: "light_blue",
  BLUE: "blue",
  ORANGE: "orange",
  GRAY: "gray",
};

const Legend = ({ colour, children, className }) => {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={classnames(styles.colourValue, styles[colour])}></div>
      <div className={classnames(styles.colourDescription)}>{children}</div>
    </div>
  );
};

Legend.propTypes = {
  colour: PropTypes.oneOf(_.values(LEGEND_COLOURS)).isRequired,
  className: PropTypes.string,
};

export default Legend;
