import axios from "axios";
import { getToken } from "../jwtHandler";
import { getErrorEvent, getErrorStringFromErrorOrResponse } from "../../utils";
import { postSendEvent } from "../user";
import { ApiError } from "../types";
import {
  ClassInfoAddPersonResponse,
  ClassInfoData,
  ClassInfoResponse,
  ClassInfoResetFoldersResponse,
} from "./classInfoTypes";
import {
  transformClassInfoResponse,
  transformAddStudentResponse,
  transformAddTeacherResponse,
  transformAddTeacherErrorResponse,
} from "./classInfoTransforms";

/** Request requires an empty object in the request body */
const emptyObjectRequestBody = {};

export const fetchClassInfoData = async (classId: string) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}`;
  const config = {
    headers: {
      Authorization: await getToken(),
      "Cache-Control": "no-cache",
    },
  };

  return axios
    .get<ClassInfoResponse>(url, config)
    .then((response) => {
      const classData: ClassInfoData = transformClassInfoResponse(
        response?.data
      );
      return classData;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classId}`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postAddStudentsToClass = async (
  classId: string,
  initialStudentEmails: string[]
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}/participant/add`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const maxRetries = 25;

  let studentEmails = [...initialStudentEmails]; // Create a copy of initial emails
  let badEmails: ClassInfoAddPersonResponse["Bad"] = [];
  let goodEmails: ClassInfoAddPersonResponse["Good"] = [];

  for (let retry = 0; retry < maxRetries; retry++) {
    try {
      const response = await axios.post<ClassInfoAddPersonResponse>(
        url,
        { EmailAddresses: studentEmails },
        config
      );

      const transformedResponse = transformAddStudentResponse(response.data);

      if (transformedResponse?.pendingStudents?.length > 0) {
        // Accumulate the unsuccessful & successful emails
        badEmails = [
          ...badEmails,
          ...transformedResponse.unsuccessfullyAddedStudents,
        ];
        goodEmails = [
          ...transformedResponse.successfullyAddedStudents,
          ...goodEmails,
        ];

        // Update studentEmails with pending students and good emails
        studentEmails = [...transformedResponse.pendingStudents, ...goodEmails];
        // Create a Set to remove duplicates
        studentEmails = [...new Set(studentEmails)];
      } else {
        // No more pending students, return the final response
        return {
          ...transformedResponse,
          successfullyAddedStudents: [
            ...goodEmails,
            ...transformedResponse.successfullyAddedStudents,
          ],
          unsuccessfullyAddedStudents: [
            ...badEmails,
            ...transformedResponse.unsuccessfullyAddedStudents,
          ],
        };
      }
    } catch (e: any) {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classId}/participant/add`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    }
  }

  // If max retries reached, throw an error
  throw new Error("Maximum retry count reached for postAddStudentsToClass");
};
export const postRemoveStudentsFromClass = async (
  classId: string,
  studentIds: string[]
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}/participant/remove`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .post<ClassInfoResponse>(url, { Users: studentIds }, config)
    .then((response) => {
      return transformClassInfoResponse(response.data);
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classId}/participant/remove`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postAddTeacherToClass = async (
  classId: string,
  teacherEmail: string
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}/manager/add`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .post<ClassInfoAddPersonResponse>(
      url,
      { EmailAddresses: [teacherEmail] },
      config
    )
    .then((response) => {
      return transformAddTeacherResponse(response.data);
    })
    .catch((e) => {
      const errorStatus = e?.response?.status;
      const errorMessages = e?.response?.data?.Message;
      const errorResponse = transformAddTeacherErrorResponse(
        errorMessages,
        errorStatus
      );
      const err = new ApiError(
        errorResponse.message,
        errorResponse.responseStatus,
        errorResponse.apiPath
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const putUpdateClassName = async (
  classId: string,
  newClassName: string
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/id/${classId}/prefs/user-group`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .put(url, { Nickname: newClassName }, config)
    .then((response) => {
      response;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/id/${classId}/prefs/user-group`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postUpdateClassGroupName = async (
  classGroupURN: string,
  newClassName: string
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classGroupURN}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .post(url, { Displayname: newClassName }, config)
    .then((response) => {
      response;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classGroupURN}`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postRemoveTeachersFromClass = async (
  classId: string,
  teacherIds: string[]
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}/manager/remove`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .post<ClassInfoResponse>(url, { Users: teacherIds }, config)
    .then((response) => {
      return transformClassInfoResponse(response.data);
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classId}/manager/remove`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postUpdateStudentPasswords = async (
  classId: string,
  studentIds: string[],
  forceReset: boolean,
  newPassword: string
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classId}/resetpwd`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const payload = {
    Users: studentIds,
    ForceReset: forceReset,
    NewPwd: newPassword,
  };
  return axios
    .post(url, payload, config)
    .then((response) => {
      response;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/edit/urn/${classId}/resetpwd`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postSyncGoogleClassroom = async (classId: string) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/sync-gclassroom/urn/${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return axios
    .post<ClassInfoResponse>(url, emptyObjectRequestBody, config)
    .then((response) => {
      const classData: ClassInfoData = transformClassInfoResponse(
        response?.data
      );
      return classData;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        `/v2/pugs/workgroup/sync-gclassroom/urn/${classId}`
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postRemoveClass = async (classGroupId: string) => {
  const url = new URL(
    `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/deactivate/id/${classGroupId}`
  );
  const config = {
    headers: { Authorization: await getToken() },
  };
  return axios
    .post(url.href, emptyObjectRequestBody, config)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status,
        url.pathname
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};

export const postResetClass = async (classFolderId: string) => {
  const url = new URL(
    `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/workgroup/edit/urn/${classFolderId}/reprovision`
  );
  const config = {
    headers: { Authorization: await getToken() },
  };

  return axios
    .post<ClassInfoResetFoldersResponse>(
      url.href,
      emptyObjectRequestBody,
      config
    )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      const err = new ApiError(
        getErrorStringFromErrorOrResponse(e),
        e?.response?.status
      );
      postSendEvent(getErrorEvent(err));
      throw err;
    });
};
