import React, { useState } from "react";
import classnames from "classnames";
import _ from "lodash";
import { Link } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styles from "./SideNavItem.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Button from "@hapara/ui/src/atomic/Button/Button";
import { LoadingState } from "./LoadingState";
import { EmptyState } from "./EmptyState";
import { ErrorState } from "./ErrorState";
import { SideNavSubItem } from "./SideNavSubItem";

interface SideNavItemProps extends WrappedComponentProps {
  color?: "green" | "violet" | "blue";
  to: string;
  icon?: string;
  title: string;
  isActive?: boolean;
  className?: string;
  type?: "no-sub-items" | "with-sub-items";
  isError?: boolean;
  isLoading?: boolean;
  subItemsList?: {
    to: string;
    title: string;
    icon?: string;
    isActive?: boolean;
  }[];
  emptyStateText?: string;
  errorStateText?: string;
  showAllText?: string;
  dataTestIdPrefix?: string;
  onTryAgain?: () => void;
}

const SideNavItem: React.FC<SideNavItemProps> = ({
  intl,
  color = "blue",
  to = "/",
  icon = "circle-blackboard-gray",
  title,
  isActive = false,
  className,
  type = "no-sub-items",
  isError = false,
  isLoading = false,
  subItemsList = [],
  emptyStateText = intl.formatMessage({
    id: "+gqTCB",
    defaultMessage: "No items found.",
  }),
  errorStateText = intl.formatMessage({
    id: "Sl6qW+",
    defaultMessage: "Trouble loading items",
  }),
  showAllText = intl.formatMessage({
    id: "JMac37",
    defaultMessage: "Show all",
  }),
  dataTestIdPrefix = "SideNav",
  onTryAgain = () => {},
  ...rest
}) => {
  const [isShowMoreActive, setIsShowMoreActive] = useState(false);

  if (type === "no-sub-items") {
    return (
      <Link
        to={to}
        className={classnames(styles.root, className, styles[color], {
          [styles.active]: isActive,
        })}
        aria-label={
          isActive
            ? intl.formatMessage(
                {
                  defaultMessage: "{linkName}, selected",
                  id: "AfHFcM",
                },
                { linkName: title }
              )
            : title
        }
        {...rest}
      >
        <div className={styles.icon}>
          <HapReactIcon svg={icon} width={28} height={28} />
        </div>
        <div className={styles.title}>{title}</div>
      </Link>
    );
  }

  if (type === "with-sub-items") {
    const VIEWS = {
      ERROR: "error",
      LOADING: "loading",
      EMPTY: "empty",
      ITEMS: "items",
    };
    let view = VIEWS.ITEMS;

    if (isError) {
      view = VIEWS.ERROR;
    } else if (isLoading) {
      view = VIEWS.LOADING;
    } else if (!subItemsList || !subItemsList.length) {
      view = VIEWS.EMPTY;
    }

    const subItemsToShow = isShowMoreActive
      ? subItemsList
      : _.slice(subItemsList, 0, 10);

    return (
      <div
        className={classnames(
          styles.rootWithSubItems,
          className,
          styles[color],
          {
            [styles.active]: isActive,
          }
        )}
        {...rest}
      >
        <div className={styles.header}>
          <div className={styles.icon}>
            <HapReactIcon svg={icon} width={28} height={28} />
          </div>
          <div className={styles.title}>{title}</div>
        </div>

        {view === VIEWS.LOADING && <LoadingState />}

        {view === VIEWS.EMPTY && <EmptyState emptyStateText={emptyStateText} />}

        {view === VIEWS.ERROR && (
          <ErrorState
            dataTestIdPrefix={dataTestIdPrefix}
            onTryAgain={onTryAgain}
            errorStateText={errorStateText}
          />
        )}

        {view === VIEWS.ITEMS && (
          <div className={styles.subItems}>
            <ul>
              {_.map(subItemsToShow, (item, index) => {
                return (
                  <li key={index}>
                    <SideNavSubItem item={item} color={color} />
                  </li>
                );
              })}
            </ul>

            {subItemsList.length > 10 && (
              <Button
                label={
                  isShowMoreActive
                    ? intl.formatMessage({
                        id: "qyJtWy",
                        defaultMessage: "Show less",
                      })
                    : showAllText
                }
                type="outlined"
                size="small"
                outlineType="solid"
                className={styles.showAll}
                onClick={() => setIsShowMoreActive(!isShowMoreActive)}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default injectIntl(SideNavItem);

interface SideNavDividerProps {
  className?: string;
}

export const SideNavDivider: React.FC<SideNavDividerProps> = ({
  className,
}) => {
  return <div className={classnames(styles.divider, className)} />;
};
