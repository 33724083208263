import React from "react";
import classnames from "classnames";
import { TimePicker as AntTimePicker } from "antd";
import styles from "./TimePicker.module.scss";
import "../../atomic/DatePicker/DateTimePicker.css";
import { Moment } from "moment";

interface TimePickerProps {
  size?: "large" | "middle" | "small";
  use12Hours?: boolean;
  format?: string;
  value?: Moment;
  onChange?: (dateMoment: Moment | null, dateString: string) => void;
  allowClear?: boolean;
  disabledDate?: (currentDate: Moment) => boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  className?: string;
  placeholder?: string;
}

/** @deprecated User TimePickerField instead */
export const TimePicker = ({ className, ...rest }: TimePickerProps) => {
  return (
    <AntTimePicker className={classnames(styles.root, className)} {...rest} />
  );
};
