import _ from "lodash";
import { history } from "../store";
import { parseSearchStringToObject } from "../../utils";
import { APP_PAGES } from "./types";
import {
  MYWORKSPACES_URL_KEYS,
  MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../workspace/myworkspaces/list/navigation";
import {
  GRADING_URL_KEYS,
  GRADING_URL_PARAMS_DEFAULT_VALUES,
} from "../workspace/grading/navigation";
import {
  PL_WORKSPACES_URL_KEYS,
  PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../workspace/pl/navigation";
import {
  LIBRARY_URL_KEYS,
  LIBRARY_URL_PARAMS_DEFAULT_VALUES,
} from "../library/library/navigation";
import {
  MY_RESOURCES_URL_KEYS,
  MY_RESOURCES_URL_PARAMS_DEFAULT_VALUES,
} from "../library/myResources/navigation";
import {
  SHARED_URL_KEYS,
  SHARED_URL_PARAMS_DEFAULT_VALUES,
} from "../shared/navigation-keys";

export const getUpdatedParamList = ({ search, newParams, pathname }) => {
  const res = [];
  const currentParams = parseSearchStringToObject(search);

  // the list of query params we control across various sections
  const URL_DATA = {
    URL_KEYS: SHARED_URL_KEYS,
    DEFAULT_VALUES: SHARED_URL_PARAMS_DEFAULT_VALUES,
  };

  // check if we are in the section where we control additional query params
  if (_.startsWith(pathname, APP_PAGES.WORKSPACE_MY_WORKSPACE.path)) {
    URL_DATA.URL_KEYS = { ...SHARED_URL_KEYS, ...MYWORKSPACES_URL_KEYS };
    URL_DATA.DEFAULT_VALUES = {
      ...SHARED_URL_PARAMS_DEFAULT_VALUES,
      ...MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES,
    };
  }
  if (_.startsWith(pathname, APP_PAGES.WORKSPACE_GRADING.path)) {
    URL_DATA.URL_KEYS = GRADING_URL_KEYS;
    URL_DATA.DEFAULT_VALUES = GRADING_URL_PARAMS_DEFAULT_VALUES;
  }
  if (_.startsWith(pathname, APP_PAGES.PROFESSIONAL_LEARNING.path)) {
    URL_DATA.URL_KEYS = PL_WORKSPACES_URL_KEYS;
    URL_DATA.DEFAULT_VALUES = PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES;
  }
  if (_.startsWith(pathname, APP_PAGES.MY_RESOURCES_BASE.path)) {
    URL_DATA.URL_KEYS = { ...SHARED_URL_KEYS, ...MY_RESOURCES_URL_KEYS };
    URL_DATA.DEFAULT_VALUES = {
      ...SHARED_URL_PARAMS_DEFAULT_VALUES,
      ...MY_RESOURCES_URL_PARAMS_DEFAULT_VALUES,
    };
  }
  if (_.startsWith(pathname, APP_PAGES.LIBRARY_BASE.path)) {
    URL_DATA.URL_KEYS = { ...SHARED_URL_KEYS, ...LIBRARY_URL_KEYS };
    URL_DATA.DEFAULT_VALUES = {
      ...SHARED_URL_PARAMS_DEFAULT_VALUES,
      ...LIBRARY_URL_PARAMS_DEFAULT_VALUES,
    };
  }

  const removeDependantsByKey = (params, key) => {
    const dependantsKeysToReset = _.get(
      URL_DATA.URL_KEYS,
      `${key}.dependantsKeysToReset`,
      []
    );
    if (!_.isEmpty(dependantsKeysToReset)) {
      _.forEach(dependantsKeysToReset, (dependantKey) => {
        removeDependantsByKey(params, dependantKey);
        delete params[dependantKey];
      });
    }
  };

  _.mapKeys(newParams, (value, key) => {
    const defaultValue = URL_DATA.DEFAULT_VALUES[key];
    const shouldDefaultBeInUrl = _.get(
      URL_DATA.URL_KEYS,
      `${key}.shouldDefaultBeInUrl`,
      false
    );

    let equalToDefault = false;
    const isArray = Array.isArray(value);

    if (isArray && Array.isArray(defaultValue)) {
      if (_.isEqual(value.sort(), defaultValue.sort())) {
        equalToDefault = true;
      }
    } else {
      if (value === defaultValue) {
        equalToDefault = true;
      }
    }

    if (equalToDefault && !shouldDefaultBeInUrl) {
      delete currentParams[key];
    } else {
      currentParams[key] = isArray ? value.sort() : value;
    }

    removeDependantsByKey(currentParams, key);
  });

  _.mapKeys(currentParams, (value, key) => {
    res.push(`${key}=${value}`);
  });

  return res || [];
};

export const pushQueryToHistory = (params) => {
  const cleanedSearch = history.location.search.replace("?", "");
  const pathname = history.location.pathname;

  // normalize params for sections where we control query param list
  const newParamList = getUpdatedParamList({
    search: cleanedSearch,
    newParams: params,
    pathname,
  });

  history.push({
    pathname,
    search: newParamList.length > 0 ? `?${newParamList.join("&")}` : "",
  });
};

export const replaceQueryInHistory = (params) => {
  const cleanedSearch = history.location.search.replace("?", "");
  const pathname = history.location.pathname;

  // normalize params for sections where we control query param list
  const newParamList = getUpdatedParamList({
    search: cleanedSearch,
    newParams: params,
    pathname,
  });

  history.replace({
    pathname,
    search: newParamList.length > 0 ? `?${newParamList.join("&")}` : "",
  });
};
