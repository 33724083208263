import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import Tippy from "@tippy.js/react";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { showResourceAssignClassModal } from "../../../../state/resources/actions";
import { dropdownItemPropsType } from "../../../../state/resources/types";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";

const ActionAssignToClasses = ({
  id,
  dataTestPrefix,
  isDropdownAction = false,
  dropdownItemProps,
  showResourceAssignClassModal,
  buttonType = BUTTON_TYPES.SECONDARY,
  label = "Share",
  tooltipLabel,
  dataTestIdPrefixWithResourceTile,
}) => {
  const actionDataTestId = `${dataTestPrefix}-AssignToClasses-Button`;
  const actionDataTestIdWithResourceTile = `${dataTestIdPrefixWithResourceTile}-AssignToClasses-Button`;
  const handleActionOnClick = (e) => {
    e.stopPropagation();
    showResourceAssignClassModal({ resourceId: id });
  };

  const actionButton = (
    <MemorisedFocusButton
      label={label}
      icon="profile-group"
      type={buttonType}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      onClick={handleActionOnClick}
      data-test-id={
        dataTestIdPrefixWithResourceTile
          ? actionDataTestIdWithResourceTile
          : actionDataTestId
      }
      isFullWidth={true}
    />
  );

  const dropdownButton = (
    <button
      type="button"
      data-test-id={actionDataTestId}
      {...dropdownItemProps}
      onClick={(e) => {
        handleActionOnClick(e);
        dropdownItemProps.onClick();
      }}
    >
      {label}
    </button>
  );

  return (
    <>
      {!isDropdownAction && tooltipLabel && (
        <Tippy content={tooltipLabel} theme="hsuite">
          {actionButton}
        </Tippy>
      )}

      {!isDropdownAction && !tooltipLabel && actionButton}

      {isDropdownAction && dropdownButton}
    </>
  );
};

ActionAssignToClasses.propTypes = {
  id: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  isDropdownAction: PropTypes.bool,
  dropdownItemProps: dropdownItemPropsType,
  label: PropTypes.string,
  tooltipLabel: PropTypes.string,
  buttonType: PropTypes.oneOf(_.values(BUTTON_TYPES)),
  dataTestIdPrefixWithResourceTile: PropTypes.string,
  // connected
  showResourceAssignClassModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showResourceAssignClassModal: ({ resourceId }) =>
      dispatch(showResourceAssignClassModal({ resourceId })),
  })
)(ActionAssignToClasses);
