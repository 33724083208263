import axios from "axios";
import { getToken } from "./jwtHandler";
import { ApiError } from "./types";
import { getErrorEvent, getErrorStringFromErrorOrResponse } from "../utils";
import { postSendEvent } from "./user";
import _ from "lodash";

type ClassServerSummary = {
  ID: string;
  Name: string;
  IsTeaching: boolean;
  SubjectFolders: string[];
  DataSource: string;
  ExternalID: string;
};

type ClassSummary = {
  class_alias: string;
  id: string;
  isTeaching: boolean;
  subjectFolders: string[];
  dataSource: string;
  externalID: string;
};

export async function getMyClasses(): Promise<ClassSummary[]> {
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API_HAPARA_URL}/admin/config/classes`,
      config
    );
    const classes = res.data;
    return classes.map((c: ClassServerSummary) => {
      const sanitizedFolderNames = c?.SubjectFolders?.map((folderName) => {
        if (folderName === "*") {
          return "Documents";
        } else {
          return encodeURIComponent(folderName);
        }
      });

      return {
        id: c.ID,
        class_alias: c.Name,
        isTeaching: c.IsTeaching,
        subjectFolders: sanitizedFolderNames,
        dataSource: c.DataSource,
        externalID: c.ExternalID,
      } as ClassSummary;
    });
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/admin/config/classes"
    );
    postSendEvent(getErrorEvent(err));
    throw err;
  }
}

type Task = {
  ID: string;
};

export async function getClassRelatedTasks(cl: String): Promise<Task[]> {
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_API_HAPARA_URL}/tasks/teacher/active`,
      {
        Name: "data-sync-class",
        Context: {
          class: cl,
        },
      },
      config
    );
    const tasks = res.data;
    return tasks;
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/tasks/teacher/active"
    );
    postSendEvent(getErrorEvent(err));
    return [];
  }
}

function timeout(ms: number) {
  return new Promise((resolve) => window.setTimeout(resolve, ms));
}

export async function waitForTaskCompletion(taskId: String): Promise<void> {
  try {
    const config = {
      headers: { Authorization: await getToken() },
    };
    let completed = false;
    const startTime = new Date().getTime();
    while (!completed && new Date().getTime() < startTime + 5 * 1000) {
      await timeout(1000);
      const res = await axios.post(
        `${process.env.REACT_APP_API_HAPARA_URL}/tasks/teacher/show`,
        {
          id: taskId,
        },
        config
      );
      const task = res.data;
      completed = task.Status === "Completed";
    }
  } catch (e) {
    const err = new ApiError(
      getErrorStringFromErrorOrResponse(e),
      _.get(e, "response.status"),
      "/tasks/teacher/show"
    );
    postSendEvent(getErrorEvent(err));
    //do not stop teacher from accessing the class if tasks are not working
    return;
  }
}

export const fetchEmailFormatPreference = async (classId: string) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/urn/${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const response = axios.get(url, config);
  return response;
};
