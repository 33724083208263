import React, { useCallback, useState, useRef, useEffect } from "react";
import styles from "./SmartshareButtons.module.scss";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../../../atomic/Button/Button";
import classnames from "classnames";
import { SmartshareDialog } from "../SmartshareDialog/SmartshareDialog";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "../../../../atomic/Dropdown/Dropdown";
import RecentShares from "../RecentShares/RecentShares";
import RecentShareModal from "../RecentShareModal/RecentShareModal";
import {
  getRecentShares,
  SMARTSHARE_STATUS,
} from "../../../../apiCalls/smartshare";
import _ from "lodash";
import { getReadyToRenderData } from "./SmartshareButtonsUtils";
import { useIntl } from "react-intl";

export const REFRESH_SECONDS = 5;

export const SmartshareButtons = ({
  onSharesUpdate,
  nameFormat,
  classUrn,
  hasUpdatedSharedDrive,
}) => {
  const [isSmartshareOpened, setIsSmartshareOpened] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [monitoringOn, setMonitoringOn] = useState(false);
  const [recentlySharedItems, setRecentlySharedItems] = useState([]);
  const { google } = window;
  const tokenClient = useRef();
  const accessToken = useRef();
  const intl = useIntl();

  const TEST_ENV = "test";
  const PROD_ENV = "production";
  const DEVELOPMENT_ENV = "development";
  const getCurrentEnv = () => {
    const host = window.location.hostname || "";
    if (host.match(/-test/)) {
      return TEST_ENV;
    } else if (host.match(/localhost/)) {
      return DEVELOPMENT_ENV;
    }
    return PROD_ENV;
  };

  const isProduction = () => {
    return getCurrentEnv() === PROD_ENV;
  };

  const googleDrivePickerScope = "https://www.googleapis.com/auth/drive.file";

  useEffect(() => {
    tokenClient.current = google.accounts.oauth2.initTokenClient({
      client_id: !isProduction()
        ? "283337183316-ric24sm1pf4j5bkiv4ev79g63vnqs9la.apps.googleusercontent.com"
        : "283337183316.apps.googleusercontent.com",
      scope: googleDrivePickerScope,
      callback: async (response) => {
        if (response.error) {
          throw response;
        }
      },
      prompt: "",
    });
  }, [google]);

  const loadShares = useCallback(async () => {
    const items = await getRecentShares(classUrn);
    setRecentlySharedItems(items);
    let isInProgress = false;
    items.forEach((item) => {
      if (
        item.status === SMARTSHARE_STATUS.SHARE_PROGRESS ||
        item.status === SMARTSHARE_STATUS.UNSHARE_PROGRESS ||
        item.status === SMARTSHARE_STATUS.UNSHARE_SCHEDULED
      ) {
        isInProgress = true;
      }
    });

    setMonitoringOn(isInProgress);
    if (!isInProgress) {
      onSharesUpdate();
    }
    return items.length;
  }, [getRecentShares, setRecentlySharedItems, classUrn, recentlySharedItems]);

  const modalDataProcess = modalData
    ? _.find(recentlySharedItems, (i) => i.id === modalData)
    : null;
  const modalDataItem = modalDataProcess
    ? getReadyToRenderData(modalDataProcess)
    : null;

  useEffect(() => {
    loadShares();
    if (monitoringOn) {
      const intervalId = setInterval(async () => {
        if (monitoringOn) {
          await loadShares();
        }
      }, REFRESH_SECONDS * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [monitoringOn]);

  const dropdownTrigger = useCallback(
    (props) => (
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "View recently shared files",
          id: "cmi/v1",
        })}
        maxWidth={100}
        placement="bottom"
        theme="hsuite"
        delay={700}
        distance={4}
      >
        <Button
          icon={!monitoringOn ? "list-checkmark" : "list-arrow-animated"}
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.XSMALL}
          dataTestId="common-SmartShare-Dropdown-Open"
          className={classnames(styles.buttonToOpenDD, styles.focusInside)}
          aria-label={intl.formatMessage({
            defaultMessage: "List of recently shared files",
            id: "KEr5Fa",
          })}
          {...props}
        />
      </Tippy>
    ),
    [monitoringOn]
  );
  const changeDropDownStatus = useRef(null);

  const dropdownContent = useCallback(
    (openDropdown) => {
      changeDropDownStatus.current = openDropdown;
      return (
        <RecentShares
          items={recentlySharedItems.map(getReadyToRenderData)}
          monitorShareUnshare={() => {
            setMonitoringOn(true);
          }}
          onOpenRecentShareDialog={(id) => {
            setModalData(id);
            setModalIsOpen(true);
          }}
        />
      );
    },
    [setModalData, setModalIsOpen, recentlySharedItems]
  );

  useEffect(() => {
    if (changeDropDownStatus.current) {
      changeDropDownStatus.current(!modalIsOpen);
    }
  }, [modalIsOpen, changeDropDownStatus.current]);

  return (
    <div className={styles.root}>
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "Hand out digital work by creating and sharing files",
          id: "2gyJGl",
        })}
        maxWidth={150}
        placement="bottom"
        theme="hsuite"
        delay={700}
        distance={4}
      >
        <Button
          label={intl.formatMessage({
            defaultMessage: "Share files",
            id: "qTznkc",
          })}
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.XSMALL}
          onClick={() => {
            changeDropDownStatus.current = null;
            setIsSmartshareOpened(true);
          }}
          dataTestId="common-SmartShare-Button-Share"
          className={classnames(styles.focusInside, styles.shareButton)}
        />
      </Tippy>
      <Dropdown
        className={styles.fileShareTypes}
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
        triggerComponent={dropdownTrigger}
        content={dropdownContent}
      />
      {isSmartshareOpened && (
        <SmartshareDialog
          hasUpdatedSharedDrive={hasUpdatedSharedDrive}
          accessToken={accessToken}
          tokenClient={tokenClient}
          onClose={() => {
            setIsSmartshareOpened(false);
          }}
          nameFormat={nameFormat}
          classUrn={classUrn}
          monitorShareUnshare={() => {
            setMonitoringOn(true);
          }}
        />
      )}
      {modalDataItem && (
        <RecentShareModal
          isOpen={modalIsOpen}
          onClose={() => {
            changeDropDownStatus.current = null;
            setModalIsOpen(false);
            setModalData(null);
          }}
          process_ids={[modalData]}
          documents={modalDataItem.documents}
          status={modalDataItem.status}
          errorText={modalDataItem.errorText}
          title={modalDataItem.modalTitle}
          classes={modalDataItem.classes}
          groups={modalDataItem.groups}
          students={modalDataItem.students}
        />
      )}
    </div>
  );
};

SmartshareButtons.propTypes = {
  onSharesUpdate: PropTypes.func.isRequired,
  nameFormat: PropTypes.string.isRequired,
  classUrn: PropTypes.string.isRequired,
  hasUpdatedSharedDrive: PropTypes.bool,
};

export default SmartshareButtons;
