import React, { useState, useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteGuideBrowsingTemplate } from "../../../../../../state/highlights/sessions/actions";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import { fetchTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import { GUIDE_BROWSING_TEMPLATE_TYPE } from "../../../../../../state/highlights/sessions/types";
import { FocusContext } from "@hapara/ui/src/components/utils";
function DeleteTemplateModal({
  dataTestPrefix,
  templateToDelete,
  onClose,
  loadTemplatesList,
  deleteGuideBrowsingTemplate,
}) {
  const [isDeleteTemplateInProgress, setIsDeleteTemplateInProgress] =
    useState(false);
  const [isDeleteTemplateError, setIsDeleteTemplateError] = useState(false);
  const focusContext = useContext(FocusContext);

  const doClose = () => {
    if (focusContext.focusRef && focusContext.focusRef.current) {
      focusContext.focusRef.current.focus();
    }
    onClose();
  };

  const handleDeleteTemplate = (e) => {
    e.stopPropagation();
    const id = _.get(templateToDelete, "ID");
    if (id) {
      setIsDeleteTemplateInProgress(true);
      setIsDeleteTemplateError(false);

      deleteGuideBrowsingTemplate(id)
        .then(() => {
          setIsDeleteTemplateInProgress(false);
          loadTemplatesList();
          doClose();
        })
        .catch(() => {
          setIsDeleteTemplateInProgress(false);
          setIsDeleteTemplateError(true);
        });
    }
  };

  return (
    <ActionConfirmationDialog
      isOpen={true}
      title="Delete?"
      subTitle={_.get(templateToDelete, "Name", "")}
      content="This template will be removed and will not be retrievable."
      isActionDanger={true}
      actionLabel="Delete"
      onClose={doClose}
      error={
        isDeleteTemplateError
          ? "Sorry, there was a problem deleting this template. Please try again."
          : null
      }
      onAction={handleDeleteTemplate}
      isActionButtonDisabled={isDeleteTemplateInProgress}
      isActionInProgress={isDeleteTemplateInProgress}
      actionDataTestId={`${dataTestPrefix}-button-templateDelete`}
      cancelDataTestId={`${dataTestPrefix}-button-templateCancelDelete`}
      dataTestPrefix={dataTestPrefix}
    />
  );
}

DeleteTemplateModal.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  templateToDelete: GUIDE_BROWSING_TEMPLATE_TYPE,
  onClose: PropTypes.func.isRequired,
  loadTemplatesList: PropTypes.func.isRequired,
  deleteGuideBrowsingTemplate: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    loadTemplatesList: () => dispatch(fetchTemplatesList()),
    deleteGuideBrowsingTemplate: (id) =>
      dispatch(deleteGuideBrowsingTemplate({ id })),
  })
)(DeleteTemplateModal);
