import React from "react";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import { Button } from "@hapara/ui/src/atomic/Button/Button";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import styles from "../CloseTabModal.module.scss";
import { StudentBrowserTabType } from "../types";
import { FormattedMessage } from "react-intl";

type CloseForStudentProps = {
  tab: StudentBrowserTabType;
  studentName: string;
  closeReasons: string[];
  dataTestIdPrefix: string;
  onCloseTab: (reason: string) => void;
};

export const CloseForStudent = ({
  tab,
  studentName,
  closeReasons,
  dataTestIdPrefix,
  onCloseTab,
}: CloseForStudentProps) => {
  return (
    <>
      <div className={styles.closeLinkContainer}>
        <Heading level={2} styledAs="h5" removeTopMargin>
          <FormattedMessage
            defaultMessage="Tab to close for {studentName}"
            id="shjevF"
            values={{ studentName }}
          />
        </Heading>
        <div className={styles.linkWrapper}>
          <ExternalLink
            url={tab.url}
            title={tab.title}
            icon={tab.icon}
            className={styles.link}
            dataTestId={`${dataTestIdPrefix}-link`}
          />
        </div>
      </div>
      <Heading level={2} styledAs="h5" id="reason" removeTopMargin>
        <FormattedMessage
          defaultMessage="Select a reason to display on student’s screen"
          id="Watid0"
        />
      </Heading>
      <div className={styles.closeReasonContainer}>
        {closeReasons.map((reason, idx) => (
          <Button
            key={reason}
            label={reason}
            type="secondary"
            onAction={() => onCloseTab(reason)}
            className={styles.closeReasonItem}
            aria-describedby="reason"
            dataTestId={`close-tab-reason-${idx}`}
          />
        ))}
      </div>
    </>
  );
};
