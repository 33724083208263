import { FormattedMessage } from "react-intl";
import React from "react";
import _ from "lodash";
import styles from "./DiscoverMainContent.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DiscoverList from "../DiscoverList/DiscoverList";
import ResourceSearch from "../../Resources/ResourceSearch/ResourceSearch";
import CategoryFiltersToggleButton from "../../Library/CategoryFiltersToggleButton/CategoryFiltersToggleButton";
import CategoryTitle from "../../Library/CategoryTitle/CategoryTitle";
import SearchParamsBlock from "../../Library/SearchParamsBlock/SearchParamsBlock";
import SortFilterBlock from "../../Resources/SortFilterBlock/SortFilterBlock";
import { MAIN_CONTENT_ID } from "../../../../consts";
import { getDiscoverItemsTotal } from "../../../../state/library/discover/selectors";
import { navigateToLanding } from "../../../../state/shared/navigation";
import {
  getSelectedFormatFilter,
  getSearchQuery,
  numberSharedFiltersSelected,
  getJoinedSharedFilterParams,
  getLeftPanelIsOpen,
} from "../../../../state/shared/selectors";
import { toggleLeftSidePanel } from "../../../../state/shared/actions";

export const DiscoverMainContent = ({
  isLeftPanelOpen,
  toggleLeftPanel,
  joinedFilterParams,
  numberFiltersSelected,
  discoverQuery,
  formatFilter,
  searchResultsTotal,
}) => {
  const dataTestIdPrefix = "lb-Discover";

  return (
    <div className={styles.root}>
      <main className={styles.contentMain} id={MAIN_CONTENT_ID} tabIndex="-1">
        <div className={styles.headerContent}>
          <CategoryTitle
            title={
              <h1>
                <FormattedMessage defaultMessage="Discover" id="cE4Hfw" />
              </h1>
            }
            areFiltersSelected={
              numberFiltersSelected > 0 || !_.isEmpty(discoverQuery)
            }
            searchResultsTotal={searchResultsTotal}
            dataTestId={`${dataTestIdPrefix}-Title`}
            clearFiltersAction={navigateToLanding}
          />
          <div className={styles.separator} />
          <div className={styles.filters}>
            <CategoryFiltersToggleButton
              toggleLeftPanel={toggleLeftPanel}
              isLeftPanelOpen={isLeftPanelOpen}
              dataTestIdPrefix={dataTestIdPrefix}
              numberFiltersSelected={numberFiltersSelected}
            />
            <ResourceSearch dataTestIdPrefix={dataTestIdPrefix} />
          </div>
        </div>
        <SortFilterBlock
          dataTestIdPrefix={`${dataTestIdPrefix}-SortFilterBlock`}
          selectedFormat={formatFilter}
        />
        <SearchParamsBlock
          dataTestId={`${dataTestIdPrefix}-SearchParams`}
          areFiltersSelected={
            numberFiltersSelected > 0 || !_.isEmpty(discoverQuery)
          }
          keywordsSearchParams={discoverQuery}
          filtersSearchParams={
            joinedFilterParams ? (
              <>
                <span>
                  <FormattedMessage defaultMessage="Filters:" id="XMzOfe" />
                </span>{" "}
                {joinedFilterParams}
              </>
            ) : null
          }
        />
        <DiscoverList />
      </main>
    </div>
  );
};

DiscoverMainContent.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  joinedFilterParams: PropTypes.string,
  formatFilter: PropTypes.string,
  numberFiltersSelected: PropTypes.number.isRequired,
  discoverQuery: PropTypes.string.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: getLeftPanelIsOpen(state),
    joinedFilterParams: getJoinedSharedFilterParams(state),
    discoverQuery: getSearchQuery(state),
    formatFilter: getSelectedFormatFilter(state),
    searchResultsTotal: getDiscoverItemsTotal(state),
    numberFiltersSelected: numberSharedFiltersSelected(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLeftSidePanel(isOpen)),
  })
)(DiscoverMainContent);
