import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import { ActionDialog } from "../Workspace/MyWorkspaces/ActionDialog/ActionDialog";
import styles from "./EditableDeletableItem.module.scss";

import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";

const getIdOfItem = (item) => _.get(item, "Id", _.get(item, "id", ""));
const getTitleOfItem = (item) =>
  _.get(
    item,
    "Name",
    _.get(item, "name", _.get(item, "Title", _.get(item, "title", "")))
  );

const EditableDeletableItem = ({
  item,
  actionInProgress = false,
  actionError = false,
  actionCompleted = false,
  items = [],
  deleteItem = () => {},
  editItem = () => {},
  itemNaming = "item",
  maxAllowedLength = 0,
  dataTestIdCore = "",
  className,
  actionToGetTotalAmount,
  dischargeActionError = () => {},
}) => {
  const title = getTitleOfItem(item);
  const itemId = getIdOfItem(item);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("Edit");
  const [inputValue, setInputValue] = useState(title);
  const [notAllowedToSave, setNotAllowedToSave] = useState(false);
  const [total, setTotal] = useState(item.wsAmount || null);

  const sameTitleExists = useMemo(
    () =>
      !!_.find(items, (item) => {
        const id = getIdOfItem(item);
        const title = getTitleOfItem(item);
        return (
          id !== itemId &&
          title.toLowerCase() === inputValue.toLowerCase() &&
          !item.deleted
        );
      }),
    [items, inputValue, itemId]
  );

  const getTotal = () => {
    if (total === null) {
      actionToGetTotalAmount()
        .then((response) => {
          if (response.status !== 200) {
            throw new Error();
          }

          setTotal(_.get(response, "data.TotalHits"));
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    if (actionCompleted && !actionError) {
      setDialogOpen(false);
    }
  }, [actionCompleted, actionError, setDialogOpen]);

  useEffect(() => {
    setNotAllowedToSave(!inputValue || inputValue === title || sameTitleExists);
  }, [inputValue, sameTitleExists, title, setNotAllowedToSave]);

  const handleInputValueChange = (e) => {
    setInputValue(_.get(e, "target.value", ""));
  };

  const actionsConfig = {
    Delete: {
      title: `Delete ${itemNaming}?`,
      description: (
        <React.Fragment>
          <p>
            When you delete this {itemNaming}, it will be removed from the
            Workspaces ( <b>{total === null ? "..." : total}</b> ) you've
            attached it to.
          </p>
        </React.Fragment>
      ),
      actionLabel: `Delete ${itemNaming}`,
      actionInProgressLabel: "Deleting",
      errorMessage: `Sorry, there was a problem deleting this ${itemNaming}. Please try again.`,
      enableCancelInProgress: false,
      cancelDataTestId: `${dataTestIdCore}ConfirmDialog-Button-CancelDelete`,
      actionDataTestId: `${dataTestIdCore}ConfirmDialog-Button-ConfirmDelete`,
      isActionDanger: true,
      displaySubtitle: true,
    },
    Edit: {
      title: `Edit ${itemNaming}`,
      actionLabel: `Save ${itemNaming}`,
      actionInProgressLabel: "Saving",
      errorMessage: `Sorry, there was a problem updating this ${itemNaming}. Please try again.`,
      enableCancelInProgress: false,
      cancelDataTestId: `${dataTestIdCore}ConfirmDialog-Button-CancelEdit`,
      actionDataTestId: `${dataTestIdCore}ConfirmDialog-Button-ConfirmEdit`,
      children: [
        <React.Fragment key={`edit ${itemNaming} input ${title}`}>
          <input
            type="text"
            className={styles.field}
            value={inputValue}
            onChange={handleInputValueChange}
            maxLength={maxAllowedLength}
            aria-label={title}
            aria-labelledby="warning-limit-reached error-duplicate-error"
            data-test-id={`${dataTestIdCore}-EditInput`}
          />
          {!sameTitleExists && inputValue.length === maxAllowedLength && (
            <div
              className={styles.editDialogAppendix}
              aria-live="polite"
              id="warning-limit-reached"
              data-test-id={`${dataTestIdCore}-LimitReachedMessage`}
            >
              {maxAllowedLength} characters limit reached
            </div>
          )}
          {sameTitleExists && (
            <div
              className={styles.sameTitleExists}
              aria-live="polite"
              id="error-duplicate-error"
            >
              <FormattedMessage
                defaultMessage="Error: This name already exists"
                id="tOSgd3"
              />
            </div>
          )}
        </React.Fragment>,
      ],
      disabelEnableButton: true,
    },
  };

  const actions = {
    Edit: () => editItem(inputValue),
    Delete: deleteItem,
  };

  return (
    <div className={classnames(styles.root, styles.editMode, className)}>
      <ActionDialog
        title={actionsConfig[currentAction].displaySubtitle ? title : null}
        actionInProgress={actionInProgress}
        action={() => actions[currentAction](getIdOfItem(item), inputValue)}
        actionConfig={actionsConfig[currentAction]}
        isOpen={dialogOpen}
        close={() => {
          setInputValue(title);
          setDialogOpen(false);
          dischargeActionError();
        }}
        error={actionError}
        isActionButtonDisabled={
          actionsConfig[currentAction].disabelEnableButton
            ? notAllowedToSave
            : null
        }
        key={getIdOfItem(item)}
        dataTestPrefix={`${dataTestIdCore}-${currentAction}`}
      >
        {actionsConfig[currentAction].children
          ? actionsConfig[currentAction].children
          : null}
      </ActionDialog>
      <div className={styles.name}>
        <div className={styles.title}>{title}</div>
        <div className={styles.buttonsContainer}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            icon="pencil"
            onAction={() => {
              setCurrentAction("Edit");
              setDialogOpen(true);
            }}
            dataTestId={`${dataTestIdCore}-Button-Edit`}
            aria-label={`Edit ${itemNaming} ${title}`}
            className={styles.updateButton}
          />
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            icon="delete"
            onAction={() => {
              setCurrentAction("Delete");
              getTotal();
              setDialogOpen(true);
            }}
            dataTestId={`${dataTestIdCore}-Button-Delete`}
            aria-label={`Delete ${itemNaming} ${title}`}
            className={styles.updateButton}
          />
        </div>
      </div>
    </div>
  );
};

EditableDeletableItem.propTypes = {
  item: PropTypes.object, // TODO ???
  inEditMode: PropTypes.bool,
  actionInProgress: PropTypes.bool,
  actionError: PropTypes.bool,
  actionCompleted: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  itemNaming: PropTypes.string,
  maxAllowedLength: PropTypes.number,
  dataTestIdCore: PropTypes.string,
  deleteItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  dischargeActionError: PropTypes.func.isRequired,
};

export default EditableDeletableItem;
