import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./GuideBrowsingTemplatesPanel.module.scss";
import { fetchTemplatesList } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import { hideTemplatesPanel } from "../../../../../../state/highlights/guideBrowsingTemplates/actions";
import GuideBrowsingPanelFilterFocusOptions from "../GuideBrowsingPanelFilterFocusOptions/GuideBrowsingPanelFilterFocusOptions";
import GuideBrowsingTemplatesList from "../GuideBrowsingTemplatesList/GuideBrowsingTemplatesList";
import DeleteTemplateModal from "./DeleteTemplateModal";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import { FormattedMessage, useIntl } from "react-intl";

const dataTestPrefix = "hl-GuideBrowsingDrawer";

const GuideBrowsingTemplatesPanel = ({
  loadTemplatesList,
  hideTemplatesPanel,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  const deleteTemplateAction = (selectedTemplate) => {
    setTemplateToDelete(selectedTemplate);
  };

  useEffect(() => {
    loadTemplatesList().then((isLoaded) => {
      if (!isLoaded) {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [loadTemplatesList]);

  const ModalTitle = () => {
    return (
      <>
        <HapReactIcon
          svg="fill-focused-browsing"
          width="20"
          height="20"
          className={styles.icon}
        />
        <FormattedMessage defaultMessage="Guide Browsing" id="Hu0KOp" />
      </>
    );
  };

  return (
    <>
      <ModalDeprecated
        isOpen={true}
        onClose={hideTemplatesPanel}
        className={styles.modal}
        classNameHeader={styles.modalHeader}
        dataTestPrefix={dataTestPrefix}
        title={<ModalTitle />}
        contentLabel={intl.formatMessage({
          defaultMessage: "Guide Browsing Templates",
          id: "dUzKfW",
        })}
      >
        <GuideBrowsingPanelFilterFocusOptions dataTestPrefix={dataTestPrefix} />
        <GuideBrowsingTemplatesList
          isLoading={isLoading}
          isError={isError}
          dataTestPrefix={dataTestPrefix}
          deleteTemplateAction={deleteTemplateAction}
        />
      </ModalDeprecated>

      {templateToDelete && (
        <DeleteTemplateModal
          dataTestPrefix={dataTestPrefix}
          templateToDelete={templateToDelete}
          onClose={() => {
            setTemplateToDelete(null);
          }}
        />
      )}
    </>
  );
};

GuideBrowsingTemplatesPanel.propTypes = {
  loadTemplatesList: PropTypes.func.isRequired,
  hideTemplatesPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    loadTemplatesList: () => dispatch(fetchTemplatesList()),
    hideTemplatesPanel: () => dispatch(hideTemplatesPanel()),
  })
)(GuideBrowsingTemplatesPanel);
