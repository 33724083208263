import _ from "lodash";
import { createSelector } from "reselect";
import { formatForPendo } from "@hapara/ui/src/components/utils";
import { UPREF_NAME_FIRST_LAST, USER_PERMISSIONS } from "./types";
import { getFFByName } from "../app/selectors";

const getUser = (state) => _.get(state, "user");

export const getUserLoggingStatus = createSelector(getUser, (user) => {
  const isSignedIn = _.get(user, "isSignedIn", false);

  const isAutomatedTests =
    window.localStorage.getItem("login-system") === "test";
  const testToken = isAutomatedTests
    ? window.localStorage.getItem("token")
    : null;

  return isSignedIn || !!testToken;
});

export const getGISInfo = createSelector(getUser, (user) =>
  _.get(user, "GISInfo")
);

const getUserMetadata = createSelector(getUser, (user) =>
  _.get(user, "userMetadata", {})
);

export const getUserPermissions = createSelector(getUser, (user) =>
  _.get(user, "userPermissions", [])
);

export const hasAccess = createSelector(getUser, (user) =>
  _.get(user, "hasAccess", [])
);

export const isUnknownUser = createSelector(getUser, (user) =>
  _.get(user, "isUnknownUser", [])
);

export const hasWSAccess = createSelector(
  getUserPermissions,
  (userPermissions) => _.includes(userPermissions, USER_PERMISSIONS.WORKSPACE)
);

export const hasPrivateLibraryAccess = createSelector(
  getUserPermissions,
  (userPermissions) =>
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_WORKSPACE)
);

export const hasResourcesAccess = createSelector(
  getUserPermissions,
  (userPermissions) =>
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_RESOURCES)
);

export const isHaparaStudent = createSelector(getUser, (user) => {
  return _.get(user, "isStudent", false);
});

export const isSharingViewEnabled = createSelector(getUser, (user) => {
  return _.get(user, "isSharingViewEnabled", true);
});

export const getUserPrefs = createSelector(getUser, (user) =>
  _.get(user, "userPrefs", [])
);

export const getUserNameFormat = createSelector(getUserPrefs, (userPrefs) => {
  const nameFormatPref = _.find(userPrefs, { key: "NameFormat" });
  return nameFormatPref ? nameFormatPref.value : UPREF_NAME_FIRST_LAST;
});

export const getUserConfigurableSchools = createSelector(getUser, (user) =>
  _.get(user, "schoolsCanConfigure", [])
);

export const getUserAvatarUrl = createSelector(getUser, (user) =>
  _.get(user, "GISInfo.imageUrl")
);

export const getUserEmail = createSelector(getUser, (user) =>
  _.get(user, "GISInfo.email")
);

export const pendoIdentitySelector = createSelector(
  [getGISInfo, getUserMetadata],
  (GISInfo, userMetadata) => {
    if (_.isEmpty(GISInfo) || _.isEmpty(userMetadata)) {
      return null;
    }

    const { id, email } = GISInfo;
    return formatForPendo(id, email, userMetadata);
  }
);

export const getUserFamilyName = createSelector(
  [getGISInfo, getUserMetadata],
  (GISInfo, userMetadata) => {
    if (!_.isEmpty(userMetadata)) {
      return _.get(userMetadata, "LastName");
    }
    if (_.isEmpty(GISInfo)) {
      return _.get(GISInfo, "LastName");
    }
    return null;
  }
);

export const getUserFullName = createSelector(
  [getGISInfo, getUserMetadata],
  (GISInfo, userMetadata) => {
    if (!_.isEmpty(userMetadata)) {
      return `${_.get(userMetadata, "FirstName")} ${_.get(
        userMetadata,
        "LastName"
      )}`;
    }
    if (!_.isEmpty(GISInfo)) {
      return `${_.get(GISInfo, "FirstName")} ${_.get(
        userMetadata,
        "LastName"
      )}`;
    }
    return null;
  }
);

export const getUserEmailFormatPreference = createSelector(getUser, (user) => {
  return _.get(user, "emailFormatPreference");
});
