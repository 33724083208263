import { useContext } from "react";
import { FeatureFlagContext } from "./FeatureFlagProvider";

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error(
      "useFeatureFlag hook must be used within a FeatureFlagProvider"
    );
  }

  return context;
};
