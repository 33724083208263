import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import styles from "./RecentShares.module.scss";
import _ from "lodash";
import ShareTile from "../ShareTile/ShareTile";
import { smartShareItemType } from "../types";

const RecentShares = ({
  onOpenRecentShareDialog,
  monitorShareUnshare = () => {},
  items,
}) => {
  return (
    <div
      className={styles.root}
      data-test-id="td-SmartShare-Dropdown-RecentShares"
    >
      <div className={styles.shareListTitle}>
        <FormattedMessage defaultMessage="Recently shared" id="obv2/Q" />
      </div>
      <div className={styles.shareListBody}>
        {_.map(items, (item) => (
          <ShareTile
            key={item.id}
            data={item}
            onOpenRecentShareDialog={onOpenRecentShareDialog}
            monitorShareUnshare={monitorShareUnshare}
          />
        ))}
        {items.length === 0 && (
          <div className={styles.empty}>
            <FormattedMessage
              defaultMessage="Share files quickly and easily with students! We'll store recently shared files here."
              id="UOjBQM"
            />
          </div>
        )}
      </div>
    </div>
  );
};

RecentShares.propTypes = {
  onOpenRecentShareDialog: PropTypes.func.isRequired,
  monitorShareUnshare: PropTypes.func,
  items: PropTypes.arrayOf(smartShareItemType).isRequired,
};

export default RecentShares;
