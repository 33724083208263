import { getToken } from "./jwtHandler";

export const getCurrentAuthHeader = async () => {
  if (!window.google) {
    return "Bearer xxxxxxxxx";
  }

  const jwtToken = await getToken();
  return `Bearer ${jwtToken}`;
};
