import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "@hapara/ui/src/atomic/Alert";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import styles from "../CloseTabModal.module.scss";
import { StudentBrowserTabType } from "../types";
import { useIntl } from "react-intl";
import { DurationInput } from "../../Highlights/DurationInput";
import { getFFByName } from "../../../../state/app/selectors";

type BlockForStudentProps = {
  tab: StudentBrowserTabType;
  duration: number | null;
  dataTestIdPrefix: string;
  activeClassFilterSession: boolean;
  activeIndividualSession?: boolean;
  activeGroupSession?: boolean;
  onDurationChange: (value: number) => void;
};

export const BlockForStudent = ({
  tab,
  duration,
  activeClassFilterSession,
  activeIndividualSession,
  activeGroupSession,
  onDurationChange,
  dataTestIdPrefix,
}: BlockForStudentProps) => {
  const intl = useIntl();
  const hasExtendedOptions = useSelector(
    getFFByName("GBE-03-extended-duration-options")
  );
  return (
    <>
      <Heading level={2} styledAs="h5" removeTopMargin>
        {intl.formatMessage({ defaultMessage: "Site to block", id: "QUSVfb" })}
      </Heading>
      <div className={styles.linkWrapper}>
        <ExternalLink
          url={tab.url}
          title={tab.title}
          icon={tab.icon}
          className={styles.link}
          dataTestId={`${dataTestIdPrefix}-link`}
        />
      </div>
      {!activeIndividualSession && (
        <div className={styles.durationContainer}>
          <Heading level={2} styledAs="h5" removeTopMargin>
            {intl.formatMessage({
              defaultMessage: "How long to block access?",
              id: "3ZGCtI",
            })}
          </Heading>
          <DurationInput
            hasExtendedOptions={hasExtendedOptions}
            duration={duration}
            onDurationChange={onDurationChange}
            dataTestPrefix={dataTestIdPrefix}
          />
        </div>
      )}
      {(activeClassFilterSession || activeGroupSession) &&
        !activeIndividualSession && (
          <Alert type="warning">
            {intl.formatMessage({
              defaultMessage:
                "Proceeding will override active Guided Sessions that were started for the student.",
              id: "mzt/0I",
            })}
          </Alert>
        )}
      {activeIndividualSession && (
        <Alert type="info">
          {intl.formatMessage({
            defaultMessage:
              "This site will be added to a Filter Session that is already active for this student.",
            id: "ojQGLc",
          })}
        </Alert>
      )}
    </>
  );
};
