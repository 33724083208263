import React from "react";
import styles from "./ResourceTile.module.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import _ from "lodash";
import {
  resourceItemType,
  RESOURCE_FORMAT,
} from "../../../../state/resources/types";
import { showResourceDetailsModal } from "../../../../state/resources/actions";
import { getWindowSize } from "../../../../state/app/selectors";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import { updateURLParams } from "../../../../state/shared/navigation";
import ResourceTileBody from "./ResourceTileBody";
import ResourceTileDetails from "./ResourceTileDetails";
import ResourceTileToolbar from "./ResourceTileToolbar";

const ResourceTile = ({
  item,
  dataTestIdPrefix,
  dataTestIdPrefixWithResourceTile,
  showResourceDetailsModal,
  windowSize,
}) => {
  const itemId = _.get(item, "ID", null);
  const itemTitle = _.get(item, "Title", null);
  const itemDescription = _.get(item, "Description", null);
  const itemType = _.get(item, "Type", null);
  const itemAuthor = _.get(item, "Author", null);
  const itemGrades = _.get(item, "Grades", []) || [];
  const itemSubjects = _.get(item, "Subjects", []) || [];
  const itemResourceTypes = _.get(item, "ResourceTypes", []) || [];
  const itemThumbnail = _.get(item, "Thumbnail", null);
  const itemDateModified = _.get(item, "EditedOn", null);
  const isItemInLibrary = _.get(item, "Statuses.IsAddedToLibrary", false);
  const isItemStarred = _.get(item, "Statuses.IsStarred", false);
  const isItemUploaded = _.get(item, "Statuses.IsUploaded", false);
  const isCopiedToLibrary = _.get(item, "Statuses.IsCopiedToLibrary", false);
  const isShared = _.get(item, "Statuses.IsShared", false);
  return (
    <article
      className={styles.root}
      onClick={() => {
        updateURLParams({ [SHARED_URL_KEYS.selectedResourceId.key]: itemId });
      }}
      data-test-id={`${dataTestIdPrefixWithResourceTile}-Button-OpenDetailsModal`}
    >
      <div className={styles.container}>
        <div className={styles.body}>
          <ResourceTileBody
            id={itemId}
            title={itemTitle}
            type={itemType}
            description={itemDescription}
            thumbnail={itemThumbnail}
            resourceTypes={itemResourceTypes}
          />
        </div>
        {windowSize >= WINDOW_SIZE_TYPES.L && (
          <>
            <div className={styles.detailsBorder} />
            <div className={styles.details}>
              <ResourceTileDetails
                type={itemType}
                grades={itemGrades}
                subjects={itemSubjects}
                author={itemAuthor}
                dateModified={itemDateModified}
                isItemUploaded={isItemUploaded}
              />
            </div>
          </>
        )}
        <div className={styles.toolbar}>
          <ResourceTileToolbar
            item={item}
            id={itemId}
            type={itemType}
            isItemInLibrary={isItemInLibrary}
            isItemStarred={isItemStarred}
            isItemUploaded={isItemUploaded}
            isCopiedToLibrary={isCopiedToLibrary}
            isShared={isShared}
            dataTestIdPrefix={dataTestIdPrefix}
            dataTestIdPrefixWithResourceTile={dataTestIdPrefixWithResourceTile}
          />
        </div>
      </div>
      <div
        className={classnames(styles.colourBorder, {
          [styles.purple]: itemType === RESOURCE_FORMAT.RESOURCE,
          [styles.blue]: itemType === RESOURCE_FORMAT.WORKSPACE,
        })}
      />
    </article>
  );
};

ResourceTile.propTypes = {
  item: resourceItemType,
  dataTestIdPrefix: PropTypes.string,
  dataTestIdPrefixWithResourceTile: PropTypes.string.isRequired,
  // connected
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
  showResourceDetailsModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({
    showResourceDetailsModal: ({ item, mode }) =>
      dispatch(showResourceDetailsModal({ item, mode })),
  })
)(ResourceTile);
