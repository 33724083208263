import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

import { applyMyResourcesSearchParams } from "../../../../state/library/myResources/actions";
import {
  getMyResourcesIsLoading,
  areMyResourcesResultsNull,
  isStarredFiltered,
  getMyResourcesSelectedItem,
} from "../../../../state/library/myResources/selectors";
import { showResourceDetailsModal } from "../../../../state/resources/actions";
import {
  getCurrentPage,
  getSearchQuery,
  getSelectedFilters,
  getClassId,
  getSelectedSortOrderFilter,
  getSelectedResourceId,
  areMyClassesLoaded,
} from "../../../../state/shared/selectors";
import {
  resourceItemType,
  RESOURCE_DETAILS_MODAL_MODE,
} from "../../../../state/resources/types";
import { SHARED_FILTER_TYPES } from "../../../../state/shared/types";

export const LocationChangeHandler = ({
  areMyResourcesResultsNull,
  isMyResourcesIsLoading,
  isClassListLoaded,
  searchParamPage,
  searchParamQuery,
  searchParamGrades,
  searchParamSubjects,
  searchParamStandards,
  searchParamResourceTypes,
  searchParamLicences,
  searchParamClass,
  searchStarred,
  searchParamSortOrder,
  applyMyResourcesSearchParams,
  activeResourceId,
  showResourceDetailsModal,
  selectedItem,
}) => {
  const dataFetchParamsRef = useRef();

  // listen to search params change and load new data if necessary
  useEffect(() => {
    // don't do anything before all necessary data is loaded
    if (isClassListLoaded) {
      const newDataFetchParams = {
        searchParamClass,
        searchParamQuery,
        searchParamGrades,
        searchParamSubjects,
        searchParamStandards,
        searchParamResourceTypes,
        searchParamLicences,
        searchParamPage,
        searchParamSortOrder,
        searchStarred,
      };

      // load should happen if it is a first load
      let shouldLoadMyResources =
        areMyResourcesResultsNull && !isMyResourcesIsLoading;

      // check all the params which should cause re-render;
      // load should happen if any given param was changed since last change
      _.forEach(newDataFetchParams, (value, key) => {
        const shouldDoTheLoad = !_.isEqual(
          _.get(dataFetchParamsRef.current, key),
          value
        );
        if (shouldDoTheLoad) {
          shouldLoadMyResources = true;
        }
      });

      // update the state and do the load
      if (shouldLoadMyResources) {
        applyMyResourcesSearchParams({
          classId: searchParamClass,
          query: searchParamQuery,
          grades: searchParamGrades,
          subjects: searchParamSubjects,
          standards: searchParamStandards,
          resourceTypes: searchParamResourceTypes,
          licences: searchParamLicences,
          page: searchParamPage,
          sortOrder: searchParamSortOrder,
          starred: searchStarred,
        });
      }

      // keep previous params values for the reference
      // so we can compare with the current ones
      dataFetchParamsRef.current = { ...newDataFetchParams };
    }
  }, [
    applyMyResourcesSearchParams,
    areMyResourcesResultsNull,
    isMyResourcesIsLoading,
    searchParamPage,
    searchParamQuery,
    searchParamGrades,
    searchParamSubjects,
    searchParamStandards,
    searchParamResourceTypes,
    searchParamLicences,
    searchParamClass,
    searchParamSortOrder,
    searchStarred,
    isClassListLoaded,
  ]);

  const activeItemIdRef = useRef();
  // listen to rId params change and open the modal window
  useEffect(() => {
    const currentActiveItemId = activeResourceId;
    const prevActiveItemId = activeItemIdRef.current;

    // do not do anything until search results are loaded
    if (!areMyResourcesResultsNull && !isMyResourcesIsLoading) {
      // open or reload the modal if
      // - activeResourceId param is present
      // - and ether it was not present before
      // - or it has changed
      if (
        currentActiveItemId &&
        (!prevActiveItemId || prevActiveItemId !== currentActiveItemId)
      ) {
        if (!_.isEmpty(selectedItem)) {
          showResourceDetailsModal({ item: selectedItem });
        }
      }

      // keep prev state of modal
      // so we can compare with the current one
      activeItemIdRef.current = currentActiveItemId;
    }
  }, [
    activeResourceId,
    areMyResourcesResultsNull,
    isMyResourcesIsLoading,
    showResourceDetailsModal,
    selectedItem,
  ]);

  return null;
};

LocationChangeHandler.propTypes = {
  areMyResourcesResultsNull: PropTypes.bool.isRequired,
  isMyResourcesIsLoading: PropTypes.bool.isRequired,
  searchParamPage: PropTypes.number.isRequired,
  searchParamQuery: PropTypes.string,
  activeResourceId: PropTypes.string,
  searchParamGrades: PropTypes.arrayOf(PropTypes.string),
  searchParamSubjects: PropTypes.arrayOf(PropTypes.string),
  searchParamStandards: PropTypes.arrayOf(PropTypes.string),
  searchParamResourceTypes: PropTypes.arrayOf(PropTypes.string),
  searchParamLicences: PropTypes.arrayOf(PropTypes.string),
  searchParamClass: PropTypes.string,
  searchParamSortOrder: PropTypes.string,
  searchStarred: PropTypes.bool.isRequired,
  applyMyResourcesSearchParams: PropTypes.func.isRequired,
  isClassListLoaded: PropTypes.bool.isRequired,
  showResourceDetailsModal: PropTypes.func.isRequired,
  selectedItem: resourceItemType,
};

export default connect(
  (state) => ({
    areMyResourcesResultsNull: areMyResourcesResultsNull(state),
    isMyResourcesIsLoading: getMyResourcesIsLoading(state),
    searchParamPage: getCurrentPage(state),
    searchParamQuery: getSearchQuery(state),
    activeResourceId: getSelectedResourceId(state),
    selectedItem: getMyResourcesSelectedItem(state),
    searchParamGrades: getSelectedFilters(SHARED_FILTER_TYPES.GRADES.value)(
      state
    ),
    searchParamSubjects: getSelectedFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(
      state
    ),
    searchParamStandards: getSelectedFilters(
      SHARED_FILTER_TYPES.STANDARDS.value
    )(state),
    searchParamResourceTypes: getSelectedFilters(
      SHARED_FILTER_TYPES.RESOURCE_TYPE.value
    )(state),
    searchParamLicences: getSelectedFilters(SHARED_FILTER_TYPES.LICENCES.value)(
      state
    ),
    searchStarred: isStarredFiltered(state),
    searchParamClass: getClassId(state),
    searchParamSortOrder: getSelectedSortOrderFilter(state),
    isClassListLoaded: areMyClassesLoaded(state),
  }),
  (dispatch) => ({
    applyMyResourcesSearchParams: (options) =>
      dispatch(applyMyResourcesSearchParams(options)),
    showResourceDetailsModal: ({ item }) =>
      dispatch(
        showResourceDetailsModal({
          item,
          mode: RESOURCE_DETAILS_MODAL_MODE.READ,
        })
      ),
  })
)(LocationChangeHandler);
