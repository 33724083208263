import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import styles from "./GradingMultipleSelectTable.module.scss";
import PropTypes from "prop-types";
import { MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE } from "../../../../../state/workspace/grading/types";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";

export const TABLE_TYPES = {
  RETURN: "return",
  SUBMIT: "submit",
};

export const GradingMultipleSelectTable = ({
  type,
  assignees,
  selectedList,
  setSelectedList,
  isAssigneeGroup,
  isInitInProgress,
  isActionInProgress,
  tableTitleId,
  dataTestIdPrefix,
}) => {
  const [isAllSelectedIndeterminate, setIsAllSelectedIndeterminate] =
    useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // handle 'select all' checkbox state
  useEffect(() => {
    const amountOfSelected = selectedList.length;
    const amountOfAll = assignees.length;
    const isIndeterminate =
      amountOfSelected >= 1 && amountOfSelected < amountOfAll;

    setIsAllSelected(amountOfSelected === amountOfAll);
    setIsAllSelectedIndeterminate(isIndeterminate);
  }, [
    assignees,
    selectedList,
    setIsAllSelectedIndeterminate,
    setIsAllSelected,
  ]);

  const isAssigneeSelected = (id) => _.indexOf(selectedList, id) !== -1;

  const handleAssigneeSelect = (isChecked, id) => {
    if (isChecked) {
      setSelectedList(_.union(selectedList, [id]));
    } else {
      setSelectedList(_.without(selectedList, id));
    }
  };

  const handleAllSelect = (isChecked) => {
    if (isChecked) {
      setSelectedList(_.map(assignees, (item) => item.ArtifactID));
    } else {
      setSelectedList([]);
    }
  };

  const getAllSelectedLabel = () => {
    if (isAllSelected) {
      return "All selected";
    }
    if (selectedList.length > 0) {
      return `${selectedList.length} Selected`;
    }
    return `${isAssigneeGroup ? "Group" : "Individual"} evidence`;
  };

  return (
    <table
      className={styles.root}
      aria-labelledby={tableTitleId}
      data-test-id={`${dataTestIdPrefix}-Table`}
    >
      <thead className={styles.tableHead}>
        <tr className={styles.tableHeadRow}>
          <th
            scope="col"
            className={classnames(styles.tableHeadCol, styles.assignee)}
          >
            {isInitInProgress && <div className={styles.loadingHeader} />}
            {!isInitInProgress && (
              <Checkbox
                checked={isAllSelected}
                semi={isAllSelectedIndeterminate}
                onChange={(isChecked) => handleAllSelect(isChecked)}
                label={getAllSelectedLabel()}
                dataTestIdPrefix={`${dataTestIdPrefix}-Checkbox-Multiselect`}
                className={styles.allSelectedCheckbox}
                disabled={isActionInProgress}
                themeType={THEME_TYPES.DARK}
              />
            )}
          </th>
          {type === TABLE_TYPES.RETURN && (
            <>
              <th
                scope="col"
                className={classnames(styles.tableHeadCol, styles.grade)}
              >
                {!isInitInProgress && "Draft grade"}
              </th>
              <th
                scope="col"
                className={classnames(styles.tableHeadCol, styles.feedback)}
              >
                {!isInitInProgress && "Draft feedback"}
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {_.map(_.orderBy(assignees, "formattedName"), (assignee) => {
          const isSelected = isAssigneeSelected(assignee.ArtifactID);
          return (
            <tr
              key={assignee.ArtifactID}
              className={classnames(styles.tableBodyRow, styles.assignee, {
                [styles.tableBodyRowSelected]: isSelected,
              })}
            >
              <th
                scope="row"
                className={classnames(styles.tableBodyCol, styles.assignee)}
              >
                {isInitInProgress && <div className={styles.loadingBody} />}
                {!isInitInProgress && (
                  <Checkbox
                    checked={isSelected}
                    onChange={(isChecked) =>
                      handleAssigneeSelect(isChecked, assignee.ArtifactID)
                    }
                    label={assignee.formattedName}
                    dataTestIdPrefix={`${dataTestIdPrefix}-Checkbox-Assignee`}
                    className={styles.assigneeCheckbox}
                    disabled={isActionInProgress}
                  />
                )}
              </th>
              {type === TABLE_TYPES.RETURN && (
                <>
                  <td className={classnames(styles.tableBodyCol, styles.grade)}>
                    {(isInitInProgress || !assignee.Grade) && (
                      <span>&nbsp;</span>
                    )}
                    {!isInitInProgress && !!assignee.Grade && (
                      <span>{assignee.Grade}</span>
                    )}
                  </td>
                  <td
                    className={classnames(styles.tableBodyCol, styles.feedback)}
                  >
                    {(isInitInProgress || !assignee.Feedback) && (
                      <span>&nbsp;</span>
                    )}
                    {!isInitInProgress && !!assignee.Feedback && (
                      <span>{assignee.Feedback}</span>
                    )}
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

GradingMultipleSelectTable.propTypes = {
  type: PropTypes.oneOf(_.values(TABLE_TYPES)),
  assignees: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE.isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAssigneeGroup: PropTypes.bool.isRequired,
  setSelectedList: PropTypes.func.isRequired,
  isInitInProgress: PropTypes.bool.isRequired,
  isActionInProgress: PropTypes.bool.isRequired,
  tableTitleId: PropTypes.string.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
};

export default GradingMultipleSelectTable;
