import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ClassInfoTableHeader } from "./ClassInfoTableHeader";
import { ClassInfoTableBody } from "./ClassInfoTableBody";
import { Person } from "../types";
import { ClassInfoPermissionsData } from "../../../../apiCalls/classInfo/classInfoTypes";
import { Table, TableContainer } from "@mui/material";
import styles from "./ClassInfoTable.module.scss";

import { getFFByName } from "../../../../state/app/selectors";

export type ClassInfoTableProps = {
  people?: Person[];
  selectedPeople: Person[];
  dataTestIdPrefix: String;
  variant: "student" | "teacher";
  permissions?: ClassInfoPermissionsData;
  onAdd: () => void;
  onReset: (selectedPeople: Person[]) => void;
  onRemove: (selectedPeople: Person[]) => void;
  setSelectedPeople: (selectedPeople: Person[]) => void;
};

export const ClassInfoTable: React.FC<ClassInfoTableProps> = ({
  people,
  variant,
  permissions,
  dataTestIdPrefix,
  selectedPeople,
  onAdd,
  onReset,
  onRemove,
  setSelectedPeople,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [searchText, setSearchText] = useState("");
  const fullDataTestIdPrefix = `${
    variant === "student" ? "Students" : "Teachers"
  }-${dataTestIdPrefix}-table`;

  const filteredAndSortedData = useMemo(() => {
    let dataCopy = people ? [...people] : [];
    if (variant === "student") {
      dataCopy = dataCopy.filter(
        (student) =>
          student.name.toLowerCase().includes(searchText.toLowerCase()) ||
          student.email.toLowerCase().includes(searchText.toLowerCase())
      );

      dataCopy.sort((a, b) => {
        if (sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    }

    return dataCopy;
  }, [people, variant, searchText, sortOrder]);

  const handleSortStudents = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
  };

  const hasNYCSpecificUIFlag = useSelector(
    getFFByName("HAP-11866-NYC-Specific-UI")
  );

  return (
    <TableContainer
      className={styles.tableContainer}
      data-test-id={fullDataTestIdPrefix}
    >
      <Table className={styles.tableRoot}>
        <caption className={styles.hidden}>{variant}</caption>
        <ClassInfoTableHeader
          people={people || []}
          variant={variant}
          sortOrder={sortOrder}
          searchText={searchText}
          selectedPeople={selectedPeople}
          dataTestIdPrefix={fullDataTestIdPrefix}
          permissions={permissions}
          onAdd={onAdd}
          onReset={onReset}
          onRemove={onRemove}
          onSortStudents={handleSortStudents}
          onSelectedPeopleChange={setSelectedPeople}
          onSearchTextChange={handleSearchTextChange}
          hasNYCSpecificUIFlag={hasNYCSpecificUIFlag}
        />
        <ClassInfoTableBody
          variant={variant}
          people={filteredAndSortedData}
          onAdd={onAdd}
          searchText={searchText}
          selectedPeople={selectedPeople}
          dataTestIdPrefix={fullDataTestIdPrefix}
          permissions={permissions}
          onSelectedPeopleChange={setSelectedPeople}
          hasNYCSpecificUIFlag={hasNYCSpecificUIFlag}
        />
      </Table>
    </TableContainer>
  );
};
