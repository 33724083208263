import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./AssignClassesModal.module.scss";
import {
  getIsResourceAssignClassModalOpen,
  getResourceAssignClassModalData,
} from "../../../../state/resources/selectors";
import { classItemType } from "../../../../state/library/config/types";
import { getClassListSortedByName } from "../../../../state/library/config/selectors";
import {
  updateResourceAssignedClasses,
  fetchClassesAssignedToDoc,
  hideResourceAssignClassModal,
} from "../../../../state/resources/actions";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import LinearProgress from "@mui/material/LinearProgress";
import {
  showSuccessToast,
  hideSuccessToast,
} from "../../../../state/app/actions";
import AssignClassesMultiSelectList from "../AssignClassesMultiSelectList/AssignClassesMultiSelectList";
import ActionDialog from "@hapara/ui/src/atomic/ActionDialog/ActionDialog";
import { isMyClassesLoadingError } from "../../../../state/shared/selectors";
import { getMyClassesAction } from "../../../../state/shared/actions";

const AssignClassesModal = ({
  isModalOpen = false,
  onModalClose,
  classList,
  loadClassAssignedList,
  modalData,
  saveAssignedClasses,
  showSuccessMessage,
  hideSuccessMessage,
  isMyClassesLoadingError,
  getMyClassesAction,
}) => {
  const { resourceId } = modalData;
  const dataTestPrefix = "lb-Resource-AssignToClasses";
  const [isLoading, setIsLoading] = useState(true);
  const [isClassListLoadError, setIsClassListLoadError] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [wasSelectedClasses, setWasSelectedClasses] = useState([]);
  const loadClassesRef = useRef(false);

  useEffect(() => {
    if (!loadClassesRef.current && isMyClassesLoadingError) {
      getMyClassesAction();
      loadClassesRef.current = true;
    }
  }, [getMyClassesAction, isMyClassesLoadingError]);

  const isSaveDisabled = useMemo(
    () =>
      wasSelectedClasses.length === selectedClasses.length &&
      _.difference(wasSelectedClasses, selectedClasses).length === 0,
    [wasSelectedClasses, selectedClasses]
  );

  useEffect(() => {
    hideSuccessMessage();
    if (isMyClassesLoadingError) {
      setIsClassListLoadError(true);
      setIsLoading(false);
    } else {
      // class list loaded, fetch assigned class list for a resource now
      if (classList.length === 0) {
        setIsLoading(false);
      } else {
        loadClassAssignedList({ resourceId })
          .then((resp) => {
            const selectedClasses = _.intersection(
              resp,
              _.map(classList, (c) => c.ID)
            );
            setSelectedClasses(selectedClasses);
            setWasSelectedClasses(selectedClasses);
          })
          .catch(() => {
            setIsClassListLoadError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [
    classList,
    hideSuccessMessage,
    isMyClassesLoadingError,
    loadClassAssignedList,
    resourceId,
  ]);

  const handleSubmit = (e) => {
    const addClasses = _.difference(selectedClasses, wasSelectedClasses);
    const removeClasses = _.difference(wasSelectedClasses, selectedClasses);

    if (!_.isEmpty(addClasses) || !_.isEmpty(removeClasses)) {
      return saveAssignedClasses({
        resourceId,
        addClasses,
        removeClasses,
      }).then(() => showSuccessMessage());
    } else {
      showSuccessMessage();
    }
  };

  return (
    <ActionDialog
      isOpen={isModalOpen}
      onClose={onModalClose}
      className={styles.root}
      dataTestIdPrefix={dataTestPrefix}
      isValidationError={isLoading || isSaveDisabled}
      title="Share to classes"
      subTitle="Students will see shared resources in the Backpack tab of Student Dashboard."
      actionLabel="Save"
      errorMessage="There was a problem saving the list of shared classes. Please try again."
      onAction={handleSubmit}
    >
      {isLoading && (
        <div className={styles.dialogBodyContainer}>
          <div className={styles.container}>
            <LinearProgress className={styles.progressbar} aria-hidden={true} />
            <div className={styles.loadingText}>
              <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
            </div>
          </div>
        </div>
      )}
      {isClassListLoadError && (
        <div className={styles.dialogBodyContainer}>
          <div className={styles.container}>
            <div className={styles.errorText}>
              <HapReactIcon
                svg="fill-circle-exclamation"
                className={styles.errorIcon}
                width={16}
                height={16}
                alt=""
              />
              <span>
                <FormattedMessage
                  defaultMessage="Trouble loading classes."
                  id="lONs1a"
                />
              </span>{" "}
              Please check your connection and try again.
            </div>
          </div>
        </div>
      )}
      {!isLoading && !isClassListLoadError && classList.length === 0 && (
        <div className={styles.dialogBodyContainer}>
          <div className={styles.container}>
            <div
              className={styles.emptyText}
              data-test-id="AssignToClasses-Modal-Empty-Text"
            >
              <span>
                <FormattedMessage
                  defaultMessage="No classes found"
                  id="iNmPy2"
                />
              </span>
            </div>
          </div>
        </div>
      )}
      {!isLoading && !isClassListLoadError && classList.length > 0 && (
        <>
          <div className={styles.dialogBody}>
            <AssignClassesMultiSelectList
              items={classList}
              dataTestPrefix={dataTestPrefix}
              itemsName="classes"
              singleItemName="class"
              selectedItemsIds={selectedClasses}
              setSelectedItemsIds={setSelectedClasses}
            />
          </div>
        </>
      )}
    </ActionDialog>
  );
};

AssignClassesModal.propTypes = {
  isModalOpen: PropTypes.bool,
  classList: PropTypes.arrayOf(classItemType),
  isMyClassesLoadingError: PropTypes.bool.isRequired,
  modalData: PropTypes.shape({
    resourceId: PropTypes.string,
  }),

  onModalClose: PropTypes.func.isRequired,
  loadClassAssignedList: PropTypes.func.isRequired,
  getMyClassesAction: PropTypes.func.isRequired,
  saveAssignedClasses: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getIsResourceAssignClassModalOpen(state),
    classList: getClassListSortedByName(state),
    isMyClassesLoadingError: isMyClassesLoadingError(state),
    modalData: getResourceAssignClassModalData(state),
  }),
  (dispatch) => ({
    onModalClose: () => dispatch(hideResourceAssignClassModal()),
    getMyClassesAction: () => dispatch(getMyClassesAction()),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
    loadClassAssignedList: ({ resourceId }) =>
      dispatch(fetchClassesAssignedToDoc({ resourceId })),
    saveAssignedClasses: ({ resourceId, addClasses, removeClasses }) =>
      dispatch(
        updateResourceAssignedClasses({ resourceId, addClasses, removeClasses })
      ),
  })
)(AssignClassesModal);
