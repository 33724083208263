import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import classnames from "classnames";
import styles from "../../Highlights/StudentTile/StudentTile.module.scss";
import { sendError } from "../../../../raygun";

// API CALLS
import { getStudentDriveDocuments } from "../../../../apiCalls/highlights";
import { getStudentEdublogsData } from "../../../../apiCalls/dashboard";

// COMPONENTS
import StudentTileGroups from "../../Highlights/StudentTileGroups/StudentTileGroups";
import StudentTileContextMenu from "../../Highlights/StudentTileContextMenu/StudentTileContextMenu";
import StudentTileDriveTable from "../StudentTileDriveTable/StudentTileDriveTable";
import StudentTileErrorState from "./StudentTileErrorState";
import StudentTileEmptyState from "./StudentTileEmptyState";
import StudentTileLoadingState from "./StudentTileLoadingState";

// SELECTORS
import { getBusWebsocketStatus } from "../../../../state/highlights/busWebsocket/selectors";
import { getScreenshotWebsocketStatus } from "../../../../state/highlights/screenshotWebsocket/selectors";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { getLocationPath } from "../../../../state/router/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import { getMonitoringTime } from "../../../../state/highlights/config/selectors";
import {
  getSearchQuery,
  getDocTypeFilter,
} from "../../../../state/shared/selectors";
import {
  DOC_FILTERS,
  ALL_DOCS_FILTER,
} from "../../../../state/highlights/drive/types";

// TYPES
import { STUDENT_STATUS_TYPES } from "../../../../state/highlights/students/types";
import {
  getDocumentsPayloadShape,
  ResponseShape,
  DocumentShape,
  MonitoringTimeShape,
  MenuPayloadShape,
  EdublogsShape,
} from "./types";

// UTILS
import { getFormattedName } from "../../../../utils";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
} from "../../../../state/storeUtils";
import { UserGroup } from "../../../../hooks/useGroups";
import EdublogsTable from "../../Dashboard/Edublogs/EdublogsTable";
import { decodeURIString } from "@hapara/ui/src/components/utils";
interface TileState {
  docs: DocumentShape[] | EdublogsShape[];
  email: string;
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

interface StudentShape {
  Id: string;
  Email: string;
  FirstName?: string;
  LastName?: string;
  instances: any;
  currentInstance?: string;
  lastSelectedInstance?: string;
}

interface StudentTileDriveProps {
  filter: string;
  student: StudentShape;
  draggableHandleClassName: string;
  busWebsocketStatus: string;
  screenshotWebsocketStatus: string;
  nameFormat: string;
  sendEvent: (event: string, data: any) => void;
  className: string;
  classId: string | null;
  monitoringTime: MonitoringTimeShape;
  dataTestIdPrefix: string;
  userGroups: UserGroup[];
  showFolderOption: boolean;
}

const TOTAL_RESULT = "25";

const StudentTileDrive: React.FC<StudentTileDriveProps> = ({
  nameFormat,
  student,
  draggableHandleClassName,
  className,
  classId,
  monitoringTime,
  dataTestIdPrefix,
  userGroups,
  showFolderOption,
}) => {
  const studentEmail = student.Email;
  const studentId = student.Id;
  const studentLastName = student.LastName;
  const studentDisplayName = getFormattedName(nameFormat, student);
  const studentStatus = _.get(
    student,
    `instances.${student.currentInstance}.status`
  );
  const searchQuery = (useSelector(getSearchQuery) as string) || "";
  const docTypeFilter =
    (useSelector(getDocTypeFilter) as string) || ALL_DOCS_FILTER.key;
  const subjectFolderID = useRef<string>();

  const {
    docType: docTypeParams,
    folderType: folderTypeParams,
    classId: classIdParams,
    edublogsType: edublogsTypeParams,
  } = useParams<{
    docType?: string;
    folderType?: string;
    classId?: string;
    edublogsType?: string;
  }>();

  const activeClassId = classIdParams ?? classId;

  const [tileState, setTileState] = useState<TileState>({
    ...DATA_LOADING_STATE_INIT,
    docs: [],
    email: "",
  });

  //SHARING: Filtering by document type
  const docTypeParamsSearch =
    docTypeParams === DOC_FILTERS[0].key ? ALL_DOCS_FILTER.key : docTypeParams;
  const activeDocTypeFilter = docTypeParamsSearch ?? docTypeFilter;

  //DASHBOARD: Filtering by subject folder
  const isSubjectFolderQuery = !!folderTypeParams;
  const activeSubjectFolder = folderTypeParams ?? "Documents";
  const subjectFolderMenuPayload: MenuPayloadShape = {
    isLoading: tileState.isLoading,
    showFolderOption: showFolderOption,
    subjectFolderID: subjectFolderID.current,
  };

  const getDriveDocuments = (payload: getDocumentsPayloadShape) => {
    setTileState({ ...tileState, ...DATA_LOADING_STATE_PENDING });
    getStudentDriveDocuments(payload)
      .then((response) => response.data)
      .then((data: ResponseShape) => {
        const userDocs = data.docsForAllUsers[0].docs;
        subjectFolderID.current = data.docsForAllUsers[0].folder_id;
        setTileState({
          ...tileState,
          docs: userDocs || [],
          ...DATA_LOADING_STATE_SUCCESS,
        });
      })
      .catch((e) => {
        sendError(e, ["StudentTileDrive.getDriveDocuments"]);
        setTileState({
          ...tileState,
          docs: [],
          ...DATA_LOADING_STATE_ERROR,
        });
      });
  };

  const getEdublogsContent = () => {
    setTileState({ ...tileState, ...DATA_LOADING_STATE_PENDING });
    return handleGetEdublogsContent()
      .then((response) => {
        return response?.data;
      })
      .then((data) => {
        const userContent =
          data.blogs[0][edublogsTypeParams === "posts" ? "posts" : "comments"];

        setTileState({
          ...tileState,
          docs: userContent as any,
          ...DATA_LOADING_STATE_SUCCESS,
        });
      })
      .catch((e) => {
        sendError(e, ["StudentTileDrive.getEdublogsContent"]);
        setTileState({
          ...tileState,
          docs: [],
          ...DATA_LOADING_STATE_ERROR,
        });
      });
  };

  const handleGetDriveDocuments = () =>
    getDriveDocuments({
      dash_id: activeClassId ?? "",
      tz: monitoringTime?.Timezone,
      email: studentEmail,
      emails: [studentEmail],
      subject: decodeURIString(activeSubjectFolder),
      searchfilter: searchQuery,
      searchfiltertype: _.isEmpty(searchQuery) ? "title" : "fullText",
      total: TOTAL_RESULT,
      type: isSubjectFolderQuery ? "" : activeDocTypeFilter,
    });

  const handleGetEdublogsContent = async () => {
    return await getStudentEdublogsData(studentEmail, edublogsTypeParams);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!edublogsTypeParams) {
          await getEdublogsContent();
        } else {
          await handleGetDriveDocuments();
        }
      } catch (error) {
        setTileState({
          ...tileState,
          docs: [],
          ...DATA_LOADING_STATE_ERROR,
        });
      }
    };
    fetchData();
  }, [
    userGroups,
    activeClassId,
    searchQuery,
    activeDocTypeFilter,
    activeSubjectFolder,
    studentEmail,
    edublogsTypeParams,
  ]);

  const isLoading = tileState.isLoading;
  const isError = tileState.isError;
  const isEmpty =
    tileState.isLoaded &&
    !tileState.isLoading &&
    !tileState.isError &&
    !(tileState.docs?.length >= 1);
  const isEmptyFilterApplied =
    isEmpty && activeDocTypeFilter !== ALL_DOCS_FILTER.key;
  const isEmptySearchApplied = isEmpty && searchQuery !== "";
  const isEmptySubjectFolderApplied = isEmpty && isSubjectFolderQuery;

  return (
    <div
      className={classnames(styles.root, className)}
      data-test-id="hl-StudentTileDrive"
    >
      <StudentTileGroups userGroups={userGroups} studentEmail={studentEmail} />

      <div className={styles.heading}>
        <div
          className={classnames(styles.name, draggableHandleClassName)}
          data-test-id="hl-StudentTile-StudentName"
        >
          <h2>{studentDisplayName}</h2>
        </div>

        <StudentTileContextMenu
          isOnline={false}
          isInGuideBrowsingSession={false}
          isPaused={false}
          isOutsideSchoolNetwork={
            studentStatus === STUDENT_STATUS_TYPES.IP_NOT_IN_RANGE
          }
          isOutsideSchoolHours={
            studentStatus === STUDENT_STATUS_TYPES.INVALID_TIME
          }
          studentId={studentId}
          studentEmail={studentEmail}
          studentDisplayName={studentDisplayName}
          studentLastName={studentLastName!}
          isDriveScreen
          reportProblemAction={() => null}
          subjectFolderMenuPayload={subjectFolderMenuPayload}
        />
      </div>
      <div className={styles.body}>
        {isLoading ? (
          <StudentTileLoadingState />
        ) : isError ? (
          <StudentTileErrorState
            getData={
              edublogsTypeParams ? getEdublogsContent : handleGetDriveDocuments
            }
          />
        ) : isEmpty ? (
          <StudentTileEmptyState
            isFilter={isEmptyFilterApplied}
            isSearch={isEmptySearchApplied}
            isFolder={isEmptySubjectFolderApplied}
            edublogsType={edublogsTypeParams}
          />
        ) : edublogsTypeParams ? (
          <EdublogsTable
            dataTestIdPrefix="student-edublogs-tile"
            tableData={tileState.docs as EdublogsShape[]}
            studentDisplayName={studentDisplayName}
            edublogsTypeParams={edublogsTypeParams as "posts" | "comments"}
          />
        ) : (
          <StudentTileDriveTable
            dataTestIdPrefix={dataTestIdPrefix}
            tableData={tileState.docs as DocumentShape[]}
            studentDisplayName={studentDisplayName}
            classId={activeClassId!}
            monitoringTime={monitoringTime}
            email={studentEmail}
          />
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  busWebsocketStatus: getBusWebsocketStatus(state),
  screenshotWebsocketStatus: getScreenshotWebsocketStatus(state),
  nameFormat: getUserNameFormat(state),
  locationPath: getLocationPath(state),
  classId: getClassId(state) as string | null,
  monitoringTime: getMonitoringTime(state),
}))(StudentTileDrive);
