import { useIntl, FormattedMessage } from "react-intl";
import React, { useCallback } from "react";
import ErrorStatePanel from "@hapara/ui/src/atomic/ErrorStatePanel/ErrorStatePanel";
import styles from "./ClassesTiles.module.scss";
import _ from "lodash";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { APP_PAGES } from "../../../../state/router/types";
import { getDataTestIdFromRawString } from "../../../../utils";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openAddClassDialog,
  openGCSyncDialog,
} from "../../../../state/dashboard/manage-classes/actions";
import {
  canAddManualClass,
  canDoGoogleClassroomSync,
  getSearchClassesQuery,
} from "../../../../state/dashboard/manage-classes/selectors";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";

const ClassesTileEmptyState = ({ allClasses }) => {
  const intl = useIntl();
  const query = useSelector(getSearchClassesQuery);
  const canAddManually = useSelector(canAddManualClass);
  const canAddGC = useSelector(canDoGoogleClassroomSync);
  const dispatch = useDispatch();
  const openAddDialogAction = useCallback(() => {
    dispatch(openAddClassDialog());
  }, [dispatch]);
  const openGCSyncDialogAction = useCallback(() => {
    dispatch(openGCSyncDialog());
  }, [dispatch]);

  const descriptionLinkText = intl.formatMessage({
    defaultMessage: "All classes",
    id: "FG+DJj",
  });

  const getDescriptionText = () => {
    if (canAddManually) {
      return (
        <FormattedMessage
          defaultMessage="Create your first class to get started!"
          id="J+utQv"
        />
      );
    }
    if (canAddGC) {
      return (
        <FormattedMessage
          defaultMessage="Import your existing classes from Google Classroom to engage with your students' browsing and Google Drive files."
          id="8cdwMd"
        />
      );
    }
    if (!allClasses) {
      const errDesc = (
        <FormattedMessage
          defaultMessage="Classes will appear here when you star them in {link}."
          id="RHY0tm"
          values={{
            link: (
              <Link
                to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.path}
                data-test-id={`Mc-EmptyState-${getDataTestIdFromRawString(
                  APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.title
                )}`}
              >
                {descriptionLinkText}
              </Link>
            ),
          }}
        />
      );
      return errDesc;
    }
    return intl.formatMessage({
      defaultMessage:
        "Your school administrator will need to add classes before you can use Hāpara.",
      id: "RlMslO",
    });
  };

  const AlternateTextComponent = () => {
    return (
      <div className={styles.alternateText}>
        <FormattedMessage
          defaultMessage="Alternatively, star your classes in {link} to see them here."
          id="Rn3zUw"
          values={{
            link: (
              <Link
                to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.path}
                data-test-id={`Mc-EmptyState-AddClass-${getDataTestIdFromRawString(
                  APP_PAGES.DASHBOARD_MANAGE_CLASSES_ALL.title
                )}`}
              >
                {descriptionLinkText}
              </Link>
            ),
          }}
        />
      </div>
    );
  };

  const AddSelfRosteringClassButton = () => {
    return (
      <MemorisedFocusButton
        isFullWidth
        label={intl.formatMessage({
          defaultMessage: "Create a class",
          id: "H9IPTD",
        })}
        type={BUTTON_TYPES.OUTLINED}
        size={BUTTON_SIZES.LARGE}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        icon="profile-group-filled"
        dataTestId="Mc-Self-CreateClass-Button"
        onClick={() => openAddDialogAction()}
      />
    );
  };

  const AddGCClassButton = () => {
    return (
      <MemorisedFocusButton
        isFullWidth
        label={intl.formatMessage({
          defaultMessage: "Add from Google Classroom",
          id: "V6fofW",
        })}
        type={BUTTON_TYPES.OUTLINED}
        size={BUTTON_SIZES.LARGE}
        outlineType={BUTTON_OUTLINE_TYPES.SOLID}
        icon="google-classroom"
        dataTestId="Mc-GC-CreateClass-Button"
        onClick={() => openGCSyncDialogAction()}
      />
    );
  };

  const AddSelfRosteringClassComponent = (
    <>
      <p>{getDescriptionText()}</p>
      <div className={styles.buttonArea}>
        <AddSelfRosteringClassButton />
      </div>
      {!allClasses ? AlternateTextComponent() : ""}
    </>
  );

  const AddGCClassComponent = (
    <>
      <p>{getDescriptionText()}</p>
      <div className={styles.buttonArea}>
        <AddGCClassButton />
      </div>
      {!allClasses ? AlternateTextComponent() : ""}
    </>
  );

  const AddSelfRosteringAndGCClassComponent = (
    <>
      <p>{getDescriptionText()}</p>
      <div className={styles.buttonArea}>
        <AddGCClassButton />
      </div>
      <div className={styles.or}>
        <FormattedMessage defaultMessage="Or" id="4wpUNc" />
      </div>
      <div className={styles.lessMargin}>
        <AddSelfRosteringClassButton />
      </div>
      {!allClasses ? AlternateTextComponent() : ""}
    </>
  );

  const getChildComponent = () => {
    if (canAddManually && canAddGC) {
      return AddSelfRosteringAndGCClassComponent;
    }
    if (canAddGC) {
      return AddGCClassComponent;
    }
    if (canAddManually) {
      return AddSelfRosteringClassComponent;
    }
    return <p>{getDescriptionText()}</p>;
  };
  if (_.isEmpty(query)) {
    return (
      <ErrorStatePanel
        message={intl.formatMessage({
          defaultMessage: "Don’t see any classes?",
          id: "fYBXTu",
        })}
      >
        {getChildComponent()}
      </ErrorStatePanel>
    );
  }
  return (
    <ErrorStatePanel
      message={intl.formatMessage({
        defaultMessage: "Sorry, we couldn’t find any results!",
        id: "AxctB8",
      })}
    >
      <p>
        <FormattedMessage
          defaultMessage="Try different keywords or double-check the spelling of the class or teacher."
          id="Jymx+N"
        />
      </p>
    </ErrorStatePanel>
  );
};

export default ClassesTileEmptyState;
