import { useState, useLayoutEffect, MutableRefObject } from "react";

type Size = {
  width: number;
  height: number;
};

/** Returns the current width and height of a referenced DOM element, updating on resize. */
export const useResizeObserver = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>
): { size: Size } => {
  const [size, setSize] = useState<Size>(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      return { width, height };
    }
    return { width: 0, height: 0 };
  });

  useLayoutEffect(() => {
    if (!ref.current) return;

    const handleResize = (width: number, height: number) => {
      if (size.width === width && size.height === height) {
        return;
      }
      setSize({ width, height });
    };

    const resizeObserverCallback: ResizeObserverCallback = (entries) => {
      requestAnimationFrame(() => {
        for (const entry of entries) {
          if (entry.target !== ref.current) {
            continue;
          }

          if (entry.contentBoxSize) {
            // TODO: array check possibly not needed, maybe for older browsers? TypeScript only considers `contentBoxSize` as an array.
            const contentBoxSizes = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize
              : [entry.contentBoxSize];

            const width = contentBoxSizes.reduce(
              (acc, size) => acc + size.inlineSize,
              0
            );
            const height = contentBoxSizes.reduce(
              (acc, size) => acc + size.blockSize,
              0
            );
            handleResize(width, height);
          } else {
            const { width, height } = entry.contentRect;
            handleResize(width, height);
          }
        }
      });
    };

    const resizeObserver = new window.ResizeObserver(resizeObserverCallback);
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  return { size };
};
