// Automatically generated list
export const iconList = [
  "add-card-hover",
  "add-card",
  "add-photo",
  "an-home-building",
  "an-interaction",
  "an-laptop",
  "an-people-group",
  "an-people-peer",
  "an-school-building",
  "an-teacher-instruct",
  "an-teachers-feedback",
  "angle-double-left",
  "angle-double-right",
  "angle-down",
  "angle-up",
  "apps",
  "archive",
  "arrow-alt",
  "arrow-angle-collapse",
  "arrow-angle-down",
  "arrow-angle-expand",
  "arrow-angle-left",
  "arrow-angle-right",
  "arrow-angle-up-down",
  "arrow-angle-up",
  "arrow-backward",
  "arrow-carvet-down",
  "arrow-carvet-left",
  "arrow-carvet-right",
  "arrow-carvet-up-down-ascending",
  "arrow-carvet-up-down-descending",
  "arrow-carvet-up-down-inactive",
  "arrow-carvet-up-down",
  "arrow-carvet-up",
  "arrow-diangonal-right",
  "arrow-down",
  "arrow-enter",
  "arrow-external-link",
  "arrow-forward",
  "arrow-inward",
  "arrow-left",
  "arrow-outward",
  "arrow-right-left",
  "arrow-right",
  "arrow-sync",
  "arrow-up-down",
  "arrow-up",
  "assignment-material",
  "assignment-question",
  "assignment",
  "attachment",
  "backpack",
  "bell-gradient-dark",
  "bell-gradient-light",
  "bell-gradient",
  "bell",
  "bomb",
  "book-open",
  "book",
  "bookmark",
  "books-stack",
  "bubble-blank",
  "bubble-box-speech",
  "bubble-speech",
  "calendar",
  "camera",
  "caret-down",
  "caret-right",
  "chain",
  "chart-fill",
  "chart-outline",
  "check-sm",
  "check",
  "checkerboard",
  "checklist-gradient-white",
  "checklist-gradient",
  "checklist",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "circle-arrow-left",
  "circle-arrow-right",
  "circle-blackboard-gray",
  "circle-blackboard-green",
  "circle-blackboard",
  "circle-book",
  "circle-check-fill",
  "circle-checklist",
  "circle-checkmark",
  "circle-cross",
  "circle-empty",
  "circle-exclamation-orange",
  "circle-exclamation-red",
  "circle-exclamation",
  "circle-google-drive-logo",
  "circle-graduate-cap-gray",
  "circle-graduate-cap",
  "circle-information",
  "circle-labels-gray",
  "circle-labels",
  "circle-minus",
  "circle-misc",
  "circle-people-gray",
  "circle-people",
  "circle-person-gray",
  "circle-person",
  "circle-plus",
  "circle-profile-gray-exclamation",
  "circle-profile-gray",
  "circle-question",
  "circle-stack",
  "circle-stacked-checkmark-gray",
  "circle-stacked-checkmark",
  "circle-stacked-ribbon-filled-gray",
  "circle-stacked-ribbon-filled",
  "circle-stacked-ribbon-gray",
  "circle-stacked-ribbon",
  "circle-star-gray",
  "circle-star",
  "circle-zipped-file-gray",
  "circle-zipped-file",
  "classes",
  "clipboard",
  "clock-history",
  "clock",
  "close",
  "co",
  "code",
  "coding",
  "cog-fill-gradient-white",
  "cog-fill",
  "cog-rounded-filled",
  "cog-rounded-outline",
  "cog",
  "cogs",
  "collection",
  "comment-o",
  "connection-no",
  "copy-link",
  "copy",
  "create-drive",
  "cross-sm",
  "cross",
  "cube",
  "delete",
  "description",
  "diamond",
  "dots",
  "drive-coloured",
  "drive",
  "dropdown-button",
  "duplicate",
  "edit-cog",
  "edit",
  "editor",
  "edublogs-icon",
  "ellipsis-h",
  "ellipsis",
  "emoji-books-stack",
  "emoji-books",
  "emoji-nerdface",
  "emoji-open-book",
  "emoji-partypopper",
  "emoji-pen",
  "emoji-raisinghands",
  "emoji-school-bag",
  "emoji-sparkles",
  "emoji-teacher",
  "emoji-telescope",
  "emoji-victoryhand",
  "emoji-writinghand",
  "envelope-o",
  "envelope",
  "exclamation-circle",
  "exclamation-triangle",
  "exclamation",
  "expand",
  "eye-closed",
  "eye",
  "fallback-icon",
  "file-add",
  "file-copy",
  "file-download-cloud",
  "file-download",
  "file-upload-cloud",
  "file-upload",
  "file",
  "fill-circle-exclamation",
  "fill-circle-question",
  "fill-envelope",
  "fill-focused-browsing",
  "filter-funnel",
  "filter-lines",
  "filter",
  "fire",
  "flag-fill",
  "flag",
  "focus-shield",
  "focus",
  "focused-browsing-on",
  "folder",
  "frown-o",
  "globe",
  "google-classroom-round",
  "google-classroom-square",
  "google-classroom",
  "google-data-studio",
  "google-document",
  "google-drawing",
  "google-drive",
  "google-folder",
  "google-form",
  "google-gmail",
  "google-image",
  "google-jam",
  "google-logo-drive",
  "google-map",
  "google-pdf",
  "google-plus",
  "google-presentation",
  "google-sheet",
  "google-site",
  "google-slides",
  "google-spreadsheet",
  "group",
  "groups-3-people-filled",
  "groups-3-people-outline",
  "hamburger",
  "hand-holding-pen",
  "heart",
  "home",
  "house-filled",
  "house-outline",
  "icon-card",
  "icon-filter",
  "icon-flag-australia",
  "icon-flag-canada",
  "icon-flag-new-zealand",
  "icon-flag-usa",
  "icon-group",
  "icon-workspace",
  "key-filled",
  "key-outline",
  "label-filled",
  "label",
  "lightbulb-o",
  "link",
  "list-arrow-animated",
  "list-arrow",
  "list-checkmark",
  "loader-light",
  "loader",
  "lock-fill",
  "lock",
  "login-logo-google",
  "logo-hapara-small-black",
  "logo-hapara-small-light",
  "logo-hapara-small",
  "magnifying-glass-plus",
  "mail-reply",
  "map-pin",
  "material-icon-folder",
  "members-gradient-white",
  "members-gradient",
  "message-text-fill",
  "message-text",
  "minus-sm",
  "minus",
  "modal-close",
  "modules",
  "multiple-devices",
  "multiple-logins",
  "open-book",
  "paperdoll-fill-partial",
  "paperdoll-fill",
  "paperdoll-outline",
  "pencil-alt",
  "pencil-fill",
  "pencil",
  "plus-circle",
  "plus-sm",
  "plus-thick",
  "plus",
  "power-off",
  "printer",
  "profile-group-filled",
  "profile-group-gradient-white",
  "profile-group-gradient",
  "profile-group",
  "profile-solo",
  "pushpin",
  "question-circle-o",
  "question",
  "record",
  "recording",
  "refresh",
  "remove",
  "return-final",
  "return-for-edit",
  "rocket",
  "school-bag",
  "school-building-filled",
  "school-building-outline",
  "screen-pause",
  "sd-arrow-angle-left-white",
  "sd-arrow-angle-left",
  "sd-arrow-angle-right-white",
  "sd-arrow-angle-right",
  "sd-arrow-carvet-down",
  "sd-arrow-carvet-up",
  "sd-calendar-outline",
  "sd-cross",
  "sd-filter-all",
  "sd-folder-fill-white",
  "sd-folder-fill",
  "sd-folder-outline",
  "sd-graphic-notebook",
  "sd-layout-arrow",
  "sd-speech-box-fill-white",
  "sd-speech-box-fill",
  "sd-speech-box-outline",
  "sd-workbook-fill-white",
  "sd-workbook-fill",
  "sd-workbook-outline",
  "search",
  "share",
  "sharing",
  "single-device",
  "sitemap",
  "smiley-face",
  "sort-asc",
  "sort-desc",
  "spinner",
  "stack",
  "star-fill",
  "star",
  "startable",
  "started",
  "student-group-add",
  "student-group-confirm-delete-alert",
  "student-groups-collapse",
  "student-groups-expand",
  "student-selector-added-teacher-alert",
  "student-selector-search-clear",
  "student-selector-search",
  "submitted",
  "tag",
  "teacher",
  "trash-o",
  "trash",
  "twitter",
  "unlock",
  "unshare",
  "upload",
  "video-play",
  "workspace-icon-gradient-white",
  "workspace-icon-gradient",
  "workspace-icon",
  "ws-thumbnail-default-square",
  "youtube",
];