import { ChatContactsResponse } from "../types/response";
import { ChatState } from "../types/state";

/** Include `channelId` on `contact` so we can pass it around on its own. */
export const transformContacts = (
  stateContacts: ChatState["contacts"],
  receivedContacts: ChatContactsResponse["contacts"]
): ChatState["contacts"] =>
  Object.entries(receivedContacts).reduce<ChatState["contacts"]>(
    (acc, [contactId, contact]) => {
      return {
        ...acc,
        [contactId]: {
          channelId: contactId,
          ...contact,
          presence: stateContacts[contactId]?.presence,
        },
      };
    },
    {}
  );
