import React, { useEffect } from "react";
import { ChatLayout } from "../../components/ChatLayout";
import { ChatHeader } from "../../components/ChatHeader";
import { ChatMessages } from "../../components/ChatMessages";
import { ChatMessage } from "../../components/ChatMessage";
import { ChatInput } from "../../components/ChatInput";
import { useDisplayName, DisplayNameFormat } from "../../../DisplayName";

import { BackButton } from "../../../../atomic/BackButton";
import { ChatChannelItem } from "../../components/ChatChannelItem";

import {
  ChatChannelState,
  ChatContactState,
  ChatRoleType,
  ChatClassState,
  ChatTextMessageState,
} from "../../types/state";
import { ChatNoMessages } from "../../components/ChatNoMessages";
import { useIntl } from "react-intl";
import { usePrevious } from "../../../../hooks/usePrevious";

type ChatChannelViewProps = {
  role: ChatRoleType;
  userId: string;
  classId: string;
  selectedClass: ChatClassState;
  contact: ChatContactState;
  channel?: ChatChannelState | null;
  onConversationClose: () => void;
  onMessageSend: (message: string) => void;
  onConversationRead: (channelId: string, classId: string) => void;
  isSchoolHours?: boolean;
  displayNameFormat?: DisplayNameFormat;
};

const getLastReadMessageId = (
  messages: ChatTextMessageState[] | undefined,
  receiverLastReadTimestamp?: string | null
): string | null => {
  if (!messages || !receiverLastReadTimestamp) {
    return null;
  }

  const lastReadMessage = messages
    .filter((message) => message.timestamp <= receiverLastReadTimestamp)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )[0];

  return lastReadMessage ? lastReadMessage.id : null;
};

export const ChatChannelView = ({
  role,
  userId,
  contact,
  onMessageSend,
  channel,
  onConversationClose,
  onConversationRead,
  classId,
  selectedClass,
  isSchoolHours,
  displayNameFormat,
}: ChatChannelViewProps) => {
  const intl = useIntl();

  const displayName = useDisplayName({
    firstName: contact.firstName,
    lastName: contact.lastName,
    order: displayNameFormat,
  });

  const isClassEnabled = selectedClass?.status?.[userId] === "enabled";
  const isTeacherEnabled =
    channel?.contactId &&
    selectedClass?.status?.[channel?.contactId] === "enabled";
  const isDisabled = !isClassEnabled && !isTeacherEnabled;

  const prevChannel = usePrevious(channel);
  useEffect(() => {
    if (
      channel?.id &&
      prevChannel?.messages?.length !== channel?.messages?.length
    ) {
      onConversationRead(channel?.id, classId);
    }
  }, [channel]);

  const filteredMessages =
    channel?.messages?.filter((message) => message.classId === classId) ?? [];

  const lastReadMessageId = getLastReadMessageId(
    filteredMessages.filter((message) => message.publisherId === userId),
    channel?.classStatus?.[classId]?.receiverLastReadTimestamp
  );

  return (
    <ChatLayout
      header={
        <ChatHeader
          leading={
            <BackButton
              onClick={onConversationClose}
              dataTestIdPrefix="chat-direct-channel"
            />
          }
          title={
            <ChatChannelItem
              role={role}
              firstName={contact.firstName}
              lastName={contact.lastName}
              presence={contact?.presence}
              isDisabled={isDisabled}
              isOutsideSchoolHours={!isSchoolHours}
              displayNameFormat={displayNameFormat}
            />
          }
        />
      }
      footer={
        <ChatInput
          presence={contact?.presence}
          isDisabled={isDisabled}
          role={role}
          isOutsideSchoolHours={!isSchoolHours}
          onSend={onMessageSend}
          placeholder={intl.formatMessage(
            {
              defaultMessage: "Message {displayName}",
              description:
                "This appears in chat input field as a placeholder, i.e. 'Message SpongeBob SquarePants'",
              id: "vb5+qb",
            },
            { displayName }
          )}
        />
      }
    >
      {filteredMessages.length === 0 ? (
        <ChatNoMessages />
      ) : (
        <ChatMessages>
          {filteredMessages.map((message) => {
            if (message.type === "text") {
              return (
                <ChatMessage
                  key={message.id}
                  timestamp={message.timestamp}
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  message={message.body}
                  isOwnMessage={userId === message.publisherId}
                  isLastReadMessage={message.id === lastReadMessageId}
                  status={message.status}
                />
              );
            }
          })}
        </ChatMessages>
      )}
    </ChatLayout>
  );
};
