import { BlockForClass } from "./BlockForClass";
import { BlockForStudent } from "./BlockForStudent";
import { CloseForStudent } from "./CloseForStudent";
import { StudentBrowserTabType } from "../types";

interface DialogBodyProps {
  modalView: "block-class" | "block-student" | "close";
  tab: StudentBrowserTabType;
  duration: number | null;
  activeClassFilterSession: boolean;
  activeOtherSession?: boolean;
  activeIndividualSession?: boolean;
  activeGroupSession?: boolean;
  onDurationChange: (duration: number | null) => void;
  studentName: string;
  closeReasons: string[];
  onCloseTab: (reason: string) => void;
}

export const DialogBody = ({
  modalView,
  tab,
  duration,
  activeClassFilterSession,
  activeOtherSession,
  activeGroupSession,
  activeIndividualSession,
  onDurationChange,
  studentName,
  closeReasons,
  onCloseTab,
}: DialogBodyProps) => {
  switch (modalView) {
    case "block-class":
      return (
        <BlockForClass
          tab={tab}
          duration={duration}
          activeClassFilterSession={activeClassFilterSession}
          activeOtherSession={!!activeOtherSession}
          onDurationChange={onDurationChange}
          dataTestIdPrefix="block-for-class"
        />
      );
    case "block-student":
      return (
        <BlockForStudent
          tab={tab}
          duration={duration}
          activeIndividualSession={activeIndividualSession}
          activeClassFilterSession={activeClassFilterSession}
          activeGroupSession={activeGroupSession}
          onDurationChange={onDurationChange}
          dataTestIdPrefix="block-for-student"
        />
      );
    default:
      return (
        <CloseForStudent
          tab={tab}
          studentName={studentName}
          closeReasons={closeReasons}
          onCloseTab={onCloseTab}
          dataTestIdPrefix="close-for-student"
        />
      );
  }
};
