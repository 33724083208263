import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Toggle.module.scss";
import { v4 as uuidv4 } from "uuid";

import TOGGLE_TYPE from "./ToggleTypes";
import _ from "lodash";
import { THEME_TYPES } from "../consts";

export const TOGGLE_POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
};

/** @deprecated use the new typed `Switch` component instead which wraps this
 * old component with sane props. */
const Toggle = ({
  id, // Introduced for new `Switch` component to link external labels to the input
  className,
  explanatoryText,
  positionOfToggle = TOGGLE_POSITIONS.RIGHT,
  toggleValues,
  disabled,
  dataTestIdPrefix,
  onChange,
  value,
  themeType = THEME_TYPES.LIGHT,
  hideExplanatoryText,
}) => {
  const [uniqId] = useState(uuidv4());
  const intl = useIntl();

  const defaultExplanatoryText = intl.formatMessage({
    defaultMessage: "toggle",
    id: "0QfDUF",
  });

  const getCompletedToggleValues = () => {
    if (!toggleValues?.left || !toggleValues?.right) {
      return {
        left: { label: null },
        right: { label: null },
      };
    }

    return toggleValues;
  };

  const completedToggleValues = getCompletedToggleValues();

  const handleToggleChange = () => {
    onChange(!value);
  };

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.toggleOnLeft]: positionOfToggle === TOGGLE_POSITIONS.LEFT },
        styles[themeType],
        className
      )}
    >
      {!hideExplanatoryText && explanatoryText && (
        <div
          className={styles.explanatoryText}
          id={`toggleLabelText_${uniqId}`}
        >
          {explanatoryText}
        </div>
      )}
      <div className={styles.toggle}>
        {completedToggleValues.left.label && (
          <span
            className={classnames(styles.visibleLabel, {
              [styles.disabled]: disabled,
              [styles.inactive]: value,
            })}
          >
            {completedToggleValues.left.label}
          </span>
        )}
        <input
          type="checkbox"
          id={id ?? `toggleControl_${uniqId}`}
          className={styles.toggleControl}
          aria-labelledby={explanatoryText ? `toggleLabelText_${uniqId}` : null}
          aria-checked={value}
          onChange={handleToggleChange}
          disabled={disabled}
          aria-label={explanatoryText ?? defaultExplanatoryText}
          checked={value}
          data-test-id={`${dataTestIdPrefix}-Checkbox`}
        />
        <label
          htmlFor={`toggleControl_${uniqId}`}
          id={`toggleControlLabel_${uniqId}`}
          className={classnames(styles.toggleLabel, {
            [styles.toggleLabelDisabled]: disabled,
          })}
          data-test-id={`${dataTestIdPrefix}-Label`}
        />
        {completedToggleValues.right.label && (
          <span
            className={classnames(styles.visibleLabel, {
              [styles.disabled]: disabled,
              [styles.inactive]: !value,
            })}
          >
            {completedToggleValues.right.label}
          </span>
        )}
      </div>
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
  explanatoryText: PropTypes.string,
  positionOfToggle: PropTypes.string,
  toggleValues: PropTypes.shape({
    left: TOGGLE_TYPE,
    right: TOGGLE_TYPE,
  }),
  disabled: PropTypes.bool,
  dataTestIdPrefix: PropTypes.string,
  onChange: PropTypes.func,
  themeType: PropTypes.oneOf(_.values(THEME_TYPES)),
  hideExplanatoryText: PropTypes.bool,
};

export default Toggle;
