import { isValidDate } from "../isValidDate";
import { isValidTime } from "../isValidTime";

// TODO: PS-1376 use timezone aware "plain" dates and times.
export const convertLocalDateTimeToUTCString = (
  inputDate: string,
  inputTime: string
) => {
  if (!isValidDate(inputDate)) throw new Error("Invalid date format");
  if (!isValidTime(inputTime)) throw new Error("Invalid time format");

  const dateObj = new Date(`${inputDate}T${inputTime}`);
  return dateObj.toISOString();
};
