import { FormattedMessage } from "react-intl";
import React from "react";
import _ from "lodash";
import styles from "./DiscoverExploreByType.module.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../../state/router/types";
import {
  LANDING_PAGE_SHORTCUT_TYPE,
  EXPLORE_BY_TYPE_SHORTCUTS,
} from "../../../../state/library/discover/types";
import { SHARED_FILTER_TYPES } from "../../../../state/shared/types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

const DiscoverExploreByTypeItem = ({ item }) => {
  return (
    <Link
      to={{
        pathname: `${APP_PAGES.DISCOVER_SEARCH.path}`,
        search: `${SHARED_FILTER_TYPES.RESOURCE_TYPE.paramName}=${item.Filter}&page=1`,
      }}
      className={styles.itemRoot}
      data-test-id={`lb-Discover-ExploreByType-${item.DataTestPrefix}`}
    >
      <div className={styles.container}>
        <HapReactIcon
          className={styles.icon}
          svg={item.Icon}
          alt={item.AltText ? item.AltText : ""}
        />
        <div className={styles.title}>{item.Title}</div>
      </div>
    </Link>
  );
};

DiscoverExploreByTypeItem.propTypes = {
  item: LANDING_PAGE_SHORTCUT_TYPE,
};

const DiscoverExploreByType = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>
          <FormattedMessage
            defaultMessage="Explore resources by type"
            id="NrnIOy"
          />
        </h2>
      </div>
      <ul
        className={styles.body}
        data-test-id="lb-Discover-ExploreByType-ShortCutsList"
      >
        {_.map(EXPLORE_BY_TYPE_SHORTCUTS, (item) => {
          return (
            <li
              key={item.Id}
              className={classnames(
                styles.listItem,
                styles[`background${item.BackgroundColor}`]
              )}
            >
              <DiscoverExploreByTypeItem item={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DiscoverExploreByType;
