import React from "react";
import { createIcon } from "./utils/createIcon";

export const FocusIcon = createIcon(
  <>
    <path
      d="m5.07 9.48 1.95 1.95 3.91-3.9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 5.08h-13m0 .2v5.45c0 1.1 0 1.64.21 2.05.2.37.49.67.86.86.41.2.96.2 2.05.2h6.76c1.1 0 1.64 0 2.05-.2.37-.2.67-.49.86-.86.2-.41.2-.96.2-2.05V5.27c0-1.1 0-1.64-.2-2.05a1.95 1.95 0 0 0-.86-.86c-.41-.2-.96-.2-2.05-.2H4.62c-1.1 0-1.64 0-2.05.2-.37.2-.67.49-.86.86-.2.41-.2.96-.2 2.05Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
