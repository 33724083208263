import styles from "./GuidedBrowsingMoreOptions.module.scss";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { GuidedBrowsingSectionContent } from "../GuidedBrowsingSectionContent";
import { FormattedMessage, useIntl } from "react-intl";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { Heading } from "@hapara/ui/src/atomic/Heading/Heading";
import { Radio } from "@hapara/ui/src/atomic/Radio/Radio";

interface GuidedBrowsingMoreOptionsPresentationProps {
  browsingAccess: "whole_site" | "links";
  onBrowsingAccessChange: (type: "whole_site" | "links") => void;
  keepLinksOpen: boolean;
  onKeepLinksOpenChange: (val: boolean) => void;
  restoreTabs: boolean;
  onRestoreTabsChange: (val: boolean) => void;
}

export const GuidedBrowsingMoreOptionsPresentation = ({
  browsingAccess,
  onBrowsingAccessChange,
  onKeepLinksOpenChange,
  onRestoreTabsChange,
  keepLinksOpen,
  restoreTabs,
}: GuidedBrowsingMoreOptionsPresentationProps) => {
  const intl = useIntl();
  return (
    <GuidedBrowsingSection>
      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <Heading level={3} styledAs="h5" removeBottomMargin removeTopMargin>
            <FormattedMessage defaultMessage="Browsing access" id="r/vbZx" />
          </Heading>
        </div>
        <div className={styles.rightColumn}>
          <div>
            <Radio
              dataTestIdPrefix="browsing-access-whole-site"
              name="browsingAccess"
              value="whole_site"
              checked={browsingAccess === "whole_site"}
              onChange={() => onBrowsingAccessChange("whole_site")}
              label={intl.formatMessage({
                defaultMessage: "Allow full website access",
                id: "4FQa+d",
              })}
            />
          </div>
          <div>
            <Radio
              dataTestIdPrefix="browsing-access-links"
              name="browsingAccess"
              value="links"
              checked={browsingAccess === "links"}
              onChange={() => onBrowsingAccessChange("links")}
              label={intl.formatMessage({
                defaultMessage: "Only view specific page",
                id: "V/U4TD",
              })}
            />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.leftColumn}>
          <Heading level={3} styledAs="h5" removeTopMargin removeBottomMargin>
            <FormattedMessage defaultMessage="After session" id="etXTSb" />
          </Heading>
        </div>
        <div className={styles.rightColumn}>
          <div>
            <Checkbox
              dataTestIdPrefix="keep-links-open"
              checked={keepLinksOpen}
              onChange={() => onKeepLinksOpenChange(!keepLinksOpen)}
              label={intl.formatMessage({
                defaultMessage: "Keep session links open",
                id: "oS9IwG",
              })}
            />
          </div>
          <div>
            <Checkbox
              dataTestIdPrefix="restore-tabs"
              checked={restoreTabs}
              onChange={() => onRestoreTabsChange(!restoreTabs)}
              label={intl.formatMessage({
                defaultMessage: "Restore students’ original tabs",
                id: "yBsuHF",
              })}
            />
          </div>
        </div>
      </div>
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingMoreOptions = ({
  browsingAccess,
  onBrowsingAccessChange,
  keepLinksOpen,
  onKeepLinksOpenChange,
  restoreTabs,
  onRestoreTabsChange,
}: GuidedBrowsingMoreOptionsPresentationProps) => {
  return (
    <GuidedBrowsingMoreOptionsPresentation
      onBrowsingAccessChange={onBrowsingAccessChange}
      browsingAccess={browsingAccess}
      keepLinksOpen={keepLinksOpen}
      onKeepLinksOpenChange={onKeepLinksOpenChange}
      restoreTabs={restoreTabs}
      onRestoreTabsChange={onRestoreTabsChange}
    />
  );
};
