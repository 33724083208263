import React from "react";
import styles from "./ChatHeader.module.scss";
import { Heading } from "../../../../atomic/Heading";

type ChatHeaderProps = {
  leading?: React.ReactNode;
  title: React.ReactNode;
  actions?: React.ReactNode;
};

export const ChatHeader = ({ leading, title, actions }: ChatHeaderProps) => {
  const shouldRenderHeading = typeof title === "string";
  return (
    <div className={styles.container}>
      {leading ? <div className={styles.leading}>{leading}</div> : null}
      <div className={styles.title}>
        {shouldRenderHeading ? (
          <Heading
            className={styles.heading}
            level={1}
            styledAs="h3"
            removeTopMargin
            removeBottomMargin
          >
            {title}
          </Heading>
        ) : (
          title
        )}
      </div>
      <div className={styles.actions}>{actions ? actions : null}</div>
    </div>
  );
};
