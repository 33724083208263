import { AnyAction } from "redux";
import actionTypes from "../actionTypes";
import {
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
  DATA_LOADING_STATE_ERROR,
} from "../storeUtils";
import { ClassInfoData } from "../../apiCalls/classInfo/classInfoTypes";
import { ClassInfoAddPersonResponse } from "../../apiCalls/classInfo";

const INIT_STATE = {
  ...DATA_LOADING_STATE_INIT,
  data: null as ClassInfoData | null,
  addStudents: {
    ...DATA_LOADING_STATE_INIT,
    successfullyAddedStudents: [] as string[],
    unsuccessfullyAddedStudents: [] as ClassInfoAddPersonResponse["Bad"],
    pendingStudents: [] as string[],
  },
  removeStudents: {
    ...DATA_LOADING_STATE_INIT,
  },
  addTeacher: {
    ...DATA_LOADING_STATE_INIT,
    unsuccessfullyAddedTeacher: [] as ClassInfoAddPersonResponse["Bad"],
    hasServerValidationError: false,
  },
  removeTeachers: {
    ...DATA_LOADING_STATE_INIT,
  },
  renameClass: {
    ...DATA_LOADING_STATE_INIT,
  },
  syncGoogleClassroom: {
    ...DATA_LOADING_STATE_INIT,
  },
  updatePasswords: {
    ...DATA_LOADING_STATE_INIT,
  },
  removeClass: {
    ...DATA_LOADING_STATE_INIT,
  },
  resetClass: {
    ...DATA_LOADING_STATE_INIT,
  },
};

const classInfoReducer = (
  state = INIT_STATE,
  action: AnyAction
): typeof INIT_STATE => {
  switch (action.type) {
    case actionTypes.CLASS_INFO_DATA_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
      };
    }

    case actionTypes.CLASS_INFO_DATA_LOAD_SUCCESS: {
      return {
        ...state,
        ...DATA_LOADING_STATE_SUCCESS,
        data: action.payload,
      };
    }

    case actionTypes.CLASS_INFO_DATA_LOAD_ERROR: {
      return {
        ...state,
        ...DATA_LOADING_STATE_ERROR,
      };
    }

    case actionTypes.CLASS_INFO_ADD_STUDENTS_PENDING: {
      return {
        ...state,
        addStudents: {
          ...state.addStudents,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_STUDENTS_SUCCESS: {
      const addStudentData = {
        successfullyAddedStudents: action.payload.successfullyAddedStudents,
        unsuccessfullyAddedStudents: action.payload.unsuccessfullyAddedStudents,
        pendingStudents: action.payload.pendingStudents,
      };

      if (action.payload.classData) {
        return {
          ...state,
          data: action.payload.classData,
          addStudents: {
            ...DATA_LOADING_STATE_SUCCESS,
            ...addStudentData,
          },
        };
      }
      return {
        ...state,
        addStudents: {
          ...DATA_LOADING_STATE_SUCCESS,
          ...addStudentData,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_STUDENTS_ERROR: {
      return {
        ...state,
        addStudents: {
          ...state.addStudents,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_STUDENTS_RESET: {
      return {
        ...state,
        addStudents: {
          ...state.addStudents,
          ...DATA_LOADING_STATE_INIT,
          unsuccessfullyAddedStudents: [],
          successfullyAddedStudents: [],
          pendingStudents: [],
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_STUDENTS_PENDING: {
      return {
        ...state,
        removeStudents: {
          ...state.removeStudents,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_STUDENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        removeStudents: {
          ...state.removeStudents,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_STUDENTS_ERROR: {
      return {
        ...state,
        removeStudents: {
          ...state.addStudents,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_STUDENTS_RESET: {
      return {
        ...state,
        removeStudents: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_TEACHER_PENDING: {
      return {
        ...state,
        addTeacher: {
          ...state.addTeacher,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_TEACHER_SUCCESS: {
      const addTeacherData = {
        unsuccessfullyAddedTeacher: action.payload.unsuccessfullyAddedTeacher,
        hasServerValidationError: false,
      };
      if (action.payload.classData) {
        return {
          ...state,
          data: action.payload.classData,
          addTeacher: {
            ...DATA_LOADING_STATE_SUCCESS,
            ...addTeacherData,
          },
        };
      }
      return {
        ...state,
        addTeacher: {
          ...DATA_LOADING_STATE_SUCCESS,
          ...addTeacherData,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_TEACHER_ERROR: {
      return {
        ...state,
        addTeacher: {
          ...state.addTeacher,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.CLASS_INFO_ADD_TEACHER_SERVER_VALIDATION_ERROR: {
      const addTeacherData = {
        hasServerValidationError: true,
      };
      return {
        ...state,
        addTeacher: {
          ...state.addTeacher,
          ...addTeacherData,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_ADD_TEACHER_RESET: {
      return {
        ...state,
        addTeacher: {
          ...state.addTeacher,
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_TEACHERS_PENDING: {
      return {
        ...state,
        removeTeachers: {
          ...state.removeTeachers,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_TEACHERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        removeTeachers: {
          ...state.removeTeachers,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_TEACHERS_ERROR: {
      return {
        ...state,
        removeTeachers: {
          ...state.removeTeachers,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_TEACHERS_RESET: {
      return {
        ...state,
        removeTeachers: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_RENAME_CLASS_PENDING: {
      return {
        ...state,
        renameClass: {
          ...state.renameClass,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }
    case actionTypes.CLASS_INFO_RENAME_CLASS_SUCCESS: {
      if (!state.data) {
        return {
          ...state,
          renameClass: {
            ...state.renameClass,
            ...DATA_LOADING_STATE_SUCCESS,
          },
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          classNickname: action.payload.newClassName,
        },
        renameClass: {
          ...state.renameClass,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.CLASS_INFO_RENAME_CLASS_ERROR: {
      return {
        ...state,
        renameClass: {
          ...state.renameClass,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.CLASS_INFO_RENAME_CLASS_RESET: {
      return {
        ...state,
        renameClass: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_SYNC_CLASS_PENDING: {
      return {
        ...state,
        syncGoogleClassroom: {
          ...state.syncGoogleClassroom,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_SYNC_CLASS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        syncGoogleClassroom: {
          ...state.syncGoogleClassroom,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }

    case actionTypes.CLASS_INFO_SYNC_CLASS_ERROR: {
      return {
        ...state,
        syncGoogleClassroom: {
          ...state.syncGoogleClassroom,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_CLASS_SYNC_RESET: {
      return {
        ...state,
        syncGoogleClassroom: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_PENDING: {
      return {
        ...state,
        updatePasswords: {
          ...state.updatePasswords,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }
    case actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_SUCCESS: {
      return {
        ...state,
        updatePasswords: {
          ...state.updatePasswords,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_ERROR: {
      return {
        ...state,
        updatePasswords: {
          ...state.updatePasswords,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_RESET: {
      return {
        ...state,
        updatePasswords: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    case actionTypes.CLASS_INFO_REMOVE_CLASS_PENDING: {
      return {
        ...state,
        removeClass: {
          ...state.removeClass,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }
    case actionTypes.CLASS_INFO_REMOVE_CLASS_SUCCESS: {
      return {
        ...state,
        removeClass: {
          ...state.removeClass,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }
    case actionTypes.CLASS_INFO_REMOVE_CLASS_ERROR: {
      return {
        ...state,
        removeClass: {
          ...state.removeClass,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }
    case actionTypes.CLASS_INFO_REMOVE_CLASS_RESET: {
      return {
        ...state,
        removeClass: {
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }
    case actionTypes.CLASS_INFO_RESET_CLASS_PENDING: {
      return {
        ...state,
        resetClass: {
          ...state.resetClass,
          ...DATA_LOADING_STATE_PENDING,
        },
      };
    }

    case actionTypes.CLASS_INFO_RESET_CLASS_SUCCESS: {
      return {
        ...state,
        resetClass: {
          ...state.resetClass,
          ...DATA_LOADING_STATE_SUCCESS,
        },
      };
    }

    case actionTypes.CLASS_INFO_RESET_CLASS_ERROR: {
      return {
        ...state,
        resetClass: {
          ...state.resetClass,
          ...DATA_LOADING_STATE_ERROR,
        },
      };
    }

    case actionTypes.CLASS_INFO_RESET_CLASS_RESET: {
      return {
        ...state,
        resetClass: {
          ...state.resetClass,
          ...DATA_LOADING_STATE_INIT,
        },
      };
    }

    default:
      return state;
  }
};

export default classInfoReducer;
