interface AllowedParamTypes {
  [key: string]: string[];
}

interface DataTestIDTypes {
  [key: string]: string;
}

type Params = "docType" | "gmailType" | "folderType" | "edublogsType";
type TabNameTypes = Record<Params, string>;

export type ClassDataSourceTypes =
  | "GoogleClassroom"
  | "SelfRostering"
  | "Dataloaded";
export interface URLParamTypes {
  classId: string;
  docType?: string;
  folderType?: string;
  gmailType?: string;
  edublogsType?: string;
}

export type DrivePageParamTypes =
  | ["docType", string]
  | ["gmailType", string]
  | ["folderType", string]
  | ["edublogsType", string]
  | null;

export const DATA_TEST_ID_PREFIX: DataTestIDTypes = {
  docType: "db-SharingPage",
  gmailType: "db-GmailPage",
  folderType: "db-DrivePage",
  edublogsType: "db-EdublogsPage",
};

export const ACTIVE_TAB_NAMES: TabNameTypes = {
  docType: "Sharing",
  gmailType: "Gmail",
  folderType: "Dashboard",
  edublogsType: "Edublogs",
};
