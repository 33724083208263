import React from "react";
import { CSSProperties } from "react";
import classnames from "classnames";
import styles from "./Paragraph.module.scss";

export type ParagraphSizes = "xs" | "sm" | "md" | "lg";

type ParagraphProps = {
  size?: ParagraphSizes;
  style?: CSSProperties;
  children: React.ReactNode;
  className?: string;
  dataTestIdPrefix?: string;
};

export const Paragraph = ({
  size = "md",
  style,
  children,
  className,
  dataTestIdPrefix,
}: ParagraphProps) => {
  const dataTestId = dataTestIdPrefix
    ? `${dataTestIdPrefix}-Paragraph`
    : undefined;

  return (
    <p
      style={style}
      data-test-id={dataTestId}
      className={classnames(className, styles.base, styles[size])}
    >
      {children}
    </p>
  );
};
