import React from "react";
import styles from "./HintText.module.scss";
import classnames from "classnames";

interface HintTextTextProps {
  hasError?: boolean;
  errorMessage?: React.ReactNode;
  message?: React.ReactNode;
  dataTestId?: string;
  id?: string;
}

export const HintText = ({
  id,
  message,
  errorMessage,
  hasError,
  dataTestId,
}: HintTextTextProps) => {
  return (hasError && errorMessage) || message ? (
    <div
      id={id}
      data-test-id={dataTestId}
      className={classnames(styles.hintText, hasError && styles.hintTextError)}
    >
      {hasError && errorMessage ? errorMessage : message}
    </div>
  ) : null;
};
