import React from "react";
import styles from "./GuidedBrowsingSchedule.module.scss";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { useIntl, FormattedMessage } from "react-intl";
import { DatePickerField } from "@hapara/ui/src/atomic/DatePickerField/DatePickerField";
import { TimePickerField } from "@hapara/ui/src/atomic/TimePickerField/TimePickerField";
import { Alert } from "@hapara/ui/src/atomic/Alert";
import { timeInputToISOString } from "@hapara/ui/src/atomic/TimePickerField/timeInputToISOString";
import { Checkbox } from "@hapara/ui/src/atomic/Checkbox";
import { TextField } from "@hapara/ui/src/atomic/TextField";
import { Heading } from "@hapara/ui/src/atomic/Heading";
import { ClockIcon } from "@hapara/ui/src/icons/ClockIcon";
import { getCurrentDate } from "@hapara/ui/src/utils/getCurrentDate";
import classNames from "classnames";

type schedulingRangeType = {
  startOfMonitoring: string;
  endOfMonitoring: string;
  sundayMonitoring: boolean;
  saturdayMonitoring: boolean;
};

interface GuidedBrowsingScheduleProps {
  hasInvalidDate?: boolean;
  hasInvalidTime?: boolean;
  hasInvalidEndDate?: boolean;
  time: string;
  date: string;
  duration?: number | null;
  schedulingRange: schedulingRangeType;
  hasRecurringSessionFeatureEnabled?: boolean;
  hasInvalidScheduleName?: boolean;
  isRepeatedSchedule?: boolean;
  selectedDays?: string[];
  scheduleName?: string;
  repeatEndDate?: string;
  sessionEndTimeValue?: string;
  scheduleState: {
    isRepeatedSchedule: boolean;
    selectedDays: string[];
    scheduleName: string;
    sessionEndTime: string;
    scheduleEndDate: string;
  };
  onDaySelect?: (day: string) => void;
  onDateChange: (date: string) => void;
  onTimeChange: (time: string) => void;
  onScheduleNameChange?: (name: string) => void;
  onRepeatEndDateChange?: (date: string) => void;
  onToggleRepeatSchedule?: () => void;
}

type GuidedBrowsingSchedulePresentationProps = Omit<
  GuidedBrowsingScheduleProps,
  "scheduleState"
>;

export const GuidedBrowsingSchedulePresentation = ({
  hasInvalidDate,
  hasInvalidTime,
  hasInvalidEndDate,
  time,
  date,
  duration,
  sessionEndTimeValue,
  schedulingRange,
  hasRecurringSessionFeatureEnabled,
  hasInvalidScheduleName,
  selectedDays,
  isRepeatedSchedule,
  scheduleName,
  repeatEndDate,
  onDaySelect,
  onDateChange,
  onTimeChange,
  onScheduleNameChange,
  onRepeatEndDateChange,
  onToggleRepeatSchedule,
}: GuidedBrowsingSchedulePresentationProps) => {
  const intl = useIntl();

  const monitoringWindowMessage = intl.formatMessage(
    {
      id: "6SqfRO",
      defaultMessage:
        "Sessions can be scheduled {firstDayOfWeek, select, Sunday {Sunday} other {Monday}} to {lastDayOfWeek, select, Saturday {Saturday} other {Friday}}, {startTime} to {endTime}",
      description:
        'Example in German: "Sitzungen können von {firstDayOfWeek, select, Sunday {Sonntag} other {Montag}} bis {lastDayOfWeek, select, Saturday {Samstag} other {Freitag}}, {startTime} bis {endTime} Uhr geplant werden."',
    },
    {
      startTime: intl.formatTime(
        timeInputToISOString(schedulingRange.startOfMonitoring)
      ),
      endTime: intl.formatTime(
        timeInputToISOString(schedulingRange.endOfMonitoring)
      ),
      firstDayOfWeek: schedulingRange.sundayMonitoring ? "Sunday" : "Monday",
      lastDayOfWeek: schedulingRange.saturdayMonitoring ? "Saturday" : "Friday",
    }
  );

  const shorthandDays = [
    {
      label: intl.formatMessage({
        defaultMessage: "Mon",
        id: "WjiSwW",
        description: "Abbreviation for Monday",
      }),
      value: "monday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Tue",
        id: "9kEfhI",
        description: "Abbreviation for Tuesday",
      }),
      value: "tuesday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Wed",
        id: "l1OmOA",
        description: "Abbreviation for Wednesday",
      }),
      value: "wednesday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Thu",
        id: "q74Op0",
        description: "Abbreviation for Thursday",
      }),
      value: "thursday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Fri",
        id: "GRm3fv",
        description: "Abbreviation for Friday",
      }),
      value: "friday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Sat",
        id: "PKx32h",
        description: "Abbreviation for Saturday",
      }),
      value: "saturday",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Sun",
        id: "gXayT9",
        description: "Abbreviation for Sunday",
      }),
      value: "sunday",
    },
  ];

  const monitoringWindowDays = shorthandDays.filter((day) => {
    const monitoringMap = {
      Saturday: schedulingRange.saturdayMonitoring,
      Sunday: schedulingRange.sundayMonitoring,
    };
    return !(day.value in monitoringMap) || monitoringMap[day.value];
  });

  const dateToday = getCurrentDate();

  return (
    <GuidedBrowsingSection>
      <div className={styles.container}>
        <TextField
          value={scheduleName || ""}
          className={styles.inputField}
          hasError={hasInvalidScheduleName}
          isRequired
          label={intl.formatMessage({
            id: "pZluLx",
            defaultMessage: "Schedule name",
          })}
          errorMessage={intl.formatMessage({
            id: "kAF9fF",
            defaultMessage: "Schedule name already exists",
          })}
          dataTestId="guided-browsing-schedule-name-input"
          onChangeText={(e) => onScheduleNameChange?.(e)}
        />

        <DatePickerField
          value={date}
          className={styles.inputField}
          isRequired
          label={intl.formatMessage({
            defaultMessage: "Start date",
            id: "n5QvJy",
          })}
          errorMessage={
            hasInvalidDate
              ? intl.formatMessage({
                  defaultMessage: "Outside school monitoring days",
                  id: "sF0qOk",
                })
              : ""
          }
          onSelectDate={onDateChange}
          minDate={dateToday}
        />
      </div>
      <div className={styles.container}>
        <TimePickerField
          value={time}
          className={styles.inputField}
          isRequired
          label={intl.formatMessage({
            defaultMessage: "Start time",
            id: "/zFP1/",
          })}
          hintText={intl.formatMessage({
            id: "0aXmFl",
            defaultMessage: "Selected time should be within school hours",
          })}
          errorMessage={
            hasInvalidTime
              ? intl.formatMessage({
                  defaultMessage: "Outside school monitoring hours",
                  id: "n5xIlF",
                })
              : ""
          }
          onSelectTime={onTimeChange}
        />

        <TextField
          value={sessionEndTimeValue ?? ""}
          className={classNames(styles.durationField, styles.inputField)}
          isDisabled
          label={intl.formatMessage({
            defaultMessage: "End time",
            id: "yc/tuy",
          })}
          hintText={intl.formatMessage(
            {
              defaultMessage: "Duration: {duration}mins",
              id: "iNxDUz",
              description: "Session duration in minutes",
            },
            { duration }
          )}
          leftAddon={
            <div className={styles.iconWrapper}>
              <ClockIcon />
            </div>
          }
          dataTestId="end-time"
          onChangeText={() => {}}
        />
      </div>
      {hasRecurringSessionFeatureEnabled && (
        <div
          className={classNames(styles.repeatedCheckbox, {
            [styles.selected]: isRepeatedSchedule,
          })}
        >
          <Checkbox
            checked={isRepeatedSchedule}
            label={intl.formatMessage({
              defaultMessage: "Repeat this session",
              id: "fSkSls",
            })}
            dataTestIdPrefix="guided-browsing-schedule-repeat-checkbox"
            onChange={onToggleRepeatSchedule}
          />
        </div>
      )}
      {isRepeatedSchedule && hasRecurringSessionFeatureEnabled && (
        <div className={styles.daySelection}>
          <Heading level={2} styledAs="h5" removeTopMargin>
            <FormattedMessage
              defaultMessage="Repeat every"
              id="xbj4gR"
              description="Label for a group of checkboxes to select which days of the week an event should repeat on"
            />
          </Heading>
          <div className={styles.dayContainer}>
            {monitoringWindowDays.map((day) => (
              <div className={styles.checkboxContainer} key={day.value}>
                <Checkbox
                  value={day.value}
                  className={styles.checkbox}
                  checked={selectedDays?.includes(day.value)}
                  label={day.label}
                  dataTestId={`guided-browsing-schedule-${day.value}-checkbox`}
                  onChange={() => onDaySelect?.(day.value)}
                  dataTestIdPrefix={`guided-browsing-schedule-${day.label}`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {isRepeatedSchedule && repeatEndDate && onRepeatEndDateChange && (
        <DatePickerField
          value={repeatEndDate}
          className={styles.endDateField}
          isRequired
          minDate={date}
          label={intl.formatMessage({
            defaultMessage: "Repeat ends",
            id: "hNrf/V",
            description:
              "Label for the date picker to select the end date for a recurring event",
          })}
          errorMessage={
            hasInvalidEndDate
              ? intl.formatMessage({
                  defaultMessage: "Cannot select more than 4 weeks",
                  id: "yIAfB9",
                })
              : ""
          }
          onSelectDate={onRepeatEndDateChange}
        />
      )}
      <Alert type="info">{monitoringWindowMessage}</Alert>
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingSchedule = ({
  time,
  date,
  hasInvalidTime,
  hasInvalidDate,
  hasInvalidEndDate,
  hasInvalidScheduleName,
  duration,
  scheduleState,
  schedulingRange,
  hasRecurringSessionFeatureEnabled,
  onDateChange,
  onTimeChange,
  onDaySelect,
  onToggleRepeatSchedule,
  onRepeatEndDateChange,
  onScheduleNameChange,
}: GuidedBrowsingScheduleProps) => {
  return (
    <GuidedBrowsingSchedulePresentation
      hasInvalidDate={hasInvalidDate}
      hasInvalidTime={hasInvalidTime}
      hasInvalidEndDate={hasInvalidEndDate}
      onDateChange={onDateChange}
      onTimeChange={onTimeChange}
      time={time}
      date={date}
      duration={duration}
      sessionEndTimeValue={scheduleState.sessionEndTime}
      schedulingRange={schedulingRange}
      hasRecurringSessionFeatureEnabled={hasRecurringSessionFeatureEnabled}
      hasInvalidScheduleName={hasInvalidScheduleName}
      isRepeatedSchedule={scheduleState.isRepeatedSchedule}
      onToggleRepeatSchedule={onToggleRepeatSchedule}
      selectedDays={scheduleState.selectedDays}
      onDaySelect={onDaySelect}
      scheduleName={scheduleState.scheduleName}
      onScheduleNameChange={onScheduleNameChange}
      repeatEndDate={scheduleState.scheduleEndDate}
      onRepeatEndDateChange={onRepeatEndDateChange}
    />
  );
};
