import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./ToolbarGroups.module.scss";

import {
  USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE,
  USER_GROUP_PREFS_TYPE,
} from "../../../../state/shared/userGroups/types";
import Tippy from "@tippy.js/react";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import GroupsFilter from "@hapara/ui/src/components/groupsFilter/groupsFilter";
import useGroups from "../../../../hooks/useGroups";
import { useIntl } from "react-intl";

const ToolbarGroups = ({
  dataTestIdPrefix,
  pageType,
  tabName,
  hasNavBarButtonUpdateFlag,
}) => {
  const intl = useIntl();
  const triggerButtonRef = useRef(null);
  const rootRef = useRef(null);
  const { data, operations } = useGroups({ pageType, selectedView: tabName });
  const { userGroups, userGroupsViewType, isGroupFilterOpened } = data;
  const {
    setIsGroupFilterOpened,
    groupSelected,
    selectAllGroups,
    editGroupsPanel,
  } = operations;

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // Inside click
      if (rootRef.current?.contains(e.target)) {
        return;
      }
      // Outside click
      setIsGroupFilterOpened(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setIsGroupFilterOpened]);

  const setButtonFocus = () => {
    _.delay(() => triggerButtonRef.current.focus(), 100);
  };

  return (
    <div className={styles.root} ref={rootRef}>
      <Tippy
        content={intl.formatMessage({
          defaultMessage: "Groups",
          id: "hzmswI",
        })}
        theme="hsuite"
      >
        <Button
          label={
            hasNavBarButtonUpdateFlag
              ? null
              : intl.formatMessage({ defaultMessage: "Groups", id: "hzmswI" })
          }
          icon="profile-group"
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.XSMALL}
          rightIcon={hasNavBarButtonUpdateFlag ? null : "arrow-carvet-down"}
          onClick={() => setIsGroupFilterOpened(!isGroupFilterOpened)}
          dataTestId={`${dataTestIdPrefix}-Toolbar-GroupsFilter-Button`}
          aria-label={intl.formatMessage({
            defaultMessage: "Create, manage and filter groups",
            id: "gnakYd",
          })}
          ref={triggerButtonRef}
          aria-haspopup={true}
          aria-expanded={isGroupFilterOpened}
        />
      </Tippy>
      {isGroupFilterOpened && (
        <GroupsFilter
          groups={userGroups}
          groupSelected={groupSelected}
          setall={selectAllGroups}
          editGroups={() => editGroupsPanel()}
          newGroup={() =>
            editGroupsPanel(USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE.DETAILS)
          }
          openPreferences={() =>
            editGroupsPanel(
              USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE.PREFERENCES
            )
          }
          isSharedGroups={
            userGroupsViewType === USER_GROUP_PREFS_TYPE.SHARED_ACROSS_TABS
          }
          dataTestIdCore="hs"
          setParentButtonFocus={setButtonFocus}
        />
      )}
    </div>
  );
};

ToolbarGroups.propTypes = {
  dataTestIdPrefix: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  tabName: PropTypes.string,
  hasNavBarButtonUpdateFlag: PropTypes.bool,
};

export default ToolbarGroups;
