import React from "react";
import PropTypes from "prop-types";
import styles from "./DiscoverLanding.module.scss";
import { connect } from "react-redux";
import CategoryFiltersToggleButton from "../../Library/CategoryFiltersToggleButton/CategoryFiltersToggleButton";
import DiscoverBrowseContent from "../DiscoverBrowseContent/DiscoverBrowseContent";
import { getLeftPanelIsOpen } from "../../../../state/shared/selectors";
import { toggleLeftSidePanel } from "../../../../state/shared/actions";
import { MAIN_CONTENT_ID } from "../../../../consts";
import DiscoverExploreSubjects from "../DiscoverExploreSubjects/DiscoverExploreSubjects";
import DiscoverFindStandards from "../DiscoverFindStandards/DiscoverFindStandards";
import DiscoverExploreByType from "../DiscoverExploreByType/DiscoverExploreByType";
import { hasResourcesAccess } from "../../../../state/user/selectors";

export const DiscoverLanding = ({
  toggleLeftPanel,
  isLeftPanelOpen,
  hasResourcesAccess,
}) => {
  const dataTestIdPrefix = "lb-Discover-Landing";

  return (
    <div className={styles.root}>
      <main className={styles.contentMain} id={MAIN_CONTENT_ID} tabIndex="-1">
        <div className={styles.headerContent}>
          <div className={styles.separator} />
          <div className={styles.filters}>
            <CategoryFiltersToggleButton
              toggleLeftPanel={toggleLeftPanel}
              isLeftPanelOpen={isLeftPanelOpen}
              dataTestIdPrefix={dataTestIdPrefix}
              numberFiltersSelected={0}
            />
          </div>
        </div>
        <DiscoverBrowseContent />
        <DiscoverExploreSubjects />
        {hasResourcesAccess && <DiscoverExploreByType />}
        <DiscoverFindStandards />
      </main>
    </div>
  );
};

DiscoverLanding.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  hasResourcesAccess: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isLeftPanelOpen: getLeftPanelIsOpen(state),
    hasResourcesAccess: hasResourcesAccess(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLeftSidePanel(isOpen)),
  })
)(DiscoverLanding);
