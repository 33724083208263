import PropTypes from "prop-types";

export const USER_GROUP_TYPE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  URN: PropTypes.string.isRequired,
  color: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.string),
  checked: PropTypes.bool.isRequired,
});

export const USER_GROUP_PREFS_TYPE = {
  SHARED_ACROSS_TABS: "SHARED_ACROSS_TABS",
  SPECIFIC_TO_TAB: "SPECIFIC_TO_TAB",
};

export const USER_GROUP_MANAGEMENT_PANEL_CONTENT_TYPE = {
  DETAILS: "details",
  PREFERENCES: "preferences",
};
