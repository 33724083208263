import React, { useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./ToolbarTilesSize.module.scss";
import Tippy from "@tippy.js/react";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { STUDENT_TILES_SIZE_TYPES } from "../../../../state/highlights/config/types";
import { getStudentTilesSizeType } from "../../../../state/highlights/config/selectors";
import { updateTilesSizeType } from "../../../../state/highlights/config/actions";
import { getClassId } from "../../../../state/shared/selectors";
import { ScalingIcon } from "@hapara/ui/src/icons/ScalingIcon";
import { useIntl } from "react-intl";

const ToolbarTilesSize = ({
  studentTilesSizeType,
  selectedClassId,
  updateTilesSizeType,
  dataTestIdPrefix,
  hasNavBarButtonUpdateFlag,
}) => {
  const triggerButtonRef = useRef();
  const intl = useIntl();
  const STUDENT_TILES_SIZE_DROPDOWN_ITEMS = [
    {
      type: STUDENT_TILES_SIZE_TYPES.REGULAR,
      label: intl.formatMessage({
        defaultMessage: "Default",
        id: "lKv8ex",
      }),
    },
    {
      type: STUDENT_TILES_SIZE_TYPES.COMPACT,
      label: intl.formatMessage({
        defaultMessage: "Compact",
        id: "kCZBrb",
      }),
    },
  ];

  return (
    <div className={styles.root}>
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={(props) => (
          <Tippy
            content={intl.formatMessage({
              defaultMessage: "Tile size",
              id: "dQ+pjj",
            })}
            theme="hsuite"
            maxWidth={162}
          >
            <Button
              label={
                hasNavBarButtonUpdateFlag
                  ? null
                  : intl.formatMessage({
                      defaultMessage: "Size",
                      id: "agOXPD",
                    })
              }
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              rightIcon={hasNavBarButtonUpdateFlag ? null : "arrow-carvet-down"}
              iconComponent={hasNavBarButtonUpdateFlag ? <ScalingIcon /> : null}
              dataTestId={`${dataTestIdPrefix}-Toolbar-TilesSizeDropdown-TriggerButton`}
              aria-label={intl.formatMessage({
                defaultMessage: "Adjust student tile size",
                id: "T3dMn4",
              })}
              ref={triggerButtonRef}
              {...props}
            />
          </Tippy>
        )}
        itemComponentList={_.map(
          STUDENT_TILES_SIZE_DROPDOWN_ITEMS,
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.type === studentTilesSizeType;
              return (
                <button
                  type="button"
                  className={classnames(className, styles.dropdownContentItem, {
                    [styles.dropdownContentItemSelected]: isSelected,
                  })}
                  data-test-id={`${dataTestIdPrefix}-Toolbar-TilesSizeDropdown-Option-${_.toLower(
                    item.type
                  )}`}
                  onClick={() => {
                    updateTilesSizeType({
                      tileSize: item.type,
                      classId: selectedClassId,
                    });
                    onClick();
                    _.delay(() => triggerButtonRef.current.focus(), 100);
                  }}
                  key={item.type}
                  aria-label={
                    isSelected
                      ? intl.formatMessage(
                          {
                            defaultMessage: "{itemLabel}, selected",
                            id: "YAmCem",
                          },
                          {
                            itemLabel: item.label,
                          }
                        )
                      : item.label
                  }
                  {...rest}
                >
                  {item.label}
                </button>
              );
            }
        )}
      />
    </div>
  );
};

ToolbarTilesSize.propTypes = {
  studentTilesSizeType: PropTypes.oneOf(_.values(STUDENT_TILES_SIZE_TYPES))
    .isRequired,
  selectedClassId: PropTypes.string,
  updateTilesSizeType: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  hasNavBarButtonUpdateFlag: PropTypes.bool,
};

export default connect(
  (state) => ({
    studentTilesSizeType: getStudentTilesSizeType(state),
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({
    updateTilesSizeType: ({ tileSize, classId }) =>
      dispatch(updateTilesSizeType({ tileSize, classId })),
  })
)(ToolbarTilesSize);
