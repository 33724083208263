import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styles from "./SnapsTiles.module.scss";
import { getSnapshots } from "../../../../state/highlights/snaps/selectors";
import SnapTile from "../SnapTile/SnapTile";
import SnapModal from "../SnapModal/SnapModal";
import { SNAPSHOT_TYPE } from "../../../../state/highlights/snaps/types";
import {
  GRID_BREAKPOINTS,
  GRID_COLS,
  GRID_BREAKPOINT_TYPES,
} from "../../../../state/highlights/config/types";
import { pendoTrack } from "../../../../utils";
import moment from "moment";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GRID_ROW_HEIGHT_DEFAULT = 270;

const getLayoutFromStudentOrderByColumns = (list, amountOfCols) => {
  return list.map((s, index) => ({
    i: s.ID,
    x: index % amountOfCols,
    y: _.floor(index / amountOfCols),
    w: 1,
    h: 1,
  }));
};

const SnapsTiles = ({ snapshots }) => {
  const [modalSnap, setModalSnap] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // use memoized list of tiles for the grid
  // to reduce amount of renders and overall performance
  const listOfTiles = useMemo(() => {
    const showSnapModal = (snap) => {
      setModalSnap(snap);
      setIsModalOpen(true);

      const timeDiff = moment
        .duration(Date.now() - snap.SnapshotTimestamp)
        .asSeconds();

      pendoTrack("Snap - Open a snap", {
        classId: snap.ClassURN,
        snapTimestamp: new Date(snap.SnapshotTimestamp).toISOString(),
        secondsSinceSnapTaken: Math.round(timeDiff),
      });
    };

    return snapshots.map((sn) => (
      <div key={sn.ID}>
        <SnapTile snapshot={sn} showModal={showSnapModal} />
      </div>
    ));
  }, [snapshots]);

  // calculate layouts for the grid
  const layouts = useMemo(() => {
    const lts = {};
    _.forEach(GRID_BREAKPOINT_TYPES, (type) => {
      lts[type] = getLayoutFromStudentOrderByColumns(
        snapshots,
        GRID_COLS[type]
      );
    });
    return lts;
  }, [snapshots]);

  return (
    <>
      <div className={styles.root}>
        <ResponsiveGridLayout
          className={classnames("layout", styles.gridLayout)}
          breakpoints={GRID_BREAKPOINTS}
          layouts={layouts}
          cols={GRID_COLS}
          rowHeight={GRID_ROW_HEIGHT_DEFAULT}
          isResizable={false}
          isDraggable={true}
          isBounded={true}
          autoSize={true}
          verticalCompact={true}
          compactType="horizontal"
          draggableHandle={"." + styles.draggableHandle}
          margin={[16, 16]}
        >
          {listOfTiles}
        </ResponsiveGridLayout>
      </div>
      {!_.isEmpty(modalSnap) && isModalOpen && (
        <SnapModal
          isModalOpen={isModalOpen}
          snapshot={_.find(snapshots, { ID: modalSnap.ID })}
          onModalClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

SnapsTiles.propTypes = {
  snapshots: PropTypes.arrayOf(SNAPSHOT_TYPE),
};

export default connect((state) => ({
  snapshots: getSnapshots(state),
}))(SnapsTiles);
