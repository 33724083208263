import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import styles from "./PageRightPanel.module.scss";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../state/app/types";
import { getWindowSize } from "../../../state/app/selectors";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

export const PageRightPanel = ({
  onClose = () => {},
  isOpen,
  windowSize,
  children,
  focusableElementId,
  dataTestIdPrefix = "hs-RightPanel",
  landmarkLabel,
  closeButtonLabel = "Close",
}) => {
  const [panelTopRef, hasPanelReachedWindowTop] = useInView({ threshold: 0 });

  const closePanel = () => {
    onClose();
  };

  return (
    <>
      {isOpen && windowSize < WINDOW_SIZE_TYPES.L && (
        <div className={styles.overlay} onClick={closePanel} />
      )}

      <aside
        className={classnames(styles.root, {
          [styles.contentRightOpen]: isOpen,
        })}
        aria-label={landmarkLabel}
      >
        <div ref={panelTopRef} />
        <div
          className={classnames(styles.contentRightInner, {
            [styles.contentRightInnerSticky]: !hasPanelReachedWindowTop,
            [styles.contentRightInnerOpen]: isOpen,
          })}
          tabIndex="-1"
          id={focusableElementId}
        >
          {isOpen && (
            <>
              <div className={styles.contentRightTop}>
                <Button
                  icon="cross"
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  onClick={closePanel}
                  data-test-id={`${dataTestIdPrefix}-Button-Close`}
                  aria-label={closeButtonLabel}
                />
              </div>
              <div className={styles.contentRightMain}>{children}</div>
            </>
          )}
        </div>
      </aside>
    </>
  );
};

PageRightPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  focusableElementId: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  landmarkLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  windowSize: getWindowSize(state),
}))(PageRightPanel);
