import actionTypes from "../actionTypes";
import { UPREF_NAME_FIRST_LAST } from "./types";
import _ from "lodash";

const INIT_STATE = {
  isSignedIn: false,
  GISInfo: {},
  userMetadata: {},
  userPermissions: [],
  userPrefs: [],
  schoolsCanConfigure: [],
  isStudent: false,
  isSharingViewEnabled: true,
  hasAccess: true,
  isUnknownUser: false,
  emailFormatPreference: "",
};

const handleUserLoginStatus = (state, isSignedIn) => ({
  ...state,
  isSignedIn,
  isUnknownUser: false,
  GISInfo: isSignedIn ? { ...state.GISInfo } : {},
});

const handleUserDetailsUpdate = (state, { payload }) => ({
  ...state,
  GISInfo: {
    ...payload,
  },
});

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGGED_IN:
      return handleUserLoginStatus(state, true);

    case actionTypes.USER_LOGGED_OUT:
      return handleUserLoginStatus(state, false);

    case actionTypes.USER_UNAUTHORISED_ACCESS:
      return { ...state, hasAccess: false };

    case actionTypes.USER_DETAILS_UPDATED:
      return handleUserDetailsUpdate(state, action);

    case actionTypes.USER_PREFS_SAVE_ERROR:
      return state;

    case actionTypes.USER_UNKNOWN:
      return { ...state, isUnknownUser: true };

    case actionTypes.USER_PREFS_SAVE_SUCCESS: {
      const userPrefs = _.cloneDeep(_.get(state, "userPrefs", []));
      const nameFormatPrefs = _.find(userPrefs, { key: "NameFormat" });
      if (nameFormatPrefs) {
        nameFormatPrefs.value = action.payload;
      }

      return {
        ...state,
        userPrefs,
      };
    }

    case actionTypes.APP_USER_INIT_SUCCESS: {
      // make sure BE data in correct format as it could be null or missing
      const userInitPrefs = _.get(action.payload, "userPrefs", []) || [];
      const schoolsCanConfigure =
        _.get(action.payload, "schoolsCanConfigure", []) || [];

      const nameFormatPrefs = _.find(userInitPrefs, { key: "NameFormat" });
      if (!nameFormatPrefs) {
        _.set(action.payload, `userPrefs[${userInitPrefs.length}]`, {
          key: "NameFormat",
          value: UPREF_NAME_FIRST_LAST,
        });
      }

      return {
        ...state,
        userPrefs: userInitPrefs,
        schoolsCanConfigure: schoolsCanConfigure,
      };
    }

    case actionTypes.APP_USER_INIT_CONFIG_SUCCESS:
      const metadata = _.get(action, "payload.metadata", {}) || {};
      return {
        ...state,
        userPermissions: action.payload.permissions,
        isStudent: action.payload.isStudent,
        isSharingViewEnabled: action.payload.isSharingViewEnabled,
        userMetadata: {
          ...state.userMetadata,
          ...metadata,
          Features: _.get(metadata, "Features", {}),
          Roles: _.get(metadata, "Roles", {}),
        },
      };

    case actionTypes.USER_EMAIL_FORMAT_LOAD_SUCCESS:
      return {
        ...state,
        emailFormatPreference: action.payload,
      };

    case actionTypes.USER_EMAIL_FORMAT_LOAD_ERROR:
      return {
        ...state,
        emailFormatPreference: "",
      };

    default:
      return state;
  }
};

export default userReducer;
