import { useContext } from "react";
import { LocaleContext } from "./LocaleProvider";

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error("useLocale hook must be used within a LocaleProvider");
  }

  return context;
};
