import { useEffect, useState } from "react";
import _ from "lodash";

const FIVE_SECONDS = 5 * 1000;

const useUpdateHighlightsSessionsAfterEndTimeHook = (
  loadSessions,
  sessionList
) => {
  const [refreshCount, setRefreshCount] = useState(0);

  // every 5 seconds check if one of the session in the list is expired
  // and load the list of sessions
  useEffect(() => {
    const doTimeout = () =>
      _.delay(() => {
        if (sessionList.length > 0) {
          setRefreshCount(refreshCount + 1);
          const now = Date.now();
          const expiredSessions = _.filter(sessionList, (s) => s.End < now);
          if (expiredSessions.length > 0) {
            loadSessions();
          }
        }
      }, FIVE_SECONDS);

    const timeoutId = doTimeout();
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [refreshCount, setRefreshCount, loadSessions, sessionList]);

  return refreshCount;
};

export default useUpdateHighlightsSessionsAfterEndTimeHook;
