import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import styles from "./CardModal.module.scss";
import { history } from "../../../../../state/store";
import {
  Action,
  Artifact,
  ArtifactInput,
  ArtifactRole,
  ArtifactType,
  Card as CardType,
  CardInput,
  Group as GroupType,
  GroupFilter,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../../hooks/useIsDimension";
import { Reference, StoreObject, useMutation } from "@apollo/client";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";
import classnames from "classnames";
import {
  embedURLCompiler,
  getUrl,
  isContentVideo,
  screenshotTakenTimeHelper,
} from "../../../../../utils";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { Divider } from "@mui/material";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import InputModal, { inputModalTypes } from "./InputModal";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import GroupDropDown from "./GroupDropDown";
import LabeledDropButton from "./LabledDropButton";
import EvidenceTypeSelection from "./EvidenceTypeSelection/EvidenceTypeSelection";
import {
  Button,
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import UpdateCardMutation from "./UpdateCardMutation";

type CardModalProps = {
  card: CardType | null;
  boardId: string;
  dataTestIdPrefix: string;
  permissions: Action[];
  workspaceGroups: GroupType[];
  closeModalAction: () => void;
  handleSaveCardModalAction: () => void;
  mutationStatusCB: (status: string) => void; //Used to display toast on BoardPage.tsx
};

export const CardModal = ({
  card,
  boardId,
  dataTestIdPrefix,
  closeModalAction,
  permissions,
  handleSaveCardModalAction,
  workspaceGroups,
  mutationStatusCB,
}: CardModalProps) => {
  const locationState: any = history.location.state;

  //Card can be null/undefined when its a new card
  if (card == null) {
    card = getDefaultCard(workspaceGroups, locationState);
  }

  const [titleValue, setTitleValue] = useState(card.Title);
  const [descrValue, setDescrValue] = useState(card.Description);
  const [imgLink, setImgLink] = useState(card.Image);
  const [imgAltText] = useState(card.ImageAltText);
  const { isDimensionOnly: isSmallScreen } = useIsDimension(
    WINDOW_SIZE_TYPES.S
  );
  const [inputModalOpen, setInputModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteCardId, setDeleteCardId] = useState<string | null>(null);
  const [addLinkPayload, setAddLinkPayload] = useState({});
  const [cardArtifacts, setCardArtifacts] = useState(card.Artifacts);
  //if no groups force to all groups
  const cardGroups: Array<string> =
    card!.GroupsFilter && card!.GroupsFilter.length > 0
      ? card!.GroupsFilter.map((i) => i.ID!)
      : workspaceGroups.map((i) => i.ID!);
  const [groupFilter, setGroupfilter] = useState(cardGroups);

  const isEditable = permissions.includes(Action.CanEdit);
  const isEvidence = card.ColumnType === "EVIDENCE";
  const hasEvidence = isEvidence && cardArtifacts.length === 1; //Used to hide attachment controls when an attachment has been added

  const deletedArtifacts = (): string[] => {
    //Looking for deleted cards
    const deletedCards = card?.Artifacts?.filter((a) => {
      return !cardArtifacts.find((b) => b.ID === a.ID);
    }).map((a) => a.ID) as string[];
    return deletedCards ? deletedCards : [];
  };

  //const [updateCard, { loading, error }] = useMutation(UpdateCardDocument, {
  const [updateCard, { loading, error }] = useMutation(
    UpdateCardMutation(deletedArtifacts()),
    {
      update: (cache, { data }) => {
        if (data) {
          //Add the card list of cards in the workspace cache if it is a new card
          cache.modify({
            id: boardId, //Which cache item(workspace) to update
            fields: {
              Cards(existingCards, { readField }) {
                //update the Cards field in the cached workspace board
                if (
                  existingCards.some(
                    (ref: Reference | StoreObject | undefined) =>
                      readField("ID", ref) === data?.updateCard?.ID
                  )
                ) {
                  //Check if the card already exsits in the cache
                  //and do nothing if it does
                  //The card update in the cache is handled by the actual mutation
                  return existingCards;
                }
                //If the card does not exist in the cache, add the reference to the Cards list in the workspace cache item
                //The card cache will be created by the actual mutation
                return [...existingCards, { __ref: data?.updateCard?.ID }];
              },
            },
          });
        }
        if (data.deleteArtifactsFromCard && card?.ID) {
          cache.modify({
            id: card.ID,
            fields: {
              Artifacts(existingArtifacts, { readField }) {
                //TOOD make the filter type an artifact
                const updatedArtifacts = existingArtifacts.filter(
                  (a: { ID: any }) => {
                    return !data.deleteArtifactsFromCard.includes(a.ID);
                  }
                );
                return updatedArtifacts ? updatedArtifacts : [];
              },
            },
          });
        }
      },
    }
  );

  //ARTIFACTS: Adds a new link (added by from input modal) to the artifact list
  useEffect(() => {
    if (!_.isEmpty(addLinkPayload)) {
      setCardArtifacts((cardArtifacts) => [...cardArtifacts, addLinkPayload]);
      setAddLinkPayload({});
    }
  }, [addLinkPayload]);

  useEffect(() => {
    if (deleteCardId !== null) {
      setDeleteDialogOpen(true);
    } else {
      setDeleteDialogOpen(false);
    }
  }, [deleteCardId]);

  //ARTIFACTS: Removes a link from the artifact list
  const removeArtifact = (id: string) => {
    const updatedArtifactList = cardArtifacts.filter((a) => a.ID !== id);
    setCardArtifacts(updatedArtifactList);
  };

  //Toogle to indicate user has altered the content
  const hasContentChanged = () => {
    return (
      !_.isEqual(card?.Title, titleValue) ||
      !_.isEqual(card?.Description, descrValue) ||
      !_.isEqual(card?.Image, imgLink) ||
      !_.isEqual(card?.Artifacts, cardArtifacts) ||
      !_.isEqual(cardGroups.sort(), groupFilter.sort())
    );
  };

  const hasMinimumRequiredInfo = () => {
    return groupFilter.length > 0;
  };

  const canSave = () => {
    return hasContentChanged() && hasMinimumRequiredInfo();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = _.get(e, "target.value", "");
    setTitleValue(newValue);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = _.get(e, "target.value", "");
    setDescrValue(newValue);
  };

  const handleGroupChange = (newGroupFilter: string[]) => {
    setGroupfilter(newGroupFilter);
  };

  const applyUpdates = (): CardInput => {
    const updates: CardInput = {};
    if (!_.isEqual(card?.Title, titleValue)) {
      updates.Title = titleValue;
    }

    //add to updates if description has changed
    if (!_.isEqual(card?.Description, descrValue)) {
      updates.Description = descrValue;
    }

    //add to updates if image has changed
    if (!_.isEqual(card?.Image, imgLink)) {
      updates.ImageUrl = imgLink;
    }

    //add to updates if new artifact has been added
    if (!_.isEqual(card?.Artifacts, cardArtifacts)) {
      //Looking for new cards
      cardArtifacts.forEach((a) => {
        if (!a.ID) {
          if (!updates.NewArtifacts) {
            updates.NewArtifacts = [];
          }
          const artifactInput: ArtifactInput = {
            URI: a.URI,
            Title: a.Title,
            Role: a.Role ? a.Role : ArtifactRole.TeacherArtifact,
            Type: a.Type === "GDrive" ? ArtifactType.GDrive : ArtifactType.Url,
          };
          updates.NewArtifacts.push(artifactInput);
        }
      });
    }

    if (!_.isEqual(card?.GroupsFilter, groupFilter)) {
      updates.GroupsFilter = groupFilter;
    }

    //this is a new card so set the postiion.
    if (!card?.ID) {
      updates.Position = {};
      updates.Position.SectionID = locationState?.sectionId;
      updates.Position.BeforeCard = locationState?.beforeCardId;
      updates.Position.AfterCard = locationState?.afterCardId;
      updates.Position.ColumnType = locationState?.columnType;
    }

    return updates;
  };

  const processUpdates = async () => {
    const result = await updateCard({
      variables: {
        id: card?.ID,
        input: applyUpdates(),
        boardId: boardId,
        artifactIds: deletedArtifacts(),
      },
    });
    if (result) {
      handleSaveCardModalAction();
    }
  };

  const getDate = (dateType: string) => {
    const DATETIME_FORMAT = "ddd, D MMM YYYY";
    const dateToConvert =
      dateType === "start" ? card?.StartDate : card?.DueDate;

    if (dateToConvert) {
      return screenshotTakenTimeHelper(dateToConvert, DATETIME_FORMAT);
    } else {
      return `No ${dateType} date`;
    }
  };

  const currentCardID = !card?.ID ? null : card?.ID;

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={closeModalAction}
      classNameContent={styles.modal}
      className={styles.modalContent}
      dataTestPrefix={`${dataTestIdPrefix}-ExpandedCard`}
      contentLabel="Card details"
      hasEmbeddedHeader={false}
    >
      <div className={styles.modalTitle}>
        <Button
          icon="cross"
          onClick={closeModalAction}
          type={BUTTON_TYPES.TERTIARY}
          aria-label="Close"
          data-test-id={`${dataTestIdPrefix}-ExpandedCard-Close`}
          className={styles.modalTitleClose}
        />
      </div>
      <div
        className={classnames(styles.root, {
          [styles.noFooter]: !isEditable,
          [styles.hasError]: error,
        })}
      >
        {/* LEFT CONTENT */}
        <div
          className={classnames(styles.leftContent, {
            [styles.scrollFooter]: !isEditable,
          })}
        >
          {imgLink && (
            <>
              <img
                className={styles.image}
                src={imgLink}
                alt={imgAltText ? imgAltText : "Decorative Image"}
              />
              {isEditable && (
                <div className={styles.editImageBtnGroup}>
                  <Button
                    type={BUTTON_TYPES.OUTLINED}
                    outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                    size={BUTTON_SIZES.SMALL}
                    data-test-id={`${dataTestIdPrefix}-ExpandedCard-Edit-Image`}
                    onClick={() => {}}
                    icon="pencil"
                    aria-label="Edit card cover image"
                  />
                  <Button
                    type={BUTTON_TYPES.OUTLINED}
                    outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                    size={BUTTON_SIZES.SMALL}
                    data-test-id={`${dataTestIdPrefix}-ExpandedCard-Delete-Image`}
                    onClick={() => {
                      setImgLink("");
                    }}
                    icon="delete"
                    aria-label="Delete card cover image"
                    isLoading={loading}
                  />
                </div>
              )}
            </>
          )}
          {!imgLink && isEditable && (
            <Button
              type={BUTTON_TYPES.SECONDARY}
              size={BUTTON_SIZES.SMALL}
              data-test-id={`${dataTestIdPrefix}-ExpandedCard-Add-Image`}
              onClick={() => {}}
              label="Add cover image"
            />
          )}
          <div className={styles.input}>
            {isEditable ? (
              <>
                <Textarea
                  rows={1}
                  placeholder="Title"
                  data-test-id={`${dataTestIdPrefix}-ExpandedCard-Title`}
                  value={titleValue}
                  aria-label="Title"
                  onChange={handleTitleChange}
                  isAutoExpandable={true}
                  className={classnames(styles.title, {
                    [styles.hasTitle]: titleValue !== "",
                  })}
                  themeType={THEME_TYPES.LIGHT}
                />
                {instructionLabel()}
                <Textarea
                  rows={12}
                  placeholder="Description"
                  data-test-id={`${dataTestIdPrefix}-ExpandedCard-Description`}
                  value={descrValue}
                  aria-label="Description"
                  onChange={handleDescriptionChange}
                  isAutoExpandable={true}
                  className={classnames(styles.description, {
                    [styles.hasDescription]: descrValue !== "",
                  })}
                  themeType={THEME_TYPES.LIGHT}
                />
              </>
            ) : (
              <>
                <div
                  className={classnames(styles.title, styles.hasTitle)}
                  data-test-id={dataTestIdPrefix + "-Expanded-Card-Title"}
                >
                  {titleValue}
                </div>
                {descrValue.length > 0 && instructionLabel()}
                <div
                  className={classnames(
                    styles.description,
                    styles.hasDescription
                  )}
                  data-test-id={dataTestIdPrefix + "-Expanded-Card-Description"}
                >
                  {descrValue}
                </div>
              </>
            )}
          </div>
        </div>
        {(isEditable ||
          (card?.Artifacts != null && card?.Artifacts.length > 0)) && (
          <>
            <div className={styles.contentSpacer}></div>
            {/* RIGHT CONTENT */}
            <div
              className={classnames(styles.rightContent, {
                [styles.scrollFooter]: !isEditable,
              })}
            >
              {isEditable && (
                <>
                  <h2>Details</h2>
                  <div
                    className={classnames(styles.buttonContainer, {
                      [styles.isEvidence]: isEvidence,
                    })}
                  >
                    <GroupDropDown
                      workspaceGroups={workspaceGroups}
                      cardGroups={cardGroups}
                      dataTestIdPrefix={dataTestIdPrefix}
                      onSelectedGroupsChange={handleGroupChange}
                    />
                    {isEvidence && (
                      <>
                        <LabeledDropButton
                          buttonLabel="Start Date:"
                          buttonContent={`${getDate("start")}`}
                          completeDataTestID={`${dataTestIdPrefix}-CardModal-AddStartDate`}
                          customStyleName="defaultStyleSet"
                        />
                        <LabeledDropButton
                          buttonLabel="Due Date:"
                          buttonContent={`${getDate("due")}`}
                          completeDataTestID={`${dataTestIdPrefix}-CardModal-AddDueDate`}
                          customStyleName="defaultStyleSet"
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              <div className={styles.attachmentsContainer}>
                <h2>{isEvidence ? "Evidence" : "Attachments"}</h2>
                {isEditable &&
                  !hasEvidence &&
                  `Select files, or drag and drop onto this card to upload`}
                {attachmentList(
                  dataTestIdPrefix,
                  cardArtifacts,
                  isEditable,
                  isEvidence,
                  removeArtifact
                )}
                {isEditable && (
                  <div
                    className={classnames(styles.attachmentControls, {
                      [styles.isEvidence]: isEvidence,
                      [styles.hasEvidence]: hasEvidence,
                    })}
                    role="list"
                    aria-label="Upload artifact options"
                  >
                    <Button
                      className={styles.addButton}
                      icon="drive"
                      type={BUTTON_TYPES.TERTIARY}
                      aria-label="Add Google Drive file"
                      dataTestId={`${dataTestIdPrefix}-ExpandedCard-Add-Google-Drive-File`}
                    />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Button
                      className={styles.addButton}
                      icon="file-upload"
                      type={BUTTON_TYPES.TERTIARY}
                      aria-label="Upload file"
                      dataTestId={`${dataTestIdPrefix}-ExpandedCard-Upload-File`}
                    />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Button
                      className={styles.addButton}
                      icon="link"
                      type={BUTTON_TYPES.TERTIARY}
                      onClick={() => setInputModalOpen(!inputModalOpen)}
                      aria-label="Add link"
                      dataTestId={`${dataTestIdPrefix}-ExpandedCard-Add-Link`}
                    />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    {CreateNewDropDown(dataTestIdPrefix)}
                  </div>
                )}
                {isEvidence && isEditable && (
                  <EvidenceTypeSelection
                    hasEvidence={hasEvidence}
                    dataTestId={`${dataTestIdPrefix}-ExpandedCard-EvidenceTypeRadio`}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <div
          className={classnames(styles.footer, {
            [styles.hasError]: error,
          })}
        >
          {error && ErrorBody(dataTestIdPrefix)}
          {bottomButtons(
            isEditable,
            isSmallScreen,
            canSave(),
            dataTestIdPrefix,
            closeModalAction,
            processUpdates,
            setDeleteCardId,
            currentCardID
          )}
        </div>
      </div>
      {/* DELETE MODAL */}
      <ConfirmationDialog
        cardId={deleteCardId}
        boardId={boardId}
        isOpen={deleteDialogOpen}
        setDeleteCardID={setDeleteCardId}
        mutationStatusCB={mutationStatusCB}
        closeModalAction={closeModalAction}
        dataTestIdPrefix={dataTestIdPrefix}
      />

      {/* INPUT LINK MODAL */}
      <InputModal
        modalType={inputModalTypes.INPUT_LINK}
        modalState={inputModalOpen}
        dataTestIdPrefix={dataTestIdPrefix}
        modalStateCB={setInputModalOpen}
        modalPayloadCB={setAddLinkPayload}
      />
    </ModalDeprecated>
  );
};

const CreateNewDropDown = (dataTestIdPrefix: string) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [dropDirection, setDropDirection] = useState("down");

  const setMenuDirection = () => {
    const footerHeight = 56;
    const elementBottom =
      triggerRef.current?.getBoundingClientRect().bottom ?? 0;
    const menuOffSet = window.innerHeight - footerHeight - elementBottom;

    //182 derivied by experiment
    menuOffSet < 182 ? setDropDirection("up") : setDropDirection("down");
  };

  const returnFocus = () => {
    //Return focus to the button that opened the menu
    triggerRef.current?.focus();
    //Close the menu
    triggerRef.current?.click();
  };

  //close menu not the modal.
  const handleEscKey = useCallback((e) => {
    //exists when the menu is open
    if (
      containerRef.current?.querySelector("ul") !== null &&
      e.key === "Escape"
    ) {
      e.stopPropagation();
      returnFocus();
    }
  }, []);

  useEffect(() => {
    //Event listener for ESC key to close drop down not modal
    document.addEventListener("keydown", handleEscKey, true); //Sets event listener to the Capture phase
    return () => {
      document.removeEventListener("keydown", handleEscKey, true);
    };
  }, [handleEscKey]);

  return (
    <div onClick={setMenuDirection} ref={containerRef}>
      <Dropdown
        className={classnames({
          [styles.addAttachmentDropUp]: dropDirection === "up",
        })}
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
        aria-label="Create new Google Drive file"
        triggerComponent={(props) => (
          <Button
            label="Create new"
            aria-label="Create new Google Drive file"
            type={BUTTON_TYPES.TERTIARY}
            rightIcon="arrow-carvet-down"
            dataTestId={`${dataTestIdPrefix}-ExpandedCard-AddArtifact-Dropdown-Trigger`}
            ref={triggerRef}
            {...props}
          />
        )}
        itemComponentList={[
          () => (
            <Button
              label="Doc"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              icon="google-document"
              aria-label="Create new Google Doc"
              onClick={() => returnFocus()}
              dataTestId={`${dataTestIdPrefix}-ExpandedCard-AddArtifact-Dropdown-CreateNew-GoogleDoc`}
            />
          ),
          () => (
            <Button
              label="Sheet"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              icon="google-sheet"
              aria-label="Create new Google Sheet"
              onClick={() => returnFocus()}
              dataTestId={`${dataTestIdPrefix}-ExpandedCard-AddArtifact-Dropdown-CreateNew-GoogleSheet`}
            />
          ),
          () => (
            <Button
              label="Slide"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              icon="google-slides"
              aria-label="Create new Google Slide"
              onClick={() => returnFocus()}
              dataTestId={`${dataTestIdPrefix}-ExpandedCard-AddArtifact-Dropdown-CreateNew-GoogleSlide`}
            />
          ),
          () => (
            <Button
              label="Drawing"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              icon="google-drawing"
              aria-label="Create new Google Drawing"
              onClick={() => returnFocus()}
              dataTestId={`${dataTestIdPrefix}-ExpandedCard-AddArtifact-Dropdown-CreateNew-GoogleDrawing`}
            />
          ),
        ]}
      />
    </div>
  );
};

const ErrorBody = (dataTestIdPrefix: string) => {
  return (
    <Alert
      type={ALERT_TYPES.FAILURE}
      animated={false}
      className={styles.alert}
      dataTestId={`${dataTestIdPrefix}-ExpandedCard-Save-Error`}
    >
      <span>
        Sorry, there was a problem completing this save. Please try again.
      </span>
    </Alert>
  );
};

const instructionLabel = () => {
  return <div className={styles.instructions}>Instructions</div>;
};

const attachmentList = (
  dataTestIdPrefix: string,
  attachments: Artifact[] | undefined,
  isEditible: boolean,
  isEvidence: boolean,
  removeArtifact: (id: string) => void
) => {
  const emptyListText =
    isEditible && !isEvidence
      ? "Attachments will appear here"
      : "When this is left blank, students can add their own work";

  if (attachments === undefined || attachments.length < 1) {
    return (
      <div
        className={styles.noAttachments}
        data-test-id={dataTestIdPrefix + "-Expanded-Card-No-Attachments"}
      >
        <div className={styles.noAttachmentMessage}>
          <div className={styles.noAttachmentHeader}>No attachments yet</div>
          <div className={classnames({ [styles.isEvidence]: isEvidence })}>
            {emptyListText}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={styles.attachmentList}
        role="list"
        aria-label="List of attachments"
        data-test-id={dataTestIdPrefix + "-Expanded-Card-Attachment-List"}
      >
        {attachments.map((a) => {
          const artifactLink = getUrl(a);
          const isVideo = isContentVideo(a.URI);
          let videoEmbedUrl = "";
          if (isVideo) {
            videoEmbedUrl = embedURLCompiler(a.URI);
            //Handle copy and paste out of youtube
            //TODO not updating embedURLComplier at the moment due to change freeze
            //Update after dataloading
            const uriToMatch = a.URI ? a.URI : "";
            if (new RegExp("youtube.com").test(uriToMatch)) {
              const normalVidRegex = /watch\?v=([^&]*)/;
              const shortsRegex = /shorts\/([^?]*)/;
              let regex: RegExp | null = null;
              if (normalVidRegex.test(uriToMatch)) {
                regex = normalVidRegex;
              } else if (shortsRegex.test(uriToMatch)) {
                regex = shortsRegex;
              }
              if (regex != null) {
                const tokens = uriToMatch.match(regex);
                const fragment =
                  tokens != null && tokens.length > 1 ? tokens[1] : "";
                videoEmbedUrl = "https://www.youtube.com/embed/" + fragment;
              }
            }
          }
          return (
            <div key={a.ID}>
              {isVideo && (
                <div
                  className={styles.attachmentVideo}
                  role="listitem"
                  aria-label={"Video of " + (a.Title ? a.Title : "No title")}
                  data-test-id={
                    dataTestIdPrefix + "-ExpandedCard-Attachtment-Video"
                  }
                >
                  <iframe
                    width="100%"
                    height="100%"
                    allowFullScreen={true}
                    frameBorder="0"
                    title={a.Title ? a.Title : ""}
                    role="application"
                    src={videoEmbedUrl}
                  ></iframe>
                </div>
              )}
              <div
                className={styles.attachment}
                role="listitem"
                aria-label={a.Title ? a.Title : "List item"}
                data-test-id={dataTestIdPrefix + "-ExpandedCard-Attachment"}
              >
                <div className={styles.attachmentLink}>
                  <ExternalLink
                    title={a.Title}
                    url={artifactLink?.url ? artifactLink.url : ""}
                    icon={a.IconURL}
                    active={true}
                    className={styles.externalLink}
                    dataTestId={
                      dataTestIdPrefix + "-Expanded-Card-Attachment-Link"
                    }
                  />
                </div>
                {isEditible && (
                  <Button
                    type={BUTTON_TYPES.TERTIARY}
                    alt={"Remove " + (isVideo ? "video" : "attachment")}
                    dataTestId={
                      dataTestIdPrefix + "-Expanded-Card-Delete-Attachment"
                    }
                    icon="cross"
                    size="xsmall"
                    onClick={() => removeArtifact(a.ID!)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

const bottomButtons = (
  isEditible: boolean,
  isSmallScreen: boolean,
  hasContentChanged: boolean,
  dataTestIdPrefix: string,
  closeModalAction: () => void,
  processUpdates: () => void,
  setDeleteCardId: (cardId: string | null) => void,
  cardId: string | null
) => {
  if (isEditible) {
    if (isSmallScreen) {
      return (
        <div className={styles.buttonContainer}>
          {cancelButton(dataTestIdPrefix, closeModalAction)}
          <div className={styles.smallViewButtons}>
            {saveCloseButton(
              dataTestIdPrefix,
              hasContentChanged,
              processUpdates
            )}
            {deleteButton(cardId, setDeleteCardId, dataTestIdPrefix)}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.buttonContainer}>
          {deleteButton(cardId, setDeleteCardId, dataTestIdPrefix)}
          {cancelButton(dataTestIdPrefix, closeModalAction)}
          {saveCloseButton(dataTestIdPrefix, hasContentChanged, processUpdates)}
        </div>
      );
    }
  }
  return;
};

const saveCloseButton = (
  dataTestIdPrefix: string,
  hasContentChanged: boolean,
  processUpdates: () => void
) => {
  return (
    <Button
      type={BUTTON_TYPES.PRIMARY}
      aria-label="Save and close"
      data-test-id={`${dataTestIdPrefix}-ExpandedCard-Button-SaveClose`}
      label="Save & close"
      className={styles.saveCloseButton}
      isDisabled={!hasContentChanged}
      onClick={() => {
        processUpdates();
      }}
    />
  );
};

const deleteButton = (
  cardId: string | null,
  setDeleteCardId: (cardId: string | null) => void,
  dataTestIdPrefix: string
) => {
  return (
    <Button
      type={BUTTON_TYPES.TERTIARY}
      aria-label="Delete card"
      data-test-id={`${dataTestIdPrefix}-ExpandedCard-Button-DeleteCard`}
      label="Delete card"
      className={styles.deleteButton}
      isDisabled={cardId === null}
      onClick={() => setDeleteCardId(cardId)}
    />
  );
};

const cancelButton = (
  dataTestIdPrefix: string,
  closeModalAction: () => void
) => {
  return (
    <Button
      type={BUTTON_TYPES.SECONDARY}
      aria-label="Cancel"
      data-test-id={`${dataTestIdPrefix}-ExpandedCard-Button-Cancel`}
      label="Cancel"
      className={styles.cancelButton}
      onClick={closeModalAction}
    />
  );
};

const getDefaultCard = (workspaceGroups: GroupType[], location: any) => {
  const allGroups = workspaceGroups.map((g) => {
    return { ID: g.ID };
  }) as GroupFilter[];

  return {
    Title: "",
    Description: "",
    Image: "",
    ImageAltText: "",
    GroupsFilter: allGroups,
    Artifacts: [] as Artifact[],
    ColumnType: location.columnType,
  };
};

export default CardModal;
