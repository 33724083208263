import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationQuery } from "../../../router/selectors";
import {
  PL_WORKSPACES_URL_KEYS,
  PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../navigation";
import { WORKSPACE_SORTING_TYPES } from "../../consts";

export const getPlWorkspaces = (state) =>
  _.get(state, "workspace.pl.list.items", null);
export const getPagesCount = (state) =>
  _.get(state, "workspace.pl.list.pagesCount", 1);
export const isLoading = (state) =>
  _.get(state, "workspace.pl.list.itemsLoading", false);

export const areThereItems = createSelector(
  [getPlWorkspaces],
  (plWorkspaces) => {
    return plWorkspaces && plWorkspaces.length > 0;
  }
);

export const getCurrentPage = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    const paramName = PL_WORKSPACES_URL_KEYS.page.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = parseInt(rawParam, 10);
    return parsedParam || PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getFilterSortType = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    const paramName = PL_WORKSPACES_URL_KEYS.sort.key;
    const rawParam = _.get(locationQuery, paramName);
    return rawParam === WORKSPACE_SORTING_TYPES.TITLE_AZ ||
      rawParam === WORKSPACE_SORTING_TYPES.TITLE_ZA
      ? rawParam
      : PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);
