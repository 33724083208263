import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./GuideBrowsingActiveSessionsBar.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getActiveGuideBrowsingSessionList } from "../../../../../../state/highlights/sessions/selectors";
import {
  loadCurrentSessions,
  releaseStudentsFromSession,
} from "../../../../../../state/highlights/sessions/actions";
import { getClassURN } from "../../../../../../state/highlights/config/selectors";
import { GUIDE_BROWSING_SESSION_TYPE } from "../../../../../../state/highlights/sessions/types";
import GuideBrowsingActiveSessionsModal from "../GuideBrowsingActiveSessionsModal/GuideBrowsingActiveSessionsModal";
import useUpdateHighlightsSessionsAfterEndTimeHook from "../../../../../../hooks/useUpdateHighlightsSessionsAfterEndTimeHook";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import { areSomeStudentsInSession } from "../../../../../../state/highlights/students/selectors";
import { getStudentsIdList } from "../../../../../../state/highlights/studentConfigs/selectors";
import { FormattedMessage, useIntl } from "react-intl";

const dataTestPrefix = "hl-GuideBrowsingModal-ActiveSessionsBar";

/** @deprecated use `GuideBrowsingSessionBar` instead. */
const GuideBrowsingActiveSessionBarDeprecated = ({
  sessionList,
  classId,
  areSomeStudentsInGBSession,
  studentIds,
  loadCurrentSessions,
  releaseStudentsFromGuideBrowsingSession,
}) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isErrorReleasing, setIsErrorReleasing] = useState(false);
  const intl = useIntl();

  const shouldBeRendered =
    (sessionList && sessionList.length) || areSomeStudentsInGBSession;

  const isOtherTeacherSession =
    areSomeStudentsInGBSession && !sessionList.length;

  // effect to check when GB sessions expire and update the list
  const loadSessions = useCallback(() => {
    loadCurrentSessions({ classId });
  }, [loadCurrentSessions, classId]);

  useUpdateHighlightsSessionsAfterEndTimeHook(loadSessions, sessionList);

  // make sure modal is closed when there are no sessions
  useEffect(() => {
    if (!sessionList.length) {
      setIsReviewModalOpen(false);
    }
  }, [sessionList.length]);

  const handleReleaseAll = () => {
    setIsErrorReleasing(false);

    releaseStudentsFromGuideBrowsingSession({
      studentIds,
    })
      .then(() => {
        setIsConfirmationModalOpen(false);
        loadCurrentSessions({ classId });
      })
      .catch(() => {
        setIsErrorReleasing(true);
      });
  };

  if (!shouldBeRendered) {
    return null;
  }

  return (
    <>
      <section
        className={styles.root}
        aria-label={intl.formatMessage({
          defaultMessage: "Active guided sessions",
          id: "BaVjar",
        })}
        tabIndex={-1}
        data-test-id={dataTestPrefix}
        id={dataTestPrefix}
      >
        <HapReactIcon svg="fill-focused-browsing" width="16" height="16" />
        {!isOtherTeacherSession && (
          <>
            <div className={styles.text}>
              <FormattedMessage
                defaultMessage="{count, plural, one {# Active guided session} other {# Active guided sessions}}"
                id="FYw2GM"
                values={{ count: sessionList.length }}
              />
            </div>
            <Button
              label={intl.formatMessage({
                defaultMessage: "Review",
                id: "R+J5ox",
              })}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              onClick={() => setIsReviewModalOpen(true)}
              data-test-id={`${dataTestPrefix}-OpenReviewModal`}
              className={styles.button}
            />
          </>
        )}
        {isOtherTeacherSession && (
          <div className={styles.text}>
            <FormattedMessage
              defaultMessage="Some students are currently in a Guided Session"
              id="iJZKN+"
            />
          </div>
        )}
        <Button
          label={intl.formatMessage({
            defaultMessage: "Release all",
            id: "jVEnps",
          })}
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.XSMALL}
          onClick={() => setIsConfirmationModalOpen(true)}
          data-test-id={`${dataTestPrefix}-ReleaseAllSessions`}
          className={styles.button}
        />
        <GuideBrowsingActiveSessionsModal
          isModalOpen={isReviewModalOpen}
          onModalClose={() => setIsReviewModalOpen(false)}
        />
      </section>
      <ActionConfirmationDialog
        dataTestPrefix={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal`}
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setIsErrorReleasing(false);
        }}
        title={intl.formatMessage({
          defaultMessage: "End session for all students?",
          id: "Y1/l5Q",
        })}
        content={intl.formatMessage({
          defaultMessage:
            "When all student are released, they will be able to browse the web without restrictions",
          id: "/DOLua",
        })}
        isActionDanger={true}
        error={
          isErrorReleasing
            ? intl.formatMessage({
                defaultMessage:
                  "Sorry, there was a problem releasing all students. Please try again.",
                id: "7iKzux",
              })
            : null
        }
        cancelDataTestId={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal-Button-Cancel`}
        actionLabel={intl.formatMessage({
          defaultMessage: "Release all students",
          id: "XSRI1F",
        })}
        onAction={handleReleaseAll}
        actionDataTestId={`${dataTestPrefix}-ReleaseAllSessionsConfirmationModal-Button-ReleaseAllStudents`}
      />
    </>
  );
};

GuideBrowsingActiveSessionBarDeprecated.propTypes = {
  sessionList: PropTypes.arrayOf(GUIDE_BROWSING_SESSION_TYPE).isRequired,
  classId: PropTypes.string,
  areSomeStudentsInGBSession: PropTypes.bool.isRequired,
  studentIds: PropTypes.arrayOf(PropTypes.string),
  loadCurrentSessions: PropTypes.func.isRequired,
  releaseStudentsFromGuideBrowsingSession: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    sessionList: getActiveGuideBrowsingSessionList(state),
    classId: getClassURN(state),
    areSomeStudentsInGBSession: areSomeStudentsInSession(state),
    studentIds: getStudentsIdList(state),
  }),
  (dispatch) => ({
    loadCurrentSessions: ({ classId }) =>
      dispatch(loadCurrentSessions({ classId })),
    releaseStudentsFromGuideBrowsingSession: ({ studentIds }) =>
      dispatch(
        releaseStudentsFromSession({
          studentIds,
        })
      ),
  })
)(GuideBrowsingActiveSessionBarDeprecated);
