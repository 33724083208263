import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ExternalLink.module.scss";
import HapReactIcon from "../icon/hapReactIcon";
import { isLink } from "../utils";
import { getGoogleResourceId } from "../../utils/getGoogleResourceId";

const defaultIcon = <HapReactIcon svg="globe" width={16} height={16} alt="" />;

const ExternalLink = ({
  icon,
  url,
  title,
  className,
  active = false,
  dataTestId,
  classId,
  studentEmail,
  showIcon = true,
}) => {
  let linkToShow = url;
  if (
    isLink(url) &&
    getGoogleResourceId(url) !== "" &&
    studentEmail !== "" &&
    classId !== ""
  ) {
    const encodedUrl = encodeURIComponent(url);
    const encodedEmail = encodeURIComponent(studentEmail);
    const domain = window.location.origin;
    linkToShow = `${domain}/click-to-share?classId=${classId}&email=${encodedEmail}&docUrl=${encodedUrl}`;
  }

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.active]: active },
        className
      )}
    >
      {showIcon && (
        <div className={styles.image} aria-hidden="true">
          {icon && (
            <img
              src={icon}
              width={16}
              height={16}
              alt=""
              className={styles.image}
            />
          )}
          {!icon && defaultIcon}
        </div>
      )}
      {isLink(linkToShow) && (
        <a
          className={classnames(styles.titleLink)}
          href={linkToShow}
          target="_blank"
          rel="noopener noreferrer"
          data-test-id={dataTestId}
        >
          {title || url}
        </a>
      )}
      {!isLink(linkToShow) && (
        <p
          className={classnames(styles.titleLink, {
            [styles.active]: active,
          })}
          role="link"
        >
          {title || linkToShow}
        </p>
      )}
    </div>
  );
};

ExternalLink.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  classId: PropTypes.string,
  studentEmail: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default ExternalLink;
