import React from "react";

export type IconSizeType = "md" | "lg" | "xl";

export type IconProps = {
  size?: IconSizeType;
  className?: string;
  style?: React.CSSProperties;
};

const sizeMap: { [key in IconSizeType]: number } = {
  md: 16,
  lg: 24,
  xl: 40,
};

export const createIcon = (paths: React.ReactNode) => {
  const Icon = ({ size = "md", className, style }: IconProps) => {
    const sizeValue = sizeMap[size];
    return (
      <svg
        aria-hidden
        width={sizeValue}
        height={sizeValue}
        viewBox="0 0 16 16"
        style={{ display: "block", ...style }}
        fill="none"
        className={className}
      >
        {paths}
      </svg>
    );
  };

  return Icon;
};
