import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styles from "./Radio.module.scss";
import { THEME_TYPES } from "../consts";

export const Radio = ({
  name,
  label,
  value,
  id = null,
  checked = false,
  disabled = false,
  className,
  onChange = () => {},
  dataTestIdPrefix,
  themeType = THEME_TYPES.LIGHT,
  hintText,
}) => {
  const [uniqId] = useState(uuidv4());
  const inputId = id || uniqId;
  const passedClassName = className ? className : null;
  const passedDataTestIdPrefix = dataTestIdPrefix ? dataTestIdPrefix : null;

  return (
    <React.Fragment>
      <input
        className={classnames(styles.root, styles[themeType], passedClassName)}
        type="radio"
        role="radio"
        id={inputId}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        data-test-id={
          passedDataTestIdPrefix ? `${passedDataTestIdPrefix}-Input` : null
        }
        disabled={disabled}
      />
      <label
        htmlFor={inputId}
        data-test-id={dataTestIdPrefix}
        id={`${inputId}-Label`}
      >
        <span className={styles.labelIcon} />
        <span>{label}</span>
        {hintText && <span className={styles.hintText}>{hintText}</span>}
      </label>
    </React.Fragment>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  themeType: PropTypes.oneOf(_.values(THEME_TYPES)),
  hintText: PropTypes.string,
};
