import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./Segment.module.scss";
import { CheckIcon } from "../../../icons/CheckIcon";
import { SegmentedControlContext } from "../SegmentedControlContext";

import { Tooltip } from "../../../components/Tooltip";

type SegmentProps = {
  icon: React.ReactNode;
  label?: string;
  tooltipText?: string;
  ariaLabel?: string;
  isDisabled?: boolean;
  dataTestId?: string;
  onClick: () => void;
};

export const Segment = ({
  icon,
  label,
  tooltipText,
  ariaLabel,
  isDisabled,
  dataTestId,
  onClick,
}: SegmentProps) => {
  const { index, dataTestIdPrefix, isSelectedSegment, onSegmentSelect } =
    useContext(SegmentedControlContext);

  if (isSelectedSegment === undefined) {
    throw new Error(
      "SegmentedControl: Segment must be rendered inside SegmentedControl"
    );
  }

  const labeledSegmentIcon = isSelectedSegment ? <CheckIcon /> : icon;

  const renderContent = (
    <div
      className={classNames(styles.content, {
        [styles.iconContent]: !label,
        [styles.labelContent]: label,
      })}
    >
      {label ? labeledSegmentIcon : isSelectedSegment && <CheckIcon />}
      {label ? label : icon}
    </div>
  );

  const handleSegmentSelect = () => {
    onSegmentSelect();
    onClick();
  };

  const compiledDataTestId = dataTestId
    ? `${dataTestIdPrefix}-${dataTestId}-segment`
    : `${dataTestIdPrefix}-segment`;

  return (
    <button
      key={index}
      role="radio"
      disabled={isDisabled}
      aria-checked={isSelectedSegment}
      aria-label={ariaLabel}
      data-test-id={compiledDataTestId}
      onClick={handleSegmentSelect}
      className={classNames(styles.segment, {
        [styles.iconSegment]: !label,
        [styles.selectedSegment]: isSelectedSegment,
      })}
    >
      {tooltipText ? (
        <Tooltip content={tooltipText} anchor={renderContent} />
      ) : (
        renderContent
      )}
    </button>
  );
};
