/** We only need to validate the domain part, validating emails is complex. */
const validateDomain = (domain: string): boolean =>
  /^(?=.{1,253}$)((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,63}$/.test(
    domain
  );

export function getDomainFromEmail(email: string): string | null {
  try {
    const trimmedEmail = email.trim();
    const domain = trimmedEmail.split("@")[1];
    return validateDomain(domain) ? domain : null;
  } catch {
    return null;
  }
}
