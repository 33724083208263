import _ from "lodash";
import { createSelector } from "reselect";
import { getFormattedName } from "../../../utils";
import { getUserNameFormat } from "../../user/selectors";
import {
  MYWORKSPACES_GRADING_ACTIVITY_TYPES,
  MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES,
} from "./types";
import { getLocationQuery } from "../../router/selectors";

export const getCardDataLoading = (state) =>
  _.get(state, "workspace.grading.cardDataLoading");
export const getAssigneeDataLoading = (state) =>
  _.get(state, "workspace.grading.assigneeDataLoading");

export const getInProgress = (state) =>
  _.get(state, "workspace.grading.actionInProgress");
export const getIsError = (state) =>
  _.get(state, "workspace.grading.actionError");

export const getHistoryUpdateInProgress = (state) =>
  _.get(state, "workspace.grading.historyUpdateInProgress");
export const getHistoryUpdateError = (state) =>
  _.get(state, "workspace.grading.historyUpdateError");

export const getGradingCardId = (state) =>
  _.get(state, "workspace.grading.selectedCardId");
export const getGradingSelectedAssigneeId = (state) =>
  _.get(state, "workspace.grading.selectedAssigneeId");

export const getGradingCard = (state) => _.get(state, "workspace.grading.card");
export const isAssigneeGroup = (state) =>
  _.get(state, "workspace.grading.card.IsGroupCard", false);

export const getAssigneeData = (state) =>
  _.get(state, "workspace.grading.assigneeData");
export const isAssigneeDataInitiallyLoading = (state) =>
  _.get(state, "workspace.grading.isAssigneeDataInitiallyLoading");

export const getGradingActivity = (state) =>
  _.get(state, "workspace.grading.selectedActivity");
export const isGradingSuccess = (state) =>
  _.get(state, "workspace.grading.actionError") === false;
export const getAssigneeCausedAction = (state) =>
  _.get(state, "workspace.grading.assigneeCausedAction");

export const cardNotValid = (state) =>
  _.get(state, "workspace.grading.cardNotValid");
export const artefactNotValid = (state) =>
  _.get(state, "workspace.grading.artefactNotValid");

export const isAutoSavingInProgress = (state) =>
  _.get(state, "workspace.grading.autoSavingInProgress");
export const isAutoSavingError = (state) =>
  _.get(state, "workspace.grading.autoSavingError");

export const isBulkActionInitInProgress = (state) =>
  _.get(state, "workspace.grading.bulkActionInitInProgress");
export const isBulkActionInitError = (state) =>
  _.get(state, "workspace.grading.bulkActionInitError");
export const isBulkReturnActionInProgress = (state) =>
  _.get(state, "workspace.grading.bulkReturnActionInProgress");
export const isBulkReturnActionError = (state) =>
  _.get(state, "workspace.grading.bulkReturnActionError");
export const isBulkSubmitActionInProgress = (state) =>
  _.get(state, "workspace.grading.bulkSubmitActionInProgress");
export const isBulkSubmitActionError = (state) =>
  _.get(state, "workspace.grading.bulkSubmitActionError");

export const getActivityHistory = (state) =>
  _.orderBy(
    _.get(state, "workspace.grading.assigneeData.ActivityHistory"),
    ["ActivityDate"],
    ["desc"]
  );

export const getAssigneesByActivity = createSelector(
  [getGradingCard],
  (card) => {
    return _.get(card, "Assignees", []);
  }
);

export const getAssigneeItemTitle = (liftFirstCharacter = false) =>
  createSelector([isAssigneeGroup], (isAssigneeGroup) => {
    const title = isAssigneeGroup ? "group" : "student";
    return liftFirstCharacter ? _.upperFirst(title) : title;
  });

export const isActivityEligible = createSelector(
  [getGradingActivity],
  (activity) => {
    return _.includes(MYWORKSPACES_GRADING_ACTIVITY_TYPES, activity);
  }
);

export const getGradingArtifact = createSelector(
  [getAssigneeData],
  (assigneeData) => {
    return assigneeData ? assigneeData.Artifact : null;
  }
);

export const getSelectedAssigneeArtifactId = createSelector(
  [getAssigneeData],
  (assigneeData) => {
    return assigneeData ? assigneeData.Artifact.Id : null;
  }
);

export const getAssigneesOrdered = createSelector(
  [getAssigneesByActivity, getUserNameFormat],
  (assigneesList, nameFormat) => {
    const assigneesListWithFormattedNames = _.map(assigneesList, (assignee) => {
      assignee.formattedName = getFormattedName(nameFormat, assignee);
      return assignee;
    });

    return _.orderBy(assigneesListWithFormattedNames, ["formattedName"]);
  }
);

export const isAssigneeListEmpty = createSelector(
  [getAssigneesByActivity],
  (assigneeList) => {
    return !(assigneeList && assigneeList.length > 0);
  }
);

export const getSelectedAssigneeName = createSelector(
  [getAssigneesOrdered, getGradingSelectedAssigneeId],
  (assigneesList, selectedAssigneeId) => {
    const assigneeData = _.find(assigneesList, { Id: selectedAssigneeId });
    return assigneeData ? assigneeData.formattedName : "";
  }
);

export const getGradingSelectedArtefactId = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    return _.get(locationQuery, "artefact", null);
  }
);

export const getNextAvailableArtefactId = createSelector(
  [getAssigneesOrdered, getGradingSelectedArtefactId],
  (assigneesList, selectedArtifactId) => {
    if (assigneesList.length < 2) {
      return null;
    }
    const ind = _.findIndex(assigneesList, { ArtifactID: selectedArtifactId });
    const nextInd =
      ind === -1 ? null : ind === assigneesList.length - 1 ? 0 : ind + 1;

    return assigneesList[nextInd] ? assigneesList[nextInd].ArtifactID : null;
  }
);

export const getLastReturnedActivityItem = createSelector(
  [getActivityHistory],
  (activityHistory) => {
    const lastRetuneditem = _.find(activityHistory, {
      Type: MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES.RETURNED_FOR_EDIT.type,
    });
    return lastRetuneditem;
  }
);

export const getAssigneeIdByArtifactId = (artifactId) =>
  createSelector([getAssigneesByActivity], (assigneesList) => {
    const assignee = _.find(assigneesList, { ArtifactID: artifactId });
    return _.get(assignee, "Id", "");
  });
