import { makeActionCreator } from "../../storeUtils";
import { postSendMessageOrShareLinks } from "../../../apiCalls/highlights";
import actionTypes from "../../actionTypes";

export const showSendMessageModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_SHOW,
  "payload"
);

export const hideSendMessageModal = makeActionCreator(
  actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_HIDE
);

export const sendMessageModalError = makeActionCreator(
  actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_ERROR
);

export const sendMessageModalSuccess = makeActionCreator(
  actionTypes.HIGHLIGHTS_SEND_MESSAGE_MODAL_SUCCESS
);

export const sendMessageAction =
  ({ messageText, recipientType, students, classId }) =>
  async (dispatch) => {
    const messageBody = {
      Message: messageText,
      RecipientType: recipientType,
      ClassURN: classId,
      Students: students,
    };

    try {
      const resp = await postSendMessageOrShareLinks({ messageBody });
      if (resp.status !== 200) {
        dispatch(sendMessageModalError());
      } else {
        dispatch(sendMessageModalSuccess());
      }
      return true;
    } catch (error) {
      console.log(error);
      dispatch(sendMessageModalError());
      return true;
    }
  };
