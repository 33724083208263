import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import styles from "./Input.module.scss";
import { ThemeType } from "../consts";

export const INPUT_DEFAULT_MAX_LENGTH = 255;

export type InputProps = {
  id?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  /** @deprecated use `hasError` instead */
  isErrorMode?: boolean;
  hasError?: boolean;
  isMasked?: boolean;
  themeType?: ThemeType;
  /** @deprecated use `onChangeText` instead */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeText?(value: string): void;
  maxLength?: number;
  /** @deprecated this will be part of the `TextField` component in an upcoming release */
  showLimitWarning?: boolean;
  placeholder?: string;
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  dataTestId?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onEnterPress?(): void;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value = "",
      disabled,
      className,
      isErrorMode,
      hasError = isErrorMode,
      themeType = "light",
      maxLength,
      showLimitWarning,
      isMasked,
      leftAddon,
      rightAddon,
      dataTestId,
      onFocus,
      onChange,
      onChangeText,
      onEnterPress,
      ...rest
    },
    ref
  ) => {
    const [uniqId] = useState(uuidv4()); // ???

    const limitDesc =
      showLimitWarning && value && maxLength && value.length >= maxLength
        ? uniqId
        : undefined;
    const ariaDescribedby = _.get(rest, "aria-describedby") || limitDesc;

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (onChangeText) {
        onChangeText(e.target.value);
      }
      if (onChange) {
        return onChange(e);
      }
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === "Enter" && onEnterPress) {
        onEnterPress();
      }
    };

    return (
      <>
        <div
          className={classnames(
            styles.inputContainer,
            { [styles.error]: hasError },
            { [styles.disabled]: disabled }
          )}
        >
          {leftAddon ? <div className={styles.addon}>{leftAddon}</div> : null}
          <input
            value={value}
            disabled={disabled}
            aria-disabled={disabled ? "true" : undefined}
            className={classnames(
              styles.root,
              styles[themeType],
              { [styles.error]: hasError },
              { [styles.disabled]: disabled },
              className
            )}
            ref={ref}
            maxLength={maxLength ? maxLength : undefined}
            aria-describedby={ariaDescribedby}
            type={isMasked ? "password" : undefined}
            data-test-id={dataTestId}
            onChange={handleChange}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            {...rest}
          />
          {rightAddon ? <div className={styles.addon}>{rightAddon}</div> : null}
        </div>
        {showLimitWarning &&
          value &&
          maxLength &&
          value.length >= maxLength && (
            <div
              className={classnames(styles.lengthWarning, styles[themeType])}
              id={uniqId}
            >
              <FormattedMessage
                defaultMessage="{maxLength} characters limit reached"
                id="Ttjs0/"
                values={{ maxLength }}
              />
            </div>
          )}
      </>
    );
  }
);

/** @deprecated use named export from index instead. */
export default Input;
