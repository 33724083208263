import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  WEBSOCKET_MESSAGE_TYPES,
  WEBSOCKET_TEACHER_REQUEST_SCREEN,
} from "../../../state/highlights/HighlightsWebsocket";
import { sendMessageToBusWebsocket } from "../../../state/highlights/busWebsocket/actions";
import { getStudentsOnlineIdList } from "../../../state/highlights/students/selectors";
import { getStudentsIdList } from "../../../state/highlights/studentConfigs/selectors";
import {
  getIsCurrentScreens,
  getIsBrowserTabs,
  getIsClassViewPage,
} from "../../../state/router/selectors";
import {
  closeScreenshotWebsocketConnection,
  openScreenshotWebsocketConnection,
} from "../../../state/highlights/screenshotWebsocket/actions";
import { getClassId } from "../../../state/shared/selectors";
import {
  getClassTeacherId,
  getIsOutsideSchoolHours,
} from "../../../state/highlights/config/selectors";
import { getFFByName } from "../../../state/app/selectors";

const ScreenshotWebsocketHandler = ({
  isCurrentScreens,
  isClassViewPage,
  sendMessageToBusWebsocket,
  onlineStudentIds,
  studentIds,
  selectedClassId,
  teacherId,
  openScreenshotWebsocketConnection,
  closeScreenshotWebsocketConnection,
  isOutsideSchoolHours,
}) => {
  const isCurrentScreensRef = useRef();
  const isClassViewPageRef = useRef();

  // handle getting in and out of Current Screens or Class View Page
  useEffect(() => {
    // only do anything if teacherId is present
    // which means the Class Config was loaded
    if (teacherId) {
      const isNavigationChanged =
        isCurrentScreens !== isCurrentScreensRef.current ||
        isClassViewPage !== isClassViewPageRef.current;
      const isNavigatingToTargetPages = isCurrentScreens || isClassViewPage;
      const isNavigatingAwayFromTargetPages = !isNavigatingToTargetPages;

      if (isNavigationChanged) {
        if (isNavigatingToTargetPages) {
          if (!isOutsideSchoolHours) {
            //open Screenshot Socket
            openScreenshotWebsocketConnection({
              classId: selectedClassId,
              students: studentIds,
              teacherId,
            });

            // ask Bus to start sending screenshots
            sendMessageToBusWebsocket({
              students: onlineStudentIds,
              type: WEBSOCKET_MESSAGE_TYPES.TEACHER_REQUEST,
              class_context: selectedClassId,
              data: { request: WEBSOCKET_TEACHER_REQUEST_SCREEN },
            });
          }
        } else if (isNavigatingAwayFromTargetPages) {
          if (!isOutsideSchoolHours) {
            // close Screenshot Socket
            closeScreenshotWebsocketConnection();

            // ask Bus to stop sending screenshots
            sendMessageToBusWebsocket({
              students: onlineStudentIds,
              type: WEBSOCKET_MESSAGE_TYPES.TEACHER_ONLINE,
            });
          }
        }

        isCurrentScreensRef.current = isCurrentScreens;
        isClassViewPageRef.current = isClassViewPage;
      }
    }
  }, [
    isCurrentScreens,
    isClassViewPage,
    sendMessageToBusWebsocket,
    onlineStudentIds,
    selectedClassId,
    teacherId,
    openScreenshotWebsocketConnection,
    closeScreenshotWebsocketConnection,
    isOutsideSchoolHours,
    studentIds,
  ]);

  return null;
};

ScreenshotWebsocketHandler.propTypes = {
  studentIds: PropTypes.arrayOf(PropTypes.string),
  onlineStudentIds: PropTypes.arrayOf(PropTypes.string),
  isCurrentScreens: PropTypes.bool.isRequired,
  isClassViewPage: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  teacherId: PropTypes.string,
  sendMessageToBusWebsocket: PropTypes.func.isRequired,
  openScreenshotWebsocketConnection: PropTypes.func.isRequired,
  closeScreenshotWebsocketConnection: PropTypes.func.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isCurrentScreens:
      getIsCurrentScreens(state) ||
      (getFFByName("HAP-9105-Current-screens-default")(state) &&
        getIsBrowserTabs(state)),
    isClassViewPage: getIsClassViewPage(state),
    studentIds: getStudentsIdList(state),
    onlineStudentIds: getStudentsOnlineIdList(state),
    selectedClassId: getClassId(state),
    teacherId: getClassTeacherId(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
  }),
  (dispatch) => ({
    sendMessageToBusWebsocket: (options) =>
      dispatch(sendMessageToBusWebsocket(options)),
    openScreenshotWebsocketConnection: ({ classId, students, teacherId }) =>
      dispatch(
        openScreenshotWebsocketConnection({ classId, students, teacherId })
      ),
    closeScreenshotWebsocketConnection: () =>
      dispatch(closeScreenshotWebsocketConnection()),
  })
)(ScreenshotWebsocketHandler);
