import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./DriveMainContentHeader.module.scss";
import {
  Button,
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  driveClearFilters,
  updateURLDocType,
} from "../../../../state/shared/navigation";
import ResourceSearch from "../../Resources/ResourceSearch/ResourceSearch";
import DriveDocTypeFilter from "../DriveDocTypeFilter/DriveDocTypeFilter";
import {
  getSearchQuery,
  getClassId,
  getDocTypeFilter,
} from "../../../../state/shared/selectors";
import { isSharingViewEnabled } from "../../../../state/user/selectors";
import { DOC_FILTERS } from "../../../../state/highlights/drive/types";
import { DrivePageParamTypes } from "../../../containers/Dashboard/DashboardPage/types";
import { PAGE_TYPES } from "../../../../hooks/useGroups";
import { decodeURIString } from "@hapara/ui/src/components/utils";
import { getFFByName } from "src/state/app/selectors";
import { useIntl } from "react-intl";

interface DriveMainContentHeaderProps {
  dataTestIdPrefix: string;
  pageType: string;
  pageParams?: DrivePageParamTypes;
}

export const DriveMainContentHeader: React.FC<DriveMainContentHeaderProps> = ({
  dataTestIdPrefix,
  pageType,
  pageParams,
}) => {
  const intl = useIntl();
  const hasUpdatedDriveFilter = useSelector(
    getFFByName("HAP-11775-highlights-drive-filter-update")
  );
  const selectedDocType = useSelector(getDocTypeFilter);
  const sharingViewEnabled = useSelector(isSharingViewEnabled);
  const searchQuery = useSelector(getSearchQuery);
  const classId = useSelector(getClassId);

  const { docType: docTypeParams, classId: classIdParams } = useParams<{
    docType?: string;
    classId?: string;
  }>();

  const createContentHeaderTitleOld = (
    pageType: string,
    docTypeFilterStr: string,
    pageParams?: DrivePageParamTypes
  ) => {
    const [activeParam, paramValue] = pageParams || [];

    if (pageType === PAGE_TYPES.HIGHLIGHTS) {
      return intl.formatMessage({
        defaultMessage: "All Documents",
        id: "uhdvFZ",
      });
    }

    const isDocTypeFiltered = !_.isEmpty(docTypeFilterStr);
    const isFolderTypeActive = activeParam === "folderType";
    const isEdublogsTypeActive = activeParam === "edublogsType";
    const isGmailTypeActive = activeParam === "gmailType";

    return isDocTypeFiltered
      ? intl.formatMessage(
          {
            defaultMessage: `Drive: {searchTerm}`,
            id: "foWpkM",
          },
          { searchTerm: docTypeFilterStr }
        )
      : isFolderTypeActive
      ? paramValue
      : isEdublogsTypeActive
      ? intl.formatMessage(
          {
            defaultMessage: `Edublogs: {searchTerm}`,
            id: "T4cCpN",
          },
          { searchTerm: `${_.capitalize(paramValue)}` }
        )
      : isGmailTypeActive
      ? intl.formatMessage({ defaultMessage: "Gmail", id: "2hslSe" })
      : intl.formatMessage({ defaultMessage: "All Documents", id: "uhdvFZ" });
  };

  const createContentHeaderTitle = (
    docTypeFilterStr: string,
    pageParams?: DrivePageParamTypes
  ) => {
    const [activeParam, paramValue] = pageParams || [];

    const isDocTypeFiltered = !_.isEmpty(docTypeFilterStr);
    const isFolderTypeActive = activeParam === "folderType";
    const isEdublogsTypeActive = activeParam === "edublogsType";
    const isGmailTypeActive = activeParam === "gmailType";

    return isDocTypeFiltered
      ? `Drive: ${docTypeFilterStr}`
      : isFolderTypeActive
      ? paramValue
      : isEdublogsTypeActive
      ? `Edublogs: ${_.capitalize(paramValue)}`
      : isGmailTypeActive
      ? intl.formatMessage({ defaultMessage: "Gmail", id: "2hslSe" })
      : intl.formatMessage({
          defaultMessage: "Drive: All Documents",
          id: "1ZBMhe",
        });
  };

  const activeDocType = docTypeParams ?? selectedDocType;
  const activeDocTypeMessageDescriptor = DOC_FILTERS.find(
    (t) => t.key === activeDocType
  )?.title;

  const docTypeFilterStr = activeDocType
    ? (activeDocTypeMessageDescriptor &&
        intl.formatMessage(activeDocTypeMessageDescriptor)) ||
      intl.formatMessage({ defaultMessage: "All documents", id: "lXxMRw" })
    : "";

  const title = hasUpdatedDriveFilter
    ? createContentHeaderTitle(docTypeFilterStr, pageParams)
    : createContentHeaderTitleOld(pageType, docTypeFilterStr, pageParams);

  const comma =
    !_.isEmpty(searchQuery) && !_.isEmpty(selectedDocType) ? "," : "";
  const isEdublogsPage = pageParams?.[0] === "edublogsType";
  const displayDriveFilter =
    sharingViewEnabled &&
    (!_.isEmpty(docTypeParams) ||
      pageType === PAGE_TYPES.HIGHLIGHTS ||
      isEdublogsPage);
  const currentPage: string = pageParams ? pageParams[1] : "";

  const handleClearSearch = () => {
    classIdParams
      ? updateURLDocType({ currentPage, classId, clear: true })
      : driveClearFilters(classId, hasUpdatedDriveFilter);
  };

  const oldSearchResultsSection = (
    <>
      {(!_.isEmpty(searchQuery) || !_.isEmpty(selectedDocType)) && (
        <div
          className={styles.searchQuery}
          data-test-id={`${dataTestIdPrefix}-SearchResults-Query`}
        >
          <div className={styles.searchString}>
            <span>
              {intl.formatMessage({
                defaultMessage: "Search results for:",
                id: "gc3R1T",
              })}
            </span>{" "}
            {selectedDocType && `${docTypeFilterStr}${comma} `}
            {searchQuery}
          </div>
          <Button
            type={BUTTON_TYPES.OUTLINED}
            size={BUTTON_SIZES.SMALL}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onAction={handleClearSearch}
            label={intl.formatMessage({
              defaultMessage: "Clear",
              id: "/GCoTA",
            })}
            dataTestId={`${dataTestIdPrefix}-SearchResults-ClearSearch`}
            aria-label="Clear search results"
          />
        </div>
      )}
    </>
  );
  const newSearchResultsSection = (
    <>
      {!_.isEmpty(searchQuery) && (
        <div
          className={styles.searchQuery}
          data-test-id={`${dataTestIdPrefix}-SearchResults-Query`}
        >
          <div className={styles.searchString}>
            <span>
              {intl.formatMessage({
                defaultMessage: "Search results for:",
                id: "gc3R1T",
              })}
            </span>{" "}
            {searchQuery}
          </div>
          <Button
            type={BUTTON_TYPES.OUTLINED}
            size={BUTTON_SIZES.SMALL}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onAction={handleClearSearch}
            label={intl.formatMessage({
              defaultMessage: "Clear",
              id: "/GCoTA",
            })}
            dataTestId={`${dataTestIdPrefix}-SearchResults-ClearSearch`}
            aria-label={intl.formatMessage({
              defaultMessage: "Clear search results",
              id: "nwPn1i",
            })}
          />
        </div>
      )}
    </>
  );

  return (
    <div className={styles.root}>
      <div
        className={styles.headerContent}
        data-test-id={`${dataTestIdPrefix}-HeaderContent`}
      >
        {title && <h1>{decodeURIString(title)}</h1>}
        <div className={styles.separator} />
        <div className={styles.filters}>
          {displayDriveFilter && (
            <DriveDocTypeFilter
              dataTestIdPrefix={dataTestIdPrefix}
              classIdParams={classIdParams}
              pageParams={pageParams}
            />
          )}
          {!isEdublogsPage && (
            <ResourceSearch
              dataTestIdPrefix={dataTestIdPrefix}
              className={styles.searchBar}
              placeholder={intl.formatMessage({
                defaultMessage: "Search for documents",
                id: "6QuRSe",
              })}
            />
          )}
        </div>
      </div>
      {hasUpdatedDriveFilter
        ? newSearchResultsSection
        : oldSearchResultsSection}
    </div>
  );
};

export default DriveMainContentHeader;
