import { useIntl } from "react-intl";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../../atomic/Button/Button";
import styles from "./Toast.module.scss";
import HapReactIcon from "../../atomic/icon/hapReactIcon";

export const TOAST_TYPES = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
};

const Toast = ({
  onClose = () => {},
  children,
  className,
  icon,
  type = TOAST_TYPES.ERROR,
  ...rest
}) => {
  const intl = useIntl();
  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    const delayedCall = _.delay(() => setIsInit(false), 100);
    return () => {
      if (delayedCall) window.clearTimeout(delayedCall);
    };
  }, [setIsInit]);

  return (
    <div
      className={classnames(
        styles.root,
        styles[type],
        {
          [styles.init]: isInit,
        },
        className
      )}
      {...rest}
    >
      {icon && (
        <div className={classnames(styles.icon, styles[type])}>
          <HapReactIcon svg={icon} width={24} height={24} />
        </div>
      )}
      <div className={styles.body}>{children}</div>
      <div>
        <Button
          icon="cross"
          onAction={onClose}
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.TERTIARY}
          className={classnames(styles.closeButton, styles[type])}
          dataTestId="Hs-Alert-Button-CloseAlert"
          aria-label={intl.formatMessage({
            defaultMessage: "Close",
            id: "rbrahO",
          })}
        />
      </div>
    </div>
  );
};

Toast.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  icon: PropTypes.string,
  type: PropTypes.string,
};

export default Toast;
