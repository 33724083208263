import React from "react";
import { createIcon } from "./utils/createIcon";

export const ListIcon = createIcon(
  <path
    d="M2.667 8.666a.667.667 0 100-1.333.667.667 0 000 1.333zm0-4a.667.667 0 100-1.333.667.667 0 000 1.333zm0 8a.667.667 0 100-1.333.667.667 0 000 1.333zM14 8H6m8-4H6m8 8H6"
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
