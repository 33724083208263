import _ from "lodash";
import styles from "./EvidenceModal.module.scss";
import { useState, useEffect } from "react";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import { Button, BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";

interface EvidenceModalTypes {
  modalOpen: boolean;
  modalPayload: Array<string>;
  dataTestIdPrefix: string;
  modalStateAction: (modalResponse: []) => void;
}

const EvidenceModal = ({
  modalOpen,
  modalPayload,
  modalStateAction,
  dataTestIdPrefix,
}: EvidenceModalTypes) => {
  const [hidePlaceholder, setHidePlaceholder] = useState<boolean>(false);
  const [evidenceName, sectionName] = modalPayload;
  const hasSection = !_.isEmpty(sectionName);
  const hasName = !_.isEmpty(evidenceName);

  useEffect(() => {
    setHidePlaceholder(!setHidePlaceholder);
  }, [modalOpen]);

  const handleClose = () => {
    setHidePlaceholder(true);
    modalStateAction([]); //Empty array closes modal
  };

  const createSectionBlock = () => {
    if (hasSection) {
      return (
        <p>
          <strong>Section:</strong> {sectionName}
        </p>
      );
    } else if (!hasSection && !hidePlaceholder) {
      return <p className={styles.noSection}>Not sectioned</p>;
    }
  };
  const createTitleBlock = () => {
    if (hasName) {
      return <h1>Activity status for {hasName && `${evidenceName}`}</h1>;
    } else if (!hasName && !hidePlaceholder) {
      return <h1>Activity status for selected card</h1>;
    }
  };

  return (
    <ModalDeprecated
      isOpen={modalOpen}
      onClose={handleClose}
      className={styles.root}
      hasEmbeddedHeader={false}
      shouldCloseOnOverlayClick={true}
      contentLabel="modalTextContent.inputHeading"
      dataTestPrefix={`${dataTestIdPrefix}-EvidenceModal`}
    >
      <div className={styles.contentContainer}>
        <Button
          icon="cross"
          aria-label="Close"
          onClick={handleClose}
          type={BUTTON_TYPES.TERTIARY}
          className={styles.modalClose}
          data-test-id={`${dataTestIdPrefix}-EvidenceModal-Close`}
        />
        <div className={styles.modalHeader}>
          {createTitleBlock()}
          {createSectionBlock()}
        </div>
        <div className={styles.modalContent}>
          <div className={styles.comingSoon}> Coming soon </div>
        </div>
      </div>
    </ModalDeprecated>
  );
};

export default EvidenceModal;
