import { history } from "../../store";
import { APP_PAGES } from "../../router/types";

export const GRADING_URL_KEYS = {
  artefact: {
    key: "artefact",
  },
};

export const GRADING_URL_PARAMS_DEFAULT_VALUES = {
  [GRADING_URL_KEYS.artefact.key]: null,
};

export const navigateBack = () => {
  if (
    history.location.state &&
    history.location.state.from === APP_PAGES.WORKSPACE_MY_WORKSPACE.path
  ) {
    history.goBack();
  } else {
    window.history.back();
  }
};
