import {
  MYWORKSPACES_URL_KEYS,
  MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../list/navigation";
import { history } from "../../../store";
import { APP_PAGES } from "../../../router/types";
import { MYWORKSPACES_CATEGORIES } from "../list/types";

export const navigateToSearchResults = ({ query, page }) => {
  const defaultValue = MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.search_page;
  const encodedQuery = window.encodeURIComponent(query);

  let search = `?${MYWORKSPACES_URL_KEYS.query.key}=${encodedQuery}&${MYWORKSPACES_URL_KEYS.page.key}=${page}`;

  if (page === defaultValue) {
    search = `?${MYWORKSPACES_URL_KEYS.query.key}=${encodedQuery}`;
  }

  history.push({
    pathname: `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.SEARCH.urlKey}`,
    search,
  });
};
