/* Avoid using this component due to several a11y issues
  use <Modal /> instead
*/
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import FocusLock from "react-focus-lock";
import styles from "./slidingGroupPanel.module.scss";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../../atomic/Button/Button";
import ErrorMessage from "../errorMessage/errorMessage";
import HapReactIcon from "../../atomic/icon/hapReactIcon";
import { useIntl } from "react-intl";

export const INLINE_MESSAGE_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
};

export const INLINE_MESSAGE_DISPLAY_DURATION = 3000;

const SlidingGroupPanel = React.forwardRef(
  (
    {
      children,
      hasOverlay,
      placement,
      onClose,
      onActionBack,
      theme,
      blocked,
      withSpinner,
      errorOccurred,
      onTryAgain,
      inlineMessage,
    },
    ref
  ) => {
    const intl = useIntl();

    let panelClassName = classnames(
      styles.panel,
      {
        [styles.panelRight]: placement === "right",
        [styles.panelLeft]: placement !== "right",
      },
      {
        [styles.gray]: theme === "gray",
      }
    );

    return (
      <FocusLock>
        <dialog open className={styles.root}>
          {hasOverlay && <div className={styles.overlay} />}
          <div className={panelClassName} tabIndex={0} ref={ref}>
            {errorOccurred && !!errorOccurred.id && (
              <ErrorMessage
                action={() => onTryAgain()}
                text={errorOccurred.text}
              />
            )}
            {blocked && (
              <div className={styles.blocked}>
                {withSpinner && (
                  <div>
                    <HapReactIcon
                      svg="loader"
                      width="40"
                      height="40"
                      spin
                      alt={intl.formatMessage({
                        defaultMessage: "loading the group in",
                        id: "kWnWDD",
                      })}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={styles.header}>
              {onActionBack && (
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  icon="arrow-left"
                  onAction={() => onActionBack()}
                  dataTestId="td-GroupsManager-GroupPanel-ButtonBack"
                  className={classnames(
                    styles.actionButton,
                    styles.actionButtonBack
                  )}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Back",
                    id: "cyR7Kh",
                  })}
                />
              )}
              <div
                className={classnames(
                  styles.inlineMessage,
                  styles[_.get(inlineMessage, "type")]
                )}
                aria-live="polite"
              >
                {inlineMessage && inlineMessage.text && (
                  <>
                    {inlineMessage.icon && (
                      <HapReactIcon
                        className={styles.inlineMessageIcon}
                        svg={inlineMessage.icon}
                        width="16"
                        height="16"
                      />
                    )}
                    <span>{inlineMessage.text}</span>
                  </>
                )}
              </div>
              {onClose && (
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  icon="cross"
                  onAction={() => onClose()}
                  dataTestId="td-GroupsManager-GroupPanel-ButtonClose"
                  className={classnames(
                    styles.actionButton,
                    styles.actionButtonClose
                  )}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Close",
                    id: "rbrahO",
                  })}
                />
              )}
            </div>
            <div className={styles.body}>
              {React.Children.count(children) === 1 ? children : children[0]}
            </div>
            {React.Children.count(children) > 1 && children[1] && (
              <div className={styles.footer}>{children[1]}</div>
            )}
          </div>
        </dialog>
      </FocusLock>
    );
  }
);

SlidingGroupPanel.propTypes = {
  onActionBack: PropTypes.func,
  onClose: PropTypes.func,
  hasOverlay: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  theme: PropTypes.string,
  blocked: PropTypes.bool,
  withSpinner: PropTypes.bool,
  errorOccurred: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  inlineMessage: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(_.values(INLINE_MESSAGE_TYPE)),
  }),
  onTryAgain: PropTypes.func,
};

export default SlidingGroupPanel;
