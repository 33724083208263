import { useIntl } from "react-intl";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./SearchDeprecated.module.scss";
import Button from "../../atomic/Button/Button";

const SearchDeprecated = ({
  onSearch = () => {},
  onClear = () => {},
  placeholder = "Search",
  className,
  initialValue = null,
  dataTestIdPrefix = "Hs-Search",
  forceValueToEmpty = false,
  forceToInitialValue,
  disabled = false,
  tabIndex,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(initialValue);
  const [prevInitialValue, setPrevInitialValue] = useState(null);
  const inputRef = useRef(value);

  useEffect(() => {
    if (value === "") {
      onClear();
    }
  }, [value, onClear]);

  useEffect(() => {
    if (forceValueToEmpty) {
      setValue("");
      inputRef.current.focus();
    }
  }, [forceValueToEmpty, inputRef]);

  const handleValueChange = (e) => {
    setValue(_.get(e, "target.value", ""));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(value);
  };

  useEffect(() => {
    if (forceToInitialValue && initialValue !== prevInitialValue) {
      setValue(initialValue);
      setPrevInitialValue(initialValue);
    }
  }, [forceToInitialValue, initialValue, prevInitialValue]);

  const isActiveState = !!value;

  return (
    <form
      className={classnames(
        styles.root,
        {
          [styles.active]: isActiveState,
        },
        className
      )}
      onSubmit={handleSubmit}
      role="search"
    >
      <input
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        className={styles.textField}
        value={value ? value : ""}
        onChange={handleValueChange}
        ref={inputRef}
        data-test-id={dataTestIdPrefix + "-Input-SearchField"}
        aria-label={placeholder}
        tabIndex={tabIndex}
      />
      <Button
        className={styles.searchButton}
        type="primary"
        icon="search"
        dataTestId={dataTestIdPrefix + "-Button-SubmitSearch"}
        aria-label={intl.formatMessage({
          defaultMessage: "Search",
          id: "xmcVZ0",
        })}
        onAction={() => onSearch(value)}
        tabIndex={tabIndex}
        disabled={disabled}
      />
    </form>
  );
};

SearchDeprecated.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  initialValue: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  forceValueToEmpty: PropTypes.bool,
  tabIndex: PropTypes.number,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

/** @deprecated */
export default SearchDeprecated;
