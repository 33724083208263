import _ from "lodash";
import actionTypes from "../../../actionTypes";
import { fetchWorkspaces } from "../../../../apiCalls/workspaces";
import {
  showAppError,
  hideAppError,
  updateAccessError,
} from "../../../app/actions";
import { navigateToCategory } from "../list/navigation";
import { navigateToSearchResults } from "./navigation";
import { getSearchQuery } from "./selectors";
import { MYWORKSPACES_SEARCH_PAGE_SIZE } from "./types";
import { populateMyWSSearchEventData, pendoTrack } from "../../../../utils";
import {
  getSearchResultTotalItems,
  getMyWSSearchGuid,
  getMyWSSearchIteration,
} from "./selectors";
import { MYWORKSPACES_CATEGORIES } from "../list/types";

export const updatePendingState = () => ({
  type: actionTypes.MYWORKSPACES_SEARCH_LOAD_PENDING,
});

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: actionTypes.MYWORKSPACES_SEARCH_CLEAR,
  });
  navigateToCategory(MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey);
};

export const changeSearchPage = (page) => (dispatch, getState) => {
  const state = getState();
  const searchQuery = getSearchQuery(state);
  navigateToSearchResults({ query: searchQuery, page: page });
};

export const searchMyWorkspaces =
  ({ query, page = 1 }) =>
  (dispatch, getState) => {
    dispatch(updatePendingState());
    dispatch(hideAppError());

    return fetchWorkspaces({
      query,
      page,
      isPublic: false,
      pageSize: MYWORKSPACES_SEARCH_PAGE_SIZE,
    })
      .then((response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return [];
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      })
      .then((data) => {
        return {
          items: _.get(data, "Results"),
          totalItems: _.get(data, "TotalHits"),
          pagesCount: _.get(data, "TotalPages"),
        };
      })
      .then(({ items, totalItems, pagesCount }) =>
        dispatch({
          type: actionTypes.MYWORKSPACES_SEARCH_LOAD_SUCCESS,
          payload: {
            items: _.map(items, (item) => ({
              ...item,
              Id: item.BoardId,
              IsArchived: false,
              MyAvailableActions: [],
              ModifiedDatetime: item.EditedOn,
            })),
            totalItems,
            pagesCount,
          },
        })
      )
      .then(() => {
        if (!_.isEmpty(query)) {
          const state = getState();
          const iterCount = getMyWSSearchIteration(state);
          const searchGuid = getMyWSSearchGuid(state);
          const totalResults = getSearchResultTotalItems(state);

          const searchFilters = populateMyWSSearchEventData({
            query,
            page,
            totalResults,
            searchGuid,
            iterCount,
          });
          pendoTrack("My Workspaces Search", searchFilters);
          dispatch({
            type: actionTypes.MYWORKSPACES_SEARCH_GUID_UPDATE,
            searchGuid: searchFilters.guid,
            iterCount: searchFilters.iter,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.MYWORKSPACES_SEARCH_LOAD_ERROR,
        });
        dispatch(showAppError(error));
      });
  };
