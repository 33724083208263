import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./InlineFeedback.module.scss";
import _ from "lodash";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../Button/Button";
import { useIntl } from "react-intl";

export const FEEDBACK_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};
const DEFAULT_DELAY = 5000;
const FeedBackContext = React.createContext();

export const InlineFeedback = ({
  contentFunction,
  delayInSeconds = DEFAULT_DELAY,
}) => {
  const intl = useIntl();
  const [isInProgress, setIsInProgress] = React.useState(false);
  const [isInFeedBack, setIsInFeedBack] = React.useState(false);
  const [feedBackType, setFeedBackType] = React.useState("info");
  const [feedbackMessage, setFeedbackMessage] = React.useState("");
  const [triggerRef] = React.useState(React.useRef());
  const [cancelInterval, setCancelInterval] = React.useState(null);

  React.useEffect(() => {
    return () => {
      if (cancelInterval) {
        window.clearTimeout(cancelInterval);
      }
    };
  }, [cancelInterval]);

  const cancelFeedback = () => {
    if (cancelInterval) {
      window.clearTimeout(cancelInterval);
      setCancelInterval(null);
    }
    setIsInFeedBack(false);
    _.delay(() => {
      triggerRef.current && triggerRef.current.focus();
    }, 100);
  };

  const applyFeedBack = (type, message) => {
    setIsInProgress(false);
    setFeedBackType(type);
    setFeedbackMessage(message);
    setIsInFeedBack(true);
    if (type === FEEDBACK_TYPES.SUCCESS) {
      setCancelInterval(
        window.setTimeout(() => {
          cancelFeedback();
        }, delayInSeconds)
      );
    }
  };
  const ctx = {
    setInProgress: () => setIsInProgress(true),
    feedback: (type, message) => applyFeedBack(type, message),
    isInProgress,
    triggerRef,
  };
  if (isInFeedBack) {
    return (
      <div className={classnames(styles.root, styles[feedBackType])}>
        <div className={styles.message}>{feedbackMessage}</div>
        <Button
          ref={(r) => {
            r && r.focus();
          }}
          onClick={cancelFeedback}
          size={BUTTON_SIZES.XSMALL}
          type={BUTTON_TYPES.OUTLINED}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
          className={styles.okayButton}
          label={intl.formatMessage({ defaultMessage: "Okay", id: "gZlFBP" })}
          aria-label={intl.formatMessage({
            defaultMessage: "Close feedback message",
            id: "nGGqNN",
          })}
        />
      </div>
    );
  }
  return (
    <FeedBackContext.Provider value={ctx}>
      <FeedBackContext.Consumer>
        {(ctx) => contentFunction(ctx)}
      </FeedBackContext.Consumer>
    </FeedBackContext.Provider>
  );
};

export const withFeedback = (f, delayInSeconds) => {
  return <InlineFeedback contentFunction={f} delayInSeconds={delayInSeconds} />;
};

InlineFeedback.propTypes = {
  contentFunction: PropTypes.func.isRequired,
  delayInSeconds: PropTypes.number.isRequired,
};

export default InlineFeedback;
