import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./SnapModal.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../state/shared/selectors";
import {
  loadSnapshotDeatils,
  sendSnapOnEmail,
} from "../../../../state/highlights/snaps/actions";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { SNAPSHOT_TYPE } from "../../../../state/highlights/snaps/types";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import SnapModalDetails from "../SnapModalDetails/SnapModalDetails";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { pendoTrack } from "../../../../utils";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { IntlDateTimeLocal } from "@hapara/ui/src/intl/dateTime";

const LoadingBody = () => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.loading}>
        <div className={styles.whiteBlock}>
          <div className={styles.leftColumn}>
            <div className={styles.label} />
            <div className={styles.dropdown} />
          </div>
          <div className={styles.rightColumn} />
        </div>
        <div className={styles.twoColumns}>
          <div className={styles.leftColumn}>
            <div className={styles.singleLine} />
            {[...Array(3)].map((e, i) => (
              <div key={i} className={styles.activity}>
                <HapReactIcon
                  svg="clock-history"
                  width={16}
                  height={16}
                  className={styles.icon}
                  alt=""
                />
                <div className={styles.date} />
                <div className={styles.url} />
              </div>
            ))}
          </div>
          <div className={styles.rightColumn}>
            <div className={styles.singleLine} />
            <div className={styles.screenshot} />
            <div className={styles.button} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorBody = (tryAgainAction, dataTestPrefix) => {
  const intl = useIntl();

  return (
    <div className={styles.modalBody}>
      <div className={styles.error}>
        <div className={styles.errorText}>
          <FormattedMessage
            defaultMessage="Sorry, there was a problem loading this snap."
            id="vG17nV"
          />
        </div>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.REGULAR}
          onAction={tryAgainAction}
          dataTestId={`${dataTestPrefix}-ErrorTryAgainButton`}
          label={intl.formatMessage({
            defaultMessage: "Try again",
            id: "FazwRl",
          })}
          className={styles.tryAgainButton}
        />
      </div>
    </div>
  );
};

const SnapModal = ({
  isModalOpen = false,
  onModalClose,
  classId,
  snapshot,
  loadSnapshotDeatils,
  sendSnapCopy,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSendingSnapCopy, setIsSendingSnapCopy] = useState(false);
  const [isSendingSnapError, setIsSendingSnapError] = useState(false);
  const [isSnapCopySent, setIsSnapCopySent] = useState(false);

  const sendSnapshotCopy = () => {
    setIsSendingSnapCopy(true);
    setIsSendingSnapError(false);
    sendSnapCopy(classId, snapshot.ID)
      .then((ok) => {
        if (ok) {
          setIsSnapCopySent(true);
          setIsSendingSnapCopy(false);
        } else {
          setIsSendingSnapError(true);
          setIsSendingSnapCopy(false);
        }
      })
      .catch((e) => {
        setIsSendingSnapError(true);
        setIsSendingSnapCopy(false);
      });

    const timeDiff = moment
      .duration(Date.now() - snapshot.SnapshotTimestamp)
      .asSeconds();
    pendoTrack("Snap - Email me a copy", {
      classId: classId,
      snapTimestamp: new Date(snapshot.SnapshotTimestamp).toISOString(),
      secondsSinceSnapTaken: Math.round(timeDiff),
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      setIsError(false);
      setIsSendingSnapCopy(false);
      setIsSendingSnapError(false);
      setIsSnapCopySent(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (snapshot.DetailsLoaded) {
      setIsLoading(false);
    } else {
      if (!isLoading) {
        loadSnapshotDeatils(snapshot.ID, classId).catch((e) => {
          setIsLoading(false);
          setIsError(true);
        });
      }
      setIsLoading(true);
    }
  }, [snapshot, classId, loadSnapshotDeatils]);

  const tryAgain = () => {
    setIsLoading(true);
    setIsError(false);
    loadSnapshotDeatils(snapshot.ID, classId).catch((e) => {
      setIsLoading(false);
      setIsError(true);
    });
  };

  if (!snapshot) {
    return null;
  }

  const expireInHowManyDays = moment(snapshot.SnapshotTimestamp)
    .add(7, "days")
    .diff(moment.now(), "days");

  const dataTestPrefix = "hl-SnapModal";

  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      onClose={onModalClose}
      hasEmbeddedHeader={false}
      className={styles.root}
      dataTestPrefix={dataTestPrefix}
    >
      <div className={styles.modalTitle}>
        <div className={styles.wrapper}>
          <div className={styles.leftPart}>
            <h1
              data-test-id={`${dataTestPrefix}-Header-Title`}
              className={styles.modalTitleHeader}
            >
              <IntlDateTimeLocal
                timestamp={`${snapshot.DateTitle}`}
                format="longDate"
              />
            </h1>
            <div className={styles.siteUrl}>
              <ExternalLink
                url={snapshot.SiteUrl}
                icon={snapshot.IconUrl}
                studentEmail={snapshot.Students[0].StudentEmail}
                classId={classId}
                dataTestId={`${dataTestPrefix}-Modal-Link`}
              />
            </div>
          </div>
          <div className={styles.rightPart}>
            <Button
              icon="cross"
              onClick={onModalClose}
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
                id: "rbrahO",
              })}
              data-test-id={`${dataTestPrefix}-Button-Close`}
              className={styles.modalClose}
            />

            <div className={styles.snapExpireText}>
              <HapReactIcon
                svg="fill-circle-exclamation"
                width={16}
                height={16}
                className={styles.icon}
                alt=""
              />{" "}
              <span>
                <FormattedMessage
                  defaultMessage="Snap is available for {expireInHowManyDays, plural, one {# day} other {# days}} "
                  id="YXBZrQ"
                  values={{ expireInHowManyDays }}
                />
              </span>
              {!isSendingSnapCopy && !isSnapCopySent && (
                <button
                  className={styles.sendCopy}
                  data-test-id={`${dataTestPrefix}-EmailMeCopy`}
                  onClick={sendSnapshotCopy}
                >
                  <FormattedMessage
                    defaultMessage="Email me a copy"
                    id="ShIYaR"
                  />
                </button>
              )}
              {isSendingSnapCopy && (
                <span className={styles.sendingCopy}>
                  <HapReactIcon
                    svg="loader"
                    width={16}
                    height={16}
                    spin={true}
                    className={styles.iconSending}
                    alt=""
                  />
                  <span>
                    <FormattedMessage defaultMessage="Sending..." id="82Y7Sa" />
                  </span>
                </span>
              )}
              {isSnapCopySent && (
                <span className={styles.copySent}>
                  <HapReactIcon
                    svg="circle-check-fill"
                    width={16}
                    height={16}
                    className={styles.iconSucess}
                    alt=""
                  />
                  <span>
                    <FormattedMessage defaultMessage="Copy sent" id="rCNDEv" />
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
        <Alert
          type={ALERT_TYPES.FAILURE}
          isVisible={isSendingSnapError}
          className={styles.alert}
        >
          <FormattedMessage
            defaultMessage="There was a problem emailing a copy. Please try again."
            id="OVTBG+"
          />
        </Alert>
      </div>

      {isLoading && <LoadingBody />}
      {isError && (
        <ErrorBody tryAgainAction={tryAgain} dataTestPrefix={dataTestPrefix} />
      )}
      {!isLoading && !isError && (
        <SnapModalDetails snapshot={snapshot} onModalClose={onModalClose} />
      )}
    </ModalDeprecated>
  );
};

SnapModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  classId: PropTypes.string,
  snapshot: SNAPSHOT_TYPE,
  loadSnapshotDeatils: PropTypes.func.isRequired,
  sendSnapCopy: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    classId: getClassId(state),
  }),
  (dispatch) => ({
    loadSnapshotDeatils: (snapId, classId) =>
      dispatch(loadSnapshotDeatils(snapId, classId)),
    sendSnapCopy: (classId, snapId) =>
      dispatch(sendSnapOnEmail(classId, snapId)),
  })
)(SnapModal);
