import React, { Profiler, useState, useEffect } from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./CurrentScreensPage.module.scss";
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import Page from "../../../partial/PageContainer/PageContainer";
import { MAIN_CONTENT_ID } from "../../../../consts";
import StudentTiles from "../../../partial/Highlights/StudentTiles/StudentTiles";
import { NoStudentsInfoPanel } from "../../../partial/Highlights/NoStudentsInfoPanel";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import {
  STUDENT_TILE_CONTENT_TYPE,
  CLASS_CONFIG_TYPE,
} from "../../../../state/highlights/config/types";
import {
  getClassConfig,
  getIsConfigLoaded,
  getIsConfigError,
  getIsOutsideSchoolHours,
  getScreenshotsEnabled,
} from "../../../../state/highlights/config/selectors";
import { getClassId } from "../../../../state/shared/selectors";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import OutsideSchoolHoursInfoPanel from "../../../partial/Highlights/OutsideSchoolHoursInfoPanel/OutsideSchoolHoursInfoPanel";
import { updateScreenshotWebsocketConnectionError } from "../../../../state/highlights/screenshotWebsocket/actions";
import { getScreenshotWebsocketConnectionError } from "../../../../state/highlights/screenshotWebsocket/selectors";
import { getFFByName } from "../../../../state/app/selectors";
import Toast, { TOAST_TYPES } from "@hapara/ui/src/deprecated/Toast/Toast";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import usePerformanceStatsSendHook from "../../../../hooks/usePerformanceStatsSendHook";
import { sendReactRenderStats } from "../../../../state/highlights/students/actions";
import { FormattedMessage } from "react-intl";

const VIEW_TYPES = {
  NO_STUDENTS: "NO_STUDENTS",
  OUTSIDE_SCHOOL_HOURS: "OUTSIDE_SCHOOL_HOURS",
  STUDENT_TILES: "STUDENT_TILES",
};

const CurrentScreensPage = ({
  isConfigLoaded,
  isConfigError,
  isOutsideSchoolHours,
  students,
  isScreenshotsEnabled = false,
  classId = "",
  classConfig = {},
  isScreenshotWebsocketConnectionError,
  updateWebsocketConnectionError,
  sendReactRenderStats,
  isMonitorPerformanceFF,
}) => {
  const [isConfigAvailable, setConfigAvailable] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(classConfig)) {
      setConfigAvailable(true);
    } else {
      setConfigAvailable(false);
    }
  }, [classConfig]);

  const viewType = isOutsideSchoolHours
    ? VIEW_TYPES.OUTSIDE_SCHOOL_HOURS
    : !students.length
    ? VIEW_TYPES.NO_STUDENTS
    : VIEW_TYPES.STUDENT_TILES;

  const onStudentTilesRender = usePerformanceStatsSendHook({
    componentName: "StudentTilesScreens",
    sendReactRenderStats,
    isMonitorPerformanceFF,
  });

  return (
    <>
      {isConfigAvailable && !isScreenshotsEnabled ? (
        <Redirect
          from={APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.path}
          to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY.path}?classId=${classId}`}
        />
      ) : (
        <Page
          header={<Header />}
          footer={
            <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
          }
          isPageLoaded={isConfigLoaded}
          isPageLoadingError={isConfigError}
          title={APP_PAGES.HIGHLIGHTS_CURRENT_SCREENS.title}
        >
          <ModalsAndPanels />

          <main
            className={styles.root}
            data-test-id="Hs-Pages-HighlightsCurrentScreensPage"
            id={MAIN_CONTENT_ID}
            tabIndex="-1"
          >
            <h1 className={styles.title}>
              <FormattedMessage defaultMessage="Current Screens" id="JMQ43b" />
            </h1>

            {viewType === VIEW_TYPES.OUTSIDE_SCHOOL_HOURS && (
              <OutsideSchoolHoursInfoPanel />
            )}

            {viewType === VIEW_TYPES.NO_STUDENTS && <NoStudentsInfoPanel />}

            {isMonitorPerformanceFF &&
              viewType === VIEW_TYPES.STUDENT_TILES && (
                <Profiler
                  id="StudentTilesScreens"
                  onRender={onStudentTilesRender}
                >
                  <StudentTiles
                    tileContentType={STUDENT_TILE_CONTENT_TYPE.SCREENS}
                  />
                </Profiler>
              )}

            {!isMonitorPerformanceFF &&
              viewType === VIEW_TYPES.STUDENT_TILES && (
                <StudentTiles
                  tileContentType={STUDENT_TILE_CONTENT_TYPE.SCREENS}
                />
              )}
          </main>

          <div className={styles.toast} aria-live="polite" aria-atomic="true">
            {isScreenshotWebsocketConnectionError && (
              <Toast
                icon="fill-circle-exclamation"
                type={TOAST_TYPES.ERROR}
                onClose={() => updateWebsocketConnectionError(false)}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div>
                  <FormattedMessage
                    defaultMessage="<strong>Trouble connecting.</strong> Try reloading the page."
                    id="xwMBM4"
                  />
                </div>
              </Toast>
            )}
          </div>
        </Page>
      )}
    </>
  );
};

CurrentScreensPage.propTypes = {
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  isScreenshotsEnabled: PropTypes.bool,
  classConfig: CLASS_CONFIG_TYPE,
  classId: PropTypes.string,
  isScreenshotWebsocketConnectionError: PropTypes.bool,
  updateWebsocketConnectionError: PropTypes.func.isRequired,
  sendReactRenderStats: PropTypes.func.isRequired,
  isMonitorPerformanceFF: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    students: getStudentConfigsList(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
    isScreenshotsEnabled: getScreenshotsEnabled(state),
    classId: getClassId(state),
    classConfig: getClassConfig(state),
    isScreenshotWebsocketConnectionError:
      getScreenshotWebsocketConnectionError(state),
    isMonitorPerformanceFF: getFFByName("HAP-8438-Monitor-React-Performance")(
      state
    ),
  }),
  (dispatch) => ({
    updateWebsocketConnectionError: (isError) =>
      dispatch(updateScreenshotWebsocketConnectionError(isError)),
    sendReactRenderStats: (options) => dispatch(sendReactRenderStats(options)),
  })
)(CurrentScreensPage);
