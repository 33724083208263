import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./addCardButton.module.scss";
import classnames from "classnames";
interface AddCardButtonProps {
  boardId: string;
  sectionId: string;
  columnType: string;
  dataTestIdPrefix: string;
  className?: string;
  circleClassName?: string;
  onClick?: () => void;
}
export const AddCardButton = ({
  boardId,
  sectionId,
  columnType,
  dataTestIdPrefix,
  className,
  circleClassName,
  onClick,
}: AddCardButtonProps) => {
  return (
    <button
      className={classnames(styles.root, className ?? "")}
      data-test-id={`${dataTestIdPrefix}-Add-Card`}
      onClick={onClick}
      aria-label="Add Card"
    >
      <div className={styles.bar}>
        <div
          className={classnames(styles.circle, {
            [`${circleClassName}`]: circleClassName,
          })}
        >
          <HapReactIcon svg="plus-thick" className={styles.plus} />
        </div>
      </div>
    </button>
  );
};

export default AddCardButton;
