//LIBRARIES
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

// TYPES/CONSTS
import { MAIN_CONTENT_ID } from "../../../../consts";
import { STUDENT_TILE_CONTENT_TYPE } from "../../../../state/highlights/config/types";
import { PAGE_TYPES } from "../../../../hooks/useGroups";
import { DrivePageParamTypes } from "../../../containers/Dashboard/DashboardPage/types";

// SELECTORS
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { getActiveClassesDataSource } from "../../../../state/shared/selectors";

//COMPONENTS
import DriveMainContentHeader from "./DriveMainContentHeader";
import { NoStudentsInfoPanel } from "../../Highlights/NoStudentsInfoPanel";
import StudentTiles from "../../Highlights/StudentTiles/StudentTiles";

//UITLS | OTHER
import styles from "./DriveMainContent.module.scss";
import { shouldShowFolderOption } from "./utils";
import { decodeURIString } from "@hapara/ui/src/components/utils";

const VIEW_TYPES = {
  NO_STUDENTS: "NO_STUDENTS",
  STUDENT_TILES: "STUDENT_TILES",
};

interface DriveMainContentProps {
  dataTestIdPrefix: string;
  pageType: string;
  tabName?: string;
  pageParams?: DrivePageParamTypes;
  activeClassFolderList?: string[];
  classDataSource?: string;
}

export const DriveMainContent: React.FC<DriveMainContentProps> = ({
  dataTestIdPrefix,
  pageType,
  tabName,
  pageParams,
  activeClassFolderList,
  classDataSource: classDataSourceProp,
}) => {
  const students = useSelector(getStudentConfigsList);
  const classDataSource =
    classDataSourceProp || useSelector(getActiveClassesDataSource);
  const viewType = !students.length
    ? VIEW_TYPES.NO_STUDENTS
    : VIEW_TYPES.STUDENT_TILES;
  const dataTestId =
    pageType === PAGE_TYPES.HIGHLIGHTS
      ? "Hs-Pages-HighlightsDrivePage"
      : "Db-Pages-DashboardDrivePage";

  const [activePageType, currentFolderName] = pageParams || [];
  const isFolderPage = activePageType === "folderType";
  const showFolderOption = shouldShowFolderOption(
    classDataSource,
    activeClassFolderList
  );

  return (
    <div className={styles.root}>
      <main
        className={styles.contentMain}
        data-test-id={dataTestId}
        id={MAIN_CONTENT_ID}
        tabIndex={-1}
      >
        {isFolderPage && (
          <Helmet>
            <title>
              {currentFolderName && decodeURIString(currentFolderName)} -
              Dashboard - Hāpara
            </title>
          </Helmet>
        )}
        <DriveMainContentHeader
          dataTestIdPrefix={dataTestIdPrefix}
          pageType={pageType}
          pageParams={pageParams}
        />

        {viewType === VIEW_TYPES.NO_STUDENTS && <NoStudentsInfoPanel />}
        {viewType === VIEW_TYPES.STUDENT_TILES && (
          <StudentTiles
            pageType={pageType}
            tabName={tabName}
            tileContentType={STUDENT_TILE_CONTENT_TYPE.DRIVE}
            showFolderOption={showFolderOption}
          />
        )}
      </main>
    </div>
  );
};

export default DriveMainContent;
