import { useState, useEffect } from "react";
import {
  ChatContainer,
  useChatBackground,
  ChatButton,
} from "@hapara/ui/src/components/Chat";
import { ChatFeatureFlags } from "@hapara/ui/src/components/Chat/types/state";
import { Drawer } from "@hapara/ui/src/components/Drawer";
import { AuthProvider, useAuth } from "@hapara/ui/src/providers/AuthProvider";
import {
  useFeatureFlag,
  FeatureFlagProvider,
} from "@hapara/ui/src/providers/FeatureFlagProvider";
import { getLocationQuery, getLocationPath } from "src/state/router/selectors";
import { useSelector } from "react-redux";
import { pendoTrack } from "../../../utils";

import styles from "./ChatTeacher.module.scss";

export const ChatTeacherInner = ({ classId }: { classId: string | null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedClassId, setUpdatedClassId] = useState<string | null>(null);
  const { authFetch, isAuthenticated, user, authToken } = useAuth();
  const { featureFlags } = useFeatureFlag();
  const [chatFeatureFlags, setChatFeatureFlags] = useState<ChatFeatureFlags>({
    isChatSwitchEnabled: false,
  });

  useEffect(() => {
    setUpdatedClassId(classId);
  }, [classId]);

  const { selectedClassUnread, disconnect } = useChatBackground({
    role: "teacher",
    authFetch,
    isAuthReady: isAuthenticated,
    authToken,
    userId: user?.id,
    apiUrl: process.env.REACT_APP_HAPARA_API_URL!,
    classId: updatedClassId,
    chatFeatureFlags,
    onTrackEvent: pendoTrack,
  });

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const handleOpenChat = () => setIsOpen(true);
  const handleCloseChat = () => setIsOpen(false);

  return (
    <div className={styles.container}>
      {classId ? (
        <ChatButton
          messageCount={selectedClassUnread}
          onClick={handleOpenChat}
          className={styles.chatButton}
        />
      ) : null}
      <Drawer isOpen={isOpen} onRequestClose={handleCloseChat} maxWidth={400}>
        <ChatContainer roleType="teacher" onClose={handleCloseChat} />
      </Drawer>
    </div>
  );
};

export const ChatTeacher = () => {
  const locationPath: string | undefined = useSelector(getLocationPath);
  const locationQuery: object | undefined = useSelector(getLocationQuery);
  const [classId, setClassId] = useState<string | null>(null);

  useEffect(() => {
    const isHighlightsPath = locationPath?.startsWith("/highlights");
    const classIdQueryParam = locationQuery?.["classId"];

    if (isHighlightsPath) {
      if (classIdQueryParam) {
        setClassId(classIdQueryParam);
      } else {
        const classIdPathname = locationPath
          ?.split("/")
          .find((segment) => segment.includes("@"));
        if (classIdPathname) {
          setClassId(classIdPathname);
        }
      }
    } else {
      setClassId(null);
    }
  }, [locationPath, locationQuery]);

  if (!classId) {
    return null;
  }

  return (
    <AuthProvider
      skipAuthRedirect
      haparaApiUrl={process.env.REACT_APP_HAPARA_API_URL}
      haparaAuthAppUrl={process.env.REACT_APP_HAPARA_AUTH_APP_URL}
      haparaAuthApiEndpoint={process.env.REACT_APP_HAPARA_AUTH_API_ENDPOINT}
      classId={classId}
    >
      <FeatureFlagProvider haparaApiUrl={process.env.REACT_APP_HAPARA_API_URL}>
        <ChatTeacherInner classId={classId} />
      </FeatureFlagProvider>
    </AuthProvider>
  );
};
