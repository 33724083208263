"use client";
import React from "react";
import classNames from "classnames";
import styles from "./Popover.module.scss";
import {
  useFloating,
  flip,
  autoUpdate,
  Middleware,
  shift,
  offset,
} from "@floating-ui/react-dom";
import { OutsideClick } from "../OutsideClick";

export type PopoverProps = {
  className?: string;
  style?: React.CSSProperties;
  anchor: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  offset?: number;
  onRequestClose?: () => void;
  placement?: "bottom" | "bottom-end" | "bottom-start" | "top";
};

export const Popover = ({
  className,
  style,
  anchor,
  children,
  isOpen,
  offset: customOffset,
  onRequestClose,
  placement = "bottom",
}: PopoverProps) => {
  const middleware: Middleware[] = [
    flip(),
    shift({ padding: 4 }),
    offset(customOffset ?? 4),
  ];

  const { x, y, strategy, refs } = useFloating({
    placement,
    middleware,
    whileElementsMounted: autoUpdate,
  });

  return (
    <div className={classNames(className, styles.anchor)} style={style}>
      <span ref={refs.setReference}>{anchor}</span>
      {isOpen && (
        <OutsideClick onOutsideClick={onRequestClose}>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {children}
          </div>
        </OutsideClick>
      )}
    </div>
  );
};
