import { FormattedMessage } from "react-intl";
import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { collectionItemType } from "../../../../state/library/collections/types";
import {
  createNewCollection,
  updateCollection,
  removeCollection,
  getNumberOfResourcesInCollection,
  loadLibraryCollections,
} from "../../../../state/library/collections/actions";
import {
  hideSuccessToast,
  showSuccessToast,
} from "../../../../state/app/actions";

import styles from "./CollectionActionDialog.module.scss";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import ActionDialog from "@hapara/ui/src/atomic/ActionDialog/ActionDialog";
import Input, {
  INPUT_DEFAULT_MAX_LENGTH,
} from "@hapara/ui/src/atomic/Input/Input";

export const COLLECTIONS_DIALOG_MODE = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
};

const DialogTitle = {
  [COLLECTIONS_DIALOG_MODE.CREATE]: "New collection",
  [COLLECTIONS_DIALOG_MODE.EDIT]: "Edit collection",
  [COLLECTIONS_DIALOG_MODE.DELETE]: "Delete collection?",
};

const ActionTitle = {
  [COLLECTIONS_DIALOG_MODE.CREATE]: "Add collection",
  [COLLECTIONS_DIALOG_MODE.EDIT]: "Save collection",
  [COLLECTIONS_DIALOG_MODE.DELETE]: "Delete collection",
};

const ErrorMessage = {
  [COLLECTIONS_DIALOG_MODE.CREATE]:
    "Sorry, there was a problem adding this collection. Please try again.",
  [COLLECTIONS_DIALOG_MODE.EDIT]:
    "Sorry, there was a problem saving this collection. Please try again.",
  [COLLECTIONS_DIALOG_MODE.DELETE]:
    "Sorry, there was a problem deleting this collection. Please try again.",
};

const DataTestId = {
  [COLLECTIONS_DIALOG_MODE.CREATE]: "Create",
  [COLLECTIONS_DIALOG_MODE.EDIT]: "Edit",
  [COLLECTIONS_DIALOG_MODE.DELETE]: "Delete",
};

const NUMBER_OR_AFFECTED_RESOURCES_LOADING = "...";

const CollectionActionDialog = ({
  isDialogOpen,
  closeDialog,
  editCollectionItem,
  mode = COLLECTIONS_DIALOG_MODE.CREATE,
  collections,
  loadLibraryCollections,
  createCollection,
  updateCollection,
  deleteCollection,
  getNumberOfResourcesAffected,
  showSuccessMessage,
  hideSuccessMessage,
}) => {
  const dataTestIdPrefix = "lb-Library-Collections";
  const [inputValue, setInputValue] = useState(
    editCollectionItem ? editCollectionItem.name : ""
  );
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [numberOfResourcesAffected, setNumberOfResourcesAffected] = useState(
    NUMBER_OR_AFFECTED_RESOURCES_LOADING
  );

  const sameTitleExists = useMemo(
    () =>
      !!_.find(collections, (item) => {
        return (
          item.name.toLowerCase() === inputValue.trim().toLowerCase() &&
          item.id !== _.get(editCollectionItem, "id") &&
          !item.deleted
        );
      }),
    [collections, inputValue, editCollectionItem]
  );

  useEffect(() => {
    if (mode === COLLECTIONS_DIALOG_MODE.DELETE) {
      getNumberOfResourcesAffected(_.get(editCollectionItem, "id"))
        .then((n) => {
          setNumberOfResourcesAffected(n);
        })
        .catch(() => {
          setNumberOfResourcesAffected(NUMBER_OR_AFFECTED_RESOURCES_LOADING); // ignore error, set to init value
        });
    }
  }, [mode, getNumberOfResourcesAffected, editCollectionItem]);

  const onAction = () => {
    hideSuccessMessage();
    if (mode === COLLECTIONS_DIALOG_MODE.CREATE) {
      return createCollection(inputValue.trim()).then(() => {
        closeDialog();
        loadLibraryCollections();
        showSuccessMessage();
      });
    } else if (mode === COLLECTIONS_DIALOG_MODE.EDIT) {
      return updateCollection(
        inputValue.trim(),
        _.get(editCollectionItem, "id")
      ).then(() => {
        closeDialog();
        loadLibraryCollections();
        showSuccessMessage();
      });
    } else if (mode === COLLECTIONS_DIALOG_MODE.DELETE) {
      setIsDeleteError(false);
      setIsDeleteInProgress(true);
      deleteCollection(_.get(editCollectionItem, "id"))
        .then(() => {
          closeDialog();
          loadLibraryCollections();
        })
        .catch(() => {
          setIsDeleteError(true);
        })
        .finally(() => {
          setIsDeleteInProgress(false);
        });
    }
  };

  const handleInputValueChange = (e) =>
    setInputValue(_.get(e, "target.value", ""));

  return (
    <div className={styles.root}>
      {(mode === COLLECTIONS_DIALOG_MODE.EDIT ||
        mode === COLLECTIONS_DIALOG_MODE.CREATE) && (
        <ActionDialog
          title={DialogTitle[mode]}
          actionLabel={ActionTitle[mode]}
          isOpen={isDialogOpen}
          errorMessage={ErrorMessage[mode]}
          isValidationError={
            sameTitleExists ||
            inputValue.trim() === _.get(editCollectionItem, "name") ||
            inputValue.trim().length === 0
          }
          onClose={closeDialog}
          onAction={onAction}
          dataTestIdPrefix={`${dataTestIdPrefix}-${DataTestId[mode]}Collection`}
          className={styles.actionDialog}
          isBorderHidden={true}
        >
          <div className={styles.dialogContent}>
            <Input
              type="text"
              placeholder="Enter a name..."
              data-test-id={`${dataTestIdPrefix}-${DataTestId[mode]}Collection-TitleInput`}
              value={inputValue}
              onChange={handleInputValueChange}
              className={styles.field}
              maxLength={INPUT_DEFAULT_MAX_LENGTH}
              showLimitWarning={true}
              aria-label="Collection name"
            />
            {sameTitleExists && (
              <div
                className={styles.sameTitleExists}
                aria-live="polite"
                id="error-duplicate-error"
              >
                <FormattedMessage
                  defaultMessage="This name already exists"
                  id="SWNBxA"
                />
              </div>
            )}
          </div>
        </ActionDialog>
      )}
      {mode === COLLECTIONS_DIALOG_MODE.DELETE && (
        <ActionConfirmationDialog
          dataTestPrefix={`${dataTestIdPrefix}-${DataTestId[mode]}-ConfirmationModal`}
          subTitle={_.get(editCollectionItem, "name")}
          isOpen={isDialogOpen}
          onClose={closeDialog}
          title={DialogTitle[mode]}
          isActionInProgress={isDeleteInProgress}
          content={
            <>
              When you delete a collection, the collection name will be removed,
              but the items within it ( <b>{numberOfResourcesAffected}</b> )
              will still be available. Items not in a collection can be found in
              the "Uncategorized" section.
            </>
          }
          isActionDanger={true}
          error={isDeleteError ? ErrorMessage[mode] : null}
          cancelDataTestId={`${dataTestIdPrefix}-${DataTestId[mode]}-ConfirmationModal-Button-Cancel`}
          actionLabel={ActionTitle[mode]}
          onAction={onAction}
          actionDataTestId={`${dataTestIdPrefix}-${DataTestId[mode]}-ConfirmationModal-Button-Delete`}
        />
      )}
    </div>
  );
};

CollectionActionDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  editCollectionItem: collectionItemType,
  mode: PropTypes.string,
  collections: PropTypes.arrayOf(collectionItemType),
  loadLibraryCollections: PropTypes.func.isRequired,
  createCollection: PropTypes.func.isRequired,
  updateCollection: PropTypes.func.isRequired,
  deleteCollection: PropTypes.func.isRequired,
  getNumberOfResourcesAffected: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  hideSuccessMessage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    loadLibraryCollections: () => dispatch(loadLibraryCollections()),
    createCollection: (name) => dispatch(createNewCollection(name)),
    updateCollection: (name, id) => dispatch(updateCollection(name, id)),
    deleteCollection: (id) => dispatch(removeCollection(id)),
    getNumberOfResourcesAffected: (id) =>
      dispatch(getNumberOfResourcesInCollection(id)),
    showSuccessMessage: () => dispatch(showSuccessToast()),
    hideSuccessMessage: () => dispatch(hideSuccessToast()),
  })
)(CollectionActionDialog);
