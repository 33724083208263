import React, { useState } from "react";
import _ from "lodash";
import classnames from "classnames";
import styles from "./WorkspaceShareModal.module.scss";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import {
  Button,
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  Action,
  BoardAccessStatus,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { MemorisedFocusButton } from "../../../MemorisedFocusButton/MemorisedFocusButton";
import Toggle, { TOGGLE_POSITIONS } from "@hapara/ui/src/atomic/Toggle/Toggle";
import { USER_PERMISSIONS } from "../../../../../state/user/types";

export type WorkspacePrivacySettings = {
  isVisibleGlobally: boolean;
  isVisibleToSchoolDistrict: boolean;
};

type WorkspaceShareModalProps = {
  dataTestIdPrefix: string;
  closeModalAction: () => void;
  permissions: Action[];
  boardAccessStatus: BoardAccessStatus | undefined;
  userPermissions: string[];
  privacySettings: WorkspacePrivacySettings;
};

export const WorkspaceShareModal = ({
  dataTestIdPrefix,
  closeModalAction,
  permissions,
  boardAccessStatus,
  userPermissions,
  privacySettings,
}: WorkspaceShareModalProps) => {
  const [accessStatus, setAccessStatus] = useState(boardAccessStatus);
  const [isVisibleGlobally, setVisibleGlobally] = useState(
    privacySettings.isVisibleGlobally
  );
  const [isVisibleToDomain, setVisibleToDomain] = useState(
    privacySettings.isVisibleToSchoolDistrict
  );
  const isEditible = permissions.includes(Action.CanEdit);

  const hasLibraryAccess =
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_WORKSPACE) ||
    _.includes(userPermissions, USER_PERMISSIONS.LIBRARY_RESOURCES);

  //Toogle to indicate user has altered the content
  const hasContentChanged = () => {
    return !_.isEqual(boardAccessStatus, accessStatus) ||
      !_.isEqual(privacySettings.isVisibleGlobally, isVisibleGlobally) ||
      !_.isEqual(privacySettings.isVisibleToSchoolDistrict, isVisibleToDomain)
      ? true
      : false;
  };

  const clickHandler = (status: BoardAccessStatus) => {
    setAccessStatus(status);
  };

  const applySvgIcon = (status: BoardAccessStatus): string => {
    return accessStatus === status ? "circle-check-fill" : "circle-empty";
  };

  const getAltText = (
    dataTestIdPrefix: string,
    label: string,
    status: BoardAccessStatus
  ): string => {
    const checkedStatus = accessStatus === status ? "checked" : "unchecked";
    return `${dataTestIdPrefix}-ShareModal-${label}-${checkedStatus}`;
  };

  const LABEL_DRAFT = "Draft";
  const LABEL_PUBLISHED = "Published";

  const StatusButton = (
    status: BoardAccessStatus,
    dataTestIdPrefix: string
  ) => {
    const label: string =
      status === BoardAccessStatus.Draft ? LABEL_DRAFT : LABEL_PUBLISHED;
    return (
      <MemorisedFocusButton
        className={classnames(styles.accessTile, {
          [styles.selected]: status === accessStatus,
        })}
        onClick={() => clickHandler(status)}
        data-test-id={`${dataTestIdPrefix}-ShareModal-Button-${label}`}
        label={
          <div className={styles.wrapper}>
            <div
              className={styles.iconWrapper}
              data-test-id={getAltText(dataTestIdPrefix, label, status)}
            >
              <HapReactIcon svg={applySvgIcon(status)} width="16" height="16" />
            </div>
            <div className={styles.buttonBody}>
              <HapReactIcon
                svg={label === LABEL_DRAFT ? "lock-fill" : "icon_group"}
                width="20"
                height="20"
                className={styles.icon}
              />
              <span className={styles.titleText}>{label}</span>
              <span className={styles.descText}>
                {label === LABEL_DRAFT
                  ? "Only teachers can access"
                  : "Teachers & students can access"}
              </span>
            </div>
          </div>
        }
      ></MemorisedFocusButton>
    );
  };

  const VISIBLE_GLOBALLY = "Visible globally";
  const toggleDescription = (title: string): string => {
    return `Educators can access this Workspace in ${
      title === VISIBLE_GLOBALLY ? "Discover" : "Our Library"
    }`;
  };

  const onChangeHandler = (title: string, value: boolean) => {
    title === VISIBLE_GLOBALLY
      ? setVisibleGlobally(value)
      : setVisibleToDomain(value);
  };

  const toggleTile = (title: string, dataTestIdPrefix: string) => {
    return (
      <div
        className={classnames(styles.toggleTile, {
          [styles.hasPrivateLibraryAccess]:
            hasLibraryAccess && title !== VISIBLE_GLOBALLY,
        })}
      >
        <div className={styles.toggleText}>
          <span className={styles.toggleTitle}>{title}</span>
          <span className={styles.toggleDesc}>{toggleDescription(title)}</span>
        </div>
        <Toggle
          className={styles.toggle}
          explanatoryText={toggleDescription(title)}
          hideExplanatoryText={true}
          positionOfToggle={TOGGLE_POSITIONS.RIGHT}
          value={
            title === VISIBLE_GLOBALLY ? isVisibleGlobally : isVisibleToDomain
          }
          onChange={(value) => onChangeHandler(title, value)}
          dataTestIdPrefix={dataTestIdPrefix}
        />
      </div>
    );
  };

  const getAriaChecked = (status: BoardAccessStatus): boolean => {
    return accessStatus === status ? true : false;
  };

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={closeModalAction}
      classNameContent={styles.modal}
      className={styles.modalContent}
      dataTestPrefix={`${dataTestIdPrefix}-Share`}
      contentLabel="Board Share details"
      title="Share"
      classNameHeader={styles.modalTitle}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.root}>
        <div className={styles.boardSettings}>
          <div className={styles.boardStatus}>
            <span className={styles.currentStatus}>Current status</span>
            <div className={styles.radioGroupContainer}>
              <ul>
                <li
                  role="checkbox"
                  aria-checked={getAriaChecked(BoardAccessStatus.Draft)}
                >
                  {StatusButton(BoardAccessStatus.Draft, dataTestIdPrefix)}
                </li>
                <li
                  role="checkbox"
                  aria-checked={getAriaChecked(BoardAccessStatus.Published)}
                >
                  {StatusButton(BoardAccessStatus.Published, dataTestIdPrefix)}
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.visibilityContainer}>
            <span className={styles.privacySettingsLabel}>
              Privacy settings
            </span>
            <span className={styles.privacySettingsDesc}>
              You can set this Workspace to be visible by non-members too.
              Non-members cannot edit or view student information.
            </span>
            <div className={styles.toggleContainer}>
              {toggleTile(
                VISIBLE_GLOBALLY,
                `${dataTestIdPrefix}-ShareModal-VisibleGlobally`
              )}
              {hasLibraryAccess &&
                toggleTile(
                  "Visible to your school/district",
                  `${dataTestIdPrefix}-ShareModal-VisibleToSchoolDistrict`
                )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {bottomButtons(
            isEditible,
            hasContentChanged(),
            dataTestIdPrefix,
            closeModalAction
          )}
        </div>
      </div>
    </ModalDeprecated>
  );
};

const bottomButtons = (
  isEditible: boolean,
  hasContentChanged: boolean,
  dataTestIdPrefix: string,
  closeModalAction: () => void
) => {
  if (isEditible) {
    return (
      <div className={styles.buttonContainer}>
        {copyLinkButton(dataTestIdPrefix)}
        {cancelButton(dataTestIdPrefix, closeModalAction)}
        {saveCloseButton(dataTestIdPrefix, hasContentChanged)}
      </div>
    );
  }
  return;
};

const saveCloseButton = (
  dataTestIdPrefix: string,
  hasContentChanged: boolean
) => {
  return (
    <Button
      type={BUTTON_TYPES.PRIMARY}
      aria-label="Save"
      data-test-id={`${dataTestIdPrefix}-ShareModal-Button-Save`}
      label="Save"
      isDisabled={!hasContentChanged}
      onClick={() => {}}
    />
  );
};

const copyLinkButton = (dataTestIdPrefix: string) => {
  return (
    <Button
      icon="link"
      type={BUTTON_TYPES.OUTLINED}
      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
      aria-label="Copy link"
      data-test-id={`${dataTestIdPrefix}-ShareModal-Button-CopyLink`}
      label="Copy link"
      className={styles.copyLinkButton}
    />
  );
};

const cancelButton = (
  dataTestIdPrefix: string,
  closeModalAction: () => void
) => {
  return (
    <Button
      type={BUTTON_TYPES.SECONDARY}
      aria-label="Cancel"
      data-test-id={`${dataTestIdPrefix}-ShareModal-Button-Cancel`}
      label="Cancel"
      className={styles.cancelButton}
      onClick={closeModalAction}
    />
  );
};
export default WorkspaceShareModal;
