import { useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./ToolbarSort.module.scss";
import Tippy from "@tippy.js/react";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { STUDENT_ORDER_TYPES } from "../../../../state/highlights/config/types";
import { getStudentsOrderType } from "../../../../state/highlights/config/selectors";
import { updateStudentsOrder } from "../../../../state/highlights/config/actions";
import { getClassId } from "../../../../state/shared/selectors";
import { useIntl } from "react-intl";

const ToolbarSort = ({
  studentOrderType,
  selectedClassId,
  updateStudentsOrder,
  dataTestIdPrefix,
  dropDownList,
  hasNavBarButtonUpdateFlag,
  canSortAlphabeticallyOnly = false,
}) => {
  const triggerButtonRef = useRef();
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={(props) => (
          <Tippy
            content={intl.formatMessage({
              defaultMessage: "Sort",
              id: "25oM9Q",
            })}
            theme="hsuite"
            maxWidth={162}
          >
            <Button
              label={
                hasNavBarButtonUpdateFlag
                  ? null
                  : intl.formatMessage({
                      defaultMessage: "Sort",
                      id: "25oM9Q",
                    })
              }
              icon="arrow-up-down"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              rightIcon={hasNavBarButtonUpdateFlag ? null : "arrow-carvet-down"}
              dataTestId={`${dataTestIdPrefix}-Toolbar-SortDropdown-TriggerButton`}
              aria-label={
                canSortAlphabeticallyOnly
                  ? intl.formatMessage({
                      defaultMessage: "Sort alphabetically",
                      id: "T2kSGl",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Sort alphabetically or by online status",
                      id: "QhGwXV",
                    })
              }
              ref={triggerButtonRef}
              {...props}
            />
          </Tippy>
        )}
        itemComponentList={_.map(
          dropDownList,
          (item) =>
            ({ onClick, className, ...rest }) => {
              const isSelected = item.type === studentOrderType;
              return (
                <button
                  type="button"
                  className={classnames(className, styles.dropdownContentItem, {
                    [styles.dropdownContentItemSelected]: isSelected,
                  })}
                  data-test-id={`${dataTestIdPrefix}-Toolbar-SortDropdown-Option-${_.toLower(
                    item.type
                  )}`}
                  onClick={() => {
                    updateStudentsOrder({
                      type: item.type,
                      customOrder: [],
                      classId: selectedClassId,
                    });
                    onClick();
                    _.delay(() => triggerButtonRef.current.focus(), 100);
                  }}
                  key={item.type}
                  aria-label={
                    isSelected
                      ? intl.formatMessage(
                          {
                            defaultMessage: "{itemLabel}, selected",
                            id: "YAmCem",
                          },
                          {
                            itemLabel: item.label,
                          }
                        )
                      : item.label
                  }
                  {...rest}
                >
                  {item.label}
                </button>
              );
            }
        )}
      />
    </div>
  );
};

ToolbarSort.propTypes = {
  studentOrderType: PropTypes.oneOf(_.values(STUDENT_ORDER_TYPES)).isRequired,
  selectedClassId: PropTypes.string,
  updateStudentsOrder: PropTypes.func.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  dropDownList: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  canSortAlphabeticallyOnly: PropTypes.bool,
  hasNavBarButtonUpdateFlag: PropTypes.bool,
};

export default connect(
  (state) => ({
    studentOrderType: getStudentsOrderType(state),
    selectedClassId: getClassId(state),
  }),
  (dispatch) => ({
    updateStudentsOrder: ({ type, customOrder, classId }) =>
      dispatch(updateStudentsOrder({ type, customOrder, classId })),
  })
)(ToolbarSort);
