import React from "react";
import { ChatAvatar } from "../ChatAvatar";
import styles from "./ChatMessage.module.scss";
import classnames from "classnames";
import { IntlDateTimeUtc } from "../../../../intl/dateTime";
import { FormattedMessage } from "react-intl";
import { Alert } from "../../../../atomic/Alert";

type ChatMessageProps = {
  firstName?: string;
  lastName?: string;
  message: string;
  isOwnMessage?: boolean;
  timestamp: string;
  isLastReadMessage?: boolean;
  status?: "pending" | "sent" | "error" | "read";
};

export const ChatMessage = ({
  message,
  isOwnMessage,
  firstName,
  lastName,
  timestamp,
  isLastReadMessage,
  status,
}: ChatMessageProps) => (
  <div
    className={classnames(styles.container, {
      [styles.ownMessage]: isOwnMessage,
    })}
  >
    {!isOwnMessage ? (
      <ChatAvatar firstName={firstName} lastName={lastName} />
    ) : null}
    <div className={styles.message}>
      <IntlDateTimeUtc value={timestamp} className={styles.timestamp} />
      <div className={styles.bubble}>{message}</div>
      {isLastReadMessage || status === "error" ? (
        <div className={styles.status}>
          {isLastReadMessage ? (
            <FormattedMessage
              defaultMessage="Read"
              id="5WPzCJ"
              description="Label indicating that a chat message has been read by the receiver."
            />
          ) : status === "error" ? (
            <Alert type="danger" size="sm" inline>
              <FormattedMessage
                defaultMessage="Message failed to send"
                id="Gw8njx"
              />
            </Alert>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);
