const actionTypes = {
  LOCATION_CHANGE: "@@router/LOCATION_CHANGE",

  APP_USER_INIT_PENDING: "APP_USER_INIT_PENDING",
  APP_USER_INIT_SUCCESS: "APP_USER_INIT_SUCCESS",
  APP_USER_INIT_ERROR: "APP_USER_INIT_ERROR",

  APP_USER_INIT_CONFIG_PENDING: "APP_USER_INIT_CONFIG_PENDING",
  APP_USER_INIT_CONFIG_SUCCESS: "APP_USER_INIT_CONFIG_SUCCESS",
  APP_USER_INIT_CONFIG_ERROR: "APP_USER_INIT_CONFIG_ERROR",

  APP_GIS_LOAD_PENDING: "APP_GIS_LOAD_PENDING",
  APP_GIS_LOAD_SUCCESS: "APP_GIS_LOAD_SUCCESS",
  APP_GIS_LOAD_ERROR: "APP_GIS_LOAD_ERROR",

  APP_ERROR_UPDATE: "APP_ERROR_UPDATE",
  APP_SUCCESS_TOAST_UPDATE: "APP_SUCCESS_TOAST_UPDATE",
  APP_INIT_LOAD_ERROR_UPDATE: "APP_INIT_LOAD_ERROR_UPDATE",
  APP_ACCESS_ERROR_UPDATE: "APP_ACCESS_ERROR_UPDATE",
  APP_WINDOW_SIZE_UPDATE: "APP_WINDOW_SIZE_UPDATE",

  USER_DETAILS_UPDATED: "USER_DETAILS_UPDATED",
  USER_LOGGED_IN: "USER_LOGGED_IN",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",
  USER_UNKNOWN: "USER_UNKNOWN",
  USER_UNAUTHORISED_ACCESS: "USER_UNAUTHORISED_ACCESS",
  USER_PREFS_SAVE_ERROR: "USER_PREFS_SAVE_ERROR",
  USER_PREFS_SAVE_SUCCESS: "USER_PREFS_SAVE_SUCCESS",
  USER_LABS_PREFS_SAVE_ERROR: "USER_LABS_PREFS_SAVE_ERROR",
  USER_LABS_PREFS_SAVE: "USER_LABS_PREFS_SAVE",

  USER_EMAIL_FORMAT_LOAD_SUCCESS: "USER_EMAIL_FORMAT_LOAD_SUCCESS",
  USER_EMAIL_FORMAT_LOAD_ERROR: "USER_EMAIL_FORMAT_LOAD_ERROR",

  USER_CLASS_LIST_LOAD_SUCCESS: "USER_CLASS_LIST_LOAD_SUCCESS",
  USER_CLASS_LIST_LOAD_ERROR: "USER_CLASS_LIST_LOAD_ERROR",
  USER_CLASS_LIST_RESET: "USER_CLASS_LIST_RESET",

  NAVIGATION_UPDATE_ITEMS: "NAVIGATION_UPDATE_ITEMS",

  HEADER_TOGGLE: "HEADER_TOGGLE",

  // ---------------------------------------------------------------------------
  // MY WORKSPACES
  // ---------------------------------------------------------------------------

  MYWORKSPACES_LOAD_PENDING: "MYWORKSPACES_LOAD_PENDING",
  MYWORKSPACES_LOAD_SUCCESS: "MYWORKSPACES_LOAD_SUCCESS",
  MYWORKSPACES_LOAD_ERROR: "MYWORKSPACES_LOAD_ERROR",
  MYWORKSPACES_ACTION_PENDING: "MYWORKSPACES_ACTION_PENDING",
  MYWORKSPACES_ACTION_SUCCESS: "MYWORKSPACES_ACTION_SUCCESS",
  MYWORKSPACES_ACTION_ERROR: "MYWORKSPACES_ACTION_ERROR",
  MYWORKSPACES_DISMISS_ERROR: "MYWORKSPACES_DISMISS_ERROR",

  MYWORKSPACES_CLEAR: "MYWORKSPACES_CLEAR",
  MYWORKSPACES_LEFT_PANEL_UPDATE: "MYWORKSPACES_LEFT_PANEL_UPDATE",
  MYWORKSPACES_RIGHT_PANEL_UPDATE: "MYWORKSPACES_RIGHT_PANEL_UPDATE",

  MYWORKSPACES_ACTIVE_ITEM_LOAD_PENDING:
    "MYWORKSPACES_ACTIVE_ITEM_LOAD_PENDING",
  MYWORKSPACES_ACTIVE_ITEM_LOAD_SUCCESS:
    "MYWORKSPACES_ACTIVE_ITEM_LOAD_SUCCESS",
  MYWORKSPACES_ACTIVE_ITEM_LOAD_ERROR: "MYWORKSPACES_ACTIVE_ITEM_LOAD_ERROR",

  MYWORKSPACES_SEARCH_LOAD_PENDING: "MYWORKSPACES_SEARCH_LOAD_PENDING",
  MYWORKSPACES_SEARCH_LOAD_SUCCESS: "MYWORKSPACES_SEARCH_LOAD_SUCCESS",
  MYWORKSPACES_SEARCH_LOAD_ERROR: "MYWORKSPACES_SEARCH_LOAD_ERROR",
  MYWORKSPACES_SEARCH_CLEAR: "MYWORKSPACES_SEARCH_CLEAR",

  MYWORKSPACES_LABELS_LOAD_PENDING: "MYWORKSPACES_LABELS_LOAD_PENDING",
  MYWORKSPACES_LABELS_LOAD_SUCCESS: "MYWORKSPACES_LABELS_LOAD_SUCCESS",
  MYWORKSPACES_LABELS_LOAD_ERROR: "MYWORKSPACES_LABELS_LOAD_ERROR",
  MYWORKSPACES_LABELS_ACTION_PENDING: "MYWORKSPACES_LABELS_ACTION_PENDING",
  MYWORKSPACES_LABELS_ACTION_SUCCESS: "MYWORKSPACES_LABELS_ACTION_SUCCESS",
  MYWORKSPACES_LABELS_ACTION_ERROR: "MYWORKSPACES_LABELS_ACTION_ERROR",
  MYWORKSPACES_LABELS_DISMISS_ERROR: "MYWORKSPACES_LABELS_DISMISS_ERROR",
  MYWORKSPACES_LABELS_CLEAR: "MYWORKSPACES_LABELS_CLEAR",

  MY_CLASSES_LOAD_PENDING: "MY_CLASSES_LOAD_PENDING",
  MY_CLASSES_LOAD_SUCCESS: "MY_CLASSES_LOAD_SUCCESS",
  MY_CLASSES_LOAD_ERROR: "MY_CLASSES_LOAD_ERROR",

  MYWORKSPACES_THUMBNAILS_FETCH_PENDING:
    "MYWORKSPACES_THUMBNAILS_FETCH_PENDING",
  MYWORKSPACES_THUMBNAILS_FETCH_SUCCESS:
    "MYWORKSPACES_THUMBNAILS_FETCH_SUCCESS",
  MYWORKSPACES_THUMBNAILS_FETCH_ERROR: "MYWORKSPACES_THUMBNAILS_FETCH_ERROR",
  MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL:
    "MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL",
  MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_PENDING:
    "MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_PENDING",
  MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_SUCCESS:
    "MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_SUCCESS",
  MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_ERROR:
    "MYWORKSPACES_THUMBNAILS_UPDATE_THUMBNAIL_ERROR",
  MYWORKSPACES_THUMBNAILS_UPDATE_CURRENT_PAGE:
    "MYWORKSPACES_THUMBNAILS_UPDATE_CURRENT_PAGE",

  MYWORKSPACES_ARTIFACT_UPDATE_MIMETYPE:
    "MYWORKSPACES_ARTIFACT_UPDATE_MIMETYPE",

  MYWORKSPACES_SEARCH_GUID_UPDATE: "MYWORKSPACES_SEARCH_GUID_UPDATE",

  URL_PARAM_UPDATE_CATEGORY: "URL_PARAM_UPDATE_CATEGORY",

  // ---------------------------------------------------------------------------
  // RESOURCES
  // ---------------------------------------------------------------------------

  RESOURCES_SHOW_RESOURCE_DETAILS_MODAL:
    "RESOURCES_SHOW_RESOURCE_DETAILS_MODAL",
  RESOURCES_HIDE_RESOURCE_DETAILS_MODAL:
    "RESOURCES_HIDE_RESOURCE_DETAILS_MODAL",
  RESOURCES_UPDATE_RESOURCE_DETAILS_MODAL_MODE:
    "RESOURCES_UPDATE_RESOURCE_DETAILS_MODAL_MODE",
  RESOURCES_SHOW_RESOURCE_ASSIGN_CLASS_MODAL:
    "RESOURCES_SHOW_RESOURCE_ASSIGN_CLASS_MODAL",
  RESOURCES_HIDE_RESOURCE_ASSIGN_CLASS_MODAL:
    "RESOURCES_HIDE_RESOURCE_ASSIGN_CLASS_MODAL",
  RESOURCES_SHOW_RESOURCE_ADD_TO_COLLECTIONS_MODAL:
    "RESOURCES_SHOW_RESOURCE_ADD_TO_COLLECTIONS_MODAL",
  RESOURCES_HIDE_RESOURCE_ADD_TO_COLLECTIONS_MODAL:
    "RESOURCES_HIDE_RESOURCE_ADD_TO_COLLECTIONS_MODAL",
  RESOURCES_SHOW_RESOURCE_REMOVE_FROM_LIBRARY_MODAL:
    "RESOURCES_SHOW_RESOURCE_REMOVE_FROM_LIBRARY_MODAL",
  RESOURCES_HIDE_RESOURCE_REMOVE_FROM_LIBRARY_MODAL:
    "RESOURCES_HIDE_RESOURCE_REMOVE_FROM_LIBRARY_MODAL",
  RESOURCES_SHOW_RESOURCE_DELETE_MODAL: "RESOURCES_SHOW_RESOURCE_DELETE_MODAL",
  RESOURCES_HIDE_RESOURCE_DELETE_MODAL: "RESOURCES_HIDE_RESOURCE_DELETE_MODAL",

  RESOURCES_MARK_AS_ADDED_TO_LIBRARY: "RESOURCES_MARK_AS_ADDED_TO_LIBRARY",
  RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY:
    "RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY",

  RESOURCES_MARK_AS_STARRED: "RESOURCES_MARK_AS_STARRED",
  RESOURCES_MARK_AS_UNSTARRED: "RESOURCES_MARK_AS_UNSTARRED",

  RESOURCES_FILTERS_LOAD_PENDING: "RESOURCES_FILTERS_LOAD_PENDING",
  RESOURCES_FILTERS_LOAD_SUCCESS: "RESOURCES_FILTERS_LOAD_SUCCESS",
  RESOURCES_FILTERS_LOAD_ERROR: "RESOURCES_FILTERS_LOAD_ERROR",

  RESOURCES_FILTERS_LEFT_PANEL_UPDATE: "RESOURCES_FILTERS_LEFT_PANEL_UPDATE",
  RESOURCES_UPDATE_RESOURCE_AFTER_EDIT: "RESOURCES_UPDATE_RESOURCE_AFTER_EDIT",

  // ---------------------------------------------------------------------------
  // WORKSPACE DISCOVER
  // ---------------------------------------------------------------------------

  DISCOVER_LOAD_PENDING: "DISCOVER_LOAD_PENDING",
  DISCOVER_LOAD_SUCCESS: "DISCOVER_LOAD_SUCCESS",
  DISCOVER_LOAD_ERROR: "DISCOVER_LOAD_ERROR",
  DISCOVER_CLEAR: "DISCOVER_CLEAR",
  DISCOVER_LEFT_PANEL_UPDATE: "DISCOVER_LEFT_PANEL_UPDATE",
  DISCOVER_RIGHT_PANEL_UPDATE: "DISCOVER_RIGHT_PANEL_UPDATE",
  DISCOVER_SEARCH_GUID_UPDATE: "DISCOVER_SEARCH_GUID_UPDATE",

  // ---------------------------------------------------------------------------
  // LIBRARY LIBRARY
  // ---------------------------------------------------------------------------

  LIBRARY_LIBRARY_ITEMS_LOAD_PENDING: "LIBRARY_LIBRARY_ITEMS_LOAD_PENDING",
  LIBRARY_LIBRARY_ITEMS_LOAD_SUCCESS: "LIBRARY_LIBRARY_ITEMS_LOAD_SUCCESS",
  LIBRARY_LIBRARY_RESET_ITEMS: "LIBRARY_LIBRARY_RESET_ITEMS",

  LIBRARY_LIBRARY_LEFT_PANEL_UPDATE: "LIBRARY_LIBRARY_LEFT_PANEL_UPDATE",

  LIBRARY_LIBRARY_SEARCH_GUID_UPDATE: "LIBRARY_LIBRARY_SEARCH_GUID_UPDATE",

  // ---------------------------------------------------------------------------
  // LIBRARY UPLOAD
  // ---------------------------------------------------------------------------

  LIBRARY_LAST_UPLOADED_FILE_UPDATE: "LIBRARY_LAST_UPLOADED_FILE_UPDATE",

  // ---------------------------------------------------------------------------
  // LIBRARY COLLECTIONS
  // ---------------------------------------------------------------------------

  LIBRARY_COLLECTIONS_LOAD_PENDING: "LIBRARY_COLLECTIONS_LOAD_PENDING",
  LIBRARY_COLLECTIONS_LOAD_ERROR: "LIBRARY_COLLECTIONS_LOAD_ERROR",
  LIBRARY_COLLECTIONS_LOAD_SUCCESS: "LIBRARY_COLLECTIONS_LOAD_SUCCESS",
  LIBRARY_COLLECTION_SET_EDIT_MODE: "LIBRARY_COLLECTION_SET_EDIT_MODE",

  // ---------------------------------------------------------------------------
  // LIBRARY DISCOVER
  // ---------------------------------------------------------------------------

  LIBRARY_DISCOVER_ITEMS_LOAD_PENDING: "LIBRARY_DISCOVER_ITEMS_LOAD_PENDING",
  LIBRARY_DISCOVER_ITEMS_LOAD_SUCCESS: "LIBRARY_DISCOVER_ITEMS_LOAD_SUCCESS",
  LIBRARY_DISCOVER_RESET_ITEMS: "LIBRARY_DISCOVER_RESET_ITEMS",
  LIBRARY_DISCOVER_LEFT_PANEL_UPDATE: "LIBRARY_DISCOVER_LEFT_PANEL_UPDATE",
  LIBRARY_DISCOVER_SEARCH_GUID_UPDATE: "LIBRARY_DISCOVER_SEARCH_GUID_UPDATE",

  LIBRARY_CONFIG_LOAD_PENDING: "LIBRARY_CONFIG_LOAD_PENDING",
  LIBRARY_CONFIG_LOAD_SUCCESS: "LIBRARY_CONFIG_LOAD_SUCCESS",
  LIBRARY_CONFIG_LOAD_ERROR: "LIBRARY_CONFIG_LOAD_ERROR",

  // ---------------------------------------------------------------------------
  // LIBRARY MY RESOURCES
  // ---------------------------------------------------------------------------

  MY_RESOURCES_LEFT_PANEL_UPDATE: "MY_RESOURCES_LEFT_PANEL_UPDATE",

  LIBRARY_MY_RESOURCES_ITEMS_LOAD_PENDING:
    "LIBRARY_MY_RESOURCES_ITEMS_LOAD_PENDING",
  LIBRARY_MY_RESOURCES_ITEMS_LOAD_SUCCESS:
    "LIBRARY_MY_RESOURCES_ITEMS_LOAD_SUCCESS",
  LIBRARY_MY_RESOURCES_RESET_ITEMS: "LIBRARY_MY_RESOURCES_RESET_ITEMS",

  LIBRARY_MY_RESOURCES_SEARCH_GUID_UPDATE:
    "LIBRARY_MY_RESOURCES_SEARCH_GUID_UPDATE",

  // ---------------------------------------------------------------------------
  // GRADING
  // ---------------------------------------------------------------------------

  URL_PARAM_UPDATE_GRADING_CARD_ID: "URL_PARAM_UPDATE_GRADING_CARD_ID",
  URL_PARAM_UPDATE_GRADING_ACTIVITY: "URL_PARAM_UPDATE_GRADING_ACTIVITY",
  URL_PARAM_UPDATE_ARTEFACT: "URL_PARAM_UPDATE_ARTEFACT",

  GRADING_CARD_LOAD_PENDING: "GRADING_CARD_LOAD_PENDING",
  GRADING_CARD_LOAD_SUCCESS: "GRADING_CARD_LOAD_SUCCESS",
  GRADING_CARD_LOAD_ERROR: "GRADING_CARD_LOAD_ERROR",
  GRADING_CARD_NOT_VALID: "GRADING_CARD_NOT_VALID",

  GRADING_ASSIGNEE_LOAD_PENDING: "GRADING_ASSIGNEE_LOAD_PENDING",
  GRADING_ASSIGNEE_LOAD_SUCCESS: "GRADING_ASSIGNEE_LOAD_SUCCESS",

  GRADING_AUTO_SAVING_IN_PROGRESS: "GRADING_AUTO_SAVING_IN_PROGRESS",
  GRADING_AUTO_SAVING_ERROR: "GRADING_AUTO_SAVING_ERROR",

  GRADING_ARTEFACT_NOT_VALID: "GRADING_ARTEFACT_NOT_VALID",
  GRADING_ACTIVITY_DONE: "GRADING_ACTIVITY_DONE",
  GRADING_ACTION_IN_PROGRESS: "GRADING_ACTION_IN_PROGRESS",
  GRADING_ACTION_SUCCESS: "GRADING_ACTION_SUCCESS",
  GRADING_ACTION_ERROR: "GRADING_ACTION_ERROR",
  GRADING_RESET_ACTION_ERROR: "GRADING_RESET_ACTION_ERROR",
  GRADING_UPDATE_CAUSED_ACTION: "GRADING_UPDATE_CAUSED_ACTION",

  GRADING_HISTORY_UPDATE_IN_PROGRESS: "GRADING_HISTORY_UPDATE_IN_PROGRESS",
  GRADING_HISTORY_UPDATE_SUCCESS: "GRADING_HISTORY_UPDATE_SUCCESS",
  GRADING_HISTORY_UPDATE_ERROR: "GRADING_HISTORY_UPDATE_ERROR",
  GRADING_RESET_HISTORY_UPDATE_ERROR: "GRADING_RESET_HISTORY_UPDATE_ERROR",

  GRADING_BULK_ACTION_INIT_IN_PROGRESS: "GRADING_BULK_ACTION_INIT_IN_PROGRESS",
  GRADING_BULK_ACTION_INIT_ERROR: "GRADING_BULK_ACTION_INIT_ERROR",
  GRADING_BULK_RETURN_ACTION_IN_PROGRESS:
    "GRADING_BULK_RETURN_ACTION_IN_PROGRESS",
  GRADING_BULK_RETURN_ACTION_ERROR: "GRADING_BULK_RETURN_ACTION_ERROR",
  GRADING_BULK_SUBMIT_ACTION_IN_PROGRESS:
    "GRADING_BULK_SUBMIT_ACTION_IN_PROGRESS",
  GRADING_BULK_SUBMIT_ACTION_ERROR: "GRADING_BULK_SUBMIT_ACTION_ERROR",

  // ---------------------------------------------------------------------------
  // CREATE
  // ---------------------------------------------------------------------------

  CREATE_WORKSPACE_INIT_PENDING: "CREATE_WORKSPACE_INIT_PENDING",
  CREATE_WORKSPACE_INIT_SUCCESS: "CREATE_WORKSPACE_INIT_SUCCESS",
  CREATE_WORKSPACE_INIT_ERROR: "CREATE_WORKSPACE_INIT_ERROR",
  CREATE_WORKSPACE_PENDING: "CREATE_WORKSPACE_PENDING",
  CREATE_WORKSPACE_SUCCESS: "CREATE_WORKSPACE_SUCCESS",
  CREATE_WORKSPACE_ERROR: "CREATE_WORKSPACE_ERROR",
  CREATE_WORKSPACE_CLEAR: "CREATE_WORKSPACE_CLEAR",

  // ---------------------------------------------------------------------------
  // PROFESSIONAL LEARNING
  // ---------------------------------------------------------------------------

  PL_WORKSPACES_PENDING: "PL_WORKSPACES_PENDING",
  PL_WORKSPACES_SUCCESS: "PL_WORKSPACE_SUCCESS",
  PL_WORKSPACES_ERROR: "PL_WORKSPACE_ERROR",

  PL_SEARCH_WORKSPACES_PENDING: "PL_SEARCH_WORKSPACES_PENDING",
  PL_SEARCH_WORKSPACES_SUCCESS: "PL_SEARCH_WORKSPACE_SUCCESS",
  PL_SEARCH_WORKSPACES_ERROR: "PL_SEARCH_WORKSPACE_ERROR",
  PL_SEARCH_WORKSPACES_CLEAR: "PL_SEARCH_WORKSPACE_CLEAR",

  // ---------------------------------------------------------------------------
  // DASHBOARD
  // ----------------------------------------------------------------------------

  DASHBOARD_CONFIG_LOAD_PENDING: "DASHBOARD_CONFIG_LOAD_PENDING",
  DASHBOARD_CONFIG_LOAD_ERROR: "DASHBOARD_CONFIG_LOAD_ERROR",
  DASHBOARD_CONFIG_LOAD_SUCCESS: "DASHBOARD_CONFIG_LOAD_SUCCESS",

  DASHBOARD_CLASS_LIST_PENDING: "DASHBOARD_CLASS_LIST_PENDING",
  DASHBOARD_CLASS_LIST_ERROR: "DASHBOARD_CLASS_LIST_ERROR",
  DASHBOARD_CLASS_LIST_SUCCESS: "DASHBOARD_CLASS_LIST_SUCCESS",
  DASHBOARD_CLASS_LIST_SYNC_SUCCESS: "DASHBOARD_CLASS_LIST_SYNC_SUCCESS",

  DASHBOARD_MANAGE_CLASSES_CLASS_COLOUR_UPDATED:
    "DASHBOARD_MANAGE_CLASSES_CLASS_COLOUR_UPDATED",
  DASHBOARD_MANAGE_CLASSES_CLASS_STAR_UPDATED:
    "DASHBOARD_MANAGE_CLASSES_CLASS_STAR_UPDATED",

  DASHBOARD_MANAGE_CLASSES_CLASS_ADDED: "DASHBOARD_MANAGE_CLASSES_CLASS_ADDED",
  DASHBOARD_MANAGE_CLASSES_GC_CLASSES_SYNCED:
    "DASHBOARD_MANAGE_CLASSES_GC_CLASSES_SYNCED",
  DASHBOARD_MANAGE_CLASSES_CLASS_PRIORITY_UPDATED:
    "DASHBOARD_MANAGE_CLASSES_CLASS_PRIORITY_UPDATED",

  DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_OPEN:
    "DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_OPEN",
  DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_CLOSE:
    "DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_CLOSE",

  DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_OPEN:
    "DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_OPEN",
  DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_CLOSE:
    "DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_CLOSE",

  DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_OPEN:
    "DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_OPEN",
  DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_CLOSE:
    "DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_CLOSE",

  DASHBOARD_MANAGE_CLASSES_SET_SEARCH_CLASSES_QUERY:
    "DASHBOARD_MANAGE_CLASSES_SET_SEARCH_CLASSES_QUERY",

  DASHBOARD_CLASS_DATA_LOAD_PENDING: "DASHBOARD_CLASS_DATA_LOAD_PENDING",
  DASHBOARD_CLASS_DATA_LOAD_SUCCESS: "DASHBOARD_CLASS_DATA_LOAD_SUCCESS",
  DASHBOARD_CLASS_DATA_LOAD_ERROR: "DASHBOARD_CLASS_DATA_LOAD_ERROR",

  DASHBOARD_USER_GROUP_SELECTED: "DASHBOARD_USER_GROUP_SELECTED",
  DASHBOARD_USER_GROUP_UPDATED: "DASHBOARD_USER_GROUP_UPDATED",

  // ---------------------------------------------------------------------------
  // HIGHLIGHTS
  // ---------------------------------------------------------------------------
  HIGHLIGHTS_CLASS_CONFIG_LOAD_PENDING: "HIGHLIGHTS_CLASS_CONFIG_LOAD_PENDING",
  HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS: "HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS",
  HIGHLIGHTS_CLASS_CONFIG_LOAD_ERROR: "HIGHLIGHTS_CLASS_CONFIG_LOAD_ERROR",

  HIGHLIGHTS_CLEAN_STUDENT_INSTANCE_DATA:
    "HIGHLIGHTS_CLEAN_STUDENT_INSTANCE_DATA",
  HIGHLIGHTS_CLEAN_ALL_STUDENTS_INSTANCE_DATA:
    "HIGHLIGHTS_CLEAN_ALL_STUDENTS_INSTANCE_DATA",
  HIGHLIGHTS_UPDATE_STUDENT_ONLINE_STATE:
    "HIGHLIGHTS_UPDATE_STUDENT_ONLINE_STATE",
  HIGHLIGHTS_UPDATE_STUDENT_STATUS: "HIGHLIGHTS_UPDATE_STUDENT_STATUS",
  HIGHLIGHTS_UPDATE_STUDENT_DATA: "HIGHLIGHTS_UPDATE_STUDENT_DATA",
  HIGHLIGHTS_UPDATE_STUDENT_CURRENT_SCREEN:
    "HIGHLIGHTS_UPDATE_STUDENT_CURRENT_SCREEN",
  HIGHLIGHTS_UPDATE_STUDENT_SNAPSHOT: "HIGHLIGHTS_UPDATE_STUDENT_SNAPSHOT",
  HIGHLIGHTS_UPDATE_STUDENT_SELECTED_INSTANCE:
    "HIGHLIGHTS_UPDATE_STUDENT_SELECTED_INSTANCE",

  HIGHLIGHTS_UPDATE_OUTSIDE_SCHOOL_HOURS:
    "HIGHLIGHTS_UPDATE_OUTSIDE_SCHOOL_HOURS",

  HIGHLIGHTS_CLASS_USER_GROUPS_LOAD_SUCCESS:
    "HIGHLIGHTS_CLASS_USER_GROUPS_LOAD_SUCCESS",
  HIGHLIGHTS_CLASS_USER_GROUPS_RESET: "HIGHLIGHTS_CLASS_USER_GROUPS_RESET",
  HIGHLIGHTS_CLASS_USER_GROUP_SELECTED: "HIGHLIGHTS_CLASS_USER_GROUP_SELECTED",
  HIGHLIGHTS_CLASS_USER_GROUPS_SHOW_MANAGEMENT_PANEL:
    "HIGHLIGHTS_CLASS_USER_GROUPS_SHOW_MANAGEMENT_PANEL",
  HIGHLIGHTS_CLASS_USER_GROUPS_HIDE_MANAGEMENT_PANEL:
    "HIGHLIGHTS_CLASS_USER_GROUPS_HIDE_MANAGEMENT_PANEL",

  HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_STATUS:
    "HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_STATUS",
  HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_GB_SESSION:
    "HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_GB_SESSION",
  HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_PAUSE:
    "HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_PROCESSED_PAUSE",
  HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_CONNECTION_ERROR:
    "HIGHLIGHTS_BUS_WEBSOCKET_UPDATE_CONNECTION_ERROR",

  HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_STATUS:
    "HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_STATUS",
  HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_CONNECTION_ERROR:
    "HIGHLIGHTS_SCREENSHOT_WEBSOCKET_UPDATE_CONNECTION_ERROR",

  HIGHLIGHTS_UPDATE_STUDENTS_ORDER: "HIGHLIGHTS_UPDATE_STUDENTS_ORDER",
  HIGHLIGHTS_UPDATE_ITEMS_PER_TILE_NUMBER:
    "HIGHLIGHTS_UPDATE_ITEMS_PER_TILE_NUMBER",
  HIGHLIGHTS_UPDATE_STUDENT_TILES_SIZE: "HIGHLIGHTS_UPDATE_STUDENT_TILES_SIZE",

  HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_PENDING:
    "HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_PENDING",
  HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_SUCCESS:
    "HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_SUCCESS",
  HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_ERROR:
    "HIGHLIGHTS_GUIDE_BROWSING_SESSIONS_LOAD_ERROR",

  HIGHLIGHTS_NOTIFICATION_SESSION_UPDATE:
    "HIGHLIGHTS_NOTIFICATION_SESSION_UPDATE",

  // GOOGLE CLASSROOM

  HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_AUTHORISED:
    "HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_AUTHORISED",
  HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_NOT_AUTHORISED:
    "HIGHLIGHTS_GCLASSROOM_CONTENT_ACCESS_NOT_AUTHORISED",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_PENDING:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_PENDING",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_SUCCESS:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_SUCCESS",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_ERROR",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_PENDING:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_PENDING",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_SUCCESS:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_SUCCESS",
  HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_ERROR:
    "HIGHLIGHTS_GET_GCLASSROOM_COURSEWORK_LOAD_MORE_ERROR",

  HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_PENDING:
    "HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_PENDING",
  HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_SUCCESS:
    "HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_SUCCESS",
  HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_ERROR:
    "HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_ERROR",
  HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_PENDING:
    "HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_PENDING",
  HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_SUCCESS:
    "HIGHLIGHTS_GET_GCLASSROOM_MATERIALS_LOAD_MORE_SUCCESS",

  // STUDENT REQUESTS
  HIGHLIGHTS_STUDENT_REQUESTS_SESSION_OPEN:
    "HIGHLIGHTS_STUDENT_REQUESTS_SESSION_OPEN",
  HIGHLIGHTS_STUDENT_REQUESTS_SESSION_CLOSE:
    "HIGHLIGHTS_STUDENT_REQUESTS_SESSION_CLOSE",
  HIGHLIGHTS_STUDENT_REQUESTS_LOAD_PENDING:
    "HIGHLIGHTS_STUDENT_REQUESTS_LOAD_PENDING",
  HIGHLIGHTS_STUDENT_REQUESTS_LOAD_SUCCESS:
    "HIGHLIGHTS_STUDENT_REQUESTS_LOAD_SUCCESS",
  HIGHLIGHTS_STUDENT_REQUESTS_LOAD_ERROR:
    "HIGHLIGHTS_STUDENT_REQUESTS_LOAD_ERROR",
  HIGHLIGHTS_STUDENT_REQUESTS_UPDATE: "HIGHLIGHTS_STUDENT_REQUESTS_UPDATE",
  HIGHLIGHTS_STUDENT_REQUESTS_WEBSOCKET_UPDATE_STATUS:
    "HIGHLIGHTS_STUDENT_REQUESTS_WEBSOCKET_UPDATE_STATUS",
  HIGHLIGHTS_STUDENT_REQUESTS_WEBSOCKET_UPDATE_CONNECTION_ERROR:
    "HIGHLIGHTS_STUDENT_REQUESTS_WEBSOCKET_UPDATE_CONNECTION_ERROR",
  HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_PENDING:
    "HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_PENDING",
  HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_SUCCESS:
    "HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_SUCCESS",
  HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_ERROR:
    "HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_ERROR",
  HIGHLIGHTS_STUDENT_REQUESTS_REJECT_PENDING:
    "HIGHLIGHTS_STUDENT_REQUESTS_REJECT_PENDING",
  HIGHLIGHTS_STUDENT_REQUESTS_REJECT_SUCCESS:
    "HIGHLIGHTS_STUDENT_REQUESTS_REJECT_SUCCESS",
  HIGHLIGHTS_STUDENT_REQUESTS_REJECT_ERROR:
    "HIGHLIGHTS_STUDENT_REQUESTS_REJECT_ERROR",
  HIGHLIGHTS_STUDENT_REQUESTS_DISMISS_SUCCESS_NOTIFICATION:
    "HIGHLIGHTS_STUDENT_REQUESTS_DISMISS_SUCCESS_NOTIFICATION",

  HIGHLIGHTS_GUIDE_BROWSING_RECENT_TABS_HISTORY_LOAD_SUCCESS:
    "HIGHLIGHTS_GUIDE_BROWSING_RECENT_TABS_HISTORY_LOAD_SUCCESS",
  HIGHLIGHTS_GUIDE_BROWSING_MODAL_SHOW: "HIGHLIGHTS_GUIDE_BROWSING_MODAL_SHOW",
  HIGHLIGHTS_GUIDE_BROWSING_MODAL_HIDE: "HIGHLIGHTS_GUIDE_BROWSING_MODAL_HIDE",
  HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_PENDING:
    "HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_PENDING",
  HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_SUCCESS:
    "HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_SUCCESS",
  HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_ERROR:
    "HIGHLIGHTS_GUIDE_BROWSING_TEMPLATES_LOAD_ERROR",

  HIGHLIGHTS_SEND_MESSAGE_MODAL_SHOW: "HIGHLIGHTS_SEND_MESSAGE_MODAL_SHOW",
  HIGHLIGHTS_SEND_MESSAGE_MODAL_HIDE: "HIGHLIGHTS_SEND_MESSAGE_MODAL_HIDE",
  HIGHLIGHTS_SEND_MESSAGE_MODAL_ERROR: "HIGHLIGHTS_SEND_MESSAGE_MODAL_ERROR",
  HIGHLIGHTS_SEND_MESSAGE_MODAL_SUCCESS:
    "HIGHLIGHTS_SEND_MESSAGE_MODAL_SUCCESS",

  HIGHLIGHTS_SHARE_LINKS_MODAL_SHOW: "HIGHLIGHTS_SHARE_LINKS_MODAL_SHOW",
  HIGHLIGHTS_SHARE_LINKS_MODAL_HIDE: "HIGHLIGHTS_SHARE_LINKS_MODAL_HIDE",
  HIGHLIGHTS_SHARE_LINKS_MODAL_ERROR: "HIGHLIGHTS_SHARE_LINKS_MODAL_ERROR",
  HIGHLIGHTS_SHARE_LINKS_MODAL_SUCCESS: "HIGHLIGHTS_SHARE_LINKS_MODAL_SUCCESS",

  HIGHLIGHTS_CLICK_TO_SHARE_PENDING: "HIGHLIGHTS_CLICK_TO_SHARE_PENDING",
  HIGHLIGHTS_CLICK_TO_SHARE_SUCCESS: "HIGHLIGHTS_CLICK_TO_SHARE_SUCCESS",
  HIGHLIGHTS_CLICK_TO_SHARE_ERROR: "HIGHLIGHTS_CLICK_TO_SHARE_ERROR",

  HIGHLIGHTS_SNAPSHOTS_LOAD_PENDING: "HIGHLIGHTS_SNAPSHOTS_LOAD_PENDING",
  HIGHLIGHTS_SNAPSHOTS_LOAD_SUCCESS: "HIGHLIGHTS_SNAPSHOTS_LOAD_SUCCESS",

  HIGHLIGHTS_SNAPSHOT_DETAILS_LOAD_SUCCESS:
    "HIGHLIGHTS_SNAPSHOT_DETAILS_LOAD_SUCCESS",

  HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_PENDING:
    "HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_PENDING",
  HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_SUCCESS:
    "HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_SUCCESS",
  HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_MORE_SUCCESS:
    "HIGHLIGHTS_SCHEDULED_SESSIONS_LOAD_MORE_SUCCESS",
  HIGHLIGHTS_SCHEDULED_SESSIONS_RELOAD_REQUEST:
    "HIGHLIGHTS_SCHEDULED_SESSIONS_RELOAD_REQUEST",
  HIGHLIGHTS_SCHEDULED_SESSIONS_UPDATE_SESSION_NAME_LOCALLY:
    "HIGHLIGHTS_SCHEDULED_SESSIONS_UPDATE_SESSION_NAME_LOCALLY",

  HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_SHOW:
    "HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_SHOW",
  HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_HIDE:
    "HIGHLIGHTS_VIEW_SCREENSHOT_MODAL_HIDE",

  HIGHLIGHTS_EXPAND_MODAL_SHOW: "HIGHLIGHTS_EXPAND_MODAL_SHOW",
  HIGHLIGHTS_EXPAND_MODAL_HIDE: "HIGHLIGHTS_EXPAND_MODAL_HIDE",
  HIGHLIGHTS_UPDATE_EXPAND_MODAL_SNAPSHOT:
    "HIGHLIGHTS_UPDATE_EXPAND_MODAL_SNAPSHOT",
  HIGHLIGHTS_EXPAND_MODAL_SET_EMPTY_SNAPSHOT:
    "HIGHLIGHTS_EXPAND_MODAL_SET_EMPTY_SNAPSHOT",
  HIGHLIGHTS_EXPAND_MODAL_CLEAR_INSTANCES:
    "HIGHLIGHTS_EXPAND_MODAL_CLEAR_INSTANCES",

  HIGHLIGHTS_UPDATE_ACTIVE_VIEW_TYPE: "HIGHLIGHTS_UPDATE_ACTIVE_VIEW_TYPE",

  // ---------------------------------------------------------------------------
  // CLASS INFO
  // ----------------------------------------------------------------------------

  CLASS_INFO_DATA_LOAD_PENDING: "CLASS_INFO_DATA_LOAD_PENDING",
  CLASS_INFO_DATA_LOAD_SUCCESS: "CLASS_INFO_DATA_LOAD_SUCCESS",
  CLASS_INFO_DATA_LOAD_ERROR: "CLASS_INFO_DATA_LOAD_ERROR",

  CLASS_INFO_ADD_STUDENTS_PENDING: "CLASS_INFO_ADD_STUDENTS_PENDING",
  CLASS_INFO_ADD_STUDENTS_SUCCESS: "CLASS_INFO_ADD_STUDENTS_SUCCESS",
  CLASS_INFO_ADD_STUDENTS_ERROR: "CLASS_INFO_ADD_STUDENTS_ERROR",
  CLASS_INFO_ADD_STUDENTS_RESET: "CLASS_INFO_ADD_STUDENTS_RESET",

  CLASS_INFO_REMOVE_STUDENTS_PENDING: "CLASS_INFO_REMOVE_STUDENTS_PENDING",
  CLASS_INFO_REMOVE_STUDENTS_SUCCESS: "CLASS_INFO_REMOVE_STUDENTS_SUCCESS",
  CLASS_INFO_REMOVE_STUDENTS_ERROR: "CLASS_INFO_REMOVE_STUDENTS_ERROR",
  CLASS_INFO_REMOVE_STUDENTS_RESET: "CLASS_INFO_REMOVE_STUDENTS_RESET",

  CLASS_INFO_ADD_TEACHER_PENDING: "CLASS_INFO_ADD_TEACHER_PENDING",
  CLASS_INFO_ADD_TEACHER_SUCCESS: "CLASS_INFO_ADD_TEACHER_SUCCESS",
  CLASS_INFO_ADD_TEACHER_ERROR: "CLASS_INFO_ADD_TEACHER_ERROR",
  CLASS_INFO_ADD_TEACHER_RESET: "CLASS_INFO_ADD_TEACHER_RESET",

  CLASS_INFO_REMOVE_TEACHERS_PENDING: "CLASS_INFO_REMOVE_TEACHERS_PENDING",
  CLASS_INFO_REMOVE_TEACHERS_SUCCESS: "CLASS_INFO_REMOVE_TEACHERS_SUCCESS",
  CLASS_INFO_REMOVE_TEACHERS_ERROR: "CLASS_INFO_REMOVE_TEACHERS_ERROR",
  CLASS_INFO_REMOVE_TEACHERS_RESET: "CLASS_INFO_REMOVE_TEACHERS_RESET",
  CLASS_INFO_ADD_TEACHER_SERVER_VALIDATION_ERROR:
    "CLASS_INFO_ADD_TEACHER_SERVER_VALIDATION_ERROR",

  CLASS_INFO_RENAME_CLASS_PENDING: "CLASS_INFO_RENAME_CLASS_PENDING",
  CLASS_INFO_RENAME_CLASS_SUCCESS: "CLASS_INFO_RENAME_CLASS_SUCCESS",
  CLASS_INFO_RENAME_CLASS_ERROR: "CLASS_INFO_RENAME_CLASS_ERROR",
  CLASS_INFO_RENAME_CLASS_RESET: "CLASS_INFO_RENAME_CLASS_RESET",

  CLASS_INFO_UPDATE_STUDENT_PASSWORDS_PENDING:
    "CLASS_INFO_UPDATE_STUDENT_PASSWORDS_PENDING",
  CLASS_INFO_UPDATE_STUDENT_PASSWORDS_SUCCESS:
    "CLASS_INFO_UPDATE_STUDENT_PASSWORDS_SUCCESS",
  CLASS_INFO_UPDATE_STUDENT_PASSWORDS_ERROR:
    "CLASS_INFO_UPDATE_STUDENT_PASSWORDS_ERROR",
  CLASS_INFO_UPDATE_STUDENT_PASSWORDS_RESET:
    "CLASS_INFO_RESET_STUDENT_PASSWORDS_RESET",

  CLASS_INFO_SYNC_CLASS_PENDING: "CLASS_INFO_CLASS_SYNC_PENDING",
  CLASS_INFO_SYNC_CLASS_SUCCESS: "CLASS_INFO_CLASS_SYNC_SUCCESS",
  CLASS_INFO_SYNC_CLASS_ERROR: "CLASS_INFO_CLASS_SYNC_ERROR",
  CLASS_INFO_CLASS_SYNC_RESET: "CLASS_INFO_CLASS_SYNC_RESET",

  CLASS_INFO_RESET_CLASS_PENDING: "CLASS_INFO_RESET_CLASS_PENDING",
  CLASS_INFO_RESET_CLASS_SUCCESS: "CLASS_INFO_RESET_CLASS_SUCCESS",
  CLASS_INFO_RESET_CLASS_ERROR: "CLASS_INFO_RESET_CLASS_ERROR",
  CLASS_INFO_RESET_CLASS_RESET: "CLASS_INFO_RESET_CLASS_RESET",

  CLASS_INFO_REMOVE_CLASS_PENDING: "CLASS_INFO_REMOVE_CLASS_PENDING",
  CLASS_INFO_REMOVE_CLASS_SUCCESS: "CLASS_INFO_REMOVE_CLASS_SUCCESS",
  CLASS_INFO_REMOVE_CLASS_ERROR: "CLASS_INFO_REMOVE_CLASS_ERROR",
  CLASS_INFO_REMOVE_CLASS_RESET: "CLASS_INFO_REMOVE_CLASS_RESET",
};

export default actionTypes;
