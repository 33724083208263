import { connect } from "react-redux";
import { useSelector } from "react-redux";
import _ from "lodash";

// TYPES/CONSTS
import { APP_PAGES } from "../../../../state/router/types";
import { FEEDBACK_PRODUCT_TYPES } from "../../../../state/app/types";
import { PAGE_TYPES } from "../../../../hooks/useGroups";

// COMPONENTS
import Page from "../../../partial/PageContainer/PageContainer";
import Header from "../../../partial/Header/Header";
import Footer from "../../../partial/Footer/Footer";
import ModalsAndPanels from "../../../partial/Highlights/ModalsAndPanels/ModalsAndPanels";
import DriveMainContent from "../../../partial/Drive/DriveMainContent/DriveMainContent";

// SELECTORS
import {
  getIsConfigLoaded,
  getIsConfigError,
} from "../../../../state/highlights/config/selectors";
import {
  getSearchQuery,
  getDocTypeFilter,
} from "../../../../state/shared/selectors";
interface Props {
  isConfigLoaded: boolean;
  isConfigError: boolean;
  isOutsideSchoolHours: boolean;
}

const DrivePage = ({ isConfigLoaded, isConfigError }: Props) => {
  const hlDriveDataTestIdPrefix = "hl-StudentDrive";

  const selectedDocType = useSelector(getDocTypeFilter);
  const searchQuery = useSelector(getSearchQuery);

  let pageTitle = APP_PAGES.HIGHLIGHTS_DRIVE.title;

  if (!_.isEmpty(selectedDocType) || !_.isEmpty(searchQuery)) {
    pageTitle = APP_PAGES.HIGHLIGHTS_DRIVE.searchResultsTitle;
  }

  return (
    <Page
      header={<Header />}
      footer={
        <Footer feedbackProductType={FEEDBACK_PRODUCT_TYPES.HIGHLIGHTS} />
      }
      isPageLoaded={isConfigLoaded}
      isPageLoadingError={isConfigError}
      title={pageTitle}
    >
      <ModalsAndPanels />
      <DriveMainContent
        dataTestIdPrefix={hlDriveDataTestIdPrefix}
        pageType={PAGE_TYPES.HIGHLIGHTS}
      />
    </Page>
  );
};

export default connect((state) => ({
  isConfigLoaded: getIsConfigLoaded(state),
  isConfigError: getIsConfigError(state),
}))(DrivePage);
