import _ from "lodash";
import { createSelector } from "reselect";
import { domainFromURL } from "../../../utils";

export const getStudentsUrlMap = (state) =>
  _.get(state, "highlights.activities.students");

export const getStudentsInfoMap = (state) =>
  _.get(state, "highlights.activities.info");

export const ACTIVITY_TYPES = {
  BY_DOMAIN: "by_domain",
  COLLABORATION: "collaboration",
};

const matchesIgnoredUrl = (url) => {
  return (
    url.startsWith("chrome-extension") || url.startsWith("chrome://newtab")
  );
};

const transformToDomainClassActivity = (studentsMap, info) => {
  const count = _.keys(studentsMap).length;
  const mapByDomain = {};
  _.keys(studentsMap).forEach((studentID) => {
    const studentTabs = studentsMap[studentID];
    const tabDetailsPrevious = [];
    _.keys(studentTabs).forEach((url) => {
      //filter out extension urls and newtab as they do not make sense as class activities
      if (matchesIgnoredUrl(url)) {
        return;
      }
      const tabDetails = _.cloneDeep(studentTabs[url]);
      const tabDomain = domainFromURL(url);
      mapByDomain[tabDomain] = mapByDomain[tabDomain] || {
        icon: tabDetails.icon,
        url: tabDomain,
        current: 0,
        previous: 0,
        total: count,
        studentInfos: { current: [], previous: [], never: [] },
      };

      const studEntry = _.findIndex(
        mapByDomain[tabDomain].studentInfos.current,
        (stud) => {
          return stud.Id === studentID;
        }
      );

      if (tabDetails.current || studEntry >= 0) {
        if (studEntry >= 0) {
          mapByDomain[tabDomain].studentInfos.current[studEntry].history.push(
            ...tabDetails.history
          );
        } else {
          mapByDomain[tabDomain].current = mapByDomain[tabDomain].current + 1;
          mapByDomain[tabDomain].studentInfos.current.push({
            ...info[studentID],
            history: tabDetails.history,
          });
        }
      } else {
        tabDetailsPrevious.push(tabDetails);
      }
    });

    tabDetailsPrevious.forEach((td) => {
      const tabDomain = domainFromURL(td.url);

      mapByDomain[tabDomain] = mapByDomain[tabDomain] || {
        icon: td.icon,
        url: tabDomain,
        current: 0,
        previous: 0,
        total: count,
        studentInfos: { current: [], previous: [], never: [] },
      };

      const studEntry = _.findIndex(
        mapByDomain[tabDomain].studentInfos.current,
        (stud) => {
          return stud.Id === studentID;
        }
      );

      if (studEntry >= 0) {
        mapByDomain[tabDomain].studentInfos.current[studEntry].history.push(
          ...td.history
        );
      } else {
        tabDetailsPrevious.push(td);
        const prevStudEntry = _.findIndex(
          mapByDomain[tabDomain].studentInfos.previous,
          (stud) => {
            return stud.Id === studentID;
          }
        );
        if (prevStudEntry >= 0) {
          mapByDomain[tabDomain].studentInfos.previous[
            prevStudEntry
          ].history.push(...td.history);
        } else {
          mapByDomain[tabDomain].previous = mapByDomain[tabDomain].previous + 1;
          mapByDomain[tabDomain].studentInfos.previous.push({
            ...info[studentID],
            history: td.history,
          });
        }
      }
    });
  });
  //let's also fill studentInfos for never
  _.keys(mapByDomain).forEach((domain) => {
    const details = mapByDomain[domain];
    details.studentInfos.never = _.values(info).filter(
      (st) =>
        !_.some(details.studentInfos.current, { Id: st.Id }) &&
        !_.some(details.studentInfos.previous, { Id: st.Id })
    );
    //lt's also add snap conversion function
    details.convertToSnapActivity = () => {
      return details.studentInfos.current
        .map((s) => {
          const student = studentsMap[s.Id];
          return {
            id: s.Id,
            email: s.Email,
            activity: s.history,
            activeTabs: Object.values(student).filter(
              (tabDetails) => tabDetails.current
            ),
          };
        })
        .concat(
          details.studentInfos.previous.map((s) => {
            const student = studentsMap[s.Id];
            return {
              id: s.Id,
              email: s.Email,
              activity: s.history,
              activeTabs: Object.values(student).filter(
                (tabDetails) => tabDetails.current
              ),
            };
          })
        );
    };
  });
  return _.sortBy(_.values(mapByDomain), ["url"]);
};

const transformToCollaborationClassActivity = (studentsMap, info) => {
  const count = _.keys(studentsMap).length;
  const mapByUrl = {};
  _.keys(studentsMap).forEach((studentID) => {
    const student = studentsMap[studentID];
    const urlProcessed = {}; //so we do not count multiple tabs for the same student and url
    _.keys(student).forEach((url) => {
      const tabDetails = student[url];
      if (!urlProcessed[url]) {
        urlProcessed[url] = true;
        mapByUrl[url] = mapByUrl[url] || {
          icon: tabDetails.icon,
          url,
          title: tabDetails.title,
          current: 0,
          previous: 0,
          total: count,
          studentInfos: { current: [], previous: [], never: [] },
        };
        if (tabDetails.current) {
          mapByUrl[url].current = mapByUrl[url].current + 1;
          mapByUrl[url].studentInfos.current.push({
            ...info[studentID],
            history: tabDetails.history,
          });
        } else {
          mapByUrl[url].previous = mapByUrl[url].previous + 1;
          mapByUrl[url].studentInfos.previous.push({
            ...info[studentID],
            history: tabDetails.history,
          });
        }
      }
    });
  });
  //let's also fill studentInfos for never
  _.keys(mapByUrl).forEach((url) => {
    const details = mapByUrl[url];
    details.studentInfos.never = _.values(info).filter(
      (st) =>
        !_.some(details.studentInfos.current, { Id: st.Id }) &&
        !_.some(details.studentInfos.previous, { Id: st.Id })
    );
    details.convertToSnapActivity = () => {
      return details.studentInfos.current
        .map((s) => {
          const student = studentsMap[s.Id];
          return {
            id: s.Id,
            email: s.Email,
            activity: s.history,
            activeTabs: Object.values(student).filter(
              (tabDetails) => tabDetails.current
            ),
          };
        })
        .concat(
          details.studentInfos.previous.map((s) => {
            const student = studentsMap[s.Id];
            return {
              id: s.Id,
              email: s.Email,
              activity: s.history,
              activeTabs: Object.values(student).filter(
                (tabDetails) => tabDetails.current
              ),
            };
          })
        );
    };
  });
  return _.sortBy(
    _.values(mapByUrl).filter((details) => details.current > 1),
    ["url"]
  );
};

const transformToClassActivity = (
  studentsMap,
  info,
  activityType = ACTIVITY_TYPES.BY_DOMAIN
) => {
  if (activityType === ACTIVITY_TYPES.BY_DOMAIN) {
    return transformToDomainClassActivity(studentsMap, info);
  }
  return transformToCollaborationClassActivity(studentsMap, info);
};

const transformToUniqueActivity = (studentsMap, info) => {
  const mapByURL = {};
  _.keys(studentsMap).forEach((studentID) => {
    const student = studentsMap[studentID];
    _.keys(student).forEach((url) => {
      const tabDetails = student[url];
      if (!tabDetails.current || matchesIgnoredUrl(url)) {
        return;
      }
      mapByURL[url] = mapByURL[url] || { count: 0, info: {} };
      mapByURL[url].count = mapByURL[url].count + 1;
      mapByURL[url].info = {
        ...student[url],
        url,
        studentInfo: info[studentID],
        history: tabDetails.history,
        convertToSnapActivity: () => {
          return [
            {
              id: studentID,
              email: info[studentID] ? info[studentID].Email : "",
              activity: tabDetails.history,
              activeTabs: Object.values(student).filter(
                (tabDetails) => tabDetails.current
              ),
            },
          ];
        },
      };
    });
  });
  const uniqueActivities = _.keys(mapByURL)
    .filter((u) => mapByURL[u].count === 1)
    .map((u) => mapByURL[u].info);
  return uniqueActivities;
};

export const getClassActivity = createSelector(
  getStudentsUrlMap,
  getStudentsInfoMap,
  (_, props) => {
    return props;
  },
  (students, info, activityType) =>
    _.isEmpty(students)
      ? []
      : transformToClassActivity(students, info, activityType)
);

export const getUniqueActivity = createSelector(
  getStudentsUrlMap,
  getStudentsInfoMap,
  (students, info) =>
    _.isEmpty(students) ? [] : transformToUniqueActivity(students, info)
);
