import React from "react";
import styles from "./WorkspacesInfoPage.module.scss";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import Card, {
  BACKGROUND_STYLE,
  CARD_TYPE,
} from "@hapara/ui/src/deprecated/Card/Card";
import { APP_PAGES } from "../../../../../state/router/types";
import buttonStyles from "@hapara/ui/src/atomic/Button/Button.module.scss";
import teacherWsCard from "@hapara/assets/src/images/vector-teacher-holding-ws-card.svg";
import blueWsBoard from "@hapara/assets/src/images/vector-blue-workspace-board.svg";
import blueEarthMagnifier from "@hapara/assets/src/images/vector-blue-earth-magnifying-glass.svg";
import CreateSubMenuButton from "../../Create/CreateSubMenuButton/CreateSubMenuButton";

const info = {
  hero: {
    background: BACKGROUND_STYLE.BLUE,
    image: teacherWsCard,
    title: "Create engaging lessons!",
    text: "Workspaces you create or copy will appear here.",
  },
  itemsDescription: "How to get started:",
  firstItem: {
    id: 1,
    image: blueWsBoard,
    title: "Create a Workspace",
    text: "Create your own lesson, project or unit from scratch.",
    linkLabel: "Create a Workspace",
    linkDataTestId: "CreateWorkspace",
  },
  secondItem: {
    id: 2,
    image: blueEarthMagnifier,
    title: "Explore shared Workspaces",
    text: "Get started with a Workspace from our global library.",
    link: APP_PAGES.DISCOVER_BASE.path,
    linkLabel: "Explore Discover",
    linkDataTestId: "ExploreDiscover",
  },
};

const WorkspacesInfoPage = () => {
  const dataTestIdPrefix = "Ws-MyWorkspaces-InfoPage";

  return (
    <div className={styles.root} data-test-id={`${dataTestIdPrefix}-Main`}>
      <div className={styles.hero}>
        <Card
          type={CARD_TYPE.HORIZONTAL}
          dataTestIdPrefix={dataTestIdPrefix}
          background={info.hero.background}
          image={info.hero.image}
          title={({ className }) => (
            <h1 className={className}>{info.hero.title}</h1>
          )}
          text={info.hero.text}
        />
      </div>
      <div
        className={styles.description}
        data-test-id={`${dataTestIdPrefix}-Description`}
      >
        {info.itemsDescription}
      </div>
      <div className={styles.container}>
        <Card
          type={CARD_TYPE.VERTICAL}
          dataTestIdPrefix={`${dataTestIdPrefix}-Item${info.firstItem.id}`}
          image={info.firstItem.image}
          title={({ className }) => (
            <h2 className={className}>{info.firstItem.title}</h2>
          )}
          text={info.firstItem.text}
          actionComponent={<CreateSubMenuButton isInsideInfoPage={true} />}
        />
        <Card
          type={CARD_TYPE.VERTICAL}
          dataTestIdPrefix={`${dataTestIdPrefix}-Item${info.secondItem.id}`}
          image={info.secondItem.image}
          title={({ className }) => (
            <h2 className={className}>{info.secondItem.title}</h2>
          )}
          text={info.secondItem.text}
          actionComponent={
            <NavLink
              to={info.secondItem.link}
              data-test-id={`${dataTestIdPrefix}-${info.secondItem.linkDataTestId}`}
              className={classnames(
                buttonStyles.root,
                buttonStyles.regular,
                buttonStyles.primary
              )}
            >
              {info.secondItem.linkLabel}
            </NavLink>
          }
        />
      </div>
    </div>
  );
};

export default WorkspacesInfoPage;
