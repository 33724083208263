import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { NavLink } from "react-router-dom";
import styles from "./CollapsableNavItem.module.scss";
import classnames from "classnames";

type CollapsableNavItemProps = {
  title: string;
  dataTestId: string;
  href: string;
  isExpanded: boolean;
  isSelected: boolean;
  icon: string;
  selectedIcon: string;
  size: string;
};

export const CollapsableNavItem = ({
  title = "No Title",
  dataTestId = "No Data Test Id",
  href = "",
  isExpanded = false,
  isSelected = false,
  icon = "question",
  selectedIcon = "question-circle-o",
  size = "16px",
}: CollapsableNavItemProps) => {
  return (
    <NavLink
      to={href}
      className={classnames(styles.root, {
        [styles.isNotExpanded]: !isExpanded,
        [styles.isSelected]: isSelected,
      })}
      aria-label={`${title}${isSelected ? ", Selected" : ""}`}
      data-test-id={dataTestId}
    >
      <div className={styles.icon}>
        <HapReactIcon
          svg={isSelected ? selectedIcon : icon}
          width={size}
          height={size}
        />
      </div>
      {isSelected && (
        <div
          className={classnames(styles.selectedIcon, {
            [styles.selectedIconNotExpanded]: !isExpanded,
          })}
        ></div>
      )}
      <div className={styles.title}>{title}</div>
    </NavLink>
  );
};

export const CollapsableNavItemDivider = () => {
  return <div className={styles.divider} />;
};
