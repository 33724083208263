import React from "react";
import styles from "./PageActions.module.scss";
import classnames from "classnames";

interface PageActionProps {
  leftAligned?: boolean;
  children: React.ReactNode;
}

export const PageActions = ({ children, leftAligned }: PageActionProps) => (
  <div
    className={classnames(styles.pageActionsContainer, {
      [styles.leftAligned]: leftAligned,
    })}
  >
    {children}
  </div>
);
