import axios from "axios";
import { getCurrentAuthHeader } from "./authHeader";
import { USER_GROUP_PREFS_TYPE } from "../state/shared/userGroups/types";
import { getToken } from "./jwtHandler";
import { pageSize as googleClassroomPageSize } from "../components/partial/Highlights/ModalFormGClassroomShare/ModalFormGClassroomShare";

export const fetchHighlightsClassConfig = async (classId) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/teacher-config/highlights?class=${classId}`;

  const tokenAtTimeOfCall = await getToken();
  const config = {
    headers: { Authorization: tokenAtTimeOfCall },
  };

  try {
    return await axios.get(url, config);
  } catch (error) {
    // If the error is a 403, check if the token has changed since the call was made
    // If a user is quickly navigating between classes, they can request for a new token between calls
    // If the token has changed, return null
    if (error.response && error.response.status === 403) {
      const currentToken = await getToken();
      if (tokenAtTimeOfCall === currentToken) {
        return null;
      }
    }
    throw error;
  }
};

export async function fetchUserGroupsCall(classId) {
  return fetch(
    `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/highlights/usergroups/${classId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export const postUserGroup = async (group) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/create`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, group, config);
};

export const updateUserGroup = async (userGroupUrn, groupUpdate) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/edit/urn/${userGroupUrn}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, groupUpdate, config);
};

export const deleteUserGroup = async (userGroupUrn) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/urn/${userGroupUrn}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.delete(url, config);
};

export const postUserGroupsPrefs = async (classId, groupsPrefs) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/urn/${classId}/prefs`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  const body = {
    viewlevel_usergroups:
      groupsPrefs === USER_GROUP_PREFS_TYPE.SPECIFIC_TO_TAB ? true : false,
  };
  return axios.post(url, body, config);
};

export const addParticipartToUserGroup = async (userGroupUrn, groupUpdate) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/edit/urn/${userGroupUrn}/participant/add`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, groupUpdate, config);
};

export const removeParticipartFromUserGroup = async (
  userGroupUrn,
  groupUpdate
) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/edit/urn/${userGroupUrn}/participant/remove`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, groupUpdate, config);
};

export const updateUserGroupsList = async (userGroupsUpdate) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/usergroup/manage`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, userGroupsUpdate, config);
};

export const putStudentsSortOrder = async ({
  classId,
  orderType,
  customOrder,
}) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/teacher-config/highlights/sorting?class=${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const data = { orderType, customOrder };

  return await axios.put(url, data, config);
};

export const putItemsPerTile = async ({ itemsNumber, classId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/teacher-config/highlights/tabsNumber?class=${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const data = { tabsNumber: itemsNumber };

  return await axios.put(url, data, config);
};

export const putTileSize = async ({ tileSize, classId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/teacher-config/highlights/tileSize?class=${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  const data = { size: tileSize };

  return await axios.put(url, data, config);
};

export const postCloseStudentTab = async ({ closeTabData }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/close-tab`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.post(url, closeTabData, config);
};

export const postSendMessageOrShareLinks = async ({ messageBody }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/messages`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.post(url, messageBody, config);
};

export const postNewSession = async ({ sessionDetails }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/create-session`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, sessionDetails, config);
};

export const getSessions = async ({ classId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/sessions?class=${classId}`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const postReleaseSession = async ({ sessionId }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/release-session`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  const data = { ID: sessionId };

  return await axios.post(url, data, config);
};

export const postReleaseStudentsFromSession = async ({
  studentIds,
  isPause,
}) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/release-students`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  const data = { StudentIDS: studentIds, IsPause: isPause };

  return await axios.post(url, data, config);
};

export const postEditGuideBrowsingSession = async ({ newSession }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/teacher/edit-session`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.post(url, newSession, config);
};

export const getGuideBrowsingRecentTabsHistory = async () => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/url-history`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.get(url, config);
};

export const postGuideBrowsingRecentTabsHistory = async ({ urls }) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/url-history`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  const data = { urls: urls };

  return axios.post(url, data, config);
};

export const postCreateSnapshot = async (data) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hldata/teacher/snapshots`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.post(url, data, config);
};

export const deleteSnap = async (snapId, classId) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/snapshot/${classId}/${snapId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.delete(url, config);
};

export const fetchSnapshotDetails = async (snapId, classId) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/snapshot/${classId}/${snapId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.get(url, config);
};

export const fetchSnapshotsList = async (classId) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/snapshot/${classId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.get(url, config);
};

export const postSnapFeedback = async (data, classId, snapId) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/snapshot/${classId}/${snapId}/email-feedback`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, data, config);
};

export const postSendCopyOnEmail = async (classId, snapId) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/snapshot/${classId}/${snapId}/email-save`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, null, config);
};

export const getGuideBrowsingTemplates = async ({ classId }) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates/${classId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };

  return axios.get(url, config);
};

export const getClickToShare = async ({ docId, email }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/click2share/docs/c2s?id=${docId}&st=${email}`;
  const config = {
    headers: { Authorization: await getToken() },
  };

  return await axios.get(url, config);
};

export const postNewGuideBrowsingTemplate = async ({
  classId,
  templateName,
  sessionData,
}) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  const data = { name: templateName, class: classId, session: sessionData };

  return axios.post(url, data, config);
};

export const putExistingGuideBrowsingTemplate = async ({
  id,
  classId,
  templateName,
  sessionData,
}) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  const data = { id, name: templateName, class: classId, session: sessionData };

  return axios.put(url, data, config);
};

// TODO: Remove once previous iteration of guided browsing is no longer used
export const deleteExistingGuideBrowsingTemplate = async ({ id }) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates/${id}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };

  return axios.delete(url, config);
};

// New iteration that has error handling
export const deleteGuidedBrowsingTemplate = async ({ id }) => {
  const url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates/${id}`;
  try {
    const config = {
      headers: { Authorization: await getCurrentAuthHeader() },
    };
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const postNewScheduledSession = async ({ messageBody }) => {
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/create-sessions`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, messageBody, config);
};

export const postNewRecurringScheduledSession = async ({ messageBody }) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/guide-browsing-schedules/v1/recurring-session`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, messageBody, config);
};

export const getScheduledSessionsList = async ({
  classId,
  fromTs,
  toTs,
  sortOrder,
  pageSize,
  page,
}) => {
  ///scheduled/class/:classid
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/scheduled/class/classid?class=${classId}&from=${fromTs}&to=${toTs}&order=${sortOrder}&pageSize=${pageSize}&page=${page}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.get(url, config);
};

export const updateScheduledSession = async ({ classId, sessionDetails }) => {
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/edit-scheduled?class=${classId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, sessionDetails, config);
};

export const postScheduledSessionName = async ({ sessionDetails }) => {
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/change-session-name`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return axios.post(url, sessionDetails, config);
};

export const deleteSession = async ({ sessionId }) => {
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/schedule/${sessionId}`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };

  return axios.delete(url, config);
};

export const getTemplatesList = async ({ offset }) => {
  let url = `${process.env.REACT_APP_API_OLD_HIGHLIGHTS_URL}/tdapi/session-templates`;
  const config = {
    headers: {
      Authorization: await getCurrentAuthHeader(),
    },
    params: {
      offset,
    },
  };
  return axios.get(url, config);
};

export const getStudentRequestsCall = async () => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hldata/teacher/unblock`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.get(url, config);
};

export const actionStudentRequest = async (data) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hldata/teacher/unblock`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.post(url, data, config);
};

export const getGClassroomContent = async (courseID, type, nextPageToken) => {
  const url = !nextPageToken
    ? `${process.env.REACT_APP_API_HAPARA_URL}/classroom/gc/${type}?courseID=${courseID}&pageSize=${googleClassroomPageSize}`
    : `${process.env.REACT_APP_API_HAPARA_URL}/classroom/gc/${type}?courseID=${courseID}&pageSize=${googleClassroomPageSize}&token=${nextPageToken}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.get(url, config);
};

export const getGCCourseWorkAttachments = async (courseID, itemID) => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/classroom/gc/links/coursework?courseID=${courseID}&courseWorkID=${itemID}`;
  const config = {
    headers: { Authorization: await getToken() },
  };
  return await axios.get(url, config);
};

export const getStudentDriveDocuments = async (data) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/docs_get?alt=json?`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return await axios.post(url, data, config);
};

export const getStudentDrivePermissions = async (data) => {
  const url = `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/dashboard/perms_get`;
  const config = {
    headers: { Authorization: await getCurrentAuthHeader() },
  };
  return await axios.post(url, data, config);
};
