import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getGuideBrowsingModalSelectedTemplate,
  getScheduledSessionList,
} from "src/state/highlights/sessions/selectors";
import { getIsOutsideSchoolHours } from "src/state/highlights/config/selectors";
import { getMonitoringTime } from "src/state/highlights/config/selectors";
import { getFFByName } from "src/state/app/selectors";
import {
  addGuideBrowsingRecentTabsHistory,
  showGuideBrowsingModal,
} from "src/state/highlights/sessions/actions";
import { hideTemplatesPanel } from "src/state/highlights/guideBrowsingTemplates/actions";
import {
  hideGuideBrowsingModal,
  scheduleRecurringSession,
  scheduleSessionGBE,
} from "src/state/highlights/sessions/actions";
import { convertLocalDateTimeToUTCString } from "@hapara/ui/src/utils/convertLocalDateTimeToUTCString";
import { isWithinTimeRange } from "@hapara/ui/src/utils/isWithinTimeRange";
import { isWithinOneMonth } from "@hapara/ui/src/utils/isWithinOneMonth";
import { getCurrentDate } from "@hapara/ui/src/utils/getCurrentDate";
import { getCurrentTime } from "@hapara/ui/src/utils/getCurrentTime";
import { isDateToday } from "@hapara/ui/src/utils/isDateToday";
import { isDayOfWeek } from "@hapara/ui/src/utils/isDayOfWeek";
import {
  getSessionEndTime,
  getLocalizedSessionEndTime,
} from "./utils/getSessionEndTime";

import { transformScheduleSessionPayload } from "./transformScheduleSessionPayload";

export const useGuidedBrowsingSchedule = (sessionDetails) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectedTemplate = useSelector(getGuideBrowsingModalSelectedTemplate);
  const isOutsideSchoolHours = useSelector(getIsOutsideSchoolHours);
  const monitoringWindow = useSelector(getMonitoringTime);
  const sessions = useSelector(getScheduledSessionList);
  const hasRecurringSessionFeatureEnabled = useSelector(
    getFFByName("GBE-04-recurring-sessions")
  );

  const initialDate = getCurrentDate();
  const initialTime = getCurrentTime();

  const [scheduledDate, setScheduledDate] = useState<string>(initialDate);
  const [scheduleEndDate, setScheduleEndDate] = useState<string>(initialDate);
  const [scheduledTime, setScheduledTime] = useState<string>(initialTime);
  const [scheduleName, setScheduleName] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [hasInvalidDate, setHasInvalidDate] = useState<boolean>(false);
  const [hasInvalidEndDate, setHasInvalidEndDate] = useState<boolean>(false);
  const [hasInvalidTime, setHasInvalidTime] = useState<boolean>(false);
  const [hasInvalidName, setHasInvalidName] = useState<boolean>(false);
  const [isRepeatedSchedule, setIsRepeatedSchedule] = useState<boolean>(false);
  const [isScheduleInProgress, setIsScheduleInProgress] =
    useState<boolean>(false);

  const validateSelectedDateAndTime = (
    selectedTime: string,
    selectedDate: string
  ) => {
    const isValidSaturday =
      !isDayOfWeek("saturday", selectedDate) ||
      (monitoringWindow?.Saturday ?? false);

    const isValidSunday =
      !isDayOfWeek("sunday", selectedDate) ||
      (monitoringWindow?.Sunday ?? false);

    const isDateWithinMonitoringBounds =
      isWithinOneMonth(selectedDate) && isValidSaturday && isValidSunday;

    const isSelectedTimeWithinMonitoringHours = isWithinTimeRange(
      selectedTime,
      monitoringWindow.Start ?? "00:00",
      monitoringWindow.End ?? "23:59"
    );

    const isSelectedTimeWithinRangeToday = isDateToday(selectedDate)
      ? isWithinTimeRange(
          selectedTime,
          initialTime,
          monitoringWindow.End ?? "23:59"
        )
      : true;

    setHasInvalidDate(
      !isDateWithinMonitoringBounds || !isSelectedTimeWithinRangeToday
    );
    setHasInvalidTime(
      !isSelectedTimeWithinMonitoringHours ||
        !isDateWithinMonitoringBounds ||
        !isSelectedTimeWithinRangeToday
    );
  };

  const handleSetScheduledTime = (time: string) => {
    setIsScheduleInProgress(true);
    setHasInvalidTime(false);
    validateSelectedDateAndTime(time, scheduledDate);
    setScheduledTime(time);
  };

  const handleSetScheduledDate = (date: string) => {
    setIsScheduleInProgress(true);
    setHasInvalidDate(false);
    validateSelectedDateAndTime(scheduledTime, date);
    setScheduledDate(date ? date : initialDate);
    setScheduleEndDate(date ? date : initialDate);
  };

  const handleToggleRepeatSchedule = () => {
    setIsRepeatedSchedule((prevState) => !prevState);
  };

  const handleDaySelect = (day: string) => {
    setSelectedDays((prevState) =>
      prevState.includes(day)
        ? prevState.filter((d) => d !== day)
        : [...prevState, day]
    );
  };

  const handleScheduleNameChange = (name: string) => {
    const trimmedName = name.trim();
    let isInvalid = false;

    if (hasRecurringSessionFeatureEnabled && trimmedName !== "") {
      isInvalid = sessions.some(
        (session) =>
          session.Active && session.SessionName.trim() === trimmedName
      );
    }

    setHasInvalidName(isInvalid);
    setScheduleName(trimmedName);
  };

  const handleScheduleEndDateChange = (endDate: string) => {
    setIsScheduleInProgress(true);
    setHasInvalidEndDate(!isWithinOneMonth(endDate));
    setScheduleEndDate(endDate);
  };

  const createScheduledSession = () => {
    let updatedSessionDetails = { ...sessionDetails };

    if (isScheduleInProgress && !hasInvalidDate && !hasInvalidTime) {
      updatedSessionDetails = {
        ...updatedSessionDetails,
        ScheduledTime: convertLocalDateTimeToUTCString(
          scheduledDate,
          scheduledTime
        ),
      };
    }

    dispatch(
      scheduleSessionGBE({
        sessionDetails: updatedSessionDetails,
        classId: updatedSessionDetails.ClassURN,
        successToastMessage: intl.formatMessage({
          defaultMessage: "Session scheduled",
          id: "MXlZKE",
        }),
      })
    );
    dispatch(addGuideBrowsingRecentTabsHistory({ urls: sessionDetails.Links }));
    dispatch(hideTemplatesPanel());
    dispatch(hideGuideBrowsingModal());
  };

  const sessionEndTime = getSessionEndTime(
    scheduledTime,
    sessionDetails.Duration
  );

  const sessionEndTimeLocalized = getLocalizedSessionEndTime(
    scheduledTime,
    sessionDetails.Duration
  );

  const createRecurringScheduledSession = () => {
    const updatedSessionDetails = transformScheduleSessionPayload({
      sessionDetails,
      scheduleName,
      scheduledDate,
      scheduleEndDate,
      scheduledTime,
      scheduleEndTime: sessionEndTime,
      domainTimeZone: monitoringWindow.Timezone,
      selectedDays,
    });

    if (
      isScheduleInProgress &&
      !hasInvalidDate &&
      !hasInvalidTime &&
      !hasInvalidEndDate &&
      !hasInvalidName &&
      selectedDays.length > 0
    ) {
      dispatch(
        scheduleRecurringSession({
          sessionDetails: updatedSessionDetails,
          successToastMessage: intl.formatMessage({
            defaultMessage: "Session scheduled",
            id: "MXlZKE",
          }),
        })
      );
      dispatch(
        addGuideBrowsingRecentTabsHistory({ urls: sessionDetails.Links })
      );
      dispatch(hideTemplatesPanel());
      dispatch(hideGuideBrowsingModal());
    }
  };

  const handleScheduleSession = () => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: "SCHEDULE_SESSION_FORM",
        sessionType: sessionDetails.SessionType,
        selectedTemplate,
      })
    );
  };

  const handleCancelSchedule = () => {
    dispatch(
      showGuideBrowsingModal({
        modalMode: selectedTemplate
          ? "SCHEDULE_TEMPLATE_FORM"
          : "CREATE_SESSION_FORM",
        sessionType: sessionDetails.SessionType,
        selectedTemplate,
      })
    );
  };

  const schedulingRange = {
    startOfMonitoring: monitoringWindow?.Start ?? "08:00",
    endOfMonitoring: monitoringWindow?.End ?? "17:00",
    sundayMonitoring: monitoringWindow?.Sunday ?? false,
    saturdayMonitoring: monitoringWindow?.Saturday ?? false,
  };

  const scheduleState = {
    isRepeatedSchedule,
    selectedDays,
    scheduleName,
    sessionEndTime: sessionEndTimeLocalized,
    scheduleEndDate,
  };

  const handleCreateScheduledSession = isRepeatedSchedule
    ? createRecurringScheduledSession
    : createScheduledSession;

  return {
    scheduleState,
    scheduledTime,
    hasInvalidTime,
    scheduledDate,
    hasInvalidDate,
    hasInvalidEndDate,
    hasInvalidName,
    isOutsideSchoolHours,
    isScheduleInProgress,
    schedulingRange,
    handleDaySelect,
    handleCancelSchedule,
    handleScheduleSession,
    handleSetScheduledDate,
    handleSetScheduledTime,
    handleScheduleNameChange,
    handleToggleRepeatSchedule,
    handleScheduleEndDateChange,
    handleCreateScheduledSession,
  };
};
