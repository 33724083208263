import PropTypes from "prop-types";
import _ from "lodash";
import { normaliseUrl } from "../../../utils";
import { isInternalLink } from "@hapara/ui/src/components/utils";

export const ACTIVITY_TYPES = {
  CLASS_ACTIVITY: "Class Activity",
  UNIQUE_ACTIVITY: "Unique Activity",
  COLLABORATION: "Collaboration",
};

export const NO_SCREENSHOT_AVAILABLE = "not_available";
export const NO_SCREENSHOT_AVAILABLE_2 = "No screenshot available"; // two different strings comming from BE

export const SNAPSHOT_TEACHER_TYPE = PropTypes.shape({
  Email: PropTypes.string.isRequired,
  FamilyName: PropTypes.string.isRequired,
  FirstName: PropTypes.string.isRequired,
  GivenName: PropTypes.string.isRequired,
});

export const SNAPSHOT_SCREENSHOT_TYPE = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  Image: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Url: PropTypes.string.isRequired,
  Error: PropTypes.string.isRequired,
});

export const SNAPSHOT_ACTIVE_TAB_TYPE = PropTypes.shape({
  Id: PropTypes.number,
  InstanceId: PropTypes.string,
  SiteName: PropTypes.string,
  UserIds: PropTypes.arrayOf(PropTypes.string),
  TimeStamp: PropTypes.number,
  DocName: PropTypes.string,
  Active: PropTypes.bool,
  Url: PropTypes.string,
  WasOpened: PropTypes.bool,
  SiteIcon: PropTypes.string,
});

export const SNAPSHOT_STUDENT_TYPE = PropTypes.shape({
  ActiveTabs: PropTypes.arrayOf(SNAPSHOT_ACTIVE_TAB_TYPE),
  Activity: PropTypes.arrayOf(SNAPSHOT_ACTIVE_TAB_TYPE),
  StudentEmail: PropTypes.string.isRequired,
  Screenshot: SNAPSHOT_SCREENSHOT_TYPE,
});

export const SNAPSHOT_TYPE = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  CreatorEmail: PropTypes.string.isRequired,
  CreatedDatetime: PropTypes.string.isRequired,
  ClassURN: PropTypes.string.isRequired,
  SiteUrl: PropTypes.string.isRequired, // e.g. "docs.google.com"
  ActivityType: PropTypes.string.isRequired, // e.g. "Domain"
  IconUrl: PropTypes.string.isRequired, // e.g. "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
  Name: PropTypes.string.isRequired, // e.g. "docs.google.com"
  DateTitle: PropTypes.number.isRequired, // e.g. 1614112831404
  SnapshotTimestamp: PropTypes.number.isRequired, // e.g. 1614112831404
  Students: PropTypes.arrayOf(SNAPSHOT_STUDENT_TYPE),
  Teacher: SNAPSHOT_TEACHER_TYPE,
  DetailsLoaded: PropTypes.bool.isRequired, // for ActiveTabs and Activity we need an extra API call
});

const tabOldToNewFormat = (tab) => ({
  Id: _.get(tab, "id", 0),
  InstanceId: _.get(tab, "instance_id", ""),
  SiteName: _.get(tab, "siteName", ""),
  UserIds: _.get(tab, "userids", ""),
  TimeStamp: _.get(tab, "when", 0),
  DocName: _.get(tab, "docName", ""),
  Active: _.get(tab, "active", false),
  Url: _.get(tab, "url", ""),
  WasOpened: _.get(tab, "wasOpened", false),
  SiteIcon: _.get(tab, "icon", ""),
});

export const parseOldTSnapsDataToNewFormat = (data) => {
  const studentsOldFormat = _.get(data, "students", []) || [];
  const students = studentsOldFormat.map((s) => {
    const activeTabsIn = _.get(s, "activeTabs", []) || [];
    const activeTabs = activeTabsIn.map((tab) => tabOldToNewFormat(tab));
    const activityIn = _.get(s, "activity", []) || [];
    const activity = activityIn.map((tab) => tabOldToNewFormat(tab));
    const screenshotImageIn = _.get(s, "screenshot.image", "");
    const screenshotImage =
      screenshotImageIn &&
      screenshotImageIn !== "" &&
      screenshotImageIn !== NO_SCREENSHOT_AVAILABLE &&
      screenshotImageIn !== NO_SCREENSHOT_AVAILABLE_2
        ? screenshotImageIn
        : NO_SCREENSHOT_AVAILABLE;

    return {
      ActiveTabs: activeTabs,
      Activity: activity,
      StudentEmail: _.get(s, "email", ""),
      Screenshot: {
        ID: _.get(s, "screenshot.id", 0),
        Image: screenshotImage,
        Title: _.get(s, "screenshot.title", ""),
        Url: _.get(s, "screenshot.url", ""),
        Error: _.get(s, "screenshot.error", ""),
      },
    };
  });

  let activityType = ACTIVITY_TYPES.CLASS_ACTIVITY;
  if (_.get(data, "capture-type", "") === "Url") {
    // possible values are: 'Url', 'Domain'
    if (students.length === 1) {
      activityType = ACTIVITY_TYPES.UNIQUE_ACTIVITY;
    } else {
      activityType = ACTIVITY_TYPES.COLLABORATION;
    }
  }

  let siteUrl = _.get(data, "capture-match", "");
  if (!isInternalLink(siteUrl)) {
    siteUrl = normaliseUrl(siteUrl);
  }

  const snapTS = _.get(data, "snapshot_timestamp", 0);

  return {
    ID: _.get(data, "id", ""),
    CreatorEmail: _.get(data, "Creator", ""),
    CreatedDatetime: _.get(data, "CreatedDatetime", ""), // obsolate ? same value as snapshot_timestamp
    ClassURN: _.get(data, "class", ""),
    SiteUrl: siteUrl,
    ActivityType: activityType,
    IconUrl: _.get(data, "icon", ""),
    Name: _.get(data, "name", ""), // obsolate ?
    DateTitle: snapTS,
    SnapshotTimestamp: snapTS,
    Teacher: {
      Email: _.get(data, "teacher", ""),
      FamilyName: _.get(data, "teacherName.family", ""),
      FirstName: _.get(data, "teacherName.name", ""),
      GivenName: _.get(data, "teacherName.given", ""),
    },
    Students: students,
    DetailsLoaded: false,
  };
};
