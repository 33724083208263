import React, { useMemo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import styles from "./StudentTileGroups.module.scss";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import { updateStudentsOrder } from "../../../../state/highlights/config/actions";
import { getClassId } from "../../../../state/shared/selectors";
import { getUserNameFormat } from "../../../../state/user/selectors";
import {
  UPREF_NAME_FIRST_LAST,
  UPREF_NAME_LAST_FIRST,
} from "../../../../state/user/types";
import { STUDENT_ORDER_TYPES } from "../../../../state/highlights/config/types";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { useIntl } from "react-intl";

const getStudentsOrderByGroup = ({
  groupUrn,
  studentsList,
  groupsList,
  nameFormat,
}) => {
  const group = _.find(groupsList, { URN: groupUrn });
  const groupParticipants = _.get(group, "participants", []);

  const studentsInTheGroup = [];
  const studentsRest = [];

  _.forEach(studentsList, (student) => {
    if (_.indexOf(groupParticipants, student.Email) !== -1) {
      studentsInTheGroup.push({ ...student });
    } else {
      studentsRest.push({ ...student });
    }
  });

  const iteratees = [
    nameFormat === UPREF_NAME_FIRST_LAST ? "FirstName" : "LastName",
  ];

  const sortedList = _.concat(
    _.orderBy(studentsInTheGroup, iteratees, ["asc"]),
    _.orderBy(studentsRest, iteratees, ["asc"])
  );

  return _.map(sortedList, (item) => item.Id);
};

const StudentTileGroups = ({
  studentEmail,
  selectedClassId,
  nameFormat,
  students,
  updateStudentsOrder,
  userGroups,
}) => {
  const intl = useIntl();

  // get list of student groups
  const listOfStudentGroups = useMemo(
    () =>
      _.filter(userGroups, (group) =>
        _.includes(group.participants, studentEmail)
      ) || [],
    [studentEmail, userGroups]
  );

  // filter and alphabetically order first 15 groups to display in a tile
  const MAX_NR_OF_DISPLAYED_GROUPS = 15;

  const LimitedListOfGroups = useMemo(
    () =>
      _.orderBy(listOfStudentGroups, ["name"], ["asc"]).slice(
        0,
        MAX_NR_OF_DISPLAYED_GROUPS
      ),
    [listOfStudentGroups]
  );

  const handleSortByGroup = (groupUrn) => {
    const newOrder = getStudentsOrderByGroup({
      groupUrn,
      studentsList: students,
      groupsList: userGroups,
      nameFormat,
    });

    updateStudentsOrder({
      type: STUDENT_ORDER_TYPES.CUSTOM,
      customOrder: newOrder,
      classId: selectedClassId,
    });
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.empty]: listOfStudentGroups.length === 0,
      })}
    >
      {listOfStudentGroups &&
        listOfStudentGroups.length > 0 &&
        _.map(LimitedListOfGroups, (group) => (
          <Tippy content={`${group.name}`} theme="hsuite" key={group.URN}>
            <button
              type="button"
              className={classnames(styles.group, styles[group.color])}
              aria-label={intl.formatMessage(
                {
                  defaultMessage: `{groupName} show all students from this group at the top`,
                  id: "5HvYUi",
                },
                {
                  groupName: group.name,
                }
              )}
              onClick={() => handleSortByGroup(group.URN)}
              data-test-id="hl-StudentTile-Button-GroupSort"
            />
          </Tippy>
        ))}
    </div>
  );
};

StudentTileGroups.propTypes = {
  studentEmail: PropTypes.string.isRequired,
  // connected props
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  selectedClassId: PropTypes.string,
  nameFormat: PropTypes.oneOf([UPREF_NAME_FIRST_LAST, UPREF_NAME_LAST_FIRST]),
  students: PropTypes.arrayOf(STUDENT_TYPE),
  updateStudentsOrder: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
    nameFormat: getUserNameFormat(state),
    students: getStudentConfigsList(state),
  }),
  (dispatch) => ({
    updateStudentsOrder: ({ type, customOrder, classId }) =>
      dispatch(updateStudentsOrder({ type, customOrder, classId })),
  })
)(StudentTileGroups);
