import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./Toasts.module.scss";
import Toast, { TOAST_TYPES } from "@hapara/ui/src/deprecated/Toast/Toast";
import {
  isAppError,
  isShowSuccessToast,
  getSuccessToastMessage,
  isShowExpandModalSuccessToast,
  getSuccessToastClassId,
} from "../../../state/app/selectors";
import _ from "lodash";

import { hideAppError, hideSuccessToast } from "../../../state/app/actions";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../state/router/types";

const HIDE_AFTER_SOME_TIME = 5000; // 5 sec

const Toasts = ({
  isAppError,
  hideAppError,
  isSuccessToastVisible,
  successToastMessage = "",
  hideSuccessToast,
  isExpandModalSuccessToast = false,
  classId = "",
}) => {
  const intl = useIntl();

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (isSuccessToastVisible) {
        hideSuccessToast();
      }
    }, HIDE_AFTER_SOME_TIME);
    return () => {
      window.clearTimeout(timer);
    };
  }, [isSuccessToastVisible, hideSuccessToast]);

  const successText = !_.isEmpty(successToastMessage) ? (
    successToastMessage
  ) : (
    <FormattedMessage
      defaultMessage="Task completed successfully"
      id="pUGkQG"
    />
  );

  return (
    <div className={styles.root} aria-live="polite" aria-atomic="true">
      {isAppError && (
        <Toast
          icon="fill-circle-exclamation"
          type={TOAST_TYPES.ERROR}
          onClose={hideAppError}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div>
            <FormattedMessage
              defaultMessage="<strong>Trouble completing this task.</strong> Please try again."
              id="+Gmidb"
            />
          </div>
        </Toast>
      )}
      {isSuccessToastVisible && (
        <Toast
          icon="circle-check-fill"
          type={TOAST_TYPES.SUCCESS}
          onClose={hideSuccessToast}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div>
            {isExpandModalSuccessToast ? (
              <FormattedMessage
                defaultMessage="<strong>Activity captured. Available to review in <a>Snaps</a> for the next 7 days.</strong>"
                id="fqV5SV"
                values={{
                  a: (chunks) => (
                    <Link
                      to={`${APP_PAGES.HIGHLIGHTS_SNAPS.path}?classId=${classId}`}
                      data-test-id="hl-Toast-SnapsLink"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Go to snaps",
                        id: "S7Hers",
                      })}
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            ) : (
              <strong>{successText}</strong>
            )}
          </div>
        </Toast>
      )}
    </div>
  );
};

Toasts.propTypes = {
  isAppError: PropTypes.bool.isRequired,
  hideAppError: PropTypes.func.isRequired,
  isSuccessToastVisible: PropTypes.bool,
  successToastMessage: PropTypes.string,
  hideSuccessToast: PropTypes.func.isRequired,
  isExpandModalSuccessToast: PropTypes.bool,
  classId: PropTypes.string,
};

export default connect(
  (state) => ({
    isAppError: isAppError(state),
    isSuccessToastVisible: isShowSuccessToast(state),
    successToastMessage: getSuccessToastMessage(state),
    isExpandModalSuccessToast: isShowExpandModalSuccessToast(state),
    classId: getSuccessToastClassId(state),
  }),
  (dispatch) => ({
    hideAppError: () => dispatch(hideAppError()),
    hideSuccessToast: () => dispatch(hideSuccessToast()),
  })
)(Toasts);
