import React from "react";
import { createIcon } from "./utils/createIcon";

export const CalendarIcon = createIcon(
  <path
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M13.8 6.7H2.2m8.5-5.2v2.6M5.4 1.5v2.6m-.1 10.4h5.4c1.1 0 1.7 0 2-.2.5-.2.8-.5 1-.9.2-.4.2-1 .2-2V5.9c0-1 0-1.6-.3-2a2 2 0 0 0-.8-.9c-.4-.2-1-.2-2-.2H5.2c-1.1 0-1.7 0-2 .2a2 2 0 0 0-1 .9c-.2.4-.2 1-.2 2v5.5c0 1 0 1.6.3 2 .1.4.4.7.8.9.4.2 1 .2 2 .2Z"
  />
);
