import _ from "lodash";
import { createSelector } from "reselect";
import { getMyClasses } from "../../shared/selectors";
import { LIBRARY_PERMISSIONS } from "./types";

const getConfig = (state) => _.get(state, "library.config");

export const getIsConfigLoading = createSelector(getConfig, (config) =>
  _.get(config, "isLoading")
);

export const getIsConfigLoaded = createSelector(getConfig, (config) =>
  _.get(config, "isLoaded")
);

export const getIsConfigError = createSelector(getConfig, (config) =>
  _.get(config, "isError")
);

export const getUserPermissions = createSelector(getConfig, (config) =>
  _.get(config, "permissions")
);

export const hasResourceUploadAccess = createSelector(
  getUserPermissions,
  (perms) => _.includes(perms, LIBRARY_PERMISSIONS.LIBRARY_RESOURCES_UPLOAD)
);

export const hasResourceAddAccess = createSelector(
  getUserPermissions,
  (perms) => _.includes(perms, LIBRARY_PERMISSIONS.LIBRARY_RESOURCES_ADD)
);

export const hasWorkspaceAddAccess = createSelector(
  getUserPermissions,
  (perms) => _.includes(perms, LIBRARY_PERMISSIONS.LIBRARY_WORKSPACE_ADD)
);

export const hasCollectionsManageAccess = createSelector(
  getUserPermissions,
  (perms) => _.includes(perms, LIBRARY_PERMISSIONS.LIBRARY_COLLECTIONS_MANAGE)
);

export const getClassList = createSelector(getMyClasses, (cl) =>
  _.map(cl, (cl) => ({
    Name: cl.class_alias,
    ID: cl.id,
    IsTeaching: cl.isTeaching,
    SubjectFolders: cl.subjectFolders,
    DataSource: cl.dataSource,
    ExternalID: cl.externalID || null,
  }))
);

export const getClassListSortedByName = createSelector(getClassList, (cl) =>
  _.sortBy(cl, ["Name"])
);
