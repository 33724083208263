import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ViewScreenshotModal.module.scss";
import classnames from "classnames";
import sunnyHills from "@hapara/assets/src/images/vector-image.svg";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import { getViewScreenshotModalInfo } from "../../../../state/highlights/viewScreenshot/selectors";
import { hideViewScreenshotModal } from "../../../../state/highlights/viewScreenshot/actions";
import { VIEW_SCREENSHOT_MODAL_INFO_TYPE } from "../../../../state/highlights/viewScreenshot/types";
import ViewScreenshotModalSnapshot from "../ViewScreenshotModalSnapshot/ViewScreenshotModalSnapshot";
import { FormattedMessage, useIntl } from "react-intl";

const TIMEOUT = 10 * 1000; //10 seconds

const ViewScreenshotModal = ({ modalInfo, onClose }) => {
  const [isTimeout, setIsTimeout] = useState(false);
  const isTimeoutSetRef = useRef();
  const intl = useIntl();
  const { studentDisplayName, snapshot } = modalInfo;

  useEffect(() => {
    let timer;
    if (!isTimeoutSetRef.current) {
      timer = window.setTimeout(() => {
        if (!snapshot) setIsTimeout(true);
      }, TIMEOUT);
      isTimeoutSetRef.current = true;
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [snapshot]);

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={onClose}
      title={studentDisplayName}
      dataTestPrefix="hl-CurrentScreen"
      classNameHeader={styles.modalHeader}
      className={styles.modal}
    >
      <>
        {isTimeout && (
          <div className={styles.timeout}>
            <div className={styles.title}>
              <FormattedMessage
                defaultMessage="Sorry, there was a problem loading the screenshot"
                id="OASfqa"
              />
            </div>
            <div className={styles.text}>
              <FormattedMessage defaultMessage="Please try again" id="fHqssj" />
            </div>
          </div>
        )}
        {!isTimeout && snapshot && <ViewScreenshotModalSnapshot />}
        {!isTimeout && !snapshot && (
          <div className={styles.loadingContainer}>
            <div className={classnames(styles.loadingImg, styles.ghost)}>
              <img
                src={sunnyHills}
                alt={intl.formatMessage({
                  defaultMessage: "Loading student snapshot",
                  id: "mZRS/r",
                })}
              />
            </div>
            <div className={styles.loadingInfoPanelContainer}>
              <div className={styles.loadingInfoPanel}>
                <div className={classnames(styles.longLine, styles.ghost)} />
                <div className={classnames(styles.shortLine, styles.ghost)} />
              </div>
              <div className={classnames(styles.btnElement, styles.ghost)} />
            </div>
          </div>
        )}
      </>
    </ModalDeprecated>
  );
};

ViewScreenshotModal.propTypes = {
  modalInfo: VIEW_SCREENSHOT_MODAL_INFO_TYPE,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    modalInfo: getViewScreenshotModalInfo(state),
  }),
  (dispatch) => ({
    onClose: () => dispatch(hideViewScreenshotModal()),
  })
)(ViewScreenshotModal);
