import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import styles from "./ShareLinksModal.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../state/shared/selectors";
import { shareLinksAction } from "../../../../state/highlights/shareLinks/actions";
import { getUserNameFormat } from "../../../../state/user/selectors";
import {
  SESSION_RECIPIENT_TYPES,
  PICKER_DISABLED_TYPES,
} from "../../../../state/highlights/sessions/types";
import {
  GUIDE_BROWSING_FOCUS_TYPES,
  SESSION_TYPES,
  SHARE_LINKS_SESSION_TYPE,
} from "../../../../state/highlights/sessions/types";
import ModalFormLinks from "../ModalFormLinks/ModalFormLinks";
import { getRecentTabsHistory } from "../../../../state/highlights/sessions/selectors";
import {
  loadGuideBrowsingRecentTabsHistory,
  addGuideBrowsingRecentTabsHistory,
} from "../../../../state/highlights/sessions/actions";
import {
  STUDENT_TYPE,
  STUDENT_SEND_MESSAGE_TYPE,
} from "../../../../state/highlights/students/types";
import {
  getGroupStatusInfo,
  getUserGroups,
} from "../../../../state/shared/userGroups/selectors";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import ModalFormStudentPicker from "../ModalFormStudentPicker/ModalFormStudentPicker";
import {
  getShareLinksModalErrorStatus,
  getShareLinksModalSuccessStatus,
  getShareLinksSelectedStudent,
  getShareLinksSessionData,
  isShareLinksDuplicateSession,
  getIsGClassroomShare,
} from "../../../../state/highlights/shareLinks/selectors";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import { hideShareLinksModal } from "../../../../state/highlights/shareLinks/actions";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import ModalFormSchedule from "../ModalFormSchedule/ModalFormSchedule";
import {
  getIsOutsideSchoolHours,
  getMonitoringTime,
} from "../../../../state/highlights/config/selectors";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import { pickerDisabledState } from "../../../../utils";
import { MONITORING_TIME_TYPE } from "../../../../state/highlights/config/types";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { extractScheduleTimeFromSession } from "../../../../utils";
import ModalFormGClassroomShare from "../ModalFormGClassroomShare/ModalFormGClassroomShare";
import { useIntl, FormattedMessage } from "react-intl";

const ShareLinksModal = ({
  isSendError = false,
  isSendSuccess = false,
  onModalClose,
  onShareLinks,
  selectedClassId,
  students,
  nameFormat,
  userGroups,
  selectedStudent,
  sessionData,
  recentTabs,
  loadRecentTabsHistory,
  addRecentTabsHistory,
  isOutsideSchoolHours,
  monitoringTime,
  isDuplicateSession,
  isGClassroomShare,
}) => {
  const intl = useIntl();
  const [recipientType, setRecipientType] = useState(
    SESSION_RECIPIENT_TYPES.CLASS
  );
  const [studentIds, setStudentIds] = useState(
    _.map(students, (student) => student.Id)
  );
  const [groupUrns, setGroupUrns] = useState([]);
  const [links, setLinks] = useState([]);
  const [sessionName, setSessionName] = useState();
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const [isScheduledSession, setIsScheduledSession] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(moment());
  const [scheduledTime, setScheduledTime] = useState(
    moment().startOf("hour").add(1, "hour")
  );
  const [isScheduledDateError, setIsScheduledDateError] = useState(false);
  const [isScheduledTimeError, setIsScheduledTimeError] = useState(false);
  const [googleClassRoomIDs, setGoogleClassRoomIDs] = useState([]);

  const isSendButtonDisabled = useMemo(() => {
    if (!links.length) {
      return true;
    }
    if (
      recipientType === SESSION_RECIPIENT_TYPES.STUDENTS &&
      !studentIds.length
    ) {
      return true;
    }
    if (
      recipientType === SESSION_RECIPIENT_TYPES.GROUPS &&
      (!studentIds.length || !groupUrns.length)
    ) {
      return true;
    }
    if (isScheduledSession && (!scheduledDate || !scheduledTime)) {
      return true;
    }
    if (isOutsideSchoolHours && !isScheduledSession) {
      return true;
    }
    if (isOutsideSchoolHours && isGClassroomShare) {
      return true;
    }
    return false;
  }, [
    links,
    recipientType,
    studentIds,
    groupUrns,
    isScheduledSession,
    scheduledDate,
    scheduledTime,
    isOutsideSchoolHours,
    isGClassroomShare,
  ]);

  const customShareTitle = !_.isEmpty(selectedStudent)
    ? intl.formatMessage(
        {
          defaultMessage: "Share links with {fullName}",
          id: "L9O//Y",
        },
        {
          fullName: selectedStudent.FullName,
        }
      )
    : intl.formatMessage({
        defaultMessage: "Share links with students",
        id: "ITKmyM",
      });

  const shareButtonTitle = () => {
    if (isGClassroomShare) {
      return intl.formatMessage({
        defaultMessage: "Share to assigned students",
        id: "9ix83z",
      });
    } else if (!isScheduledSession) {
      return intl.formatMessage({
        defaultMessage: "Share links",
        id: "86oph/",
      });
    } else {
      return intl.formatMessage({
        defaultMessage: "Schedule Session",
        id: "8Rz5NF",
      });
    }
  };

  const gcShareTitle = intl.formatMessage({
    defaultMessage: "Share links from Google Classroom",
    id: "UzhOYr",
  });

  const dataTestPrefix = "hl-ShareLinksModal";

  // load resent tabs on mount and clear form
  useEffect(() => {
    loadRecentTabsHistory();
  }, [loadRecentTabsHistory]);

  // update session data when individual student is selected
  useEffect(() => {
    if (!_.isEmpty(selectedStudent)) {
      setRecipientType(SESSION_RECIPIENT_TYPES.STUDENTS);
      setStudentIds([selectedStudent.Id]);
    }
  }, [selectedStudent]);

  // update session data when it is pre-populated
  useEffect(() => {
    if (!_.isEmpty(sessionData)) {
      const scheduledDateTime = isDuplicateSession
        ? extractScheduleTimeFromSession(sessionData)
        : _.get(sessionData, "ScheduledDateTime");

      const sessionId = _.get(sessionData, "SessionId");
      const scheduledRecipientType =
        _.get(sessionData, "Recipient.Type") || SESSION_RECIPIENT_TYPES.CLASS;
      const scheduledGroupsUrns = _.get(sessionData, "Recipient.Groups") || [];
      let selectedStudents = _.get(sessionData, "Recipient.Students") || [];
      if (scheduledRecipientType === SESSION_RECIPIENT_TYPES.CLASS) {
        selectedStudents = _.map(students, (student) => student.Id);
      } else if (scheduledRecipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
        selectedStudents = [];
        _.forEach(scheduledGroupsUrns, (urn) => {
          const group = _.find(userGroups, (item) => item.URN === urn);
          const groupParticipants = _.get(group, "participants", []);
          selectedStudents = _.union(selectedStudents, groupParticipants);
        });
      }
      // sessionId not empty - Edit session, show provided date
      // sessionId is null and scheduledDateTime provided -> duplicate existing. Date selector should show placeholder
      // no sessionID and no scheduledDateTime -> create a new session. Date selector should show today's date
      const scheduledDate =
        scheduledDateTime && sessionId
          ? moment(scheduledDateTime)
          : scheduledDateTime
          ? null
          : moment();

      setLinks(_.get(sessionData, "Links") || []);
      setSessionName(_.get(sessionData, "SessionName"));
      setIsScheduledSession(_.get(sessionData, "IsScheduledSession", true));
      setRecipientType(scheduledRecipientType);
      setGroupUrns(scheduledGroupsUrns);
      setStudentIds(selectedStudents);
      setScheduledDate(scheduledDate);
      setScheduledTime(
        scheduledDateTime
          ? moment(scheduledDateTime)
          : moment().startOf("hour").add(1, "hour")
      );
    }
  }, [sessionData, userGroups]);

  // set schedule toggle ON if out of school hours
  useEffect(() => {
    if (isOutsideSchoolHours) {
      setIsScheduledSession(true);
    }
  }, [isOutsideSchoolHours, setIsScheduledSession]);

  // close modal on success
  useEffect(() => {
    if (isSendSuccess) {
      onModalClose();
    }
  }, [isSendSuccess, onModalClose]);

  //Pass google shared student ids to studentids
  useEffect(() => {
    if (!_.isEmpty(googleClassRoomIDs)) {
      setStudentIds(googleClassRoomIDs);
    } else {
      setStudentIds(_.map(students, (student) => student.Id));
    }
  }, [googleClassRoomIDs, setStudentIds, students]);

  const switchRecipientType = (rt) => {
    setRecipientType(rt);
    // when recipientType changes, update studentIds for "class" type
    if (rt === SESSION_RECIPIENT_TYPES.CLASS) {
      setStudentIds(_.map(students, (student) => student.Id));
    }
    // when recipientType changes, update studentIds for "students" type
    if (rt === SESSION_RECIPIENT_TYPES.STUDENTS) {
      setStudentIds([]);
    }
    // when recipientType changes, update studentIds for "groups" type
    if (rt === SESSION_RECIPIENT_TYPES.GROUPS) {
      setStudentIds([]);
    }
    if (
      rt === SESSION_RECIPIENT_TYPES.STUDENTS &&
      !_.isEmpty(googleClassRoomIDs)
    ) {
      setStudentIds(googleClassRoomIDs);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const students = !_.isEmpty(selectedStudent)
      ? [selectedStudent.Id]
      : studentIds;
    const hour = scheduledTime.format("HH");
    const minute = scheduledTime.format("mm");
    const formattedDate = scheduledDate.hour(hour).minute(minute);
    const sessionId = _.get(sessionData, "SessionId", "");
    const sessionDetails = {
      links,
      recipientType: recipientType,
      students,
      classId: selectedClassId,
      isScheduled: isScheduledSession,
      scheduledTime: isScheduledSession ? formattedDate.toISOString() : null,
      sessionName: sessionName,
    };
    const isSelectedDateOutOfSchoolHours = pickerDisabledState(
      PICKER_DISABLED_TYPES.DATE,
      monitoringTime
    )(scheduledDate);
    const isSelectedTimeOutOfSchoolHours = pickerDisabledState(
      PICKER_DISABLED_TYPES.TIME,
      monitoringTime,
      scheduledDate
    )(scheduledTime);

    if (!_.isEmpty(selectedStudent)) {
      sessionDetails.recipientType = SESSION_RECIPIENT_TYPES.STUDENTS;
    }
    if (recipientType === SESSION_RECIPIENT_TYPES.GROUPS) {
      sessionDetails.groups = groupUrns;
    }
    if (sessionId && sessionId !== "") {
      sessionDetails.sessionId = sessionId;
    }
    if (isScheduledSession) {
      if (isSelectedDateOutOfSchoolHours || isSelectedTimeOutOfSchoolHours) {
        if (isSelectedDateOutOfSchoolHours) setIsScheduledDateError(true);
        if (isSelectedTimeOutOfSchoolHours) setIsScheduledTimeError(true);
        return;
      }
      if (
        scheduledTime.add(1, "minutes").isBefore() &&
        moment(scheduledDate).isSame(moment(), "day")
      ) {
        setIsScheduledTimeError(true);
        return;
      }
      if (scheduledDate.endOf("day").isBefore()) {
        setIsScheduledDateError(true);
        return;
      }
    }
    setSendingInProgress(true);
    onShareLinks(sessionDetails);
    addRecentTabsHistory({ urls: links });
  };

  return (
    <ModalDeprecated
      isOpen={true}
      onClose={onModalClose}
      hasEmbeddedHeader={false}
      className={styles.root}
      dataTestPrefix={dataTestPrefix}
      contentLabel={!isGClassroomShare ? customShareTitle : gcShareTitle}
    >
      <div className={styles.modalTitle}>
        <div className={styles.modalTitleHeaderContainer}>
          <h1
            data-test-id={`${dataTestPrefix}-Header`}
            className={styles.modalTitleHeader}
          >
            {!isGClassroomShare ? customShareTitle : gcShareTitle}
          </h1>
          <Button
            icon="cross"
            onClick={onModalClose}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              id: "rbrahO",
            })}
            data-test-id={`${dataTestPrefix}-Button-Close`}
            className={styles.modalTitleClose}
          />
        </div>
        <WarningMessage
          isVisible={isOutsideSchoolHours}
          icon="circle-information"
          className={styles.modalTitleWarning}
          dataTestId={`${dataTestPrefix}-OutOfSchoolHoursWarning`}
        >
          <FormattedMessage
            defaultMessage="School is over for the day. Please schedule this session for later."
            id="Byc8xM"
          />
        </WarningMessage>
      </div>
      <div className={styles.modalBody}>
        {!isGClassroomShare ? (
          <ModalFormLinks
            links={links}
            onLinksChange={setLinks}
            focusType={GUIDE_BROWSING_FOCUS_TYPES.LINKS}
            onFocusTypeChange={() => {}}
            sessionType={SESSION_TYPES.SHARE_LINKS}
            dataTestPrefix={dataTestPrefix}
            recentTabs={recentTabs}
          />
        ) : null}
        {isGClassroomShare && (
          <ModalFormGClassroomShare
            addShareLink={setLinks}
            dataTestPrefix={dataTestPrefix}
            addLinkRecipients={setGoogleClassRoomIDs}
          />
        )}
        {_.isEmpty(selectedStudent) && !isGClassroomShare && (
          <ModalFormStudentPicker
            recipientType={recipientType}
            onRecipientTypeChange={switchRecipientType}
            studentIds={studentIds}
            onStudentIdsChange={setStudentIds}
            groupUrns={groupUrns}
            onGroupUrnsChange={setGroupUrns}
            dataTestPrefix={dataTestPrefix}
            nameFormat={nameFormat}
            userGroups={userGroups}
            getGroupStatusInfo={getGroupStatusInfo}
            blockTitle={intl.formatMessage({
              defaultMessage: "Who is this session for?",
              id: "Vq2Dd1",
            })}
            studentsInGbSessionWarning={intl.formatMessage({
              defaultMessage:
                "Some students are in an active Guided Session. Links not accessible to students will not open right now.",
              id: "TyhQ2b",
            })}
          />
        )}
        {!isGClassroomShare && (
          <ModalFormSchedule
            dataTestPrefix={dataTestPrefix}
            isScheduledSession={isScheduledSession}
            onScheduledSessionChange={setIsScheduledSession}
            scheduledDate={scheduledDate}
            scheduledTime={scheduledTime}
            onScheduledDateChange={(ts) => {
              setIsScheduledDateError(false);
              setScheduledDate(ts);
            }}
            onScheduledTimeChange={(ts) => {
              setIsScheduledTimeError(false);
              setScheduledTime(ts);
            }}
            isScheduledDateError={isScheduledDateError}
            isScheduledTimeError={isScheduledTimeError}
          />
        )}
      </div>
      <div className={styles.actions}>
        <Alerts>
          <Alert
            type={ALERT_TYPES.FAILURE}
            isVisible={isSendError}
            className={styles.alert}
          >
            <FormattedMessage
              defaultMessage="Sorry, there was a problem sharing links. Please try again."
              id="Z6N6U3"
            />
          </Alert>
        </Alerts>
        <div className={styles.actionsInner}>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onClick={onModalClose}
            data-test-id={`${dataTestPrefix}-Button-Cancel`}
            type={BUTTON_TYPES.SECONDARY}
            className={styles.cancelButton}
          />
          <Button
            label={shareButtonTitle()}
            onClick={handleSubmit}
            data-test-id={
              isScheduledSession
                ? `${dataTestPrefix}-Button-Schedule`
                : `${dataTestPrefix}-Button-Start${
                    isGClassroomShare ? "GClassroom" : ""
                  }`
            }
            className={styles.sendButton}
            isDisabled={isSendButtonDisabled}
            isLoading={sendingInProgress && !isSendError}
          />
        </div>
      </div>
    </ModalDeprecated>
  );
};

ShareLinksModal.propTypes = {
  isSendError: PropTypes.bool,
  isSendSuccess: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onShareLinks: PropTypes.func.isRequired,
  selectedClassId: PropTypes.string,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  nameFormat: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  selectedStudent: STUDENT_SEND_MESSAGE_TYPE,
  sessionData: SHARE_LINKS_SESSION_TYPE,
  recentTabs: PropTypes.arrayOf(PropTypes.string),
  loadRecentTabsHistory: PropTypes.func.isRequired,
  addRecentTabsHistory: PropTypes.func.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  monitoringTime: MONITORING_TIME_TYPE,
  isGClassroomShare: PropTypes.bool,
};

export default connect(
  (state) => ({
    isSendError: getShareLinksModalErrorStatus(state),
    isSendSuccess: getShareLinksModalSuccessStatus(state),
    selectedClassId: getClassId(state),
    students: getStudentConfigsList(state),
    nameFormat: getUserNameFormat(state),
    userGroups: getUserGroups(state),
    selectedStudent: getShareLinksSelectedStudent(state),
    sessionData: getShareLinksSessionData(state),
    recentTabs: getRecentTabsHistory(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
    monitoringTime: getMonitoringTime(state),
    isDuplicateSession: isShareLinksDuplicateSession(state),
    isGClassroomShare: getIsGClassroomShare(state),
  }),
  (dispatch) => ({
    onModalClose: () => dispatch(hideShareLinksModal()),
    loadRecentTabsHistory: () => dispatch(loadGuideBrowsingRecentTabsHistory()),
    addRecentTabsHistory: ({ urls }) => {
      dispatch(addGuideBrowsingRecentTabsHistory({ urls }));
    },
    onShareLinks: ({
      links,
      recipientType,
      students,
      classId,
      scheduledTime,
      isScheduled,
      groups,
      sessionName,
      sessionId,
    }) =>
      dispatch(
        shareLinksAction({
          links,
          recipientType,
          students,
          classId,
          scheduledTime,
          isScheduled,
          groups,
          sessionName,
          sessionId,
        })
      ),
  })
)(ShareLinksModal);
