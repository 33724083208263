import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import styles from "./GradingAssigneesDropdown.module.scss";
import _ from "lodash";
import Dropdown, {
  DropdownTrigger,
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import {
  getAssigneesOrdered,
  getGradingSelectedArtefactId,
  getGradingCardId,
  getGradingActivity,
  isAssigneeGroup,
} from "../../../../../state/workspace/grading/selectors";
import { MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE } from "../../../../../state/workspace/grading/types";
import { APP_PAGES } from "../../../../../state/router/types";

export const GradingAssigneesDropdown = ({
  cardId = "",
  activity = "",
  selectedArtefactId = "",
  assigneesList = [],
  isAssigneeGroup,
}) => {
  const triggerButtonRef = useRef();
  const [mode, setMode] = useState("");

  const setFocusToButton = useCallback(() => {
    if (triggerButtonRef && triggerButtonRef.current) {
      triggerButtonRef.current.focus();
    }
  }, [triggerButtonRef]);

  const onClose = useCallback(() => {
    _.delay(setFocusToButton, 100);
  }, [setFocusToButton]);

  const getNameAndDate = () => {
    const name = _.get(
      _.find(assigneesList, { ArtifactID: selectedArtefactId }),
      "formattedName",
      ""
    );

    return (
      <div className={styles.name}>
        <h1>{name}</h1>
      </div>
    );
  };

  useEffect(() => {
    if (isAssigneeGroup) {
      setMode("Group");
    } else {
      setMode("Individual");
    }
  }, [isAssigneeGroup]);

  return (
    <div className={styles.root}>
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
        className={styles.dropdown}
        classNameContent={styles.ddContent}
        triggerComponent={(props) => (
          <DropdownTrigger
            label={getNameAndDate()}
            widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
            data-test-id="ws-Grading-DropdownList-SelectNext"
            className={styles.dropdownTrigger}
            {...props}
          />
        )}
        itemComponentList={_.map(
          assigneesList,
          (assignee) =>
            ({ onClick, className, ...rest }) =>
              (
                <Link
                  key={assignee.Id}
                  to={{
                    pathname: `${APP_PAGES.WORKSPACE_GRADING.path}/${cardId}/${activity}`,
                    search: `?artefact=${assignee.ArtifactID}`,
                    state: { from: APP_PAGES.WORKSPACE_MY_WORKSPACE.path },
                  }}
                  replace
                  className={classnames(className, {
                    [styles.selected]:
                      assignee.ArtifactID === selectedArtefactId,
                  })}
                  data-test-id={`ws-Grading-DropDownList-Select${mode}Item`}
                  onClick={() => {
                    onClose();
                    onClick();
                  }}
                  {...rest}
                >
                  {assignee.formattedName}
                </Link>
              )
        )}
      />
    </div>
  );
};

GradingAssigneesDropdown.propTypes = {
  activity: PropTypes.string,
  cardId: PropTypes.string,
  selectedArtefactId: PropTypes.string,
  assigneesList: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE,
};

export default connect((state) => ({
  cardId: getGradingCardId(state),
  activity: getGradingActivity(state),
  selectedArtefactId: getGradingSelectedArtefactId(state),
  assigneesList: getAssigneesOrdered(state),
  isAssigneeGroup: isAssigneeGroup(state),
}))(GradingAssigneesDropdown);
