const UNIX_TIMESTAMP_MAX_MS = 2147483647_000; // January 19, 2038, 03:14:07 UTC

export const isUnixTimestamp = (timestampString: string): boolean => {
  if (!/^0$|^[1-9]\d*$/.test(timestampString)) {
    return false;
  }
  const timestamp = Number(timestampString);
  return (
    Number.isInteger(timestamp) &&
    !isNaN(timestamp) &&
    timestamp >= 0 &&
    timestamp <= UNIX_TIMESTAMP_MAX_MS
  );
};
