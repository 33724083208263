import { hipi } from "./hipi";
// @ts-check
// <reference types="../types/global.d.ts" />

// NOTE: This is written in JavaScript so we don't have to update build steps in
// other apps. Eventually we should be publishing transpiled versions of our
// packages, so we don't need to include them in our Webpack configs for example.

/** @typedef {"ui" | "chat" | "messaging" | "hsuite" | "admin" | "student" | "onboarding" | "highlights-extension"} LoggerScope */
/** @type {Record<LoggerScope, string>} */

const colors = {
  ui: "#33A2FC",
  chat: "#8B61AE",
  messaging: "#358558",
  hsuite: "#2146A4",
  admin: "#334158",
  student: "#BF3381",
  onboarding: "#F6872E",
  "highlights-extension": "#105E29",
};

(function () {
  const haparaLoggerKey = "hapara-logger";

  window.hapara = {
    ...window.hapara,
    logger: {
      /** @param {LoggerScope} [scope] */
      enable: (scope) => {
        localStorage.setItem(haparaLoggerKey, scope ?? "all");
        hipi();
      },

      disable: () => {
        localStorage.removeItem(haparaLoggerKey);
      },
      /** @returns {string | null} */
      enabledScope: () => {
        return localStorage.getItem(haparaLoggerKey);
      },
    },
  };

  if (hapara.logger.enabledScope() !== null) {
    hipi();
  }
})();

/**
 * Reduce noise in the `console` by using this util. Enable logs in the `console` by calling:
 * - `hapara.logger.enable()` for all logs.
 * - `hapara.logger.enable("chat")` for logs specific to a namespace only.
 * @param {LoggerScope} scope
 * @param {string} message
 * @param {unknown} [data] */
export const logger = (scope, message, data) => {
  const enabledScope = window.hapara?.logger?.enabledScope();
  const isEnabled = enabledScope === "all" || enabledScope === scope;

  if (isEnabled) {
    const namespaceMessage = [
      `%chapara:${scope}%c ${message}`,
      `background: ${colors[scope]}; color: white; padding: 2px 4px;`,
      "color: inherit",
    ];
    data
      ? // eslint-disable-next-line no-console, no-restricted-syntax
        console.log(...namespaceMessage, data)
      : // eslint-disable-next-line no-console, no-restricted-syntax
        console.log(...namespaceMessage);
  }
};
