import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./GradingWidgetSubmitted.module.scss";
import {
  returnFinal,
  returnForEdit,
  saveGrade,
  saveFeedback,
} from "../../../../../state/workspace/grading/actions";
import {
  getInProgress,
  getIsError,
  getGradingArtifact,
  isAutoSavingInProgress,
  isAutoSavingError,
  getLastReturnedActivityItem,
  getSelectedAssigneeArtifactId,
} from "../../../../../state/workspace/grading/selectors";
import Input from "@hapara/ui/src/atomic/Input/Input";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";
import GradingReturnMultiple from "../GradingReturnMultiple/GradingReturnMultiple";
import {
  MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE,
  MYWORKSPACES_GRADING_ARTIFACT_TYPE,
} from "../../../../../state/workspace/grading/types";

export const GradingWidgetSubmitted = ({
  isActionInProgress,
  isActionError = false,
  gradingArtifact = { Grade: "", Feedback: "" },
  autoSavingInProgress,
  autoSavingError,
  returnFinal,
  returnForEdit,
  saveGrade = () => {},
  saveFeedback = () => {},
  lastReturned,
  selectedArtifactId = "",
}) => {
  const [gradeValue, setGradeValue] = useState(gradingArtifact.Grade);
  const [feedbackValue, setFeedbackValue] = useState(gradingArtifact.Feedback);
  const [draftSaved, setDraftSaved] = useState(
    feedbackValue ||
      (!lastReturned && gradingArtifact.Grade) ||
      (lastReturned && !lastReturned.Grade && gradingArtifact.Grade) ||
      (lastReturned &&
        lastReturned.Grade &&
        gradingArtifact.Grade !== lastReturned.Grade)
  );

  const [actionPerformed, setActionPerformed] = useState(false);
  const [mode, setMode] = useState(gradeValue ? "-AddGrade" : "");

  const [afterSaving, setAfterSaving] = useState(draftSaved);
  const [amountOfSecondsLeft, setAmountOfSecondsLeft] = useState(5);
  let delayedId = useRef(null);

  const debouncedGradeSaving = useCallback(
    _.debounce((grade) => {
      setAfterSaving(true);
      saveGrade(selectedArtifactId, grade);
    }, 2000),
    []
  );

  const debouncedFeedbackSaving = useCallback(
    _.debounce((feedback) => {
      setAfterSaving(true);
      saveFeedback(selectedArtifactId, feedback);
    }, 2000),
    []
  );

  const countDown = useCallback(
    () => setAmountOfSecondsLeft(amountOfSecondsLeft - 1),
    [amountOfSecondsLeft]
  );

  const handleGradeChange = (e) => {
    const newValue = _.get(e, "target.value", "");
    setGradeValue(newValue);
    setAfterSaving(false);
    debouncedGradeSaving(newValue);
  };

  const handleFeedbackChange = (e) => {
    const newValue = _.get(e, "target.value", "");
    setFeedbackValue(newValue);
    setAfterSaving(false);
    debouncedFeedbackSaving(newValue);
  };

  const forceSaving = useCallback(() => {
    clearTimeout(delayedId.current);
    setAfterSaving(true);
    saveGrade(selectedArtifactId, gradeValue);
    saveFeedback(selectedArtifactId, feedbackValue);
    setAmountOfSecondsLeft(5);
  }, [gradeValue, feedbackValue, saveGrade, saveFeedback, selectedArtifactId]);

  useEffect(() => {
    if (actionPerformed && !isActionError) {
      setGradeValue("");
      setActionPerformed(false);
    }
  }, [isActionError, actionPerformed, setActionPerformed]);

  useEffect(() => {
    const updatedDraft =
      feedbackValue ||
      (!lastReturned && gradingArtifact.Grade) ||
      (lastReturned && !lastReturned.Grade && gradingArtifact.Grade) ||
      (lastReturned &&
        lastReturned.Grade &&
        gradingArtifact.Grade !== lastReturned.Grade);

    setDraftSaved(!!updatedDraft);
  }, [feedbackValue, gradingArtifact, lastReturned]);

  useEffect(() => {
    if (amountOfSecondsLeft && autoSavingError) {
      delayedId.current = _.delay(countDown, 1000);
    } else if (amountOfSecondsLeft === 0) {
      forceSaving();
    }
  }, [amountOfSecondsLeft, autoSavingError, countDown, forceSaving]);

  // clean up on unMount
  useEffect(() => {
    return () => {
      clearTimeout(delayedId.current);
    };
  }, []);

  useEffect(() => {
    if (gradeValue && feedbackValue) {
      setMode("-AddGradeFeedback");
    } else if (gradeValue) {
      setMode("-AddGrade");
    } else if (feedbackValue) {
      setMode("-AddFeedback");
    } else {
      setMode("");
    }
  }, [gradeValue, feedbackValue, setMode]);

  return (
    <div className={styles.root} data-test-id="Ws-GradingWidget-Submitted">
      <form className={styles.body}>
        <div className={styles.notifications}>
          {autoSavingInProgress && !autoSavingError && (
            <div className={styles.inProgressMessage}>
              <div className={styles.loader}>
                <HapReactIcon svg="loader" width={16} height={16} />
              </div>
              <span className={styles.bold}>
                <FormattedMessage
                  defaultMessage="Saving draft..."
                  id="/FfBlg"
                />
              </span>
            </div>
          )}

          {!autoSavingInProgress &&
            !autoSavingError &&
            draftSaved &&
            afterSaving && (
              <div
                className={styles.warningMessage}
                data-test-id="ws-Grading-AutoSavingDraft"
              >
                <span className={styles.bold}>
                  <FormattedMessage defaultMessage="Draft saved." id="u8XK9q" />
                </span>{" "}
                Return for students to see.
              </div>
            )}

          {!autoSavingInProgress && autoSavingError && (
            <div
              className={styles.errorMessage}
              data-test-id="ws-Grading-AutoSavingError"
            >
              <span>
                Error saving draft. Reconnecting in {amountOfSecondsLeft}s...{" "}
              </span>
            </div>
          )}
        </div>

        <div className={styles.grade}>
          Grade:
          <Input
            value={gradeValue}
            type="text"
            disabled={isActionInProgress}
            onChange={handleGradeChange}
            aria-label="Assess work"
            placeholder="Assess work"
            maxLength={7}
            className={styles.field}
            themeType={THEME_TYPES.MEDIUM}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.feedback}>
          Feedback:
          <Textarea
            value={feedbackValue}
            data-test-id="Ws-GradingWidget-Submitted-feedback"
            aria-label="Share feedback"
            placeholder="Share feedback"
            onChange={handleFeedbackChange}
            disabled={isActionInProgress}
            isAutoExpandable={true}
            className={styles.field}
            themeType={THEME_TYPES.MEDIUM}
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Alerts className={styles.returnErrorAlertContainer}>
            <Alert
              type={ALERT_TYPES.FAILURE}
              isVisible={isActionError}
              className={styles.returnErrorAlert}
            >
              <FormattedMessage
                defaultMessage="There was a problem returning work. Please try again."
                id="4bw9ZW"
              />
            </Alert>
          </Alerts>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={() => {
              setActionPerformed(true);
              returnForEdit({ grade: gradeValue, feedback: feedbackValue });
            }}
            label="Return for edit"
            icon="refresh"
            dataTestId={`ws-Grading${mode}-ReturnForEdit`}
            className={classnames(styles.fullWidth, {
              [styles.marginTop]: isActionError,
            })}
            isDisabled={isActionInProgress}
          />
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={() => {
              setActionPerformed(true);
              returnFinal({ grade: gradeValue, feedback: feedbackValue });
            }}
            label={isActionInProgress ? "Returning final..." : "Return final"}
            icon="mail-reply"
            dataTestId={`ws-Grading${mode}-ReturnFinal`}
            className={styles.fullWidth}
            isDisabled={isActionInProgress}
          />
        </div>
      </form>
      <div className={styles.bulkReturn}>
        <GradingReturnMultiple />
      </div>
    </div>
  );
};

GradingWidgetSubmitted.propTypes = {
  isActionInProgress: PropTypes.bool.isRequired,
  isActionError: PropTypes.bool,
  gradingArtifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  autoSavingInProgress: PropTypes.bool,
  autoSavingError: PropTypes.bool,
  returnFinal: PropTypes.func.isRequired,
  returnForEdit: PropTypes.func.isRequired,
  saveGrade: PropTypes.func.isRequired,
  saveFeedback: PropTypes.func.isRequired,
  lastReturned: MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE,
  selectedArtifactId: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    isActionInProgress: getInProgress(state),
    isActionError: getIsError(state),
    gradingArtifact: getGradingArtifact(state),
    autoSavingInProgress: isAutoSavingInProgress(state),
    autoSavingError: isAutoSavingError(state),
    lastReturned: getLastReturnedActivityItem(state),
    selectedArtifactId: getSelectedAssigneeArtifactId(state),
  }),
  (dispatch) => ({
    returnFinal: (options) => dispatch(returnFinal(options)),
    returnForEdit: (options) => dispatch(returnForEdit(options)),
    saveGrade: (artifactId, grade) => dispatch(saveGrade(artifactId, grade)),
    saveFeedback: (artifactId, feedback) =>
      dispatch(saveFeedback(artifactId, feedback)),
  })
)(GradingWidgetSubmitted);
