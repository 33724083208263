import React from "react";
import { createIcon } from "./utils/createIcon";

export const EditIcon = createIcon(
  <path
    d="M12 6.7 9.3 4M1.7 14.3l2.2-.2.6-.1s.2 0 .3-.2l.4-.3L14 4.7A1.9 1.9 0 1 0 11.3 2l-8.8 8.8-.3.4-.2.3v.6l-.3 2.2Z"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
