export const SHARE_TYPE_COPY = "copy";
export const SHARE_TYPE_READ = "reader";
export const SHARE_TYPE_READ_COMMENT = "commenter";
export const SHARE_TYPE_READ_WRITE = "writer";

export const SHARE_TYPES = [
  {
    name: "Copy: student-owned",
    id: SHARE_TYPE_COPY,
  },
  {
    name: "Share: viewer",
    id: SHARE_TYPE_READ,
  },
  {
    name: "Share: commenter",
    id: SHARE_TYPE_READ_COMMENT,
  },
  {
    name: "Share: editor",
    id: SHARE_TYPE_READ_WRITE,
  },
];
