import React from "react";
import { ChatChannelItem } from "../ChatChannelItem/ChatChannelItem";
import {
  ChatContactsState,
  ChatChannelsState,
  ChatClassState,
  ChatRoleType,
} from "../../types/state";
import { ChatSubHeading } from "../ChatSubHeading";
import { FormattedMessage } from "react-intl";
import styles from "./ChatChannelList.module.scss";
import { DisplayNameFormat } from "../../../DisplayName";

export type ChatChannelListProps = {
  classId?: string | null;
  selectedClass: ChatClassState;
  role: ChatRoleType;
  contacts: ChatContactsState | null;
  channels: ChatChannelsState | null;
  onSelectChannelId(id: string): void;
  isClassEnabled?: boolean;
  isSchoolHours?: boolean;
  displayNameFormat?: DisplayNameFormat;
};

export const ChatChannelList = ({
  classId,
  selectedClass,
  role,
  contacts,
  channels,
  onSelectChannelId,
  isClassEnabled,
  isSchoolHours,
  displayNameFormat,
}: ChatChannelListProps) => {
  if (!contacts || !channels) return null;

  // TODO: This is a temporary UX solution PS-1289, see linked tickets.
  const sortedChannelsKeys = Object.keys(channels).sort((a, b) => {
    const contactA = contacts[channels[a].contactId];
    const contactB = contacts[channels[b].contactId];
    if (!contactA || !contactB) return 0;
    const firstNameComparison = contactA.firstName.localeCompare(
      contactB.firstName
    );
    if (firstNameComparison !== 0) return firstNameComparison;
    return contactA.lastName.localeCompare(contactB.lastName);
  });

  return (
    <div className={styles.container}>
      <ChatSubHeading>
        {role === "student" ? (
          <FormattedMessage defaultMessage="Select teacher" id="uGWGGM" />
        ) : (
          <FormattedMessage defaultMessage="Select student" id="k4C6r4" />
        )}
      </ChatSubHeading>
      <div className={styles.channels}>
        {sortedChannelsKeys.map((id) => {
          const channel = channels[id];
          const contact = contacts[channel.contactId];

          if (!contact) return null;

          const isTeacherEnabled =
            selectedClass.status?.[channel.contactId] === "enabled";
          const isDisabled = !isClassEnabled && !isTeacherEnabled;

          return (
            // NOTE: this isn't included in "ChatChannelItem" since it's reused
            // in the "ChatChannelView" title, maybe that should change.
            <div
              // TODO: in future we may have group channels,
              // this shouldn't be fixed to "direct"
              data-test-id="chat-direct-channel-list-item"
              className={styles.channel}
              onClick={() => onSelectChannelId(id)}
              key={channel.contactId}
              role="button"
            >
              <ChatChannelItem
                role={role}
                unreadCount={
                  classId ? channel.classStatus?.[classId]?.unreadCount : 0
                }
                firstName={contact.firstName}
                lastName={contact.lastName}
                presence={contact.presence}
                isDisabled={isDisabled}
                isOutsideSchoolHours={!isSchoolHours}
                displayNameFormat={displayNameFormat}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
