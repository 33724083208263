import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import classnames from 'classnames'
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from '@hapara/ui/src/atomic/Button/Button'
import Dropdown, { DROPDOWN_CONTENT_POSITION_TYPES } from '@hapara/ui/src/atomic/Dropdown/Dropdown'
import {
  archiveWorkspaceAction,
  copyWorkspaceAction,
  deleteWorkspaceAction,
  dismissError,
  restoreWorkspaceAction,
} from '../../../../../state/workspace/myworkspaces/list/actions'
import {
  actionInProgress,
  actionError,
} from '../../../../../state/workspace/myworkspaces/list/selectors'
import { getFFByName } from '../../../../../state/app/selectors'
import {
  MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS,
  wsItemType,
} from '../../../../../state/workspace/myworkspaces/list/types'
import ActionDialog from '../ActionDialog/ActionDialog'
import styles from './WsTileContextMenuDropdown.module.scss'
import { stripHtmlTagsFromString } from '@hapara/ui/src/components/utils'

const actionsConfig = {
  copy: {
    title: 'Copy?',
    description: (
      <React.Fragment>
        <p>Only cards and groups will be copied. Students and student work will not.</p>
        <p>It may take some time to generate the copy, but you can keep using Workspace!</p>
        <p>Copies appear under "Owned by me".</p>
      </React.Fragment>
    ),
    actionLabel: 'Make a copy',
    actionInProgressLabel: 'Copying',
    errorMessage: 'Sorry, there is a problem copying this Workspace. Please try again.',
    enableCancelInProgress: true,
    cancelInProgressLabel: 'Keep using Workspace',
    cancelDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-CancelCopy',
    actionDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-ConfirmCopy',
  },
  copyPL: {
    title: 'Copy professional learning Workspace?',
    description: (
      <React.Fragment>
        <p>Only cards and groups will be copied. Participants and their work will not.</p>
        <p>It may take some time to generate the copy, but you can keep using Workspace!</p>
        <p>Copies appear under "Owned by me" and “Professional learning”.</p>
      </React.Fragment>
    ),
    actionLabel: 'Make a copy',
    actionInProgressLabel: 'Copying',
    errorMessage: 'Sorry, there is a problem copying this Workspace. Please try again.',
    enableCancelInProgress: true,
    cancelInProgressLabel: 'Keep using Workspace',
    cancelDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-CancelCopy',
    actionDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-ConfirmCopy',
  },
  archive: {
    title: 'Archive?',
    description: (
      <React.Fragment>
        <p>You and your students will no longer be able to add and interact with the cards.</p>
        <p>You can access archived Workspaces from the menu.</p>
      </React.Fragment>
    ),
    actionLabel: 'Archive',
    actionInProgressLabel: 'Archiving',
    errorMessage: 'Sorry, there is a problem archiving this Workspace. Please try again.',
    cancelDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-CancelArchive',
    actionDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-ConfirmArchive',
  },
  restore: {
    title: 'Restore?',
    description: (
      <React.Fragment>
        <p>You and your students will have the option to add and interact with the cards again.</p>
        <p>
          This Workspace will be restored to the status (draft or published) it was archived in.
        </p>
      </React.Fragment>
    ),
    actionLabel: 'Restore Workspace',
    actionInProgressLabel: 'Restoring',
    errorMessage: 'Sorry, there is a problem restoring this Workspace. Please try again.',
    cancelDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-CancelRestore',
    actionDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-ConfirmRestore',
  },
  delete: {
    title: 'Delete?',
    description: 'All content in this Workspace will be permanently deleted.',
    actionLabel: 'Delete Workspace',
    actionInProgressLabel: 'Deleting',
    errorMessage: 'Sorry, there is a problem deleting this Workspace. Please try again.',
    isActionDanger: true,
    cancelDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-CancelDelete',
    actionDataTestId: 'Ws-TileContextMenuConfirmDialog-Button-ConfirmDelete',
  },
}

export const WsTileContextMenuDropdown = ({
  archiveWorkspace,
  copyWorkspace,
  deleteWorkspace,
  restoreWorkspace,
  item,
  actionInProgress,
  actionError,
  dismissError,
  plFF,
}) => {
  const [currentAction, setCurrentAction] = useState('')
  const [startedAction, setStartedAction] = useState(false)

  const triggerButtonRef = useRef()

  const setFocusToButton = useCallback(() => {
    if (triggerButtonRef && triggerButtonRef.current) {
      triggerButtonRef.current.focus()
    }
  }, [triggerButtonRef])

  const close = useCallback(() => {
    setCurrentAction('')
    setStartedAction(false)
    dismissError()
    _.delay(setFocusToButton, 100)
  }, [dismissError, setFocusToButton, setStartedAction, setCurrentAction])

  const open = action => {
    dismissError()
    setCurrentAction(action)
  }

  useEffect(() => {
    if (startedAction && !actionInProgress && !actionError) {
      close()
    }
  }, [actionInProgress, actionError, close, startedAction])

  const actions = {
    copy: copyWorkspace,
    copyPL: copyWorkspace,
    archive: archiveWorkspace,
    delete: deleteWorkspace,
    restore: restoreWorkspace,
  }
  const isArchived = item.IsArchived

  const listOfActions = []

  if (item.MyAvailableActions.indexOf('CopyBoard') >= 0 && !plFF) {
    listOfActions.push({
      label: 'Copy',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.COPY,
      id: 'Ws-TileContextMenu-Button-Copy',
    })
  }
  if (item.MyAvailableActions.indexOf('CopyBoard') >= 0 && !item.IsPL && plFF) {
    listOfActions.push({
      label: 'Copy',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.COPY,
      id: 'Ws-TileContextMenu-Button-Copy',
    })
  }
  if (item.MyAvailableActions.indexOf('CopyBoard') >= 0 && item.IsPL && plFF) {
    listOfActions.push({
      label: 'Copy',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.COPYPL,
      id: 'Ws-TileContextMenu-Button-Copy',
    })
  }
  if (!isArchived && item.MyAvailableActions.indexOf('EditBoard') >= 0) {
    listOfActions.push({
      label: 'Archive',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.ARCHIVE,
      id: 'Ws-TileContextMenu-Button-Archive',
    })
  }
  if (isArchived && item.MyAvailableActions.indexOf('UnarchiveBoard') >= 0) {
    listOfActions.push({
      label: 'Restore',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.RESTORE,
      id: 'Ws-TileContextMenu-Button-Restore',
    })
  }
  if (item.MyAvailableActions.indexOf('DeleteWorkspace') >= 0) {
    listOfActions.push({
      label: 'Delete',
      type: MYWORKSPACES_TILE_CONTEXT_MENU_ITEMS.DELETE,
      id: 'Ws-TileContextMenu-Button-Delete',
    })
  }

  if (listOfActions.length === 0) {
    return <></>
  }

  return (
    <div className={styles.root}>
      <ActionDialog
        title={stripHtmlTagsFromString(item.Title)}
        actionInProgress={actionInProgress}
        action={() => {
          if (currentAction) {
            setStartedAction(true)
            actions[currentAction](item)
          }
        }}
        actionConfig={actionsConfig[currentAction] || {}}
        isOpen={currentAction !== ''}
        close={close}
        error={actionError}
        dataTestPrefix="Ws-TileContextMenu-Confirmation"
      />

      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={props => (
          <Button
            icon="ellipsis"
            size={BUTTON_SIZES.SMALL}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            data-test-id="Ws-TileContextMenu-Dropdown-Trigger"
            alt="Open"
            aria-label="Open dropdown"
            ref={triggerButtonRef}
            className={styles.dropdownTriggerButton}
            {...props}
          />
        )}
        itemComponentList={_.map(listOfActions, actionItem => ({ onClick, className, ...rest }) => (
          <button
            onClick={() => {
              open(actionItem.type)
              onClick()
            }}
            data-test-id={actionItem.id}
            className={classnames(className, styles.dropdownContentItem)}
            {...rest}
          >
            {actionItem.label}
          </button>
        ))}
      />
    </div>
  )
}

WsTileContextMenuDropdown.propTypes = {
  item: wsItemType,
  actionInProgress: PropTypes.bool,
  actionError: PropTypes.bool,
  archiveWorkspace: PropTypes.func.isRequired,
  copyWorkspace: PropTypes.func.isRequired,
  deleteWorkspace: PropTypes.func.isRequired,
  restoreWorkspace: PropTypes.func.isRequired,
  dismissError: PropTypes.func.isRequired,
  plFF: PropTypes.bool.isRequired,
}

export default connect(
  state => ({
    actionInProgress: actionInProgress(state),
    actionError: actionError(state),
    plFF: getFFByName('HAP-7034-professional-learning')(state),
  }),
  dispatch => ({
    archiveWorkspace: item => dispatch(archiveWorkspaceAction(item)),
    copyWorkspace: item => dispatch(copyWorkspaceAction(item)),
    deleteWorkspace: item => dispatch(deleteWorkspaceAction(item)),
    restoreWorkspace: item => dispatch(restoreWorkspaceAction(item)),
    dismissError: () => dispatch(dismissError()),
  })
)(WsTileContextMenuDropdown)
