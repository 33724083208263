import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import styles from "./StudentTileGuideBrowsing.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { SESSION_TYPES } from "../../../../state/highlights/sessions/types";
import ActionConfirmationDialog from "@hapara/ui/src/atomic/ActionConfirmationDialog/ActionConfirmationDialog";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { UPREF_NAME_FIRST_LAST } from "../../../../state/user/types";
import { releaseStudentsFromSession } from "../../../../state/highlights/sessions/actions";
import { getActiveGuideBrowsingSessionIds } from "../../../../state/highlights/sessions/selectors";
import { STUDENT_LOCK_LOCKING_TYPE } from "../../../../state/highlights/students/types";
import { useIntl, FormattedMessage } from "react-intl";

const StudentTileGuideBrowsing = ({
  studentId,
  gbSessionDetails,
  studentDisplayName,
  nameFormat,
  activeGbSessionsIds = [],
  releaseStudentsFromGuideBrowsingSession,
}) => {
  const intl = useIntl();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isReleaseInProgress, setIsReleaseInProgress] = useState(false);
  const [isReleaseError, setIsReleaseError] = useState(false);

  const isInGuideBrowsingSession = _.get(gbSessionDetails, "active", false);

  const sessionID = _.get(gbSessionDetails, "sessionID");

  const isNotInThisClassSession = useMemo(
    () => !_.includes(activeGbSessionsIds, sessionID),
    [activeGbSessionsIds, sessionID]
  );

  if (!isInGuideBrowsingSession) return null;

  const { sessionType, teacherFirstName, teacherLastName } = gbSessionDetails;

  let sessionTypeName = intl.formatMessage({
    defaultMessage: "guide browsing session",
    id: "nsgdi+",
  });

  let releaseFromText = intl.formatMessage({
    defaultMessage: "Release from guide browsing session",
    id: "d4qAfh",
  });

  if (sessionType === SESSION_TYPES.FILTER) {
    sessionTypeName = intl.formatMessage({
      defaultMessage: "filter session",
      id: "Lrt7w9",
    });
    releaseFromText = intl.formatMessage({
      defaultMessage: "Release from filter session",
      id: "UKsueX",
    });
  } else if (sessionType === SESSION_TYPES.FOCUS) {
    sessionTypeName = intl.formatMessage({
      defaultMessage: "focus session",
      id: "u2ZmzK",
    });
    releaseFromText = intl.formatMessage({
      defaultMessage: "Release from focus session",
      id: "HNcYCc",
    });
  } else if (sessionType === SESSION_TYPES.LOCK) {
    sessionTypeName = intl.formatMessage({
      defaultMessage: "freeze tabs",
      id: "ap7O6c",
    });
    releaseFromText = intl.formatMessage({
      defaultMessage: "Release frozen tabs",
      id: "bGm5BZ",
    });
  }

  const teacherFullName =
    nameFormat === UPREF_NAME_FIRST_LAST
      ? `${teacherFirstName} ${teacherLastName}`
      : `${teacherLastName}, ${teacherFirstName}`;

  const handleStudentRelease = () => {
    setIsReleaseInProgress(true);
    setIsReleaseError(false);

    releaseStudentsFromGuideBrowsingSession({
      studentIds: [studentId],
    })
      .then(() => {
        setIsReleaseInProgress(false);
        setIsConfirmationModalOpen(false);
      })
      .catch(() => {
        setIsReleaseInProgress(false);
        setIsReleaseError(true);
      });
  };

  return (
    <div className={styles.root}>
      <Tippy content={releaseFromText} theme="hsuite">
        <Button
          icon="fill-focused-browsing"
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id="hl-StudentTile-GuideBrowsing-Trigger"
          aria-label={releaseFromText}
          className={classnames(styles.guideBrowsingTriggerButton, {
            [styles.notInThisClassSession]: isNotInThisClassSession,
          })}
          onClick={() => setIsConfirmationModalOpen(true)}
        />
      </Tippy>

      <ActionConfirmationDialog
        isOpen={isConfirmationModalOpen}
        title={intl.formatMessage(
          {
            defaultMessage: `End session for {studentName}?`,
            id: "NsocET",
          },
          { studentName: studentDisplayName }
        )}
        subTitle={intl.formatMessage(
          {
            defaultMessage: `{sessionType} was initiated by {teacherName}`,
            id: "vLMJ8A",
          },
          {
            sessionType: _.capitalize(sessionTypeName),
            teacherName: teacherFullName,
          }
        )}
        content={
          <FormattedMessage
            defaultMessage="When a student is released, they will be able to browse the web without restrictions."
            id="GjlCl1"
          />
        }
        isActionDanger={true}
        actionLabel={releaseFromText}
        onClose={() => {
          setIsReleaseError(false);
          setIsConfirmationModalOpen(false);
        }}
        error={
          isReleaseError
            ? intl.formatMessage({
                defaultMessage:
                  "Sorry, there was a problem updating this session. Please try again.",
                id: "71DUFp",
              })
            : null
        }
        onAction={handleStudentRelease}
        isActionButtonDisabled={isReleaseInProgress}
        isActionInProgress={isReleaseInProgress}
        actionDataTestId="hl-StudentTile-GuideBrowsing-ReleaseStudent"
        cancelDataTestId="hl-StudentTile-GuideBrowsing-Cancel"
        dataTestPrefix="hl-StudentTile-GuideBrowsing"
      />
    </div>
  );
};

StudentTileGuideBrowsing.propTypes = {
  studentId: PropTypes.string.isRequired,
  gbSessionDetails: STUDENT_LOCK_LOCKING_TYPE,
  studentDisplayName: PropTypes.string.isRequired,
  // connected props
  nameFormat: PropTypes.string.isRequired,
  activeGbSessionsIds: PropTypes.arrayOf(PropTypes.string),
  releaseStudentsFromGuideBrowsingSession: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    nameFormat: getUserNameFormat(state),
    activeGbSessionsIds: getActiveGuideBrowsingSessionIds(state),
  }),
  (dispatch) => ({
    releaseStudentsFromGuideBrowsingSession: ({ studentIds }) =>
      dispatch(
        releaseStudentsFromSession({
          studentIds,
        })
      ),
  })
)(StudentTileGuideBrowsing);
