import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./SnapModalDetails.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import classnames from "classnames";
import { getClassId } from "../../../../state/shared/selectors";
import { getFormattedName } from "../../../../utils";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { getUserNameFormat } from "../../../../state/user/selectors";
import {
  SNAPSHOT_TYPE,
  NO_SCREENSHOT_AVAILABLE,
} from "../../../../state/highlights/snaps/types";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import { isRestrictedScreenshotUrl } from "@hapara/ui/src/components/utils";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import noSnapsImage from "@hapara/assets/src/images/vector-hl-no-snaps.svg";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DROPDOWN_WIDTH_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import _ from "lodash";
import moment from "moment";
import SnapsModalFeedback from "../SnapsModalFeedback/SnapsModalFeedback";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { ACTIVITY_TYPES } from "../../../../state/highlights/snaps/types";

const SnapModalDetails = ({
  classId,
  students,
  studentNameFormat,
  snapshot,
  onModalClose,
}) => {
  const intl = useIntl();
  const [isFeedbackView, setIsFeedbackView] = useState(false);
  const [isScreenshotFullWidth, setIsScreenshotFullWidth] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(
    snapshot ? snapshot.Students[0] : {}
  );
  const studentNames = [];
  students.forEach((s) => {
    studentNames[s.Email] = getFormattedName(studentNameFormat, s);
  });
  const totalAmountOfStudents = _.size(snapshot.Students);

  const getTimeFromTs = (ts) => moment(ts).format("h:mm a");

  if (!snapshot) {
    return null;
  }

  const screenshotUrl = _.get(selectedStudent, "Screenshot.Image");
  const screenshotAvailable = screenshotUrl !== NO_SCREENSHOT_AVAILABLE;

  const dataTestPrefix = "hl-SnapModal-Details";
  if (isFeedbackView) {
    return (
      <SnapsModalFeedback
        snapshot={snapshot}
        selectedStudent={_.get(selectedStudent, "StudentEmail")}
        closeAction={onModalClose}
        cancelAction={() => setIsFeedbackView(false)}
      />
    );
  }

  const activityMessage =
    snapshot.ActivityType === ACTIVITY_TYPES.UNIQUE_ACTIVITY
      ? intl.formatMessage({ defaultMessage: "Unique Activity:", id: "bf8Naj" })
      : snapshot.ActivityType === ACTIVITY_TYPES.CLASS_ACTIVITY
      ? intl.formatMessage({ defaultMessage: "Class Activity:", id: "5epydq" })
      : intl.formatMessage({ defaultMessage: "Collaboration:", id: "8lVFB1" });

  return (
    <div className={styles.root}>
      <div className={styles.whiteBlock}>
        <div className={styles.leftColumn}>
          <div className={styles.label}>
            {activityMessage}
            <span className={styles.studentName}>
              <FormattedMessage
                defaultMessage="{count, plural, one {1 Learner} other {# Learners}}"
                id="8kYhKC"
                values={{ count: totalAmountOfStudents }}
              />
            </span>
          </div>

          {totalAmountOfStudents < 2 && (
            <div
              className={styles.readOnlyField}
              data-test-id={`${dataTestPrefix}-SelectedStudentName`}
            >
              {studentNames[selectedStudent.StudentEmail]}
            </div>
          )}
          {totalAmountOfStudents > 1 && (
            <Dropdown
              positionType={DROPDOWN_CONTENT_POSITION_TYPES.LEFT}
              widthType={DROPDOWN_WIDTH_TYPES.FULL_WIDTH}
              className={styles.studentsDropdown}
              classNameContent={styles.ddContent}
              triggerComponent={(props) => (
                <Button
                  label={studentNames[selectedStudent.StudentEmail]}
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.XSMALL}
                  rightIcon="arrow-carvet-down"
                  dataTestId={`${dataTestPrefix}-SelectStudentDropdown`}
                  className={styles.dropDownButton}
                  {...props}
                />
              )}
              itemComponentList={snapshot.Students.map(
                (s) =>
                  ({ onClick, className, ...rest }) =>
                    (
                      <button
                        className={classnames(styles.dropdownItem, {
                          [styles.itemSelected]:
                            s.StudentEmail === selectedStudent.StudentEmail,
                        })}
                        onClick={() => {
                          onClick();
                          setSelectedStudent(s);
                        }}
                        aria-label={
                          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                          `${studentNames[s.StudentEmail]}`
                        }
                        aria-selected={
                          s.StudentEmail === selectedStudent.StudentEmail
                        }
                        tabIndex={0}
                        data-test-id={`${dataTestPrefix}-SelectStudentDropdown-ItemSelected`}
                        {...rest}
                      >
                        {studentNames[s.StudentEmail]}
                      </button>
                    )
              )}
            />
          )}
        </div>
        <div className={styles.rightColumn}>
          <Button
            icon="bubble-box-speech"
            type={BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={() => setIsFeedbackView(true)}
            dataTestId={`${dataTestPrefix}-SendFeedbackButton`}
            label={intl.formatMessage({
              defaultMessage: "Send learner(s) feedback",
              id: "eLqo9I",
            })}
          />
        </div>
      </div>
      <div
        className={classnames(styles.twoColumns, {
          [styles.fullWidth]: isScreenshotFullWidth,
        })}
      >
        <div className={styles.leftColumn}>
          <h2 className={styles.activityTitle}>
            <FormattedMessage
              defaultMessage="Activities on this website"
              id="5bpATl"
            />
          </h2>
          {_.orderBy(selectedStudent.Activity, ["TimeStamp"], ["desc"]).map(
            (tab, i) => (
              <div key={i} className={styles.activity}>
                <HapReactIcon
                  svg="clock-history"
                  width={16}
                  height={16}
                  className={styles.icon}
                  alt=""
                />
                <div className={styles.date}>
                  {tab.WasOpened ? (
                    <FormattedMessage defaultMessage="Opened -" id="SX1NqQ" />
                  ) : (
                    <FormattedMessage defaultMessage="Closed -" id="BQS0vQ" />
                  )}{" "}
                  {getTimeFromTs(tab.TimeStamp)}
                </div>
                <div className={styles.docName}>{tab.DocName}</div>
                <div className={styles.url}>
                  <ExternalLink
                    url={tab.Url}
                    studentEmail={selectedStudent.StudentEmail}
                    classId={classId}
                    dataTestId={`${dataTestPrefix}-Modal-ActivityLink`}
                    showIcon={false}
                  />
                </div>
              </div>
            )
          )}
        </div>
        <div className={styles.midPadding} />
        <div className={styles.rightColumn}>
          <h2 className={styles.activityTitle}>
            <FormattedMessage
              defaultMessage="Screen when snap was taken"
              id="mOuA3h"
            />
          </h2>
          <div className={styles.screenshot}>
            {screenshotAvailable &&
              !isRestrictedScreenshotUrl(snapshot.SiteUrl) && (
                <img
                  src={screenshotUrl}
                  alt={snapshot.Name}
                  className={styles.image}
                />
              )}
            {(!screenshotAvailable ||
              isRestrictedScreenshotUrl(snapshot.SiteUrl)) && (
              <div className={styles.noScreenshot}>
                <img src={noSnapsImage} alt="" width={147} height={101} />
                <p>
                  <FormattedMessage
                    defaultMessage="No screenshot available"
                    id="JA2gFS"
                  />
                </p>
              </div>
            )}
          </div>
          {screenshotAvailable && (
            <div className={styles.enlargeButton}>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                size={BUTTON_SIZES.REGULAR}
                onAction={() => {
                  setIsScreenshotFullWidth(!isScreenshotFullWidth);
                }}
                data-test-id={`${dataTestPrefix}-${
                  isScreenshotFullWidth ? "Button-Reduce" : "Button-Enlarge"
                }`}
                label={
                  isScreenshotFullWidth
                    ? intl.formatMessage({
                        defaultMessage: "Reduce",
                        id: "rst9kl",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Enlarge",
                        id: "IaqTie",
                      })
                }
                rightIcon={
                  isScreenshotFullWidth ? "arrow-inward" : "arrow-outward"
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SnapModalDetails.propTypes = {
  snapshot: SNAPSHOT_TYPE,
  onModalClose: PropTypes.func.isRequired,
  // connected props
  classId: PropTypes.string,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  studentNameFormat: PropTypes.string.isRequired,
};

export default connect((state) => ({
  classId: getClassId(state),
  students: getStudentConfigsList(state),
  studentNameFormat: getUserNameFormat(state),
}))(SnapModalDetails);
