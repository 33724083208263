import { useIntl } from "react-intl";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";

import styles from "./studentGroupParticipant.module.scss";

import Button, { BUTTON_SIZES, BUTTON_TYPES } from "../../atomic/Button/Button";
import Checkbox from "../../atomic/Checkbox/Checkbox";

/**
 *
 * @param data
 * @param spinner
 * @param order
 * @param canDelete,
 * @param onStudentSelect
 * @param onStudentDelete
 * @param errorOccurred
 * @returns {*}
 * @constructor
 */
const StudentGroupParticipant = ({
  data,
  order,
  canDelete,
  selected,
  errorOccurred,
  onStudentSelect,
  onStudentDelete,
}) => {
  const [spinner, setSpinner] = useState(!!errorOccurred.id);
  const intl = useIntl();
  // handling deleting of teh student from the group
  function handleStudentDelete(student) {
    setSpinner(true);
    onStudentDelete(student);
  }

  useEffect(() => {
    if (!errorOccurred.id) {
      setSpinner(false);
    }
  }, [errorOccurred]);

  const studentName =
    order === "fname"
      ? `${data.name} ${data.lastname}`
      : `${data.lastname}, ${data.name}`;

  return (
    <div className={styles.root}>
      {!canDelete && (
        <div className={styles.checkboxWrap}>
          <Checkbox
            onChange={onStudentSelect}
            id={`chck_${data.email}`}
            checked={selected}
            label={studentName}
            className={styles.checkbox}
          />
        </div>
      )}
      {canDelete && (
        <React.Fragment>
          <div className={styles.name}>{studentName}</div>
          <Tippy content="Remove" maxWidth={150} theme="hsuite">
            <Button
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              icon="cross"
              isLoading={spinner && canDelete}
              onAction={() => handleStudentDelete(data)}
              dataTestId="td-GroupsManager-StudentGroupParticipant-ButtonClose"
              aria-label={intl.formatMessage({
                defaultMessage: "Remove student",
                id: "fHSiuM",
              })}
            />
          </Tippy>
        </React.Fragment>
      )}
    </div>
  );
};

StudentGroupParticipant.propTypes = {
  data: PropTypes.object,
  canDelete: PropTypes.bool,
  order: PropTypes.string,
  onStudentSelect: PropTypes.func,
  onStudentDelete: PropTypes.func,
  errorOccurred: PropTypes.object,
};

export default StudentGroupParticipant;
