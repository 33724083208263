export const THEME_TYPES = {
  LIGHT: "light",
  MEDIUM: "medium",
  DARK: "dark",
} as const;

export type ThemeType = (typeof THEME_TYPES)[keyof typeof THEME_TYPES];

export const THEME_BACKGROUNDS = {
  [THEME_TYPES.LIGHT]: "#ffffff",
  [THEME_TYPES.MEDIUM]: "#f5f5f5",
  [THEME_TYPES.DARK]: "#2D2D47",
};
