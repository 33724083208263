import { FormattedMessage } from "react-intl";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./GradingHistoryEditableBlockItem.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import { THEME_TYPES } from "@hapara/ui/src/atomic/consts";
import {
  MYWORKSPACES_GRADING_HISTORY_PARAM_TYPE,
  MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES,
} from "../../../../../state/workspace/grading/types";
import {
  getHistoryUpdateInProgress,
  getHistoryUpdateError,
} from "../../../../../state/workspace/grading/selectors";
import {
  updateGradeFeedback,
  resetHistoryUpdateError,
} from "../../../../../state/workspace/grading/actions";

export const GradingHistoryEditableBlockItem = ({
  param,
  activityId,
  edited,
  isEditable,
  isActionInProgress,
  isActionError,
  resetHistoryUpdateError,
  updateGradeFeedback,
}) => {
  const dataTestPrefix = `ws-Grading-ActivityHistory`;
  const [editMode, setEditMode] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(param.value);
  const [actionPerformed, setActionPerformed] = useState(false);
  const maxLength =
    param.key === MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES.GRADE
      ? { maxlength: 7 }
      : {};

  const handleCancelEditing = () => {
    setEditMode(false);
    setActionPerformed(false);
    resetHistoryUpdateError();
  };

  const handleUpdateParam = () => {
    const updatedParam = {};
    updatedParam[param.key] = updatedValue;
    setActionPerformed(true);
    updateGradeFeedback({ ...updatedParam, activityId });
  };

  useEffect(() => {
    if (actionPerformed && !isActionInProgress && !isActionError) {
      setActionPerformed(false);
      setEditMode(false);
    }
  }, [
    actionPerformed,
    isActionInProgress,
    isActionError,
    setActionPerformed,
    setEditMode,
  ]);

  if (!param.value) return null;

  return (
    <div
      className={classnames(styles.root, { [styles.editing]: editMode })}
      data-test-id={`${dataTestPrefix}-${_.upperFirst(param.key)}`}
    >
      {!editMode && (
        <>
          <div className={styles.content}>
            <div className={styles.header}>{_.upperFirst(param.key)}</div>
            <div className={styles.body}>
              {param.value}{" "}
              {edited && (
                <>
                  <span>
                    <FormattedMessage defaultMessage="(edited)" id="gy/Kkr" />
                  </span>
                </>
              )}
            </div>
          </div>
          {isEditable && (
            <Button
              type={BUTTON_TYPES.SECONDARY}
              size={BUTTON_SIZES.SMALL}
              icon="pencil"
              onAction={() => {
                setEditMode(true);
                setUpdatedValue(param.value);
              }}
              dataTestId={`${dataTestPrefix}-Edit${_.upperFirst(param.key)}`}
              aria-label={`Edit ${param.key}`}
            />
          )}
        </>
      )}
      {editMode && (
        <>
          <div className={styles.content}>
            <div className={styles.header}>{_.upperFirst(param.key)}</div>
            <Textarea
              rows={1}
              value={updatedValue}
              disabled={isActionInProgress}
              aria-label={`${dataTestPrefix}-Editing${_.upperFirst(param.key)}`}
              onChange={(e) => setUpdatedValue(e.target.value)}
              isAutoExpandable={true}
              themeType={THEME_TYPES.MEDIUM}
              {...maxLength}
            />
          </div>
          <Alert type={ALERT_TYPES.FAILURE} isVisible={isActionError}>
            <FormattedMessage
              defaultMessage="Sorry, there was a problem updating. Please try again."
              id="W4HePf"
            />
          </Alert>
          <div
            className={classnames(styles.buttonsContainer, {
              [styles.marginTop]: isActionError,
            })}
          >
            <Button
              type={BUTTON_TYPES.OUTLINED}
              size={BUTTON_SIZES.SMALL}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              label="Cancel"
              onAction={handleCancelEditing}
              dataTestId={`${dataTestPrefix}-Edit${_.upperFirst(
                param.key
              )}-Cancel`}
              className={classnames(styles.whiteButton, {
                [styles.disabled]: isActionInProgress,
              })}
              isDisabled={isActionInProgress}
            />
            <Button
              type={BUTTON_TYPES.PRIMARY}
              size={BUTTON_SIZES.SMALL}
              label={isActionInProgress ? "Updating" : "Update"}
              onAction={handleUpdateParam}
              dataTestId={`${dataTestPrefix}-Edit${_.upperFirst(
                param.key
              )}-Update`}
              isDisabled={isActionInProgress}
            />
          </div>
        </>
      )}
    </div>
  );
};

GradingHistoryEditableBlockItem.propTypes = {
  param: MYWORKSPACES_GRADING_HISTORY_PARAM_TYPE,
  activityId: PropTypes.string.isRequired,
  edited: PropTypes.bool,
  isEditable: PropTypes.bool,
  isActionInProgress: PropTypes.bool.isRequired,
  isActionError: PropTypes.bool,
  resetHistoryUpdateError: PropTypes.func.isRequired,
  updateGradeFeedback: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isActionInProgress: getHistoryUpdateInProgress(state),
    isActionError: getHistoryUpdateError(state),
  }),
  (dispatch) => ({
    resetHistoryUpdateError: () => dispatch(resetHistoryUpdateError()),
    updateGradeFeedback: (options) => dispatch(updateGradeFeedback(options)),
  })
)(GradingHistoryEditableBlockItem);
