import actionTypes from "../../actionTypes";
import { updateItemStatus, updateItem } from "../discover/reducer";

export const INIT_STATE = {
  items: null,
  totalItems: 0,
  isLoading: false,
  pagesCount: 0,
  currentPage: 1,
  leftSidePanelOpen: false,
  searchGuid: null,
  searchIteration: 0,
};

const myResourcesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIBRARY_MY_RESOURCES_ITEMS_LOAD_SUCCESS:
      const { Results, TotalHits, TotalPages, CurrentPage } = action.payload;
      return {
        ...state,
        isLoading: false,
        items: Results ? Results : [],
        totalItems: TotalHits,
        currentPage: CurrentPage,
        pagesCount: TotalPages,
      };

    case actionTypes.LIBRARY_MY_RESOURCES_ITEMS_LOAD_PENDING:
      return { ...state, isLoading: true };

    case actionTypes.MY_RESOURCES_LEFT_PANEL_UPDATE:
      return { ...state, leftSidePanelOpen: action.payload };

    case actionTypes.LIBRARY_MY_RESOURCES_RESET_ITEMS:
      return INIT_STATE;

    case actionTypes.LIBRARY_MY_RESOURCES_SEARCH_GUID_UPDATE:
      return {
        ...state,
        searchGuid: action.searchGuid,
        searchIteration: action.iterCount,
      };

    case actionTypes.RESOURCES_MARK_AS_ADDED_TO_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: false,
      });

    case actionTypes.RESOURCES_MARK_AS_STARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_UNSTARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: false,
      });

    case actionTypes.RESOURCES_UPDATE_RESOURCE_AFTER_EDIT: {
      const { itemId, newData } = action.payload;
      return updateItem({ state, itemId, newData });
    }

    default:
      return state;
  }
};

export default myResourcesReducer;
