import React from "react";
import { createIcon } from "./utils/createIcon";

export const AnnounceIcon = createIcon(
  <>
    <path
      fill="currentColor"
      d="M6.8 3.7H4.5c-1 0-1.6 0-2 .2a2 2 0 0 0-1 .9c-.2.4-.2 1-.2 2v.9l.1 1.1c.2.4.4.6.8.8h1.1V12.9c.1.6.6 1.1 1.2 1.2h.6c.7 0 1.2-.6 1.2-1.2V9.7h.5c1.2 0 2.7.6 3.8 1.2.6.4 1 .5 1.1.5.2 0 .4-.1.5-.3l.1-1.2V2.2a.6.6 0 0 0-.6-.3l-1.1.5a8.7 8.7 0 0 1-3.8 1.3Z"
    />
    <path
      fill="currentColor"
      d="m11.5 2.5-.7.4a9.2 9.2 0 0 1-4 1.3H3.3l-.6.1c-.3.2-.6.4-.7.7l-.1.6v2.1a11.4 11.4 0 0 0 0 1c0 .2.2.3.4.4h1c.3 0 .5.3.5.6v3c0 .4.4.8.8.8a3.1 3.1 0 0 0 .5 0c.4 0 .7-.4.7-.8v-3c0-.3.3-.5.5-.5h.5c1.3 0 2.9.7 4 1.3l.7.3.2.1.1-.2V2.5H11.5Zm-.4-1h.7c.3 0 .6.1.8.4.2.2.2.5.2.7v8.2c0 .2 0 .4-.2.6-.2.3-.5.5-.8.5l-.7-.1-.8-.4a8.3 8.3 0 0 0-3.5-1.2V12.8c0 1-.7 1.6-1.6 1.7h-.7a1.8 1.8 0 0 1-1.7-2v-2.3h-.2c-.2 0-.4 0-.6-.2-.5-.2-.8-.5-1-1l-.2-.6V5.5c0-.4.2-.7.3-1 .2-.4.6-.8 1.1-1 .3-.2.6-.3 1-.3H6.8c1 0 2.4-.6 3.5-1.2l.8-.4Zm3.6 3.3c.2 0 .5.3.5.5V8a.5.5 0 0 1-1 0V5.3c0-.2.2-.5.5-.5Z"
    />
  </>
);
