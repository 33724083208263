import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import styles from "./GradingTitle.module.scss";
import GradingTitleLoading from "../GradingTitleLoading/GradingTitleLoading";
import GradingAssigneesDropdown from "../GradingAssigneesDropdown/GradingAssigneesDropdown";
import GradingAssigneesLoop from "../GradingAssigneesLoop/GradingAssigneesLoop";
import GradingToast from "../GradingToast/GradingToast";

import emptyThumbnail from "@hapara/assets/src/images/ws-thumbnail-default.svg";

import {
  getGradingCard,
  getCardDataLoading,
  cardNotValid,
  isActivityEligible,
} from "../../../../../state/workspace/grading/selectors";

import { MYWORKSPACES_GRADING_CARD_TYPE } from "../../../../../state/workspace/grading/types";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";

export const GradingTitle = ({
  card = {},
  cardDataLoading,
  cardNotValid,
  isActivityEligible,
}) => {
  const THUMBNAIL_SIZE = 40;

  if (!cardDataLoading && (cardNotValid || !isActivityEligible)) {
    return <div className={styles.container}></div>;
  }

  if (cardDataLoading) {
    return (
      <div className={styles.container}>
        <GradingTitleLoading />
      </div>
    );
  }

  return (
    <>
      <div
        className={classnames(styles.root, styles.container)}
        data-test-id="Ws-Grading-Title"
      >
        <div className={styles.main}>
          <div className={styles.thumbnail}>
            <img
              src={
                card.BoardThumbnail
                  ? `${card.BoardThumbnail}&w=${THUMBNAIL_SIZE * 2}&h=${
                      THUMBNAIL_SIZE * 2
                    }`
                  : `${emptyThumbnail}`
              }
              width={THUMBNAIL_SIZE}
              height={THUMBNAIL_SIZE}
              alt={card.BoardThumbnailAlt}
            />
          </div>
          <div className={styles.title}>
            <h2>{stripHtmlTagsFromString(card.Title)}</h2>
            <h3>{stripHtmlTagsFromString(card.BoardTitle)}</h3>
          </div>
        </div>
        <div className={styles.assignees}>
          <GradingAssigneesLoop />
          <GradingAssigneesDropdown />
        </div>
      </div>
      <GradingToast />
    </>
  );
};

GradingTitle.propTypes = {
  card: MYWORKSPACES_GRADING_CARD_TYPE,
  cardDataLoading: PropTypes.bool.isRequired,
  cardNotValid: PropTypes.bool.isRequired,
  isActivityEligible: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  card: getGradingCard(state),
  cardDataLoading: getCardDataLoading(state),
  cardNotValid: cardNotValid(state),
  isActivityEligible: isActivityEligible(state),
}))(GradingTitle);
