import * as React from "react";
import type { SVGProps } from "react";

type SafeSVGProps = Omit<SVGProps<SVGSVGElement>, "dangerouslySetInnerHTML">;

const SvgFallbackIcon = (props: SafeSVGProps) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_158_7)">
      <rect width={16} height={16} fill="currentColor" fillOpacity={0.01} />
    </g>
    <defs>
      <clipPath id="clip0_158_7">
        <rect width={16} height={16} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFallbackIcon;
