import React from "react";
import { Column as ColumnType } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import classNames from "classnames";
import styles from "./ColumnsHeaders.module.scss";

interface ColumnProps {
  columns: ColumnType[];
}

export const ColumnsHeaders = ({ columns }: ColumnProps) => {
  return (
    <div className={styles.root}>
      {columns.map((column) => {
        return (
          <div
            className={classNames(styles.columnName, {
              [styles.singleColumnHeader]: columns.length === 1,
            })}
            key={`${column.Title}`}
          >
            <h2>{column.Title}</h2>
          </div>
        );
      })}
    </div>
  );
};

export default ColumnsHeaders;
