import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { getIsSearchActive } from "../../../../../state/workspace/myworkspaces/search/selectors";
import styles from "./WorkspacesList.module.scss";
import FiltersStatus from "../FiltersStatus/FiltersStatus";
import FiltersSort from "../FiltersSort/FiltersSort";
import FiltersPlRole from "../FiltersPlRole/FiltersPlRole";
import WorkspacesListItems from "../WorkspacesListItems/WorkspacesListItems";
import WorkspacesSearchItems from "../WorkspacesSearchItems/WorkspacesSearchItems";
import WorkspaceLabelsInsideCategory from "../WorkspaceLabelsInsideCategory/WorkspaceLabelsInsideCategory";
import WorkspacesLoading from "../WorkspacesLoading/WorkspacesLoading";
import {
  getFilterCategoryType,
  getMyWorkspaces,
  getTitleForWorkspacesList,
  isLoading,
} from "../../../../../state/workspace/myworkspaces/list/selectors";
import {
  MYWORKSPACES_CATEGORIES,
  MYWORKSPACES_CATEGORY_FEATURES,
  wsItemType,
} from "../../../../../state/workspace/myworkspaces/list/types";
import { notAllowedToShowWorkspaces } from "../../../../../state/shared/selectors";
import ResourceSearch from "../../../Resources/ResourceSearch/ResourceSearch";
import CategoryTitle from "../../../Library/CategoryTitle/CategoryTitle";
import CategoryFiltersToggleButton from "../../../Library/CategoryFiltersToggleButton/CategoryFiltersToggleButton";
import { areThereAnyLabels } from "../../../../../state/workspace/myworkspaces/labels/selectors";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getSearchResultTotalItems } from "../../../../../state/workspace/myworkspaces/search/selectors";
import { clearSearch } from "../../../../../state/workspace/myworkspaces/search/actions";
import { clearList } from "../../../../../state/workspace/myworkspaces/list/actions";
import { updateLeftPanel } from "../../../../../state/workspace/myworkspaces/list/actions";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../../state/app/types";
import { getFFByName, getWindowSize } from "../../../../../state/app/selectors";
import { MAIN_CONTENT_ID } from "../../../../../consts";
import { getLocationPath } from "../../../../../state/router/selectors";
import { APP_PAGES } from "../../../../../state/router/types";
import WorkspacesInfoPage from "../WorkspacesInfoPage/WorkspacesInfoPage";

const WorkspacesList = ({
  isSearchActive,
  filterCategoryType,
  title,
  notAllowedToShowWorkspaces,
  areThereAnyLabels,
  windowSize,
  updateLeftPanel,
  plFF,
  totalItems,
  clearSearch,
  clearList,
  locationPath,
  items,
  isLoading,
}) => {
  const currentCategory =
    _.find(MYWORKSPACES_CATEGORIES, { type: filterCategoryType }) || {};
  const currentCategoryFeatures = _.get(currentCategory, "features", []);

  const isStatusFilterVisible =
    _.includes(
      currentCategoryFeatures,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_STATUS
    ) && !notAllowedToShowWorkspaces;

  const isPlRoleFilterVisible =
    plFF &&
    _.includes(
      currentCategoryFeatures,
      MYWORKSPACES_CATEGORY_FEATURES.FILTER_PL_ROLE
    );

  const isSortFilterVisible = _.includes(
    currentCategoryFeatures,
    MYWORKSPACES_CATEGORY_FEATURES.FILTER_SORT
  );

  const isSearchFieldVisible = _.includes(
    currentCategoryFeatures,
    MYWORKSPACES_CATEGORY_FEATURES.SEARCH_FIELD
  );

  const isLabelsFilterVisible = _.includes(
    currentCategoryFeatures,
    MYWORKSPACES_CATEGORY_FEATURES.FILTER_LABELS
  );

  const isEditLabelsButtonVisible =
    areThereAnyLabels &&
    _.get(currentCategory, "feature_filter_labels.canEditLabels");

  const isInsideOwnedByMe = _.startsWith(
    locationPath,
    `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey}`
  );

  const isOwnedByMeEmpty = items && items.length === 0 && !isLoading;

  const displayInfoPage = isInsideOwnedByMe && isOwnedByMeEmpty;

  const [labelsInEditMode, setLabelsEditMode] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const dataTestIdPrefix = "lb-MyWorkspaces";

  useEffect(() => {
    if (filterCategoryType) setLabelsEditMode(false);
  }, [filterCategoryType]);

  if (!filterCategoryType) {
    return <WorkspacesLoading />;
  }

  return (
    <div className={styles.root}>
      {!displayInfoPage && (
        <div className={styles.headerContent}>
          <div className={styles.titleWithLabels}>
            <div className={styles.titleWithManageLabels}>
              <CategoryTitle
                dataTestId="Ws-MyWorkspaces-Title"
                areFiltersSelected={isSearchActive}
                searchResultsTotal={totalItems}
                clearFiltersAction={() => {
                  clearSearch();
                  clearList();
                }}
                title={<h1 className={styles.title}>{title}</h1>}
              />
              {isEditLabelsButtonVisible && (
                <Button
                  type={BUTTON_TYPES.OUTLINED}
                  size={BUTTON_SIZES.LARGE}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  label={labelsInEditMode ? "Exit manager" : "Manage labels"}
                  icon="cog"
                  onClick={() => setLabelsEditMode(!labelsInEditMode)}
                  data-test-id="Ws-ManageLabels-Button"
                  className={styles.greyOut}
                />
              )}
              {windowSize >= WINDOW_SIZE_TYPES.M && isSearchFieldVisible && (
                <ResourceSearch
                  className={styles.searchBlock}
                  dataTestIdPrefix={dataTestIdPrefix}
                  placeholder="Search all my Workspaces"
                />
              )}
            </div>
            <div>
              {isLabelsFilterVisible && (
                <div className={styles.placeholderFolLabels}>
                  <WorkspaceLabelsInsideCategory
                    hasAllLabels={_.get(
                      currentCategory,
                      "feature_filter_labels.hasAllLabels"
                    )}
                    inEditMode={labelsInEditMode}
                  />
                </div>
              )}
            </div>
          </div>
          {windowSize < WINDOW_SIZE_TYPES.M && (
            <>
              <div className={styles.separator} />
              <div className={styles.filters}>
                <CategoryFiltersToggleButton
                  toggleLeftPanel={updateLeftPanel}
                  isLeftPanelOpen={false}
                  dataTestIdPrefix={dataTestIdPrefix}
                  numberFiltersSelected={0}
                  buttonLabel="Display menu"
                />
                {isSearchFieldVisible && (
                  <ResourceSearch
                    dataTestIdPrefix={dataTestIdPrefix}
                    placeholder="Search all my Workspaces"
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}

      <main className={styles.content} id={MAIN_CONTENT_ID} tabIndex="-1">
        {!isSearchActive &&
          (displayInfoPage ? (
            <WorkspacesInfoPage />
          ) : (
            <React.Fragment>
              <div className={styles.filtersContainer}>
                {isPlRoleFilterVisible && windowSize < WINDOW_SIZE_TYPES.S && (
                  <div className={styles.filtersTrigger}>
                    <Button
                      type={BUTTON_TYPES.OUTLINED}
                      size={BUTTON_SIZES.REGULAR}
                      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                      label="Filters"
                      onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                      data-test-id="ws-MyWorkspaces-Filters-Trigger"
                      className={classnames(styles.filtersTriggerButton, {
                        [styles.open]: isFiltersOpen,
                      })}
                      aria-expanded={isFiltersOpen}
                    />
                  </div>
                )}
                {(!isPlRoleFilterVisible ||
                  isFiltersOpen ||
                  windowSize >= WINDOW_SIZE_TYPES.S) && (
                  <div
                    className={classnames(styles.filtersContent, {
                      [styles.filtersContent_PlFilters]: isPlRoleFilterVisible,
                    })}
                  >
                    {isStatusFilterVisible && <FiltersStatus />}
                    {isPlRoleFilterVisible && <FiltersPlRole />}
                  </div>
                )}
                <div className={styles.filtersAside}>
                  {isSortFilterVisible && <FiltersSort />}
                </div>
              </div>

              <WorkspacesListItems />
            </React.Fragment>
          ))}

        {isSearchActive && <WorkspacesSearchItems />}
      </main>
    </div>
  );
};

WorkspacesList.propTypes = {
  isSearchActive: PropTypes.bool.isRequired,
  filterCategoryType: PropTypes.string,
  title: PropTypes.node,
  notAllowedToShowWorkspaces: PropTypes.bool,
  areThereAnyLabels: PropTypes.bool.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
  updateLeftPanel: PropTypes.func.isRequired,
  plFF: PropTypes.bool.isRequired,
  totalItems: PropTypes.number.isRequired,
  clearSearch: PropTypes.func.isRequired,
  clearList: PropTypes.func.isRequired,
  locationPath: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(wsItemType),
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isSearchActive: getIsSearchActive(state),
    filterCategoryType: getFilterCategoryType(state),
    notAllowedToShowWorkspaces: notAllowedToShowWorkspaces(state),
    title: getTitleForWorkspacesList(state),
    areThereAnyLabels: areThereAnyLabels(state),
    windowSize: getWindowSize(state),
    plFF: getFFByName("HAP-7034-professional-learning")(state),
    totalItems: getSearchResultTotalItems(state),
    locationPath: getLocationPath(state),
    items: getMyWorkspaces(state),
    isLoading: isLoading(state),
  }),
  (dispatch) => ({
    updateLeftPanel: (isOpen) => dispatch(updateLeftPanel(isOpen)),
    clearSearch: () => dispatch(clearSearch()),
    clearList: () => dispatch(clearList()),
  })
)(WorkspacesList);
