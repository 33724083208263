import React, { useEffect, useState, useCallback, useRef } from "react";
import classNames from "classnames";
import _ from "lodash";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../../hooks/useIsDimension";
import { Group as GroupType } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import { colourRange } from "@hapara/assets/src/colours/colourRange";
import { colourLookup } from "../../../../../utils";
import styles from "./GroupFilterBar.module.scss";

interface FilterPropTypes {
  groups?: GroupType[];
  currentSelection: string; //The currently applied Filter's group ID
  dataTestIdPrefix: string;
  a11y: number; //Button bar button count for a11y feedback
  setDropDownSelect: (id: string, name: string) => void;
}

const FilterDropDown = ({
  groups,
  currentSelection,
  dataTestIdPrefix,
  a11y,
  setDropDownSelect,
}: FilterPropTypes) => {
  //Get filter & utility colour data for quick referencing
  const ALL_FILTER_NAME = "All";
  const ALL_STRING_ID = "";
  const ALL_COLOUR_ID = 200;
  const NONE_COLOUR_ID = 201;

  const utilityColours = {
    allFilters: colourLookup(colourRange, true, ALL_COLOUR_ID),
    noSelection: colourLookup(colourRange, true, NONE_COLOUR_ID),
  };

  const [dropOpen, setDropOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(ALL_FILTER_NAME);
  const [selectedColour, setSelectedColour] = useState(
    utilityColours.allFilters.colourHex
  ); //Sets mobile button display colour to match selection colour
  const [selectedTextColour, setSelectedTextColour] = useState(
    utilityColours.allFilters.textHex
  ); //Sets mobile button text colour to match selection colour
  const [containsCurrentSelection, setContainsCurrentSelection] =
    useState(false); //Sets persistant selection state on filters
  const { isDimensionOnly: isMediumScreen } = useIsDimension(
    WINDOW_SIZE_TYPES.L
  );
  const rootRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  //Break groups into sets
  const dropdownFilters = _.slice(groups, 3, groups!.length); //Filters that exceed desktop button count
  const displayFilters = isMediumScreen ? groups : dropdownFilters; //Toggles which filter set to use for drop menu

  //Determine if currently active filter is in the drop menu and sets the button UI to match
  useEffect(() => {
    const dropMenuIDs = dropdownFilters?.map((group) => group.ID);
    setContainsCurrentSelection(_.includes(dropMenuIDs, currentSelection));
  }, [dropdownFilters, currentSelection]);

  const handleMenu = () => setDropOpen(!dropOpen);

  const handleSelection = (id: string, name: string, colour: number) => {
    setSelectedName(name);
    setSelectedColour(colourLookup(colourRange, true, colour).colourHex);
    setSelectedTextColour(colourLookup(colourRange, true, colour).textHex);
    setDropDownSelect(id, name);
  };

  //Close menu on outside click
  const handleOutsideClick = useCallback(
    (e) => {
      if (dropOpen) {
        if (rootRef.current && rootRef.current.contains(e.target)) {
          return;
        }
        setDropOpen(false);
      }
    },
    [dropOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  //Close menu on esc and return focus to trigger
  const handleEscKey = useCallback(
    (e) => {
      if (dropOpen && e.keyCode === 27) {
        setDropOpen(false);
        if (isMediumScreen) {
          const rootNodes = _.get(rootRef, "current.childNodes");
          // TODO: https://hapara.atlassian.net/browse/HAP-10884
          !_.isEmpty(rootNodes) && (rootNodes?.[0] as any).focus();
        } else {
          !_.isEmpty(triggerRef) && triggerRef.current!.focus();
        }
      }
    },
    [isMediumScreen, dropOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handleEscKey]);

  return (
    <div ref={rootRef}>
      {groups!.length > 3 && !isMediumScreen && (
        <div
          className={classNames(
            styles.filterBarButtonGroup,
            styles.dropTrigger
          )}
        >
          <div
            className={classNames(styles.indicatorBar, {
              [styles.selected]: containsCurrentSelection,
            })}
            style={{ background: utilityColours.noSelection.colourHex }}
          ></div>
          <button
            ref={triggerRef}
            className={styles.filterButton}
            style={{
              background: utilityColours.noSelection.colourHex,
              color: utilityColours.noSelection.textHex,
            }}
            onClick={handleMenu}
            data-test-id={`${dataTestIdPrefix}-GroupFilter-OpenDropMenu_desktop`}
            aria-expanded={dropOpen}
            aria-label={`${dropdownFilters.length} more groups. Menu pop-up ${
              dropOpen ? "open" : "collapsed"
            }. Button ${a11y} of ${a11y}`}
          >
            {`+${dropdownFilters!.length}`}
          </button>
        </div>
      )}
      {isMediumScreen && (
        <button
          className={classNames(styles.filterButton, styles.dropAllButton)}
          style={{ background: selectedColour, color: selectedTextColour }}
          onClick={handleMenu}
          data-test-id={`${dataTestIdPrefix}-GroupFilter-OpenDropMenu_mobile`}
          aria-expanded={dropOpen}
          aria-label={`Menu pop-up ${dropOpen ? "open" : "collapsed"}`}
        >
          <div className={styles.textWrapper}>{selectedName}</div>
          <HapReactIcon
            svg="arrow-carvet-down"
            height={16}
            width={16}
            alt="Open Dropdown Menu"
            className={classNames({
              [styles.rotateClose]: dropOpen,
              [styles.rotateOpen]: !dropOpen,
            })}
          />
        </button>
      )}
      {dropOpen && (
        <div ref={panelRef} className={classNames(styles.dropMenu)}>
          <ul>
            {isMediumScreen && (
              <li>
                <button
                  className={classNames({
                    [styles.isSelected]: _.isEmpty(currentSelection),
                  })}
                  onClick={() =>
                    handleSelection(
                      ALL_STRING_ID,
                      ALL_FILTER_NAME,
                      ALL_COLOUR_ID
                    )
                  }
                  data-test-id={`${dataTestIdPrefix}-GroupFilter-DropMenuAllButton`}
                  aria-label={`${"All groups button"} ${
                    _.isEmpty(currentSelection) ? "Selected" : ""
                  }`}
                >
                  <div
                    className={styles.dropItemColour}
                    style={{ background: utilityColours.allFilters.colourHex }}
                  />
                  <div className={styles.dropItemText}>{ALL_FILTER_NAME}</div>
                </button>
              </li>
            )}
            {displayFilters!.map((filter) => {
              return (
                <li key={filter.ID}>
                  <button
                    className={classNames({
                      [styles.isSelected]: filter.ID === currentSelection,
                    })}
                    onClick={() =>
                      handleSelection(
                        filter.ID!,
                        filter.Title!,
                        filter.ColorIndex!
                      )
                    }
                    data-test-id={`${dataTestIdPrefix}-GroupFilter-DropMenuButton`}
                    aria-label={`${filter.Title!} ${
                      filter.ID === currentSelection ? "Selected" : ""
                    }`}
                  >
                    <div
                      className={styles.dropItemColour}
                      style={{
                        background: colourLookup(
                          colourRange,
                          true,
                          filter.ColorIndex!
                        ).colourHex,
                      }}
                    ></div>
                    <div className={styles.dropItemText}>{filter.Title!}</div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterDropDown;
