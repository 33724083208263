import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { filterItemType } from "../../../../state/shared/types";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import styles from "./FiltersListCollapsable.module.scss";
import Reveal, {
  RevealToggle,
  RevealDetails,
} from "@hapara/ui/src/atomic/Reveal/Reveal";

const OptionsList = ({
  items,
  multiSelect,
  onItemsSelect,
  selected,
  type,
  grouped,
  groupItemSelectable,
  groupName,
}) => {
  const allSelected = useMemo(
    () =>
      _.difference(
        items.map((i) => i.Key),
        selected
      ).length === 0,
    [items, selected]
  );

  const anySelected = useMemo(
    () =>
      _.intersection(
        items.map((i) => i.Key),
        selected
      ).length > 0,
    [items, selected]
  );

  if (grouped) {
    return (
      <div className={styles.groupedOption}>
        <fieldset
          aria-labelledby={`lb-discover-tags-item-${groupName}`}
          className={styles.fieldsetSubgroup}
          role="list"
        >
          <div className={styles.groupName}>
            {groupItemSelectable && (
              <Checkbox
                checked={allSelected}
                semi={!allSelected && anySelected}
                id={`lb-discover-tags-item-${groupName}`}
                onChange={(isChecked) => onItemsSelect(items, isChecked)}
                label={groupName}
                dataTestIdPrefix={`lb-Discover-Filter${type.title}-${groupName}`}
              />
            )}
            {!groupItemSelectable && (
              <div className={styles.groupNameTitle}>{groupName}</div>
            )}
          </div>
          {_.map(items, (item, index) => {
            return (
              <div
                className={classnames(styles.option, {
                  [styles.noMargin]: !groupItemSelectable,
                })}
                key={index}
              >
                <Checkbox
                  checked={_.includes(selected, item.Key)}
                  id={`lb-discover-tags-item-${item.Key}`}
                  onChange={(isChecked) => onItemsSelect([item], isChecked)}
                  label={item.Value}
                  dataTestIdPrefix={`lb-Discover-Filter${type.title}-${item.Key}`}
                />
              </div>
            );
          })}
        </fieldset>
      </div>
    );
  } else {
    return _.map(items, (item, index) => {
      return (
        <div className={styles.option} key={index}>
          {multiSelect && (
            <Checkbox
              checked={_.includes(selected, item.Key)}
              id={`lb-discover-tags-item-${item.Key}`}
              onChange={(isChecked) => onItemsSelect([item], isChecked)}
              label={item.Value}
              dataTestIdPrefix={`lb-Discover-Filter${type.title}-${item.Key}`}
            />
          )}

          {!multiSelect && (
            <Radio
              name={type.value}
              value={item.Key}
              checked={_.includes(selected, item.Key)}
              onChange={() => onItemsSelect(item)}
              label={item.Value}
              dataTestIdPrefix={`lb-Discover-Filter${type.title}-${item.Key}`}
            />
          )}
        </div>
      );
    });
  }
};

export const FiltersListCollapsable = ({
  items,
  type,
  onItemsSelect,
  selected,
  multiSelect = true,
  grouped = false,
  groupItemSelectable = true,
}) => {
  const radioDefaultValue = "None selected";
  const [isExpanded, setIsExpanded] = useState(selected.length > 0);
  const titleElementId = `Title-Id-${type.value}`;
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <Reveal expanded={isExpanded} onChange={toggleExpanded}>
      <div
        className={classnames(styles.root, {
          [styles.noBorder]: isExpanded,
        })}
        data-test-id={`lb-Discover-Tags-List-${type.title}`}
      >
        <RevealToggle
          data-test-id={`lb-Discover-Tags-List-${type.title}-Expand-Button`}
          className={classnames(styles.expandButton, {
            [styles.expanded]: isExpanded,
          })}
        >
          <h3 className={styles.title} id={titleElementId}>
            {type.title}
          </h3>
          <HapReactIcon
            svg={isExpanded ? "arrow-angle-up" : "arrow-angle-down"}
            width={18}
            height={18}
            alt=""
            className={styles.expandIcon}
          />
        </RevealToggle>

        <RevealDetails>
          <fieldset
            aria-labelledby={titleElementId}
            className={styles.fieldset}
            role="list"
          >
            {!multiSelect && (
              <div className={styles.option}>
                <Radio
                  name={type.value}
                  value="none"
                  checked={_.isEmpty(selected)}
                  onChange={() => onItemsSelect(null)}
                  label={radioDefaultValue}
                  data-test-id="lb-Discover-FilterStandards-NONE"
                />
              </div>
            )}
            {!grouped && (
              <OptionsList
                items={items}
                multiSelect={multiSelect}
                onItemsSelect={onItemsSelect}
                selected={selected}
                type={type}
              />
            )}

            {multiSelect && grouped && (
              <>
                {_.map(_.groupBy(items, "Group"), (groupItems, groupName) => {
                  return (
                    <OptionsList
                      items={groupItems}
                      multiSelect={multiSelect}
                      onItemsSelect={onItemsSelect}
                      selected={selected}
                      type={type}
                      grouped={true}
                      groupItemSelectable={groupItemSelectable}
                      groupName={groupName}
                      key={groupName}
                    />
                  );
                })}
              </>
            )}
          </fieldset>
        </RevealDetails>
      </div>
    </Reveal>
  );
};

FiltersListCollapsable.propTypes = {
  items: PropTypes.arrayOf(filterItemType),
  type: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onItemsSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  multiSelect: PropTypes.bool,
  grouped: PropTypes.bool,
  groupItemSelectable: PropTypes.bool,
};

export default FiltersListCollapsable;
