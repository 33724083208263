import React from "react";
import { isComponentTypeOf } from "./isComponentOf";

export const countComponentsInChildren = (
  children: React.ReactNode,
  componentName: string
): number => {
  if (!children) return 0;

  return React.Children.toArray(children).reduce<number>((count, child) => {
    if (isComponentTypeOf(child as React.ReactElement, componentName)) {
      return count + 1;
    }
    if (React.isValidElement(child) && child.props && child.props.children) {
      return (
        count + countComponentsInChildren(child.props.children, componentName)
      );
    }
    return count;
  }, 0);
};
