import classnames from "classnames";
import styles from "./Card.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

interface CardDetailBlockProps {
  groups: string;
  attachments: number;
  videos: number;
  isStaticCard: boolean;
  dataTestIdPrefix: string;
}

export const CardDetailBlock = ({
  groups,
  attachments,
  videos,
  isStaticCard,
  dataTestIdPrefix,
}: CardDetailBlockProps) => {
  return (
    <div
      className={classnames(styles.detailWrapper, {
        [styles.isStaticCard]: isStaticCard,
      })}
    >
      <div
        className={styles.detailBlock}
        data-test-id="WS-Board-Card-GroupAssigned"
      >
        <HapReactIcon
          svg="profile-group-filled"
          width={16}
          alt="Card assigned to:"
        />
        {groups}
      </div>
      <div className={styles.styledBlockWrapper}>
        {videos > 0 && (
          <div
            className={classnames(styles.styledBlock)}
            data-test-id={`${dataTestIdPrefix}-CardFooter-Video-Count`}
          >
            <div className={styles.styledBlockIcon}>
              <HapReactIcon svg="video-play" width={16} alt="Videos" />
            </div>
            <div className={styles.styledBlockText}> {videos}</div>
          </div>
        )}
        {attachments - videos > 0 && (
          <div
            className={classnames(styles.styledBlock)}
            data-test-id={`${dataTestIdPrefix}-CardFooter-Artefact-Count`}
          >
            <div className={styles.styledBlockIcon}>
              <HapReactIcon svg="attachment" width={16} alt="Videos" />
            </div>
            <div className={styles.styledBlockText}>
              {" "}
              {attachments - videos}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDetailBlock;
