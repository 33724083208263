import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./ActionDelete.module.scss";
import { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import { showResourceDeleteModal } from "../../../../state/resources/actions";
import { dropdownItemPropsType } from "../../../../state/resources/types";

const ActionDelete = ({
  id,
  dataTestPrefix,
  isDropdownAction = false,
  dropdownItemProps,
  buttonItemProps,
  showResourceDeleteModal,
}) => {
  const actionLabel = "Delete resource";
  const actionDataTestId = `${dataTestPrefix}-DeleteUploadedResource-Button`;

  const handleActionOnClick = () => {
    showResourceDeleteModal({
      resourceId: id,
    });
  };

  return (
    <>
      {!isDropdownAction && (
        <MemorisedFocusButton
          label={actionLabel}
          icon="delete"
          type={_.get(buttonItemProps, "type", BUTTON_TYPES.SECONDARY)}
          data-test-id={actionDataTestId}
          onClick={handleActionOnClick}
          isFullWidth={_.get(buttonItemProps, "isFullWidth", true)}
        />
      )}

      {isDropdownAction && (
        <button
          type="button"
          data-test-id={actionDataTestId}
          {...dropdownItemProps}
          onClick={(e) => {
            e.stopPropagation();
            handleActionOnClick();
            dropdownItemProps.onClick();
          }}
          className={classnames(
            dropdownItemProps.className,
            styles.removeDropdownAction
          )}
        >
          {actionLabel}
        </button>
      )}
    </>
  );
};

ActionDelete.propTypes = {
  id: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  isDropdownAction: PropTypes.bool,
  dropdownItemProps: dropdownItemPropsType,
  buttonItemProps: PropTypes.shape({
    isFullWidth: PropTypes.bool,
    type: PropTypes.oneOf(_.values(BUTTON_TYPES)),
  }),
  // connected
  showResourceDeleteModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showResourceDeleteModal: (options) =>
      dispatch(showResourceDeleteModal(options)),
  })
)(ActionDelete);
