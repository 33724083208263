export const timeInputToISOString = (timeString: string): string => {
  if (typeof timeString !== "string") {
    throw new Error("Input must be a string");
  }

  const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  if (!timeRegex.test(timeString)) {
    throw new Error("Invalid time format");
  }

  const [hours, minutes] = timeString.split(":").map(Number);

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    throw new Error("Invalid time values");
  }

  const date = new Date(Date.UTC(1970, 0, 1, hours, minutes));

  return date.toISOString().slice(0, 19);
};
