import React from "react";
import { ChatAvatar } from "../ChatAvatar";
import { ChatBadge } from "../ChatBadge";
import styles from "./ChatChannelItem.module.scss";
import { ChatContactPresence, ChatRoleType } from "../../types/state";
import { FormattedMessage } from "react-intl";
import { useDisplayName, DisplayNameFormat } from "../../../DisplayName";

export type ChatChannelItemProps = {
  role: ChatRoleType;
  firstName: string;
  lastName: string;
  unreadCount?: number;
  presence?: ChatContactPresence;
  isDisabled?: boolean;
  /** @deprecated TODO: switch to `isSchoolHours` */
  isOutsideSchoolHours?: boolean;
  displayNameFormat?: DisplayNameFormat;
};

export const ChatChannelItem = ({
  role,
  firstName,
  lastName,
  unreadCount,
  presence = "offline",
  isDisabled,
  isOutsideSchoolHours,
  displayNameFormat,
}: ChatChannelItemProps) => {
  const displayName = useDisplayName({
    firstName,
    lastName,
    order: displayNameFormat,
  });
  const isUnavailableForStudent = role === "student" ? isDisabled : false;
  return (
    <div className={styles.container}>
      <ChatAvatar
        firstName={firstName}
        lastName={lastName}
        presence={
          isUnavailableForStudent || isOutsideSchoolHours ? "offline" : presence
        }
        isDisabled={role === "teacher" ? false : isDisabled}
      />
      <div className={styles.textContainer}>
        <div className={styles.name} title={displayName}>
          {displayName}
        </div>
        <div className={styles.status}>
          {isOutsideSchoolHours ? (
            <FormattedMessage defaultMessage="Not available" id="cPhKTw" />
          ) : role === "teacher" ? (
            isDisabled ? (
              <FormattedMessage defaultMessage="Chat is off" id="yAb1YH" />
            ) : presence === "online" ? (
              <FormattedMessage defaultMessage="Online" id="ccU6l/" />
            ) : (
              <FormattedMessage defaultMessage="Offline" id="7UOvbT" />
            )
          ) : isDisabled || presence === "offline" ? (
            <FormattedMessage defaultMessage="Not available" id="cPhKTw" />
          ) : (
            <FormattedMessage defaultMessage="Available" id="PgJGHT" />
          )}
        </div>
      </div>
      <div className={styles.info}>
        <ChatBadge unreadCount={unreadCount} hideOutline />
      </div>
    </div>
  );
};
