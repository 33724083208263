import { FormattedMessage } from "react-intl";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import { submitForAssignee } from "../../../../../state/workspace/grading/actions";
import {
  getInProgress,
  getIsError,
  getAssigneeItemTitle,
} from "../../../../../state/workspace/grading/selectors";
import styles from "./GradingWidgetStarted.module.scss";
import GradingSubmitMultiple from "../GradingSubmitMultiple/GradingSubmitMultiple";

export const GradingWidgetStarted = ({
  isActionInProgress = false,
  isActionError = false,
  assigneeItemTitle,
  submitForAssignee = () => {},
}) => {
  return (
    <div className={styles.root} data-test-id="Ws-GradingWidget-Strated">
      <div className={styles.body}>
        <p>
          <FormattedMessage
            defaultMessage="Assess work here after it is submitted."
            id="9hKpvI"
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="View previous grades and feedback in the activity history."
            id="z4kBDk"
          />
        </p>
        <Alert
          type={ALERT_TYPES.FAILURE}
          isVisible={isActionError}
          className={styles.submitError}
        >
          There was a problem submitting work for {assigneeItemTitle}. Please
          try again.
        </Alert>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.REGULAR}
          onAction={() => submitForAssignee()}
          label={
            isActionInProgress
              ? "Submitting..."
              : `Submit for ${assigneeItemTitle}`
          }
          dataTestId="ws-Grading-SubmitDocumentForUser"
          className={styles.submitButton}
          isDisabled={isActionInProgress}
        />
      </div>
      <div className={styles.bulkSubmit}>
        <GradingSubmitMultiple />
      </div>
    </div>
  );
};

GradingWidgetStarted.propTypes = {
  isActionInProgress: PropTypes.bool.isRequired,
  isActionError: PropTypes.bool,
  assigneeItemTitle: PropTypes.string.isRequired,
  submitForAssignee: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isActionInProgress: getInProgress(state),
    isActionError: getIsError(state),
    assigneeItemTitle: getAssigneeItemTitle()(state),
  }),
  (dispatch) => ({
    submitForAssignee: () => dispatch(submitForAssignee()),
  })
)(GradingWidgetStarted);
