import PropTypes from "prop-types";
import _ from "lodash";

export const MYWORKSPACES_GRADING_ACTIVITY_TYPES = {
  NOT_STARTED: "not-started",
  STARTED: "started",
  GROUP_STARTED: "started",
  SUBMITTED: "submitted",
  GROUP_SUBMITTED: "submitted",
  REVISED: "revised",
  GROUP_REVISED: "revised",
  ASSESSED: "assessed",
  GROUP_ASSESSED: "assessed",
};

export const MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES = {
  GRADE: "grade",
  FEEDBACK: "feedback",
};

export const ARTIFACT_TYPE = {
  GDRIVE: "GDrive",
  URL: "URL",
};

// object for using default states for some url params
export const MYWORKSPACES_GRADING_DEFAULT_KEYS = {
  grading_card_id: "grading_card_id",
  grading_activity: "grading_activity",
};

export const MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES = {
  STARTED: {
    header: "All work has been submitted!",
    body: "Submitted assignments are ready for you to assess.",
  },
  SUBMITTED: {
    header: `You've reviewed all student work!`,
    body: "All work has been returned to your students.",
  },
  REVISED: {
    header: `You've reviewed all student work!`,
    body: "All work has been returned to your students.",
  },
  ASSESSED: {
    header: `You've reviewed all student work!`,
    body: "All work has been returned to students for resubmission",
  },
  NOT_VALID: {
    header: "Nothing to see here!",
    body: `We can't find this assignment, please go back to Workspace and try again.`,
  },
};

export const MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE = PropTypes.shape({
  Id: PropTypes.string,
  Type: PropTypes.string,
  Grade: PropTypes.string,
  Feedback: PropTypes.string,
  ActivityDate: PropTypes.string,
});

export const MYWORKSPACES_GRADING_ARTIFACT_TYPE = PropTypes.shape({
  Id: PropTypes.string,
  URI: PropTypes.string,
  Type: PropTypes.oneOf(_.values(ARTIFACT_TYPE)),
  Grade: PropTypes.string,
  Feedback: PropTypes.string,
});

const MYWORKSPACES_GRADING_STUDENT_TYPE = PropTypes.shape({
  Id: PropTypes.string,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  ActivityDate: PropTypes.string,
  Artifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  ActivityHistory: PropTypes.arrayOf(
    MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE
  ),
});

const MYWORKSPACES_GRADING_GROUP_TYPE = PropTypes.shape({
  Id: PropTypes.string,
  Title: PropTypes.string,
  ActivityDate: PropTypes.string,
  Artifact: MYWORKSPACES_GRADING_ARTIFACT_TYPE,
  ActivityHistory: PropTypes.arrayOf(
    MYWORKSPACES_GRADING_ACTIVITY_HISTORY_ITEM_TYPE
  ),
});

export const MYWORKSPACES_GRADING_ASSIGNEE_TYPE = PropTypes.oneOfType([
  MYWORKSPACES_GRADING_STUDENT_TYPE,
  MYWORKSPACES_GRADING_GROUP_TYPE,
]);

const MYWORKSPACES_GRADING_STUDENTS_LIST_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    Id: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
  })
);

const MYWORKSPACES_GRADING_GROUPS_LIST_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    Id: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  })
);

export const MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE = PropTypes.oneOfType([
  MYWORKSPACES_GRADING_STUDENTS_LIST_TYPE,
  MYWORKSPACES_GRADING_GROUPS_LIST_TYPE,
]);

export const MYWORKSPACES_GRADING_CARD_TYPE = PropTypes.shape({
  Assignees: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE,
  Id: PropTypes.string,
  Title: PropTypes.string,
  BoardTitle: PropTypes.string,
  BoardThumbnail: PropTypes.string,
  BoardThumbnailAlt: PropTypes.string,
  Students: MYWORKSPACES_GRADING_STUDENTS_LIST_TYPE,
  Groups: MYWORKSPACES_GRADING_GROUPS_LIST_TYPE,
});

export const MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES = {
  ASSIGNED: { type: "AssignedCard", action: "Assigned" },
  STARTED: { type: "StartEvidence", action: "Started" },
  SUBMITTED: { type: "SubmitEvidence", action: "Submitted" },
  RETURNED_FOR_EDIT: {
    type: "ReturnUnassessedEvidence",
    action: "Returned for edit",
  },
  RETURNED_FINAL: { type: "FinishAssessment", action: "Returned final" },
};

export const MYWORKSPACES_GRADING_HISTORY_PARAM_TYPE = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string,
});

export const MYWORKSPACES_GRADING_DOCUMENT_TYPE = {
  EMBEDED: "embeded",
  EXTERNAL: "external",
};

export const GOOGLE_DOCS_HOST = "https://docs.google.com";
export const GOOGLE_DRIVE_HOST = "https://drive.google.com";
export const GOOGLE_DOCS_ICON_HOST =
  "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.";

export const GRADING_EMBEDDABLE_DOCUMENT_TYPES = {
  DOC: {
    mimeTypes: [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.google-apps.document",
    ],
    type: "document",
    iconAppendix: "document",
  },
  SHEET: {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.google-apps.spreadsheet",
      "application/vnd.ms-excel",
    ],
    type: "spreadsheets",
    iconAppendix: "spreadsheet",
  },
  PRESO: {
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.google-apps.presentation",
      "application/vnd.ms-powerpoint",
    ],
    type: "presentation",
    iconAppendix: "presentation",
  },
  DRAWING: {
    mimeTypes: [
      "application/vnd.google-apps.drawing",
      "application/vnd.google-apps.drawing",
    ],
    type: "drawings",
    iconAppendix: "drawing",
  },
  IMAGE: {
    mimeTypes: ["image/jpg", "image/jpeg", "image/png", "image/gif"],
    type: "file",
    iconAppendix: "drawing",
  },
};

export const RETURN_ARTIFACT_TYPES = {
  FINAL: "FinishAssessment",
  ASSESSED: "ReturnAssessedAction",
  UNASSESSED: "ReturnUnassessedAction",
};
