import { useEffect, RefObject } from "react";

type TargetKeyType = "Escape" | "Enter" | "ArrowUp" | "ArrowDown";

const isKeyboardEvent = (event: unknown): event is KeyboardEvent => {
  return event instanceof KeyboardEvent;
};

export const useKeyPress = (
  targetKey: TargetKeyType,
  onKeyPress: (event: KeyboardEvent) => void,
  ref?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleKeyDown: EventListenerOrEventListenerObject = (event) => {
      if (isKeyboardEvent(event) && event.key === targetKey) {
        onKeyPress(event);
      }
    };

    const element = ref?.current || window;
    element.addEventListener("keydown", handleKeyDown);
    return () => {
      element.removeEventListener("keydown", handleKeyDown);
    };
  }, [targetKey, onKeyPress, ref]);
};
