import React from "react";
import { createIcon } from "./utils/createIcon";

export const ScalingIcon = createIcon(
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M1.6 1.6A2 2 0 0 1 3 1h5a.5.5 0 0 1 0 1H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8a.5.5 0 0 1 1 0v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3c0-.5.2-1 .6-1.4Zm8.8 0c0-.3.2-.6.5-.6h3.6c.2 0 .5.3.5.5v3.6a.5.5 0 0 1-1 0V2.7l-7 7h2.4a.5.5 0 1 1 0 1H5.8a.5.5 0 0 1-.5-.5V6.6a.5.5 0 0 1 1 0v2.3l7-6.9h-2.4a.5.5 0 0 1-.5-.5Z"
  />
);
