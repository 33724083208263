import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Person } from "../../components/partial/ClassInfo/types";

//ACTIONS
import {
  updateStudentPasswords,
  resetUpdateStudentPasswordsState,
} from "../../state/classInfo/actions";

//SELECTORS
import {
  getIsUpdatePasswordsLoading,
  getIsUpdatePasswordsError,
  getIsUpdatePasswordsLoaded,
} from "../../state/classInfo/selectors";

type ExportedValues = {
  isUpdatePasswordsError: boolean;
  isUpdatePasswordsLoaded: boolean;
  isUpdatePasswordsLoading: boolean;
  handleUpdatePasswords: (forceReset: boolean, newPassword: string) => void;
};

type UseClassInfoUpdatePasswordsProps = {
  selectedStudents: Person[];
  closeModal: () => void;
  isModalOpen: boolean;
};
export const useClassInfoUpdatePasswords = ({
  selectedStudents,
  closeModal,
  isModalOpen,
}: UseClassInfoUpdatePasswordsProps): ExportedValues => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const isUpdatePasswordsLoading = useSelector(getIsUpdatePasswordsLoading);
  const isUpdatePasswordsError = useSelector(getIsUpdatePasswordsError);
  const isUpdatePasswordsLoaded = useSelector(getIsUpdatePasswordsLoaded);

  const handleUpdatePasswords = (forceReset: boolean, newPassword: string) => {
    const studentIds = selectedStudents.map((p) => p.id);
    return dispatch(
      updateStudentPasswords(classId, studentIds, forceReset, newPassword)
    );
  };

  useEffect(() => {
    if (
      !isUpdatePasswordsLoading &&
      isUpdatePasswordsLoaded &&
      !isUpdatePasswordsError
    ) {
      closeModal();
    }
  }, [
    isUpdatePasswordsLoading,
    isUpdatePasswordsLoaded,
    isUpdatePasswordsError,
  ]);

  useEffect(() => {
    dispatch(resetUpdateStudentPasswordsState());
  }, [isModalOpen]);

  return {
    isUpdatePasswordsError,
    isUpdatePasswordsLoaded,
    isUpdatePasswordsLoading,
    handleUpdatePasswords,
  };
};
