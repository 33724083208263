import { ChatState } from "../../types/state";

export const calculateTotalUnread = (channels: ChatState["channels"]) => {
  return Object.values(channels).reduce((total, channel) => {
    if (channel.classStatus) {
      return (
        total +
        Object.values(channel.classStatus).reduce((channelTotal, status) => {
          return channelTotal + (status?.unreadCount || 0);
        }, 0)
      );
    }
    return total;
  }, 0);
};
