import actionTypes from "../../actionTypes";
import { studentRequest, actionedRequest } from "./types";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
} from "../../storeUtils";

interface studentRequestsState {
  requests: studentRequest[];
  actionedRequests: actionedRequest[];
  isLoading: boolean;
  isError: boolean;
  isLoaded: boolean;
}

const INIT_STATE: studentRequestsState = {
  ...DATA_LOADING_STATE_INIT,
  requests: [],
  actionedRequests: [],
};

const studentRequestsReducer = (
  state = INIT_STATE,
  action: { type: string; payload: studentRequest[] | string }
) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        requests: payload,
        ...DATA_LOADING_STATE_SUCCESS,
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_ERROR: {
      return {
        ...state,
        ...DATA_LOADING_STATE_ERROR,
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_UPDATE: {
      return {
        ...state,
        requests: action.payload,
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_PENDING: {
      return {
        ...state,
        actionedRequests: [
          ...state.actionedRequests,
          { id: action.payload, approved: true, ...DATA_LOADING_STATE_PENDING },
        ],
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_SUCCESS: {
      return {
        ...state,
        actionedRequests: state?.actionedRequests.map((request) =>
          request.id === action.payload
            ? { ...request, ...DATA_LOADING_STATE_SUCCESS, approved: true }
            : request
        ),
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_APPROVE_ERROR: {
      return {
        ...state,
        actionedRequests: state.actionedRequests.map((request) =>
          request.id === action.payload
            ? { ...request, ...DATA_LOADING_STATE_ERROR }
            : request
        ),
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_PENDING: {
      return {
        ...state,
        actionedRequests: [
          ...state.actionedRequests,
          { id: action.payload, ...DATA_LOADING_STATE_PENDING },
        ],
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_SUCCESS: {
      return {
        ...state,
        actionedRequests: state?.actionedRequests.map((request) =>
          request.id === action.payload
            ? { ...request, ...DATA_LOADING_STATE_SUCCESS, approved: false }
            : request
        ),
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_REJECT_ERROR: {
      return {
        ...state,
        actionedRequests: state.actionedRequests.map((request) =>
          request.id === action.payload
            ? { ...request, ...DATA_LOADING_STATE_ERROR }
            : request
        ),
      };
    }
    case actionTypes.HIGHLIGHTS_STUDENT_REQUESTS_DISMISS_SUCCESS_NOTIFICATION: {
      return {
        ...state,
        actionedRequests: state.actionedRequests.filter(
          (request) => request.id !== action.payload
        ),
        requests: state.requests.filter(
          (request) => request.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};

export default studentRequestsReducer;
