import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { useIntl } from "react-intl";
import { TextField } from "@hapara/ui/src/atomic/TextField/TextField";

interface GuidedBrowsingTemplateNameProps {
  value: string;
  onChangeText: (value: string) => void;
  isDuplicateTemplateName?: boolean;
}

export const GuidedBrowsingTemplateNamePresentation = ({
  onChangeText,
  value,
  isDuplicateTemplateName,
}: GuidedBrowsingTemplateNameProps) => {
  const intl = useIntl();

  return (
    <GuidedBrowsingSection>
      <TextField
        hasError={isDuplicateTemplateName}
        label={intl.formatMessage({
          defaultMessage: "Template name",
          id: "Yj2l3k",
        })}
        value={value}
        isRequired
        placeholder={intl.formatMessage({
          defaultMessage: "Enter template name",
          id: "dCFxw3",
        })}
        onChangeText={onChangeText}
        maxLength={60}
        errorMessage={intl.formatMessage({
          defaultMessage: "Error: This name already exists.",
          id: "cKX91e",
        })}
        showLimitWarning
      />
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingTemplateName = ({
  value,
  onChangeText,
  isDuplicateTemplateName,
}: GuidedBrowsingTemplateNameProps) => {
  return (
    <GuidedBrowsingTemplateNamePresentation
      value={value}
      isDuplicateTemplateName={isDuplicateTemplateName}
      onChangeText={onChangeText}
    />
  );
};
