import { history } from "../../store";
import { WORKSPACE_SORTING_TYPES } from "../consts";
import { APP_PAGES } from "../../router/types";

export const PL_WORKSPACES_URL_KEYS = {
  page: {
    key: "page",
  },
  search_page: {
    key: "page",
  },
  sort: {
    key: "sort",
  },
  query: {
    key: "query",
  },
};

export const PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES = {
  page: 1,
  search_page: 1,
  sort: WORKSPACE_SORTING_TYPES.LAST,
  query: "",
};

export const navigateToPLPage = () => {
  history.push({
    pathname: `${APP_PAGES.PROFESSIONAL_LEARNING.path}`,
    search: "",
  });
};

export const navigateToPLSearchResults = ({ query, page }) => {
  const defaultValue = PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES.search_page;
  const encodedQuery = window.encodeURIComponent(query);
  let search = `?${PL_WORKSPACES_URL_KEYS.query.key}=${encodedQuery}&${PL_WORKSPACES_URL_KEYS.page.key}=${page}`;
  if (page === defaultValue) {
    search = `?${PL_WORKSPACES_URL_KEYS.query.key}=${encodedQuery}`;
  }
  history.push({
    pathname: `${APP_PAGES.PROFESSIONAL_LEARNING.path}`,
    search,
  });
};
