import _ from "lodash";
import { createSelector } from "reselect";
import { SHARED_URL_KEYS } from "../../shared/navigation-keys";
import { SHARED_URL_PARAMS_DEFAULT_VALUES } from "../../shared/navigation-keys";
import { LIBRARY_CATEGORIES } from "./types";
import { getLocationPath } from "../../router/selectors";
import {
  getSelectedFormatFilter,
  getSelectedResourceId,
} from "../../shared/selectors";
import { getSelectedCollectionId } from "../collections/selectors";
import { APP_PAGES } from "../../router/types";
import { DEFAULT_PAGE_SIZE } from "../../shared/types";

const getLibrary = (state) => _.get(state, "library.library");

export const getLibraryItemsList = createSelector(getLibrary, (d) =>
  _.get(d, "items")
);

export const getLibrarySelectedItem = createSelector(
  [getLibraryItemsList, getSelectedResourceId],
  (items, selectedId) => _.find(items, { ID: selectedId })
);

export const getLibraryItemsTotal = createSelector(getLibrary, (d) =>
  _.get(d, "totalItems", 0)
);

export const getLibraryPagesCount = createSelector(getLibrary, (d) =>
  _.get(d, "pagesCount", 0)
);

export const getLibrarySearchGuid = createSelector(getLibrary, (d) =>
  _.get(d, "searchGuid", null)
);

export const getLibrarySearchIteration = createSelector(getLibrary, (d) =>
  _.get(d, "searchIteration", 0)
);

export const getLibraryIsAssignClassModalOpen = createSelector(
  getLibrary,
  (d) => _.get(d, "isModalOpen", false)
);
export const getLibraryAssignClassModalData = createSelector(getLibrary, (d) =>
  _.get(d, "modalData", {})
);

export const getLibraryPageSize = createSelector(getLibrary, (d) =>
  _.get(d, "pageSize", DEFAULT_PAGE_SIZE)
);

export const getLibraryIsLoading = createSelector(getLibrary, (d) =>
  _.get(d, "isLoading", false)
);

export const getLibraryLeftPanelIsOpen = createSelector(getLibrary, (d) =>
  _.get(d, "isLeftSidePanelOpen")
);

export const areLibraryResultsNull = (state) =>
  getLibraryItemsList(state) === null;

export const isInsideLibrary = createSelector(
  [getLocationPath],
  (locationPath) => !!_.includes(locationPath, APP_PAGES.LIBRARY_BASE.path)
);

export const numberLibraryFiltersSelected = createSelector(
  [getSelectedCollectionId, getSelectedFormatFilter],
  (collectionId, formatFilter) => {
    let filtersCounter = 0;
    if (
      !_.isEmpty(collectionId) &&
      collectionId !== LIBRARY_CATEGORIES.UNCATEGORIZED.urlKey &&
      collectionId !== LIBRARY_CATEGORIES.ALL.urlKey
    ) {
      filtersCounter += 1;
    }

    if (
      !_.isEmpty(formatFilter) &&
      formatFilter !==
        SHARED_URL_PARAMS_DEFAULT_VALUES[SHARED_URL_KEYS.format.key]
    ) {
      filtersCounter += 1;
    }
    return filtersCounter;
  }
);

export const getJoinedFilterParams = createSelector(
  [numberLibraryFiltersSelected],
  (totalSelected) => {
    if (!totalSelected) return "";

    // to be implemented

    return "";
  }
);
