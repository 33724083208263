import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ClassTile.module.scss";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import classnames from "classnames";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { isBlackFont } from "@hapara/ui/src/components/utils";
import ClassTileContextMenu from "./ClassTileContextMenu";
import { LandingModules } from "../../../../types/dashboard";
import { Link } from "react-router-dom";
import { APP_PAGES } from "../../../../state/router/types";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import Tippy from "@tippy.js/react";
import { getClassSubjectFolders } from "../../../../state/shared/selectors";
import { useIntl } from "react-intl";

export const TILE_DEFAULT_COLOUR = "#3346D8";
const TILE_SYNC_COLOUR = "#D6D6D8";
const TILE_BLACK_FONT_COLOUR = "#000000";
const TILE_WHITE_FONT_COLOUR = "#ffffff";

const ClassTile = ({
  classInfo,
  draggableHandleClassName,
  onStar,
  landingModule = LandingModules.DASHBOARD,
  className,
}) => {
  const intl = useIntl();
  const [starringInProgress, setStarringInProgress] = useState(false);

  const state = useSelector((state) => state);
  const subjectFolders = getClassSubjectFolders(state, classInfo.id);
  const defaultSubjectFolder = subjectFolders?.length
    ? subjectFolders[0]
    : "Documents";

  const classURL = `${process.env.REACT_APP_HSUITE_BASE_URL}/dashboard/folder/${classInfo.id}/${defaultSubjectFolder}`;

  const classColor = classInfo.isClassSyncInProgress
    ? TILE_SYNC_COLOUR
    : classInfo.color
    ? classInfo.color
    : TILE_DEFAULT_COLOUR;

  return (
    <article
      className={classnames(styles.root, className)}
      data-test-id={`td-ClassTile-article-${classInfo.id}`}
    >
      {landingModule === LandingModules.DASHBOARD && (
        <a
          style={{ backgroundColor: classColor }}
          className={styles.header}
          href={classURL}
          data-test-id="td-ClassTile-Header-ClassLink-Dashboard"
          aria-label={`${classInfo.class_alias} - Open Dashboard`}
        >
          <h2
            style={{
              color: isBlackFont(classColor)
                ? TILE_BLACK_FONT_COLOUR
                : TILE_WHITE_FONT_COLOUR,
            }}
          >
            {classInfo.class_alias}
          </h2>
        </a>
      )}
      {landingModule === LandingModules.HIGHLIGHTS && (
        <Link
          style={{ backgroundColor: classColor }}
          className={styles.header}
          to={`${APP_PAGES.HIGHLIGHTS_ACTIVITY.path}?classId=${classInfo.id}`}
          data-test-id="td-ClassTile-Header-ClassLink-Highlights"
          aria-label={`${classInfo.class_alias} - Open Highlights`}
        >
          <h2
            style={{
              color: isBlackFont(classColor)
                ? TILE_BLACK_FONT_COLOUR
                : TILE_WHITE_FONT_COLOUR,
            }}
          >
            {classInfo.class_alias}
          </h2>
        </Link>
      )}
      <div
        className={classnames(styles.content, draggableHandleClassName)}
        data-test-id="Mc-ClassTile-Body"
      >
        <div
          className={classnames(styles.teacherName)}
          data-test-id="Mc-ClassTile-Description"
        >
          {classInfo.isClassSyncInProgress &&
            intl.formatMessage({
              defaultMessage: "Adding class from Google Classroom...",
              id: "elSQBH",
            })}
          {!classInfo.isClassSyncInProgress &&
            classInfo.teachers.map((t) => t.name).join(", ")}
        </div>
        {classInfo.classType === "GoogleClassroom" && (
          <div>
            <HapReactIcon
              className={styles.gcIcon}
              svg="google-classroom-square"
              width={32}
              height={32}
              alt="Google Classroom"
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Tippy
          content={
            classInfo.starred
              ? intl.formatMessage({
                  defaultMessage: "Remove from My Classes",
                  id: "R+bXz6",
                })
              : intl.formatMessage({
                  defaultMessage: "Add to My Classes",
                  id: "8PTLfe",
                })
          }
          maxWidth={150}
          theme="hsuite"
          placement="top-start"
        >
          <MemorisedFocusButton
            aria-label={
              classInfo.starred ? "Remove from My Classes" : "Add to My Classes"
            }
            tooltip
            isLoading={starringInProgress}
            isDisabled={starringInProgress || classInfo.isClassSyncInProgress}
            type={BUTTON_TYPES.OUTLINED}
            size={BUTTON_SIZES.SMALL}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            data-test-id={`td-ClassTile-Action-${
              classInfo.starred ? "Unstar" : "Star"
            }`}
            icon={classInfo.starred ? "star-fill" : "star"}
            onClick={async () => {
              setStarringInProgress(true);
              try {
                await onStar();
              } finally {
                setStarringInProgress(false);
              }
            }}
          />
        </Tippy>
        <div className={styles.gap} />
        <ClassTileContextMenu
          isDisabled={classInfo.isDisabled}
          classId={classInfo.id}
          color={classInfo.color}
          classType={classInfo.classType}
        />
      </div>
    </article>
  );
};

ClassTile.propTypes = {
  classInfo: PropTypes.object,
  landingModule: PropTypes.oneOf([
    LandingModules.HIGHLIGHTS,
    LandingModules.DASHBOARD,
  ]),
  draggableHandleClassName: PropTypes.string,
  className: PropTypes.string,
};

export default ClassTile;
