import { User } from "../types/User";
import {
  object,
  parse,
  string,
  optional,
  boolean,
  nullable,
  Output,
  array,
} from "valibot";
import { getUserFromAuthToken } from "./getUserFromAuthToken";
import { getDomainFromEmail } from "./getDomainFromEmail";

import { transformPermissions } from "./transformPermissions";

const InitResponseSchema = object({
  Permissions: optional(array(string())),
  Metadata: object({
    MasqUser: nullable(string()),
    ID: string(),
    Email: string(),
    Name: string(),
    FirstName: string(),
    LastName: string(),

    ClassTypesAvailable: optional(
      object({
        DataLoader: boolean(),
        GoogleClassroom: boolean(),
        SelfRostering: boolean(),
      })
    ),
    ClassTypesTaught: optional(
      object({
        DataLoader: boolean(),
        GoogleClassroom: boolean(),
        SelfRostering: boolean(),
      })
    ),
    Schools: nullable(
      array(
        object({
          Code: string(),
          Name: string(),
          Domain: string(),
        })
      )
    ),
    Roles: optional(
      object({
        Teacher: optional(boolean()),
        Student: optional(boolean()),
        DistrictAdmin: optional(boolean()),
        SchoolAdmin: optional(boolean()),
        SysAdmin: optional(boolean()),
        SuperAdmin: optional(boolean()),
        AnalyticsUser: optional(boolean()),
        DRM: optional(boolean()),
      })
    ),
    Features: optional(
      object({
        Chat: optional(boolean()),
        Analytics: optional(boolean()),
        Dashboard: optional(boolean()),
        DigitalBackpack: optional(boolean()),
        GCAnalytics: optional(boolean()),
        Highlights: optional(boolean()),
        StudentDashboard: optional(boolean()),
        Workspace: optional(boolean()),
        WorkspacePrivateLibrary: optional(boolean()),
      })
    ),
  }),
});

export type InitResponse = Output<typeof InitResponseSchema>;

/** Role determination from JWT
 * | JWT Role          | Admin Role           |
 * | ------------------| -------------------- |
 * | `domain_sysadmin` | District Tech Admin  |
 * | `site_sysadmin`   | School Tech Admin    | */
export const transformUser = (
  userResponse: unknown,
  authToken: string
): User => {
  const { Metadata: metadata, Permissions: permissions } = parse(
    InitResponseSchema,
    userResponse
  );
  const userFromAuthToken = getUserFromAuthToken(authToken);

  const classTypesTaught = metadata.ClassTypesTaught;
  const classTypesAvailable = metadata.ClassTypesAvailable;
  const features = metadata.Features;

  const roles = metadata.Roles;

  const masqueradeEmail =
    metadata.MasqUser !== "" ? metadata.MasqUser ?? null : null;

  const domain = getDomainFromEmail(metadata.Email);

  return {
    name: metadata.Name,
    email: metadata.Email,
    domain,
    familyName: metadata.LastName,
    givenName: metadata.FirstName,
    id: userFromAuthToken.id,
    masqueradeEmail,
    avatarUrl: userFromAuthToken.avatarUrl,
    roles: {
      districtAdmin:
        userFromAuthToken.roles?.includes("domain_sysadmin") ?? false,
      schoolAdmin: userFromAuthToken.roles?.includes("site_sysadmin") ?? false,
      analyticsUser: roles?.AnalyticsUser ?? false,
      drm: roles?.DRM ?? false,
      student: roles?.Student ?? false,
      superAdmin: roles?.SuperAdmin ?? false,
      sysAdmin: roles?.SysAdmin ?? false,
      teacher: roles?.Teacher ?? false,
    },

    schools:
      metadata.Schools?.map((school) => ({
        code: school.Code,
        name: school.Name,
        domain: school.Domain,
      })) ?? [],

    features: {
      analytics: features?.Analytics ?? false,
      chat: features?.Chat ?? false,
      dashboard: features?.Dashboard ?? false,
      digitalBackpack: features?.DigitalBackpack ?? false,
      gcAnalytics: features?.GCAnalytics ?? false,
      highlights: features?.Highlights ?? false,
      studentDashboard: features?.StudentDashboard ?? false,
      workspace: features?.Workspace ?? false,
      workspacePrivateLibrary: features?.WorkspacePrivateLibrary ?? false,
    },

    permissions: transformPermissions(permissions),

    classTypesTaught: {
      dataLoader: classTypesTaught?.DataLoader ?? false,
      googleClassroom: classTypesTaught?.GoogleClassroom ?? false,
      selfRostering: classTypesTaught?.SelfRostering ?? false,
    },

    classTypesAvailable: {
      dataLoader: classTypesAvailable?.DataLoader ?? false,
      googleClassroom: classTypesAvailable?.GoogleClassroom ?? false,
      selfRostering: classTypesAvailable?.SelfRostering ?? false,
    },
  };
};
