import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./BoardPage.module.scss";
import Header from "../../../partial/Workspace/Boards/Header/Header";
import BoardLayout from "../../../partial/Workspace/Boards/BoardLayout/BoardLayout";
import CardModal from "../../../partial/Workspace/Boards/CardModal/CardModal";
import WorkspaceShareModal, {
  WorkspacePrivacySettings,
} from "../../../partial/Workspace/Boards/WorkspaceShareModal/WorkspaceShareModal";
import LeftSidePanel from "../../../partial/Workspace/Boards/LeftSidePanel/LeftSidePanel";
import useIsDimension, {
  WINDOW_SIZE_TYPES,
} from "../../../../hooks/useIsDimension";
import Page from "../../../partial/PageContainer/PageContainer";
import NotFoundPage from "../../NotFoundPage/NotFoundPage";
import { APP_PAGES } from "../../../../state/router/types";
import { useQuery } from "@apollo/client";
// import { getIsConfigError, getIsConfigLoaded } from '../../../../state/library/config/selectors'
import { getFFByName } from "../../../../state/app/selectors";
import { getUserPermissions } from "../../../../state/user/selectors";
import { useAppSelector } from "../../../../state/hooks";
import {
  Action,
  Card,
  GetWorkspaceByIdDocument,
  Group,
  Workspace,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import { closeCardModal } from "./navigation";
import { stripHtmlTagsFromString } from "@hapara/ui/src/components/utils";
import classnames from "classnames";
import _ from "lodash";
import Toast, { TOAST_TYPES } from "@hapara/ui/src/deprecated/Toast/Toast";
import { MembersLayout } from "../../../partial/Workspace/Boards/MembersLayout/MembersLayout";
import GroupsLayout from "../../../partial/Workspace/Boards/GroupsLayout/GroupsLayout";
import SettingsLayout from "../../../partial/Workspace/Boards/SettingsLayout/SettingsLayout";
import LearningEvidenceLayout from "../../../partial/Workspace/Boards/LearningEvidenceLayout/LearningEvidenceLayout";
import ConfirmationDialog from "../../../partial/Workspace/Boards/ConfirmationDialog/ConfirmationDialog";
import EvidenceModal from "../../../partial/Workspace/Boards/CardModal/EvidenceModal/EvidenceModal";
const dataTestIdPrefix = "Ws-Pages-Board";

export const MUTATION_STATUS = {
  IDLE: "idle",
  SUCCESS: "success",
  ERROR: "error",
};

// TODO: check for isPageLoaded & isPageLoadingError when config and init endpoints will be connected

export const BoardPage = () => {
  //   const isPageLoaded = useAppSelector(state => getIsConfigLoaded(state))
  //   const isPageLoadingError = useAppSelector(state => getIsConfigError(state))
  const isWSR = useAppSelector((state) =>
    getFFByName("HAP-9580-WS-Rewrite-FE")(state)
  );
  const userPermissions = useAppSelector((state) => getUserPermissions(state));
  const { boardId } = useParams<{ boardId?: string }>();
  const { cardId } = useParams<{ cardId?: string }>();
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(true);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [mutationStatus, setMutationStatus] = useState(MUTATION_STATUS.IDLE);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteCardId, setDeleteCardId] = useState<string | null>(null);
  const [evidenceModalOpen, setEvidenceModalOpen] = useState<boolean>(false);
  const [evidenceModalPayload, setEvidenceModalPayload] = useState<
    Array<string>
  >([]);

  const { isDimensionOnly: isBelowMenuThreshold } = useIsDimension(
    WINDOW_SIZE_TYPES.L
  ); //Checks screensize & sets menu display to suit
  const [isWorkspaceShareModalOpen, setWorkspaceShareModalOpen] =
    useState(false);
  const { loading, error, data } = useQuery(GetWorkspaceByIdDocument, {
    variables: { id: boardId },
  });

  const isCardModel = () => {
    return window.location.pathname.match(/\/c\/.*/) !== null;
  };

  useEffect(() => {
    setIsLeftPanelOpen(!isBelowMenuThreshold);
  }, [isBelowMenuThreshold]);

  //TOAST
  useEffect(() => {
    const timeoutSuccess = setTimeout(function () {
      setShowSuccessToast(false);
      setMutationStatus(MUTATION_STATUS.IDLE);
    }, 5000);

    return () => {
      clearTimeout(timeoutSuccess);
    };
  }, [showSuccessToast]);

  useEffect(() => {
    switch (mutationStatus) {
      case MUTATION_STATUS.SUCCESS:
        setShowSuccessToast(true);
        break;
      case MUTATION_STATUS.ERROR:
        setShowErrorToast(true);
        const timeoutError = setTimeout(function () {
          setShowErrorToast(false);
          setMutationStatus(MUTATION_STATUS.IDLE);
        }, 5000);
        return () => {
          clearTimeout(timeoutError);
        };
    }
  }, [mutationStatus]);

  //MODALS
  useEffect(() => {
    if (deleteCardId !== null) {
      setDeleteDialogOpen(true);
    } else {
      setDeleteDialogOpen(false);
    }
  }, [deleteCardId]);

  useEffect(() => {
    if (!_.isEmpty(evidenceModalPayload)) {
      setEvidenceModalOpen(true);
    } else {
      setEvidenceModalOpen(false);
    }
  }, [evidenceModalPayload]);

  const navigationPage = NavigationLocation(window.location.pathname);

  const closeCardModalAction = () => closeCardModal({ boardId: boardId || "" });
  const workspace = data?.getWorkspaceByID;
  const modalCardData = () => {
    if (cardId) {
      return workspace && workspace!.Cards.find((c: Card) => c.ID === cardId);
    }
    return null;
  };

  const boardPermissions = workspace?.Permissions[0]
    ? workspace?.Permissions[0].Actions
    : [];

  if (
    !boardId ||
    !isWSR ||
    (!loading && !workspace) ||
    //Dont show nav pages if you dont have edit permission
    (workspace &&
      !boardPermissions.includes(Action.CanEdit) &&
      navigationPage !== "board")
  ) {
    return <NotFoundPage />;
  }

  const handleOpenBoardShareModal = (setOpen: boolean) => {
    setWorkspaceShareModalOpen(setOpen);
  };

  const closeBoardShareModalAction = () => handleOpenBoardShareModal(false);

  const toast = (success: boolean) => {
    return (
      <div
        className={styles.toast}
        aria-live="polite"
        aria-atomic="true"
        key={success ? "success-toast" : "error-toast"}
        data-test-id={
          success
            ? `${dataTestIdPrefix}-Success-Toast`
            : `${dataTestIdPrefix}-Error-Toast`
        }
      >
        <Toast
          className=""
          icon={success ? "circle-check-fill" : "fill-circle-exclamation"}
          type={success ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR}
          onClose={() => {
            setShowSuccessToast(false);
            setShowErrorToast(false);
            setMutationStatus(MUTATION_STATUS.IDLE);
          }}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div>
            {success ? <strong>Task completed successfully</strong> : null}
            {!success ? (
              <span>
                <strong>Task can’t be completed.</strong> Please try again
              </span>
            ) : null}
          </div>
        </Toast>
      </div>
    );
  };

  const handleSaveCardModalAction = () => {
    closeCardModalAction();
    setShowSuccessToast(true);
  };

  const handleCollapseSideMenuClick = () => {
    setIsLeftPanelOpen(!isLeftPanelOpen);
  };

  const pageTitle = (workspace: any) => {
    let titlePrefix = "";
    switch (navigationPage) {
      case "members":
        titlePrefix = "Members - ";
        break;
      case "groups":
        titlePrefix = "Groups - ";
        break;
      case "settings":
        titlePrefix = "Settings - ";
        break;
      case "learningevidence":
        titlePrefix = "Learning Evidence - ";
        break;
    }
    return workspace
      ? `${titlePrefix}${stripHtmlTagsFromString(workspace!.Title)} - ${
          APP_PAGES.WORKSPACE_BOARDS.title
        }`
      : APP_PAGES.WORKSPACE_BOARDS.title;
  };

  const privacySettings: WorkspacePrivacySettings = {
    isVisibleGlobally: !!workspace?.IsVisibleGlobally,
    isVisibleToSchoolDistrict: !!workspace?.IsVisibleToSchoolDistrict,
  };

  return (
    <Page
      header={
        <Header
          permissions={boardPermissions}
          boardAccessStatus={workspace?.AccessStatus}
          dataTestIdPrefix={dataTestIdPrefix}
          handleOpenBoardShareModal={handleOpenBoardShareModal}
        />
      }
      title={pageTitle(workspace)}
      // isPageLoaded={isPageLoaded}
      // isPageLoadingError={isPageLoadingError}
      isPageLoaded={!loading}
      isPageLoadingError={!!error}
      isScrollableHeader={true}
    >
      {!loading && !error && (
        <div className={styles.root} data-test-id={`${dataTestIdPrefix}-Root`}>
          <nav
            className={classnames(styles.contentLeft, {
              [styles.collapsedLeftPanel]: !isLeftPanelOpen,
            })}
          >
            <LeftSidePanel
              sections={workspace!.Sections!}
              boardImage={workspace!.Image}
              imageAltText={workspace!.ImageAltText}
              dataTestIdPrefix={dataTestIdPrefix}
              isOpen={isLeftPanelOpen}
              setIsOpen={handleCollapseSideMenuClick}
              permissions={boardPermissions}
            />
          </nav>
          <main className={styles.contentMain} id="mainContent">
            <div className={styles.contentWrapper}>
              {getPageComponent(
                navigationPage,
                workspace,
                setDeleteCardId,
                setEvidenceModalPayload
              )}
            </div>
          </main>
          {isCardModel() && (
            <CardModal
              card={modalCardData() as Card}
              boardId={boardId}
              dataTestIdPrefix={dataTestIdPrefix}
              closeModalAction={closeCardModalAction}
              permissions={boardPermissions}
              handleSaveCardModalAction={handleSaveCardModalAction}
              workspaceGroups={workspace?.Groups as Group[]}
              mutationStatusCB={setMutationStatus}
            />
          )}
          {isWorkspaceShareModalOpen && (
            <WorkspaceShareModal
              dataTestIdPrefix={dataTestIdPrefix}
              closeModalAction={closeBoardShareModalAction}
              permissions={boardPermissions}
              boardAccessStatus={workspace?.AccessStatus}
              userPermissions={userPermissions}
              privacySettings={privacySettings}
            />
          )}
          {showSuccessToast && toast(true)}
          {showErrorToast && toast(false)}

          <ConfirmationDialog
            cardId={deleteCardId}
            boardId={boardId}
            isOpen={deleteDialogOpen}
            setDeleteCardID={setDeleteCardId}
            dataTestIdPrefix={dataTestIdPrefix}
            mutationStatusCB={setMutationStatus}
          />

          <EvidenceModal
            modalOpen={evidenceModalOpen}
            modalPayload={evidenceModalPayload}
            modalStateAction={setEvidenceModalPayload}
            dataTestIdPrefix={dataTestIdPrefix}
          />
        </div>
      )}
    </Page>
  );
};

export const NavigationLocation = (location: string) => {
  //Match URL on */w/boardId/(location) to get the naviagtion page
  const locationMatch = location.match(/\/w\/\w+\/(\w+)\/?$/);
  if (locationMatch && locationMatch.length > 0) {
    return locationMatch[1];
  }

  return "board";
};

const getPageComponent = (
  location: string,
  workspace: Workspace | null | undefined,
  setDeleteCardID: (cardId: string) => void,
  setEvidenceModalPayload: (modalResponse: [string, string]) => void
) => {
  switch (location) {
    case "members":
      return <MembersLayout />;
    case "groups":
      return <GroupsLayout />;
    case "settings":
      return <SettingsLayout />;
    case "learningevidence":
      return <LearningEvidenceLayout />;

    default:
      return (
        <BoardLayout
          workspace={workspace!}
          dataTestIdPrefix={dataTestIdPrefix}
          setDeleteCardID={setDeleteCardID}
          setEvidenceModalPayload={setEvidenceModalPayload}
        />
      );
  }
};
export default BoardPage;
