import {
  fetchDiscoverResources,
  getFiltersList,
} from "../../../apiCalls/library";
import { showAppError } from "../../app/actions";
import actionTypes from "../../actionTypes";
import _ from "lodash";
import { updateAccessError } from "../../app/actions";
import { DEFAULT_PAGE_SIZE } from "../../shared/types";
import { populateDiscoverSearchEventData, pendoTrack } from "../../../utils";
import {
  getDiscoverItemsTotal,
  getDiscoverSearchGuid,
  getDiscoverSearchIteration,
} from "./selectors";
import { RESOURCE_FORMAT } from "../../resources/types";

export const loadDiscoverItems =
  ({
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
    query,
    format,
    pageSize,
    page = 1,
  }) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.LIBRARY_DISCOVER_ITEMS_LOAD_PENDING,
    });

    const bodyRequest = {
      Grades: grades,
      Subjects: subjects,
      Authorities: standards,
      ResourceTypes: resourceTypes,
      LicenceTypes: licences,
      Query: query,
      PageSize: pageSize,
      Page: page,
    };

    switch (format) {
      case RESOURCE_FORMAT.WORKSPACE:
        bodyRequest.Format = RESOURCE_FORMAT.WORKSPACE;
        break;

      case RESOURCE_FORMAT.RESOURCE:
        bodyRequest.Format = RESOURCE_FORMAT.RESOURCE;
        break;

      default:
        break;
    }

    return fetchDiscoverResources({ bodyRequest })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(showAppError(Error(response.status)));
        } else {
          dispatch({
            type: actionTypes.LIBRARY_DISCOVER_ITEMS_LOAD_SUCCESS,
            payload: response.data,
          });
        }
      })
      .then(() => {
        // send search and filter actions to pendo
        const state = getState();
        const iterCount = getDiscoverSearchIteration(state);
        const searchGuid = getDiscoverSearchGuid(state);
        const totalResults = getDiscoverItemsTotal(state);
        const searchFilters = populateDiscoverSearchEventData({
          grades,
          subjects,
          standards,
          resourceTypes,
          licences,
          query,
          page,
          totalResults,
          searchGuid,
          iterCount,
        });
        pendoTrack("Discover Search", searchFilters);
        dispatch({
          type: actionTypes.LIBRARY_DISCOVER_SEARCH_GUID_UPDATE,
          searchGuid: searchFilters.guid,
          iterCount: searchFilters.iter,
        });
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        } else {
          dispatch(showAppError(error));
        }
      });
  };

export const resetDiscoverItems = () => ({
  type: actionTypes.LIBRARY_DISCOVER_RESET_ITEMS,
});

export const applyLibraryDiscoverSearchParams =
  ({
    grades,
    subjects,
    standards,
    resourceTypes,
    licences,
    query,
    format,
    page,
  }) =>
  (dispatch) => {
    dispatch(
      loadDiscoverItems({
        grades,
        subjects,
        standards,
        resourceTypes,
        licences,
        query,
        format,
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      })
    );
  };

export const toggleDiscoverLeftPanel = (isOpen) => ({
  type: actionTypes.LIBRARY_DISCOVER_LEFT_PANEL_UPDATE,
  payload: isOpen,
});

export const loadFiltersList = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESOURCES_FILTERS_LOAD_PENDING,
  });

  getFiltersList()
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      }
      return response.data;
    })
    .then((data) => {
      dispatch({
        type: actionTypes.RESOURCES_FILTERS_LOAD_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.RESOURCES_FILTERS_LOAD_ERROR,
      });
      dispatch(showAppError(error));
    });
};
