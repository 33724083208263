// the file needs to be split to more logical chunks

import { getCurrentAuthHeader } from "./authHeader";
import {
  MYWORKSPACES_DEFAULT_LABEL,
  MYWORKSPACES_FILTER_CATEGORY_TYPES,
  MYWORKSPACES_PAGE_SIZE,
  MYWORKSPACES_PL_ROLES,
} from "../state/workspace/myworkspaces/list/types";
import { MYWORKSPACES_THUMBNAIL_PAGE_SIZE } from "../state/workspace/myworkspaces/thumbnails/types";
import { PL_WORKSPACES_PAGE_SIZE } from "../state/workspace/pl/types";

export async function setWorkspacePublished(item, isPublished) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/update/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        BoardId: item.Id,
        Operations: [
          {
            Path: "IsPublished",
            BoolOp: {
              Item: isPublished,
              ReplacedItem: !isPublished,
              Type: "Replace",
            },
          },
        ],
      }),
    }
  );
}

export async function archiveWorkspace(item) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/update/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        BoardId: item.Id,
        Operations: [
          {
            Path: "IsArchived",
            BoolOp: { Item: true, ReplacedItem: false, Type: "Replace" },
          },
        ],
      }),
    }
  );
}

export async function restoreWorkspace(item) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/update/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        BoardId: item.Id,
        Operations: [
          {
            Path: "IsArchived",
            BoolOp: { Item: false, ReplacedItem: true, Type: "Replace" },
          },
        ],
      }),
    }
  );
}

export async function copyWorkspace(item) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/copy/${item.Id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        BoardId: item.Id,
        Operations: [
          {
            Path: "IsArchived",
            BoolOp: { Item: true, ReplacedItem: false, Type: "Replace" },
          },
        ],
      }),
    }
  );
}

export async function deleteWorkspace(item) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/${item.Id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchProfessionalLearningWorkspacesCall(options) {
  const { page, order } = options;
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/v2/workspaces/?category=pd&status=all&page_number=${page}&pl_filter=participant&order_by=${order}&page_size=${PL_WORKSPACES_PAGE_SIZE}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchMyWorkspacesCall({
  category,
  filterPlRole,
  classId,
  filterStatusType,
  page,
  filterSortType,
  filterLabelId,
}) {
  const categoryString = `category=${category}&`;
  const classIdString =
    category === MYWORKSPACES_FILTER_CATEGORY_TYPES.CLASSES
      ? `class_id=${classId}&`
      : "";
  const statusString = filterStatusType ? `status=${filterStatusType}&` : "";
  const plFilterString = filterPlRole
    ? filterPlRole !== MYWORKSPACES_PL_ROLES.ALL
      ? `pl_filter=${filterPlRole}&`
      : ""
    : "";
  const labelString = filterLabelId
    ? filterLabelId === MYWORKSPACES_DEFAULT_LABEL.id
      ? `subcategory=${MYWORKSPACES_DEFAULT_LABEL.id}&`
      : `label_id=${filterLabelId}&`
    : "";

  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/v2/workspaces/` +
      "?" +
      categoryString +
      classIdString +
      statusString +
      labelString +
      plFilterString +
      `order_by=${filterSortType}` +
      `&page_number=${page}` +
      `&page_size=${MYWORKSPACES_PAGE_SIZE}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function saveUserPrefs(nameFormatPref) {
  return fetch(`${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/user/prefs`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: await getCurrentAuthHeader(),
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify({ pref: "NameFormat", value: nameFormatPref }),
  });
}

export async function saveUserLabsPrefs(labsPref) {
  return fetch(
    `${process.env.REACT_APP_PUGS_BASE_URL}/v2/pugs/user/labs_prefs`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({ pref: labsPref.name, enabled: labsPref.value }),
    }
  );
}

export async function fetchMyWorkspacesLabelsCall() {
  return fetch(`${process.env.REACT_APP_WORKSPACE_BASE_URL}/boardlabels`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: await getCurrentAuthHeader(),
      Accept: "application/json, text/plain, */*",
    },
  });
}

export async function createAndAssignLabelToWorkspace({ labelName, boardId }) {
  return fetch(`${process.env.REACT_APP_WORKSPACE_BASE_URL}/boardlabels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: await getCurrentAuthHeader(),
      Accept: "application/json, text/plain, */*",
    },
    body: JSON.stringify({ Name: labelName, BoardId: boardId }),
  });
}

export async function assignAndUnassignLabelToWorkspace({
  boardId,
  assignList,
  unassignList,
}) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/${boardId}/boardlabels`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({ assign: assignList, unassign: unassignList }),
    }
  );
}

export async function deleteLabelCall(labelId) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/boardlabels/${labelId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function editLabelCall(labelId, newTitle) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/boardlabels/${labelId}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({ Name: newTitle }),
    }
  );
}

export async function fetchDiscoverFilters() {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/discovery/filters`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchThumbnailsByQuery({ query, page }) {
  const host = process.env.REACT_APP_WORKSPACE_BASE_URL + "/proxy/unsplash";
  const encodedQuery = window.encodeURIComponent(query);

  return fetch(
    `${host}/search/photos?query=${encodedQuery}&per_page=${MYWORKSPACES_THUMBNAIL_PAGE_SIZE}&page=${page}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchPhotoById(id) {
  const host = process.env.REACT_APP_WORKSPACE_BASE_URL + "/proxy/unsplash";

  return fetch(`${host}/photos/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      authorization: await getCurrentAuthHeader(),
      Accept: "application/json, text/plain, */*",
    },
  });
}

export async function saveBoardThumb(boardId, { url, alt }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/workspaces/update/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        BoardId: boardId,
        Operations: [
          { Path: "BoardThumbnail", StringOp: { Item: url, Type: "Change" } },
          {
            Path: "BoardThumbnailAlt",
            StringOp: { Item: alt, Type: "Change" },
          },
        ],
      }),
    }
  );
}

export async function trackDownloadFromUnsplash(downloadUrl) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/proxy/unsplash/${downloadUrl}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchWorkspaceProgressById(boardId) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/workspaces/${boardId}/progress`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchGradingActivityUsers({ cardId, activity }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/cards/${cardId}/get-activity-users?activityType=${activity}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchArtifactInfoCall({ cardId, activity, artefactId }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/cards/${cardId}/get-activity-info?activityType=${activity}&artifactId=${artefactId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function submitForAssigneeCall({
  cardId,
  assigneeId,
  assigneeIsGroup,
}) {
  let body = {
    CardId: cardId,
    GroupId: null,
    StudentId: assigneeId,
    Type: "ForceSubmit",
  };

  if (assigneeIsGroup) {
    body = { ...body, GroupId: assigneeId, StudentId: "" };
  }

  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/card-actions/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify(body),
    }
  );
}

/** This is a old version that takes extra parameters to allow return for edit for assessed evidence **/
/** to be removed when the ff HAP-7144-Return-Assessed-Artifact is cleaned up **/
export async function returnArtifactsCall({ cardId, artifactIdList, final }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/card-actions/return-artifacts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        ArtifactsId: artifactIdList,
        CardId: cardId,
        Type: final ? "FinishAssessment" : "ReturnUnassessedAction",
      }),
    }
  );
}

/** This is a new version that takes extra parameters to allow return for edit for assessed evidence **/
export async function returnArtifactsCallWithReturnType({
  cardId,
  artifactIdList,
  returnType,
}) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/card-actions/return-artifacts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        ArtifactsId: artifactIdList,
        CardId: cardId,
        Type: returnType,
      }),
    }
  );
}

export async function gradeArtifactsCall({ cardId, artifactId, grade }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/cards/${cardId}/artifacts/${artifactId}/grade`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({ Grade: grade }),
    }
  );
}

export async function feedbackArtifactsCall({ cardId, artifactId, feedback }) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/cards/${cardId}/artifacts/${artifactId}/feedback`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({ Feedback: feedback }),
    }
  );
}

export async function updateGradeFeedbackOfArtifactCall({
  activityId,
  activityType,
  cardId,
  artifactId,
  assigneeId,
  grade,
  feedback,
  assigneeIsGroup,
}) {
  const body = { ActivityType: activityType, ArtifactId: artifactId };

  if (assigneeIsGroup) {
    body.GroupId = assigneeId;
  } else {
    body.StudentId = assigneeId;
  }

  if (grade) {
    body.Grade = grade;
  }

  if (feedback) {
    body.Feedback = feedback;
  }

  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/cards/${cardId}/activities/${activityId}/grading`,

    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify(body),
    }
  );
}

export async function fetchWorkspaces({
  query,
  grades,
  subjects,
  standards,
  page,
  isPublic,
  pageSize,
}) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/search/v2/s` +
      "?" +
      `page=${page}` +
      `&pageSize=${pageSize}` +
      `&type=${isPublic ? "public" : "mine"}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        Grades: grades,
        Subjects: subjects,
        Authorities: standards,
        Keyword: query,
      }),
    }
  );
}

export async function fetchMimeType(fileId) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/gdriveutils/find-document?documentId=${fileId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
    }
  );
}

export async function fetchInitCreateWorkspace() {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/workspaces/init/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({}),
    }
  );
}

export async function fetchCreateWorkspace({
  id,
  title,
  description,
  isPlWorkspace,
  thumbnail,
  thumbnailAlt,
  isAutoCreateGroupForClasses,
  classIds,
}) {
  return fetch(
    `${process.env.REACT_APP_WORKSPACE_BASE_URL}/engage-api/latest/workspaces/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        authorization: await getCurrentAuthHeader(),
        Accept: "application/json, text/plain, */*",
      },
      body: JSON.stringify({
        Id: id,
        Title: title,
        Description: description || "",
        IsPDWorkspace: isPlWorkspace || false,
        BoardThumbnail: thumbnail || "",
        BoardThumbnailAlt: thumbnailAlt || "",
        AutoCreateGroupForClasses: isAutoCreateGroupForClasses || false,
        ClassIds: classIds || [],
      }),
    }
  );
}
