import _ from "lodash";
import { createSelector } from "reselect";
import { getLocationQuery } from "../../../router/selectors";
import {
  PL_WORKSPACES_URL_KEYS,
  PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES,
} from "../navigation";

export const getSearchResults = (state) =>
  _.get(state, "workspace.pl.search.items", null);
export const getSearchPagesCount = (state) =>
  _.get(state, "workspace.pl.search.pagesCount", 1);
export const getIsSearchLoading = (state) =>
  _.get(state, "workspace.pl.search.itemsLoading", false);
export const getSearchResultTotalItems = (state) =>
  _.get(state, "workspace.pl.search.totalItems", 0);

export const areThereItems = createSelector(
  [getSearchResults],
  (searchResults) => {
    return searchResults && searchResults.length > 0;
  }
);

export const getIsSearchActive = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    const paramName = PL_WORKSPACES_URL_KEYS.query.key;
    return !!_.get(locationQuery, paramName);
  }
);

export const getSearchCurrentPage = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    const paramName = PL_WORKSPACES_URL_KEYS.search_page.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = parseInt(rawParam, 10);
    return parsedParam || PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);

export const getSearchQuery = createSelector(
  [getLocationQuery],
  (locationQuery) => {
    const paramName = PL_WORKSPACES_URL_KEYS.query.key;
    const rawParam = _.get(locationQuery, paramName);
    const parsedParam = rawParam ? window.decodeURIComponent(rawParam) : "";
    return parsedParam || PL_WORKSPACES_URL_PARAMS_DEFAULT_VALUES[paramName];
  }
);
