import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClassInfoPermissionsData } from "../../apiCalls/classInfo/classInfoTypes";

//ACTIONS
import { loadClassInfoData } from "../../state/classInfo/actions";

//SELECTORS
import {
  getClassInfoData,
  getIsClassInfoLoading,
  getIsClassInfoError,
  getIsClassInfoLoaded,
} from "../../state/classInfo/selectors";
import { getMyClasses } from "../../state/shared/selectors";
import { getUserNameFormat } from "../../state/user/selectors";

//TYPES | CONSTS
import { Person } from "../../components/partial/ClassInfo/types";

// HOOKS
import { useClassInfoRemoveStudents } from "./useClassInfoRemoveStudents";
import { useClassInfoAddStudents } from "./useClassInfoAddStudents";
import { useClassInfoRemoveTeachers } from "./useClassInfoRemoveTeachers";
import { useClassInfoAddTeacher } from "./useClassInfoAddTeacher";
import { useClassInfoRenameClass } from "./useClassInfoRenameClass";
import { useClassInfoUpdatePasswords } from "./useClassInfoUpdatePasswords";
import { useClassInfoSyncClass } from "./useClassInfoSyncClass";
import { useClassInfoRemoveClass } from "./useClassInfoRemoveClass";
import { useClassInfoResetClass } from "./useClassInfoResetClass";

import { BASE_GMAIL_URL } from "../../utils";

export type ActiveModalType =
  | "addTeacher"
  | "addStudent"
  | "removeStudent"
  | "removeTeacher"
  | "editClass"
  | "renameClass"
  | "removeClass"
  | "resetPassword"
  | "resetClass";

export type InputValidationErrorType = {
  hasValidationError: boolean;
  validationErrorMessage?: string;
};

type ModalOperationsType = {
  requestResetFolders?: () => void;
  requestEditGoogleClass?: () => void;
};

type ModalControlType = {
  closeModal: () => void;
  openAddTeacherModal: () => void;
  openAddStudentModal: () => void;
  openRemoveClassModal?: () => void;
  openEditDisplayNameModal: () => void;
  openRemoveTeacherModal: (people: Person[]) => void;
  openRemoveStudentModal: (people: Person[]) => void;
  openResetPasswordModal: (people: Person[]) => void;
  openResetClassModal: () => void;
};

// TODO: we should infer these types from `useClassInfo`
export type ClassInfoState = {
  data: {
    isError: boolean;
    isDataLoadedClass: boolean;
    isGoogleClassroomClass: boolean;
    isSelfRosteringClass: boolean;
    isLoaded: boolean;
    classDisplayName?: string;
    emailLink: string;
    isLoading: boolean;
    students?: Person[];
    teachers?: Person[];
    googleDriveFolderLink?: string;
    permissions?: ClassInfoPermissionsData;
    classIdentifier?: string;
    classAlternateLink?: string | null;
    activeModal: ActiveModalType | null;
    selectedStudents: Person[];
    selectedTeachers: Person[];
  };
  operations: {
    modalControl: ModalControlType;
    modalOperations: ModalOperationsType;
    setSelectedTeachers: (teachers: Person[]) => void;
    setSelectedStudents: (students: Person[]) => void;
  };
  removeStudents: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleRemoveStudents: () => void;
  };
  addStudents: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleAddStudents: (studentEmails: string) => void;
    unsuccessfulEmails: string[];
    addStudentValidationErrorMessage: string;
  };
  addTeacher: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    validationError: InputValidationErrorType;
    showNetworkErrorBlock: boolean;
    handleAddTeacher: (teacherEmail: string) => void;
  };
  removeTeachers: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleRemoveTeachers: () => Promise<void>;
  };
  renameClass: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleRenameClass: (newClassName: string) => void;
  };
  resetClass: {
    hasError: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    handleResetClass: () => void;
  };
  updatePasswords: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleUpdatePasswords: (forceReset: boolean, newPassword: string) => void;
  };
  syncClass: {
    displaySyncInProgress: boolean;
    requestSyncClass: (() => void) | undefined;
  };
  removeClass: {
    isLoading: boolean;
    isError: boolean;
    isLoaded: boolean;
    handleRemoveClass: () => void;
  };
};

const useClassInfo = (): ClassInfoState => {
  const dispatch = useDispatch();
  const classInfoData = useSelector(getClassInfoData);
  const isLoading = useSelector(getIsClassInfoLoading);
  const isError = useSelector(getIsClassInfoError);
  const isLoaded = useSelector(getIsClassInfoLoaded);
  const [activeModal, setActiveModal] = useState<ActiveModalType | null>(null);
  const [selectedTeachers, setSelectedTeachers] = useState<Person[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<Person[]>([]);
  const nameFormat = useSelector(getUserNameFormat);

  const { classId } = useParams<{ classId: string }>();
  const classDataSource = classInfoData?.classDataSource;
  const isDataLoadedClass = classDataSource === "Dataloaded";
  const isSelfRosteringClass = classDataSource === "SelfRostering";
  const isGoogleClassroomClass = classDataSource
    ? classDataSource === "GoogleClassroom"
    : classInfoData?.permissions?.openAlternateLink ?? false;

  const sharedClassName = useSelector(getMyClasses)?.find(
    (c) => c.id === classId
  )?.class_alias;

  const {
    teachers,
    students,
    className,
    classIdentifier,
    classAlternateLink,
    permissions,
    classNickname,
    emailFormat,
    googleDriveFolderLink,
  } = classInfoData || {};
  // Users can change order of name from `fname lname` to `lname fname`
  const [renamedStudents, setRenamedStudents] = useState<Person[]>([]);
  const [renamedTeachers, setRenamedTeachers] = useState<Person[]>([]);

  useEffect(() => {
    if (students) {
      const adjustedStudents = students.map((student) => {
        const { first_name, last_name } = student;
        const adjustedName =
          nameFormat === "lname_fname"
            ? `${last_name}, ${first_name}`
            : `${first_name} ${last_name}`;
        return {
          name: adjustedName,
          id: student.id,
          email: student.email,
          firstName: first_name,
          lastName: last_name,
        };
      });
      setRenamedStudents(adjustedStudents);
    }
  }, [students, nameFormat]);

  useEffect(() => {
    if (teachers) {
      const adjustedteachers = teachers.map((teacher) => {
        const { first_name, last_name } = teacher;
        const adjustedName =
          nameFormat === "lname_fname"
            ? `${last_name}, ${first_name}`
            : `${first_name} ${last_name}`;
        return { name: adjustedName, id: teacher.id, email: teacher.email };
      });
      setRenamedTeachers(adjustedteachers);
    }
  }, [teachers, nameFormat]);

  const modalControl: ModalControlType = {
    closeModal: () => setActiveModal(null),
    openAddTeacherModal: () =>
      setActiveModal(isGoogleClassroomClass ? "editClass" : "addTeacher"),
    openAddStudentModal: () =>
      setActiveModal(isGoogleClassroomClass ? "editClass" : "addStudent"),
    openRemoveClassModal: !isDataLoadedClass
      ? () => setActiveModal("removeClass")
      : undefined,
    openEditDisplayNameModal: () =>
      setActiveModal(isGoogleClassroomClass ? "editClass" : "renameClass"),
    openRemoveTeacherModal: (people: Person[]) => {
      setActiveModal(isGoogleClassroomClass ? "editClass" : "removeTeacher");
      setSelectedTeachers(people);
    },
    openRemoveStudentModal: (people: Person[]) => {
      setActiveModal(isGoogleClassroomClass ? "editClass" : "removeStudent");
      setSelectedStudents(people);
    },
    openResetPasswordModal: (people: Person[]) => {
      setActiveModal("resetPassword");
      setSelectedStudents(people);
    },
    openResetClassModal: () => setActiveModal("resetClass"),
  };

  useEffect(() => {
    setSelectedTeachers([]);
    setSelectedStudents([]);
  }, [classId]);

  const completeStudentOperation = () => {
    setActiveModal(null);
    setSelectedStudents([]);
  };

  const completeTeacherOperation = () => {
    setActiveModal(null);
    setSelectedTeachers([]);
  };

  // REMOVE STUDENTS
  const {
    handleRemoveStudents,
    isRemoveStudentError,
    isRemoveStudentLoaded,
    isRemoveStudentLoading,
  } = useClassInfoRemoveStudents({
    selectedStudents,
    closeModal: completeStudentOperation,
    isModalOpen: activeModal === "removeStudent",
  });

  // ADD STUDENTS
  const {
    handleAddStudents,
    isAddStudentError,
    isAddStudentLoaded,
    isAddStudentLoading,
    unsuccessfulEmails,
    addStudentValidationErrorMessage,
  } = useClassInfoAddStudents({
    closeModal: completeStudentOperation,
    isModalOpen: activeModal === "addStudent",
  });

  // ADD TEACHER
  const {
    handleAddTeacher,
    isAddTeacherError,
    isAddTeacherLoaded,
    isAddTeacherLoading,
    validationErrorPayload,
    showNetworkErrorBlock,
  } = useClassInfoAddTeacher({
    teacherEmail: "",
    closeModal: completeTeacherOperation,
    isModalOpen: activeModal === "addTeacher",
  });

  // REMOVE TEACHERS
  const {
    handleRemoveTeachers,
    isRemoveTeachersError,
    isRemoveTeachersLoaded,
    isRemoveTeachersLoading,
  } = useClassInfoRemoveTeachers({
    selectedTeachers,
    closeModal: completeTeacherOperation,
    isModalOpen: activeModal === "removeTeacher",
  });

  //SYNC CLASS
  const { handleSyncClass, displaySyncInProgress } = useClassInfoSyncClass(
    classId,
    permissions?.sync
  );

  // RENAME CLASS
  const {
    handleRenameClass,
    isRenameClassError,
    isRenameClassLoaded,
    isRenameClassLoading,
  } = useClassInfoRenameClass({
    closeModal: modalControl.closeModal,
    isModalOpen: activeModal === "renameClass",
  });

  const {
    handleResetClass,
    hasResetClassError,
    isResetClassLoaded,
    isResetClassLoading,
  } = useClassInfoResetClass({
    closeModal: modalControl.closeModal,
    isModalOpen: activeModal === "resetClass",
  });

  // UPDATE PASSWORDS
  const {
    handleUpdatePasswords,
    isUpdatePasswordsError,
    isUpdatePasswordsLoaded,
    isUpdatePasswordsLoading,
  } = useClassInfoUpdatePasswords({
    selectedStudents,
    closeModal: completeStudentOperation,
    isModalOpen: activeModal === "resetPassword",
  });

  // REMOVE CLASS
  const {
    handleRemoveClass,
    isRemoveClassError,
    isRemoveClassLoaded,
    isRemoveClassLoading,
  } = useClassInfoRemoveClass({
    closeModal: modalControl.closeModal,
    isModalOpen: activeModal === "removeClass",
  });

  useEffect(() => {
    if (!isLoading && classId) {
      dispatch(loadClassInfoData(classId));
    }
  }, [classId]);

  const handleResetFolders = () => {
    setActiveModal("resetClass");
  };

  const handleEditGoogleClass = () => {
    window.open(classAlternateLink!, "_blank");
    setActiveModal(null);
  };

  const emailLink =
    emailFormat === "mailto"
      ? `mailto:${classId}`
      : `${BASE_GMAIL_URL}/u/0/?tf=cm&to=${classId}`;

  const modalOperations: ModalOperationsType = {
    requestResetFolders: isDataLoadedClass ? handleResetFolders : undefined,
    requestEditGoogleClass: classAlternateLink
      ? handleEditGoogleClass
      : undefined,
  };

  return {
    data: {
      isError,
      isDataLoadedClass,
      isSelfRosteringClass,
      isGoogleClassroomClass,
      emailLink,
      teachers: renamedTeachers,
      students: renamedStudents,
      googleDriveFolderLink,
      isLoaded,
      classDisplayName: sharedClassName || classNickname || className,
      isLoading,
      permissions,
      classIdentifier,
      activeModal,
      selectedStudents,
      selectedTeachers,
    },
    operations: {
      modalControl,
      modalOperations,
      setSelectedStudents,
      setSelectedTeachers,
    },
    removeStudents: {
      isLoading: isRemoveStudentLoading,
      isError: isRemoveStudentError,
      isLoaded: isRemoveStudentLoaded,
      handleRemoveStudents,
    },
    addStudents: {
      isLoading: isAddStudentLoading,
      isError: isAddStudentError,
      isLoaded: isAddStudentLoaded,
      handleAddStudents,
      unsuccessfulEmails,
      addStudentValidationErrorMessage,
    },
    addTeacher: {
      isLoading: isAddTeacherLoading,
      isError: isAddTeacherError,
      isLoaded: isAddTeacherLoaded,
      validationError: validationErrorPayload,
      showNetworkErrorBlock,
      handleAddTeacher,
    },
    removeTeachers: {
      isLoading: isRemoveTeachersLoading,
      isError: isRemoveTeachersError,
      isLoaded: isRemoveTeachersLoaded,
      handleRemoveTeachers,
    },
    renameClass: {
      isLoading: isRenameClassLoading,
      isError: isRenameClassError,
      isLoaded: isRenameClassLoaded,
      handleRenameClass,
    },
    resetClass: {
      isLoading: isResetClassLoading,
      hasError: hasResetClassError,
      isLoaded: isResetClassLoaded,
      handleResetClass,
    },
    updatePasswords: {
      isLoading: isUpdatePasswordsLoading,
      isError: isUpdatePasswordsError,
      isLoaded: isUpdatePasswordsLoaded,
      handleUpdatePasswords,
    },
    syncClass: {
      displaySyncInProgress,
      requestSyncClass: handleSyncClass,
    },
    removeClass: {
      isLoading: isRemoveClassLoading,
      isError: isRemoveClassError,
      isLoaded: isRemoveClassLoaded,
      handleRemoveClass,
    },
  };
};

export default useClassInfo;
