import React from "react";
import PropTypes from "prop-types";
import styles from "./ModalProblemReported.module.scss";
import Button from "@hapara/ui/src/atomic/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";

const ModalProblemReported = ({ dataTestPrefix, onDone }) => {
  const intl = useIntl();
  return (
    <>
      <div className={styles.modalBody}>
        <div className={styles.text}>
          <FormattedMessage
            defaultMessage="This session report has been successfully sent to <span>Hāpara</span>. We will investigate this problem as soon as we can."
            id="xYwEuK"
            values={{ span: (chunks) => <span lang="mi">{chunks}</span> }}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          label={intl.formatMessage({ defaultMessage: "Done", id: "JXdbo8" })}
          onClick={onDone}
          data-test-id={`${dataTestPrefix}-Button-Done`}
          className={styles.doneButton}
        />
      </div>
    </>
  );
};

ModalProblemReported.propTypes = {
  onDone: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default ModalProblemReported;
