import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./GuidedBrowsingTable.module.scss";
import classNames from "classnames";
import {
  TableView,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
} from "@hapara/ui/src/components/TableView";
import { Tooltip } from "@hapara/ui/src/components/Tooltip";
import { FocusIcon } from "@hapara/ui/src/icons/FocusIcon";
import { FilterIcon } from "@hapara/ui/src/icons/FilterIcon";
import { VisuallyHidden } from "@hapara/ui/src/atomic/VisuallyHidden";
import { GuidedBrowsingActions } from "./GuidedBrowsingActions";
import { GuidedBrowsingTemplateName } from "./GuidedBrowsingTemplateName";
import { Template } from "src/state/highlights/guideBrowsingTemplates/type";

interface TableProps {
  data: Template[];
  onStart: (template: Template) => void;
  onEdit: (template: Template) => void;
  onDelete: (template: Template) => void;
  onSchedule: (template: Template) => void;
}

interface TooltipContentProps {
  links: string[];
}

const TooltipContent = ({ links }: TooltipContentProps) => {
  const displayedLinks = links.slice(0, 10);
  const remainingCount = links.length - 10;

  return (
    <div className={styles.tooltipContent}>
      {displayedLinks.map((link, index) => (
        <span key={index}>{link}</span>
      ))}
      {remainingCount > 0 && (
        <FormattedMessage
          id="GjKZ/c"
          defaultMessage="+ {count} more..."
          values={{ count: remainingCount }}
        />
      )}
    </div>
  );
};

export const GuidedBrowsingTable = ({
  data,
  onStart,
  onEdit,
  onDelete,
  onSchedule,
}: TableProps) => {
  return (
    <TableView
      className={styles.table}
      emptyComponent={
        <FormattedMessage defaultMessage="No available templates" id="i9QiXA" />
      }
    >
      <TableHeader>
        <Column width={42}>
          <VisuallyHidden>
            <FormattedMessage defaultMessage="Type" id="+U6ozc" />
          </VisuallyHidden>
        </Column>
        <Column width={265}>
          <FormattedMessage defaultMessage="Name" id="HAlOn1" />
        </Column>

        <Column width={55} align="center">
          <FormattedMessage defaultMessage="Links" id="qCcwo3" />
        </Column>

        <Column width={220} align="center">
          <FormattedMessage defaultMessage="Actions" id="wL7VAE" />
        </Column>
      </TableHeader>
      <TableBody>
        {data.map((template) => {
          return (
            <Row key={template.Name}>
              <Cell>
                <div
                  className={classNames(styles.iconWrapper, {
                    [styles.focus]: template.Type === "focus",
                  })}
                >
                  {template.Type === "focus" ? (
                    <FocusIcon size="md" />
                  ) : (
                    <FilterIcon size="md" />
                  )}
                </div>
              </Cell>
              <Cell>
                <GuidedBrowsingTemplateName name={template.Name} />
              </Cell>

              <Cell>
                <div className={styles.linksContainer}>
                  <Tooltip
                    content={<TooltipContent links={template?.Links} />}
                    anchor={
                      <span className={styles.anchor}>
                        {template?.Links?.length}
                      </span>
                    }
                  />
                </div>
              </Cell>
              <Cell>
                <GuidedBrowsingActions
                  sessionType={template.Type}
                  onStart={() => onStart(template)}
                  onDelete={() => onDelete(template)}
                  onEdit={() => onEdit(template)}
                  onSchedule={() => onSchedule(template)}
                />
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </TableView>
  );
};
