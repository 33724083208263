import React, { useRef } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { ALT_TEXT } from "../../../../../state/app/types";
import { APP_PAGES } from "../../../../../state/router/types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { MAIN_CONTENT_ID } from "../../../../../consts";
import {
  Button,
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Dropdown, {
  DROPDOWN_WIDTH_TYPES,
  DROPDOWN_CONTENT_COLOUR_TYPES,
  DROPDOWN_CONTENT_POSITION_TYPES,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { MemorisedFocusButton } from "../../../MemorisedFocusButton/MemorisedFocusButton";
import { focusSafelyByRef } from "@hapara/ui/src/components/utils";
import {
  Action,
  BoardAccessStatus,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";

type HeaderProps = {
  permissions: Action[];
  boardAccessStatus: BoardAccessStatus | undefined;
  dataTestIdPrefix: string;
  handleOpenBoardShareModal: (setOpen: boolean) => void;
};

type DropDownItemProps = {
  title: string;
  description: string;
  clickHandler: () => void;
  ariaLabel: string;
  dataTestId: string;
  customStyle?: string;
};

export const Header = ({
  permissions,
  boardAccessStatus,
  dataTestIdPrefix,
  handleOpenBoardShareModal,
}: HeaderProps) => {
  const ref = useRef(null);

  const setDropdownFocus = () => {
    focusSafelyByRef(ref, 100);
  };

  const isEditible = permissions.includes(Action.CanEdit);

  const DropDownItem = ({
    title,
    description,
    clickHandler,
    ariaLabel,
    dataTestId,
    customStyle,
  }: DropDownItemProps) => {
    return (
      <MemorisedFocusButton
        className={styles.dropDownItem}
        onClick={clickHandler}
        aria-label={ariaLabel}
        data-test-id={dataTestId}
        label={
          <div className={styles.wrapper}>
            <div className={styles.itemBody}>
              <span
                className={classnames(styles.itemTitle, customStyle)}
                data-test-id="Ws-Pages-Board-AppBarTitle"
              >
                {title}
              </span>
              <span
                className={classnames(styles.itemDesc, customStyle)}
                data-test-id="Ws-Pages-Board-AppBarDescription"
              >
                {description}
              </span>
            </div>
          </div>
        }
      ></MemorisedFocusButton>
    );
  };

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <a
          href={`#${MAIN_CONTENT_ID}`}
          className={classnames(
            styles.visibleOnFocusOnly,
            styles.skipToContent
          )}
        >
          Skip to content
        </a>
        <Link
          to={APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path}
          aria-label={ALT_TEXT.TD_MANAGE_CLASSES}
          className={styles.logo}
          data-test-id="Hs-Header-Link-HaparaLogoMain"
        >
          <HapReactIcon svg="logo-hapara-small-light" width={40} height={40} />
        </Link>
        <Link
          to={APP_PAGES.WORKSPACE_MY_WORKSPACE.path}
          className={styles.navigationItem}
          data-test-id={`${dataTestIdPrefix}-Header-Link-Workspaces}`}
        >
          Workspaces
        </Link>
        {/* container for the all user controls on App Bar */}
        <div className={styles.userControls}>
          {isEditible && (
            <>
              <Button
                className={
                  boardAccessStatus === BoardAccessStatus.Published
                    ? styles.published
                    : styles.draft
                }
                size={BUTTON_SIZES.SMALL}
                type={BUTTON_TYPES.OUTLINED}
                icon={
                  boardAccessStatus === BoardAccessStatus.Published
                    ? "profile-group-filled"
                    : "lock-fill"
                }
                label={
                  boardAccessStatus === BoardAccessStatus.Published
                    ? "Shared"
                    : "Share"
                }
                outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                data-test-id={`${dataTestIdPrefix}-AppBar-Button-AccessStatus`}
                onClick={() => {
                  handleOpenBoardShareModal(true);
                }}
              />
              <div className={styles.moreActions}>
                <Dropdown
                  className={styles.dropdown}
                  positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
                  colorType={DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY}
                  widthType={DROPDOWN_WIDTH_TYPES.MIN_CONTENT}
                  classNameContent={styles.dropdownContent}
                  triggerComponent={(props: any) => (
                    <Button
                      icon="dropdown-button"
                      type={BUTTON_TYPES.OUTLINED}
                      size={BUTTON_SIZES.SMALL}
                      outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                      dataTestId={`${dataTestIdPrefix}-More-Actions-Trigger`}
                      className={styles.dropdownTrigger}
                      aria-label="More actions for this workspace"
                      ref={ref}
                      {...props}
                    />
                  )}
                  itemComponentList={[
                    ({ onClick, ...rest }) => (
                      <DropDownItem
                        title="Copy"
                        description="Everything except students and teachers will be copied"
                        clickHandler={() => {
                          onClick();
                          setDropdownFocus();
                        }}
                        ariaLabel="Copy"
                        dataTestId={`${dataTestIdPrefix}-Dropdown-Copy-Workspace`}
                        {...rest}
                      />
                    ),
                    ({ onClick, ...rest }) => (
                      <DropDownItem
                        title="Archive"
                        description="When you are finished with this Workspace, archive to remove student access"
                        clickHandler={() => {
                          onClick();
                          setDropdownFocus();
                        }}
                        ariaLabel="Archive"
                        dataTestId={`${dataTestIdPrefix}-Dropdown-Archive-Workspace`}
                        {...rest}
                      />
                    ),
                    ({ onClick, ...rest }) => (
                      <DropDownItem
                        title="Delete"
                        description="Workspace will permanently removed"
                        clickHandler={() => {
                          onClick();
                          setDropdownFocus();
                        }}
                        ariaLabel="Delete"
                        dataTestId={`${dataTestIdPrefix}-Dropdown-Delete-Workspace`}
                        customStyle={styles.red}
                        {...rest}
                      />
                    ),
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
