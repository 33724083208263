import { defineMessages, MessageDescriptor } from "react-intl";

interface FilterType {
  key: string;
  title: MessageDescriptor;
  desc: MessageDescriptor;
  searchTitle: MessageDescriptor;
}

interface AllDocsFilterType {
  key: string;
  title: MessageDescriptor;
}

export const messages = defineMessages({
  allDocuments: {
    id: "lXxMRw",
    defaultMessage: "All documents",
  },
  viewMostRecent: {
    id: "kMrm2L",
    defaultMessage:
      "View the most recent files in each student's Google Drive, regardless of folder or location.",
  },
  publicDocuments: {
    id: "2rHJzC",
    defaultMessage: "Public documents",
  },
  viewPublicFiles: {
    id: "MxskHo",
    defaultMessage:
      "View files in each student's Google Drive that have been shared to people outside your school domain.",
  },
  externalDocuments: {
    id: "WmMpDd",
    defaultMessage: "External documents",
  },
  viewExternalFiles: {
    id: "WCM+Cc",
    defaultMessage:
      "View files from outside your school domain that students have access to in Drive.",
  },
  unsharedDocuments: {
    id: "6uS64x",
    defaultMessage: "Unshared documents",
  },
  viewUnsharedFiles: {
    id: "N0OXQh",
    defaultMessage:
      "View files from each student's Google Drive that have not been shared with you.",
  },
  deletedDocuments: {
    id: "Y7AXk/",
    defaultMessage: "Deleted documents",
  },
  viewDeletedFiles: {
    id: "eEIkSQ",
    defaultMessage:
      "View files that the student has recently moved to their trash.",
  },
  posts: {
    id: "eR3YIn",
    defaultMessage: "Posts",
  },
  viewRecentBlogEntries: {
    id: "U+dZh7",
    defaultMessage: "View each student’s most recently posted blog entries.",
  },
  comments: {
    id: "wCgTu5",
    defaultMessage: "Comments",
  },
  viewRecentFeedback: {
    id: "HNwQge",
    defaultMessage:
      "View each student’s most recent feedback on posts and pages.",
  },
  otherOptions: {
    id: "KcETCo",
    defaultMessage: "Other options",
  },
});

export const DOC_FILTERS: FilterType[] = [
  {
    key: "all",
    title: messages.allDocuments,
    desc: messages.viewMostRecent,
    searchTitle: messages.allDocuments,
  },
  {
    key: "public",
    title: messages.publicDocuments,
    desc: messages.viewPublicFiles,
    searchTitle: messages.publicDocuments,
  },
  {
    key: "external",
    title: messages.externalDocuments,
    desc: messages.viewExternalFiles,
    searchTitle: messages.externalDocuments,
  },
  {
    key: "unshared",
    title: messages.unsharedDocuments,
    desc: messages.viewUnsharedFiles,
    searchTitle: messages.unsharedDocuments,
  },
  {
    key: "trash",
    title: messages.deletedDocuments,
    desc: messages.viewDeletedFiles,
    searchTitle: messages.deletedDocuments,
  },
];

export const EDUBLOGS_FILTER: FilterType[] = [
  {
    key: "posts",
    title: messages.posts,
    desc: messages.viewRecentBlogEntries,
    searchTitle: messages.posts,
  },
  {
    key: "comments",
    title: messages.comments,
    desc: messages.viewRecentFeedback,
    searchTitle: messages.comments,
  },
];

export const ALL_DOCS_FILTER: AllDocsFilterType = {
  key: "all",
  title: messages.otherOptions,
};
