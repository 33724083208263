import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./GradingReturnMultiple.module.scss";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import {
  getAssigneesByActivity,
  isAssigneeGroup,
  getGradingSelectedArtefactId,
  isBulkActionInitInProgress,
  isBulkActionInitError,
  isBulkReturnActionInProgress,
  isBulkReturnActionError,
} from "../../../../../state/workspace/grading/selectors";
import {
  loadGradingActivityUsersForBulkActions,
  doBulkReturn,
} from "../../../../../state/workspace/grading/actions";
import PropTypes from "prop-types";
import { MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE } from "../../../../../state/workspace/grading/types";
import { replaceQueryInHistory } from "../../../../../state/router/navigation";
import GradingMultipleSelectTable, {
  TABLE_TYPES,
} from "../GradingMultipleSelectTable/GradingMultipleSelectTable";
import useWorkspaceGradingBulkModalHook from "../../../../../hooks/useWorkspaceGradingBulkModalHook";

const RETURN_TYPES = {
  RETURN_FOR_EDIT: "return-for-edit",
  RETURN_FINAL: "return-final",
};

export const GradingReturnMultiple = ({
  assignees,
  isAssigneeGroup,
  selectedArtefactId,
  isBulkActionInitInProgress,
  isBulkActionInitError,
  isBulkReturnActionInProgress,
  isBulkReturnActionError,
  loadGradingActivityUsersForBulkActions,
  doBulkReturn,
}) => {
  const modalDataTestPrefix = "ws-Grading-BulkReturnModal";

  const { isModalOpen, setIsModalOpen, selectedList, setSelectedList } =
    useWorkspaceGradingBulkModalHook({
      assignees,
      loadAction: loadGradingActivityUsersForBulkActions,
    });

  const [lastReturnActionType, setLastReturnActionType] = useState(null);

  return (
    <div className={styles.root}>
      {assignees.length > 1 && (
        <Button
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.REGULAR}
          onAction={() => setIsModalOpen(true)}
          label="Return multiple"
          dataTestId="ws-Grading-BulkReturn-ReturnMultiple"
          className={styles.returnMultipleButton}
        />
      )}
      <ModalDeprecated
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (
            assignees.length > 0 &&
            _.findIndex(
              assignees,
              (item) => item.ArtifactID === selectedArtefactId
            ) === -1
          ) {
            // if current assignee is not in the list anymore, go to the next one
            replaceQueryInHistory({ artefact: assignees[0].ArtifactID });
          }
        }}
        title={`Select ${isAssigneeGroup ? "group" : "individual"} evidence`}
        classNameHeader={styles.bulkReturnModalTitle}
        className={styles.bulkReturnModal}
        dataTestPrefix={modalDataTestPrefix}
      >
        <form className={styles.bulkReturnModalBody}>
          <div className={styles.scrollArea}>
            <Alerts>
              {isBulkActionInitError && (
                <div className={styles.initLoadError}>
                  <Alert type={ALERT_TYPES.FAILURE}>
                    <FormattedMessage
                      defaultMessage="There was a problem. Please try again."
                      id="QHNLuA"
                    />
                  </Alert>
                </div>
              )}
              {!isBulkActionInitError && (
                <GradingMultipleSelectTable
                  type={TABLE_TYPES.RETURN}
                  assignees={assignees}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                  isAssigneeGroup={isAssigneeGroup}
                  isActionInProgress={isBulkReturnActionInProgress}
                  isInitInProgress={isBulkActionInitInProgress}
                  tableTitleId={`${modalDataTestPrefix}-Header`}
                  dataTestIdPrefix="ws-Grading-BulkReturn"
                />
              )}
            </Alerts>
          </div>

          <Alerts>
            {isBulkReturnActionError && (
              <div
                className={styles.actionAlerts}
                data-test-id="ws-Grading-BulkReturn-Error"
              >
                <Alert type={ALERT_TYPES.FAILURE}>
                  <FormattedMessage
                    defaultMessage="There was a problem returning work. Please try again."
                    id="4bw9ZW"
                  />
                </Alert>
              </div>
            )}
          </Alerts>

          <div className={styles.actionButtons}>
            {!isBulkActionInitInProgress && (
              <>
                <Button
                  type={BUTTON_TYPES.OUTLINED}
                  size={BUTTON_SIZES.REGULAR}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  onAction={() => {
                    doBulkReturn({
                      artifactIdList: selectedList,
                      isFinal: false,
                    });
                    setLastReturnActionType(RETURN_TYPES.RETURN_FOR_EDIT);
                  }}
                  label="Return for edit"
                  icon="refresh"
                  dataTestId="ws-Grading-BulkReturn-ReturnForEdit"
                  isDisabled={
                    !selectedList.length || isBulkReturnActionInProgress
                  }
                  isLoading={
                    isBulkReturnActionInProgress &&
                    lastReturnActionType === RETURN_TYPES.RETURN_FOR_EDIT
                  }
                />
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  size={BUTTON_SIZES.REGULAR}
                  onAction={() => {
                    doBulkReturn({
                      artifactIdList: selectedList,
                      isFinal: true,
                    });
                    setLastReturnActionType(RETURN_TYPES.RETURN_FINAL);
                  }}
                  label="Return final"
                  icon="mail-reply"
                  dataTestId="ws-Grading-BulkReturn-ReturnFinal"
                  isDisabled={
                    !selectedList.length || isBulkReturnActionInProgress
                  }
                  isLoading={
                    isBulkReturnActionInProgress &&
                    lastReturnActionType === RETURN_TYPES.RETURN_FINAL
                  }
                />
              </>
            )}
          </div>
        </form>
      </ModalDeprecated>
    </div>
  );
};

GradingReturnMultiple.propTypes = {
  assignees: MYWORKSPACES_GRADING_ASSIGNEES_LIST_TYPE.isRequired,
  isAssigneeGroup: PropTypes.bool.isRequired,
  selectedArtefactId: PropTypes.string,
  isBulkActionInitInProgress: PropTypes.bool.isRequired,
  isBulkActionInitError: PropTypes.bool.isRequired,
  isBulkReturnActionInProgress: PropTypes.bool.isRequired,
  isBulkReturnActionError: PropTypes.bool.isRequired,
  loadGradingActivityUsersForBulkActions: PropTypes.func.isRequired,
  doBulkReturn: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    assignees: getAssigneesByActivity(state),
    isAssigneeGroup: isAssigneeGroup(state),
    selectedArtefactId: getGradingSelectedArtefactId(state),
    isBulkActionInitInProgress: isBulkActionInitInProgress(state),
    isBulkActionInitError: isBulkActionInitError(state),
    isBulkReturnActionInProgress: isBulkReturnActionInProgress(state),
    isBulkReturnActionError: isBulkReturnActionError(state),
  }),
  (dispatch) => ({
    loadGradingActivityUsersForBulkActions: () =>
      dispatch(loadGradingActivityUsersForBulkActions()),
    doBulkReturn: (options) => dispatch(doBulkReturn(options)),
  })
)(GradingReturnMultiple);
