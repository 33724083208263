import styles from "./GroupsLayout.module.scss";

export const GroupsLayout = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h1>Groups</h1>
      </div>
      <div className={styles.content}>
        {/*Temp content, the splash style is likely to be removed*/}
        <div className={styles.splash}>Coming soon</div>
      </div>
    </div>
  );
};

export default GroupsLayout;
