import _ from "lodash";
import actionTypes from "../../actionTypes";
import { getHostname } from "../../../utils";

const INIT_STATE = { students: {}, info: {} };

const activitiesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS: {
      const { Students } = action.payload;
      const updatedStudentMap = {};
      if (Students && Students.length) {
        Students.forEach((s) => {
          updatedStudentMap[s.Id] = {};
        });
      }
      const studentInfoMap =
        Students && Students.length ? _.keyBy(Students, "Id") : {};
      return { ...state, students: updatedStudentMap, info: studentInfoMap };
    }
    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_ONLINE_STATE: {
      const { studentId, isOnline } = action.payload;
      if (!isOnline) {
        //we need to set all tabs to inactive
        const currentUrls = _.keys(state.students[studentId]);
        const updatedMap = {};
        currentUrls.forEach((url) => {
          updatedMap[url] = {
            ...state.students[studentId][url],
            current: false,
          };
        });
        return {
          ...state,
          students: { ...state.students, [studentId]: updatedMap },
        };
      } else {
        return state;
      }
    }
    case actionTypes.HIGHLIGHTS_UPDATE_STUDENT_DATA: {
      const { studentId, tabs, instanceId } = action.payload;
      const studentIds = _.keys(state.students);
      const currentTime = new Date().getTime();
      if (tabs && _.indexOf(studentIds, studentId) !== -1) {
        //we need to add all incoming urls and mark all that are not in the list as inactive
        const currentUrls = _.keys(state.students[studentId]);
        const updatedUrlMap = { ...state.students[studentId] };

        tabs.forEach((tab) => {
          if (!updatedUrlMap[tab.url]) {
            //special handling for old tabs that were opened before FE started
            //may not always work, but as activity listener is subject to removal when history is properky implemented
            //leaving as is until somebody complains
            const openTime =
              tab.when * 1000 < currentTime - 5000
                ? tab.when * 1000
                : currentTime;
            updatedUrlMap[tab.url] = {
              ...tab,
              when: openTime,
              current: true,
              start: new Date().getTime(),
              history: [
                {
                  ...tab,
                  when: openTime,
                  wasOpened: true,
                  docName: tab.title,
                  siteName: getHostname(tab.url),
                  instance_id: instanceId,
                },
              ],
            };
          } else {
            let addToHistory = !updatedUrlMap[tab.url].current;
            updatedUrlMap[tab.url] = {
              ...updatedUrlMap[tab.url],
              ...tab,
              when: addToHistory ? currentTime : updatedUrlMap[tab.url].when,
              current: true,
              docName: tab.title,
              siteName: getHostname(tab.url),
              instance_id: instanceId,
            };
            updatedUrlMap[tab.url].history = updatedUrlMap[tab.url].history.map(
              (td) => {
                return {
                  ...td,
                  title: tab.title,
                  docName: tab.title,
                };
              }
            );
            if (addToHistory) {
              updatedUrlMap[tab.url].history = updatedUrlMap[
                tab.url
              ].history.concat([
                {
                  ...tab,
                  when: currentTime,
                  wasOpened: true,
                  docName: tab.title,
                  siteName: getHostname(tab.url),
                  instance_id: instanceId,
                },
              ]);
            }
          }
        });
        //also we need to make all urls that are not current - closed
        currentUrls.forEach((u) => {
          if (updatedUrlMap[u].current) {
            const isInNewTabList = _.findIndex(tabs, (t) => t.url === u) >= 0;
            if (!isInNewTabList) {
              updatedUrlMap[u].current = false;
              updatedUrlMap[u].history = updatedUrlMap[u].history.concat([
                {
                  ...updatedUrlMap[u],
                  history: null,
                  wasOpened: false,
                  when: currentTime - 1, //when student changed url in the same tab
                },
              ]);
            }
          }
        });
        return {
          ...state,
          info: {
            ...state.info,
            [studentId]: { ...(state.info[studentId] || {}), instanceId },
          },
          students: { ...state.students, [studentId]: updatedUrlMap },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export default activitiesReducer;
