import React from "react";
import styles from "./NoSessionsInfoPanel.module.scss";
import PropTypes from "prop-types";
import noSnapsImage from "@hapara/assets/src/images/vector-hl-no-scheduled-sessions.svg";

const NoSessionsInfoPanel = ({ noSessionsText }) => {
  return (
    <div className={styles.root} data-test-id="hs-HighlightsSnaps-NoSpansPanel">
      <div className={styles.image}>
        <img src={noSnapsImage} alt="" width={156} height={155} />
      </div>
      <h2 className={styles.title}>{noSessionsText}</h2>
    </div>
  );
};

NoSessionsInfoPanel.propTypes = {
  noSessionsText: PropTypes.string.isRequired,
};

export default NoSessionsInfoPanel;
