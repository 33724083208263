import actionTypes from "../../../actionTypes";
import {
  fetchMyWorkspacesLabelsCall,
  createAndAssignLabelToWorkspace,
  assignAndUnassignLabelToWorkspace,
  deleteLabelCall,
  editLabelCall,
} from "../../../../apiCalls/workspaces";
import { updateAccessError } from "../../../app/actions";
import _ from "lodash";

import { applyAllFilters } from "../list/actions";

export const dismissError = () => {
  return {
    type: actionTypes.MYWORKSPACES_LABELS_DISMISS_ERROR,
  };
};

export const loadLabelsAction = () => (dispatch) => {
  dispatch({
    type: actionTypes.MYWORKSPACES_LABELS_LOAD_PENDING,
  });

  fetchMyWorkspacesLabelsCall()
    .then((response) => {
      if (response.status === 403) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }

      return response.json();
    })
    .then((data) => {
      dispatch({
        type: actionTypes.MYWORKSPACES_LABELS_LOAD_SUCCESS,
        payload: _.sortBy(
          _.map(data, (item) => {
            return { ...item, wsAmount: item.BoardIds.length, deleted: false };
          }),
          (item) => item.Name.toLowerCase()
        ),
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.MYWORKSPACES_LABELS_LOAD_ERROR,
        error,
      });
    });
};

export const createAndAssignLabelToWs =
  ({ labelName, boardId }) =>
  (dispatch) => {
    return createAndAssignLabelToWorkspace({ labelName, boardId }).then(
      (response) => {
        if (response.status === 403) {
          // Not a WS user, show 403 screen
          dispatch(updateAccessError(true));
          return {};
        }

        if (!response.ok) {
          throw Error(response);
        }

        return response.json();
      }
    );
  };

export const assignAndUnassignLabelToWS =
  ({ boardId, assignList, unassignList }) =>
  (dispatch) => {
    return assignAndUnassignLabelToWorkspace({
      boardId,
      assignList,
      unassignList,
    }).then((response) => {
      if (response.status === 403) {
        // Not a WS user, show 403 screen
        dispatch(updateAccessError(true));
        return {};
      }

      if (!response.ok) {
        throw Error(response);
      }

      return response.json();
    });
  };

export const makeLabelAction = (appCall, action, labelId, newTitle = null) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MYWORKSPACES_LABELS_ACTION_PENDING });
    return appCall(labelId, newTitle)
      .then((response) => {
        if (!response.ok) {
          throw Error(response);
        } else {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          }
          return response.text();
        }
      })
      .then((data) => {
        dispatch({
          type: actionTypes.MYWORKSPACES_LABELS_ACTION_SUCCESS,
          action,
          labelId,
          newTitle,
        });
        dispatch(applyAllFilters(false)); // isSilentUpdate is a parameter
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: actionTypes.MYWORKSPACES_LABELS_ACTION_ERROR, error });
      });
  };
};

export const deleteLabelAction = (labelId) =>
  makeLabelAction(deleteLabelCall, "delete", labelId);
export const editLabelAction = (labelId, newTitle) =>
  makeLabelAction(editLabelCall, "edit", labelId, newTitle);

export const clearDeletedLabels = () => {
  return {
    type: actionTypes.MYWORKSPACES_LABELS_CLEAR,
  };
};
