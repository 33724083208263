import { connect } from "react-redux";

// SELECTORS
import { getStudentTilesSizeType } from "../../../../state/highlights/config/selectors";
import { STUDENT_TILES_SIZE_TYPES } from "../../../../state/highlights/config/types.js";

// TYPES
import { EdublogsShape } from "../../Drive/StudentTileDrive/types";

// UTILS
import { formatDate } from "../../Drive/DriveMainContent/utils";

// HOOKS
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

import styles from "../../Drive/StudentTileDriveTable/StudentTileDriveTable.module.scss";

interface Column {
  id: "Posts" | "Comments" | "Last Modified";
  label: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  align?: "right" | "left" | "center";
}

interface Props {
  studentDisplayName: string;
  studentTileSize: string;
  tableData: EdublogsShape[];
  dataTestIdPrefix: string;
  edublogsTypeParams: "posts" | "comments";
}

const StudentTileEdublogsTable: React.FC<Props> = ({
  studentDisplayName,
  studentTileSize,
  tableData,
  dataTestIdPrefix,
  edublogsTypeParams,
}) => {
  const handleClick = (rowData: EdublogsShape) =>
    window.open(rowData.url, "_blank");
  const handleEnter = (
    e: React.KeyboardEvent<HTMLDivElement>,
    rowData: EdublogsShape
  ) => {
    if (e.key === "Enter") {
      window.open(rowData.url, "_blank");
    }
  };

  const columns: readonly Column[] = [
    {
      id: edublogsTypeParams === "posts" ? "Posts" : "Comments",
      label: edublogsTypeParams === "posts" ? "Posts" : "Comments",
      width:
        studentTileSize === STUDENT_TILES_SIZE_TYPES.REGULAR ? "60%" : "70%",
      align: "left",
    },
    {
      id: "Last Modified",
      label: "Last Modified",
      width:
        studentTileSize === STUDENT_TILES_SIZE_TYPES.REGULAR ? "40%" : "30%",
      align: "left",
    },
  ];

  const sortedEdublogsData = tableData.sort((a, b) => {
    const dateA = new Date(a.lastupdated_iso);
    const dateB = new Date(b.lastupdated_iso);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <>
      <div className={styles.tableContainer}>
        <table tabIndex={0} className={styles.table} cellSpacing={0}>
          <caption className={styles.caption}>
            {studentDisplayName}'s drive documents
          </caption>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.id}
                  align={column.align}
                  style={{
                    width: column.width,
                    maxWidth: column.maxWidth,
                  }}
                  className={styles.tableHeader}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedEdublogsData?.map((row: EdublogsShape, index) => (
              <tr
                onClick={() => handleClick(row)}
                onKeyDown={(e) => handleEnter(e, row)}
                data-test-id={`${dataTestIdPrefix}-Document-Link`}
                tabIndex={0}
                key={index}
                className={styles.tableRow}
                aria-label={`Row ${index + 1} of ${tableData.length}. ${
                  columns[0].label
                }: ${row.title}, ${columns[1].label}: ${
                  (row.lastupdated, row.author)
                }`}
              >
                <td
                  className={styles.tableCell}
                  style={{
                    width: columns[0].width,
                    maxWidth: columns[0].maxWidth,
                  }}
                >
                  <span
                    className={styles.firstColumnContainer}
                    data-test-id={`${dataTestIdPrefix}-Title`}
                  >
                    {edublogsTypeParams === "posts" && (
                      <HapReactIcon
                        svg="edublogs-icon"
                        className={styles.docIcon}
                      />
                    )}
                    {row.title}
                  </span>
                </td>
                <td
                  className={styles.tableCell}
                  style={{
                    width: columns[1].width,
                    maxWidth: columns[1].maxWidth,
                  }}
                  data-test-id={`${dataTestIdPrefix}-LastModified`}
                >
                  {row.lastupdated_iso
                    ? `${formatDate(row.lastupdated_iso)}, ${row.author}`
                    : `${row.lastupdated}, ${row.author}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect((state) => ({
  studentTileSize: getStudentTilesSizeType(state),
}))(StudentTileEdublogsTable);
