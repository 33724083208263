import { useEffect, useRef } from "react";
import _ from "lodash";
import { REACT_PERFORMANCE_STATS_FREQUENCY } from "../state/highlights/students/types";

const usePerformanceStatsSendHook = ({
  componentName,
  sendReactRenderStats,
  isMonitorPerformanceFF,
}) => {
  const renderAmountRef = useRef(0);
  const renderDurationRef = useRef(0);
  const statTimerRef = useRef(null);

  useEffect(() => {
    if (isMonitorPerformanceFF) {
      const sendStats = () => {
        sendReactRenderStats({
          componentName,
          renderAmount: renderAmountRef.current,
          averageRenderDuration: _.round(
            renderAmountRef.current
              ? renderDurationRef.current / renderAmountRef.current
              : 0,
            2
          ),
        });

        // reset
        renderAmountRef.current = 0;
        renderDurationRef.current = 0;
        statTimerRef.current = _.delay(
          sendStats,
          REACT_PERFORMANCE_STATS_FREQUENCY
        );
      };

      statTimerRef.current = _.delay(
        sendStats,
        REACT_PERFORMANCE_STATS_FREQUENCY
      );
    }

    return () => {
      if (statTimerRef.current) {
        window.clearTimeout(statTimerRef.current);
      }
    };
  }, [sendReactRenderStats, componentName, isMonitorPerformanceFF]);

  const onRender = (id, phase, actualDuration) => {
    renderAmountRef.current++;
    renderDurationRef.current = renderDurationRef.current + actualDuration;
  };

  return onRender;
};

export default usePerformanceStatsSendHook;
