import React, { useState } from "react";
import classNames from "classnames";
import styles from "./SegmentedControl.module.scss";
import { SegmentedControlContext } from "./SegmentedControlContext";

type SegmentedControlProps = {
  children: React.ReactNode[];
  defaultSelectedIndex?: number;
  iconOnlySegments?: boolean;
  className?: string;
  dataTestIdPrefix: string;
};

export const SegmentedControl = ({
  children: segments,
  defaultSelectedIndex,
  iconOnlySegments,
  className,
  dataTestIdPrefix,
}: SegmentedControlProps) => {
  const [selectedSegment, setSelectedSegment] = useState<number | undefined>(
    defaultSelectedIndex
  );

  return (
    <ul
      role="radiogroup"
      className={classNames(styles.segmentContainer, className, {
        [styles.iconSegments]: iconOnlySegments,
      })}
      data-test-id={`${dataTestIdPrefix}-segmented-control`}
    >
      {segments.map((segment, index) => {
        return (
          <SegmentedControlContext.Provider
            key={index}
            value={{
              index,
              isSelectedSegment: selectedSegment === index,
              onSegmentSelect: () => setSelectedSegment(index),
              dataTestIdPrefix,
            }}
          >
            {segment}
          </SegmentedControlContext.Provider>
        );
      })}
    </ul>
  );
};
