import _ from "lodash";
import { APP_PAGES } from "../router/types";

export const getGoogleAnalyticsTrackingIdByPath = (path) => {
  if (
    _.startsWith(path, APP_PAGES.DASHBOARD_MANAGE_CLASSES.path) ||
    _.startsWith(path, APP_PAGES.LOGIN.path)
  ) {
    return process.env.REACT_APP_GA_TRACKING_ID_TD;
  }

  if (
    _.startsWith(path, APP_PAGES.HIGHLIGHTS_BASE.path) ||
    _.startsWith(path, APP_PAGES.CLICK_TO_SHARE.path)
  ) {
    return process.env.REACT_APP_GA_TRACKING_ID_HL;
  }

  return process.env.REACT_APP_GA_TRACKING_ID_WS;
};
