import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tippy from "@tippy.js/react";
import { showSendMessageModal } from "../../../../state/highlights/sendMessage/actions";
import styles from "./StudentTileSendMessage.module.scss";
import MemorisedFocusButton from "../../MemorisedFocusButton/MemorisedFocusButton";
import { AnnounceIcon } from "@hapara/ui/src/icons/AnnounceIcon";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

const StudentTileSendMessage = ({
  isOnline,
  isPaused,
  isOutsideSchoolNetwork,
  isOutsideSchoolHours,
  studentId,
  studentEmail,
  studentDisplayName,
  showSendMessageModal,
  hasGBETileUpdateFlag = false,
}) => {
  const intl = useIntl();
  if (!isOnline || isOutsideSchoolNetwork || isOutsideSchoolHours || isPaused) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tippy
        content={
          hasGBETileUpdateFlag
            ? intl.formatMessage({
                defaultMessage: "Send announcement",
                id: "NQRIYb",
              })
            : intl.formatMessage({
                defaultMessage: "Send message",
                id: "Xx0WZV",
              })
        }
        theme="hsuite"
      >
        <MemorisedFocusButton
          icon={hasGBETileUpdateFlag ? undefined : "message-text"}
          iconComponent={hasGBETileUpdateFlag ? <AnnounceIcon /> : null}
          size={BUTTON_SIZES.SMALL}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id="hl-StudentTile-SendMessage-Trigger"
          aria-label={
            hasGBETileUpdateFlag
              ? intl.formatMessage({
                  defaultMessage: "Send announcement",
                  id: "NQRIYb",
                })
              : intl.formatMessage({
                  defaultMessage: "Send message",
                  id: "Xx0WZV",
                })
          }
          className={styles.triggerButton}
          onClick={() =>
            showSendMessageModal({
              selectedStudent: {
                Id: studentId,
                Email: studentEmail,
                FullName: studentDisplayName,
              },
            })
          }
        />
      </Tippy>
    </div>
  );
};

StudentTileSendMessage.propTypes = {
  studentId: PropTypes.string.isRequired,
  studentEmail: PropTypes.string.isRequired,
  studentDisplayName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  isOutsideSchoolNetwork: PropTypes.bool.isRequired,
  isOutsideSchoolHours: PropTypes.bool.isRequired,
  showSendMessageModal: PropTypes.func.isRequired,
  hasGBETileUpdateFlag: PropTypes.bool,
};

export default StudentTileSendMessage;
