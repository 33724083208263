import classnames from "classnames";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import styles from "./PageRequests.module.scss";

interface Props {
  studentFullName: string;
  isApproved?: boolean;
  handleDismiss: () => void;
  dataId?: string;
}

const DropdownSuccessItem: React.FC<Props> = ({
  studentFullName,
  isApproved,
  handleDismiss,
  dataId,
}) => {
  const message = isApproved
    ? "You approved this site for 24 hours. A message was sent to the student."
    : "You denied access to this site.";

  return (
    <li className={styles.item}>
      <div className={styles.name}>
        <span>{studentFullName}</span>
      </div>
      <div>
        <div
          className={classnames(
            styles.successAlert,
            isApproved ? styles.approvedVariant : styles.declinedVariant
          )}
          role="alert"
        >
          <span>{message}</span>
        </div>
        <Button
          isFullWidth
          size={BUTTON_SIZES.LARGE}
          type={BUTTON_TYPES.TERTIARY}
          data-test-id={`${dataId}-ok-button`}
          label="OK"
          className={styles.dismissButton}
          onClick={handleDismiss}
        />
      </div>
    </li>
  );
};

export default DropdownSuccessItem;
