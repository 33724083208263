/** Validates if date is in correct `YYYY-MM-DD` format and is a valid date. */
export const isValidDate = (date: string): boolean => {
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  if (!dateRegex.test(date)) {
    return false;
  }

  // Check if date is actually valid
  const [year, month, day] = date.split("-").map(Number);
  const dateObj = new Date(year, month - 1, day);
  return (
    dateObj.getFullYear() === year &&
    dateObj.getMonth() === month - 1 &&
    dateObj.getDate() === day
  );
};
