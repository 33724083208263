import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./ResourceTypesGroupList.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import { getFilters } from "../../../../state/shared/selectors";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../state/shared/types";
import Pill, {
  PILL_SIZES,
  PILL_COLOURS,
  PILL_TYPES,
} from "@hapara/ui/src/atomic/Pill/Pill";

const pillColorsByGroup = {
  ACT: PILL_COLOURS.LIGHT_BLUE,
  RD: PILL_COLOURS.GREEN,
  TR: PILL_COLOURS.MAGENTA,
  OT: PILL_COLOURS.DARK_BLUE,
};

const ResourceTypesGroupList = ({ resourceTypes, itemResourceTypes }) => {
  const groups = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(
          _.map(itemResourceTypes, (key) =>
            _.find(resourceTypes, (item) => item.Key === key)
          ),
          "GroupKey"
        ),
        "Group"
      ),
    [itemResourceTypes, resourceTypes]
  );

  if (!itemResourceTypes || !itemResourceTypes.length) return null;

  return (
    <div className={styles.root}>
      {_.map(groups, (item, index) => {
        const groupName = _.get(item, "Group", "");
        const groupKey = _.get(item, "GroupKey", "");
        const pillColor = _.get(pillColorsByGroup, groupKey, PILL_COLOURS.NAVY);
        return (
          <Pill
            key={index}
            colour={pillColor}
            size={PILL_SIZES.XSMALL}
            type={PILL_TYPES.SQUARE}
          >
            {groupName}
          </Pill>
        );
      })}
    </div>
  );
};

ResourceTypesGroupList.propTypes = {
  itemResourceTypes: PropTypes.arrayOf(PropTypes.string),
  // connected
  resourceTypes: PropTypes.arrayOf(filterItemType),
};

export default connect((state) => ({
  resourceTypes: getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value)(state),
}))(ResourceTypesGroupList);
