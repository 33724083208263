import { decodeJwt } from "./decodeJwt";
import { AuthToken } from "../types/AuthToken";

/** @throws {Error} Throws an error if the JWT is invalid. */
export const decodeAuthToken = (
  token: string
): { header: unknown; payload: AuthToken } => {
  const decodedJwt = decodeJwt<AuthToken>(token);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { header, payload } = decodedJwt!;

  return {
    header,
    payload,
  };
};
