import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./ViewScreenshotModalSnapshot.module.scss";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import { NO_CURRENT_SCREEN_AVAILABLE } from "../../../../state/highlights/screenshotWebsocket/types";
import sunnyHills from "@hapara/assets/src/images/vector-image.svg";
import {
  screenshotTakenTimeHelper,
  downloadScreenshotWithAddedMetaData,
} from "../../../../utils";
import { getViewScreenshotModalInfo } from "../../../../state/highlights/viewScreenshot/selectors";
import { VIEW_SCREENSHOT_MODAL_INFO_TYPE } from "../../../../state/highlights/viewScreenshot/types";
import { getClassId } from "../../../../state/shared/selectors";
import { FormattedMessage, useIntl } from "react-intl";

const TIME_FORMAT_TYPES = {
  MODAL: "h:mm:ss a, MMM DD",
  DOWNLOAD: "h_mm_ss a, MMMM Do YYYY",
};

const ViewScreenshotModalSnapshot = ({ modalInfo, classId }) => {
  const dataTestPrefix = "hl-CurrentScreen";
  const {
    studentDisplayName,
    studentEmail,
    activeTabUrl,
    activeTabIcon,
    snapshot,
  } = modalInfo;
  const { image = null, stamp = null } = snapshot;
  const [isSnapshot, setIsSnapshot] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    setIsSnapshot(!!image && !(image === NO_CURRENT_SCREEN_AVAILABLE));
  }, [image]);

  return (
    <>
      {isSnapshot ? (
        <div className={styles.snapshot}>
          <img
            src={image}
            alt={intl.formatMessage({
              defaultMessage: "screenshot of website",
              id: "AEpOJU",
            })}
            className={styles.image}
          />
        </div>
      ) : (
        <div className={styles.loadingSnapshot}>
          <img src={sunnyHills} alt="" />
          <div className={styles.text}>
            <FormattedMessage
              defaultMessage="Trouble loading screenshot"
              id="9YLsN0"
            />
          </div>
        </div>
      )}
      <div className={styles.infoPanelContainer}>
        <div className={styles.infoPanel}>
          <div className={styles.linkContainer}>
            <div className={styles.urlText}>
              <FormattedMessage defaultMessage="URL:" id="zbjnEr" />
            </div>
            <ExternalLink
              url={activeTabUrl}
              icon={activeTabIcon}
              studentEmail={studentEmail}
              classId={classId}
              dataTestId={dataTestPrefix + "hl-CurrentScreen-Modal-Link"}
              className={styles.externalLink}
            />
          </div>
          <div className={styles.dateContainer}>
            <div className={styles.dateText}>
              <FormattedMessage
                defaultMessage="Screenshot taken:"
                id="LM1tkq"
              />
            </div>
            <div>{
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              `${screenshotTakenTimeHelper(stamp, TIME_FORMAT_TYPES.MODAL)}`
            }</div>
          </div>
        </div>
        <div className={styles.btn}>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Download",
              id: "5q3qC0",
            })}
            onAction={() => {
              downloadScreenshotWithAddedMetaData(
                image,
                studentEmail,
                studentDisplayName,
                moment(stamp).format(TIME_FORMAT_TYPES.MODAL),
                moment(stamp).format(TIME_FORMAT_TYPES.DOWNLOAD),
                activeTabUrl
              );
            }}
            isDisabled={!isSnapshot}
            type={BUTTON_TYPES.PRIMARY}
            data-test-id={`${dataTestPrefix}-Modal-Button-DownloadScreen`}
          />
        </div>
      </div>
    </>
  );
};

ViewScreenshotModalSnapshot.propTypes = {
  modalInfo: VIEW_SCREENSHOT_MODAL_INFO_TYPE,
  classId: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    modalInfo: getViewScreenshotModalInfo(state),
    classId: getClassId(state),
  }),
  (dispatch) => ({})
)(ViewScreenshotModalSnapshot);
