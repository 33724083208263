import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import styles from "./GradingToast.module.scss";
import Toast, { TOAST_TYPES } from "@hapara/ui/src/deprecated/Toast/Toast";
import { FormattedMessage, useIntl } from "react-intl";
import {
  isGradingSuccess,
  getAssigneeCausedAction,
} from "../../../../../state/workspace/grading/selectors";
import { hideGradingSuccess } from "../../../../../state/workspace/grading/actions";

const GradingToast = ({
  assigneeCausedAction,
  isGradingSuccess,
  hideGradingSuccess,
}) => {
  const getToastText = () => {
    switch (assigneeCausedAction.mode) {
      case "submitted":
        return (
          <div data-test-id="ws-Grading-Toast-Text">
            <strong>
              <FormattedMessage
                id="FcqUOb"
                defaultMessage="Work is submitted for {assigneeName}."
                values={{ assigneeName: assigneeCausedAction.assigneeName }}
              />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            </strong>{" "}
            <FormattedMessage
              id="i72wJW"
              defaultMessage={`This is now listed as "submitted" work.`}
            />
          </div>
        );

      case "final":
      case "edit":
        return (
          <div data-test-id="ws-Grading-Toast-Text">
            <strong>
              <FormattedMessage
                id="F0sH2c"
                defaultMessage="Work has returned to {assigneeName}."
                values={{ assigneeName: assigneeCausedAction.assigneeName }}
              />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            </strong>{" "}
            {assigneeCausedAction.mode === "final" ? (
              <FormattedMessage
                id="AGi7Nc"
                defaultMessage={`This is now listed as "assessed" work.`}
              />
            ) : (
              <FormattedMessage
                id="gcp5fe"
                defaultMessage={`This is now listed as "started" work.`}
              />
            )}
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (isGradingSuccess) {
      _.delay(hideGradingSuccess, 5000);
    }
  }, [isGradingSuccess, hideGradingSuccess]);

  return (
    <div className={styles.root} aria-live="polite" aria-atomic="true">
      {isGradingSuccess && (
        <Toast
          icon="circle-check-fill"
          onClose={hideGradingSuccess}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          type={TOAST_TYPES.SUCCESS}
        >
          {getToastText()}
        </Toast>
      )}
    </div>
  );
};

GradingToast.propTypes = {
  assigneeCausedAction: PropTypes.shape({
    assigneeName: PropTypes.string,
    mode: PropTypes.string,
  }),
  isGradingSuccess: PropTypes.bool.isRequired,
  hideGradingSuccess: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    isGradingSuccess: isGradingSuccess(state),
    assigneeCausedAction: getAssigneeCausedAction(state),
  }),
  (dispatch) => ({
    hideGradingSuccess: () => dispatch(hideGradingSuccess()),
  })
)(GradingToast);
