import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AppLoadingPage from "../AppLoadingPage/AppLoadingPage";
import { loadResourceAuthToken } from "../../../state/library/myResources/actions";
import _ from "lodash";
import { RESOURCE_AUTH_ERRORS } from "../../../state/library/myResources/types";
import { RESOURCE_AUTH_URL_KEYS } from "../../../state/library/myResources/navigation";
import { getResourceRedirectUrlQuery } from "../../../state/library/myResources/selectors";
import NoAccessPage from "../NoAccessPage/NoAccessPage";

export const ResourceAuthPage = ({ redirectUrl, loadResourceAuthToken }) => {
  const { resourceId } = useParams();

  const loadTokenRef = useRef(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isNoAccess, setIsNoAccess] = useState(false);
  const [isTryAgainVisible, setIsTryAgainVisible] = useState(false);

  useEffect(() => {
    if (!redirectUrl) {
      // if redirect url is missing, show generic error
      setErrorMessage(RESOURCE_AUTH_ERRORS.SYSTEM);
    } else if (!loadTokenRef.current && resourceId) {
      // try to fetch resource access token
      loadResourceAuthToken(resourceId)
        .then((response) => {
          const jwt = _.get(response, "data");

          if (jwt) {
            // if success, do redirect
            window.location.href = `${redirectUrl}?${RESOURCE_AUTH_URL_KEYS.token.key}=${jwt}`;
          } else {
            setErrorMessage(RESOURCE_AUTH_ERRORS.FORBIDDEN);
          }
        })
        .catch((error) => {
          const errorStatus = _.get(error, "response.status");
          const errorApiUrl = _.get(error, "response.config.url", "") || "";

          if (errorStatus === 403) {
            if (
              errorApiUrl &&
              errorApiUrl.indexOf("/library/docs/auth/") !== -1
            ) {
              // the error came from resource access token api
              // the resource is no longer available
              // show resource access error
              setErrorMessage(RESOURCE_AUTH_ERRORS.FORBIDDEN);
            } else {
              // the error came from general access token api
              // the account has no access
              // show No Access Page
              setIsNoAccess(true);
            }
          } else {
            // show generic error
            setErrorMessage(RESOURCE_AUTH_ERRORS.SYSTEM);
            setIsTryAgainVisible(true);
          }
        })
        .finally(() => {
          loadTokenRef.current = true;
        });
    }
  }, [loadResourceAuthToken, resourceId, redirectUrl]);

  if (isNoAccess) return <NoAccessPage />;

  return (
    <AppLoadingPage
      isError={!!errorMessage}
      isTryAgainVisible={isTryAgainVisible}
      errorContent={errorMessage}
      loadingContent="Loading"
    />
  );
};

ResourceAuthPage.propTypes = {
  redirectUrl: PropTypes.string,
  loadResourceAuthToken: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    redirectUrl: getResourceRedirectUrlQuery(state),
  }),
  (dispatch) => ({
    loadResourceAuthToken: (resourceId) =>
      dispatch(loadResourceAuthToken({ resourceId })),
  })
)(ResourceAuthPage);
