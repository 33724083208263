// @ts-nocheck
import React, { ReactNode, useRef } from "react";
import MuiPopover, { PopoverOrigin } from "@mui/material/Popover";
import styles from "./PopoverDeprecated.module.scss";

const POPOVER_TOP = "top";
const POPOVER_CENTER = "center";
const POPOVER_BOTTOM = "bottom";
const POPOVER_LEFT = "left";
const POPOVER_RIGHT = "right";
interface PopoverProps {
  children: ReactNode;
  content: ReactNode;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  open: boolean;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

const PopoverDeprecated = ({
  children,
  content = null,
  anchorOrigin = { vertical: POPOVER_TOP, horizontal: POPOVER_LEFT },
  transformOrigin = { vertical: POPOVER_BOTTOM, horizontal: POPOVER_LEFT },
  open = false,
  onClose,
}: PopoverProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <MuiPopover
        open={open}
        onClose={onClose}
        anchorEl={ref.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className={styles.popoverContent}>{content}</div>
      </MuiPopover>
      {children}
    </div>
  );
};
/** @deprecated */
export default PopoverDeprecated;
export {
  POPOVER_LEFT,
  POPOVER_RIGHT,
  POPOVER_CENTER,
  POPOVER_TOP,
  POPOVER_BOTTOM,
};
