import React from "react";
import { useSelector } from "react-redux";
import styles from "./GuidedBrowsingAudiencePicker.module.scss";
import { useIntl, FormattedMessage } from "react-intl";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import { GroupPicker, StudentPicker } from "./Pickers";
import { Heading } from "@hapara/ui/src/atomic/Heading/Heading";
import { getGroupStatusInfo } from "src/state/shared/userGroups/selectors";
import { Alert } from "@hapara/ui/src/atomic/Alert";

export interface Group {
  URN: string;
  name: string;
  color: string;
  participants: string[];
  checked: boolean;
}

export interface Student {
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  instances: {
    [key: string]: {
      isOnline: boolean;
      lock: {
        locking: {
          active: boolean;
        };
      };
    };
  };
  currentInstance: string;
}

interface GroupStatus {
  areAllOffline: boolean;
  message: string | null;
}

export interface GuidedBrowsingAudiencePickerProps {
  userGroups: Group[];
  students: Student[];
  groupUrns: string[];
  studentIds: string[];
  displayRestrictedOptions?: boolean;
  getGroupStatusInfo: (group: Group) => GroupStatus;
  onGroupsChange: (groupUrns: string[]) => void;
  onStudentsChange: (studentIds: string[]) => void;
  onRecipientTypeChange: (
    recipientType: "class" | "groups" | "students"
  ) => void;
  recipientType: "class" | "groups" | "students";
  areAnyStudentsInSession?: boolean;
}

export const GuidedBrowsingAudiencePickerPresentation = ({
  userGroups,
  groupUrns,
  students,
  studentIds,
  getGroupStatusInfo,
  onGroupsChange,
  onStudentsChange,
  onRecipientTypeChange,
  recipientType,
  displayRestrictedOptions,
  areAnyStudentsInSession,
}: GuidedBrowsingAudiencePickerProps) => {
  const intl = useIntl();
  return (
    <GuidedBrowsingSection>
      <div className={styles.header}>
        <div className={styles.headingContainer}>
          <Heading level={2} styledAs="h4" removeBottomMargin removeTopMargin>
            <FormattedMessage defaultMessage="Apply to" id="PR3NBG" />
          </Heading>
        </div>
        <div className={styles.radioContainer}>
          <Radio
            dataTestIdPrefix="guided-browsing-audience-picker-class"
            name="class"
            value="class"
            checked={recipientType === "class"}
            onChange={() => onRecipientTypeChange("class")}
            label={intl.formatMessage({
              defaultMessage: "Class",
              id: "nraEHM",
            })}
          />
          <Radio
            dataTestIdPrefix="guided-browsing-audience-picker-groups"
            name="groups"
            value="groups"
            checked={recipientType === "groups"}
            onChange={() => onRecipientTypeChange("groups")}
            label={intl.formatMessage({
              defaultMessage: "Groups",
              id: "hzmswI",
            })}
          />
          <Radio
            dataTestIdPrefix="guided-browsing-audience-picker-students"
            name="students"
            value="students"
            checked={recipientType === "students"}
            onChange={() => onRecipientTypeChange("students")}
            label={intl.formatMessage({
              defaultMessage: "Students",
              id: "uhwf+D",
            })}
          />
        </div>
      </div>
      {areAnyStudentsInSession && (
        <Alert type="warning">
          <FormattedMessage
            defaultMessage="Some students are already in an active Guided Session. A new session will override the active session."
            id="HtpT8t"
          />
        </Alert>
      )}
      {!displayRestrictedOptions && recipientType === "groups" && (
        <GroupPicker
          userGroups={userGroups}
          groupUrns={groupUrns}
          onGroupUrnsChange={onGroupsChange}
          getGroupStatusInfo={getGroupStatusInfo}
        />
      )}
      {!displayRestrictedOptions && recipientType === "students" && (
        <StudentPicker
          students={students}
          studentIds={studentIds}
          onStudentIdsChange={onStudentsChange}
        />
      )}
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingAudiencePicker = ({
  onGroupsChange,
  onStudentsChange,
  onRecipientTypeChange,
  recipientType,
  students,
  userGroups,
  groupUrns,
  studentIds,
  displayRestrictedOptions,
  areAnyStudentsInSession,
}: Omit<GuidedBrowsingAudiencePickerProps, "getGroupStatusInfo">) => {
  return (
    <GuidedBrowsingAudiencePickerPresentation
      displayRestrictedOptions={displayRestrictedOptions}
      onGroupsChange={onGroupsChange}
      onStudentsChange={onStudentsChange}
      onRecipientTypeChange={onRecipientTypeChange}
      recipientType={recipientType}
      userGroups={userGroups}
      students={students}
      studentIds={studentIds}
      groupUrns={groupUrns}
      areAnyStudentsInSession={areAnyStudentsInSession}
      getGroupStatusInfo={(group: Group) =>
        useSelector(getGroupStatusInfo(group))
      }
    />
  );
};
