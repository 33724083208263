import PropTypes from "prop-types";

export const LIBRARY_PAGE_SIZE = 20;

export const libraryItemType = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Description: PropTypes.string,
  Link: PropTypes.string,
  Thumbnail: PropTypes.string,
});

export const LIBRARY_CATEGORIES = {
  ALL: {
    type: "all",
    title: "All",
    icon: "circle-stacked-checkmark-gray",
    iconActive: "circle-stacked-checkmark",
    urlKey: "all",
  },
  UNCATEGORIZED: {
    type: "uncategorized",
    title: "Uncategorized",
    icon: "circle-stacked-ribbon-gray",
    iconActive: "circle-stacked-ribbon",
    urlKey: "uncategorized",
  },
  COLLECTIONS: {
    type: "collections",
    title: "Collections",
    icon: "circle-stacked-ribbon-filled-gray",
    iconActive: "circle-stacked-ribbon-filled",
  },
};

export const USER_TYPES = {
  TEACHER_PLIB_ONLY: "TEACHER_PLIB_ONLY",
  TEACHER_DB: "TEACHER_DB",
  DRM_PLIB_ONLY: "DRM_PLIB_ONLY",
  DRM_DB: "DRM_DB",
};
