import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import styles from "./MyResourcesFilters.module.scss";
import { isStarredFiltered } from "../../../../state/library/myResources/selectors";
import { classItemType } from "../../../../state/library/config/types";
import { isMyResourcesLeftPanelOpen } from "../../../../state/library/myResources/selectors";
import { getClassListSortedByName } from "../../../../state/library/config/selectors";
import {
  areMyClassesLoaded,
  getClassId,
  isMyClassesLoadingError,
} from "../../../../state/shared/selectors";
import SideNavItem, { SideNavDivider } from "../../SideNavItem/SideNavItem";
import { APP_PAGES } from "../../../../state/router/types";
import { SHARED_URL_KEYS } from "../../../../state/shared/navigation-keys";
import { getMyClassesAction } from "../../../../state/shared/actions";

export const MyResourcesFilters = ({
  isLeftSidePanelOpen,
  classList,
  isClassListLoaded,
  selectedClassId,
  isStarredActive,
  getMyClassesAction,
  isMyClassesLoadingError,
}) => {
  const [isClassListLoadError, setIsClassListLoadError] = useState(false);

  useEffect(() => {
    if (isMyClassesLoadingError) {
      setIsClassListLoadError(true);
    }
  }, [isMyClassesLoadingError]);

  const isAllClassesActive = !selectedClassId && !isStarredActive;

  const subItemsList = [];
  if (classList.length > 0) {
    subItemsList.push({
      to: `${APP_PAGES.MY_RESOURCES_SEARCH.path}`,
      title: "All Classes",
      icon: "profile-group-filled",
      isActive: isAllClassesActive,
      "data-test-id":
        "lb-MyResources-LeftSidePanel-ClassFilter-Link-AllClasses",
      "aria-label": `All Classes${isAllClassesActive ? ", Selected" : ""}`,
    });
    _.forEach(classList, (item) => {
      const isSelectedClass = selectedClassId === item.ID;
      subItemsList.push({
        to: `${APP_PAGES.MY_RESOURCES_SEARCH.path}?${SHARED_URL_KEYS.classId.key}=${item.ID}`,
        title: item.Name,
        icon: "profile-group-filled",
        isActive: isSelectedClass,
        "data-test-id": "lb-MyResources-LeftSidePanel-ClassFilter-Link-Class",
        "aria-label": `${item.Name}${isSelectedClass ? ", Selected" : ""}`,
      });
    });
  }

  return (
    <div
      className={classnames(styles.root, {
        [styles.open]: isLeftSidePanelOpen,
      })}
    >
      <div className={styles.scrollableArea}>
        <div className={styles.content}>
          <SideNavItem
            to={APP_PAGES.MY_RESOURCES_STARRED.path}
            icon="circle-star"
            title="Starred"
            color="green"
            isActive={isStarredActive}
            data-test-id="lb-MyResources-LeftSidePanel-Starred"
          />
          <SideNavDivider />
          <SideNavItem
            title="My Classes"
            icon={
              !isStarredActive
                ? "circle-blackboard-green"
                : "circle-blackboard-gray"
            }
            color="green"
            data-test-id="lb-MyResources-LeftSidePanel-ClassesArea"
            type="with-sub-items"
            emptyStateText="No classes found."
            showAllText="Show all classes"
            errorStateText="Trouble loading classes"
            isLoading={!isClassListLoaded && !isClassListLoadError}
            isError={isClassListLoadError}
            onTryAgain={() => {
              setIsClassListLoadError(false);
              getMyClassesAction();
            }}
            subItemsList={subItemsList}
          />
        </div>
      </div>
    </div>
  );
};

MyResourcesFilters.propTypes = {
  isLeftSidePanelOpen: PropTypes.bool.isRequired,
  classList: PropTypes.arrayOf(classItemType),
  isClassListLoaded: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  isStarredActive: PropTypes.bool.isRequired,
  getMyClassesAction: PropTypes.func.isRequired,
  isMyClassesLoadingError: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isLeftSidePanelOpen: isMyResourcesLeftPanelOpen(state),
    classList: getClassListSortedByName(state),
    isClassListLoaded: areMyClassesLoaded(state),
    selectedClassId: getClassId(state),
    isStarredActive: isStarredFiltered(state),
    isMyClassesLoadingError: isMyClassesLoadingError(state),
  }),
  (dispatch) => ({
    getMyClassesAction: () => dispatch(getMyClassesAction()),
  })
)(MyResourcesFilters);
