import React from "react";
import styles from "./GradingTitleLoading.module.scss";

export const GradingTitleLoading = () => {
  return (
    <div className={styles.root}>
      <div className={styles.thumbnail} />
      <div className={styles.title}>
        <div className={styles.longItem} />
        <div className={styles.shortItem} />
      </div>
      <div className={styles.dropdown} />
    </div>
  );
};

export default GradingTitleLoading;
