import React from "react";
import HapReactIcon from "../../icon/hapReactIcon";
import styles from "./PasswordInputVisibility.module.scss";

type PasswordInputVisibilityProps = {
  isMasked: boolean;
  dataTestIdPrefix?: string;
  onClick(): void;
};

const maskedIcon = "eye";
const unMaskedIcon = "eye-closed";

export const PasswordInputVisibility = ({
  isMasked,
  dataTestIdPrefix,
  onClick,
}: PasswordInputVisibilityProps) => (
  <button
    className={styles.rootButton}
    data-test-id={`${dataTestIdPrefix}-visibility-button`}
    onClick={onClick}
    aria-label={isMasked ? "show password" : "hide password"}
    tabIndex={0}
  >
    <HapReactIcon
      svg={isMasked ? maskedIcon : unMaskedIcon}
      className={styles.icon}
    />
  </button>
);
