import React, { useState, useRef } from "react";
import styles from "./RecentLinksDropdown.module.scss";
import { Button } from "@hapara/ui/src/atomic/Button";
import { Popover } from "@hapara/ui/src/atomic/Popover";
import { useIntl } from "react-intl";
import { useKeyPress } from "@hapara/ui/src/hooks/useKeyPress";
import { Tooltip } from "@hapara/ui/src/components/Tooltip";

type RecentLinksDropdownProps = {
  isAtMaxLimit?: boolean;
  recentSiteList?: string[];
  onAddRecentLink: (link: string) => void;
};

export const RecentLinksDropdown = ({
  isAtMaxLimit,
  recentSiteList,
  onAddRecentLink,
}: RecentLinksDropdownProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useKeyPress("Escape", () => setIsOpen(false));

  const dropdownItems = recentSiteList?.map((link, index) => {
    return (
      <Button
        key={index}
        type="tertiary"
        size="small"
        label={link}
        className={styles.dropdownItem}
        dataTestId="dropdown-item-recent-link"
        isFullWidth
        onAction={() => {
          onAddRecentLink(link);
          setIsOpen(false);
        }}
      />
    );
  });

  const dropdownMessage = (
    <div className={styles.dropdownMessage}>
      {intl.formatMessage({
        defaultMessage: "No links available",
        id: "jPIghd",
      })}
    </div>
  );

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      anchor={
        <Tooltip
          content={intl.formatMessage({
            defaultMessage: "Access your 20 most recent links for 7 days.",
            id: "WTvAZP",
          })}
          anchor={
            <Button
              type="outlined"
              size="large"
              outlineType="solid"
              label={intl.formatMessage({
                defaultMessage: "Recent links",
                id: "qQdf+q",
              })}
              className={styles.triggerButton}
              rightIcon="arrow-carvet-down"
              onClick={() => setIsOpen(!isOpen)}
              ref={triggerRef}
              isDisabled={isAtMaxLimit}
              dataTestId="session-recent-links-button"
            />
          }
        />
      }
    >
      <div className={styles.popover}>
        {recentSiteList && recentSiteList.length > 0
          ? dropdownItems
          : dropdownMessage}
      </div>
    </Popover>
  );
};
