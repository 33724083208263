import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ModalReportProblem.module.scss";
import { connect } from "react-redux";
import _ from "lodash";
import { sendEvent } from "../../../../state/app/actions";
import { Textarea } from "@hapara/ui/src/atomic/Textarea";
import Button, { BUTTON_TYPES } from "@hapara/ui/src/atomic/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";

const descriptionId = "highlights-report-problem";

const ModalReportProblem = ({
  studentId,
  dataTestPrefix,
  onReportIssue,
  onCancel,
  sendEvent,
}) => {
  const [message, setMessage] = useState("");
  const intl = useIntl();
  const handleReportIssue = () => {
    sendEvent({ name: "reportIssue", channel: studentId, message: message });
    onReportIssue();
  };

  return (
    <>
      <div className={styles.info}>
        <p>
          <FormattedMessage
            defaultMessage="Please help us resolve your problem more effectively by sending us the details of what you are experiencing."
            id="WPTTFZ"
          />
        </p>
        <label htmlFor={descriptionId} className={styles.description}>
          <FormattedMessage
            defaultMessage="<span>Describe the problem</span> (include the date and time)"
            id="LBn9+a"
            values={{
              span: (chunks) => <span className={styles.bold}>{chunks}</span>,
            }}
          />
        </label>
        <div className={styles.textForm}>
          <Textarea
            name={descriptionId}
            id={descriptionId}
            placeholder={intl.formatMessage({
              defaultMessage: "Type here",
              id: "+/goVX",
            })}
            data-test-id={`${dataTestPrefix}-TextareaField`}
            value={message}
            onChange={(e) => setMessage(_.get(e, "target.value", ""))}
            className={styles.textInput}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsInner}>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onClick={onCancel}
            data-test-id={`${dataTestPrefix}-Button-Cancel`}
            type={BUTTON_TYPES.SECONDARY}
            className={styles.cancelButton}
          />
          <Button
            label={intl.formatMessage({
              defaultMessage: "Report issue",
              id: "7Pgy+/",
            })}
            onClick={handleReportIssue}
            data-test-id={`${dataTestPrefix}-Button-ReportIssue`}
            className={styles.reportIssueButton}
            isDisabled={_.isEmpty(message)}
          />
        </div>
      </div>
    </>
  );
};

ModalReportProblem.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onReportIssue: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  sendEvent: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    sendEvent: (message) => dispatch(sendEvent(message)),
  })
)(ModalReportProblem);
