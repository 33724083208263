import actionTypes from "../../actionTypes";
import {
  DATA_LOADING_STATE_ERROR,
  DATA_LOADING_STATE_INIT,
  DATA_LOADING_STATE_PENDING,
  DATA_LOADING_STATE_SUCCESS,
} from "../../storeUtils";

const INIT_STATE = {
  ...DATA_LOADING_STATE_INIT,
};

const configReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.LIBRARY_CONFIG_LOAD_PENDING: {
      return {
        ...state,
        ...DATA_LOADING_STATE_PENDING,
      };
    }

    case actionTypes.LIBRARY_CONFIG_LOAD_SUCCESS: {
      const { Permissions } = action.payload;
      return {
        ...state,
        permissions: Permissions,
        ...DATA_LOADING_STATE_SUCCESS,
      };
    }

    case actionTypes.LIBRARY_CONFIG_LOAD_ERROR: {
      return {
        ...state,
        ...DATA_LOADING_STATE_ERROR,
      };
    }

    default:
      return state;
  }
};

export default configReducer;
