import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./GuideBrowsingActiveSessionsModal.module.scss";
import { getActiveGuideBrowsingSessionList } from "../../../../../../state/highlights/sessions/selectors";
import { loadGuideBrowsingRecentTabsHistory } from "../../../../../../state/highlights/sessions/actions";
import { GUIDE_BROWSING_SESSION_TYPE } from "../../../../../../state/highlights/sessions/types";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import GuideBrowsingActiveSession from "../GuideBrowsingActiveSession/GuideBrowsingActiveSession";
import { useIntl } from "react-intl";

const GuideBrowsingActiveSessionsModal = ({
  isModalOpen,
  onModalClose,
  sessionList,
  loadRecentTabsHistory,
}) => {
  const intl = useIntl();
  // load resent tabs on modal mount
  useEffect(() => {
    loadRecentTabsHistory();
  }, [loadRecentTabsHistory]);

  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      onClose={onModalClose}
      title={intl.formatMessage(
        {
          id: "MH89kN",
          defaultMessage:
            "{count, plural, one {# active guided session} other {# active guided sessions}}:",
        },
        { count: sessionList?.length }
      )}
      className={styles.root}
      dataTestPrefix="hl-GuideBrowsingReviewActiveSessionsModal"
      classNameHeader={styles.modalTitle}
    >
      <div className={styles.modalBody}>
        {_.map(sessionList, (session) => (
          <GuideBrowsingActiveSession key={session.ID} session={session} />
        ))}
      </div>
    </ModalDeprecated>
  );
};

GuideBrowsingActiveSessionsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  sessionList: PropTypes.arrayOf(GUIDE_BROWSING_SESSION_TYPE).isRequired,
  loadRecentTabsHistory: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    sessionList: getActiveGuideBrowsingSessionList(state),
  }),
  (dispatch) => ({
    loadRecentTabsHistory: () => dispatch(loadGuideBrowsingRecentTabsHistory()),
  })
)(GuideBrowsingActiveSessionsModal);
