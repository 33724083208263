import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./UniqueActivity.module.scss";
import { getUniqueActivity } from "../../../../state/highlights/activities/selectors";
import { APP_PAGES } from "../../../../state/router/types";
import _ from "lodash";
import Panel from "@hapara/ui/src/atomic/Panel/Panel";
import ExternalLink from "@hapara/ui/src/atomic/ExternalLink/ExternalLink";
import FormattedStudentName from "../../FormattedStudentName/FormattedStudentName";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";
import { Link } from "react-router-dom";
import { getClassId } from "../../../../state/shared/selectors";
import CloseTabHandler from "../CloseTabHandler/CloseTabHandler";
import classnames from "classnames";
import Tippy from "@tippy.js/react";
import {
  SNAPSHOT_TYPES,
  UNIQUE_ACTIVITY_TYPE,
} from "../../../../state/highlights/activities/types";
import {
  FEEDBACK_TYPES,
  withFeedback,
} from "@hapara/ui/src/atomic/InlineFeedback/InlineFeedback";
import { createSnapshot } from "../../../../state/highlights/snaps/actions";
import { getScreenshotsEnabled } from "../../../../state/highlights/config/selectors";
import { isRestrictedScreenshotUrl } from "@hapara/ui/src/components/utils";
import { pendoTrack } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";

const UniqueActivityItem = ({
  activity,
  classId,
  closeTab,
  isClosing,
  time,
  createSnapshot,
  isScrEnabled,
}) => {
  const intl = useIntl();
  const secondsAgo = Math.floor((time - activity.when * 1000) / 1000);
  let timeMessage = intl.formatMessage({
    defaultMessage: "< 1m ago",
    id: "Ln4K6G",
  });

  if (secondsAgo / (60 * 60) > 1) {
    intl.formatMessage(
      {
        defaultMessage: "{hours}h ago",
        id: "0+9dD8",
      },
      {
        hours: Math.floor(secondsAgo / (60 * 60)),
      }
    );
  } else if (secondsAgo / 60 > 1) {
    intl.formatMessage(
      {
        defaultMessage: "{minutes}m ago",
        id: "3Ek5z7",
      },
      {
        minutes: Math.floor(secondsAgo / 60),
      }
    );
  }

  return (
    <li
      className={classnames(styles.listItem, {
        [styles.tabItemToBeClosed]: isClosing,
      })}
    >
      {withFeedback(({ feedback, isInProgress, setInProgress, triggerRef }) => {
        return (
          <div className={styles.listItemContent}>
            <div className={styles.details}>
              <div className={styles.link}>
                <ExternalLink
                  url={activity.url}
                  title={activity.title}
                  icon={activity.icon}
                  studentEmail={activity.studentInfo.Email}
                  classId={classId}
                />
              </div>
              <div className={styles.name}>
                <FormattedStudentName student={activity.studentInfo} />
              </div>
              <div className={styles.when}>{timeMessage}</div>
            </div>
            {isScrEnabled && !isRestrictedScreenshotUrl(activity.url) && (
              <Tippy
                content={intl.formatMessage({
                  defaultMessage:
                    "Take a snap of this activity to review and compare",
                  id: "4Fbo71",
                })}
                theme="hsuite"
                maxWidth={140}
              >
                <Button
                  isLoading={isInProgress}
                  className={styles.firstButton}
                  ref={triggerRef}
                  icon="camera"
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.XSMALL}
                  dataTestId="hl-ActivityViewer-UniqueActivity-Button-TakeSnap"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Take a snap",
                    id: "ldZA+V",
                  })}
                  onAction={async () => {
                    setInProgress();
                    try {
                      await createSnapshot({
                        classId,
                        activityData: activity.convertToSnapActivity(),
                        snapshotType: SNAPSHOT_TYPES.URL,
                        icon: activity.icon,
                        title: activity.title,
                        url: activity.url,
                      });

                      pendoTrack("Snap - Take a snap from Activity Viewer", {
                        classId: classId,
                        snapTimestamp: new Date().toISOString(),
                      });

                      feedback(
                        FEEDBACK_TYPES.SUCCESS,
                        <FormattedMessage
                          defaultMessage="Activity captured. Review in <a>Snaps</a>"
                          id="JFP/Gr"
                          values={{
                            a: (chunks) => (
                              <span className={styles.snapLink}>
                                <Link
                                  to={`${APP_PAGES.HIGHLIGHTS_SNAPS.path}?classId=${classId}`}
                                  data-test-id="hs-hl-ActivityViewer-UniqueActivity-SnapsLink"
                                  aria-label={intl.formatMessage({
                                    defaultMessage: "Go to snaps",
                                    id: "S7Hers",
                                  })}
                                >
                                  {chunks}
                                </Link>
                              </span>
                            ),
                          }}
                        />
                      );
                    } catch (e) {
                      console.error(e);
                      feedback(FEEDBACK_TYPES.ERROR, "Some error occured.");
                    }
                  }}
                />
              </Tippy>
            )}
            {isScrEnabled && isRestrictedScreenshotUrl(activity.url) && (
              <div className={styles.spaceMaintainer}></div>
            )}
            <Button
              icon="cross"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.XSMALL}
              aria-label={intl.formatMessage({
                defaultMessage: "Close tab",
                id: "HqPKrA",
              })}
              dataTestId="hl-ActivityViewer-UniqueActivity-Button-Close"
              onAction={(e) => {
                closeTab(e);
              }}
              isLoading={isClosing}
            />
          </div>
        );
      }, 5000)}
    </li>
  );
};

const UniqueActivity = ({
  activities,
  classId,
  createSnapshot,
  isScreenshotsEnabled = false,
}) => {
  const [time, setTime] = React.useState(new Date().getTime());
  React.useEffect(() => {
    const refreshInterval = setInterval(() => {
      setTime(new Date().getTime());
    }, 60 * 1000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [setTime]);
  const keys = _.keys(activities);
  keys.sort();
  return (
    <Panel
      className={styles.root}
      emptyClassName={styles.emptyStyle}
      header={
        <div className={styles.header}>
          <h2>
            <FormattedMessage defaultMessage="Unique Activity" id="94mG+a" />
          </h2>
        </div>
      }
      emptyState={activities.length === 0}
    >
      {activities.length === 0 && (
        <div className={styles.center}>
          <FormattedMessage
            defaultMessage="No unique individual activity happening right now."
            id="v7O8q0"
          />
        </div>
      )}
      {activities.length > 0 && (
        <ul className={styles.list}>
          <CloseTabHandler
            tabs={activities}
            tabsLimit={0} // unlimited
            tabComponent={(activity, closeTab, isClosing) => {
              return (
                <UniqueActivityItem
                  isScrEnabled={isScreenshotsEnabled}
                  time={time}
                  createSnapshot={(data) => createSnapshot(data)}
                  activity={activity}
                  key={activity.url}
                  classId={classId}
                  closeTab={closeTab}
                  isClosing={isClosing}
                />
              );
            }}
            getStudentInfo={(activity) => activity.studentInfo}
          />
        </ul>
      )}
    </Panel>
  );
};

UniqueActivity.propTypes = {
  activities: PropTypes.arrayOf(UNIQUE_ACTIVITY_TYPE),
  classId: PropTypes.string.isRequired,
  createSnapshot: PropTypes.func.isRequired,
  isScreenshotsEnabled: PropTypes.bool,
};

export default connect(
  (state) => ({
    activities: getUniqueActivity(state),
    classId: getClassId(state),
    isScreenshotsEnabled: getScreenshotsEnabled(state),
  }),
  (dispatch) => ({
    createSnapshot: ({
      classId,
      activityData,
      snapshotType,
      url,
      icon,
      title,
    }) => {
      return dispatch(
        createSnapshot(classId, activityData, snapshotType, url, icon, title)
      );
    },
  })
)(UniqueActivity);
