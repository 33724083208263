import PropTypes from "prop-types";

export const MYWORKSPACES_THUMBNAIL_PAGE_SIZE = 15;
export const THUMBNAIL_SIZE = 92;

export const THUMBNAIL_TYPE = PropTypes.shape({
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt_description: PropTypes.string,
  urls: PropTypes.shape({ raw: PropTypes.string }),
});
