import { FormattedMessage } from "react-intl";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classnames from "classnames";
import styles from "./FiltersSort.module.scss";
import Dropdown, {
  DROPDOWN_CONTENT_POSITION_TYPES,
  DropdownTrigger,
} from "@hapara/ui/src/atomic/Dropdown/Dropdown";
import { getFilterSortType } from "../../../../../state/workspace/myworkspaces/list/selectors";
import { WORKSPACE_SORTING_TYPES } from "../../../../../state/workspace/consts";
import { getDataTestIdFromRawString } from "../../../../../utils";
import { pushQueryToHistory } from "../../../../../state/router/navigation";
import { focusSafelyByRef } from "@hapara/ui/src/components/utils";

export const FiltersSort = ({
  filterSortType,
  dataTestIdPrefix = "Ws-SortFilter",
}) => {
  const triggerRef = useRef();

  const filterOptionList = [
    {
      id: 1,
      name: "Last modified",
      type: WORKSPACE_SORTING_TYPES.LAST,
    },
    {
      id: 2,
      name: "Title A to Z",
      type: WORKSPACE_SORTING_TYPES.TITLE_AZ,
    },
    {
      id: 3,
      name: "Title Z to A",
      type: WORKSPACE_SORTING_TYPES.TITLE_ZA,
    },
  ];

  const selectedOption =
    _.find(filterOptionList, { type: filterSortType }) || {};

  const triggerComponent = (props) => (
    <DropdownTrigger
      className={classnames(styles.dropdownTrigger_isPlRoleFF, {
        [styles.notDefault]:
          selectedOption.type !== WORKSPACE_SORTING_TYPES.LAST,
      })}
      data-test-id={`${dataTestIdPrefix}-Dropdown-Trigger`}
      label={selectedOption.name}
      ref={triggerRef}
      {...props}
    />
  );

  const itemComponentList = filterOptionList.map(
    (item) =>
      ({ onClick, className, ...rest }) => {
        const isSelected = item.type === filterSortType;
        return (
          <button
            onClick={() => {
              pushQueryToHistory({
                sort: item.type,
              });
              onClick();
              focusSafelyByRef(triggerRef, 100);
            }}
            className={classnames(className, styles.dropdownItem_isPlRoleFF, {
              [styles.selected]: isSelected,
            })}
            data-test-id={`${dataTestIdPrefix}-Button-${getDataTestIdFromRawString(
              item.name
            )}`}
            aria-label={`${item.name}${isSelected ? ", selected" : ""}`}
            {...rest}
          >
            {item.name}
          </button>
        );
      }
  );

  return (
    <div className={styles.root}>
      <span className={styles.label}>
        <FormattedMessage defaultMessage="Sort by:" id="40bnK/" />
      </span>
      <Dropdown
        positionType={DROPDOWN_CONTENT_POSITION_TYPES.RIGHT}
        triggerComponent={triggerComponent}
        itemComponentList={itemComponentList}
      />
    </div>
  );
};

FiltersSort.propTypes = {
  filterSortType: PropTypes.oneOf(_.values(WORKSPACE_SORTING_TYPES)),
  dataTestIdPrefix: PropTypes.string,
};

export default connect((state) => ({
  filterSortType: getFilterSortType(state),
}))(FiltersSort);
