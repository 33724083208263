import { trackError } from "../../utils/Raygun";

export const LOGIN_ATTEMPT_THRESHOLD_MS = 180_000;
export const LOGIN_ATTEMPT_LIMIT = 3;

type LoginAttemptLocalStorageKeys = "lastLoginAttempt" | "loginAttemptCount";

const getLocalStorage = (key: LoginAttemptLocalStorageKeys) => {
  const value = localStorage.getItem(key);
  if (!value) return null;

  const parsedValue = parseInt(value);
  if (isNaN(parsedValue)) return null;
  return parsedValue;
};

const setLocalStorage = (key: LoginAttemptLocalStorageKeys, value: number) => {
  localStorage.setItem(key, value.toString());
};

export const loginAttemptTracker = (): void => {
  const currentTimestamp = Date.now();

  const lastLoginAttemptTimestamp = getLocalStorage("lastLoginAttempt");
  let loginAttemptCount = getLocalStorage("loginAttemptCount") ?? 0;

  const isWithinLastLoginAttemptThreshold = lastLoginAttemptTimestamp
    ? currentTimestamp - lastLoginAttemptTimestamp < LOGIN_ATTEMPT_THRESHOLD_MS
    : false;

  if (isWithinLastLoginAttemptThreshold) {
    loginAttemptCount += 1;
  } else {
    loginAttemptCount = 1;
  }

  setLocalStorage("loginAttemptCount", loginAttemptCount);
  setLocalStorage("lastLoginAttempt", currentTimestamp);

  if (loginAttemptCount >= LOGIN_ATTEMPT_LIMIT) {
    trackError(new Error("Repeated login attempts detected"));
  }
};
