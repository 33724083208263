import React, { useState, useEffect } from "react";
import style from "./ChatLoader.module.scss";
import PageLoading from "../../../../atomic/LoadIndicators/PageLoading/PageLoading";
import { ChatRoleType } from "../../types/state";

type ChatLoaderProps = {
  role: ChatRoleType;
};
export const ChatLoader = ({ role }: ChatLoaderProps) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (role === "student") {
        window.location.reload();
        return;
      }
      setShowError(true);
    }, 10_000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={style.container}>
      <PageLoading isGoBackVisible={false} isError={showError} />
    </div>
  );
};
