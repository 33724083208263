import { FormattedMessage } from "react-intl";
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./WorkspaceThumbnailsSearch.module.scss";
import classnames from "classnames";
import _ from "lodash";
import SearchDeprecated from "@hapara/ui/src/deprecated/SearchDeprecated/SearchDeprecated";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import TagsLoading from "../TagsLoading/TagsLoading";
import {
  getFilters,
  areFiltersLoading,
} from "../../../../../state/shared/selectors";
import { getInitialSearchTerm } from "../../../../../state/workspace/myworkspaces/thumbnails/selectors";
import {
  SHARED_FILTER_TYPES,
  filterItemType,
} from "../../../../../state/shared/types";
import { getDataTestIdFromRawString } from "../../../../../utils";

export const WorkspaceThumbnailsSearch = ({
  query,
  onSearch,
  onClear,
  tags,
  initialSearch,
  areTagsLoading,
}) => {
  const isQueryAmongstTags = useCallback(
    (term) => {
      return (
        term === "" || term === initialSearch || _.find(tags, { Value: term })
      );
    },
    [initialSearch, tags]
  );

  const [searchTerm, setSearchTerm] = useState(query);
  const [usingTag, setUsingTag] = useState(isQueryAmongstTags(query));
  const [forceSearchClear, setForceSearchClear] = useState(false);

  useEffect(() => {
    if (tags && tags.length > 0 && !searchTerm) {
      setSearchTerm(initialSearch);
    }
  }, [tags, initialSearch, searchTerm, setSearchTerm]);

  useEffect(() => {
    setUsingTag(isQueryAmongstTags(searchTerm));
  }, [searchTerm, setUsingTag, isQueryAmongstTags]);

  useEffect(() => {
    if (!searchTerm || searchTerm === "") setForceSearchClear(false);
  }, [searchTerm]);

  return (
    <div className={styles.root}>
      <div className={styles.searchContainer}>
        <SearchDeprecated
          placeholder="Search royalty-free images from Unsplash"
          onSearch={(val) => {
            setSearchTerm(val);
            onSearch(val);
          }}
          dataTestIdPrefix="Ws-ThumbnailsSearch"
          initialValue={isQueryAmongstTags(searchTerm) ? "" : searchTerm}
          forceValueToEmpty={forceSearchClear}
        />
      </div>
      <div>
        {areTagsLoading && <TagsLoading />}
        {!areTagsLoading && usingTag && (
          <div className={styles.tagsContainer}>
            <Button
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              size={BUTTON_SIZES.SMALL}
              label="All subjects"
              onAction={() => {
                setSearchTerm(initialSearch);
                onSearch(initialSearch);
              }}
              dataTestId="Ws-Thumbnails-Search-AllSubjects"
              className={classnames({
                [styles.selected]: searchTerm === initialSearch,
              })}
            />
            {_.map(tags, (tag) => {
              return (
                <Button
                  type={BUTTON_TYPES.OUTLINED}
                  outlineType={BUTTON_OUTLINE_TYPES.SOLID}
                  size={BUTTON_SIZES.SMALL}
                  label={tag.Value}
                  onAction={() => {
                    setSearchTerm(tag.Value);
                    onSearch(tag.Value);
                  }}
                  dataTestId={`Ws-Thumbnails-Search-${getDataTestIdFromRawString(
                    tag.Value
                  )}`}
                  className={classnames({
                    [styles.selected]: tag.Value === searchTerm,
                  })}
                  key={tag.Key}
                />
              );
            })}
          </div>
        )}
        {!areTagsLoading && !usingTag && (
          <div className={styles.searchResults}>
            <h2>
              <FormattedMessage defaultMessage="Search results" id="79lKyO" />
            </h2>
            <Button
              dataTestId="Ws-Thumbnails-Button-ClearResults"
              label="Clear"
              size={BUTTON_SIZES.SMALL}
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              onAction={() => {
                setSearchTerm("");
                setForceSearchClear(true);
                onClear();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

WorkspaceThumbnailsSearch.propTypes = {
  query: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  showTags: PropTypes.bool,
  initialSearch: PropTypes.string,
  areTagsLoading: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(filterItemType),
};

export default connect((state) => ({
  tags: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
  initialSearch: getInitialSearchTerm(state),
  areTagsLoading: areFiltersLoading(state),
}))(WorkspaceThumbnailsSearch);
