import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import styles from "./Dropdown.module.scss";
import HapReactIcon from "../icon/hapReactIcon";

export const DROPDOWN_CONTENT_POSITION_TYPES = {
  LEFT: "left",
  RIGHT: "right",
};

export const DROPDOWN_CONTENT_COLOUR_TYPES = {
  LIGHT_GREY: "light-grey",
  DARK_GREY: "dark-grey",
};

export const DROPDOWN_WIDTH_TYPES = {
  MIN_CONTENT: "min-content",
  FULL_WIDTH: "full-width",
};

export const Dropdown = ({
  id,
  className,
  classNameContent,
  positionType = DROPDOWN_CONTENT_POSITION_TYPES.LEFT,
  colorType = DROPDOWN_CONTENT_COLOUR_TYPES.LIGHT_GREY,
  widthType = DROPDOWN_WIDTH_TYPES.MIN_CONTENT,
  triggerComponent: TriggerComponent,
  itemComponentList,
  content,
  dropdownTitle: DropdownTitle,
  triggerRef,
  dataTestId,
  selectedItemIndex,
}) => {
  const [triggerId] = useState(id || uuidv4());
  const rootRef = useRef();
  const contentRef = useRef();
  const [open, setOpen] = useState(false);

  // close Dropdown if clicked outside
  const handleOutsideClick = useCallback(
    (e) => {
      if (open) {
        if (rootRef.current && rootRef.current.contains(e.target)) {
          // inside click
          return;
        }
        // outside click
        setOpen(false);
      }
    },
    [open]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);
  // end

  // close Dropdown and focus trigger button if Esc is pressed
  const handleEscKey = useCallback(
    (e) => {
      if (open && e.keyCode === 27) {
        setOpen(false);
        const rootNodes = _.get(rootRef, "current.childNodes");
        if (rootNodes && rootNodes.length > 0) {
          triggerRef?.current
            ? triggerRef.current.focus()
            : rootNodes[0].focus();
        }
      }
    },
    [open]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handleEscKey]);
  // end

  // keep looking for item to focus until we reach the end of the list
  const focusNextSearchItem = (children, index) => {
    const nextIndex = index + 1;
    if (nextIndex < children.length) {
      if (!children[nextIndex].children[0].disabled) {
        children[nextIndex].children[0].focus();
      } else {
        focusNextSearchItem(children, nextIndex);
      }
    }
  };

  // keep looking for item to focus until we reach the beginning of the list
  const focusPreviousSearchItem = (children, index) => {
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      if (!children[previousIndex].children[0].disabled) {
        children[previousIndex].children[0].focus();
      } else {
        focusPreviousSearchItem(children, previousIndex);
      }
    }
  };

  const handleItemKeyDown = (e, index) => {
    // on Arrow Down
    if (e.keyCode === 40) {
      e.preventDefault();
      focusNextSearchItem(contentRef.current.childNodes, index);
    }

    // on Arrow Up
    if (e.keyCode === 38) {
      e.preventDefault();
      focusPreviousSearchItem(contentRef.current.childNodes, index);
    }
  };

  const handleTriggerKeyDown = (e) => {
    // on Arrow Down
    if (e.keyCode === 40) {
      e.preventDefault();
      setOpen(true);

      const children = _.get(contentRef, "current.childNodes");
      if (children && children.length) {
        focusNextSearchItem(children, -1);
      }
    }

    // on Enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      setOpen(!open);
    }
  };

  return (
    <div className={classnames(styles.root, className)} ref={rootRef}>
      <TriggerComponent
        onClick={() => {
          setOpen(!open);
        }}
        aria-haspopup="true"
        aria-expanded={open}
        id={triggerId}
        onKeyDown={(e) => handleTriggerKeyDown(e)}
      />
      {open && content && (
        <div
          className={classnames(
            styles.customContent,
            {
              [styles.right]:
                positionType === DROPDOWN_CONTENT_POSITION_TYPES.RIGHT,
              [styles.fullWidth]: widthType === DROPDOWN_WIDTH_TYPES.FULL_WIDTH,
            },
            classNameContent
          )}
          aria-labelledby={triggerId}
          ref={contentRef}
        >
          {content((isOpened) => setOpen(isOpened))}
        </div>
      )}
      {itemComponentList &&
        open &&
        (DropdownTitle ? (
          <div
            className={classnames(
              styles.content,
              {
                [styles.right]:
                  positionType === DROPDOWN_CONTENT_POSITION_TYPES.RIGHT,
                [styles.fullWidth]:
                  widthType === DROPDOWN_WIDTH_TYPES.FULL_WIDTH,
              },
              classNameContent
            )}
            aria-labelledby={triggerId}
            ref={contentRef}
          >
            {DropdownTitle && <DropdownTitle />}
            {_.map(itemComponentList, (ItemComponent, index) => (
              <div key={index} className={styles.contentItemWrap}>
                <ItemComponent
                  onClick={() => {
                    setOpen(!open);
                  }}
                  className={classnames(styles.contentItem, {
                    [styles.darkGrey]:
                      colorType === DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY,
                  })}
                  onKeyDown={(e) => handleItemKeyDown(e, index)}
                />
              </div>
            ))}
          </div>
        ) : (
          <ul
            data-test-id={dataTestId}
            className={classnames(
              styles.content,
              {
                [styles.right]:
                  positionType === DROPDOWN_CONTENT_POSITION_TYPES.RIGHT,
                [styles.fullWidth]:
                  widthType === DROPDOWN_WIDTH_TYPES.FULL_WIDTH,
              },
              classNameContent
            )}
            aria-labelledby={triggerId}
            ref={contentRef}
          >
            {_.map(itemComponentList, (ItemComponent, index) => (
              <li
                key={index}
                className={classnames(styles.contentItemWrap, {
                  [styles.selected]: index === selectedItemIndex,
                })}
              >
                <ItemComponent
                  onClick={() => {
                    setOpen(!open);
                  }}
                  className={classnames(styles.contentItem, {
                    [styles.darkGrey]:
                      colorType === DROPDOWN_CONTENT_COLOUR_TYPES.DARK_GREY,
                  })}
                  onKeyDown={(e) => handleItemKeyDown(e, index)}
                />
              </li>
            ))}
          </ul>
        ))}
    </div>
  );
};

Dropdown.propTypes = {
  triggerComponent: PropTypes.elementType.isRequired,
  itemComponentList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.elementType),
    PropTypes.oneOf([null, undefined]),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  positionType: PropTypes.oneOf(_.values(DROPDOWN_CONTENT_POSITION_TYPES)),
  colorType: PropTypes.oneOf(_.values(DROPDOWN_CONTENT_COLOUR_TYPES)),
  widthType: PropTypes.oneOf(_.values(DROPDOWN_WIDTH_TYPES)),
  content: PropTypes.elementType,
  dropdownTitle: PropTypes.elementType,
  triggerRef: PropTypes.object,
  dataTestId: PropTypes.string,
  selectedItemIndex: PropTypes.number,
};

export const DropdownTrigger = React.forwardRef((props, ref) => {
  const {
    className = null,
    label = "",
    widthType = DROPDOWN_WIDTH_TYPES.MIN_CONTENT,
    precedingIcon = "",
    hideArrowDown = false,
    disabled = false,
    ...rest
  } = props;

  return (
    <button
      type="button"
      className={classnames(
        styles.dropdownTrigger,
        {
          [styles.fullWidth]: widthType === DROPDOWN_WIDTH_TYPES.FULL_WIDTH,
        },
        className
      )}
      ref={ref}
      disabled={disabled}
      {...rest}
    >
      {precedingIcon && (
        <HapReactIcon
          svg={precedingIcon}
          width={16}
          height={16}
          className={styles.precedingIcon}
        />
      )}
      <span className={styles.label}>{label}</span>
      {!hideArrowDown && (
        <HapReactIcon
          svg="arrow-carvet-down"
          width={16}
          height={16}
          className={styles.icon}
        />
      )}
    </button>
  );
});

DropdownTrigger.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node,
  widthType: PropTypes.oneOf(_.values(DROPDOWN_WIDTH_TYPES)),
  precedingIcon: PropTypes.string,
  hideArrowDown: PropTypes.bool,
  disabled: PropTypes.bool,
};

/** @deprecated use named export, default export will be removed in a future version */
export default Dropdown;
