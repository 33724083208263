import { AuthScope, validAuthScopes } from "../types/AuthScope";

/** Currently oauth only expects `admin` as a scope */
const passthroughScopes = ["admin"] as const;

type PassthroughScope = (typeof passthroughScopes)[number];

export const isPassthroughScope = (scope?: string): boolean =>
  !!scope && passthroughScopes.includes(scope as PassthroughScope);

export const getPassthroughScope = (
  scope?: string | null
): PassthroughScope | undefined =>
  validAuthScopes.includes(scope as AuthScope)
    ? (scope as PassthroughScope)
    : undefined;
