import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./MyResourcesPage.module.scss";
import HeaderAccountOnly from "../../../partial/HeaderAccountOnly/HeaderAccountOnly";
import Header from "../../../partial/Header/Header";
import Page from "../../../partial/PageContainer/PageContainer";
import { isMyResourcesLeftPanelOpen } from "../../../../state/library/myResources/selectors";
import MyResourcesMainContent from "../../../partial/MyResources/MyResourcesMainContent/MyResourcesMainContent";
import { updateMyResourcesLeftPanel } from "../../../../state/library/myResources/actions";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import MyResourcesFilters from "../../../partial/MyResources/MyResourcesFilters/MyResourcesFilters";
import {
  getClassList,
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/library/config/selectors";
import { hasWSAccess } from "../../../../state/user/selectors";
import PageLeftPanel from "../../../partial/PageLeftPanel/PageLeftPanel";
import { APP_PAGES } from "../../../../state/router/types";
import LocationChangeHandler from "./LocationChangeHandler";
import ModalsAndPanels from "../../../partial/Resources/ModalsAndPanels/ModalsAndPanels";
import FiltersLeftPanel from "../../../partial/Resources/FiltersLeftPanel/FiltersLeftPanel";
import ConfigHandler from "../../Library/ConfigHandler";
import { getClassId } from "../../../../state/shared/selectors";
import { classItemType } from "../../../../state/library/config/types";
import {
  isStarredFiltered,
  getMyResourcesItemsTotal,
} from "../../../../state/library/myResources/selectors";
import { getSearchQuery } from "../../../../state/shared/selectors";

export const MyResourcesPage = ({
  isLeftPanelOpen,
  toggleLeftPanel,
  hasWSAccess,
  isConfigLoaded,
  isConfigError,
  selectedClassId,
  isStarredActive,
  classList,
  searchQuery,
  title,
  searchResultsTotal,
}) => {
  const dataTestIdPrefix = "lb-MyResources";

  const headerToShow = hasWSAccess ? (
    <Header />
  ) : (
    <HeaderAccountOnly rootPageLink={APP_PAGES.MY_RESOURCES_STARRED.path} />
  );

  let pageTitle;
  if (!selectedClassId && !isStarredActive && !searchQuery) {
    pageTitle = `All Classes (${searchResultsTotal}) - ${title}`;
  } else if (!isStarredActive && !searchQuery) {
    let classInfo = classList.filter(
      (classInfo) => classInfo.ID === selectedClassId
    );
    if (classInfo && classInfo.length > 0) {
      pageTitle = `${classInfo[0].Name} (${searchResultsTotal}) - ${title}`;
    } else {
      pageTitle = title;
    }
  } else if (searchQuery) {
    pageTitle = APP_PAGES.MY_RESOURCES_SEARCH.title;
  } else {
    pageTitle = `Starred (${searchResultsTotal}) - ${title}`;
  }

  return (
    <>
      <ConfigHandler />
      <Page
        header={headerToShow}
        isPageLoaded={isConfigLoaded}
        isPageLoadingError={isConfigError}
        title={pageTitle}
      >
        <LocationChangeHandler />
        <ModalsAndPanels />
        <FiltersLeftPanel />

        <div className={styles.root} data-test-id="lb-Pages-MyResourcesPage">
          <div>
            <LibrarySubMenu />
          </div>
          <div className={styles.content}>
            <PageLeftPanel
              isOpen={isLeftPanelOpen}
              focusableElementId={null}
              dataTestIdPrefix={dataTestIdPrefix}
              landmarkLabel="Filter My resources by class"
              closeButtonLabel="Close my resources filters panel"
              onClose={() => toggleLeftPanel(false)}
            >
              <MyResourcesFilters />
            </PageLeftPanel>
            <MyResourcesMainContent />
          </div>
        </div>
      </Page>
    </>
  );
};

MyResourcesPage.propTypes = {
  isLeftPanelOpen: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  hasWSAccess: PropTypes.bool.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  selectedClassId: PropTypes.string,
  classList: PropTypes.arrayOf(classItemType),
  isStarredActive: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  title: PropTypes.string,
  searchResultsTotal: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    selectedClassId: getClassId(state),
    classList: getClassList(state),
    isStarredActive: isStarredFiltered(state),
    isLeftPanelOpen: isMyResourcesLeftPanelOpen(state),
    hasWSAccess: hasWSAccess(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    searchQuery: getSearchQuery(state),
    searchResultsTotal: getMyResourcesItemsTotal(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(updateMyResourcesLeftPanel(isOpen)),
  })
)(MyResourcesPage);
