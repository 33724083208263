import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  SESSION_TYPES,
  MODAL_MODE_TYPES,
} from "../../../../../../state/highlights/sessions/types";
import styles from "./GuideBrowsingPanelFilterFocusOptions.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { showGuideBrowsingModal } from "../../../../../../state/highlights/sessions/actions";
import { getTemplatesPanelScheduledStatus } from "../../../../../../state/highlights/guideBrowsingTemplates/selectors";
import MemorisedFocusButton from "../../../../MemorisedFocusButton/MemorisedFocusButton";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

const GuideBrowsingPanelFilterFocusOptions = ({
  showGuideBrowsingModal,
  dataTestPrefix,
  ifSessionIsScheduled,
}) => {
  const intl = useIntl();

  const showGuideFilter = () =>
    showGuideBrowsingModal({
      modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
      sessionType: SESSION_TYPES.FILTER,
      isScheduled: ifSessionIsScheduled,
      isHideBackButton: true,
    });
  const showGuideFocus = () =>
    showGuideBrowsingModal({
      modalMode: MODAL_MODE_TYPES.CREATE_SESSION_FORM,
      sessionType: SESSION_TYPES.FOCUS,
      isScheduled: ifSessionIsScheduled,
      isHideBackButton: true,
    });

  const GuideBrowsingSession = ({
    icon,
    title,
    description,
    clickHandler,
    ariaLabel,
    dataTestId,
  }) => {
    return (
      <li className={styles.sessionItem}>
        <MemorisedFocusButton
          className={styles.sessionSection}
          onClick={clickHandler}
          aria-label={ariaLabel}
          data-test-id={dataTestId}
          label={
            <div className={styles.wrapper}>
              <div className={styles.sessionIconPart}>{icon}</div>
              <div className={styles.sessionBody}>
                <span className={styles.sessionTitle}>{title}</span>
                <span className={styles.sessionDesc}>{description}</span>
              </div>
            </div>
          }
        ></MemorisedFocusButton>
      </li>
    );
  };

  return (
    <div className={styles.guideBrowsing}>
      <div className={styles.divider}></div>
      <div className={styles.browsingSection}>
        <ul className={styles.sessionList}>
          <GuideBrowsingSession
            icon={
              <div className={classnames(styles.iconWrapper, styles.green)}>
                <HapReactIcon
                  svg="focus-shield"
                  width="14"
                  height="14"
                  className={styles.icon}
                />
              </div>
            }
            title={intl.formatMessage({
              defaultMessage: "Set up a Focus session",
              id: "6/+kC1",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Allow students to access only a few, specific websites",
              id: "ND8J9I",
            })}
            clickHandler={showGuideFocus}
            ariaLabel={intl.formatMessage({
              defaultMessage: "Set up a Focus session",
              id: "6/+kC1",
            })}
            dataTestId={`${dataTestPrefix}-SetUpFocusSession`}
          />
          <GuideBrowsingSession
            icon={
              <div className={classnames(styles.iconWrapper, styles.blue)}>
                <HapReactIcon
                  svg="filter-funnel"
                  width="14"
                  height="14"
                  className={styles.icon}
                />
              </div>
            }
            title={intl.formatMessage({
              defaultMessage: "Set up a Filter session",
              id: "+X7RMa",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Prevent students from accessing specific websites",
              id: "xKz26B",
            })}
            clickHandler={showGuideFilter}
            ariaLabel={intl.formatMessage({
              defaultMessage: "Set up a Filter session",
              id: "+X7RMa",
            })}
            dataTestId={`${dataTestPrefix}-SetUpFilterSession`}
          />
        </ul>
      </div>
    </div>
  );
};

GuideBrowsingPanelFilterFocusOptions.propTypes = {
  showGuideBrowsingModal: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    ifSessionIsScheduled: getTemplatesPanelScheduledStatus(state),
  }),
  (dispatch) => ({
    showGuideBrowsingModal: (payload) =>
      dispatch(showGuideBrowsingModal(payload)),
  })
)(GuideBrowsingPanelFilterFocusOptions);
