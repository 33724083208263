import React from "react";
import styles from "./CollectionLoadingItem.module.scss";

const CollectionLoadingItem = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}></div>
    </div>
  );
};

export default CollectionLoadingItem;
