import PropTypes from "prop-types";

export const UPREF_NAME_FIRST_LAST = "fname_lname";
export const UPREF_NAME_LAST_FIRST = "lname_fname";
export const USER_INFO = "user_info";

export const userGISInfoType = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  imageUrl: PropTypes.string,
});

export const USER_PERMISSIONS = {
  GMAIL: "access.gmail",
  HIGHLIGHTS: "access.highlights",
  WORKSPACE: "access.workspace",
  TEACHER_DASHBOARD: "access.dashboard",
  MANAGE_CLASSES: "access.manage.classes",
  PROFESSIONAL_LEARNING: "access.workspace.professional.learning",
  LIBRARY_WORKSPACE: "access.workspace.private.library",
  LIBRARY_RESOURCES: "access.library",
  CLASS_LIST_STUDENTS: "access.class.students",
  CLASS_CONFIG: "access.class.config",
  LIBRARY_STUDENT_DIGITAL_BACKPACK: "access.student.library",
};
