import { Alert } from "@hapara/ui/src/atomic/AlertDeprecated";
import { FormattedMessage } from "react-intl";

type ClassInfoNetworkErrorAlertProps = {
  className?: string;
};

export const ClassInfoNetworkErrorAlert = ({
  className,
}: ClassInfoNetworkErrorAlertProps) => (
  <Alert isVisible type="failure" className={className} animated={false}>
    <FormattedMessage
      defaultMessage="Sorry, there was a problem updating this change. Please try again."
      id="6oPKXx"
    />
  </Alert>
);
