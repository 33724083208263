import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./ModalFormStudentPicker.module.scss";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { STUDENT_TYPE } from "../../../../state/highlights/students/types";
import { SESSION_RECIPIENT_TYPES } from "../../../../state/highlights/sessions/types";
import { Radio } from "@hapara/ui/src/atomic/Radio";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import { getFormattedName } from "../../../../utils";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  areSomeStudentsInSession,
  getStudentList,
} from "../../../../state/highlights/students/selectors";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { FormattedMessage, useIntl } from "react-intl";

const ModalFormStudentPicker = ({
  recipientType,
  onRecipientTypeChange,
  studentIds = [],
  onStudentIdsChange,
  dataTestPrefix,
  nameFormat,
  userGroups = [],
  groupUrns = [],
  onGroupUrnsChange,
  getGroupStatusInfo,
  blockTitle,
  areSomeStudentsInGBSession,
  studentsInGbSessionWarning,
}) => {
  const intl = useIntl();
  const handleRadioChange = (e) => {
    onRecipientTypeChange(_.get(e, "target.value", ""));
  };

  const radioName = `${dataTestPrefix}-RecipientTypeRadio`;
  const radioClassId = `${dataTestPrefix}-RecipientTypeRadio-Class`;
  const radioStudentsId = `${dataTestPrefix}-RecipientTypeRadio-Students`;
  const radioGroupsId = `${dataTestPrefix}-RecipientTypeRadio-Groups`;

  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormStudentPickerBlock`}
    >
      <fieldset className={styles.radioGroup}>
        <legend className={styles.radioGroupTitle}>{blockTitle}</legend>
        <div className={styles.radioGroupContainer}>
          <Radio
            name={radioName}
            id={radioClassId}
            value={SESSION_RECIPIENT_TYPES.CLASS}
            checked={recipientType === SESSION_RECIPIENT_TYPES.CLASS}
            onChange={handleRadioChange}
            label={intl.formatMessage({
              defaultMessage: "The Class",
              id: "8EY2fG",
            })}
            className={styles.radioGroupRadio}
            dataTestIdPrefix={radioClassId}
          />
          <Radio
            name={radioName}
            id={radioStudentsId}
            value={SESSION_RECIPIENT_TYPES.STUDENTS}
            checked={recipientType === SESSION_RECIPIENT_TYPES.STUDENTS}
            onChange={handleRadioChange}
            label={intl.formatMessage({
              defaultMessage: "Student(s)",
              id: "Z8cb+q",
            })}
            className={styles.radioGroupRadio}
            dataTestIdPrefix={radioStudentsId}
          />
          <Radio
            name={radioName}
            id={radioGroupsId}
            value={SESSION_RECIPIENT_TYPES.GROUPS}
            checked={recipientType === SESSION_RECIPIENT_TYPES.GROUPS}
            onChange={handleRadioChange}
            label={intl.formatMessage({
              defaultMessage: "Group(s)",
              id: "0yA81b",
            })}
            className={styles.radioGroupRadio}
            dataTestIdPrefix={radioGroupsId}
          />
        </div>
      </fieldset>

      <WarningMessage
        isVisible={studentsInGbSessionWarning && areSomeStudentsInGBSession}
        className={styles.warning}
      >
        {studentsInGbSessionWarning}
      </WarningMessage>

      {recipientType === SESSION_RECIPIENT_TYPES.STUDENTS && (
        <PickerByStudent
          studentIds={studentIds}
          dataTestPrefix={dataTestPrefix}
          nameFormat={nameFormat}
          radioStudentsId={radioStudentsId}
          onStudentIdsChange={onStudentIdsChange}
        />
      )}

      {recipientType === SESSION_RECIPIENT_TYPES.GROUPS && (
        <PickerByGroup
          dataTestPrefix={dataTestPrefix}
          radioGroupsId={radioGroupsId}
          userGroups={userGroups}
          onStudentIdsChange={onStudentIdsChange}
          groupUrns={groupUrns}
          onGroupUrnsChange={onGroupUrnsChange}
          getGroupStatusInfo={getGroupStatusInfo}
        />
      )}
    </div>
  );
};

ModalFormStudentPicker.propTypes = {
  recipientType: PropTypes.oneOf(_.values(SESSION_RECIPIENT_TYPES)).isRequired,
  onRecipientTypeChange: PropTypes.func.isRequired,
  studentIds: PropTypes.arrayOf(PropTypes.string),
  onStudentIdsChange: PropTypes.func.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  nameFormat: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  groupUrns: PropTypes.arrayOf(PropTypes.string),
  onGroupUrnsChange: PropTypes.func.isRequired,
  getGroupStatusInfo: PropTypes.func.isRequired,
  blockTitle: PropTypes.string.isRequired,
  studentsInGbSessionWarning: PropTypes.node,
  // connected
  areSomeStudentsInGBSession: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    areSomeStudentsInGBSession: areSomeStudentsInSession(state),
  }),
  (dispatch) => ({})
)(ModalFormStudentPicker);

const PickerByStudentInternal = ({
  students = [],
  studentIds,
  dataTestPrefix,
  nameFormat,
  radioStudentsId,
  onStudentIdsChange,
}) => {
  const intl = useIntl();
  const [isAllSelectedIndeterminate, setIsAllSelectedIndeterminate] =
    useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [search, setSearch] = useState("");

  // handle 'select all' checkbox state
  useEffect(() => {
    const amountOfSelected = studentIds.length;
    const amountOfAll = students.length;
    const isIndeterminate =
      amountOfSelected >= 1 && amountOfSelected < amountOfAll;

    setIsAllSelected(
      amountOfAll > 0 ? amountOfSelected === amountOfAll : false
    );
    setIsAllSelectedIndeterminate(isIndeterminate);
  }, [
    students.length,
    studentIds,
    setIsAllSelectedIndeterminate,
    setIsAllSelected,
  ]);

  const isStudentSelected = (id) => _.indexOf(studentIds, id) !== -1;

  const handleStudentSelect = (isChecked, id) => {
    if (isChecked) {
      onStudentIdsChange(_.union(studentIds, [id]));
    } else {
      onStudentIdsChange(_.without(studentIds, id));
    }
  };

  const handleAllSelect = (isChecked) => {
    if (isChecked) {
      onStudentIdsChange(_.map(students, (item) => item.Id));
    } else {
      onStudentIdsChange([]);
    }
  };

  const handleSearchInputChange = (e) => {
    const value = _.get(e, "target.value", "");
    setSearch(value);
  };

  const filteredStudents = useMemo(() => {
    const filteredStudents = _.filter(
      students,
      (student) =>
        _.toLower(getFormattedName(nameFormat, student)).indexOf(
          _.toLower(search)
        ) !== -1
    );
    return _.sortBy(filteredStudents, (student) =>
      _.toLower(getFormattedName(nameFormat, student))
    );
  }, [students, nameFormat, search]);

  return (
    <fieldset
      aria-labelledby={`${radioStudentsId}-Label`}
      className={styles.secondaryGroup}
    >
      <div className={styles.secondaryGroupTitle}>
        <div className={styles.secondaryGroupTitleCheckboxContainer}>
          <Checkbox
            label={intl.formatMessage({
              defaultMessage: "All students",
              id: "zdSuNW",
            })}
            checked={isAllSelected}
            semi={isAllSelectedIndeterminate}
            dataTestIdPrefix={`${dataTestPrefix}-RecipientTypeStudents-All`}
            className={styles.secondaryGroupTitleCheckbox}
            onChange={handleAllSelect}
          />
        </div>
        <div className={styles.secondaryGroupTitleSearchContainer}>
          <HapReactIcon
            svg="search"
            width="16"
            height="16"
            className={styles.secondaryGroupTitleSearchIcon}
          />
          <input
            type="text"
            className={styles.secondaryGroupTitleSearchField}
            placeholder={intl.formatMessage({
              defaultMessage: "Search students",
              id: "xkKwUn",
            })}
            aria-label={intl.formatMessage({
              defaultMessage: "Search students",
              id: "xkKwUn",
            })}
            onChange={handleSearchInputChange}
            value={search}
          />
        </div>
      </div>
      {!filteredStudents.length && (
        <div className={styles.secondaryGroupBody}>
          <div className={styles.secondaryGroupBodyEmpty}>
            <FormattedMessage defaultMessage="No student found." id="Wf+sE0" />
          </div>
        </div>
      )}
      {filteredStudents.length > 0 && (
        <ul className={styles.secondaryGroupBody}>
          {_.map(filteredStudents, (student) => {
            const isOffline = !_.get(
              student,
              `instances.${student.currentInstance}.isOnline`,
              false
            );
            const isInASession = _.get(
              student,
              `instances.${student.currentInstance}.lock.locking.active`,
              false
            );

            const nameText = getFormattedName(nameFormat, student);

            const statusText = isOffline ? (
              <FormattedMessage defaultMessage="(offline)" id="bLm0E3" />
            ) : isInASession ? (
              <FormattedMessage
                defaultMessage="(active in a session)"
                id="tMUcpz"
              />
            ) : null;

            const label = (
              <span className={styles.secondaryGroupBodyItemCheckboxInner}>
                <span
                  className={classnames(styles.name, {
                    [styles.offline]: isOffline,
                  })}
                >
                  {nameText}
                </span>
                {statusText && (
                  <span className={styles.status}>{statusText}</span>
                )}
              </span>
            );
            return (
              <li key={student.Id} className={styles.secondaryGroupBodyItem}>
                <Checkbox
                  label={label}
                  checked={isStudentSelected(student.Id)}
                  dataTestIdPrefix={`${dataTestPrefix}-RecipientTypeStudents-Student`}
                  className={styles.secondaryGroupBodyItemCheckbox}
                  onChange={(isChecked) =>
                    handleStudentSelect(isChecked, student.Id)
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </fieldset>
  );
};

PickerByStudentInternal.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  studentIds: PropTypes.arrayOf(PropTypes.string),
  nameFormat: PropTypes.string.isRequired,
  radioStudentsId: PropTypes.string.isRequired,
  onStudentIdsChange: PropTypes.func.isRequired,

  // connected
  students: PropTypes.arrayOf(STUDENT_TYPE),
};

const PickerByStudent = connect(
  (state) => ({
    students: getStudentList(state),
  }),
  (dispatch) => ({})
)(PickerByStudentInternal);

const PickerByGroupInternal = ({
  students = [],
  userGroups = [],
  dataTestPrefix,
  radioGroupsId,
  onStudentIdsChange,
  groupUrns = [],
  onGroupUrnsChange,
  getGroupStatusInfo,
}) => {
  const intl = useIntl();
  const [isAllSelectedIndeterminate, setIsAllSelectedIndeterminate] =
    useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const isGroupSelected = (urn) => _.indexOf(groupUrns, urn) !== -1;

  const handleGroupSelect = (isChecked, urn) => {
    if (isChecked) {
      onGroupUrnsChange(_.union(groupUrns, [urn]));
    } else {
      onGroupUrnsChange(_.without(groupUrns, urn));
    }
  };

  const handleAllSelect = (isChecked) => {
    if (isChecked) {
      onGroupUrnsChange(_.map(userGroups, (item) => item.URN));
    } else {
      onGroupUrnsChange([]);
    }
  };

  const getStudentIdByEmail = useCallback(
    (email) => {
      const student = _.find(students, (student) => student.Email === email);
      return _.get(student, "Id", null);
    },
    [students]
  );

  // handle 'select all' checkbox state
  useEffect(() => {
    const amountOfSelected = groupUrns.length;
    const amountOfAll = userGroups.length;
    const allGroupsURNs = _.map(userGroups, (g) => g.URN);
    const isIndeterminate =
      amountOfSelected >= 1 && amountOfSelected < amountOfAll;

    const isAllSelected =
      amountOfAll > 0
        ? amountOfSelected === amountOfAll &&
          _.isEqual(groupUrns.sort(), allGroupsURNs.sort()) // in case of session duplication some groups can be added / deleted since the session was created
        : false;
    setIsAllSelected(isAllSelected);
    setIsAllSelectedIndeterminate(isIndeterminate);
  }, [userGroups, groupUrns, setIsAllSelectedIndeterminate, setIsAllSelected]);

  // handle group list change and update student ids
  useEffect(() => {
    let studentEmails = [];

    _.forEach(groupUrns, (urn) => {
      const group = _.find(userGroups, (item) => item.URN === urn);
      const groupParticipants = _.get(group, "participants", []);
      studentEmails = _.union(studentEmails, groupParticipants);
    });

    const newStudentIds = _.map(studentEmails, (email) =>
      getStudentIdByEmail(email)
    );
    onStudentIdsChange(newStudentIds);
  }, [userGroups, groupUrns, getStudentIdByEmail, onStudentIdsChange]);

  const sortedGroups = useMemo(
    () => _.sortBy(userGroups, (group) => _.toLower(group.name)),
    [userGroups]
  );

  return (
    <fieldset
      aria-labelledby={`${radioGroupsId}-Label`}
      className={styles.secondaryGroup}
    >
      <div className={styles.secondaryGroupTitle}>
        <Checkbox
          label={intl.formatMessage({
            defaultMessage: "All groups",
            id: "V3nIzA",
          })}
          checked={isAllSelected}
          semi={isAllSelectedIndeterminate}
          dataTestIdPrefix={`${dataTestPrefix}-RecipientTypeGroups-All`}
          className={styles.secondaryGroupTitleCheckbox}
          onChange={(isChecked) => handleAllSelect(isChecked)}
        />
      </div>
      {!sortedGroups.length && (
        <div className={styles.secondaryGroupBody}>
          <div className={styles.secondaryGroupBodyEmpty}>
            <FormattedMessage defaultMessage="No group(s) found." id="RTeYWv" />
          </div>
        </div>
      )}
      {sortedGroups.length > 0 && (
        <ul className={styles.secondaryGroupBody}>
          {_.map(sortedGroups, (group) => {
            const groupStatus = getGroupStatusInfo(group);

            const label = (
              <span className={styles.secondaryGroupBodyItemCheckboxInner}>
                <span
                  className={classnames(styles.group, styles[group.color])}
                />
                <span
                  className={classnames(styles.name, {
                    [styles.offline]: groupStatus.areAllOffline,
                  })}
                >
                  {group.name}
                </span>
                {groupStatus.message && (
                  <span className={styles.status}>({groupStatus.message})</span>
                )}
              </span>
            );
            return (
              <li key={group.URN} className={styles.secondaryGroupBodyItem}>
                <Checkbox
                  label={label}
                  checked={isGroupSelected(group.URN)}
                  dataTestIdPrefix={`${dataTestPrefix}-RecipientTypeGroups-Group`}
                  className={styles.secondaryGroupBodyItemCheckbox}
                  onChange={(isChecked) =>
                    handleGroupSelect(isChecked, group.URN)
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </fieldset>
  );
};

PickerByGroupInternal.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  radioGroupsId: PropTypes.string.isRequired,
  onStudentIdsChange: PropTypes.func.isRequired,
  groupUrns: PropTypes.arrayOf(PropTypes.string),
  onGroupUrnsChange: PropTypes.func.isRequired,
  getGroupStatusInfo: PropTypes.func.isRequired,

  // connected
  students: PropTypes.arrayOf(STUDENT_TYPE),
};

const PickerByGroup = connect(
  (state) => ({
    students: getStudentConfigsList(state),
  }),
  (dispatch) => ({})
)(PickerByGroupInternal);
