import { useIntl } from "react-intl";
import React from "react";
import PropTypes from "prop-types";

import Button, { BUTTON_TYPES, BUTTON_SIZES } from "../../atomic/Button/Button";
import styles from "./errorMessage.module.scss";

/**
 * //TODO: Maria - description + ref
 */
const ErrorMessage = React.forwardRef(({ text, action }, _ref) => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.text}>{text}</div>
        <Button
          onAction={action}
          label={intl.formatMessage({
            defaultMessage: "Try again",
            id: "FazwRl",
          })}
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.REGULAR}
          preventPropagation={true}
          className={styles.fullWidth}
        />
      </div>
    </div>
  );
});

ErrorMessage.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
};

export default ErrorMessage;
