import { defineMessages } from "react-intl";

export const intlGroupColourNames = defineMessages({
  groupColour_0: {
    id: "T1BcD7",
    defaultMessage: "Dark Red",
  },
  groupColour_1: {
    id: "w95a+m",
    defaultMessage: "Medium Red",
  },
  groupColour_2: {
    id: "knSCBM",
    defaultMessage: "Light Red",
  },
  groupColour_3: {
    id: "3ttxsD",
    defaultMessage: "Pastel Red",
  },
  groupColour_4: {
    id: "GxrXs+",
    defaultMessage: "Dark Purple",
  },
  groupColour_5: {
    id: "8JMidM",
    defaultMessage: "Medium Purple",
  },
  groupColour_6: {
    id: "YJEU9+",
    defaultMessage: "Light Purple",
  },
  groupColour_7: {
    id: "I12Zr3",
    defaultMessage: "Pastel Purple",
  },
  groupColour_8: {
    id: "Qhd9CI",
    defaultMessage: "Dark Violet",
  },
  groupColour_9: {
    id: "8C0Wv1",
    defaultMessage: "Medium Violet",
  },
  groupColour_10: {
    id: "akXfgt",
    defaultMessage: "Light Violet",
  },
  groupColour_11: {
    id: "gDq3QK",
    defaultMessage: "Pastel Violet",
  },
  groupColour_12: {
    id: "xB2G4T",
    defaultMessage: "Dark Indigo",
  },
  groupColour_13: {
    id: "0zNhnG",
    defaultMessage: "Medium Indigo",
  },
  groupColour_14: {
    id: "MTCeiq",
    defaultMessage: "Light Indigo",
  },
  groupColour_15: {
    id: "GkiLq9",
    defaultMessage: "Pastel Indigo",
  },
  groupColour_16: {
    id: "LyDNah",
    defaultMessage: "Dark Blue",
  },
  groupColour_17: {
    id: "s/8X54",
    defaultMessage: "Medium Blue",
  },
  groupColour_18: {
    id: "Wb8pTK",
    defaultMessage: "Light Blue",
  },
  groupColour_19: {
    id: "tqBfh2",
    defaultMessage: "Pastel Blue",
  },
  groupColour_20: {
    id: "BxLhXr",
    defaultMessage: "Dark Turquoise",
  },
  groupColour_21: {
    id: "GqzWp2",
    defaultMessage: "Medium Turquoise",
  },
  groupColour_22: {
    id: "CrGCXw",
    defaultMessage: "Light Turquoise",
  },
  groupColour_23: {
    id: "ZOuRqa",
    defaultMessage: "Pastel Turquoise",
  },
  groupColour_24: {
    id: "8zkJnU",
    defaultMessage: "Dark Green",
  },
  groupColour_25: {
    id: "/c/gQB",
    defaultMessage: "Medium Green",
  },
  groupColour_26: {
    id: "Nsm79V",
    defaultMessage: "Light Green",
  },
  groupColour_27: {
    id: "ZTGEuP",
    defaultMessage: "Pastel Green",
  },
  groupColour_28: {
    id: "51/4Q0",
    defaultMessage: "Dark Lime",
  },
  groupColour_29: {
    id: "YQ9AM3",
    defaultMessage: "Medium Lime",
  },
  groupColour_30: {
    id: "aQfVgv",
    defaultMessage: "Light Lime",
  },
  groupColour_31: {
    id: "Xq0Hww",
    defaultMessage: "Pastel Lime",
  },
  groupColour_32: {
    id: "h+XQel",
    defaultMessage: "Dark Yellow",
  },
  groupColour_33: {
    id: "HvvGGy",
    defaultMessage: "Medium Yellow",
  },
  groupColour_34: {
    id: "m7rT2E",
    defaultMessage: "Light Yellow",
  },
  groupColour_35: {
    id: "aJ1RZu",
    defaultMessage: "Pastel Yellow",
  },
  groupColour_36: {
    id: "Q9QU2K",
    defaultMessage: "Dark Mustard",
  },
  groupColour_37: {
    id: "zQBcNk",
    defaultMessage: "Medium Mustard",
  },
  groupColour_38: {
    id: "flsdeB",
    defaultMessage: "Light Mustard",
  },
  groupColour_39: {
    id: "14ezBh",
    defaultMessage: "Pastel Mustard",
  },
  groupColour_40: {
    id: "irsRq3",
    defaultMessage: "Dark Orange",
  },
  groupColour_41: {
    id: "XCFgZx",
    defaultMessage: "Medium Orange",
  },
  groupColour_42: {
    id: "C341gS",
    defaultMessage: "Light Orange",
  },
});
