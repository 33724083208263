import { getTemplatesList } from "../../../apiCalls/highlights";
import { TEMPLATES } from "./type";
import { sendEvent } from "../../app/actions";
import { getErrorStringFromErrorOrResponse } from "../../../utils";
import { makeActionCreator } from "../../storeUtils";
import _ from "lodash";

export const fetchTemplatesList: any =
  (offset = 0) =>
  async (dispatch: any) => {
    try {
      const templatesListResponse = await getTemplatesList({ offset });
      const templatesList = _.get(
        templatesListResponse,
        "data.SessionTemplate",
        []
      );
      const offsetUpdated = _.get(templatesListResponse, "data.Offset", 0);
      dispatch({
        type:
          offsetUpdated > 20
            ? TEMPLATES.APPEND_TEMPLATES
            : TEMPLATES.GET_TEMPLATES,
        payload: {
          isMoreTemplatesAvailable: _.get(
            templatesListResponse,
            "data.IsMoreTemplatesAvailable",
            false
          ),
          templatesList: templatesList,
          offset: offsetUpdated,
        },
      });
      return true;
    } catch (error) {
      dispatch(
        sendEvent({
          name: "apiError",
          apiPath: "/tdapi/session-templates",
          apiError: getErrorStringFromErrorOrResponse(error),
        })
      );
      return false;
    }
  };

export const showTemplatesPanel =
  ({ isScheduledPanel }: { isScheduledPanel: boolean }) =>
  (dispatch: any) => {
    dispatch({
      type: TEMPLATES.SHOW_TEMPLATES_PANEL,
      payload: isScheduledPanel,
    });
  };

export const hideTemplatesPanel = makeActionCreator(
  TEMPLATES.HIDE_TEMPLATES_PANEL,
  "payload"
);
