import {
  createStore,
  combineReducers,
  applyMiddleware,
  AnyAction,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk, { ThunkDispatch } from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import app from "./app/reducer";
import user from "./user/reducer";
import navigation from "./navigation/reducer";
import header from "./header/reducer";
import myworkspacesList from "./workspace/myworkspaces/list/reducer";
import myworkspacesSearch from "./workspace/myworkspaces/search/reducer";
import libraryDiscoverList from "./library/discover/reducer";
import libraryMyResources from "./library/myResources/reducer";
import libraryLibrary from "./library/library/reducer";
import libraryCollections from "./library/collections/reducer";
import labels from "./workspace/myworkspaces/labels/reducer";
import shared from "./shared/reducer";
import thumbnails from "./workspace/myworkspaces/thumbnails/reducer";
import grading from "./workspace/grading/reducer";
import createWorkspace from "./workspace/create/reducer";
import pllist from "./workspace/pl/list/reducer";
import plsearch from "./workspace/pl/search/reducer";
import libraryConfig from "./library/config/reducer";
import libraryUploadStatus from "./library/uploadStatus/reducer";
import highlightsConfig from "./highlights/config/reducer";
import highlightsStudents from "./highlights/students/reducer";
import highlightsStudentConfigs from "./highlights/studentConfigs/reducer";
import highlightsUserGroups from "./shared/userGroups/reducer";
import highlightsStudentRequests from "./highlights/studentRequests/reducer";
import highlightsBusWebsocket from "./highlights/busWebsocket/reducer";
import highlightsScreenshotWebsocket from "./highlights/screenshotWebsocket/reducer";
import highlightsActivities from "./highlights/activities/reducer";
import highlightsSessions from "./highlights/sessions/reducer";
import highlightsSendMessage from "./highlights/sendMessage/reducer";
import highlightsShareLinks from "./highlights/shareLinks/reducer";
import highlightsNotification from "./highlights/notification/reducer";
import highlightsClickToShare from "./highlights/clickToShare/reducer";
import highlightsSnapshots from "./highlights/snaps/reducer";
import highlightsViewScreenshot from "./highlights/viewScreenshot/reducer";
import highlightsCurrentScreenExpandModal from "./highlights/currentScreenExpandModal/reducer";
import dashboardConfig from "./dashboard/config/reducer";
import dashboardManageClasses from "./dashboard/manage-classes/reducer";
import highlightsTemplates from "./highlights/guideBrowsingTemplates/reducer";
import highlightsGCContent from "./highlights/googleClassroom/reducer";
import dashboardClass from "./dashboard/class/reducer";
import classInfo from "./classInfo/reducer";

import resources from "./resources/reducer";

export const history = createBrowserHistory();
const routerMW = routerMiddleware(history);
const reducers = combineReducers({
  router: connectRouter(history),
  app,
  user,
  navigation,
  header,
  shared,
  workspace: combineReducers({
    create: createWorkspace,
    grading,
    myworkspaces: combineReducers({
      labels,
      list: myworkspacesList,
      search: myworkspacesSearch,
      thumbnails,
    }),
    pl: combineReducers({
      list: pllist,
      search: plsearch,
    }),
  }),
  dashboard: combineReducers({
    config: dashboardConfig,
    manageClasses: dashboardManageClasses,
    class: dashboardClass,
  }),
  classInfo,
  highlights: combineReducers({
    config: highlightsConfig,
    students: highlightsStudents,
    studentConfigs: highlightsStudentConfigs,
    userGroups: highlightsUserGroups,
    busWebsocket: highlightsBusWebsocket,
    screenshotWebsocket: highlightsScreenshotWebsocket,
    activities: highlightsActivities,
    sessions: highlightsSessions,
    notification: highlightsNotification,
    sendMessage: highlightsSendMessage,
    shareLinks: highlightsShareLinks,
    clickToShare: highlightsClickToShare,
    snapshots: highlightsSnapshots,
    viewScreenshot: highlightsViewScreenshot,
    currentScreenExpandModal: highlightsCurrentScreenExpandModal,
    guideBrowsingTemplates: highlightsTemplates,
    studentRequests: highlightsStudentRequests,
    googleClassroomContent: highlightsGCContent,
  }),
  resources,
  library: combineReducers({
    config: libraryConfig,
    discover: libraryDiscoverList,
    myResources: libraryMyResources,
    library: libraryLibrary,
    collections: libraryCollections,
    uploadStatus: libraryUploadStatus,
  }),
});

const middlewareEnhancer = applyMiddleware(thunk, routerMW);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(reducers, composedEnhancers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default store;
