import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./PLWorkspacesListEmptyState.module.scss";

const PLWorkspacesListEmptyState = () => {
  return (
    <div className={styles.root} data-test-id="Ws-PL-WorkspacesList-EmptyState">
      <div className={styles.header}>
        <FormattedMessage defaultMessage="There's nothing here!" id="7kWlAm" />
      </div>
      <div className={styles.body}>
        <FormattedMessage
          defaultMessage="Once you are added to professional learning Workspaces, you'll find them here."
          id="4csycv"
        />
      </div>
    </div>
  );
};

export default PLWorkspacesListEmptyState;
