import { ClassInfoDialogModal } from "./ClassInfoDialogModal";
import styles from "./ClassInfoDialogModal.module.scss";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

type ClassInfoDialogModalRemoveClassProps = {
  isOpen: boolean;
  hasNetworkError?: boolean;
  isProcessing?: boolean;
  dataTestIdPrefix: string;
  onRequestClose: () => void;
  modalAction: () => void;
};

export const ClassInfoDialogModalRemoveClass: React.FC<
  ClassInfoDialogModalRemoveClassProps
> = ({
  isOpen,
  hasNetworkError,
  dataTestIdPrefix,
  isProcessing,
  onRequestClose,
  modalAction,
}) => {
  const intl = useIntl();

  return (
    <ClassInfoDialogModal
      dataTestIdPrefix={dataTestIdPrefix}
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Remove class",
        id: "03IYhy",
      })}
      onRequestClose={onRequestClose}
      className={classnames(styles.dialogModal, styles.classInfoModal)}
      onConfirm={modalAction}
      confirmLabel={intl.formatMessage({
        defaultMessage: "Yes, remove",
        id: "KpV+N+",
      })}
      isConfirmDangerous
      hasNetworkError={hasNetworkError}
      isProcessing={isProcessing}
    >
      <FormattedMessage
        defaultMessage="Are you sure you want to remove this class from Hāpara?"
        id="5sdnIJ"
      />
    </ClassInfoDialogModal>
  );
};
