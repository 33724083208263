import React from "react";
import { ButtonLink } from "../../../../atomic/Button";
import styles from "./ChatFooterLinks.module.scss";
import { useIntl } from "react-intl";

export const ChatFooterLinks = () => {
  const intl = useIntl();
  return (
    <div className={styles.container} data-test-id="chat-footer-link">
      <ButtonLink
        label={intl.formatMessage({
          defaultMessage: "Troubleshoot",
          id: "uA+F/o",
        })}
        rightIcon="arrow-diangonal-right"
        type="tertiary"
        target="_blank"
        href="/diagnostics.html"
      />
      <ButtonLink
        label={intl.formatMessage({
          defaultMessage: "Student Dashboard",
          id: "WHuocz",
        })}
        rightIcon="arrow-diangonal-right"
        type="tertiary"
        target="_blank"
        href="https://app.hapara.com/student"
      />
    </div>
  );
};
