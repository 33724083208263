import { useEffect } from "react";
import { ChromeEventEmitter } from "../../utils/ChromeEventEmitter/ChromeEventEmitter";
import { ChatState, ChatClassStatus, ChatContactPresence } from "./types/state";

type ChatEventHandlers = {
  CHAT_STATE_CHANGE: (state: ChatState) => void;
  CHAT_REQUEST_STATE: () => void;
  CHAT_RECEIVE_TEXT_MESSAGE: (message: string) => void;
  CHAT_SEND_TEXT_MESSAGE: (payload: {
    channelId: string;
    to: string;
    from: string;
    body: string;
  }) => void;
  CHAT_ERROR: () => void;
  CHAT_SELECT_CONTACT: (id: string | null) => void;
  CHAT_CONVERSATION_READ: (payload: {
    channelId: string;
    classId: string;
  }) => void;
  CHAT_SET_CLASS_STATUS: (payload: {
    classId: string;
    userId: string;
    status: ChatClassStatus;
  }) => void;
  CHAT_SET_CONTACT_PRESENCE: (payload: {
    channelId: string;
    presence: ChatContactPresence;
  }) => void;
  CHAT_SELECT_CLASS: (classId: string | null) => void;
};

export const chatEventEmitter = new ChromeEventEmitter<ChatEventHandlers>();

export const useEventListener = <T extends keyof ChatEventHandlers>(
  event: T,
  handler: ChatEventHandlers[T],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies?: any[]
) => {
  useEffect(() => {
    chatEventEmitter.on(event, handler);
    return () => {
      chatEventEmitter.off(event, handler);
    };
  }, [...(dependencies ?? [])]);
};

export const useChatStateChangeListener = (cb: (state: ChatState) => void) => {
  useEventListener("CHAT_STATE_CHANGE", cb, []);
};

export const requestChatStateEvent = () =>
  chatEventEmitter.emit("CHAT_REQUEST_STATE");

export const updateChatStateEvent = (state: ChatState) =>
  chatEventEmitter.emit("CHAT_STATE_CHANGE", state);

export const sendTextMessageEvent = (
  channel: string,
  to: string,
  from: string,
  body: string
) => {
  chatEventEmitter.emit("CHAT_SEND_TEXT_MESSAGE", {
    channelId: channel,
    to,
    from,
    body,
  });
};

export const conversationReadEvent = (channelId: string, classId: string) =>
  chatEventEmitter.emit("CHAT_CONVERSATION_READ", {
    channelId,
    classId,
  });

export const selectContactEvent = (id: string | null) =>
  chatEventEmitter.emit("CHAT_SELECT_CONTACT", id);

export const setClassStatusEvent = (
  classId: string,
  userId: string,
  status: ChatClassStatus
) =>
  chatEventEmitter.emit("CHAT_SET_CLASS_STATUS", { classId, userId, status });

export const setContactPresenceEvent = (
  channelId: string,
  presence: ChatContactPresence
) =>
  chatEventEmitter.emit("CHAT_SET_CONTACT_PRESENCE", { channelId, presence });

export const selectClassEvent = (classId: string | null) =>
  chatEventEmitter.emit("CHAT_SELECT_CLASS", classId);
