import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import styles from "./MyWorkspacesPage.module.scss";
import Header from "../../../partial/Header/Header";
import Page from "../../../partial/PageContainer/PageContainer";
import PageLeftPanel from "../../../partial/PageLeftPanel/PageLeftPanel";
import PageRightPanelModal from "../../../partial/PageRightPanelModal/PageRightPanelModal";
import PageRightPanel from "../../../partial/PageRightPanel/PageRightPanel";
import PageCentralPanel from "./PageCentralPanel";
import LocationChangeHandler from "./LocationChangeHandler";
import NotFoundPage from "../../NotFoundPage/NotFoundPage";
import {
  clearList,
  updateLeftPanel,
} from "../../../../state/workspace/myworkspaces/list/actions";
import {
  getActiveItemId,
  getLeftPanelOpenState,
  getPageTitle,
  getRightPanelOpenState,
} from "../../../../state/workspace/myworkspaces/list/selectors";
import { APP_PAGES } from "../../../../state/router/types";
import { loadLabelsAction } from "../../../../state/workspace/myworkspaces/labels/actions";
import LibrarySubMenu from "../../../partial/Library/LibrarySubMenu/LibrarySubMenu";
import { getWindowSize } from "../../../../state/app/selectors";
import {
  getIsConfigError,
  getIsConfigLoaded,
} from "../../../../state/library/config/selectors";
import ConfigHandler from "../../Library/ConfigHandler";
import {
  MYWORKSPACES_CATEGORIES,
  MYWORKSPACES_LEFT_PANEL_ID,
  MYWORKSPACES_RIGHT_PANEL_ID,
  MYWORKSPACES_TILE_ID,
} from "../../../../state/workspace/myworkspaces/list/types";
import WSLeftSidePanel from "../../../partial/Workspace/MyWorkspaces/WSLeftSidePanel/WSLeftSidePanel";
import WorkspaceProgressSidePanel from "../../../partial/Workspace/MyWorkspaces/WorkspaceProgressSidePanel/WorkspaceProgressSidePanel";
import { replaceQueryInHistory } from "../../../../state/router/navigation";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "../../../../state/workspace/myworkspaces/list/navigation";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";

export const MyWorkspacesPage = ({
  isUserDataLoaded,
  title,
  clearList,
  loadLabelsAction,
  isConfigLoaded,
  isConfigError,
  isLeftPanelOpen,
  isRightPanelOpen,
  activeItemId,
  updateLeftPanel,
  windowSize,
}) => {
  const isPageLoaded = isConfigLoaded;
  const isPageLoadingError = isConfigError;

  const { category } = useParams();

  const categoryKey = _.findKey(
    MYWORKSPACES_CATEGORIES,
    (alias) => alias.urlKey === category
  );

  useEffect(() => {
    loadLabelsAction();
  }, [loadLabelsAction]);

  // clean up the store when unmount
  useEffect(() => {
    return () => {
      clearList();
    };
  }, [clearList]);

  if (!categoryKey) {
    return <NotFoundPage />;
  }

  const closeLeftPanel = () => {
    updateLeftPanel(false);
  };

  const closeRightPanel = () => {
    const tileElement = document.getElementById(
      MYWORKSPACES_TILE_ID + activeItemId
    );
    if (tileElement) tileElement.focus();
    replaceQueryInHistory({
      selected_ws: MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.selected_ws,
    });
  };

  return (
    <>
      <ConfigHandler />
      <Page
        header={<Header />}
        title={`${title} - ${APP_PAGES.WORKSPACE_MY_WORKSPACE.title}`}
        isPageLoaded={isPageLoaded}
        isPageLoadingError={isPageLoadingError}
      >
        <LocationChangeHandler isUserDataLoaded={isUserDataLoaded} />

        <div
          className={styles.root}
          data-test-id="Hs-Pages-WorkspaceMyWorkspacesPage"
        >
          <div>
            <LibrarySubMenu />
          </div>
          <div className={styles.content}>
            <PageLeftPanel
              isOpen={isLeftPanelOpen}
              focusableElementId={MYWORKSPACES_LEFT_PANEL_ID}
              dataTestIdPrefix="ws-LeftPanel"
              landmarkLabel="My Workspaces categories"
              onClose={closeLeftPanel}
            >
              <WSLeftSidePanel />
            </PageLeftPanel>

            <PageCentralPanel />

            {windowSize >= WINDOW_SIZE_TYPES.L && (
              <PageRightPanel
                isOpen={isRightPanelOpen}
                onClose={closeRightPanel}
                dataTestIdPrefix="ws-MyWorkspaces-ProgressPanel"
                landmarkLabel="Progress panel"
                focusableElementId={MYWORKSPACES_RIGHT_PANEL_ID}
              >
                <WorkspaceProgressSidePanel />
              </PageRightPanel>
            )}
            {windowSize < WINDOW_SIZE_TYPES.L && (
              <PageRightPanelModal
                isOpen={isRightPanelOpen}
                onClose={closeRightPanel}
                dataTestIdPrefix="ws-MyWorkspaces-ProgressPanel"
                landmarkLabel="Progress panel"
                focusableElementId={MYWORKSPACES_RIGHT_PANEL_ID}
              >
                <WorkspaceProgressSidePanel />
              </PageRightPanelModal>
            )}
          </div>
        </div>
      </Page>
    </>
  );
};

MyWorkspacesPage.propTypes = {
  isUserDataLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  clearList: PropTypes.func.isRequired,
  loadLabelsAction: PropTypes.func.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  isConfigError: PropTypes.bool.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  isRightPanelOpen: PropTypes.bool.isRequired,
  activeItemId: PropTypes.string,
  updateLeftPanel: PropTypes.func.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect(
  (state) => ({
    title: getPageTitle(state),
    isConfigLoaded: getIsConfigLoaded(state),
    isConfigError: getIsConfigError(state),
    isLeftPanelOpen: getLeftPanelOpenState(state),
    isRightPanelOpen: getRightPanelOpenState(state),
    activeItemId: getActiveItemId(state),
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({
    clearList: () => dispatch(clearList()),
    loadLabelsAction: () => dispatch(loadLabelsAction()),
    updateLeftPanel: (isOpen) => dispatch(updateLeftPanel(isOpen)),
  })
)(MyWorkspacesPage);
