import actionTypes from "../actionTypes";
import _ from "lodash";
import { updateAccessError } from "../app/actions";
import {
  patchAssignedClasses,
  getClassesAssignedToDoc,
  addItemToLibrary,
  removeItemFromLibrary,
  putStar,
  deleteStar,
} from "../../apiCalls/library";
import { resetLibraryItems } from "../library/library/actions";
import { resetMyResources } from "../library/myResources/actions";

const ITEMS_REFETCH_DELAY = 2000;

export const showResourceDetailsModal = (payload) => ({
  type: actionTypes.RESOURCES_SHOW_RESOURCE_DETAILS_MODAL,
  payload,
});

export const hideResourceDetailsModal = (payload) => ({
  type: actionTypes.RESOURCES_HIDE_RESOURCE_DETAILS_MODAL,
  payload,
});

export const updateResourceDetailsModalMode = (mode) => ({
  type: actionTypes.RESOURCES_UPDATE_RESOURCE_DETAILS_MODAL_MODE,
  payload: mode,
});

export const showResourceAssignClassModal = (payload) => ({
  type: actionTypes.RESOURCES_SHOW_RESOURCE_ASSIGN_CLASS_MODAL,
  payload,
});

export const hideResourceAssignClassModal = () => ({
  type: actionTypes.RESOURCES_HIDE_RESOURCE_ASSIGN_CLASS_MODAL,
});

export const showResourceAddToCollectionsModal = (payload) => ({
  type: actionTypes.RESOURCES_SHOW_RESOURCE_ADD_TO_COLLECTIONS_MODAL,
  payload,
});

export const hideResourceAddToCollectionsModal = () => ({
  type: actionTypes.RESOURCES_HIDE_RESOURCE_ADD_TO_COLLECTIONS_MODAL,
});

export const showResourceRemoveFromLibraryModal = (payload) => ({
  type: actionTypes.RESOURCES_SHOW_RESOURCE_REMOVE_FROM_LIBRARY_MODAL,
  payload,
});

export const hideResourceRemoveFromLibraryModal = () => ({
  type: actionTypes.RESOURCES_HIDE_RESOURCE_REMOVE_FROM_LIBRARY_MODAL,
});

export const showResourceDeleteModal = (payload) => ({
  type: actionTypes.RESOURCES_SHOW_RESOURCE_DELETE_MODAL,
  payload,
});

export const hideResourceDeleteModal = () => ({
  type: actionTypes.RESOURCES_HIDE_RESOURCE_DELETE_MODAL,
});

export const markResourceAsAddedToLibrary = (itemId) => ({
  type: actionTypes.RESOURCES_MARK_AS_ADDED_TO_LIBRARY,
  payload: itemId,
});

export const markResourceAsRemovedFromLibrary = (itemId) => ({
  type: actionTypes.RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY,
  payload: itemId,
});

export const markResourceAsStarred = (itemId) => ({
  type: actionTypes.RESOURCES_MARK_AS_STARRED,
  payload: itemId,
});

export const markResourceAsUnstarred = (itemId) => ({
  type: actionTypes.RESOURCES_MARK_AS_UNSTARRED,
  payload: itemId,
});

export const updateResourceAfterEdit = ({ itemId, newData }) => ({
  type: actionTypes.RESOURCES_UPDATE_RESOURCE_AFTER_EDIT,
  payload: { itemId, newData },
});

export const updateResourceAssignedClasses =
  ({ resourceId, addClasses, removeClasses }) =>
  (dispatch) => {
    return patchAssignedClasses({ resourceId, addClasses, removeClasses })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
        _.delay(() => {
          dispatch(resetMyResources());
        }, ITEMS_REFETCH_DELAY);
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const fetchClassesAssignedToDoc =
  ({ resourceId }) =>
  (dispatch) => {
    return getClassesAssignedToDoc({ resourceId })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
        return response.data;
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const addToLibraryAction =
  ({ itemId }) =>
  (dispatch) => {
    return addItemToLibrary({ itemId })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
        dispatch(markResourceAsAddedToLibrary(itemId));
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const removeFromLibraryAction =
  ({ itemId }) =>
  (dispatch) => {
    return removeItemFromLibrary({ itemId })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        }
        dispatch(markResourceAsRemovedFromLibrary(itemId));
        // clean up items list in Library and My Resources to trigger a reload
        _.delay(() => {
          dispatch(resetLibraryItems());
          dispatch(resetMyResources());
        }, ITEMS_REFETCH_DELAY);
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const starUnstarAction =
  ({ itemId, isStarred }) =>
  (dispatch) => {
    if (isStarred) {
      return deleteStar({ itemId })
        .then((response) => {
          if (response.status !== 200) {
            throw Error(response.status);
          }
          dispatch(markResourceAsUnstarred(itemId));
        })
        .catch((error) => {
          if (_.get(error, "response.status") === 403) {
            // Not a valid user, show 403 screen
            dispatch(updateAccessError(true));
          }
          throw Error(error);
        });
    } else {
      return putStar({ itemId })
        .then((response) => {
          if (response.status !== 200) {
            throw Error(response.status);
          }
          dispatch(markResourceAsStarred(itemId));
        })
        .catch((error) => {
          if (_.get(error, "response.status") === 403) {
            // Not a valid user, show 403 screen
            dispatch(updateAccessError(true));
          }
          throw Error(error);
        });
    }
  };
