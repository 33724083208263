import actionTypes from "../actionTypes";
import _ from "lodash";
import { RESOURCE_DETAILS_MODAL_MODE } from "./types";

const INIT_MODAL_STATE = {
  isModalOpen: false,
  modalData: {},
};

const populateModalData = (data) => ({
  isModalOpen: true,
  modalData: data,
});

export const INIT_STATE = {
  resourceDetails: {
    ...INIT_MODAL_STATE,
    mode: RESOURCE_DETAILS_MODAL_MODE.READ,
  },
  resourceAssignClass: INIT_MODAL_STATE,
  resourceAddToCollections: INIT_MODAL_STATE,
  resourceRemoveFromLibrary: INIT_MODAL_STATE,
  resourceDelete: INIT_MODAL_STATE,
};

const updateItemStatus = ({ state, itemId, statusName, value }) => {
  const stateModalData = _.get(state, "resourceDetails.modalData");
  const stateModalDataId = _.get(stateModalData, "ID");
  const stateModalDataStatuses = _.get(stateModalData, "Statuses");
  if (!_.isEmpty(stateModalData) && stateModalDataId === itemId) {
    return {
      ...state,
      resourceDetails: {
        ...state.resourceDetails,
        modalData: {
          ...stateModalData,
          Statuses: {
            ...stateModalDataStatuses,
            [statusName]: value,
          },
        },
      },
    };
  } else {
    return state;
  }
};

const resourcesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESOURCES_SHOW_RESOURCE_DETAILS_MODAL:
      return {
        ...state,
        resourceDetails: {
          ...populateModalData(_.get(action.payload, "item")),
          mode: _.get(action.payload, "mode"),
        },
      };

    case actionTypes.RESOURCES_HIDE_RESOURCE_DETAILS_MODAL:
      return {
        ...state,
        resourceDetails: {
          ...INIT_MODAL_STATE,
          mode: RESOURCE_DETAILS_MODAL_MODE.READ,
        },
      };

    case actionTypes.RESOURCES_UPDATE_RESOURCE_DETAILS_MODAL_MODE: {
      return {
        ...state,
        resourceDetails: {
          ...state.resourceDetails,
          mode: action.payload,
        },
      };
    }

    case actionTypes.RESOURCES_MARK_AS_ADDED_TO_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_REMOVED_FROM_LIBRARY:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsAddedToLibrary",
        value: false,
      });

    case actionTypes.RESOURCES_MARK_AS_STARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: true,
      });

    case actionTypes.RESOURCES_MARK_AS_UNSTARRED:
      return updateItemStatus({
        state,
        itemId: action.payload,
        statusName: "IsStarred",
        value: false,
      });

    case actionTypes.RESOURCES_SHOW_RESOURCE_ASSIGN_CLASS_MODAL:
      return {
        ...state,
        resourceAssignClass: populateModalData(action.payload),
      };

    case actionTypes.RESOURCES_HIDE_RESOURCE_ASSIGN_CLASS_MODAL:
      return {
        ...state,
        resourceAssignClass: INIT_MODAL_STATE,
      };

    case actionTypes.RESOURCES_SHOW_RESOURCE_ADD_TO_COLLECTIONS_MODAL:
      return {
        ...state,
        resourceAddToCollections: populateModalData(action.payload),
      };

    case actionTypes.RESOURCES_HIDE_RESOURCE_ADD_TO_COLLECTIONS_MODAL:
      return {
        ...state,
        resourceAddToCollections: INIT_MODAL_STATE,
      };

    case actionTypes.RESOURCES_SHOW_RESOURCE_REMOVE_FROM_LIBRARY_MODAL:
      return {
        ...state,
        resourceRemoveFromLibrary: populateModalData(action.payload),
      };

    case actionTypes.RESOURCES_HIDE_RESOURCE_REMOVE_FROM_LIBRARY_MODAL:
      return {
        ...state,
        resourceRemoveFromLibrary: INIT_MODAL_STATE,
      };

    case actionTypes.RESOURCES_SHOW_RESOURCE_DELETE_MODAL:
      return {
        ...state,
        resourceDelete: populateModalData(action.payload),
      };

    case actionTypes.RESOURCES_HIDE_RESOURCE_DELETE_MODAL:
      return {
        ...state,
        resourceDelete: INIT_MODAL_STATE,
      };

    case actionTypes.RESOURCES_UPDATE_RESOURCE_AFTER_EDIT: {
      const { itemId, newData } = action.payload;
      if (itemId === _.get(state, "resourceDetails.modalData.ID")) {
        return {
          ...state,
          resourceDetails: {
            ...state.resourceDetails,
            modalData: {
              ...state.resourceDetails.modalData,
              ...newData,
            },
          },
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default resourcesReducer;
