import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import styles from "./Button.module.scss";
import HapReactIcon from "../icon/hapReactIcon";
import { VisuallyHidden } from "../VisuallyHidden";
import { FormattedMessage } from "react-intl";

export const BUTTON_SIZES = {
  XSMALL: "xsmall",
  SMALL: "small",
  REGULAR: "regular",
  LARGE: "large",
};

export const BUTTON_MIN_HEIGHT = {
  [BUTTON_SIZES.XSMALL]: 28,
  [BUTTON_SIZES.SMALL]: 32,
  [BUTTON_SIZES.REGULAR]: 40,
  [BUTTON_SIZES.LARGE]: 48,
};

export const BUTTON_TYPES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  TERTIARY: "tertiary",
  OUTLINED: "outlined",
  DANGER_OUTLINED: "dangerOutlined",
};

export const BUTTON_OUTLINE_TYPES = {
  DASHED: "dashed",
  SOLID: "solid",
};

const BUTTON_LAYOUTS = {
  LABELONLY: "labelonly",
  ICONONLY: "icononly",
  ICONLABEL: "iconlabel",
  LABELICON: "labelicon",
  ICONLABELICON: "iconlabelicon",
};

const iconSize = 40;

const getButtonContent = ({
  label,
  icon,
  rightIcon,
  layout,
  iconSize,
  altProp,
  spin,
  iconComponent,
}) => {
  switch (layout) {
    case BUTTON_LAYOUTS.ICONONLY:
      return iconComponent ? (
        iconComponent
      ) : (
        <HapReactIcon
          svg={icon}
          width={iconSize}
          height={iconSize}
          className={classnames(styles.icon, styles.onlyIcon)}
          {...altProp}
        />
      );
    case BUTTON_LAYOUTS.ICONLABELICON:
      return (
        <React.Fragment>
          {iconComponent ? (
            <div className={styles.iconComponent}>{iconComponent}</div>
          ) : (
            <HapReactIcon
              svg={icon}
              width={iconSize}
              height={iconSize}
              className={classnames(styles.icon, styles.leftIcon)}
              {...altProp}
            />
          )}
          <span className={styles.label}>{label}</span>
          <HapReactIcon
            svg={rightIcon}
            width={iconSize}
            height={iconSize}
            className={classnames(styles.icon, styles.rightIcon)}
          />
        </React.Fragment>
      );
    case BUTTON_LAYOUTS.ICONLABEL:
      return (
        <React.Fragment>
          {iconComponent ? (
            <div className={styles.iconComponent}>{iconComponent}</div>
          ) : (
            <HapReactIcon
              svg={icon}
              width={iconSize}
              height={iconSize}
              className={classnames(styles.icon, styles.leftIcon)}
              spin={spin}
              {...altProp}
            />
          )}
          <span className={styles.label}>{label}</span>
        </React.Fragment>
      );
    case BUTTON_LAYOUTS.LABELICON:
      return (
        <React.Fragment>
          <span className={styles.label}>{label}</span>
          <HapReactIcon
            svg={rightIcon}
            width={iconSize}
            height={iconSize}
            className={classnames(styles.icon, styles.rightIcon)}
            {...altProp}
          />
        </React.Fragment>
      );
    case BUTTON_LAYOUTS.LABELONLY:
    default:
      return <span className={styles.label}>{label}</span>;
  }
};

const getButtonLayout = ({
  label,
  rightIcon,
  icon,
  isLoading,
  iconComponent,
}) => {
  if (!label) {
    return BUTTON_LAYOUTS.ICONONLY;
  } else {
    if (!rightIcon && (icon || iconComponent || isLoading)) {
      return BUTTON_LAYOUTS.ICONLABEL;
    } else {
      if (rightIcon && (icon || iconComponent || isLoading)) {
        return BUTTON_LAYOUTS.ICONLABELICON;
      } else {
        if (rightIcon) {
          return BUTTON_LAYOUTS.LABELICON;
        }
      }
    }
  }

  return BUTTON_LAYOUTS.LABELONLY;
};

export const Button = React.forwardRef((props, ref) => {
  const {
    size = BUTTON_SIZES.REGULAR,
    type = BUTTON_TYPES.PRIMARY,
    label = "",
    icon = "",
    iconComponent,
    rightIcon = "",
    onAction = () => {},
    onClick,
    onKeyDown,
    isDisabled = false,
    isLoading = false,
    dataTestId,
    className,
    tabIndex,
    alt = "",
    outlineType = BUTTON_OUTLINE_TYPES.DASHED,
    id,
    isFullWidth = false,
    style,
  } = props;

  const layout = getButtonLayout({
    label,
    rightIcon,
    icon,
    isLoading,
    iconComponent,
  });

  const tabIndexProp = tabIndex && !isDisabled ? { tabIndex: tabIndex } : {};
  const altProp = alt ? { alt: alt } : {};
  const disabledProp = isDisabled ? { disabled: "disabled" } : {};
  const ariaProps = _.pickBy(props, (value, key) => _.startsWith(key, "aria-"));
  const dataProps = _.pickBy(props, (value, key) => _.startsWith(key, "data-"));
  const a11yProps = _.pickBy(props, (value, key) => _.startsWith(key, "role")); //adds button role if provided
  // TODO clean up dataTestId Prop
  const dataTestIdProp = dataTestId ? { "data-test-id": dataTestId } : {};
  return (
    <button
      type="button"
      className={classnames(
        styles.root,
        styles[type],
        styles[outlineType],
        styles[size],
        styles[layout],
        {
          [styles.disabled]: isDisabled,
          [styles.loading]: isLoading,
          [styles.fullWidth]: isFullWidth,
          [styles.hasIconComponent]: iconComponent,
        },
        className
      )}
      onClick={(e) => {
        if (!isDisabled) {
          // onAction is deprecated, use onClick instead
          // TODO clean up onAction Prop
          onClick ? onClick(e) : onAction(e);
        }
      }}
      onKeyDown={onKeyDown}
      ref={ref}
      id={id}
      style={style}
      {...dataTestIdProp}
      {...tabIndexProp}
      {...disabledProp}
      {...ariaProps}
      {...dataProps}
      {...a11yProps}
    >
      {getButtonContent({
        label,
        icon: isLoading ? "loader" : icon,
        rightIcon,
        layout,
        iconSize,
        altProp,
        iconComponent,
      })}
    </button>
  );
});

Button.propTypes = {
  size: PropTypes.oneOf(_.values(BUTTON_SIZES)),
  type: PropTypes.oneOf(_.values(BUTTON_TYPES)),
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAction: PropTypes.func, // DEPRECATED, use onClick instead
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  dataTestId: PropTypes.string, // DEPRECATED, use data-test-id instead
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  alt: PropTypes.string,
  outlineType: PropTypes.oneOf(_.values(BUTTON_OUTLINE_TYPES)),
  id: PropTypes.string,
  isFullWidth: PropTypes.bool,
  iconComponent: PropTypes.node,
};

export const ButtonLink = React.forwardRef((props, ref) => {
  const {
    size = BUTTON_SIZES.REGULAR,
    type = BUTTON_TYPES.PRIMARY,
    label = "",
    icon = "",
    rightIcon = "",
    isDisabled = false,
    dataTestId,
    className,
    tabIndex,
    alt = "",
    outlineType = BUTTON_OUTLINE_TYPES.DASHED,
    href = "",
    target,
    rel,
    id,
    onClick = () => {},
    isFullWidth = false,
    spin = false,
    ...rest
  } = props;
  const layout = getButtonLayout({ label, rightIcon, icon, isLoading: false });

  const tabIndexProp = tabIndex && !isDisabled ? { tabIndex: tabIndex } : {};
  const altProp = alt ? { alt: alt } : {};
  const disabledProp = isDisabled ? { disabled: "disabled" } : {};
  const ariaProps = _.pickBy(props, (value, key) => _.startsWith(key, "aria-"));
  const dataProps = _.pickBy(props, (value, key) => _.startsWith(key, "data-"));
  // TODO clean up dataTestId Prop
  const dataTestIdProp = dataTestId ? { "data-test-id": dataTestId } : {};

  return (
    <a
      className={classnames(
        styles.root,
        styles[type],
        styles[outlineType],
        styles[size],
        styles[layout],
        {
          [styles.disabled]: isDisabled,
          [styles.fullWidth]: isFullWidth,
        },
        className
      )}
      ref={ref}
      href={href}
      target={target}
      rel={rel}
      id={id}
      onClick={onClick}
      {...rest}
      {...dataTestIdProp}
      {...tabIndexProp}
      {...disabledProp}
      {...ariaProps}
      {...dataProps}
    >
      {getButtonContent({
        label,
        icon,
        rightIcon,
        layout,
        iconSize,
        altProp,
        spin,
      })}
      {target === "_blank" && (
        <VisuallyHidden>
          <FormattedMessage defaultMessage=", opens in new tab" id="YaGAJi" />
        </VisuallyHidden>
      )}
    </a>
  );
});

ButtonLink.propTypes = {
  size: PropTypes.oneOf(_.values(BUTTON_SIZES)),
  type: PropTypes.oneOf(_.values(BUTTON_TYPES)),
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string, // DEPRECATED, use data-test-id instead
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  alt: PropTypes.string,
  outlineType: PropTypes.oneOf(_.values(BUTTON_OUTLINE_TYPES)),
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  isFullWidth: PropTypes.bool,
  spin: PropTypes.bool,
};

export default Button;
