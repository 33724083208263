import PropTypes from "prop-types";

export type Collection = {
  name: string;
  id: string;
};

export type CollectionsState = {
  items: Collection[];
  isLoading: boolean;
  isLoaded: boolean;
  isLoadingError: boolean;
  isCollectionsInEditMode: boolean;
};

export const collectionItemType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const COLLECTIONS_MAX_AMOUNT = 200;
