import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./SendMessageModal.module.scss";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../state/shared/selectors";
import { sendMessageAction } from "../../../../state/highlights/sendMessage/actions";
import { getUserNameFormat } from "../../../../state/user/selectors";
import { SEND_MESSAGE_RECIPIENT_TYPES } from "../../../../state/highlights/sendMessage/types";
import {
  STUDENT_TYPE,
  STUDENT_SEND_MESSAGE_TYPE,
} from "../../../../state/highlights/students/types";
import {
  getGroupStatusInfo,
  getUserGroups,
} from "../../../../state/shared/userGroups/selectors";
import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import ModalFormStudentPicker from "../ModalFormStudentPicker/ModalFormStudentPicker";
import ModalFormTextField from "../ModalFormTextField/ModalFormTextField";
import {
  getSendMessageModalStatus,
  getSendMessageModalErrorStatus,
  getSendMessageModalSuccessStatus,
  getSendMessageSelectedStudent,
  getSendMessageSelectedRecipientType,
} from "../../../../state/highlights/sendMessage/selectors";
import { USER_GROUP_TYPE } from "../../../../state/shared/userGroups/types";
import { hideSendMessageModal } from "../../../../state/highlights/sendMessage/actions";
import Alerts from "@hapara/ui/src/atomic/Alerts/Alerts";
import Alert, { ALERT_TYPES } from "@hapara/ui/src/atomic/AlertDeprecated";
import WarningMessage from "@hapara/ui/src/atomic/WarningMessage/WarningMessage";
import { getIsOutsideSchoolHours } from "../../../../state/highlights/config/selectors";
import { getStudentConfigsList } from "../../../../state/highlights/studentConfigs/selectors";
import { FocusContext } from "@hapara/ui/src/components/utils";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getFFByName } from "../../../../state/app/selectors";

const SendMessageModal = ({
  isModalOpen = false,
  isSendError = false,
  isSendSuccess = false,
  onModalClose,
  onSendMessage,
  selectedClassId,
  students,
  nameFormat,
  userGroups,
  selectedStudent,
  selectedRecipientType = SEND_MESSAGE_RECIPIENT_TYPES.CLASS,
  isOutsideSchoolHours,
}) => {
  const intl = useIntl();
  const [recipientType, setRecipientType] = useState(selectedRecipientType);
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const [studentIds, setStudentIds] = useState(
    selectedRecipientType !== SEND_MESSAGE_RECIPIENT_TYPES.CLASS
      ? []
      : _.map(students, (student) => student.Id)
  );
  const [groupUrns, setGroupUrns] = useState([]);
  const [messageText, setMessageText] = useState("");
  const focusContext = useContext(FocusContext);

  const hasModalCopyUpdateFlag = useSelector(
    getFFByName("GBE-02-nav-bar-button-update")
  );

  const isSendButtonDisabled = useMemo(() => {
    if (messageText === "") {
      return true;
    }
    if (
      recipientType === SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS &&
      !studentIds.length
    ) {
      return true;
    }
    if (
      recipientType === SEND_MESSAGE_RECIPIENT_TYPES.GROUPS &&
      (!studentIds.length || !groupUrns.length)
    ) {
      return true;
    }
    if (isOutsideSchoolHours) {
      return true;
    }
    return false;
  }, [messageText, recipientType, studentIds, groupUrns, isOutsideSchoolHours]);

  const titleOld = !_.isEmpty(selectedStudent)
    ? intl.formatMessage(
        {
          defaultMessage: "Send a message to {fullName}",
          id: "RkuMNz",
        },
        {
          fullName: selectedStudent.FullName,
        }
      )
    : intl.formatMessage({
        defaultMessage: "Send a message to students",
        id: "Z8bwz4",
      });

  const titleNew = !_.isEmpty(selectedStudent)
    ? intl.formatMessage(
        {
          defaultMessage: "Send an announcement to {fullName}",
          id: "kkPefA",
        },
        {
          fullName: selectedStudent.FullName,
        }
      )
    : intl.formatMessage({
        defaultMessage: "Send an announcement to students",
        id: "GAoFeh",
      });

  const title = hasModalCopyUpdateFlag ? titleNew : titleOld;

  const dataTestPrefix = "hl-SendMessageModal";

  useEffect(() => {
    setMessageText("");
    setSendingInProgress(false);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    if (focusContext.focusRef && focusContext.focusRef.current) {
      focusContext.focusRef.current.focus();
    }
    onModalClose();
    setRecipientType(SEND_MESSAGE_RECIPIENT_TYPES.CLASS);
  }, [onModalClose, focusContext.focusRef]);

  // close modal on success
  useEffect(() => {
    if (isSendSuccess) {
      handleCloseModal();
    }
  }, [isSendSuccess, handleCloseModal]);

  // update session data when individual student is selected
  useEffect(() => {
    if (!_.isEmpty(selectedStudent)) {
      setRecipientType(SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS);
      setStudentIds([selectedStudent.Id]);
    }
  }, [selectedStudent]);

  const switchRecipientType = (rt) => {
    setRecipientType(rt);
    // when recipientType changes, update studentIds for "class" type
    if (rt === SEND_MESSAGE_RECIPIENT_TYPES.CLASS) {
      setStudentIds(_.map(students, (student) => student.Id));
    }
    // when recipientType changes, update studentIds for "students" type
    if (rt === SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS) {
      setStudentIds([]);
    }
    // when recipientType changes, update studentIds for "groups" type
    if (rt === SEND_MESSAGE_RECIPIENT_TYPES.GROUPS) {
      setStudentIds([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingInProgress(true);
    let rt =
      recipientType === SEND_MESSAGE_RECIPIENT_TYPES.CLASS
        ? recipientType
        : SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS;
    if (!_.isEmpty(selectedStudent)) {
      rt = SEND_MESSAGE_RECIPIENT_TYPES.STUDENTS;
    }
    const students = !_.isEmpty(selectedStudent)
      ? [selectedStudent.Id]
      : studentIds;
    onSendMessage({
      messageText,
      recipientType: rt,
      students: students,
      classId: selectedClassId,
    });
  };

  return (
    <ModalDeprecated
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      hasEmbeddedHeader={false}
      className={styles.root}
      dataTestPrefix={dataTestPrefix}
      contentLabel={title}
    >
      <div className={styles.modalTitle}>
        <div className={styles.modalTitleHeaderContainer}>
          <h1
            data-test-id={`${dataTestPrefix}-Header`}
            className={styles.modalTitleHeader}
          >
            {title}
          </h1>
          <Button
            icon="cross"
            onClick={handleCloseModal}
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.SMALL}
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              id: "rbrahO",
            })}
            data-test-id={`${dataTestPrefix}-Button-Close`}
            className={styles.modalTitleClose}
          />
        </div>
        <WarningMessage
          isVisible={isOutsideSchoolHours}
          icon="circle-information"
          className={styles.modalTitleWarning}
          dataTestId={`${dataTestPrefix}-OutOfSchoolHoursWarning`}
        >
          {hasModalCopyUpdateFlag ? (
            <FormattedMessage
              defaultMessage=" School is over for the day. Announcements can only be sent to students during school hours."
              id="5Qf8j/"
            />
          ) : (
            <FormattedMessage
              defaultMessage=" School is over for the day. Messages can only be sent to students during school hours."
              id="HOpWOc"
            />
          )}
        </WarningMessage>
      </div>
      <div className={styles.modalBody}>
        <ModalFormTextField
          text={messageText}
          onTextChange={(m) => setMessageText(m)}
          hasModalCopyUpdateFlag={hasModalCopyUpdateFlag}
          dataTestPrefix={dataTestPrefix}
        />
        {_.isEmpty(selectedStudent) && (
          <ModalFormStudentPicker
            recipientType={recipientType}
            onRecipientTypeChange={switchRecipientType}
            studentIds={studentIds}
            onStudentIdsChange={setStudentIds}
            groupUrns={groupUrns}
            onGroupUrnsChange={setGroupUrns}
            dataTestPrefix={dataTestPrefix}
            nameFormat={nameFormat}
            userGroups={userGroups}
            getGroupStatusInfo={getGroupStatusInfo}
            blockTitle={
              hasModalCopyUpdateFlag
                ? intl.formatMessage({
                    defaultMessage: "Who is this announcement for?",
                    id: "a7NULy",
                  })
                : intl.formatMessage({
                    defaultMessage: "Who is this message for?",
                    id: "iax3Mw",
                  })
            }
            studentsInGbSessionWarning={null}
          />
        )}
      </div>
      <div className={styles.actions}>
        <Alerts>
          <Alert
            type={ALERT_TYPES.FAILURE}
            isVisible={isSendError}
            className={styles.alert}
          >
            {hasModalCopyUpdateFlag ? (
              <FormattedMessage
                defaultMessage="Sorry, there was a problem sending this announcement. Please try again."
                id="04S5j8"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Sorry, there was a problem sending this message. Please try again."
                id="rI3HY5"
              />
            )}
          </Alert>
        </Alerts>
        <div className={styles.actionsInner}>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "47FYwb",
            })}
            onClick={handleCloseModal}
            data-test-id={`${dataTestPrefix}-Button-Cancel`}
            type={BUTTON_TYPES.SECONDARY}
            className={styles.cancelButton}
          />
          <Button
            label={
              hasModalCopyUpdateFlag
                ? intl.formatMessage({
                    defaultMessage: "Send",
                    id: "9WRlF4",
                  })
                : intl.formatMessage({
                    defaultMessage: "Send message",
                    id: "Xx0WZV",
                  })
            }
            onClick={handleSubmit}
            data-test-id={`${dataTestPrefix}-Button-Start`}
            className={styles.sendButton}
            isDisabled={isSendButtonDisabled}
            isLoading={sendingInProgress && !isSendError}
          />
        </div>
      </div>
    </ModalDeprecated>
  );
};

SendMessageModal.propTypes = {
  isModalOpen: PropTypes.bool,
  isSendError: PropTypes.bool,
  isSendSuccess: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  selectedClassId: PropTypes.string,
  students: PropTypes.arrayOf(STUDENT_TYPE),
  nameFormat: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(USER_GROUP_TYPE),
  selectedStudent: STUDENT_SEND_MESSAGE_TYPE,
  selectedRecipientType: PropTypes.oneOf(
    _.values(SEND_MESSAGE_RECIPIENT_TYPES)
  ),
  isOutsideSchoolHours: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    isModalOpen: getSendMessageModalStatus(state),
    isSendError: getSendMessageModalErrorStatus(state),
    isSendSuccess: getSendMessageModalSuccessStatus(state),
    selectedClassId: getClassId(state),
    students: getStudentConfigsList(state),
    nameFormat: getUserNameFormat(state),
    userGroups: getUserGroups(state),
    selectedStudent: getSendMessageSelectedStudent(state),
    selectedRecipientType: getSendMessageSelectedRecipientType(state),
    isOutsideSchoolHours: getIsOutsideSchoolHours(state),
  }),
  (dispatch) => ({
    onModalClose: () => dispatch(hideSendMessageModal()),
    onSendMessage: ({ messageText, recipientType, students, classId }) =>
      dispatch(
        sendMessageAction({
          messageText,
          recipientType,
          students,
          classId,
        })
      ),
  })
)(SendMessageModal);
