import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import styles from "./PageLeftPanel.module.scss";
import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../state/app/types";
import { getWindowSize } from "../../../state/app/selectors";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";

export const PageLeftPanel = ({
  onClose = () => {},
  isOpen,
  windowSize,
  children,
  focusableElementId,
  dataTestIdPrefix = "hs-LeftPanel",
  landmarkLabel,
  closeButtonLabel = "Close",
}) => {
  const [panelTopRef, hasPanelReachedWindowTop] = useInView({ threshold: 0 });

  const closePanel = () => {
    onClose();
  };

  return (
    <>
      {isOpen && windowSize < WINDOW_SIZE_TYPES.M && (
        <div className={styles.overlay} onClick={closePanel} />
      )}
      <div className={styles.root}>
        <div ref={panelTopRef} />
        <div
          className={classnames(styles.rootInner, {
            [styles.sticky]: !hasPanelReachedWindowTop,
            [styles.open]: isOpen,
          })}
        >
          {isOpen && windowSize < WINDOW_SIZE_TYPES.M && (
            <Button
              icon="cross"
              type={BUTTON_TYPES.TERTIARY}
              size={BUTTON_SIZES.SMALL}
              onClick={closePanel}
              data-test-id={`${dataTestIdPrefix}-Button-Close`}
              aria-label={closeButtonLabel}
              className={styles.contentLeftCloseButton}
            />
          )}
          <nav
            className={styles.scrollable}
            id={focusableElementId}
            tabIndex={-1}
            aria-label={landmarkLabel}
            data-test-id={`${dataTestIdPrefix}-LeftSideMenu-Panel`}
          >
            {(isOpen || windowSize >= WINDOW_SIZE_TYPES.M) && children}
          </nav>
        </div>
      </div>
    </>
  );
};

PageLeftPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  focusableElementId: PropTypes.string,
  dataTestIdPrefix: PropTypes.string,
  landmarkLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  windowSize: getWindowSize(state),
}))(PageLeftPanel);
