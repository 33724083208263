import React from "react";
import { createIcon } from "./utils/createIcon";

export const FilterIcon = createIcon(
  <path
    d="m10.17 7.05-4.34 4.33m0-4.33 4.34 4.33M14.5 5.07h-13m0 .2v5.46c0 1.1 0 1.64.21 2.06.19.36.49.66.85.85.42.21.97.21 2.06.21h6.76c1.1 0 1.64 0 2.06-.21.36-.19.66-.49.85-.85.21-.42.21-.97.21-2.06V5.27c0-1.1 0-1.64-.21-2.06a1.95 1.95 0 0 0-.85-.85c-.42-.21-.97-.21-2.06-.21H4.62c-1.1 0-1.64 0-2.06.21-.36.19-.66.49-.85.85-.21.42-.21.97-.21 2.06Z"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
