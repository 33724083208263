import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

import styles from "./WorkspaceLabelsInsideCategory.module.scss";
import {
  getLabels,
  areThereAnyLabels,
} from "../../../../../state/workspace/myworkspaces/labels/selectors";
import { labelItemType } from "../../../../../state/workspace/myworkspaces/labels/types";
import LabelItemInsideCategory from "../LabelItemInsideCategory/LabelItemInsideCategory";
import { MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES } from "../../../../../state/workspace/myworkspaces/list/navigation";
import { MYWORKSPACES_DEFAULT_LABEL } from "../../../../../state/workspace/myworkspaces/list/types";

const WorkspaceLabelsInsideCategory = ({
  hasAllLabels,
  inEditMode,
  labels,
  areThereAnyLabels,
}) => {
  const allLabels = {
    Id: MYWORKSPACES_DEFAULT_LABEL.id,
    Name: MYWORKSPACES_DEFAULT_LABEL.name,
  };
  const clearSelectionLabel = {
    Id: MYWORKSPACES_URL_PARAMS_DEFAULT_VALUES.label,
    Name: "Clear selection",
    clear: true,
  };

  if (!areThereAnyLabels)
    // show this message only in NOT Archived categories
    return (
      <React.Fragment>
        {hasAllLabels && (
          <div
            className={styles.noLabelsContainer}
            data-test-id="Ws-Labels-EmptyContainer"
          >
            <span className={styles.bold}>
              <FormattedMessage
                defaultMessage="No labels created yet."
                id="dXrkDW"
              />
            </span>
            <span>
              <FormattedMessage
                defaultMessage="Labels you’ve created will be listed here, and only visible to you."
                id="OAlDhU"
              />
            </span>
          </div>
        )}
      </React.Fragment>
    );

  return (
    <div className={styles.root} data-test-id="Ws-Labels-Container">
      {hasAllLabels && !inEditMode && (
        <LabelItemInsideCategory item={allLabels} key={allLabels.name} />
      )}
      {_.map(labels, (label) => (
        <LabelItemInsideCategory
          item={label}
          key={`${label.Id}_${label.Name}`}
          inEditMode={inEditMode}
        />
      ))}
      {!hasAllLabels && (
        <LabelItemInsideCategory
          item={clearSelectionLabel}
          key="Clear selection"
        />
      )}
    </div>
  );
};

WorkspaceLabelsInsideCategory.propTypes = {
  hasAllLabels: PropTypes.bool,
  inEditMode: PropTypes.bool,
  labels: PropTypes.arrayOf(labelItemType),
  areThereAnyLabels: PropTypes.bool,
};

export default connect((state) => ({
  labels: getLabels(state),
  areThereAnyLabels: areThereAnyLabels(state),
}))(WorkspaceLabelsInsideCategory);
