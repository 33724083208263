import React from "react";
import styles from "./AppLoadingPage.module.scss";
import {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
  Button,
} from "@hapara/ui/src/atomic/Button/Button";
import PropTypes from "prop-types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { ALT_TEXT } from "../../../state/app/types";
import LinearProgress from "@mui/material/LinearProgress";
import { FormattedMessage } from "react-intl";
import { HaparaText } from "@hapara/ui/src/atomic/HaparaText/HaparaText";

type AppLoadingPageProps = {
  isError: boolean;
  errorContent?: React.ReactNode;
  loadingContent?: React.ReactNode;
  isTryAgainVisible?: boolean;
};

const AppLoadingPage = ({
  isError,
  errorContent = (
    <FormattedMessage
      defaultMessage="We're having trouble loading {companyName}."
      id="/voAHj"
      values={{ companyName: <HaparaText /> }}
    />
  ),
  loadingContent = (
    <FormattedMessage
      defaultMessage="Loading {companyName}"
      id="6748uv"
      values={{ companyName: <HaparaText /> }}
    />
  ),
  isTryAgainVisible = true,
}: AppLoadingPageProps): JSX.Element => {
  return (
    <main className={styles.root} data-test-id="Hs-Pages-LoadingPage">
      <div aria-live="polite" role="status" className={styles.content}>
        <span lang="mi">
          <HapReactIcon
            className={styles.logo}
            svg="logo-hapara-small"
            width={74}
            height={74}
            alt={ALT_TEXT.HAPARA_LOGO}
          />
        </span>

        {!isError && (
          <LinearProgress className={styles.progressbar} aria-hidden={true} />
        )}

        <h1 className={styles.header}>
          {isError ? errorContent : loadingContent}
        </h1>

        {isError && isTryAgainVisible && (
          <Button
            label={<FormattedMessage defaultMessage="Try again" id="FazwRl" />}
            onClick={() => window.location.reload()}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            size={BUTTON_SIZES.LARGE}
            className={styles.tryAgainBtn}
          />
        )}
      </div>

      {isError && (
        <div className={styles.footer}>
          <FormattedMessage
            defaultMessage="If problems persist, please <link>contact Support for help.</link>"
            id="89rAak"
            values={{
              contactSupportLink: (chunks) => (
                <a
                  className={styles.footerLink}
                  href="https://support.hapara.com/hc/en-us/requests/new"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </div>
      )}
    </main>
  );
};

AppLoadingPage.propTypes = {
  isError: PropTypes.bool.isRequired,
  isTryAgainVisible: PropTypes.bool,
  errorContent: PropTypes.node,
  loadingContent: PropTypes.node,
};

export default AppLoadingPage;
