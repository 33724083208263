import React from "react";
import classnames from "classnames";
import styles from "./ModalFormGClassroomShare.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Checkbox from "@hapara/ui/src/atomic/Checkbox/Checkbox";
import { screenshotTakenTimeHelper } from "../../../../utils";
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { useIntl } from "react-intl";

interface GClassroomShareItemProps {
  itemID: string;
  itemLink: string;
  itemName: string;
  itemType: string;
  itemPosted: string;
  itemAssignees: string[];
  itemAttachmentCount: number;
  hasFocus: boolean;
  isAlert: boolean;
  isSelected: boolean;
  dataTestPrefix: string;
  setSelectedAction: (itemID: string) => void;
  setSelectedItemURL: (itemLink: string) => void;
  setSelectedItemRecipients: (itemAssignees: string[]) => void;
}

const GClassroomShareItem = ({
  itemID,
  isAlert,
  itemType,
  hasFocus,
  itemName,
  itemLink,
  itemPosted,
  isSelected,
  itemAssignees,
  dataTestPrefix,
  setSelectedAction,
  setSelectedItemURL,
  itemAttachmentCount,
  setSelectedItemRecipients,
}: GClassroomShareItemProps) => {
  const intl = useIntl();

  const hasAttachments = itemAttachmentCount > 0;

  const handleSelect = () => {
    setSelectedAction(!isSelected ? itemID : "");
    setSelectedItemRecipients(!isSelected ? itemAssignees : []);
    setSelectedItemURL(!isSelected ? itemLink : "");
  };

  const itemTypeAltText = {
    ASSIGNMENT: intl.formatMessage({
      id: "0n634D",
      defaultMessage: "assignment",
    }),
    MATERIAL: intl.formatMessage({
      id: "iLMajD",
      defaultMessage: "assignment material",
    }),
    SHORT_ANSWER_QUESTION: intl.formatMessage({
      id: "QfdEwK",
      defaultMessage: "assignment question",
    }),
  };

  const itemTypeSvgName = {
    ASSIGNMENT: "assignment",
    MATERIAL: "assignment-material",
    SHORT_ANSWER_QUESTION: "assignment-question",
  };

  return (
    <tr
      className={classnames(styles.tableItem, {
        [styles.isSelected]: isSelected,
        [styles.isAlert]: isAlert,
      })}
    >
      <td className={styles.itemClasswork}>
        <div
          className={styles.itemIconRondel}
          data-test-id={`${dataTestPrefix}-GCLinksItem-Icon`}
        >
          <HapReactIcon
            width={18}
            height={18}
            svg={itemTypeSvgName[itemType]}
            alt={itemTypeAltText[itemType]}
          />
        </div>
        <Checkbox
          label=""
          checked={isSelected}
          onChange={handleSelect}
          dataTestIdPrefix={`${dataTestPrefix}-GCLinksItem`}
          hasFocus={hasFocus}
        />
        <div
          className={classnames(
            styles.itemName,
            { [styles.isAlert]: isAlert },
            { [styles.hasAttachments]: hasAttachments }
          )}
          data-test-id={`${dataTestPrefix}-GCLinksItem-Title`}
        >
          {itemName}
          {isAlert && (
            <div className={styles.alertText}>
              {intl.formatMessage({
                id: "Y2GG7b",
                defaultMessage: "Cannot display number of attachments",
              })}
            </div>
          )}
        </div>

        {hasAttachments && (
          <div className={styles.itemAttachments}>
            <HapReactIcon
              width={9}
              height={16}
              svg="attachment"
              className={styles.attachmentIcon}
              alt={intl.formatMessage({
                id: "LrLqkd",
                defaultMessage: "attachment",
              })}
            />
            <div
              className={styles.attachmentText}
              data-test-id={`${dataTestPrefix}-GCLinksItem-Attachments`}
            >
              {itemAttachmentCount}
            </div>
          </div>
        )}
      </td>
      <td
        className={styles.itemAssignees}
        data-test-id={`${dataTestPrefix}-GCLinksItem-AssignedCount`}
      >
        {itemAssignees.length === 0
          ? intl.formatMessage({
              id: "zdSuNW",
              defaultMessage: "All students",
            })
          : intl.formatMessage(
              {
                id: "jWH4Ql",
                defaultMessage:
                  "{count, plural, one {# student} other {# students}}",
              },
              {
                count: itemAssignees.length,
              }
            )}
      </td>
      <td
        className={styles.itemPosted}
        data-test-id={`${dataTestPrefix}-GCLinksItem-Posted`}
      >
        {screenshotTakenTimeHelper(itemPosted, "MMM D, YYYY")}
      </td>
      <td className={styles.itemLink}>
        <a
          href={itemLink}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={-1}
        >
          <Button
            size={BUTTON_SIZES.SMALL}
            icon="arrow-external-link"
            type={BUTTON_TYPES.SECONDARY}
            alt={intl.formatMessage({
              id: "pQjUQi",
              defaultMessage: "link",
            })}
            dataTestId={`${dataTestPrefix}-GCLinksItem-LinkButton`}
          />
        </a>
      </td>
    </tr>
  );
};

export default GClassroomShareItem;
