export const dateTimeFormats = [
  "default",
  "shortDate",
  "timeOnly",
  "longDate",
  "dateOnly",
] as const;

export type DateTimeFormat = (typeof dateTimeFormats)[number];

export const dateTimeFormatOptions: {
  [key in DateTimeFormat]: Intl.DateTimeFormatOptions;
} = {
  default: {
    dateStyle: "medium",
    timeStyle: "short",
  },
  shortDate: {
    month: "short",
    day: "numeric",
  },
  longDate: {
    dateStyle: "long",
    timeStyle: "short",
  },
  timeOnly: {
    hour: "numeric",
    minute: "numeric",
  },
  dateOnly: {
    dateStyle: "medium",
  },
};
