import { useState, useLayoutEffect, MutableRefObject } from "react";
import { useResizeObserver } from "../useResizeObserver";

/** This hook takes the reference provided and returns true if it has overflowing content. */
export const useOverflow = <T extends HTMLElement | null>(
  containerRef: MutableRefObject<T | null>
) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const { size } = useResizeObserver(containerRef);

  useLayoutEffect(() => {
    const currentOverflow = checkOverflow(containerRef.current);
    if (containerRef && currentOverflow !== isOverflowing) {
      setIsOverflowing(currentOverflow);
    }
  }, [size, containerRef]);

  const checkOverflow = (e: T | null) => {
    if (e) {
      return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }
    return false;
  };

  return {
    isOverflowing,
  };
};
