import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./hapReactIcon.module.scss";
import FallbackIcon from "@hapara/assets/src/icons/react/fallback-icon.tsx";

// TODO: use @hapara/assets
import { iconList } from "../../../../assets/src/icons/index.ts";

function getExistingSvg(iconName) {
  if (!iconList.includes(iconName)) {
    iconName = "fallback-icon";
  }
  return iconName;
}
const spinnables = ["spinner", "loader"];

// TODO: Currently, `HapReactIcon` is implemented to lazy load icons, which ensures
// not all 300+ icons are bundled at once. This provides significant performance benefits.
// In a future breaking change, we plan to require consumers of our to explicitly
// import the icons they want to use, like so: `import { FlagIcon } from '@hapara/ui/src/icons/FlagIcon'`
// This change means that only the required icons will be bundled during the build process,
// and be available upfront without dynamically loading them.
const iconCache = new Map();
const loadIconComponent = async (iconName) => {
  if (iconCache.has(iconName)) {
    return iconCache.get(iconName);
  } else {
    const module = await import(
      `../../../../assets/src/icons/react/${iconName}.tsx`
    );

    const IconComponent = module.default;

    const LazyIconComponent = React.lazy(() =>
      Promise.resolve({ default: IconComponent })
    );

    iconCache.set(iconName, LazyIconComponent);
    return LazyIconComponent;
  }
};

/** @deprecated new icons are being created here: [@hapara/ui/src/icons/README.md](../../icons/README.md) */
const HapReactIcon = ({
  svg = "",
  width,
  height,
  spin = false,
  alt = "",
  className,
  viewWidth,
  viewHeight,
  imageAlt,
}) => {
  width = width || 16;
  height = height || 16;
  viewWidth = viewWidth || 28;
  viewHeight = viewHeight || 28;

  const viewBox = `0 0 ${viewWidth} ${viewHeight}`;

  const [IconComponent, setIconComponent] = useState(() => () => null);

  const tokens = (viewBox || "").split(" ");
  const [x, y, w, h] = tokens;
  let calculated_width;
  let calculated_height;
  let iconClassName = "";

  svg = getExistingSvg(svg);

  calculated_height = h;

  calculated_width = Number(w * (calculated_height / h)).toFixed(1);

  const id = "#" + svg;

  if (spin || _.includes(spinnables, id)) {
    iconClassName = styles.hapIconSpin;
  }

  height = height || calculated_height;
  width = width || calculated_width;

  const svgTitleId = `hap-react-icon-${svg}-${_.random(1, 999999999999)}`;
  const ariaExtraProp = alt
    ? { "aria-labelledby": svgTitleId, "aria-label": alt }
    : { "aria-hidden": "true" };

  useEffect(() => {
    // Silence: "Warning: An update to HapReactIcon inside a test was not wrapped in act(...)."
    if (process.env.NODE_ENV === "test") {
      return;
    }

    const tryLoadIconComponent = async () => {
      try {
        const LoadedIconComponent = await loadIconComponent(svg);
        setIconComponent(LoadedIconComponent);
      } catch (error) {
        console.warn(`Error loading icon: ${svg}`, error);
      }
    };
    tryLoadIconComponent();
  }, [svg]);

  return (
    <Suspense
      fallback={
        <FallbackIcon
          xmlns="http://www.w3.org/2000/svg"
          height={height}
          x={x}
          y={y}
          width={width}
          className={`${iconClassName} ${className}`}
          role="img"
          {...ariaExtraProp}
        />
      }
    >
      <IconComponent
        xmlns="http://www.w3.org/2000/svg"
        // TODO: Why is this no longer required?
        // viewBox={viewBox}
        height={height}
        x={x}
        y={y}
        width={width}
        className={`${iconClassName} ${className}`}
        role="img"
        alt={imageAlt}
        {...ariaExtraProp}
      />
    </Suspense>
  );
};

HapReactIcon.propTypes = {
  svg: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spin: PropTypes.bool,
  alt: PropTypes.string,
  className: PropTypes.string,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
};

export default HapReactIcon;
