import _ from "lodash";
import actionTypes from "../actionTypes";
import { WINDOW_SIZE_TYPES } from "./types";
import { getGoogleAnalyticsTrackingIdByPath } from "./navigation";

const INIT_STATE = {
  featureFlags: {},
  labPrefs: {},

  GISLoading: false,
  GISLoaded: false,

  userInitLoading: false,
  userInitLoaded: false,

  userInitConfigLoading: false,
  userInitConfigLoaded: false,

  windowSize: WINDOW_SIZE_TYPES.XS,

  // global app error states
  appError: false, // when true, triggers in-app error message toast
  initialLoadError: false, // when true triggers full screen error page
  accessError: false, // when true triggers 403 page

  successToast: {
    isVisible: false,
  },
};

const appReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // when user loges out, clean the state but keep:
    // - GIS info
    // - window size
    case actionTypes.USER_LOGGED_OUT:
      return {
        ...INIT_STATE,
        GISLoading: false,
        GISLoaded: true,
        windowSize: state.windowSize,
      };

    case actionTypes.APP_USER_INIT_PENDING:
      return {
        ...state,
        userInitLoading: true,
        userInitLoaded: false,
      };

    case actionTypes.APP_USER_INIT_ERROR:
      return {
        ...state,
        userInitLoading: false,
        userInitLoaded: true,
      };

    case actionTypes.APP_USER_INIT_SUCCESS:
      return {
        ...state,
        userInitLoading: false,
        userInitLoaded: true,
        labPrefs: _.get(action, "payload.lab_prefs", {}),
      };

    case actionTypes.APP_USER_INIT_CONFIG_SUCCESS:
      return {
        ...state,
        userInitConfigLoading: false,
        userInitConfigLoaded: true,
        featureFlags: _.get(action, "payload.featureFlags", {}),
      };

    case actionTypes.APP_USER_INIT_CONFIG_PENDING:
      return {
        ...state,
        userInitConfigLoading: true,
      };

    case actionTypes.APP_USER_INIT_CONFIG_ERROR:
      return {
        ...state,
        userInitConfigLoading: false,
        userInitConfigLoaded: true,
      };

    case actionTypes.APP_GIS_LOAD_PENDING:
      return {
        ...state,
        GISLoading: true,
        GISLoaded: false,
      };

    case actionTypes.APP_GIS_LOAD_SUCCESS:
      return {
        ...state,
        GISLoading: false,
        GISLoaded: true,
      };
    case actionTypes.APP_GIS_LOAD_ERROR:
      return {
        ...state,
        GISLoading: false,
        GISLoaded: true,
      };

    case actionTypes.APP_ERROR_UPDATE:
      return {
        ...state,
        appError: _.get(action, "payload", false),
      };

    case actionTypes.APP_SUCCESS_TOAST_UPDATE:
      return {
        ...state,
        successToast: {
          isVisible: _.get(action, "payload.isVisible", false),
          message: _.get(action, "payload.message"),
          isExpandModal: _.get(action, "payload.isExpandModal", false),
          classId: _.get(action, "payload.classId", false),
        },
      };

    case actionTypes.APP_INIT_LOAD_ERROR_UPDATE:
      return {
        ...state,
        initialLoadError: _.get(action, "payload", false),
      };

    case actionTypes.APP_ACCESS_ERROR_UPDATE:
      return {
        ...state,
        accessError: _.get(action, "payload", false),
      };

    case actionTypes.APP_WINDOW_SIZE_UPDATE:
      return {
        ...state,
        windowSize: _.get(action, "payload", WINDOW_SIZE_TYPES.XS),
      };

    case actionTypes.LOCATION_CHANGE: {
      const location = _.get(action, "payload.location");
      const pathname = _.get(location, "pathname", "");
      const search = _.get(location, "search", "");
      const hash = _.get(location, "hash", "");

      // for debugging purposes;
      // use 'Verbose' level in console to see the log
      console.debug(
        "LOCATION_CHANGE:",
        _.get(action, "payload.action"),
        location
      );

      // Google analytics
      if (window.gtag) {
        window.gtag("event", "page_view", {
          // page_title: '<Page Title>',
          page_location: `${pathname}${search}${hash}`,
          page_path: pathname,
          send_to: getGoogleAnalyticsTrackingIdByPath(pathname),
        });
      }
      return state;
    }

    case actionTypes.CLASS_INFO_ADD_STUDENTS_SUCCESS: {
      const successfullyAddedStudents =
        action.payload?.successfullyAddedStudents;
      const unsuccessfullyAddedStudents =
        action.payload?.unsuccessfullyAddedStudents;
      const partialSuccess =
        successfullyAddedStudents?.length > 0 &&
        unsuccessfullyAddedStudents?.length > 0;
      const succesfullyAddedUniqueStudents = [
        ...new Set(successfullyAddedStudents),
      ]?.length;

      if (partialSuccess) {
        const message = `${succesfullyAddedUniqueStudents} of ${
          succesfullyAddedUniqueStudents + unsuccessfullyAddedStudents?.length
        } students were successfully added to the class.`;
        return {
          ...state,
          successToast: {
            isVisible: true,
            message: message,
          },
        };
      }
      if (succesfullyAddedUniqueStudents > 0) {
        const message = `${succesfullyAddedUniqueStudents} ${
          succesfullyAddedUniqueStudents > 1
            ? "students were successfully added."
            : "student was successfully added."
        }`;
        return {
          ...state,
          successToast: {
            isVisible: true,
            message: message,
          },
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.CLASS_INFO_REMOVE_STUDENTS_SUCCESS:
    case actionTypes.CLASS_INFO_ADD_TEACHER_SUCCESS:
    case actionTypes.CLASS_INFO_REMOVE_TEACHERS_SUCCESS:
    case actionTypes.CLASS_INFO_RENAME_CLASS_SUCCESS:
    case actionTypes.CLASS_INFO_UPDATE_STUDENT_PASSWORDS_SUCCESS:
    case actionTypes.CLASS_INFO_SYNC_CLASS_SUCCESS:
      return {
        ...state,
        successToast: {
          isVisible: true,
        },
      };
    case actionTypes.CLASS_INFO_RESET_CLASS_SUCCESS:
      return {
        ...state,
        successToast: {
          isVisible: true,
          message: "Reset class folders underway. This may take a few minutes.",
        },
      };
    default:
      return state;
  }
};

export default appReducer;
