import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ActionStarUnstar.module.scss";
import Button, {
  BUTTON_TYPES,
  BUTTON_OUTLINE_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import Tippy from "@tippy.js/react";
import { hideAppError, showAppError } from "../../../../state/app/actions";
import { starUnstarAction } from "../../../../state/resources/actions";
import { dropdownItemPropsType } from "../../../../state/resources/types";
import { FocusContext } from "@hapara/ui/src/components/utils";

const ActionStarUnstar = ({
  id,
  dataTestPrefix,
  isStarred,
  iconOnly = false,
  isDropdownAction = false,
  dropdownItemProps,
  starUnstarAction,
  showErrorMessage,
  hideErrorMessage,
  dataTestIdPrefixWithResourceTile,
}) => {
  const [isAddInProgress, setIsAddInProgress] = useState(false);

  const actionLabel = isStarred ? "Remove from Starred" : "Add to Starred";
  const actionIcon = isStarred ? "star-fill" : "star";
  let actionDataTestId = isStarred
    ? `${dataTestPrefix}-UnstarResource-Button`
    : `${dataTestPrefix}-StarResource-Button`;

  if (dataTestIdPrefixWithResourceTile) {
    actionDataTestId = isStarred
      ? `${dataTestIdPrefixWithResourceTile}-UnstarResource-Button`
      : `${dataTestIdPrefixWithResourceTile}-StarResource-Button`;
  }

  const focusContext = useContext(FocusContext);

  const handleClickAction = (e) => {
    e.stopPropagation();
    setIsAddInProgress(true);
    hideErrorMessage();
    starUnstarAction({ itemId: id, isStarred })
      .then(() => {})
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => {
        setIsAddInProgress(false);
        focusContext.restoreFocus();
      });
  };

  return (
    <>
      {!isDropdownAction && iconOnly && (
        <Tippy content={actionLabel} theme="hsuite">
          <Button
            aria-label={actionLabel}
            icon={actionIcon}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            data-test-id={actionDataTestId}
            onClick={handleClickAction}
            isLoading={isAddInProgress}
            isDisabled={isAddInProgress}
            isFullWidth={true}
            className={styles.starButton}
          />
        </Tippy>
      )}
      {!isDropdownAction && !iconOnly && (
        <Button
          label={actionLabel}
          icon={actionIcon}
          type={BUTTON_TYPES.SECONDARY}
          outlineType={BUTTON_OUTLINE_TYPES.SOLID}
          data-test-id={actionDataTestId}
          onClick={handleClickAction}
          isLoading={isAddInProgress}
          isDisabled={isAddInProgress}
          isFullWidth={true}
          className={styles.starButton}
        />
      )}

      {isDropdownAction && (
        <button
          type="button"
          data-test-id={actionDataTestId}
          {...dropdownItemProps}
          onClick={(e) => {
            handleClickAction(e);
            dropdownItemProps.onClick();
          }}
          className={dropdownItemProps.className}
        >
          {actionLabel}
        </button>
      )}
    </>
  );
};

ActionStarUnstar.propTypes = {
  id: PropTypes.string.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  isStarred: PropTypes.bool.isRequired,
  iconOnly: PropTypes.bool,
  isDropdownAction: PropTypes.bool,
  dropdownItemProps: dropdownItemPropsType,
  dataTestIdPrefixWithResourceTile: PropTypes.string,
  // connected
  showErrorMessage: PropTypes.func.isRequired,
  hideErrorMessage: PropTypes.func.isRequired,
  starUnstarAction: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    showErrorMessage: (error) => dispatch(showAppError(error)),
    hideErrorMessage: () => dispatch(hideAppError()),
    starUnstarAction: ({ itemId, isStarred }) =>
      dispatch(starUnstarAction({ itemId, isStarred })),
  })
)(ActionStarUnstar);
