import _ from "lodash";
import { createSelector } from "reselect";

const getSnapshotsInfo = (state) => _.get(state, "highlights.snapshots", []);

export const getSnapshots = createSelector(getSnapshotsInfo, (sn) =>
  _.get(sn, "snaps")
);

export const getSnapshotsLoadingState = createSelector(getSnapshotsInfo, (sn) =>
  _.get(sn, "isLoading")
);
