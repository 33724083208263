import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./CategoryFiltersToggleButton.module.scss";

import {
  WINDOW_SIZE_TYPE_LIST,
  WINDOW_SIZE_TYPES,
} from "../../../../state/app/types";
import { getWindowSize } from "../../../../state/app/selectors";

const CategoryFiltersToggleButton = ({
  numberFiltersSelected = 0,
  toggleLeftPanel,
  isLeftPanelOpen,
  dataTestIdPrefix,
  buttonLabel = "Category filters",
  windowSize,
}) => {
  if (windowSize >= WINDOW_SIZE_TYPES.M) return null;

  return (
    <div className={styles.root}>
      <button
        type="button"
        className={classnames(styles.btnOpenPanel, {
          [styles.activated]: !!numberFiltersSelected,
        })}
        onClick={() => {
          toggleLeftPanel(!isLeftPanelOpen);
        }}
        data-test-id={`${dataTestIdPrefix}-OpenTagsPanel-Button`}
      >
        {`${buttonLabel}${
          numberFiltersSelected ? ` • ${numberFiltersSelected}` : ""
        }`}
      </button>
    </div>
  );
};

CategoryFiltersToggleButton.propTypes = {
  numberFiltersSelected: PropTypes.number,
  toggleLeftPanel: PropTypes.func.isRequired,
  isLeftPanelOpen: PropTypes.bool.isRequired,
  dataTestIdPrefix: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect((state) => ({
  windowSize: getWindowSize(state),
}))(CategoryFiltersToggleButton);
