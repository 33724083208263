import _ from "lodash";
import { hideAppError, sendEvent, updateAccessError } from "../../app/actions";
import {
  fetchHighlightsClassConfig,
  putStudentsSortOrder,
  putItemsPerTile,
  putTileSize,
} from "../../../apiCalls/highlights";
import actionTypes from "../../actionTypes";
import {
  getErrorStringFromErrorOrResponse,
  isOutOfSchoolHours,
} from "../../../utils";
import {
  openBusWebsocketConnection,
  closeBusWebsocketConnection,
} from "../busWebsocket/actions";
import {
  openScreenshotWebsocketConnection,
  closeScreenshotWebsocketConnection,
} from "../screenshotWebsocket/actions";
import {
  getClassRelatedTasks,
  waitForTaskCompletion,
} from "../../../apiCalls/config";

export const loadClassConfig =
  ({ classId, shouldOpenWebsocketConnection }) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_PENDING,
    });
    dispatch(hideAppError());
    dispatch(closeBusWebsocketConnection());
    if (shouldOpenWebsocketConnection) {
      dispatch(closeScreenshotWebsocketConnection());
    }

    //let's check if the class is still loading data first
    let tasks = await getClassRelatedTasks(classId);
    if (tasks.length > 0) {
      await waitForTaskCompletion(tasks[0].ID);
    }
    fetchHighlightsClassConfig(classId)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_SUCCESS,
          payload: data,
        });

        const studentList = _.get(data, "Students", []);
        const students = _.map(studentList, (item) => item.Id);
        const teacherId = _.get(data, "Email", "");

        const monitoringTime = _.get(data, "MonitoringTime", "");
        const outOfSchoolHours = isOutOfSchoolHours(monitoringTime);

        if (!outOfSchoolHours) {
          dispatch(
            openBusWebsocketConnection({
              classId,
              students,
              teacherId,
            })
          );

          if (shouldOpenWebsocketConnection) {
            dispatch(
              openScreenshotWebsocketConnection({
                classId,
                students,
                teacherId,
              })
            );
          }
        }

        return data;
      })
      .catch((error) => {
        dispatch(
          sendEvent({
            name: "apiError",
            apiPath: "/admin/teacher-config/highlights?class=",
            apiError: getErrorStringFromErrorOrResponse(error),
          })
        );
        dispatch({
          type: actionTypes.HIGHLIGHTS_CLASS_CONFIG_LOAD_ERROR,
        });
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };

export const updateStudentsOrder =
  ({ type, customOrder, classId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_UPDATE_STUDENTS_ORDER,
      payload: { type, customOrder },
    });

    // we do silent update for now, if it fails, we do not notify the app or user
    putStudentsSortOrder({
      classId,
      orderType: type,
      customOrder: customOrder,
    });
  };

export const updateItemsPerTile =
  ({ itemsNumber, classId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_UPDATE_ITEMS_PER_TILE_NUMBER,
      payload: { itemsNumber },
    });

    // we do silent update for now, if it fails, we do not notify the app or user
    putItemsPerTile({
      itemsNumber,
      classId,
    });
  };

export const updateTilesSizeType =
  ({ tileSize, classId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HIGHLIGHTS_UPDATE_STUDENT_TILES_SIZE,
      payload: { tileSize },
    });

    //we do silent update for now, if it fails, we do not notify the app or user
    putTileSize({
      tileSize,
      classId,
    });
  };

export const updateOutsideSchoolHours = (isOutsideSchoolHours) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_OUTSIDE_SCHOOL_HOURS,
  payload: isOutsideSchoolHours,
});

export const updateActiveViewType = (viewType) => ({
  type: actionTypes.HIGHLIGHTS_UPDATE_ACTIVE_VIEW_TYPE,
  payload: viewType,
});
