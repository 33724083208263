import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import styles from "./GradingActivityHistoryItem.module.scss";

import { MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES } from "../../../../../state/workspace/grading/types";

import GradingHistoryEditableBlock from "../GradingHistoryEditableBlock/GradingHistoryEditableBlock";

export const GradingActivityHistoryItem = ({
  id,
  activityDate,
  grade,
  feedback,
  type,
  gradeEdited,
  feedbackEdited,
  isItemEditable,
}) => {
  const activityDateFormatted = moment(activityDate).format("MMM D, YYYY");
  const key = _.findKey(MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES, {
    type: type,
  });

  return (
    <div
      className={classnames(styles.root, {
        [styles.noBottomPadding]: isItemEditable,
      })}
      data-test-id="Ws-Grading-ActivityHistory-Item"
    >
      <div className={styles.header}>
        <div className={styles.body}>
          <h3>{MYWORKSPACES_GRADING_ACTIVITY_HISTORY_TYPES[key].action}</h3>
          <div className={styles.activityDate}>{activityDateFormatted}</div>
        </div>
      </div>
      {(grade || feedback) && (
        <GradingHistoryEditableBlock
          grade={grade}
          feedback={feedback}
          activityId={id}
          gradeEdited={gradeEdited}
          feedbackEdited={feedbackEdited}
          isEditable={isItemEditable}
        />
      )}
    </div>
  );
};

GradingActivityHistoryItem.propTypes = {
  id: PropTypes.string,
  activityDate: PropTypes.string,
  grade: PropTypes.string,
  feedback: PropTypes.string,
  type: PropTypes.string,
  gradeEdited: PropTypes.bool,
  isItemEditable: PropTypes.bool,
};

export default GradingActivityHistoryItem;
