import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddClassDialog,
  syncGoogleClassromsClasses,
} from "../../../../state/dashboard/manage-classes/actions";
import { Permissions } from "../../../../types/shared";
import styles from "./SyncGoogleClassroomsModal.module.scss";
import { getDomains } from "../../../../state/dashboard/config/selectors";
import { getDefaultDomainSelection } from "../../../../utils";
import ActionDialog from "@hapara/ui/src/atomic/ActionDialog/ActionDialog";
import classnames from "classnames";
import LocationField from "../LocationField/LocationField";
import { getUserGoogleClassrooms } from "../../../../apiCalls/dashboard";
import ContentLoading from "@hapara/ui/src/components/ContentLoading/ContentLoading";
import MultiSelectList from "@hapara/ui/src/atomic/MultiSelectList/MultiSelectList";
import { useIntl } from "react-intl";

const SyncGoogleClassroomsModal = () => {
  const intl = useIntl();
  const domains = useSelector(getDomains);

  const defaultDomainSelection = getDefaultDomainSelection(
    domains,
    Permissions.DASHBOARD_SYNC_GOOGLE_CLASSROOM
  );

  const [selectedDomain, setSelectedDomain] = useState(
    defaultDomainSelection.domain
  );
  const [selectedSchool, setSelectedSchool] = useState(
    defaultDomainSelection.school
  );
  const [loadedClasses, setLoadedClasses] = useState([]);
  const [disabledClasses, setDisabledClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const load = useCallback(async () => {
    const classes = await getUserGoogleClassrooms();
    const disabled = classes.filter((c) => c.isHaparaClass);
    setLoadedClasses(classes);
    setSelectedClasses(disabled);
    setDisabledClasses(disabled);
    return classes.length !== 0;
  }, [setLoadedClasses]);

  const dispatch = useDispatch();
  const closeAddClassDialogAction = useCallback(() => {
    dispatch(closeAddClassDialog());
  }, [dispatch]);

  const syncGCClasses = useCallback(() => {
    return dispatch(
      syncGoogleClassromsClasses(
        selectedDomain,
        selectedSchool,
        selectedClasses.filter((c) => !c.isHaparaClass)
      )
    );
  }, [dispatch, selectedDomain, selectedSchool, selectedClasses]);
  const handleOnDomainSchoolChange = useCallback(
    (domain, school) => {
      setSelectedDomain(domain);
      setSelectedSchool(school);
    },
    [setSelectedDomain, setSelectedSchool]
  );
  const [validationError, setValidationError] = useState(
    _.isEmpty(selectedDomain) || _.isEmpty(selectedSchool)
  );
  useEffect(() => {
    setValidationError(
      _.isEmpty(selectedDomain) ||
        _.isEmpty(selectedSchool) ||
        selectedClasses.length <= disabledClasses.length
    );
  }, [
    selectedDomain,
    selectedSchool,
    selectedClasses,
    disabledClasses,
    setValidationError,
  ]);

  return (
    <ActionDialog
      isOpen={true}
      title={intl.formatMessage({
        defaultMessage: "Add classes from Google Classroom to Hāpara",
        id: "VnBUOM",
      })}
      subTitle={intl.formatMessage({
        defaultMessage:
          "You’ll be able to engage with your students’ browsing and Google Drive files. Google Classroom will not be impacted!",
        id: "Xr3KlJ",
      })}
      actionLabel={intl.formatMessage({
        defaultMessage: "Add classes to Hāpara",
        id: "UC0tX0",
      })}
      errorMessage={intl.formatMessage({
        defaultMessage:
          "There was a problem adding classes to Hāpara. Please try again.",
        id: "2FfnwO",
      })}
      onClose={() => closeAddClassDialogAction()}
      isValidationError={validationError}
      onAction={syncGCClasses}
      dataTestIdPrefix="td-ManageClasses-SyncGoogleClassroom"
    >
      <div className={classnames(styles.syncGoogleClassroomsModal)}>
        <ContentLoading
          loadFunc={load}
          dataTestIdPrefix="td-ManageClasses-SyncGoogleclassrooms-ContentLoading"
          errorMessageText={intl.formatMessage({
            defaultMessage:
              "Trouble loading classes. Please check your connection and try again.",
            id: "xDHrFv",
          })}
          emptyMessageText={intl.formatMessage({
            defaultMessage: "No classes found",
            id: "iNmPy2",
          })}
        >
          <div className={styles.classSelect}>
            <MultiSelectList
              items={loadedClasses || []}
              dataTestPrefix="td-ManageClasses-SyncGoogleclassrooms-MultiSelect"
              itemsName={intl.formatMessage({
                defaultMessage: "classes",
                id: "KhZ4UH",
              })}
              singleItemName={intl.formatMessage({
                defaultMessage: "class",
                id: "030zL6",
              })}
              selectedItems={selectedClasses || []}
              disabledItems={disabledClasses || []}
              onItemsSelect={(cls) => setSelectedClasses(cls)}
            />
          </div>
          <LocationField
            label={intl.formatMessage({
              defaultMessage: "Location",
              id: "rvirM2",
            })}
            required={true}
            domains={domains}
            permission={Permissions.DASHBOARD_SYNC_GOOGLE_CLASSROOM}
            dataTestIdPrefix="td-ManageClasses-SyncGoogleclassrooms-SelectLocation"
            defaultDomainSelection={defaultDomainSelection}
            onDomainSchoolChange={handleOnDomainSchoolChange}
          />
        </ContentLoading>
      </div>
    </ActionDialog>
  );
};

export default SyncGoogleClassroomsModal;
