import { FormattedMessage } from "react-intl";
import React from "react";
import styles from "./PLWorkspacesSearchEmptyState.module.scss";

const PLWorkspacesSearchEmptyState = () => {
  return (
    <div
      className={styles.root}
      data-test-id="Ws-PL-WorkspacesList-SearchEmptyState"
    >
      <div className={styles.header}>
        <FormattedMessage defaultMessage="There's nothing here!" id="7kWlAm" />
      </div>
      <div className={styles.body}>
        <FormattedMessage
          defaultMessage="We can't find any Workspaces based on this search."
          id="Jb9Mkx"
        />
      </div>
    </div>
  );
};

export default PLWorkspacesSearchEmptyState;
