import { LocaleProvider } from "@hapara/ui/src/providers/LocaleProvider";
import { useSelector } from "react-redux";
import { RootState } from "src/state/store";

export const ConditionalLocaleProvider = ({ children }) => {
  const featureFlags = useSelector<RootState>(
    (state) => state.app.featureFlags
  );

  const isTranslationsEnabled =
    featureFlags?.["HAP-11660-Enable-Translations"] === true;

  return (
    <LocaleProvider isTranslationsEnabled={isTranslationsEnabled}>
      {children}
    </LocaleProvider>
  );
};
