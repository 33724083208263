import PropTypes from "prop-types";
import { STUDENT_TYPE } from "../../../state/highlights/students/types";
import { getUserNameFormat } from "../../../state/user/selectors";
import { getFormattedName } from "../../../utils";
import { connect } from "react-redux";

const FormattedStudentName = ({ nameFormat, student }) => {
  return getFormattedName(nameFormat, student);
};

FormattedStudentName.propTypes = {
  student: STUDENT_TYPE.isRequired,
  nameFormat: PropTypes.string.isRequired,
};

export default connect((state) => ({
  nameFormat: getUserNameFormat(state),
}))(FormattedStudentName);
