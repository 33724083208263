import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./GradingWorkDone.module.scss";

import {
  MYWORKSPACES_GRADING_ACTIVITY_TYPES,
  MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES,
} from "../../../../../state/workspace/grading/types";

export const GradingWorkDone = ({ activity }) => {
  const activityKey = _.findKey(
    MYWORKSPACES_GRADING_ACTIVITY_TYPES,
    (type) => type === activity
  );

  return (
    <div className={styles.root} data-test-id="Ws-Grading-Work-Done">
      <div className={styles.header}>
        {MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES[activityKey].header}
      </div>
      <div className={styles.body}>
        {MYWORKSPACES_GRADING_ACTIVITY_EMPTY_STATES[activityKey].body}
      </div>
    </div>
  );
};

GradingWorkDone.propTypes = {
  activity: PropTypes.string.isRequired,
};

export default GradingWorkDone;
