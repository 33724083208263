import React from "react";
import PropTypes from "prop-types";

import styles from "./FormBlock.module.scss";
import classnames from "classnames";
import { Radio } from "../../atomic/Radio";
import { THEME_TYPES } from "../../atomic/consts";

const FormBlock = ({
  title,
  children,
  variant,
  options,
  selectedOption = "",
  onOptionSelect,
  dataTestIdPrefix,
  className,
  isTitleBorder = false,
}) => {
  if (options) {
    // we are doing some specific accessibility stuff to avoid nested fieldsets
    return (
      <div className={classnames(styles.root, styles[variant], className)}>
        <div
          className={classnames(styles.title, {
            [styles.border]: isTitleBorder,
          })}
        >
          <fieldset className={styles.radioGroup}>
            <legend className={styles.radioGroupTitle}>{title}</legend>
            <div className={styles.radioGroupContainer}>
              {options.map((option) => {
                return (
                  <Radio
                    key={option.value}
                    name={`${dataTestIdPrefix}-Select`}
                    id={`radio-${option.value}`}
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={onOptionSelect}
                    label={option.label}
                    className={styles.radioGroupRadio}
                    dataTestIdPrefix={`${dataTestIdPrefix}-Select-${option.value}`}
                  />
                );
              })}
            </div>
          </fieldset>
        </div>
        <fieldset
          aria-labelledby={`radio-${selectedOption}`}
          className={classnames(styles.fieldset, styles.content)}
        >
          {children}
        </fieldset>
      </div>
    );
  }
  return (
    <div className={classnames(styles.root, styles[variant])}>
      <fieldset className={styles.contentFieldSet}>
        <legend
          className={classnames(styles.title, {
            [styles.border]: isTitleBorder,
          })}
        >
          {title}
        </legend>
        <div className={styles.content}>{children}</div>
      </fieldset>
    </div>
  );
};

FormBlock.propTypes = {
  variant: PropTypes.oneOf(Object.values(THEME_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  selectedOption: PropTypes.string,
  onOptionSelect: PropTypes.func,
  dataTestIdPrefix: PropTypes.string.isRequired,
  className: PropTypes.string,
  isTitleBorder: PropTypes.bool,
};

export default FormBlock;
