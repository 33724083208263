import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";

import styles from "./MetadataFilters.module.scss";
import FiltersListCollapsable from "../FiltersListCollapsable/FiltersListCollapsable";
import Button, {
  BUTTON_TYPES,
  BUTTON_SIZES,
} from "@hapara/ui/src/atomic/Button/Button";

import {
  filterItemType,
  SHARED_FILTER_TYPES,
} from "../../../../state/shared/types";
import {
  areFiltersLoading,
  getSearchQuery,
  getFilters,
  getSelectedFilters,
} from "../../../../state/shared/selectors";
import {
  navigateToLanding,
  updateURLParams,
} from "../../../../state/shared/navigation";
import { toggleLeftSidePanel } from "../../../../state/shared/actions";
import { hasResourcesAccess } from "../../../../state/user/selectors";
import { areListsEqual } from "../../../../utils";
import { FocusContext } from "@hapara/ui/src/components/utils";

export const MetadataFilters = ({
  grades,
  subjects,
  standards,
  licences,
  resourceTypes,
  loadingFilters,
  preselectedGrades,
  preselectedSubjects,
  preselectedStandards,
  preselectedResourceTypes,
  preselectedLicences,
  searchQuery,
  hasResourcesAccess,
  toggleLeftPanel,
  showApplyButton,
}) => {
  const [selectedSubjects, setSelectedSubjects] = useState(
    preselectedSubjects || []
  );
  const [selectedGrades, setSelectedGrades] = useState(preselectedGrades || []);
  const [selectedStandards, setSelectedStandards] = useState(
    preselectedStandards || []
  );
  const [selectedResourceTypes, setSelectedResourceTypes] = useState(
    preselectedResourceTypes || []
  );
  const [selectedLicences, setSelectedLicences] = useState(
    preselectedLicences || []
  );

  const focusContext = useContext(FocusContext);

  useEffect(() => {
    // if URL is changed we need to reset values
    // setState is needed as there are 2 scenarios:
    // - filters applied on every change
    // - filters applied by click on Done button
    if (!areListsEqual(selectedSubjects, preselectedSubjects)) {
      setSelectedSubjects(preselectedSubjects);
    }
    if (!areListsEqual(selectedGrades, preselectedGrades)) {
      setSelectedGrades(preselectedGrades);
    }
    if (!areListsEqual(selectedStandards, preselectedStandards)) {
      setSelectedStandards(preselectedStandards);
    }
    if (!areListsEqual(selectedResourceTypes, preselectedResourceTypes)) {
      setSelectedResourceTypes(preselectedResourceTypes);
    }
    if (!areListsEqual(selectedLicences, preselectedLicences)) {
      setSelectedLicences(preselectedLicences);
    }
  }, [
    preselectedSubjects,
    preselectedGrades,
    preselectedStandards,
    preselectedResourceTypes,
    preselectedLicences,
  ]);

  const applyFilters = ({
    sSubjects,
    sGrades,
    sStandards,
    sResourceTypes,
    sLicences,
  }) => {
    if (
      _.isEmpty(searchQuery) &&
      _.isEmpty(sSubjects) &&
      _.isEmpty(sGrades) &&
      _.isEmpty(sStandards) &&
      _.isEmpty(sResourceTypes) &&
      _.isEmpty(sLicences)
    ) {
      navigateToLanding();
    } else {
      updateURLParams({
        subjects: sSubjects,
        grades: sGrades,
        standards: sStandards,
        resourceTypes: sResourceTypes,
        licences: sLicences,
      });
    }
  };

  const applyFilersAndClose = () => {
    applyFilters({
      sSubjects: selectedSubjects,
      sGrades: selectedGrades,
      sStandards: selectedStandards,
      sResourceTypes: selectedResourceTypes,
      sLicences: selectedLicences,
    });
    focusContext.restoreFocus();
    toggleLeftPanel(false);
  };
  const handleFilterItemsSelected = (typeOfFilter, items, isChecked) => {
    let current = [];
    switch (typeOfFilter) {
      case SHARED_FILTER_TYPES.GRADES.value:
        current = _.cloneDeep(selectedGrades);
        break;
      case SHARED_FILTER_TYPES.SUBJECTS.value:
        current = _.cloneDeep(selectedSubjects);
        break;
      case SHARED_FILTER_TYPES.LICENCES.value:
        current = _.cloneDeep(selectedLicences);
        break;
      case SHARED_FILTER_TYPES.RESOURCE_TYPE.value:
        current = _.cloneDeep(selectedResourceTypes);
        break;
      case SHARED_FILTER_TYPES.STANDARDS.value:
        current = [];
        break;
      default:
    }

    if (isChecked) {
      current.push(...items.map((i) => i.Key));
    } else {
      _.pullAll(
        current,
        items.map((i) => i.Key)
      );
    }

    switch (typeOfFilter) {
      case SHARED_FILTER_TYPES.GRADES.value:
        setSelectedGrades(current);
        break;
      case SHARED_FILTER_TYPES.SUBJECTS.value:
        setSelectedSubjects(current);
        break;
      case SHARED_FILTER_TYPES.LICENCES.value:
        setSelectedLicences(current);
        break;
      case SHARED_FILTER_TYPES.RESOURCE_TYPE.value:
        setSelectedResourceTypes(current);
        break;
      case SHARED_FILTER_TYPES.STANDARDS.value:
        setSelectedStandards(current);
        break;
      default:
    }

    if (!showApplyButton) {
      applyFilters({
        sSubjects:
          typeOfFilter === SHARED_FILTER_TYPES.SUBJECTS.value
            ? current
            : selectedSubjects,
        sGrades:
          typeOfFilter === SHARED_FILTER_TYPES.GRADES.value
            ? current
            : selectedGrades,
        sStandards:
          typeOfFilter === SHARED_FILTER_TYPES.STANDARDS.value
            ? current
            : selectedStandards,
        sResourceTypes:
          typeOfFilter === SHARED_FILTER_TYPES.RESOURCE_TYPE.value
            ? current
            : selectedResourceTypes,
        sLicences:
          typeOfFilter === SHARED_FILTER_TYPES.LICENCES.value
            ? current
            : selectedLicences,
      });
    }
  };

  if (loadingFilters)
    return (
      <div className={styles.root}>
        <div className={styles.loading}>
          <div className={styles.header} />
          <div className={styles.item} />
          <div className={styles.item} />
        </div>
      </div>
    );

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.wrapper, {
          [styles.scrollable]: showApplyButton,
        })}
      >
        <h2 className={styles.title}>
          <FormattedMessage defaultMessage="Refine search" id="O/hQKY" />
        </h2>
        <ul className={styles.filtersList}>
          <li>
            <FiltersListCollapsable
              type={SHARED_FILTER_TYPES.SUBJECTS}
              items={subjects}
              selected={selectedSubjects}
              onItemsSelect={(items, isChecked) =>
                handleFilterItemsSelected(
                  SHARED_FILTER_TYPES.SUBJECTS.value,
                  items,
                  isChecked
                )
              }
              multiSelect={true}
            />
          </li>
          <li>
            <FiltersListCollapsable
              type={SHARED_FILTER_TYPES.GRADES}
              items={grades}
              selected={selectedGrades}
              onItemsSelect={(items, isChecked) => {
                handleFilterItemsSelected(
                  SHARED_FILTER_TYPES.GRADES.value,
                  items,
                  isChecked
                );
              }}
              multiSelect={true}
              grouped={true}
            />
          </li>
          <li>
            <FiltersListCollapsable
              type={SHARED_FILTER_TYPES.STANDARDS}
              items={standards}
              selected={selectedStandards}
              onItemsSelect={(item) => {
                handleFilterItemsSelected(
                  SHARED_FILTER_TYPES.STANDARDS.value,
                  item ? [item] : [],
                  true
                );
              }}
              multiSelect={false}
            />
          </li>
          {hasResourcesAccess && (
            <li>
              <FiltersListCollapsable
                type={SHARED_FILTER_TYPES.RESOURCE_TYPE}
                items={resourceTypes}
                selected={selectedResourceTypes}
                onItemsSelect={(items, isChecked) =>
                  handleFilterItemsSelected(
                    SHARED_FILTER_TYPES.RESOURCE_TYPE.value,
                    items,
                    isChecked
                  )
                }
                multiSelect={true}
                grouped={true}
              />
            </li>
          )}
          {hasResourcesAccess && (
            <li>
              <FiltersListCollapsable
                type={SHARED_FILTER_TYPES.LICENCES}
                items={licences}
                selected={selectedLicences}
                onItemsSelect={(items, isChecked) =>
                  handleFilterItemsSelected(
                    SHARED_FILTER_TYPES.LICENCES.value,
                    items,
                    isChecked
                  )
                }
                multiSelect={true}
                grouped={true}
                groupItemSelectable={false}
              />
            </li>
          )}
        </ul>
      </div>
      {showApplyButton && (
        <div className={styles.controlsBottom}>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.REGULAR}
            label="Done"
            onAction={applyFilersAndClose}
            data-test-id="lb-SidePanelFilters-Button-Done"
            className={styles.applyButton}
            aria-label="Apply filters"
          />
        </div>
      )}
    </div>
  );
};

MetadataFilters.propTypes = {
  grades: PropTypes.arrayOf(filterItemType),
  subjects: PropTypes.arrayOf(filterItemType),
  standards: PropTypes.arrayOf(filterItemType),
  licences: PropTypes.arrayOf(filterItemType),
  resourceTypes: PropTypes.arrayOf(filterItemType),
  preselectedGrades: PropTypes.arrayOf(PropTypes.string),
  preselectedSubjects: PropTypes.arrayOf(PropTypes.string),
  preselectedStandards: PropTypes.arrayOf(PropTypes.string),
  preselectedResourceTypes: PropTypes.arrayOf(PropTypes.string),
  preselectedLicences: PropTypes.arrayOf(PropTypes.string),
  loadingFilters: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  hasResourcesAccess: PropTypes.bool.isRequired,
  toggleLeftPanel: PropTypes.func.isRequired,
  showApplyButton: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    grades: getFilters(SHARED_FILTER_TYPES.GRADES.value)(state),
    subjects: getFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(state),
    standards: getFilters(SHARED_FILTER_TYPES.STANDARDS.value)(state),
    licences: getFilters(SHARED_FILTER_TYPES.LICENCES.value)(state),
    resourceTypes: getFilters(SHARED_FILTER_TYPES.RESOURCE_TYPE.value)(state),
    preselectedGrades: getSelectedFilters(SHARED_FILTER_TYPES.GRADES.value)(
      state
    ),
    preselectedSubjects: getSelectedFilters(SHARED_FILTER_TYPES.SUBJECTS.value)(
      state
    ),
    preselectedStandards: getSelectedFilters(
      SHARED_FILTER_TYPES.STANDARDS.value
    )(state),
    preselectedResourceTypes: getSelectedFilters(
      SHARED_FILTER_TYPES.RESOURCE_TYPE.value
    )(state),
    preselectedLicences: getSelectedFilters(SHARED_FILTER_TYPES.LICENCES.value)(
      state
    ),
    loadingFilters: areFiltersLoading(state),
    searchQuery: getSearchQuery(state),
    hasResourcesAccess: hasResourcesAccess(state),
  }),
  (dispatch) => ({
    toggleLeftPanel: (isOpen) => dispatch(toggleLeftSidePanel(isOpen)),
  })
)(MetadataFilters);
