import React from "react";

//TODO: https://hapara.atlassian.net/browse/PS-1521 - update to use Context API
export const injectProps = <T>(
  children: React.ReactNode | undefined,
  props: (index: number) => Partial<T>
) => {
  let childIndex = 0;

  const inject = (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as React.ReactElement<T>,
        props(childIndex++)
      );
    }
    return child;
  };

  return children
    ? React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === React.Fragment) {
          return React.cloneElement(
            child,
            {},
            React.Children.map(child.props.children, inject)
          );
        }
        return inject(child);
      })
    : null;
};
