import React from "react";
import PropTypes from "prop-types";
import styles from "./GradingHistoryEditableBlock.module.scss";
import { MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES } from "../../../../../state/workspace/grading/types";

import GradingHistoryEditableBlockItem from "../GradingHistoryEditableBlockItem/GradingHistoryEditableBlockItem";

export const GradingHistoryEditableBlock = ({
  grade,
  feedback,
  activityId,
  gradeEdited,
  feedbackEdited,
  isEditable,
}) => {
  return (
    <div className={styles.root} data-test-id="Ws-Grading-HistoryEditableBlock">
      <GradingHistoryEditableBlockItem
        param={{
          key: MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES.GRADE,
          value: grade,
        }}
        activityId={activityId}
        edited={gradeEdited}
        isEditable={isEditable}
      />
      <GradingHistoryEditableBlockItem
        param={{
          key: MYWORKSPACES_GRADING_EDITABLE_ITEM_TYPES.FEEDBACK,
          value: feedback,
        }}
        activityId={activityId}
        edited={feedbackEdited}
        isEditable={isEditable}
      />
    </div>
  );
};

GradingHistoryEditableBlock.propTypes = {
  grade: PropTypes.string,
  feedback: PropTypes.string,
  activityId: PropTypes.string.isRequired,
  gradeEdited: PropTypes.bool,
  feedbackEdited: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default GradingHistoryEditableBlock;
