import _ from "lodash";
import { createSelector } from "reselect";
import { WEBSOCKET_STATUS_TYPES } from "../HighlightsWebsocket";

const getWebsocket = (state) => _.get(state, "highlights.busWebsocket");

export const getBusWebsocketStatus = createSelector(getWebsocket, (ws) =>
  _.get(ws, "status", WEBSOCKET_STATUS_TYPES.OFFLINE)
);

export const getBusWebsocketProcessedGuideBrowsing = createSelector(
  getWebsocket,
  (ws) => _.get(ws, "processedGuideBrowsing", {})
);

export const getLastProcessedPause = createSelector(getWebsocket, (ws) =>
  _.get(ws, "lastProcessedPause", 0)
);

export const getBusWebsocketConnectionError = createSelector(
  getWebsocket,
  (ws) => _.get(ws, "isConnectionError", false)
);
