import React, { useState, useEffect, useRef, useCallback } from "react";
import { Group } from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import classnames from "classnames";
import styles from "./GroupDropDown.module.scss";
import _ from "lodash";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import ListItem from "./ListItem";

interface GroupDropDownTypes {
  workspaceGroups: Array<Group>;
  cardGroups: Array<string>;
  dataTestIdPrefix: string;
  onSelectedGroupsChange?: (groupFilter: string[]) => void;
}

export const GroupDropDown = ({
  workspaceGroups,
  cardGroups,
  dataTestIdPrefix,
  onSelectedGroupsChange,
}: GroupDropDownTypes) => {
  const allAvailableGroups = workspaceGroups.map((i) => i.ID!);
  const compiledSelection = !_.isEmpty(cardGroups)
    ? cardGroups
    : allAvailableGroups;
  const isFullSelection = cardGroups.length === workspaceGroups.length;

  const [selectedGroups, setSelectedGroups] = useState(compiledSelection);
  const [initialEntry, setInitialEntry] = useState<boolean>(true);
  const [allToggled, setAllToggled] = useState<boolean>(false); //Used to determine if All Groups empties or fills list
  const [allCheckStatus, setAllCheckStatus] = useState<boolean>(
    isFullSelection || _.isEmpty(cardGroups)
  );
  const [compiledButtonText, setCompiledButtonText] =
    useState<string>("All Groups");
  const [noGroupError, setNoGroupError] = useState<boolean>(false); //Handles alert content and styling
  const [dropOpen, setDropOpen] = useState<boolean>(false);

  const rootRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const buttonSecondaryLabel = "Groups:";
  const buttonErrorAlert = "You must select a group";
  const menuA11yState = dropOpen ? "open" : "closed";

  //COMPILE BUTTON TEXT
  useEffect(() => {
    let compiledButtonText = "";

    if (selectedGroups.length === 1) {
      //BUTTON: Single group selected results in the group title being displayed
      const selectedGroup = _.find(workspaceGroups, { ID: selectedGroups[0] }); //Finds single selected group's name
      //Error handling when a group is deleted but relationship with card is retained
      if (typeof selectedGroup === "undefined") {
        compiledButtonText = buttonErrorAlert;
        setNoGroupError(true);
      } else {
        const groupTitle = selectedGroup.Title
          ? selectedGroup.Title
          : "Group Name";
        compiledButtonText = groupTitle;
      }
    } else {
      //BUTTON: Multiple selected groups displays an assigned group count
      const buttonTally =
        _.isEmpty(selectedGroups) ||
        selectedGroups.length === workspaceGroups.length
          ? "All"
          : selectedGroups?.length.toString();
      const buttonText =
        _.isEmpty(selectedGroups) || selectedGroups!.length > 1
          ? "Groups"
          : "Group";
      compiledButtonText = `${buttonTally} ${buttonText}`;
    }

    setCompiledButtonText(compiledButtonText);
  }, [selectedGroups, workspaceGroups]);

  //CLOSE ON OUTSIDE CLICK
  const handleOutsideClick = useCallback(
    (e) => {
      if (dropOpen) {
        if (rootRef.current && rootRef.current.contains(e.target)) {
          return;
        }
        setDropOpen(false);
      }
    },
    [dropOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  //CLOSE ON ESCAPE (prevents propagtion to parent modal)
  const handleEscKey = useCallback(
    (e) => {
      if (dropOpen && e.keyCode === 27) {
        e.stopPropagation();
        setDropOpen(false);
        !_.isEmpty(triggerRef) && triggerRef.current!.focus();
      }
    },
    [dropOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey, true); //Sets event listener to the Capture phase
    return () => {
      document.removeEventListener("keydown", handleEscKey, true);
    };
  }, [handleEscKey]);

  //CLICK HANDLERS
  const handleMenu = () => {
    setDropOpen(!dropOpen);
  };

  const handleAllClick = () => {
    if (!allToggled && !initialEntry) {
      onSelectedGroupsChange && onSelectedGroupsChange(allAvailableGroups);
      setSelectedGroups(allAvailableGroups);
      setAllCheckStatus(true);
      setAllToggled(true);
      setNoGroupError(false);
    } else {
      onSelectedGroupsChange && onSelectedGroupsChange([]);
      setSelectedGroups([]);
      setAllCheckStatus(false);
      setNoGroupError(true);
      setAllToggled(false);
    }
    setInitialEntry(false);
  };

  const handleItemClick = (itemID: string) => {
    let updatedSelection = [...selectedGroups];

    if (selectedGroups.includes(itemID)) {
      updatedSelection = _.without(updatedSelection, itemID);

      if (_.isEmpty(updatedSelection)) {
        updatedSelection = allAvailableGroups; //Restacks selection to All when empty
        setAllToggled(true);
        setAllCheckStatus(true);
      }
    } else {
      updatedSelection.push(itemID);
      setNoGroupError(false);

      if (updatedSelection.length === workspaceGroups.length) {
        setAllToggled(true);
        setAllCheckStatus(true);
      }
    }
    setInitialEntry(false);
    onSelectedGroupsChange && onSelectedGroupsChange(updatedSelection);
    setSelectedGroups(updatedSelection);
  };

  //COMPILE DROPLIST SOURCE DATA (All Checkbox & List Item Checkboxes)
  const allCheckboxContent = {
    ID: "01",
    Title: "All Groups",
    isSelected: allCheckStatus,
    isSemiState:
      !_.isEmpty(selectedGroups) &&
      selectedGroups.length < workspaceGroups.length,
    dataTestId: `${dataTestIdPrefix}-ModalGroupDropDown_all`,
  };

  const dropMenuContent = workspaceGroups!.map((i) => {
    return {
      ID: i.ID!,
      ColorIndex: i.ColorIndex!,
      Title: i.Title!,
      isSelected:
        initialEntry && _.isEmpty(selectedGroups)
          ? true
          : selectedGroups.includes(i.ID!),
      dataTestId: `${dataTestIdPrefix}-ModalGroupDropDown_listItem-${i.ID!}`,
    };
  });

  return (
    <div className={styles.root} ref={rootRef}>
      {/* TRIGGER BUTTON */}
      <button
        className={styles.groupDropdownButton}
        onClick={handleMenu}
        data-test-id={`${dataTestIdPrefix}-ModalGroupDropDown_open`}
        ref={triggerRef}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls="groups_dropdown"
        aria-expanded={dropOpen}
      >
        <div className={styles.buttonContentBlockFirst}>
          {buttonSecondaryLabel}
        </div>
        <div
          className={classnames(styles.buttonContentBlockSecond, {
            [styles.hasError]: noGroupError,
          })}
        >
          {noGroupError ? buttonErrorAlert : compiledButtonText}
        </div>
        <HapReactIcon
          svg="arrow-carvet-down"
          height={16}
          width={16}
          alt={`selected. Menu ${menuA11yState}`}
          className={classnames({
            [styles.rotateClose]: dropOpen,
            [styles.rotateOpen]: !dropOpen,
          })}
        />
      </button>
      {/* DROP MENU PANEL */}
      {dropOpen && (
        <div className={styles.dropMenuPanel}>
          <ul role="listbox" id="groups_dropdown">
            <ListItem
              itemPayload={allCheckboxContent}
              handleSelect={handleAllClick}
            />

            {dropMenuContent.map((itemContent) => (
              <ListItem
                key={itemContent.ID}
                itemPayload={itemContent}
                handleSelect={handleItemClick}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GroupDropDown;
