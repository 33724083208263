import React from "react";
import { createIcon } from "./utils/createIcon";

export const TargetIcon = createIcon(
  <path
    fillRule="evenodd"
    d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8Zm15 0A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-7 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM3 8a5 5 0 1 0 10 0A5 5 0 0 0 3 8Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
    fill="currentColor"
    clipRule="evenodd"
  />
);
