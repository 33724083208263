import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./StudentTileExpandModalScreens.module.scss";
import classnames from "classnames";
import { SCREEN_STATES } from "../../../../state/highlights/currentScreenExpandModal/types";
import StudentTileExpandModalCurrentScreen from "./StudentTileExpandModalCurrentScreen";
import sunnyHills from "@hapara/assets/src/images/vector-image.svg";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import StudentTileMultipleDevicesState, {
  MULTI_DEVICE_TILE_MODES,
} from "../StudentTileMultipleDevicesState/StudentTileMultipleDevicesState";
import { INSTANCE_TYPE } from "../../../../state/highlights/students/types";
import StudentTileMultipleDevicesModal from "../StudentTileMultipleDevicesModal/StudentTileMultipleDevicesModal";
import { FormattedMessage, useIntl } from "react-intl";

const ScreenUnavailable = () => {
  return (
    <div className={styles.screenContainer}>
      <img className={styles.sunnyHills} src={sunnyHills} alt="" />
      <div className={styles.text}>
        <FormattedMessage defaultMessage="Screen is unavailable" id="sVlSlP" />
      </div>
    </div>
  );
};

const ScreenLoading = () => {
  return (
    <div className={classnames(styles.screenContainer, styles.loading)}>
      <img className={styles.sunnyHills} src={sunnyHills} alt="" />
    </div>
  );
};

const ScreenRestricted = ({ dataTestPrefix }) => {
  const intl = useIntl();
  return (
    <div className={styles.screenContainer}>
      <div className={styles.privacy}>
        <FormattedMessage
          defaultMessage="Screenshot is unavailable to protect student privacy."
          id="f85DnF"
        />
      </div>
      <a
        href="https://support.hapara.com/hc/en-us/articles/360058963171"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.helpLink}
        data-test-id={`${dataTestPrefix}-Privacy-HelpCenterLink`}
      >
        <FormattedMessage
          defaultMessage="Learn more in this support article"
          id="aktWJU"
        />
        <HapReactIcon
          className={styles.arrowIcon}
          svg="arrow-external-link"
          width={16}
          height={16}
          alt={intl.formatMessage({
            defaultMessage: "opens new window",
            id: "PcoEfO",
          })}
        />
      </a>
    </div>
  );
};

ScreenRestricted.propTypes = {
  dataTestPrefix: PropTypes.string.isRequired,
};

const ScreenPaused = () => {
  return (
    <div className={styles.screenContainer}>
      <div className={styles.paused}>
        <FormattedMessage defaultMessage="Screen is paused" id="3jr93X" />
      </div>
    </div>
  );
};

const StudentTileExpandModalScreens = ({
  screenState,
  dataTestPrefix,
  instanceId,
  instanceList,
  lastSelectedInstance,
  studentDisplayName,
  studentId,
  isMultipleDeviceNotBrowsing,
}) => {
  const [isMultipleDeviceModalOpen, setIsMultipleDeviceModalOpen] =
    useState(false);

  return (
    <>
      {isMultipleDeviceModalOpen && (
        <StudentTileMultipleDevicesModal
          instanceList={instanceList}
          studentDisplayName={studentDisplayName}
          currentInstance={instanceId}
          lastSelectedInstance={lastSelectedInstance}
          studentId={studentId}
          onModalOpen={setIsMultipleDeviceModalOpen}
          isModalOpen={isMultipleDeviceModalOpen}
        />
      )}
      <div
        className={styles.root}
        data-test-id={`${dataTestPrefix}-CurrentScreenshot`}
      >
        {screenState === SCREEN_STATES.ONLINE && (
          <StudentTileExpandModalCurrentScreen
            screenLoading={<ScreenLoading />}
            screenUnavailable={<ScreenUnavailable />}
            instanceId={instanceId}
            dataTestPrefix={dataTestPrefix}
          />
        )}
        {screenState === SCREEN_STATES.LOADING && <ScreenLoading />}
        {screenState === SCREEN_STATES.UNAVAILABLE && <ScreenUnavailable />}
        {screenState === SCREEN_STATES.RESTRICTED && (
          <ScreenRestricted dataTestPrefix={dataTestPrefix} />
        )}
        {screenState === SCREEN_STATES.PAUSED && <ScreenPaused />}
        {screenState === SCREEN_STATES.MULTIPLE_DEVICES && (
          <div className={styles.screenContainer}>
            <StudentTileMultipleDevicesState
              mode={
                isMultipleDeviceNotBrowsing
                  ? MULTI_DEVICE_TILE_MODES.NOT_BROWSING
                  : MULTI_DEVICE_TILE_MODES.SELECT
              }
              onModalOpen={setIsMultipleDeviceModalOpen}
              deviceCount={instanceList.length}
              expandModalStyle={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

StudentTileExpandModalScreens.propTypes = {
  screenState: PropTypes.oneOf(_.values(SCREEN_STATES)),
  instanceId: PropTypes.string,
  instanceList: PropTypes.arrayOf(INSTANCE_TYPE),
  studentDisplayName: PropTypes.string.isRequired,
  lastSelectedInstance: PropTypes.string,
  studentId: PropTypes.string.isRequired,
  isMultipleDeviceNotBrowsing: PropTypes.bool,
  dataTestPrefix: PropTypes.string.isRequired,
};

export default StudentTileExpandModalScreens;
