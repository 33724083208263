import React, { useEffect } from "react";
import { createContext } from "react";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { defaultRichTextElements } from "./defaultRichTextElements";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const supportedLocales = ["en-US", "ja-JP"] as const;

export type SupportedLocale = (typeof supportedLocales)[number];

type LocaleProviderProps = {
  children: React.ReactNode;
  isTranslationsEnabled?: boolean;
  /** @deprecated this is used for test and Storybook purposes ONLY! */
  overrideLocale?: SupportedLocale;
};

export type LocaleContextState = {
  locale: SupportedLocale;
};

const defaultLocale: SupportedLocale = "en-US";

export const LocaleContext = createContext<LocaleContextState | undefined>(
  undefined
);

const getSupportedLocale = (): SupportedLocale => {
  try {
    const matchedLocale = supportedLocales.find(
      (supportedLocale) =>
        supportedLocale.split("-")[0] === navigator.language.split("-")[0]
    );
    return matchedLocale ?? defaultLocale;
  } catch {
    return defaultLocale;
  }
};

export const LocaleProvider = ({
  children,
  isTranslationsEnabled = true,
  overrideLocale,
}: LocaleProviderProps) => {
  const locale = isTranslationsEnabled
    ? overrideLocale ?? getSupportedLocale()
    : defaultLocale;

  const [translations, setTranslations] = useLocalStorage<
    Record<string, MessageFormatElement[]>
  >(`hapara-${locale}`);

  useEffect(() => {
    if (isTranslationsEnabled) {
      const fetchTranslations = async () => {
        const response = await fetch(
          `https://cdn.simplelocalize.io/febab956d4814da581d4971737c92fca/_latest/${locale}`
        );
        setTranslations(await response.json());
      };

      fetchTranslations();
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale }}>
      <IntlProvider
        locale={locale}
        messages={translations ?? undefined}
        defaultRichTextElements={defaultRichTextElements}
        defaultLocale={defaultLocale}
        onWarn={(_message) => {
          // TODO: React-Intl complains about not using precompiled messages when using 'defaultRichTextElements'
          // https://formatjs.io/docs/getting-started/message-distribution
          // We can't get these directly from SimpleLocalize, and we'll need to compile them at build time.
        }}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
