import { useRef, useState } from "react";
import classnames from "classnames";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { openCardModal } from "../../../../containers/Workspace/BoardPage/navigation";
import {
  Card as CardModel,
  ColumnType,
  Action,
} from "@hapara/ui/src/graphql/generated/workspace/__generated__";
import CardEditDropMenu from "./CardEditDropMenu";
import CardDetailBlock from "./CardDetailBlock";
import CardArtifact from "./CardArtifact";
import styles from "./Card.module.scss";
import { isContentVideo } from "../../../../../utils";
import { useOverflow } from "@hapara/ui/src/hooks/useOverflow";
import { screenshotTakenTimeHelper } from "../../../../../utils";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import { cardColourPalette } from "@hapara/assets/src/colours/colourCards";
import EvidenceStatus from "./EvidenceStatus";

interface CardProps {
  cardData: CardModel;
  permissions: Action[];
  singleColumnCardStyle: boolean;
  flexiCardMode: boolean;
  isLastCard: boolean;
  sectionTitle: string;
  dataTestIdPrefix: string;
  setDeleteCardID: (cardId: string) => void;
  setEvidenceModalPayload: (modalResponse: [string, string]) => void;
}

export const DATETIME_FORMAT = "ddd D MMM";

export const Card = ({
  cardData,
  permissions,
  singleColumnCardStyle,
  flexiCardMode,
  isLastCard,
  sectionTitle,
  dataTestIdPrefix,
  setDeleteCardID,
  setEvidenceModalPayload,
}: CardProps) => {
  const { boardId } = useParams<{ boardId?: string }>();
  const [cardColour, setCardColour] = useState<string>(colourValidator);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOverflowing: overflow } = useOverflow(containerRef); //Checks description length
  const assignedGroups = cardData.GroupsFilter.length;
  const attachmentCount = cardData.Artifacts.length;
  const videoCount = cardData.Artifacts.filter((artifact) =>
    isContentVideo(artifact.URI)
  ).length; //Checks Artifact URI for video providers

  //CONDITIONAL CONTENT & STYLING
  const groupCount = !assignedGroups
    ? "All Groups"
    : `${assignedGroups} ${assignedGroups > 1 ? "Groups" : "Group"}`; //Compiles assigned group content

  //CARD COLOUR HANDLING
  const colourRange = _.map(_.sortBy(cardColourPalette, "colourIndex"));
  const cardColourHex = colourRange[+cardColour - 1].colourHex;
  const cardBorderHex = cardColour === "1" ? "#d6d6d8" : "#f2f2f2";
  const isDarkCard = colourRange[+cardColour - 1].isDarkCard;

  function colourValidator() {
    return _.isNil(cardData.Colour) || _.isEmpty(cardData.Colour)
      ? "1"
      : cardData.Colour;
  }

  const showCardDates = (): boolean => {
    //ideally should be using the cardData.Type to find out
    //a card's type, but apparently that field is
    //not being populated currently
    return (
      cardData.ColumnType === ColumnType.Evidence &&
      permissions.includes(Action.CanEdit)
    );
  };

  const showEvidenceStatus = (): boolean => {
    //Dup of showCardDates
    return (
      cardData.ColumnType === ColumnType.Evidence &&
      permissions.includes(Action.CanEdit)
    );
  };
  const showStartDate = (): boolean => {
    return cardData.StartDate ? true : false;
  };

  const showDueDate = (): boolean => {
    return cardData.DueDate ? true : false;
  };

  const getStartDate = (): string => {
    return screenshotTakenTimeHelper(cardData.StartDate, DATETIME_FORMAT);
  };

  const isColouredCard = (): boolean => {
    return cardData.Image || cardData.Colour
      ? cardColour !== "1" //ignore white coloured cards
        ? true
        : false
      : false;
  };

  const isMinimalContentCard = (): boolean => {
    return (
      !cardData.Image &&
      !cardData.Title &&
      !cardData.Description &&
      !cardData.StartDate &&
      !cardData.DueDate
    );
  };

  const isSemiContentCard = (): boolean => {
    return (
      !cardData.Image &&
      !cardData.Title &&
      !cardData.Description &&
      (cardData.StartDate !== null || cardData.DueDate !== null)
    );
  };

  const isMaxEvidenceCard = (): boolean => {
    return (
      cardData.ColumnType === ColumnType.Evidence &&
      !_.isEmpty(cardData.Image) &&
      (!_.isEmpty(cardData.Title) || !_.isEmpty(cardData.Description))
    );
  };

  return (
    <article
      className={classnames(styles.root, {
        [styles.tallCard]: singleColumnCardStyle,
        [styles.flexiCard]: flexiCardMode,
        [styles.darkCard]: isDarkCard,
        [styles.minimalContentCard]: isMinimalContentCard(),
      })}
      style={{
        background: cardColourHex,
        border: `1px solid ${cardBorderHex}`,
      }}
      onClick={(e) => {
        openCardModal({ cardId: cardData.ID!, boardId: boardId || "" });
      }}
      data-test-id={`${dataTestIdPrefix}-Card-${cardData.ID}`}
    >
      {showCardDates() && (
        <div className={styles.datesContainer}>
          <div className={styles.dueDateContainer}>
            {showDueDate() && (
              <span
                data-test-id={`${dataTestIdPrefix}-Card-DueDate-${cardData.ID}`}
                className={classnames(styles.dueDate, {
                  [styles.colouredCard]: isColouredCard(),
                })}
              >{`Due: ${screenshotTakenTimeHelper(
                cardData.DueDate,
                DATETIME_FORMAT
              )}`}</span>
            )}
          </div>
          {showStartDate() && (
            <div
              className={classnames(styles.wrapper, {
                [styles.colouredCard]: isColouredCard(),
              })}
              data-test-id={`${dataTestIdPrefix}-Card-StartDate-${cardData.ID}`}
            >
              <HapReactIcon
                className={styles.startDate}
                svg="clock"
                width={16}
                height={16}
                alt={`Start date: ${getStartDate()}`}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.cardContentContainer}>
        {permissions.includes(Action.CanEdit) && (
          <CardEditDropMenu
            cardID={cardData.ID!}
            cardType={cardData.ColumnType!}
            isLastCard={isLastCard}
            currentColour={cardColour}
            originalColour={cardData.Colour ? cardData.Colour : "1"}
            dataTestIdPrefix={dataTestIdPrefix}
            changeColourAction={setCardColour}
            setDeleteCardID={setDeleteCardID}
          />
        )}
        {cardData.Image && (
          <img
            src={cardData.Image}
            alt={
              cardData.ImageAltText ? cardData.ImageAltText : "Decorative Image"
            }
            data-test-id="WS-Board-Card-Image"
          />
        )}
        {cardData.Title && (
          <h3
            className={classnames(styles.title, {
              [styles.lineClamp]: !flexiCardMode,
              [styles.maxEvidenceClamp]: isMaxEvidenceCard(),
              [styles.withImg]: cardData.Image,
            })}
            dangerouslySetInnerHTML={{ __html: cardData.Title }}
            data-test-id="WS-Board-Card-Title"
          />
        )}
        {cardData?.Description && (
          <div
            ref={containerRef}
            className={classnames(styles.description, {
              [styles.withoutTitleWithImg]: !cardData.Title && cardData.Image, //Used: No title but sits underneath an img
              [styles.withoutTitleNoImg]: !cardData.Image && !cardData.Title, //Used: No title or img - sits at top of card
              [styles.nonFlexiWithImg]:
                !flexiCardMode && cardData.Image && cardData.Title, //Hides description on std cards when there is an img
              [styles.lineClamp]: !singleColumnCardStyle && !flexiCardMode, //Applies lineclamp to non flexicards only
              [styles.flexiCard]: flexiCardMode, //Applies flexicard styling only
              [styles.tallCard]: singleColumnCardStyle, //Applies single column card specific description styling
              [styles.hasScroll]: overflow, //Adjust spacing to allow for scrollbar
              [styles.maxEvidenceClamp]: isMaxEvidenceCard(),
            })}
            dangerouslySetInnerHTML={{ __html: cardData?.Description }}
            data-test-id="WS-Board-Card-Description"
          />
        )}
        {showEvidenceStatus() && (
          <EvidenceStatus
            isStaticCard={!flexiCardMode}
            className={classnames(styles.evidenceStatus)}
            dataTestIdPrefix={`${dataTestIdPrefix}-Card`}
            sectionTitle={sectionTitle}
            evidenceTitle={cardData?.Title || ""}
            setEvidenceModalPayload={setEvidenceModalPayload}
            isDarkCard={isDarkCard}
            cardColour={cardColour}
          />
        )}
        {cardData?.Artifacts && flexiCardMode && (
          <div
            className={classnames(styles.artifactContainer, {
              [styles.semiContentCard]: isSemiContentCard(),
            })}
          >
            {cardData?.Artifacts.map((artifact, idx) => {
              return (
                <CardArtifact
                  key={artifact.ID}
                  artifact={artifact}
                  idx={idx}
                  isSingleColumn={singleColumnCardStyle}
                />
              );
            })}
          </div>
        )}
      </div>
      <CardDetailBlock
        isStaticCard={!flexiCardMode}
        groups={groupCount}
        attachments={attachmentCount}
        videos={videoCount}
        dataTestIdPrefix={dataTestIdPrefix}
      />
    </article>
  );
};

export default Card;
