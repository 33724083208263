import _ from "lodash";
import axios from "axios";
import { isProduction } from "../components/utils";

export const JWT_EXPIRY_TIME = "JWT_EXPIRY_TIME";

export let jwtToken = null;

let jwtRequestBody = null;

const API_URL = isProduction()
  ? "https://api.hapara.com"
  : "https://api-test.hapara.com";

const REFRESH_TOKEN_DURATION_MINUTES = 5 * 60 * 1000; //  5 min

export const postSendEvent = (data) => {
  const url = `${API_URL}/troubleshooting/send`;
  return axios.post(url, { data });
};

export const getErrorStringFromErrorOrResponse = (error) => {
  return _.get(error, "response")
    ? `${_.get(error, "response.status")}:${_.get(
        error,
        "response.data.error_code"
      )}`
    : `${_.get(error, "name")}: ${_.get(error, "message")}`;
};

export const postJWT = async (requestBody) => {
  console.debug("[auth] [postJWT]");
  const url = `${API_URL}/auth-service/oauth/token`;
  return axios
    .post(url, requestBody, { withCredentials: true })
    .then((response) => {
      localStorage.setItem(
        JWT_EXPIRY_TIME,
        Date.now() + REFRESH_TOKEN_DURATION_MINUTES
      );
      jwtToken = response.data;
      return response.data;
    })
    .catch((e) => {
      return getErrorStringFromErrorOrResponse(e);
    });
};

export const getTokenSync = () => {
  let token = "";
  if (jwtToken) {
    const expired_at = localStorage.getItem(JWT_EXPIRY_TIME);
    const isExpired = Date.now() > expired_at;

    if (!isExpired) {
      return token;
    }
  }

  getToken().catch((e) => {
    getErrorStringFromErrorOrResponse(e);
  });
  return token;
};

export const getToken = async () => {
  let token = "";

  if (jwtToken) {
    token = _.get(jwtToken, "access_token", "");
    const expired_at = localStorage.getItem(JWT_EXPIRY_TIME);
    const isExpired = Date.now() > expired_at;

    if (!isExpired) {
      return token;
    }
  }

  const response = jwtRequestBody && (await postJWT(jwtRequestBody));
  return response ? response.access_token : null;
};

export const initJwtHandling = async (payload) => {
  if (!_.isEqual(payload, jwtRequestBody)) {
    jwtRequestBody = payload;
    jwtToken = null;
  }
};
