import { FormattedMessage } from "react-intl";
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";

import ModalDeprecated from "@hapara/ui/src/deprecated/ModalDeprecated/ModalDeprecated";
import WorkspaceThumbnailEdit from "../WorkspaceThumbnailEdit/WorkspaceThumbnailEdit";
import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";

import WorkspacesThumbnailsSearch from "../WorkspaceThumbnailsSearch/WorkspaceThumbnailsSearch";
import ContentLoading from "@hapara/ui/src/atomic/LoadIndicators/ContentLoading/ContentLoading";

import {
  MYWORKSPACES_THUMBNAIL_PAGE_SIZE,
  THUMBNAIL_TYPE,
  THUMBNAIL_SIZE,
} from "../../../../../state/workspace/myworkspaces/thumbnails/types";
import {
  getPhotosByQuery,
  getPhotoItemById,
  updateCurrentPage,
  thumbnailSavingError,
  thumbnailSavingSuccess,
} from "../../../../../state/workspace/myworkspaces/thumbnails/actions";
import {
  getThumbnails,
  getThumbnailsPagesCount,
  getThumbnailsCurrentPage,
  getInitialSearchTerm,
  areThumbnailsLoading,
} from "../../../../../state/workspace/myworkspaces/thumbnails/selectors";

import { showAppError, hideAppError } from "../../../../../state/app/actions";

import styles from "./WorkspaceThumbnailModal.module.scss";

export const WorkspaceThumbnailModal = ({
  thumbnails = [],
  areThumbnailsLoading = false,
  isOpen = false,
  predefined,
  pagesCount = 0,
  currentPage = 0,
  isSavingThumbnailInProgress = false,
  isSavingThumbnailError = false,
  isSavingThumbnailSuccess = false,
  initialSearch = "",
  onClose = () => {},
  onSave = () => {},
  getThumbnails = () => {},
  updateCurrentPage = () => {},
  showAppError = () => {},
  hideAppError = () => {},
  resetSavingError = () => {},
  resetSavingSuccess,
}) => {
  const getItemToEdit = (thumbnail) => {
    const { url, alt } = thumbnail;
    const idStr = "&id=";
    const indId = url.indexOf(idStr);
    const imgID = url.substring(indId + idStr.length);

    const arrParams = url.split("&");
    const fpX = arrParams[4].split("=")[1];
    const fpY = arrParams[5].split("=")[1];
    const fpZ = arrParams[6].split("=")[1];

    hideAppError();

    getPhotoItemById(imgID)
      .then((response) => {
        if (response.status === 403) {
          console.log("403 error getting image: ", imgID);
          return [];
        }

        if (!response.ok) {
          console.log("Error getting image: ", Error(response));
          throw Error(response);
        }

        const data = response.json();
        return data;
      })
      .then((data) => {
        setPredefinedItem({ ...data, alt_description: alt, fpX, fpY, fpZ });
      })
      .catch((error) => {
        showAppError(error);
      });
  };

  const [inEditMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [predefinedItem, setPredefinedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [upgradedThumbnails, setUpgradedThumbnails] = useState([]);

  const isDefaultSearchTerm = searchTerm === initialSearch;

  let isFirstImagePredefined =
    currentPage === 1 && !!predefined.url && isDefaultSearchTerm;

  const onCancel = () => {
    setEditMode(false);
    resetSavingError();
    resetSavingSuccess();
  };

  const onCloseModal = useCallback(() => {
    setEditMode(false);
    updateCurrentPage(1);
    setSearchTerm(initialSearch);
    setPredefinedItem(null);
    setSelectedItem({});
    resetSavingError();
    resetSavingSuccess();
    onClose();
  }, [
    initialSearch,
    updateCurrentPage,
    resetSavingError,
    resetSavingSuccess,
    onClose,
  ]);

  const onClearResults = () => {
    handleSearch(initialSearch);
  };

  const handlePageChange = (page) => {
    updateCurrentPage(page);
  };

  const handleSearch = (query) => {
    if (!query) return false;

    updateCurrentPage(1);
    setSearchTerm(query);
  };

  useEffect(() => {
    if (isOpen && !!searchTerm) {
      getThumbnails({
        query: searchTerm,
        page: currentPage ? currentPage : 1,
      });
    }
  }, [isOpen, currentPage, searchTerm, getThumbnails]);

  useEffect(() => {
    if (initialSearch) {
      setSearchTerm(initialSearch);
    }
  }, [initialSearch, setSearchTerm]);

  useEffect(() => {
    if (isSavingThumbnailSuccess) {
      onCloseModal();
    }
  }, [isSavingThumbnailSuccess, onCloseModal]);

  useEffect(() => {
    if (isOpen && predefined.url && !predefinedItem) {
      getItemToEdit(predefined);
    }
  });

  useEffect(() => {
    if (thumbnails) {
      const thumbnailsToShow = _.clone(thumbnails);

      if (isFirstImagePredefined) {
        // in this case we need to replace first image with predefined image
        thumbnailsToShow[0] = predefinedItem
          ? predefinedItem
          : {
              id: 0,
              alt_description: predefined.alt,
              urls: {
                raw: predefined.url,
              },
            };
      }
      setUpgradedThumbnails(thumbnailsToShow);
    }
  }, [
    thumbnails,
    predefinedItem,
    isFirstImagePredefined,
    setUpgradedThumbnails,
    predefined,
  ]);

  return (
    <div>
      <ModalDeprecated
        isOpen={isOpen}
        onClose={() => onCloseModal()}
        contentLabel="Workspace Thumbnails Modal"
        title={inEditMode ? "Edit cover image" : "Select a cover image"}
        classNameHeader={styles.header}
        dataTestPrefix="ws-SelectThumbnailModal"
      >
        {!inEditMode && (
          <div className={styles.galleryBlock}>
            <WorkspacesThumbnailsSearch
              onSearch={(query) => handleSearch(query)}
              onClear={onClearResults}
              query={searchTerm}
            />
            {areThumbnailsLoading && (
              <React.Fragment>
                <div className={styles.block}>
                  <ContentLoading
                    amount={MYWORKSPACES_THUMBNAIL_PAGE_SIZE}
                    className={styles.loading}
                  />
                </div>
                <div className={styles.emulatedPagination}>
                  <ContentLoading
                    amount={MYWORKSPACES_THUMBNAIL_PAGE_SIZE / 3}
                    className={styles.pageLoading}
                  />
                </div>
              </React.Fragment>
            )}
            {!areThumbnailsLoading &&
              upgradedThumbnails &&
              upgradedThumbnails.length > 0 && (
                <div className={styles.block}>
                  {_.map(upgradedThumbnails, (item, idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          setSelectedItem(item);
                          setEditMode(true);
                        }}
                        data-test-id="ws-SelectThumbnailModal-SelectImage"
                      >
                        <img
                          src={`${item.urls.raw}&fit=crop&crop=focalpoint&w=${THUMBNAIL_SIZE}&h=${THUMBNAIL_SIZE}&fp-x=0.5&fp-y=0.5&fp-z=1`}
                          alt={item.alt_description}
                        />
                        {isFirstImagePredefined && (
                          <div className={styles.tick}>
                            <HapReactIcon svg="check" width={16} height={16} />
                          </div>
                        )}
                      </button>
                    );
                  })}
                </div>
              )}
            {!areThumbnailsLoading &&
              upgradedThumbnails &&
              upgradedThumbnails.length === 0 && (
                <div className={styles.emptyState}>
                  <div className={styles.emptyHeader}>
                    <FormattedMessage
                      defaultMessage="Sorry, we couldn’t find any results!"
                      id="AxctB8"
                    />
                  </div>
                  <div className={styles.body}>
                    <FormattedMessage
                      defaultMessage="Try a different keyword related to your topic of interest"
                      id="tzpulm"
                    />
                  </div>
                </div>
              )}
            {!areThumbnailsLoading && (
              <Pagination
                pages={areThumbnailsLoading ? 10 : pagesCount}
                currentPage={currentPage}
                onSelectPage={handlePageChange}
                dataTestIdPrefix="Ws-ThumbnailsPagination"
                showTotalPages={false}
              />
            )}
          </div>
        )}
        {inEditMode && (
          <div className={styles.editingBlock}>
            <WorkspaceThumbnailEdit
              item={selectedItem}
              onCancel={onCancel}
              onSave={onSave}
              isSavingThumbnailInProgress={isSavingThumbnailInProgress}
              isSavingError={isSavingThumbnailError}
              onClose={onCloseModal}
            />
          </div>
        )}
      </ModalDeprecated>
    </div>
  );
};

WorkspaceThumbnailModal.propTypes = {
  thumbnails: PropTypes.arrayOf(THUMBNAIL_TYPE),
  areThumbnailsLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSavingThumbnailInProgress: PropTypes.bool,
  isSavingThumbnailError: PropTypes.bool,
  isSavingThumbnailSuccess: PropTypes.bool,
  initialSearch: PropTypes.string,
  getThumbnails: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  getPhotoItemById: PropTypes.func.isRequired,
  showAppError: PropTypes.func.isRequired,
  hideAppError: PropTypes.func.isRequired,
  resetSavingError: PropTypes.func.isRequired,
  resetSavingSuccess: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    thumbnails: getThumbnails(state),
    areThumbnailsLoading: areThumbnailsLoading(state),
    pagesCount: getThumbnailsPagesCount(state),
    currentPage: getThumbnailsCurrentPage(state),
    initialSearch: getInitialSearchTerm(state),
  }),
  (dispatch) => ({
    getThumbnails: (options) => dispatch(getPhotosByQuery(options)),
    updateCurrentPage: (page) => dispatch(updateCurrentPage(page)),
    showAppError: (error) => dispatch(showAppError(error)),
    hideAppError: () => dispatch(hideAppError()),
    resetSavingError: () => dispatch(thumbnailSavingError(false)),
    resetSavingSuccess: () => dispatch(thumbnailSavingSuccess(false)),
  })
)(WorkspaceThumbnailModal);
