import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./StudentTileExpandModalCurrentScreen.module.scss";
import { NO_CURRENT_SCREEN_AVAILABLE } from "../../../../state/highlights/screenshotWebsocket/types";
import { CURRENT_SCREEN_EXPAND_MODAL_TYPE } from "../../../../state/highlights/currentScreenExpandModal/types";
import { getCurrentScreenSnapshot } from "../../../../state/highlights/currentScreenExpandModal/action";
import { getCurrentScreenExpandModalInfo } from "../../../../state/highlights/currentScreenExpandModal/selector";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import {
  ACTIVITY_TYPES,
  getClassActivity,
  getUniqueActivity,
} from "../../../../state/highlights/activities/selectors";
import {
  ACTIVITY_DETAILS_TYPE,
  SNAPSHOT_TYPES,
  UNIQUE_ACTIVITY_TYPE,
} from "../../../../state/highlights/activities/types";
import { getClassId } from "../../../../state/shared/selectors";
import { createSnapshot } from "../../../../state/highlights/snaps/actions";
import {
  showAppError,
  showExpandModalSuccessToast,
} from "../../../../state/app/actions";
import { pendoTrack } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";

const BOUNDARIES_TIME = 15 * 60 * 1000; // 15 mins

const ScreenBoundariesPassed = ({
  resetAction,
  isBoundariesLoadingState,
  setIsBoundariesLoadingState,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.snapshot}>
      <div className={styles.container}>
        <div className={styles.boundariesScreenContainer}>
          <div className={styles.text}>
            <FormattedMessage
              defaultMessage="Are you still viewing this screen?"
              id="G+IDNJ"
            />
          </div>
          <Button
            label={intl.formatMessage({
              defaultMessage: "Continue",
              id: "acrOoz",
            })}
            size={BUTTON_SIZES.REGULAR}
            type={BUTTON_TYPES.SECONDARY}
            data-test-id="td-Pages-ManageClasses-Clear-Search"
            onAction={() => {
              setIsBoundariesLoadingState(true);
              resetAction();
              window.setTimeout(() => {
                setIsBoundariesLoadingState(false);
              }, 5000);
            }}
            isDisabled={isBoundariesLoadingState}
            isLoading={isBoundariesLoadingState}
            className={styles.button}
          />
        </div>
      </div>
    </div>
  );
};

const StudentTileExpandModalCurrentScreen = ({
  expandModalInfo,
  getCurrentScreenSnapshot,
  screenUnavailable,
  screenLoading,
  instanceId,
  dataTestPrefix,
  uniqueActivities,
  classActivities,
  classId,
  createSnapshot,
  showSuccessMessage,
  showErrorMessage,
}) => {
  const intl = useIntl();
  const studentId = _.get(expandModalInfo, `studentId`);
  const image = _.get(expandModalInfo, `instances.${instanceId}.image`);
  const url = _.get(expandModalInfo, `instances.${instanceId}.url`);
  const isNoImage = _.get(expandModalInfo, `instances.${instanceId}.isNoImage`);

  const [isSnapshot, setIsSnapshot] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isBoundariesPassed, setIsBoundariesPassed] = useState(false);
  const [isBoundariesLoadingState, setIsBoundariesLoadingState] =
    useState(false);
  const [isSnapInProgress, setIsSnapInProgress] = useState(false);

  const boundariesTimer = useRef();

  const REFRESH_RATE = 2000; //2 sec
  const NO_IMAGE_TIMEOUT = 5000; //5 sec

  useEffect(() => {
    setIsSnapshot(!!image && !(image === NO_CURRENT_SCREEN_AVAILABLE));
  }, [image]);

  useEffect(() => {
    boundariesTimer.current = window.setTimeout(() => {
      setIsBoundariesPassed(true);
    }, BOUNDARIES_TIME);
    return () => {
      if (boundariesTimer.current) {
        window.clearTimeout(boundariesTimer.current);
      }
    };
  }, []);

  // fetch image every REFRESH_RATE
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isBoundariesPassed) {
        getCurrentScreenSnapshot({
          studentId,
        });
      }
    }, REFRESH_RATE);
    return () => clearInterval(interval);
  }, [getCurrentScreenSnapshot, studentId, isBoundariesPassed]);

  //display empty screen if there is no image available longer than NO_IMAGE_TIMEOUT
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (isNoImage) {
        setIsTimeout(true);
      }
    }, NO_IMAGE_TIMEOUT);
    return () => {
      setIsTimeout(false);
      window.clearTimeout(timer);
    };
  }, [NO_IMAGE_TIMEOUT, isNoImage]);

  const onClick = async () => {
    setIsSnapInProgress(true);
    try {
      await createSnapshot({
        classId,
        activityData: currentActivity.convertToSnapActivity(),
        snapshotType: SNAPSHOT_TYPES.URL,
        icon: currentActivity.icon,
        title: currentActivity.title,
        url: currentActivity.url,
      });

      pendoTrack("Snap - Take a snap from Student Tile Expand Modal", {
        classId: classId,
        snapTimestamp: new Date().toISOString(),
      });

      showSuccessMessage(classId);
    } catch (error) {
      showErrorMessage(error);
    }
    setIsSnapInProgress(false);
  };

  const uniqueActivity = _.find(uniqueActivities, { url: url });
  const classActivity = _.find(classActivities, { url: url });
  const currentActivity = uniqueActivity ? uniqueActivity : classActivity;

  if (
    !isTimeout &&
    isSnapshot &&
    !(isBoundariesPassed || isBoundariesLoadingState)
  ) {
    return (
      <div className={styles.snapshot}>
        <div className={styles.container}>
          <img
            src={image}
            alt={intl.formatMessage({
              defaultMessage: "screenshot of website",
              id: "AEpOJU",
            })}
            className={styles.image}
          />
          <div
            className={styles.info}
            data-test-id="hl-StudentTileExpandModal-Screens-CurrentUrl"
          >
            <div className={styles.textContainer}>
              <span className={styles.text}>
                <FormattedMessage
                  defaultMessage="Currently viewing:"
                  id="RBaTVa"
                />
              </span>
              {url}
            </div>
            <Button
              className={styles.button}
              isDisabled={!currentActivity}
              isLoading={isSnapInProgress}
              icon="camera"
              label={intl.formatMessage({
                defaultMessage: "Take a snap",
                id: "ldZA+V",
              })}
              type={BUTTON_TYPES.OUTLINED}
              outlineType={BUTTON_OUTLINE_TYPES.SOLID}
              size={BUTTON_SIZES.SMALL}
              dataTestId={`${dataTestPrefix}-Button-TakeSnap`}
              aria-label={intl.formatMessage({
                defaultMessage: "Take a snap",
                id: "ldZA+V",
              })}
              onAction={onClick}
            />
          </div>
          {isNoImage && (
            <div className={styles.loading}>
              <HapReactIcon svg="loader" spin={true} width={16} height={16} />
            </div>
          )}
        </div>
      </div>
    );
  }

  if (isBoundariesPassed || isBoundariesLoadingState) {
    return (
      <ScreenBoundariesPassed
        isBoundariesLoadingState={isBoundariesLoadingState}
        setIsBoundariesLoadingState={setIsBoundariesLoadingState}
        resetAction={() => {
          setIsBoundariesPassed(false);
          boundariesTimer.current = window.setTimeout(() => {
            setIsBoundariesPassed(true);
          }, BOUNDARIES_TIME);
        }}
      />
    );
  }

  if (isTimeout) {
    return screenUnavailable;
  }

  return screenLoading;
};

StudentTileExpandModalCurrentScreen.propTypes = {
  screenLoading: PropTypes.element.isRequired,
  screenUnavailable: PropTypes.element.isRequired,
  instanceId: PropTypes.string,
  dataTestPrefix: PropTypes.string.isRequired,
  // connected
  expandModalInfo: CURRENT_SCREEN_EXPAND_MODAL_TYPE,
  getCurrentScreenSnapshot: PropTypes.func.isRequired,
  uniqueActivities: PropTypes.arrayOf(UNIQUE_ACTIVITY_TYPE),
  classActivities: PropTypes.arrayOf(ACTIVITY_DETAILS_TYPE),
  classId: PropTypes.string.isRequired,
  createSnapshot: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    expandModalInfo: getCurrentScreenExpandModalInfo(state),
    uniqueActivities: getUniqueActivity(state),
    classActivities: getClassActivity(state, ACTIVITY_TYPES.COLLABORATION),
    classId: getClassId(state),
  }),
  (dispatch) => ({
    getCurrentScreenSnapshot: (payload) =>
      dispatch(getCurrentScreenSnapshot(payload)),
    showErrorMessage: (error) => dispatch(showAppError(error)),
    showSuccessMessage: (classId) =>
      dispatch(showExpandModalSuccessToast(classId)),
    createSnapshot: ({
      classId,
      activityData,
      snapshotType,
      url,
      icon,
      title,
    }) => {
      return dispatch(
        createSnapshot(classId, activityData, snapshotType, url, icon, title)
      );
    },
  })
)(StudentTileExpandModalCurrentScreen);
