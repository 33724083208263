import React from "react";
import styles from "./SideNavItem.module.scss";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { FormattedMessage } from "react-intl";

interface ErrorStateProps {
  onTryAgain: () => void;
  dataTestIdPrefix: string;
  errorStateText: string;
}

export const ErrorState = ({
  onTryAgain,
  dataTestIdPrefix,
  errorStateText,
}: ErrorStateProps) => {
  return (
    <div className={styles.stateContainer}>
      <div className={styles.text}>
        <HapReactIcon
          className={styles.textIcon}
          svg="fill-circle-exclamation"
          width={16}
          height={16}
        />
        <div>{errorStateText}</div>
      </div>
      <div className={styles.button}>
        <Button
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          label={<FormattedMessage id="FazwRl" defaultMessage="Try again" />}
          onClick={onTryAgain}
          data-test-id={`${dataTestIdPrefix}-Button-Reload`}
          className={styles.tryAgainButton}
        />
      </div>
    </div>
  );
};
