import { BlockForClassActions } from "./BlockForClassActions";
import { BlockForStudentActions } from "./BlockForStudentActions";

interface DialogActionsProps {
  modalView: "block-class" | "block-student";
  onClose: () => void;
  onSave: () => void;
  activeClassFilterSession: boolean;
  duration?: number | null;
  activeOtherSession?: boolean;
}

export const DialogActions = ({
  modalView,
  onClose,
  onSave,
  activeClassFilterSession,
  duration,
  activeOtherSession,
}: DialogActionsProps) => {
  switch (modalView) {
    case "block-class":
      return (
        <BlockForClassActions
          onClose={onClose}
          onSave={onSave}
          dataTestIdPrefix="block-for-class"
          hasActiveClassSession={!!activeClassFilterSession}
          isSaveDisabled={
            !activeClassFilterSession && !duration && !activeOtherSession
          }
        />
      );
    case "block-student":
      return (
        <BlockForStudentActions
          onClose={onClose}
          onSave={onSave}
          dataTestIdPrefix="block-for-student"
          isSaveDisabled={
            !activeClassFilterSession && !duration && !activeOtherSession
          }
        />
      );
    default:
      return undefined;
  }
};
