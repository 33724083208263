import actionTypes from "../../actionTypes";
import _ from "lodash";

const INIT_STATE = {
  modalData: {
    isOpen: false,
    isError: false,
    isSent: false,
    isDuplicateSession: false,
  },
};

const shareLinksReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_SHOW: {
      const newModalData = {
        isOpen: true,
        isError: false,
        isSent: false,
      };
      const selectedStudent = _.get(action, "payload.selectedStudent");
      if (selectedStudent) {
        newModalData.selectedStudent = selectedStudent;
      }
      const sessionData = _.get(action, "payload.sessionData");
      if (sessionData) {
        newModalData.sessionData = sessionData;
      }
      const isDuplicateSession = _.get(action, "payload.isDuplicateSession");
      if (isDuplicateSession) {
        newModalData.isDuplicateSession = isDuplicateSession;
      }
      const isGClassroomShare = _.get(action, "payload.isGClassroomShare");
      if (isGClassroomShare) {
        newModalData.isGClassroomShare = isGClassroomShare;
      }
      const GClassroomID = _.get(action, "payload.GClassroomID");
      if (GClassroomID) {
        newModalData.GClassroomID = GClassroomID;
      }
      return {
        ...state,
        modalData: {
          ...newModalData,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_HIDE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_ERROR: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          isError: true,
        },
      };
    }

    case actionTypes.HIGHLIGHTS_SHARE_LINKS_MODAL_SUCCESS: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          isSent: true,
        },
      };
    }

    default:
      return state;
  }
};

export default shareLinksReducer;
