import React from "react";
import styles from "./ModalFormDuration.module.scss";
import { FormattedMessage } from "react-intl";
import { DurationInput } from "../DurationInput";
import { useSelector } from "react-redux";
import { getFFByName } from "../../../../state/app/selectors";

interface ModalFormDurationProps {
  duration: number | null;
  onDurationChange: (duration: number) => void;
  dataTestPrefix: string;
}

const ModalFormDuration: React.FC<ModalFormDurationProps> = ({
  duration,
  onDurationChange,
  dataTestPrefix,
}) => {
  const hasExtendedOptions = useSelector(
    getFFByName("GBE-03-extended-duration-options")
  );
  return (
    <div
      className={styles.root}
      data-test-id={`${dataTestPrefix}-FormDurationBlock`}
    >
      <fieldset className={styles.durationForm}>
        <div>
          <legend className={styles.title}>
            <FormattedMessage
              defaultMessage="How long is this session?"
              id="2Nx/OD"
            />
          </legend>
          <div
            id="hl-GuideBrowsingCreateSession-Duration-Maxtime"
            className={styles.subTitle}
          >
            <FormattedMessage
              defaultMessage="Maximum time is 180 minutes"
              id="uLim27"
            />
          </div>
        </div>
        <DurationInput
          hasExtendedOptions={hasExtendedOptions}
          duration={duration}
          onDurationChange={onDurationChange}
          dataTestPrefix={dataTestPrefix}
        />
      </fieldset>
    </div>
  );
};

export default ModalFormDuration;
