import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import HapReactIcon from "../../atomic/icon/hapReactIcon";
import styles from "./ColorPicker.module.scss";
import { isBlackFont } from "../utils";
import { intlGroupColourNames } from "../colourPicker/intlGroupColourNames";

/**
 *
 * @param selectedColour
 * @param onSelectColour
 * @returns {*}
 * @constructor
 */
const ColorPicker = ({
  selectedColour,
  onSelectColour,
  isFlat = true,
  dataTestId,
  className = "",
}) => {
  const intl = useIntl();
  const data = [
    {
      colorCode: "#941F22",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_0),
    },
    {
      colorCode: "#781069",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_4),
    },
    {
      colorCode: "#53128D",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_8),
    },
    {
      colorCode: "#142397",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_12),
    },
    {
      colorCode: "#007CAD",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_16),
    },
    {
      colorCode: "#008080",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_20),
    },
    {
      colorCode: "#06892D",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_24),
    },
    {
      colorCode: "#78A419",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_28),
    },
    {
      colorCode: "#A6A81C",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_32),
    },
    {
      colorCode: "#BD5920",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_36),
    },
    {
      colorCode: "#E36621",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_40),
    },
    {
      colorCode: "#EA0000",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_1),
    },
    {
      colorCode: "#D100B5",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_5),
    },
    {
      colorCode: "#8509E6",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_9),
    },
    {
      colorCode: "#3346D8",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_12),
    },
    {
      colorCode: "#0B9AD4",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_17),
    },
    {
      colorCode: "#00D3D4",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_21),
    },
    {
      colorCode: "#00C12C",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_25),
    },
    {
      colorCode: "#97CD21",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_29),
    },
    {
      colorCode: "#D1D319",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_33),
    },
    {
      colorCode: "#ECAB1F",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_37),
    },
    {
      colorCode: "#FF975C",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_41),
    },
    {
      colorCode: "#FF6769",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_2),
    },
    {
      colorCode: "#FF7DED",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_6),
    },
    {
      colorCode: "#B76DFD",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_10),
    },
    {
      colorCode: "#8191FE",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_14),
    },
    {
      colorCode: "#60CBFC",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_18),
    },
    {
      colorCode: "#47FFFF",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_22),
    },
    {
      colorCode: "#44F56B",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_26),
    },
    {
      colorCode: "#C4F74E",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_30),
    },
    {
      colorCode: "#F3F636",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_34),
    },
    {
      colorCode: "#FFD16D",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_38),
    },
    {
      colorCode: "#FFCBAD",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_42),
    },
    {
      colorCode: "#FFBBBC",
      colorName: intl.formatMessage({
        defaultMessage: "Pastel Pink",
        id: "M+v4Lc",
      }),
    },
    {
      colorCode: "#FFB6F5",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_7),
    },
    {
      colorCode: "#D0A1FE",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_11),
    },
    {
      colorCode: "#B4BDFE",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_15),
    },
    {
      colorCode: "#ABE4FF",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_19),
    },
    {
      colorCode: "#ADFFFF",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_23),
    },
    {
      colorCode: "#A5FFB4",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_27),
    },
    {
      colorCode: "#DDFF85",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_31),
    },
    {
      colorCode: "#FFFF89",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_35),
    },
    {
      colorCode: "#FFE8B0",
      colorName: intl.formatMessage(intlGroupColourNames.groupColour_39),
    },
  ];

  return (
    <div className={classnames(styles.root, className)}>
      <div
        className={classnames(styles.container, [
          { [styles.hasShadow]: !isFlat },
        ])}
        role="radiogroup"
        aria-label={intl.formatMessage({
          defaultMessage: "Change class color",
          id: "0bukt0",
        })}
      >
        {data.map((item) => {
          const { colorName, colorCode } = item;
          return (
            <button
              key={colorCode}
              style={{ backgroundColor: colorCode }}
              data-test-id={`${dataTestId}-${colorCode}`}
              className={classnames([styles.tile])}
              onClick={(e) => {
                e.preventDefault();
                onSelectColour(colorCode);
              }}
              role="radio"
              aria-checked={colorCode === selectedColour}
              aria-label={colorName}
            >
              <div
                style={{
                  color: isBlackFont(selectedColour) ? "#000000" : "#ffffff",
                }}
                className={classnames([styles.check], {
                  [styles.visible]: colorCode === selectedColour,
                })}
              >
                <HapReactIcon svg="check" width="16" height="16" />
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  selectedColour: PropTypes.string,
  onSelectColour: PropTypes.func.isRequired,
  isFlat: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ColorPicker;
