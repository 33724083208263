import actionTypes from "../actionTypes";
import { APP_PAGES } from "../router/types";
import { MYWORKSPACES_CATEGORIES } from "../workspace/myworkspaces/list/types";
import { NAVIGATION_ITEMS_IDS } from "./types";
import { USER_PERMISSIONS } from "../user/types";
import { AppDispatch } from "../store";
import { FormattedMessage } from "react-intl";

type navItem = {
  id: string;
  testId: string;
  label: React.ReactNode;
  path: string;
  isExternal?: boolean;
  basePaths?: string[];
  permissions?: string[];
  active?: boolean;
};
export const updateNavigationItems = (items: navItem[]) => ({
  type: actionTypes.NAVIGATION_UPDATE_ITEMS,
  payload: items,
});

export const setNavigationByClassId =
  (classId: string, defaultSubjectFolder?: string) =>
  (dispatch: AppDispatch): void => {
    const newItems: navItem[] = [];
    const getWorkspaceNavItem = (classId: string | null): navItem => ({
      id: NAVIGATION_ITEMS_IDS.WORKSPACE,
      testId: "Workspace",
      label: <FormattedMessage defaultMessage="Workspace" id="rOZptg" />,
      path: classId
        ? `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.CLASSES.urlKey}?classId=${classId}`
        : `${APP_PAGES.WORKSPACE_MY_WORKSPACE.path}/${MYWORKSPACES_CATEGORIES.OWNED_BY_ME.urlKey}`,
      isExternal: false,
      basePaths: [
        APP_PAGES.WORKSPACE_BASE.path,
        APP_PAGES.MY_RESOURCES_BASE.path,
        APP_PAGES.LIBRARY_BASE.path,
        APP_PAGES.DISCOVER_BASE.path,
      ],
      permissions: [USER_PERMISSIONS.WORKSPACE],
    });

    if (!classId) {
      newItems.push({
        id: NAVIGATION_ITEMS_IDS.MANAGE_CLASSES,
        testId: "Manage Classes",
        label: <FormattedMessage defaultMessage="Manage Classes" id="UActyV" />,
        path: APP_PAGES.DASHBOARD_MANAGE_CLASSES_MY.path,
        isExternal: false,
        basePaths: [APP_PAGES.DASHBOARD_MANAGE_CLASSES.path],
        permissions: [USER_PERMISSIONS.MANAGE_CLASSES],
      });
      newItems.push(getWorkspaceNavItem(null));
    } else {
      newItems.push({
        id: NAVIGATION_ITEMS_IDS.DASHBOARD,
        path: `${APP_PAGES.DASHBOARD_BASE.path}/folder/${classId}/${defaultSubjectFolder}`,
        isExternal: false,
        testId: "Dashboard",
        label: <FormattedMessage defaultMessage="Dashboard" id="hzSNj4" />,
        permissions: [USER_PERMISSIONS.TEACHER_DASHBOARD],
        basePaths: [APP_PAGES.DASHBOARD_BASE.path],
      });
      newItems.push({
        id: NAVIGATION_ITEMS_IDS.HIGHLIGHTS,
        testId: "Highlights",
        label: <FormattedMessage defaultMessage="Highlights" id="KGmQjH" />,
        path: `${APP_PAGES.HIGHLIGHTS_ACTIVITY.path}?classId=${classId}`,
        isExternal: false,
        basePaths: [APP_PAGES.HIGHLIGHTS_BASE.path],
        permissions: [USER_PERMISSIONS.HIGHLIGHTS],
      });
      newItems.push(getWorkspaceNavItem(classId));
      newItems.push({
        id: NAVIGATION_ITEMS_IDS.CLASS_INFO,
        label: <FormattedMessage defaultMessage="Class Info" id="mE2oyy" />,
        path: `${APP_PAGES.CLASSINFO.path}/${classId}`,
        isExternal: false,
        permissions: [
          USER_PERMISSIONS.TEACHER_DASHBOARD,
          USER_PERMISSIONS.MANAGE_CLASSES,
        ],
        testId: "Class Info",
      });
    }

    dispatch(updateNavigationItems(newItems));
  };
