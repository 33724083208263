import _ from "lodash";
import { updateAccessError } from "../../app/actions";
import {
  getLibraryFileUploadURI,
  postLibraryResource,
  putLibraryResource,
} from "../../../apiCalls/library";

export const getFileUploadURI = () => (dispatch) => {
  return getLibraryFileUploadURI()
    .then((response) => {
      if (response.status !== 200) {
        throw Error(response.status);
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (_.get(error, "response.status") === 403) {
        // Not a valid user, show 403 screen
        dispatch(updateAccessError(true));
      }
      throw Error(error);
    });
};

export const addLibraryResource =
  ({ payload }) =>
  (dispatch) => {
    return postLibraryResource({ payload })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };

export const updateLibraryResource =
  ({ payload, resourceId }) =>
  (dispatch) => {
    return putLibraryResource({ payload, resourceId })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.status);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
        throw Error(error);
      });
  };
