import { useState } from "react";
import { GuidedBrowsingSection } from "../GuidedBrowsingSection";
import { GuidedBrowsingSectionContent } from "../GuidedBrowsingSectionContent";
import { useIntl } from "react-intl";
import { DurationInput } from "../../DurationInput";

interface GuidedBrowsingDurationPresentationProps {
  duration: number | null;
  onDurationChange: (duration: number) => void;
}

export const GuidedBrowsingDurationPresentation = ({
  duration,
  onDurationChange,
}: GuidedBrowsingDurationPresentationProps) => {
  const intl = useIntl();
  return (
    <GuidedBrowsingSection>
      <GuidedBrowsingSectionContent
        withCentredHeading
        withPersistedLayout
        title={intl.formatMessage({
          defaultMessage: "Duration",
          id: "IuFETn",
        })}
      >
        <DurationInput
          hasExtendedOptions
          duration={duration}
          onDurationChange={onDurationChange}
          dataTestPrefix="guided-browsing-duration"
        />
      </GuidedBrowsingSectionContent>
    </GuidedBrowsingSection>
  );
};

export const GuidedBrowsingDuration = ({
  duration,
  onDurationChange,
}: GuidedBrowsingDurationPresentationProps) => {
  return (
    <GuidedBrowsingDurationPresentation
      duration={duration}
      onDurationChange={onDurationChange}
    />
  );
};
