import {
  ClassInfoAddPersonResponse,
  ClassInfoResponse,
  ClassInfoData,
  ClassInfoAvailableActionResponse,
  ClassInfoPermissionsData,
} from "./classInfoTypes";
import { ClassDataSourceTypes } from "../../components/containers/Dashboard/DashboardPage/types";

export const transformDataSource = (
  sourceinfo?: string
): ClassDataSourceTypes => {
  /*
Possible values for sourceinfo: 
  SourceGoogleDirectory  Source = "Google:Directory"    //overnight scans
  SourceGoogleDrive      Source = "Google:Drive"        //overnight scans
  SourceGoogleClassroom  Source = "Google:Classroom"    //added via UI
  SourceMSAD             Source = "MS:AD"               //overnight scans
  SourceMSON             Source = "MS:ON"               //User Sync MS OneNote Roster
  SourceTDAdmin          Source = "Hap:TDAdmin"         //prefs, domain list
  SourceHScriptCommands  Source = "Hap:HScript:cmds"    //commands
  SourceHScriptGroupInfo Source = "Hap:HScript:grpinfo" //commands
  SourceSelfRostering    Source = "Hap:SelfRostering"   //"AKA CreateClass/EditClass/ClassInfo/ManageWorkGroup". Differentiate from DataLoad
  SourceHUGSAdmin        Source = "Hap:HUGSAdmin"       //Support etc
  SourceMonkeyWrench     Source = "Hap:MonkeyWrench"
  SourceUserUpload       Source = "Hap:UserUpload"
  SourceDataLoad  Source = "Hap:DataLoad"  //Data load is a deliberate act. It is important to differentiate this from SelfRostering
  SourceArchiving Source = "Hap:Archiving" //Archiving is a deliberate act.
*/

  if (sourceinfo === "Hap:SelfRostering") {
    return "SelfRostering";
  }
  if (sourceinfo === "Google:Classroom") {
    return "GoogleClassroom";
  }
  return "Dataloaded";
};

export const transformAddStudentResponse = (
  response: ClassInfoAddPersonResponse
) => {
  return {
    successfullyAddedStudents: response.Good,
    unsuccessfullyAddedStudents: response.Bad,
    pendingStudents: response.ToDo,
    classData: response.UpdatedGroupInfo
      ? transformClassInfoResponse(response.UpdatedGroupInfo)
      : undefined,
  };
};

export const transformAddTeacherResponse = (
  response: ClassInfoAddPersonResponse
) => {
  return {
    unsuccessfullyAddedTeacher: response.Bad,
    classData: response.UpdatedGroupInfo
      ? transformClassInfoResponse(response.UpdatedGroupInfo)
      : undefined,
  };
};

export const transformManagers = (managers: ClassInfoResponse["Managers"]) => {
  return (
    managers?.map((manager) => ({
      name: `${manager.Fname} ${manager.Lname}`,
      email: manager.PrimaryEmail,
      id: manager.Id,
      first_name: manager.Fname,
      last_name: manager.Lname,
    })) || []
  );
};

export const transformParticipants = (
  participants: ClassInfoResponse["Participants"]
) => {
  return (
    participants?.map((student) => ({
      name: `${student.Fname} ${student.Lname}`,
      email: student.PrimaryEmail,
      id: student.Id,
      first_name: student.Fname,
      last_name: student.Lname,
    })) || []
  );
};

export const transformPermissions = (
  availableActionsResponse?: string[]
): ClassInfoData["permissions"] => {
  const permissionFlags: {
    [K in keyof ClassInfoPermissionsData]: boolean;
  } = {
    teacherAdd: false,
    teacherRemove: false,
    resetPassword: false,
    studentAdd: false,
    studentRemove: false,
    editDisplayName: false,
    editNickname: false,
    classRemove: false,
    openAlternateLink: false,
    sync: false,
    studentsEdit: false,
    teachersEdit: false,
    classReset: false,
  };

  if (!availableActionsResponse) {
    return permissionFlags;
  }

  const permissionMapping: {
    [K in ClassInfoAvailableActionResponse]: keyof ClassInfoPermissionsData;
  } = {
    "Manager:Add": "teacherAdd",
    "Manager:Remove": "teacherRemove",
    ResetPwd: "resetPassword",
    "Participant:Add": "studentAdd",
    "Participant:Remove": "studentRemove",
    /** @description Used to rename data loaded classes */
    "WorkGroup:EditNickName": "editDisplayName",
    /** @description Used to rename self rostered classes */
    "WorkGroup:Edit": "editDisplayName",
    "WorkGroup:Deactivate": "classRemove",
    "WorkGroup:OpenAlternateLink": "openAlternateLink",
    "WorkGroup:Sync": "sync",
    Reprovision: "classReset",
  };

  for (const availableAction of availableActionsResponse) {
    const mappedPermission = permissionMapping[availableAction];
    if (mappedPermission) {
      if (mappedPermission !== "sync") {
        permissionFlags[mappedPermission] = true;
      }
      if (
        mappedPermission === "teacherAdd" ||
        mappedPermission === "teacherRemove" ||
        mappedPermission === "classRemove"
      ) {
        permissionFlags.teachersEdit = true;
      }
      if (
        mappedPermission === "studentAdd" ||
        mappedPermission === "studentRemove" ||
        mappedPermission === "openAlternateLink"
      ) {
        permissionFlags.studentsEdit = true;
      }
      if (
        mappedPermission === "editDisplayName" ||
        mappedPermission === "editNickname" ||
        mappedPermission === "classRemove"
      ) {
        permissionFlags.editDisplayName = true;
      }
      if (mappedPermission === "classRemove") {
        permissionFlags.sync = true;
      }
    }
  }

  return permissionFlags;
};

const transformGoogleDriveFolderLink = (
  googleDriveFolderId: string | undefined
) =>
  googleDriveFolderId
    ? `https://drive.google.com/drive/folders/${googleDriveFolderId}`
    : undefined;

export const transformClassInfoResponse = (
  response: ClassInfoResponse
): ClassInfoData => {
  return {
    teachers: transformManagers(response.Managers),
    students: transformParticipants(response.Participants),
    classIdentifier: response.Group.URN,
    classAlternateLink: response.Group.AlternateLink,
    className: response.Title,
    classNickname: response.Prefs.Nickname,
    permissions: transformPermissions(response.AvailableActions),
    classGroupId: response.Group.Id,
    classGroupURN: response.Group.URN,
    classDataSource: transformDataSource(
      response?.Group?.SourceInfo?.Main?.[0]
    ),
    emailFormat: response?.GroupProps?.EmailLinkFormat,
    googleDriveFolderLink: transformGoogleDriveFolderLink(
      response.GDriveFolderId
    ),
  };
};

export const transformAddTeacherErrorResponse = (
  error: string,
  statusCode: number
) => {
  const errorCode = error.includes("status code 400") ? 400 : statusCode;

  return {
    message: error,
    responseStatus: errorCode,
    apiPath: error.split('"')[1],
  };
};
