import { AnyAction } from "redux";
import actionTypes from "../../actionTypes";
import { DashboardManageClassesState, LoadClassesSuccessAction } from "./types";
import _ from "lodash";

const INIT_STATE: DashboardManageClassesState = {
  isLoadingClasses: false,
  isLoadedClasses: false,
  isErrorLoading: false,
  classesList: [],
  ui: {
    editColourDialogOpened: false,
    addClassDialogOpened: false,
    googleClassroomSyncOpened: false,
    searchClassesQuery: "",
  },
};

const manageClassesReducer = (
  state: DashboardManageClassesState = INIT_STATE,
  action: AnyAction
): DashboardManageClassesState => {
  switch (action.type) {
    case actionTypes.DASHBOARD_CLASS_LIST_PENDING: {
      return {
        ...state,
        isLoadingClasses: true,
        isErrorLoading: false,
        classesList: [],
        isLoadedClasses: false,
      };
    }

    case actionTypes.DASHBOARD_CLASS_LIST_SUCCESS: {
      const dashAction = action as LoadClassesSuccessAction;
      return {
        ...state,
        isLoadingClasses: false,
        isErrorLoading: false,
        classesList: dashAction.payload,
        isLoadedClasses: true,
      };
    }

    case actionTypes.DASHBOARD_CLASS_LIST_ERROR: {
      return {
        ...state,
        isLoadingClasses: false,
        isErrorLoading: true,
        classesList: [],
        isLoadedClasses: false,
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_OPEN: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: true,
          editedClass: action.payload,
          addClassDialogOpened: false,
          googleClassroomSyncOpened: false,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_EDIT_COLOR_MODAL_CLOSE: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: false,
          addClassDialogOpened: false,
          googleClassroomSyncOpened: false,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_OPEN: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: false,
          addClassDialogOpened: true,
          googleClassroomSyncOpened: false,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_OPEN: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: false,
          addClassDialogOpened: false,
          googleClassroomSyncOpened: true,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_ADD_CLASS_MODAL_CLOSE: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: false,
          addClassDialogOpened: false,
          googleClassroomSyncOpened: false,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_GC_SYNC_MODAL_CLOSE: {
      return {
        ...state,
        ui: {
          searchClassesQuery: state.ui.searchClassesQuery,
          editColourDialogOpened: false,
          addClassDialogOpened: false,
          googleClassroomSyncOpened: false,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_COLOUR_UPDATED: {
      return {
        ...state,
        classesList: state.classesList.map((cl) => {
          if (cl.id === action.classURN) {
            return { ...cl, color: action.newColour };
          }
          return cl;
        }),
      };
    }
    case actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_STAR_UPDATED: {
      return {
        ...state,
        classesList: state.classesList.map((cl) => {
          if (cl.id === action.classURN) {
            return { ...cl, starred: action.isStarred };
          }
          return cl;
        }),
      };
    }
    case actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_PRIORITY_UPDATED: {
      return {
        ...state,
        classesList: state.classesList.map((cl) => {
          for (let i = 0; i < action.updates.length; i++) {
            if (cl.id === action.updates[i].id) {
              return { ...cl, order_priority: action.updates[i].priority };
            }
          }
          return cl;
        }),
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_SET_SEARCH_CLASSES_QUERY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          searchClassesQuery: action.query,
        },
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_CLASS_ADDED: {
      return {
        ...state,
        classesList: state.classesList.concat([action.payload]),
      };
    }

    case actionTypes.DASHBOARD_MANAGE_CLASSES_GC_CLASSES_SYNCED: {
      return {
        ...state,
        classesList: state.classesList.concat(action.payload),
      };
    }

    case actionTypes.DASHBOARD_CLASS_LIST_SYNC_SUCCESS: {
      return {
        ...state,
        classesList: state.classesList.map((cl) => {
          const matchedClass = _.find(action.payload, (c) => c.id === cl.id);
          if (matchedClass) {
            if (
              matchedClass.isClassSyncInProgress !== cl.isClassSyncInProgress
            ) {
              return {
                ...cl,
                isClassSyncInProgress: matchedClass.isClassSyncInProgress,
              };
            }
          }
          return cl;
        }),
      };
    }
    default:
      return state;
  }
};

export default manageClassesReducer;
