import { useIntl } from "react-intl";
import React from "react";
import styles from "./SmartshareFilePicker.module.scss";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import Button, {
  BUTTON_OUTLINE_TYPES,
  BUTTON_TYPES,
} from "../../../../atomic/Button/Button";
import _ from "lodash";
import ButtonGroup from "../../../FormBlock/ButtonGroup";
import { SHARE_TYPE_COPY } from "./types";
import { useGooglePicker } from "../../../../hooks/useGooglePicker";

export const SmartshareFilePicker = ({
  files,
  setFiles,
  filesConfig,
  setFilesConfig,
  hasUpdatedSharedDrive,
}) => {
  const intl = useIntl();
  const addNewDoc = (mimeType, name) => {
    const newFilesNumber = files.filter((fl) =>
      _.startsWith(fl.id, "new_file")
    ).length;
    const data = {
      id: "new_file_" + (newFilesNumber + 1),
      mimeType: mimeType,
      name: name,
    };
    setFiles(files.concat([data]));
    setFilesConfig({
      ...filesConfig,
      [data.id]: { appendName: false, shareType: SHARE_TYPE_COPY },
    });
  };

  const { launchPicker, isReady } = useGooglePicker({
    tabs: [
      {
        type: "docs",
        label: "My Google Drive",
        mode: "list",
        ownedByMe: true,
        includeFolders: true,
      },
      {
        type: "recent",
        label: "Recent",
      },
      {
        type: "upload",
      },
      {
        type: "all",
        label: "Shared drives",
        mode: "list",
        ownedByMe: false,
        includeFolders: true,
        enableSharedDrives: hasUpdatedSharedDrive ? true : undefined,
      },
      {
        type: "spreadsheets",
        label: "Spreadsheets",
      },
      {
        type: "docs-images-and-videos",
        label: "My images and videos",
      },
      {
        type: "documents",
        label: "Documents",
      },
      {
        type: "presentations",
        label: "Presentations",
      },
      {
        type: "pdfs",
        label: "PDFs",
      },
    ],
    onSelect: (data) => {
      if (data) {
        if (_.find(files, (fl) => fl.id === data.id)) {
          // TODO: show app error
        } else {
          setFilesConfig({
            ...filesConfig,
            [data.id]: {
              appendName: false,
              shareType: SHARE_TYPE_COPY,
              originalName: data.name,
            },
          });
          setFiles(files.concat([data]));
        }
      }
    },
  });

  return (
    <div className={styles.root}>
      <div className={styles.existingFileShare}>
        <ButtonGroup
          label={intl.formatMessage({
            defaultMessage: "Share existing file",
            id: "Rvew59",
          })}
        >
          <Button
            label={intl.formatMessage({
              defaultMessage: "Select or upload file",
              id: "sF35lC",
            })}
            icon="drive-coloured"
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            onClick={launchPicker}
            disabled={!isReady}
          />
        </ButtonGroup>
      </div>
      <ButtonGroup
        label={intl.formatMessage({
          defaultMessage: "Share new, blank file",
          id: "NBsUs2",
        })}
      >
        <Tippy
          content="Create a new Google Document"
          theme="hsuite"
          placement="top"
        >
          <Button
            className={styles.newFile}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            icon="google-document"
            onClick={() =>
              addNewDoc("application/vnd.google-apps.document", "New Doc")
            }
            data-test-id="common-Smartshare-Dialog-ChooseShareType-Action-CreateGoogleDoc"
          />
        </Tippy>
        <Tippy
          content="Create a new Google Spreadsheet"
          theme="hsuite"
          placement="top"
        >
          <Button
            className={styles.newFile}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            icon="google-spreadsheet"
            onClick={() =>
              addNewDoc("application/vnd.google-apps.spreadsheet", "New Sheet")
            }
            data-test-id="common-Smartshare-Dialog-ChooseShareType-Action-CreateGoogleSpreadSheet"
          />
        </Tippy>
        <Tippy
          content="Create a new Google Slide"
          theme="hsuite"
          placement="top"
        >
          <Button
            className={styles.newFile}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            icon="google-presentation"
            onClick={() =>
              addNewDoc("application/vnd.google-apps.presentation", "New Slide")
            }
            data-test-id="common-Smartshare-Dialog-ChooseShareType-Action-CreateGoogleSlide"
          />
        </Tippy>
        <Tippy
          content="Create a new Google Drawing"
          theme="hsuite"
          placement="top"
        >
          <Button
            className={styles.newFile}
            type={BUTTON_TYPES.OUTLINED}
            outlineType={BUTTON_OUTLINE_TYPES.SOLID}
            icon="google-drawing"
            onClick={() =>
              addNewDoc("application/vnd.google-apps.drawing", "New Drawing")
            }
            data-test-id="common-Smartshare-Dialog-ChooseShareType-Action-CreateGoogleDrawing"
          />
        </Tippy>
      </ButtonGroup>
    </div>
  );
};

SmartshareFilePicker.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  filesConfig: PropTypes.object.isRequired,
  setFiles: PropTypes.func.isRequired,
  setFilesConfig: PropTypes.func.isRequired,
  tokenClient: PropTypes.object,
  accessToken: PropTypes.object,
  hasUpdatedSharedDrive: PropTypes.bool,
};

export default SmartshareFilePicker;
