import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Loader.module.scss";
import HapReactIcon from "../../icon/hapReactIcon";

const Loader = ({ visible, width, height }) => {
  return (
    <div
      className={classnames(styles.root, {
        [styles.visible]: visible,
      })}
    >
      <div>
        <HapReactIcon svg="loader" spin={true} width={width} height={height} />
      </div>
    </div>
  );
};

Loader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Loader;
