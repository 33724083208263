import _ from "lodash";
import store from "../../store";

//SHARED SELECTORS
export const getCourseID = () =>
  _.get(store.getState(), "highlights.shareLinks.modalData.GClassroomID");
export const hasGCMaterialsAccess = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.hasGCMaterialsAccess"
  ); //NEEDS TO BE AUTHORISED BY SCHOOL

//COURSEWORK SELECTORS
export const getCourseWork = () =>
  // TODO: https://hapara.atlassian.net/browse/HAP-10884
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.courseWork"
  ) as any;
export const getIsCourseworkLoading = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.courseWork.isLoading"
  );
export const getIsCourseworkError = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.courseWork.isError"
  );
export const getIsCourseWorkLoadingMore = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.courseWork.isCourseWorkLoadingMore"
  );

//MATERIALS SELECTORS
export const getMaterials = () =>
  // TODO: https://hapara.atlassian.net/browse/HAP-10884
  _.get(store.getState(), "highlights.googleClassroomContent.materials") as any;
export const getIsMaterialsLoading = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.materials.isLoading"
  );
export const getIsMaterialsError = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.materials.isError"
  );
export const getIsMaterialsLoadingMore = () =>
  _.get(
    store.getState(),
    "highlights.googleClassroomContent.materials.isMaterialsLoadingMore"
  );
