import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import styles from "./ScheduledSessionsList.module.scss";
import PropTypes from "prop-types";
import HapReactIcon from "@hapara/ui/src/atomic/icon/hapReactIcon";
import {
  GUIDE_BROWSING_SESSION_TYPE,
  SESSION_TYPES,
  SESSION_RECIPIENT_TYPES,
} from "../../../../state/highlights/sessions/types";
import {
  WINDOW_SIZE_TYPES,
  WINDOW_SIZE_TYPE_LIST,
} from "../../../../state/app/types";
import classnames from "classnames";
import ScheduledSessionsDeleteModal from "../ScheduledSessionDeleteModal/ScheduledSessionDeleteModal";
import Button, {
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "@hapara/ui/src/atomic/Button/Button";
import { getClassId } from "../../../../state/shared/selectors";
import { updateScheduledSessionName } from "../../../../state/highlights/sessions/actions";
import { getWindowSize } from "../../../../state/app/selectors";
import Tippy from "@tippy.js/react";
import moment from "moment";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

const dataTestPrefix = "hl-ScheduleSessions-List";

const ScheduledSessionsList = ({
  sessions,
  classId = "",
  updateSessionName,
  loadMoreSessions,
  reloadSessionsList,
  isLoadMoreInProgress,
  showLoadMoreButton,
  editOrDuplicateSession,
  windowSize,
}) => {
  const intl = useIntl();
  const [savingError, setSavingError] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sessionToBeDeleted, setSessionToBeDeleted] = useState({});
  const sessionsByDays = [];
  const SESSION_TYPES_NAMES = {
    [SESSION_TYPES.FOCUS]: intl.formatMessage({
      defaultMessage: "Focus",
      id: "hsJlm7",
    }),
    [SESSION_TYPES.FILTER]: intl.formatMessage({
      defaultMessage: "Filter",
      id: "9Obw6C",
    }),
    [SESSION_TYPES.LOCK]: intl.formatMessage({
      defaultMessage: "Freeze",
      id: "EgadwQ",
    }),
    [SESSION_TYPES.SHARE_LINKS]: intl.formatMessage({
      defaultMessage: "Links",
      id: "qCcwo3",
    }),
  };
  const setErrorState = (sessionId, isError) => {
    const se = _.cloneDeep(savingError);
    se[sessionId] = isError;
    setSavingError(se);
  };

  const modalClose = () => {
    setIsDeleteModalOpen(false);
    setSessionToBeDeleted({});
  };

  const debouncedSessionNameSaving = useCallback(
    _.debounce((sessionId, sessionName) => {
      updateSessionName({ classId, sessionId, sessionName })
        .then((r) => {
          setErrorState(sessionId, false);
        })
        .catch((e) => {
          setErrorState(sessionId, true);
        });
    }, 1000),
    [savingError]
  );

  sessions.forEach((s) => {
    const startTime = moment(s.Start);
    const dateKey = startTime.format("ddd-DD-MMM");
    const duration = s.Duration;
    const session = {
      Name: s.SessionName,
      Time: startTime.format("h[.]mm a"),
      Type: s.Type,
      Duration:
        duration > 0
          ? intl.formatMessage(
              { defaultMessage: "{duration} min", id: "nFssez" },
              { duration: duration / 60 }
            )
          : intl.formatMessage({ defaultMessage: "N/A", id: "PW+sL4" }),
      RecipientType: s.Recipient.Type,
      Students: s.Recipient.Students,
      Groups: s.Recipient.Groups,
      Links: s.Links,
      Past: moment() > startTime,
      Id: s.ID,
    };
    if (!sessionsByDays[dateKey]) {
      sessionsByDays[dateKey] = {
        dayOfWeek: startTime.format("ddd"),
        dateNumber: startTime.format("DD"),
        month: startTime.format("MMM"),
        sessions: [],
      };
    }
    sessionsByDays[dateKey].sessions.push(session);
  });

  return (
    <div className={styles.root}>
      {Object.keys(sessionsByDays).map((key) => {
        const sessionBlock = sessionsByDays[key];
        return (
          <div className={styles.block} key={key}>
            <div className={styles.date}>
              <span className={styles.dayOfWeek}>{sessionBlock.dayOfWeek}</span>
              <span className={styles.dateNumber}>
                {sessionBlock.dateNumber}
              </span>
              <span className={styles.month}>{sessionBlock.month}</span>
            </div>
            <div className={styles.sessions}>
              {sessionBlock.sessions.map((s) => {
                return (
                  <div
                    className={classnames(styles.sessionDetails, {
                      [styles.pastSession]: s.Past,
                    })}
                    key={s.Id}
                  >
                    <span className={styles.time}>{s.Time}</span>
                    <span className={styles.name}>
                      <input
                        type="text"
                        data-test-id={`${dataTestPrefix}-TitleInput`}
                        aria-label={intl.formatMessage({
                          defaultMessage: "Edit session name",
                          id: "fblLli",
                        })}
                        defaultValue={s.Name}
                        placeholder={
                          s.Past
                            ? intl.formatMessage({
                                defaultMessage: "Untitled session",
                                id: "VZOiVM",
                              })
                            : intl.formatMessage({
                                defaultMessage: "Add a session name",
                                id: "3HxU9O",
                              })
                        }
                        className={classnames(styles.nameInput, {
                          [styles.errorState]: savingError[s.Id],
                        })}
                        onChange={(e) => {
                          debouncedSessionNameSaving(
                            s.Id,
                            _.get(e, "target.value", "")
                          );
                        }}
                        disabled={s.Past}
                      />
                      {savingError[s.Id] && (
                        <span className={styles.errorSaving}>
                          <FormattedMessage
                            defaultMessage="Sorry, there was a problem saving the name"
                            id="bJScRE"
                          />
                        </span>
                      )}
                    </span>
                    {windowSize >= WINDOW_SIZE_TYPES.SM && (
                      <>
                        <span className={styles.type}>
                          <span className={styles.label}>
                            <FormattedMessage
                              defaultMessage="Session type"
                              id="Eng7Nh"
                            />
                          </span>
                          <span className={styles.text}>
                            {
                              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                              `${SESSION_TYPES_NAMES[s.Type]} `
                            }
                            {(s.Type === SESSION_TYPES.FOCUS ||
                              s.Type === SESSION_TYPES.FILTER ||
                              s.Type === SESSION_TYPES.LOCK) && (
                              <HapReactIcon
                                svg="fill-focused-browsing"
                                width={16}
                                height={16}
                                className={styles.focusIcon}
                              />
                            )}
                          </span>
                        </span>
                        <span className={styles.duration}>
                          <span className={styles.label}>
                            <FormattedMessage
                              defaultMessage="Set duration"
                              id="Ok5IRL"
                            />
                          </span>
                          <span className={styles.text}>{s.Duration}</span>
                        </span>
                        <span className={styles.students}>
                          <span className={styles.label}>
                            <FormattedMessage
                              defaultMessage="Students"
                              id="uhwf+D"
                            />
                          </span>
                          <span className={styles.text}>
                            <span className={styles.text}>
                              {s.RecipientType ===
                                SESSION_RECIPIENT_TYPES.STUDENTS && (
                                <FormattedMessage
                                  defaultMessage="{count, plural, one {1 student} other {# students}}"
                                  id="4UnBl1"
                                  values={{ count: (s.Students || []).length }}
                                />
                              )}

                              {s.RecipientType ===
                                SESSION_RECIPIENT_TYPES.GROUPS && (
                                <FormattedMessage
                                  defaultMessage="{count, plural, one {1 group} other {# groups}}"
                                  id="/ZQwOT"
                                  values={{ count: (s.Groups || []).length }}
                                />
                              )}
                              {s.RecipientType ===
                                SESSION_RECIPIENT_TYPES.CLASS && (
                                <FormattedMessage
                                  defaultMessage="The class"
                                  id="ZqRDOJ"
                                />
                              )}
                            </span>
                          </span>
                        </span>
                        <span className={styles.links}>
                          <span className={styles.label}>
                            <FormattedMessage
                              defaultMessage="Links"
                              id="qCcwo3"
                            />
                          </span>
                          <Tippy
                            content={
                              <>
                                {s.Links
                                  ? s.Links.map((l) => {
                                      return (
                                        <span key={l}>
                                          {l}
                                          <br />
                                        </span>
                                      );
                                    })
                                  : ""}
                              </>
                            }
                            theme="hsuite"
                          >
                            <div>
                              <span className={styles.text}>
                                {s.Links ? (
                                  s.Links.length
                                ) : (
                                  <FormattedMessage
                                    defaultMessage="N/A"
                                    id="PW+sL4"
                                  />
                                )}
                              </span>
                            </div>
                          </Tippy>
                        </span>
                      </>
                    )}
                    <span className={styles.actions}>
                      {!s.Past && (
                        <Tippy content="Edit this session" theme="hsuite">
                          <Button
                            type={BUTTON_TYPES.TERTIARY}
                            size={BUTTON_SIZES.SMALL}
                            onAction={() => {
                              editOrDuplicateSession({
                                sessionId: s.Id,
                                isDuplicate: false,
                              });
                            }}
                            icon="pencil"
                            data-test-id={`${dataTestPrefix}-EditSession-Button`}
                            aria-label={intl.formatMessage({
                              defaultMessage: "Edit this session",
                              id: "9znIRo",
                            })}
                            className={styles.actionIcons}
                          />
                        </Tippy>
                      )}
                      <Tippy
                        content={intl.formatMessage({
                          defaultMessage: "Duplicate this session",
                          id: "qWUUHr",
                        })}
                        theme="hsuite"
                      >
                        <Button
                          type={BUTTON_TYPES.TERTIARY}
                          size={BUTTON_SIZES.SMALL}
                          onAction={() => {
                            editOrDuplicateSession({
                              sessionId: s.Id,
                              isDuplicate: true,
                            });
                          }}
                          icon="duplicate"
                          data-test-id={`${dataTestPrefix}-DuplicateSession-Button`}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Duplicate this session",
                            id: "qWUUHr",
                          })}
                          className={styles.actionIcons}
                        />
                      </Tippy>
                      {!s.Past && (
                        <Tippy
                          content={intl.formatMessage({
                            defaultMessage: "Delete this session",
                            id: "D7kmpl",
                          })}
                          theme="hsuite"
                        >
                          <Button
                            type={BUTTON_TYPES.TERTIARY}
                            size={BUTTON_SIZES.SMALL}
                            onAction={() => {
                              setSessionToBeDeleted(s);
                              setIsDeleteModalOpen(true);
                            }}
                            icon="delete"
                            data-test-id={`${dataTestPrefix}-DeleteSession-Button`}
                            aria-label={intl.formatMessage({
                              defaultMessage: "Delete this session",
                              id: "D7kmpl",
                            })}
                            className={styles.actionIcons}
                          />
                        </Tippy>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {showLoadMoreButton && (
        <div className={styles.loadMore}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            size={BUTTON_SIZES.REGULAR}
            onAction={loadMoreSessions}
            data-test-id={`${dataTestPrefix}-ShowMore-Button`}
            label={intl.formatMessage({
              defaultMessage: "Show more",
              id: "aWpBzj",
            })}
            isLoading={isLoadMoreInProgress}
            className={styles.loadMoreButton}
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <ScheduledSessionsDeleteModal
          session={{ Id: sessionToBeDeleted.Id, Name: sessionToBeDeleted.Name }}
          modalClose={modalClose}
          reloadSessionsList={reloadSessionsList}
        />
      )}
    </div>
  );
};

ScheduledSessionsList.propTypes = {
  sessions: PropTypes.arrayOf(GUIDE_BROWSING_SESSION_TYPE),
  classId: PropTypes.string,
  updateSessionName: PropTypes.func.isRequired,
  loadMoreSessions: PropTypes.func.isRequired,
  reloadSessionsList: PropTypes.func.isRequired,
  isLoadMoreInProgress: PropTypes.bool.isRequired,
  showLoadMoreButton: PropTypes.bool.isRequired,
  editOrDuplicateSession: PropTypes.func.isRequired,
  windowSize: PropTypes.oneOf(WINDOW_SIZE_TYPE_LIST),
};

export default connect(
  (state) => ({
    classId: getClassId(state),
    windowSize: getWindowSize(state),
  }),
  (dispatch) => ({
    updateSessionName: ({ classId, sessionId, sessionName }) =>
      dispatch(updateScheduledSessionName({ classId, sessionId, sessionName })),
  })
)(ScheduledSessionsList);
