import { hideAppError, updateAccessError } from "../../app/actions";
import _ from "lodash";
import { getClickToShare } from "../../../apiCalls/highlights";
import actionTypes from "../../actionTypes";
import { sendEvent } from "../../app/actions";

export const clickToShareAction =
  ({ docId, email }) =>
  (dispatch) => {
    dispatch(hideAppError());
    dispatch({
      type: actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_PENDING,
    });

    dispatch(sendEvent({ name: "click2share" }));

    getClickToShare({ docId, email })
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.HIGHLIGHTS_CLICK_TO_SHARE_ERROR,
        });

        if (_.get(error, "response.status") === 403) {
          // Not a valid user, show 403 screen
          dispatch(updateAccessError(true));
        }
      });
  };
