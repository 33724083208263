import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";
import {
  getSearchResults,
  getIsSearchLoading,
  getSearchCurrentPage,
  getSearchPagesCount,
  getSearchQuery,
} from "../../../../../state/workspace/myworkspaces/search/selectors";
import {
  searchMyWorkspaces,
  changeSearchPage,
} from "../../../../../state/workspace/myworkspaces/search/actions";
import { wsItemType } from "../../../../../state/workspace/myworkspaces/list/types";
import styles from "./WorkspacesSearchItems.module.scss";
import WorkspaceTile from "../WorkspaceTile/WorkspaceTile";
import Pagination from "@hapara/ui/src/atomic/Pagination/Pagination";
import WorkspacesSearchEmptyState from "../WorkspacesSearchEmptyState/WorkspacesSearchEmptyState";
import ResourcesLoading from "../../../Resources/ResourcesLoading/ResourcesLoading";

export const WorkspacesSearchItems = ({
  items,
  currentPage,
  pagesCount,
  searchQuery,
  isLoading,
  changeSearchPage,
  searchMyWorkspaces,
}) => {
  const handlePageSelect = (page) => {
    changeSearchPage(page);
  };

  useEffect(() => {
    searchMyWorkspaces({ query: searchQuery, page: currentPage });
  }, [currentPage, searchQuery, searchMyWorkspaces]);

  if (isLoading || items === null) {
    return <ResourcesLoading />;
  }

  return (
    <div className={styles.root}>
      {items.length === 0 && <WorkspacesSearchEmptyState />}

      {items.length > 0 && (
        <React.Fragment>
          <ul className={styles.list}>
            {_.map(items, (item) => (
              <li
                className={styles.listItem}
                key={item.Id}
                data-test-id="Ws-WorkspacesSearch-ListItem"
              >
                <WorkspaceTile
                  item={item}
                  dataTestIdPrefix="Ws-WorkspacesSearchListItem"
                  isShowLabels={false}
                  isShowProgress={false}
                />
              </li>
            ))}
          </ul>

          <Pagination
            pages={pagesCount}
            currentPage={currentPage}
            onSelectPage={handlePageSelect}
            dataTestIdPrefix="Ws-WorkspacesSearchPagination"
          />
        </React.Fragment>
      )}
    </div>
  );
};

WorkspacesSearchItems.propTypes = {
  items: PropTypes.arrayOf(wsItemType),
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  searchMyWorkspaces: PropTypes.func.isRequired,
  changeSearchPage: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    items: getSearchResults(state),
    currentPage: getSearchCurrentPage(state),
    pagesCount: getSearchPagesCount(state),
    isLoading: getIsSearchLoading(state),
    searchQuery: getSearchQuery(state),
  }),
  (dispatch) => ({
    searchMyWorkspaces: (options) => dispatch(searchMyWorkspaces(options)),
    changeSearchPage: (page) => dispatch(changeSearchPage(page)),
  })
)(WorkspacesSearchItems);
