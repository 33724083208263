import _ from "lodash";
import { createSelector } from "reselect";

const getSendMessageModalInfo = (state) =>
  _.get(state, "highlights.sendMessage.modalData");

export const getSendMessageModalStatus = createSelector(
  getSendMessageModalInfo,
  (sm) => _.get(sm, "isOpen", false)
);

export const getSendMessageModalErrorStatus = createSelector(
  getSendMessageModalInfo,
  (sm) => _.get(sm, "isError", false)
);

export const getSendMessageModalSuccessStatus = createSelector(
  getSendMessageModalInfo,
  (sm) => _.get(sm, "isSent", false)
);

export const getSendMessageSelectedStudent = createSelector(
  getSendMessageModalInfo,
  (sm) => _.get(sm, "selectedStudent")
);

export const getSendMessageSelectedRecipientType = createSelector(
  getSendMessageModalInfo,
  (sm) => _.get(sm, "selectedRecipientType")
);
